import React, { useEffect, useState, Fragment } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import DataTable from 'react-data-table-component';
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import AuthService from "../Authentication/auth.service";
import { useTranslation } from 'react-i18next';
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";
import Alerts from "../../components/Alerts/alerts"
import AlertService from '../../components/Alerts/alerts';
import Services from "./services";
import AddMember from "./AddMember";
import InfoIcon from "@mui/icons-material/Info";
import UpdateMember from "./UpdateMember";
import MemberFilter from "../../components/Table Filters/MemberFilter.js";
import styled from 'styled-components';
import DashboardService from "../Dashboard/dashboard.service.js";
import PermissionAccess from '../../PermissionAccess/Access.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faEye, faKey, faUser, faStar } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@mui/material/Tooltip';
import { Chip } from '@mui/material';
import StockistApprovalPopup from './MemberStockistApprovalPopup';
import axios from "axios";
import { Modal, FormGroup, Label, Input } from "reactstrap";

const B_URL = process.env.REACT_APP_LOGINURL
const API_URL = process.env.REACT_APP_BASEURL;


const headers = {
  'Content-Type': 'application/json',
  'token': localStorage.getItem("session-id")
}


const StyledChip = styled(Chip)`
  font-size: 12px;
  height: 24px;
  font-weight: 500;
`;
const ImageContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const SmallImage = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  transition: all 0.3s ease;
`;

const LargeImage = styled.img`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 80%;
  max-height: 80%;
  z-index: 1000;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0,0,0,0.3);
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.7);
  z-index: 999;
`;

const MemberListing = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [Customers, setCustomers] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [filterStatus, setFilterStatus] = useState('');

  const [FilterPackage, setFilterPackage] = useState('');
  const [FilterRank, setFilterRank] = useState('');

  const { authData, accessPages, super_admin, current_module } = PermissionAccess();
  const [MemberToEdit, setMemberToEdit] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [popupRequest, setPopupRequest] = useState(null);
  const [statusModal, setStatusModal] = useState(false);
  const [Package_Modal, setPackageModal] = useState(false);
  const [currentMember, setCurrentMember] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [packages, setPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);


  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchCustomers(currentPage, pageLimit, filterText, filterStatus, FilterPackage, FilterRank);
    fetchPackages(); // Fetch packages
  }, [pageLimit, i18n]);

  const fetchPackages = async () => {
    try {
      let payload = {
        functionName: "GetPackages",
        postData: {
          offset: 0,
          filterStatus: "",
          filterText: "",
          sort: { _id: -1 },
          limit: 10,
          business_id: []
        }
      }
      const response = await axios.post(`${B_URL}PackageModule/GetPackages`, payload, { headers });
      console.log("Packages response", response);
      setPackages(response?.data?.data || []);
    } catch (error) {
      console.error("Failed to fetch packages", error);
    }
  };


  const handleActivate = (member) => {
    setPopupRequest(member); // Open popup with member details
  };

  const handleApprove = async () => {
    setLoading(true);
    var payload = {
      "functionName": "updateStockistStatus",
      "postData": {
        "popupRequest": popupRequest,
        "status": 'approved',

      },
    }
    let response = await axios.post(B_URL + "TopUpModule/updateStockistStatus", payload, { headers });
    setLoading(false);
    console.log("updateStockistStatus response", response);
    if (response.data.status === true) {
      Alerts.swalSuccessAlert("Member upgrade to Stockist successfully!");
    } else {
      Alerts.swalErrorAlert(response.data.message);
    }
    setPopupRequest(null);
    fetchCustomers(currentPage, pageLimit, filterStatus);
  };

  const handleReject = () => {
    Alerts.swalConfirmGeneralAlert("Are you sure you want to reject this Request?")
      .then(async (res) => {
        if (res) {
          setLoading(true);
          var payload = {
            "functionName": "updateStockistStatus",
            "postData": {
              "popupRequest": popupRequest,
              "status": 'rejected',
            },
          }
          let response = await axios.post(B_URL + "TopUpModule/updateStockistStatus", payload, { headers });
          setLoading(false);
          Alerts.swalSuccessAlert("Activation rejected!");
          setPopupRequest(null);
          fetchCustomers(currentPage, pageLimit, filterStatus);
        }
      });
  };
  const toggleStatusModal = () => setStatusModal(!statusModal);
  const PackageModal = () => setPackageModal(!Package_Modal);

  const handleStatusChange = async () => {
    if (!selectedStatus) {
      Alerts.swalErrorAlert("Please select a valid status.");
      return;
    }

    setLoading(true);
    try {
      const payload = {
        functionName: "updateAccountStatus",
        postData: {
          MemberId: currentMember._id,
          status: selectedStatus,
        },
      };
      const response = await axios.post(B_URL + "MemberModule/updateAccountStatus", payload, { headers });

      if (response.data.status) {
        Alerts.swalSuccessAlert("Member status updated successfully!");
        setStatusModal(false);
        fetchCustomers(currentPage, pageLimit, filterText, filterStatus, FilterPackage, FilterRank); // Refresh the table data
      } else {
        Alerts.swalErrorAlert(response.data.message || "Failed to update status.");
      }
    } catch (error) {
      Alerts.swalErrorAlert("An error occurred while updating the status.");
    } finally {
      setLoading(false);
    }
  };



  const handlePackageChange = () => {
    console.log("selectedPackage", selectedPackage);

    const selectedPackageDetails = selectedPackage !== null ? packages.find(pkg => pkg._id === selectedPackage) : null;

    // Filter selected products
    const selectedProducts = {};
    console.log("selectedPackageDetails",selectedPackageDetails)

    setPackageModal(false);
    // const userString = localStorage.getItem('user');
    // const user = JSON.parse(userString);
    // const userId = user['_id'];
    let Payload = {
      functionName: "Update_User_Package",
      postData: {
        user_id: currentMember._id,
        selectedPackage: selectedPackageDetails,
        totalMPV: selectedPackageDetails?.mpv,
        totalPrice: selectedPackageDetails?.amount,
        selectedOrderType: '',
        selectedDeliveryAddress: '',
        customAddress: '',
        receiver_name: '',
        receiver_phone: '',
        paymentProofBase64: '',
        selectedProducts: selectedProducts
      }
    };
    console.log("Payload", Payload)



    // HandlePlaceOrder(selectedProducts, selectedDeliveryAddress, customAddress, totalPrice, totalMPV, Payload)

    setLoading(true);
    DashboardService.Update_User_Package(localStorage.getItem("session-id"), Payload)
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          Alerts.swalClickProceedAlert(response.data.message).then(res => {
            console.log("Resp:-", res)
            if (res === true) {
              (navigate("/Member"))
            }
          })
        } else {
          AlertService.swalErrorAlert(response.data.message);
          console.error("Failed to fetch dashboard data");
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching dashboard data:", error);
        if (error.response?.data?.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        }
      });


  };

  const openStatusModal = (member) => {
    setCurrentMember(member);
    setSelectedStatus(member.status); // Pre-select the current status
    toggleStatusModal();
  };
  const openPackageModal = (member) => {
    setCurrentMember(member);
    setSelectedStatus(member.status); // Pre-select the current status
    PackageModal();
  };

  function fetchCustomers(page_index, page_limit, filterText, filterStatus, FilterPackage, FilterRank) {
    var offset = (page_index - 1) * page_limit;
    if (offset < 1) {
      offset = 0;
    }
    const stored_user = authData?.userData;
    let business = []
    if (stored_user?.businesses) {
      business = stored_user?.businesses
    }

    var getData = {
      functionName: "GetMembers",
      "postData": {
        "offset": offset,
        "filterStatus": filterStatus,
        "filterText": filterText,
        "FilterPackage": FilterPackage,
        "FilterRank": FilterRank,
        "sort": {
          "_id": -1
        },
        "limit": page_limit,
        business_id: business
      }
    }
    setLoading(true);
    Services.GetMembers(authData?.sessionID, getData).then((response) => {
      setLoading(false);
      console.log("Get Member response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setCustomers(response.data.data);
        console.log("Responsse", response.data);
        setTotalRows(response.data.count.count);
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };
  const handleSuccess = () => {
    fetchCustomers(currentPage, pageLimit, filterText, filterStatus, FilterPackage, FilterRank);
  };

  const confirmDelete = (id) => {
    Alerts.swalConfirmDeleteAlert(t("Once deleted, you will not be able to recover this Record!"))
      .then(res => {
        if (res) {
          deleteMember(id);
        }
      });
  };

  const deleteMember = (id) => {
    setLoading(true);
    Services.DeleteMember(authData.sessionID, id)
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          Alerts.swalSuccessAlert(response.data.message || t("Data Deleted Successfully."));
          fetchCustomers(currentPage, pageLimit, filterText, filterStatus, FilterPackage, FilterRank);
        } else {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
          } else {
            Alerts.swalErrorAlert(response.data.message || t("Error: Can't delete at the moment."));
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        Alerts.swalErrorAlert(t("Delete operation failed"));
      });
  };

  const handleDeleteSelected = () => {
    if (selectedRows?.length > 0) {
      Alerts.swalConfirmDeleteAlert(t(selectedRows?.length + " records selected. Once deleted, you will not be able to recover this!"))
        .then(res => {
          if (res) {
            deleteBulkCustomers(selectedRows);
          }
        });
    } else {
      Alerts.swalErrorAlert('No records selected');
    }
  };

  const deleteBulkCustomers = (ids) => {
    setLoading(true);
    Services.DeleteBulkMember(authData.sessionID, ids)
      .then((response) => {
        setLoading(false);
        setSelectedRows([]);
        if (response.data.status) {
          Alerts.swalSuccessAlert(response.data.message || t("Data Deleted Successfully."));
          fetchCustomers(currentPage, pageLimit, filterText, filterStatus, FilterPackage, FilterRank);
        } else {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
          } else {
            Alerts.swalErrorAlert(response.data.message || t("Error: Can't delete the Collection Data at the moment."));
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        Alerts.swalErrorAlert(t("Delete operation failed"));
      });
  };
  const handleSelectAllChange = (e) => {
    if (e.target.checked) {
      setSelectedRows(Customers.map(Member => Member._id));
    } else {
      setSelectedRows([]);
    }
  };

  function regeneratePassword(_id) {
    let msg = t("Are you sure you want to regenerate password for this user?")
    Alerts.swalConfirmGeneralAlert(msg).then(res => {
      console.log("Resp:-", res)
      if (res === true) {
        sendPassword(_id)
      }
    })
  };

  function sendPassword(id) {
    setLoading(true);
    Services.sendPassword(authData.sessionID, id).then((response) => {
      console.log("Send Password response:-", response)
      setLoading(false);
      if (response.data.status) {
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Password emailed Successfully."))
        }
        console.log(response.data.message)
      }
      else {
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't send the Password."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Operation failed"))
      })
  };


  const columns = [
    {
      name: t("Username"),
      selector: row => row.username,
      sortable: true,
      cell: row => (
        <div className="d-flex align-items-center">
          <FontAwesomeIcon icon={faUser} className="text-primary me-2" />
          {row.username}
        </div>
      )
    },
    // {
    //   name: t("Full Name"),
    //   selector: row => row.fullName,
    //   sortable: true,
    // },
    // {
    //   name: t("Mobile Number"),
    //   selector: row => row.mobileNumber,
    //   cell: row => (
    //     <div>
    //       {row.mobileNumber || "-"}
    //     </div>
    //   )
    // },
    {
      name: t("Email Address"),
      selector: row => row.email,
      cell: row => (
        <div style={{
          maxWidth: '200px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}>
          {row.email}
        </div>
      )
    },
    {
      name: t("Sponsor"),
      selector: row => row.sponsor_username,
      cell: row => (
        <div>
          {row.sponsor_username || "-"}
        </div>
      )
    },
    {
      name: t("Package"),
      selector: row => row?.package_name,
      cell: row => (
        <StyledChip
          onClick={() => openPackageModal(row)}
          label={row?.package_name || "No Package"}
          color="primary"
          variant="outlined"
          size="small"
        />
      )
    },
    {
      name: t("Rank"),
      selector: row => row.rank?.name,
      cell: row => (
        <div className="d-flex align-items-center">
          <FontAwesomeIcon icon={faStar} className="text-warning me-1" />
          {row.rank?.name || "No Rank"}
        </div>
      )
    },
    {
      name: t("Type"),
      width: '15%',
      selector: row => {
        if (row.type) return row.type;
        if (row?.StockistData?.selectedSource === 'Nano ProTech') {
          return row?.StockistData?.status === 'pending' ? 'Requested for Stockist' : 'Member';
        }
        return 'Member';
      },
      cell: row => {
        const isPending = row?.StockistData?.status === 'pending';
        const label = row.type
          || (row?.StockistData?.selectedSource === 'Nano ProTech'
            ? (isPending ? 'Requested for Stockist' : 'Member')
            : 'Member');
        const color = row.type
          ? 'default'
          : (row?.StockistData?.selectedSource === 'Nano ProTech'
            ? (isPending ? 'primary' : 'secondary')
            : 'secondary');

        return (
          <StyledChip
            onClick={() => isPending && handleActivate(row)}
            label={label}
            color={color}
            size="small"
          />
        );
      }
    },
    {
      name: "Status",
      cell: (row) => (
        <StyledChip
          label={row.status}
          size="small"
          onClick={() => openStatusModal(row)}
          sx={{
            cursor: "pointer",
            backgroundColor:
              row.status === "Active" ? "#dcfce7" :
                row.status === "Inactive" ? "#bfdbfe" :
                  row.status === "Suspended" ? "#fef3c7" :
                    "#fee2e2",
            color:
              row.status === "Active" ? "#166534" :
                row.status === "Inactive" ? "#1e40af" :
                  row.status === "Suspended" ? "#92400e" :
                    "#991b1b",
            border:
              row.status === "Active" ? "1px solid #bbf7d0" :
                row.status === "Inactive" ? "1px solid #93c5fd" :
                  row.status === "Suspended" ? "1px solid #fde68a" :
                    "1px solid #fecaca",
            fontWeight: "500",
          }}
        />
      ),
    },
    {
      name: t("Actions"),
      width: '150px',
      selector: (row) => {
        return (
          <div>
            <button className="mt-2 mb-2 me-2 btn-icon btn">
              {(super_admin || current_module?.ViewData === 1) && (
                <Tooltip title={t("View Details")}>
                  <Link to="/Member-details" state={row}>
                    <i className="pe-7s-note2 btn-icon-wrapper text-info"> </i>
                  </Link>
                </Tooltip>
              )}
              {(super_admin || current_module?.EditData === 1) && (
                <Tooltip title={t("Edit")}>
                  <i onClick={() => setMemberToEdit(row)} className="pe-7s-pen btn-icon-wrapper text-primary"> </i>
                </Tooltip>
              )}
              {(super_admin || current_module?.DeleteData === 1) && (
                <Tooltip title={t("Delete")}>
                  <i onClick={() => confirmDelete(row._id)} className="pe-7s-trash text-danger btn-icon-wrapper"> </i>
                </Tooltip>
              )}
              {!super_admin ? current_module?.RegeneratePassword === 1 &&
                <Tooltip placement="top" title={t("Regenerate Password")} arrow>
                  <a onClick={() => regeneratePassword(row._id)} style={{ cursor: "pointer" }} className="text-warning btn-sm mt-4">
                    <FontAwesomeIcon icon={["fas", "key"]} />
                  </a>
                </Tooltip>
                :
                <Tooltip placement="top" title={t("Regenerate Password")} arrow>
                  <a onClick={() => regeneratePassword(row._id)} style={{ cursor: "pointer" }} className="text-warning btn-sm mt-4">
                    <FontAwesomeIcon icon={["fas", "key"]} />
                  </a>
                </Tooltip>
              }

            </button>
          </div>
        );
      }
    }
  ]

  const handleCheckboxChange = (e, row) => {
    if (e.target.checked) {
      setSelectedRows([...selectedRows, row._id]);
    } else {
      setSelectedRows(selectedRows.filter(id => id !== row._id));
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchCustomers(page, pageLimit, filterText, filterStatus, FilterPackage, FilterRank);
  };

  const handlePerRowsChange = async (newLimit, page) => {
    setPageLimit(newLimit);
  };

  const handleSearch = (searchText, status, Package, Rank) => {
    setFilterText(searchText);

    setFilterStatus(status);
    setFilterRank(Rank);
    setFilterPackage(Package);

    fetchCustomers(0, pageLimit, searchText, status, Package, Rank);
  };

  const handleClear = () => {
    setFilterText('');
    setFilterStatus('');
    fetchCustomers(0, pageLimit, '', '', '', '');
  };

  const subHeaderComponentMemo = React.useMemo(() => (
    <MemberFilter
      onSearch={handleSearch}
      onClear={handleClear}
      filterText={filterText}
      filterStatus={filterStatus}
      FilterPackage={FilterPackage}
      FilterRank={FilterRank}

    />
  ), [filterText, filterStatus]);

  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className="bg-light-gray bg-animation">
            <div className="d-flex justify-content-center align-items-center">
              <Col md="12" className="mx-auto app-login-box">

                <div className="app-page-title mb-3">
                  <div className="page-title-wrapper">
                    <div className="page-title-heading fw-bold">
                      <div className="page-title-icon">
                        <i className="pe-7s-note2 icon-gradient bg-tempting-azure" />
                      </div>
                      <div>
                        {t("Member Data Management")}
                        <div className="page-title-subheading">
                          {t("View Basic and detailed information of ")} {t("Members")}
                        </div>
                      </div>
                    </div>
                    <div className="page-title-actions">
                      <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-success btn-hover-shine me-3">
                        {t("Back")}
                      </button>
                    </div>
                  </div>
                </div>

                <Card className="main-card mb-3">
                  <CardBody>
                    <LoadingOverlay active={loading} spinner={<DNALoader />}>
                      <Row className="mb-3">
                        <Col md="12">
                          {subHeaderComponentMemo}
                          {(super_admin || current_module?.BulkDeleteData === 1) && (
                            <Button color="danger" onClick={handleDeleteSelected} className="me-2">
                              {t('Bulk Delete')}
                            </Button>
                          )}
                          {(super_admin || current_module?.AddData === 1) && (
                            <AddMember onSuccess={handleSuccess} />
                          )}
                        </Col>
                      </Row>
                      <DataTable
                        columns={columns}
                        data={Customers}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handlePerRowsChange}
                        selectableRows
                        onSelectedRowsChange={({ selectedRows }) => setSelectedRows(selectedRows.map(row => row._id))}
                        highlightOnHover
                        pointerOnHover
                        dense
                        responsive
                      />
                    </LoadingOverlay>
                  </CardBody>
                </Card>

              </Col>
            </div>
          </div>
        </div>

      </div>
      <StockistApprovalPopup
        request={popupRequest} // Pass selected member details
        onClose={() => setPopupRequest(null)} // Close popup
        onApprove={handleApprove} // Approve function
        onReject={handleReject} // Reject function
      />

      {MemberToEdit && (
        <UpdateMember
          MEMBER={MemberToEdit}
          onSuccess={() => {
            setMemberToEdit(null);
            handleSuccess();
          }}
          onCancel={() => setMemberToEdit(null)}
        />
      )}
      {/* Status Update Modal */}
      <Modal isOpen={statusModal} toggle={toggleStatusModal}>
        <div className="modal-header">
          <h5 className="modal-title">
            Update Status
            <Tooltip
              title={
                <>
                  <strong>Active:</strong> User is currently active and can access the system. <br />
                  <strong>Inactive:</strong> User is inactive and may need to be reactivated. <br />
                  <strong>Suspended:</strong> User access is temporarily restricted due to policy violations. <br />
                  <strong>Terminated:</strong> User account has been permanently removed from the system.
                </>
              }
              arrow
            >
              <InfoIcon sx={{ fontSize: "18px", color: "gray", cursor: "pointer" }} />
            </Tooltip>
          </h5>

          <button type="button" className="btn-close" onClick={toggleStatusModal}></button>
        </div>
        <div className="modal-body">
          <FormGroup>
            <Label for="statusSelect">Select Status</Label>
            <Input
              type="select"
              name="status"
              id="statusSelect"
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
            >
              <option value="">Select Status</option>
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
              <option value="Suspended">Suspended</option>
              <option value="Terminated">Terminated</option>
            </Input>
          </FormGroup>
        </div>
        <div className="modal-footer">
          <Button color="secondary" onClick={toggleStatusModal}>
            Cancel
          </Button>
          <Button color="primary" onClick={handleStatusChange}>
            Update
          </Button>
        </div>
      </Modal>

      <Modal isOpen={Package_Modal} toggle={PackageModal}>
        <div className="modal-header">
          <h5 className="modal-title">
            Update Package
            <Tooltip
              title={
                <>
                  <strong>Active:</strong> User is currently active and can access the system. <br />
                  <strong>Inactive:</strong> User is inactive and may need to be reactivated. <br />
                  <strong>Suspended:</strong> User access is temporarily restricted due to policy violations. <br />
                  <strong>Terminated:</strong> User account has been permanently removed from the system.
                </>
              }
              arrow
            >
              <InfoIcon sx={{ fontSize: "18px", color: "gray", cursor: "pointer" }} />
            </Tooltip>
          </h5>

          <button type="button" className="btn-close" onClick={PackageModal}></button>
        </div>
        <div className="modal-body">
          <FormGroup>
            <Label for="packageSelect">Select Package</Label>
            <Input
              type="select"
              name="package"
              id="packageSelect"
              value={selectedPackage}
              onChange={(e) => setSelectedPackage(e.target.value)}
            >
              <option value="">Select Package</option>
              {packages.map(pkg => (
                <option key={pkg._id} value={pkg._id}>{pkg.name}</option>
              ))}
            </Input>
          </FormGroup>
        </div>
        <div className="modal-footer">
          <Button color="secondary" onClick={PackageModal}>
            Cancel
          </Button>
          <Button color="primary" onClick={handlePackageChange}>
            Update Package
          </Button>
        </div>
      </Modal>
    </Fragment>
  );
};

export default MemberListing;