import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FaFileAlt, FaFileInvoiceDollar, FaFileUpload, FaCheckCircle, FaTimesCircle, FaBan } from 'react-icons/fa';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import DashboardService from "./dashboard.service";
import AuthService from "../Authentication/auth.service";
import Alerts from "../../components/Alerts/alerts";
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import PermissionAccess from '../../PermissionAccess/Access.js';
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";

const customStyles = {
  headRow: {
    backgroundColor: '#FFFFFF',
    color: '#A7A7A7 !important',
    border: 'none',
  },
  headCells: {
    style: {
      fontSize: '16px',
      fontWeight: 'bold', // Customize font weight
      border: 'none', // Remove bottom border of each header cell
    },
  },
  rows: {
    style: {
      border: 'none',
    },
  },
  cells: {
    style: {
      padding: '8px',
      border: 'none',

    },
  },
};
const Home = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [E_invoice_req, SetE_invoice_req] = useState(0);
  const [Customer_E_invoice_req, SetCustomer_E_invoice_req] = useState(0);
  const [submitted_to_lhdn, SetSubmitted_to_lhdn] = useState(0);
  const [valid_documents, SetValid_documents] = useState(0);
  const [invalid_documents, SetInvalid_documents] = useState(0);
  const [cancel_documents, SetCancel_documents] = useState(0);
  const [Business, setBusiness] = useState('');
  const [AllBusinesses, setAllBusinesses] = useState([]);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [currentPage, setCurrentPage] = useState("");
  const [daysDropdownValue, setDaysDropdownValue] = useState('Last 7 Days');


  const [pageLimit, setPageLimit] = useState(10);
  const [Data, setData] = useState([]);
  const { authData, accessPages, super_admin, current_module } = PermissionAccess();

  // Perform actions on component mount or when 'pageLimit' changes
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchData(0, pageLimit);
  }, [pageLimit, Business, daysDropdownValue]);

  // Perform actions on component mount or when 'pageLimit' changes
  useEffect(() => {
    fetchBusinesses()
  }, []);


  // Function to fetch table data based on page index and limit
  function fetchData(page_index, page_limit) {
    const stored_user = authData.userData
    let business = []
    if (Business !== '') {
      business.push(Business)
    } else if (stored_user.businesses) {
      business = stored_user.businesses
    }
    var offset = (page_index - 1) * page_limit;
    if (offset < 1) {
      offset = 0;
    }

    // Set loading state to true before fetching data
    setLoading(true);

    // Fetch data from DashboardService
    DashboardService.getInvoices(offset, page_limit, business, daysDropdownValue, authData.sessionID).then((response) => {
      setLoading(false); // Set loading state to false after data retrieval
      // Handle response data
      if (response.data.message === "Unauthorized") {
        // Perform logout and redirection for unauthorized access
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setData(response.data.data);
        if (response.data.data) {
          SetE_invoice_req(response.data.e_invoice_req.count);
          SetCustomer_E_invoice_req(response.data.Customer_E_invoice_req.count);
          SetSubmitted_to_lhdn(response.data.submitted_to_lhdn.count);
          SetValid_documents(response.data.valid_documents.count);
          SetInvalid_documents(response.data.invalid_documents.count);
          SetCancel_documents(response.data.cancel_documents.count);
          setTotalRows(response.data.count.count);
        }
      }
    },
      // Handle error scenarios
      (error) => {
        setLoading(false); // Set loading state to false in case of error
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  function fetchBusinesses() {
    const stored_user = authData.userData
    let business = []
    if (stored_user.businesses) {
      business = stored_user.businesses
    }

    var getData = {
      functionName: "GetBusinesses",
      postData: {
        sort: { _id: -1 },
        offset: 0,
        limit: 1000,
        business_ids: business,
      },
    };
    DashboardService.GetBusinesses(getData, authData.sessionID).then((response) => {
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        if (response.data) {
          if (response.data.data.length === 1) {
            setBusiness(response.data.data[0]._id)
          }
          setAllBusinesses(response.data.data);
        }
      }
    },
      (error) => {
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };





  const trends = [
    { icon: <FaFileAlt />, title: 'Total Documents', value: totalRows, color: 'bg-light-blue' },
    { icon: <FaFileInvoiceDollar />, title: 'Waiting to Submit', value: E_invoice_req, color: 'bg-light-yellow' },
    { icon: <FaFileInvoiceDollar />, title: 'E-Invoice Requested', value: Customer_E_invoice_req, color: 'bg-light-yellow' },
    { icon: <FaFileUpload />, title: 'Submitted to LHDN', value: submitted_to_lhdn, color: 'bg-light-pink' },
    { icon: <FaCheckCircle />, title: 'Validated Documents', value: valid_documents, color: 'bg-light-green' },
    { icon: <FaTimesCircle />, title: 'Invalid Documents', value: invalid_documents, color: 'bg-light-red' },
    { icon: <FaBan />, title: 'Cancelled Documents', value: cancel_documents, color: 'bg-light-red' },
  ];

  const handleRowClick = (row) => {
    navigate(`/e-invoice-details/${row._id}`, { state: row });
  };


  const columns = [
    { name: 'No. #', width: '8%', selector: (row, index) => `00${index + 1}`, sortable: true },
    { name: 'E-Invoice ID', width: '12%', selector: row => row?.invoice_number, sortable: true },
    { name: 'Buyer', width: '16%', selector: row => row?.user_details?.[0]?.name, sortable: true },
    { name: 'Submitted', width: '17%', selector: row => moment(row?.submitted_time).format('MM/DD/YYYY (hh:mm A)'), sortable: true },
    { name: 'Processed', width: '17%', selector: row => row.processed_time !== '' ? moment(row?.processed_time).format('MM/DD/YYYY (hh:mm A)') : 'N/A', sortable: true },
    { name: 'Total', width: '10%', selector: row => row?.total_amount, sortable: true },
    { name: 'Type', width: '10%', selector: row => row?.invoice_type, sortable: true },
    {
      name: 'Status',
      width: '10%',
      selector: row => row?.lhdn_status,
      cell: row => <span className={`status-${row?.lhdn_status?.toLowerCase()}`}
        style={{ color: row?.status === 'Valid' ? '#16DBCC' : row?.lhdn_status === 'Invalid' ? '#F16262' : row?.lhdn_status === 'Cancelled' ? 'red' : row?.lhdn_status === 'Submitted' ? '#50A6E4' : '#50A6E4' }}>
        {row?.lhdn_status === 'Valid' ? 'Valid' : row?.lhdn_status === 'Invalid' ? 'Invalid' : row?.lhdn_status === 'Cancelled' ? 'Cancelled' : row?.lhdn_status === 'Submitted' ? 'Submitted' : row.lhdn_status}
      </span>
      ,
      sortable: true
    },
  ];

  //for search with enter keypress
  function checkenterkey(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      setResetPaginationToggle(!resetPaginationToggle);
      var page = 0
      handlePageChange(page++)
    }
  };
  function handlePageChange(page_index) {
    console.log("Page index:-", page_index)
    setCurrentPage(page_index)
    fetchData(page_index, pageLimit);
  }

  async function handlePerRowsChange(newPageLimit, page_index) {
    setPageLimit(newPageLimit);
  }



  return (
    <div className="app-main__outer">
      <div >
        <LoadingOverlay tag="div" active={loading}
          styles={{ overlay: (base) => ({ ...base }) }}
          spinner={<DNALoader />}>

          <Card body>
            <div className="container mt-4">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h1 style={{ fontWeight: '900', fontSize: '26px' }}>Trends</h1>
                <div className="d-flex justify-content-end">
                  <Input
                    type="select"
                    style={{
                      borderColor: '#ccc',
                      backgroundColor: '#1E5EAA',
                      borderRadius: '4px',
                      fontSize: '16px',
                      padding: '10px 20px',
                      color: '#fff',
                      backgroundPosition: 'right 10px center',
                      backgroundRepeat: 'no-repeat',
                    }}
                    placeholder="Search Business"
                    value={Business}
                    onChange={(e) => {
                      console.log(e.target.value);
                      setBusiness(e.target.value);
                    }}
                    onKeyDown={(event) => checkenterkey(event)}
                    id="colfilter"
                  >
                    <option disabled={false} value="">
                      Select Business
                    </option>
                    {AllBusinesses &&
                      AllBusinesses.map((Businesses, index) => (
                        <option key={index} value={Businesses._id}>
                          {Businesses.name}
                        </option>
                      ))}
                  </Input>
                  <Input
                    type="select"
                    style={{
                      marginLeft: '10px',
                      backgroundColor: 'white',
                      borderRadius: '4px',
                      fontSize: '16px',
                      padding: '10px 20px',
                      color: 'black',
                      backgroundRepeat: 'no-repeat',
                    }}
                    value={daysDropdownValue} // Replace this with the actual state variable
                    onChange={(e) => setDaysDropdownValue(e.target.value)} // Replace with actual handler
                    id="daysDropdown"
                  >
                    <option value="Last 7 Days">Last 7 Days</option>
                    <option value="Last 30 Days">Last 30 Days</option>
                    <option value="Last 60 Days">Last 60 Days</option>
                  </Input>
                </div>
              </div>

              <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                <Row className="g-4">
                  {trends.map((trend, index) => (
                    <Col md={3} key={index}>
                      <Card
                        body
                        className="trend-card"
                        onClick={() => {
                          if (trend.title === "E-Invoice Requested") {
                            navigate('/request_e_invoice');
                          }
                        }}
                        style={{ cursor: trend.title === "E-Invoice Requested" ? 'pointer' : 'default' }}
                      >
                        <div className="d-flex align-items-center">
                          <div className={`icon-circle ${trend.color}`}>{trend.icon}</div>
                          <div className="trend-content">
                            <div className="trend-title">{trend.title}</div>
                            <div className="trend-value">{trend.value}</div>
                          </div>
                        </div>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </div>
              <div className="mt-5">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h1 bold style={{ fontWeight: '900', fontSize: '26px' }}>Invoices</h1>
                  <div className="d-flex">
                    <Input type="text" placeholder="Search for something" className="mr-2" />
                    <Dropdown isOpen={false} toggle={() => { }}>
                      <DropdownToggle caret color="light">
                        All (118)
                      </DropdownToggle>
                    </Dropdown>
                  </div>
                </div>
                <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                  <DataTable
                    columns={columns}
                    data={Data}
                    pagination
                    customStyles={customStyles}
                    paginationPerPage={10}
                    paginationRowsPerPageOptions={[5, 10, 15, 20]}
                    onRowClicked={handleRowClick}
                    pointerOnHover
                    fixedHeader
                    fixedHeaderScrollHeight="400px"
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handlePerRowsChange}
                    paginationResetDefaultPage={resetPaginationToggle}

                  />
                </div>
              </div>
              <style jsx>{`
            .trend-card {
              padding: 25px;
              border: none;
              border-radius:10px;
              box-shadow: 0 2px 10px rgba(0,0,0,0.05);
              background-color: #ffffff;
            }
            .icon-circle {
              width: 50px;
              height: 50px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 15px;
              font-size: 20px;
              flex-shrink: 0;
            }
            .trend-content {
              flex-grow: 1;
            }
            .trend-title {
              font-size: 14px;
              color: #777;
              margin-bottom: 2px;
            }
            .trend-value {
              font-size: 22px;
              font-weight: 600;
              color: #333;
            }
            .bg-light-blue { background-color: #e8f4ff; color: #4a90e2; }
            .bg-light-yellow { background-color: #fff8e1; color: #ffc107; }
            .bg-light-pink { background-color: #ffe8f0; color: #ff4081; }
            .bg-light-green { background-color: #e8f5e9; color: #4caf50; }
            .bg-light-red { background-color: #ffebee; color: #f44336; }
          `}</style>
            </div>
          </Card>
        </LoadingOverlay>
      </div>
    </div>
  );
};

export default Home;