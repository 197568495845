import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import BusinessService from "./Businesses.service.js";
import AuthService from "../Authentication/auth.service.js";
import Alerts from "../../components/Alerts/alerts.js";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import { Row, Col, CardBody, Card } from "reactstrap";
import { Tooltip } from "@mui/material";

const DynamicContentTab = ({ section_id, onSuccess }) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [businessAttributes, setBusinessAttributes] = useState([]);
    const [dynamicCollection, setDynamicCollection] = useState('');
    const [hoveredimages, setHoveredimages] = useState({});
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        value: [],
        attribute_id: [],
        business_id: id,
        section_id: section_id
    });

    const fetchDynamicData = async () => {
        try {
            const optresponse = await BusinessService.getoptions(dynamicCollection, "", {}, 5000);
            const optdata = await optresponse.data.data;
            // console.log('optdata :',optdata);
          
        } catch (error) {
          console.log("error", error);
        }
      };

    const fetchBusinessAttributes = () => {   ///
        BusinessService.getAllBusinessAttributes(id).then(async (response) => {
            if (response.data.message === "Unauthorized") {
                AuthService.logout();
                navigate("/login");
                window.location.reload();
            } else {
                 const dynamicAttributes = response.data.data;
                  //set table selection options
                 // Use map and Promise.all to handle asynchronous operations
                 const updatedAttributes = await Promise.all(dynamicAttributes.map(async dynamicAttr => {
                     if (dynamicAttr.getOptionsFrom !== null) {
                         const optresponse = await BusinessService.getoptions(dynamicAttr.getOptionsFrom, "", {}, 5000);
                         const optdata = await optresponse.data.data;
                         const dynamicArray = optdata.map(rowAttr => ({
                             'option_label': rowAttr[dynamicAttr.getOptionFieldName], //set table column selection
                             'option_value': rowAttr._id,
                         }));
         
                         dynamicAttr.BusinessAttributesDynamicDataDetails.push(...dynamicArray);
                     }
                     return dynamicAttr;
                 }));
         
                 setBusinessAttributes(updatedAttributes);

                const filteredAttributes = response.data.data.filter(attr => attr.SectionDetails[0]._id === section_id);
                if (filteredAttributes.length > 0) {
                    const initialValues = filteredAttributes.map(attr => attr.value);
                    const initialAttributeIds = filteredAttributes.map(attr => attr.attribute_id);
                    setData(prevData => ({
                        ...prevData,
                        value: initialValues,
                        attribute_id: initialAttributeIds
                    }));
                }
            }
        }, (error) => {
            const errContent = (error.response && error.response.data) || error.message || error.toString();
            if (errContent) {
                Alerts.swalErrorAlert(errContent);
            } else {
                Alerts.swalErrorAlert("Server Error, Please try again later.");
            }
        });
    };

    useEffect(() => {
        i18n.changeLanguage(localStorage.getItem('language'));
        fetchBusinessAttributes();
        fetchDynamicData();
    }, [id]);

    const handleChange = (index, event) => {
        const { name, value, type, checked, files } = event.target;
        let newData = { ...data };
    
        if (name === "value[]") {
            if (type === "checkbox" || type === "radio") {
                newData.value[index] = checked ? value : "";
            } else if (type === "file") {
                const file = files[0];
                if (file) {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        newData.value[index] = e.target.result; // This is the base64 string
                        setData(newData);
                    };
                    reader.readAsDataURL(file);
                }
            } else {
                newData.value[index] = value;
            }
        } else if (name === "attribute_id[]") {
            newData.attribute_id[index] = value;
        }
    
        if (type !== "file") {
            setData(newData);
        }
    };    

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { business_id, section_id, value, attribute_id } = data;
    
        const updates = value.map((val, idx) => {
            const currentAttribute = businessAttributes.find(attr => attr._id === attribute_id[idx]);
            return {
                attribute_id: attribute_id[idx],
                value: val,
                baseObject: currentAttribute && currentAttribute.field_type === 'File Input' ? val : undefined
            };
        });
    
        const payload = {
            business_id,
            section_id,
            updates
        };
        setLoading(true);
        BusinessService.updateMultipleBusinessAttributes(payload).then((response) => {
            setLoading(false);
            if (response.data.message === "Unauthorized") {
                AuthService.logout();
                navigate("/login");
                window.location.reload();
            } else {
                if (response.data.status === true) {
                    fetchBusinessAttributes();
                    Alerts.swalSuccessAlert('Business attributes updated successfully.');
                } else {
                    Alerts.swalErrorAlert("Something went wrong, Please try again.");
                }
            }
        }, (error) => {
            setLoading(false);
            const errContent = (error.response && error.response.data) || error.message || error.toString();
            if (errContent) {
                Alerts.swalErrorAlert(errContent);
            } else {
                Alerts.swalErrorAlert("Server Error, Please try again later.");
            }
        });
    };

    function confirmDelete(_id) {
        let msg = t("Once deleted, you will not be able to recover this user!");
        Alerts.swalConfirmDeleteAlert(msg).then(res => {
            console.log("Resp:-", res);
            if (res === true) {
                sendDelete(_id);
            }
        });
    };

    function sendDelete(id) {
        setLoading(true);
        BusinessService.deleteBusinessAttribute(id).then((response) => {
            console.log("Delete Business Attribute response:-", response);
            setLoading(false);
            fetchBusinessAttributes();
            if (response.data.status) {
                if (response.data.message !== '') {
                    Alerts.swalSuccessAlert(response.data.message);
                } else {
                    Alerts.swalSuccessAlert(t("Business Attribute Deleted Successfully."));
                }
                console.log(response.data.message);
            } else {
                setLoading(false);
                if (response.data.message !== '') {
                    if (response.data.message === "Unauthorized") {
                        AuthService.logout();
                        navigate("/login");
                        window.location.reload();
                    } else {
                        Alerts.swalErrorAlert(response.data.message);
                    }
                } else {
                    Alerts.swalErrorAlert(t("Error: Can't delete the Section."));
                }
            }
        },
            (error) => {
                setLoading(false);
                const errContent = (error.response && error.response.data) || error.message || error.toString();
                Alerts.swalErrorAlert(t("Delete operation failed"));
            });
    };

    const handleToggleModalEditBusinessAttribute = (arrayData) => {
        onSuccess(arrayData);
    };

    return (
        <Row>
            <Fragment>
                <Row>
                    <Col md="12">
                        <Card className="main-card mb-3">
                            <CardBody>
                                <LoadingOverlay tag="div" active={loading}
                                    styles={{ overlay: (base) => ({ ...base }) }}
                                    spinner={<DNALoader />}>
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">
                                            {businessAttributes.filter(attr => attr.SectionDetails[0]._id === section_id).map((b_attr_val, index) => (
                                                <div className="col-6" key={index}>
                                                    <div className="form-group row mt-3">

                                                    <label 
                                                            className="col-sm-2 col-form-label"
                                                            style={{
                                                                ...(b_attr_val.field_type === 'File Input' 
                                                                    ? { 
                                                                        cursor: 'pointer', 
                                                                        textDecoration: 'underline', 
                                                                        position: 'relative'
                                                                    } 
                                                                    : {})
                                                            }}
                                                            onMouseEnter={() => b_attr_val.field_type === 'File Input' && setHoveredimages(prev => ({ ...prev, [b_attr_val._id]: true }))}
                                                            onMouseLeave={() => b_attr_val.field_type === 'File Input' && setHoveredimages(prev => ({ ...prev, [b_attr_val._id]: false }))}
                                                        >
                                                            {b_attr_val.AttributeDetails[0].lable}
                                                            {b_attr_val.field_type === 'File Input' && hoveredimages[b_attr_val._id] && (
                                                                <div 
                                                                    style={{
                                                                        position: 'absolute',
                                                                        top: '100%',
                                                                        left: '0',
                                                                        zIndex: 1000,
                                                                        display: 'block',
                                                                    }}
                                                                >
                                                                    <img 
                                                                        src={b_attr_val.value} 
                                                                        alt={b_attr_val.AttributeDetails[0].lable}
                                                                        style={{
                                                                            maxWidth: '150px',
                                                                            maxHeight: '150px',
                                                                            objectFit: 'contain',
                                                                        }}
                                                                    />
                                                                </div>
                                                            )}
                                                        </label>

                                                        <div className="col-sm-8">
                                                            {b_attr_val.field_type === 'Dropdown' ? (
                                                                <select className="form-control"
                                                                    placeholder="Value"
                                                                    name="value[]"
                                                                    value={data.value[index] || ""}
                                                                    onChange={(e) => handleChange(index, e)}
                                                                >
                                                                    <option value=''>--Choose an option--</option>
                                                                    {b_attr_val.BusinessAttributesDynamicDataDetails && b_attr_val.BusinessAttributesDynamicDataDetails.length > 0 ? (
                                                                        b_attr_val.BusinessAttributesDynamicDataDetails.map((optionRow, optionIndex) => (
                                                                            <option key={optionIndex} value={optionRow.option_value}>{optionRow.option_label}</option>
                                                                        ))
                                                                    ) : (
                                                                        <option value=''>No option yet</option>
                                                                    )}
                                                                </select>
                                                            ) : b_attr_val.field_type === 'Checkbox' ? (
                                                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                                                    {b_attr_val.BusinessAttributesDynamicDataDetails && b_attr_val.BusinessAttributesDynamicDataDetails.length > 0 ? (
                                                                        b_attr_val.BusinessAttributesDynamicDataDetails.map((checkboxRow, checkboxIndex) => (
                                                                            <div key={checkboxIndex} style={{ marginLeft: '15px' }}>
                                                                                <input
                                                                                    type="checkbox"
                                                                                    name="value[]"
                                                                                    value={checkboxRow.option_value}
                                                                                    checked={data.value[index] === checkboxRow.option_value}
                                                                                    onChange={(e) => handleChange(index, e)}
                                                                                />
                                                                                <label style={{ marginLeft: '5px' }}>{checkboxRow.option_label}</label>
                                                                            </div>
                                                                        ))
                                                                    ) : (
                                                                        <span>No checkbox yet</span>
                                                                    )}
                                                                </div>
                                                            ) : b_attr_val.field_type === 'Radio Button' ? (
                                                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                                                    {b_attr_val.BusinessAttributesDynamicDataDetails && b_attr_val.BusinessAttributesDynamicDataDetails.length > 0 ? (
                                                                        b_attr_val.BusinessAttributesDynamicDataDetails.map((radioRow, radioIndex) => (
                                                                            <div key={radioIndex} style={{ marginLeft: '15px' }}>
                                                                                <input
                                                                                    type="radio"
                                                                                    name="value[]"
                                                                                    value={radioRow.option_value}
                                                                                    checked={data.value[index] === radioRow.option_value}
                                                                                    onChange={(e) => handleChange(index, e)}
                                                                                />
                                                                                <label style={{ marginLeft: '5px' }}>{radioRow.option_label}</label>
                                                                            </div>
                                                                        ))
                                                                    ) : (
                                                                        <span>No radio button yet</span>
                                                                    )}
                                                                </div>
                                                            ) : b_attr_val.field_type === 'File Input' ? (
                                                                <input
                                                                        type='file'
                                                                        className="form-control"
                                                                        placeholder="Value"
                                                                        name="value[]"
                                                                        onChange={(e) => handleChange(index, e)}
                                                                        accept="image/*" // This ensures only image files can be selected
                                                                    />
                                                            ) : (
                                                                <>
                                                                
                                                                <input
                                                                    type={
                                                                        b_attr_val.field_type === 'Text Input' ? 'text'
                                                                        : b_attr_val.field_type === 'Number Input' ? 'number'
                                                                        : b_attr_val.field_type === 'Email Input' ? 'email'
                                                                        : b_attr_val.field_type === 'Password Input' ? 'password'
                                                                        : b_attr_val.field_type === 'Time Picker' ? 'time'
                                                                        : 'date'
                                                                    }
                                                                    className="form-control"
                                                                    placeholder="Value"
                                                                    name="value[]"
                                                                    value={data.value[index] || ""}
                                                                    onChange={(e) => handleChange(index, e)}
                                                                />
                                                                </>
                                                            )}
                                                        </div>
                                                        {/* <div className="col-sm-2">
                                                            <Tooltip placement="top" title={`${t("Update")} ${t("Attribute")}`} arrow onClick={() => handleToggleModalEditBusinessAttribute(b_attr_val)} style={{ cursor: "pointer" }}>
                                                                <i className="pe-7s-pen btn-icon-wrapper text-primary fs-5 p-1"> </i>
                                                            </Tooltip>
                                                            <Tooltip placement="top" title={`${t("Delete")} ${t("Attribute")}`} arrow onClick={() => confirmDelete(b_attr_val._id)} style={{ cursor: "pointer" }}>
                                                                <i className="pe-7s-trash text-danger btn-icon-wrapper fs-5"> </i>
                                                            </Tooltip>
                                                        </div> */}
                                                        <input
                                                            type="hidden"
                                                            className="form-control"
                                                            name="attribute_id[]"
                                                            value={data.attribute_id[index] || ""}
                                                            onChange={(e) => handleChange(index, e)} ////
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                            <div className="form-group row mt-5">
                                                <div className="col-5"></div>
                                                <div className="col-7">
                                                    <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-light btn-hover-shine me-3">{t("Cancel")}</button>
                                                    <button type="submit" className="btn btn-custom-color">{t("Save")}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </LoadingOverlay>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Fragment>
        </Row>
    );
};

export default DynamicContentTab;
