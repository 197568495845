import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Input, Button, Form, FormGroup, Label, Modal, ModalBody } from 'reactstrap';
import { FaPlus, FaCheck, FaTrash, FaArrowLeft } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom';
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import Alerts from "../../../components/Alerts/alerts";
import DashboardService from "../dashboard.service";
import AuthService from "../../Authentication/auth.service";
import { useTranslation } from 'react-i18next';

const OrderConfirmationModal = ({ isOpen, toggle, orderId }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered className="order-confirmation-modal">
      <ModalBody className="text-center p-4">
        <div className="confirmation-icon mb-3">
          <FaCheck size={24} color="#fff" />
        </div>
        <h4 className="mb-2">Order Placed</h4>
        <h2 className="order-id mb-0">ID# {orderId}</h2>
      </ModalBody>
    </Modal>
  );
};

const AddNewRFQ = () => {
  const [items, setItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoryItems, setCategoryItems] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [orderId, setOrderId] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState(new Set());
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('user')));
  const [Fetched_Businesses, setFetched_Businesses] = useState([]);
  const location = useLocation();
  const orderDetails = location.state?.orderDetails;
  console.log("order - Details", orderDetails)
  useEffect(() => {
    fetchCategories();
    Fetch_Businesses();
  }, []);

  useEffect(() => {
    if (categories.length > 0 && orderDetails) {
      prefillOrderDetails();
    }
  }, [categories, orderDetails]);

  useEffect(() => {
    items.forEach(item => {
      if (item.category && !categoryItems[item.category]) {
        fetchItemsForCategory(item.category);
      }
    });
  }, [items]);

  useEffect(() => {
    const fetchMissingCategoryItems = async () => {
      for (const item of items) {
        if (item.category && !categoryItems[item.category]) {
          await fetchItemsForCategory(item.category);
        }
      }
    };
    fetchMissingCategoryItems();
  }, [items]);



  function Fetch_Businesses() {
    setLoading(true);
    DashboardService.GetBusinesses().then(
      (response) => {
        setLoading(false);
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        } else {
          const business = response.data.data[0];
          setFetched_Businesses([business]);
        }
      },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        Alerts.swalErrorAlert(errContent || t("Server Error, Please try again later."));
      }
    );
  }

  const fetchCategories = async () => {
    setLoading(true);
    try {
      let offset = 0;
      let limit = 1000;
      let business_id = []
      const response = await DashboardService.getAllCategories(offset, limit, business_id);
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
      } else {
        setCategories(response.data.data);
      }
    } catch (error) {
      Alerts.swalErrorAlert(t("Error fetching categories"));
    }
    setLoading(false);
  };

  const fetchItemsForCategory = async (categoryId) => {
    if (categoryItems[categoryId]) return;
    setLoading(true);
    try {
      const getData = {
        functionName: "GetItems",
        postData: {
          sort: { _id: -1 },
          offset: 0,
          limit: 1000,
          filter: { category: categoryId },
          business: []
        },
      };
      const response = await DashboardService.getAllItems(getData);
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
      } else {
        setCategoryItems(prevItems => ({
          ...prevItems,
          [categoryId]: response.data.data
        }));
      }
    } catch (error) {
      Alerts.swalErrorAlert(t("Error fetching items"));
    }
    setLoading(false);
  };


  console.log("setCategoryItems", categoryItems)
  const handleAddItem = () => {
    setItems([...items, { category: '', name: '', quantity: 1 }]);
  };

  const handleItemChange = (index, key, value) => {
    const newItems = [...items];
    const oldItem = newItems[index];
    newItems[index] = { ...oldItem, [key]: value };

    if (key === 'name') {
      setSelectedItems(prev => {
        const updated = new Set(prev);
        if (oldItem.name) {
          updated.delete(oldItem.name);
        }
        if (value) {
          updated.add(value);
        }
        return updated;
      });
    }

    setItems(newItems);

    if (key === 'category' && value && !categoryItems[value]) {
      fetchItemsForCategory(value);
    }
  };

  const handleRemoveItem = (index) => {
    const removedItem = items[index];
    const newItems = items.filter((_, i) => i !== index);
    setItems(newItems);

    if (removedItem.name) {
      setSelectedItems(prev => {
        const updated = new Set(prev);
        updated.delete(removedItem.name);
        return updated;
      });
    }
  };

  const prefillOrderDetails = async () => {
    if (orderDetails && orderDetails.items) {
      // Prepare items with pre-filled category, item, and quantity
      const prefillItems = orderDetails.items.map(item => ({
        category: item.category,
        name: item.item,  // Use 'item' field directly from orderDetails
        quantity: item.quantity
      }));

      // Extract unique categories from the prefillItems
      const uniqueCategories = [...new Set(prefillItems.map(item => item.category))];

      // Fetch items for all unique categories
      await Promise.all(uniqueCategories.map(fetchItemsForCategory));

      // Set the pre-filled items
      setItems(prefillItems);

      // Update the selectedItems set to include pre-selected items
      const newSelectedItems = new Set(prefillItems.map(item => item.name));
      setSelectedItems(newSelectedItems);
    }
  };



  const getCategoryForItem = async (itemId) => {
    try {
      const response = await DashboardService.getCategoryForItem(itemId);
      return response.data;
    } catch (error) {
      console.error('Error fetching category for item:', error);
      return null;
    }
  };

  const saveRFQData = async (rfqData) => {
    try {
      const response = await DashboardService.saveRFQ(rfqData);
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
      } else {
        return response.data;
      }
    } catch (error) {
      console.error('Error saving RFQ:', error);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const RFQ_ID = Math.floor(100000000 + Math.random() * 900000000)
      const rfqData = {
        items: items.map(item => ({
          category: item.category,
          item: item.name,
          quantity: item.quantity
        })),
        user_id: userInfo._id,
        rfq_id: RFQ_ID,
        business_id: Fetched_Businesses[0]._id,
        status: 'requested'
      };
      await saveRFQData(rfqData);
      Alerts.swalSuccessAlert(t("RFQ submitted successfully"));
      navigate('/request_for_quotation')
    } catch (error) {
      Alerts.swalErrorAlert(t("Error submitting RFQ"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="app-main__outer">
      <div className="container mt-4">
        <div className="mb-4">
          <Button color="link" className="p-0 text-decoration-none" onClick={() => navigate(-1)}>
            <FaArrowLeft className="me-2" />
            <span className="text-dark fw-bold">{t("Request For Quotation (RFQ)")}</span>
          </Button>
        </div>
        <Card className="rfq-card">
          <div className="p-4">
            <h4 className="mb-4 fw-bold">{orderDetails ? t("Re Request For Quotation (RFQ)") : t("Add New (RFQ)")}</h4>
            <Form onSubmit={handleSubmit}>
              <LoadingOverlay active={loading} spinner={<DNALoader />}>
                <h5 className="mb-3 fw-bold">{t("Add Item(s)")}</h5>
                <div className="item-list mb-3">
                  <Row className="mb-2 text-muted">
                    <Col md={4}>{t("Category")}</Col>
                    <Col md={4}>{t("Item(s)")}</Col>
                    <Col md={2}>{t("Quantity")}</Col>
                    <Col md={2} className="text-end">{t("Action")}</Col>
                  </Row>
                  {items.map((item, index) => (
                    <Row key={index} className="mb-2 align-items-center">
                      <Col md={4}>
                        <Input
                          type="select"
                          value={item.category}
                          onChange={(e) => handleItemChange(index, 'category', e.target.value)}
                        >
                          <option value="">{t("Select Category")}</option>
                          {categories.map(category => (
                            <option key={category._id} value={category._id}>{category.Name}</option>
                          ))}
                        </Input>
                      </Col>
                      <Col md={4}>
                        <Input
                          type="select"
                          value={item.name}
                          onChange={(e) => handleItemChange(index, 'name', e.target.value)}
                          disabled={!item.category}
                        >
                          <option value="">{t("Select Item")}</option>
                          {categoryItems[item.category]?.map(catItem => (
                            <option
                              key={catItem._id}
                              value={catItem._id}
                              disabled={selectedItems.has(catItem._id) && item.name !== catItem._id}
                            >
                              {catItem.item_name}
                            </option>
                          ))}
                        </Input>
                      </Col>
                      <Col md={2}>
                        <Input
                          type="number"
                          min="1"
                          value={item.quantity}
                          onChange={(e) => handleItemChange(index, 'quantity', e.target.value)}
                        />
                      </Col>
                      <Col md={2} className="text-end">
                        <Button color="link" className="p-0 text-danger" onClick={() => handleRemoveItem(index)}>
                          <FaTrash />
                        </Button>
                      </Col>
                    </Row>
                  ))}
                </div>
                <div className="d-flex">
                  <Button type="button" color="light" className="me-2 px-3" onClick={handleAddItem}>
                    <FaPlus className="me-2" /> {t("Add New")}
                  </Button>
                  <Button type="submit" color="primary" className="px-3">
                    <FaCheck className="me-2" /> {t("Submit")}
                  </Button>
                </div>
              </LoadingOverlay>
            </Form>
          </div>
        </Card>
        <OrderConfirmationModal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)} orderId={orderId} />
      </div>
      <style jsx>{`
        .app-main__outer {
          background-color: #f8f9fa;
          min-height: 100vh;
          padding-top: 20px;
        }
        .rfq-card {
          border-radius: 10px;
          box-shadow: 0 2px 10px rgba(0,0,0,0.05);
          border: none;
        }
        .form-select, .form-control {
          border-color: #e0e0e0;
          border-radius: 5px;
          padding: 10px;
        }
        .btn-light {
          background-color: #ffffff;
          border-color: #e0e0e0;
          color: #333;
        }
        .btn-primary {
          background-color: #3D607A;
          border-color: #3D607A;
        }
        .text-muted {
          color: #6c757d !important;
        }
        h4, h5 {
          color: #333;
        }
        .item-list {
          border-top: 1px solid #e0e0e0;
          border-bottom: 1px solid #e0e0e0;
          padding: 20px 0;
        }
        .order-confirmation-modal {
          display: flex !important;
          align-items: center;
          justify-content: center;
        }
        .order-confirmation-modal .modal-dialog {
          width: 100%;
          max-width: 400px;
          margin: 0 auto;
        }
        .order-confirmation-modal .modal-content {
          border-radius: 20px;
          border: none;
          box-shadow: 0 5px 15px rgba(0,0,0,0.1);
        }
        .order-confirmation-modal .modal-body {
          padding: 2rem;
        }
        .confirmation-icon {
          width: 60px;
          height: 60px;
          background-color: #4CAF50;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto 1.5rem;
        }
        .order-confirmation-modal h4 {
          font-size: 1.25rem;
          color: #333;
          margin-bottom: 0.5rem;
        }
        .order-id {
          font-size: 1.5rem;
          font-weight: bold;
          color: #000;
        }
      `}</style>
    </div>
  );
};

export default AddNewRFQ;