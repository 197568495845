import axios from "axios";

const B_URL = process.env.REACT_APP_LOGINURL


const headers = {
  'Content-Type': 'application/json',
  'token': localStorage.getItem("session-id")
}


const getAllWarehouse = (sessionID,page, limit, filterStatus, filterText) => {
  var payload = {
    "functionName": "GetWarehouse",
    "postData": {
      "offset": page,
      "filterStatus": filterStatus,
      "filterText": filterText,
      "sort": {
        "_id": -1
      },
      "limit": limit,
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "WarehouseModule/GetWarehouse", payload, { headers });
};
const GetWarehouse = (sessionID,payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }


  return axios.post(B_URL + "WarehouseModule/Get_Warehouse_sub_Types", payload, { headers });
};

const getAllWarehouseAttributes = (Warehouse_id) => {
  var payload = {
    "functionName": "GetWarehouseAttribute",
    "postData": {
      "offset": 0,
      "sort": {
        "_id": -1
      },
      "limit": 99999,
      "Warehouse_id": Warehouse_id,
      "attribute_id": ""
    }
  }
  return axios.post(B_URL + "WarehouseAttributeModule/GetWarehouseAttribute", payload, { headers });
};

const updateMultipleWarehouseAttributes = (data) => {
  // console.log("DATA:", data);
  var payload = {
    "functionName": "UpdateMultipleWarehouseAttributes",
    "postData": {
      "Warehouse_id": data.Warehouse_id,
      "section_id": data.section_id,
      "updates": data.updates
    }
  }
  return axios.post(B_URL + "WarehouseAttributeModule/UpdateMultipleWarehouseAttribute", payload, { headers });
};

const UpdateWarehouse = (sessionID,payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  // console.log("DATA:", data);
  return axios.post(B_URL + "WarehouseModule/UpdateWarehouse", payload, { headers });
};

const AddSection = (data) => {
  // console.log("DATA:", data);
  var payload = {
    "functionName": "AddSection",
    "postData": {
      "name": data.name,
      "status": data.status
    }
  }
  return axios.post(B_URL + "SectionModule/AddSection", payload, { headers });
};

const AddWarehouse = (payloadData) => {
  return axios.post(B_URL + "WarehouseModule/AddWarehouse", payloadData, { headers });
};
const AddBulkWarehouse = (sessionID, payloadData) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "WarehouseModule/AddBulkWarehouse", payloadData, { headers });
};

const EditSection = (data, id) => {
  // console.log("DATA:", data);
  var payload = {
    "functionName": "UpdateSection",
    "postData": {
      "section_id": id,
      "name": data.name,
      "status": data.status
    }
  }
  return axios.post(B_URL + "SectionModule/UpdateSection", payload, { headers });
};

const EditAttribute = (data, id) => {
  // console.log("DATA:", data);
  var payload = {
    "functionName": "UpdateAttribute",
    "postData": {
      "attribute_id": id,
      "lable": data.lable,
      "key": data.key,
      "status": data.status
    }
  }
  return axios.post(B_URL + "AttributeModule/UpdateAttribute", payload, { headers });
};

const AddAttribute = (data) => {
  // console.log("DATA:", data);
  var payload = {
    "functionName": "AddAttribute",
    "postData": {
      "lable": data.lable,
      "key": data.key,
      "status": data.status
    }
  }
  return axios.post(B_URL + "AttributeModule/AddAttribute", payload, { headers });
};

const getAllSections = (page, limit) => {
  var payload = {
    "functionName": "GetSections",
    "postData": {
      "offset": 0,
      "sort": {
        "_id": -1
      },
      "limit": 10
    }
  }
  return axios.post(B_URL + "SectionModule/GetSections", payload, { headers });
};

const GetSingleSection = (id) => {
  var payload = {
    "functionName": "GetSingleSection",
    "postData": {
      "id": id
    }
  }
  return axios.post(B_URL + "SectionModule/GetSingleSection", payload, { headers });
};

const GetSingleAttribute = (id) => {
  var payload = {
    "functionName": "GetSingleAttribute",
    "postData": {
      "id": id
    }
  }
  return axios.post(B_URL + "AttributeModule/GetSingleAttribute", payload, { headers });
};

const getAllAttributes = (page, limit) => {
  var payload = {
    "functionName": "GetAttributes",
    "postData": {
      "offset": 0,
      "sort": {
        "_id": -1
      },
      "limit": 10
    }
  }
  return axios.post(B_URL + "AttributeModule/GetAttributes", payload, { headers });
};

const deleteSection = (id) => {
  var payload = {
    "functionName": "DeleteSection",
    "postData": {
      "section_id": id
    }
  }
  return axios.post(B_URL + "SectionModule/DeleteSection", payload, { headers });
};

const deleteAttribute = (id) => {
  var payload = {
    "functionName": "DeleteAttribute",
    "postData": {
      "attribute_id": id
    }
  }
  return axios.post(B_URL + "AttributeModule/DeleteAttribute", payload, { headers });
};

const deleteBulkSection = (selectedRows) => {
  var payload = {
    "functionName": "DeleteBulkSection",
    "postData": {
      "section_ids": selectedRows
    }
  }
  return axios.post(B_URL + "SectionModule/DeleteBulkSection", payload, { headers });
};

const deleteBulkAttribute = (selectedRows) => {
  var payload = {
    "functionName": "DeleteBulkAttribute",
    "postData": {
      "attribute_ids": selectedRows
    }
  }
  return axios.post(B_URL + "AttributeModule/DeleteBulkAttribute", payload, { headers });
};

const DeleteBulkWarehouse = (sessionID,selectedRows) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  var payload = {
    "functionName": "DeleteBulkWarehouse",
    "postData": {
      "_ids": selectedRows
    }
  }
  return axios.post(B_URL + "WarehouseModule/DeleteBulkWarehouse", payload, { headers });
};

const deleteWarehouse = (sessionID,id) => {
  var payload = {
    "functionName": "DeleteWarehouse",
    "postData": {
      "Warehouse_id": id
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "WarehouseModule/DeleteWarehouse", payload, { headers });
};
const DeleteWarehouse = (sessionID,id) => {
  var payload = {
    "functionName": "DeleteWarehouse",
    "postData": {
      "_id": id
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "WarehouseModule/DeleteWarehouse", payload, { headers });
};

const WarehouseService = {
  getAllWarehouse,
  GetWarehouse,
  AddWarehouse,
  AddBulkWarehouse,
  getAllWarehouseAttributes,
  updateMultipleWarehouseAttributes,
  UpdateWarehouse,
  getAllSections,
  getAllAttributes,
  AddSection,
  AddAttribute,
  GetSingleSection,
  EditSection,
  GetSingleAttribute,
  EditAttribute,
  deleteSection,
  deleteAttribute,
  deleteWarehouse,
  deleteBulkSection,
  deleteBulkAttribute,
  DeleteBulkWarehouse
};

export default WarehouseService;