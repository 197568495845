import React, { useState } from 'react';
import { Card, Row, Col, Input, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { FaEye, FaEdit, FaFilter, FaPlus } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const QuotationsRequested = ({
  data,
  columns,
  header,
  sort,
  add,
  totalRows,
  onChangePage,
  onChangeRowsPerPage,
  resetPaginationToggle
}) => {
  const navigate = useNavigate();

  const Navigate = () => {
    navigate("/add_new_rfq");
  };

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: '#f8f9fa',
        borderBottom: 'none',
      },
    },
    headCells: {
      style: {
        color: '#6c757d',
        fontSize: '0.9rem',
        fontWeight: '600',
        paddingLeft: '8px',
        paddingRight: '8px',
      },
    },
    rows: {
      style: {
        fontSize: '0.9rem',
        paddingLeft: '8px',
        paddingRight: '8px',
      },
    },
  };


  return (
    <Card className="mt-4 mb-4">
      <div className="p-4">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h4 className="mb-0">{header}</h4>
          <div>
            {sort &&
              <UncontrolledDropdown className="me-2 d-inline-block">
                < DropdownToggle caret color="light">
                  Sort by <FaFilter size={14} />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem>Date</DropdownItem>
                  <DropdownItem>Status</DropdownItem>
                  <DropdownItem>Total Item</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            }
            {add &&
              <Button onClick={Navigate} style={{ backgroundColor: '#3D607A', borderColor: '#3D607A' }}>
                Add New <FaPlus size={14} className="ms-1" />
              </Button>
            }
          </div>
        </div>
        <DataTable        
          columns={columns}
          data={data}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          // paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
          fixedHeader
          fixedHeaderScrollHeight="400px"
          subHeader
          // subHeaderComponent={<div></div>}
          paginationResetDefaultPage={resetPaginationToggle}
          customStyles={customStyles}
        />
      </div>
    </Card >
  );
};

export default QuotationsRequested;