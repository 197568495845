import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, Navigate } from "react-router-dom";
import { Row, Col, FormGroup, Input, Button, Label, Modal, ModalHeader, ModalBody, Card, CardBody } from "reactstrap";
import Services from "./services";
import AuthService from "../Authentication/auth.service";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import Alerts from "../../components/Alerts/alerts";
import PermissionAccess from '../../PermissionAccess/Access.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeadset, faComment, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

const StyledModal = styled(Modal)`
  .modal-content {
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
`;

const StyledCard = styled(Card)`
  border: none;
  box-shadow: none;
`;

const FormLabel = styled(Label)`
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  
  .icon {
    color: var(--buttons-color, #30b1ff);
  }
`;

const StyledInput = styled(Input)`
  border-radius: 6px;
  border: 1px solid #e2e8f0;
  padding: 10px;
  font-size: 14px;
  transition: all 0.2s;
  
  &:focus {
    border-color: var(--buttons-color, #30b1ff);
    box-shadow: 0 0 0 2px rgba(var(--buttons-color, #30b1ff), 0.1);
  }

  &.is-invalid {
    border-color: #ef4444;
    &:focus {
      box-shadow: 0 0 0 2px rgba(239, 68, 68, 0.1);
    }
  }
`;

const StyledTextArea = styled(StyledInput)`
  min-height: 120px;
  resize: vertical;
`;

const ActionButton = styled(Button)`
  font-weight: 600;
  padding: 8px 20px;
  border-radius: 6px;
  transition: all 0.2s;
  
  &.btn-primary {
    background-color: var(--buttons-color, #30b1ff);
    border-color: var(--buttons-color, #30b1ff);
    color: white;

    &:hover {
      transform: translateY(-1px);
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    }
  }
`;

const TicketInfo = styled.div`
  background-color: #f8fafc;
  border-radius: 6px;
  padding: 16px;
  margin-bottom: 20px;

  .info-label {
    font-weight: 600;
    color: #64748b;
    font-size: 0.875rem;
  }

  .info-value {
    color: #1e293b;
    margin-top: 4px;
  }
`;

const UpdateCustomerSupport = ({ ticket, onSuccess, onCancel }) => {
  const [modal, setModal] = useState(true);
  const [ticketData, setTicketData] = useState({
    comment: '',
    status: ticket?.status || 'Open'
  });

  const [loading, setLoading] = useState(false);
  const [loginNavigate, setLoginNavigate] = useState(false);
  const { authData } = PermissionAccess();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
  }, [i18n]);

  const handleInputChange = (field, value) => {
    setTicketData(prev => ({ ...prev, [field]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!ticketData.comment.trim()) {
      Alerts.swalErrorAlert("Please add a comment");
      return;
    }

    const payload = {
      comment: ticketData.comment.trim(),
      status: ticketData.status,
      business_id: ticket?.business_id
    };
    
    setLoading(true);
    try {
      const response = await Services.UpdateCustomerSupport(authData.sessionID, ticket?._id, payload);
      setLoading(false);
      if (response.data.status) {
        onSuccess();
        Alerts.swalSuccessAlert(response.data.message || "Support ticket updated successfully").then(() => {
          setModal(false);
        });
      } else {
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          setLoginNavigate(true);
        } else {
          Alerts.swalErrorAlert(response.data.message || "Error updating support ticket");
        }
      }
    } catch (error) {
      setLoading(false);
      Alerts.swalErrorAlert("Network operation failed");
    }
  };

  if (loginNavigate) {
    return <Navigate to={'/login'} />;
  }

  return (
    <StyledModal size='lg' isOpen={modal} toggle={() => setModal(false)} backdrop={true}>
      <LoadingOverlay active={loading} spinner={<DNALoader />}>
        <ModalHeader className="bg-light">
          <div className="d-flex align-items-center">
            <FontAwesomeIcon 
              icon={faHeadset} 
              style={{ color: 'var(--buttons-color, #30b1ff)' }} 
              className="me-2" 
            />
            {t("Update Support Ticket")}
          </div>
        </ModalHeader>
        <ModalBody className="px-4 py-4">
          <StyledCard>
            <CardBody>
              <TicketInfo>
                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <div className="info-label">{t("Member")}</div>
                      <div className="info-value">{ticket?.member?.[0]?.name || ticket?.member?.[0]?.email || 'N/A'}</div>
                    </div>
                    <div className="mb-3">
                      <div className="info-label">{t("Type")}</div>
                      <div className="info-value">{ticket?.type}</div>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <div className="info-label">{t("Summary")}</div>
                      <div className="info-value">{ticket?.summary}</div>
                    </div>
                    <div>
                      <div className="info-label">{t("Current Status")}</div>
                      <div className="info-value">{ticket?.status}</div>
                    </div>
                  </Col>
                </Row>
              </TicketInfo>

              <form onSubmit={handleSubmit}>
                <Row>
                  <Col md="12" className="mb-3">
                    <FormLabel>
                      <FontAwesomeIcon 
                        icon={faComment}
                        className="icon"
                      />
                      {t("Add Comment")}
                    </FormLabel>
                    <StyledTextArea
                      type="textarea"
                      value={ticketData.comment}
                      onChange={(e) => handleInputChange('comment', e.target.value)}
                      placeholder={t("Add your comment or update about the ticket")}
                      required
                    />
                  </Col>

                  <Col md="12" className="mb-4">
                    <FormLabel>
                      <FontAwesomeIcon 
                        icon={faInfoCircle}
                        className="icon"
                      />
                      {t("Update Status")}
                    </FormLabel>
                    <StyledInput
                      type="select"
                      value={ticketData.status}
                      onChange={(e) => handleInputChange('status', e.target.value)}
                    >
                      <option value="Open">{t("Open")}</option>
                      <option value="In Progress">{t("In Progress")}</option>
                      <option value="Resolved">{t("Resolved")}</option>
                      <option value="Closed">{t("Closed")}</option>
                    </StyledInput>
                  </Col>
                </Row>

                <div className="d-flex justify-content-end gap-2 mt-4">
                  <ActionButton
                    type="button"
                    color="secondary"
                    onClick={() => { setModal(false); onCancel(); }}
                  >
                    {t("Cancel")}
                  </ActionButton>
                  <ActionButton 
                    type="submit" 
                    className="btn-primary"
                  >
                    {t("Update Ticket")}
                  </ActionButton>
                </div>
              </form>
            </CardBody>
          </StyledCard>
        </ModalBody>
      </LoadingOverlay>
    </StyledModal>
  );
};

export default UpdateCustomerSupport;