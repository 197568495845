import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";
import {
  Typography, Chip, Grid, Paper, Box, Table,
  TableBody, TableCell, TableContainer, TableRow,
  Card, CardHeader, Divider, Container
} from '@mui/material';
import { styled } from '@mui/material/styles';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import GroupIcon from '@mui/icons-material/Group';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import BuildIcon from '@mui/icons-material/Build';
import StarIcon from '@mui/icons-material/Star';
import Button from '@mui/material/Button';
import Services from "./services.js";
import AuthService from "../Authentication/auth.service";
import Alerts from "../../components/Alerts/alerts";
import PermissionAccess from '../../PermissionAccess/Access.js';

const MainContainer = styled(Container)({
  padding: '2rem 0',
  maxWidth: '1200px !important'
});

const StyledCard = styled(Card)({
  background: 'linear-gradient(135deg, #04041F 0%, #000000 100%)',
  color: '#fff',
  borderRadius: '20px',
  overflow: 'hidden',
  boxShadow: '0 8px 32px rgba(0,0,0,0.2)'
});

const StyledCardHeader = styled(CardHeader)({
  background: 'rgba(255, 255, 255, 0.05)',
  borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
  padding: '2rem',
  '& .MuiTypography-root': {
    color: '#fff',
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
    gap: '10px'
  }
});

const ContentSection = styled(Box)({
  padding: '2rem',
});

const SectionTitle = styled(Typography)({
  color: '#F2731C',
  fontWeight: 600,
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  marginBottom: '1rem',
  padding: '0.5rem 1rem',
  borderRadius: '8px',
  background: 'rgba(242, 115, 28, 0.1)',
});

const StyledTableContainer = styled(TableContainer)({
  background: 'transparent',
  '& .MuiTable-root': {
    borderCollapse: 'separate',
    borderSpacing: '0 8px'
  }
});

const InfoRow = styled(TableRow)({
  background: 'rgba(255, 255, 255, 0.03)',
  transition: 'all 0.3s ease',
  '&:hover': {
    background: 'rgba(255, 255, 255, 0.05)',
    transform: 'translateX(5px)'
  }
});

const LabelCell = styled(TableCell)({
  color: '#a0aec0',
  fontWeight: 500,
  border: 'none',
  padding: '16px 24px',
  width: '30%',
});

const ValueCell = styled(TableCell)({
  color: '#fff',
  border: 'none',
  padding: '16px 24px',
  fontSize: '1rem',
});

const StatusChip = styled(Chip)(({ theme, status }) => ({
  backgroundColor: status === 'Active' ? 'rgba(46, 204, 113, 0.15)' : 'rgba(231, 76, 60, 0.15)',
  color: status === 'Active' ? '#2ecc71' : '#e74c3c',
  fontWeight: 600,
  padding: '8px 16px',
  height: 'auto',
  '& .MuiChip-label': {
    padding: 0
  }
}));

const ActionButton = styled(Button)({
  padding: '12px 32px',
  borderRadius: '12px',
  fontWeight: 600,
  textTransform: 'none',
  fontSize: '1rem',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 5px 15px rgba(0,0,0,0.3)'
  }
});

const RANK_NAMES = {
  infin8_starter: "Infin8 Starter Rank",
  infin8_explorer: "Infin8 Explorer Rank",
  infin8_pioneer: "Infin8 Pioneer Rank",
  infin8_leader: "Infin8 Leader Rank",
  infin8_visionary: "Infin8 Visionary Rank"
};

const RanksDetailsComponent = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { state: ranks } = location;
  const [loading, setLoading] = useState(false);
  const { authData } = PermissionAccess();

  const handleApproval = async (approve) => {
    setLoading(true);
    try {
      const response = await Services.updateRanksApproval(ranks._id, approve, authData.sessionID);
      if (response.data.status) {
        ranks.status = approve ? "Active" : "Inactive";
        Alerts.swalSuccessAlert(approve ? "Rank approved successfully" : "Rank disapproved");
      } else {
        Alerts.swalErrorAlert(response.data.message || "Error updating rank approval status");
      }
    } catch (error) {
      Alerts.swalErrorAlert("Network operation failed");
    } finally {
      setLoading(false);
    }
  };

  const renderInfoRow = (icon, label, value) => (
    <InfoRow>
      <LabelCell>
        <Box display="flex" alignItems="center" gap={1}>
          {icon}
          <Typography>{t(label)}</Typography>
        </Box>
      </LabelCell>
      <ValueCell>{value}</ValueCell>
    </InfoRow>
  );

  return (
    <LoadingOverlay
      active={loading}
      styles={{ overlay: (base) => ({ ...base }) }}
      spinner={<DNALoader />}
    >
      <MainContainer>
        <StyledCard>
          <StyledCardHeader
            title={
              <Box display="flex" alignItems="center" gap={2}>
                <StarIcon sx={{ color: '#F2731C', fontSize: 32 }} />
                <Typography variant="h4">
                  {RANK_NAMES[ranks.name] || ranks.name}
                </Typography>
              </Box>
            }
            action={
              <StatusChip
                label={ranks.status}
                status={ranks.status}
                size="large"
              />
            }
          />
          <ContentSection>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <StyledTableContainer>
                  <Table>
                    <TableBody>
                      {/* Basic Information */}
                      <TableRow>
                        <TableCell colSpan={2} sx={{ border: 'none', pb: 3 }}>
                          <SectionTitle variant="h6">
                            <MilitaryTechIcon sx={{ color: '#F2731C' }} />
                            {t("Rank Information")}
                          </SectionTitle>
                        </TableCell>
                      </TableRow>
                      {renderInfoRow(
                        <MilitaryTechIcon sx={{ color: '#F2731C' }} />,
                        'Rank Category',
                        RANK_NAMES[ranks.name] || ranks.name
                      )}

                      {/* Membership Requirements */}
                      <TableRow>
                        <TableCell colSpan={2} sx={{ border: 'none', py: 3 }}>
                          <SectionTitle variant="h6">
                            <GroupIcon sx={{ color: '#F2731C' }} />
                            {t("Membership Requirements")}
                          </SectionTitle>
                        </TableCell>
                      </TableRow>
                      {renderInfoRow(
                        <GroupIcon sx={{ color: '#F2731C' }} />,
                        'Required Members on Left Org',
                        ranks.left_org_members?.toLocaleString()
                      )}
                      {renderInfoRow(
                        <GroupIcon sx={{ color: '#F2731C' }} />,
                        'Required Members on Right Org',
                        ranks.right_org_members?.toLocaleString()
                      )}
                      {renderInfoRow(
                        <GroupIcon sx={{ color: '#F2731C' }} />,
                        'Max Members',
                        ranks.max_members?.toLocaleString()
                      )}

                      {/* Rewards */}
                      <TableRow>
                        <TableCell colSpan={2} sx={{ border: 'none', py: 3 }}>
                          <SectionTitle variant="h6">
                            <MonetizationOnIcon sx={{ color: '#F2731C' }} />
                            {t("Rewards")}
                          </SectionTitle>
                        </TableCell>
                      </TableRow>
                      {renderInfoRow(
                        <MonetizationOnIcon sx={{ color: '#F2731C' }} />,
                        'SPV Reward Per Member',
                        ranks.spv_reward_per_member
                      )}
                      {renderInfoRow(
                        <WorkspacePremiumIcon sx={{ color: '#F2731C' }} />,
                        'One Time Reward (DPV)',
                        ranks.one_time_reward
                      )}
                      {renderInfoRow(
                        <MonetizationOnIcon sx={{ color: '#F2731C' }} />,
                        'Infin8 SPV Reward',
                        ranks.infin8_spv_reward
                      )}

                      {/* Maintenance */}
                      <TableRow>
                        <TableCell colSpan={2} sx={{ border: 'none', py: 3 }}>
                          <SectionTitle variant="h6">
                            <BuildIcon sx={{ color: '#F2731C' }} />
                            {t("Maintenance Requirements")}
                          </SectionTitle>
                        </TableCell>
                      </TableRow>
                      {renderInfoRow(
                        <BuildIcon sx={{ color: '#F2731C' }} />,
                        'Maintenance',
                        ranks.maintenance
                      )}
                    </TableBody>
                  </Table>
                </StyledTableContainer>
              </Grid>
            </Grid>
          </ContentSection>

          <Box display="flex" justifyContent="center" gap={2} pb={4}>
            {ranks.status === "Inactive" ? (
              <ActionButton
                variant="contained"
                sx={{
                  background: 'linear-gradient(45deg, #2ecc71 30%, #27ae60 90%)',
                  color: 'white'
                }}
                onClick={() => handleApproval(true)}
              >
                Activate Rank
              </ActionButton>
            ) : (
              <ActionButton
                variant="contained"
                sx={{
                  background: 'linear-gradient(45deg, #e74c3c 30%, #c0392b 90%)',
                  color: 'white'
                }}
                onClick={() => handleApproval(false)}
              >
                Deactivate Rank
              </ActionButton>
            )}
          </Box>
        </StyledCard>
      </MainContainer>
    </LoadingOverlay>
  );
};

export default RanksDetailsComponent;