import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const TermsOfUse = () => {
    const styles = {
        container: {
            padding: '20px',
            maxWidth: '800px',
            margin: '0 auto',
            fontFamily: 'Arial, sans-serif',
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: '20px',
        },
        backButton: {
            background: 'none',
            border: 'none',
            cursor: 'pointer',
            fontSize: '24px',
            marginRight: '20px',
        },
        title: {
            fontSize: '24px',
            fontWeight: 'bold',
        },
        paragraph: {
            marginBottom: '15px',
            lineHeight: '1.6',
        },
        quote: {
            fontStyle: 'italic',
            marginTop: '20px',
        },
    };

    return (
        <div style={styles.container}>
            <div style={styles.header}>
                <h1 style={styles.title}>Terms of Use</h1>
            </div>
            <div>
                <p style={styles.paragraph}>
                    JomSmart Marketplace Sdn Bhd, A Fully Malaysian Owned Company, is the first company to focus on "Localized Community Marketplace" on the Web and Mobile Application Platform in Malaysia. Our Marketplace is created with intentions of creating A "More People Values, And Not Just Technology" in our mind. By building a closer relationship between our MP BIZ Sellers, Service Providers, and MP RIDERZ, we sincerely intend to enable our MP BIZ and our MP Users like yourself to grow closer and experience e-Commerce literally first-hand.
                </p>
                <p style={styles.paragraph}>
                    Our focus is primarily on the growth of entrepreneurs whom we equip with specially customized designed delivery solutions tools in preparing them for their entrepreneurial journey to give their local neighbourhood and its surroundings a more direct customer relationship building experience. This concept is directed towards building a vision and mission to establish "ONE HOME, ONE ENTREPRENEUR" Concept.
                </p>
                <p style={styles.paragraph}>
                    We Equip, Provide Online Training and as well as providing our future JomSmart Biz and MP RiderZ an opportunity to gain wider market coverage and enabling their Customers to build a rapport and relationship to have a continuous growth in their business. Our vision is aimed to indirectly impact the growth by having more economical and commercial values between a buyer and seller in the same community hence creating a more prosperous and positive community.
                </p>
                <p style={styles.paragraph}>
                    We create Entrepreneurs from any industry including your local handyman to transform their conventional selling method to a more personalized one aiming towards a happier community by bringing them directly at your doorstep.
                </p>
                <p style={styles.paragraph}>
                    In any case, our ultimate value proposition is directed towards the end results of our users on JomSmart with a more complete positive and happy buying experience. We will continue to strive and bring a JomSmart In Every Home of Malaysia.
                </p>
                <p style={styles.paragraph}>
                    So Join Us In Creating A JomSmart Community In Your Local Neighbourhood "Together We Create A More People Values Marketplace and Not Just Technology"...
                </p>
                <p style={styles.quote}>
                    "When You Are Buying & Selling On JomSmart, You Are Buying & Selling In Your Community And It Matters Because A Prosperous Community IS A Happy Community" ~ Sham, Founder, JomSmart
                </p>
            </div>
        </div>
    );
};

export default TermsOfUse;