import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from "react-router-dom";
import { Col, Row, Label, FormGroup, Input, Card, CardBody } from "reactstrap";
import { Form, Field } from "react-final-form";

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";

import Alerts from "../../components/Alerts/alerts";

import EntitiesService from "./entities.service";
import AuthService from "../Authentication/auth.service";
import PermissionAccess from '../../PermissionAccess/Access.js';


const AddEntity = () => {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { authData, accessPages, super_admin, current_module } = PermissionAccess();

  const location = useLocation();
  const { state } = location;
  const [cruds, setcruds] = useState([]);

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchEntities();
  },[]);

  function fetchEntities() {
    EntitiesService.getAll({ _id: { $ne: state._id } },authData.sessionID).then((response) => {
      console.log("Get Entities response:-", response)
      if(response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setcruds(response.data.data);
      }
    },
    (error) => {
      const errContent =
      (error.response && error.response.data) || error.message || error.toString();
      if(errContent) {
        Alerts.swalErrorAlert(errContent)
      }
      else {
        Alerts.swalErrorAlert(t("Server Error, Please try again later."))
      }
    })
  };

  const onSubmit = values => {
    
    var payloadData = {
      functionName: "UpdateCrud",
      postData: {
        name: values.name,
        page_title: values.page_title,
        menu_label: values.menu_label,
        menu_positon: values.menu_positon,
        referal_name: values.referal_name,
        showIn: values.showIn,
        crud_id: values.crud_id,
        _id: state._id
      },
    }
    setLoading(true);
    EntitiesService.UpdateEntity(authData.sessionID,payloadData).then((response) => {        
      console.log("API Response:-", response);
      setLoading(false);
      if(response.data.status){
        navigate("/entities-list")
        if(response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Entity updated successfully."));
        }
      }
      else {
        if(response.data.message !== '') {
          if(response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't update entity at the moment. Please try again later."))
        }
      }
    },
    (error) => {
      setLoading(false);
      const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      Alerts.swalErrorAlert(t("Network Operation failed."))
    });

  }

  const validateFtn = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = t("Entity Name Required.");
    }
    if (!values.page_title) {
      errors.page_title = t("Entity Title Required.");
    }
    if (!values.menu_label) {
      errors.menu_label = t("Menu Label Required.");
    }
    if (!values.menu_positon) {
      errors.menu_positon = t("Menu Position Required.");
    }
    if (!values.referal_name) {
      errors.referal_name = t("Referal Name Required.");
    }
    return errors;
  };


  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className="app-page-title">
            <div className="page-title-wrapper justify-content-left">
              <div className="page-title-heading fw-bold">
                <div className="page-title-icon">
                  <i className="pe-7s-pen icon-gradient bg-happy-green" />
                </div>
                <div>{t('Update Entity')}
                  <div className="page-title-subheading">
                  {t('Edit the form below to update an Entity.')}
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        <div className="d-flex justify-content-center">
          <div className="col-md-12">
            <Card className="main-card mb-3">
              <CardBody>
                <Form onSubmit={onSubmit}
                  initialValues={state}
                  validate={validateFtn}
                  render={({ handleSubmit, values, submitting, validating, valid }) => (
                    <form onSubmit={handleSubmit}>
                      <LoadingOverlay tag="div" active={loading}
                        styles = {{overlay: (base) => ({...base})}}
                        spinner = {<DNALoader/>}>  
                        <Row form>
                          <Col md={6}>
                            <FormGroup>
                              <Field name="name">
                                {({ input, meta }) => (
                                  <div>
                                    <Label for="name">{t('Name')}</Label>
                                    <Input {...input} type="text" 
                                      invalid={meta.error && meta.touched} 
                                      placeholder={t('Entity Name')}
                                    />
                                    {meta.error && meta.touched && 
                                    <span className="text-danger">{meta.error}</span>}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                            <FormGroup>
                              <Field name="page_title">
                                {({ input, meta }) => (
                                  <div>
                                    <Label for="page_title">{t('Page Title')}</Label>
                                    <Input {...input} type="text" 
                                      invalid={meta.error && meta.touched} 
                                      placeholder={t('Entity Page Title')}
                                    />
                                    {meta.error && meta.touched && 
                                    <span className="text-danger">{meta.error}</span>}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                            <FormGroup>
                              <Field name="menu_label">
                                {({ input, meta }) => (
                                  <div>
                                    <Label for="menu_label">{t('Menu Label')}</Label>
                                    <Input {...input} type="text"
                                      invalid={meta.error && meta.touched}
                                      placeholder={t('Entity Menu Label')}
                                    />
                                    {meta.error && meta.touched && 
                                    <span className="text-danger">{meta.error}</span>}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                            <FormGroup>
                              <Field name="menu_positon">
                                {({ input, meta }) => (
                                  <div>
                                    <Label for="menu_positon">{t('Menu Postion')}</Label>
                                    <Input {...input} type="number" 
                                      invalid={meta.error && meta.touched} 
                                      placeholder={t('Entity Menu Position')}
                                    />
                                    {meta.error && meta.touched && 
                                    <span className="text-danger">{meta.error}</span>}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                           </Col>
                          <Col md={6}>
                            <FormGroup>
                              <Field name="referal_name">
                                {({ input, meta }) => (
                                  <div>
                                    <Label for="referal_name">{t('Collection Name')}</Label>
                                    <Input {...input} type="text" 
                                      invalid={meta.error && meta.touched} 
                                      placeholder={t('Entity Referal Name')}
                                      readOnly= {true}
                                    />
                                    {meta.error && meta.touched && 
                                    <span className="text-danger">{meta.error}</span>}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                            <FormGroup>
                              <Field name="showIn">
                                {({ input, meta }) => (
                                  <div>
                                    <Label for="showIn">{t('Show In')}</Label>
                                    <Input {...input} type="select" 
                                      invalid={meta.error && meta.touched} 
                                    >
                                      <option disabled={true} value="">{t('--Choose an option--')}</option>
                                      <option key="0" value ="menu">{t('Menu')}</option>
                                      <option key="1" value ="new_page">{t('Nested Entity')}</option>
                                    </Input>
                                    {meta.error && meta.touched && 
                                    <span className="text-danger">{meta.error}</span>}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                            <FormGroup>
                              <Field name="crud_id">
                                {({ input, meta }) => (
                                  <div>
                                    <Label for="crud_id">{t('Parent Entity')}</Label>
                                    <Input {...input}  type="select" 
                                      invalid={meta.error && meta.touched} 
                                    >
                                      <option disabled={true} value="">{t('--Choose an option--')}</option>
                                      {cruds &&
                                        cruds.map((crud, inde) => (
                                          <option key = {inde} value={crud._id}>{crud.name}</option>
                                        ))
                                      }
                                    </Input>
                                    {meta.error && meta.touched && 
                                    <span className="text-danger">{meta.error}</span>}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                         
                          </Col>
                          <FormGroup>
                              <div className="d-flex justify-content-center">
                                <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-light btn-hover-shine me-3 mt-3">{t('Cancel')}</button>
                                <button className="btn btn-custom-color btn-hover-shine me-3 mt-3" disabled={!valid}>{t('Update Entity')}</button>
                              </div>
                            </FormGroup>
                        </Row>   
                      </LoadingOverlay>
                    </form>
                  )}
                />
              </CardBody>
            </Card>
          </div>
        </div>
        
        </div>
      </div>
    </Fragment>
  );

};
export default AddEntity;


































// import React, { useState, useRef, useEffect } from "react";
// import { useNavigate, useLocation } from "react-router-dom";

// import Form from "react-validation/build/form";
// import Input from "react-validation/build/input";
// import CheckButton from "react-validation/build/button";
// import axios from 'axios';

// import UserService from "../../pages/Users Management/users.service";
// import RoleService from "../Roles/roles.service";





// const UpdateEntity = () => {
  
//   const location = useLocation();
//   const { state } = location;
//   console.log("Received Object:-", state);
  
//   const [dataObj, setdataObj] = useState("");

//   useEffect(() => {
//     setData();
//   }, []); 
  
  
//   function setData() {
//     if(state) {
//       console.log("Setting Editable values")
//       setdataObj(state);
//     }
//   }
  
 
  
//   const API_URL  = process.env.REACT_APP_BASEURL;
//   const navigate = useNavigate();
//   const form = useRef();
//   const checkBtn = useRef();
//   const [roles, setRoles] = useState([]);
//   const [successful, setSuccessful] = useState(false);
//   const [message, setMessage] = useState("");
//   let formdata = {};
  
//   const handleForm = (e) => {
    
    
//     dataObj[e.target.name] = e.target.value;
//   }
//   const handleSubmit = (e) => {

//     e.preventDefault();
//     setMessage("");
//     setSuccessful(false);
    
    
//     console.log(dataObj);
    
//       const headers= { 
//         'Content-Type': 'application/json',
//         'token': localStorage.getItem("session-id")
//       }
//       var getData = {
//         functionName: "UpdateCrud",
//         postData: dataObj
//       }
       
//       axios.post(API_URL, getData, {headers})
//       .then((response) => {
//         if(response.data.status){
//           alert(t("Data process Succesfully "));
//           setSuccessful(true);
//           navigate("/entities")
//           window.location.reload();
          
//         }else{

//           var  resMessage  = "" ;
//           resMessage = response.data.message;
          

//           setMessage(resMessage);
//           setSuccessful(false);
//         }
        
//       }).catch((error) => {
//         const resMessage =
//           (error.response &&
//             error.response.data &&
//             error.response.data.message) ||
//           error.message ||
//           error.toString();

//         setMessage(resMessage);
//         setSuccessful(false);
//       });
      
    
    
//     };
//     return (
//       <>
//       <div className="container-fluid">
        
//         <br/>
//         <div className="row">
//           <div className="col-sm-12">
//             <div className="page-title-box">
//               <center><h4 className="page-title">Entity Details</h4></center>
//             </div>
            
//           </div>
//         </div>
        
//         <br/>
        
//         <div className="row">
//         <div className="col-lg-3" ></div>
//           <div className="col-lg-6">
//             <div className="card">
//               <div className="card-body">
              
//                 <Form onSubmit={handleSubmit} ref={form}>
//                   {!successful && (
//                     <div>
//                       <div className="form-group">
//                         <div className="col-md-12 col-xl-12" >
//                           <label htmlFor="name">Name</label>
//                           <Input type="text" className="form-control" value = {dataObj.name} name="name" id="name"   onChange={(e)=>handleForm(e)} />
//                         </div>
//                       </div>
//                       <br/>
//                       <div className="form-group">
//                         <div className="col-md-12 col-xl-12" >
//                           <label htmlFor="page_title">Page Title</label>
//                           <Input type="text" className="form-control" value = {dataObj.page_title}  name="page_title"  id="page_title"  onChange={(e)=>handleForm(e)} />
//                         </div>
//                       </div>
//                       <br/>
//                       <div className="form-group">
//                         <div className="col-md-12 col-xl-12" >
//                           <label htmlFor="menu_label">Menu Label</label>
//                           <Input type="text" className="form-control" value = {dataObj.menu_label}  name="menu_label" id="menu_label"  onChange={(e)=>handleForm(e)} />
//                         </div>
//                       </div>
//                       <br/>
//                       <div className="form-group">
//                         <div className="col-md-12 col-xl-12" >
//                           <label htmlFor="referal_name">Menu Position</label>
//                           <Input type="number" className="form-control" value = {dataObj.menu_positon}  name="menu_positon"  id="menu_positon"  onChange={(e)=>handleForm(e)} />
//                         </div>
//                       </div>
//                       <br/>
//                       <div className="form-group">
//                         <div className="col-md-12 col-xl-12" >
//                           <label htmlFor="referal_name">Referal Name</label>
//                           <Input type="text" className="form-control" value = {dataObj.referal_name} name="referal_name" id="referal_name"  onChange={(e)=>handleForm(e)} readOnly={true} />
//                         </div>
//                       </div>
//                       <br/>
//                       <div className="form-group">
//                         <div className="col-md-12 col-xl-12" >
//                           <center>
//                             <button type="button" onClick={() => navigate(-1)} className="btn btn-soft-secondary btn-block me-2">Cancel</button>
//                             <button className="btn btn-custom-color btn-block">Update Entity</button>
//                           </center>
//                         </div>
//                       </div>
//                     </div>
//                   )}

//                   {message && (
//                     <div className="form-group">
//                       <br/>
//                       <div
//                         className={
//                           successful ? "alert alert-success" : "alert alert-danger"
//                         }
//                         role="alert"
//                       >
//                         {message}
//                       </div>
//                     </div>
//                   )}
//                   <CheckButton style={{ display: "none" }} ref={checkBtn} />
//                 </Form>

//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       </>
//     );


// }
// export default UpdateEntity;