import axios from "axios";

const B_URL = process.env.REACT_APP_LOGINURL
const API_URL  = process.env.REACT_APP_BASEURL;


const headers = {
  'Content-Type': 'application/json',
  'token': localStorage.getItem("session-id")
}


const getAllVariations = (page, limit) => {
  var payload = {
    "functionName": "GetVariations",
    "postData": {
      "offset": page,
      "sort": {
        "_id": -1
      },
      "limit": limit,
    }
  }
  return axios.post(B_URL + "VariationsModule/GetVariations", payload, { headers });
};
const GetVariationTypes = (payload) => {

  return axios.post(B_URL + "VariationTypesModule/Get_Variation_sub_Types", payload, { headers });
};

const getAllVariationsAttributes = (Variations_id) => {
  var payload = {
    "functionName": "GetVariationsAttribute",
    "postData": {
      "offset": 0,
      "sort": {
        "_id": -1
      },
      "limit": 99999,
      "Variations_id": Variations_id,
      "attribute_id": ""
    }
  }
  return axios.post(B_URL + "VariationsAttributeModule/GetVariationsAttribute", payload, { headers });
};

const updateMultipleVariationsAttributes = (data) => {
  // console.log("DATA:", data);
  var payload = {
    "functionName": "UpdateMultipleVariationsAttributes",
    "postData": {
      "Variations_id": data.Variations_id,
      "section_id": data.section_id,
      "updates": data.updates
    }
  }
  return axios.post(B_URL + "VariationsAttributeModule/UpdateMultipleVariationsAttribute", payload, { headers });
};

const UpdateVariations = (payload) => {
  // console.log("DATA:", data);
  return axios.post(B_URL + "VariationsModule/UpdateVariations", payload, { headers });
};

const AddSection = (data) => {
  // console.log("DATA:", data);
  var payload = {
    "functionName": "AddSection",
    "postData": {
      "name": data.name,
      "status": data.status
    }
  }
  return axios.post(B_URL + "SectionModule/AddSection", payload, { headers });
};

const AddVariations = (payloadData) => {
  return axios.post(B_URL + "VariationsModule/AddVariations", payloadData, { headers });
};
const AddVariationTypes = (payloadData) => {
  return axios.post(B_URL + "VariationsTypesModule/AddVariationTypes", payloadData, { headers });
};
const AddBulkVariations = (payloadData) => {
  return axios.post(B_URL + "VariationsModule/AddBulkVariations", payloadData, { headers });
};
const AddBulkVariationTypes = (payloadData) => {
  return axios.post(B_URL + "VariationTypesModule/AddBulkVariationTypes", payloadData, { headers });
};

const EditSection = (data, id) => {
  // console.log("DATA:", data);
  var payload = {
    "functionName": "UpdateSection",
    "postData": {
      "section_id": id,
      "name": data.name,
      "status": data.status
    }
  }
  return axios.post(B_URL + "SectionModule/UpdateSection", payload, { headers });
};

const EditAttribute = (data, id) => {
  // console.log("DATA:", data);
  var payload = {
    "functionName": "UpdateAttribute",
    "postData": {
      "attribute_id": id,
      "lable": data.lable,
      "key": data.key,
      "status": data.status
    }
  }
  return axios.post(B_URL + "AttributeModule/UpdateAttribute", payload, { headers });
};

const AddAttribute = (data) => {
  // console.log("DATA:", data);
  var payload = {
    "functionName": "AddAttribute",
    "postData": {
      "lable": data.lable,
      "key": data.key,
      "status": data.status
    }
  }
  return axios.post(B_URL + "AttributeModule/AddAttribute", payload, { headers });
};

const getAllSections = (page, limit) => {
  var payload = {
    "functionName": "GetSections",
    "postData": {
      "offset": 0,
      "sort": {
        "_id": -1
      },
      "limit": 10
    }
  }
  return axios.post(B_URL + "SectionModule/GetSections", payload, { headers });
};

const GetSingleSection = (id) => {
  var payload = {
    "functionName": "GetSingleSection",
    "postData": {
      "id": id
    }
  }
  return axios.post(B_URL + "SectionModule/GetSingleSection", payload, { headers });
};

const GetSingleAttribute = (id) => {
  var payload = {
    "functionName": "GetSingleAttribute",
    "postData": {
      "id": id
    }
  }
  return axios.post(B_URL + "AttributeModule/GetSingleAttribute", payload, { headers });
};

const getAllAttributes = (page, limit) => {
  var payload = {
    "functionName": "GetAttributes",
    "postData": {
      "offset": 0,
      "sort": {
        "_id": -1
      },
      "limit": 10
    }
  }
  return axios.post(B_URL + "AttributeModule/GetAttributes", payload, { headers });
};

const deleteSection = (id) => {
  var payload = {
    "functionName": "DeleteSection",
    "postData": {
      "section_id": id
    }
  }
  return axios.post(B_URL + "SectionModule/DeleteSection", payload, { headers });
};

const deleteAttribute = (id) => {
  var payload = {
    "functionName": "DeleteAttribute",
    "postData": {
      "attribute_id": id
    }
  }
  return axios.post(B_URL + "AttributeModule/DeleteAttribute", payload, { headers });
};

const deleteBulkSection = (selectedRows) => {
  var payload = {
    "functionName": "DeleteBulkSection",
    "postData": {
      "section_ids": selectedRows
    }
  }
  return axios.post(B_URL + "SectionModule/DeleteBulkSection", payload, { headers });
};

const deleteBulkAttribute = (selectedRows) => {
  var payload = {
    "functionName": "DeleteBulkAttribute",
    "postData": {
      "attribute_ids": selectedRows
    }
  }
  return axios.post(B_URL + "AttributeModule/DeleteBulkAttribute", payload, { headers });
};
const DeleteBulkVariations = (selectedRows) => {
  var payload = {
    "functionName": "DeleteBulkVariations",
    "postData": {
      "Variations_ids": selectedRows
    }
  }
  return axios.post(B_URL + "VariationsModule/DeleteBulkVariations", payload, { headers });
};

const DeleteBulkVariationTypes = (selectedRows) => {
  var payload = {
    "functionName": "DeleteBulkVariationTypes",
    "postData": {
      "_ids": selectedRows
    }
  }
  return axios.post(B_URL + "VariationTypesModule/DeleteBulkVariationTypes", payload, { headers });
};
const deleteVariation = (id) => {
  var payload = {
    "functionName": "DeleteVariations",
    "postData": {
      "Variations_id": id
    }
  }
  return axios.post(B_URL + "VariationsModule/DeleteVariations", payload, { headers });
};
const DeleteVariationTypes = (id) => {
  var payload = {
    "functionName": "DeleteVariationTypes",
    "postData": {
      "_id": id
    }
  }
  return axios.post(B_URL + "VariationTypesModule/DeleteVariationTypes", payload, { headers });
};

const UpdateVariationTypes = (payload) => {
  // console.log("DATA:", data);
  return axios.post(B_URL + "VariationTypesModule/UpdateVariationTypes", payload, { headers });
};


const GetCustomers = (sessionID, payload) => {
  // console.log("DATA--------:", payload);
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "RanksModule/GetCustomers", payload, { headers });
};

const GetRanks = (sessionID, payload) => {
  // console.log("DATA--------:", payload);
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "RanksModule/GetRanks", payload, { headers });
};

const GetBusinesses = (sessionID, payload) => {
  // console.log("DATA:", data);
  var payload = {
    "functionName": "GetBusinesses",
    "postData": {
      "offset": 0,
      "sort": {
        "_id": -1
      },
      "limit": 9999999999,
      "business_ids": []
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "BusinessModule/GetBusinesses", payload, { headers });
};


const AddRanks = (sessionID, payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "RanksModule/AddRanks", payload, { headers });
};


const DeleteRanks = (sessionID, _id) => {
  var payload = {
    "functionName": "DeleteRanks",
    "postData": {
      "_id": _id
    },
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "RanksModule/DeleteRanks", payload, { headers });
};

const DeleteBulkRanks = (sessionID, _ids) => {
  var payload = {
    "functionName": "DeleteBulkRanks",
    "postData": {
      "_ids": _ids
    },
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "RanksModule/DeleteBulkRanks", payload, { headers });
};



const UpdateRanks = (sessionID, _id, payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.put(B_URL + `RanksModule/UpdateRanks/${_id}`, payload, { headers });
};



const updateRanksApproval = (RanksId, approve, sessionID) => {
  var payload = {
    "functionName": "updateRanksApproval",
    "postData": {
      "RanksId": RanksId,
      "approve": approve,

    },
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "RanksModule/updateRanksApproval", payload, { headers });
};


const getUserInfo = (payload, sessionID) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  console.log("getUserInfo" , payload);
  return axios.post(B_URL + 'AddressModule/getUserInfo', payload, { headers });
};

const sendPassword = (sessionID,id) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  var payload = {
    functionName: "GetRanksPassword",
    postData: {
      "_id": id,
    },
  }
  return axios.post(API_URL, payload, {headers});
};


const VariationsService = {
  GetCustomers,
  sendPassword,
  getUserInfo,
  GetRanks,
  GetBusinesses,
  AddRanks,
  DeleteRanks,
  DeleteBulkRanks,
  UpdateRanks,
  updateRanksApproval,
  getAllVariations,
  GetVariationTypes,
  AddVariationTypes,
  AddVariations,
  AddBulkVariations,
  AddBulkVariationTypes,
  getAllVariationsAttributes,
  updateMultipleVariationsAttributes,
  UpdateVariations,
  UpdateVariationTypes,
  getAllSections,
  getAllAttributes,
  AddSection,
  AddAttribute,
  GetSingleSection,
  EditSection,
  GetSingleAttribute,
  EditAttribute,
  deleteSection,
  deleteAttribute,
  DeleteVariationTypes,
  deleteVariation,
  deleteBulkSection,
  deleteBulkAttribute,
  DeleteBulkVariations,
  DeleteBulkVariationTypes
};

export default VariationsService;