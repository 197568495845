import axios from "axios";

const B_URL = process.env.REACT_APP_LOGINURL

const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
};

const getAddresses = (_id) => {
    var payload = {
        functionName: "GetAddresses",
        postData: {
            user_id:_id
        },
    };
    return axios.post(B_URL + 'AddressModule/GetAddresses', payload, { headers });
};

const addAddress = (addressData) => {
    var payload = {
        functionName: "AddAddress",
        postData: addressData,
    };
    return axios.post(B_URL + 'AddressModule/AddAddress', payload, { headers });
};

const updateAddress = (addressData) => {
    var payload = {
        functionName: "UpdateAddress",
        postData: addressData,
    };
    return axios.post(B_URL + 'AddressModule/UpdateAddress', payload, { headers });
};

const deleteAddress = (addressId) => {
    console.log("DeleteAddress" , addressId);
    var payload = {
        functionName: "DeleteAddress",
        postData: {
            "address_id": addressId
        },
    };
    return axios.post(B_URL + 'AddressModule/DeleteAddress', payload, { headers });
};

const submitUserInfo = (payload) => {
    console.log("submitUserInfo" , payload);
    return axios.post(B_URL + 'AddressModule/submitUserInfo', payload, { headers });
};

const SubmitVerificationDocs = (payload) => {
    console.log("SubmitVerificationDocs" , payload);
    return axios.post(B_URL + 'AddressModule/SubmitVerificationDocs', payload, { headers });
};

const getUserInfo = (payload) => {
    console.log("getUserInfo" , payload);
    return axios.post(B_URL + 'AddressModule/getUserInfo', payload, { headers });
};

const AddressService = {
    getAddresses,
    addAddress,
    updateAddress,
    SubmitVerificationDocs,
    submitUserInfo,
    getUserInfo,
    deleteAddress
};

export default AddressService;