import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, CardBody, Card } from "reactstrap";
import Tabs, { TabPane } from "rc-tabs";
import TabContent from "rc-tabs/lib/SwipeableTabContent";
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar";
import DynamicContentTab from "./DynamicContentTab.js";
import BusinessService from "./Businesses.service.js";
import AuthService from "../Authentication/auth.service.js";
import Alerts from "../../components/Alerts/alerts.js";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import AddBusinessAttributeModal from './AddBusinessAttributeModal';
import TabGenralBUsiness from "./TabGenralBUsiness";
import TabE_Invoice from "./TabE_Invoice.js";

import LaLamove from "./LaLamove";
import General from "./General";
import FlatShipment from "./FlatShipment";

const BusinessDetails = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('1');
    const [businessAttributes, setBusinessAttributes] = useState([]);
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const [arrayData, setarrayData] = useState([]);
    const [activeShipmentTab, setActiveShipmentTab] = useState('1');

    const [showModalAddBusinessAttribute, setShowModalAddBusinessAttribute] = useState(false);
    const handleToggleModalAddBusinessAttribute = (arrayData) => {
        setarrayData(arrayData);
        setShowModalAddBusinessAttribute(!showModalAddBusinessAttribute);
    }
    const fetchBusinessAttributes = () => {
        setLoading(true);
        BusinessService.getAllBusinessAttributes(id).then((response) => {
            setLoading(false);
            if (response.data.message === "Unauthorized") {
                AuthService.logout();
                navigate("/login");
                window.location.reload();
            } else {
                setBusinessAttributes(response.data.data);
                setActiveTab('1');
            }
        }, (error) => {
            setLoading(false);
            const errContent = (error.response && error.response.data) || error.message || error.toString();
            if (errContent) {
                Alerts.swalErrorAlert(errContent);
            } else {
                Alerts.swalErrorAlert("Server Error, Please try again later.");
            }
        });
    };

    useEffect(() => {
        i18n.changeLanguage(localStorage.getItem('language'));
        fetchBusinessAttributes();
    }, []);

    const uniqueSections = businessAttributes.reduce((unique, item) => {
        const sectionId = item.SectionDetails[0]._id;
        if (!unique.find(u => u.SectionDetails[0]._id === sectionId)) {
            unique.push(item);
        }
        return unique;
    }, []);

    const handleTabChange = (key) => {
        setActiveTab(key);
        if (key === '3') {
            setActiveShipmentTab('1');
        }
    };

    const handleShipmentTabChange = (key) => {
        setActiveShipmentTab(key);
    };

    return (
        <div className="app-main__outer">
            <div className="app-main__inner">
                <div className="bg-light-gray bg-animation">
                    <div className="d-flex justify-content-center align-items-center">
                        <Col md="12" className="mx-auto app-login-box">
                            <div className="app-page-title">
                                <div className="page-title-wrapper">
                                    <div className="page-title-heading fw-bold">
                                        <div className="page-title-icon">
                                            <i className="pe-7s-news-paper icon-gradient bg-tempting-azure" />
                                        </div>
                                        <div>
                                            {`${t("Business")} ${t("Management")}`}
                                            <div className="page-title-subheading">
                                                {`${t("Details of")} ${t("Business")}`}.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="page-title-actions">
                                        <AddBusinessAttributeModal 
                                            showModalAddBusinessAttribute={showModalAddBusinessAttribute} 
                                            handleToggleModalAddBusinessAttribute={handleToggleModalAddBusinessAttribute} 
                                            busness_id={id} 
                                            arrayData={arrayData} 
                                        />
                                        <NavLink to="/businesses-list">
                                            <button type="button" className="btn btn-outline-success btn-hover-shine me-3">
                                                {t("Back")}
                                            </button>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex justify-content-center">
                                <div className="col-md-12">
                                    <LoadingOverlay 
                                        tag="div" 
                                        active={loading}
                                        styles={{ overlay: (base) => ({ ...base }) }}
                                        spinner={<DNALoader />}
                                    >
                                        <Tabs
                                            activeKey={activeTab}
                                            onChange={handleTabChange}
                                            renderTabBar={() => (
                                                <ScrollableInkTabBar
                                                    extraContent={<div style={{ width: 50 }}></div>}
                                                    scrollAnimated={true}
                                                />
                                            )}
                                            renderTabContent={() => <TabContent animated={false} />}
                                        >
                                            <TabPane tab={t('General')} key="1">
                                                <TabGenralBUsiness className="btn-custom-color" business_id={id} />
                                            </TabPane>
                                            <TabPane tab={t('E-Invoice')} key="2">
                                                <TabE_Invoice className="btn-custom-color" business_id={id} />
                                            </TabPane>
                                            <TabPane tab={t('Shipment')} key="3">
                                                <Card>
                                                    <CardBody>
                                                        <ul className="nav nav-tabs">
                                                        <li className="nav-item">
                                                                <a 
                                                                    className={`nav-link ${activeShipmentTab === '1' ? 'active' : ''}`} 
                                                                    onClick={() => handleShipmentTabChange('1')}
                                                                >
                                                                    {t('General')}
                                                                </a>
                                                            </li>
                                                            <li className="nav-item">
                                                                <a 
                                                                    className={`nav-link ${activeShipmentTab === '2' ? 'active' : ''}`} 
                                                                    onClick={() => handleShipmentTabChange('2')}
                                                                >
                                                                    {t('Own Shipment')}
                                                                </a>
                                                            </li>
                                                            <li className="nav-item">
                                                                <a 
                                                                    className={`nav-link ${activeShipmentTab === '3' ? 'active' : ''}`} 
                                                                    onClick={() => handleShipmentTabChange('3')}
                                                                >
                                                                    {t('Lalamove')}
                                                                </a>
                                                            </li>
                                                        </ul>
                                                        <div className="tab-content">
                                                        <div className={`tab-pane fade ${activeShipmentTab === '1' ? 'show active' : ''}`}>
                                                                <General business_id={id} />
                                                            </div>
                                                            <div className={`tab-pane fade ${activeShipmentTab === '2' ? 'show active' : ''}`}>
                                                                <FlatShipment business_id={id} />
                                                            </div>
                                                            <div className={`tab-pane fade ${activeShipmentTab === '3' ? 'show active' : ''}`}>
                                                                <LaLamove business_id={id} />
                                                            </div>
                                                         
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </TabPane>
                                            {uniqueSections.map((b_attr_val, index) => {
                                                const tabKey = b_attr_val.SectionDetails[0]._id;
                                                return (
                                                    <TabPane tab={b_attr_val.SectionDetails[0].name} key={tabKey}>
                                                        {activeTab === tabKey && (
                                                            <DynamicContentTab
                                                                section_id={b_attr_val.SectionDetails[0]._id}
                                                                onSuccess={handleToggleModalAddBusinessAttribute}
                                                                className="btn-custom-color"
                                                            />
                                                        )}
                                                    </TabPane>
                                                );
                                            })}
                                        </Tabs>
                                    </LoadingOverlay>
                                </div>
                            </div>
                        </Col>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BusinessDetails;