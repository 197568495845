import React, { useState, useEffect, useRef } from 'react';
import {
  Box, Typography, TextField, Button, Dialog, DialogContent,
  IconButton, styled, Grid, FormControl, InputLabel, Select, MenuItem
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useTranslation } from 'react-i18next';
import AddressService from './addressService';
import AlertService from "../../components/Alerts/alerts";
import { ArrowBack, Close as CloseIcon, Check as CheckIcon } from '@mui/icons-material';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: '16px',
    width: '100%',
    maxWidth: '600px',
    margin: 0,
  },
}));

const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px',
}));

const SearchContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  marginBottom: theme.spacing(2),
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '100%',
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    '& fieldset': {
      border: 'none',
    },
  },
  backgroundColor: '#F0F5FB',
  '& .MuiInputLabel-root': {
    fontSize: '16px',
    color: '#757575',
  },
  '& .MuiOutlinedInput-input': {
    padding: '12px 14px',
    fontSize: '13px',
  },
  marginBottom: theme.spacing(2),
}));


const ActionButton = styled(Button)(({ theme }) => ({
  borderRadius: '8px',
  padding: '8px 16px', 
  textTransform: 'none',
  fontWeight: 'bold',
  boxShadow: 'none',
  width: '30%', 
  borderRadius: '10px',
  backgroundColor: '#e67e22',
  padding: '10px 20px',
}));

const CancelButton = styled(Button)(({ theme }) => ({
padding: '8px 16px', 
textTransform: 'none',
fontWeight: 'bold',
color: '#16294E',
boxShadow: 'none',
width: '30%', 
borderRadius: '10px',
backgroundColor: '#E7E7E7',
padding: '10px 20px',
border: '1px solid #E7E7E7',
// padding: '10px 20px',
}));
const UpdateAddressPopup = ({ open, onClose, address, onUpdateAddress }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    location: '',
    block: '',
    unit: '',
    label: '',
    latitude: '',
    longitude: '',
    address_url: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
  });
  const autocompleteRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (address) {
      setFormData({
        ...address,
        location: address.location || `${address.block} ${address.unit}, ${address.city}, ${address.state} ${address.postalCode}, ${address.country}`.trim()
      });
    }
  }, [address]);

  useEffect(() => {

    const loadGoogleMapsScript = () => {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDlKtLygdyr4XpjVE1WcyR7yHuigyq_agw&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = initAutocomplete;
      script.onerror = () => console.error("Error loading Google Maps script");
      document.head.appendChild(script);
    };

    if (!window.google) {
      loadGoogleMapsScript();
    } else {
      initAutocomplete();
    }

    return () => {
      if (autocompleteRef.current) {
        window.google.maps.event.clearInstanceListeners(autocompleteRef.current);
      }
    };
  }, [open, address, formData.location, formData]);

  const initAutocomplete = () => {
    if (!inputRef.current) {
      console.error("Input ref is not available");
      return;
    }

    try {
      autocompleteRef.current = new window.google.maps.places.Autocomplete(inputRef.current, {
        types: ['address'],
      });
      autocompleteRef.current.addListener('place_changed', handlePlaceSelect);
    } catch (error) {
      console.error("Error initializing Google Maps Autocomplete:", error);
    }
  };

  const handlePlaceSelect = () => {
    try {
      const place = autocompleteRef.current.getPlace();
      if (!place.geometry) {
        console.error('No details available for input:', place.name);
        return;
      }

      const addressComponents = place.address_components;
      let block = '';
      let unit = '';
      let city = '';
      let state = '';
      let postalCode = '';
      let country = '';

      addressComponents.forEach((component) => {
        const componentType = component.types[0];

        switch (componentType) {
          case 'street_number':
            unit = component.long_name;
            break;
          case 'route':
            block = component.long_name;
            break;
          case 'locality':
            city = component.long_name;
            break;
          case 'administrative_area_level_1':
            state = component.long_name;
            break;
          case 'postal_code':
            postalCode = component.long_name;
            break;
          case 'country':
            country = component.long_name;
            break;
        }
      });

      const location = `${block} ${unit}, ${city}, ${state} ${postalCode}, ${country}`.trim();

      setFormData({
        ...formData,
        location: location,
        block: block,
        unit: unit,
        city: city,
        state: state,
        postalCode: postalCode,
        country: country,
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        address_url: place.url,
      });
    } catch (error) {
      console.error("Error handling place selection:", error);
    }
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleUpdateAddress = async () => {
    try {
      const data = {
        address_id: address._id,
        label: formData.label,
        unit: formData.unit,
        location: formData.location,
        block: formData.block,
        city: formData.city,
        state: formData.state,
        postalCode: formData.postalCode,
        country: formData.country,
        latitude: formData.latitude,
        longitude: formData.longitude
      };
      const response = await AddressService.updateAddress(data);

      if (response.data.status) {

        AlertService.swalSuccessAlert(t("Address updated successfully."));
        onUpdateAddress(response.data.address);
        onClose();
      } else {
        AlertService.swalErrorAlert(response.data.message || t("Error updating address."));
      }
    } catch (error) {
      AlertService.swalErrorAlert(t("Error updating address. Please try again."));
    }
  };

  return (
    <StyledDialog open={open} onClose={onClose} fullWidth>
      <Header>
        <IconButton onClick={onClose}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h5">{t('Address')}</Typography>
        <Box width={24} />
      </Header>
      <DialogContent sx={{ padding: 5 }}>
        <SearchContainer>
          <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }} variant="h6">{t('Address')}</Typography>
          <StyledTextField
            inputRef={inputRef}
            fullWidth
            placeholder={t("Search or edit address")}
            name="location"
            value={formData.location}
            onChange={handleInputChange}
            InputProps={{
              startAdornment: <LocationOnIcon color="action" />,
            }}
          />
        </SearchContainer>
        <Grid container spacing={2}>
          <Grid item xs={6}>
          <Typography sx={{ fontSize: '13px', fontWeight: 'bold' }} variant="h6">{t('Block or Street name')}</Typography>
            <StyledTextField
              fullWidth
              placeholder={t("Block or Street name")}
              name="block"
              value={formData.block}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
          <Typography sx={{ fontSize: '13px', fontWeight: 'bold' }} variant="h6">{t('Unit/House no.')}</Typography>
            <StyledTextField
              fullWidth
              placeholder={t("Unit/House no.")}
              name="unit"
              value={formData.unit}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
          <Typography sx={{ fontSize: '13px', fontWeight: 'bold' }} variant="h6">{t('City')}</Typography>
            <StyledTextField
              fullWidth
              placeholder={t("City")}
              name="city"
              value={formData.city}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
          <Typography sx={{ fontSize: '13px', fontWeight: 'bold' }} variant="h6">{t('State/Province')}</Typography>
            <StyledTextField
              fullWidth
              placeholder={t("State/Province")}
              name="state"
              value={formData.state}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
          <Typography sx={{ fontSize: '13px', fontWeight: 'bold' }} variant="h6">{t('Postal Code')}</Typography>
            <StyledTextField
              fullWidth
              placeholder={t("Postal Code")}
              name="postalCode"
              value={formData.postalCode}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={6}>
          <Typography sx={{ fontSize: '13px', fontWeight: 'bold' }} variant="h6">{t('Country')}</Typography>
            <StyledTextField
              fullWidth
              placeholder={t("Country")}
              name="country"
              value={formData.country}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
        <FormControl fullWidth sx={{ mb: 2 }}>
        <Typography sx={{ fontSize: '13px', fontWeight: 'bold' }} variant="h6">{t('Label')}</Typography>
          <Select
            name="label"
            sx={{
              width: '100%',
              '& .MuiOutlinedInput-root': {
                borderRadius: '12px',
              },
              backgroundColor: '#F0F5FB',
              '& .MuiInputLabel-root': {
                fontSize: '16px',
                color: '#757575',
              },
              '& .MuiOutlinedInput-input': {
                padding: '12px 14px',
                fontSize: '13px',
              },
              marginBottom: '2px',
              '& fieldset': {
                border: 'none', 
              },
            }}
            value={formData.label}
            onChange={handleInputChange}
          >
            <MenuItem value="Home">{t("Home")}</MenuItem>
            <MenuItem value="Work">{t("Work")}</MenuItem>
            <MenuItem value="Other">{t("Other")}</MenuItem>
          </Select>
        </FormControl>
        <Box sx={{ display: 'flex', gap: 2, justifyContent:'center' }}>
          <CancelButton
            fullWidth
            variant="outlined"
            onClick={onClose}
            startIcon={<CloseIcon />}
          >
            {t("Cancel")}
          </CancelButton>
          <ActionButton
            fullWidth
            variant="contained"
            onClick={handleUpdateAddress}
            startIcon={<CheckIcon />}
          >
            {t("Update")}
          </ActionButton>
        </Box>
      </DialogContent>
      {/* z-index: 1300 !important; */}

      <style jsx global>{`
        .pac-container {
          z-index: 9999 !important;
          width: calc(100% - 32px) !important;
          left: 16px !important;
          right: 16px !important;
        }
      `}</style>
    </StyledDialog>
  );
};

export default UpdateAddressPopup;