import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const PermissionAccess = () => {
  const authData = useSelector((state) => state.authReducer.authData);
  const [current_module, setCurrent_module] = useState({});
  let accessPages = [];
  let super_admin = false;
  let moduleDetails = authData?.userData?.role_details?.all_module_details;

  useEffect(() => {
    AdminAccess();
  }, []);

  if (authData?.userData?.role_details?.super_admin === true) {
    super_admin = true;
  } else {
    if (Array.isArray(moduleDetails)) {
      accessPages = moduleDetails.map(module => module.route);
    }
  }

  const AdminAccess = async () => {
    const stored_user = authData?.userData;
    console.log("All Access : ", moduleDetails);
    let Access_Pages = [];
    if (moduleDetails) {
      Access_Pages = moduleDetails;
    }
    const currentRoute = window.location.pathname.slice(1);
    let Current_Page;
    Access_Pages.forEach((page) => {
      if (page.route === currentRoute) {
        Current_Page = page;
      }
    });
    let role_modules = stored_user?.role_details?.role_modules;
    if (role_modules) {
      role_modules.forEach(role => {
        if (role.module_id === Current_Page?._id) {
          setCurrent_module(role);
        }
      });
    }
  };

  return { authData, accessPages, super_admin, current_module };
};

export default PermissionAccess;