import React, { Fragment, useState, useEffect, useRef } from "react";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import "../../../assets/custom css/loadoverlay.scss";
import moment from 'moment';
import Header from './Header';
import NumberFormatter from "../../../components/NumberFormatter";
import DataTable from 'react-data-table-component';
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import AddTopUp from './AddTopUp';
import AlertService from '../../../components/Alerts/alerts';
import DashboardService from "../dashboard.service.js";
import AuthService from "../../Authentication/auth.service";
import { useTranslation } from 'react-i18next';
import CloseIcon from "@mui/icons-material/Close";
import { Chip } from '@mui/material';
import { Badge } from "react-bootstrap";
import TablePagination from "@mui/material/TablePagination";
import Alerts from "../../../components/Alerts/alerts";
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Divider,
  Typography,
  Box,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import {
  Avatar, Tabs, Tab, TextField, Button, Drawer,
  Card, CardContent, IconButton, Container, Grid, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel
} from '@mui/material';
import { Row, Col, CardBody, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import axios from "axios";
import styled from "styled-components";
const B_URL = process.env.REACT_APP_LOGINURL
const Image_URL = process.env.REACT_APP_IMAGEURL;

const headers = {
  'Content-Type': 'application/json',
  'token': localStorage.getItem("session-id")
};

const StyledChip = styled(Chip)`
  font-size: 12px;
  height: 24px;
  font-weight: 500;
`;



const SidebarContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 130vh;
  background-color: #f9f9f9;
  overflow: hidden;
  border-radius: 15px;

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
  }
`;


const inputStyle = {
  padding: "10px 12px",
  borderRadius: "8px",
  border: "1px solid #e0e0e0",
  fontSize: "14px",
  width: "100%",
  outline: "none",
  transition: "all 0.3s ease",
  boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
}

const focusStyle = {
  border: "1px solid #6366f1",
  boxShadow: "0 0 0 3px rgba(99, 102, 241, 0.1)",
}

const selectStyle = {
  ...inputStyle,
  appearance: "none",
  backgroundImage:
    "url(\"data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e\")",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "right 10px center",
  backgroundSize: "16px",
  paddingRight: "40px",
}

const buttonStyle = {
  padding: "10px 16px",
  borderRadius: "8px",
  border: "none",
  fontSize: "14px",
  fontWeight: "600",
  cursor: "pointer",
  transition: "all 0.2s ease",
  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
}

const searchButtonStyle = {
  ...buttonStyle,
  backgroundColor: "#6366f1",
  color: "white",
}

const resetButtonStyle = {
  ...buttonStyle,
  backgroundColor: "#f3f4f6",
  color: "#4b5563",
}

const labelStyle = {
  display: "block",
  marginBottom: "6px",
  fontSize: "14px",
  fontWeight: "500",
  color: "#4b5563",
}

const formGroupStyle = {
  marginBottom: "16px",
  flex: 1,
}



const Sidebar = styled.div`
  flex: 1;
  background-color: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  min-width: 250px;
  @media (max-width: 768px) {
    width: 100%;
    padding: 10px;
  }

`;

const SidebarItem = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${({ active }) => (active ? "#FFFFFF" : "#888")};
  margin-bottom: 15px;
  padding: 10px 15px;
  height:18%;
  cursor: pointer;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: ${({ active }) => (active ? "#C1B314" : "transparent")};
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background-color: #C1B314;
    color: white;
  }
`;



const StyledButton = styled(Button)`
  font-weight: bold;
  border-radius: 5px;
  color: #c1b314;
  paddingLeft:20px;
  paddingRight:20px;
  border: 1px solid #c1b314;
  text-transform: none;
  &:hover {
    background-color: #c1b314;
    color: white;
  }
`;
const Top_Up_Button = styled(Button)`
  font-weight: bold;
  border-radius: 5px;
  color: #c1b314;
  paddingLeft:10px;
  width:170px;
  height:45px;
  paddingRight:10px;
  border: 1px solid #c1b314;
  text-transform: none;
  &:hover {
    background-color: #c1b314;
    color: white;
  }
`;

const StyledButton2 = styled(Button)`
  font-weight: bold;
  border-radius: 5px;
  margin-left: 5px;
  background-color: #c1b314;
  color: white;
  paddingLeft:20px;
  paddingRight:20px;
  border: 1px solid #c1b314;
  text-transform: none;
  &:hover {
    background-color: white;
    color: black;
  }
`;

const StyledTableCell = styled(TableCell)`
  font-size: 14px;
  font-weight: 600;
  color: #5a5a5a;
`;

const StyledTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #f9f9f9;
  }
`;

const StyledHeaderCell = styled(TableCell)`
  font-size: 14px;
  font-weight: bold;
  color: #303030;
`;

const StyledTypography = styled(Typography)`
  font-size: 20px;
  font-weight: bold;
  color: #000;
  margin-bottom: 10px;
`;


const MainContent = styled.div`
  flex: 3;
  padding: 20px;
  background-color: white;
  overflow-y: auto;
  position: relative;
  @media (max-width: 768px) {
    padding: 10px;
  }
  &::before {
    content: '';
    position: absolute;
    top: 30px; 
    bottom: 30px; 
    left: 0;
    width: 1px;
    background-color: #CDCDCD; 
  }

`;

const CardStyled = styled(Card)`
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: 1px solid #CDCDCD;
  padding: 20px;
`;

const SidebarArrow = styled.div`
  font-size: 16px;
  color: #888;
`;

const PreviewContainer = styled(Box)`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

const PreviewImage = styled.img`
  max-width: 100%;
  max-height: 200px;
  border: 1px solid #CDCDCD;
  border-radius: 5px;
  object-fit: cover;
`;


const UploadButton = styled(Button)`
  margin-top: 15px;
  width: 100%;
  height: 60px;
  border: 1px dashed #CDCDCD;
  color: #888;
  &:hover {
    border-color: #C1B314;
  }
`;




const StatusDetailsModal = ({ show, onHide, statusDetails }) => {
  console.log("Modal show prop:", show); // Log the prop
  return (
    <Modal isOpen={show} toggle={onHide} >
      <ModalHeader toggle={onHide}>Status Details</ModalHeader>
      <ModalBody>
        {Object.entries(statusDetails).map(([status, quantity]) => (
          <div key={status} style={{ marginBottom: "10px" }}>
            <Badge
              style={{
                backgroundColor: "#007bff",
                padding: "5px 10px",
                borderRadius: "15px",
                fontSize: "14px",
                marginRight: "10px",
              }}
            >
              {status.toUpperCase()}
            </Badge>
            <span style={{ fontWeight: "bold" }}>Quantity: {quantity}</span>
          </div>
        ))}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onHide}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};


const CreditPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [logo, setLogo] = useState(localStorage.getItem("system_logo"));
  const [ALLMPV, setALLMPV] = useState(0);
  const [ALLIPV, setALLIPV] = useState(0);
  const [ALLSPV, setALLSPV] = useState(0);
  const [ALLDPV, setALLDPV] = useState(0);
  const [ALLAmount, setALLAmount] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [ipv_transactions, setIpv_transactions] = useState([]);
  const [spv_transactions, setSpv_transactions] = useState([]);
  const [showTopUp, setShowTopUp] = useState(false);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(1);
  const [storeduser, setStoredUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [selectedSource, setSelectedSource] = useState("");
  const [activeOption, setActiveOption] = useState("");
  const [MPV, setMPV] = useState(storeduser?.type ? storeduser?.type === 'City/Town Stockist' ? 1000 : 100 : 25);
  const [paymentProof, setPaymentProof] = useState(null);
  const [discount, setDiscount] = useState(storeduser?.type ? storeduser?.type === 'City/Town Stockist' ? 45 : 38 : 30);
  const [source, setSource] = useState(storeduser?.type ? storeduser?.type === 'City/Town Stockist' ? ["Nano ProTech"] : ["Nano ProTech", "City/Town Stockist"] : ["City/Town Stockist", "Mobile Stockist"]);
  const [selectedRows, setSelectedRows] = useState([]);
  const userString = localStorage.getItem('user');
  const authData = JSON.parse(userString);
  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [popupRequest, setPopupRequest] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [statusDetails, setStatusDetails] = useState({});
  console.log("showStatusModal", showStatusModal)
  const [SPVRows, setTotalSPVRows] = useState(0);

  const [filterDate, setFilterDate] = useState(null);
  const [filterText, setFilterText] = useState('');
  const [filterLevel, setFilterLevel] = useState('');
  const [filterStatus, setFilterStatus] = useState('');


  const [filterDateSPV, setFilterDateSPV] = useState(null);
  const [filterTextSPV, setFilterTextSPV] = useState('');
  const [filterLevelSPV, setFilterLevelSPV] = useState('');
  const [filterStatusSPV, setFilterStatusSPV] = useState('');






  const [selectedCurrency, setSelectedCurrency] = useState(
    JSON.parse(localStorage.getItem("selectedCurrency")) || {
      code: "PHP",
      rate: 1,
      symbol: "₱",
    }
  );

  useEffect(() => {
    fetchTopups()
  }, []);


  const fetchTopups = () => {
    const userString = localStorage.getItem('user');
    const user = JSON.parse(userString);
    const userId = user['_id'];
    let data = {
      user_id: userId,
    }
    setLoading(true);
    DashboardService.GetTopUp(data).then((response) => {
      setLoading(false);
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      } else {
        let top_ups = response.data.data
        setTransactions(top_ups);
        setALLMPV(response?.data?.total?.[0]?.total_mpv || 0)
        setALLSPV(response?.data?.total?.[0]?.SPV || 0)
        setALLDPV(response?.data?.total?.[0]?.DPV || 0)
        setALLIPV(response?.data?.total?.[0]?.total_ipv || 0)
      }
    }).catch((error) => {
      setLoading(false);
      const errContent = (error.response && error.response.data) || error.message || error.toString();
      if (errContent) {
        Alerts.swalErrorAlert(errContent);
      } else {
        Alerts.swalErrorAlert(t("Server Error, Please try again later."));
      }
    });
  };

  // const fetchIPV = async () => {
  //   const userString = localStorage.getItem('user');
  //   const user = JSON.parse(userString);
  //   const userId = user['_id'];
  //   let data = {
  //     user_id: userId,
  //   }
  //   setLoading(true);
  //   let response = await axios.post(B_URL + "TopUpModule/GetTopUp", data, { headers });
  //   setLoading(false);
  //   if (response.data.message === "Unauthorized") {
  //     AuthService.logout();
  //     navigate("/login");
  //     window.location.reload();
  //   } else {
  //     let Ipv_transactions = response.data.data
  //     setIpv_transactions(Ipv_transactions);
  //     setALLIPV(response?.data?.total?.[0]?.total_mpv || 0)
  //   }
  // };


  async function fetchIPV(page_index, page_limit, filterText, filterStatus, filterLevel) {
    var offset = (page_index - 1) * page_limit;
    if (offset < 1) {
      offset = 0;
    }
    const stored_user = authData;
    let business = []
    if (stored_user?.businesses) {
      business = stored_user?.businesses
    }

    var getData = {
      functionName: "GetIpv_transactions",
      "postData": {
        "offset": offset,
        "user_id": authData._id,
        "filterStatus": filterStatus,
        "filterText": filterText,
        "filterLevel": filterLevel,
        "sort": {
          "_id": -1
        },
        "limit": page_limit,
      }
    }
    setLoading(true);
    let response = await axios.post(B_URL + "TopUpModule/GetIpv_transactions", getData, { headers });
    setLoading(false);
    console.log("Get Member response:-", response)
    if (response.data.message === "Unauthorized") {
      AuthService.logout();
      navigate("/login");
      window.location.reload();
    }
    else {
      setIpv_transactions(response.data.data);
      console.log("Responsse", response.data);
      setALLIPV(response?.data?.total?.[0]?.total_ipv || 0)
      setALLAmount(response?.data?.total?.[0]?.total_amount || 0)
      setTotalRows(response.data.count);
    }
  };

  async function GetSPV_History(page_index, page_limit, filterTextSPV, filterStatusSPV, filterLevelSPV, filterDateSPV) {
    var offset = (page_index - 1) * page_limit;
    if (offset < 1) {
      offset = 0;
    }
    const stored_user = authData;
    let business = []
    if (stored_user?.businesses) {
      business = stored_user?.businesses
    }

    var getData = {
      functionName: "GetSPV_History",
      "postData": {
        "offset": offset,
        "user_id": authData._id,
        "filterStatus": filterStatusSPV,
        "filterText": filterTextSPV,
        "filterLevel": filterLevelSPV,
        "filterDate": filterDateSPV,
        "sort": {
          "_id": -1
        },
        "limit": page_limit,
      }
    }
    setLoading(true);
    let response = await axios.post(B_URL + "TopUpModule/GetSPV_History", getData, { headers });
    setLoading(false);
    console.log("Get Member response:-", response)
    if (response.data.message === "Unauthorized") {
      AuthService.logout();
      navigate("/login");
      window.location.reload();
    }
    else {
      setSpv_transactions(response.data.data);
      console.log("Responsse Responsse", response.data);
      // setALLIPV(response?.data?.total?.[0]?.total_ipv || 0)
      // setALLAmount(response?.data?.total?.[0]?.total_amount || 0)
      setTotalRows(response.data.countData.count);
    }
  };


  const tabs = [
    { id: 1, title: "Member Point Value", abb: 'MPV', value: formatNumber(ALLMPV || 0) },
    { id: 2, title: "Inventory Member Point Value", abb: 'IMPV', value: formatNumber(ALLIPV || 0) },
    { id: 3, title: "Shopping Point Value", abb: 'SPV', value: formatNumber(ALLSPV || 0) },
    { id: 4, title: "Distributor Point Value", abb: 'DPV', value: formatNumber(ALLDPV || 0) },
    { id: 5, title: "Advocacy Point Value", abb: 'APV', value: formatNumber(400) },
  ];

  // Helper function to format numbers
  function formatNumber(value) {
    if (!value || isNaN(value)) return value; // Return as is if not a number
    return Number(value).toLocaleString("en-US");
  }


  // Function to handle payment proof upload
  const handlePaymentProofChange = (event) => {
    const file = event.target.files[0];
    if (file) {

      // Validate file size (optional, e.g., max 5MB)
      const maxSize = 5 * 1024 * 1024;
      if (file.size > maxSize) {
        alert("File size should be less than 5MB.");
        return;
      }

      const reader = new FileReader();
      reader.onload = () => {
        setPaymentProof(reader.result); // Store Base64 string
      };
      reader.readAsDataURL(file);
    }
  };


  const [isDrawerOpen, setDrawerOpen] = useState(false);


  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };


  // Function to handle form submission
  const handleSubmit = async () => {
    try {
      if (storeduser?.type ? storeduser?.type === 'City/Town Stockist' ? MPV < 1000 : MPV < 100 : MPV < 25) {
        return AlertService.swalErrorAlert(`MPV must be at least ${storeduser?.type ? storeduser?.type === 'City/Town Stockist' ? 1000 : 100 : 25}`)
      }
      if (!storeduser?.type) {
        if (!(parseInt(parseInt(ALLMPV) + parseInt(MPV)) < 500)) {
          return AlertService.swalErrorAlert(`MPV Wallet Limit exceed from 500`)
        }
      } else if (!(storeduser?.type === 'City/Town Stockist')) {
        return AlertService.swalErrorAlert(`MPV Wallet Limit exceed from 2000`)
      }
      const amount = parseFloat(MPV * parseFloat(136 * selectedCurrency.rate).toFixed(2)).toFixed(2);
      const discountAmount = (amount * discount) / 100;
      const payableAmount = amount - discountAmount;
      let payload = {
        functionName: 'AddTopUp',
        postData: {
          user_id: storeduser?._id,
          selectedSource: selectedSource,
          activeOption: activeOption,
          MPV: MPV,
          currency: selectedCurrency.symbol,
          amount: parseFloat(amount),
          discount: discount,
          discountAmount: parseFloat(discountAmount),
          payableAmount: parseFloat(payableAmount),
          paymentProof: paymentProof, // Base64 string of payment proof image
        }
      }
      console.log("payload", payload)
      // Make backend request
      const response = await DashboardService.SubmitTopUp(payload);
      if (response.data.status) {
        AlertService.swalSuccessAlert(t("Top-up request submitted successfully. Wait for approval."));
        setDrawerOpen(false);
        setMPV(0);
        setPaymentProof(null);
        setSelectedSource('')
        setActiveOption('')
        fetchTopups()
      } else {
        AlertService.swalErrorAlert(response.data.message || t("Error submitting top-up request."));
        setDrawerOpen(false);
      }
    } catch (error) {
      setDrawerOpen(false);
      console.error("Error submitting top-up request:", error);
      AlertService.swalErrorAlert(t("Error submitting top-up request. Please try again."));
    } finally {
      setDrawerOpen(false);
    }
  };

  const [MobileStockists, setMobileStockists] = useState([]);
  const [CityStockists, setCityStockists] = useState([]);
  const handleOpenStatusDetails = (row) => {
    console.log("Opening modal...");
    setStatusDetails(row.status_quantities); // Set the details
    setShowStatusModal(true); // Open the modal
  };




  const columns = [
    {
      name: t("Image"),
      selector: (row) => row.product_details?.image?.[0],
      sortable: true,
      cell: (row) => (
        <div style={{ position: 'relative', display: 'inline-block' }}>
          <img
            src={`${Image_URL}${row.product_details?.image?.[0]}`}
            alt="Product"
            style={{
              width: '50px',
              height: '50px',
              objectFit: 'cover',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
            className="hover-image"
          />
          <div
            style={{
              display: 'none',
              position: 'absolute',
              top: '-150%',
              left: '50%',
              transform: 'translateX(-50%)',
              zIndex: 10,
              border: '1px solid #ccc',
              backgroundColor: '#fff',
              padding: '5px',
              borderRadius: '5px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            }}
            className="hover-enlarge"
          >
            <img
              src={`${Image_URL}${row.product_details?.image?.[0]}`}
              alt="Product Enlarged"
              style={{
                width: '150px',
                height: '150px',
                objectFit: 'cover',
              }}
            />
          </div>
        </div>
      ),
    },
    {
      name: t("Name"),
      selector: row => row?.product_details?.item_name,
      sortable: true,
    },
    {
      name: t("MPV"),
      selector: row => row?.product_details?.item_mpv,
    },
    {
      name: t("Price"),
      selector: (row) => row?.product_details?.price,
      cell: (row) => {
        const price = row.product_details?.price || 0;
        const convertedPrice = (price * selectedCurrency.rate).toFixed(2);
        return (
          <span>
            {selectedCurrency.symbol} {convertedPrice}
          </span>
        );
      },
      sortable: true,
    },
    {
      name: t("Quantity"),
      selector: row => row?.total_quantity,
    },
    // {
    //   name: t("Status"),
    //   selector: (row) => {
    //     const buttonStyle = { borderColor: "#ffc107", backgroundColor: "#ffc107", color: "#000" };
    //     return (
    //       <button
    //         type="button"
    //         style={buttonStyle}
    //         className="btn btn-sm btn-hover-shine me-3"
    //         onClick={() => { handleOpenStatusDetails(row) }}
    //       >
    //         View
    //       </button>
    //     );
    //   },
    //   sortable: true,
    // },
  ];

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchIPV(page, pageLimit, filterText, filterStatus);
  };

  const handlePerRowsChange = async (newLimit, page) => {
    setPageLimit(newLimit);
  };


  const handlePageChange2 = (page) => {
    setCurrentPage(page);
    GetSPV_History(page, pageLimit, filterTextSPV, filterStatusSPV, filterDateSPV);
  };

  const ResetFiltersSPV = () => {
    setFilterDateSPV();
    setFilterLevelSPV();
    setFilterStatusSPV();
    setFilterTextSPV();
    setCurrentPage(0);
    GetSPV_History(0, pageLimit, '', '', '');
  };

  const SearchFiltersSPV = () => {
    setCurrentPage(0);
    GetSPV_History(0, pageLimit, filterTextSPV, filterStatusSPV, filterLevelSPV, filterDateSPV)
  };


  const handlePerRowsChange2 = async (newLimit, page) => {
    setPageLimit(newLimit);
  };

  const handleSearch = (searchText, status) => {
    setFilterText(searchText);
    setFilterStatus(status);
    fetchIPV(0, pageLimit, searchText, status);
  };

  const handleClear = () => {
    setFilterText('');
    setFilterStatus('');
    fetchIPV(0, pageLimit, '', '');
  };

  const renderContent = () => {
    switch (activeTab) {
      case 1:
        return (
          <CardStyled>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginBottom={4}
            >
              <StyledTypography>Member Point Value (MPV)</StyledTypography>
              <Top_Up_Button onClick={handleDrawerOpen}>Top Up</Top_Up_Button>
            </Box>
            <Divider sx={{ marginBottom: 2 }} />
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              gutterBottom
              sx={{ marginBottom: 2 }}
            >
              Transaction History
            </Typography>
            <TableContainer component={Paper} sx={{ borderRadius: '10px' }}>
              <Table>
                <TableBody>
                  {/* Header Row */}
                  <TableRow sx={{ background: '#F8F8F8' }} >
                    <StyledHeaderCell>Points</StyledHeaderCell>
                    <StyledHeaderCell>Source</StyledHeaderCell>
                    <StyledHeaderCell>Type</StyledHeaderCell>
                    <StyledHeaderCell>Date Time</StyledHeaderCell>
                    <StyledHeaderCell>Status</StyledHeaderCell>
                  </TableRow>

                  {transactions.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell style={{ color: row.is_added ? 'blue' : 'red' }}>{`${row.is_added ? '+' : '-'} ${formatNumber(row.MPV)}`}</StyledTableCell>
                      <StyledTableCell>{row.selectedSource}</StyledTableCell>
                      <StyledTableCell>{row.type}</StyledTableCell>
                      <StyledTableCell>{moment(row.date).format('DD-MM-YYYY hh:mm A')}</StyledTableCell>
                      <StyledTableCell>
                        <StyledChip
                          label={row.status === 'confirmed' ? 'Approved' : row.status === 'approved' ? 'Approved' : row.status}
                          color={row.status === "confirmed" ? "success" : row.status === "approved" ? "success" : row.status === "pending" ? 'primary' : "error"}
                          size="small"
                          style={{ cursor: row.status === "pending" ? "pointer" : "default" }}
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardStyled>
        );
      case 2:
        return (
          <div className="bg-light-gray bg-animation">
            <div className="d-flex justify-content-center align-items-center">
              <Col md="12" className="mx-auto app-login-box">
                <Card className="main-card mb-3" style={{ padding: '2%', borderRadius: '20px' }}>
                  <CardBody style={{ borderRadius: '20px', border: '1px solid #CDCDCD' }} >
                    <LoadingOverlay active={loading} spinner={<DNALoader />}>
                      <>
                        <Row className="mb-3">
                          <Row className="mb-3" style={{ alignItems: "center", marginBottom: "20px", marginTop: "10px" }}>
                            <Col md="4">
                              <h4 style={{ fontSize: "1.2rem", fontWeight: "bold", margin: 0, color: "#333" }}>
                                Products
                              </h4>
                            </Col>
                            <Col
                              md="8"
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                                gap: "15px",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "0.9rem",
                                  fontWeight: 500,
                                  color: "#666",
                                }}
                              >
                                Filter by
                              </span>
                              <select
                                style={{
                                  padding: "5px 10px",
                                  border: "1px solid #ddd",
                                  borderRadius: "5px",
                                  backgroundColor: "#f9f9f9",
                                  fontSize: "0.9rem",
                                  color: "#333",
                                }}
                                onChange={(e) => setFilterStatus(e.target.value)}
                                value={filterStatus}
                              >
                                <option value="">{t("Status")}</option>
                                <option value="rejected">{t("Rejected")}</option>
                                <option value="pending">{t("Pending")}</option>
                                <option value="approved">{t("Approved")}</option>
                              </select>
                              <select
                                style={{
                                  padding: "5px 10px",
                                  border: "1px solid #ddd",
                                  borderRadius: "5px",
                                  backgroundColor: "#f9f9f9",
                                  fontSize: "0.9rem",
                                  color: "#333",
                                }}
                                onChange={(e) => setFilterLevel(e.target.value)}
                                value={filterLevel}
                              >
                                <option value="">{t("Level")}</option>
                                <option value="1st">{t("1st")}</option>
                                <option value="2nd">{t("2nd")}</option>
                                <option value="3rd">{t("3rd")}</option>
                                <option value="4th">{t("4th")}</option>
                              </select>
                              <div
                                style={{
                                  position: "relative",
                                  width: "250px",
                                }}
                              >
                                <input
                                  type="text"
                                  placeholder={t("Type here...")}
                                  value={filterText}
                                  onChange={(e) => setFilterText(e.target.value)}
                                  style={{
                                    width: "100%",
                                    padding: "5px 40px 5px 10px",
                                    border: "1px solid #ddd",
                                    borderRadius: "5px",
                                    backgroundColor: "#f9f9f9",
                                    fontSize: "0.9rem",
                                    color: "#333",
                                  }}
                                />
                                <i
                                  className="fas fa-search"
                                  style={{
                                    position: "absolute",
                                    right: "10px",
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                    fontSize: "1rem",
                                    color: "#aaa",
                                  }}
                                ></i>
                              </div>
                            </Col>
                          </Row>
                          <Row className="mb-3" style={{ alignItems: "center", marginBottom: "20px", marginTop: "10px" }}>
                            <Col md="6">
                              <span
                                style={{
                                  fontSize: "0.9rem",
                                  fontWeight: 500,
                                  color: "#666",
                                }}
                              >
                                Total Stock MPV:
                                <span
                                  style={{
                                    fontSize: "1rem",
                                    fontWeight: 500,
                                    color: "black",
                                  }}
                                >
                                  {ALLIPV}
                                </span>

                              </span>
                              {/* <span
                                style={{
                                  marginLeft: '10px',
                                  fontSize: "0.9rem",
                                  fontWeight: 500,
                                  color: "#666",
                                }}
                              >
                                Total Amount: Pesos
                                <span
                                  style={{
                                    fontSize: "1rem",
                                    fontWeight: 500,
                                    color: "black",
                                  }}
                                >
                                  {ALLIPV}
                                </span>

                              </span> */}

                            </Col>
                          </Row>
                        </Row>
                        <DataTable
                          columns={columns}
                          data={ipv_transactions}
                          pagination
                          paginationServer
                          paginationTotalRows={totalRows}
                          onChangePage={handlePageChange}
                          onChangeRowsPerPage={handlePerRowsChange}
                          // selectableRows
                          // onSelectedRowsChange={({ selectedRows }) => setSelectedRows(selectedRows.map(row => row._id))}
                          highlightOnHover
                          pointerOnHover
                          responsive
                        />
                      </>
                    </LoadingOverlay>
                  </CardBody>
                </Card>

              </Col>
            </div>
          </div>
        );

      case 3:
        return (
          <CardStyled>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginBottom={4}
            >
              <StyledTypography>Shopping Point Value (SPV)</StyledTypography>
              <Box
                display="flex"
                justifyContent="right"
                alignItems="center"
                marginBottom={4}
              >
                <StyledButton
                // onClick={() => { navigate('/redeemProducts', { state: { points: ALLSPV, type: 'SPV' } }) }}
                >Redeem Products</StyledButton>
              </Box>
            </Box>
            <Divider sx={{ marginBottom: 2 }} />
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              gutterBottom
              sx={{ marginBottom: 2 }}
            >
              Transaction History
            </Typography>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              padding="2px"
            >

              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "16px",
                  marginBottom: "24px",
                }}
              >
                {/* Search Field */}
                <div style={formGroupStyle}>
                  <label style={labelStyle} htmlFor="search">
                    Search
                  </label>
                  <input
                    id="search"
                    type="text"
                    placeholder="Search..."
                    value={filterTextSPV}
                    onChange={(e) => setFilterTextSPV(e.target.value)}
                    style={inputStyle}
                    onFocus={(e) => {
                      e.target.style.border = focusStyle.border
                      e.target.style.boxShadow = focusStyle.boxShadow
                    }}
                    onBlur={(e) => {
                      e.target.style.border = inputStyle.border
                      e.target.style.boxShadow = inputStyle.boxShadow
                    }}
                  />
                </div>

                {/* Transaction Type Dropdown */}
                <div style={formGroupStyle}>
                  <label style={labelStyle} htmlFor="transactionType">
                    Transaction Type
                  </label>
                  <select
                    id="transactionType"
                    value={filterStatusSPV}
                    onChange={(e) => setFilterStatusSPV(e.target.value)}
                    style={selectStyle}
                    onFocus={(e) => {
                      e.target.style.border = focusStyle.border
                      e.target.style.boxShadow = focusStyle.boxShadow
                    }}
                    onBlur={(e) => {
                      e.target.style.border = inputStyle.border
                      e.target.style.boxShadow = inputStyle.boxShadow
                    }}
                  >
                    <option value="">None</option>
                    <option value="Unilevel Bonus">Unilevel Bonus</option>
                    <option value="Active Bonus">Active Bonus</option>
                    <option value="Leadership Bonus">Leadership Bonus</option>
                    <option value="Upgrade Bonus">Upgrade Bonus</option>
                    <option value="Consistency Bonus">Consistency Bonus</option>
                  </select>
                </div>

                {/* Level Dropdown */}
                <div style={formGroupStyle}>
                  <label style={labelStyle} htmlFor="level">
                    Level
                  </label>
                  <select
                    id="level"
                    value={filterLevelSPV}
                    onChange={(e) => setFilterLevelSPV(e.target.value)}
                    style={selectStyle}
                    onFocus={(e) => {
                      e.target.style.border = focusStyle.border
                      e.target.style.boxShadow = focusStyle.boxShadow
                    }}
                    onBlur={(e) => {
                      e.target.style.border = inputStyle.border
                      e.target.style.boxShadow = inputStyle.boxShadow
                    }}
                  >
                    <option value="">None</option>
                    <option value="1">1st</option>
                    <option value="2">2nd</option>
                    <option value="3">3rd</option>
                    <option value="4">4th</option>
                    <option value="5">5th</option>
                  </select>
                </div>

                {/* Date Picker */}
                <div style={formGroupStyle}>
                  <label style={labelStyle} htmlFor="date">
                    Date
                  </label>
                  <input
                    id="date"
                    type="date"
                    value={filterDateSPV}
                    onChange={(e) => setFilterDateSPV(e.target.value)}
                    style={inputStyle}
                    onFocus={(e) => {
                      e.target.style.border = focusStyle.border
                      e.target.style.boxShadow = focusStyle.boxShadow
                    }}
                    onBlur={(e) => {
                      e.target.style.border = inputStyle.border
                      e.target.style.boxShadow = inputStyle.boxShadow
                    }}
                  />
                </div>
              </div>

              {/* Buttons */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "12px",
                }}
              >
                <button
                  onClick={() => {
                    GetSPV_History(1, 10, filterTextSPV, filterStatusSPV, filterLevelSPV, filterDateSPV)
                  }}
                  style={searchButtonStyle}
                  onMouseOver={(e) => {
                    e.target.style.backgroundColor = "#4f46e5"
                    e.target.style.transform = "translateY(-1px)"
                  }}
                  onMouseOut={(e) => {
                    e.target.style.backgroundColor = "#6366f1"
                    e.target.style.transform = "translateY(0)"
                  }}
                >
                  Search
                </button>
                <button
                  onClick={ResetFiltersSPV}
                  style={resetButtonStyle}
                  onMouseOver={(e) => {
                    e.target.style.backgroundColor = "#e5e7eb"
                    e.target.style.transform = "translateY(-1px)"
                  }}
                  onMouseOut={(e) => {
                    e.target.style.backgroundColor = "#f3f4f6"
                    e.target.style.transform = "translateY(0)"
                  }}
                >
                  Reset
                </button>
              </div>
            </Box>
            <TableContainer component={Paper} sx={{ borderRadius: '10px' }}>
              <Table>
                <TableBody>
                  {/* Header Row */}
                  <TableRow sx={{ background: '#F8F8F8' }} >
                    <StyledHeaderCell>Points</StyledHeaderCell>
                    <StyledHeaderCell>Transaction Type</StyledHeaderCell>
                    <StyledHeaderCell>Level</StyledHeaderCell>
                    <StyledHeaderCell>Date Time</StyledHeaderCell>
                  </TableRow>

                  {spv_transactions.map((row, index) => {
                    if (row.type !== 'Security Bonus' && row.type !== 'Premium Acquisition Bonus') {
                      return (
                        <StyledTableRow key={index}>
                          <StyledTableCell style={{ color: row.type === 'redeem' ? 'red' : 'blue' }}>
                            {`${row.type === 'redeem' ? '-' : '+'} ${formatNumber(row.amount)}`}
                          </StyledTableCell>
                          <StyledTableCell>{row.type}</StyledTableCell>
                          <StyledTableCell>{row.level}</StyledTableCell>
                          <StyledTableCell>{moment(row.datetime_added).format('DD-MM-YYYY hh:mm A')}</StyledTableCell>
                        </StyledTableRow>
                      );
                    }
                    return null; // Ensures .map() always returns something
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={totalRows}
              page={currentPage}
              onPageChange={(event, newPage) => handlePageChange2(newPage)}
              rowsPerPage={pageLimit}
              rowsPerPageOptions={[]}
            />
          </CardStyled>
        );
      case 4:
        return (
          <CardStyled>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginBottom={4}
            >
              <StyledTypography>Distributor Point Value (DPV)</StyledTypography>
              <Box
                display="flex"
                justifyContent="right"
                alignItems="center"
                marginBottom={4}
              >
                <StyledButton
                // onClick={() => { navigate('/redeemProducts', { state: { points: ALLDPV, type: 'DPV' } }) }}
                >Redeem Products</StyledButton>
              </Box>
            </Box>
            <Divider sx={{ marginBottom: 2 }} />
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              gutterBottom
              sx={{ marginBottom: 2 }}
            >
              Transaction History
            </Typography>
            <TableContainer component={Paper} sx={{ borderRadius: '10px' }}>
              <Table>
                <TableBody>
                  {/* Header Row */}
                  <TableRow sx={{ background: '#F8F8F8' }} >
                    <StyledHeaderCell>Points</StyledHeaderCell>
                    <StyledHeaderCell>Transaction Type</StyledHeaderCell>
                    <StyledHeaderCell>Level</StyledHeaderCell>
                    <StyledHeaderCell>Date Time</StyledHeaderCell>
                    {/* <StyledHeaderCell>Status</StyledHeaderCell> */}
                  </TableRow>

                  {spv_transactions.map((row, index) => {
                    if (row.type === 'Security Bonus' || row.type === 'Premium Acquisition Bonus') {
                      return (
                        <StyledTableRow key={index}>
                          <StyledTableCell style={{ color: row.type === 'redeem' ? 'red' : 'blue' }}>
                            {`${row.type === 'redeem' ? '-' : '+'} ${formatNumber(row.amount)}`}
                          </StyledTableCell>
                          {/* <StyledTableCell>{row.selectedSource}</StyledTableCell> */}
                          <StyledTableCell>{row.type}</StyledTableCell>
                          <StyledTableCell>{row.level}</StyledTableCell>
                          <StyledTableCell>{moment(row.date).format('DD-MM-YYYY hh:mm A')}</StyledTableCell>
                          {/* <StyledTableCell>
                            <StyledChip
                              label={row.status === 'confirmed' ? 'Approved' : row.status === 'approved' ? 'Approved' : row.status}
                              color={row.status === "confirmed" ? "success" : row.status === "approved" ? "success" : row.status === "pending" ? 'primary' : "error"}
                              size="small"
                              style={{ cursor: row.status === "pending" ? "pointer" : "default" }}
                            />
                          </StyledTableCell> */}
                        </StyledTableRow>
                      );
                    }
                    return null; // Ensures .map() always returns something
                  })}

                </TableBody>
              </Table>
            </TableContainer>
          </CardStyled>
        );
      case 5:
        return (
          <CardStyled>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginBottom={4}
            >
              <StyledTypography>Distributor Point Value (DPV)</StyledTypography>
              <Box
                display="flex"
                justifyContent="right"
                alignItems="center"
                marginBottom={4}
              >
                <StyledButton onClick={handleDrawerOpen}>Redeem</StyledButton>
                <StyledButton2 onClick={handleDrawerOpen}>Donate Points</StyledButton2>
              </Box>
            </Box>
            <Divider sx={{ marginBottom: 2 }} />
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              gutterBottom
              sx={{ marginBottom: 2 }}
            >
              Transaction History
            </Typography>
            <TableContainer component={Paper} sx={{ borderRadius: '10px' }}>
              <Table>
                <TableBody>
                  {/* Header Row */}
                  <TableRow sx={{ background: '#F8F8F8' }} >
                    <StyledHeaderCell>Points</StyledHeaderCell>
                    <StyledHeaderCell>Transaction Type</StyledHeaderCell>
                    <StyledHeaderCell>Level</StyledHeaderCell>
                    <StyledHeaderCell>Date Time</StyledHeaderCell>
                    {/* <StyledHeaderCell>Status</StyledHeaderCell> */}
                  </TableRow>

                  {spv_transactions.map((row, index) => {
                    if (row.type === 'Security Bonus' || row.type === 'Premium Acquisition Bonus') {
                      return (
                        <StyledTableRow key={index}>
                          <StyledTableCell style={{ color: row.type === 'redeem' ? 'red' : 'blue' }}>
                            {`${row.type === 'redeem' ? '-' : '+'} ${formatNumber(row.amount)}`}
                          </StyledTableCell>
                          {/* <StyledTableCell>{row.selectedSource}</StyledTableCell> */}
                          <StyledTableCell>{row.type}</StyledTableCell>
                          <StyledTableCell>{row.level}</StyledTableCell>
                          <StyledTableCell>{moment(row.date).format('DD-MM-YYYY hh:mm A')}</StyledTableCell>
                          {/* <StyledTableCell>
                              <StyledChip
                                label={row.status === 'confirmed' ? 'Approved' : row.status === 'approved' ? 'Approved' : row.status}
                                color={row.status === "confirmed" ? "success" : row.status === "approved" ? "success" : row.status === "pending" ? 'primary' : "error"}
                                size="small"
                                style={{ cursor: row.status === "pending" ? "pointer" : "default" }}
                              />
                            </StyledTableCell> */}
                        </StyledTableRow>
                      );
                    }
                    return null; // Ensures .map() always returns something
                  })}

                </TableBody>
              </Table>
            </TableContainer>
          </CardStyled>
        );
      default:
        return null;
    }
  };


  useEffect(() => {
    const userString = localStorage.getItem('user');
    if (userString) {
      let user = JSON.parse(userString);
      fetchMobileStockists(user._id);
      fetchCityStockists(user._id);
    }
  }, []);
  const TabChange = async (id) => {
    setActiveTab(id)
    console.log("fetchIPV TabChange ", id)
    if (id === 2) {
      fetchIPV(currentPage, pageLimit, filterText, filterStatus, filterLevel);
    }
    if (id === 3) {
      GetSPV_History(currentPage, pageLimit, filterTextSPV, filterStatusSPV, filterLevelSPV, filterDateSPV);
    }
    if (id === 4) {
      GetSPV_History(currentPage, pageLimit, filterTextSPV, filterStatusSPV, filterLevelSPV, filterDateSPV);
    }
  };
  const fetchCityStockists = async (user_id) => {
    setLoading(true);
    try {
      var payload = {
        functionName: "GetCityStockists",
        postData: {
          user_id: user_id
        },
      };
      const response = await axios.post(B_URL + 'AddressModule/GetCityStockists', payload, { headers });
      if (response.data.status) {
        console.log("Get City Stockists", response.data.data.data);
        setCityStockists(response.data.data.data);
      } else {
        AlertService.swalErrorAlert(response.data.message || t("Error fetching addresses."));
      }
    } catch (error) {
      AlertService.swalErrorAlert(t("Error fetching addresses. Please try again."));
    } finally {
      setLoading(false);
    }
  };

  const fetchMobileStockists = async (user_id) => {
    setLoading(true);
    try {
      var payload = {
        functionName: "GetMobileStockists",
        postData: {
          user_id: user_id
        },
      };
      const response = await axios.post(B_URL + 'AddressModule/GetMobileStockists', payload, { headers });
      if (response.data.status) {
        console.log("CITIES", response.data.data.data);
        setMobileStockists(response.data.data.data);
      } else {
        AlertService.swalErrorAlert(response.data.message || t("Error fetching addresses."));
      }
    } catch (error) {
      AlertService.swalErrorAlert(t("Error fetching addresses. Please try again."));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <StatusDetailsModal
            show={showStatusModal}
            onHide={() => setShowStatusModal(false)}
            statusDetails={statusDetails}
          />

          <SidebarContainer>
            {/* Sidebar */}
            <Sidebar>
              {tabs.map((tab) => (
                <SidebarItem
                  key={tab.id}
                  active={activeTab === tab.id}
                  onClick={() => { TabChange(tab.id) }}
                >
                  <div className="info">
                    <Typography sx={{ color: 'black' }} className="title">{tab.title}</Typography>
                    <Typography sx={{ marginTop: '10px', marginBottom: '30px', color: 'black' }} className="title">{`(${tab.abb})`}</Typography>
                    <Typography sx={{ fontSize: '28px', fontWeight: 'Bold', color: 'black' }} className="value">{tab.value}
                      <span style={{ fontSize: '18px', fontWeight: '100', color: '#5A5A5A' }} className="value">.00 {tab.abb}
                      </span>
                    </Typography>
                  </div>
                  <SidebarArrow>→</SidebarArrow>
                </SidebarItem>
              ))}
            </Sidebar>
            {/* Main Content */}
            <MainContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {renderContent()}
                </Grid>
              </Grid>
            </MainContent>
          </SidebarContainer>
          <Drawer
            anchor="right"
            open={isDrawerOpen}
            onClose={handleDrawerClose}
            sx={{
              "& .MuiDrawer-paper": {
                width: "480px",
                padding: "20px",
                borderRadius: "15px 0 0 15px",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
              },
            }}
          >
            {/* Header Section */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <Typography variant="h6" fontWeight="bold">
                Top Up Wallet
              </Typography>
              <IconButton onClick={handleDrawerClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Typography
              variant="body2"
              sx={{
                fontSize: "14px",
                marginBottom: "20px",
                color: "#6B7280",
              }}
            >
              Top Up Your Wallet Balance to Enable Smooth, Fast, and Secure Transactions
              Anytime
            </Typography>
            {/* Display Current MPV and Remaining MPV */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "20px",
                padding: "10px",
                border: "1px solid #CDCDCD",
                borderRadius: "8px",
                backgroundColor: "#F9F9F9",
              }}
            >
              <Typography variant="subtitle1" fontWeight="bold" color="#000">
                Current MPV: {ALLMPV}
              </Typography>
              <Typography variant="subtitle1" fontWeight="bold" color="#000">
                Remaining MPV: {storeduser?.type ? storeduser?.type === 'City/Town Stockist' ? 2000 - ALLMPV : 500 - ALLMPV : 500 - ALLMPV}
              </Typography>
            </Box>
            {/* Radio Buttons for Source */}
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              sx={{
                marginBottom: "10px",
                fontSize: "14px",
                color: "#000",
              }}
            >
              Select Source
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              {source.map((source, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <input
                      type="radio"
                      name="source"
                      value={source}
                      checked={selectedSource === source}
                      onChange={(e) => setSelectedSource(e.target.value)}
                      style={{
                        marginRight: "8px",
                        accentColor: "#C1B314",
                        width: "14px",
                        height: "14px",
                      }}
                    />
                  }
                  label={source}
                  sx={{ fontSize: "14px", color: "#6B7280" }}
                />
              ))}
            </Box>

            {/* Show Dropdown for City/Town Stockist or Mobile Stockist */}
            {selectedSource === "City/Town Stockist" ? (
              <FormControl fullWidth sx={{ marginBottom: "20px" }}>
                <Autocomplete
                  options={CityStockists || []}
                  getOptionLabel={(option) =>
                    `${option?.username} (${option?.mobileNumber || ''})`
                  }
                  value={CityStockists.find((option) => option?._id === activeOption) || null}
                  onChange={(event, newValue) =>
                    setActiveOption(newValue ? newValue._id : '')
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={`Select ${selectedSource}`}
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": { borderRadius: "8px" },
                      }}
                    />
                  )}
                />
              </FormControl>
            ) : selectedSource === "Mobile Stockist" ? (
              <FormControl fullWidth sx={{ marginBottom: "20px" }}>
                <Autocomplete
                  options={MobileStockists || []}
                  getOptionLabel={(option) =>
                    `${option?.username} (${option?.mobileNumber || ''})`
                  }
                  value={MobileStockists.find((option) => option?._id === activeOption) || null}
                  onChange={(event, newValue) =>
                    setActiveOption(newValue ? newValue._id : '')
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={`Select ${selectedSource}`}
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": { borderRadius: "8px" },
                      }}
                    />
                  )}
                />
              </FormControl>
            ) : null}



            {/* Remaining Drawer Content */}
            <Box sx={{ mt: 4 }}>
              {/* Required MPV */}
              <Typography
                variant="subtitle1"
                fontWeight="bold"
                sx={{
                  marginBottom: "10px",
                  fontSize: "14px",
                }}
              >
                Required MPV
              </Typography>
              <TextField
                fullWidth
                type='number'
                placeholder="Enter MPV"
                min={storeduser?.type ? storeduser?.type === 'City/Town Stockist' ? 1000 : 100 : 25}
                value={MPV}
                onChange={(e) => setMPV(e.target.value)}
                sx={{
                  "& .MuiOutlinedInput-root": { borderRadius: "8px" },
                }}
              />
              {(storeduser?.type ? storeduser?.type === 'City/Town Stockist' ? MPV < 1000 : MPV < 100 : MPV < 25) && <Typography sx={{ fontSize: '13px', color: 'red' }}>MPV must be at least {`${storeduser?.type ? storeduser?.type === 'City/Town Stockist' ? 1000 : 100 : 25}`}</Typography>}
              {/* Amount Section */}
              <Typography sx={{ marginTop: "20px" }} variant="subtitle1">
                Amount
              </Typography>





              <Typography sx={{ marginLeft: '5px', fontSize: '16px', marginBottom: '15px' }} variant="subtitle1" color='#5A5A5A'>
                {selectedCurrency.symbol} {<NumberFormatter value={parseFloat(MPV * parseFloat(136 * selectedCurrency.rate).toFixed(2)).toFixed(2)} />}
              </Typography>
              {/* Discount Section */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <Typography variant="subtitle1" >
                  Discount
                </Typography>
                <Typography variant="subtitle1" color="black">
                  {selectedCurrency.symbol} <NumberFormatter value={(parseFloat(MPV * parseFloat(136 * selectedCurrency.rate).toFixed(2)).toFixed(2) * discount) / 100} />
                </Typography>
              </Box>
              <Typography sx={{ marginLeft: '5px', fontSize: '16px', marginBottom: '15px' }} variant="subtitle1" color='#5A5A5A'>
                {discount}%
              </Typography>
              {/* Payable Amount Section */}
              <Typography variant="subtitle1" >
                Payable Amount
              </Typography>
              <Typography sx={{ marginLeft: '5px', fontSize: '16px', marginBottom: '15px' }} variant="subtitle1" color='#5A5A5A'>
                {selectedCurrency.symbol} <NumberFormatter value={(parseFloat(MPV * parseFloat(136 * selectedCurrency.rate).toFixed(2)).toFixed(2)) - ((parseFloat(MPV * parseFloat(136 * selectedCurrency.rate).toFixed(2)).toFixed(2) * discount) / 100)} />
              </Typography>

              {/* Payment Proof Section */}
              <Typography
                variant="subtitle1"
                fontWeight="bold"
                sx={{
                  marginBottom: "10px",
                  fontSize: "14px",
                }}
              >
                Payment Proof
              </Typography>
              <Button
                variant="outlined"
                fullWidth
                sx={{
                  height: "60px",
                  border: "1px dashed #CDCDCD",
                  color: "#888",
                  "&:hover": { borderColor: "#C1B314" },
                  marginBottom: "20px",
                }}
                component="label"
              >
                <Typography
                  variant="body2"
                  fontWeight="bold"
                  sx={{
                    fontSize: "16px",
                    color: "#888",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  +
                  <span style={{ marginTop: "5px" }}>Upload Payment Proof</span>
                </Typography>
                <input
                  type="file"
                  hidden
                  onChange={handlePaymentProofChange}
                />
              </Button>

              {/* Display uploaded payment proof */}
              {paymentProof && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "20px",
                    border: "1px solid #CDCDCD",
                    padding: "10px",
                    borderRadius: "10px",
                    backgroundColor: "#F9F9F9",
                  }}
                >
                  <img
                    src={paymentProof}
                    alt="Payment Proof"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "200px",
                      borderRadius: "8px",
                    }}
                  />
                </Box>
              )}
              {/* Submit and Cancel Buttons */}
              <Button
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: !selectedSource || MPV <= 0 || !paymentProof ? 'gray' : "#C1B314",
                  color: "#fff",
                  "&:hover": { backgroundColor: !selectedSource || MPV <= 0 || !paymentProof ? 'gray' : "#A99E14" },
                  marginBottom: "10px",
                  borderRadius: "10px",
                  textTransform: "none",
                  fontWeight: "bold",
                }}
                onClick={handleSubmit}
                disabled={!selectedSource || MPV <= 0 || !paymentProof} >
                Submit
              </Button>
              <Button
                variant="outlined"
                fullWidth
                onClick={handleDrawerClose}
                sx={{
                  borderColor: "#CDCDCD",
                  color: "#888",
                  "&:hover": { borderColor: "#888" },
                  borderRadius: "10px",
                  textTransform: "none",
                  fontWeight: "bold",
                }}
              >
                Cancel
              </Button>
            </Box>
          </Drawer>

        </div>
      </div>
    </Fragment>

    // <>
    //   <div style={{ backgroundColor: "black", minHeight: "100vh", display: "flex", justifyContent: "center" }}>
    //     <div style={{ width: "100%", maxWidth: "1300px", backgroundColor: "white", position: "relative" }}>
    //       <TransitionGroup>
    //         <CSSTransition component="div" classNames="TabsAnimation"
    //           appear={true} timeout={0} enter={false} exit={false}>
    //           <div>
    //             <div>

    //               <div style={styles.balanceSection}>
    //                 <div style={styles.walletIcon}>
    //                   <FontAwesomeIcon icon={faWallet} />
    //                 </div>
    //                 <p>Balance</p>
    //                 <h2>{parseFloat(balance).toFixed(2)}</h2>
    //               </div>

    //               <div style={styles.transactionsSection}>
    //                 <div style={styles.topUp}>
    //                   <span>Top-up now</span>
    //                   <button style={styles.topUpButton} onClick={handleTopUpClick}>
    //                     <FontAwesomeIcon icon={faPlus} />
    //                   </button>
    //                 </div>

    //                 <h3>Transactions History</h3>
    //                 <ul style={styles.transactionsList}>
    //                   {transactions.map((transaction, index) => (
    //                     <li key={index} style={styles.transactionItem}>
    //                       <div style={styles.transactionInfo}>
    //                         <span style={styles.transactionType}>{transaction.type}</span>
    //                         <span style={styles.transactionDate}>{moment(transaction.date, 'YYYY-MM-DD HH:mm:ss').format('DD MMM, YYYY - HH:mm A')}</span>


    //                       </div>
    //                       <span style={{
    //                         ...styles.transactionAmount,
    //                         color: transaction.type === 'Topup' || 'Reward' ? 'green' : transaction.type === 'Order Cancelled' ? 'green' : 'red'
    //                       }}>
    //                         {parseFloat(transaction?.amount).toFixed(2)}
    //                         {transaction.type === 'Topup' || 'Reward' ? '↑' : transaction.type === 'Order Cancelled' ? '↑' : '↓'}
    //                       </span>
    //                     </li>
    //                   ))}
    //                 </ul>
    //               </div>
    //             </div>

    //           </div>
    //         </CSSTransition>
    //       </TransitionGroup>
    //     </div>
    //   </div>
    //   {showTopUp && <AddTopUp onClose={handleCloseTopUp} />}
    // </>
  );
};

export default CreditPage;