import React from 'react';
import { useNavigate } from 'react-router-dom';
import './UnverifiedIcon.css'; // Add the CSS to a separate file

const UnverifiedIcon = () => {
    let navigate = useNavigate();
    return (
        <div onClick={() => { navigate('/Member-profile') }} className="unverified-icon-container">
            <div className="tooltip">VERIFY YOUR ACCOUNT NOW!</div>
            {/* Cross SVG */}
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 24 24"
                fill="none"
                stroke="red"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <circle cx="12" cy="12" r="10" />
                <line x1="8" y1="8" x2="16" y2="16" />
                <line x1="16" y1="8" x2="8" y2="16" />
            </svg>
        </div>
    );
};

export default UnverifiedIcon;
