import React, { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, CardBody, CardHeader } from "reactstrap";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip,
  ResponsiveContainer, PieChart, Pie, Cell, Legend, BarChart, Bar
} from 'recharts';
import { styled } from '@mui/material/styles';
import DashboardService from "./dashboard.service";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import { format } from 'date-fns';
import generateDummyData from "./generateDummyData";
import PermissionAccess from '../../PermissionAccess/Access.js';
import BusinessService from "../Business/Businesses.service.js";
import Alerts from "../../components/Alerts/alerts";
import AuthService from "../../pages/Authentication/auth.service";
import { useNavigate, NavLink } from 'react-router-dom';

// Styled Components
const StyledCard = styled(Card)({
  borderRadius: '12px',
  boxShadow: '0 2px 12px rgba(0,0,0,0.08)',
  background: '#ffffff',
  height: '100%',
  '& .card-header': {
    background: 'transparent',
    borderBottom: '1px solid #edf2f7',
    padding: '1rem 1.5rem',
    fontWeight: 600
  }
});

const StatsNumber = styled('div')({
  fontSize: '24px',
  fontWeight: 600,
  color: '#2d3748',
  marginBottom: '4px'
});

const StatsLabel = styled('div')({
  fontSize: '14px',
  color: '#718096'
});

const Dashboard = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState(null);
  const { authData, accessPages, super_admin, current_module } = PermissionAccess();
  const [selectedBusiness, setSelectedBusiness] = useState('');
  const [businesses, setBusinesses] = useState([]);
  const navigate = useNavigate();
  const [dateRange, setDateRange] = useState([
    new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    new Date()
  ]);

  useEffect(() => {
    fetchDashboardData();
    fetchBusinesses();
  }, []);

  const fetchDashboardData = async () => {
    try {
      setLoading(true);
      console.log("response.data 125",authData.sessionID)
      const stored_user = authData.userData;
      let business = []
      if (stored_user.businesses) {
        business = stored_user.businesses
      }
      const payload = {
        "functionName": "DashboardSummary",
        "postData": {
          "date_range": `${format(dateRange[0], 'yyyy/MM/dd')} - ${format(dateRange[1] || dateRange[0], 'yyyy/MM/dd')}`,
        }
      };
      const response = await DashboardService.GetDashboardSummary(authData.sessionID, payload);
      console.log("response.data 124",response.data)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      } else {
        setDashboardData(response.data.data);
        console.log("Dashboard summary received", response.data.data);
      }
      // const dummyData = generateDummyData();
      // setDashboardData(dummyData);
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    } finally {
      setLoading(false);
    }
  };

  function fetchBusinesses() {
    BusinessService.getAllBusinesses(authData?.sessionID, 0, 9999999, selectedBusiness).then(
      (response) => {
        console.log("Get businesses response:-", response);
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        } else {
          console.log(response.data);
          setBusinesses(response.data.data);

          if (response.data.data.length === 1) {
            setSelectedBusiness(response.data.data[0]._id);
          }
        }
      },
      (error) => {
        const errContent =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        if (errContent) {
          Alerts.swalErrorAlert(errContent);
        } else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."));
        }
      }
    );
  }

  // Components for different sections
  const MemberStats = ({ data }) => (
    <StyledCard>
      <CardHeader>Member Statistics</CardHeader>
      <CardBody>
        <Row>
          <Col md="4">
            <StatsNumber>{data?.totalMembers || 0}</StatsNumber>
            <StatsLabel>Total Members</StatsLabel>
          </Col>
          <Col md="4">
            <StatsNumber>{data?.activeMembers || 0}</StatsNumber>
            <StatsLabel>Active Members</StatsLabel>
          </Col>
          <Col md="4">
            <StatsNumber>{data?.newMembers || 0}</StatsNumber>
            <StatsLabel>New Members (This Month)</StatsLabel>
          </Col>
        </Row>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={data?.memberGrowth}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="members"
              stroke="var(--buttons-color, #30b1ff)"
            />
          </LineChart>
        </ResponsiveContainer>
      </CardBody>
    </StyledCard>
  );

  const PackageStats = ({ data }) => (
    <StyledCard>
      <CardHeader>Package Statistics</CardHeader>
      <CardBody>
        <Row className="mb-4">
          <Col md="6">
            <StatsNumber>{data?.totalActivePackages || 0}</StatsNumber>
            <StatsLabel>Active Packages</StatsLabel>
          </Col>
          <Col md="6">
            <StatsNumber>${data?.totalPackageValue || 0}</StatsNumber>
            <StatsLabel>Total Package Value</StatsLabel>
          </Col>
        </Row>
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              data={data?.packageDistribution}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={100}
              fill="var(--buttons-color, #30b1ff)"
            >
              {data?.packageDistribution?.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={`var(--buttons-color-${index}, #${Math.floor(Math.random() * 16777215).toString(16)})`} />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </CardBody>
    </StyledCard>
  );

  const RankStats = ({ data }) => (
    <StyledCard>
      <CardHeader>Infini8 Rank Statistics</CardHeader>
      <CardBody>
        <Row className="mb-4">
          {data?.rankCounts?.map((rank, index) => (
            <Col key={index} md="4" className="mb-3">
              <StatsNumber>{rank.count || 0}</StatsNumber>
              <StatsLabel>{rank.name || 'No Rank'}</StatsLabel>
            </Col>
          ))}
        </Row>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={data?.rankProgressions}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="rank" />
            <YAxis />
            <Tooltip />
            <Bar
              dataKey="members"
              fill="var(--buttons-color, #30b1ff)"
            />
          </BarChart>
        </ResponsiveContainer>
      </CardBody>
    </StyledCard>
  );

  const MLMTierStats = ({ data }) => (
    <StyledCard>
      <CardHeader>Tier Statistics</CardHeader>
      <CardBody>
        <Row className="mb-4">
          <Col md="4">
            <StatsNumber>{data?.totalTiers || 0}</StatsNumber>
            <StatsLabel>Total Tiers</StatsLabel>
          </Col>
          <Col md="4">
            <StatsNumber>{data?.averageEarnings || 0}</StatsNumber>
            <StatsLabel>Avg. Earnings/Tier</StatsLabel>
          </Col>
          <Col md="4">
            <StatsNumber>{data?.topPerformers || 0}</StatsNumber>
            <StatsLabel>Top Performers</StatsLabel>
          </Col>
        </Row>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={data?.tierDistribution}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="tier" />
            <YAxis />
            <Tooltip />
            <Bar
              dataKey="members"
              fill="var(--buttons-color, #30b1ff)"
            />
          </BarChart>
        </ResponsiveContainer>
      </CardBody>
    </StyledCard>
  );

  const PaymentStats = ({ data }) => (
    <StyledCard>
      <CardHeader>Payment Statistics</CardHeader>
      <CardBody>
        <Row className="mb-4">
          <Col md="3">
            <StatsNumber>${data?.totalPayouts || 0}</StatsNumber>
            <StatsLabel>Total Payouts</StatsLabel>
          </Col>
          <Col md="3">
            <StatsNumber>${data?.pendingPayouts || 0}</StatsNumber>
            <StatsLabel>Pending Payouts</StatsLabel>
          </Col>
          <Col md="3">
            <StatsNumber>${parseFloat(data?.monthlyPayouts).toFixed(2) || 0}</StatsNumber>
            <StatsLabel>Average Payouts</StatsLabel>
          </Col>
          <Col md="3">
            <StatsNumber>{data?.successRate || 0}%</StatsNumber>
            <StatsLabel>Success Rate</StatsLabel>
          </Col>
        </Row>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={data?.payoutHistory}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Line
              type="monotone"
              dataKey="amount"
              stroke="var(--buttons-color, #30b1ff)"
            />
          </LineChart>
        </ResponsiveContainer>
      </CardBody>
    </StyledCard>
  );

  const SupportStats = ({ data }) => (
    <StyledCard>
      <CardHeader>Support Ticket Statistics</CardHeader>
      <CardBody>
        <Row className="mb-4">
          <Col md="3">
            <StatsNumber>{data?.totalTickets || 0}</StatsNumber>
            <StatsLabel>Total Tickets</StatsLabel>
          </Col>
          <Col md="3">
            <StatsNumber>{data?.openTickets || 0}</StatsNumber>
            <StatsLabel>Open Tickets</StatsLabel>
          </Col>
          <Col md="3">
            <StatsNumber>{data?.resolvedTickets || 0}</StatsNumber>
            <StatsLabel>Resolved Tickets</StatsLabel>
          </Col>
          <Col md="3">
            <StatsNumber>{data?.CancelledTickets || '0'}</StatsNumber>
            <StatsLabel>Cancelled Tickets</StatsLabel>
          </Col>
        </Row>
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              data={data?.ticketCategories}
              dataKey="value"
              nameKey="_id"
              cx="50%"
              cy="50%"
              outerRadius={100}
            >
              {data?.ticketCategories?.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={`var(--buttons-color-${index}, #${Math.floor(Math.random() * 16777215).toString(16)})`} />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </CardBody>
    </StyledCard>
  );
  const SalesPerformance = ({ data }) => (
    <StyledCard>
      <CardHeader>Sales Performance</CardHeader>
      <CardBody>
        <Row className="mb-4">
          <Col md="4">
            <StatsNumber>${data?.totalSalesVolume?.toLocaleString()}</StatsNumber>
            <StatsLabel>Total Sales Volume</StatsLabel>
          </Col>
          <Col md="4">
            <StatsNumber>${data?.averageOrderValue?.toLocaleString()}</StatsNumber>
            <StatsLabel>Average Order Value</StatsLabel>
          </Col>
          <Col md="4">
            <StatsNumber>{data?.salesGrowthRate}%</StatsNumber>
            <StatsLabel>Sales Growth Rate</StatsLabel>
          </Col>
        </Row>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={data?.salesTrend}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="sales" stroke="#30b1ff" />
          </LineChart>
        </ResponsiveContainer>
      </CardBody>
    </StyledCard>
  );

  const TopPerformers = ({ data }) => (
    <StyledCard>
      <CardHeader>Top Performers</CardHeader>
      <CardBody>
        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Rank</th>
                <th>Name</th>
                <th>Sales</th>
                <th>Family Tree</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((performer, index) => (
                <tr key={performer.id}>
                  <td>{performer.rank}</td>
                  <td>{performer.name}</td>
                  <td>${performer.sales.toLocaleString()}</td>
                  <td>{performer.recruits}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </CardBody>
    </StyledCard>
  );

  const SalesFunnel = ({ data }) => (
    <StyledCard>
      <CardHeader>Sales Funnel</CardHeader>
      <CardBody>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={data?.stages} layout="vertical">
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis type="number" />
            <YAxis dataKey="stage" type="category" />
            <Tooltip />
            <Bar dataKey="count" fill="#30b1ff" />
          </BarChart>
        </ResponsiveContainer>
        <div style={{ marginTop: "1rem" }}>
          <p><strong>Customer Acquisition Cost (CAC):</strong> ${data?.customerAcquisitionCost || 0}</p>
          <p><strong>Lead to Closure Time:</strong> {data?.leadToClosureTime || 0} days</p>
        </div>
      </CardBody>
    </StyledCard>
  );


  const CompensationAnalysis = ({ data }) => (
    <StyledCard>
      <CardHeader>Compensation Plan Analysis</CardHeader>
      <CardBody>
        <Row className="mb-4">
          {data?.planDistribution?.map((plan) => (
            <Col md="4" key={plan.plan}>
              <StatsNumber>${plan.earnings.toLocaleString()}</StatsNumber>
              <StatsLabel>{plan.plan} ({plan.members} members)</StatsLabel>
            </Col>
          ))}
        </Row>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={data?.rankProgression.progressionRates}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="rank" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="rate" fill="#30b1ff" name="Progression Rate %" />
          </BarChart>
        </ResponsiveContainer>
      </CardBody>
    </StyledCard>
  );

  const InventoryOverview = ({ data }) => (
    <StyledCard>
      <CardHeader>Inventory Overview</CardHeader>
      <CardBody>
        <Row className="mb-4">
          <Col md="3">
            <StatsNumber>{data?.totalProducts || 0}</StatsNumber>
            <StatsLabel>Total Products</StatsLabel>
          </Col>
          <Col md="3">
            <StatsNumber>{data?.lowStockItems || 0}</StatsNumber>
            <StatsLabel>Low Stock Items</StatsLabel>
          </Col>
          <Col md="3">
            <StatsNumber>{data?.outOfStockItems || 0}</StatsNumber>
            <StatsLabel>Out of Stock</StatsLabel>
          </Col>
          <Col md="3">
            <StatsNumber>${data?.inventoryValue.toLocaleString() || 0}</StatsNumber>
            <StatsLabel>Inventory Value</StatsLabel>
          </Col>
        </Row>
        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>No. #</th>
                <th>Product</th>
                <th>Sales</th>
                {/* <th>Profit</th> */}
                <th>Stock</th>
              </tr>
            </thead>
            <tbody>
              {data?.topProducts?.map((product, index) => (
                <tr key={product.id}>
                  <td>{`${index + 1}`}</td>
                  <td>{product.name}</td>
                  <td>{product?.sales?.toLocaleString()}</td>
                  {/* <td>${product.profit.toLocaleString()}</td> */}
                  <td>{product.stock}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </CardBody>
    </StyledCard>
  );

  // Add the TeamPerformance component
  const TeamPerformance = ({ data }) => (
    <StyledCard>
      <CardHeader>Team Performance Analysis</CardHeader>
      <CardBody>
        <Row className="mb-4">
          <Col md="4">
            <StatsNumber>{data?.teamSize || 0}</StatsNumber>
            <StatsLabel>Total Team Members</StatsLabel>
          </Col>
          <Col md="4">
            <StatsNumber>{data?.activeTeams || 0}</StatsNumber>
            <StatsLabel>Active Teams</StatsLabel>
          </Col>
          <Col md="4">
            <StatsNumber>{data?.avgTeamPerformance || 0}%</StatsNumber>
            <StatsLabel>Average Team Performance</StatsLabel>
          </Col>
        </Row>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={data?.teamStats}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="team" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="sales" name="Sales" fill="#30b1ff" />
            <Bar dataKey="recruits" name="Recruits" fill="#33c758" />
          </BarChart>
        </ResponsiveContainer>
      </CardBody>
    </StyledCard>
  );

  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <TransitionGroup>
            <CSSTransition
              component="div"
              classNames="TabsAnimation"
              appear={true}
              timeout={0}
              enter={false}
              exit={false}
            >
              <LoadingOverlay
                active={loading}
                spinner={<DNALoader />}
              >
                {/* Dashboard Header */}
                <div className="app-page-title">
                  <div className="page-title-wrapper">
                    <div className="page-title-heading">
                      <div className="page-title-icon">
                        <i className="pe-7s-graph icon-gradient bg-tempting-azure" />
                      </div>
                      <div>
                        {t("System Dashboard")}
                        <div className="page-title-subheading">
                          {t("Comprehensive overview of your system performance")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Sales & Financial Overview Section */}
                <Row className="mb-4">
                  <Col md="12">
                    <SalesPerformance data={dashboardData?.salesPerformance} />
                  </Col>
                </Row>

                {/* Member Statistics Section */}
                <Row className="mb-4">
                  <Col md="12">
                    <MemberStats data={dashboardData?.memberStats} />
                  </Col>
                </Row>

                {/* Top Performers & Sales Funnel */}
                <Row className="mb-4">
                  <Col md="12">
                    <TopPerformers data={dashboardData?.topPerformers} />
                  </Col>
                  {/* <Col md="6">
                    <SalesFunnel data={dashboardData?.salesFunnel} />
                  </Col> */}
                </Row>

                {/* Package & Rank Statistics */}
                <Row className="mb-4">
                  <Col md="6">
                    <PackageStats data={dashboardData?.packageStats} />
                  </Col>
                  <Col md="6">
                    <RankStats data={dashboardData?.rankStats} />
                  </Col>
                </Row>

                {/* Compensation Analysis */}
                {/* <Row className="mb-4">
                  <Col md="12">
                    <CompensationAnalysis data={dashboardData?.compensationAnalysis} />
                  </Col>
                </Row> */}

                {/* Inventory Overview */}
                {/* <Row className="mb-4">
                  <Col md="12">
                    <InventoryOverview data={dashboardData?.inventoryStats} />
                  </Col>
                </Row> */}

                {/* MLM Tier Stats */}
                {/* <Row className="mb-4">
                  <Col md="12">
                    <MLMTierStats data={dashboardData?.tierStats} />
                  </Col>
                </Row> */}

                {/* Payment Statistics */}
                {/* <Row className="mb-4">
                  <Col md="12">
                    <PaymentStats data={dashboardData?.paymentStats} />
                  </Col>
                </Row> */}

                {/* Customer Service & Support */}
                <Row className="mb-4">
                  <Col md="12">
                    <SupportStats data={dashboardData?.supportStats} />
                  </Col>
                </Row>

                {/* Team Performance Analysis */}
                {/* <Row className="mb-4">
                <Col md="12">
                  <TeamPerformance data={dashboardData?.teamPerformance} />
                </Col>
              </Row> */}

              </LoadingOverlay>
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
    </Fragment>
  );
};


export default Dashboard;

// const fetchDashboardData = async () => {
//   try {
//     setLoading(true);
// const payload = {
//   "functionName": "DashboardSummary",
//   "postData": {
//     "date_range": `${format(dateRange[0], 'yyyy/MM/dd')} - ${format(dateRange[1] || dateRange[0], 'yyyy/MM/dd')}`,
//     "business_id": selectedBusiness || "",
//     "businessIDs": business
//   }
// };

//     const response = await DashboardService.GetDashboardSummary(authData?.sessionID, payload);
//     if (response.data.message === "Unauthorized") {
//       AuthService.logout();
//       navigate("/login");
//       window.location.reload();
//     } else {
//       setSummaryData(response.data);
//     }

//   } catch (error) {
//     const errContent =
//       (error.response && error.response.data) ||
//       error.message ||
//       error.toString();
//     Alerts.swalErrorAlert(errContent);
//     setLoading(false);
//     console.error("Error fetching dashboard data:", error);
//     if (error.response && error.response.data.message === "Unauthorized") {
//       AuthService.logout();
//       navigate("/login");
//       window.location.reload();
//     }
//   } finally {
//     setLoading(false);
//   }
// };

// fetchBusinesses();
// fetchDashboardData();
// }, [authData?.sessionID, dateRange, selectedBusiness]);

