import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, Navigate } from "react-router-dom";
import { Row, Col, FormGroup, Input, Button, Label, Modal, ModalHeader, ModalBody, Card, CardBody } from "reactstrap";
import CustomerService from "./Variations.service";
import AuthService from "../Authentication/auth.service";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import Alerts from "../../components/Alerts/alerts";
import PermissionAccess from '../../PermissionAccess/Access.js';
const ImagePopup = ({ src }) => (
  <div style={{
    position: 'absolute',
    zIndex: 1000,
    border: '1px solid #ccc',
    borderRadius: '4px',
    padding: '5px',
    background: 'white',
    boxShadow: '0 2px 10px rgba(0,0,0,0.1)'
  }}>
    <img src={src} alt="Preview" style={{ maxWidth: '200px', maxHeight: '200px' }} />
  </div>
);

const DocsAddCustomer = ({ onSuccess }) => {
  const [modal, setModal] = useState(false);
  const [variations, setVariations] = useState([{ Name: '', Image: '', Status: 'Active', business_id: '' }]);
  const [loading, setLoading] = useState(false);
  const [showImagePopup, setShowImagePopup] = useState(null);
  const [loginNavigate, setLoginNavigate] = useState(false);
  const [Fetched_Businesses, setFetched_Businesses] = useState([]);
  const { authData } = PermissionAccess();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    Fetch_Businesses();
  }, []);

  useEffect(() => {
    if (Fetched_Businesses.length > 0) {
      setVariations(prevVariations => prevVariations.map(variation => ({
        ...variation,
        business_id: Fetched_Businesses[0]._id // Automatically select the first business
      })));
    }
  }, [Fetched_Businesses]);


  function Fetch_Businesses() {
    setLoading(true);
    const stored_user = authData.userData;
    let business = stored_user.businesses || [];
    CustomerService.GetBusinesses(authData.sessionID, business).then(
      (response) => {
        setLoading(false);
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        } else {
          setFetched_Businesses(response.data.data);
        }
      },
      (error) => {
        setLoading(false);
        Alerts.swalErrorAlert(t("Server Error, Please try again later."));
      }
    );
  }


  const handleImageChange = (index, event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setVariations(prevVariations => prevVariations.map((variation, i) =>
          i === index ? { ...variation, Image: reader.result} : variation
        ));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageHover = (index, isHovering) => {
    setShowImagePopup(isHovering ? index : null);
  };

  const toggle = () => {
    setModal(!modal);
    setVariations([{ Name: '', Image: '', Status: 'Active', business_id: Fetched_Businesses[0]?._id || '' }]);
  };

  const addVariationRow = () => {
    setVariations([...variations, { Name: '', Image: '', Status: 'Active', business_id: Fetched_Businesses[0]?._id || '' }]);
  };

  const removeVariationRow = (index) => {
    setVariations(variations.filter((_, i) => i !== index));
  };

  const handleVariationChange = (index, field, value) => {
    setVariations(prevVariations => prevVariations.map((variation, i) =>
      i === index ? { ...variation, [field]: value } : variation
    ));
  };

  const validateForm = () => {
    const businessCategories = {};

    for (let variation of variations) {
      if (!variation.Name || !variation.Image || !variation.business_id) {
        Alerts.swalErrorAlert("Please fill all fields for all variations.");
        return false;
      }

      const lowerCaseName = variation.Name.toLowerCase().trim();

      if (!businessCategories[variation.business_id]) {
        businessCategories[variation.business_id] = new Set();
      }

      if (businessCategories[variation.business_id].has(lowerCaseName)) {
        Alerts.swalErrorAlert(`Category "${variation.Name}" is already used for the selected business.`);
        return false;
      }

      businessCategories[variation.business_id].add(lowerCaseName);
    }

    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const payloadData = {
      functionName: "AddBulkCategory",
      postData: { categories: variations }
    };

    setLoading(true);
    CustomerService.AddBulkCategory(authData.sessionID, payloadData).then(
      (response) => {
        setLoading(false);
        if (response.data.status) {
          onSuccess();
          Alerts.swalSuccessAlert(response.data.message || "Data Added Successfully.").then(() => toggle());
        } else {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            setLoginNavigate(true);
          } else {
            Alerts.swalErrorAlert(response.data.message || "Error: Can't Add Category at the moment.");
          }
        }
      },
      (error) => {
        setLoading(false);
        Alerts.swalErrorAlert("Network Operation failed.");
      }
    );
  };


  if (loginNavigate) {
    return <Navigate to={'/login'} />;
  }

  return (
    <span className="d-inline-block float-end mb-2 me-2">
      <Button color="success" onClick={toggle} className="btn-hover-shine ms-1">
        {t("Add New")}
      </Button>
      <Modal size='lg' isOpen={modal} toggle={toggle} backdrop={true}>
        <LoadingOverlay active={loading} spinner={<DNALoader />}>
          <ModalHeader>{t("Add Category")}</ModalHeader>
          <ModalBody>
            <Card className="main-card mb-3">
              <CardBody>
                <form onSubmit={handleSubmit}>
                  {variations.map((variation, index) => (
                    <Row key={index} className="mb-3">
                      <Col md="5">
                        <FormGroup>
                          <Label>{t("Category Name")}</Label>
                          <Input
                            type="text"
                            value={variation.Name}
                            onChange={(e) => handleVariationChange(index, 'Name', e.target.value)}
                            placeholder="Enter Category Name"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="5">
                        <FormGroup>
                          <Label
                            onMouseEnter={() => handleImageHover(index, true)}
                            onMouseLeave={() => handleImageHover(index, false)}
                            style={{ position: 'relative', cursor: 'pointer' }}
                          >
                            {t("Image")}
                            {showImagePopup === index && variation.Image && (
                              <ImagePopup src={variation.Image} />
                            )}
                          </Label>
                          <Input
                            type="file"
                            onChange={(e) => handleImageChange(index, e)}
                            accept="image/*"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="2">
                        <Row>
                          <div>
                            {variations.length > 1 && (
                              <i style={{ cursor: 'pointer', marginTop: '38px' }} onClick={() => removeVariationRow(index)} className="pe-7s-trash text-danger btn-icon-wrapper fs-5"> </i>
                            )}
                            <i style={{ cursor: 'pointer', marginLeft: '3px', marginTop: '38px' }} onClick={addVariationRow} className="pe-7s-plus text-danger btn-icon-wrapper fs-5"> </i>
                          </div>
                        </Row>
                      </Col>
                    </Row>
                  ))}
                  <div className="text-center">
                    <Button type="submit" color="success" size="lg" className="me-3">
                      {t("Add")}
                    </Button>
                    <Button type="button" color="success" outline size="lg" onClick={toggle}>
                      {t("Cancel")}
                    </Button>
                  </div>
                </form>
              </CardBody>
            </Card>
          </ModalBody>
        </LoadingOverlay>
      </Modal>
    </span>
  );
};

export default DocsAddCustomer;