import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import styled from "styled-components";
import BusinessService from "../../pages/Business/Businesses.service";
import AuthService from "../../pages/Authentication/auth.service";
import Alerts from "../../components/Alerts/alerts";
import { useNavigate } from 'react-router-dom';
import PermissionAccess from '../../PermissionAccess/Access.js';

const Input = styled.input.attrs(props => ({
  type: "text",
  size: props.small ? 5 : undefined
}))`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid var(--buttons-color, #30b1ff);
  padding: 0 32px 0 16px;
`;

const Select = styled.select`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid var(--buttons-color, #30b1ff);
  margin-left: 10px;
  padding: 0 16px;
`;

const Button = styled.button`
  margin-left: 2%;
  type: button;
  class: btn btn-custom-color btn-hover-shine me-3 btn;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const OrdersFilter = ({ onClear, onSearch }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [searchText, setSearchText] = useState("");
  const [status, setStatus] = useState("");
  const [filterNanoOnly, setFilterNanoOnly] = useState(false);
  const { authData, accessPages, super_admin, current_module } = PermissionAccess();

  const handleClear = () => {
    setSearchText('');
    setStatus('');
    setFilterNanoOnly(false);
    onClear();
  };

  const handleSearch = () => {
    onSearch(searchText, status, filterNanoOnly);
  };

  return (
    <Container>
      <Input
        type="text"
        placeholder={t('search text')}
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
      />
      <Select
        value={status}
        onChange={(e) => setStatus(e.target.value)}
      >
        <option value="">{t('Select Status')}</option>
        <option value="confirmed">{t('Confirmed')}</option>
        <option value="processing">{t('Processing')}</option>
        <option value="delivered">{t('Delivered')}</option>
        <option value="in_transit">{t('In Transit')}</option>
        <option value="cancelled">{t('Cancelled')}</option>
        <option value="pending">{t('Pending')}</option>
      </Select>

      <Select
        value={filterNanoOnly}
        onChange={(e) => setFilterNanoOnly(e.target.value)}
      >
        <option value="">{t('Select Source')}</option>
        <option value={'Nano ProTech'}>{t('Nano ProTech')}</option>
        <option value={'City/Town Stockist'}>{t('City/Town Stockist')}</option>
        <option value={'Mobile Stockist'}>{t('Mobile Stockist')}</option>
      </Select>

      <Button
        className="btn btn-custom-color btn-hover-shine me-3 btn"
        onClick={handleSearch}
      >
        {t('Search')}
      </Button>
      <Button
        className="btn btn-custom-color btn-hover-shine me-3 btn"
        onClick={handleClear}
      >
        {t('Reset')}
      </Button>
    </Container>
  );
};

export default OrdersFilter;