import React, { useEffect, useState, Fragment } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import DataTable from 'react-data-table-component';
import { Row, Col, Card, CardBody, Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import AuthService from "../Authentication/auth.service";
import { useTranslation } from 'react-i18next';
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";
import Alerts from "../../components/Alerts/alerts";
import Services from "./services";
import AddMember from "./AddMember";
import UpdateMember from "./UpdateMember";
import MemberFilter from "../../components/Table Filters/MemberFilter.js";
import styled from 'styled-components';
import PermissionAccess from '../../PermissionAccess/Access.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faEye, faKey, faUser, faStar } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@mui/material/Tooltip';
import { Chip } from '@mui/material';
import MemberApprovalPopup from './MemberApprovalPopup';
import moment from 'moment';

const StyledChip = styled(Chip)`
  font-size: 12px;
  height: 24px;
  font-weight: 500;
`;


const MemberListing = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [Customers, setCustomers] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [filterLevel, setFilterLevel] = useState(1);
  const [filterStatus, setFilterStatus] = useState('');
  const userString = localStorage.getItem('user');
  const authData = JSON.parse(userString);
  const { accessPages, super_admin, current_module } = PermissionAccess();
  const [MemberToEdit, setMemberToEdit] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [popupMember, setPopupMember] = useState(null);

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchMembers(currentPage, pageLimit, filterText, filterStatus, filterLevel);
  }, [pageLimit, i18n, filterStatus, filterLevel, filterText]);

  function fetchMembers(page_index, page_limit, filterText, filterStatus, filterLevel) {
    var offset = (page_index - 1) * page_limit;
    if (offset < 1) {
      offset = 0;
    }
    const stored_user = authData;
    let business = []
    if (stored_user?.businesses) {
      business = stored_user?.businesses
    }

    var getData = {
      functionName: "GetSponsorMembers",
      "postData": {
        "offset": offset,
        "user_id": authData._id,
        "filterStatus": filterStatus,
        "filterText": filterText,
        "filterLevel": filterLevel,
        "sort": {
          "_id": -1
        },
        "limit": page_limit,
        business_id: business
      }
    }
    setLoading(true);
    Services.GetSponsorMembers(getData).then((response) => {
      setLoading(false);
      console.log("Get Member response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setCustomers(response.data.data);
        console.log("Responsse", response.data);
        setTotalRows(response.data.count);
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };
  const handleSuccess = () => {
    fetchMembers(currentPage, pageLimit, filterText, filterStatus);
  };

  const confirmDelete = (id) => {
    Alerts.swalConfirmDeleteAlert(t("Once deleted, you will not be able to recover this Record!"))
      .then(res => {
        if (res) {
          deleteMember(id);
        }
      });
  };

  const handleActivate = (member) => {
    setPopupMember(member); // Open popup with member details
  };

  const handleApprove = () => {
    setLoading(true);
    // Approve activation API call
    Services.updateMemberStatus(popupMember._id, true).then((response) => {
      setLoading(false);
      if (response.data.status === true) {
        Alerts.swalSuccessAlert("Member activated successfully!");
        setPopupMember(null);
        fetchMembers(currentPage, pageLimit, filterStatus);
      } else {
        Alerts.swalErrorAlert(response.data.message);
        setPopupMember(null);
        // fetchMembers(currentPage, pageLimit, filterStatus);  
      }
    });
  };

  const handleReject = () => {
    Alerts.swalConfirmGeneralAlert("Are you sure you want to reject this activation?")
      .then((res) => {
        if (res) {
          setLoading(true);
          Services.updateMemberStatus(popupMember._id, false).then(() => {
            setLoading(false);
            Alerts.swalSuccessAlert("Activation rejected!");
            setPopupMember(null);
            fetchMembers(currentPage, pageLimit, filterStatus);
          });
        }
      });
  };




  const deleteMember = (id) => {
    setLoading(true);
    Services.DeleteMember(authData.sessionID, id)
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          Alerts.swalSuccessAlert(response.data.message || t("Data Deleted Successfully."));
          fetchMembers(currentPage, pageLimit, filterText, filterStatus);
        } else {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
          } else {
            Alerts.swalErrorAlert(response.data.message || t("Error: Can't delete at the moment."));
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        Alerts.swalErrorAlert(t("Delete operation failed"));
      });
  };

  const handleDeleteSelected = () => {
    if (selectedRows?.length > 0) {
      Alerts.swalConfirmDeleteAlert(t(selectedRows?.length + " records selected. Once deleted, you will not be able to recover this!"))
        .then(res => {
          if (res) {
            deleteBulkCustomers(selectedRows);
          }
        });
    } else {
      Alerts.swalErrorAlert('No records selected');
    }
  };

  const deleteBulkCustomers = (ids) => {
    setLoading(true);
    Services.DeleteBulkMember(authData.sessionID, ids)
      .then((response) => {
        setLoading(false);
        setSelectedRows([]);
        if (response.data.status) {
          Alerts.swalSuccessAlert(response.data.message || t("Data Deleted Successfully."));
          fetchMembers(currentPage, pageLimit, filterText, filterStatus);
        } else {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
          } else {
            Alerts.swalErrorAlert(response.data.message || t("Error: Can't delete the Collection Data at the moment."));
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        Alerts.swalErrorAlert(t("Delete operation failed"));
      });
  };
  const handleSelectAllChange = (e) => {
    if (e.target.checked) {
      setSelectedRows(Customers.map(Member => Member._id));
    } else {
      setSelectedRows([]);
    }
  };

  function regeneratePassword(_id) {
    let msg = t("Are you sure you want to regenerate password for this user?")
    Alerts.swalConfirmGeneralAlert(msg).then(res => {
      console.log("Resp:-", res)
      if (res === true) {
        sendPassword(_id)
      }
    })
  };

  function sendPassword(id) {
    setLoading(true);
    Services.sendPassword(authData.sessionID, id).then((response) => {
      console.log("Send Password response:-", response)
      setLoading(false);
      if (response.data.status) {
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Password emailed Successfully."))
        }
        console.log(response.data.message)
      }
      else {
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't send the Password."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Operation failed"))
      })
  };


  // const columns = [
  //   {
  //     name: t("Username"),
  //     selector: row => row.username,
  //     sortable: true,
  //     cell: row => (
  //       <div className="d-flex align-items-center">
  //         <FontAwesomeIcon icon={faUser} className="text-primary me-2" />
  //         {row.username}
  //       </div>
  //     )
  //   },
  //   // {
  //   //   name: t("Full Name"),
  //   //   selector: row => row.fullName,
  //   //   sortable: true,
  //   // },
  //   // {
  //   //   name: t("Mobile Number"),
  //   //   selector: row => row.mobileNumber,
  //   //   cell: row => (
  //   //     <div>
  //   //       {row.mobileNumber || "-"}
  //   //     </div>
  //   //   )
  //   // },
  //   {
  //     name: t("Email Address"),
  //     selector: row => row.email,
  //     cell: row => (
  //       <div style={{
  //         maxWidth: '200px',
  //         whiteSpace: 'nowrap',
  //         overflow: 'hidden',
  //         textOverflow: 'ellipsis'
  //       }}>
  //         {row.email}
  //       </div>
  //     )
  //   },
  //   {
  //     name: t("Sponsor"),
  //     selector: row => row.sponsor?.username,
  //     cell: row => (
  //       <div>
  //         {row.sponsor?.username || "-"}
  //       </div>
  //     )
  //   },
  //   {
  //     name: t("Package"),
  //     selector: row => row?.package_name,
  //     cell: row => (
  //       <StyledChip
  //         label={row?.package_name || "No Package"}
  //         color="primary"
  //         variant="outlined"
  //         size="small"
  //       />
  //     )
  //   },
  //   {
  //     name: t("Rank"),
  //     selector: row => row.rank?.name,
  //     cell: row => (
  //       <div className="d-flex align-items-center">
  //         <FontAwesomeIcon icon={faStar} className="text-warning me-1" />
  //         {row.rank?.name || "No Rank"}
  //       </div>
  //     )
  //   },
  //   {
  //     name: t("Type"),
  //     selector: row => row.type,
  //     cell: row => (
  //       <StyledChip
  //         label={row.type}
  //         color={row.type === 'Customer' ? 'default' : 'secondary'}
  //         size="small"
  //       />
  //     )
  //   },
  //   {
  //     name: t('Status'),
  //     width: '120px',
  //     cell: (row) => (
  //       <StyledChip
  //         label={row.status}
  //         color={row.status === 'Active' ? 'success' : 'error'}
  //         size="small"
  //       />
  //     )
  //   },
  //   {
  //     name: t("Actions"),
  //     width: '150px',
  //     selector: (row) => {
  //       return (
  //         <div>
  //           <button className="mt-2 mb-2 me-2 btn-icon btn">
  //             {(super_admin || current_module?.ViewData === 1) && (
  //               <Tooltip title={t("View Details")}>
  //                 <Link to="/Member-details" state={row}>
  //                   <i className="pe-7s-note2 btn-icon-wrapper text-info"> </i>
  //                 </Link>
  //               </Tooltip>
  //             )}
  //             {(super_admin || current_module?.EditData === 1) && (
  //               <Tooltip title={t("Edit")}>
  //                 <i onClick={() => setMemberToEdit(row)} className="pe-7s-pen btn-icon-wrapper text-primary"> </i>
  //               </Tooltip>
  //             )}
  //             {(super_admin || current_module?.DeleteData === 1) && (
  //               <Tooltip title={t("Delete")}>
  //                 <i onClick={() => confirmDelete(row._id)} className="pe-7s-trash text-danger btn-icon-wrapper"> </i>
  //               </Tooltip>
  //             )}
  //             {!super_admin ? current_module?.RegeneratePassword === 1 &&
  //               <Tooltip placement="top" title={t("Regenerate Password")} arrow>
  //                 <a onClick={() => regeneratePassword(row._id)} style={{ cursor: "pointer" }} className="text-warning btn-sm mt-4">
  //                   <FontAwesomeIcon icon={["fas", "key"]} />
  //                 </a>
  //               </Tooltip>
  //               :
  //               <Tooltip placement="top" title={t("Regenerate Password")} arrow>
  //                 <a onClick={() => regeneratePassword(row._id)} style={{ cursor: "pointer" }} className="text-warning btn-sm mt-4">
  //                   <FontAwesomeIcon icon={["fas", "key"]} />
  //                 </a>
  //               </Tooltip>
  //             }

  //           </button>
  //         </div>
  //       );
  //     }
  //   }
  // ]
  const columns = [
    {
      name: t("Name"),
      selector: row => row.fullName,
      sortable: true,
    },
    {
      name: t("Username"),
      selector: row => row.username,
      sortable: true,
    },
    {
      name: t("Account Type"),
      selector: row => row.type ? row.type : row?.user_type || '---',
      sortable: true,
    },
    {
      name: t("Current Rank"),
      selector: row => row.rank ? row.rank : 'ASPIRING Starter Rank',
      sortable: true,
    },
    {
      name: t("Date Joined"),
      selector: row => moment(row.datetime_added).format('DD-MM-YYYY hh:mm A'),
      sortable: true,
    },
    {
      name: t("Sponsor"),
      selector: row => row.sponsor_name,
    },
    {
      name: t("Level"),
      selector: row => filterLevel,
    },
    {
      name: t("Status"),
      cell: (row) => (
        <StyledChip
          label={row.status}
          color={row.status === "Active" ? "success" : "error"}
          size="small"
          onClick={() => handleActivate(row)}
          style={{ cursor: row.status === "Inactive" ? "pointer" : "default" }}
        />
      ),
    },

    // {
    //   name: t("Status"),
    //   cell: row => (
    //     <StyledChip
    //       label={row.status}
    //       color={
    //         row.status === "Active"
    //           ? "success"
    //           : row.status === "Terminated"
    //             ? "error"
    //             : row.status === "Suspended"
    //               ? "warning"
    //               : "default"
    //       }
    //       size="small"
    //     />
    //   ),
    // },
  ];

  const handleCheckboxChange = (e, row) => {
    if (e.target.checked) {
      setSelectedRows([...selectedRows, row._id]);
    } else {
      setSelectedRows(selectedRows.filter(id => id !== row._id));
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchMembers(page, pageLimit, filterText, filterStatus);
  };

  const handlePerRowsChange = async (newLimit, page) => {
    setPageLimit(newLimit);
  };

  const handleSearch = (searchText, status) => {
    setFilterText(searchText);
    setFilterStatus(status);
    fetchMembers(0, pageLimit, searchText, status);
  };

  const handleClear = () => {
    setFilterText('');
    setFilterStatus('');
    fetchMembers(0, pageLimit, '', '');
  };

  const subHeaderComponentMemo = React.useMemo(() => (
    <MemberFilter
      onSearch={handleSearch}
      onClear={handleClear}
      filterText={filterText}
      filterStatus={filterStatus}
    />
  ), [filterText, filterStatus]);

  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className="bg-light-gray bg-animation">
            <div className="d-flex justify-content-center align-items-center">
              <Col md="12" className="mx-auto app-login-box">
                <Card className="main-card mb-3" style={{ padding: '2%', borderRadius: '20px' }}>
                  <CardBody style={{ borderRadius: '20px', border: '1px solid #CDCDCD' }} >
                    <LoadingOverlay active={loading} spinner={<DNALoader />}>
                      <>
                        <Row className="mb-3">
                          <Row className="mb-3" style={{ alignItems: "center", marginBottom: "20px", marginTop: "10px" }}>
                            <Col md="6">
                              <h4 style={{ fontSize: "1.2rem", fontWeight: "bold", margin: 0, color: "#333" }}>
                                Members
                              </h4>
                            </Col>
                            <Col
                              md="6"
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                                gap: "15px",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "1rem",
                                  fontWeight: 500,
                                  color: "#666",
                                }}
                              >
                                Filter by
                              </span>
                              <select
                                style={{
                                  padding: "5px 10px",
                                  border: "1px solid #ddd",
                                  borderRadius: "5px",
                                  backgroundColor: "#f9f9f9",
                                  fontSize: "0.9rem",
                                  color: "#333",
                                }}
                                onChange={(e) => setFilterStatus(e.target.value)}
                                value={filterStatus}
                              >
                                <option value="">{t("Status")}</option>
                                <option value="Active">{t("Active")}</option>
                                <option value="Inactive">{t("Inactive")}</option>
                                <option value="Terminated">{t("Terminated")}</option>
                              </select>
                              <select
                                style={{
                                  padding: "5px 10px",
                                  border: "1px solid #ddd",
                                  borderRadius: "5px",
                                  backgroundColor: "#f9f9f9",
                                  fontSize: "0.9rem",
                                  color: "#333",
                                }}
                                onChange={(e) => setFilterLevel(e.target.value)}
                                value={filterText}
                              >
                                <option value="">{t("Level")}</option>
                                <option value="1">{t("1st")}</option>
                                <option value="2">{t("2nd")}</option>
                              </select>
                              <div
                                style={{
                                  position: "relative",
                                  width: "250px",
                                }}
                              >
                                <input
                                  type="text"
                                  placeholder={t("Type here...")}
                                  value={filterText}
                                  onChange={(e) => setFilterText(e.target.value)}
                                  style={{
                                    width: "100%",
                                    padding: "5px 40px 5px 10px",
                                    border: "1px solid #ddd",
                                    borderRadius: "5px",
                                    backgroundColor: "#f9f9f9",
                                    fontSize: "0.9rem",
                                    color: "#333",
                                  }}
                                />
                                <i
                                  className="fas fa-search"
                                  style={{
                                    position: "absolute",
                                    right: "10px",
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                    fontSize: "1rem",
                                    color: "#aaa",
                                  }}
                                ></i>
                              </div>
                            </Col>
                          </Row>

                          <Col md="6" className="text-end">
                            {(super_admin || current_module?.BulkDeleteData === 1) && (
                              <Button color="danger" onClick={handleDeleteSelected} className="me-2">
                                {t('Delete Selected')}
                              </Button>
                            )}
                            {(super_admin || current_module?.AddData === 1) && (
                              <AddMember onSuccess={handleSuccess} />
                            )}
                          </Col>
                        </Row>
                        <DataTable
                          columns={columns}
                          data={Customers}
                          pagination
                          paginationServer
                          paginationTotalRows={totalRows}
                          onChangePage={handlePageChange}
                          onChangeRowsPerPage={handlePerRowsChange}
                          // selectableRows
                          // onSelectedRowsChange={({ selectedRows }) => setSelectedRows(selectedRows.map(row => row._id))}
                          highlightOnHover
                          pointerOnHover
                          responsive
                        />
                        {/* <DataTable
                          columns={columns}
                          data={Customers}
                          pagination
                          paginationServer
                          paginationTotalRows={totalRows}
                          onChangePage={(page) => {
                            setCurrentPage(page);
                            fetchMembers(page, pageLimit, filterStatus);
                          }}
                          onChangeRowsPerPage={(limit) => {
                            setPageLimit(limit);
                            fetchMembers(currentPage, limit, filterStatus);
                          }}
                        /> */}

                      </>
                    </LoadingOverlay>
                  </CardBody>
                </Card>

              </Col>
            </div>
          </div>
        </div>

      </div>
      {MemberToEdit && (
        <UpdateMember
          MEMBER={MemberToEdit}
          onSuccess={() => {
            setMemberToEdit(null);
            handleSuccess();
          }}
          onCancel={() => setMemberToEdit(null)}
        />
      )}
      {/* Popup for Member Activation */}
      <MemberApprovalPopup
        member={popupMember} // Pass selected member details
        onClose={() => setPopupMember(null)} // Close popup
        onApprove={handleApprove} // Approve function
        onReject={handleReject} // Reject function
      />
    </Fragment>
  );
};

export default MemberListing;