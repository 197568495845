import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { Col, Row, Button, Label, FormGroup, Input, FormText } from "reactstrap";
import { Form, Field } from "react-final-form";
import { Modal, ModalHeader, ModalBody } from 'reactstrap'; // Import Modal
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import AuthService from "./auth.service";
import AddressService from "./auth.service";
import AlertService from "../../components/Alerts/alerts";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faTimes, faFilePdf, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import loginLogo from '../../assets/loginLogo.svg';
import Swal from 'sweetalert2';
import Select from 'react-select';
import { Container, Typography, useMediaQuery, useTheme } from "@mui/material";
import './register.css';

const Register = () => {
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState(localStorage.getItem("system_logo"));
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const params = useParams();
  const location = useLocation();
  const theme = useTheme();
  const usertype = location.pathname.split('/')[2];
  const [isCheckingUsername, setIsCheckingUsername] = useState(false);
  const [isUsernameAvailable, setIsUsernameAvailable] = useState(true);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
  const [username, setUsername] = useState('');

  // Toggle modal function
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const [referralId, setReferralId] = useState(null);
  const [sponsorUsername, setSponsorUsername] = useState(null);
  const [sponsorName, setSponsorName] = useState(null);
  const [sponsorContact, setSponsorContact] = useState(null);

  // Address state variables
  const [countries, setCountries] = useState([]);
  const [regions, setRegions] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [cities, setCities] = useState([]);
  const [barangays, setBarangays] = useState([]);
  const [zipCodes, setZipCodes] = useState([]);

  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedRegion, setSelectedRegion] = useState('');
  const [selectedProvince, setSelectedProvince] = useState('');
  const [selectedDistrict, setSelectedDistrict] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedBarangay, setSelectedBarangay] = useState('');
  const [selectedZipCode, setSelectedZipCode] = useState('');
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  useEffect(() => {
    getSetLanguage();
    fetchCountries();
    extractReferralParams();
  }, []);

  const fetchCountries = async () => {
    try {
      const response = await AddressService.getCountries();
      console.log("Countries", response.data.data.data);
      setCountries(response.data.data.data);
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  // Fetch regions when country changes
  useEffect(() => {
    const fetchRegions = async () => {
      if (selectedCountry) {
        try {
          const response = await AddressService.getRegions(selectedCountry);
          setRegions(response.data.data.data);
          // Reset dependent fields
          setSelectedRegion('');
          setSelectedProvince('');
          setSelectedDistrict('');
          setSelectedCity('');
          setSelectedBarangay('');
          setSelectedZipCode('');
        } catch (error) {
          console.error('Error fetching regions:', error);
        }
      }
    };
    fetchRegions();
  }, [selectedCountry]);

  // Fetch provinces when region changes
  useEffect(() => {
    const fetchProvinces = async () => {
      if (selectedRegion) {
        try {
          const response = await AddressService.getProvinces(selectedRegion);
          setProvinces(response.data.data.data);
          // Reset dependent fields
          setSelectedProvince('');
          setSelectedDistrict('');
          setSelectedCity('');
          setSelectedBarangay('');
          setSelectedZipCode('');
        } catch (error) {
          console.error('Error fetching provinces:', error);
        }
      }
    };
    fetchProvinces();
  }, [selectedRegion]);

  // Fetch districts when province changes
  useEffect(() => {
    const fetchDistricts = async () => {
      if (selectedProvince) {
        try {
          const response = await AddressService.getDistricts(selectedProvince);
          setDistricts(response.data.data.data);
          // Reset dependent fields
          setSelectedDistrict('');
          setSelectedCity('');
          setSelectedBarangay('');
          setSelectedZipCode('');
        } catch (error) {
          console.error('Error fetching districts:', error);
        }
      }
    };
    fetchDistricts();
  }, [selectedProvince]);

  // Fetch cities when district changes
  useEffect(() => {
    const fetchCities = async () => {
      if (selectedDistrict) {
        try {
          const response = await AddressService.getCities(selectedDistrict);
          setCities(response.data.data.data);
          // Reset dependent fields
          setSelectedCity('');
          setSelectedBarangay('');
          setSelectedZipCode('');
        } catch (error) {
          console.error('Error fetching cities:', error);
        }
      }
    };
    fetchCities();
  }, [selectedDistrict]);

  // Fetch barangays when city changes
  useEffect(() => {
    const fetchBarangays = async () => {
      if (selectedCity) {
        try {
          const response = await AddressService.getBarangays(selectedCity);
          setBarangays(response.data.data.data);
          // Reset dependent fields
          setSelectedBarangay('');
          setSelectedZipCode('');
        } catch (error) {
          console.error('Error fetching barangays:', error);
        }
      }
    };
    fetchBarangays();
  }, [selectedCity]);

  // Fetch zip codes when barangay changes
  useEffect(() => {
    const fetchZipCodes = async () => {
      if (selectedBarangay) {
        try {
          const response = await AddressService.getZipCodes(selectedBarangay);
          setZipCodes(response.data.data.data);
          setSelectedZipCode('');
        } catch (error) {
          console.error('Error fetching zip codes:', error);
        }
      }
    };
    fetchZipCodes();
  }, [selectedBarangay]);

  const extractReferralParams = () => {
    const queryParams = new URLSearchParams(location.search);
    const ref = queryParams.get('ref');
    const sponsor = queryParams.get('sponsor');
    const contact = queryParams.get('contact');
    const name = queryParams.get('name');
    if (ref && sponsor && contact) {
      setReferralId(ref);
      setSponsorUsername(sponsor);
      setSponsorContact(contact);
      setSponsorName(name);
      localStorage.setItem('referralId', ref);
      localStorage.setItem('sponsorUsername', sponsor);
      localStorage.setItem('sponsorName', name);
      localStorage.setItem('sponsorContact', contact);
    }
  };

  const checkStoredReferralData = () => {
    const storedReferralId = localStorage.getItem('referralId');
    const storedSponsorUsername = localStorage.getItem('sponsorUsername');
    const storedSponsorName = localStorage.getItem('sponsorName');
    const storedSponsorContact = localStorage.getItem('sponsorContact');

    if (storedReferralId && storedSponsorUsername && storedSponsorName && storedSponsorContact) {
      setReferralId(storedReferralId);
      setSponsorUsername(storedSponsorUsername);
      setSponsorName(storedSponsorName);
      setSponsorContact(storedSponsorContact);
      return true;
    }
    return false;
  };

  useEffect(() => {
    checkStoredReferralData();
  }, []);

  const checkUsernameAvailability = async (username) => {
    if (!username || username.length < 3) return;

    setIsCheckingUsername(true);
    try {
      let payload = {
        username: username
      }
      const response = await AuthService.checkUsername(payload);
      setIsUsernameAvailable(response.data.available);
    } catch (error) {
      console.error('Username check failed:', error);
    } finally {
      setIsCheckingUsername(false);
    }
  };

  const validateUsername = (username) => {
    if (!username) return false;
    const regex = /^[a-zA-Z0-9]+$/;
    return regex.test(username) && !username.includes(' ');
  };

  const validatePassword = (password) => {
    const minLength = 6;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    return (
      password.length >= minLength &&
      hasUpperCase &&
      hasLowerCase &&
      hasNumbers &&
      hasSpecialChar
    );
  };

  function getSetLanguage() {
    const language = localStorage.getItem("language") || 'en';
    i18n.changeLanguage(language);
  }

  const validateForm = (values) => {
    const errors = {};

    if (!values.username) {
      errors.username = t('Username is required');
    } else if (!validateUsername(values.username)) {
      errors.username = t('Username must contain only alphanumeric characters and no spaces');
    } else if (!isUsernameAvailable) {
      errors.username = t('Username is already taken');
    }

    if (!values.fullName) {
      errors.fullName = t('Full Name is required');
    } else if (values.fullName.length < 2) {
      errors.fullName = t('Full Name must be at least 2 characters long');
    }

    if (!values.fullAddress) {
      errors.fullAddress = t('Full Address is required');
    }

    if (!selectedCountry) errors.country = t('Country is required');
    if (!selectedRegion) errors.region = t('Region is required');
    if (!selectedProvince) errors.province = t('Province is required');
    if (!selectedDistrict) errors.district = t('District is required');
    if (!selectedCity) errors.city = t('City is required');
    if (!selectedBarangay) errors.barangay = t('Barangay is required');
    if (!selectedZipCode) errors.zipCode = t('Zip Code is required');

    if (!values.mobileNumber) {
      errors.mobileNumber = t('Mobile Number is required');
    } else if (!/^\+?[\d\s-]{8,}$/.test(values.mobileNumber)) {
      errors.mobileNumber = t('Invalid phone number format');
    }

    if (!values.email) {
      errors.email = t('Email is required');
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = t('Invalid email address format');
    }

    if (!values.password) {
      errors.password = t('Password is required');
    } else if (!validatePassword(values.password)) {
      errors.password = t('Password must be at least 6 characters and include uppercase, lowercase, number, and special character');
    }

    if (!values.confirmPassword) {
      errors.confirmPassword = t('Confirm Password is required');
    } else if (values.password !== values.confirmPassword) {
      errors.confirmPassword = t('Passwords do not match');
    }

    return errors;
  };

  const onSubmit = async (values) => {
    if (referralId === undefined || referralId === null || referralId === '') {
      alert(t('You must be referred by a already member in System.'));
      return;
    } else if (!isChecked) {
      alert(t('Please agree to the Terms and Conditions to proceed.'));
      return;
    } else {

      setLoading(true);
      const formData = {};

      const address = {
        barangay: barangays.find(b => b._id === selectedBarangay)?.barangay || '',
        district: districts.find(d => d._id === selectedDistrict)?.district || '',
        city: cities.find(c => c._id === selectedCity)?.['city/town'] || '',
        zipCode: zipCodes.find(z => z._id === selectedZipCode)?.Zip_Code || '',
        province: provinces.find(p => p._id === selectedProvince)?.province || '',
        region: regions.find(r => r._id === selectedRegion)?.region || '',
        country: countries.find(c => c._id === selectedCountry)?.country || '',
      };
      Object.keys(values).forEach(key => {
        if (key !== 'confirmPassword') {
          formData[key] = values[key];
        }
      });

      setUsername(formData.username)

      formData.address = address;
      formData.referral_id = referralId || '';
      formData.sponsor_username = sponsorUsername || '';
      formData.sponsor_username = sponsorName || '';
      formData.sponsor_contact = sponsorContact || '';
      formData.status = 'Inactive';
      formData.user_type = 'Members';
      formData.first_login = true;

      try {
        const response = await AuthService.Register_Member(formData);
        setLoading(false);
        console.log("response.data", response.data)
        if (response.data.status) {
          localStorage.removeItem('referralId');
          localStorage.removeItem('sponsorUsername');
          localStorage.removeItem('sponsorName');
          localStorage.removeItem('sponsorContact');
          localStorage.removeItem('referralData');

          Swal.fire({
            title: t('Registration Successful!'),
            text: t(`Congratulations ${formData.username}! Your account has been created and is awaiting activation. To complete the registration process, please click the link in your activation email.`),
            icon: 'success',
            confirmButtonText: t('OK')
          }).then((result) => {
            if (result.isConfirmed) {
              navigate(`/user-login/${usertype}`);
            }
          });
        } else {
          AlertService.swalErrorAlert(response.data.message);
        }
      } catch (error) {
        setLoading(false);
        const errorMessage = error.response?.data?.message || 'Registration failed';
        AlertService.swalErrorAlert(t(errorMessage));
      }
    }
  };
  const [isFocused, setIsFocused] = useState(false);


  return (
    <container style={styles.container}>
      <div style={styles.leftContainer}>
        <div style={styles.logoContainer}>
          <img src={loginLogo} alt="Logo" style={styles.logo} />
        </div>
        <div style={{
          ...styles.leftInsideContainer, width: isMobile ? '100%' : '60%',
          maxWidth: isMobile ? '100%' : '60%',
        }} className="modal-body">
          <Typography style={styles.heading}>{t('Hi, Welcome to Nano Pro Tech!')}</Typography>

          <Typography sx={{ color: '#212121' }}>{t('Create Your Nano ProTech Account Join us and unlock the full potential of Nano ProTech! Create your account in a few simple steps and start exploring all the features, tools, and resources crafted just for you.')}</Typography>
          {/* Referral Information */}
          {referralId && (
            <div style={styles.referralCard}>
              <div style={styles.referralHeading}>Sponsor Details:</div>
              <div style={styles.referralText}>
                Name: <span style={styles.referralBoldText}>{sponsorName}</span>
              </div>
              <div style={styles.referralText}>
                Username:  <span style={styles.referralBoldText}>{sponsorUsername}</span>
              </div>
              <div style={styles.referralText}>
                Mobile:<span style={styles.referralBoldText}>{sponsorContact}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div style={styles.rightContainer}>
        <Form
          onSubmit={onSubmit}
          validate={validateForm}
          render={({ handleSubmit, valid, values }) => (
            <form onSubmit={handleSubmit} style={styles.form}>
              <LoadingOverlay active={loading} spinner={<DNALoader />}>
                {/* <div style={styles.buttons}>
                  <Button
                    disabled
                    onClick={() => { navigate("/register_client/Members") }}
                    style={styles.signup}
                  >
                    {t('Sign up')}
                  </Button>
                  <Button
                    onClick={() => { navigate("/user-login/Members") }}
                    style={{
                      ...styles.login,
                      ':hover': styles.loginHover,
                    }}
                  >
                    {t('Sign in')}
                  </Button>
                </div> */}
                <Row form>
                  {/* Username Field */}
                  {/* <Col md={6}>
                    <Field name="username">
                      {({ input, meta }) => (
                        <FormGroup>
                          <Label>
                            {t('Username')} *
                            <FontAwesomeIcon
                              icon={faInfoCircle}
                              className="ms-1"
                              title={t('Alphanumeric characters only, case-sensitive, no spaces')}
                            />
                          </Label>
                          <Input
                            {...input}
                            type="text"
                            placeholder={t('Enter Username')}
                            style={{
                              border: '1px solid #303030',
                              background: isFocused ? '#F3F1D3' : '#F9F8E8',
                            }}
                            invalid={meta.error && meta.touched}
                            onFocus={() => setIsFocused(true)} // Set focus state
                            onBlur={() => setIsFocused(false)} // Reset focus state
                            onChange={(e) => {
                              input.onChange(e);
                              checkUsernameAvailability(e.target.value);
                            }}
                          />
                          {isCheckingUsername && (
                            <FormText >{t('Checking username availability...')}</FormText>
                          )}
                          {meta.error && meta.touched && (
                            <span className="text-danger">{meta.error}</span>
                          )}
                        </FormGroup>
                      )}
                    </Field>
                  </Col> */}

                  <Col md={6}>
                    <Field name="username" validate={(value) => validateForm({ username: value }).username}>
                      {({ input, meta }) => (
                        <FormGroup>
                          <Label>
                            {t('Username')} *
                            <FontAwesomeIcon
                              icon={faInfoCircle}
                              className="ms-1"
                              title={t('Alphanumeric characters only, case-sensitive, no spaces')}
                            />
                          </Label>
                          <Input
                            {...input}
                            type="text"
                            placeholder={t('Enter Username')}
                            style={{
                              border: meta.error && meta.touched ? '1px solid red' : '1px solid #303030',
                              background: isFocused ? '#F3F1D3' : '#F9F8E8',
                            }}
                            invalid={meta.error && meta.touched} // This should trigger Bootstrap's invalid style
                            onFocus={() => setIsFocused(true)}
                            onBlur={() => {
                              setIsFocused(false);
                              input.onBlur(); // Ensure onBlur is properly triggered
                            }}
                            onChange={(e) => {
                              input.onChange(e);
                              checkUsernameAvailability(e.target.value);
                            }}
                          />
                          {isCheckingUsername && <FormText>{t('Checking username availability...')}</FormText>}

                          {/* Make sure this block is present to display errors */}
                          {meta.error && meta.touched && (
                            <div className="text-danger">{meta.error}</div>
                          )}
                        </FormGroup>
                      )}
                    </Field>
                  </Col>



                  {/* Full Name Field */}
                  <Col md={6}>
                    <Field name="fullName">
                      {({ input, meta }) => (
                        <FormGroup>
                          <Label>{t('Full Name')} *</Label>
                          <Input
                            {...input}
                            type="text"
                            style={{
                              border: '1px solid #303030',
                              background: '#F9F8E8',
                            }}
                            placeholder={t('Enter full name as per ID')}
                            invalid={meta.error && meta.touched}
                          />
                          {meta.error && meta.touched && (
                            <span className="text-danger">{meta.error}</span>
                          )}
                        </FormGroup>
                      )}
                    </Field>
                  </Col>
                  <Label>{t('Address')} *</Label>
                  {/* Country Selection */}
                  <Col md={12}>
                    <FormGroup>
                      <Select
                        options={countries.map(country => ({
                          value: country._id,
                          label: country.country,
                        }))}
                        value={countries.find(option => option.value === selectedCountry)}
                        onChange={option => setSelectedCountry(option?.value || '')}
                        placeholder={t('Select Country')}
                        isSearchable
                        styles={{
                          control: base => ({
                            ...base,
                            border: '1px solid #303030',
                            background: '#F9F8E8',
                          }),
                        }}
                      />
                    </FormGroup>
                  </Col>
                  {/* Region Selection */}
                  <Col md={6}>
                    <FormGroup>
                      <Select
                        options={regions.map(region => ({
                          value: region._id,
                          label: region.region,
                        }))}
                        value={regions.find(option => option.value === selectedRegion)}
                        onChange={option => setSelectedRegion(option?.value || '')}
                        placeholder={t('Select Region')}
                        isSearchable
                        isDisabled={!selectedCountry}
                        styles={{
                          control: base => ({
                            ...base,
                            border: '1px solid #303030',
                            background: '#F9F8E8',
                          }),
                        }}
                      />
                    </FormGroup>
                  </Col>

                  {/* Province Selection */}
                  <Col md={6}>
                    <FormGroup>
                      <Select
                        options={provinces.map(province => ({
                          value: province._id,
                          label: province.province,
                        }))}
                        value={provinces.find(option => option.value === selectedProvince)}
                        onChange={option => setSelectedProvince(option?.value || '')}
                        placeholder={t('Select Province')}
                        isSearchable
                        isDisabled={!selectedRegion}
                        styles={{
                          control: base => ({
                            ...base,
                            border: '1px solid #303030',
                            background: '#F9F8E8',
                          }),
                        }}
                      />
                    </FormGroup>
                  </Col>

                  {/* District Selection */}
                  <Col md={6}>
                    <FormGroup>
                      <Select
                        options={districts.map(district => ({
                          value: district._id,
                          label: district.district,
                        }))}
                        value={districts.find(option => option.value === selectedDistrict)}
                        onChange={option => setSelectedDistrict(option?.value || '')}
                        placeholder={t('Select District')}
                        isSearchable
                        isDisabled={!selectedProvince}
                        styles={{
                          control: base => ({
                            ...base,
                            border: '1px solid #303030',
                            background: '#F9F8E8',
                          }),
                        }}
                      />
                    </FormGroup>
                  </Col>

                  {/* City Selection */}
                  <Col md={6}>
                    <FormGroup>
                      <Select
                        options={cities.map(city => ({
                          value: city._id,
                          label: city['city/town'],
                        }))}
                        value={cities.find(option => option.value === selectedCity)}
                        onChange={option => setSelectedCity(option?.value || '')}
                        placeholder={t('Select City/Town')}
                        isSearchable
                        isDisabled={!selectedDistrict}
                        styles={{
                          control: base => ({
                            ...base,
                            border: '1px solid #303030',
                            background: '#F9F8E8',
                          }),
                        }}
                      />
                    </FormGroup>
                  </Col>

                  {/* Barangay Selection */}
                  <Col md={6}>
                    <FormGroup>
                      <Select
                        options={barangays.map(barangay => ({
                          value: barangay._id,
                          label: barangay.barangay,
                        }))}
                        value={barangays.find(option => option.value === selectedBarangay)}
                        onChange={option => setSelectedBarangay(option?.value || '')}
                        placeholder={t('Select Barangay')}
                        isSearchable
                        isDisabled={!selectedCity}
                        styles={{
                          control: base => ({
                            ...base,
                            border: '1px solid #303030',
                            background: '#F9F8E8',
                          }),
                        }}
                      />
                    </FormGroup>
                  </Col>

                  {/* Zip Code Selection */}
                  <Col md={6}>
                    <FormGroup>
                      <Select
                        options={zipCodes.map(zip => ({
                          value: zip._id,
                          label: zip.Zip_Code,
                        }))}
                        value={zipCodes.find(option => option.value === selectedZipCode)}
                        onChange={option => setSelectedZipCode(option?.value || '')}
                        placeholder={t('Select Zip Code')}
                        isSearchable
                        isDisabled={!selectedBarangay}
                        styles={{
                          control: base => ({
                            ...base,
                            border: '1px solid #303030',
                            background: '#F9F8E8',
                          }),
                        }}
                      />
                    </FormGroup>
                  </Col>
                  {/* Email Field */}
                  <Col md={12}>
                    <Field name="fullAddress">
                      {({ input, meta }) => (
                        <FormGroup>
                          <Label>{t('Exact Address')} *</Label>
                          <Input
                            {...input}
                            type="fullAddress"
                            style={{
                              border: '1px solid #303030',
                              background: '#F9F8E8',
                            }}
                            placeholder={t('Enter Full Address')}
                            invalid={meta.error && meta.touched}
                          />
                          {meta.error && meta.touched && (
                            <span className="text-danger">{meta.error}</span>
                          )}
                        </FormGroup>
                      )}
                    </Field>
                  </Col>


                  {/* Phone Input with country code */}
                  <Col md={6}>
                    <Field name="mobileNumber">
                      {({ input, meta }) => (
                        <FormGroup>
                          <Label>{t('Mobile Number')} *</Label>
                          <div
                            style={{
                              position: 'relative',
                              width: '100%',
                              height: 'fit-content',
                            }}
                          >
                            <PhoneInput
                              country={'my'}
                              value={input.value}
                              onChange={(phone) => input.onChange(phone)}
                              containerStyle={{
                                width: '100%',
                              }}
                              inputStyle={{
                                width: '100%',
                                height: '50px', // Ensures consistent height
                                border: `1px solid red`,
                                background: '#F9F8E8',
                                paddingLeft: '50px', // Adjust padding to prevent text overlap with the flag
                                borderRadius: '5px',
                                boxShadow: '0 0 5px black',
                                // outline: 'none',
                                transition: 'border-color 0.2s, box-shadow 0.2s', // Smooth transition for focus/blur
                              }}
                              buttonStyle={{
                                border: 'none',
                                background: 'transparent',
                                padding: '0 10px',
                              }}
                              inputProps={{
                                required: true,
                                className: meta.error && meta.touched ? 'is-invalid' : '',
                              }}
                            />
                          </div>
                          {meta.error && meta.touched && (
                            <span className="text-danger">{meta.error}</span>
                          )}
                        </FormGroup>
                      )}
                    </Field>
                  </Col>



                  {/* Email Field */}
                  <Col md={6}>
                    <Field name="email">
                      {({ input, meta }) => (
                        <FormGroup>
                          <Label>{t('Email')} *</Label>
                          <Input
                            {...input}
                            type="email"
                            style={{
                              border: '1px solid #303030',
                              background: '#F9F8E8',
                            }}
                            placeholder={t('Enter email')}
                            invalid={meta.error && meta.touched}
                          />
                          {meta.error && meta.touched && (
                            <span className="text-danger">{meta.error}</span>
                          )}
                        </FormGroup>
                      )}
                    </Field>
                  </Col>

                  {/* Password Fields */}
                  <Col md={6}>
                    <Field name="password">
                      {({ input, meta }) => (
                        <FormGroup>
                          <Label>{t('Password')} *</Label>
                          <Input
                            {...input}
                            type="password"
                            style={{
                              border: '1px solid #303030',
                              background: '#F9F8E8',
                            }}
                            placeholder={t('Enter password')}
                            invalid={meta.error && meta.touched}
                          />
                          {meta.error && meta.touched && (
                            <span className="text-danger">{meta.error}</span>
                          )}
                        </FormGroup>
                      )}
                    </Field>
                  </Col>

                  <Col md={6}>
                    <Field name="confirmPassword">
                      {({ input, meta }) => (
                        <FormGroup>
                          <Label>{t('Confirm Password')} *</Label>
                          <Input
                            {...input}
                            type="password"
                            style={{
                              border: '1px solid #303030',
                              background: '#F9F8E8',
                            }}
                            placeholder={t('Confirm password')}
                            invalid={meta.error && meta.touched}
                          />
                          {meta.error && meta.touched && (
                            <span className="text-danger">{meta.error}</span>
                          )}
                        </FormGroup>
                      )}
                    </Field>
                  </Col>
                </Row>

                {/* Submit Button */}
                <div className="d-grid mb-3">
                  <Button
                    size="lg"
                    color={valid ? "primary" : "secondary"}
                    type="submit"

                    disabled={!valid || isCheckingUsername || !isChecked}
                  >
                    {t('Sign up')}
                  </Button>
                </div>

                <div>
                  {/* Terms and Conditions Checkbox */}
                  <FormGroup check style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Input
                      type="checkbox"
                      id="termsCheckbox"
                      checked={isChecked}
                      onChange={handleCheckboxChange} // Handle checkbox state change
                      style={{ marginRight: '10px', width: '16px', height: '16px', cursor: 'pointer' }}
                    />
                    <Label
                      check
                      htmlFor="termsCheckbox"
                      style={{ display: 'flex', alignItems: 'center', fontSize: '16px', color: '#000' }}
                    >
                      {t('I agree to the')}&nbsp;
                      <span
                        onClick={toggleModal} // Open the modal on click
                        style={{
                          color: '#C1B315',
                          textDecoration: 'underline',
                          cursor: 'pointer',
                          fontWeight: 'bold',
                        }}
                      >
                        {t('Terms and Conditions')}
                      </span>
                    </Label>
                  </FormGroup>

                  {/* Terms and Conditions Modal */}
                  <Modal
                    isOpen={isModalOpen}
                    toggle={toggleModal}
                    centered
                    backdrop={true} // Keep backdrop but adjust its opacity
                    style={{ maxWidth: '600px', width: '90%' }}
                  >
                    <ModalHeader toggle={toggleModal} style={{ background: '#F8F9FA', fontWeight: 'bold', fontSize: '20px', padding: '15px' }}>
                      {t('Terms and Conditions')}
                    </ModalHeader>
                    <ModalBody style={{ padding: '20px', fontSize: '16px', color: '#333', background: '#FFFFFF' }}>
                      <h5 style={{ fontSize: '18px', marginBottom: '8px' }}>1. Account Registration</h5>
                      <p style={{ marginBottom: '15px' }}>You must provide accurate and complete information when creating an account.</p>

                      <h5 style={{ fontSize: '18px', marginBottom: '8px' }}>2. Privacy Policy</h5>
                      <p style={{ marginBottom: '15px' }}>We respect your privacy. Your data will not be shared with third parties.</p>

                      <h5 style={{ fontSize: '18px', marginBottom: '8px' }}>3. Prohibited Activities</h5>
                      <p style={{ marginBottom: '15px' }}>You must not use our service for illegal or fraudulent activities.</p>

                      <h5 style={{ fontSize: '18px', marginBottom: '8px' }}>4. Termination</h5>
                      <p style={{ marginBottom: '15px' }}>We reserve the right to terminate accounts that violate our terms.</p>

                      <h5 style={{ fontSize: '18px', marginBottom: '8px' }}>5. Changes to Terms</h5>
                      <p style={{ marginBottom: '15px' }}>We may update these terms at any time. Continued use means you accept changes.</p>
                    </ModalBody>
                  </Modal>
                </div>


                {/* <div>
                  <FormGroup check className="d-flex align-items-center justify-content-center">
                    <Input
                      type="checkbox"
                      id="termsCheckbox"
                      checked={isChecked}
                      onChange={handleCheckboxChange} // Handle checkbox state change
                      style={{ marginRight: '10px' }}
                    />
                    <Label check htmlFor="termsCheckbox" className="d-flex align-items-center">
                      {t('I agree to the')}&nbsp;
                      <Typography
                        style={{
                          color: '#C1B315', // Terms and Conditions color
                          textDecoration: 'underline', // Optional: Add underline for better emphasis
                          cursor: 'pointer', // Optional: Pointer cursor for clickable effect
                        }}
                      >
                        {t('Terms and Conditions')}
                      </Typography>
                    </Label>
                  </FormGroup>
                </div> */}
              </LoadingOverlay>
            </form>
          )}
        />
      </div>
    </container>
  );
};


const styles = {
  container: {
    padding: '5%',
    display: 'flex',
    flexDirection: 'row',
    // height: '100vh',
    flexWrap: 'wrap',
  },
  loginLink: {
    color: '#007bff',
    textDecoration: 'none',
    marginLeft: '5px'
  },
  sponsorInfo: {
    backgroundColor: '#1E34E3',
    padding: '15px',
    borderRadius: '8px',
    marginBottom: '20px',
    border: '1px solid #dee2e6'
  },
  form: {
    background: "#F9F8E8",
    padding: "50px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
  },
  sponsorLabel: {
    fontWeight: 'bold',
    marginRight: '10px',
    color: '#FFFFFF'
  },
  leftContainer: {
    flex: 1,
    display: 'flex',
    marginTop: '1%',
    flexDirection: 'column',
    overflowY: 'auto',
    width: '100%',
  },
  leftInsideContainer: {
    flex: 1,
    display: 'flex',
    marginTop: '1%',
    flexDirection: 'column',
    background: 'white',
    overflowY: 'auto'
  },
  rightContainer: {
    flex: 1,
    margin: '1%',
    padding: '50px 20px 0 0',
    borderRadius: '1%',
    position: 'relative',
    width: '100%',
  },
  logoContainer: {
    marginBottom: '5px',
    '@media (max-width: 768px)': {
      textAlign: 'center', // Center logo on small screens
    }
  },
  logo: {
    width: '50%',
    height: 'auto',
  },
  heading: {
    color: '#010101',
    marginTop: '10px',
    fontSize: '32px',
    fontWeight: 'bold',
    marginBottom: '10px',
    '@media (max-width: 768px)': {
      fontSize: '24px',
    },
  },
  requiredField: {
    color: 'red',
    marginLeft: '3px',
  },

  referralCard: {
    backgroundColor: "#0000FF",
    color: "#FFFFFF",
    padding: "20px",

    borderRadius: "10px",
    marginTop: '12%',
    textAlign: "left",
    fontFamily: "'Arial', sans-serif",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)"
  },
  referralHeading: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  referralText: {
    marginBottom: "5px",
    fontSize: "1rem",
  },
  referralBoldText: {
    marginBottom: "5px",
    fontWeight: "bold",
  },
  buttons: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fcfbee",
    borderRadius: "10px",
    padding: "10px",
    width: "100%",
    maxWidth: "500px",
    marginBottom: "10px",
  },
  signup: {
    backgroundColor: "#c5b048",
    color: "#fff",
    padding: "12px 30px",
    fontWeight: "bold", // Bold text
    fontSize: "16px", // Font size
    borderRadius: "8px", // Rounded corners for the button
    border: "none", // Remove border
    cursor: "pointer", // Pointer cursor on hover
    flex: 1,
    transition: "all 0.3s ease",
    marginRight: "5px",
    width: "50%",
  },
  login: {
    backgroundColor: "#f4f4f4",
    color: "#000", // Black text
    padding: "12px 30px", // Adjust padding for a larger button
    fontWeight: "bold", // Bold text
    fontSize: "16px", // Font size
    borderRadius: "8px", // Rounded corners for the button
    border: "none", // Remove border
    cursor: "pointer", // Pointer cursor on hover
    flex: 1,
    transition: "all 0.3s ease", // Smooth hover transition
    width: "50%", // Ensure button spans half of the container
  },
  loginHover: {
    backgroundColor: "#e2e2e2",
  },


};

export default Register;