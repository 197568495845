import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Row, Col, FormGroup, Input, Button, Label, Modal, ModalHeader, ModalBody, Card, CardBody } from "reactstrap";
import ItemsService from "./Item.service";
import AuthService from "../Authentication/auth.service";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import Alerts from "../../components/Alerts/alerts";
import PermissionAccess from '../../PermissionAccess/Access.js';

const EditItemVariation = ({ onSuccess, data }) => {
    const [modal, setModal] = useState(false);
    const { i18n, t } = useTranslation();
    const [variation, setVariation] = useState(data);
    const [Fetched_Variations, setFetched_Variations] = useState([]);
    const [Fetched_Variation_Options, setFetched_Variation_Options] = useState([]);
    const [loading, setLoading] = useState(false);
    const { authData, accessPages, super_admin, current_module } = PermissionAccess();
    const [loginNavigate, setLoginNavigate] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        i18n.changeLanguage(localStorage.getItem('language'));
        fetchVariations();
    }, []);

    useEffect(() => {
        if (variation.variation_id) {
            fetchVariationOptions(variation.variation_id);
        }
    }, [variation.variation_id]);

    function fetchVariations() {
        setLoading(true);
        ItemsService.getAllVariations(authData.sessionID).then(
            (response) => {
                setLoading(false);
                if (response.data.message === "Unauthorized") {
                    AuthService.logout();
                    navigate("/login");
                    window.location.reload();
                } else {
                    setFetched_Variations(response.data.data);
                }
            },
            (error) => {
                setLoading(false);
                Alerts.swalErrorAlert(error.response?.data || t("Server Error, Please try again later."));
            }
        );
    }

    function fetchVariationOptions(variation_id) {
        setLoading(true);
        ItemsService.Get_Variation_sub_Types(0, variation_id, authData.sessionID).then(
            (response) => {
                setLoading(false);
                if (response.data.message === "Unauthorized") {
                    AuthService.logout();
                    navigate("/login");
                    window.location.reload();
                } else {
                    setFetched_Variation_Options(response.data.data);
                }
            },
            (error) => {
                setLoading(false);
                Alerts.swalErrorAlert(error.response?.data || t("Server Error, Please try again later."));
            }
        );
    }

    const toggle = () => setModal(!modal);

    const handleVariationChange = (field, value) => {
        if (field === "variation_id") {
            setVariation({ ...variation, [field]: value, variation_option_id: '', price: '' });
            fetchVariationOptions(value);
        } else if (field === "variation_option_id") {
            const selectedOption = Fetched_Variation_Options.find(option => option._id === value);
            setVariation({ ...variation, [field]: value, price: selectedOption ? selectedOption.price : '' });
        } else {
            setVariation({ ...variation, [field]: value });
        }
    };

    const validateForm = () => {
        return variation.variation_id && variation.variation_option_id && variation.price && variation.status;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validateForm()) {
            Alerts.swalErrorAlert("Please fill all fields for the variation.");
            return;
        }

        setLoading(true);
        const payloadData = {
            functionName: "UpdateItemVariation",
            postData: {
                variation: variation
            }
        };

        ItemsService.UpdateItemVariation(payloadData, authData.sessionID).then(
            (response) => {
                setLoading(false);
                if (response.data.status) {
                    onSuccess();
                    Alerts.swalSuccessAlert(response.data.message || "Data Updated Successfully.").then(() => {
                        toggle();
                    });
                } else {
                    if (response.data.message === "Unauthorized") {
                        AuthService.logout();
                        setLoginNavigate(true);
                    } else {
                        Alerts.swalErrorAlert(response.data.message || "Error: Can't Update Variation at the moment. Please try again later.");
                    }
                }
            },
            (error) => {
                setLoading(false);
                Alerts.swalErrorAlert("Network Operation failed.");
            }
        );
    };

    if (loginNavigate) {
        navigate('/login');
        return null;
    }

    return (
        <span className="d-inline-block mb-2 me-2">
            <i onClick={toggle} className="pe-7s-pen btn-icon-wrapper text-primary"> </i>
            <Modal isOpen={modal} toggle={toggle} backdrop={true}>
                <LoadingOverlay active={loading} spinner={<DNALoader />}>
                    <ModalHeader>{t("Edit Item Variation")}</ModalHeader>
                    <ModalBody>
                        <Card className="main-card mb-3">
                            <CardBody>
                                <form onSubmit={handleSubmit}>
                                    <Row className="mb-3">
                                        <Col md="6">
                                            <FormGroup>
                                                <Label>{t("Select Variation")}</Label>
                                                <Input
                                                    type="select"
                                                    value={variation.variation_id}
                                                    onChange={(e) => handleVariationChange('variation_id', e.target.value)}
                                                >
                                                    <option value="">--{t("Select Variation")}--</option>
                                                    {Fetched_Variations.map((Variation, idx) => (
                                                        <option key={idx} value={Variation._id}>{Variation.name}</option>
                                                    ))}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <Label>{t("Select Variation Option")}</Label>
                                                <Input
                                                    type="select"
                                                    value={variation.variation_option_id}
                                                    onChange={(e) => handleVariationChange('variation_option_id', e.target.value)}
                                                    disabled={!variation.variation_id}
                                                >
                                                    <option value="">--{t("Select Variation Option")}--</option>
                                                    {Fetched_Variation_Options && Fetched_Variation_Options.map((option, idx) => (
                                                        <option key={idx} value={option._id}>{option.variation_type_name}</option>
                                                    ))}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col md="6">
                                            <FormGroup>
                                                <Label>{t("Price")}</Label>
                                                <Input
                                                    type="text"
                                                    value={variation.price}
                                                    onChange={(e) => handleVariationChange('price', e.target.value)}
                                                    placeholder="Enter Price"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <Label>{t("Status")}</Label>
                                                <Input
                                                    type="select"
                                                    value={variation.status}
                                                    onChange={(e) => handleVariationChange('status', e.target.value)}
                                                >
                                                    <option value="">{t("Select status")}</option>
                                                    <option value="Active">{t("Active")}</option>
                                                    <option value="InActive">{t("InActive")}</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <div className="text-center">
                                        <Button type="submit" size="lg" className="btn btn-custom-color me-3">
                                            {t("Update")}
                                        </Button>
                                        <Button type="button" outline size="lg" onClick={toggle}>
                                            {t("Cancel")}
                                        </Button>
                                    </div>
                                </form>
                            </CardBody>
                        </Card>
                    </ModalBody>
                </LoadingOverlay>
            </Modal>
        </span>
    );
};

export default EditItemVariation;