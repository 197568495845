import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import BusinessService from "./Businesses.service";
import AuthService from "../Authentication/auth.service";
import Alerts from "../../components/Alerts/alerts";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, CardBody, Card } from "reactstrap";
// import EntitiesService from "../Entities/entities.service";

const AddBusinessAttributeModal = ({ showModalAddBusinessAttribute, handleToggleModalAddBusinessAttribute, busness_id, arrayData }) => {
  console.log('arrayData :',arrayData);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [sections, setSections] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dynamicMsg, setDynamicMsg] = useState('Add');
  const [fieldType, setFieldType] = useState('');
  const [options, setOptions] = useState([{ label: '', value: '' }]);
  const [getOptionsFrom, setGetOptionsFrom] = useState('');
  const [getOptionFieldName, setOptionFieldName] = useState('');
  // const [options_collection, setoptions_collection] = useState([]);
  const [selected_attribute, setselected_attribute] = useState([]);

  const [data, setData] = useState({
    business_id: '',
    section_id: '',
    attribute_id: '',
    value: '',
    field_type: ''
  });

  const [errors, setErrors] = useState({
    section_id: '',
    attribute_id: '',
    value: '',
    field_type: ''
  });

/*
  function fetchcollections() {
    BusinessService.fetchcollections().then((response) => {
      if(response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setoptions_collection(response.data.data);
      }
    },
    (error) => {
      const errContent =
      (error.response && error.response.data) || error.message || error.toString();
      if(errContent) {
        Alerts.swalErrorAlert(errContent)
      }
      else {
        Alerts.swalErrorAlert(t("Server Error, Please try again later."))
      }
    })
  };
*/

  useEffect(() => {
    fetchSections();
    fetchAttributes();
    // fetchcollections();
  }, []);

  useEffect(() => {
    setData((prevData) => ({
      ...prevData,
      business_id: busness_id,
    }));

    if (arrayData && arrayData.value) {
      setData((prevData) => ({
        ...prevData,
        section_id: arrayData.section_id,
        attribute_id: arrayData.attribute_id,
        value: arrayData.value,
        field_type: arrayData.field_type
      }));
      setFieldType(arrayData.field_type);

      var tempArr = [];
      arrayData.BusinessAttributesDynamicDataDetails.forEach(optionAttr => {
        const opt = {
          'label' : optionAttr.option_label,
          'value' : optionAttr.option_value,
        }
        tempArr.push(opt);
      });
      setOptions(tempArr);
      setDynamicMsg('Edit');
    } else {
      setDynamicMsg('Add');
    }

    if (arrayData && arrayData.getOptionsFrom !==null && arrayData.getOptionFieldName !==null) {
      getDynamicAttributeVal(arrayData.getOptionsFrom, arrayData.getOptionFieldName);
    }

    if (arrayData && arrayData.getOptionsFrom ===null && arrayData.getOptionFieldName ===null) {
      GetSingleAttributeFunction(arrayData.attribute_id);
    }

  }, [busness_id, arrayData]);

  const fetchSections = () => {
    setLoading(true);
    BusinessService.getAllSections(0, 9999999).then(
      (response) => {
        setLoading(false);
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        } else {
          setSections(response.data.data);
        }
      },
      (error) => {
        setLoading(false);
        const errContent = (error.response && error.response.data) || error.message || error.toString();
        Alerts.swalErrorAlert(errContent || t("Server Error, Please try again later."));
      }
    );
  }

  const fetchAttributes = () => {
    setLoading(true);
    BusinessService.getAllAttributes(0, 9999999).then(
      (response) => {
        setLoading(false);
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        } else {
          setAttributes(response.data.data);
        }
      },
      (error) => {
        setLoading(false);
        const errContent = (error.response && error.response.data) || error.message || error.toString();
        Alerts.swalErrorAlert(errContent || t("Server Error, Please try again later."));
      }
    );
  }

 
  const handleChange = (e) => {
    const { name, value, type } = e.target;
    let error = '';
  
    if (name === 'section_id' && !value) {
      error = 'Section is required';
    } else if (name === 'attribute_id' && !value) {
      error = 'Attribute is required';
    } else if (name === 'value' && !value) {
      error = 'Value is required';
    }
  
  
    setErrors(prevErrors => {
      const newErrors = { ...prevErrors, [name]: error };
      if (name === 'field_type' && ['Dropdown', 'Checkbox', 'Radio Button'].includes(value)) {
        newErrors.value = '';
      }
      return newErrors;
    });
  
    if (name === 'attribute_id') {
      const selectedAttribute = attributes.find(attr => attr._id === value);
      if (selectedAttribute) {
      // getGetSingleAttributeData()
        console.log('Selected Attribute ID:', selectedAttribute._id); 
        GetSingleAttributeFunction(selectedAttribute._id);
        

        setFieldType(selectedAttribute.field_type);
      }



      setData(prevData => ({ ...prevData, attribute_id: value }));
    } else {
      setData(prevData => ({
        ...prevData,
        [name]: type === 'number' ? parseFloat(value) : value
      }));
    }
  };
  

  const handleOptionChange = (index, event) => {
    const { name, value } = event.target;
    const updatedOptions = [...options];
    updatedOptions[index][name] = value;
    setOptions(updatedOptions);
  };

  const addOption = () => {
    setOptions([...options, { label: '', value: '' }]);
  };

  const removeOption = (index) => {
    const updatedOptions = options.filter((_, i) => i !== index);
    setOptions(updatedOptions);
  };

  const getOptionHeading = () => {
    switch (fieldType) {
      case 'Dropdown':
        return 'Add Dropdown Options (if any)';
      case 'Checkbox':
        return 'Add Checkbox Options (if any)';
      case 'Radio Button':
        return 'Add Radio Button Options (if any)';
      default:
        return 'Add Attribute Options (if any)';
    }
  };

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    let validationErrors = {};
  
    if (!data.section_id) validationErrors.section_id = 'Section is required';
    if (!data.attribute_id) validationErrors.attribute_id = 'Attribute is required';
    if (!['Dropdown', 'Checkbox', 'Radio Button', 'File Input'].includes(data.field_type) && !data.value) {
      validationErrors.value = 'Value is required';
    }
  
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      const formData = {
        ...data,
        options: ['Dropdown', 'Checkbox', 'Radio Button'].includes(fieldType) ? options : [],
        getOptionsFrom: getOptionsFrom ? getOptionsFrom : null,
        getOptionFieldName: getOptionFieldName ? getOptionFieldName : null,
        value: fieldType === 'File Input' ? data.value : data.value, // This will be the base64 string for File Input
        field_type: fieldType // Ensure field_type is included in the payload
      };

      setLoading(true);
      BusinessService.AddBusinessAttribute(formData).then((response) => {
        setLoading(false);
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        } else {
          if (response.data.status === true) {
            resetForm();
            handleToggleModalAddBusinessAttribute();
            const msg = t(`Business attribute ${dynamicMsg === 'Add' ? 'added' : 'updated'} successfully.`);
            Alerts.swalClickProceedAlert(msg).then(res => {
              if (res === true) {
                navigate("/business-details/" + busness_id);
                window.location.reload();
              }
            });
          } else {
            Alerts.swalErrorAlert(t("Something went wrong, Please try again."));
          }
        }
      }, (error) => {
        setLoading(false);
        const errContent = (error.response && error.response.data) || error.message || error.toString();
        Alerts.swalErrorAlert(errContent || t("Server Error, Please try again later."));
      });
    }
  };



  const resetForm = () => {
    setData({
      business_id: busness_id,
      section_id: '',
      attribute_id: '',
      value: '',
      field_type: ''
    });
    setOptions([{ label: '', value: '' }]);
  };

  const handleClose = () => {
    setErrors({
      section_id: '',
      attribute_id: '',
      value: '',
      field_type: ''
    });
    resetForm();
    handleToggleModalAddBusinessAttribute();
  };


  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setData({ ...data, value: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  ///

  const getInputType = () => {
    switch(fieldType) {
      case 'Date Picker':
        return 'date';
      case 'Number Input':
        return 'number';
      case 'Email Input':
        return 'email';
      case 'Password Input':
        return 'password';
      case 'File Input':
        return 'file';
      default:
        return 'text';
    }
  };
  
  const getDynamicAttributeVal = async (table,field) => {
    setLoading(true);
    const optresponse = await BusinessService.getoptions(table, "", {}, 5000);
    setLoading(false);
    const optdata = await optresponse.data.data;
    if(optdata.length > 0)
    {
      const optVal = [];
      optdata.forEach(row => {
        const option = {
          'label': row[field],
          'value': row._id,
        };
        optVal.push(option); // Add the option to the array
      });
      setselected_attribute(optVal);
    }
    
  }

  const GetSingleAttributeFunction = async (id) => {
    // Get Single Attribute
    setLoading(true);
    BusinessService.GetSingleAttribute(id).then(async (response) => {
    setLoading(false);
        if (response.data.data.data.getOptionsFrom === null && response.data.data.data.options.length > 0) {
          setselected_attribute(response.data.data.data.options);
        }
        else if(response.data.data.data.getOptionsFrom !== null)
        {
             getDynamicAttributeVal(response.data.data.data.getOptionsFrom, response.data.data.data.getOptionFieldName);
        }
    },
    (error) => {
      const errContent =
      (error.response && error.response.data) || error.message || error.toString();
      if(errContent) {
        Alerts.swalErrorAlert(errContent)
      }
      else {
        Alerts.swalErrorAlert(t("Server Error, Please try again later."))
      }
    })
    
  }

  return (
    <div className={showModalAddBusinessAttribute ? "modal d-block mt-4" : "modal"} style={{ display: showModalAddBusinessAttribute ? 'block' : 'none', backgroundColor: 'rgba(0, 0, 0, 0.5)' }} tabIndex="-1" role="dialog">
      <form onSubmit={handleSubmit}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">{dynamicMsg} Business Attribute</h5>
            </div>
            <div className="modal-body">
              <Col md="12">
                <Card className="main-card mb-3">
                  <CardBody>
                    <LoadingOverlay
                      tag="div"
                      active={loading}
                      styles={{ overlay: (base) => ({ ...base }) }}
                      spinner={<DNALoader />}
                    >
                      <input type="hidden" className="form-control"
                        name="business_id"
                        onChange={handleChange}
                        value={data.business_id}
                        disabled
                      />
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="section-select" className="col-form-label">{t("Sections")}:</label>
                            <select className="form-control"
                              name="section_id"
                              onChange={handleChange}
                              value={data.section_id}
                            >
                              <option value="">Select Section</option>
                              {sections.length > 0 ? (
                                sections.map((section) => (
                                  <option value={section._id}>{section.name}</option>
                                ))
                              ) : (
                                <option value="">No sections available</option>
                              )}
                            </select>
                            {errors.section_id && <strong className="error text-danger">{errors.section_id}</strong>}
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="attribute-select" className="col-form-label">{t("Attributes")}:</label>
                            <select className="form-control"
                              name="attribute_id"
                              onChange={handleChange}
                              value={data.attribute_id}
                            >
                              <option value="">Select Attribute</option>
                              {attributes.length > 0 ? (
                                attributes.map((attribute) => (
                                  <option value={attribute._id}>{attribute.lable}</option>
                                ))
                              ) : (
                                <option value="">No attributes available</option>
                              )}
                            </select>
                            {errors.attribute_id && <strong className="error text-danger">{errors.attribute_id}</strong>}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                              <div className="col-12">
                                <div className="form-group">
                                  <label htmlFor="value" className="col-form-label">{t("Value")}:</label>
                                  {fieldType === 'File Input' ? (
                                    <input 
                                      type="file" 
                                      className="form-control" 
                                      name="value"
                                      onChange={handleFileChange}
                                      accept="image/*"
                                    />
                                  ) : ['Dropdown', 'Checkbox', 'Radio Button'].includes(fieldType) ? (
                                    

                                    <select className="form-control"
                                        name="value"
                                        onChange={handleChange}
                                        value={data.value}
                                        >
                                      <option value="">Please Select</option>

                                      {selected_attribute &&
                                          selected_attribute.map((optionsRow) => (
                                          <option value={optionsRow.value}>{optionsRow.label}</option>
                                          ))
                                      }
                                      
                                    </select>

                                  ) : (
                                    <input 
                                      type={getInputType()}
                                      className="form-control" 
                                      placeholder={`Enter Value`}
                                      name="value"
                                      onChange={handleChange}
                                      value={data.value}
                                    />
                                  )}
                                  {errors.value && <strong className="error text-danger">{errors.value}</strong>}
                                </div>
                              </div>
                            </div>


                      {/* {['Dropdown', 'Checkbox', 'Radio Button'].includes(fieldType) && (
                        !getOptionsFrom && (
                        <>
                          <div className="row mt-3">
                            <div className="col-6">
                              <h5>{getOptionHeading()}</h5>
                            </div>
                          </div>
                          {options.map((option, index) => (
                            <div className="row" key={index}>
                              <div className="col-5">
                                <div className="form-group">
                                  <label htmlFor="option-label" className="col-form-label">{t("Option Label")}:</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Option Label"
                                    name="label"
                                    value={option.label}
                                    onChange={(e) => handleOptionChange(index, e)}
                                  />
                                </div>
                              </div>
                              <div className="col-5">
                                <div className="form-group">
                                  <label htmlFor="option-value" className="col-form-label">{t("Option Value")}:</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Option Value"
                                    name="value"
                                    value={option.value}
                                    onChange={(e) => handleOptionChange(index, e)}
                                  />
                                </div>
                              </div>
                              <div className="col-2" style={{ marginTop: "35px" }}>
                                <div className="form-group">
                                  <button
                                    type="button"
                                    className="btn-icon btn-icon-only btn-shadow btn-outline-2x btn btn-outline-danger"
                                    onClick={() => removeOption(index)}
                                  >
                                    <i className="pe-7s-close-circle btn-icon-wrapper"> </i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))}
                          <div className="row mt-3">
                            <div className="col-md-2 col-xl-2">
                              <button type="button" className="btn btn-custom-color btn-hover-shine" onClick={addOption}>
                                Add More
                              </button>
                            </div>
                          </div>
                        </>
                      )
                      )}

                    {['Dropdown'].includes(fieldType) && (
                      <>
                          <div className="row mt-3">                  
                            <div className="col-6">
                              <div className="form-group" >
                                    <label htmlFor="referal_name">{t('Get Options From')}</label>
                                    <select className="form-select"
                                    onChange={(e) => setGetOptionsFrom(e.target.value)}
                                    value={getOptionsFrom}
                                    >
                                        <option value="">{t('--Choose an option--')}</option>
                                        {options_collection &&
                                          options_collection.map((opt_collection) => (
                                          <option value={opt_collection.name} selected={opt_collection.name === 'Sections'}>{opt_collection.name}</option>
                                          ))
                                        }
                                    </select>
                                    </div>

                            </div>

                            <div className="col-6">
                              <div className="form-group" >
                                    <label htmlFor="referal_name">{t('Options Field Name')}</label>
                                    
                                    <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Option Value"
                                    onChange={(e) => setOptionFieldName(e.target.value)}
                                    value={getOptionFieldName}
                                  />
                                    </div>

                            </div>

                          </div>
                          </>
                         )} */}

                      <div className="text-center mt-4">
                        <button type="button" className="btn btn-outline-light btn-hover-shine me-3" onClick={handleClose}>{t("Close")}</button>
                        <button type="submit" className="btn btn-custom-color">{t("Save")}</button>
                      </div>
                    </LoadingOverlay>
                  </CardBody>
                </Card>
              </Col>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddBusinessAttributeModal;
