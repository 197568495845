import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import { Row, Col, CardBody, Card } from "reactstrap";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import DataTable from 'react-data-table-component';
import DiscountCouponService from "../DiscountCoupon/Discountcoupons.service";
import AuthService from "../Authentication/auth.service";
import Alerts from "../../components/Alerts/alerts";
import { Tooltip } from "@mui/material";
import DiscountCouponsFilter from "../../components/Table Filters/DiscountCouponsFilter";
import AddDiscountCouponModal from './AddDiscountCouponModal';
import EditDiscountCouponModal from './EditDiscountCouponModal';
import PermissionAccess from '../../PermissionAccess/Access.js';

const DiscountcouponsList = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [discountcoupons, setDiscountcoupons] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [filterBusiness, setFilterBusiness] = useState('');
  const [selectedId, setSelectedId] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);

  const [pageLimit, setPageLimit] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const { authData, accessPages, super_admin, current_module } = PermissionAccess();

  const [showModalAddDiscountCoupon, setShowModalAddDiscountCoupon] = useState(false);
  const handleToggleModalAddDiscountCoupon = () => {
    setShowModalAddDiscountCoupon(!showModalAddDiscountCoupon);
  }

  const [showModalEditDiscountCoupon, setShowModalEditDiscountCoupon] = useState(false);
  const handleToggleModalEditDiscountCoupon = (id) => {
    setShowModalEditDiscountCoupon(!showModalEditDiscountCoupon);
    setSelectedId(id); // Set the selected ID when toggling modal
  };

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchDiscountcoupons(0, pageLimit);
  }, [pageLimit]);


  function fetchDiscountcoupons(page_index, page_limit, text, status, business) {
    setLoading(true);
    var offset = (page_index - 1) * page_limit;
    if (offset < 1) {
      offset = 0;
    }

    const stored_user = authData.userData;
    let businessIDs = []
    if (stored_user.businesses) {
      businessIDs = stored_user.businesses
    }
    DiscountCouponService.getAllDiscountCoupons(authData.sessionID,offset, page_limit, text, status, business,businessIDs).then(
      (response) => {
        setLoading(false);
        console.log("Get discountcoupons response:-", response);
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        } else {
          console.log(response.data);
          setDiscountcoupons(response.data.data);
          setTotalRows(response.data.count.count);
        }
      },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        if (errContent) {
          Alerts.swalErrorAlert(errContent);
        } else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."));
        }
      }
    );
  }


  function confirmDelete(_id) {
    let msg = t("Once deleted, you will not be able to recover this user!")
    Alerts.swalConfirmDeleteAlert(msg).then(res => {
      console.log("Resp:-", res)
      if (res === true) {
        sendDelete(_id)
      }
    })
  };

  function sendDelete(id) {
    setLoading(true);
    DiscountCouponService.deleteDiscountCoupon(authData.sessionID,id).then((response) => {
      console.log("Delete Discount Coupon response:-", response)
      setLoading(false);
      fetchDiscountcoupons(currentPage, pageLimit, filterText, filterStatus, filterBusiness);
      if (response.data.status) {
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Discount Coupon Deleted Successfully."))
        }
        console.log(response.data.message)
      }
      else {
        setLoading(false);
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't delete the Section."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Delete operation failed"))
      })
  };


  const handleCheckboxChange = (e, row) => {
    const { checked } = e.target;
    if (checked) {
      setSelectedRows((prevSelected) => [...prevSelected, row._id]);
    } else {
      setSelectedRows((prevSelected) => prevSelected.filter(id => id !== row._id));
    }
  };

  const handleSelectAllChange = (e) => {
    const { checked } = e.target;
    if (checked) {
      setSelectedRows(discountcoupons.map((discountcoupon) => discountcoupon._id));
    } else {
      setSelectedRows([]);
    }
  };


  const handleDeleteSelected = () => {
    if (selectedRows.length > 0) {
      let msg = t(selectedRows.length + " records selected. Once deleted, you will not be able to recover this!");
      Alerts.swalConfirmDeleteAlert(msg).then(res => {
        console.log("Resp:-", res)
        if (res === true) {
          sendBulkDelete(selectedRows)
        }
      })
    } else {
      Alerts.swalErrorAlert('No records selected');
    }
  };

  function sendBulkDelete(selectedRows) {
    setLoading(true);
    DiscountCouponService.deleteBulkDiscountSection(authData.sessionID,selectedRows).then((response) => {
      console.log("Delete BulkSection response:-", response)
      setLoading(false);
      setSelectedRows([]);
      fetchDiscountcoupons(currentPage, pageLimit, filterText, filterStatus, filterBusiness);
      if (response.data.status) {
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Collection Data Deleted Successfully."))
        }
        console.log(response.data.message)
      }
      else {
        setLoading(false);
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't delete the Collection Data at the moment."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Delete operation failed"))
      })
  };


  const handleUpdateSelected = (type) => {
    if (selectedRows.length > 0) {
      let msg = t(selectedRows.length + " records selected, Want to update their status?");
      Alerts.swalOKAlert(msg).then(res => {
        console.log("Resp:-", res)
        if (res === true) {
          sendBulkUpdate(selectedRows, type);
        }
      })
    } else {
      Alerts.swalErrorAlert('No records selected');
    }
  };

  function sendBulkUpdate(selectedRows, type) {
    setLoading(true);
    DiscountCouponService.updateBulkStatus(authData.sessionID,selectedRows, type).then((response) => {
      console.log("Update Bulk Status response:-", response)
      setLoading(false);
      setSelectedRows([]);
      fetchDiscountcoupons(currentPage, pageLimit, filterText, filterStatus, filterBusiness);
      if (response.data.status) {
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {

          Alerts.swalOKAlert(t("Collection Data Updated Successfully."));
        }
        console.log(response.data.message)
      }
      else {
        setLoading(false);
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't delete the Collection Data at the moment."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Delete operation failed"))
      })
  };

  const mycolumns = [
    {
      name: (
        <div>
          <input
            className="form-check-input"
            type="checkbox"
            onChange={handleSelectAllChange}
          />
        </div>
      ),
      width: '10%',
      selector: (row) => {
        return (
          <div>
            <input
              className="form-check-input"
              type="checkbox"
              checked={selectedRows.includes(row._id)}
              onChange={(e) => handleCheckboxChange(e, row)}
            />
          </div>
        );
      },
    },
    {
      name: t('Coupon Code'),
      selector: "coupon_code",
      width: "10%",
      sortable: true,
    },
    {
      name: t('Status'),
      width: '10%',
      selector: (row) => {
        return (
          <div>
            {row.status === "Active" ? (
              <button
                type="button"
                style={{ borderColor: '#3ac47d', backgroundColor: '#1BD35A' }}
                className="btn btn-sm btn-success btn-hover-shine me-3"
              >
                {row.status}
              </button>
            ) : (
              <button
                type="button"
                style={{ borderColor: '#FF0000', backgroundColor: 'red' }}
                className="btn btn-sm btn-danger btn-hover-shine me-3"
              >
                {row.status}
              </button>
            )}
          </div>
        );
      },
      sortable: true,
    },

    {
      name: t('Business'),
      selector: (row) => {
        return row.business_details && row.business_details.name ? row.business_details.name : t('N/A');
      },
      width: "14%",
      sortable: true,
    },

    {
      name: t('Discount'),
      selector: "amount",
      width: "10%",
      sortable: true,
      cell: (row) => {
        let formattedAmount = parseFloat(row.amount).toFixed(2);
        if (row.type === 'Percentage') {
          formattedAmount += ' %';
        }
        return formattedAmount;
      },
    },
    {
      name: t('Available'),
      selector: "total_coupons",
      width: "10%",
      sortable: true,
      cell: (row) => parseFloat(row.total_coupons).toFixed(2),
    },
    {
      name: t('Consumed'),
      selector: "consumed",
      width: "10%",
      sortable: true,
      cell: (row) => parseFloat(row.consumed).toFixed(2),
    },

    {
      name: t('Start Date'),
      selector: "start_date",
      width: "10%",
      sortable: true,
    },


    {
      name: t('End Date'),
      selector: "end_date",
      width: "10%",
      sortable: true,
    },

    {
      name: t('Actions'),
      width: "6%",
      selector: (row) => {
        return (
          <div>
            {!super_admin ? current_module?.EditData === 1 &&
              <Tooltip placement="top" title={`${t("Update")} ${t("Discount")}`} arrow onClick={() => handleToggleModalEditDiscountCoupon(row._id)} style={{ cursor: "pointer" }}>
                <i className="pe-7s-pen btn-icon-wrapper text-primary fs-5 p-1"> </i>
              </Tooltip>
              :
              <Tooltip placement="top" title={`${t("Update")} ${t("Discount")}`} arrow onClick={() => handleToggleModalEditDiscountCoupon(row._id)} style={{ cursor: "pointer" }}>
                <i className="pe-7s-pen btn-icon-wrapper text-primary fs-5 p-1"> </i>
              </Tooltip>
            }
            {!super_admin ? current_module?.DeleteData === 1 &&
              <Tooltip placement="top" title={`${t("Delete")} ${t("Discount")}`} arrow onClick={() => confirmDelete(row._id)} style={{ cursor: "pointer" }}>
                <i className="pe-7s-trash text-danger btn-icon-wrapper fs-5"> </i>
              </Tooltip>
              :
              <Tooltip placement="top" title={`${t("Delete")} ${t("Discount")}`} arrow onClick={() => confirmDelete(row._id)} style={{ cursor: "pointer" }}>
                <i className="pe-7s-trash text-danger btn-icon-wrapper fs-5"> </i>
              </Tooltip>
            }
          </div>
        );
      }
    },
  ];



  const handleSearch = (searchText, status, business) => {
    setFilterText(searchText);
    setFilterStatus(status);
    setFilterBusiness(business);
    if (searchText !== '' || status !== '' || business !== '') {
      fetchDiscountcoupons(0, pageLimit, searchText, status, business)
    }
  };

  const handleClear = () => {
    setFilterText('');
    setFilterStatus('');
    setFilterBusiness('');
    fetchDiscountcoupons(0, pageLimit);
  };
  const filteredItems = discountcoupons;


  /*
    const filteredItems = discountcoupons.filter(
      item =>
        (item.coupon_code.includes(filterText)) &&
        (filterStatus === '' || item.status === filterStatus) &&
        (filterBusiness === '' || item.business_details.name === filterBusiness)
    );
  */

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <DiscountCouponsFilter
        onSearch={handleSearch}
        onClear={handleClear}
        filterText={filterText}
        filterStatus={filterStatus}
        filterBusiness={filterBusiness}
      />
    );
  }, [filterText, filterStatus, filterBusiness]);


  function handlePageChange(page_index) {
    console.log("Page index:-", page_index)
    setCurrentPage(page_index)
    fetchDiscountcoupons(page_index, pageLimit, filterText, filterStatus, filterBusiness);
  }

  async function handlePerRowsChange(newPageLimit, page_index) {
    setPageLimit(newPageLimit);
  }

  return (
    <Fragment>

      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className="bg-light-gray bg-animation">
            <div className="d-flex justify-content-center align-items-center">
              <Col md="12" className="mx-auto app-login-box">
                <div className="app-page-title">
                  <div className="page-title-wrapper">
                    <div className="page-title-heading fw-bold">
                      <div className="page-title-icon">
                        <i className="pe-7s-news-paper icon-gradient bg-tempting-azure" />
                      </div>
                      <div>
                        {`${t("Discount Coupons")} ${t("Management")}`}
                        <div className="page-title-subheading">
                          {`${t("Add, Update, Delete & View Details of")} ${t("Discount Coupons")}`}
                        </div>
                      </div>
                    </div>
                    <div className="page-title-actions">

                      <AddDiscountCouponModal showModalAddDiscountCoupon={showModalAddDiscountCoupon} handleToggleModalAddDiscountCoupon={handleToggleModalAddDiscountCoupon} onSuccess={fetchDiscountcoupons} />
                      <EditDiscountCouponModal
                        onSuccess={fetchDiscountcoupons}
                        showModalEditDiscountCoupon={showModalEditDiscountCoupon}
                        handleToggleModalEditDiscountCoupon={() => handleToggleModalEditDiscountCoupon(null)} // Reset ID when modal is closed
                        id={selectedId} // Pass selected ID to modal
                      />

                    </div>
                  </div>
                </div>
                <Row>
                  <LoadingOverlay
                    tag="div"
                    active={loading}
                    styles={{ overlay: (base) => ({ ...base }) }}
                    spinner={<DNALoader />}
                  >
                    <Col md="12">
                      <Card className="main-card mb-3">
                        <CardBody>
                          <Col md="6" style={{ float: "left", position: "absolute", zIndex: "1" }}>
                            {subHeaderComponentMemo}
                          </Col>
                          <DataTable
                            data={filteredItems}
                            columns={mycolumns}
                            pagination
                            fixedHeader
                            fixedHeaderScrollHeight="400px"
                            subHeader
                            subHeaderComponent={
                              <div>
                                {!super_admin ? current_module?.BulkDelete === 1 &&
                                  <button type="button"
                                    className="btn btn-outline-danger btn-hover-shine me-3 btn"
                                    onClick={handleDeleteSelected}
                                  >
                                    {t('Delete Selected')}
                                  </button>
                                  :
                                  <button type="button"
                                    className="btn btn-outline-danger btn-hover-shine me-3 btn"
                                    onClick={handleDeleteSelected}
                                  >
                                    {t('Delete Selected')}
                                  </button>
                                }
                                {!super_admin ? current_module?.Active_Inactive_Status === 1 &&
                                  <button type="button"
                                    className="btn btn-outline-danger btn-hover-shine me-3 btn"
                                    onClick={() => handleUpdateSelected('Active')}
                                  >
                                    {t('Active Status')}
                                  </button>
                                  :
                                  <button type="button"
                                    className="btn btn-outline-danger btn-hover-shine me-3 btn"
                                    onClick={() => handleUpdateSelected('Active')}
                                  >
                                    {t('Active Status')}
                                  </button>
                                }

                                {!super_admin ? current_module?.Active_Inactive_Status === 1 &&
                                  <button type="button"
                                    className="btn btn-outline-danger btn-hover-shine me-3 btn"
                                    onClick={() => handleUpdateSelected('InActive')}
                                  >
                                    {t('InActive Status')}
                                  </button>
                                  :
                                  <button type="button"
                                    className="btn btn-outline-danger btn-hover-shine me-3 btn"
                                    onClick={() => handleUpdateSelected('InActive')}
                                  >
                                    {t('InActive Status')}
                                  </button>
                                }

                                {!super_admin ? current_module?.AddData === 1 &&
                                  <button type="button" className="btn btn-custom-color btn-hover-shine me-3 btn" onClick={handleToggleModalAddDiscountCoupon}>
                                    {t('Add New')}
                                  </button>
                                  :
                                  <button type="button" className="btn btn-custom-color btn-hover-shine me-3 btn" onClick={handleToggleModalAddDiscountCoupon}>
                                    {t('Add New')}
                                  </button>
                                }

                              </div>
                            }

                            paginationServer
                            paginationTotalRows={totalRows}
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handlePerRowsChange}
                            paginationResetDefaultPage={resetPaginationToggle}
                          />
                        </CardBody>
                      </Card>
                    </Col>
                  </LoadingOverlay>
                </Row>
              </Col>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default DiscountcouponsList;
