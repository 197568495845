import React, { useState, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faTrophy } from '@fortawesome/free-solid-svg-icons';
import AlertService from '../../../components/Alerts/alerts';
import DashboardService from "../dashboard.service";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";

const RedeemPoints = ({ onClose, availablePoints, onSuccessfulRedeem }) => {
    const [pointsToRedeem, setPointsToRedeem] = useState('');
    const [loading, setLoading] = useState(false);

    const handleRedeemPoints = async () => {
        try {
            setLoading(true);
            const userString = localStorage.getItem('user');
            const user = JSON.parse(userString);
            const userId = user['_id'];
            
            const data = {
                user_id: userId,
                points: pointsToRedeem
            };
            
            const response = await DashboardService.RedeemPoints(data);
            if (response.data.status === true) {
                AlertService.swalSuccessAlert("Points redeemed successfully.");
                onSuccessfulRedeem(); // Call the callback function
                onClose();
            } else {
                AlertService.swalErrorAlert(response.data.message || "Failed to redeem points.");
            }
        } catch (err) {
            console.error('Error redeeming points:', err);
            AlertService.swalErrorAlert("Failed to redeem points. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const handlePointsChange = (e) => {
        const value = e.target.value;
        if (value === '' || (Number(value) > 0 && Number(value) <= availablePoints)) {
            setPointsToRedeem(value);
        }
    };

    const handleOutsideClick = useCallback((e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    }, [onClose]);

    const styles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
        },
        popup: {
            width: '90%',
            maxWidth: '500px',
            backgroundColor: 'white',
            borderRadius: '10px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            overflow: 'hidden',
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '20px',
            borderBottom: '1px solid #eee',
        },
        content: {
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        title: {
            fontSize: '18px',
            fontWeight: 'bold',
        },
        input: {
            width: '200px',
            padding: '10px',
            margin: '10px',
            border: '1px solid #ccc',
            borderRadius: '5px',
        },
        redeemButton: (isDisabled) => ({
            width: '200px',
            padding: '10px',
            margin: '20px 10px',
            backgroundColor: isDisabled ? '#ccc' : '#F63E3D',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: isDisabled ? 'not-allowed' : 'pointer',
            opacity: isDisabled ? 0.5 : 1,
        }),
    };

    const isRedeemDisabled = !pointsToRedeem || Number(pointsToRedeem) <= 0 || Number(pointsToRedeem) > availablePoints || loading;

    return (
        <div style={styles.overlay} onClick={handleOutsideClick}>
            <div style={styles.popup}>
                <div style={styles.header}>
                    <FontAwesomeIcon icon={faArrowLeft} onClick={onClose} style={{ cursor: 'pointer' }} />
                    <span style={styles.title}>Redeem Points</span>
                    <FontAwesomeIcon icon={faTrophy} />
                </div>

                <LoadingOverlay tag="div" active={loading}
                  styles={{ overlay: (base) => ({ ...base }) }}
                  spinner={<DNALoader />}>
                <div style={styles.content}>
                    <h3>Available Points: {availablePoints}</h3>
                    <input
                        type="number"
                        style={styles.input}
                        value={pointsToRedeem}
                        onChange={handlePointsChange}
                        placeholder="Enter points to redeem"
                        min="1"
                        max={availablePoints}
                    />
                    <button
                        style={styles.redeemButton(isRedeemDisabled)}
                        onClick={handleRedeemPoints}
                        disabled={isRedeemDisabled}
                    >
                        Redeem Points
                    </button>
                </div>
                </LoadingOverlay>
            </div>
        </div>
    );
};

export default RedeemPoints;