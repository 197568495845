import React, { useEffect, useState, Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import DataTable from 'react-data-table-component';
import {
  Row,
  Col
} from "reactstrap";

import { useTranslation } from 'react-i18next';

import Tabs, { TabPane } from "rc-tabs";
import TabContent from "rc-tabs/lib/SwipeableTabContent";
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar";
import BasicInfo from "./BasicInfo";
import DetailedInfo from "./DetailedInfo";
import DetailsTab from "./DetailsTab";


const CustomerDetails = () => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [checkTab, setCheckTab] = useState(localStorage.getItem('tab5'));
  const location = useLocation();
  const { state } = location;
  console.log("Received Object:-", state);

  useEffect(() => {
    if (!checkTab) {
      setCheckTab("1")
    } else {
      localStorage.removeItem('tab5');
    }
  }, [checkTab]);

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
  }, []);

  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className="bg-light-gray bg-animation">
            <div className="d-flex justify-content-center align-items-center">
              <Col md="12" className="mx-auto app-login-box">
                <div className="app-page-title mb-1">
                  <div className="page-title-wrapper">
                    <div className="page-title-heading fw-bold">
                      <div className="page-title-icon">
                        <i className="pe-7s-note2 icon-gradient bg-tempting-azure" />
                      </div>
                      <div>
                        {t("Member Details")}
                        <div className="page-title-subheading">
                          {t("View Basic and detailed information of Member.")}
                        </div>
                      </div>
                    </div>
                    <div className="page-title-actions">
                      <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-success btn-hover-shine me-3">
                        {t("Back")}
                      </button>
                    </div>
                  </div>
                </div>

                <Row>
                  <Tabs defaultActiveKey={checkTab} renderTabBar={() => <ScrollableInkTabBar />} renderTabContent={() => <TabContent />}>
                    <TabPane tab={t("Basic Information")} key="1">
                      <BasicInfo />
                    </TabPane>
                    <TabPane tab={t("Detailed Information")} key="2">
                      <DetailedInfo />
                    </TabPane>
                    <TabPane tab={t("Documents and Certificates")} key="3">
                      <DetailsTab />
                    </TabPane>
                  </Tabs>
                </Row>

              </Col>
            </div>

          </div>
        </div>
      </div>
    </Fragment>
  );
};


export default CustomerDetails;

