import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from "react-router-dom";
import { Col, Row, Label, FormGroup, Input, Card, CardBody } from "reactstrap";
import { Form, Field } from "react-final-form";

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";

import Alerts from "../../components/Alerts/alerts";

import UserService from "../Users Management/users.service";
import AuthService from "../Authentication/auth.service";

import AlertService from "../../components/Alerts/alerts";
import axios from "axios";


const API_URL  = process.env.REACT_APP_BASEURL;


const MyProfile = () => {
  
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [storeduser, setStoredUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [opass, setOpass] = useState("");
  

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    // getroles();
    // setStoredUser(JSON.parse(localStorage.getItem("user")));
    console.log("Stored User:-", storeduser);
    setTimeout(() => {
      decryptPass(storeduser.password);
    }, 2000);
  },[]);

  

  function decryptPass(pass) {
    console.log("Stored Pass:-", pass);
    let dec = atob(pass);
    setOpass(dec)
  }

  const onSubmitInfo = values => {
    setLoading(true);
      var payloadData2 = {
        functionName: "UpdateUserProfile",
        postData: {
          name: values.name,
          contact: values.contact,
          _id: storeduser._id
        },
      }
      console.log("Payload:-", payloadData2);
      SendSubmit(payloadData2);
  };

  const onSubmitPass = values => {

    if(values.opassword !== opass) {
      AlertService.swalErrorAlert("Old password is incorrect. Please enter correct old password.")
    }
    else {
      if(values.npassword !== values.cpassword) {
        AlertService.swalErrorAlert("Passwords do not match.")
      }
      else {
        setLoading2(true);
        var payloadData = {
          functionName: "UpdateUserProfile",
          postData: {
            password: values.npassword,
            _id: storeduser._id
          },
        }
        console.log("Payload:-", payloadData);
        SendSubmit(payloadData);
      }
    }
  };


  function SendSubmit(payload) {
    sendUpdateProfile(payload).then((response) => {        
      console.log("API Response:-", response);
      setLoading(false);
      setLoading2(false);
      if(response.data.status){
        saveUpdatedCreds(payload, response.data.message)
      }
      else {
        if(response.data.message !== '') {
          if(response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't update profile at the moment. Please try again later."))
        }
      }
    },
    (error) => {
      setLoading(false);
      setLoading2(false);
      const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      Alerts.swalErrorAlert(t("Network Operation failed."))
    });
  }
  

  function sendUpdateProfile(payload) {
    const headers= { 
      'Content-Type': 'application/json',
      'token': localStorage.getItem("session-id")
    }
    return axios.post(API_URL, payload, {headers});
  };


  function saveUpdatedCreds(payload, msg) {
    if(payload.postData.name) {
      console.log("name:-", payload.postData.name)
      storeduser.name = payload.postData.name;
      localStorage.setItem("user", JSON.stringify(storeduser));
    }
    if(payload.postData.contact) {
      console.log("contact:-", payload.postData.contact)
      storeduser.contact = payload.postData.contact;
      localStorage.setItem("user", JSON.stringify(storeduser));
    }
    if(payload.postData.password) {
      console.log("password:-", payload.postData.password)
      let enc = btoa(payload.postData.password);
      storeduser.password = enc;
      localStorage.setItem("user", JSON.stringify(storeduser));
    }

    if(msg !== '') {
      Alerts.swalSuccessAlert(msg).then(res => {
        console.log("Resp:-",res)
        if(res === true) {
          window.location.reload();
        }
        else {
          window.location.reload();
        }
    })
    }
    if(msg === '') {
      Alerts.swalSuccessAlert(t("Profile updated successfully.")).then(res => {
        console.log("Resp:-",res)
        if(res === true) {
          window.location.reload();
        }
        else {
          window.location.reload();
        }
      })
    }
    
  }


  const validateFtn1 = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Name Required.";
    }
    if (!values.contact) {
      errors.contact = t("Contact No Required.");
    }
    return errors;
  };

  const validateFtn2 = (values) => {
    const errors = {};
    if (!values.opassword) {
      errors.opassword = "Old Password Required.";
    }
    if (!values.npassword) {
      errors.npassword = "New Password Required.";
    }
    if (!values.cpassword) {
      errors.cpassword = "Confirm Password Required.";
    }
    return errors;
  };
    
  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className="app-page-title">
            <div className="page-title-wrapper">
              <div className="page-title-heading fw-bold">
                <div className="page-title-icon">
                  <i className="pe-7s-id icon-gradient bg-happy-green" />
                </div>
                <div>Admin Profile
                  <div className="page-title-subheading">
                  View and update your profile.
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        <div className="d-flex justify-content-center">
          <div className="col-md-12">
          <div className="row">
                          
            <Card className="main-card mb-3">
            <h5 className="mt-3 fw-bold">Basic Information</h5>
              <CardBody>
                <Form onSubmit={onSubmitInfo}
                  initialValues={storeduser}
                  validate={validateFtn1}
                  render={({ handleSubmit, values, submitting, validating, valid }) => (
                    <form onSubmit={handleSubmit}>
                      <LoadingOverlay tag="div" active={loading}
                        styles = {{overlay: (base) => ({...base})}}
                        spinner = {<DNALoader/>}>  
                        <Row>
                          <Col md={6}>
                            <FormGroup>
                              <Field name="name">
                                {({ input, meta }) => (
                                  <div>
                                    <Label for="username">Name</Label>
                                    <Input {...input} type="text" 
                                      invalid={meta.error && meta.touched} 
                                      placeholder={t('User Name')}
                                    />
                                    {meta.error && meta.touched && 
                                    <span className="text-danger">{meta.error}</span>}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                            </Col>
                            <Col md={6}>
                            <FormGroup>
                              <Field name="email">
                                {({ input, meta }) => (
                                  <div>
                                    <Label for="email">{t('Email')}</Label>
                                    <Input {...input} type="text" readOnly
                                      invalid={meta.error && meta.touched} 
                                      placeholder={t('Email')}
                                    />
                                    {meta.error && meta.touched && 
                                    <span className="text-danger">{meta.error}</span>}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                            </Col>
                            <Col md={6}>
                            <FormGroup>
                              <Field name="contact">
                                {({ input, meta }) => (
                                  <div>
                                    <Label for="contact">{t('Contact No')}</Label>
                                    <Input {...input} type="number" 
                                      invalid={meta.error && meta.touched} 
                                      placeholder={t('User Contact No')}
                                    />
                                    {meta.error && meta.touched && 
                                    <span className="text-danger">{meta.error}</span>}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                            </Col>
                            <FormGroup>
                              <div className="d-flex justify-content-end">
                                <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-light btn-hover-shine me-3 mt-3">{t('Cancel')}</button>
                                <button className="btn btn-custom-color btn-hover-shine me-3 mt-3" disabled={!valid}>Update</button>
                              </div>
                            </FormGroup>
                          
                        </Row>   
                      </LoadingOverlay>
                    </form>
                  )}
                />
              </CardBody>
           
            </Card>
            </div>




            <div className="row">
                          
                          <Card className="main-card mb-3">
                          <h5 className="mt-3 fw-bold">Change Password</h5>
                            <CardBody>
                              <Form onSubmit={onSubmitPass}
                                initialValues={storeduser}
                                validate={validateFtn2}
                                render={({ handleSubmit, values, submitting, validating, valid }) => (
                                  <form onSubmit={handleSubmit}>
                                    <LoadingOverlay tag="div" active={loading2}
                                      styles = {{overlay: (base) => ({...base})}}
                                      spinner = {<DNALoader/>}>  
                                      <Row >
                                        <Col md={6}>
                                          <FormGroup>
                                            <Field name="opassword">
                                              {({ input, meta }) => (
                                                <div>
                                                  <Label for="opassword">Old Password</Label>
                                                  <Input {...input} type="password" 
                                                    invalid={meta.error && meta.touched} 
                                                    placeholder={t('Old Password')}
                                                  />
                                                  {meta.error && meta.touched && 
                                                  <span className="text-danger">{meta.error}</span>}
                                                </div>
                                              )}
                                            </Field>
                                          </FormGroup>
                                          </Col>
                                          </Row>
                                          <Row >
                                          <Col md={6}>
                                          <FormGroup>
                                            <Field name="npassword">
                                              {({ input, meta }) => (
                                                <div>
                                                  <Label for="password">{t('New Password')}</Label>
                                                  <Input {...input} type="password" 
                                                    invalid={meta.error && meta.touched} 
                                                    placeholder={t('New Password')}
                                                  />
                                                  {meta.error && meta.touched && 
                                                  <span className="text-danger">{meta.error}</span>}
                                                </div>
                                              )}
                                            </Field>
                                          </FormGroup>
                                          </Col>
                                          <Col md={6}>
                                            <FormGroup>
                                              <Field name="cpassword">
                                                {({ input, meta }) => (
                                                  <div>
                                                    <Label for="npassword">{t('Confirm New Password')}</Label>
                                                    <Input {...input} type="password" 
                                                      invalid={meta.error && meta.touched} 
                                                      placeholder={t('Confirm New Password')}
                                                    />
                                                    {meta.error && meta.touched && 
                                                    <span className="text-danger">{meta.error}</span>}
                                                  </div>
                                                )}
                                              </Field>
                                            </FormGroup>
                                            </Col>
                                          </Row>
                                          
                                            <FormGroup>
                                              <div className="d-flex justify-content-end">
                                                <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-light btn-hover-shine me-3 mt-3">{t('Cancel')}</button>
                                                <button className="btn btn-custom-color btn-hover-shine me-3 mt-3" disabled={!valid}>Update</button>
                                              </div>
                                            </FormGroup>

                                        
                                        
                                    </LoadingOverlay>
                                  </form>
                                )}
                              />
                            </CardBody>
                         
                          </Card>
                          </div>
            
            
          </div>
        </div>
        
        </div>
      </div>
    </Fragment>
  );

};
export default MyProfile;














// import React, { useState, useRef } from "react";
// import { useNavigate } from "react-router-dom";
// import Form from "react-validation/build/form";
// import Input from "react-validation/build/input";
// import CheckButton from "react-validation/build/button";
// import UserService from "../Users Management/users.service";


// const required = (value) => {
//   if (!value) {
//     return (
//       <div className="invalid-feedback d-block">
//         This field is required!
//       </div>
//     );
//   }
// };

// const Profile = () => {

//   const currentUser = UserService.getCurrentUser();
//   const form = useRef();
//   const checkBtn = useRef();
//   const form1 = useRef();
//   const checkProfileBtn = useRef();

//   const navigate = useNavigate();

//   //For profile info
//   const [uid, setID] = useState(currentUser._id);
//   const [fullname, setFullName] = useState(currentUser.name);
//   const [status, setStatus] = useState(currentUser.status);
//   const [role_id, setRoleID] = useState(currentUser.role_id);

//   const [loadingprofile, setLoadingProfile] = useState(false);
//   const [messageprofile, setMessageProfile] = useState("");

//   const onChangeID = (e) => {
//     const uid = e.target.value;
//     setID(uid);
//   };

//   const onChangeFullName = (e) => {
//     const fullname = e.target.value;
//     setFullName(fullname);
//   };

//   const onChangeStatus = (e) => {
//     const status = e.target.value;
//     setStatus(status);
//   };

//   const onChangeRoleID = (e) => {
//     const role_id = e.target.value;
//     setRoleID(role_id);
//   };

//   const handleProfile = (e) => {
//     e.preventDefault();

//     setMessageProfile("");
//     setLoadingProfile(true);

//     form1.current.validateAll();

//     //For profile info
//     if (checkProfileBtn.current.context._errors.length === 0) {
//       console.log(uid);
//       console.log(fullname);
//       console.log(status);
//       console.log(role_id);
//       UserService.updateUserInfo(uid, fullname, status, role_id).then(
//         () => {
//           window.location.reload(false);
//           alert(t("Your Info is updated!"));
//         },
//         (error) => {
//           const resMessage =
//             (error.response &&
//               error.response.data &&
//               error.response.data.message) ||
//             error.message ||
//             error.toString();

//           setLoadingProfile(false);
//           setMessageProfile(resMessage);
//         }
//       );
//     } else {
//       setLoadingProfile(false);
//     }

//   };

//   //For password
//   const [loading, setLoading] = useState(false);
//   const [message, setMessage] = useState("");
//   const [password, setPassword] = useState("");
//   const [newpassword, setNewPassword] = useState("");
//   const [confirmpassword, setConfirmPassword] = useState("");

//   const onChangePassword = (e) => {
//     const password = e.target.value;
//     setPassword(password);
//   };
//   const onChangeNewPassword = (e) => {
//     const newpassword = e.target.value;
//     setNewPassword(newpassword);
//   };
//   const onChangeConfirmPassword = (e) => {
//     const confirmpassword = e.target.value;
//     setConfirmPassword(confirmpassword);
//   };


//   const handleLogin = (e) => {
//     e.preventDefault();

//     setMessage("");
//     setLoading(true);

//     form.current.validateAll();

//     //For password
//     if (checkBtn.current.context._errors.length === 0) {
      
//       UserService.changeUserPassword(uid,password, newpassword, confirmpassword).then(
//         () => {
//           navigate("/");
//           alert(t("Your Password Has been changed please LogIn again!"));
//           window.location.reload();
//         },
//         (error) => {
//           const resMessage =
//             (error.response &&
//               error.response.data &&
//               error.response.data.message) ||
//             error.message ||
//             error.toString();

//           setLoading(false);
//           setMessage(resMessage);
//         }
//       );
//     } else {
//       setLoading(false);
//     }
//   };
//   return (
//     <>
    
//       <div className="container-fluid">
//         { }
//         <div className="row">
//           <div className="col-sm-12">
//             <div className="page-title-box">
//               {/* <div className="float-end">
//                 <button type="button" className="btn btn-primary">
//                   Add New Profile
//                 </button>
//               </div> */}
//               <h4 className="page-title">Profile</h4>
//             </div>
//             { }
//           </div>
//           { }
//         </div>
//         { }

//         <div className="row">
//           <div className="col-lg-6 col-xl-6">
//             <div className="card">
//               <div className="card-header">
//                 <div className="row align-items-center">
//                   <div className="col">
//                     <h4 className="card-title">
//                       Personal Information
//                     </h4>
//                   </div>

//                 </div>{" "}

//               </div>

//               <div className="card-body">
//                 <Form onSubmit={handleProfile} ref={form1}>
//                   <Input
//                     type="hidden"
//                     name="uid"
//                     value={uid}
//                     onChange={onChangeID}
//                   />
//                   <div className="form-group mb-3 row">
//                     <label htmlFor="fullname" className="col-xl-3 col-lg-3 text-end mb-lg-0 align-self-center form-label">
//                       Full Name
//                     </label>
//                     <div className="col-lg-9 col-xl-8">
//                       <Input
//                         type="text"
//                         className="form-control"
//                         name="fullname"
//                         value={fullname}
//                         onChange={onChangeFullName}
//                         validations={[required]}
//                       />
//                     </div>
//                   </div>
//                   {/* <div className="form-group mb-3 row">
//                     <label htmlFor="role_id" className="col-xl-3 col-lg-3 text-end mb-lg-0 align-self-center form-label">
//                       Role id
//                     </label>
//                     <div className="col-lg-9 col-xl-8">
//                       <Input
//                         type="text"
//                         className="form-control"
//                         name="role_id"
//                         value={role_id}
//                         onChange={onChangeRoleID}
//                         validations={[required]}
//                       />
//                     </div>
//                   </div> */}
//                   {/* <div className="form-group mb-3 row">
//                     <label htmlFor="status" className="col-xl-3 col-lg-3 text-end mb-lg-0 align-self-center form-label">
//                       Status
//                     </label>
//                     <div className="col-lg-9 col-xl-8">
//                       <select className="form-select" name="status" id="status" value={status}>
//                         <option value="0">Inactive</option>
//                         <option value="1">Active</option>
//                       </select>
//                     </div>
//                   </div> */}
//                   <div className="form-group mb-3 row">
//                     <label className="col-xl-3 col-lg-3 text-end mb-lg-0 align-self-center form-label">
//                       Email Address
//                     </label>
//                     <div className="col-lg-9 col-xl-8">
//                       <div className="input-group">
//                         <span className="input-group-text">
//                           <i className="las la-at" />
//                         </span>
//                         <input
//                           type="text"
//                           className="form-control"
//                           defaultValue={"" + currentUser.email + ""}
//                           placeholder="Email"
//                           aria-describedby="basic-addon1"
//                           disabled
//                         />
//                       </div>
//                     </div>
//                   </div>
//                   <div className="form-group mb-3 row">
//                     <label className="col-xl-3 col-lg-3 text-end mb-lg-0 align-self-center form-label">
//                       Created
//                     </label>
//                     <div className="col-lg-9 col-xl-8">
//                       <div className="input-group">
//                         <span className="input-group-text">
//                           <i className="las la-phone" />
//                         </span>
//                         <input
//                           type="text"
//                           className="form-control"
//                           defaultValue={"" + currentUser.datetime_added + ""}
//                           placeholder="Created on"
//                           aria-describedby="basic-addon1"
//                           disabled
//                         />
//                       </div>
//                     </div>
//                   </div>
//                   <div className="form-group mb-3 row">
//                     <label className="col-xl-3 col-lg-3 text-end mb-lg-0 align-self-center form-label">
//                       Last login
//                     </label>
//                     <div className="col-lg-9 col-xl-8">
//                       <div className="input-group">
//                         <span className="input-group-text">
//                           <i className="la la-globe" />
//                         </span>
//                         <input
//                           type="text"
//                           className="form-control"
//                           defaultValue={"" + currentUser.last_login + ""}
//                           placeholder="Last login Datetime"
//                           aria-describedby="basic-addon1"
//                           disabled
//                         />
//                       </div>
//                     </div>
//                   </div>
//                   <div className="form-group mb-3 row">
//                     <label className="col-xl-3 col-lg-3 text-end mb-lg-0 align-self-center form-label">
//                       Super Admin
//                     </label>
//                     <div className="col-lg-9 col-xl-8">
//                       <select disabled className="form-select" name="super_admin" id="super_admin" value={currentUser.super_admin}>
//                         <option value="0">No</option>
//                         <option value="1">Yes</option>
//                       </select>
//                     </div>
//                   </div>
//                   <div className="form-group mb-3 row">
//                     <div className="col-lg-9 col-xl-8 offset-lg-3">
//                       <center>
//                         <button className="btn btn-custom-color btn-block me-2" disabled={loadingprofile}>
//                           {loading && (
//                             <span className="spinner-border spinner-border-sm"></span>
//                           )}
//                           Update
//                         </button>
//                       </center>
//                       {/* <button
//                         type="button"
//                         className="btn btn-de-danger"
//                       >
//                         Cancel
//                       </button> */}
//                     </div>
//                   </div>
//                   <CheckButton style={{ display: "none" }} ref={checkProfileBtn} />
//                 </Form>
//               </div>
//             </div>
//           </div>{" "}

//           <div className="col-lg-6 col-xl-6">
//             <div className="card">
//               <div className="card-header">
//                 <h4 className="card-title">Change Password</h4>
//               </div>

//               <div className="card-body">
//                 <Form onSubmit={handleLogin} ref={form}>
//                   <div className="form-group mb-3 row">
//                     <label htmlFor="password" className="col-xl-3 col-lg-3 text-end mb-lg-0 align-self-center form-label">
//                       Current Password
//                     </label>
//                     <div className="col-lg-9 col-xl-8">
//                       <Input
//                         type="password"
//                         className="form-control"
//                         name="password"
//                         value={password}
//                         onChange={onChangePassword}
//                         validations={[required]}
//                       />
//                       {/* <a
//                         href="/forgot-password"
//                         className="text-primary font-12"
//                       >
//                         Forgot password ?
//                       </a> */}
//                     </div>
//                   </div>

//                   <div className="form-group mb-3 row">
//                     <label htmlFor="newpassword" className="col-xl-3 col-lg-3 text-end mb-lg-0 align-self-center form-label">
//                       New Password
//                     </label>
//                     <div className="col-lg-9 col-xl-8">
//                       <Input
//                         type="password"
//                         className="form-control"
//                         name="newpassword"
//                         placeholder="New-Password"
//                         value={newpassword}
//                         onChange={onChangeNewPassword}
//                         validations={[required]}
//                       />
//                     </div>
//                   </div>
//                   <div className="form-group mb-3 row">
//                     <label className="col-xl-3 col-lg-3 text-end mb-lg-0 align-self-center form-label">
//                       Confirm Password
//                     </label>
//                     <div className="col-lg-9 col-xl-8">
//                       <Input
//                         type="password"
//                         className="form-control"
//                         placeholder="Confirm New-Password"
//                         name="confirmpassword"
//                         value={confirmpassword}
//                         onChange={onChangeConfirmPassword}
//                         validations={[required]}
//                       />
//                     </div>
//                   </div>
//                   <div className="form-group mb-3 row">
//                     <div className="col-lg-9 col-xl-8 offset-lg-3">
//                       <center>
//                         <button className="btn btn-custom-color btn-block me-2" disabled={loading}>
//                           {loading && (
//                             <span className="spinner-border spinner-border-sm"></span>
//                           )}
//                           Change Password
//                         </button>
//                       </center>
//                       {/* <button
//                         type="button"
//                         className="btn btn-de-danger">
//                         Cancel
//                       </button> */}
//                     </div>
//                   </div>
//                   {message && (
//                     <div className="form-group">
//                       <div className="alert alert-danger" role="alert">
//                         {message}
//                       </div>
//                     </div>
//                   )}
//                   <CheckButton style={{ display: "none" }} ref={checkBtn} />
//                 </Form>
//               </div>

//             </div>



//           </div>
//           {" "}

//         </div>
//       </div>
          
//     </>
//   );
// };

// export default Profile;
