import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  FormGroup,
  Label,
} from "reactstrap";
import { Form, Field } from "react-final-form";
import Select from "react-select";
import { countries } from "countries-list";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import Alerts from "../../../components/Alerts/alerts";
import AttributesService from "./fields.service";
import AuthService from "../../Authentication/auth.service";

// Country-to-Timezone Mapping
const countryTimezones = {
  AF: "Asia/Kabul",
  AL: "Europe/Tirane",
  DZ: "Africa/Algiers",
  AS: "Pacific/Pago_Pago",
  AD: "Europe/Andorra",
  AO: "Africa/Luanda",
  AI: "America/Anguilla",
  AQ: "Antarctica/McMurdo",
  AG: "America/Antigua",
  AR: "America/Argentina/Buenos_Aires",
  AM: "Asia/Yerevan",
  AW: "America/Aruba",
  AU: "Australia/Sydney",
  AT: "Europe/Vienna",
  AZ: "Asia/Baku",
  BS: "America/Nassau",
  BH: "Asia/Bahrain",
  BD: "Asia/Dhaka",
  BB: "America/Barbados",
  BY: "Europe/Minsk",
  BE: "Europe/Brussels",
  BZ: "America/Belize",
  BJ: "Africa/Porto-Novo",
  BM: "Atlantic/Bermuda",
  BT: "Asia/Thimphu",
  BO: "America/La_Paz",
  BA: "Europe/Sarajevo",
  BW: "Africa/Gaborone",
  BR: "America/Sao_Paulo",
  BN: "Asia/Brunei",
  BG: "Europe/Sofia",
  BF: "Africa/Ouagadougou",
  BI: "Africa/Bujumbura",
  KH: "Asia/Phnom_Penh",
  CM: "Africa/Douala",
  CA: "America/Toronto",
  CV: "Atlantic/Cape_Verde",
  KY: "America/Cayman",
  CF: "Africa/Bangui",
  TD: "Africa/Ndjamena",
  CL: "America/Santiago",
  CN: "Asia/Shanghai",
  CO: "America/Bogota",
  KM: "Indian/Comoro",
  CG: "Africa/Brazzaville",
  CD: "Africa/Kinshasa",
  CR: "America/Costa_Rica",
  CI: "Africa/Abidjan",
  HR: "Europe/Zagreb",
  CU: "America/Havana",
  CY: "Asia/Nicosia",
  CZ: "Europe/Prague",
  DK: "Europe/Copenhagen",
  DJ: "Africa/Djibouti",
  DM: "America/Dominica",
  DO: "America/Santo_Domingo",
  EC: "America/Guayaquil",
  EG: "Africa/Cairo",
  SV: "America/El_Salvador",
  GQ: "Africa/Malabo",
  ER: "Africa/Asmara",
  EE: "Europe/Tallinn",
  SZ: "Africa/Mbabane",
  ET: "Africa/Addis_Ababa",
  FJ: "Pacific/Fiji",
  FI: "Europe/Helsinki",
  FR: "Europe/Paris",
  GA: "Africa/Libreville",
  GM: "Africa/Banjul",
  GE: "Asia/Tbilisi",
  DE: "Europe/Berlin",
  GH: "Africa/Accra",
  GR: "Europe/Athens",
  GD: "America/Grenada",
  GT: "America/Guatemala",
  GN: "Africa/Conakry",
  GW: "Africa/Bissau",
  GY: "America/Guyana",
  HT: "America/Port-au-Prince",
  HN: "America/Tegucigalpa",
  HU: "Europe/Budapest",
  IS: "Atlantic/Reykjavik",
  IN: "Asia/Kolkata",
  ID: "Asia/Jakarta",
  IR: "Asia/Tehran",
  IQ: "Asia/Baghdad",
  IE: "Europe/Dublin",
  IL: "Asia/Jerusalem",
  IT: "Europe/Rome",
  JM: "America/Jamaica",
  JP: "Asia/Tokyo",
  JO: "Asia/Amman",
  KZ: "Asia/Almaty",
  KE: "Africa/Nairobi",
  KI: "Pacific/Tarawa",
  KP: "Asia/Pyongyang",
  KR: "Asia/Seoul",
  KW: "Asia/Kuwait",
  KG: "Asia/Bishkek",
  LA: "Asia/Vientiane",
  LV: "Europe/Riga",
  LB: "Asia/Beirut",
  LS: "Africa/Maseru",
  LR: "Africa/Monrovia",
  LY: "Africa/Tripoli",
  LI: "Europe/Vaduz",
  LT: "Europe/Vilnius",
  LU: "Europe/Luxembourg",
  MG: "Indian/Antananarivo",
  MW: "Africa/Blantyre",
  MY: "Asia/Kuala_Lumpur",
  MV: "Indian/Maldives",
  ML: "Africa/Bamako",
  MT: "Europe/Malta",
  MH: "Pacific/Majuro",
  MR: "Africa/Nouakchott",
  MU: "Indian/Mauritius",
  MX: "America/Mexico_City",
  FM: "Pacific/Pohnpei",
  MD: "Europe/Chisinau",
  MC: "Europe/Monaco",
  MN: "Asia/Ulaanbaatar",
  ME: "Europe/Podgorica",
  MA: "Africa/Casablanca",
  MZ: "Africa/Maputo",
  MM: "Asia/Yangon",
  NA: "Africa/Windhoek",
  NR: "Pacific/Nauru",
  NP: "Asia/Kathmandu",
  NL: "Europe/Amsterdam",
  NZ: "Pacific/Auckland",
  NI: "America/Managua",
  NE: "Africa/Niamey",
  NG: "Africa/Lagos",
  NU: "Pacific/Niue",
  MK: "Europe/Skopje",
  NO: "Europe/Oslo",
  OM: "Asia/Muscat",
  PK: "Asia/Karachi",
  PW: "Pacific/Palau",
  PA: "America/Panama",
  PG: "Pacific/Port_Moresby",
  PY: "America/Asuncion",
  PE: "America/Lima",
  PH: "Asia/Manila",
  PL: "Europe/Warsaw",
  PT: "Europe/Lisbon",
  QA: "Asia/Qatar",
  RO: "Europe/Bucharest",
  RU: "Europe/Moscow",
  RW: "Africa/Kigali",
  KN: "America/St_Kitts",
  LC: "America/St_Lucia",
  VC: "America/St_Vincent",
  WS: "Pacific/Apia",
  SM: "Europe/San_Marino",
  ST: "Africa/Sao_Tome",
  SA: "Asia/Riyadh",
  SN: "Africa/Dakar",
  RS: "Europe/Belgrade",
  SC: "Indian/Mahe",
  SL: "Africa/Freetown",
  SG: "Asia/Singapore",
  SK: "Europe/Bratislava",
  SI: "Europe/Ljubljana",
  SB: "Pacific/Guadalcanal",
  SO: "Africa/Mogadishu",
  ZA: "Africa/Johannesburg",
  SS: "Africa/Juba",
  ES: "Europe/Madrid",
  LK: "Asia/Colombo",
  SD: "Africa/Khartoum",
  SR: "America/Paramaribo",
  SE: "Europe/Stockholm",
  CH: "Europe/Zurich",
  SY: "Asia/Damascus",
  TW: "Asia/Taipei",
  TJ: "Asia/Dushanbe",
  TZ: "Africa/Dar_es_Salaam",
  TH: "Asia/Bangkok",
  TL: "Asia/Dili",
  TG: "Africa/Lome",
  TO: "Pacific/Tongatapu",
  TT: "America/Port_of_Spain",
  TN: "Africa/Tunis",
  TR: "Europe/Istanbul",
  TM: "Asia/Ashgabat",
  TV: "Pacific/Funafuti",
  UG: "Africa/Kampala",
  UA: "Europe/Kiev",
  AE: "Asia/Dubai",
  GB: "Europe/London",
  US: "America/New_York",
  UY: "America/Montevideo",
  UZ: "Asia/Tashkent",
  VU: "Pacific/Efate",
  VE: "America/Caracas",
  VN: "Asia/Ho_Chi_Minh",
  YE: "Asia/Aden",
  ZM: "Africa/Lusaka",
  ZW: "Africa/Harare",
  // Add more as needed
};

// Generate country options
const countryOptions = Object.keys(countries).map((code) => ({
  value: code,
  label: `${countries[code].name} (${countries[code].currency})`,
}));

const AddAttribute = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const validateFtn = (values) => {
    const errors = {};
    if (!values.name) errors.name = "Name Required.";
    if (!values.currencyCode) errors.currencyCode = "Currency Code Required.";
    if (!values.currencySymbol) errors.currencySymbol = "Currency Symbol Required.";
    if (!values.timezone) errors.timezone = "Time Zone Required.";
    if (!values.exchangeRate || isNaN(values.exchangeRate)) {
      errors.exchangeRate = "Valid Exchange Rate Required.";
    }
    return errors;
  };

  const onSubmit = (values) => {
    console.log("Form Values:", values);
    const payloadData = {
      functionName: "ADD_Currency",
      name: values.name,
      code: values.currencyCode,
      symbol: values.currencySymbol,
      timezone: values.timezone,
      rate: parseFloat(values.exchangeRate),
    };

    setLoading(true);
    AttributesService.ADD_Currency(payloadData).then((response) => {
      setLoading(false);
      if (response.data.status) {
        navigate("/Finance-Settings");
        Alerts.swalSuccessAlert(response.data.message || t("Class added successfully."));
      } else {
        Alerts.swalErrorAlert(response.data.message || t("Error adding Currency."));
      }
    }).catch((error) => {
      setLoading(false);
      Alerts.swalErrorAlert(t("Network Operation failed."));
    });
  }

  return (
    <>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className="app-page-title">
            <div className="page-title-wrapper justify-content-left">
              <div className="page-title-heading fw-bold">
                <div className="page-title-icon">
                  <i className="pe-7s-plus icon-gradient bg-happy-green" />
                </div>
                <div>
                  {t('Add New Currency')}
                  <div className="page-title-subheading">
                    {t('Fill the form below to add a Currency.')}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center">
            <div className="col-md-6">
              <Card className="main-card mb-3">
                <CardBody>
                  <LoadingOverlay active={loading} spinner={<DNALoader />}>
                    <Form
                      onSubmit={onSubmit}
                      validate={validateFtn}
                      render={({ handleSubmit, form }) => (
                        <form onSubmit={handleSubmit}>
                          <Row>
                            {/* Name */}
                            <Col md="12">
                              <Label>Name</Label>
                              <Field name="name">
                                {({ input, meta }) => (
                                  <Input {...input} placeholder="Name for Currency" invalid={meta.error && meta.touched} />
                                )}
                              </Field>
                            </Col>

                            {/* Country Selector */}
                            <Col md="12">
                              <Label>Country</Label>
                              <Field name="currencyCode">
                                {({ input, meta }) => (
                                  <Select
                                    {...input}
                                    options={countryOptions}
                                    placeholder="Select a country"
                                    onChange={(option) => {
                                      const country = countries[option.value];
                                      form.change("currencyCode", option.value);
                                      form.change("name", country.name);
                                      form.change("currencySymbol", country.currency);
                                      form.change("timezone", countryTimezones[option.value] || "");
                                    }}
                                    invalid={meta.error && meta.touched}
                                  />
                                )}
                              </Field>
                            </Col>

                            {/* Currency Symbol */}
                            <Col md="12">
                              <Label>Currency Symbol</Label>
                              <Field name="currencySymbol">
                                {({ input, meta }) => (
                                  <Input {...input} placeholder="e.g., $, ₱" invalid={meta.error && meta.touched} />
                                )}
                              </Field>
                            </Col>

                            {/* Time Zone - Auto-selected */}
                            <Col md="12">
                              <Label>Time Zone</Label>
                              <Field name="timezone">
                                {({ input, meta }) => (
                                  <Input {...input} placeholder="Time Zone" readOnly invalid={meta.error && meta.touched} />
                                )}
                              </Field>
                            </Col>

                            {/* Exchange Rate */}
                            <Col md="12">
                              <Label>Exchange Rate</Label>
                              <Field name="exchangeRate">
                                {({ input, meta }) => (
                                  <Input
                                    {...input}
                                    placeholder="e.g., 1.0 for base currency"
                                    type="number"
                                    step="0.01"
                                    invalid={meta.error && meta.touched}
                                  />
                                )}
                              </Field>
                            </Col>
                          </Row>

                          {/* Submit Buttons */}
                          <Row className="mt-4">
                            <Col className="text-center">
                              <Button type="submit" color="secondary" size="lg" className="me-3">
                                Save
                              </Button>
                              <Button
                                type="button"
                                color="outline-success"
                                size="lg"
                                onClick={() => navigate("/Finance-Settings")}
                              >
                                Cancel
                              </Button>
                            </Col>
                          </Row>
                        </form>
                      )}
                    />
                  </LoadingOverlay>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </div >
    </>
  );
};

export default AddAttribute;
