import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import {
  Typography,
  Card,
  CardMedia,
  Box,
  Modal,
  Fade,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Services from "./services.js";
import AuthService from "../Authentication/auth.service";
import Alerts from "../../components/Alerts/alerts";
import PermissionAccess from "../../PermissionAccess/Access.js";

const StyledPaper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.default,
  boxShadow: theme.shadows[3],
  borderRadius: theme.shape.borderRadius * 2,
}));

const ImageRowContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-around",
  gap: theme.spacing(3),
  marginTop: theme.spacing(4),
  flexWrap: "nowrap", // Ensure the items remain in a single row
}));

const DocumentCard = styled(Card)(({ theme }) => ({
  width: 180,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  "&:hover": {
    boxShadow: theme.shadows[6],
  },
}));

const DocumentImage = styled(CardMedia)(({ theme }) => ({
  width: "100%",
  height: 150,
  objectFit: "cover",
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(1),
}));

const LabelText = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: "bold",
  textAlign: "center",
  color: theme.palette.text.primary,
}));

const StyledModal = styled(Modal)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const ModalContent = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  borderRadius: theme.shape.borderRadius,
  maxWidth: "90vw",
  maxHeight: "90vh",
  overflow: "auto",
}));

const MemberDetailsComponent = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { state: Member } = location;
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [Documents, setDocuments] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const navigate = useNavigate();
  const { authData } = PermissionAccess();

  const handleDocumentClick = (doc) => {
    setSelectedDocument(doc);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedDocument(null);
  };

  useEffect(() => {
    if (Documents.length === 0) {
      fetchDocuments();
    }
  }, []);

  const fetchDocuments = () => {
    const getData = {
      functionName: "GetMembersDocuments",
      postData: {
        member_id: Member._id,
      },
    };
    setLoading(true);
    Services.GetMemberDocuments(authData?.sessionID, getData)
      .then((response) => {
        setLoading(false);
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        } else {
          setDocuments(response.data.data[0]);
        }
      })
      .catch((error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        Alerts.swalErrorAlert(errContent || t("Server Error, Please try again later."));
      });
  };

  return (
    <LoadingOverlay active={loading} spinner={<DNALoader />}>
      <StyledPaper>
        <ImageRowContainer>
          {/* Front ID */}
          <DocumentCard onClick={() => handleDocumentClick(Documents?.frontId)}>
            {Documents?.frontId ? (
              Documents.frontId.toLowerCase().startsWith("data:image") ? (
                <DocumentImage component="img" image={Documents?.frontId} alt="Front ID" />
              ) : (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  height={150}
                  bgcolor="grey.200"
                >
                  <PictureAsPdfIcon style={{ fontSize: 64, color: "red" }} />
                </Box>
              )
            ) : (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height={150}
                bgcolor="grey.200"
              >
                {t("No Image Available")}
              </Box>
            )}
            <LabelText>{t("Front ID")}</LabelText>
          </DocumentCard>

          {/* Back ID */}
          <DocumentCard onClick={() => handleDocumentClick(Documents?.backId)}>
            {Documents?.backId ? (
              Documents.backId.toLowerCase().startsWith("data:image") ? (
                <DocumentImage component="img" image={Documents?.backId} alt="Back ID" />
              ) : (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  height={150}
                  bgcolor="grey.200"
                >
                  <PictureAsPdfIcon style={{ fontSize: 64, color: "red" }} />
                </Box>
              )
            ) : (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height={150}
                bgcolor="grey.200"
              >
                {t("No Image Available")}
              </Box>
            )}
            <LabelText>{t("Back ID")}</LabelText>
          </DocumentCard>

          {/* Selfie */}
          <DocumentCard onClick={() => handleDocumentClick(Documents?.selfie)}>
            {Documents?.selfie ? (
              Documents.selfie.toLowerCase().startsWith("data:image") ? (
                <DocumentImage component="img" image={Documents?.selfie} alt="Selfie" />
              ) : (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  height={150}
                  bgcolor="grey.200"
                >
                  <PictureAsPdfIcon style={{ fontSize: 64, color: "red" }} />
                </Box>
              )
            ) : (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height={150}
                bgcolor="grey.200"
              >
                {t("No Image Available")}
              </Box>
            )}
            <LabelText>{t("Selfie")}</LabelText>
          </DocumentCard>
          {/* Front ID */}
          <DocumentCard onClick={() => handleDocumentClick(Member?.payment_slip)}>
            {Member?.payment_slip ? (
              Member?.payment_slip.toLowerCase().startsWith("data:image") ? (
                <DocumentImage component="img" image={Member?.payment_slip} alt="Front ID" />
              ) : (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  height={150}
                  bgcolor="grey.200"
                >
                  <PictureAsPdfIcon style={{ fontSize: 64, color: "red" }} />
                </Box>
              )
            ) : (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height={150}
                bgcolor="grey.200"
              >
                {t("No Image Available")}
              </Box>
            )}
            <LabelText>{t("Payment Slip")}</LabelText>
          </DocumentCard>
        </ImageRowContainer>
      </StyledPaper>

      {/* Modal for viewing larger images */}
      <StyledModal open={modalOpen} onClose={handleCloseModal} closeAfterTransition>
        <Fade in={modalOpen}>
          <ModalContent>
            {selectedDocument ? (
              selectedDocument.toLowerCase().endsWith(".pdf") ? (
                <iframe
                  src={selectedDocument}
                  width="100%"
                  height="500px"
                  title="PDF Document"
                />
              ) : (
                <img
                  src={selectedDocument}
                  alt="Document"
                  style={{ maxWidth: "100%", maxHeight: "80vh" }}
                />
              )
            ) : null}
          </ModalContent>
        </Fade>
      </StyledModal>
    </LoadingOverlay>
  );
};

export default MemberDetailsComponent;