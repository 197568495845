import React, { useState, useMemo } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, FormGroup } from 'reactstrap';
import { FaFileUpload } from 'react-icons/fa';
import Papa from 'papaparse';
import CustomerService from "./dashboard.service.js";
import AuthService from "../Authentication/auth.service";
import Alerts from '../../components/Alerts/alerts';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const InvoiceUpload = ({ authData }) => {
  const [file, setFile] = useState(null);
  const [showMappingModal, setShowMappingModal] = useState(false);
  const [csvHeaders, setCsvHeaders] = useState([]);
  const [fieldMapping, setFieldMapping] = useState({});
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [processedData, setProcessedData] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation();


  // const requiredFields = [
  //   'Customer_Name', 'Customer_TIN', 'Customer_ID_Type',
  //   'Customer_ID_Value', 'Customer_Email', 'Customer_Address',

  //   "invoice_number", "order_number", "due_date",
  //   // "companyName",
  //   // "companyAddress",
  //   // "companyEmail",
  //   // "business_id",
  //   "invoice_type",
  //   // "customer_id",
  //   "comments",

  //   "item_name",
  //   "item_description",
  //   "item_tax_class",
  //   "item_quantity",
  //   "item_type",
  //   "item_tax",
  //   "item_amount",
  //   "item_rate",
  //   "item_sku",

  //   "paymentMethods", "invoice_date", "currency",
  //   // "discount_type",
  //   // "discount",
  //   "discount_amount", "total_amount", "remaining_amount",
  //   "status",
  //   // "lhdn_status",
  //   // "scheduledSubmissionTime",
  //   // "datetime_added"
  // ];




  const requiredFields = [
    'Customer_Name', 'Customer_TIN', 'Customer_ID_Type',
    'Customer_ID_Value', 'Customer_Email', 'Customer_Address',
    "phone",
    "city",
    "zip",
    "invoice_number", "order_number", "due_date",
    "invoice_type", "comments", "item_name",
    "item_description", "item_tax_class", "item_quantity",
    "item_type", "item_tax", "item_amount",
    "item_rate", "item_sku", "paymentMethods",
    "invoice_date", "currency", "discount_amount",
    "total_amount", "remaining_amount", "status"
  ];

  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];
    setFile(uploadedFile);

    Papa.parse(uploadedFile, {
      header: true,
      skipEmptyLines: true,
      complete: (result) => {
        const headers = result.meta.fields;
        setCsvHeaders(headers);
        initializeFieldMapping(headers);
        setShowMappingModal(true);
      },
    });
  };

  const initializeFieldMapping = (headers) => {
    const initialMapping = requiredFields.reduce((mapping, field) => {
      mapping[field] = '';
      return mapping;
    }, {});
    setFieldMapping(initialMapping);
  };

  const handleMappingChange = (requiredField, selectedCsvField) => {
    setFieldMapping(prevMapping => {
      const updatedMapping = { ...prevMapping };

      // Remove the previous mapping if it exists
      Object.keys(updatedMapping).forEach(key => {
        if (updatedMapping[key] === selectedCsvField) {
          updatedMapping[key] = '';
        }
      });

      // Set the new mapping
      updatedMapping[requiredField] = selectedCsvField;

      return updatedMapping;
    });
  };

  const getAvailableCsvFields = (currentField) => {
    const selectedFields = new Set(Object.values(fieldMapping));
    return csvHeaders.filter(header =>
      !selectedFields.has(header) || fieldMapping[currentField] === header
    );
  };

  const processData = (results) => {
    const data = results.data;
    const processedRows = data.map(row => {
      const processedRow = {};
      Object.entries(fieldMapping).forEach(([csvField, mappedField]) => {
        if (mappedField) {
          processedRow[mappedField] = row[csvField];
        }
      });
      return processedRow;
    });
    setProcessedData(processedRows);
  };

  const handleSubmit = () => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        processData(results);
        submitInvoices(results.data);
      },
    });
  };

  const submitInvoices = (invoices) => {
    setLoading(true);

    // Group items by invoice number
    const groupedInvoices = invoices.reduce((acc, invoice) => {
      const key = invoice.invoice_number;
      if (!acc[key]) {
        acc[key] = {
          ...invoice,
          items: []
        };
      }
      acc[key].items.push({
        name: invoice.item_name,
        description: invoice.item_description,
        tax_class: invoice.item_tax_class,
        quantity: parseInt(invoice.item_quantity),
        item_type: invoice.item_type,
        tax: parseFloat(invoice.item_tax),
        amount: parseFloat(invoice.item_amount),
        rate: invoice.item_rate,
        sku: invoice.item_sku
      });
      return acc;
    }, {});

    const payloadData = {
      functionName: "Add_Bulk_Invoices",
      invoices: Object.values(groupedInvoices).map(invoice => ({
        //customer
        customer_name: invoice.Customer_Name,
        customer_tin: invoice.Customer_TIN,
        customer_id_type: invoice.Customer_ID_Type,
        customer_id_value: invoice.Customer_ID_Value,
        customer_email: invoice.Customer_Email,
        customer_address: invoice.Customer_Address,
        phone: invoice.phone,
        city: invoice.city,
        zip: invoice.zip,
        //
        external_invoice_number: invoice.invoice_number,
        order_number: invoice.order_number,
        due_date: invoice.due_date,
        invoice_type: invoice.invoice_type,
        comments: invoice.comments,
        paymentMethods: invoice.paymentMethods,
        invoice_date: invoice.invoice_date,
        currency: invoice.currency,
        discount_amount: parseFloat(invoice.discount_amount),
        total_amount: parseFloat(invoice.total_amount),
        remaining_amount: parseFloat(invoice.remaining_amount),
        status: invoice.status,
        items: invoice.items,
        business_id: "6698a715b67d920e38e7d1ab" ,
      }))
    };

    CustomerService.Add_Bulk_Invoices(payloadData, authData.sessionID).then(
      (response) => {
        setLoading(false);
        if (response.data.status) {
          // setShowMappingModal(false);
          // setShowSuccessModal(true);
          Alerts.swalSuccessAlert(response.data.message || "Invoices added successfully");
        } else {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          } else {
            Alerts.swalErrorAlert(response.data.message || t("Error: Can't add invoices at the moment. Please try again later."));
          }
        }
      },
      (error) => {
        setLoading(false);
        Alerts.swalErrorAlert(t("Network Operation failed."));
      }
    );
  };

  return (
    <div className="mb-4">
      <input
        type="file"
        accept=".csv"
        onChange={handleFileUpload}
        id="csvFileUpload"
      />
      <label htmlFor="csvFileUpload">
        <Button color="primary" className="d-flex align-items-center">
          <FaFileUpload className="mr-2" />
          Upload CSV
        </Button>
      </label>

      <Modal isOpen={showMappingModal} toggle={() => setShowMappingModal(!showMappingModal)} size="lg">
        <ModalHeader toggle={() => setShowMappingModal(!showMappingModal)}>
          Map CSV Fields to Invoice Fields
        </ModalHeader>
        <ModalBody>
          <div className="row">
            {requiredFields.map((field) => (
              <div className="col-md-6" key={field}>
                <FormGroup>
                  <Label for={`select-${field}`} className="font-weight-bold">{field}</Label>
                  <Input
                    type="select"
                    id={`select-${field}`}
                    onChange={(e) => handleMappingChange(field, e.target.value)}
                    value={fieldMapping[field] || ''}
                  >
                    <option value="">Select CSV Field</option>
                    {getAvailableCsvFields(field).map((csvField, index) => (
                      <option key={index} value={csvField}>{csvField}</option>
                    ))}
                  </Input>
                </FormGroup>
              </div>
            ))}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit} disabled={loading}>
            {loading ? 'Processing...' : 'Process Data'}
          </Button>{' '}
          <Button color="secondary" onClick={() => setShowMappingModal(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={showSuccessModal} toggle={() => setShowSuccessModal(!showSuccessModal)}>
        <ModalHeader toggle={() => setShowSuccessModal(!showSuccessModal)}>Upload Successful</ModalHeader>
        <ModalBody>
          Your CSV file has been successfully processed and invoices have been added.
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => setShowSuccessModal(false)}>OK</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default InvoiceUpload;