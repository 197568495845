import React, { Fragment, useEffect, useState } from "react";
import { Routes, Route, useNavigate, Navigate, useLocation } from "react-router-dom";
import cx from "classnames";
import ResizeDetector from "react-resize-detector";
import { useTranslation } from 'react-i18next';

import ConfigurationService from "./pages/Configuration/configuration.service";
import AuthService from "./pages/Authentication/auth.service";

import Leftbar from "./components/Left Bar/Leftbar";
import Topbar from "./components/Top Bar/Topbar";
import TermOfUse from "./pages/Dashboard/Customer/ContentPages/TermOfUse";
import PrivacyPolicy from "./pages/Dashboard/Customer/ContentPages/PrivacyPolicy";
import ReturnPolicy from "./pages/Dashboard/Customer/ContentPages/ReturnPolicy";
import NewsRoom from "./pages/Dashboard/Customer/ContentPages/NewsRoom";
import TermsAndConditions from "./pages/Dashboard/Customer/ContentPages/TermsAndConditions";
import NewPage from "./pages/Dashboard/Customer/ContentPages/NewPage";
import Reviews from "./pages/Dashboard/Customer/Reviews";
import Footer from "./components/Footer/FooterWhatsapp.js";

import Login from "./pages/Authentication/Login";
import ResetPassword from "./pages/Authentication/ResetPassword";
import ForgotPassword from "./pages/Authentication/ForgotPassword";

import CustomerLogin from "./pages/Authentication_customer/CustomerLogin";
import CustomerResetPassword from "./pages/Authentication_customer/CustomerForgotPassword";
import CustomerForgotPassword from "./pages/Authentication_customer/CustomerResetPassword";
import Register_Customer from "./pages/Authentication_customer/Register_Customer.js";


import UserLogin from "./pages/Authentication_usertypes/Login";
import UserResetPassword from "./pages/Authentication_usertypes/ResetPassword";
import UserForgotPassword from "./pages/Authentication_usertypes/ForgotPassword";
import Login_Business from "./pages/Authentication_usertypes/Login_Business.js";
import RegisterCustomer from "./pages/Authentication_usertypes/RegisterCustomer.js";


//E-Invoice
import E_Invoice_Dashboard from "./pages/E-Invoice/E-Invoice_Dashboard.js";
import E_Invoice_Documents from "./pages/E-Invoice/E-Invoice_Documents.js";
import Request_E_Invoice from "./pages/E-Invoice/Request_E_Invoice.js";
import E_Invoice_Consolidated_Invoice from "./pages/E-Invoice/E-Invoice_Consolidated_invoice.js";
import EInvoiceDetails from "./pages/E-Invoice/EInvoiceDetails.js";
import EInvoiceDetails_2 from "./pages/E-Invoice/EInvoiceDetails_2.js";



import Dashboard from "./pages/Dashboard/Dashboard";
import RedeemProducts from "./pages/Dashboard/Customer/redeemProducts";
import CustomerDashboard from "./pages/Dashboard/Customer/CustomerDashboard";
import Customer_Analytic_Dashboard from "./pages/Dashboard/Customer/Customer_Analytic_Dashboard";
import MemberDashboard from "./pages/Dashboard/Customer/MemberDashboard";
import Business_Customer_Home from "./pages/Dashboard/Customer/Business_Customer_Home";
import MyProfile from "./pages/Profile/MyProfile";
import EditProfile from "./pages/Profile/EditProfile";
import UserProfile from "./pages/Profile/UserProfile";
import MemberProfile from "./pages/Profile/MemberProfile";
import AddAddress from './pages/Profile/AddAddress';
import UpdateAddress from './pages/Profile/UpdateAddress';


import CartView from "./pages/Dashboard/Customer/CartView";
import CustomerOrders from "./pages/Dashboard/Customer/CustomerOrders";
import MemberOrders from "./pages/Dashboard/Customer/MemberOrders";
import Customer_Invoices from "./pages/Dashboard/Customer/CustomerInvoices";
import AddNewRfq from "./pages/Dashboard/Customer/add_new_rfq";
import ViewRfqs from "./pages/Dashboard/Customer/ViewRfqs";
import ViewInvoice from "./pages/Dashboard/Customer/ViewInvoice";
import EditRfqs from "./pages/Dashboard/Customer/EditRfqs";
import ReturnItems from "./pages/Dashboard/Customer/ReturnItems";
import ReturnItemsAdmin from "./pages/Dashboard/Customer/ReturnItemsAdmin";
import RequestForQuotation from "./pages/Dashboard/Customer/RequestForQuotation";
import QuotationChat from "./pages/Dashboard/Customer/QuotationChat";
import ProductDetails from "./pages/Dashboard/Customer/ProductDetails";
import CustomerTopup from "./pages/Dashboard/Customer/Topup";
import OrdersDetails from "./pages/Dashboard/Customer/OrdersDetails";
import CustomerRewards from "./pages/Dashboard/Customer/CustomerRewards";



import EmailTemplates from "./pages/EmailTemplates/EmailTemplates";
import AddEmailTemplate from "./pages/EmailTemplates/AddEmailTemplate";
import UpdateEmailTemplate from "./pages/EmailTemplates/UpdateEmailTemplate";
import ViewEmailTemplate from "./pages/EmailTemplates/ViewEmailTemplate";

import Configuration from "./pages/Configuration/Configuration";
import ProcessAutomation from "./pages/Configuration/ProcessAutomation";

import ActivityLogs from "./pages/Activity Logs/ActivityLogs";
import ViewActivityLog from "./pages/Activity Logs/ViewActivityLog";
import EmailLogs from "./pages/Email Logs/EmailLogs";
import ViewEmailLog from "./pages/Email Logs/ViewEmailLog";

import EntitiesList from "./pages/Entities/EntitiesList";
import AddEntity from "./pages/Entities/AddEntity";
import UpdateEntity from "./pages/Entities/UpdateEntity";

import AttributesList from "./pages/Attributes/AttributesList";
import AddAttribute from "./pages/Attributes/AddAttribute";
import UpdateAttribute from "./pages/Attributes/UpdateAttribute";

import AddField from "./pages/Finance/Settings/AddField.js";
import UpdateField from "./pages/Finance/Settings/UpdateField";


import RolesList from "./pages/Roles/RolesList";
import AddRole from "./pages/Roles/AddRole";
import UpdateRole from "./pages/Roles/UpdateRole";
import AssignRole from "./pages/Roles/AssignRole";

import UsersList from "./pages/Users Management/UsersList";
import AddUser from "./pages/Users Management/AddUser";
import UpdateUser from "./pages/Users Management/UpdateUser";


import ItemList from "./pages/Items/ItemsList";
import AddItem from "./pages/Items/ItemInsertionForm";
import UpdateItem from "./pages/Items/ItemUpdationForm";
import ItemSettings from "./pages/Items/Settings/Settings";


import UserTypesView from "./pages/UserTypes/DataTableView";
import UserTypesInsertionForm from "./pages/UserTypes/InsertionForm";
import UserTypesUpdationFrom from "./pages/UserTypes/UpdationFrom";

import DataTableView from "./pages/Dynamic Modules/DataTableView";
import InsertionForm from "./pages/Dynamic Modules/InsertionForm";
import UpdationFrom from "./pages/Dynamic Modules/UpdationFrom";

import PageNotFound from "./pages/Page Not Found/PageNotFound";
import RestrictedAccess from "./pages/Restricted Access/RestrictedAccess";

import UserFormList from "./pages/DynamicUserForms/UserFormList";
import AddUserForm from "./pages/DynamicUserForms/AddUserForm";
import UpdateUserForm from "./pages/DynamicUserForms/UpdateUserForm";

import DynamicSignup from "./pages/DynamicUserForms/DynamicSignup";

import UserFormAttributesList from "./pages/UserForm_Attributes/AttributesList";
import UserFormAddAttribute from "./pages/UserForm_Attributes/AddAttribute";
import UserFormUpdateAttribute from "./pages/UserForm_Attributes/UpdateAttribute";

import PaymentsDetails from "./pages/Finance/Payments/PaymentsDetails";
import CustomerInvoices from "./pages/Finance/Invoices/CustomerInvoices";
import CustomerPayments from "./pages/Finance/Payments/CustomerPayments";
import PaymentRedirectPage from "./pages/Finance/Payments/paymentRedirectPage";

import AddBuyer from "./pages/Finance/Payments/AddBuyer";
import RedirectDocumentPage from "./pages/Finance/Payments/RedirectDocumentPage";
import RedirectDocumentPageFailure from "./pages/Finance/Payments/RedirectDocumentPageFailure";


import RecurringInvoices from "./pages/Finance/RecurringInvoices/RecurringInvoices";
import AddRecurringInvoice from "./pages/Finance/RecurringInvoices/AddRecurringInvoice";
import UpdateRecurringInvoice from "./pages/Finance/RecurringInvoices/UpdateRecurringInvoice";

import InvoicesList from "./pages/Finance/Invoices/InvoicesList";
import CreditNotes from "./pages/Finance/Invoices/creditNotes";
import StatementsList from "./pages/Finance/Statements/StatementsList";
import UserStatementsList from "./pages/Finance/Statements/UserStatementsList";
import PaymentsList from "./pages/Finance/Payments/PaymentsList";
import AddInvoice from "./pages/Finance/Invoices/AddInvoice";
import Add_Requested_Invoice from "./pages/Finance/Invoices/Add_Requested_Invoice";
import UpdateInvoice from "./pages/Finance/Invoices/UpdateInvoice";
import FinanceSettings from "./pages/Finance/Settings/Settings";

import AddPayments from "./pages/Finance/Invoices/payments/AddPayments";
import UpdatePayments from "./pages/Finance/Invoices/payments/UpdatePayments";

import AddCurrency from "./pages/Finance/Settings/AddCurrency";
import UpdateCurrency from "./pages/Finance/Settings/UpdateCurrency";


import AddTaxClasses from "./pages/Finance/Settings/AddTaxClasses";
import UpdateTaxClasses from "./pages/Finance/Settings/UpdateTaxClasses";
import AddReminders from "./pages/Finance/Settings/AddReminders";
import UpdateReminders from "./pages/Finance/Settings/UpdateReminders";
import AddPaymentMethod from "./pages/Finance/Settings/AddPaymentMethod";
import UpdatePaymentMethod from "./pages/Finance/Settings/UpdatePaymentMethod";

import BusinessesList from "./pages/Business/BusinessesList.js";
import BusinessDetails from "./pages/Business/BusinessDetails.js";
import BusinessesSettings from "./pages/Business/BusinessesSettings.js";
import AddSection from "./pages/Business/AddSection.js";
import EditSection from "./pages/Business/EditSection.js";
import EditAttribute from "./pages/Business/EditAttribute.js";
import AddBusinessAttribute from "./pages/Business/AddBusinessAttribute.js";
import Logout from "./pages/Profile/Logout";

import DiscountCouponsList from "./pages/DiscountCoupon/DiscountCouponsList.js";
import OrdersList from "./pages/Order/OrdersList.js";
import OrderDetails from "./pages/Order/OrderDetails.js";
import UserOrderDetails from "./pages/Order/UserOrderDetails.js";
import SettlementReport from "./pages/Report/SettlementReport.js";
import SalesReport from "./pages/Report/SalesReport.js";
import DeliveryFeeReport from "./pages/Report/DeliveryFeeReport.js";

import UserPointsStatement from "./pages/Report/UserPointsStatement.js";
import UserStatement from "./pages/Report/UserStatement.js";


import VariationsSettings from "./pages/Variations/VariationsSettings.js";
import AddVariations from "./pages/Variations/AddVariations.js";
import EditVariations from "./pages/Variations/EditVariations.js";
import VariationsDetails from "./pages/Variations/VariationsDetails.js";
import ItemDetails from "./pages/Items/ItemDetails.js";

import WarehouseSettings from "./pages/Warehouse/WarehouseSettings.js";
import AddWarehouse from "./pages/Warehouse/AddWarehouse.js";
import EditWarehouse from "./pages/Warehouse/EditWarehouse.js";
import WarehouseDetails from "./pages/Warehouse/WarehouseDetails.js";



import CategorySettings from "./pages/Category/VariationsSettings.js";
import AddCategory from "./pages/Category/AddVariations.js";
import EditCategory from "./pages/Category/EditVariations.js";
import CategoryDetails from "./pages/Category/VariationsDetails.js";
import { useDispatch, useSelector } from 'react-redux';


import PackageDetails from "./pages/Package/PackageDetails.js";
import Package from "./pages/Package/Package.js";

import RanksDetails from "./pages/Ranks/RanksDetails.js";
import Ranks from "./pages/Ranks/Ranks.js";

import MLMTierDetails from "./pages/MLMTier/MLMTierDetails.js";
import MLMTier from "./pages/MLMTier/MLMTier.js";

import CustomerSupport from "./pages/CustomerSupport/CustomerSupport.js";
import CustomerSupportDetails from "./pages/CustomerSupport/CustomerSupportDetails.js";


import MemberDetails from "./pages/Member/MemberDetails.js";
import Member from "./pages/Member/Member.js";
import SponsorMembers from "./pages/Member/SponsorMembers.js";
import TopUpRequests from "./pages/Member/TopUpRequests.js";
import StockistRequests from "./pages/Member/StockistRequests.js";

import BannerDetails from "./pages/Banner/BannerDetails.js";
import Banner from "./pages/Banner/Banner.js";

import BulkEmails from "./pages/BulkEmails/BulkEmails.js";


import StocksDetails from "./pages/Stocks/StocksDetails.js";
import Stocks from "./pages/Stocks/Stocks.js";
import QuotationsDetails from "./pages/Quotations/QuotationsDetails.js";
import Quotations from "./pages/Quotations/Quotations.js";
import QuotationChatAdmin from "./pages/Quotations/QuotationChat";



const App = () => {
  const authData = useSelector((state) => state?.authReducer?.authData);
  let accessPages = [];
  let super_admin = false;

  if (authData?.userData?.role_details?.super_admin === true) {
    super_admin = true
  } else {
    const moduleDetails = authData?.userData?.role_details?.all_module_details;
    // Check if moduleDetails exists and is an array
    if (Array.isArray(moduleDetails)) {
      accessPages = moduleDetails.map(module => module.route);
      console.log("My Access Pages", accessPages);
    } else {
      console.log("No module details available");
    }
  }

  const userString = localStorage.getItem('user');
  const user = JSON.parse(userString);
  let user_type;
  if (user !== null) {
    user_type = user['user_type'];
  }


  const { t, i18n } = useTranslation();
  const location = useLocation();
  const pathname = window.location.pathname
  // const accessPages = localStorage.getItem('access_pages')?.split(',') ?? [];
  const [storeduser, setStoredUser] = useState(JSON.parse(localStorage.getItem("user")));
  console.log("storeduser", storeduser);
  useEffect(() => {
    getColors();
    // getSetLanguage();
  }, [storeduser]);

  function getColors() {
    const buttonsColor = localStorage.getItem("buttons_color");
    const topbarColor = localStorage.getItem("topbar_color");
    const leftbarColor = localStorage.getItem("leftbar_color");
    const buttonsTextColor = localStorage.getItem("buttons_text_color");
    setColors(buttonsColor, topbarColor, leftbarColor, buttonsTextColor);
  }

  function setColors(buttons_color, topbar_color, leftbar_color, buttons_text_color) {
    document.documentElement.style.setProperty('--buttons-color', buttons_color);
    document.documentElement.style.setProperty('--topbar-color', topbar_color);
    document.documentElement.style.setProperty('--leftbar-color', leftbar_color);
    document.documentElement.style.setProperty('--buttons-text-color', buttons_text_color);
  }

  function getSetLanguage() {
    if (localStorage.getItem("language")) {
      const language = localStorage.getItem("language");
      i18n.changeLanguage(language);
    }
    else {
      localStorage.setItem('language', 'en');
      i18n.changeLanguage('en');
    }
  }


  if (location.pathname === '/login') {
    console.log("Login Page inbound")
    return <Login />
  }

  if (location.pathname === '/RedirectPage') {
    console.log("Login Page inbound")
    return <PaymentRedirectPage />
  }


  if (location.pathname === '/AddBuyer') {
    console.log("Login Page inbound")
    return <AddBuyer />
  }

  if (location.pathname === '/Redirect_Document_Page') {
    console.log("Login Page inbound")
    return <RedirectDocumentPage />
  }
  if (location.pathname === '/Redirect_Document_Page_') {
    console.log("Login Page inbound")
    return <RedirectDocumentPageFailure />
  }

  if (location.pathname === '/forgot-password') {
    console.log("Forgot Page inbound")
    return <ForgotPassword />
  }

  if (location.pathname === '/auth-password') {
    console.log("Reset Pass Page inbound")
    return <ResetPassword />
  }

  if (pathname.includes("user-login")) {
    //if (location.pathname === '/UserLogin/:id') {
    console.log("Login Page inbound")
    return <UserLogin />
  }

  if (pathname.includes("customer_login")) {
    //if (location.pathname === '/UserLogin/:id') {
    console.log("Login Page inbound")
    return <CustomerLogin />
  }
  if (pathname.includes("customer_register")) {
    return <Register_Customer />
  }

  if (location.pathname === '/customer_forgot_password') {
    console.log("Forgot Page inbound")
    return <CustomerResetPassword />
  }



  if (location.pathname === '/customer_reset_password') {
    console.log("Reset Pass Page inbound")
    return <CustomerForgotPassword />
  }


  if (pathname.includes("Login_Customer")) {
    return <Login_Business />
  }
  if (pathname.includes("register_client")) {
    return <RegisterCustomer />
  }


  if (pathname.includes("User_Profile")) {
    return <MyProfile />
  }

  if (location.pathname === '/Userforgot-password') {
    console.log("Forgot Page inbound")
    return <UserForgotPassword />
  }



  if (location.pathname === '/Userauth-password') {
    console.log("Reset Pass Page inbound")
    return <UserResetPassword />
  }



  if (location.pathname === '/404') {
    console.log("PageNotFound inbound")
    return <PageNotFound />
  }

  if (location.pathname === '/restricted-access') {
    console.log("RestrictedAccess page inbound")
    return <RestrictedAccess />
  }



  if (location.pathname === '/CustomerRewards') {
    return <CustomerRewards />
  }
  if (location.pathname === '/CartView') {
    return <CartView />
  }

  if (location.pathname === '/edit_profile') {
    return <EditProfile />
  }
  // <Route exact path="/Log_out" element={<Logout />} />
  if (location.pathname === '/Log_out') {
    return <Logout />
  }

  else {
    return (
      <ResizeDetector handleWidth
        render={({ width }) => (
          <Fragment>
            <div
              className={cx(
                "app-container app-theme-white",
                { "fixed-header": true },
                { "fixed-sidebar": true || width < 1500 },
                { "fixed-footer": true },
                { "closed-sidebar": true || width < 1500 },
                {
                  "closed-sidebar-mobile": true || width < 1500,
                },
                { "sidebar-mobile-open": false },
                { "body-tabs-shadow-btn": true }
              )}>
              {pathname.search('/register') < 0 && <Topbar />}
              {pathname.search('/Log_out') < 0 && <Topbar />}
              <div className="app-main">

                {pathname.search('/register') < 0 && <Leftbar />}
                {pathname.search('/Log_out') < 0 && <Leftbar />}
                <Routes>
                  {/* <Route exact path={"/"} element={<Dashboard />} /> */}
                  {/* <Route exact path="/login" element={<Login />} />s
                      <Route exact path="/auth-password" element={<ResetPassword />} />
                      <Route exact path="/forgot-password" element={<ForgotPassword />} /> */}
                  <Route exact path={"/Store"} element={<CustomerDashboard />} />
                  <Route exact path={"/redeemProducts"} element={<RedeemProducts />} />
                  <Route exact path={"/customer_analytic_dashboard"} element={<Customer_Analytic_Dashboard />} />
                  <Route exact path="/home" element={<Dashboard />} />
                  <Route exact path="/" element={<Dashboard />} />
                  <Route exact path="/my-profile" element={< UserProfile />} />
                  <Route exact path="/Member-profile" element={< MemberProfile />} />
                  <Route path="/add_address" element={<AddAddress />} />
                  <Route path="/update_address" element={<UpdateAddress />} />

                  <Route exact path="/term_of_use" element={<TermOfUse />} />
                  <Route exact path="/privacy_policy" element={<PrivacyPolicy />} />
                  <Route exact path="/return_policy" element={<ReturnPolicy />} />
                  <Route exact path="/terms_and_conditions" element={<TermsAndConditions />} />
                  <Route exact path="/news_room" element={<NewsRoom />} />
                  <Route exact path="/new_page" element={<NewPage />} />
                  <Route exact path="/reviews" element={<Reviews />} />

                  {/* <Route exact path="/home" element={super_admin? <Dashboard /> :
                    accessPages.includes('home') ? <Dashboard />
                      : <Navigate to="/login" />
                  } />
                    <Route exact path="/" element={super_admin? <Dashboard /> :
                    accessPages.includes('') ? <Dashboard />
                      : <Navigate to="/login" />
                  } />
                  
                  <Route exact path="/my-profile" element={super_admin? <MyProfile /> :
                  accessPages.includes('my-profile') ? <MyProfile />
                    : <Navigate to="/404" />
                } /> */}


                  <Route exact path="/email-templates" element={super_admin ? <EmailTemplates /> :
                    accessPages.includes('email-templates') ? <EmailTemplates />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/add-email-template" element={super_admin ? <AddEmailTemplate /> :
                    accessPages.includes('add-email-template') ? <AddEmailTemplate />
                      : <Navigate to="/404" />
                  } />
                  <Route path="/update-email-template" element={<UpdateEmailTemplate />} />
                  <Route path="/view-email-template" element={<ViewEmailTemplate />} />

                  <Route exact path="/configuration" element={super_admin ? <Configuration /> :
                    accessPages.includes('configuration') ? <Configuration />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/ProcessAutomation" element={super_admin ? <ProcessAutomation /> :
                    accessPages.includes('ProcessAutomation') ? <ProcessAutomation />
                      : <Navigate to="/404" />
                  } />

                  <Route exact path="/email-logs" element={super_admin ? <EmailLogs /> :
                    accessPages.includes('Email-logs/') ? <EmailLogs />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/view-Email-log" element={<ViewEmailLog />} />
                  <Route exact path="/orders_details" element={<OrdersDetails />} />

                  <Route exact path="/activity-logs" element={super_admin ? <ActivityLogs /> :
                    accessPages.includes('activity-logs') ? <ActivityLogs />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/view-activity-log" element={<ViewActivityLog />} />

                  <Route exact path="/entities-list" element={super_admin ? <EntitiesList /> :
                    accessPages.includes('entities-list') ? <EntitiesList />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/add-entity" element={super_admin ? <AddEntity /> :
                    accessPages.includes('add-entity') ? <AddEntity />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/update-entity" element={super_admin ? <UpdateEntity /> :
                    accessPages.includes('update-entity') ? <UpdateEntity />
                      : <Navigate to="/404" />
                  } />

                  <Route exact path="/attributes-list" element={super_admin ? <AttributesList /> :
                    accessPages.includes('attributes-list') ? <AttributesList />
                      : <Navigate to="/404" />
                  } />

                  <Route exact path="/add-attribute" element={super_admin ? <AddAttribute /> :
                    accessPages.includes('add-attribute') ? <AddAttribute />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/update-attribute" element={super_admin ? <UpdateAttribute /> :
                    accessPages.includes('update-attribute') ? <UpdateAttribute />
                      : <Navigate to="/404" />
                  } />

                  <Route exact path="/add_field" element={
                    // super_admin? 
                    <AddField />
                    // : accessPages.includes('add_field') ? <AddField /> : <Navigate to="/404"/>
                  } />
                  <Route exact path="/update_field" element={
                    // super_admin ? 
                    <UpdateField />
                    //  :accessPages.includes('update_field') ? <UpdateField /> : <Navigate to="/404" />
                  } />


                  <Route exact path="/Banners" element={super_admin ? <Banner /> :
                    accessPages.includes('Banners') ? <Banner />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/Banner-details" element={<BannerDetails />} />


                  <Route exact path="/bulk_emails" element={super_admin ? <BulkEmails /> :
                    accessPages.includes('bulk_emails') ? <BulkEmails />
                      : <Navigate to="/404" />
                  } />

                  <Route exact path="/Member" element={super_admin ? <Member /> :
                    accessPages.includes('Member') ? <Member />
                      : <Navigate to="/404" />
                  } />

                  <Route exact path="/CustomerTopup" element={<CustomerTopup />} />
                  <Route exact path="/members" element={<SponsorMembers />} />
                  <Route exact path="/TopUpRequests" element={<TopUpRequests />} />
                  <Route exact path="/StockistRequests" element={<StockistRequests />} />
                  <Route exact path="/Member-details" element={<MemberDetails />} />

                  <Route exact path="/package" element={super_admin ? <Package /> :
                    accessPages.includes('package') ? <Package />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/Package-details" element={<PackageDetails />} />

                  <Route exact path="/Ranks" element={super_admin ? <Ranks /> :
                    accessPages.includes('Ranks') ? <Ranks />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/Ranks-details" element={<RanksDetails />} />

                  <Route exact path="/MLMTier" element={super_admin ? <MLMTier /> :
                    accessPages.includes('MLMTier') ? <MLMTier />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/MLM_Tier_details" element={<MLMTierDetails />} />

                  <Route exact path="/Customer_Support" element={super_admin ? <CustomerSupport /> :
                    accessPages.includes('Customer_Support') ? <CustomerSupport />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/customer-support-details" element={<CustomerSupportDetails />} />




                  <Route exact path="/Stocks" element={super_admin ? <Stocks /> :
                    accessPages.includes('Stocks') ? <Stocks />
                      : <Navigate to="/404" />

                  } />
                  <Route exact path="/Stocks-details" element={<StocksDetails />} />

                  <Route exact path="/Quotations" element={super_admin ? <Quotations /> :
                    accessPages.includes('Quotations') ? <Quotations />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/quotation_details" element={<QuotationsDetails />} />
                  <Route exact path="/CustomerOrders" element={<CustomerOrders />} />
                  <Route exact path="/roles-list" element={super_admin ? <RolesList /> :
                    accessPages.includes('roles-list') ? <RolesList />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/add-role" element={super_admin ? <AddRole /> :
                    accessPages.includes('add-role') ? <AddRole />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/update-role" element={<UpdateRole />} />
                  <Route exact path="/assign-role" element={<AssignRole />} />

                  <Route exact path="/users-list" element={super_admin ? <UsersList /> :
                    accessPages.includes('users-list') ? <UsersList />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/add-user" element={<AddUser />} />
                  <Route exact path="/update-user" element={<UpdateUser />} />

                  <Route exact path="/add_Currency" element={<AddCurrency />} />
                  <Route exact path="/update_Currency" element={<UpdateCurrency />} />


                  <Route exact path="/add_Tax_Classes" element={<AddTaxClasses />} />
                  <Route exact path="/update_Tax_Classes" element={<UpdateTaxClasses />} />
                  <Route exact path="/add_payment_method" element={<AddPaymentMethod />} />
                  <Route exact path="/update_payment_method" element={<UpdatePaymentMethod />} />
                  <Route exact path="/Add_Reminders" element={<AddReminders />} />
                  <Route exact path="/Update_Reminders" element={<UpdateReminders />} />



                  <Route path="/viewdata/:id" element={storeduser ? <DataTableView /> : <Login />} />
                  <Route path="/adddata/:id" element={storeduser ? <InsertionForm /> : <Login />} />
                  <Route path="/editdata/:id" element={storeduser ? <UpdationFrom /> : <Login />} />

                  <Route exact path="/userform-attributes-list" element={super_admin ? <UserFormAttributesList /> :
                    accessPages.includes('userform-attributes-list') ? <UserFormAttributesList />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/add-userform-attribute" element={super_admin ? <UserFormAddAttribute /> :
                    accessPages.includes('add-userform-attribute') ? <UserFormAddAttribute />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/update-userform-attribute" element={super_admin ? <UserFormUpdateAttribute /> :
                    accessPages.includes('update-userform-attribute') ? <UserFormUpdateAttribute />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/userform-list" element={super_admin ? <UserFormList /> :
                    accessPages.includes('userform-list') ? <UserFormList />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/add-userform" element={super_admin ? <AddUserForm /> :
                    accessPages.includes('add-userform') ? <AddUserForm />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/update-userform" element={super_admin ? <UpdateUserForm /> :
                    accessPages.includes('update-userform') ? <UpdateUserForm />
                      : <Navigate to="/404" />
                  } />

                  {/* <Route exact path="/driver-list" element={storeduser ? <DriverList /> : <Login />} /> */}
                  {/* <Route exact path="/add-driver" element={storeduser ? <AddDriver /> : <Login />} /> */}
                  {/* <Route exact path="/update-driver" element={storeduser ? <UpdateDriver /> : <Login />} /> */}

                  <Route path="/usertypes_view/:id" element={<UserTypesView />} />
                  <Route path="/usertypes_add/:id" element={<UserTypesInsertionForm />} />
                  <Route path="/usertypes_edit/:id" element={<UserTypesUpdationFrom />} />


                  <Route exact path="/invoices-list" element={super_admin ? <InvoicesList /> :
                    accessPages.includes('invoices-list') ? <InvoicesList />
                      : <Navigate to="/404" />
                  } />


                  <Route exact path="/credit_notes" element={super_admin ? <CreditNotes /> :
                    accessPages.includes('credit_notes') ? <CreditNotes />
                      : <Navigate to="/404" />
                  } />


                  <Route exact path="/recurring-invoices" element={super_admin ? <RecurringInvoices /> :
                    accessPages.includes('recurring-invoices') ? <RecurringInvoices />
                      : <Navigate to="/404" />
                  } />


                  {/* E-Invoice Routes */}
                  <Route exact path={"/E-Invoice_Dashboard"} element={<E_Invoice_Dashboard />} />
                  <Route exact path={"/E-Invoice_Documents"} element={<E_Invoice_Documents />} />
                  <Route exact path={"/request_e_invoice"} element={<Request_E_Invoice />} />
                  <Route exact path={"/E-Invoice_Consolidated_Invoice"} element={<E_Invoice_Consolidated_Invoice />} />
                  <Route exact path={"/e-invoice-details/:invoiceId"} element={<EInvoiceDetails />} />
                  <Route exact path={"/e-invoice-details-2/:invoiceId"} element={<EInvoiceDetails_2 />} />


                  <Route path="/CustomerInvoices" element={<CustomerInvoices />} />
                  <Route exact path={"/view_invoice/:id"} element={<ViewInvoice />} />
                  <Route path="/CustomerPayments" element={<CustomerPayments />} />
                  <Route exact path={"/MemberHome"} element={<MemberDashboard />} />
                  <Route exact path={"/Business_Customer_Home"} element={<Business_Customer_Home />} />
                  <Route exact path={"/MemberOrders"} element={<MemberOrders />} />
                  <Route exact path={"/Customer_Invoices"} element={<Customer_Invoices />} />
                  <Route exact path={"/user-statements-list"} element={<UserStatementsList />} />
                  <Route exact path={"/add_new_rfq"} element={<AddNewRfq />} />
                  <Route exact path={"/view_rfqs/:id"} element={<ViewRfqs />} />
                  <Route exact path={"/edit_rfqs/:id"} element={<EditRfqs />} />
                  <Route exact path={"/return_items/:id"} element={<ReturnItems />} />
                  <Route exact path={"/return_items_admin/:id"} element={<ReturnItemsAdmin />} />
                  <Route exact path={"/request_for_quotation"} element={<RequestForQuotation />} />
                  <Route exact path={"/chat/:id"} element={<QuotationChat />} />
                  <Route exact path={"/chat_admin/:id"} element={<QuotationChatAdmin />} />\
                  <Route exact path={"/ProductDetails/:id"} element={<ProductDetails />} />
                  <Route exact path="/payments-details" element={<PaymentsDetails />} />


                  <Route exact path="/payments-list" element={super_admin ? <PaymentsList /> :
                    accessPages.includes('payments-list') ? <PaymentsList />
                      : <Navigate to="/404" />
                  } />

                  <Route exact path="/Finance-Settings" element={super_admin ? <FinanceSettings /> :
                    accessPages.includes('Finance-Settings') ? <FinanceSettings />
                      : <Navigate to="/404" />
                  } />

                  <Route exact path="/item-Settings" element={super_admin ? <ItemSettings /> :
                    accessPages.includes('item-Settings') ? <ItemSettings />
                      : <Navigate to="/404" />
                  } />



                  <Route exact path="/statements-list" element={super_admin ? <StatementsList /> :
                    accessPages.includes('statements-list') ? <StatementsList />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/add-requested-invoice" element={<Add_Requested_Invoice />} />
                  <Route exact path="/add-invoice" element={<AddInvoice />} />
                  <Route exact path="/update-invoice" element={<UpdateInvoice />} />
                  <Route exact path="/add-recurring-invoice" element={<AddRecurringInvoice />} />
                  <Route exact path="/update-recurring-invoice" element={<UpdateRecurringInvoice />} />

                  <Route exact path="/add-payment" element={<AddPayments />} />
                  <Route exact path="/update-payment" element={<UpdatePayments />} />

                  <Route path="/register/:id" element={<DynamicSignup />} />

                  <Route exact path="/items-list" element={super_admin ? <ItemList /> :
                    accessPages.includes('items-list') ? <ItemList />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/add-Items" element={<AddItem />} />
                  <Route exact path="/update-Items" element={<UpdateItem />} />

                  <Route exact path="/businesses-list" element={super_admin ? <BusinessesList /> :
                    accessPages.includes('businesses-list') ? <BusinessesList />
                      : <Navigate to="/404" />
                  } />

                  {/* <Route exact path="/businesses-list" element={<BusinessesList />} /> */}
                  <Route exact path="/business-settings" element={<BusinessesSettings />} />
                  <Route exact path="/add-section" element={<AddSection />} />
                  <Route exact path="/add-business-attribute" element={<AddBusinessAttribute />} />
                  <Route exact path="/business-details/:id" element={<BusinessDetails />} />
                  <Route exact path="/edit-section/:id" element={<EditSection />} />
                  <Route exact path="/edit-attribute/:id" element={<EditAttribute />} />
                  <Route exact path="/discount-coupons-list" element={<DiscountCouponsList />} />

                  <Route exact path="/orders-list" element={<OrdersList />} />
                  <Route exact path="/user-order-details/:id" element={<UserOrderDetails />} />
                  <Route exact path="/order-details/:id" element={<OrderDetails />} />
                  <Route exact path="/settlement-report" element={<SettlementReport />} />
                  <Route exact path="/sales-report" element={<SalesReport />} />
                  <Route exact path="/delivery-fee-report" element={<DeliveryFeeReport />} />

                  <Route exact path="/user-points-statement" element={<UserPointsStatement />} />
                  <Route exact path="/user-statement" element={<UserStatement />} />

                  <Route exact path="/variations-details" element={<VariationsDetails />} />
                  <Route exact path="/variations-settings" element={<VariationsSettings />} />
                  <Route exact path="/add-variations" element={<AddVariations />} />
                  <Route exact path="/edit-variations/:id" element={<EditVariations />} />

                  <Route exact path="/warehouse-details" element={<WarehouseDetails />} />
                  <Route exact path="/warehouse-settings" element={<WarehouseSettings />} />
                  <Route exact path="/add-warehouse" element={<AddWarehouse />} />
                  <Route exact path="/edit-warehouse/:id" element={<EditWarehouse />} />


                  <Route exact path="/categories_list" element={<CategoryDetails />} />
                  <Route exact path="/add-category" element={<AddCategory />} />
                  <Route exact path="/edit-category/:id" element={<EditCategory />} />


                  <Route exact path="/item-details" element={<ItemDetails />} />
                  <Route path="*" element={<Navigate to="/404" />} />

                </Routes>
              </div>


              {user_type === 'Members' &&
                <Footer
                  phoneNumber="+601112800897"
                  message={`Hello, I have a question about my order`}

                />
              }

            </div>
          </Fragment>
        )}
      />
    );
  }







};

export default App;

// <Route exact path="/users" element={<UsersList />} />
// <Route path="/users/:id" element={<Users />} />
// <Route path="/datatable" element={<DataTableTest />} />
