
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { Col, Row, Button, Label, FormGroup, Input, FormText } from "reactstrap";
import { Form, Field } from "react-final-form";
import { Box, TextField } from "@mui/material";
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import AuthService from "./auth.service";
import AddressService from "./auth.service";
import AlertService from "../../components/Alerts/alerts";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faTimes, faFilePdf, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import loginLogo from '../../assets/loginLogo.svg';
import Swal from 'sweetalert2';
import { Container, Typography, useMediaQuery, useTheme } from "@mui/material";
import axios from 'axios';
import ConfigurationService from "../Configuration/configuration.service";
import FinanceService from "../Finance/Settings/configuration.service";

const Register = () => {
  const location = useLocation();
  const theme = useTheme();
  const usertype = location.pathname.split('/')[2];
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [resendTimer, setResendTimer] = useState(30);

  useEffect(() => {
    if (resendTimer > 0) {
      const timer = setInterval(() => {
        setResendTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [resendTimer]);


  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState(localStorage.getItem("system_logo"));
  localStorage.setItem("user_type", 'users');
  const [show2FAInput, setShow2FAInput] = useState(false);
  const [twoFactorCode, setTwoFactorCode] = useState(Array(6).fill(""));
  const [tempUserData, setTempUserData] = useState(null);
  const inputRefs = useRef([]);

  function getSetLanguage() {
    if (localStorage.getItem("language")) {
      const language = localStorage.getItem("language");
      i18n.changeLanguage(language);
    }
    else {
      localStorage.setItem('language', 'en');
      i18n.changeLanguage('en');
    }
  }

  function getSystemConfig() {
    ConfigurationService.getConfiguration().then((response) => {
      console.log("API Response:-", response);
      if (response.data.status) {
        setColors(response.data.data[0].buttons_color, '--buttons-color');
        setColors(response.data.data[0].topbar_color, '--topbar-color');
        setColors(response.data.data[0].leftbar_color, '--leftbar-color');
        setColors(response.data.data[0].buttons_text_color, '--buttons-text-color');
        setSystemLogo(response.data.data[0].system_logo);
        localStorage.setItem("google_api_key", response.data.data[0].google_api_key)
      }
      else {
        setColors('#3ac47d', 'onerror');
      }
    },
      (error) => {
        setColors('#3ac47d', 'onerror');
      });
  }

  function setColors(color, param) {
    if (param === '--buttons-color') {
      localStorage.setItem("buttons_color", color);
      document.documentElement.style.setProperty('--buttons-color', color)
    }
    if (param === '--topbar-color') {
      localStorage.setItem("topbar_color", color);
      document.documentElement.style.setProperty('--topbar-color', color)
    }
    if (param === '--leftbar-color') {
      localStorage.setItem("leftbar_color", color);
      document.documentElement.style.setProperty('--leftbar-color', color)
    }
    if (param === '--buttons-text-color') {
      localStorage.setItem("buttons_text_color", color);
      document.documentElement.style.setProperty('--buttons-text-color', color)
    }
    if (param === 'onerror') {
      localStorage.setItem("buttons_color", color);
      localStorage.setItem("topbar_color", '#fff');
      localStorage.setItem("leftbar_color", '#fff');
      localStorage.setItem("buttons_text_color", '#fff');
      document.documentElement.style.setProperty('--buttons-color', color)
      document.documentElement.style.setProperty('--topbar-color', '#fff')
      document.documentElement.style.setProperty('--leftbar-color', '#fff')
      document.documentElement.style.setProperty('--buttons-text-color', '#fff')
    }
  }

  function setSystemLogo(sys_logo) {
    setLogo(sys_logo);
    localStorage.setItem("system_logo", sys_logo);
  }

  function handlereset() {
    localStorage.setItem("reset", true)
  };

  function handleregprod() {
    localStorage.setItem("regprod", true)
  };


  useEffect(() => {
    getSetLanguage();
    setTimeout(() => {
      getFinanceConfig();
      getSystemConfig();
    }, 2000);
  }, []);

  const validatePassword = (password) => {
    const minLength = 6;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    return (
      password.length >= minLength &&
      hasUpperCase &&
      hasLowerCase &&
      hasNumbers &&
      hasSpecialChar
    );
  };

  function getSetLanguage() {
    const language = localStorage.getItem("language") || 'en';
    i18n.changeLanguage(language);
  }

  const onSubmit = values => {
    setLoading(true);
    AuthService.login(values.username, values.password, usertype).then((response) => {
      console.log("Login response:-", response);
      setLoading(false);
      if (response.is_2fa_enabled) {
        // If 2FA is enabled, show 2FA input instead of completing login
        setShow2FAInput(true);
        setTempUserData(response); // Store the response temporarily
        setLoading(false);
      } else {
        // If 2FA is not enabled, proceed with normal login
        completeLogin(response);
      }
    },
      (error) => {
        console.log("Login ERROR", error)
        setLoading(false);
        const resMessage =
          (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        if (resMessage === 'Unauthorized') {
          AlertService.swalErrorAlert(t("Invalid Credentials."))
        }
        else {
          AlertService.swalErrorAlert(t('Invalid Credentials'))
        }
      }
    );
  };

  const verify2FA = async () => {
    setLoading(true);
    try {
      const codeString = twoFactorCode.join(""); 
      console.log("tempUserData", tempUserData);
      const response = await axios.post(`${process.env.REACT_APP_LOGINURL}UserModule/verify-2fa`, {
        userId: tempUserData._id,
        code: codeString
      });

      if (response.data.status) {
        // If 2FA verification is successful, complete the login
        completeLogin(tempUserData);
      } else {
        setLoading(false);
        AlertService.swalErrorAlert(t('Invalid 2FA code. Please try again.'));
      }
    } catch (error) {
      setLoading(false);
      AlertService.swalErrorAlert(t('Error verifying 2FA code. Please try again.'));
    }
  };

  // New function to complete the login process
  const completeLogin = (userData) => {
    setLoading(false);
    AlertService.successToast("Login Successful.")
    navigate("/customer_analytic_dashboard");
    window.location.reload();
  };

  const resendCode = () => {
    if (resendTimer === 0) {
      setResendTimer(30);
    }
  };


  function getFinanceConfig() {
    FinanceService.getConfiguration().then((response) => {
      console.log("Finance API Response:-", response.data.data);
      if (response.data.status) {
        localStorage.setItem("payment_method", response.data.data[0]?.payment_method);
        localStorage.setItem("due_date", response.data.data[0]?.due_date);
        localStorage.setItem("default_notes", response.data.data[0]?.default_notes);
        localStorage.setItem("tax_percentage", response.data.data[0]?.tax_percentage);
        localStorage.setItem("currency", response.data.data[0]?.currency);
        localStorage.setItem("invoice_to", response.data.data[0]?.invoice_to);
        localStorage.setItem("invoice_to_field", response.data.data[0]?.invoice_to_field);
        localStorage.setItem("payment_types", response.data.data[0]?.payment_types);

      }
      else {
        setColors('#3ac47d', 'onerror');
      }
    },
      (error) => {
        setColors('#3ac47d', 'onerror');
      });
  }

  const handleInputChange = (e, index) => {
    const value = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
    if (value.length > 1) return; // Prevent more than 1 character per box

    const newCode = [...twoFactorCode];
    newCode[index] = value;
    setTwoFactorCode(newCode);

    // Move to the next field if input is valid
    if (value && index < 5) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      const newCode = [...twoFactorCode];

      // If the field is empty, move focus to the previous field
      if (!newCode[index] && index > 0) {
        inputRefs.current[index - 1].focus();
      }

      newCode[index] = ""; // Clear the current field
      setTwoFactorCode(newCode);
    }
  };


  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData("text").slice(0, 6).replace(/[^0-9]/g, "");
    const newCode = [...twoFactorCode];

    pasteData.split("").forEach((char, i) => {
      if (i < 6) {
        newCode[i] = char;
      }
    });

    setTwoFactorCode(newCode);

    // Focus on the first empty input after pasting
    const firstEmptyIndex = newCode.findIndex((char) => char === "");
    if (firstEmptyIndex !== -1) {
      inputRefs.current[firstEmptyIndex].focus();
    }
  };


  const validateFtn = (values) => {
    const errors = {};
    function validateusername(username) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(username).toLowerCase());
    }
    if (!values.username) {
      errors.username = t('Username/Email Required.');
    }
    // else if (!validateusername(values.username)) {
    //   errors.username = t('Please enter a valid username address.');
    // }
    if (!values.password) {
      errors.password = t('Password Required.');
    }
    return errors;
  };

  return (
    <div>
      {!show2FAInput ? (
        <container style={styles.container}>
          <div style={styles.leftContainer}>
            <div style={styles.logoContainer}>
              <img src={loginLogo} alt="Logo" style={styles.logo} />
            </div>
            <div style={{
              ...styles.leftInsideContainer, width: isMobile ? '100%' : '60%',
              maxWidth: isMobile ? '100%' : '60%',
            }} className="modal-body">
              <Typography style={styles.heading}>{t('Hi, Welcome to Nano Pro Tech!')}</Typography>
              <Typography sx={{ color: '#212121' }}>{t('Create Your Nano ProTech Account Join us and unlock the full potential of Nano ProTech! Create your account in a few simple steps and start exploring all the features, tools, and resources crafted just for you.')}</Typography>
            </div>
          </div>
          <div style={{ ...styles.rightContainer, marginTop: isMobile ? "0px" : "15%" }}>
            <Form
              onSubmit={onSubmit}
              validate={validateFtn}
              render={({ handleSubmit, valid, values }) => (
                <form onSubmit={handleSubmit} style={styles.form}>
                  <LoadingOverlay active={loading} spinner={<DNALoader />}>
                    {/* <div style={styles.buttons}> */}
                      {/* <Button
                        onClick={() => { navigate("/register_client/Members") }}
                        style={styles.signup}
                      >
                        {t('Sign up')}
                      </Button> */}
                      {/* <Button
                        disabled
                        onClick={() => { navigate("/user-login/Members") }}
                        style={{
                          ...styles.login,
                          ':hover': styles.loginHover,
                        }}
                      >
                        {t('sign in')}
                      </Button> */}
                    {/* </div> */}
                    <Row form>
                      <Col md={12}>
                        <FormGroup>
                          <Field name="username">
                            {({ input, meta }) => (
                              <div className="mb-3">
                                <Label for="username">{t('Username / Email')}</Label>

                                <Input {...input} type="username"
                                  style={{
                                    border: '1px solid #303030',
                                    background: '#F9F8E8',
                                  }}
                                  invalid={meta.error && meta.touched} placeholder={t('Enter Username or Email')} />
                                {meta.error && meta.touched && <span className="text-danger">{meta.error}</span>}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup>
                          <Field name="password">
                            {({ input, meta }) => (
                              <div className="mb-3">
                                <Label for="password">{t('Password')}</Label>
                                <Input {...input} type="password"
                                  style={{
                                    border: '1px solid #303030',
                                    background: '#F9F8E8',
                                  }}
                                  invalid={meta.error && meta.touched} placeholder="**********" />
                                {meta.error && meta.touched && <span className="text-danger">{meta.error}</span>}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormGroup check className="mb-3">
                      <div style={styles.formFooter}>
                        <div style={styles.rememberMe}>
                          {/* <Input type="checkbox" id="rememberMe" />
                        <label htmlFor="rememberMe" style={styles.checkboxLabel}>Remember Me</label> */}
                        </div>
                        <a href="/Userforgot-password" style={styles.forgotPassword}>Forgot Password?</a>
                      </div>
                    </FormGroup>
                    {/* Submit Button */}
                    <div className="d-grid mb-3">
                      <Button
                        size="lg"
                        color={valid ? "primary" : "secondary"}
                        type="submit"

                        disabled={!valid}
                      >
                        {t('sign in')}
                      </Button>
                    </div>
                  </LoadingOverlay>
                </form>
              )}
            />
          </div>
        </container>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
          <img src={loginLogo} alt="Logo" style={{ marginBottom: '100px', width: '30%', height: 'auto' }} />
          <Box
            sx={{
              maxWidth: 500,
              width: '100%',
              // background: '#f9f8e8' 
              backgroundColor: '#fff8dc',
              borderRadius: 8,
              padding: 4,
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              textAlign: 'center',
            }}
          >
            <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
              {t('Security Verification!')}
            </Typography>
            <Typography variant="body1" sx={{ color: '#666', marginBottom: 3 }}>
              {t('For security please enter the 6-digit verification code below.')}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: 1,
                marginBottom: 3,
              }}
              onPaste={handlePaste} // Handle paste functionality
            >
              {Array.from({ length: 6 }).map((_, index) => (
                <TextField
                  key={index}
                  variant="outlined"
                  inputRef={(el) => (inputRefs.current[index] = el)} // Store reference to each input
                  inputProps={{
                    maxLength: 1,
                    style: {
                      textAlign: "center",
                      fontSize: "24px",
                      width: "40px",
                      height: "40px",
                    },
                  }}
                  value={twoFactorCode[index]}
                  onChange={(e) => handleInputChange(e, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                />
              ))}
            </Box>

            <Button
              variant="contained"
              fullWidth

              sx={{
                backgroundColor: '#ffcc00',
                color: '#000',
                width: '400px',
                maxWidth: '100%',
                fontWeight: 'bold',
                ':hover': { backgroundColor: '#ffb700' },
              }}
              onClick={verify2FA}
              disabled={twoFactorCode.length !== 6 || loading}
            >
              {loading ? t('Verifying...') : t('Verify')}
            </Button>
            <Typography
              variant="body2"
              sx={{ marginTop: 2, color: resendTimer === 0 ? '#007bff' : '#999', cursor: resendTimer === 0 ? 'pointer' : 'default' }}
              onClick={resendTimer === 0 ? resendCode : null}
            >
              {resendTimer === 0
                ? resendCode()
                : `${t("Open your authenticator app and get Otp. New Otp will be in")} ${Math.floor(resendTimer / 60)}:${(resendTimer % 60).toString().padStart(2, '0')}`}
            </Typography>
          </Box>
        </div>
      )
      }
    </div>
  );
};

const styles = {
  container: {
    padding: '5%',
    display: 'flex',
    flexDirection: 'row',
    height: '100vh',
    flexWrap: 'wrap',
  },
  loginLink: {
    color: '#007bff',
    textDecoration: 'none',
    marginLeft: '5px'
  },
  sponsorInfo: {
    backgroundColor: '#1E34E3',
    padding: '15px',
    borderRadius: '8px',
    marginBottom: '20px',
    border: '1px solid #dee2e6'
  },
  form: {
    background: "#F9F8E8",
    padding: "50px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
  },
  sponsorLabel: {
    fontWeight: 'bold',
    marginRight: '10px',
    color: '#FFFFFF'
  },
  leftContainer: {
    flex: 1,
    display: 'flex',
    marginTop: '1%',
    flexDirection: 'column',
    overflowY: 'auto',
    width: '100%',
  },
  leftInsideContainer: {
    flex: 1,
    display: 'flex',
    marginTop: '1%',
    flexDirection: 'column',
    background: 'white',
    overflowY: 'auto'
  },
  rightContainer: {
    flex: 1,
    margin: '1%',
    padding: '50px 20px 0 0',
    borderRadius: '1%',
    position: 'relative',
    width: '100%',
  },
  logoContainer: {
    marginBottom: '5px',
    '@media (max-width: 768px)': {
      textAlign: 'center', // Center logo on small screens
    }
  },
  logo: {
    width: '50%',
    height: 'auto',
  },
  heading: {
    color: '#010101',
    marginTop: '10px',
    fontSize: '32px',
    fontWeight: 'bold',
    marginBottom: '10px',
    '@media (max-width: 768px)': {
      fontSize: '24px',
    },
  },
  requiredField: {
    color: 'red',
    marginLeft: '3px',
  },

  referralCard: {
    backgroundColor: "#0000FF",
    color: "#FFFFFF",
    padding: "20px",

    borderRadius: "10px",
    marginTop: '12%',
    textAlign: "left",
    fontFamily: "'Arial', sans-serif",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)"
  },
  referralHeading: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  referralText: {
    marginBottom: "5px",
    fontSize: "1rem",
  },
  referralBoldText: {
    marginBottom: "5px",
    fontWeight: "bold",
  },
  buttons: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fcfbee",
    borderRadius: "10px",
    padding: "10px",
    width: "100%",
    maxWidth: "500px",
    marginBottom: "10px",
  },
  signup: {
    backgroundColor: "#f4f4f4",
    padding: "12px 30px",
    color: "#000",
    fontWeight: "bold", // Bold text
    fontSize: "16px", // Font size
    borderRadius: "8px", // Rounded corners for the button
    border: "none", // Remove border
    cursor: "pointer", // Pointer cursor on hover
    flex: 1,
    transition: "all 0.3s ease",
    marginRight: "5px",
    width: "50%",
  },
  login: {
    backgroundColor: "#c5b048",
    color: "#fff",
    padding: "12px 30px", // Adjust padding for a larger button
    fontWeight: "bold", // Bold text
    fontSize: "16px", // Font size
    borderRadius: "8px", // Rounded corners for the button
    border: "none", // Remove border
    cursor: "pointer", // Pointer cursor on hover
    flex: 1,
    transition: "all 0.3s ease", // Smooth hover transition
    width: "50%", // Ensure button spans half of the container
  },
  loginHover: {
    backgroundColor: "#e2e2e2",
  },


};

export default Register;


//         {/* <CopyRight /> */}
//       </div>
//       <div style={styles.rightContainer}>
//         {/* <SliderComponent /> */}
//       </div>
//     </div>
//   );
// };