import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, NavLink } from 'react-router-dom';
import { Row, Col, CardBody, Card } from "reactstrap";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import DataTable from 'react-data-table-component';
import DiscountCouponService from "../DiscountCoupon/Discountcoupons.service";
import AuthService from "../Authentication/auth.service";
import Alerts from "../../components/Alerts/alerts";
import { Tooltip } from "@mui/material";
import DeliveryFeeReportFilter from "../../components/Table Filters/DeliveryFeeReportFilter";
import PermissionAccess from '../../PermissionAccess/Access.js';
import moment from 'moment';

const DeliveryFeeReport = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filterBusiness, setFilterBusiness] = useState('');
    const [filterSearchText, setFilterSearchText] = useState('');
    const [filterStatus, setFilterStatus] = useState('');
    const [filterDate, setFilterDate] = useState('');
    const [pageLimit, setPageLimit] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const { authData, super_admin, current_module } = PermissionAccess();
    const [dateRange, setDateRange] = useState({ from: '', to: '' });

    const [summaryData, setSummaryData] = useState({
        receivedExtra: 0,
        balance: 0,
        paidExtra: 0
    });

    useEffect(() => {
        i18n.changeLanguage(localStorage.getItem('language'));
        fetchOrders(currentPage, pageLimit, filterBusiness, filterSearchText, filterStatus, '');
    }, [])

    const fetchOrders = (page_index, page_limit, business = filterBusiness, search_text = filterSearchText, status = filterStatus, date = filterDate) => {
        setLoading(true);
        const stored_user = authData.userData;
        let businessIDs = []
        if (stored_user.businesses) {
            businessIDs = stored_user.businesses
        }
        DiscountCouponService.getDeliveryFeeReport(authData.sessionID, page_index, page_limit, business, search_text, status, date, businessIDs).then(
            (response) => {
                setLoading(false);
                if (response.data.message === "Unauthorized") {
                    AuthService.logout();
                    navigate("/login");
                    window.location.reload();
                } else {
                    setOrders(response.data.data);
                    setTotalRows(response.data.count.count);
                    setSummaryData({
                        receivedExtra: response.data.sums.receivedExtra,
                        balance: response.data.sums.balance,
                        paidExtra: response.data.sums.paidExtra
                    });
                }
            },
            (error) => {
                setLoading(false);
                const errContent =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
                Alerts.swalErrorAlert(errContent || t("Server Error, Please try again later."));
            }
        );
    }

    const mycolumns = [
        {
            name: t('Order ID'),
            selector: "order_number",
            width: "10%",
            sortable: true,
            cell: row => (
                <NavLink 
                    to={`/order-details/${row._id}`} 
                    className="text-primary"
                    style={{ textDecoration: 'none' }}
                >
                    {row.order_number}
                </NavLink>
            )
        },
        {
            name: t('Seller Name'),
            selector: row => row.business_details?.name || '',
            width: "20%",
            sortable: true,
        },

        {
            name: t('Date'),
            selector: row => row.order_date,
            width: "20%",
            sortable: true,
            cell: row => moment(row.order_date).format('DD-MM-YYYY hh:mm:ss A')
        },

        {
            name: t('Status'),
            selector: row => row.additional_delivery_fee_details,
            width: "20%",
            cell: row => {
                const details = row.additional_delivery_fee_details || {};
                const remainingValue = parseFloat(details.remaining_additional_delivery_fee || 0);
                const isNegative = remainingValue < 0;

                return (
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }} className="mt-2">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{
                                backgroundColor: isNegative ? '#dc3545' : '#20c997',
                                color: 'white',
                                padding: '2px 7px',
                                borderRadius: '3px',
                                minWidth: '45px',
                                textAlign: 'right'
                            }}>
                                {remainingValue.toFixed(2)}
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }} className="mt-2">
                            <i className="pe-7s-user fs-5" style={{ marginRight: '5px', color: 'black' }}></i>
                            {parseFloat(details.additional_delivery_fee || 0).toFixed(2)}
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }} className="mt-2">
                            <i className="pe-7s-home fs-5" style={{ marginRight: '5px', color: 'black' }}></i>
                            {parseFloat(details.seller_additional_delivery_fee || 0).toFixed(2)}
                        </div>
                    </div>
                );
            }
        },

        {
            name: t('Customer'),
            selector: row => row.user_details,
            width: "20%",
            sortable: true,
            cell: row => {
                const details = row.user_details || {};
                return (
                    <div style={{ lineHeight: '1.5' }}>
                        <div className="mt-2">
                            <i className="pe-7s-user fs-5" style={{ marginRight: '5px', color: '#black' }}></i>
                            {details.name || t('N/A')}
                        </div>
                        <div className="mt-2">
                            <i className="pe-7s-mail fs-5" style={{ marginRight: '5px', color: '#black' }}></i>
                            {details.email || t('N/A')}
                        </div>
                        <div className="mt-2">
                            <i className="pe-7s-call fs-5" style={{ marginRight: '5px', color: '#black' }}></i>
                            {details.phone || t('N/A')}
                        </div>
                    </div>
                );
            }
        },

        {
            name: t('Actions'),
            width: "10%",
            selector: (row) => {
                return (
                    <div>

                        {!super_admin ? current_module?.ViewDetails === 1 &&
                            <NavLink to={`/order-details/${row._id}`}><Tooltip placement="top" title={`${t("View")} ${t("Details")}`} arrow style={{ cursor: "pointer" }}>
                                <i className="pe-7s-look text-primary btn-icon-wrapper text-primary fs-5 p-1"> </i>
                            </Tooltip>
                            </NavLink>
                            :
                            <NavLink to={`/order-details/${row._id}`}><Tooltip placement="top" title={`${t("View")} ${t("Details")}`} arrow style={{ cursor: "pointer" }}>
                                <i className="pe-7s-look text-primary btn-icon-wrapper text-primary fs-5 p-1"> </i>
                            </Tooltip>
                            </NavLink>
                        }

                    </div>
                );
            }
        }


    ];

    const handleSearch = (business, searchText, status, date) => {
        setFilterBusiness(business);
        setFilterSearchText(searchText);
        setFilterStatus(status);
        setFilterDate(date);
        if (date) {
            const [from, to] = date.split(' - ');
            setDateRange({ from, to });
        } else {
            setDateRange({ from: '', to: '' });
        }
        fetchOrders(0, pageLimit, business, searchText, status, date);
        setCurrentPage(0);
    };

    const handleClear = () => {
        setFilterBusiness('');
        setFilterSearchText('');
        setFilterStatus('');
        setFilterDate('');
        setDateRange({ from: '', to: '' });
        fetchOrders(0, pageLimit, '', '');
        setCurrentPage(0);
    };

    const subHeaderComponentMemo = React.useMemo(() => {
        return (
            <DeliveryFeeReportFilter
                onSearch={handleSearch}
                onClear={handleClear}
                filterBusiness={filterBusiness}
                filterSearchText={filterSearchText}
                filterStatus={filterStatus}
                filterDate={filterDate}
            />
        );
    }, [filterBusiness, filterSearchText, filterStatus, filterDate]);

    const handlePageChange = page => {
        setCurrentPage(page - 1);  // Adjust for 0-based index
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPageLimit(newPerPage);
        setCurrentPage(page - 1);  // Adjust for 0-based index
    };

    const SummaryCard = ({ title, value, color, icon }) => (
        <Card className="mb-3" style={{ backgroundColor: color, color: 'white' }}>
            <CardBody>
                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <h6>{title}</h6>
                        <h3>{value.toFixed(2)}</h3>
                    </div>
                    <i className={`pe-7s-${icon} fa-2x`} />
                </div>
            </CardBody>
        </Card>
    );

    return (
        <Fragment>
            <div className="app-main__outer">
                <div className="app-main__inner">
                    <div className="bg-light-gray bg-animation">
                        <div className="d-flex justify-content-center align-items-center">
                            <Col md="12" className="mx-auto app-login-box">
                                <div className="app-page-title">
                                    <div className="page-title-wrapper">
                                        <div className="page-title-heading fw-bold">
                                            <div className="page-title-icon">
                                                <i className="pe-7s-news-paper icon-gradient bg-tempting-azure" />
                                            </div>
                                            <div>
                                                {t("Delivery Fee Report")}
                                                <div className="page-title-subheading">
                                                    {t("Delivery Report Filters")}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="page-title-actions"></div>
                                    </div>
                                </div>
                                <Row>
                                    <LoadingOverlay
                                        tag="div"
                                        active={loading}
                                        styles={{ overlay: (base) => ({ ...base }) }}
                                        spinner={<DNALoader />}
                                    >
                                        <Col md="12">
                                            <Card className="main-card mb-3">
                                                <CardBody>
                                                    <Col md="12" className="mb-3">
                                                        {subHeaderComponentMemo}
                                                    </Col>

                                                    {/* Summary Cards */}
                                                    <Row className="mb-3">
                                                        <Col md="4">
                                                            <SummaryCard
                                                                title={t("Paid Extra")}
                                                                value={summaryData.paidExtra}
                                                                color="#ff6b6b"
                                                                icon="cash"
                                                            />
                                                        </Col>
                                                        <Col md="4">
                                                            <SummaryCard
                                                                title={t("Received Extra")}
                                                                value={summaryData.receivedExtra}
                                                                color="#4ecdc4"
                                                                icon="download"
                                                            />
                                                        </Col>
                                                        <Col md="4">
                                                            <SummaryCard
                                                                title={t("Balance")}
                                                                value={summaryData.balance}
                                                                color="#45aaf2"
                                                                icon="calculator"
                                                            />
                                                        </Col>
                                                    </Row>

                                                    {/* DataTable */}
                                                    <DataTable
                                                        data={orders}
                                                        columns={mycolumns}
                                                        pagination
                                                        fixedHeader
                                                        fixedHeaderScrollHeight="400px"
                                                        subHeader
                                                        paginationServer
                                                        paginationTotalRows={totalRows}
                                                        onChangePage={handlePageChange}
                                                        onChangeRowsPerPage={handlePerRowsChange}
                                                        paginationResetDefaultPage={resetPaginationToggle}
                                                    />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </LoadingOverlay>
                                </Row>
                            </Col>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default DeliveryFeeReport;