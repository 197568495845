import React, { Fragment, useEffect } from "react";
import { useTranslation } from 'react-i18next';

import Tabs, { TabPane } from "rc-tabs";
import TabContent from "rc-tabs/lib/SwipeableTabContent";
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar";

import TabGeneralConfig from "./TabGeneralConfig";
import TabPaymentGateway from "./TabPaymentGateway";
import TabTaxClassesConfig from "./TabTaxClassesConfig";
import PaymentMethods from "./PaymentMethods";
import TabReminders from "./TabReminders";
import TabCurrency from "./TabCurrency";

const Configuration = () => {

  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
  }, []);

  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className="app-page-title mb-0">
            <div className="page-title-wrapper">
              <div className="page-title-heading fw-bold">
                <div className="page-title-icon">
                  <i className="pe-7s-settings icon-gradient bg-happy-green" />
                </div>
                <div>{t('Finance Settings')}
                  <div className="page-title-subheading">
                    {t('View and update some basic configurations of Finance.')}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <div className="col-md-12">
              <Tabs defaultActiveKey="1" renderTabBar={() => <ScrollableInkTabBar />} renderTabContent={() => <TabContent />}>
                <TabPane tab={t('General Settings')} key="1" >
                  <TabGeneralConfig className="btn-custom-color" />
                </TabPane>
                {/* <TabPane tab={t('Payment Gateway')} key="2" >
                  <TabPaymentGateway className="btn-custom-color" />
                </TabPane> */}
                {/* <TabPane tab={t('Payment Methods')} key="3" >
                  <PaymentMethods className="btn-custom-color" />
                </TabPane> */}
                {/* <TabPane tab={t('Reminders')} key="4" >
                  <TabReminders className="btn-custom-color" />
                </TabPane> */}
                {/* <TabPane tab={t('Tax Classes')} key="5" >
                  <TabTaxClassesConfig className="btn-custom-color" />
                </TabPane>  */}
                <TabPane tab={t('Currency')} key="6" >
                  <TabCurrency className="btn-custom-color" />
                </TabPane> 
              </Tabs>
            </div>
          </div>

        </div>
      </div>
    </Fragment>
  );

};
export default Configuration; 