import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";
import {
  Typography, Chip, Grid, Paper, Box, Table,
  TableBody, TableCell, TableContainer, TableRow, IconButton,
  Card, CardMedia, Divider, Avatar, Button, Modal, Fade
} from '@mui/material';
import { styled } from '@mui/material/styles';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import EmailIcon from '@mui/icons-material/Email';
import CurrencyIcon from '@mui/icons-material/PriceCheck';
import PhoneIcon from '@mui/icons-material/Phone';
import Services from "./services.js";
import AuthService from "../Authentication/auth.service";
import { useNavigate } from 'react-router-dom';
import Alerts from "../../components/Alerts/alerts";
import PermissionAccess from '../../PermissionAccess/Access.js';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  margin: theme.spacing(3, 0),
  backgroundColor: theme.palette.background.default,
  boxShadow: theme.shadows[3],
  borderRadius: theme.shape.borderRadius * 2,
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.contrastText,
}));


const PackageDetailsComponent = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { state: Package } = location;
  const [loading, setLoading] = useState(false);
  const { authData } = PermissionAccess();


  const handleApproval = async (approve) => {
    setLoading(true);
    try {
      const response = await Services.updatePackageApproval(Package._id, approve, authData.sessionID);
      if (response.data.status) {
        Package.status = approve ? "Active" : "Inactive";
        Alerts.swalSuccessAlert(approve ? "Package approved successfully" : "Package disapproved");
        // Refresh Package data or navigate back
      } else {
        Alerts.swalErrorAlert(response.data.message || "Error updating Package approval status");
      }
    } catch (error) {
      Alerts.swalErrorAlert("Network operation failed");
    } finally {
      setLoading(false);
    }
  };

  const renderInfoRow = (icon, label, value) => (
    <TableRow>
      <StyledTableCell>
        <Box display="flex" alignItems="center">
          {icon}
          <Typography variant="body1" style={{ marginLeft: 8 }}>{t(label)}</Typography>
        </Box>
      </StyledTableCell>
      <TableCell>{value}</TableCell>
    </TableRow>
  );

  return (
    <LoadingOverlay
      active={loading}
      styles={{ overlay: (base) => ({ ...base }) }}
      spinner={<DNALoader />}
    >
      <StyledPaper elevation={3}>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={24} md={12}>
            <TableContainer style={{ marginTop: 16 }}>
              <Table>
                <TableBody>
                  {renderInfoRow(<LocalHospitalIcon />, 'Name', Package.name)}
                  {renderInfoRow(<CurrencyIcon />, 'Price', Package.price)}
                  {renderInfoRow(<EmailIcon />, 'MPV', Package.mpv)}
                  {renderInfoRow(<EmailIcon />, 'Multiplier', Package.multiplier)}
                  {renderInfoRow(<EmailIcon />, 'Description', Package.description)}
                  {renderInfoRow(<PhoneIcon />, 'Status', <Chip
                    label={Package.status}
                    color={Package.status === 'Active' ? 'success' : 'error'}
                    size="small"
                    style={{ marginTop: 8 }}
                  />
                  )}

                </TableBody>
              </Table>

            </TableContainer>
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="center" mt={5}>
          {Package.status === "Inactive" ?
            <Button
              variant="contained"
              color="success"
              onClick={() => handleApproval(true)}
              style={{ marginRight: 16 }}
            >
              Active
            </Button>
            :
            <Button
              variant="contained"
              color="error"
              onClick={() => handleApproval(false)}
            >
              Inactive
            </Button>
          }
        </Box>
      </StyledPaper>
    </LoadingOverlay>
  );
};

export default PackageDetailsComponent;
