import axios from "axios";

const API_URL = process.env.REACT_APP_BASEURL;
const B_URL = process.env.REACT_APP_LOGINURL


const headers = {
  'Content-Type': 'application/json',
  'token': localStorage.getItem("session-id")
}


const getAllOrders = (active_orders, past_orders, page, user_id) => {
  var payload = {
    "functionName": "GetOrdersList",
    "postData": {
      "page": page,
      "user_id": user_id,
      "filters": {
        "active_orders": active_orders,
        "past_orders": past_orders
      }
    }
  }
  console.log("GetItems", payload);
  return axios.post(B_URL + "OrderModule/GetOrdersList", payload, { headers });
};

const getAllOrdersPastPresent = (payload) => {
  return axios.post(B_URL + "OrderModule/getAllOrdersPastPresent", payload, { headers });
};



const getOrderDetails = (order_id) => {
  var payload = {
    "functionName": "GetOrderDetails",
    "postData": {
      "order_id": order_id,
    }
  }
  console.log("GetItems", payload);
  return axios.post(B_URL + "OrderModule/GetOrderDetails", payload, { headers });
};

const GetOrderTrack = (payload) => {
  return axios.post(B_URL + "OrderModule/TrackOrder", payload, { headers });
};




const OrderService = {
  GetOrderTrack,
  getAllOrders,
  getAllOrdersPastPresent,
  getOrderDetails
};

export default OrderService;