import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  IconButton,
  Typography,
  Alert
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ShareIcon from '@mui/icons-material/Share';
import CloseIcon from '@mui/icons-material/Close';

const ShareDialog = ({ open, onClose, referralLink, onCopy, onShare }) => {
  const handleCopyAndClose = () => {
    onCopy();
    onClose();
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '12px',
          overflow: 'hidden'
        }
      }}
    >
      <DialogTitle sx={{ 
        backgroundColor: '#f8fafc', 
        borderBottom: '1px solid #e2e8f0',
        px: 3,
        py: 2
      }}>
        <Typography variant="h6" component="div" sx={{ fontWeight: 600 }}>
          Share Referral Link
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'gray.500'
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      
      <DialogContent sx={{ p: 3 }}>
        <Alert severity="info" sx={{ mb: 3 }}>
          Share this link with potential members to join using your referral.
        </Alert>
        
        <Box>
          <TextField
            fullWidth
            value={referralLink}
            variant="outlined"
            InputProps={{
              sx: { pr: 1 },
              endAdornment: (
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <Button
                    variant="contained"
                    onClick={handleCopyAndClose}
                    startIcon={<ContentCopyIcon />}
                    sx={{
                      textTransform: 'none',
                      backgroundColor: '#3b82f6',
                      '&:hover': {
                        backgroundColor: '#2563eb'
                      }
                    }}
                  >
                    Copy
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={onShare}
                    startIcon={<ShareIcon />}
                    sx={{
                      textTransform: 'none',
                      borderColor: '#3b82f6',
                      color: '#3b82f6',
                      '&:hover': {
                        borderColor: '#2563eb',
                        backgroundColor: '#f8fafc'
                      }
                    }}
                  >
                    Share
                  </Button>
                </Box>
              )
            }}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ShareDialog;