import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, CardBody, Card } from "reactstrap";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import BusinessService from "./Businesses.service.js";
import AuthService from "../Authentication/auth.service.js";
import Alerts from "../../components/Alerts/alerts.js";
import PermissionAccess from '../../PermissionAccess/Access.js';

const EditAttribute = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [options_collection, setoptions_collection] = useState([]);
    const [sections, setSections] = useState([]);
    const { authData, accessPages, super_admin, current_module } = PermissionAccess();
    
    const fetchSections = () => {
        setLoading(true);
        BusinessService.getAllSections(authData.sessionID,0,9999999).then(
          (response) => {
            setLoading(false);
            if (response.data.message === "Unauthorized") {
              AuthService.logout();
              navigate("/login");
              window.location.reload();
            } else {
              setSections(response.data.data);
            }
          },
          (error) => {
            setLoading(false);
            const errContent = (error.response && error.response.data) || error.message || error.toString();
            Alerts.swalErrorAlert(errContent || t("Server Error, Please try again later."));
          }
        );
      }

    function fetchcollections() {
        BusinessService.fetchcollections(authData.sessionID).then((response) => {
          if(response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            setoptions_collection(response.data.data);
          }
        },
        (error) => {
          const errContent =
          (error.response && error.response.data) || error.message || error.toString();
          if(errContent) {
            Alerts.swalErrorAlert(errContent)
          }
          else {
            Alerts.swalErrorAlert(t("Server Error, Please try again later."))
          }
        })
      };

    const [data, setData] = useState({
        label: '',
        key: '',
        status: '',
        field_type: '',
        section_id:'',
    });

    const [errors, setErrors] = useState({
        label: '',
        key: '',
        status: '',
        field_type: '',
        section_id:'',
    });

    const [options, setOptions] = useState([{ label: '', value: '' }]);
    const [getOptionsFrom, setGetOptionsFrom] = useState('');
    const [getOptionFieldName, setOptionFieldName] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await BusinessService.GetSingleAttribute(authData.sessionID,id);
                setLoading(false);
                if (response.data.message === "Unauthorized") {
                    AuthService.logout();
                    navigate("/login");
                    window.location.reload();
                } else {
                    if (response.data.status === true) {
                        const attributeData = response.data.data.data;
                        setData({
                            label: attributeData.lable || '',
                            key: attributeData.key || '',
                            status: attributeData.status || '',
                            field_type: attributeData.field_type || '',
                            section_id: attributeData.section_id || '',
                        });
                        // Check if getOptionsFrom is set, then populate options accordingly
                        if (attributeData.getOptionsFrom) {
                            setOptions([]);
                            setGetOptionsFrom(attributeData.getOptionsFrom);
                            setOptionFieldName(attributeData.getOptionFieldName || '');
                        } else {
                            setOptions(attributeData.options || [{ label: '', value: '' }]);
                            setGetOptionsFrom('');
                            setOptionFieldName('');
                        }
                    } else {
                        Alerts.swalErrorAlert(t("Something went wrong, Please try again."));
                    }
                }
            } catch (error) {
                setLoading(false);
                const errContent = (error.response && error.response.data) || error.message || error.toString();
                if (errContent) {
                    Alerts.swalErrorAlert(errContent);
                } else {
                    Alerts.swalErrorAlert(t("Server Error, Please try again later."));
                }
            }
        };
        fetchData();
        fetchcollections();
        fetchSections();
    }, [id, navigate, t]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        let error = '';

        if (name === 'label') {
            error = value.trim() === '' ? 'Label is required' : '';
        }

        if (name === 'key') {
            error = value.trim() === '' ? 'Key is required' : '';
        }

        if (name === 'status') {
            error = value.trim() === '' ? 'Status is required' : '';
        }

        if (name === 'section_id') {
            error = value.trim() === '' ? 'Section is required' : '';
        }

        if (name === 'field_type') {
            error = value.trim() === '' ? 'Field type is required' : '';
            if (['Dropdown', 'Checkbox', 'Radio Button'].includes(value)) {
                setOptions([{ label: '', value: '' }]);
            } else {
                setOptions([]);
            }
            setGetOptionsFrom('');
            setOptionFieldName('');
        }

        // Update state and errors accordingly
        setErrors({ ...errors, [name]: error });
        setData({ ...data, [name]: value });
    };

    const handleOptionChange = (index, event) => {
        const { name, value } = event.target;
        const updatedOptions = [...options];
        updatedOptions[index][name] = value;
        setOptions(updatedOptions);
    };

    const addOption = () => {
        setOptions([...options, { label: '', value: '' }]);
    };

    const removeOption = (index) => {
        const updatedOptions = options.filter((_, i) => i !== index);
        setOptions(updatedOptions);
    };

    
    const handleSubmit = async (e) => {
        e.preventDefault();
        let validationErrors = {};
    
        if (!data.label || data.label.trim() === '') validationErrors.label = 'Label is required';
        if (!data.key || data.key.trim() === '') validationErrors.key = 'Key is required';
        if (!data.status || data.status.trim() === '') validationErrors.status = 'Status is required';
        if (!data.section_id || data.section_id.trim() === '') validationErrors.section_id = 'Section is required';
        if (!data.field_type || data.field_type.trim() === '') validationErrors.field_type = 'Field type is required';
    
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            setLoading(true);
            const isManualOptionsVisible = ['Dropdown', 'Checkbox', 'Radio Button'].includes(data.field_type) && !getOptionsFrom;

        const payload = {
            ...data,
            options: isManualOptionsVisible ? options.filter(opt => opt.label && opt.value) : [],
            getOptionsFrom: isManualOptionsVisible ? null : (getOptionsFrom || null),
            getOptionFieldName: isManualOptionsVisible ? null : (getOptionsFrom ? getOptionFieldName : null),
        };
    
            try {
                const response = await BusinessService.EditAttribute(authData.sessionID,payload, id);
                setLoading(false);
                if (response.data.message === "Unauthorized") {
                    AuthService.logout();
                    navigate("/login");
                    window.location.reload();
                } else {
                    if (response.data.status === true) {
                        Alerts.swalSuccessAlert(t('Attribute updated successfully.'));
                        validationErrors.label = '';
                        validationErrors.key = '';
                        navigate("/business-settings");
                    } else {
                        if (response.data.errors && response.data.errors.length > 0) {
                            const validationErrors = {};
                            response.data.errors.forEach(error => {
                                validationErrors[error.field] = error.message;
                            });
                            setErrors(validationErrors);
                        }
                    }
                }
            } catch (error) {
                setLoading(false);
                const errContent = (error.response && error.response.data) || error.message || error.toString();
                if (errContent) {
                    Alerts.swalErrorAlert(errContent);
                } else {
                    Alerts.swalErrorAlert(t("Server Error, Please try again later."));
                }
            }
        }
    };
    
    

    return (
        <div className="app-main__outer">
            <div className="app-main__inner">
                <div className="bg-light-gray bg-animation">
                    <div className="d-flex justify-content-center align-items-center">
                        <Col md="12" className="mx-auto app-login-box">
                            <div className="app-page-title">
                                <div className="page-title-wrapper">
                                    <div className="page-title-heading fw-bold">
                                        <div className="page-title-icon">
                                            <i className="pe-7s-news-paper icon-gradient bg-tempting-azure" />
                                        </div>
                                        <div>
                                            {`${t("Attribute")} ${t("Management")}`}
                                            <div className="page-title-subheading">
                                                {`${t("Update")} ${t("Attribute")}`}.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="page-title-actions">
                                        <NavLink to="/business-settings">
                                            <button type="button" className="btn btn-outline-success btn-hover-shine me-3">
                                                {t("Back")}
                                            </button>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex justify-content-center">
                                <Col md="12">
                                    <Card className="main-card mb-3">
                                        <CardBody>
                                            <LoadingOverlay tag="div" active={loading}
                                                styles={{ overlay: (base) => ({ ...base }) }}
                                                spinner={<DNALoader />}>
                                                <form onSubmit={handleSubmit}>
                                                    <div className="row">
                                                    <div className="col-6">
                                                            <label className="col-form-label">{t('Sections')}:</label>
                                                            <select className="form-control"
                                                                name="section_id"
                                                                onChange={handleChange}
                                                                value={data.section_id}
                                                            >
                                                                <option value="">Select Section</option>

                                                             {sections.length > 0 ? (
                                                                sections.map((section) => (
                                                                <option value={section._id}>{section.name}</option>
                                                                ))
                                                            ) : (
                                                                <option value="">No sections available</option>
                                                            )}

                                                            </select>
                                                            {errors.section_id && <strong className="error text-danger">{errors.section_id}</strong>}
                                                        </div>

                                                        <div className="col-6">
                                                            <label className="col-form-label">{t('Label')}:</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder={t('Label')}
                                                                name="label"
                                                                onChange={handleChange}
                                                                value={data.label}
                                                            />
                                                            {errors.label && <strong className="error text-danger">{errors.label}</strong>}
                                                        </div>

                                                        <div className="col-6">
                                                            <label className="col-form-label">{t('Key')}:</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder={t('Key')}
                                                                name="key"
                                                                onChange={handleChange}
                                                                value={data.key}
                                                            />
                                                            {errors.key && <strong className="error text-danger">{errors.key}</strong>}
                                                        </div>

                                                        <div className="col-6">
                                                            <label className="col-form-label">{t('Status')}:</label>
                                                            <select
                                                                className="form-control"
                                                                name="status"
                                                                onChange={handleChange}
                                                                value={data.status}
                                                            >
                                                                <option value="">{t('Please select status.')}</option>
                                                                <option value="Active">{t('Active')}</option>
                                                                <option value="InActive">{t('InActive')}</option>
                                                            </select>
                                                            {errors.status && <strong className="error text-danger">{errors.status}</strong>}
                                                        </div>

                                                        <div className="col-6">
                                                            <label className="col-form-label">{t('Field Type')}:</label>
                                                            <select className="form-control"
                                                                name="field_type"
                                                                onChange={handleChange}
                                                                value={data.field_type}
                                                            >
                                                                <option value="">Select Field Type</option>
                                                                <option value="Text Input">Text Input</option>
                                                                <option value="Number Input">Number Input</option>
                                                                <option value="Email Input">Email Input</option>
                                                                <option value="Password Input">Password Input</option>
                                                                <option value="Date Picker">Date Picker</option>
                                                                <option value="Time Picker">Time Picker</option>
                                                                <option value="File Input">File Input</option>
                                                                <option value="Dropdown">Dropdown</option>
                                                                <option value="Checkbox">Checkbox</option>
                                                                <option value="Radio Button">Radio Button</option>
                                                            </select>
                                                            {errors.field_type && <strong className="error text-danger">{errors.field_type}</strong>}
                                                        </div>

                                                        {['Dropdown', 'Checkbox', 'Radio Button'].includes(data.field_type) && !getOptionsFrom && (
                                                            <>
                                                                <div className="col-12 mt-3">
                                                                    <h5>{`Add ${data.field_type} Options`}</h5>
                                                                </div>
                                                                {options.map((option, index) => (
                                                                    <div className="row" key={index}>
                                                                        <div className="col-5">
                                                                            <div className="form-group">
                                                                                <label htmlFor="option-label" className="col-form-label">{t("Option Label")}:</label>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    placeholder="Option Label"
                                                                                    name="label"
                                                                                    value={option.label}
                                                                                    onChange={(e) => handleOptionChange(index, e)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-5">
                                                                            <div className="form-group">
                                                                                <label htmlFor="option-value" className="col-form-label">{t("Option Value")}:</label>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    placeholder="Option Value"
                                                                                    name="value"
                                                                                    value={option.value}
                                                                                    onChange={(e) => handleOptionChange(index, e)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-2" style={{ marginTop: "35px" }}>
                                                                            <button
                                                                                type="button"
                                                                                className="btn-icon btn-icon-only btn-shadow btn-outline-2x btn btn-outline-danger"
                                                                                onClick={() => removeOption(index)}
                                                                            >
                                                                                <i className="pe-7s-close-circle btn-icon-wrapper"> </i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                                <div className="col-12 mt-3">
                                                                    <button type="button" className="btn btn-custom-color btn-hover-shine" onClick={addOption}>
                                                                        Add More
                                                                    </button>
                                                                </div>
                                                            </>
                                                        )}

                                                        {data.field_type === 'Dropdown' && (
                                                            <>
                                                                <div className="col-6 mt-3">
                                                                    <div className="form-group">
                                                                        <label htmlFor="referal_name">{t('Get Options From')}</label>
                                                                        <select className="form-control"
                                                                            onChange={(e) => setGetOptionsFrom(e.target.value)}
                                                                            value={getOptionsFrom}
                                                                        >
                                                                            <option value="">{t('--Choose an option--')}</option>
                                                                            {options_collection &&
                                                                                options_collection.map((opt_collection) => (
                                                                                <option value={opt_collection.name}>{opt_collection.name}</option>
                                                                                ))
                                                                            }
                                                                            {/* Add more options as needed */}
                                                                        </select>
                                                                    </div>
                                                                </div>

                                                                <div className="col-6 mt-3">
                                                                    <div className="form-group">
                                                                        <label htmlFor="referal_name">{t('Options Field Name')}</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Option Field Name"
                                                                            onChange={(e) => setOptionFieldName(e.target.value)}
                                                                            value={getOptionFieldName}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}

                                                        <div className="form-group row mt-5">
                                                            <div className="col-12 text-center">
                                                                <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-light btn-hover-shine me-3">{t("Cancel")}</button>
                                                                <button type="submit" className="btn btn-custom-color">{t("Update")}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </LoadingOverlay>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </div>
                        </Col>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditAttribute;
