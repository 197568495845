import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { Row, Col,Card, CardBody } from "reactstrap";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import BusinessService from "./Businesses.service.js";
import AuthService from "../Authentication/auth.service.js";
import Alerts from "../../components/Alerts/alerts.js";

const TabGenralConfig = ({business_id}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  
  const [data, setData] = useState({
    name: '',
    email: '',
    status: '',
  });

  //get data
  useEffect(() => {
    const fetchData = async () => {
        setLoading(true);
        BusinessService.GetSingleBusiness(business_id).then((response) => {
            setLoading(false);
            if (response.data.message === "Unauthorized") {
                AuthService.logout();
                navigate("/login");
                window.location.reload();
            } else {
                if (response.data.status == true) {
                    setData({
                        name: response.data.data.data.name,
                        email: response.data.data.data.email,
                        status: response.data.data.data.status,
                      });
                    
                  }
                  else {
                    Alerts.swalErrorAlert(t("Something went wrong, Please try again."));
                  }
            }
        }, (error) => {
            setLoading(false);
            const errContent = (error.response && error.response.data) || error.message || error.toString();
            if (errContent) {
                Alerts.swalErrorAlert(errContent);
            } else {
                Alerts.swalErrorAlert(t("Server Error, Please try again later."));
            }
        });
    };
    fetchData();
  }, [business_id]);


  return (
    
    <Fragment>
      <Row>
        <Col md="12">
          <Card className="main-card">
            <LoadingOverlay tag="div" active={loading}
              styles={{ overlay: (base) => ({ ...base }) }}
              spinner={<DNALoader />}>
              <CardBody>
                <table className="table table-striped table-bordered nowrap" >
                  <tbody>
                    <tr style={{ width: '100%' }}>
                      <th style={{ width: '50%' }}>{t('Name')}</th>
                      <td style={{ width: '50%' }}>{data.name}</td>
                    </tr>
                    <tr>
                      <th>{t('Email')}</th>
                      <td>{data.email}</td>
                    </tr>
                    <tr>
                      <th>{t('Status')}</th>
                      <td>{data.status}</td>
                    </tr>

                  </tbody>
                </table>
              </CardBody>
            </LoadingOverlay>

          </Card>
        </Col>
      </Row>
    </Fragment >

  );

};
export default TabGenralConfig; 