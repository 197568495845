import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate,NavLink } from 'react-router-dom';
import { Row, Col, CardBody, Card } from "reactstrap";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import DataTable from 'react-data-table-component';
import DiscountCouponService from "../DiscountCoupon/Discountcoupons.service";
import AuthService from "../Authentication/auth.service";
import Alerts from "../../components/Alerts/alerts";
import SalesReportFilter from "../../components/Table Filters/SalesReportFilter";
import PermissionAccess from '../../PermissionAccess/Access.js';
import moment from 'moment';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { format, subDays } from 'date-fns';

const SalesReport = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filterBusiness, setFilterBusiness] = useState('');
    const [filterStatus, setFilterStatus] = useState('');
    const [filterItem, setFilterItem] = useState('');
    const [filterDate, setFilterDate] = useState('');
    const [pageLimit, setPageLimit] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [pageSums, setPageSums] = useState({});
    const { authData, super_admin, current_module } = PermissionAccess();
    const [dateRange, setDateRange] = useState({ from: '', to: '' });

    useEffect(() => {
        i18n.changeLanguage(localStorage.getItem('language'));
        
        // Set initial date range for the last 30 days
        const toDate = new Date();
        const fromDate = subDays(toDate, 30);
        const initialDateRange = {
            from: format(fromDate, 'yyyy/MM/dd'),
            to: format(toDate, 'yyyy/MM/dd')
        };
        setDateRange(initialDateRange);
        setFilterDate(`${initialDateRange.from} - ${initialDateRange.to}`);
    
        fetchOrders(currentPage, pageLimit, filterBusiness, filterStatus,filterItem, `${initialDateRange.from} - ${initialDateRange.to}`);
    }, []);

    const fetchOrders = (page_index, page_limit, business = filterBusiness, status = filterStatus, item=filterItem, date = filterDate) => {
        setLoading(true);
        const stored_user = authData.userData;
        let businessIDs = stored_user.businesses || [];

        DiscountCouponService.getSalesReport(authData.sessionID, page_index, page_limit, business, status, item, date, businessIDs).then(
            (response) => {
                setLoading(false);
                if (response.data.message === "Unauthorized") {
                    AuthService.logout();
                    navigate("/login");
                    window.location.reload();
                } else {
                    setOrders(response.data.data);
                    setTotalRows(response.data.count.count);
                    setPageSums(response.data.pageSums);
                }
            },
            (error) => {
                setLoading(false);
                const errContent = (error.response && error.response.data) || error.message || error.toString();
                Alerts.swalErrorAlert(errContent || t("Server Error, Please try again later."));
            }
        );
    }


    const mycolumns = [
        {
            name: t('Customer Name(Phone)'),
            selector: row => row.user_details?.name === t('Total') ? t('Total') : `${row.user_details?.name} (${row.user_details?.phone})`,
            width: "19%",
            sortable: true,
        },
        {
            name: t('Order ID'),
            selector: "order_number",
            width: "10%",
            sortable: true,
            cell: row => {
                if (row.user_details?.name === t('Total')) {
                    // Footer row: render as plain text
                    return row.order_number;
                } else {
                    // Regular row: render as NavLink
                    return (
                        <NavLink 
                            to={`/order-details/${row._id}`} 
                            className="text-primary"
                            style={{ textDecoration: 'none' }}
                        >
                            {row.order_number}
                        </NavLink>
                    );
                }
            }
        },
        {
            name: t('Order Date & Time'),
            selector: row => row.order_date,
            width: "14%",
            sortable: true,
            cell: row => row.user_details?.name === t('Total') ? '' : moment(row.order_date).format('DD-MM-YYYY hh:mm:ss A')
        },
        {
            name: t('Order Status'),
            selector: "status",
            width: "9%",
            sortable: true,
        },
        {
            name: t('Sub Total(RM)'),
            selector: row => row.Calculations?.subTotal,
            width: "10%",
            sortable: true,
            cell: row => parseFloat(row.Calculations?.subTotal).toFixed(2)
        },
        {
            name: t('Delivery Charges(RM)'),
            selector: row => row.Calculations?.deliveryTipFee,
            width: "13%",
            sortable: true,
            cell: row => parseFloat(row.Calculations?.deliveryTipFee).toFixed(2)
        },
        {
            name: t('Discount(RM)'),
            selector: row => row.Calculations?.discount,
            width: "10%",
            sortable: true,
            cell: row => parseFloat(row.Calculations?.discount).toFixed(2)
        },
        {
            name: t('Tax(RM)'),
            selector: row => row.Calculations?.sst,
            width: "7%",
            sortable: true,
            cell: row => parseFloat(row.Calculations?.sst).toFixed(2)
        },
        {
            name: t('Total(RM)'),
            selector: row => row.Calculations?.grandTotal,
            width: "8%",
            sortable: true,
            cell: row => parseFloat(row.Calculations?.grandTotal).toFixed(2)
        }
    ];

    const footerRow = {
        user_details: { name: t('Total') },
        order_number: pageSums.Orders,
        order_date: '',
        status: '',
        Calculations: {
            subTotal: parseFloat(pageSums.SubTotal).toFixed(2),
            deliveryTipFee: parseFloat(pageSums.TotalDeliveryCharges).toFixed(2),
            discount: parseFloat(pageSums.TotalDiscount).toFixed(2),
            sst: parseFloat(pageSums.Tax).toFixed(2),
            grandTotal: parseFloat(pageSums.GrandTotal).toFixed(2)
        }
    };

    const customStyles = {

        footer: {
            style: {
                fontWeight: 'bold',
                fontSize: '20px',
                color: '#202124',
                backgroundColor: '#f1f1f1',
                borderTopStyle: 'solid',
                borderTopWidth: '1px',
                borderTopColor: '#E0E0E0',
            }
        }
    };

    const paginatedOrders = [
        ...orders,
        footerRow
    ];

    const handleSearch = (business,status,item, date) => {
        setFilterBusiness(business);
        setFilterStatus(status);
        setFilterItem(item);
        setFilterDate(date);
        if (date) {
            const [from, to] = date.split(' - ');
            setDateRange({ from, to });
        }
        fetchOrders(0, pageLimit, business,status,item, date);
        setCurrentPage(0);
    };

    const handleClear = () => {
        setFilterBusiness('');
        setFilterStatus('');
        setFilterItem('');
        setFilterDate('');
        setDateRange({ from: '', to: '' });
        fetchOrders(0, pageLimit, '', '');
        setCurrentPage(0);
    };

    const subHeaderComponentMemo = React.useMemo(() => {
        return (
            <SalesReportFilter
                onSearch={handleSearch}
                onClear={handleClear}
                filterBusiness={filterBusiness}
                filterStatus={filterStatus}
                filterItem={filterItem}
                filterDate={filterDate}
            />
        );
    }, [filterBusiness,filterStatus, filterItem, filterDate]);

    const handlePageChange = page => {
        setCurrentPage(page - 1);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPageLimit(newPerPage);
        setCurrentPage(page - 1);
    };

    const generateCSV = () => {
        const dateRangeStr = dateRange.from && dateRange.to
        ? `(${dateRange.from} - ${dateRange.to})`
        : '';
    let csvContent = `Sales Report ${dateRangeStr}\n\n`;
    csvContent += "Customer Name(Phone),Order ID,Order Date & Time,Order Status,Sub Total(RM),Delivery Charges(RM),Discount(RM),Tax(RM),Total(RM)\n";


        paginatedOrders.forEach(row => {
            if (row.user_details.name === t('Total')) {
                csvContent += `${t('Total')},${pageSums.Orders},,,${row.Calculations.subTotal},${row.Calculations.deliveryTipFee},${row.Calculations.discount},${row.Calculations.sst},${row.Calculations.grandTotal}\n`;
            } else {
                csvContent += `${row.user_details.name} (${row.user_details.phone}),${row.order_number},${moment(row.order_date).format('DD/MM/YYYY')},${row.status},${row.Calculations.subTotal},${row.Calculations.deliveryTipFee},${row.Calculations.discount},${row.Calculations.sst},${row.Calculations.grandTotal}\n`;
            }
        });

        return csvContent;
    };

    const downloadCSV = () => {
        const csvContent = generateCSV();
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const dateRangeStr = dateRange.from && dateRange.to
            ? `${dateRange.from.replace(/\//g, '-')}_${dateRange.to.replace(/\//g, '-')}`
            : '';
        saveAs(blob, `sales_report_${dateRangeStr}.csv`);
    };

    const downloadPDF = () => {
        const doc = new jsPDF({
            orientation: 'landscape',
            unit: 'pt',
            format: [842, 595] // A4 landscape
        });
        const pageWidth = doc.internal.pageSize.width;
    
        // Set background color and text for the header
        doc.setFillColor(255, 255, 255);
        doc.rect(0, 0, pageWidth, 40, 'F');
    
        doc.setTextColor(0, 0, 0);
        doc.setFontSize(18);
        const dateRangeStr = dateRange.from && dateRange.to
            ? ` (${dateRange.from} - ${dateRange.to})`
            : '';
        const text = `Sales Report${dateRangeStr}`;
        const textWidth = doc.getStringUnitWidth(text) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        const textX = (pageWidth - textWidth) / 2;
        doc.text(text, textX, 30);

        // Define table columns with adjusted widths
        const tableColumn = [
            { title: "Customer Name(Phone)", dataKey: "name" },
            { title: "Order ID", dataKey: "orderId" },
            { title: "Order Date & Time", dataKey: "orderDate" },
            { title: "Order Status", dataKey: "orderStatus" },
            { title: "Sub Total(RM)", dataKey: "subTotal" },
            { title: "Delivery Charges(RM)", dataKey: "deliveryCharges" },
            { title: "Discount(RM)", dataKey: "discount" },
            { title: "Tax(RM)", dataKey: "tax" },
            { title: "Total(RM)", dataKey: "total" }
        ];

        // Map data for table rows
        const tableRows = paginatedOrders.map(row => ({
            name: row.user_details.name === t('Total') ? t('Total') : `${row.user_details.name} (${row.user_details.phone})`,
            orderId: row.user_details.name === t('Total') ? row.order_number : row.order_number,
            orderDate: row.order_date ? moment(row.order_date).format('DD/MM/YYYY HH:mm:ss') : '',
            orderStatus: row.status || '',
            subTotal: row.Calculations.subTotal,
            deliveryCharges: row.Calculations.deliveryTipFee,
            discount: row.Calculations.discount,
            tax: row.Calculations.sst,
            total: row.Calculations.grandTotal
        }));

        // Add table to the document with adjusted position and column widths
        doc.autoTable({
            head: [tableColumn.map(col => col.title)],
            body: tableRows.map(row => Object.values(row)),
            startY: 50,
            margin: { left: 10, right: 10 },
            headStyles: { fillColor: [0, 0, 0], textColor: [255, 255, 255] },
            alternateRowStyles: { fillColor: [245, 245, 245] },
            columnStyles: {
                name: { cellWidth: 150 },
                orderId: { cellWidth: 80 },
                orderDate: { cellWidth: 120 },
                orderStatus: { cellWidth: 80 },
                subTotal: { cellWidth: 80 },
                deliveryCharges: { cellWidth: 80 },
                discount: { cellWidth: 80 },
                tax: { cellWidth: 80 },
                total: { cellWidth: 80 }
            }
        });

        

        const filename = `sales_report_${dateRange.from.replace(/\//g, '-')}_${dateRange.to.replace(/\//g, '-')}.pdf`;
        doc.save(filename)
    };


    return (
        <Fragment>
            <div className="app-main__outer">
                <div className="app-main__inner">
                    <div className="bg-light-gray bg-animation">
                        <div className="d-flex justify-content-center align-items-center">
                            <Col md="12" className="mx-auto app-login-box">
                                <div className="app-page-title">
                                    <div className="page-title-wrapper">
                                        <div className="page-title-heading fw-bold">
                                            <div className="page-title-icon">
                                                <i className="pe-7s-news-paper icon-gradient bg-tempting-azure" />
                                            </div>
                                            <div>
                                                {`${t("Sales Report")}`}
                                                <div className="page-title-subheading">
                                                    {`${t("Sales Report Filters")}`}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="page-title-actions"></div>
                                    </div>
                                </div>
                                <Row>
                                    <LoadingOverlay
                                        tag="div"
                                        active={loading}
                                        styles={{ overlay: (base) => ({ ...base }) }}
                                        spinner={<DNALoader />}
                                    >
                                        <Col md="12">
                                            <Card className="main-card mb-3">
                                                <CardBody>
                                                    <Col md="12" className="mb-3">
                                                        {subHeaderComponentMemo}
                                                    </Col>
                                                    <DataTable
                                                        data={paginatedOrders}
                                                        columns={mycolumns}
                                                        pagination
                                                        fixedHeader
                                                        fixedHeaderScrollHeight="400px"
                                                        subHeader
                                                        subHeaderComponent={
                                                            <div>

                                                                {!super_admin ? current_module?.PrintCSV === 1 &&
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-custom-color btn-hover-shine me-3 btn"
                                                                        onClick={downloadCSV}
                                                                    >
                                                                        {t('CSV')}
                                                                    </button>
                                                                    :
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-custom-color btn-hover-shine me-3 btn"
                                                                        onClick={downloadCSV}
                                                                    >
                                                                        {t('CSV')}
                                                                    </button>
                                                                }


                                                                {!super_admin ? current_module?.PrintPDF === 1 &&
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-custom-color btn-hover-shine me-3 btn"
                                                                        onClick={downloadPDF}
                                                                    >
                                                                        {t('PDF')}
                                                                    </button>
                                                                    :
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-custom-color btn-hover-shine me-3 btn"
                                                                        onClick={downloadPDF}
                                                                    >
                                                                        {t('PDF')}
                                                                    </button>
                                                                }

                                                            </div>
                                                        }
                                                        paginationServer
                                                        paginationTotalRows={totalRows}
                                                        onChangePage={handlePageChange}
                                                        onChangeRowsPerPage={handlePerRowsChange}
                                                        paginationResetDefaultPage={resetPaginationToggle}
                                                        customStyles={customStyles}
                                                    />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </LoadingOverlay>
                                </Row>
                            </Col>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default SalesReport;