import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import { Tooltip, Chip } from "@mui/material";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import BusinessService from "./Businesses.service.js";
import Alerts from "../../components/Alerts/alerts.js";
import { useNavigate } from 'react-router-dom';

const FlatShipment = ({ business_id }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [zones, setZones] = useState([]);
  const [loading, setLoading] = useState(false);
  const [methodStatus, setMethodStatus] = useState('disabled');
  const [hasData, setHasData] = useState(false);
  const [modal, setModal] = useState(false);
  const [currentZone, setCurrentZone] = useState({
    _id: null,
    name: '',
    regions: [],
    shipment_method: 'flat_rate',
    flat_rate: '',
    per_kg_rate: '',
    status: 'enabled' // Added status
  });
  const [regionInput, setRegionInput] = useState('');
  const [totalRows, setTotalRows] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    fetchZones();
    fetchMethodStatus();
  }, []);

  const fetchMethodStatus = async () => {
    try {
      const sessionID = localStorage.getItem("session-id");
      const response = await BusinessService.getDeliveryMethodStatus(sessionID, business_id, 'flatShipment');
      if (response.data.status) {
        setMethodStatus(response.data.data.status);
        setHasData(response.data.data.hasData);
      }
    } catch (error) {
      console.error('Error fetching method status:', error);
    }
  };

  const handleToggle = async () => {
    try {
      const sessionID = localStorage.getItem("session-id");
      const newStatus = methodStatus === 'enabled' ? 'disabled' : 'enabled';
      const response = await BusinessService.updateDeliveryMethodStatus(sessionID, business_id, 'flatShipment', newStatus);
      if (response.data.status) {
        setMethodStatus(newStatus);
        Alerts.swalSuccessAlert(t(`Flat Shipment ${newStatus}`));
      }
    } catch (error) {
      console.error('Error updating method status:', error);
      Alerts.swalErrorAlert(t("Error updating status"));
    }
  };



  const fetchZones = async () => {
    setLoading(true);
    try {
      const sessionID = localStorage.getItem("session-id");
      const response = await BusinessService.getShippingZones(sessionID, business_id);
      if (response.data.status) {
        setZones(response.data.data);
        setTotalRows(response.data.data.length);
      } else {
        Alerts.swalErrorAlert(t("Error fetching zones"));
      }
    } catch (error) {
      Alerts.swalErrorAlert(t("Error fetching zones"));
    }
    setLoading(false);
  };

  const toggle = () => {
    setModal(!modal);
    if (!modal) {
      resetForm();
    }
  };

  const resetForm = () => {
    setCurrentZone({
      _id: null,
      name: '',
      regions: [],
      shipment_method: 'flat_rate',
      flat_rate: '',
      per_kg_rate: '',
      status: 'enabled' // Reset status
    });
    setRegionInput('');
    setIsEditing(false);
  };

  const handleInputChange = (e) => {
    setCurrentZone({ ...currentZone, [e.target.name]: e.target.value });
  };

  const handleRegionInputChange = (e) => {
    setRegionInput(e.target.value);
  };

  const handleStatusToggle = async (zone) => {
    const newStatus = zone.status === 'enabled' ? 'disabled' : 'enabled';
    setLoading(true);
    try {
      const sessionID = localStorage.getItem("session-id");
      await BusinessService.updateShippingZone(sessionID, business_id, zone._id, { ...zone, status: newStatus });
      Alerts.swalSuccessAlert(t(`Zone ${newStatus}`));
      fetchZones();
    } catch (error) {
      Alerts.swalErrorAlert(t("Error updating status"));
    }
    setLoading(false);
  };


  const handleRegionInputKeyDown = (e) => {
    if (e.key === 'Enter' && regionInput.trim()) {
      e.preventDefault();
      addRegion();
    }
  };

  const addRegion = () => {
    if (regionInput.trim()) {
      setCurrentZone({
        ...currentZone,
        regions: [...currentZone.regions, regionInput.trim()]
      });
      setRegionInput('');
    }
  };

  const removeRegion = (index) => {
    const updatedRegions = currentZone.regions.filter((_, i) => i !== index);
    setCurrentZone({ ...currentZone, regions: updatedRegions });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const sessionID = localStorage.getItem("session-id");
      const zoneData = {
        ...currentZone,
        regions: currentZone.regions.join(',')
      };
      if (isEditing) {
        let response = await BusinessService.updateShippingZone(sessionID, business_id, currentZone._id, zoneData);
        if (response.data.status) {
          Alerts.swalSuccessAlert(t("Zone updated successfully"));
        } else {
          Alerts.swalErrorAlert(t(response.data.message));
          if (response.data.message === 'Unauthorized') {
            navigate('/login');
          }
        }
      } else {
        let response = await BusinessService.addShippingZone(sessionID, business_id, zoneData);
        if (response.data.status) {
          Alerts.swalSuccessAlert(t("Zone added successfully"));
        } else {
          Alerts.swalErrorAlert(t(response.data.message));
          if (response.data.message === 'Unauthorized') {
            navigate('/login');
          }
        }
      }
      fetchZones();
      toggle();
    } catch (error) {
      Alerts.swalErrorAlert(t("Error saving zone"));
    }
    setLoading(false);
  };


  const handleEdit = (zone) => {
    setIsEditing(true);
    setCurrentZone({
      ...zone,
      regions: zone.regions.split(',').map(region => region.trim()),
      _id: zone._id // Ensure _id is set
    });
    setModal(true);
  };


  const handleDelete = async (zoneId) => {
    if (window.confirm(t("Are you sure you want to delete this zone?"))) {
      setLoading(true);
      try {
        const sessionID = localStorage.getItem("session-id");
        await BusinessService.deleteShippingZone(sessionID, business_id, zoneId);
        Alerts.swalSuccessAlert(t("Zone deleted successfully"));
        fetchZones();
      } catch (error) {
        Alerts.swalErrorAlert(t("Error deleting zone"));
      }
      setLoading(false);
    }
  };

  const columns = [
    {
      name: t("Name"),
      width: '15%',
      selector: row => row.name,
      sortable: true,
    },
    {
      name: t("Regions"),
      width: '30%',
      selector: row => row.regions,
      sortable: true,
      cell: row => (
        <div style={{ display: 'flex', flexWrap: 'wrap', maxWidth: '200px' }}>
          {row.regions.split(',').map((region, index) => (
            <Chip key={index} label={region.trim()} size="small" style={{ margin: '2px', maxWidth: '100%' }} />
          ))}
        </div>
      ),
      wrap: true,
    },
    {
      name: t("Shipment Method"),
      width: '15%',
      selector: row => row.shipment_method === 'flat_rate' ? t("Flat Rate") : t("Per Kg"),
      sortable: true,
    },
    {
      name: t("Rate"),
      width: '10%',
      selector: row => row.shipment_method === 'flat_rate'
        ? `${row.flat_rate} ${t("per shipment")}`
        : `${row.per_kg_rate} ${t("per kg")}`,
      sortable: true,
    },
    {
      name: t('Status'),
      width: '15%',
      selector: (row) => {
        return (
          <div>
            {row.status === "enabled" ?
              <button type="button"
                className="btn btn-danger btn-hover-shine me-3 btn">Enabled
              </button>
              :
              row.status === "disabled" ?
                <button type="button"
                  className="btn btn-success btn-hover-shine me-3 btn">Disabled
                </button>
                :
                <button type="button"
                  className="btn btn-default btn-hover-shine me-3 btn">{row.status}
                </button>
            }
          </div>
        );
      }
    },
    {
      name: t("Actions"),
      width: '15%',
      cell: row => (
        <div>
          <Tooltip placement="top" title={t("Edit")} arrow>
            <i onClick={() => handleEdit(row)} className="pe-7s-pen text-primary btn-icon-wrapper" style={{ fontSize: '1.3rem' }}></i>
          </Tooltip>
          <Tooltip placement="top" title={t("Delete")} arrow>
            <i onClick={() => handleDelete(row._id)} className="pe-7s-trash text-danger btn-icon-wrapper" style={{ fontSize: '1.3rem' }}></i>
          </Tooltip>
          <Tooltip placement="top" title={row.status === 'enabled' ? t("Disable") : t("Enable")} arrow>
            <i onClick={() => handleStatusToggle(row)} className={`pe-7s-${row.status === 'enabled' ? 'close-circle' : 'check'} text-warning btn-icon-wrapper`} style={{ fontSize: '1.3rem' }}></i>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <Card>
      <LoadingOverlay active={loading} spinner={<DNALoader />}>
        <CardBody>
          <h3>{t("Flat Shipment Zones")}</h3>
          <Col md="12" className="d-flex justify-content-end pt-3">
          <Button 
              color={methodStatus === 'enabled' ? "success" : "secondary"} 
              onClick={handleToggle}
              disabled={methodStatus === 'disabled' && !hasData}
              className="btn-hover-shine ms-1 mb-3"
            >
              {methodStatus === 'enabled' ? t("Enabled") : t("Disabled")}
            </Button>
            <Button color="success" onClick={() => { resetForm(); setModal(true); }} className="btn-hover-shine ms-1 mb-3">
              {t("Add New")}
            </Button>
          </Col>
          <DataTable
            columns={columns}
            data={zones}
            pagination
            paginationTotalRows={totalRows}
            paginationPerPage={pageLimit}
            onChangeRowsPerPage={setPageLimit}
            fixedHeader
            fixedHeaderScrollHeight="400px"
          />

          <Modal isOpen={modal} toggle={toggle} size="lg">
            <ModalHeader toggle={toggle}>
              {isEditing ? t("Edit Zone") : t("Add New Zone")}
            </ModalHeader>
            <ModalBody>
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <Label for="name">{t("Zone Name")}</Label>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    value={currentZone.name || ''}
                    onChange={handleInputChange}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label>{t("Regions")}</Label>
                  <Input
                    type="text"
                    value={regionInput}
                    onChange={handleRegionInputChange}
                    onKeyDown={handleRegionInputKeyDown}
                    placeholder={t("Enter region and press Enter")}
                  />
                  <div style={{ marginTop: '10px', display: 'flex', flexWrap: 'wrap' }}>
                    {currentZone.regions.map((region, index) => (
                      <Chip
                        key={index}
                        label={region}
                        onDelete={() => removeRegion(index)}
                        style={{ margin: '2px' }}
                      />
                    ))}
                  </div>
                </FormGroup>
                <FormGroup>
                  <Label for="shipment_method">{t("Shipment Method")}</Label>
                  <Input
                    type="select"
                    name="shipment_method"
                    id="shipment_method"
                    value={currentZone.shipment_method || 'flat_rate'}
                    onChange={handleInputChange}
                  >
                    <option value="flat_rate">{t("Flat Rate")}</option>
                    <option value="per_kg">{t("Per Kg")}</option>
                  </Input>
                </FormGroup>
                {currentZone.shipment_method === 'flat_rate' ? (
                  <FormGroup>
                    <Label for="flat_rate">{t("Flat Rate")}</Label>
                    <Input
                      type="number"
                      name="flat_rate"
                      id="flat_rate"
                      value={currentZone.flat_rate || ''}
                      onChange={handleInputChange}
                      required
                    />
                  </FormGroup>
                ) : (
                  <FormGroup>
                    <Label for="per_kg_rate">{t("Per Kg Rate")}</Label>
                    <Input
                      type="number"
                      name="per_kg_rate"
                      id="per_kg_rate"
                      value={currentZone.per_kg_rate || ''}
                      onChange={handleInputChange}
                      required
                    />
                  </FormGroup>
                )}
                <Button color="primary" type="submit">
                  {isEditing ? t("Update Zone") : t("Add Zone")}
                </Button>
              </Form>
            </ModalBody>
          </Modal>
        </CardBody>
      </LoadingOverlay>
    </Card>
  );
};

export default FlatShipment;