
import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
    Box, Typography, Button,
    Card, CardContent, IconButton, Divider, CircularProgress, ThemeProvider, createTheme
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useNavigate } from "react-router-dom";
import styled from '@emotion/styled';
import DashboardService from "../dashboard.service";
import AuthService from "../../Authentication/auth.service";
import Alerts from "../../../components/Alerts/alerts";
import AlertService from '../../../components/Alerts/alerts';
import EmptyCart from '../../../assets/emptyCart.png';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import BOX from '../../../assets/box.svg';
import group from '../../../assets/group.svg';
import routing from '../../../assets/routing.svg';
import Minus from "../../../assets/minus.svg";
import Add from "../../../assets/add.svg";
const Image_URL = process.env.REACT_APP_IMAGEURL;

const theme = createTheme({
    palette: {
        primary: {
            main: '#e67e22',
        },
        background: {
            default: '#f8f9fa',
        },
    },
    typography: {
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    },
});

const StyledCard = styled(Card)(({ theme }) => ({
    boxShadow: 'none',
    borderRadius: '8px',
    marginBottom: '16px',
    [theme.breakpoints.down('sm')]: {
        borderRadius: 0,
        border: 'none',
    },
}));

const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    borderRadius: '8px',
    padding: '12px',
    '&:hover': {
        backgroundColor: theme.palette.primary.dark,
    },
}));

const EmptyCard = styled(Card)(({ theme }) => ({
    boxShadow: 'none',
    borderRadius: '8px',
    marginBottom: '16px',
    minHeight: '80vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
        borderRadius: 0,
    },
}));

const LoaderOverlay = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  backdrop-filter: blur(5px);
`;

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const PopupContent = styled.div`
  background-color: white;
  border-radius: 10px;
  width: 80%;
  max-width: 300px;
  overflow: hidden;
`;

const PopupHeader = styled.div`
  background-color: white;
  padding: 20px;
  text-align: center;
`;

const PopupIcon = styled.div`
  background-color: #d32f2f;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 20px;
`;

const PopupTitle = styled.h2`
  margin: 0;
  font-size: 18px;
  color: #333;
`;

const PopupButtons = styled.div`
  display: flex;
`;

const PopupButton = styled.button`
  flex: 1;
  padding: 15px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:first-of-type {
    background-color: #e67e22;
    color: white;
  }

  &:last-of-type {
    background-color: #c0392b;
    color: white;
  }

  &:hover {
    opacity: 0.9;
  }
`;


const CartView = ({ state, setDrawerOpen }) => {
    const [Prioritize, setPrioritize] = useState([]);
    const [cartData, setCartData] = useState([]);
    console.log("state CartView", state)
    const [DistanceFormula, setDistanceFormula] = useState(0);
    const [addressesList, setAddressesList] = useState([]);
    const [userDetails, setUserDetails] = useState(null);
    const [userBalance, setUserBalance] = useState(null);
    const [calculations, setCalculations] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [deliveryMethod, setDeliveryMethod] = useState('delivery');
    const [deliveryAddress, setDeliveryAddress] = useState('');
    const [priorityTip, setPriorityTip] = useState(0);
    const [couponCode, setCouponCode] = useState('');
    const navigate = useNavigate();
    const [RemoveItem, setRemoveItem] = useState(false);
    const [deliveryMethods, setDeliveryMethods] = useState([]);
    const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState('');
    const [customAddress, setCustomAddress] = useState("");
    const [receiver_name, setReceiver_name] = useState("");
    const [receiver_phone, setReceiver_phone] = useState("");
    const [selectedOrderType, setSelectedOrderType] = useState(null);
    const [selectedDeliveryAddress, setSelectedDeliveryAddress] = useState(null);
    const [grand_mpv, setGrand_mpv] = useState('');
    const [isPlaceOrderDisabled, setIsPlaceOrderDisabled] = useState(true);
    const [selectedCurrency, setSelectedCurrency] = useState(
        JSON.parse(localStorage.getItem("selectedCurrency")) || {
            code: "PHP",
            rate: 1,
            symbol: "₱",
        }
    );

    useEffect(() => {
        validatePlaceOrder();
    }, [cartData, deliveryMethod, deliveryAddress, receiver_name, receiver_phone, selectedOrderType, selectedDeliveryAddress]);

    const validatePlaceOrder = () => {
        let isValid = true;

        // Check if cart data is available and has items
        if (!cartData || cartData.length === 0) {
            isValid = false;
        }

        // Check if delivery method is selected
        if (!deliveryMethod && deliveryMethod !== 'pickup') {
            isValid = false;
        }

        // Check if delivery address or custom address is provided
        if (selectedDeliveryAddress === "different") {
            if (!receiver_name || !receiver_phone || !customAddress) {
                isValid = false;
            }
        }

        // Check if order type is selected
        if (!selectedOrderType) {
            isValid = false;
        }

        // Set the validation result
        setIsPlaceOrderDisabled(!isValid);
    };


    const styles = {
        ...{
            card: {
                width: '300px',
                height: '100%',
                backgroundColor: '#fff',
                borderRadius: '12px',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                padding: '20px',
                cursor: 'pointer',
                fontFamily: 'Arial, sans-serif',
                textAlign: 'center',
                margin: 'auto',
                transition: '0.3s',
                border: '2px solid transparent',
            },
            cardSelected: {
                border: '2px solid #C1B314',
            },
            button: {
                backgroundColor: 'transparent',
                border: '2px solid #000',
                borderRadius: '8px',
                padding: '10px 20px',
                fontSize: '1em',
                cursor: 'pointer',
                transition: '0.3s',
            },
            buttonSelected: {
                backgroundColor: '#C1B314',
                color: '#000',
                border: '2px solid #C1B314',
            },
            // drawer: {
            //   position: 'fixed',
            //   top: 90,
            //   right: showDrawer ? 6 : showProductDrawer ? 6 : '-500px',
            //   width: isMobile ? '100%' : '31%',
            //   height: 'calc(100vh - 90px)',
            //   backgroundColor: '#fff',
            //   boxShadow: '0 0 10px rgba(0,0,0,0.3)',
            //   transition: 'right 0.3s ease-in-out',
            //   zIndex: '100',
            //   padding: '20px',
            //   borderRadius: '20px',
            //   overflow: 'auto', // Enable scrolling
            //   scrollbarWidth: 'thin', // For Firefox
            // },
            // scrollbar: {
            //   '&::-webkit-scrollbar': {
            //     width: '6px',
            //   },
            //   '&::-webkit-scrollbar-track': {
            //     background: '#f1f1f1',
            //   },
            //   '&::-webkit-scrollbar-thumb': {
            //     background: '#888',
            //     borderRadius: '8px',
            //   },
            //   '&::-webkit-scrollbar-thumb:hover': {
            //     background: '#555',
            //   },
            // },
            // overlay: {
            //   position: 'fixed',
            //   top: '0',
            //   left: '0',
            //   width: '100%',
            //   height: '100%',
            //   backgroundColor: 'rgba(0, 0, 0, 0.5)',
            //   display: showDrawer ? 'block' : showProductDrawer ? 'block' : 'none',
            //   zIndex: '99', // Slightly below the drawer
            // },
            spanStyle: {
                fontFamily: 'Metropolis',
                fontSize: '1.1rem',
                color: 'black',
            },

            drawerHeader: {
                display: 'flex',
                alignItems: 'center',
                fontFamily: 'Metropolis',
                fontSize: '1.2rem',
                fontWeight: '500px',
                color: 'black',
                marginBottom: '10px',
            },
            drawerCloseButton: {
                fontSize: '2rem',
                cursor: 'pointer',
                border: 'none',
                background: 'none',
            },
            drawerSelect: {
                width: '100%',
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '8px',
                fontSize: '1rem',
                backgroundColor: '#fff',
                position: 'relative',
            },
            drawerButtonGroup: {
                display: 'flex',
                gap: '10px',
                marginBottom: '20px',
            },
            drawerButton: {
                flex: 1,
                height: '60px',
                padding: '10px',
                borderRadius: '8px',
                fontSize: '1rem',
                fontWeight: 'bold',
                cursor: 'pointer',
                border: 'none',
                textAlign: 'center',
            },
            drawerButtonActive: {
                height: '60px',
                backgroundColor: '#C1B314',
                color: '#fff',
                borderColor: '#C1B314',
            },
            drawerUploadSection: {
                border: '1px dashed #ccc',
                borderRadius: '8px',
                padding: '20px',
                textAlign: 'center',
                cursor: 'pointer',
                marginBottom: '20px',
            },
            drawerFooter: {
                display: 'flex',
                marginTop: '10px',
                flexDirection: 'column',
                gap: '10px',
            },
            drawerFooterButton: {
                width: '100%',
                padding: '10px',
                borderRadius: '8px',
                fontSize: '1rem',
                fontWeight: 'bold',
                cursor: 'pointer',
            },
            drawerFooterPrimary: {
                backgroundColor: '#C1B314',
                color: '#fff',
                border: 'none',
            },
            drawerFooterSecondary: {
                backgroundColor: '#f5f5f5',
                color: '#333',
                border: '1px solid #ccc',
            },
            drawerButtonActive: {
                backgroundColor: '#C1B314', // Active background color
                color: '#fff', // Active text color
                borderColor: '#C1B314', // Match active border
            },

        },
        ...{
            header: {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            },
            icon: {
                width: '120px',
                height: '120px',
            },
            title: {
                fontSize: '1.5em',
                color: 'black',
                margin: '5px 0',
                fontWeight: 'bold',
            },
            priceText: {
                fontSize: '2em',
                fontWeight: '500',
                color: 'black',
            },
            points: {
                color: 'black',
                margin: '5px 0',
                fontSize: '1.2em',
            },
            divider: {
                border: '0.5px solid #d1d1d1',
                margin: '15px 0',
            },
            description: {
                fontSize: '1.2em',
                color: 'black',
                lineHeight: '1.5',
                height: '50vh', // Fixed height
                overflowY: 'auto', // Add scroll if content exceeds height
                margin: '10px 0', // Add spacing
                textAlign: 'center', // Center-align the text
                display: 'flex', // Flexbox for vertical alignment
                alignItems: 'center', // Center content vertically
                justifyContent: 'center', // Center content horizontally
            },
        },
    };

    useEffect(() => {
        fetchCartData();
    }, []);

    const fetchCartData = async () => {
        try {
            setLoading(true);
            let status = false;
            if (deliveryMethod !== 'delivery') {
                status = true
            }
            const userString = localStorage.getItem('user');
            const user = JSON.parse(userString);
            const userId = user['_id'];
            const updateData = {
                "functionName": "GetCartList",
                "postData": {
                    "user_id": userId,
                    "filters": {
                        "status": false,
                        "coupan_code": '',
                        "prioritize": "",
                        "DeliveryMethod": '',
                        "address_id": '',
                        "self_pickup": status,
                        "quantity": {
                            "cart_id": '',
                            "quantity": ''
                        }
                    }

                }
            };
            DashboardService.GetCartList(updateData).then((response) => {
                setLoading(false); // Set loading state to false after data retrieval
                // Handle response data
                console.log("cart Data", response.data);
                if (response.data.message === "Unauthorized") {
                    // Perform logout and redirection for unauthorized access
                    AuthService.logout();
                    navigate("/login");
                    window.location.reload();
                }
                else {
                    if (response.data) {
                        setPrioritize(response.data.Prioritizes);
                        setCartData(response.data.cartData);
                        setDeliveryMethods(response.data.DeliveryMethods);
                        if (response?.data?.DeliveryMethods?.length > 0 && !selectedDeliveryMethod) {
                            setSelectedDeliveryMethod(response?.data?.DeliveryMethods?.[0]?._id);
                        }
                        setDistanceFormula(response.data.DistanceFormula);
                        setAddressesList(response.data.AddressessList);
                        setUserDetails(response.data.UserDetails?.[0]);
                        setUserBalance(response.data.UserBalance?.[0]);
                        setCalculations(response.data.Calculations);
                        setGrand_mpv(response.data.grand_mpv);
                    }
                }
            },
                (error) => {
                    setLoading(false); // Set loading state to false in case of error
                    const errContent =
                        (error.response && error.response.data) || error.message || error.toString();
                    console.log("Net:-", errContent)
                    if (errContent) {
                        Alerts.swalErrorAlert(errContent)
                    }
                    else {
                        Alerts.swalErrorAlert("Server Error, Please try again later.")
                    }
                })
        } catch (err) {
            // setError('Failed to fetch cart data. Please try again.');
            console.error('Error fetching cart data:', err);
        } finally {
            // setLoading(false);
        }
    };

    const handleUpdateQuantity = (Item, newQuantity) => {
        if (newQuantity === 0) {
            handleOpenRemoveItemPopup(Item)
        } else {

            setCartData(prevItems =>
                prevItems.map(item =>
                    item._id === Item._id ? { ...item, quantity: Math.max(1, newQuantity).toString() } : item
                )
            );

            console.log('Applying update item:', Item);
            try {
                setLoading(true);
                let status = false;
                if (deliveryMethod !== 'delivery') {
                    status = true
                }
                const updateData = {
                    "functionName": "GetCartList",
                    "postData": {
                        "user_id": userDetails?._id,
                        "filters": {
                            "status": true,
                            "coupan_code": couponCode,
                            "DeliveryMethod": selectedDeliveryMethod,
                            "address_id": deliveryAddress,
                            "self_pickup": status,
                            "prioritize": priorityTip,
                            "quantity": {
                                "cart_id": Item._id,
                                "quantity": newQuantity
                            }
                        }
                    }
                };
                DashboardService.GetCartList(updateData).then((response) => {
                    setLoading(false); // Set loading state to false after data retrieval
                    // Handle response data
                    console.log("cart Data", response.data);
                    if (response.data.message === "Unauthorized") {
                        // Perform logout and redirection for unauthorized access
                        AuthService.logout();
                        navigate("/login");
                        window.location.reload();
                    }
                    else {
                        if (response.data) {
                            console.log("my updated Item cart Data", response.data.cartData);
                            // setBranches(response.data.data); // Set total rows based on response length
                            setCartData(response.data.cartData);
                            setPrioritize(response.data.Prioritizes);
                            setDistanceFormula(response.data.DistanceFormula);
                            setAddressesList(response.data.AddressessList);
                            setUserDetails(response.data.UserDetails[0]);
                            setUserBalance(response.data.UserBalance[0]);
                            setCalculations(response.data.Calculations);
                            setGrand_mpv(response.data.grand_mpv);
                        }
                    }
                },
                    (error) => {
                        setLoading(false); // Set loading state to false in case of error
                        const errContent =
                            (error.response && error.response.data) || error.message || error.toString();
                        console.log("Net:-", errContent)
                        if (errContent) {
                            Alerts.swalErrorAlert(errContent)
                        }
                        else {
                            Alerts.swalErrorAlert("Server Error, Please try again later.")
                        }
                    })
            } catch (err) {
                // setError('Failed to fetch cart data. Please try again.');
                console.error('Error fetching cart data:', err);
            } finally {
                // setLoading(false);
            }
        }
    };


    const handleRemoveItem = async () => {
        console.log('Applying Remove item:', RemoveItem);
        try {
            setLoading(true);
            let status = false;
            if (deliveryMethod !== 'delivery') {
                status = true
            }
            const updateData = {
                "functionName": "GetCartList",
                "postData": {
                    "user_id": userDetails?._id,
                    "filters": {
                        "status": true,
                        "coupan_code": couponCode,
                        "DeliveryMethod": selectedDeliveryMethod,
                        "address_id": deliveryAddress,
                        "self_pickup": status,
                        "prioritize": priorityTip,
                        "quantity": {
                            "cart_id": RemoveItem._id,
                            "quantity": ''
                        }
                    }


                }
            };
            console.log("updateData", updateData);
            DashboardService.GetCartList(updateData).then((response) => {
                setLoading(false); // Set loading state to false after data retrieval
                // Handle response data
                setShowRemoveItemPopup(false);
                console.log("cart Data", response.data);
                if (response.data.message === "Unauthorized") {
                    // Perform logout and redirection for unauthorized access
                    AuthService.logout();
                    navigate("/login");
                    window.location.reload();
                }
                else {
                    if (response.data) {
                        console.log("my updated Item cart Data", response.data.cartData);
                        // setBranches(response.data.data); // Set total rows based on response length
                        setCartData(response.data.cartData);
                        setPrioritize(response.data.Prioritizes);
                        setDistanceFormula(response.data.DistanceFormula);
                        setAddressesList(response.data.AddressessList);
                        setUserDetails(response.data.UserDetails[0]);
                        setUserBalance(response.data.UserBalance[0]);
                        setCalculations(response.data.Calculations);
                        setGrand_mpv(response.data.grand_mpv);
                    }
                }
            },
                (error) => {
                    setLoading(false); // Set loading state to false in case of error
                    const errContent =
                        (error.response && error.response.data) || error.message || error.toString();
                    console.log("Net:-", errContent)
                    if (errContent) {
                        Alerts.swalErrorAlert(errContent)
                    }
                    else {
                        Alerts.swalErrorAlert("Server Error, Please try again later.")
                    }
                })
        } catch (err) {
            // setError('Failed to fetch cart data. Please try again.');
            console.error('Error fetching cart data:', err);
        } finally {
            // setLoading(false);
        }
    };

    const [showEmptyCartPopup, setShowEmptyCartPopup] = useState(false);
    const [showRemoveItemPopup, setShowRemoveItemPopup] = useState(false);

    const handleOpenRemoveItemPopup = (item) => {
        setShowRemoveItemPopup(true);
        setRemoveItem(item)
    };

    const handleCloseRemoveItemPopup = () => {
        setShowRemoveItemPopup(false);
    };

    const handleOpenEmptyCartPopup = () => {
        setShowEmptyCartPopup(true);

    };

    const handleCloseEmptyCartPopup = () => {
        setShowEmptyCartPopup(false);
    };

    const HandleCartEmpty = async () => {
        setShowEmptyCartPopup(false);
        try {
            setLoading(true);
            const userString = localStorage.getItem('user');
            const user = JSON.parse(userString);
            const userId = user['_id'];

            const updateData = {
                "functionName": "DeleteCart",
                "postData": {
                    "user_id": userId,
                    "cart_id": ""
                }
            };
            console.log("DeleteCart", updateData);
            DashboardService.DeleteCart(updateData).then((response) => {
                setLoading(false);
                console.log("cart Data", response.data);
                if (response.data.message === "Unauthorized") {
                    AuthService.logout();
                    navigate("/login");
                    window.location.reload();
                } else {
                    if (response.data) {
                        console.log("my updated Item cart Data", response.data.cartData);
                        AlertService.successToast("Cart emptied successfully");
                        window.location.reload();
                    }
                }
            }, (error) => {
                setLoading(false);
                const errContent = (error.response && error.response.data) || error.message || error.toString();
                console.log("Net:-", errContent)
                if (errContent) {
                    Alerts.swalErrorAlert(errContent)
                } else {
                    Alerts.swalErrorAlert("Server Error, Please try again later.")
                }
            })
        } catch (err) {
            console.error('Error emptying cart:', err);
        } finally {
            setLoading(false);
        }
    };


    const HandlePlaceOrder = async (cartData, deliveryMethod, deliveryAddress, priorityTip, couponCode, total) => {
        console.log('cartData----->', cartData);
        try {
            let Cart = []
            cartData.map((cartItem) => {
                Cart.push({
                    _id: cartItem?._id,
                    quantity: cartItem?.quantity,
                    active_quantity: cartItem?.quantity,
                    price: cartItem?.price,
                    selectedSource: cartItem?.selectedSource,
                    activeOption: cartItem?.activeOption,
                    total_price: cartItem?.total_price,
                    category_id: cartItem?.category_details?._id,
                    business_id: cartItem?.business_details?._id,
                    product_id: cartItem?.product_details?._id,
                    variations: cartItem?.variations,
                    is_reviewed: 0,
                })
            })
            const userString = localStorage.getItem('user');
            const user = JSON.parse(userString);
            const userId = user['_id'];
            let Addresses = [];
            if (deliveryMethod !== 'selfPickUp') {
                Addresses = addressesList.filter(address =>
                    address._id === deliveryAddress
                );
            }

            let pay_topup = false;
            console.log("userBalance?.total_balance", userBalance?.total_balance);
            if (userBalance?.total_balance > total) {
                let userRemainingBalance = userBalance?.total_balance - total
                if (userRemainingBalance >= 0) {
                    console.log("Pay From Topup");
                    pay_topup = true;
                }
            }

            setLoading(true);
            var getData = {
                functionName: "placeOrder",
                "postData": {
                    user_id: userId,
                    pay_topup: pay_topup,
                    cartData: Cart,
                    deliveryMethod,
                    Addresses,
                    priorityTip,
                    couponCode,
                    grand_mpv,
                    total: parseFloat(total).toFixed(2),
                    DistanceFormula: DistanceFormula,
                    Calculations: calculations,
                    selectedOrderType: selectedOrderType,
                    selectedDeliveryAddress: selectedDeliveryAddress,
                    customAddress: customAddress,
                    receiver_name: receiver_name,
                    receiver_phone: receiver_phone,

                }
            }
            console.log("getData", getData);
            setLoading(true);
            DashboardService.PlaceOrder(getData).then((response) => {
                console.log(response);
                setLoading(false);
                setDrawerOpen(false);
                if (response.status === 200) {
                    if (response.data.status) {
                        if (response.data.message === `MPV wallet insufficient`) {
                            Alerts.swalErrorAlert(`${state.type} wallet insufficient for this order`)
                        } else {
                            Alerts.swalClickProceedAlert('Order Placed Successfully').then(res => {
                                if (res === true) {
                                    HandleCartEmpty();
                                }
                            });
                        }
                    } else {
                        Alerts.swalErrorAlert(response.data.message)
                    }
                } else if (response.status === 401) {
                    AuthService.logout();
                    navigate("/login");
                    window.location.reload();
                } else {
                    window.open(response.data.data, '_blank');
                }
            },
                (error) => {
                    setLoading(false);
                    const errContent =
                        (error.response && error.response.data) || error.message || error.toString();
                    console.log("Net:-", errContent);
                    if (errContent) {
                        Alerts.swalErrorAlert(errContent);
                    } else {
                        Alerts.swalErrorAlert("Server Error, Please try again later.");
                    }
                })
        } catch (err) {
            console.error('Error placing order:', err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div >
            <ThemeProvider theme={theme}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1, mt: 3, px: { xs: 2, sm: 3 } }}>
                    <IconButton onClick={() => { window.location.reload() }}>
                        <ArrowBackIcon />
                        <Typography sx={{ color: 'black', fontSize: '20px' }} variant="h6">Complete Purchase Order</Typography>
                    </IconButton>
                    <IconButton onClick={handleOpenEmptyCartPopup}>
                        <DeleteOutlineIcon />
                    </IconButton>
                </Box>
                <Box sx={{ maxWidth: 600, margin: 'auto', padding: { xs: 1, sm: 2 }, backgroundColor: 'white', minHeight: '100vh' }}>
                    {loading && (
                        <LoaderOverlay>
                            <CircularProgress />
                        </LoaderOverlay>
                    )}
                    {error ? (
                        <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
                            <Typography color="error">{error}</Typography>
                        </Box>
                    ) : cartData && cartData.length > 0 ? (
                        <StyledCard>
                            <div>
                                <Typography style={{ ...styles.spanStyle, marginTop: '20px', marginBottom: '10px' }}>Order Type</Typography>
                                <div style={styles.drawerButtonGroup}>
                                    <button
                                        style={{
                                            ...styles.drawerButton,
                                            ...(selectedOrderType === "delivery" ? styles.drawerButtonActive : {}),
                                        }}
                                        onClick={() => setSelectedOrderType("delivery")}
                                    >
                                        <img src={group} alt="Different Address" style={{ ...(selectedOrderType === "delivery" ? { color: 'white' } : { color: 'black' }), width: '24px', height: '24px' }} /> Delivery
                                    </button>
                                    <button
                                        style={{
                                            ...styles.drawerButton,
                                            ...(selectedOrderType === "pickup" ? styles.drawerButtonActive : {}),
                                        }}
                                        onClick={() => setSelectedOrderType("pickup")}
                                    >
                                        <img src={BOX} alt="Different Address" style={{ width: '24px', height: '24px' }} /> Pick-Up
                                    </button>
                                </div>
                            </div>

                            <div>
                                <Typography style={{ ...styles.spanStyle, marginTop: '10px', marginBottom: '10px' }}>Delivery Address</Typography>
                                <div style={styles.drawerButtonGroup}>
                                    <button
                                        style={{
                                            ...styles.drawerButton,
                                            ...(selectedDeliveryAddress === "same" ? styles.drawerButtonActive : {}),
                                        }}
                                        onClick={() => {
                                            setSelectedDeliveryAddress("same");
                                            setCustomAddress("");
                                            setReceiver_name("");
                                            setReceiver_phone("");
                                        }}
                                    >
                                        <img src={routing} alt="Different Address" style={{ width: '24px', height: '24px' }} />  Same Address
                                    </button>
                                    <button
                                        style={{
                                            ...styles.drawerButton,
                                            ...(selectedDeliveryAddress === "different" ? styles.drawerButtonActive : {}),
                                        }}
                                        onClick={() => setSelectedDeliveryAddress("different")}
                                    >
                                        <img src={BOX} alt="Different Address" style={{ width: '24px', height: '24px' }} /> Different Address
                                    </button>
                                </div>

                                {selectedDeliveryAddress === "different" && (
                                    <>
                                        <div style={{ marginTop: "10px" }}>
                                            <label htmlFor="fullName" style={{ display: "block", marginBottom: "5px", fontWeight: "bold" }}>
                                                Full Name:
                                            </label>
                                            <input
                                                type="text"
                                                id="fullName"
                                                value={receiver_name}
                                                onChange={(e) => setReceiver_name(e.target.value)}
                                                placeholder="Enter receiver name"
                                                style={{
                                                    width: "100%",
                                                    padding: "10px",
                                                    border: "1px solid #ccc",
                                                    borderRadius: "8px",
                                                    fontSize: "1rem",
                                                }}
                                            />
                                        </div>
                                        <div style={{ marginTop: "10px" }}>
                                            <label htmlFor="contactNumber" style={{ display: "block", marginBottom: "5px", fontWeight: "bold" }}>
                                                Contact Number:
                                            </label>
                                            <input
                                                type="text"
                                                id="contactNumber"
                                                value={receiver_phone}
                                                onChange={(e) => setReceiver_phone(e.target.value)}
                                                placeholder="Enter receiver phone"
                                                style={{
                                                    width: "100%",
                                                    padding: "10px",
                                                    border: "1px solid #ccc",
                                                    borderRadius: "8px",
                                                    fontSize: "1rem",
                                                }}
                                            />
                                        </div>
                                        <div style={{ marginTop: "10px" }}>
                                            <label htmlFor="customAddress" style={{ display: "block", marginBottom: "5px", fontWeight: "bold" }}>
                                                Enter Delivery Address:
                                            </label>
                                            <input
                                                type="text"
                                                id="customAddress"
                                                value={customAddress}
                                                onChange={(e) => setCustomAddress(e.target.value)}
                                                placeholder="Enter receiver address"
                                                style={{
                                                    width: "100%",
                                                    padding: "10px",
                                                    border: "1px solid #ccc",
                                                    borderRadius: "8px",
                                                    fontSize: "1rem",
                                                }}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                            <Divider sx={{ marginTop: '5px' }} />
                            <CardContent
                                sx={{
                                    background: "white",
                                    padding: "20px",
                                }}
                            >
                                {cartData?.map((item, index) => (
                                    <Box
                                        key={item._id}
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            marginBottom: "20px",
                                            borderBottom: "1px solid #f1f1f1",
                                            paddingBottom: "10px",
                                        }}
                                    >
                                        {/* Checkbox and Product Details */}
                                        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                            {/* Product Image */}
                                            <img
                                                src={`${Image_URL}${item?.product_details?.images?.[0]}`}
                                                alt="Product"
                                                style={{
                                                    borderRadius: "8px",
                                                    width: "50px",
                                                    height: "50px",
                                                }}
                                            />

                                            {/* Product Info */}
                                            <Box>
                                                <Typography variant="subtitle2">{item?.product_details?.item_name}</Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    {selectedCurrency.symbol} {parseFloat(item.price * selectedCurrency.rate).toFixed(2)}
                                                    {" | "}  {parseFloat(state.type === 'MPV' ? item.item_mpv : item[state.type] || 0).toFixed(2)} {state.type}</Typography>
                                            </Box>
                                        </Box>

                                        {/* Quantity Controls */}
                                        <Box sx={{ display: "flex", flexDirection: "column", textAlign: "right" }}>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "flex-end",
                                                }}
                                            >
                                                <img
                                                    src={Minus}
                                                    alt="Decrease"
                                                    style={{
                                                        cursor: item.quantity > 0 ? "pointer" : "not-allowed",
                                                        opacity: item.quantity > 0 ? 1 : 0.5,
                                                    }}
                                                    onClick={() => handleUpdateQuantity(item, parseInt(item.quantity) - 1)}
                                                />
                                                <Typography
                                                    sx={{
                                                        fontSize: "0.9rem",
                                                        fontWeight: "bold",
                                                        color: "#333",
                                                        margin: "0 10px",
                                                    }}
                                                >
                                                    {item.quantity || 0}
                                                </Typography>
                                                <img
                                                    src={Add}
                                                    alt="Increase"
                                                    style={{
                                                        marginRight: "15px",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => handleUpdateQuantity(item, parseInt(item?.quantity) + 1)}
                                                />
                                            </Box>

                                            {/* Price & state.type */}
                                            <Typography
                                                sx={{
                                                    margin: 0,
                                                    marginRight: "10px",
                                                    fontSize: "0.8rem",
                                                    color: "#333",
                                                }}
                                            >
                                                {selectedCurrency.symbol} {parseFloat((item.quantity || 0) * parseFloat(item?.price || 0).toFixed(2) * selectedCurrency.rate).toFixed(2)}
                                                {" | "} {(item?.quantity || 0) * parseFloat(state.type === 'MPV' ? item?.item_mpv : item[state.type] || 0).toFixed(2)} {state?.type}
                                            </Typography>
                                        </Box>
                                    </Box>
                                ))}
                            </CardContent>

                            <StyledButton
                                fullWidth
                                style={{ background: isPlaceOrderDisabled ? "#ccc" : "#C1B413", marginTop: '30%' }}
                                size="large"
                                disabled={isPlaceOrderDisabled}
                                startIcon={<ShoppingCartIcon />}
                                onClick={() => HandlePlaceOrder(cartData, deliveryMethod, deliveryAddress, priorityTip, couponCode, calculations.grandTotal)}
                            >
                                PLACE ORDER (
                                {selectedCurrency.symbol} {parseFloat(calculations?.grandTotal * selectedCurrency.rate).toFixed(2)}
                                {" | "} {parseFloat(grand_mpv).toFixed(2)} {state.type})
                            </StyledButton>

                            <StyledButton
                                fullWidth
                                style={{ marginTop: '2%', background: '#F5F5F5', color: 'black' }}
                                size="large"
                                onClick={() => { window.location.reload() }}
                            >
                                Cancel
                            </StyledButton>

                        </StyledCard>
                    ) : (
                        <EmptyCard>
                            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" flexGrow={1} p={2}>
                                <img alt='' src={EmptyCart} style={{ maxWidth: '100%', height: 'auto' }} />
                                <Typography variant="h5" align="center" gutterBottom>Your Cart is Empty</Typography>
                                <Typography variant="body1" align="center">
                                    Looks like you haven't added any items to your cart yet.
                                </Typography>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    size="large"
                                    sx={{ mt: 3, py: 1.5, fontSize: '1.1rem', background: '#C1B413', maxWidth: 300, borderRadius: '20px', color: 'white' }}
                                    onClick={() => { setDrawerOpen(false) }}
                                >
                                    Start Shopping
                                </Button>
                            </Box>
                        </EmptyCard>
                    )}

                    {showEmptyCartPopup && (
                        <PopupOverlay>
                            <PopupContent>
                                <PopupHeader>
                                    <PopupIcon>
                                        <span style={{ color: 'white', fontSize: '30px' }}>?</span>
                                    </PopupIcon>
                                    <PopupTitle>Remove all cart items?</PopupTitle>
                                </PopupHeader>
                                <PopupButtons>
                                    <PopupButton onClick={handleCloseEmptyCartPopup}>No</PopupButton>
                                    <PopupButton onClick={HandleCartEmpty}>Yes</PopupButton>
                                </PopupButtons>
                            </PopupContent>
                        </PopupOverlay>
                    )}

                    {showRemoveItemPopup && (
                        <PopupOverlay>
                            <PopupContent>
                                <PopupHeader>
                                    <PopupIcon>
                                        <span style={{ color: 'white', fontSize: '30px' }}>?</span>
                                    </PopupIcon>
                                    <PopupTitle>Remove cart item?</PopupTitle>
                                </PopupHeader>
                                <PopupButtons>
                                    <PopupButton onClick={handleCloseRemoveItemPopup}>No</PopupButton>
                                    <PopupButton onClick={() => { handleRemoveItem() }}>Yes</PopupButton>
                                </PopupButtons>
                            </PopupContent>
                        </PopupOverlay>
                    )}

                </Box>
            </ThemeProvider>
        </div>
    );
};

export default CartView;