import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Row, Col, Card, CardBody } from "reactstrap";

import axios from "axios";
import { useNavigate, Link } from "react-router-dom";

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";
import AuthService from "../Authentication/auth.service";

import EmailFilter from "../../components/Table Filters/EmailLogsFilters/EmailFilter";
import FunctionFilter from "../../components/Table Filters/EmailLogsFilters/FunctionFilter";
import ActionFilter from "../../components/Table Filters/EmailLogsFilters/ActionFilter";
// import DateFilter from "../../components/Table Filters/EmailLogsFilters/ActionFilter";

import DataTable from 'react-data-table-component';
import Alerts from "../../components/Alerts/alerts";
import $ from 'jquery';
import Tooltip from '@mui/material/Tooltip';
import PermissionAccess from '../../PermissionAccess/Access.js';

const API_URL = process.env.REACT_APP_BASEURL;

const ActivityLogs = () => {

  const { t, i18n } = useTranslation();

  const [filteredItems, setFilteredItems] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [filterInvoice_StartDate, setFilterInvoice_StartDate] = useState('');
  const [filterInvoicdue_date, setFilterInvoicdue_date] = useState('');
  const { authData, accessPages, super_admin, current_module } = PermissionAccess();

  const [filterText1, setFilterText1] = useState('');
  const [filterText2, setFilterText2] = useState('');
  const [filterText3, setFilterText3] = useState('');
  const [filterText4, setFilterText4] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    getlogs(0, pageLimit);
  }, [pageLimit]);

  async function getlogs(page_index, page_limit) {
    const headers = {
      'Content-Type': 'application/json',
      'token': authData.sessionID
    }
    var filter = {}

    if ($("#datefilter").val() !== "") {
      if (filterInvoice_StartDate) {
        filter["startDate"] = filterInvoice_StartDate;
      }
      if (filterInvoicdue_date) {
        filter["endDate"] = filterInvoicdue_date;
      }
    }
    if ($("#colfilter").val() !== "")
      filter["search_text"] = $("#colfilter").val();

    if ($("#actfilter").val() !== "")
      filter["status"] = $("#actfilter").val();

    var offset = (page_index - 1) * page_limit;
    if (offset < 1) offset = 0;
    var getData = {
      functionName: "GetEmailLogs",
      postData: {
        "email_name": "email_logs",
        "sort": { _id: -1 },
        "limit": page_limit,
        "offset": offset,
        "filter": filter,
      },
    }
    setLoading(true);
    axios.post(API_URL, getData, { headers })
      .then((response) => {
        setLoading(false);
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        }
        else {
          const data = response.data.data;
          console.log("Get Loglist Resp:-", data);
          setFilteredItems(data);
          setTotalRows(response.data.count.count);
        }
      }).catch((error) => {
        setLoading(false);
        console.log("API Error:-", error)
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        Alerts.swalErrorAlert("Network Error. Unable to retrieve data from Server")
        // alert(t("Unable to retrieve data from Server"))
      });

  };

  function handlePageChange(page_index) {
    getlogs(page_index, pageLimit);
  }

  async function handlePerRowsChange(newPageLimit, page_index) {
    setPageLimit(newPageLimit);
  }

  const TBcolumns = [
    {
      name: t('Recipient'),
      width: '20%',
      selector: row => row?.recipient,
    },
    {
      name: t('Subject'),
      width: '20%',
      selector: row => row?.subject,
    },
    // {
    //   name: t('Module'),
    //   width: '15%',
    //   selector: row => row.module_name,
    // },
    {
      name: t('Status'),
      width: '15%',
      selector: (row) => {
        return (
          <div>
            {row.status === "queued" ?
              <button type="button" style={{ borderColor: '#F39026', backgroundColor: '#F39026' }}
                className="btn btn-success btn-hover-shine me-3 btn">{row.status}
              </button>
              :
              row.status === "failed" ?
                <button type="button"
                  className="btn btn-danger btn-hover-shine me-3 btn">Failed
                </button>
                :
                row.status === "sent" ?
                  <button type="button" style={{ borderColor: '#1BD35A', backgroundColor: '#1BD35A' }}
                    className="btn btn-c btn-hover-shine me-3 btn">Sent
                  </button>
                  :
                  row.status === "delivered" ?
                  <button type="button" style={{ borderColor: '#1BD35A', backgroundColor: '#1BD35A' }}
                    className="btn btn-c btn-hover-shine me-3 btn">Delivered
                  </button>
                  :
                  row.status === "bounced" ?
                    <button type="button"
                      className="btn btn-danger btn-hover-shine me-3 btn">{row.status}
                    </button>
                    :
                    <button type="button"
                      className="btn btn-default btn-hover-shine me-3 btn">{row.status}
                    </button>
            }
          </div>
        );
      }
    },
    {
      name: t('Date'),
      width: '20%',
      // selector: row => format(parseISO(row.datetime_added, new Date()), "dd-MMM-yyyy H:i:s"),
      selector: (row) => {
        return row?.createdAt ? new Date(row.createdAt).toLocaleString() : ''
      }

    },
    {
      name: t('Actions'),
      width: '20%',
      selector: (row) => {
        return (
          <div>
            <Link to='/view-email-log' state={row} >
              <Tooltip placement="top" title={t("View Log Details")} arrow>
                <button className="mt-2 mb-2 me-2 btn-icon btn-link btn text-info">
                  <i className="pe-7s-news-paper btn-icon-wrapper"> </i>
                </button>
              </Tooltip >
            </Link>
          </div>
        );
      }
    },
  ];

  const subHeaderComponentMemo = React.useMemo(() => {

    return (
      <>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <EmailFilter
            onFilter1={e => setFilterTextFtn(e.target.value, 'email')}
            handleKeyDown={event => checkenterkey(event)}
            filterText1={filterText1}
            onClear={() => handleClear('email')}
          />
          <ActionFilter
            handleKeyDown={event => checkenterkey(event)}
            onFilter2={e => setFilterTextFtn(e.target.value, 'status')}
            filterText2={filterText2}
            onClear={e => handleClear('status')}
          />
          <FunctionFilter
            onFilterInvoice_StartDate={(e) => {
              console.log('e', e);
              setFilterInvoice_StartDate(e)
            }}
            onFilterInvoicdue_date={(e) => setFilterInvoicdue_date(e)}
            handleKeyDown={event => checkenterkey(event)}
            onClear={e => handleClear('date')}
          />
          <button style={{ marginBottom: '10px', marginLeft: '1%' }} type="button" onClick={search_logs} className="btn btn-custom-color btn-hover-shine me-3 ">
            {t("Search")}
          </button>
          <button style={{ marginBottom: '10px', marginLeft: '1%' }} type="button" onClick={clear_filters} className="btn btn-custom-color btn-hover-shine me-3 ">
            {t("Clear All")}
          </button>

          {/* <DateFilter 
          handlechange = {event => handledatechange(event)}
          onFilter4={e => setFilterTextFtn(e.target.value, 'date')}
          filterText4={filterText4} 
          onClear={handleClear} 
        /> */}
        </div>

      </>
    );
  }, [filterText1, filterText2, filterInvoice_StartDate, filterInvoicdue_date, , filterText3, filterText4, resetPaginationToggle]);


  function setFilterTextFtn(itemrec, param) {
    console.log('item:-', itemrec)
    if (param === 'email') {
      setFilterText1(itemrec);
    }
    if (param === 'status') {
      setFilterText2(itemrec);
    }
  };

  function clear_filters() {

    $("#colfilter").val('');
    setFilterInvoicdue_date('');
    setFilterInvoice_StartDate('');
    setFilterText1('');
    $("#actfilter").val('');
    setFilterInvoicdue_date('');
    setFilterInvoice_StartDate('');
    setFilterText2('');
    $("#datefilter").val('');
    setFilterInvoicdue_date('');
    setFilterInvoice_StartDate('');
    // setResetPaginationToggle(!resetPaginationToggle);
    // var page = 0
    // handlePageChange(page++)

    let page_index = 0
    let page_limit = pageLimit
    const headers = {
      'Content-Type': 'application/json',
      'token': authData.sessionID
    }
    var filter = {}

    if ($("#datefilter").val() !== "") {
      if (filterInvoice_StartDate) {
        filter["startDate"] = '';
      }
      if (filterInvoicdue_date) {
        filter["endDate"] = '';
      }
    }
    if ($("#colfilter").val() !== "")
      filter["search_text"] = $("#colfilter").val();

    if ($("#actfilter").val() !== "")
      filter["status"] = $("#actfilter").val();

    var offset = (page_index - 1) * page_limit;
    if (offset < 1) offset = 0;
    var getData = {
      functionName: "GetEmailLogs",
      postData: {
        "email_name": "email_logs",
        "sort": { _id: -1 },
        "limit": page_limit,
        "offset": offset,
        "filter": filter,
      },
    }
    setLoading(true);
    axios.post(API_URL, getData, { headers })
      .then((response) => {
        setLoading(false);
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        }
        else {
          const data = response.data.data;
          console.log("Get Loglist Resp:-", data);
          setFilteredItems(data);
          setTotalRows(response.data.count.count);
        }
      }).catch((error) => {
        setLoading(false);
        console.log("API Error:-", error)
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        Alerts.swalErrorAlert("Network Error. Unable to retrieve data from Server")
        // alert(t("Unable to retrieve data from Server"))
      });
  }

  function checkenterkey(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      setResetPaginationToggle(!resetPaginationToggle);
      var page = 0
      handlePageChange(page++)
    }
  };

  function search_logs() {
    setResetPaginationToggle(!resetPaginationToggle);
    var page = 0
    handlePageChange(page++)
  };

  function handledatechange(event) {
    var page = 0
    setFilterText4($("#datefilter").val());
    handlePageChange(page++)
  };

  function handleClear(item) {
    console.log("item", item);
    if (item === 'email') {
      $("#colfilter").val('');
      setFilterText1('');
      setResetPaginationToggle(!resetPaginationToggle);
      var page = 0
      handlePageChange(page++)
    }
    if (item === 'status') {
      // $("#modfilter").val('');
      $("#actfilter").val('');
      setFilterText2('');
      setResetPaginationToggle(!resetPaginationToggle);
      var page = 0
      handlePageChange(page++)
    }
    if (item === 'date') {
      $("#datefilter").val('');
      setFilterInvoicdue_date('');
      setFilterInvoice_StartDate('');
      setResetPaginationToggle(!resetPaginationToggle);
      var page = 0
      handlePageChange(page++)
    }
  };


  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <TransitionGroup>
            <CSSTransition component="div" classNames="TabsAnimation" appear={true}
              timeout={1500} enter={false} exit={false}>
              <div>
                <div className="app-page-title">
                  <div className="page-title-wrapper">
                    <div className="page-title-heading fw-bold">
                      <div className="page-title-icon">
                        <i className="pe-7s-display2 icon-gradient bg-happy-green" />
                      </div>
                      <div>
                        {t('Email Logs')}
                        <div className="page-title-subheading">
                          {t('View all Email Logs. Click on a log to view its details.')}
                        </div>
                      </div>
                    </div>
                    <div className="page-title-actions">
                      <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-success btn-hover-shine me-3">
                        {t('Back')}
                      </button>
                    </div>
                  </div>
                </div>
                <Row>
                  <Col md="12">
                    <Card className="main-card mb-3">
                      <CardBody>
                        <LoadingOverlay tag="div" active={loading}
                          styles={{ overlay: (base) => ({ ...base }) }}
                          spinner={<DNALoader />}>
                          <Col md="12" style={{ float: "left", position: "absolute", zIndex: "5" }}>
                            {subHeaderComponentMemo}
                          </Col>

                          <DataTable
                            columns={TBcolumns}
                            data={filteredItems}
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handlePerRowsChange}
                            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                            subHeader
                            selectableRowDisabled={true}
                            persistTableHead
                          />
                        </LoadingOverlay>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
    </Fragment >
  );
}

export default ActivityLogs;