import React, { useEffect, useState, Fragment, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import BusinessService from "./Businesses.service.js";
import AuthService from "../Authentication/auth.service.js";
import Alerts from "../../components/Alerts/alerts.js";
import QRCode from 'qrcode.react';
import html2canvas from 'html2canvas';

const TabGenralConfig = ({ business_id }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const qrCodeRef = useRef(null);

  const loginUrl = `${process.env.REACT_APP_IMAGEURL}Login_Customer?business_id=${business_id}`;
  const [logo, setLogo] = useState(localStorage.getItem("system_logo"));

  const [data, setData] = useState({
    name: '',
    email: '',
    status: '',
  });

  //get data
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      BusinessService.GetSingleBusiness(business_id).then((response) => {
        setLoading(false);
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        } else {
          if (response.data.status === true) {
            setData({
              name: response.data.data.data.name,
              email: response.data.data.data.email,
              status: response.data.data.data.status,
            });
          } else {
            Alerts.swalErrorAlert(t("Something went wrong, Please try again."));
          }
        }
      }, (error) => {
        setLoading(false);
        const errContent = (error.response && error.response.data) || error.message || error.toString();
        if (errContent) {
          Alerts.swalErrorAlert(errContent);
        } else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."));
        }
      });
    };
    fetchData();
  }, [business_id]);

  const downloadQRCode = () => {
    html2canvas(qrCodeRef.current).then((canvas) => {
      const link = document.createElement('a');
      link.download = 'qrcode.png';
      link.href = canvas.toDataURL();
      link.click();
    });
  };


  const printQRCode = () => {
    const printContent = `
      <div class="print-container">
        <div class="print-header">
          <img src="${logo}" alt="Business Logo" class="print-logo" />
          <h1 class="print-title">${data.name}</h1>
          <h2 class="print-subtitle">Welcome to Our Store</h2>
        </div>
        <div class="print-qr">
          <img src="${qrCodeRef.current.children[3].toDataURL()}" alt="QR Code" />
        </div>
        <p class="print-text">Scan the QR code to get your e-invoice</p>
        <div class="print-footer">
          <p class="print-footer-details">${data.email}</p>
          <p class="print-footer-details">Address: 123 Business Street, City, Country</p>
          <p class="print-footer-details">Phone: (123) 456-7890</p>
          <p class="print-footer-details">Website: www.businesswebsite.com</p>
        </div>
      </div>
    `;
  
    const WindowPrint = window.open('', '', 'width=600,height=800');
    WindowPrint.document.write('<html><head><title>Print QR Code</title>');
    WindowPrint.document.write(`
      <style>
        @page {
          size: A4;
          margin: 10mm;
        }
        body {
          font-family: Arial, sans-serif;
          text-align: center;
        }
        .print-container {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          padding: 20px;
        }
        .print-header {
          text-align: center;
          margin-bottom: 20px;
        }
        .print-logo {
          max-width: 100px;
          margin-bottom: 10px;
        }
        .print-title {
          font-size: 30px;
          font-weight: bold;
          margin: 5px 0;
          color: #333;
        }
        .print-subtitle {
          font-size: 22px;
          color: #777;
          margin-bottom: 20px;
        }
        .print-qr img {
          width: 500px;
          height: 500px;
          border: 3px solid #333;
          padding: 20px;
          background-color: #f8f8f8;
        }
        .print-text {
          font-weight: bold;
          font-size: 20px;
          color: #333;
          margin-top: 20px;
        }
        .print-footer {
          margin-top: 30px;
          text-align: center;
          color: #555;
          font-size: 16px;
        }
        .print-footer-details {
          margin: 2px 0;
        }
      </style>
    `);
    WindowPrint.document.write('</head><body>');
    WindowPrint.document.write(printContent);
    WindowPrint.document.close();
    WindowPrint.print();
  };
  


  return (
    <Fragment>
      <Row>
        <Col md="12">
          <Card className="main-card" style={{
            textAlign: 'center',
            padding: '30px',
            background: 'linear-gradient(to right, #f0f4f7, #ffffff)',
            borderRadius: '15px',
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)',
          }}>
            <LoadingOverlay tag="div" active={loading}
              styles={{ overlay: (base) => ({ ...base }) }}
              spinner={<DNALoader />}>
              <CardBody>
                <div style={{
                  backgroundColor: '#2c3e50',
                  color: '#fff',
                  padding: '10px',
                  borderRadius: '10px',
                  marginBottom: '20px',
                }}>
                  <h2 style={{ marginBottom: '10px', fontWeight: 'bold' }}>{t('Welcome to Our Store')}</h2>
                  <p>{t('Scan the QR Code to Login/Signup')}</p>
                </div>
                <div ref={qrCodeRef} style={{
                  display: 'inline-block',
                  padding: '20px',
                  backgroundColor: '#fff',
                  borderRadius: '15px',
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  cursor: 'pointer',
                  textAlign: 'center',
                  border: '2px solid #e0e0e0',
                }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.transform = 'scale(1.05)';
                    e.currentTarget.style.boxShadow = '0 8px 24px rgba(0, 0, 0, 0.2)';
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.transform = 'scale(1)';
                    e.currentTarget.style.boxShadow = '0 4px 12px rgba(0, 0, 0, 0.1)';
                  }}>
                  <img src={logo} alt="Business Logo" className="print-logo" style={{
                    maxWidth: '120px',
                    marginBottom: '15px',
                  }} />
                  <h5 className="print-details" style={{
                    fontSize: '18px',
                    fontWeight: 'bold',
                    color: '#333',
                  }}>{data.name}</h5>
                  <p className="print-details" style={{
                    fontSize: '16px',
                    color: '#666',
                  }}>{data.email}</p>
                  <QRCode value={loginUrl} size={200} className="print-qr" style={{
                    border: '2px solid #e0e0e0',
                    padding: '10px',
                    backgroundColor: '#f9f9f9',
                  }} />
                  <p className="print-text" style={{
                    marginTop: '20px',
                    fontWeight: 'bold',
                    color: '#333',
                    fontSize: '16px',
                  }}>{t('Scan the QR code to get your e-invoice')}</p>
                </div>
                <div style={{ marginTop: '20px' }}>
                  <Button color="primary" onClick={downloadQRCode}
                    style={{ marginRight: '10px', transition: 'background-color 0.3s ease' }}
                    onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#0056b3'}
                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = ''}>
                    {t('Download QR Code')}
                  </Button>
                  <Button color="secondary" onClick={printQRCode}
                    style={{ transition: 'background-color 0.3s ease' }}
                    onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#5a6268'}
                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = ''}>
                    {t('Print QR Code')}
                  </Button>
                </div>
              </CardBody>
            </LoadingOverlay>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default TabGenralConfig;
