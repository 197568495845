import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, Navigate } from "react-router-dom";
import { Row, Col, FormGroup, Input, Button, Label, Modal, ModalHeader, ModalBody, Card, CardBody } from "reactstrap";
import Services from "./services";
import AuthService from "../Authentication/auth.service";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import Alerts from "../../components/Alerts/alerts";
import PermissionAccess from '../../PermissionAccess/Access.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faTimes, faFilePdf, faImage } from '@fortawesome/free-solid-svg-icons';

const UpdatePackage = ({ PACKAGE, onSuccess, onCancel }) => {
  const [modal, setModal] = useState(true);
  const [Package, setPackage] = useState({ ...PACKAGE });
  const [loading, setLoading] = useState(false);
  const [loginNavigate, setLoginNavigate] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);
  const { authData } = PermissionAccess();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
  }, [i18n]);



  const handlePackageChange = (field, value) => {
    setPackage(prev => ({ ...prev, [field]: value }));
  };

  const validateForm = () => {
    return Package.name &&
      Package.price &&
      Package.mpv &&
      Package.multiplier &&
      Package.description

  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      Alerts.swalErrorAlert("Please fill all fields and upload at least one document for the Package.");
      return;
    }

    let payload = {
      name: Package.name,
      price: Package.price,
      mpv: Package.mpv,
      multiplier: Package.multiplier,
      description: Package.description
    }

    setLoading(true);
    try {
      const response = await Services.UpdatePackage(authData.sessionID, Package._id, payload);
      setLoading(false);
      if (response.data.status) {
        onSuccess();
        Alerts.swalSuccessAlert(response.data.message || "Package Updated Successfully.").then(() => {
          setModal(false);
        });
      } else {
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          setLoginNavigate(true);
        } else {
          Alerts.swalErrorAlert(response.data.message || "Error: Can't Update Package at the moment. Please try again later.");
        }
      }
    } catch (error) {
      setLoading(false);
      Alerts.swalErrorAlert("Network Operation failed.");
    }
  };

  if (loginNavigate) {
    return <Navigate to={'/login'} />;
  }

  return (
    <Modal size='lg' isOpen={modal} toggle={() => setModal(false)} backdrop={true}>
      <LoadingOverlay active={loading} spinner={<DNALoader />}>
        <ModalHeader>{t("Update Package")}</ModalHeader>
        <ModalBody>
          <Card className="main-card mb-3">
            <CardBody>
              <form onSubmit={handleSubmit}>
                <FormGroup className="mb-3">
                  <Row>
                    <Col md="12">
                      <Label>{t("Package Name")}</Label>
                      <Input
                        type="text"
                        value={Package.name}
                        onChange={(e) => handlePackageChange('name', e.target.value)}
                        placeholder={t("Enter Package Name")}
                      />
                    </Col>
                    <Col style={{ marginTop: '15px' }} md="12">
                      <Label>{t("Price")}</Label>
                      <Input
                        type="number"
                        value={Package.price}
                        onChange={(e) => handlePackageChange('price', e.target.value)}
                        placeholder={t("Enter Registration Number")}
                      />
                    </Col>
                    <Col style={{ marginTop: '15px' }} md="12">
                      <Label>{t("Points Value")}</Label>
                      <Input
                        type="number"
                        value={Package.mpv}
                        onChange={(e) => handlePackageChange('mpv', e.target.value)}
                        placeholder={t("Enter points value")}
                      />
                    </Col>
                    <Col style={{ marginTop: '15px' }} md="12">
                      <Label>{t("Multiplier")}</Label>
                      <Input
                        type="number"
                        value={Package.multiplier}
                        onChange={(e) => handlePackageChange('multiplier', e.target.value)}
                        placeholder={t("Enter Multiplier")}
                      />
                    </Col>
                    <Col style={{ marginTop: '15px' }} md="12">
                      <Label>{t("Description")}</Label>
                      <Input
                        type="text"
                        value={Package.description}
                        onChange={(e) => handlePackageChange('description', e.target.value)}
                        placeholder={t("Enter Description")}
                      />
                    </Col>
                  </Row>
                  <div className="text-center mt-3">
                    <Button type="submit" color="success" size="lg" className="me-3">
                      {t("Update")}
                    </Button>
                    <Button type="button" color="secondary" size="lg" onClick={() => { setModal(false); onCancel(); }}>
                      {t("Cancel")}
                    </Button>
                  </div>
                </FormGroup>
              </form>
            </CardBody>
          </Card>
        </ModalBody>
      </LoadingOverlay>
    </Modal >
  );
};

export default UpdatePackage;