import React from "react";
import { Modal, ModalBody, Button } from "reactstrap";
import { Divider } from "@mui/material";
import styled from "styled-components";
import moment from "moment";

const StyledModal = styled(Modal)`
.modal-dialog {
  max-width: 200px !important;
  width: 90%;
  margin: 10% auto;
}

.modal-content {
  border-radius: 10px;
  padding: 20px;
  border: 1px solid #e5e5e5;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
`;

const ModalHeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  color: #333;
  padding-bottom: 10px;
  margin-bottom: 5px;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 30px;
  color: #333;
  cursor: pointer;

  &:hover {
    color: #000;
  }
`;

const SectionTitle = styled.p`
  font-weight: bold;
  font-size: 14px;
  color: black;
  margin: 0;
`;

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  margin-top: 10px;

  .info-label {
    font-weight: bold;
    color: #333;
  }

  .info-value {
    color: #C1B314;
    cursor: pointer;
    text-decoration: underline;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  .image-wrapper {
    text-align: center;

    img {
      width: 90px;
      height: 90px;
      object-fit: cover;
      border: 1px solid #ddd;
      border-radius: 5px;
    }

    p {
      margin-top: 5px;
      font-size: 12px;
      color: #666;
      font-weight: bold;
    }
  }
`;

const FooterButtons = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 20px;

  button {
    width: 100%;
    height: 40px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 5px;
  }

  .approve-btn {
    background-color: #C1B314;
    color: #fff;
    border: none;

    &:hover {
      background-color: #6eb233;
    }
  }

  .reject-btn {
    background-color: #FEB6B6;
    color: #ED4E57;
    border: none;

    &:hover {
      color: #fff;
      background-color: #e24b4e;
    }
  }
`;

const requestApprovalPopup = ({ request, onClose, onApprove, onReject }) => {
  if (!request) return null;
  console.log("request", request);
  return (
    <StyledModal isOpen={!!request} toggle={onClose}>
      <ModalBody>
        {/* Header */}
        <ModalHeaderStyled>
          <span>Top Up Details</span>
          <CloseButton onClick={onClose}>×</CloseButton>
        </ModalHeaderStyled>

        {/* Description */}
        <SectionTitle>
          Review and Manage Top-Up Transaction Details for Accurate Account Administration.
        </SectionTitle>

        {/* Info Rows */}
        <InfoRow>
          <div style={{ color: '#838383' }} className="info-label">Name:</div>
          <div style={{ color: '#838383' }} className="info-label">User Name:</div>
        </InfoRow>
        <InfoRow style={{ marginTop: '-3px' }}>
          <div className="info-label">{request?.user_Details?.[0]?.fullName || "user"}</div>
          <div className="info-label">{request?.user_Details?.[0]?.username || "username"}</div>
        </InfoRow>

        <InfoRow style={{ marginTop: '20px' }}>
          <div style={{ color: '#838383' }} className="info-label">Phone Number:</div>
          <div style={{ color: '#838383' }} className="info-label">Type:</div>
        </InfoRow>
        <InfoRow style={{ marginTop: '-3px' }}>
          <div className="info-label">{request?.user_Details?.[0]?.mobileNumber || "N/A"}</div>
          <div className="info-label">{request?.user_Details?.[0]?.user_type || "Member"}</div>
        </InfoRow>

        <InfoRow style={{ marginTop: '20px' }}>
          <div style={{ color: '#838383' }} className="info-label">Date & Time:</div>
          <div style={{ color: '#838383' }} className="info-label">Status:</div>
        </InfoRow>
        <InfoRow style={{ marginTop: '-3px' }}>
          <div className="info-label">{moment(request?.datetime_added).format("DD-MM-YYYY hh:mm a") || "N/A"}</div>
          <div className="info-label">{request?.status || ""}</div>
        </InfoRow>

        <Divider sx={{ marginTop: '20px' }} />

        <InfoRow >
          <div style={{ color: '#838383' }} className="info-label">Requested MPV:</div>
          <div style={{ color: 'black' }} className="info-label">{request?.MPV}</div>
        </InfoRow>
        <InfoRow >
          <div style={{ color: '#838383' }} className="info-label">Amount:</div>
          <div style={{ color: 'black' }} className="info-label">{request?.amount}</div>
        </InfoRow>
        <InfoRow >
          <div style={{ color: '#838383' }} className="info-label">{`Discount: ${request?.discount} %)`}</div>
          <div style={{ color: 'black' }} className="info-label">{request?.discountAmount}</div>
        </InfoRow>
        <InfoRow >
          <div style={{ color: '#838383' }} className="info-label">Paid:</div>
          <div style={{ color: 'black' }} className="info-label">{request?.payableAmount}</div>
        </InfoRow>

        <Divider sx={{ marginTop: '30px' }} />
        <div style={{ color: '#838383' }} className="info-label">Payment Proof:</div>
        {request?.paymentProof && (
          < ImageContainer >
            <div className="image-wrapper">
              {request?.paymentProof?.toLowerCase()?.startsWith('data:application/pdf;base64') ?
                <iframe src={request?.paymentProof} alt="Proof" />
                :
                <img src={request?.paymentProof} alt="Proof" />
              }
            </div>
          </ImageContainer>
        )}


        {/* Footer Buttons */}
        {request?.status === "pending" ?
          <>
            <FooterButtons>
              <Button className="approve-btn" onClick={onApprove}>
                Approve
              </Button>
            </FooterButtons>
            <FooterButtons>
              <Button className="reject-btn" onClick={onReject}>
                Reject
              </Button>
            </FooterButtons>
          </>
          : ''
        }
      </ModalBody>
    </StyledModal >
  );
};

export default requestApprovalPopup;
