import React, { useState } from "react";
import { Modal, ModalBody, Button } from "reactstrap";
import { Divider } from "@mui/material";
import styled from "styled-components";
import moment from "moment";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

const StyledModal = styled(Modal)`
.modal-dialog {
  max-width: 200px !important;
  width: 90%;
  margin: 10% auto;
}

.modal-content {
  border-radius: 10px;
  padding: 20px;
  border: 1px solid #e5e5e5;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
`;

const ModalHeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  color: #333;
  padding-bottom: 10px;
  margin-bottom: 5px;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 30px;
  color: #333;
  cursor: pointer;

  &:hover {
    color: #000;
  }
`;

const SectionTitle = styled.p`
  font-weight: bold;
  font-size: 14px;
  color: black;
  margin: 0;
`;

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  margin-top: 10px;

  .info-label {
    font-weight: bold;
    color: #333;
  }

  .info-value {
    color: #C1B314;
    cursor: pointer;
    text-decoration: underline;
  }
`;

const EnlargedModal = styled(Modal)`
  .modal-dialog {
    max-width: 100%;
    margin: 10% auto;
  }
  .modal-content {
    border-radius: 10px;
    padding: 20px;
    border: 1px solid #e5e5e5;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  .modal-body {
    text-align: center;
  }
  .enlarged-content {
    max-width: 100%;
    max-height: 80vh;
    margin: auto;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin-top: 15px;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  padding-top: 15px;

  .image-wrapper {
    text-align: center;
    width: 100px;
    height: 100px;

    img,
    iframe {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border: 1px solid #ddd;
      border-radius: 5px;
      cursor: pointer; /* Indicate it's clickable */
    }
  }
`;

const FooterButtons = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 20px;

  button {
    width: 100%;
    height: 40px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 5px;
  }

  .approve-btn {
    background-color: #C1B314;
    color: #fff;
    border: none;

    &:hover {
      background-color: #6eb233;
    }
  }

  .reject-btn {
    background-color: #FEB6B6;
    color: #ED4E57;
    border: none;

    &:hover {
      color: #fff;
      background-color: #e24b4e;
    }
  }
`;

const RequestApprovalPopup = ({ request, onClose, onApprove, onReject }) => {
  const [enlargedContent, setEnlargedContent] = useState(null); // For selected image/pdf
  const [isEnlargedModalOpen, setEnlargedModalOpen] = useState(false);
  const handleEnlarge = (content) => {
    setEnlargedContent(content);
    setEnlargedModalOpen(true);
  };

  // Close Enlarged Modal
  const closeEnlargedModal = () => {
    setEnlargedContent(null);
    setEnlargedModalOpen(false);
  };

  // Render Payment Proofs
  const renderPaymentProofs = () =>
    request.StockistData?.paymentProof?.map((proof, index) => (
      <div onClick={() => handleEnlarge(proof)} className="image-wrapper" key={index}>
        {proof?.toLowerCase()?.startsWith("data:application/pdf;base64") ? (
          // <iframe
          //   src={proof}
          //   title={`Proof ${index}`}
          //   onClick={() => handleEnlarge(proof)}
          // />
          <PictureAsPdfIcon onClick={() => handleEnlarge(proof)}  sx={{ fontSize: "100px", color: "#C60023" }} />
        ) : (
          <img src={proof} alt={`Proof ${index}`} onClick={() => handleEnlarge(proof)} />
        )}
      </div>
    ));

  // Render Required Documents
  const renderRequiredDocuments = () =>
    request.StockistData?.RequiredDocuments?.map((doc, index) => (
      <div onClick={() => handleEnlarge(doc)} className="image-wrapper" key={index}>
        {doc?.toLowerCase()?.startsWith("data:application/pdf;base64") ? (
          <PictureAsPdfIcon onClick={() => handleEnlarge(doc)}  sx={{ fontSize: "100px", color: "#C60023" }} />
          // <iframe
          //   src={doc}
          //   title={`Document ${index}`}
          //   onClick={() => handleEnlarge(doc)}
          // />
        ) : (
          <img src={doc} alt={`Document ${index}`} onClick={() => handleEnlarge(doc)} />
        )}
      </div>
    ));

  if (!request) return null;
  return (
    <>
      <StyledModal isOpen={!!request} toggle={onClose}>
        <ModalBody>
          {/* Header */}
          <ModalHeaderStyled>
            <span>Request For Stockist</span>
            <CloseButton onClick={onClose}>×</CloseButton>
          </ModalHeaderStyled>

          {/* Description */}
          <SectionTitle>
            Review and Manage Stockist Request Details for Accurate Account Administration.
          </SectionTitle>

          {/* Info Rows */}
          <InfoRow>
            <div style={{ color: '#838383' }} className="info-label">Name:</div>
            <div style={{ color: '#838383' }} className="info-label">User Name:</div>
          </InfoRow>
          <InfoRow style={{ marginTop: '-3px' }}>
            <div className="info-label">{request?.user_Details?.fullName || "user"}</div>
            <div className="info-label">{request?.username || "username"}</div>
          </InfoRow>

          <InfoRow style={{ marginTop: '20px' }}>
            <div style={{ color: '#838383' }} className="info-label">Phone Number:</div>
            <div style={{ color: '#838383' }} className="info-label">Type:</div>
          </InfoRow>
          <InfoRow style={{ marginTop: '-3px' }}>
            <div className="info-label">{request?.mobileNumber || "N/A"}</div>
            <div className="info-label">{request?.user_type || "Member"}</div>
          </InfoRow>

          <InfoRow style={{ marginTop: '20px' }}>
            <div style={{ color: '#838383' }} className="info-label">Date & Time:</div>
            <div style={{ color: '#838383' }} className="info-label">Status:</div>
          </InfoRow>
          <InfoRow style={{ marginTop: '-3px' }}>
            <div className="info-label">{moment(request?.StockistData?.datetime_added).format("DD-MM-YYYY hh:mm a") || "N/A"}</div>
            <div className="info-label">{request?.StockistData?.status || ""}</div>
          </InfoRow>

          <InfoRow style={{ marginTop: '20px' }}>
            <div style={{ color: '#838383' }} className="info-label">Requested For:</div>
            <div style={{ color: '#838383' }} className="info-label">City:</div>
          </InfoRow>
          <InfoRow style={{ marginTop: '-3px' }}>
            <div className="info-label">{request?.StockistData?.StockistType || "N/A"}</div>
            <div className="info-label">{request?.CityName || ""}</div>
          </InfoRow>


          <Divider sx={{ marginTop: '20px' }} />

          <InfoRow >
            <div style={{ color: '#838383' }} className="info-label">Requested MPV:</div>
            <div style={{ color: 'black' }} className="info-label">{request?.StockistData?.MPV}</div>
          </InfoRow>
          <InfoRow >
            <div style={{ color: '#838383' }} className="info-label">Amount:</div>
            <div style={{ color: 'black' }} className="info-label">{request?.StockistData?.amount}</div>
          </InfoRow>
          <InfoRow >
            <div style={{ color: '#838383' }} className="info-label">{`Discount: ${request?.StockistData?.discount} %)`}</div>
            <div style={{ color: 'black' }} className="info-label">{request?.StockistData?.discountAmount}</div>
          </InfoRow>
          <InfoRow >
            <div style={{ color: '#838383' }} className="info-label">Paid:</div>
            <div style={{ color: 'black' }} className="info-label">{request?.StockistData?.payableAmount}</div>
          </InfoRow>

          {/* Payment Proof Section */}
          <Divider sx={{ marginTop: "30px" }} />
          <div style={{ color: "#838383" }} className="info-label">
            Payment Proof:
          </div>
          <ImageContainer>{renderPaymentProofs()}</ImageContainer>

          {/* Required Documents Section */}
          <Divider sx={{ marginTop: "30px" }} />
          <div style={{ color: "#838383" }} className="info-label">
            Required Documents:
          </div>
          <ImageContainer>{renderRequiredDocuments()}</ImageContainer>




          {/* Footer Buttons */}
          <FooterButtons>
            <Button className="approve-btn" onClick={onApprove}>
              Approve
            </Button>
          </FooterButtons>
          <FooterButtons>
            <Button className="reject-btn" onClick={onReject}>
              Reject
            </Button>
          </FooterButtons>

        </ModalBody>
      </StyledModal >
      {/* Enlarged Modal */}
      <EnlargedModal isOpen={isEnlargedModalOpen} toggle={closeEnlargedModal}>
        <ModalBody>
          {enlargedContent ? (
            enlargedContent?.toLowerCase()?.startsWith("data:application/pdf;base64") ? (
              <iframe
                src={enlargedContent}
                title="Enlarged PDF"
                className="enlarged-content"
              />
            ) : (
              <img src={enlargedContent} alt="Enlarged Content" className="enlarged-content" />
            )
          ) : null}

         
        </ModalBody>
        <Button
            style={{ marginTop: "10px" }}
            color="primary"
            onClick={() => {
              const a = document.createElement("a");
              a.href = enlargedContent;
              a.download = "download";
              a.click();
            }}
          >
            Download
          </Button>
      </EnlargedModal>
    </>

  );
};

export default RequestApprovalPopup;
