import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, Navigate } from "react-router-dom";
import { 
  Row, Col, FormGroup, Input, Button, Label, 
  Modal, ModalHeader, ModalBody, Card, CardBody 
} from "reactstrap";
import Services from "./services";
import AuthService from "../Authentication/auth.service";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import Alerts from "../../components/Alerts/alerts";
import PermissionAccess from '../../PermissionAccess/Access.js';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroup, faPercent, faUsers } from '@fortawesome/free-solid-svg-icons';

const StyledModal = styled(Modal)`
  .modal-content {
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
`;

const StyledCard = styled(Card)`
  border: none;
  box-shadow: none;
`;

const FormLabel = styled(Label)`
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledInput = styled(Input)`
  border-radius: 6px;
  border: 1px solid #e2e8f0;
  padding: 10px;
  font-size: 14px;
  transition: all 0.2s;
  
  &:focus {
    border-color: #3B82F6;
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.1);
  }
`;

const ActionButton = styled(Button)`
  font-weight: 600;
  padding: 8px 20px;
  border-radius: 6px;
  transition: all 0.2s;
  color:white;
  background:var(--buttons-color, #30b1ff);
  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
`;

const CancelButton = styled(Button)`
  font-weight: 600;
  padding: 8px 20px;
  border-radius: 6px;
  transition: all 0.2s;
  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
`;

const AddMLMTier = ({ onSuccess }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [tierData, setTierData] = useState({
    level: '',
    percentage: '',
    referral_tier: ''
  });
  const [loading, setLoading] = useState(false);
  const [loginNavigate, setLoginNavigate] = useState(false);
  const [Fetched_Businesses, setFetched_Businesses] = useState([]);
  const { authData, accessPages, super_admin, current_module } = PermissionAccess();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const modalRef = useRef(null);

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    Fetch_Businesses();
  }, []);

  function Fetch_Businesses() {
    setLoading(true);
    Services.GetBusinesses(authData.sessionID).then(
      (response) => {
        setLoading(false);
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        } else {
          const business = response.data.data[0];
          setFetched_Businesses([business]);
        }
      },
      (error) => {
        setLoading(false);
        const errContent = (error.response && error.response.data) || 
          error.message || 
          error.toString();
        Alerts.swalErrorAlert(errContent || t("Server Error, Please try again later."));
      }
    );
  }

  const toggle = () => {
    setModalOpen(!modalOpen);
    if (!modalOpen) {
      setTierData({
        level: '',
        percentage: '',
        referral_tier: ''
      });
    }
  };

  const handleInputChange = (field, value) => {
    setTierData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const validateForm = () => {
    return (
      tierData.level && 
      tierData.percentage && 
      tierData.referral_tier &&
      Number(tierData.percentage) >= 0 &&
      Number(tierData.percentage) <= 100
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      Alerts.swalErrorAlert("Please fill all fields correctly. Percentage must be between 0 and 100.");
      return;
    }

    let payload = {
      ...tierData,
      business_id: Fetched_Businesses[0]._id,
      status: "Active"
    };

    setLoading(true);
    try {
      const response = await Services.AddMLMTier(authData.sessionID, payload);
      setLoading(false);
      if (response.data.status) {
        onSuccess();
        Alerts.swalSuccessAlert(response.data.message || "Tier Added Successfully.").then(() => {
          toggle();
        });
      } else {
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          setLoginNavigate(true);
        } else {
          Alerts.swalErrorAlert(response.data.message || "Error: Can't Add Tier at the moment. Please try again later.");
        }
      }
    } catch (error) {
      setLoading(false);
      Alerts.swalErrorAlert("Network Operation failed.");
    }
  };

  if (loginNavigate) {
    return <Navigate to={'/login'} />;
  }

  return (
    <span className="d-inline-block float-end mb-2 me-2">
      <ActionButton color="var(--buttons-color, #30b1ff)" onClick={toggle} className="btn-hover-shine ms-1">
        {t("Add New Tier")}
      </ActionButton>
      <StyledModal size='lg' isOpen={modalOpen} toggle={toggle} backdrop={true} ref={modalRef}>
        <LoadingOverlay active={loading} spinner={<DNALoader />}>
          <ModalHeader>
            <span className="d-flex align-items-center gap-2">
              <FontAwesomeIcon icon={faLayerGroup} className="text-primary" />
              {t("Add Tier")}
            </span>
          </ModalHeader>
          <ModalBody>
            <StyledCard>
              <CardBody>
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col md="12" className="mb-3">
                      <FormLabel>
                        <FontAwesomeIcon icon={faLayerGroup} />
                        {t("Level")}
                      </FormLabel>
                      <StyledInput
                        type="text"
                        value={tierData.level}
                        onChange={(e) => handleInputChange('level', e.target.value)}
                        placeholder={t("Enter tier level (e.g., 1, 2, 3)")}
                        required
                      />
                    </Col>
                    <Col md="12" className="mb-3">
                      <FormLabel>
                        <FontAwesomeIcon icon={faPercent} />
                        {t("Percentage")}
                      </FormLabel>
                      <StyledInput
                        type="number"
                        min="0"
                        max="100"
                        step="0.01"
                        value={tierData.percentage}
                        onChange={(e) => handleInputChange('percentage', e.target.value)}
                        placeholder={t("Enter percentage (0-100)")}
                        required
                      />
                    </Col>
                    <Col md="12" className="mb-4">
                      <FormLabel>
                        <FontAwesomeIcon icon={faUsers} />
                        {t("Referral Tier")}
                      </FormLabel>
                      <StyledInput
                        type="text"
                        value={tierData.referral_tier}
                        onChange={(e) => handleInputChange('referral_tier', e.target.value)}
                        required
                      >
                        {/* <option value="">{t("Select Referral Tier")}</option>
                        <option value="Basic">Basic</option>
                        <option value="Silver">Silver</option>
                        <option value="Gold">Gold</option>
                        <option value="Platinum">Platinum</option>
                        <option value="Diamond">Diamond</option> */}
                      </StyledInput>
                    </Col>
                  </Row>
                  <div className="text-end mt-4">
                    <CancelButton 
                      type="button" 
                      color="secondary" 
                      onClick={toggle}
                      className="me-2"
                    >
                      {t("Cancel")}
                    </CancelButton>
                    <ActionButton type="submit"  >
                      {t("Save Tier")}
                    </ActionButton>
                  </div>
                </form>
              </CardBody>
            </StyledCard>
          </ModalBody>
        </LoadingOverlay>
      </StyledModal>
    </span>
  );
};

export default AddMLMTier;