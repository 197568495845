import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import styled from "styled-components";
import CustomerService from "../../../pages/Finance/Invoices/Invoices.service";
import AuthService from "../../../pages/Authentication/auth.service";
import Alerts from "../../Alerts/alerts";
import { Row, Col } from 'react-bootstrap';
import DateRangePickerComponent from './DateRangePickerComponent'; // Adjust the import path as needed
// import { CSVLink } from 'react-csv';
import { Input } from 'reactstrap';

const DateInput = styled.input.attrs(props => ({
  type: "date",
  placeholder: "Select Date",
}))`
  height: 32px;
  width: 100%;
  border-color:#ccc; 
  border-radius: 6px;
`;

const Inputs = styled.select.attrs(props => ({
  type: "select",
  size: props.small ? 5 : undefined
}))`
  height: 32px;
  width: 100%;
  border-radius: 6px;
  border-color:#ccc; 
  background: white;
`;

const StatusInput = styled.input.attrs(props => ({
  type: "text",
  placeholder: "Search Status"
}))`
  height: 32px;
  width: 100%;
  border-radius: 6px;
  
`;

const Label = styled.label`
  display: block;
  font-weight: bold;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  gap: 40px;
`;

const FilterRow = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const CollectionFilter = ({ search_text, onSearch_text, filterText1, filterText2,
  handleKeyDown, onFilter1, onFilter2, onFilterInvoice_StartDate, filterDudue_date,
  onFilterInvoicdue_date, onFilterDue_StartDate, onFilterDudue_date,
  searchCustomers, resetFilters,
  filterDue_StartDate, filterInvoice_StartDate, filterInvoicdue_date }) => {
  const { t, i18n } = useTranslation();
  const [collection, setCollection] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchcollections();
  }, []);
  const [selectedDate, setSelectedDate] = useState(null);

  function fetchcollections() {
    var getData = {
      functionName: "GetInvoiceTo",
      postData: {
        sort: { _id: -1 },
        offset: 0,
        limit: 1000,
        filter: {
          user_type: localStorage.getItem("invoice_to").split(','),
        },
        TableName: 'users',
        TableField: localStorage.getItem("invoice_to_field")
      },
    };
    CustomerService.GetInvoiceTo(getData).then((response) => {
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        if (response.data) {
          setCollection(response.data.data);

        }
      }
    },
      (error) => {
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  console.log(filterText1);

  const handleDateChange = (startDateFormatted, endDateFormatted) => {
    // Call the callback functions to update the parent component's state
    onFilterDue_StartDate(startDateFormatted);
    onFilterDudue_date(endDateFormatted);
  };

  const handleDateChange_Invoice = (startDateFormatted, endDateFormatted) => {
    // Call the callback functions to update the parent component's state
    onFilterInvoice_StartDate(startDateFormatted);
    onFilterInvoicdue_date(endDateFormatted);
  };
  return (
    <>
      <Row style={{ marginTop: '20px', marginBottom: '20px' }}>
        <Col style={{ marginBottom: "20px" }} xs="12" sm="8" md="4" lg="2">
          <Input type="text" name="fname" value={search_text} placeholder={t("Search Text")} onChange={onSearch_text}></Input>
        </Col>

        {/* <Col xs="12" sm="8" md="4" lg="2">
          <Inputs
            type="select"
            placeholder={t("Search Customer")}
            value={filterText1}
            onChange={onFilter1}
            onKeyDown={handleKeyDown}
            id="colfilter"
          >

            <option disabled={false} value="">--{t("Select Customer")}--</option>
            {collection &&
              collection.map((opt_collection, index) => (
                <option key={index} value={opt_collection._id}>{opt_collection[localStorage.getItem('invoice_to_field')]}</option>
              ))
            }
          </Inputs>
        </Col> */}
        <Col xs="12" sm="8" md="4" lg="2">
          <Inputs
            type="select"
            placeholder={t("Search Status")}
            value={filterText2}
            onChange={onFilter2}
          // onKeyDown={handleKeyDown}
          // id="colfilter"
          >

            <option disabled={false} value="">--{t("Select Status")}--</option>
            {/* <option key='generated' value='generated'>{t("Generated")}</option> */}
            <option key='paid' value='paid'>{t("Paid")}</option>
            <option key='partial paid' value='partial paid'>{t("Partial Paid")}</option>
            <option key='unpaid' value='unpaid'>{t("Unpaid")}</option>
            {/* <option key='void' value='void'>{t("Void")}</option> */}
            {/* <option key='cancelled' value='cancelled'>{t("Cancelled")}</option> */}
          </Inputs>
        </Col>
        <Col style={{
          marginLeft: '10px',
          background: '#F9F9FB', height: "35px", border: '1px solid #ccc',
          borderRadius: '6px', marginBottom: '20px', display: 'flex', alignItems: 'center'
        }} xs="12" sm="8" md="6" lg="4">
          <Label style={{ width: '150px', background: '#F9F9FB', paddingRight: '10px' }}>
            {t('Payment Date')}</Label>
          <div className="d-flex justify-content-end align-items-center h-100 pr-2">
            <div style={{ borderRight: '2px solid #ccc', height: '4%' }}></div>
          </div>
          <DateRangePickerComponent onDateChange={handleDateChange_Invoice} />
        </Col>

        <Col style={{}} xs="4" sm="3" md="2" lg="1">
          <button type="button" className="btn btn-custom-color btn-hover-shine ms-1"
            onClick={() => searchCustomers()}
          >Search</button>
        </Col>
        <Col style={{}} xs="4" sm="3" md="2" lg="1">

          <button style={{ marginLeft: '10px' }} type="button"
            className="btn btn-custom-color btn-hover-shine"
            onClick={() => resetFilters()}
          >Reset</button>
        </Col>

      </Row>
    </>

  );
};

export default CollectionFilter;
