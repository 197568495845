import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { Row, Col, Card, CardBody, Form, FormGroup, Label, Input, Button, CustomInput } from "reactstrap";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import BusinessService from "./Businesses.service.js";
import AuthService from "../Authentication/auth.service.js";
import Alerts from "../../components/Alerts/alerts.js";

const GeneralTab = ({ business_id }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const sessionID = localStorage.getItem("session-id");

  const initialData = {
    distance_for_free_delivery: '',
    order_amount_for_free_delivery: '',
  };

  const [data, setData] = useState(initialData);

  useEffect(() => {
    fetchData();
  }, [business_id]);


  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await BusinessService.getGeneralData(sessionID, business_id);
      console.log("response.data", response.data)
      setLoading(false);
      if (response.data.status) {
        if (response.data.data.length > 0) {
          setData(response.data.data[0]);
        } else {
          setData(initialData);
        }
      } else {
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
        } else {
          Alerts.swalErrorAlert(t("Something went wrong, Please try again."));
        }
      }
    } catch (error) {
      setLoading(false);
      Alerts.swalErrorAlert(t("Server Error, Please try again later."));
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setData(prevData => ({ ...prevData, [name]: value }));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let response;
      if (data._id) {
        // Update existing settings
        console.log("data update", data)
        response = await BusinessService.updateGeneralData(sessionID, business_id, data);
      } else {
        // Add new settings
        console.log("data add", data)
        response = await BusinessService.addGeneralData(sessionID, business_id, data);
      }
      setLoading(false);
      if (response.data.status) {
        Alerts.swalSuccessAlert(t("Settings saved successfully"));
        fetchData();
      } else {
        console.log("response.data.message", response.data.message)
        Alerts.swalErrorAlert(response.data.message || t("Save failed"));
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error)
      Alerts.swalErrorAlert(t("Server Error, Please try again later."));
    }
  };

  return (
    <Card>
      <CardBody>
        <h3>{t("General Details")}</h3>
        <LoadingOverlay active={loading} spinner={<DNALoader />}>
          <Form onSubmit={handleSubmit}>

            <Row>
              <Col md="6">
                <FormGroup>
                  <Label for="distance_for_free_delivery">{t('Distance for Free Delivery')}</Label>
                  <Input type="number" name="distance_for_free_delivery" id="distance_for_free_delivery" value={data.distance_for_free_delivery} onChange={handleInputChange} />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="order_amount_for_free_delivery">{t('Order Amount for Free Delivery')}</Label>
                  <Input type="number" name="order_amount_for_free_delivery" id="order_amount_for_free_delivery" value={data.order_amount_for_free_delivery} onChange={handleInputChange} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12" className="d-flex justify-content-center pt-3">
                <Button color="success" type="submit">{data._id ? t('Update') : t('Add')}</Button>
              </Col>

            </Row>
          </Form>
        </LoadingOverlay>
      </CardBody>
    </Card>
  );
};

export default GeneralTab;