import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom';
import { Row, Col, Card, CardBody } from "reactstrap";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Tooltip from '@mui/material/Tooltip';

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import "../../../assets/custom css/loadoverlay.scss";

import Alerts from "../../../components/Alerts/alerts";

import DynModuleService from "../../../pages/Dynamic Modules/dynamicmodules.service.js";
import AuthService from "../../Authentication/auth.service";
import fileDownload from 'js-file-download'
import CollectionFilter from "../../../components/Table Filters/LogsFilters/CollectionFilter";
import ModuleFilter from "../../../components/Table Filters/LogsFilters/ModuleFilter";
import FunctionFilter from "../../../components/Table Filters/LogsFilters/FunctionFilter";
import DateFilter from "../../../components/Table Filters/LogsFilters/DateFilter";
import PermissionAccess from '../../../PermissionAccess/Access.js';
// import CommonService from "../common.service.js";

import DataTable from 'react-data-table-component';
import $ from 'jquery';
import moment from "moment";
const API_URL = process.env.REACT_APP_BASEURL;
const API2_URL = process.env.REACT_APP_LOGINURL;
const storeduser = JSON.parse(localStorage.getItem("user"));

const DataTableView = () => {


  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { t, i18n } = useTranslation();
  const { authData, accessPages, super_admin, current_module } = PermissionAccess();

  var pathname = '6604f8f16eeb27e523a8d0e1';
  var pathData = {}
  console.log("pathname: " + pathname)

  pathData.Parent_referal_name = "public_holidays";
  pathData.redirect_param = "6604f8f16eeb27e523a8d0e1";
  pathData.Parentid = "6604f8f16eeb27e523a8d0e1";
  pathData.id = pathname;
  if (pathData.id) {
    pathData.redirect_param = pathname;
    var text = pathname;
    if (text.indexOf("-") > -1) {
      pathData.id = text.split('-')[0];
      pathData.Parentid = '65f8e8315215c10ace5df7bb';
      // pathData.Parentid = text.split('-')[1];
      pathData.Parent_referal_name = text.split('-')[2];
      console.log("text.split('-')[2]: " + text.split('-')[2])
    }
  }

  const [collectionlist, setcollectionlist] = useState([]);
  const [pre_params, setpre_params] = useState(pathData)
  const [childlist, setchildlist] = useState([]);
  const [fieldlist, setfieldlist] = useState([]);
  const [datalist, setdatalist] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalPages, settotalPages] = useState(0);
  const [currentPage, setcurrentPage] = useState(0);
  const [basefilter, setbasefilter] = useState({});

  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [total_records, settotal_records] = useState(0);
  const [imported_records, setimported_records] = useState(0);
  const [not_imported, setnot_imported] = useState(0);
  const [duplicates, setduplicates] = useState([]);
  const [totalduplicates, settotalduplicates] = useState(0);
  const [parentCRUD, setParentCRUD] = useState(0);


  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchChildlinksData();
    fetchDynamicData();
  }, []);

  const getParentData = async (crud_id) => {
    //get parent data!
    const response = await DynModuleService.get_cruds_dynamic_data(authData.sessionID,crud_id);
    if (response.data.message === "Unauthorized") {
      AuthService.logout();
      navigate("/login");
      window.location.reload();
    }
    else {
      const data2 = response.data.data;
      console.log("data2");
      console.log(data2);
      const collection_name = data2[0].referal_name
      var filter = { _id: pre_params.Parentid }
      const response2 = await DynModuleService.getcollection(authData.sessionID,collection_name, filter, {}, pageLimit, currentPage);
      const responsedata2 = await response2.data;
      console.log(responsedata2);
      if (responsedata2.data.length > 0) {
        setParentCRUD(responsedata2.data[0])
      }
    }

  }
  const fetchDynamicData = async () => {
    try {

      const response = await DynModuleService.get_cruds_dynamic_data(authData.sessionID,pre_params.id);
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        const data = await response.data.data;
        console.log(data[0]);
        setcollectionlist(data[0]);
        setfieldlist(data[0].custom_fields);


        var filter = basefilter;
        console.log("pre_params : ", pre_params);
        if (pre_params.Parentid !== "") {

          var custom_fields = data[0].custom_fields;
          custom_fields.forEach((element) => {
            if (element.parent_col === pre_params.Parent_referal_name) {
              filter[element.referal_name] = pre_params.Parentid
              setbasefilter(filter)
            }

          });
          getParentData(data[0].crud_id);
        }



        const response1 = await DynModuleService.getcollection(authData.sessionID,data[0].referal_name, filter, data[0].custom_fields, pageLimit, currentPage);
        const responsedata = await response1.data;
        console.log("responsedata", responsedata);
        setdatalist(responsedata.data);
        setTotalRows(responsedata.count.count);
        settotalPages(responsedata.count.total_pages)
        if (responsedata.count.total_pages)
          $('#paging_stats').html('Page 1 of ' + responsedata.count.total_pages);
        else
          $('#paging_stats').html('No Records');
        var endingat = pageLimit; if (endingat > totalRows) { endingat = responsedata.count.count }
        $('#record_stats').html('Showing 0 - ' + endingat + ' of ' + responsedata.count.count + ' records');
        $('.select_all_records').prop("checked", false);
        $('.check_records').prop("checked", false);
      }

    } catch (error) {
      console.log("error", error);
    }

  };

  const reset12 = () => {


    var fieldsarr = fieldlist
    if (fieldsarr[0]) {
      var fields = basefilter;
      var or_arr = [];

      for (var i in fieldsarr) {
        if (parseInt(fieldsarr[i].is_filterable)) {

          $("#" + fieldsarr[i].referal_name).val('');

        }
      }

      if (or_arr.length > 0) {
        fields['$or'] = or_arr;
      } else {
        delete fields['$or'];
      }

      fetchDynamicData();

    }

  }

  const search_products = async () => {

    // if (e.key === 'Enter' || e.keyCode === 13) {
    var fieldsarr = fieldlist;
    if (fieldsarr[0]) {
      var fields = basefilter;
      var or_arr = [];

      for (var i in fieldsarr) {
        if (parseInt(fieldsarr[i].is_filterable)) {

          var s_fieldval = $("#" + fieldsarr[i].referal_name).val();

          if (s_fieldval !== "") {

            var filter_entry = {};
            filter_entry[fieldsarr[i].referal_name] = s_fieldval;
            or_arr.push(filter_entry);

          }

        }
      }

      if (or_arr.length > 0) {
        fields['$or'] = or_arr;
      } else {
        delete fields['$or'];
      }
      // dyn_col_public_holidays
      console.log("fields", fields);
      console.log("fieldsarr", fieldsarr);
      console.log("currentPage", currentPage);
      console.log("pageLimit", pageLimit);

      const response1 = await DynModuleService.getcollection(authData.sessionID,'public_holidays', fields, fieldsarr, pageLimit, currentPage);
      const responsedata = await response1.data;
      console.log("responsedata", responsedata);
      setdatalist(responsedata.data);
      setTotalRows(responsedata.count.count);
      settotalPages(responsedata.count.total_pages)
      if (responsedata.count.total_pages)
        $('#paging_stats').html('Page 1 of ' + responsedata.count.total_pages);
      else
        $('#paging_stats').html('No Records');
      var endingat = pageLimit; if (endingat > totalRows) { endingat = responsedata.count.count }
      $('#record_stats').html('Showing 0 - ' + endingat + ' of ' + responsedata.count.count + ' records');
      $('.select_all_records').prop("checked", false);
      $('.check_records').prop("checked", false);

      setbasefilter(fields);
      // fetchDynamicData();

    }
    // }
  }


  const get_collection_data = async (page_index) => {
    const response1 = await DynModuleService.getcollection(authData.sessionID,collectionlist.referal_name, basefilter, collectionlist.custom_fields, pageLimit, page_index);
    if (response1.data.message === "Unauthorized") {
      AuthService.logout();
      navigate("/login");
      window.location.reload();
    }
    else {
      const responsedata = await response1.data;
      setdatalist(responsedata.data);
      $('.select_all_records').prop("checked", false);
      $('.check_records').prop("checked", false);
    }

  }
  function nextPage() {
    var page = currentPage;
    page = page + 1;
    if (page > totalPages - 1) {

    } else {
      setcurrentPage(page)
      var offset = page * pageLimit;
      get_collection_data(offset)
      pagination_text(offset, page + 1)
    }

  }

  function prevPage() {
    var page = currentPage;
    page = page - 1;
    if (page < 0) {

    } else {
      setcurrentPage(page)
      var offset = page * pageLimit;
      get_collection_data(offset)
      pagination_text(offset, page + 1)
    }

  }
  function firstPage() {
    var page = 0;
    setcurrentPage(page)
    var offset = page * pageLimit;
    get_collection_data(offset)
    pagination_text(offset, page + 1)
  }
  function lastPage() {
    var page = totalPages - 1;
    setcurrentPage(page)
    var offset = page * pageLimit;
    get_collection_data(offset)
    pagination_text(offset, page + 1)
  }
  function pagination_text(offset, page) {

    $('#paging_stats').html('Page ' + page + ' of ' + totalPages);
    var startingfrom = offset;
    var endingat = offset + pageLimit; if (endingat > totalRows) { endingat = totalRows }
    $('#record_stats').html('Showing ' + startingfrom + ' - ' + endingat + ' of ' + totalRows + ' records');


  }
  function handlePageChange(page_index) {
    get_collection_data(page_index);
  }

  const fetchChildlinksData = async () => {
    try {
      const response = await DynModuleService.getAllCruds(authData.sessionID,{ "crud_id": pre_params.id });
      const data = await response.data.data;
      setchildlist(data);
    } catch (error) {
      console.log("error", error);
    }
  };
  const TBcolumns = [];


  const handleDownload = (filename) => {

    var columnslist = [];
    var valuelist = [];
    if (fieldlist.length > 0) {

      fieldlist.map((field, index) => {

        if (field.referal_name === 'date') {
          console.log('field.referal_name', field.referal_name);
          columnslist.push(field.referal_name);
          valuelist.push(moment(field.field_label, 'DD/MM/YYYY').startOf('day'));
        } else {
          console.log('field.referal_name else', field.referal_name);
          columnslist.push(field.referal_name);
          valuelist.push(field.field_lable);
        }
      })
      var csvData = columnslist.join(',') + "\r\n" + valuelist.join(',');
    } else {

      var csvData = "";
    }

    fileDownload(csvData, filename)

  }
  async function handlePerRowsChange(newPageLimit, page_index) {
    setPageLimit(newPageLimit);
  }
  function confirmDelete(_id, _referal_name) {
    let msg = t("Once deleted, you will not be able to recover this!")
    Alerts.swalConfirmDeleteAlert(msg).then(res => {
      console.log("Resp:-", res)
      if (res === true) {
        sendDelete(_id, _referal_name)
      }
    })
  }

  function uploadFileButton() {

    //var form = $('form')[0];
    var formData = new FormData();
    formData.append('file', $('#fileUpload').prop('files')[0]);
    formData.append("login_user_id", storeduser._id)
    formData.append("id", pre_params.id)
    formData.append("Parentid", pre_params.Parentid)
    formData.append("functionName", "ImportDynCollectionData")
    const headers = {
      'token': authData.sessionID
    }
    $.ajax({
      url: API2_URL + '/import_csv/',
      data: formData,
      type: 'POST',
      headers: headers,
      contentType: false, // NEEDED, DON'T OMIT THIS (requires jQuery 1.6+)
      processData: false, // NEEDED, DON'T OMIT THIS
      success: function (response) {


        toggleModal();
        var dups = response.duplicates
        if (dups) {
          response.dup_records = dups.length;
          settotal_records(response.total_records);
          setimported_records(response.imported_records);
          setnot_imported(response.not_imported);
          setduplicates(response.dups);
          settotalduplicates(response.dup_records);
          toggleModal2();
          Alerts.swalSuccessAlert('')
        } else {
          settotal_records(0);
          setimported_records(0);
          setnot_imported(0);
          setduplicates([]);
          settotalduplicates(0);

          Alerts.swalErrorAlert('Unable to process CSV!')
        }


        fetchDynamicData();

      }
    });
  }

  function sendDelete(id, referal_name) {
    setLoading(true);
    DynModuleService.deleteCollection(authData.sessionID,id, referal_name).then((response) => {
      setLoading(false);
      console.log("Delete Collection Data response:-", response)
      fetchDynamicData();
      if (response.data.status) {
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Collection Data Deleted Successfully."))
        }
        console.log(response.data.message)
      }
      else {
        if (response.data.message !== '') {
          Alerts.swalErrorAlert(response.data.message)
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't delete the Collection Data at the moment."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Delete operation failed"))
      })
  };

  const toggleModal = () => {
    setModal(!modal);
  };
  const toggleModal2 = () => {
    setModal2(!modal2);
  };
  const select_all_records = () => {

    if ($('.select_all_records').is(":checked")) {
      $('.check_records').prop("checked", true);
    } else {
      $('.check_records').prop("checked", false);
    }


  }

  const check_record = (e) => {
    var element = e.target;
    if ($(element).is(":checked")) {

      var checkall = 1;
      $('.check_records').map(function () {

        if ($(this).is(":checked")) {
        } else {
          checkall = 0;

        }

      });
      if (checkall) {
        $('.select_all_records').prop("checked", true);
      }
    } else {
      $('.select_all_records').prop("checked", false);
    }
  }

  function delete_selected() {

    var delete_ids = [];
    $('.check_records').map(function () {

      if ($(this).is(":checked")) {
        delete_ids.push($(this).val());
      }

    });
    if (delete_ids.length > 0) {
      process_bulkDelete(collectionlist.referal_name, delete_ids)
    }
  }
  console.log(collectionlist);
  function process_bulkDelete(referal_name, delete_ids) {


    if (delete_ids.length > 0) {
      let msg = t(delete_ids.length + " records selected. Once deleted, you will not be able to recover this!")
      Alerts.swalConfirmDeleteAlert(msg).then(res => {

        if (res === true) {
          setLoading(true);
          DynModuleService.bulkdeleteCollection(authData.sessionID,delete_ids, referal_name).then((response) => {
            setLoading(false);

            fetchDynamicData();
            if (response.data.status) {
              if (response.data.message !== '') {
                Alerts.swalSuccessAlert(response.data.message)
              }
              else {
                Alerts.swalSuccessAlert(t("Collection Data Deleted Successfully."))
              }

            }
            else {
              if (response.data.message !== '') {
                Alerts.swalErrorAlert(response.data.message)
              }
              else {
                Alerts.swalErrorAlert(t("Error: Can't delete the Collection Data at the moment."))
              }
            }
          },
            (error) => {
              setLoading(false);
              const errContent =
                (error.response && error.response.data) || error.message || error.toString();
              Alerts.swalErrorAlert(t("Delete operation failed"))
            })
        }
      })
    }

  }

  // const changeLimit= (e) =>{
  //   setPageLimit(parseInt( e.target.value));
  //   fetchDynamicData();
  // }
  return (
    <Fragment>
      <TransitionGroup>
        <CSSTransition component="div" classNames="TabsAnimation" appear={true}
          timeout={1500} enter={false} exit={false}>
          <div>
            <div>
              <span className="d-inline-block mb-2 me-2">
                <Modal isOpen={modal} toggle={toggleModal} backdrop={true}>
                  <ModalHeader >{t('Import Records')}</ModalHeader>
                  <ModalBody>
                    <Card className="main-card mb-3">
                      <CardBody>
                        <form id="importform">
                          <input
                            type='file' name="importfile" label={t('Upload')} accept='.csv'
                            onChange={uploadFileButton}
                            id="fileUpload"
                          />

                          <br />
                          <p style={{ border: "3px solid red", padding: "5px", marginTop: "10px" }}>{t("* Please do not remove or change the first row in the sample file.")}</p>
                        </form>

                      </CardBody>
                    </Card>
                  </ModalBody>
                  <ModalFooter>
                    <Button className="btn btn-success btn-hover-shine me-3 btn" onClick={() => {
                      handleDownload('sample.csv')
                    }}>{t('Download Sample')}</Button>
                    <Button color="link" onClick={toggleModal} className="btn btn-danger btn-hover-shine me-3 btn">
                      {t('Cancel')}
                    </Button>
                  </ModalFooter>
                </Modal>


                <Modal isOpen={modal2} toggle={toggleModal2} backdrop={true}>
                  <ModalHeader ><h4 style={{ color: "green" }} >{t('Import Records Stats')}</h4></ModalHeader>
                  <ModalBody>
                    <Card className="main-card mb-3">
                      <CardBody>
                        <h4>{t("Total Records")} : <span> {total_records}</span></h4>
                        <h4 style={{ color: "green" }} >{t("Records Imported")} : <span > {imported_records}</span></h4>
                        <h4 style={{ color: "red" }}>{t("Records Not Imported")} : <span  > {not_imported}</span></h4>
                        {/* <h4 style={{ color: "orange" }} >{t("Serials Already Found")} : <span > {totalduplicates}</span></h4> */}
                        {/* {duplicates && duplicates.map((dup, index) => {
                            return (
                              <><span>{dup}</span></>
                            )
                          })} */}



                      </CardBody>
                    </Card>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="link" onClick={toggleModal2} className="btn btn-danger btn-hover-shine me-3 btn">
                      {t('Close')}
                    </Button>
                  </ModalFooter>
                </Modal>
              </span>
            </div>
            <Row>
              <Col md="12">
                <Card className="main-card mb-3">
                  <CardBody>
                    <LoadingOverlay tag="div" active={loading}
                      styles={{ overlay: (base) => ({ ...base }) }}
                      spinner={<DNALoader />}>
                      <Col md="3" className="text-end mt-2" style={{ float: "right" }}>
                        <a href={"/adddata/" + pre_params.redirect_param} className="AddData">
                          <button type="button" className="btn btn-success btn-hover-shine me-3 btn">{t('Add New')}</button>
                        </a>


                        <button type="button" className="btn btn-danger btn-hover-shine me-3 btn ImportData" onClick={toggleModal}>{t('Import CSV')}</button>

                      </Col>
                      {/* <Col md="3"  style={{float:"right"}}>
                              <input
                                  type='text' className="form-control" name="searchfield" placeholder={t('Search Records ...')}
                                  id="searchfield" onKeyUp={search_products}
                                />
                            </Col> */}

                      <br /><br />
                      <br />

                      <div className="row">
                        {fieldlist && fieldlist.map((field, index) => {
                          if (field.referal_name === 'date' && parseInt(field.is_filterable)) {
                            // Generate an array of years including the current year, five years prior, and five years next
                            const currentYear = new Date().getFullYear();
                            const years = [];
                            for (let i = -5; i <= 5; i++) {
                              years.push(currentYear + i);
                            }

                            return (
                              <>
                                <Col md="3" style={{ float: "left" }}>
                                  <div className="col-md-11 col-xl-11">
                                    <div className="form-group">
                                      {/* Use a select element for the dropdown */}
                                      <select className="form-control searchfield" name={field.referal_name} id={field.referal_name}>
                                        {/* Map over the years array to generate options */}
                                        <option  key={'1'} value={''}>{"Select Year"}</option>
                                        {years.map((year, index) => (
                                          <option key={index} value={year}>{year}</option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                </Col>
                              </>
                            )
                          }
                          if ((field.field_type === 'text' || field.field_type === 'email' || field.field_type === 'number' || field.field_type === 'password') && parseInt(field.is_filterable)) {
                            return (
                              <>
                                <Col md="3" style={{ float: "left" }}>
                                  <div className="col-md-11 col-xl-11" >
                                    <div className="form-group" >

                                      <input type={field.field_type} className="form-control searchfield" placeholder={t(field.field_lable)} name={field.referal_name} id={field.referal_name} />
                                    </div>
                                  </div>
                                </Col>

                              </>
                            )
                          }

                        })}
                        <Col md="1">
                          <button type="button" className="btn btn-custom-color btn-hover-shine ms-1" onClick={() => search_products()}>{t("Search")}</button>
                        </Col>
                        <Col md="2">
                          <button type="button" className="btn btn-custom-color btn-hover-shine ms-2" onClick={() => reset12()}>{t("Reset")}</button>
                        </Col>
                      </div>
                      <br /><br />
                      <table className="table table-bordered table-striped table-hover mb-0 square border">
                        <thead fontWeight={'700'} style={{ height: '50px', color: '#545CD8' }} className="thead-light">
                          <tr>
                            <th>
                              <input
                                className={"form-check-input select_all_records"}
                                type="checkbox"
                                onChange={select_all_records}
                              />
                            </th>
                            {fieldlist && fieldlist.map((field, index) => {
                              if (field.datatable)
                                return (
                                  <th key={"th" + index}>{t(field.field_lable)}</th>
                                )
                            })}
                            <th>{t('Actions')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {datalist && datalist.map((dataval, index) => (
                            <tr key={"tr" + index}>
                              <td key={"check" + index} >
                                <input
                                  className={"form-check-input check_records"}
                                  type="checkbox"
                                  value={dataval['_id']}
                                  onChange={check_record}
                                />
                              </td>

                              {fieldlist && fieldlist.map((field, index1) => {
                                if (field.datatable) {
                                  if (field.referal_name === 'date') {
                                    // Convert moment object to string before rendering
                                    const formattedDate = moment(dataval[field.referal_name], 'YYYY-MM-DD').startOf('day').format('DD-MM-YYYY');
                                    return (
                                      <td key={index1 + "td" + index}>{formattedDate}</td>
                                    )
                                  } else {
                                    return (
                                      <td key={index1 + "td" + index} >{dataval[field.referal_name]}</td>
                                    )
                                  }
                                } else if (!field.datatable) {
                                  console.log(field)
                                }
                              })}

                              <td key={"ltd" + index}>
                                {childlist && childlist.map((link, index2) => (
                                  <Tooltip placement="top" title={t("Attributes")} arrow>
                                    <a href={"/viewdata/" + link._id + "-" + dataval._id + "-" + collectionlist.referal_name} style={{ cursor: "pointer" }}>

                                      <i className="pe-7s-news-paper btn-icon-wrapper icon-gradient bg-arielle-smile btn-lg px-1"> </i>

                                    </a>
                                  </Tooltip>

                                ))}
                                <Tooltip placement="top" title={t("Edit")} arrow>
                                  <Link to={"/editdata/" + pre_params.redirect_param} state={dataval} className="EditData" >
                                    <i className="lnr-pencil btn-icon-wrapper icon-gradient bg-arielle-smile btn-lg px-1"> </i>

                                  </Link>
                                </Tooltip >

                                <Tooltip placement="top" title={t("Delete")} arrow>
                                  <a onClick={() => confirmDelete(dataval._id, collectionlist.referal_name)} style={{ cursor: "pointer" }} className="DeleteData">

                                    <i className="lnr-trash btn-icon-wrappe icon-gradient bg-ripe-malin btn-lg px-0"> </i>

                                  </a>
                                </Tooltip >
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <br />
                      <div>
                        <span className="" >

                          <button type="button" onClick={delete_selected} className="btn btn-outline-danger btn-hover-shine me-3 mb-3 delete_selected_btn">
                            {t('Delete Selected')}
                          </button>
                        </span>
                        <nav className="sc-jOiSOi ksCHta rdt_Pagination">

                          <span className="sc-cOxWqc sc-khsqcC kSZUzF eBqHmd" id="record_stats"> </span>
                          {/* <span className="sc-cOxWqc sc-khsqcC kSZUzF eBqHmd" id="record_stats" style = {{paddingLeft : 20 }}>
                                  <select onChange={changeLimit} name="pagelimitchange" value={pageLimit} id="pagelimitchange" >
                                    <option value="10">10 records per page</option>
                                    <option value="20">20 records per page</option>
                                    <option value="50">50 records per page</option>
                                    <option value="100">100 records per page</option>
                                  </select>
                                </span>          */}
                          <span className="sc-bCfvAP gEnHjZ" style={{ float: "right" }}>
                            <div>
                              <span id="paging_stats" ></span>
                              <button onClick={firstPage} style={{ border: "none", background: "white", fill: "#8d8a8a" }} id="pagination-first-page" type="button" aria-label="First Page" aria-disabled="true" disabled="" className="sc-dPWrhe fXiHqP">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                  <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
                                  <path fill="none" d="M24 24H0V0h24v24z"></path>
                                </svg>
                              </button>
                              <button onClick={prevPage} style={{ border: "none", background: "white", fill: "#8d8a8a" }} id="pagination-previous-page" type="button" aria-label="Previous Page" aria-disabled="true" disabled="" className="sc-dPWrhe fXiHqP">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                  <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                                  <path d="M0 0h24v24H0z" fill="none"></path>
                                </svg>
                              </button>
                              <button onClick={nextPage} style={{ border: "none", background: "white", fill: "#8d8a8a" }} id="pagination-next-page" type="button" aria-label="Next Page" aria-disabled="true" disabled="" className="sc-dPWrhe fXiHqP">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                  <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                                  <path d="M0 0h24v24H0z" fill="none"></path>
                                </svg>
                              </button>
                              <button onClick={lastPage} style={{ border: "none", background: "white", fill: "#8d8a8a" }} id="pagination-last-page" type="button" aria-label="Last Page" aria-disabled="true" disabled="" className="sc-dPWrhe fXiHqP">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                  <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
                                  <path fill="none" d="M0 0h24v24H0V0z"></path>
                                </svg>
                              </button>
                            </div>
                          </span>

                        </nav>
                      </div>
                    </LoadingOverlay>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </Fragment>
  );




}

export default DataTableView;

// import React, { useEffect, useState, Fragment } from "react";
// import { useTranslation } from 'react-i18next';
// import { CSSTransition, TransitionGroup } from 'react-transition-group';
// import { format, parseISO, set } from 'date-fns';
// import { useNavigate, useLocation, useParams, Link } from 'react-router-dom';
// import Tooltip from '@mui/material/Tooltip';
// import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
// import $ from 'jquery';
// import fileDownload from 'js-file-download'
// import CSVReader from 'react-csv-reader';

// import moment from 'moment';
// import {
//   Row,
//   Col,
//   Input,
//   Card,
//   CardBody
// } from "reactstrap";

// import LoadingOverlay from "react-loading-overlay-ts";
// import DNALoader from "../../../components/Loaders/DnaLoader";
// import "../../../assets/custom css/loadoverlay.scss";
// import Alerts from "../../../components/Alerts/alerts";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { library } from "@fortawesome/fontawesome-svg-core";
// import { faLockOpen, faLock, faKey } from "@fortawesome/free-solid-svg-icons";

// import CustomerService from "./Customer.service";
// import AuthService from "../../Authentication/auth.service";
// import DataTable from 'react-data-table-component';

// library.add(
//   faLockOpen,
//   faLock,
//   faKey
// );


// const API_URL = process.env.REACT_APP_BASEURL;
// const API2_URL = process.env.REACT_APP_LOGINURL;
// const storeduser = JSON.parse(localStorage.getItem("user"));

// const CustomersList = () => {

//   const { t, i18n } = useTranslation();
//   const navigate = useNavigate();
//   const [loading, setLoading] = useState(false);
//   const [loading2, setLoading2] = useState(false);
//   const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
//   const [customers, setCustomers] = useState([]);
//   const [totalRows, setTotalRows] = useState(0);
//   const [pageLimit, setPageLimit] = useState(10);
//   const [currentPage, setCurrentPage] = useState("");
//   const [searchName, setsearchName] = useState('');
//   const [searchRegNo, setsearchRegNo] = useState('');
//   const [searchEmail, setsearchEmail] = useState('');
//   const [modal, setModal] = useState(false);
//   const [fieldlist, setfieldlist] = useState([]);
//   const [modal2, setModal2] = useState(false);
//   const [total_records, settotal_records] = useState(0);
//   const [imported_records, setimported_records] = useState(0);
//   const [not_imported, setnot_imported] = useState(0);
//   const [duplicates, setduplicates] = useState([]);
//   const [totalduplicates, settotalduplicates] = useState(0);
//   const [parentCRUD, setParentCRUD] = useState(0);
//   const [TotalRecords, setTotalRecords] = useState(0);
//   const [ImportedRecords, setImportedRecords] = useState(0);
//   const [SkippedRecords, setSkippedRecords] = useState(0);

//   useEffect(() => {
//     i18n.changeLanguage(localStorage.getItem('language'));
//     fetchCustomers(0, pageLimit);
//   }, [pageLimit]);


//   function fetchCustomers(page_index, page_limit) {
//     var filter = {};
//     if (searchName !== "") {
//       console.log("Search name:-", searchName)
//       filter["search_Text"] = searchName
//     }

//     var offset = (page_index - 1) * page_limit;
//     if (offset < 1) {
//       offset = 0;
//     }
//     var getData = {
//       functionName: "GetCustomers",
//       postData: {
//         "sort": { _id: -1 },
//         "offset": offset,
//         "limit": page_limit,
//         "filter": filter
//       },
//     }
//     setLoading(true);
//     CustomerService.getAllCustomers(getData).then((response) => {
//       setLoading(false);
//       console.log("Get Customers response:-", response)
//       if (response.data.message === "Unauthorized") {
//         AuthService.logout();
//         navigate("/login");
//         window.location.reload();
//       }
//       else {
//         setCustomers(response.data.data);
//         setTotalRows(response.data.count.count);
//       }
//     },
//       (error) => {
//         setLoading(false);
//         const errContent =
//           (error.response && error.response.data) || error.message || error.toString();
//         console.log("Net:-", errContent)
//         if (errContent) {
//           Alerts.swalErrorAlert(errContent)
//         }
//         else {
//           Alerts.swalErrorAlert(t("Server Error, Please try again later."))
//         }
//       })
//   };



//   const handleDownload = (filename) => {

//     var columnslist = [];
//     var valuelist = [];
//     columnslist.push('name');
//     columnslist.push('email');
//     columnslist.push('phone_number');

//     valuelist.push("Ihtesham");
//     valuelist.push("ihtesham112@gmail.com");
//     valuelist.push("03425505565");

//     var csvData = columnslist.join(',') + "\r\n" + valuelist.join(',');
//     fileDownload(csvData, filename)

//   }

//   function handlePageChange(page_index) {
//     console.log("Page index:-", page_index)
//     setCurrentPage(page_index)
//     fetchCustomers(page_index, pageLimit);
//   }

//   async function handlePerRowsChange(newPageLimit, page_index) {
//     setPageLimit(newPageLimit);
//   }

//   function nameFilter(e) {
//     console.log(e.target.value);
//     setsearchName(e.target.value)
//   }
//   function regFilter(e) {
//     console.log(e.target.value);
//     setsearchRegNo(e.target.value)
//   }
//   function emailFilter(e) {
//     console.log(e.target.value);
//     setsearchEmail(e.target.value)
//   }


//   function resetFilters() {
//     setResetPaginationToggle(!resetPaginationToggle);
//     setsearchName('');
//     setsearchRegNo('');
//     setsearchEmail('');
//     var offset = 0;
//     var getData = {
//       functionName: "GetCustomers",
//       postData: {
//         "sort": { _id: -1 },
//         "offset": offset,
//         "limit": pageLimit,
//         "filter": {}
//       },
//     }
//     setLoading(true);
//     CustomerService.getAllCustomers(getData).then((response) => {
//       setLoading(false);
//       console.log("Get Customers response:-", response)
//       if (response.data.message === "Unauthorized") {
//         AuthService.logout();
//         navigate("/login");
//         window.location.reload();
//       }
//       else {
//         setCustomers(response.data.data);
//         setTotalRows(response.data.count.count);
//       }
//     },
//       (error) => {
//         setLoading(false);
//         const errContent =
//           (error.response && error.response.data) || error.message || error.toString();
//         console.log("Net:-", errContent)
//         if (errContent) {
//           Alerts.swalErrorAlert(errContent)
//         }
//         else {
//           Alerts.swalErrorAlert(t("Server Error, Please try again later."))
//         }
//       })
//   }

//   function searchCustomers() {
//     setResetPaginationToggle(!resetPaginationToggle);
//     var page = 0
//     handlePageChange(page++)
//   }

  // function uploadFileButton() {
  //   setLoading2(true);
  //   var formData = new FormData();
  //   formData.append('file', $('#fileUpload').prop('files')[0]);
  //   formData.append("login_user_id", storeduser._id)
  //   formData.append("functionName", "BulkImportedMembers")

  //   formData.append("currency", localStorage.getItem('currency'))
  //   formData.append("paymentMethod", localStorage.getItem('payment_method'))
  //   formData.append("comments", localStorage.getItem('default_notes'))
  //   formData.append("due_date", parseInt(localStorage.getItem('due_date')))

  //   const headers = {
  //     'token': localStorage.getItem("session-id")
  //   }
  //   $.ajax({
  //     url: API2_URL + 'import_csv/',
  //     data: formData,
  //     type: 'POST',
  //     headers: headers,
  //     contentType: false, // NEEDED, DON'T OMIT THIS (requires jQuery 1.6+)
  //     processData: false, // NEEDED, DON'T OMIT THIS
  //     success: function (response) {
  //       setLoading2(false);
  //       console.log(response);
  //       toggleModal();
  //       toggleModal2();
  //       if (response.status) {
  //         setTotalRecords(response.data.total_rows);
  //         setImportedRecords(response.data.imported_records);
  //         setSkippedRecords(response.data.skipped_records);
  //         fetchCustomers(0, pageLimit);
  //         Alerts.swalSuccessAlert(response.message)
  //       }
  //       else {
  //         if (response.message !== '') {
  //           if (response.message === "Unauthorized") {
  //             AuthService.logout();
  //             navigate("/login");
  //             window.location.reload();
  //           }
  //           else {
  //             Alerts.swalErrorAlert(response.message)
  //           }
  //         }
  //         else {
  //           Alerts.swalErrorAlert(t("Error: Can't add attribute at the moment. Please try again later."))
  //         }
  //       }

  //     }
  //   });
  // }
//   function confirmDeleteCustomer(_id) {
//     let msg = t("Once deleted, you will not be able to recover this Member!")
//     Alerts.swalConfirmDeleteAlert(msg).then(res => {
//       console.log("Resp:-", res)
//       if (res === true) {
//         sendDelete(_id)
//       }
//     })
//   };

//   function sendDelete(id) {
//     setLoading(true);
//     CustomerService.deleteCustomer(id).then((response) => {
//       setLoading(false);
//       console.log("Delete customer response:-", response)
//       if (response.data.status) {
//         fetchCustomers(currentPage, pageLimit)
//         if (response.data.message !== '') {
//           Alerts.swalSuccessAlert(response.data.message)
//         }
//         else {
//           Alerts.swalSuccessAlert(t("Member Deleted Successfully."))
//         }
//         console.log(response.data.message)
//       }
//       else {
//         if (response.data.message !== '') {
//           if (response.data.message === "Unauthorized") {
//             AuthService.logout();
//             navigate("/login");
//             window.location.reload();
//           }
//           else {
//             Alerts.swalErrorAlert(response.data.message);
//           }
//         }
//         else {
//           Alerts.swalErrorAlert(t("Error: Can't delete the member at the moment."))
//         }
//       }
//     },
//       (error) => {
//         setLoading(false);
//         const errContent =
//           (error.response && error.response.data) || error.message || error.toString();
//         Alerts.swalErrorAlert(t("Delete operation failed"))
//       })
//   };

//   const toggleModal = () => {
//     setModal(!modal);
//   };

//   const toggleModal2 = () => {
//     setModal2(!modal2);
//   };
//   const select_all_records = () => {

//     if ($('.select_all_records').is(":checked")) {
//       $('.check_records').prop("checked", true);
//     } else {
//       $('.check_records').prop("checked", false);
//     }


//   }

//   const check_record = (e) => {
//     var element = e.target;
//     if ($(element).is(":checked")) {

//       var checkall = 1;
//       $('.check_records').map(function () {

//         if ($(this).is(":checked")) {
//         } else {
//           checkall = 0;

//         }

//       });
//       if (checkall) {
//         $('.select_all_records').prop("checked", true);
//       }
//     } else {
//       $('.select_all_records').prop("checked", false);
//     }
//   }

//   function regeneratePassword(_id) {
//     let msg = t("Are you sure you want to regenerate password for this Member?")
//     Alerts.swalConfirmGeneralAlert(msg).then(res => {
//       console.log("Resp:-", res)
//       if (res === true) {
//         sendPassword(_id)
//       }
//     })
//   };

//   function sendPassword(id) {
//     setLoading(true);
//     CustomerService.sendCustomerPassword(id).then((response) => {
//       console.log("Send Password response:-", response)
//       setLoading(false);
//       fetchCustomers(currentPage, pageLimit)
//       if (response.data.status) {
//         if (response.data.message !== '') {
//           Alerts.swalSuccessAlert(response.data.message)
//         }
//         else {
//           Alerts.swalSuccessAlert(t("Password emailed Successfully."))
//         }
//         console.log(response.data.message)
//       }
//       else {
//         if (response.data.message !== '') {
//           if (response.data.message === "Unauthorized") {
//             AuthService.logout();
//             navigate("/login");
//             window.location.reload();
//           }
//           else {
//             Alerts.swalErrorAlert(response.data.message);
//           }
//         }
//         else {
//           Alerts.swalErrorAlert(t("Error: Can't send the Password."))
//         }
//       }
//     },
//       (error) => {
//         setLoading(false);
//         const errContent =
//           (error.response && error.response.data) || error.message || error.toString();
//         Alerts.swalErrorAlert(t("Operation failed"))
//       })
//   };

//   const mycolumns = [
//     {
//       name: "Name",
//       selector: row => row?.name
//     },
//     {
//       name: "Email",
//       selector: row => row?.email
//     },
//     {
//       name: "Phone Number",
//       selector: row => row?.phone_number
//     },
//     {
//       name: t('Status'),
//       selector: (row) => {
//         return (
//           <div>
//             {row.status === "Active" &&
//               <button type="button"
//                 className="btn btn-success btn-hover-shine me-3 btn">{t('Active')}
//               </button>
//             }
//             {row.status === "InActive" &&
//               <button type="button"
//                 className="btn btn-danger btn-hover-shine me-3 btn">{t('Deactivated')}
//               </button>
//             }
//           </div>
//         );
//       }
//     },
//     {
//       name: 'Actions',
//       selector: (row) => {
//         return (
//           <div>
//             <button className="mt-2 mb-2 me-2 btn-icon btn">
//               <Tooltip placement="top" title="User Details" arrow>
//                 <Link to="/publicHoliday-details" state={row}>
//                   <i className="pe-7s-note2 btn-icon-wrapper text-info"> </i>
//                 </Link>
//               </Tooltip >

//               <Tooltip placement="top" title="Update" arrow>
//                 <i>
//                   <Link to="/update-publicHoliday" state={row}>
//                     <i className="pe-7s-pen btn-icon-wrapper text-primary"> </i>
//                   </Link>
//                 </i>
//               </Tooltip >

//               <Tooltip placement="top" title="Delete" arrow>
//                 <i onClick={() => confirmDeleteCustomer(row._id)} className="pe-7s-trash text-danger btn-icon-wrapper"> </i>
//               </Tooltip >
//               {/* {row.customer_status === "Active" && */}
//               <Tooltip placement="top" title="Send Credentials" arrow>
//                 <i onClick={() => regeneratePassword(row._id)} className="pe-7s-mail-open-file text-danger btn-icon-wrapper"> </i>
//               </Tooltip >
//               {/* } */}
//               {/* <Tooltip placement="top" title="Regenrate Password" arrow>
//                 <i onClick={() => regeneratePassword(row._id)} className="text-warning btn-sm px-0 btn-icon-wrapper">
//                   <FontAwesomeIcon icon={["fas", "key"]} />
//                 </i>
//               </Tooltip> */}
//             </button>
//           </div>
//         );
//       }
//     },
//   ];


//   return (
//     <Fragment>

//           <div>
//             <span className="d-inline-block mb-2 me-2">

//               <Modal isOpen={modal} toggle={toggleModal} backdrop={true}>
//                 <ModalHeader >{t('Import Records')}</ModalHeader>
//                 <ModalBody>
//                   <LoadingOverlay tag="div" active={loading2}
//                     styles={{ overlay: (base) => ({ ...base }) }}
//                     spinner={<DNALoader />}>
//                     <Card className="main-card mb-3">
//                       <CardBody>
//                         <form id="importform">
//                           <input
//                             type='file' name="importfile" label={t('Upload')} accept='.csv'
//                             onChange={uploadFileButton}
//                             id="fileUpload"
//                           />

//                           <br />
//                           <p style={{ border: "3px solid red", padding: "5px", marginTop: "10px" }}>{t("* Please do not remove or change the first row in the sample file also add member type.")}</p>
//                         </form>

//                       </CardBody>
//                     </Card>
//                   </LoadingOverlay>
//                 </ModalBody>
//                 <ModalFooter>
//                   <Button className="btn btn-success btn-hover-shine me-3 btn" onClick={() => {
//                     handleDownload('sample.csv')
//                   }}>{t('Download Sample')}</Button>
//                   <Button color="link" onClick={toggleModal} className="btn btn-danger btn-hover-shine me-3 btn">
//                     {t('Cancel')}
//                   </Button>
//                 </ModalFooter>
//               </Modal>
//               <Modal isOpen={modal2} toggle={toggleModal2} backdrop={true}>
//                 <ModalHeader ><h4 style={{ color: "green" }} >{t('Import Records Stats')}</h4></ModalHeader>
//                 <ModalBody>
//                   <Card className="main-card mb-3">
//                     <CardBody>
//                       <h4>{t("Total Records")} : <span> {TotalRecords}</span></h4>
//                       <h4 style={{ color: "green" }} >{t("Records Imported")} : <span > {ImportedRecords}</span></h4>
//                       <h4 style={{ color: "red" }}>{t("Records Not Imported")} : <span  > {SkippedRecords}</span></h4>
//                       {/* <h4 style={{ color: "orange" }} >{t("Serials Already Found")} : <span > {totalduplicates}</span></h4> */}
//                       {/* {duplicates && duplicates.map((dup, index) => {
//                             return (
//                               <><span>{dup}</span></>
//                             )
//                           })} */}



//                     </CardBody>
//                   </Card>
//                 </ModalBody>
//                 <ModalFooter>
//                   <Button color="link" onClick={toggleModal2} className="btn btn-danger btn-hover-shine me-3 btn">
//                     {t('Close')}
//                   </Button>
//                 </ModalFooter>
//               </Modal>

//             </span>
//           </div>

//           <div className=" bg-light-gray bg-animation ">
//             <div className="d-flex  justify-content-center align-items-center">
//               <Col md="12" className="mx-auto app-login-box">
//                 <Row>
//                   <Fragment>
//                     <Row>
//                       <Col md="12">
//                         <Card className="main-card mb-3">
//                           <CardBody>
//                             <Row className="mt-2">
//                               <Row>
//                                 <Col md="3">
//                                   <Input type="text" name="fname" value={searchName} placeholder="Search Text" onChange={event => nameFilter(event)} />
//                                 </Col>
//                                 <Col md="9" className="d-flex justify-content-end align-items-center">
//                                   <button style={{ marginLeft: '5px' }} type="button" className="btn btn-custom-color btn-hover-shine mr-10" onClick={() => searchCustomers()}>Search</button>
//                                   <button style={{ marginLeft: '5px' }} type="button" className="btn btn-custom-color btn-hover-shine mr-10" onClick={() => resetFilters()}>Reset</button>
//                                   <button style={{ marginLeft: '5px' }} type="button" className="btn btn-custom-color btn-hover-shine mr-10" onClick={toggleModal}>{t('Import')}</button>
//                                   <a style={{ marginLeft: '5px' }} href="/add-publicHoliday" className="btn btn-custom-color btn-hover-shine ml-10">Add New</a>
//                                 </Col>
//                               </Row>


//                               <Col md="12" className="pt-3">
//                                 <LoadingOverlay tag="div" active={loading}
//                                   styles={{ overlay: (base) => ({ ...base }) }}
//                                   spinner={<DNALoader />}>
//                                   <DataTable
//                                     data={customers}
//                                     columns={mycolumns}
//                                     pagination
//                                     fixedHeader
//                                     fixedHeaderScrollHeight="400px"
//                                     paginationServer
//                                     paginationTotalRows={totalRows}
//                                     onChangePage={handlePageChange}
//                                     onChangeRowsPerPage={handlePerRowsChange}
//                                     paginationResetDefaultPage={resetPaginationToggle}
//                                   />
//                                 </LoadingOverlay>
//                               </Col>
//                             </Row>

//                           </CardBody>
//                         </Card>
//                       </Col>
//                     </Row>
//                   </Fragment>

//                 </Row>
//               </Col>
//             </div>
//           </div>
//     </Fragment>
//   );
// };

// export default CustomersList;