import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from "react-router-dom";
import { Col, Row, Label, FormGroup, Input, Card, CardBody } from "reactstrap";
import { Form, Field } from "react-final-form";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";
import Alerts from "../../components/Alerts/alerts";
import UserService from "../../pages/Users Management/users.service";
import AuthService from "../Authentication/auth.service";
import RoleService from "../Roles/roles.service";
import $ from 'jquery';
import PermissionAccess from '../../PermissionAccess/Access.js';

const UpdateUser = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  console.log("Received Data:-", state);

  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [BusinessList, setBusinesses] = useState([]);
  const [modulesvalues, setmodulesvalues] = useState(state?.businesses || []);
  const [checkAll, setCheckAll] = useState(false);
  const { authData, accessPages, super_admin, current_module } = PermissionAccess();
  console.log(modulesvalues);

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    getroles();
    GetBusinesses();
  }, []);

  const GetBusinesses = async () => {
    try {
      const response = await RoleService.GetBusinesses(authData.sessionID);
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      } else {
        console.log("Business : ", response.data.data);
        const data = await response.data.data;
        setBusinesses(data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleModuleChange = (e) => {
    const modval = e.target.value;
    const parent = $(e.target).attr('parent');
    const modulesvaluesarr = [...modulesvalues];

    if (e.target.checked) {
      $(".child-check-box-" + modval).prop("checked", true);
      if (parent !== "") {
        $("#module-checkbox" + parent).prop("checked", true);
      }
      if (!modulesvaluesarr.includes(modval)) {
        modulesvaluesarr.push(modval);
      }
    } else {
      $(".child-check-box-" + modval).prop("checked", false);
      const index = modulesvaluesarr.indexOf(modval);
      if (index !== -1) {
        modulesvaluesarr.splice(index, 1);
      }
    }

    setmodulesvalues(modulesvaluesarr);
    setCheckAll(modulesvaluesarr.length === BusinessList.length);
  };

  const handleCheckAllChange = () => {
    if (checkAll) {
      setmodulesvalues([]);
      $(".module-checkbox").prop("checked", false);
    } else {
      const allBusinessIds = BusinessList.map(business => business._id);
      setmodulesvalues(allBusinessIds);
      $(".module-checkbox").prop("checked", true);
    }
    setCheckAll(!checkAll);
  };

  const getroles = async () => {
    try {
      const response = await RoleService.getAll(authData.sessionID);
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      } else {
        const data = await response.data.data;
        setRoles(data);
        console.log("Roles:-", data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const onSubmit = values => {
    console.log(values);
    const payloadData = {
      functionName: "UpdateUser",
      postData: {
        name: values.name,
        password: values.password,
        contact: values.contact,
        role_id: values.role_id,
        status: values.status,
        _id: state._id,
        businesses: modulesvalues
      },
    };
    setLoading(true);
    UserService.updateUser(authData.sessionID,payloadData).then((response) => {
      console.log("API Response:-", response);
      setLoading(false);
      if (response.data.status) {
        navigate("/users-list");
        Alerts.swalSuccessAlert(response.data.message || t("User updated successfully."));
      } else {
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        } else {
          Alerts.swalErrorAlert(response.data.message || t("Error: Can't update user at the moment. Please try again later."));
        }
      }
    }, (error) => {
      setLoading(false);
      const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      Alerts.swalErrorAlert(t("Network Operation failed."));
    });
  };

  const validateFtn = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = t("User Name Required.");
    }
    if (!values.contact) {
      errors.contact = t("Contact No Required.");
    }
    if (!values.role_id) {
      errors.role_id = t("Please select a role.");
    }
    if (!values.status) {
      errors.status = t("Please select status.");
    }
    return errors;
  };

  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className="app-page-title">
            <div className="page-title-wrapper justify-content-left">
              <div className="page-title-heading fw-bold">
                <div className="page-title-icon">
                  <i className="pe-7s-add-user icon-gradient bg-happy-green" />
                </div>
                <div>{t('Update User')}
                  <div className="page-title-subheading">
                    {t('Edit the form below to update a User.')}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center">
            <div className="col-md-12">
              <Card className="main-card mb-3">
                <CardBody>
                  <Form onSubmit={onSubmit}
                    initialValues={state}
                    validate={validateFtn}
                    render={({ handleSubmit, values, submitting, validating, valid }) => (
                      <form onSubmit={handleSubmit}>
                        <LoadingOverlay tag="div" active={loading}
                          styles={{ overlay: (base) => ({ ...base }) }}
                          spinner={<DNALoader />}>
                          <Row form>
                            <Col md={6}>
                              <FormGroup>
                                <Field name="name">
                                  {({ input, meta }) => (
                                    <div>
                                      <Label for="username">{t('User Name')}</Label>
                                      <Input {...input} type="text"
                                        invalid={meta.error && meta.touched}
                                        placeholder={t('User Name')}
                                      />
                                      {meta.error && meta.touched &&
                                        <span className="text-danger">{meta.error}</span>}
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                              <FormGroup>
                                <Field name="contact">
                                  {({ input, meta }) => (
                                    <div>
                                      <Label for="contact">{t('Contact No')}</Label>
                                      <Input {...input} type="number"
                                        invalid={meta.error && meta.touched}
                                        placeholder={t('User Contact No')}
                                      />
                                      {meta.error && meta.touched &&
                                        <span className="text-danger">{meta.error}</span>}
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <Field name="role_id">
                                  {({ input, meta }) => (
                                    <div>
                                      <Label for="role">{t('Role')}</Label>
                                      <Input {...input} type="select"
                                        invalid={meta.error && meta.touched}
                                      >
                                        <option disabled={true} value="">{t('--Choose an option--')}</option>
                                        {roles &&
                                          roles.map((role, index) => (
                                            <option key={index} value={role._id}>{role.name}</option>
                                          ))
                                        }
                                      </Input>
                                      {meta.error && meta.touched &&
                                        <span className="text-danger">{meta.error}</span>}
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                              <FormGroup>
                                <Field name="status">
                                  {({ input, meta }) => (
                                    <div>
                                      <Label for="status">{t('Status')}</Label>
                                      <Input {...input} type="select"
                                        invalid={meta.error && meta.touched}
                                      >
                                        <option disabled={true} value="">{t('--Choose an option--')}</option>
                                        <option value="1">{t('Activate')}</option>
                                        <option value="0">{t('Deactivate')}</option>
                                      </Input>
                                      {meta.error && meta.touched &&
                                        <span className="text-danger">{meta.error}</span>}
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                            </Col>
                            <div className="mb-3">
                              <h5 >{t('Assigned Business')}
                                <input
                                  style={{ marginLeft: 15, marginTop: 2 }}
                                  type="checkbox"
                                  checked={checkAll}
                                  onChange={handleCheckAllChange}
                                />
                              </h5>
                            </div>
                            {BusinessList.length > 0 && BusinessList.map((module, index) => (
                              <div className="accordion col-md-6 col-xl-6 pb-3" key={module._id}>
                                <div className="card">
                                  <div className="card-header bg-light" id={"heading" + module._id}>
                                    <input
                                      className="form-check-input module-checkbox"
                                      type="checkbox"
                                      name="modules[]"
                                      value={module._id}
                                      onChange={handleModuleChange}
                                      id={"module-checkbox" + module._id}
                                      parent=""
                                      checked={modulesvalues.includes(module._id)}
                                    />
                                    {'  '}
                                    <a className="mt-1">
                                      &nbsp;&nbsp;{module.name}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            ))}
                            <FormGroup>
                              <div className="d-flex justify-content-center">
                                <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-light btn-hover-shine me-3 mt-3">{t('Cancel')}</button>
                                <button className="btn btn-custom-color btn-hover-shine me-3 mt-3" disabled={!valid}>{t('Update User')}</button>
                              </div>
                            </FormGroup>
                          </Row>
                        </LoadingOverlay>
                      </form>
                    )}
                  />
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </div >
    </Fragment >
  );
};

export default UpdateUser;
