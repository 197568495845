import React, { Fragment, useState, useEffect } from "react";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useNavigate, useParams } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import DashboardService from "../dashboard.service";
import Header from './Header';

const ProductDetails = () => {
    const { id } = useParams();
    const [branch, setBranch] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [showReviews, setShowReviews] = useState(false);
    const [productData, setProductData] = useState({
        amount: '',
        item_name: '',
        image: '',
        images: [],
        index: 0,
    });
    const [reviewsData, setReviewsData] = useState([]);

    useEffect(() => {
        fetchProductDetails();
    }, []);

    const fetchProductDetails = async () => {
        setLoading(true);
        try {
            const updateData = {
                "functionName": "SearchItem",
                "postData": {
                    "item_id": id,
                    "business_id": ""
                }
            };
            const response = await DashboardService.GetProducctDetails(updateData);
            if (response.data.status === true) {
                const product = response.data.data[0];
                const imageIndex = product.index || 0;
                const imageUrl = product.images?.[imageIndex] || product.images[0];

                setProductData({
                    amount: product.amount,
                    item_name: product.item_name,
                    image: imageUrl,
                    images: product.images,
                    index: imageIndex,
                });
            }
        } catch (error) {
            console.error('Error fetching product details:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchReviews = async () => {
        setLoading(true);
        try {
            const updateData = {
                "functionName": "ProductReviews",
                "postData": {
                  "item_id": id
                }
            };
            const response = await DashboardService.GeProductReviews(updateData);
            if (response.data.status === true) {
                setReviewsData(response.data.reviewsData.data);
            }
        } catch (error) {
            console.error('Error fetching reviews:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleDetailsClick = () => {
        setShowReviews(false);
    };

    const handleReviewsClick = () => {
        if (!showReviews) {
            fetchReviews();
        }
        setShowReviews(true);
    };

    return (
        <Fragment>
            <div className="app-main__outer">
                <div className="app-main__inner">
                    <TransitionGroup>
                        <CSSTransition component="div" classNames="TabsAnimation" appear={true} timeout={0} enter={false} exit={false}>
                            <div>
                                <div className="app-page-title">
                                    <div className="page-title-wrapper">
                                        <Header branch={branch} setBranch={setBranch} />
                                    </div>
                                </div>
                                <LoadingOverlay active={loading} styles={{ overlay: (base) => ({ ...base }) }} spinner={<DNALoader />}>
                                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                                        <button
                                            style={{
                                                backgroundColor: 'blue',
                                                color: 'white',
                                                border: 'none',
                                                borderRadius: '5px',
                                                padding: '10px 20px',
                                                cursor: 'pointer',
                                                marginLeft: '-425px',
                                            }}
                                            onClick={() => navigate(-1)}
                                        >
                                            Back
                                        </button>
                                    </div>
                                    <div style={{ width: '100%', maxWidth: '500px', margin: '0 auto 15px', borderRadius: '10px', boxShadow: '0 2px 5px rgba(0,0,0,0.1)', backgroundColor: '#fff', overflow: 'hidden' }}>
                                        <img
                                            src={productData.image}
                                            alt={productData.item_name}
                                            style={{ width: '100%', height: '200px', objectFit: 'cover' }}
                                        />
                                        <div style={{ padding: '15px' }}>
                                            <h2 style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '10px' }}>{productData.item_name}</h2>
                                            <p style={{ fontSize: '16px', fontWeight: 'bold', color: '#e53935' }}>{parseFloat(productData.amount).toFixed(2)}</p>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '15px' }}>
                                                <button
                                                    style={{ flex: '1', padding: '10px', textAlign: 'center', border: 'none', borderRadius: '5px', cursor: 'pointer', backgroundColor: showReviews ? '#f5f5f5' : '#e53935', color: showReviews ? 'black' : 'white' }}
                                                    onClick={handleDetailsClick}
                                                >
                                                    Details
                                                </button>
                                                <button
                                                    style={{ flex: '1', padding: '10px', textAlign: 'center', border: 'none', borderRadius: '5px', cursor: 'pointer', backgroundColor: showReviews ? '#e53935' : '#f5f5f5', color: showReviews ? 'white' : 'black' }}
                                                    onClick={handleReviewsClick}
                                                >
                                                    Reviews
                                                </button>
                                            </div>
                                            {showReviews && (
                                                <div style={{ marginTop: '15px' }}>
                                                    <h3>Reviews</h3>
                                                    {reviewsData.length > 0 ? (
                                                        reviewsData.map((review, index) => (
                                                            <div key={index} style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '10px' }}>
                                                                <div style={{ width: '40px', height: '40px', borderRadius: '50%', backgroundColor: '#e53935', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold', marginRight: '10px' }}>
                                                                    {review.user_details.name.charAt(0).toUpperCase()}
                                                                </div>
                                                                <div>
                                                                    <strong>{review.user_details.name} (★{review.rating})</strong>
                                                                    <p style={{ margin: '5px 0' }}>{review.comment}</p>
                                                                    <small>
                                                                        {new Date(review.datetime_added).toLocaleDateString('en-US', {
                                                                            month: 'short',
                                                                            day: 'numeric',
                                                                            year: 'numeric'
                                                                        })}
                                                                    </small>
                                                                </div>
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <p>No reviews yet.</p>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </LoadingOverlay>
                            </div>
                        </CSSTransition>
                    </TransitionGroup>
                </div>
            </div>
        </Fragment>
    );
};

export default ProductDetails;
