import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";
import {
  Typography, Chip, Grid, Paper, Box, Table,
  TableBody, TableCell, TableContainer, TableRow,
  Button
} from '@mui/material';
import { styled } from '@mui/material/styles';
import LayersIcon from '@mui/icons-material/Layers';
import PercentIcon from '@mui/icons-material/Percent';
import PeopleIcon from '@mui/icons-material/People';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Services from "./services.js";
import AuthService from "../Authentication/auth.service";
import Alerts from "../../components/Alerts/alerts";
import PermissionAccess from '../../PermissionAccess/Access.js';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  margin: theme.spacing(3, 0),
  backgroundColor: '#fff',
  boxShadow: '0 2px 12px rgba(0,0,0,0.08)',
  borderRadius: '12px',
  border: '1px solid #edf2f7'
}));

const SectionTitle = styled(Typography)({
  fontSize: '1.25rem',
  fontWeight: 600,
  color: '#2d3748',
  marginBottom: '1.5rem',
  paddingBottom: '1rem',
  borderBottom: '1px solid #edf2f7',
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  '& .icon': {
    color: 'var(--buttons-color, #30b1ff)'
  }
});

const StyledTableCell = styled(TableCell)({
  fontWeight: 600,
  color: '#4a5568',
  borderBottom: '1px solid #edf2f7',
  padding: '1rem',
  width: '200px',
  '& .icon': {
    color: 'var(--buttons-color, #30b1ff)'
  }
});

const ValueCell = styled(TableCell)({
  color: '#2d3748',
  borderBottom: '1px solid #edf2f7',
  padding: '1rem'
});

const StyledChip = styled(Chip)({
  fontWeight: 500,
  '&.tier-chip': {
    backgroundColor: 'var(--buttons-color-light, rgba(48, 177, 255, 0.1))',
    color: 'var(--buttons-color, #30b1ff)',
    border: '1px solid var(--buttons-color, #30b1ff)'
  },
  '&.success': {
    backgroundColor: '#dcfce7',
    color: '#166534',
    border: '1px solid #bbf7d0'
  },
  '&.error': {
    backgroundColor: '#fee2e2',
    color: '#991b1b',
    border: '1px solid #fecaca'
  }
});

const ActionButton = styled(Button)({
  padding: '8px 24px',
  borderRadius: '8px',
  fontWeight: 600,
  textTransform: 'none',
  boxShadow: 'none',
  '&.success': {
    backgroundColor: '#22c55e',
    '&:hover': {
      backgroundColor: '#16a34a'
    }
  },
  '&.error': {
    backgroundColor: '#ef4444',
    '&:hover': {
      backgroundColor: '#dc2626'
    }
  }
});

const MLMTierDetailsComponent = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { state: tier } = location;
  const [loading, setLoading] = useState(false);
  const { authData } = PermissionAccess();

  const handleApproval = async (approve) => {
    setLoading(true);
    try {
      const response = await Services.updateMLMTierApproval(tier._id, approve, authData.sessionID);
      if (response.data.status) {
        tier.status = approve ? "Active" : "Inactive";
        Alerts.swalSuccessAlert(approve ? "Tier activated successfully" : " Tier deactivated");
      } else {
        Alerts.swalErrorAlert(response.data.message || "Error updating Tier status");
      }
    } catch (error) {
      Alerts.swalErrorAlert("Network operation failed");
    } finally {
      setLoading(false);
    }
  };

  const renderInfoRow = (icon, label, value) => (
    <TableRow>
      <StyledTableCell>
        <Box display="flex" alignItems="center" gap={1}>
          {icon}
          <Typography>{t(label)}</Typography>
        </Box>
      </StyledTableCell>
      <ValueCell>{value}</ValueCell>
    </TableRow>
  );

  return (
    <LoadingOverlay active={loading} spinner={<DNALoader />}>
      <StyledPaper elevation={3}>
        <SectionTitle>
          <LayersIcon className="icon" />
          {t("Tier Configuration Details")}
        </SectionTitle>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TableContainer>
              <Table>
                <TableBody>
                  {/* Level Information */}
                  {renderInfoRow(
                    <ArrowUpwardIcon className="icon" />,
                    'Level',
                    <Typography variant="body1" fontWeight={600}>
                      Level {tier.level}
                    </Typography>
                  )}

                  {/* Percentage */}
                  {renderInfoRow(
                    <PercentIcon className="icon" />,
                    'Percentage',
                    <Typography variant="body1" fontWeight={600}>
                      {tier.percentage}%
                    </Typography>
                  )}

                  {/* Referral Tier */}
                  {renderInfoRow(
                    <PeopleIcon className="icon" />,
                    'Referral Tier',
                    <StyledChip
                      label={tier.referral_tier}
                      className="tier-chip"
                    />
                  )}

                  {/* Status */}
                  {renderInfoRow(
                    <LayersIcon className="icon" />,
                    'Status',
                    <StyledChip
                      label={tier.status}
                      className={tier.status === 'Active' ? 'success' : 'error'}
                    />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>

        <Box display="flex" justifyContent="center" mt={4}>
          {tier.status === "Inactive" ? (
            <ActionButton
              variant="contained"
              className="success"
              onClick={() => handleApproval(true)}
            >
              Activate Tier
            </ActionButton>
          ) : (
            <ActionButton
              variant="contained"
              className="error"
              onClick={() => handleApproval(false)}
            >
              Deactivate Tier
            </ActionButton>
          )}
        </Box>
      </StyledPaper>
    </LoadingOverlay>
  );
};

export default MLMTierDetailsComponent;
