import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, Navigate } from "react-router-dom";
import { Row, Col, FormGroup, Input, Button, Label, Modal, ModalHeader, ModalBody, Card, CardBody } from "reactstrap";
import Services from "./services";
import AuthService from "../Authentication/auth.service";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import Alerts from "../../components/Alerts/alerts";
import PermissionAccess from '../../PermissionAccess/Access.js';


const AddPackage = ({ onSuccess }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [Customers, setCustomers] = useState([{ name: '', price: '', mpv: '' , multiplier:'', description: ''}]);
  const [loading, setLoading] = useState(false);
  const [loginNavigate, setLoginNavigate] = useState(false);
  const [Fetched_Businesses, setFetched_Businesses] = useState([]);
  const { authData, accessPages, super_admin, current_module } = PermissionAccess();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const modalRef = useRef(null);

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    Fetch_Businesses();
  }, []);

  function Fetch_Businesses() {
    setLoading(true);
    Services.GetBusinesses(authData.sessionID).then(
      (response) => {
        setLoading(false);
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        } else {
          const business = response.data.data[0];
          setFetched_Businesses([business]);
        }
      },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        Alerts.swalErrorAlert(errContent || t("Server Error, Please try again later."));
      }
    );
  }


  const toggle = () => {
    setModalOpen(!modalOpen);
    if (!modalOpen) {
      setCustomers([{ name: '', price: '', mpv: '' }]);
    }
  };


  const handlePackageChange = (index, field, value) => {
    setCustomers(prevCustomers => prevCustomers.map((Package, i) =>
      i === index ? { ...Package, [field]: value } : Package
    ));
  };

  const validateForm = () => {
    return Customers.every(Package =>
      Package.name &&
      Package.price &&
      Package.mpv &&
      Package.multiplier &&
      Package.description
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      Alerts.swalErrorAlert("Please fill all fields.");
      return;
    }
    let payload = {}
    Object.keys(Customers[0]).forEach(key => {
      payload[key] = Customers[0][key]
    });

    payload.business_id = Fetched_Businesses[0]._id;
    payload.status = "Active";
    setLoading(true);
    try {
      const response = await Services.AddPackage(authData.sessionID, payload);
      setLoading(false);
      if (response.data.status) {
        onSuccess();
        Alerts.swalSuccessAlert(response.data.message || "Package Added Successfully.").then(() => {
          toggle();
        });
      } else {
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          setLoginNavigate(true);
        } else {
          Alerts.swalErrorAlert(response.data.message || "Error: Can't Add Package at the moment. Please try again later.");
        }
      }
    } catch (error) {
      setLoading(false);
      Alerts.swalErrorAlert("Network Operation failed.");
    }
  };

  if (loginNavigate) {
    return <Navigate to={'/login'} />;
  }

  return (
    <span className="d-inline-block float-end mb-2 me-2">
      <Button color="success" onClick={toggle} className="btn-hover-shine ms-1">
        {t("Add New")}
      </Button>
      <Modal size='lg' isOpen={modalOpen} toggle={toggle} backdrop={true} ref={modalRef}>
        <LoadingOverlay active={loading} spinner={<DNALoader />}>
          <ModalHeader>{t("Add Package")}</ModalHeader>
          <ModalBody>
            <Card className="main-card mb-3">
              <CardBody>
                <form onSubmit={handleSubmit}>
                  {Customers.map((Package, index) => (
                    <FormGroup key={index} className="mb-3">
                      <Row>
                        <Col md="12">
                          <Label>{t("Package Name")}</Label>
                          <Input
                            type="text"
                            value={Package.name}
                            onChange={(e) => handlePackageChange(index, 'name', e.target.value)}
                            placeholder={t("Enter Package Name")}
                          />
                        </Col>
                        <Col style={{ marginTop: '15px' }} md="12">
                          <Label>{t("Price")}</Label>
                          <Input
                            type="text"
                            value={Package.price}
                            onChange={(e) => handlePackageChange(index, 'price', e.target.value)}
                            placeholder={t("Enter Price")}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col style={{ marginTop: '15px' }} md="12">
                          <Label>{t("MPV")}</Label>
                          <Input
                            type="number"
                            value={Package.mpv}
                            onChange={(e) => handlePackageChange(index, 'mpv', e.target.value)}
                            placeholder={t("Enter MPV")}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col style={{ marginTop: '15px' }} md="12">
                          <Label>{t("Multiplier")}</Label>
                          <Input
                            type="number"
                            value={Package.multiplier}
                            onChange={(e) => handlePackageChange(index, 'multiplier', e.target.value)}
                            placeholder={t("Enter Multiplier")}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col style={{ marginTop: '15px' }} md="12">
                          <Label>{t("Description")}</Label>
                          <Input
                            type="text"
                            value={Package.description}
                            onChange={(e) => handlePackageChange(index, 'description', e.target.value)}
                            placeholder={t("Enter Description")}
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                  ))}
                  <div className="text-center">
                    <Button type="submit" color="success" size="lg" className="me-3">
                      {t("Save")}
                    </Button>
                    <Button type="button" color="secondary" size="lg" onClick={toggle}>
                      {t("Cancel")}
                    </Button>
                  </div>
                </form>
              </CardBody>
            </Card>
          </ModalBody>
        </LoadingOverlay>
      </Modal>
      <style jsx>{`
        .pac-container {
          z-index: 1500 !important;
        }
      `}</style>
    </span>
  );
};

export default AddPackage;

