import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Box,
  Button,
  Grid,
  Avatar,
  CircularProgress,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import moment from "moment";
import AlertService from "../../components/Alerts/alerts";
import EpinVerification from "../../components/EpinVerification"; // Adjust path as needed

const B_URL = process.env.REACT_APP_LOGINURL;

const Profile = ({ userData }) => {
  const [editable, setEditable] = useState(false); // Toggle edit mode
  const [loading, setLoading] = useState(true); // Loading state for fetching data
  const [epin, setEpin] = useState(""); // State for E-Pin input
  const [epinDialogOpen, setEpinDialogOpen] = useState(false);

  const [profile, setProfile] = useState({
    profilePicture: "",
    fullName: "",
    membershipStatus: "",
    datetime_added: "",
    accountType: "",
    membershipLevel: "",
    username: "",
    idType: "",
    validIdNo: "",
    nationality: "",
    dateOfBirth: "",
    gender: "",
    mobileNumber: "",
    email: "",
    idAddress: "",
    mailingAddress: "",
    sponsorName: "",
    sponsorUsername: "",
    sponsorContactNo: "",
    beneficiaryName: "",
    relationshipToBeneficiary: "",
  });

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const headers = { "Content-Type": "application/json" };
        const payload = {
          functionName: "User_Data",
          postData: { user_id: userData._id },
        };
        const response = await axios.post(
          `${B_URL}UserModule/User_Data`,
          payload,
          { headers }
        );
        const data = response?.data?.data?.[0] || {};
        console.log("User details:", data);
        setProfile({
          profilePicture: data.profilePicture || "",
          fullName: data.fullName || "",
          membershipStatus: data.membershipStatus || "",
          datetime_added: moment(data.datetime_added).format('DD MM YYYY') || "",
          accountType: data.type ? data.type : data.user_type || "",
          membershipLevel: data.membershipLevel || "",
          username: data.username || "",
          idType: data.idType || "",
          validIdNo: data.validIdNo || "",
          nationality: data.nationality || "",
          dateOfBirth: data.dateOfBirth || "",
          gender: data.gender || "",
          mobileNumber: data.mobileNumber || "",
          email: data.email || "",
          idAddress: data.idAddress || "",
          mailingAddress: data.mailingAddress || "",
          sponsorName: data?.Sponsor?.fullName || "",
          sponsorUsername: data?.Sponsor?.username || "",
          sponsorContactNo: data.Sponsor?.mobileNumber || "",
          beneficiaryName: data.beneficiaryName || "",
          relationshipToBeneficiary: data.relationshipToBeneficiary || "",
        });
      } catch (error) {
        console.error("Failed to fetch user details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, [userData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfile((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setProfile((prevState) => ({ ...prevState, profilePicture: reader.result }));
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleSaveChanges = async () => {
    setEpinDialogOpen(true);
  };

  const handleEpinSubmit = async () => {
    try {
      setLoading(true);
      const user = JSON.parse(localStorage.getItem("user"));
      if (!user || !user._id) {
        console.error("User data not found in localStorage");
        return;
      }
      const headers = { "Content-Type": "application/json" };
      const Profile = { ...profile, user_id: user._id };
      await axios.post(`${B_URL}UserModule/Update_User_Profile`, Profile, { headers });
      AlertService.swalSuccessAlert("Profile updated successfully!");
      setEditable(false);
    } catch (error) {
      console.error("Failed to update profile:", error);
      AlertService.swalErrorAlert("Failed to update profile. Please try again.");
    } finally {
      setLoading(false);
      setEpinDialogOpen(false);
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: 900, margin: "20px auto", padding: "20px" }}>
      <Card elevation={3} sx={{ marginBottom: 4, borderRadius: 2 }}>
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h4" fontWeight="bold" gutterBottom>
              Profile Information
            </Typography>
            <Button
              variant="contained"
              color={editable ? "success" : "primary"}
              onClick={editable ? handleSaveChanges : () => setEditable(true)}
              sx={{ marginTop: 2 }}
            >
              {editable ? "Save Changes" : "Edit Profile"}
            </Button>
          </Box>
          <Divider sx={{ marginY: 2 }} />
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Box sx={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
                <Avatar
                  src={profile.profilePicture}
                  variant="rounded"
                  sx={{ width: 120, height: 120, border: '2px solid #1976d2' }}
                />
                {editable && (
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                    style={{
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      opacity: 0,
                      cursor: 'pointer',
                    }}
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Name"
                name="fullName"
                value={profile.fullName}
                onChange={handleInputChange}
                fullWidth
                disabled={!editable}
                variant="outlined"
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Membership Status"
                name="membershipStatus"
                value={profile.membershipStatus}
                fullWidth
                disabled={true}
                variant="outlined"
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Date Joined"
                name="datetime_added"
                value={profile.datetime_added}
                fullWidth
                disabled={true}
                variant="outlined"
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Current Account Type"
                name="accountType"
                value={profile.accountType}
                fullWidth
                disabled={true}
                variant="outlined"
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Current Membership Level"
                name="membershipLevel"
                value={profile.membershipLevel}
                fullWidth
                disabled={true}
                variant="outlined"
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Username"
                name="username"
                value={profile.username}
                fullWidth
                disabled={true}
                variant="outlined"
                margin="normal"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card elevation={3} sx={{ marginBottom: 4, borderRadius: 2 }}>
        <CardContent>
          <Typography variant="h5" fontWeight="bold" gutterBottom>
            Identification Information
          </Typography>
          <Divider sx={{ marginY: 2 }} />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                label="ID Type"
                name="idType"
                value={profile.idType}
                onChange={handleInputChange}
                fullWidth
                disabled={!editable}
                variant="outlined"
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Valid ID No."
                name="validIdNo"
                value={profile.validIdNo}
                onChange={handleInputChange}
                fullWidth
                disabled={!editable}
                variant="outlined"
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Nationality"
                name="nationality"
                value={profile.nationality}
                onChange={handleInputChange}
                fullWidth
                disabled={!editable}
                variant="outlined"
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Date of Birth"
                name="dateOfBirth"
                value={profile.dateOfBirth}
                onChange={handleInputChange}
                fullWidth
                disabled={!editable}
                variant="outlined"
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Gender"
                name="gender"
                value={profile.gender}
                onChange={handleInputChange}
                fullWidth
                disabled={!editable}
                variant="outlined"
                margin="normal"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card elevation={3} sx={{ marginBottom: 4, borderRadius: 2 }}>
        <CardContent>
          <Typography variant="h5" fontWeight="bold" gutterBottom>
            Contact Information
          </Typography>
          <Divider sx={{ marginY: 2 }} />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Mobile No."
                name="mobileNumber"
                value={profile.mobileNumber}
                onChange={handleInputChange}
                fullWidth
                disabled={!editable}
                variant="outlined"
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Email Address"
                name="email"
                value={profile.email}
                fullWidth
                disabled={true}
                variant="outlined"
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="ID Address"
                name="idAddress"
                value={profile.idAddress}
                onChange={handleInputChange}
                fullWidth
                disabled={!editable}
                variant="outlined"
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Mailing Address"
                name="mailingAddress"
                value={profile.mailingAddress}
                onChange={handleInputChange}
                fullWidth
                disabled={!editable}
                variant="outlined"
                margin="normal"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card elevation={3} sx={{ marginBottom: 4, borderRadius: 2 }}>
        <CardContent>
          <Typography variant="h5" fontWeight="bold" gutterBottom>
            Sponsor Information
          </Typography>
          <Divider sx={{ marginY: 2 }} />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Direct Sponsor Name"
                name="sponsorName"
                value={profile.sponsorName}
                fullWidth
                disabled={true}
                variant="outlined"
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Direct Sponsor Username"
                name="sponsorUsername"
                value={profile.sponsorUsername}
                fullWidth
                disabled={true}
                variant="outlined"
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Direct Sponsor Contact No."
                name="sponsorContactNo"
                value={profile.sponsorContactNo}
                fullWidth
                disabled={true}
                variant="outlined"
                margin="normal"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card elevation={3} sx={{ borderRadius: 2 }}>
        <CardContent>
          <Typography variant="h5" fontWeight="bold" gutterBottom>
            Beneficiary Information
          </Typography>
          <Divider sx={{ marginY: 2 }} />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Beneficiary Name"
                name="beneficiaryName"
                value={profile.beneficiaryName}
                onChange={handleInputChange}
                fullWidth
                disabled={!editable}
                variant="outlined"
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Relationship to Beneficiary"
                name="relationshipToBeneficiary"
                value={profile.relationshipToBeneficiary}
                onChange={handleInputChange}
                fullWidth
                disabled={!editable}
                variant="outlined"
                margin="normal"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <EpinVerification
        open={epinDialogOpen}
        onClose={() => setEpinDialogOpen(false)}
        onSuccess={handleEpinSubmit} // Function to execute after successful verification
      />

    </Box>
  );
};

export default Profile;
