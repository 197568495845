import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";
import AlertService from "../../components/Alerts/alerts";
import {
  Typography, Chip, Grid, Paper, Box, Table,
  TableBody, TableCell, TableContainer, TableRow, IconButton,
  Card, CardMedia, Divider, Avatar, Button, Modal, Fade
} from '@mui/material';
import { styled } from '@mui/material/styles';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import BusinessIcon from '@mui/icons-material/Business';
import DescriptionIcon from '@mui/icons-material/Description';
import StarIcon from '@mui/icons-material/Star';
import RoomIcon from '@mui/icons-material/Room';
import EventNoteIcon from '@mui/icons-material/EventNote';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';
import Services from "./services.js";
import AuthService from "../Authentication/auth.service";
import { useNavigate } from 'react-router-dom';
import Alerts from "../../components/Alerts/alerts";
import PermissionAccess from '../../PermissionAccess/Access.js';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  margin: theme.spacing(3, 0),
  backgroundColor: theme.palette.background.default,
  boxShadow: theme.shadows[3],
  borderRadius: theme.shape.borderRadius * 2,
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.contrastText,
}));

const ImageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(2),
  marginTop: theme.spacing(2),
}));

const MemberImage = styled(CardMedia)(({ theme }) => ({
  width: 200,
  height: 200,
  objectFit: 'cover',
  borderRadius: '50%',
  border: `4px solid ${theme.palette.primary.main}`,
  boxShadow: theme.shadows[3],
}));

const DocumentImage = styled(CardMedia)(({ theme }) => ({
  width: 150,
  height: 150,
  objectFit: 'cover',
  cursor: 'pointer',
  borderRadius: theme.shape.borderRadius,
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: theme.shadows[5],
  },
}));

const StyledModal = styled(Modal)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const ModalContent = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  border: '2px solid #000',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  maxWidth: '90vw',
  maxHeight: '90vh',
  overflow: 'auto',
}));


const MemberDetailsComponent = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { state: Member } = location;
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const navigate = useNavigate();
  const { authData } = PermissionAccess();

  const handleAddressClick = () => {
    if (Member.Address) {
      const addressText = encodeURIComponent(Member.Address);
      const mapsURL = `https://www.google.com/maps/search/?api=1&query=${addressText}`;
      window.open(mapsURL, '_blank', 'noopener,noreferrer');
    } else {
      AlertService.swalErrorAlert("No valid address available");
    }
  };

  const handleApproval = async (approve) => {
    setLoading(true);
    try {
      const response = await Services.updateMemberApproval(Member._id, approve, authData.sessionID);
      if (response.data.status) {
        Member.status = approve ? "Active" : "Inactive";
        Alerts.swalSuccessAlert(approve ? "Member approved successfully" : "Member disapproved");
        // Refresh Member data or navigate back
      } else {
        Alerts.swalErrorAlert(response.data.message || "Error updating Member approval status");
      }
    } catch (error) {
      Alerts.swalErrorAlert("Network operation failed");
    } finally {
      setLoading(false);
    }
  };

  const renderInfoRow = (icon, label, value) => (
    <TableRow>
      <StyledTableCell>
        <Box display="flex" alignItems="center">
          {icon}
          <Typography variant="body1" style={{ marginLeft: 8 }}>{t(label)}</Typography>
        </Box>
      </StyledTableCell>
      <TableCell>{value}</TableCell>
    </TableRow>
  );

  return (
    <LoadingOverlay
      active={loading}
      styles={{ overlay: (base) => ({ ...base }) }}
      spinner={<DNALoader />}
    >
      <StyledPaper elevation={3}>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={24} md={12}>
            <TableContainer style={{ marginTop: 16 }}>
              <Table>
                <TableBody>
                  {renderInfoRow(<LocalHospitalIcon />, 'Name', Member.name)}
                  {renderInfoRow(<LocalHospitalIcon />, 'Registration Number', Member.RegistrationNumber)}
                  {renderInfoRow(<EmailIcon />, 'Email', Member.email)}
                  {renderInfoRow(<PhoneIcon />, 'Phone', Member.Phone)}
                  {renderInfoRow(<RoomIcon />, 'Address',
                    <Box component="span" onClick={handleAddressClick} style={{ cursor: 'pointer', color: 'blue', display: 'flex', alignItems: 'center' }}>
                      {Member.Address}
                      <IconButton size="small" color="primary">
                        <RoomIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  )}
                  {renderInfoRow(<PhoneIcon />, 'Status', <Chip
                    label={Member.status}
                    color={Member.status === 'Active' ? 'success' : 'error'}
                    size="small"
                    style={{ marginTop: 8 }}
                  />
                  )}

                </TableBody>
              </Table>

            </TableContainer>
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="center" mt={5}>
          {Member.status === "Inactive" ?
            <Button
              variant="contained"
              color="success"
              onClick={() => handleApproval(true)}
              style={{ marginRight: 16 }}
            >
              Approve Member
            </Button>
            :
            <Button
              variant="contained"
              color="error"
              onClick={() => handleApproval(false)}
            >
              Disapprove Member
            </Button>
          }
        </Box>
      </StyledPaper>
    </LoadingOverlay>
  );
};

export default MemberDetailsComponent;
