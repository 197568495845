import React, { useState } from "react";
import { Button, FormGroup, Label, Input, Modal } from "reactstrap";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import { Chip } from "@mui/material";
import Alerts from "../../components/Alerts/alerts";
import axios from "axios";

const StatusUpdateSection = ({ member, Status, onUpdate }) => {
  const [statusModal, setStatusModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(Status);

  const toggleStatusModal = () => setStatusModal(!statusModal);

  const handleStatusChange = async () => {
    if (!selectedStatus) {
      Alerts.swalErrorAlert("Please select a valid status.");
      return;
    }

    try {
      onUpdate(member._id, selectedStatus);
      toggleStatusModal();
    } catch (error) {
      Alerts.swalErrorAlert("An error occurred while updating the status.");
    }
  };

  return (
    <>
      <Chip
        label={Status}
        size="small"
        onClick={toggleStatusModal}
        sx={{
          cursor: "pointer",
          backgroundColor:
            Status === "Active" ? "#dcfce7" :
              Status === "Inactive" ? "#bfdbfe" :
                Status === "Suspended" ? "#fef3c7" :
                  "#fee2e2",
          color:
            Status === "Active" ? "#166534" :
              Status === "Inactive" ? "#1e40af" :
                Status === "Suspended" ? "#92400e" :
                  "#991b1b",
          border:
            Status === "Active" ? "1px solid #bbf7d0" :
              Status === "Inactive" ? "1px solid #93c5fd" :
                Status === "Suspended" ? "1px solid #fde68a" :
                  "1px solid #fecaca",
          fontWeight: "500",
        }}
      />

      <Modal isOpen={statusModal} toggle={toggleStatusModal}>
        <div className="modal-header">
          <h5 className="modal-title">
            Update Status
            <Tooltip
              title={
                <>
                  <strong>Active:</strong> User is currently active and can access the system. <br />
                  <strong>Inactive:</strong> User is inactive and may need to be reactivated. <br />
                  <strong>Suspended:</strong> User access is temporarily restricted due to policy violations. <br />
                  <strong>Terminated:</strong> User account has been permanently removed from the system.
                </>
              }
              arrow
            >
              <InfoIcon sx={{ fontSize: "18px", color: "gray", cursor: "pointer", marginLeft: "5px" }} />
            </Tooltip>
          </h5>

          <button type="button" className="btn-close" onClick={toggleStatusModal}></button>
        </div>
        <div className="modal-body">
          <FormGroup>
            <Label for="statusSelect">Select Status</Label>
            <Input
              type="select"
              name="status"
              id="statusSelect"
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
            >
              <option value="">Select Status</option>
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
              <option value="Suspended">Suspended</option>
              <option value="Terminated">Terminated</option>
            </Input>
          </FormGroup>
        </div>
        <div className="modal-footer">
          <Button color="secondary" onClick={toggleStatusModal}>
            Cancel
          </Button>
          <Button color="primary" onClick={handleStatusChange}>
            Update
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default StatusUpdateSection;
