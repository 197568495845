import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, Navigate } from "react-router-dom";
import { Row, Col, FormGroup, Input, Button, Label, Modal, ModalHeader, ModalBody, Card, CardBody } from "reactstrap";
import Services from "./services";
import AuthService from "../Authentication/auth.service";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import Alerts from "../../components/Alerts/alerts";
import PermissionAccess from '../../PermissionAccess/Access.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroup, faPercent, faUsers } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

const StyledModal = styled(Modal)`
  .modal-content {
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
`;

const StyledCard = styled(Card)`
  border: none;
  box-shadow: none;
`;

const FormLabel = styled(Label)`
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  
  .icon {
    color: var(--buttons-color, #30b1ff);
  }
`;


const StyledInput = styled(Input)`
  border-radius: 6px;
  border: 1px solid #e2e8f0;
  padding: 10px;
  font-size: 14px;
  transition: all 0.2s;
  
  &:focus {
    border-color: var(--buttons-color, #30b1ff);
    box-shadow: 0 0 0 2px rgba(var(--buttons-color, #30b1ff), 0.1);
  }

  &.is-invalid {
    border-color: #ef4444;
    &:focus {
      box-shadow: 0 0 0 2px rgba(239, 68, 68, 0.1);
    }
  }
`;

const ActionButton = styled(Button)`
  font-weight: 600;
  padding: 8px 20px;
  border-radius: 6px;
  transition: all 0.2s;
  
  &.btn-primary {
    background-color: var(--buttons-color, #30b1ff);
    border-color: var(--buttons-color, #30b1ff);
    color: white;

    &:hover {
      background-color: ${props => 
        (('--buttons-color', '#30b1ff'), -10)};
      border-color: ${props => 
        (('--buttons-color', '#30b1ff'), -10)};
      transform: translateY(-1px);
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    }
  }
`;


const UpdateMLMTier = ({ MLM_Tier, onSuccess, onCancel }) => {
  const [modal, setModal] = useState(true);
  const [mlmTier, setMlmTier] = useState({ ...MLM_Tier });
  const [loading, setLoading] = useState(false);
  const [loginNavigate, setLoginNavigate] = useState(false);
  const [validation, setValidation] = useState({
    level: true,
    percentage: true,
    referral_tier: true
  });
  const { authData } = PermissionAccess();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
  }, [i18n]);

  const handleMLMTierChange = (field, value) => {
    setMlmTier(prev => ({ ...prev, [field]: value }));
    validateField(field, value);
  };

  const validateField = (field, value) => {
    switch (field) {
      case 'level':
        setValidation(prev => ({
          ...prev,
          level: value.trim() !== ''
        }));
        break;
      case 'percentage':
        setValidation(prev => ({
          ...prev,
          percentage: value >= 0 && value <= 100
        }));
        break;
      case 'referral_tier':
        setValidation(prev => ({
          ...prev,
          referral_tier: value.trim() !== ''
        }));
        break;
      default:
        break;
    }
  };

  const validateForm = () => {
    const newValidation = {
      level: mlmTier.level?.trim() !== '',
      percentage: mlmTier.percentage >= 0 && mlmTier.percentage <= 100,
      referral_tier: mlmTier.referral_tier?.trim() !== ''
    };
    
    setValidation(newValidation);
    return Object.values(newValidation).every(v => v);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      Alerts.swalErrorAlert("Please fill all fields correctly.");
      return;
    }

    let payload = {
      level: mlmTier.level,
      percentage: parseFloat(mlmTier.percentage),
      referral_tier: mlmTier.referral_tier,
      business_id: mlmTier.business_id
    };
    
    setLoading(true);
    try {
      const response = await Services.UpdateMLMTier(authData.sessionID, mlmTier._id, payload);
      setLoading(false);
      if (response.data.status) {
        onSuccess();
        Alerts.swalSuccessAlert(response.data.message || "Tier Updated Successfully.").then(() => {
          setModal(false);
        });
      } else {
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          setLoginNavigate(true);
        } else {
          Alerts.swalErrorAlert(response.data.message || "Error: Can't Update Tier at the moment. Please try again later.");
        }
      }
    } catch (error) {
      setLoading(false);
      Alerts.swalErrorAlert("Network Operation failed.");
    }
  };

  if (loginNavigate) {
    return <Navigate to={'/login'} />;
  }

  return (
    <StyledModal size='lg' isOpen={modal} toggle={() => setModal(false)} backdrop={true}>
    <LoadingOverlay active={loading} spinner={<DNALoader />}>
      <ModalHeader className="bg-light">
        <div className="d-flex align-items-center">
          <FontAwesomeIcon 
            icon={faLayerGroup} 
            style={{ color: 'var(--buttons-color, #30b1ff)' }} 
            className="me-2" 
          />
          {t("Update Tier")}
        </div>
      </ModalHeader>
      <ModalBody className="px-4 py-4">
        <StyledCard>
          <CardBody>
            <form onSubmit={handleSubmit}>
              <Row>
                <Col md="12" className="mb-3">
                  <FormLabel>
                    <FontAwesomeIcon 
                      icon={faLayerGroup} 
                      className="icon"
                    />
                    {t("Level")}
                  </FormLabel>
                    <StyledInput
                      type="text"
                      value={mlmTier.level}
                      onChange={(e) => handleMLMTierChange('level', e.target.value)}
                      placeholder={t("Enter tier level (e.g., 1, 2, 3)")}
                      className={!validation.level ? 'is-invalid' : ''}
                      required
                    />
                    {!validation.level && (
                      <small className="text-danger">Level must be a positive number</small>
                    )}
                  </Col>

                  <Col md="12" className="mb-3">
                  <FormLabel>
                    <FontAwesomeIcon 
                      icon={faPercent} 
                      className="icon"
                    />
                    {t("Percentage")}
                  </FormLabel>
                    <StyledInput
                      type="number"
                      min="0"
                      max="100"
                      step="0.01"
                      value={mlmTier.percentage}
                      onChange={(e) => handleMLMTierChange('percentage', e.target.value)}
                      placeholder={t("Enter percentage (0-100)")}
                      className={!validation.percentage ? 'is-invalid' : ''}
                      required
                    />
                    {!validation.percentage && (
                      <small className="text-danger">Percentage must be between 0 and 100</small>
                    )}
                  </Col>

                  <Col md="12" className="mb-4">
                  <FormLabel>
                    <FontAwesomeIcon 
                      icon={faUsers} 
                      className="icon"
                    />
                    {t("Referral Tier")}
                  </FormLabel>
                    <StyledInput
                      type="text"
                      value={mlmTier.referral_tier}
                      onChange={(e) => handleMLMTierChange('referral_tier', e.target.value)}
                      className={!validation.referral_tier ? 'is-invalid' : ''}
                      required
                    >
                      {/* <option value="">{t("Select Referral Tier")}</option>
                      <option value="Basic">Basic</option>
                      <option value="Silver">Silver</option>
                      <option value="Gold">Gold</option>
                      <option value="Platinum">Platinum</option>
                      <option value="Diamond">Diamond</option> */}
                    </StyledInput>
                    {!validation.referral_tier && (
                      <small className="text-danger">Please select a referral tier</small>
                    )}
                  </Col>
                </Row>

                <div className="d-flex justify-content-end gap-2 mt-4">
                <ActionButton
                  type="button"
                  color="secondary"
                  onClick={() => { setModal(false); onCancel(); }}
                >
                  {t("Cancel")}
                </ActionButton>
                <ActionButton 
                  type="submit" 
                  color="primary"
                  style={{
                    backgroundColor: 'var(--buttons-color, #30b1ff)',
                    borderColor: 'var(--buttons-color, #30b1ff)'
                  }}
                >
                  {t("Update Tier")}
                </ActionButton>
              </div>
            </form>
          </CardBody>
        </StyledCard>
      </ModalBody>
    </LoadingOverlay>
  </StyledModal>
  );
};

export default UpdateMLMTier;