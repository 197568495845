import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Input, Button, Form, FormGroup, Label, Table } from 'reactstrap';
import { FaArrowLeft } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import DashboardService from "../dashboard.service";
import Alerts from "../../../components/Alerts/alerts";
import { useTranslation } from 'react-i18next';
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import moment from 'moment';

const ViewInvoice = () => {
  const [invoiceDetails, setInvoiceDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    fetchInvoiceDetails();
  }, [id]);

  const fetchInvoiceDetails = async () => {
    setLoading(true);
    try {
      const payload = {
        functionName: "getUserInvoices",
        postData: { _id: id }
      };
      const response = await DashboardService.getUserInvoices(payload);
      if (response.data.status) {
        setInvoiceDetails(response?.data?.data?.[0]);
      } else {
        Alerts.swalErrorAlert(response.data.message || t("Failed to fetch invoice details"));
      }
    } catch (error) {
      console.error("Error fetching invoice details:", error);
      Alerts.swalErrorAlert(t("An error occurred while fetching invoice details"));
    } finally {
      setLoading(false);
    }
  };

  const handlePayNow = async () => {
    try {
      const payload = {
        functionName: "PayNow",
        postData: { _id: id }
      };
      const response = await DashboardService.AcceptRFQ(payload);
      if (response.data.status) {
        Alerts.swalSuccessAlert(t("Payment successful"));
        fetchInvoiceDetails();
      } else {
        Alerts.swalErrorAlert(response.data.message || t("Payment failed"));
      }
    } catch (error) {
      console.error("Error processing payment:", error);
      Alerts.swalErrorAlert(t("An error occurred while processing the payment"));
    }
  };

  const calculateTotals = (items) => {
    const subtotal = items?.reduce((total, item) => {
      const itemTotal = item.selectedBatches.reduce((sum, batch) => sum + (batch.quantity * item.itemDetails.price), 0);
      return total + itemTotal;
    }, 0);
    const discount = invoiceDetails?.discount || 0;
    const discountAmount = discount ? (discount / 100) * subtotal : 0;
    const totalAmount = subtotal - discountAmount;
    return { subtotal, discountPercentage: discountAmount, totalAmount };
  };

  const renderItemRows = (items) => {
    let rows = [];
    
    // Group items by category
    const groupedItems = items?.reduce((acc, item) => {
      const category = item?.categoryDetails?.Name || 'Uncategorized';
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(item);
      return acc;
    }, {});
  
    // Iterate through each category
    Object.entries(groupedItems).forEach(([category, categoryItems]) => {
      let isFirstItemInCategory = true;
  
      categoryItems.forEach((item, itemIndex) => {
        const batches = item?.selectedBatches || [{}];
        
        batches.forEach((batch, batchIndex) => {
          rows.push(
            <tr key={`${category}-${itemIndex}-${batchIndex}`}>
              {isFirstItemInCategory && (
                <td rowSpan={categoryItems.reduce((sum, item) => sum + (item.selectedBatches?.length || 1), 0)}>
                  {category}
                </td>
              )}
              {batchIndex === 0 && (
                <>
                  <td rowSpan={batches.length}>
                    <div className="d-flex align-items-center">
                      {item?.itemDetails?.images && (
                        <img 
                          src={item?.itemDetails?.images[(item?.itemDetails?.index - 1) || 0]} 
                          alt={item?.itemDetails?.item_name} 
                          className="item-image me-2" 
                        />
                      )}
                      <span>{item?.itemDetails?.item_name}</span>
                    </div>
                  </td>
                  <td rowSpan={batches.length}>{item?.quantity}</td>
                  <td rowSpan={batches.length}>{`RM ${parseFloat(item?.price).toFixed(2)}`}</td>
                </>
              )}
              <td>{batch.batch_number || '-'}</td>
              <td>{batch.expiry ? moment(batch.expiry).format('DD-MM-YYYY') : '-'}</td>
              <td>{`RM ${((batch.quantity || 0) * (item?.price || 0)).toFixed(2)}`}</td>
            </tr>
          );
  
          if (batchIndex === 0) {
            isFirstItemInCategory = false;
          }
        });
      });
    });
  
    return rows;
  };


  return (
    <div className="app-main__outer">
      <div className="container mt-4">
        <div className="header">
          <Button color="link" className="p-0 text-decoration-none" onClick={() => navigate(-1)}>
            <FaArrowLeft className="me-2" />
            <span className="text-dark fw-bold">{t("Invoice Details")}</span>
          </Button>
          <div className="actions">
            {invoiceDetails?.status === "paid" ? (
              <button className="accept-button" disabled>{t("Paid")}</button>
            ) : invoiceDetails?.status === "generated" ? (
              <button onClick={handlePayNow} className="accept-button">{t("Generated (Pay Now)")}</button>
            ) : (
              <button disabled className="reject-button">{t("Not paid")}</button>
            )}
          </div>
        </div>
        <LoadingOverlay active={invoiceDetails ? false : true} spinner={<DNALoader />} >
          <Card className="rfq-card">
            <div className="p-4">
              <h4 className="mb-4 fw-bold">{t("Invoice Number")} #{invoiceDetails?.invoice_number}</h4>
              <Form>
                <Row className="mb-4">
                  <Col md={6}>
                    <FormGroup>
                      <Label for="invoice_date" className="text-muted mb-2">{t("Invoice Date")}</Label>
                      <Input type="text" id="invoice_date" className="form-select" readOnly value={new Date(invoiceDetails?.datetime_added).toLocaleDateString()} />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="order_number" className="text-muted mb-2">{t("Order number")}</Label>
                      <Input type="text" id="order_number" className="form-select" readOnly value={invoiceDetails?.order_details?.order_number} />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="invoice_status" className="text-muted mb-2">{t("Invoice Status")}</Label>
                      <Input type="text" id="invoice_status" className="form-select" readOnly value={invoiceDetails?.status} />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="order_status" className="text-muted mb-2">{t("Order Status")}</Label>
                      <Input type="text" id="order_status" className="form-select" readOnly value={invoiceDetails?.order_details?.status} />
                    </FormGroup>
                  </Col>
                </Row>

                <h5 className="mb-3 fw-bold">{t("List of Item(s)")}</h5>
                <div className="item-list mb-3">
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>{t("Category")}</th>
                        <th>{t("Item(s) - Brand")}</th>
                        <th>{t("Quantity")}</th>
                        <th>{t("Unit Price")}</th>
                        <th>{t("Batches")}</th>
                        <th>{t("Expiry Date")}</th>
                        <th>{t("Total Price")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {renderItemRows(invoiceDetails?.item || [])}
                    </tbody>
                  </Table>
                </div>

                <div className="totals text-end">
                  {(() => {
                    const { subtotal, discountPercentage, totalAmount } = calculateTotals(invoiceDetails?.item || []);
                    return (
                      <>
                        <div className="totals-row">
                          <span>{t("Subtotal")}:</span>
                          <span className="ms-3">RM {subtotal.toFixed(2)}</span>
                        </div>
                        <div className="totals-row">
                          <span>{t(`Discount(${invoiceDetails?.discount || 0}%)`)}:</span>
                          <span className="ms-3">RM {discountPercentage.toFixed(2)}</span>
                        </div>
                        <div className="totals-row total-amount fw-bold">
                          <span>{t("Total Amount")}:</span>
                          <span className="ms-3">RM {totalAmount.toFixed(2)}</span>
                        </div>
                      </>
                    );
                  })()}
                </div>
              </Form>
            </div>
          </Card>
        </LoadingOverlay>
      </div>
      <style jsx>{`
        .app-main__outer {
          background-color: #f8f9fa;
          min-height: 100vh;
          padding-top: 20px;
        }
        .rfq-card {
          border-radius: 10px;
          box-shadow: 0 2px 10px rgba(0,0,0,0.05);
          border: none;
        }
        .form-select, .form-control {
          border-color: #e0e0e0;
          background-color: red;
          border-radius: 5px;
          padding: 10px;
        }
        .btn-light {
          background-color: #ffffff;
          border-color: #e0e0e0;
          color: #333;
        }
        .btn-primary {
          background-color: #3D607A;
          border-color: #3D607A;
        }
        .text-muted {
          color: #6c757d !important;
        }
        h4, h5 {
          color: #333;
        }
        .item-list {
          border-top: 1px solid #e0e0e0;
          border-bottom: 1px solid #e0e0e0;
          padding: 20px 0;
        }
        .item-image {
          width: 40px;
          height: 40px;
          object-fit: cover;
          border-radius: 5px;
        }
        .totals {
          margin-top: 20px;
        }
        .totals-row {
          display: flex;
          justify-content: flex-end;
          margin-bottom: 5px;
        }
        .total-amount {
          font-weight: bold;
        }
        .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;
        }
        .back-button {
          background: none;
          border: none;
          font-size: 20px;
          cursor: pointer;
        }
        .rfq-id {
          font-weight: bold;
          font-size: 1.5rem;
        }
        .actions {
          display: flex;
          gap: 10px;
        }
        .reject-button,
        .accept-button {
          padding: 8px 16px;
          border: none;
          border-radius: 5px;
          cursor: pointer;
        }
        .reject-button {
          background-color: #ffdddd;
          color: #d9534f;
        }
        .accept-button {
          background-color: #5cb85c;
          color: #fff;
        }

      `}</style>
    </div>
  );
};

export default ViewInvoice;