import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, Navigate } from "react-router-dom";
import {
  Row, Col, FormGroup, Input, Button, Label,
  Modal, ModalHeader, ModalBody, Card, CardBody
} from "reactstrap";
import Services from "./services";
import AuthService from "../Authentication/auth.service";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import Alerts from "../../components/Alerts/alerts";
import PermissionAccess from '../../PermissionAccess/Access.js';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHeadset,
  faUser,
  faInfoCircle,
  faList,
  faImage,
  faCircle
} from '@fortawesome/free-solid-svg-icons';
import CustomerService from "../../pages/Finance/Invoices/Invoices.service";

const StyledModal = styled(Modal)`
  .modal-content {
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
`;

const StyledCard = styled(Card)`
  border: none;
  box-shadow: none;
`;

const FormLabel = styled(Label)`
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledInput = styled(Input)`
  border-radius: 6px;
  border: 1px solid #e2e8f0;
  padding: 10px;
  font-size: 14px;
  transition: all 0.2s;
  
  &:focus {
    border-color: #3B82F6;
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.1);
  }
`;

const StyledTextArea = styled(Input)`
  min-height: 120px;
  resize: vertical;
`;

const FileUploadContainer = styled.div`
  border: 2px dashed #e2e8f0;
  border-radius: 6px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s;
  
  &:hover {
    border-color: #3B82F6;
    background-color: rgba(59, 130, 246, 0.05);
  }
`;

const FilePreview = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
`;

const PreviewItem = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #e2e8f0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  button {
    position: absolute;
    top: 4px;
    right: 4px;
    background: rgba(255, 255, 255, 0.9);
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    
    &:hover {
      background: white;
    }
  }
`;

const ActionButton = styled(Button)`
  font-weight: 600;
  padding: 8px 20px;
  border-radius: 6px;
  transition: all 0.2s;
  color: white;
  background: var(--buttons-color, #30b1ff);
  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
`;

const CancelButton = styled(Button)`
  font-weight: 600;
  padding: 8px 20px;
  border-radius: 6px;
  transition: all 0.2s;
  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
`;

const AddCustomerSupport = ({ onSuccess }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [ticketData, setTicketData] = useState({
    member: '',
    summary: '',
    type: '',
    details: '',
    status: 'Open'
  });
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [previewUrls, setPreviewUrls] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loginNavigate, setLoginNavigate] = useState(false);
  const [Fetched_Businesses, setFetched_Businesses] = useState([]);
  const { authData, accessPages, super_admin, current_module } = PermissionAccess();
  const navigate = useNavigate();
  const [Users, setUsers] = useState([]);
  const { t, i18n } = useTranslation();
  const modalRef = useRef(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    Fetch_Businesses();
    fetchMembers();
  }, []);


  function fetchMembers() {
    var getData = {
      functionName: "GetInvoiceTo",
      postData: {
        sort: { _id: -1 },
        offset: 0,
        limit: 1000,
        filter: {
          user_type: localStorage.getItem("invoice_to")?.split(','),
        },
        TableName: 'users',
        TableField: localStorage.getItem("invoice_to_field")
      },
    };
    CustomerService.GetInvoiceTo(getData, authData.sessionID).then((response) => {
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        if (response.data) {
          setUsers(response.data.data);

        }
      }
    },
      (error) => {
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  function Fetch_Businesses() {
    setLoading(true);
    Services.GetBusinesses(authData.sessionID).then(
      (response) => {
        setLoading(false);
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        } else {
          const business = response.data.data[0];
          setFetched_Businesses([business]);
        }
      },
      (error) => {
        setLoading(false);
        Alerts.swalErrorAlert(error.response?.data || t("Server Error, Please try again later."));
      }
    );
  }

  const toggle = () => {
    setModalOpen(!modalOpen);
    if (!modalOpen) {
      setTicketData({
        member: '',
        summary: '',
        type: '',
        details: '',
        status: 'Open'
      });
      setSelectedFiles([]);
      setPreviewUrls([]);
    }
  };

  const handleInputChange = (field, value) => {
    setTicketData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleFileSelect = (e) => {
    const files = Array.from(e.target.files);
    if (files.length > 5) {
      Alerts.swalErrorAlert("Maximum 5 files can be uploaded");
      return;
    }

    setSelectedFiles(files);

    // Create preview URLs
    const newPreviewUrls = files.map(file => URL.createObjectURL(file));
    setPreviewUrls(newPreviewUrls);
  };

  const removeFile = (index) => {
    setSelectedFiles(prev => prev.filter((_, i) => i !== index));
    setPreviewUrls(prev => prev.filter((_, i) => i !== index));
  };

  const validateForm = () => {
    return (
      ticketData.member &&
      ticketData.summary &&
      ticketData.type &&
      ticketData.details
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      Alerts.swalErrorAlert("Please fill all required fields");
      return;
    }

    const formData = new FormData();
    Object.keys(ticketData).forEach(key => {
      formData.append(key, ticketData[key]);
    });
    formData.append('business_id', Fetched_Businesses[0]._id);

    selectedFiles.forEach(file => {
      formData.append('media', file);
    });

    setLoading(true);
    try {
      const response = await Services.AddCustomerSupport(authData.sessionID, formData);
      setLoading(false);
      if (response.data.status) {
        onSuccess();
        Alerts.swalSuccessAlert(response.data.message || "Support ticket created successfully").then(() => {
          toggle();
        });
      } else {
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          setLoginNavigate(true);
        } else {
          Alerts.swalErrorAlert(response.data.message || "Error creating support ticket");
        }
      }
    } catch (error) {
      setLoading(false);
      Alerts.swalErrorAlert("Network operation failed");
    }
  };

  if (loginNavigate) {
    return <Navigate to={'/login'} />;
  }

  return (
    <span className="d-inline-block float-end mb-2 me-2">
      <ActionButton onClick={toggle} className="btn-hover-shine ms-1">
        {t("Create Ticket")}
      </ActionButton>
      <StyledModal size='lg' isOpen={modalOpen} toggle={toggle} backdrop={true} ref={modalRef}>
        <LoadingOverlay active={loading} spinner={<DNALoader />}>
          <ModalHeader>
            <span className="d-flex align-items-center gap-2">
              <FontAwesomeIcon icon={faHeadset} className="text-primary" />
              {t("Create Support Ticket")}
            </span>
          </ModalHeader>
          <ModalBody>
            <StyledCard>
              <CardBody>
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col md="12" className="mb-3">
                      <FormLabel>
                        <FontAwesomeIcon icon={faUser} />
                        {t("Member")}
                      </FormLabel>
                      <StyledInput
                        type="select"
                        value={ticketData.member}
                        onChange={(e) => handleInputChange('member', e.target.value)}
                        placeholder={t("Enter member name or ID")}
                        required
                      >
                        <option value="">{t("Select Member")}</option>
                        {Users.map((user) => (
                        <option key={user._id} value={user._id}>{user.fullName}</option>
                        ))}
                      </StyledInput>
                    </Col>

                    <Col md="12" className="mb-3">
                      <FormLabel>
                        <FontAwesomeIcon icon={faInfoCircle} />
                        {t("Summary")}
                      </FormLabel>
                      <StyledInput
                        type="text"
                        value={ticketData.summary}
                        onChange={(e) => handleInputChange('summary', e.target.value)}
                        placeholder={t("Brief summary of the issue")}
                        required
                      />
                    </Col>

                    <Col md="12" className="mb-3">
                      <FormLabel>
                        <FontAwesomeIcon icon={faList} />
                        {t("Type")}
                      </FormLabel>
                      <StyledInput
                        type="select"
                        value={ticketData.type}
                        onChange={(e) => handleInputChange('type', e.target.value)}
                        required
                      >
                        <option value="">{t("Select ticket type")}</option>
                        <option value="Technical">{t("Technical")}</option>
                        <option value="Billing">{t("Billing")}</option>
                        <option value="Account">{t("Account")}</option>
                        <option value="General">{t("General")}</option>
                      </StyledInput>
                    </Col>

                    <Col md="12" className="mb-3">
                      <FormLabel>
                        <FontAwesomeIcon icon={faInfoCircle} />
                        {t("Details")}
                      </FormLabel>
                      <StyledTextArea
                        type="textarea"
                        value={ticketData.details}
                        onChange={(e) => handleInputChange('details', e.target.value)}
                        placeholder={t("Detailed description of the issue")}
                        required
                      />
                    </Col>

                    <Col md="12" className="mb-4">
                      <FormLabel>
                        <FontAwesomeIcon icon={faImage} />
                        {t("Attachments")}
                      </FormLabel>
                      <input
                        type="file"
                        ref={fileInputRef}
                        onChange={handleFileSelect}
                        multiple
                        accept="image/*"
                        style={{ display: 'none' }}
                      />
                      <FileUploadContainer onClick={() => fileInputRef.current.click()}>
                        <FontAwesomeIcon icon={faImage} size="2x" color="#3B82F6" />
                        <p className="mt-2 mb-0">{t("Click to upload images (max 5)")}</p>
                      </FileUploadContainer>

                      {previewUrls.length > 0 && (
                        <FilePreview>
                          {previewUrls.map((url, index) => (
                            <PreviewItem key={index}>
                              <img src={url} alt={`Preview ${index + 1}`} />
                              <button onClick={() => removeFile(index)} type="button">&times;</button>
                            </PreviewItem>
                          ))}
                        </FilePreview>
                      )}
                    </Col>
                  </Row>
                  <div className="text-end mt-4">
                    <CancelButton
                      type="button"
                      color="secondary"
                      onClick={toggle}
                      className="me-2"
                    >
                      {t("Cancel")}
                    </CancelButton>
                    <ActionButton type="submit">
                      {t("Create Ticket")}
                    </ActionButton>
                  </div>
                </form>
              </CardBody>
            </StyledCard>
          </ModalBody>
        </LoadingOverlay>
      </StyledModal>
    </span>
  );
};

export default AddCustomerSupport;