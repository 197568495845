import React, { useState } from 'react';
import { Card } from 'reactstrap';
import { FaCheckCircle } from 'react-icons/fa';
import { BsCalendar3, BsFillFileEarmarkTextFill } from 'react-icons/bs';
import { AiOutlineNumber } from 'react-icons/ai';
import { QRCode } from "react-qr-svg";
import { BsPersonFill } from 'react-icons/bs';
import { AiOutlineFileText } from 'react-icons/ai';
import { useLocation } from 'react-router-dom';
import InvoicePopup from './InvoicePopup.js';
import { MdQrCodeScanner } from 'react-icons/md';

const styles = {
  container: { fontFamily: 'Arial, sans-serif', padding: '20px' },
  header: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' },
  title: { fontSize: '24px', fontWeight: 'bold', color: '#333' },
  button: { padding: '8px 16px', backgroundColor: 'white', color: '#2563eb', border: '1px solid #2563eb', borderRadius: '4px', marginLeft: '10px', cursor: 'pointer' },
  cardContainer: { display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '16px', marginBottom: '20px' },
  card: { backgroundColor: 'white', padding: '16px', borderRadius: '10px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' },
  cardTitle: { fontSize: '14px', color: '#888', marginBottom: '8px' },
  cardValue: { fontSize: '14px', color: '#333', display: 'flex', alignItems: 'center' },
  icon: { marginRight: '15px', color: '#5c6bc0' },
  submitted: { color: '#5c6bc0' },
  invoiceInfo: { display: 'flex', justifyContent: 'space-between' },
  invoiceSummary: { backgroundColor: 'white', padding: '20px', borderRadius: '10px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', width: '70%' },
  summaryTitle: { marginBottom: '20px', color: '#333', fontWeight: 600 },
  invoiceTable: { width: '100%', borderCollapse: 'collapse', marginTop: '20px' },
  tableCell: { padding: '10px', borderBottom: '1px solid #ddd', textAlign: 'left' },
  totalAmount: { display: 'flex', justifyContent: 'space-between', marginTop: '20px' },
  invoiceContact: { width: '28%', backgroundColor: 'white', padding: '20px', borderRadius: '10px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' },
  qrCode: { textAlign: 'center', marginTop: '20px' },
};


const EInvoiceDetails = () => {
  const [isInvoicePopupOpen, setIsInvoicePopupOpen] = useState(false);
  const location = useLocation();
  const data = location.state
  console.log("data", data);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  const invoiceDetails = {
    invoiceNumber: data.invoice_number,
    status: data.lhdn_status,
    lastUpdated: '09/08/2024 (12:36:13)',
    eInvoiceDocumentCode: data.invoiceCodeNumber,
    eInvoiceType: data.invoice_type,
    buyerInformation: data.user_details[0].name,
    issuedOn: data.invoice_date,
    uniqueIdentifierNo: data.uuid,
    items: data.item,
    // items: [
    //   { classification: '003', description: 'Simple Stream', qty: 58, price: 'RM 35.00', amount: 'RM 2,030.00', disc: '-', tax: 'RM 121.80', total: 'RM 2,151.80' },
    //   // ... other items
    // ],
    total: data.total_amount,
  };
  const openEInvoice = () => {
    window.open(`https://preprod.myinvois.hasil.gov.my/${data.uuid}/share/${data.longID}`, '_blank');
  };


  return (
    <div className="app-main__outer">
      <div>
        <InvoicePopup
          isOpen={isInvoicePopupOpen}
          data={data}
          onClose={() => setIsInvoicePopupOpen(false)}
        />
      </div>
      <Card body>
        <div style={styles.container}>
          <div style={styles.header}>
            <h1 style={styles.title}>Invoice - {invoiceDetails.invoiceNumber}</h1>
            <div>
              <button onClick={() => setIsInvoicePopupOpen(true)} style={styles.button}>View invoice</button>
              {data.longID ?
                <button onClick={() => openEInvoice()} style={styles.button}>View E-invoice</button>
                :
                <button style={{ ...styles.button, backgroundColor: '#f3f4f6', color: '#9ca3af', border: 'none' }}>View E-invoice</button>
              }
              {/* <button onClick={() => openEInvoice()} style={styles.button}>
                <svg style={styles.buttonIcon} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                </svg>
                View E-invoice
              </button> */}
            </div>
          </div>

          <div style={styles.cardContainer}>
            <InfoCard icon={<FaCheckCircle />} title="Status" value={invoiceDetails.status} isDot={true} />
            <InfoCard icon={<BsCalendar3 />} title="Last Updated" value={invoiceDetails.lastUpdated} />
            <InfoCard icon={<BsFillFileEarmarkTextFill />} title="E-Invoice Document Code" value={invoiceDetails.eInvoiceDocumentCode} />
            <InfoCard icon={<AiOutlineFileText />} title="E-Invoice Type" value={invoiceDetails.eInvoiceType} />
          </div>

          <div style={styles.cardContainer}>
            <InfoCard icon={<BsPersonFill />} title="Buyer Information" value={invoiceDetails.buyerInformation} />
            <InfoCard icon={<BsCalendar3 />} title="Issued On" value={invoiceDetails.issuedOn} />
            <InfoCard icon={<AiOutlineNumber />} title="Unique Identifier No." value={invoiceDetails.uniqueIdentifierNo} />
            <InfoCard icon={<MdQrCodeScanner />} title="View Invoice" value={
              <QRCode
                value={`https://preprod.myinvois.hasil.gov.my/${data.uuid}/share/${data.longID}`}
                style={{ width: 50, height: 50 }} // Adjust size as needed
                level="Q" // Error correction level (L, M, Q, H)
              />

            } />
          </div>

          <div style={styles.invoiceInfo}>
            <div style={styles.invoiceSummary}>
              <div style={styles.totalAmount}>
                <span><h2 style={styles.summaryTitle}>Invoice</h2></span>
                <h3 style={{ fontSize: '12px', margin: 0, color: '#333' }}>Invoice No.<br /><h3 style={{}}>#{data.invoice_number}</h3></h3>

              </div>



              <h2 style={styles.summaryTitle}></h2>
              <p><strong>Billed To:</strong> {invoiceDetails.buyerInformation}</p>
              <p>Address / Contact Info</p>
              <p>{data.user_details[0].AddressLine1} , {data.user_details[0].AddressLine2} / {data.user_details[0].phone}</p>

              <table style={styles.invoiceTable}>
                <thead>
                  <tr>
                    <th style={styles.th}>No #</th>
                    <th style={styles.th}>Description</th>
                    <th style={styles.th}>Qty</th>
                    <th style={styles.th}>Price</th>
                    {/* <th style={styles.th}>Amount</th> */}
                    <th style={styles.th}>Disc.</th>
                    <th style={styles.th}>Tax</th>
                    <th style={styles.th}>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {invoiceDetails.items.map((item, index) => (
                    <tr key={index}>
                      <td style={styles.td}>{index}</td>
                      <td style={styles.td}>{item.description}</td>
                      <td style={styles.td}>{item.quantity}</td>
                      <td style={styles.td}>{item.rate}</td>
                      {/* <td style={styles.td}>{item.item_total ? item.item_total : item.amount}</td> */}
                      <td style={styles.td}>{item.discount_amount ? item.discount_amount : '--'}</td>
                      <td style={styles.td}>{item?.line_item_taxes?.[0]?.tax_amount ? item.line_item_taxes[0].tax_amount : item.tax ? item.tax : 0}</td>
                      <td style={styles.td}>{item.item_total ? item.item_total + item?.line_item_taxes?.[0]?.tax_amount : item.amount + item.tax}</td>
                    </tr>))}
                </tbody>
              </table>
              {data.discount_amount &&
                <div style={styles.totalAmount}>
                  <span>Discount ({data.currency})</span>
                  <h5 >{data.discount_amount}</h5>
                </div>
              }

              <div style={styles.totalAmount}>
                <span>Total ({data.currency})</span>
                <h3 style={{ margin: 0, color: '#333' }}>{invoiceDetails.total}</h3>
              </div>
            </div>

            <div style={styles.invoiceContact}>
              <div>
                <h2 style={{ marginTop: 0, fontWeight: 600 }}>{data.user_details[0].name}</h2>
                <p style={{ margin: '5px 0' }}>{data.user_details[0].AddressLine1} , {data.user_details[0].AddressLine2} / {data.user_details[0].phone}</p>
                <p style={{ margin: '5px 0' }}>{data.user_details[0].email}</p>
                <p style={{ margin: '5px 0' }}>{data.user_details[0].tin}</p>
                <p style={{ margin: '5px 0' }}>{data.user_details[0].id_type}</p>
              </div>

              <div style={{ marginTop: '20px' }}>
                <h3 style={{ marginTop: 0, fontWeight: 600, color: '#333' }}>Payment Instructions</h3>
                <p>{data.paymentMethods}</p>
              </div>

              <div style={{ marginTop: '20px' }}>
                <h3 style={{ marginTop: 0, fontWeight: 600, color: '#333' }}>Additional Notes</h3>
                <p>{data.comments}</p>
              </div>

              <div style={styles.qrCode}>
                <QRCode
                  value={`https://preprod.myinvois.hasil.gov.my/${data.uuid}/share/${data.longID}`}
                  style={{ width: 150, height: 150 }} 
                  level="Q" 
                />
                <p style={{ marginTop: '10px', color: '#333', fontWeight: 600 }}>View Invoice</p>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

const InfoCard = ({ icon, title, value, isSubmitted }) => (
  <div style={styles.card}>
    {icon}
    <div>
      <span style={styles.cardTitle}>{title}</span>
      <h3 style={{ ...styles.cardValue, ...(isSubmitted ? styles.submitted : {}) }}>{value}</h3>
    </div>
  </div>
);

export default EInvoiceDetails;