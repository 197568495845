import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, Navigate } from "react-router-dom";
import { Row, Col, FormGroup, Input, Button, Label, Modal, ModalHeader, ModalBody, Card, CardBody } from "reactstrap";
import Services from "./services";
import AuthService from "../Authentication/auth.service";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import Alerts from "../../components/Alerts/alerts";
import PermissionAccess from '../../PermissionAccess/Access.js';


const AddRanks = ({ onSuccess }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [ranks, setRanks] = useState([{
    name: '',
    left_org_members: '',
    right_org_members: '',
    max_members: '',
    start_counting: '',
    spv_reward_per_member: '',
    one_time_reward: '',
    infin8_spv_reward: '',
    maintenance: ''
  }]);
  const [loading, setLoading] = useState(false);
  const [loginNavigate, setLoginNavigate] = useState(false);
  const [Fetched_Businesses, setFetched_Businesses] = useState([]);
  const { authData, accessPages, super_admin, current_module } = PermissionAccess();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const modalRef = useRef(null);

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    Fetch_Businesses();
  }, []);


  function Fetch_Businesses() {
    setLoading(true);
    Services.GetBusinesses(authData.sessionID).then(
      (response) => {
        setLoading(false);
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        } else {
          const business = response.data.data[0];
          setFetched_Businesses([business]);
        }
      },
      (error) => {
        setLoading(false);
        const errContent = (error.response && error.response.data) || error.message || error.toString();
        Alerts.swalErrorAlert(errContent || t("Server Error, Please try again later."));
      }
    );
  }

  const toggle = () => {
    setModalOpen(!modalOpen);
    if (!modalOpen) {
      setRanks([{
        name: '',
        left_org_members: '',
        right_org_members: '',
        max_members: '',
        start_counting: '',
        spv_reward_per_member: '',
        one_time_reward: '',
        infin8_spv_reward: '',
        maintenance: ''
      }]);
    }
  };

  const handleRanksChange = (index, field, value) => {
    setRanks(prevRanks => prevRanks.map((rank, i) =>
      i === index ? { ...rank, [field]: value } : rank
    ));
  };

  const validateForm = () => {
    return ranks.every(rank =>
      rank.name &&
      rank.left_org_members &&
      rank.right_org_members &&
      rank.max_members &&
      rank.spv_reward_per_member &&
      rank.one_time_reward &&
      rank.infin8_spv_reward &&
      rank.maintenance
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      Alerts.swalErrorAlert("Please fill all required fields.");
      return;
    }

    let payload = {
      ...ranks[0],
      business_id: Fetched_Businesses[0]._id,
      status: "Active"
    };

    setLoading(true);
    try {
      const response = await Services.AddRanks(authData.sessionID, payload);
      setLoading(false);
      if (response.data.status) {
        onSuccess();
        Alerts.swalSuccessAlert(response.data.message || "Rank Added Successfully.").then(() => {
          toggle();
        });
      } else {
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          setLoginNavigate(true);
        } else {
          Alerts.swalErrorAlert(response.data.message || "Error: Can't Add Rank at the moment. Please try again later.");
        }
      }
    } catch (error) {
      setLoading(false);
      Alerts.swalErrorAlert("Network Operation failed.");
    }
  };

  if (loginNavigate) {
    return <Navigate to={'/login'} />;
  }

  return (
    <span className="d-inline-block float-end mb-2 me-2">
      <Button color="success" onClick={toggle} className="btn-hover-shine ms-1">
        {t("Add New")}
      </Button>
      <Modal size='lg' isOpen={modalOpen} toggle={toggle} backdrop={true} ref={modalRef}>
        <LoadingOverlay active={loading} spinner={<DNALoader />}>
          <ModalHeader>{t("Add Rank")}</ModalHeader>
          <ModalBody>
            <Card className="main-card mb-3">
              <CardBody>
                <form onSubmit={handleSubmit}>
                  {ranks.map((rank, index) => (
                    <FormGroup key={index} className="mb-3">
                      <Row>
                        <Col md="12" className="mb-3">
                          <Label>{t("Rank Category")} *</Label>
                          <Input
                            type="text"
                            value={rank.name}
                            placeholder="e.g. Infin8 Starter Rank"
                            onChange={(e) => handleRanksChange(index, 'name', e.target.value)}
                            required
                          />
                        </Col>
                        <Col md="6" className="mb-3">
                          <Label>{t("Required Members on Left Org")} *</Label>
                          <Input
                            type="number"
                            value={rank.left_org_members}
                            onChange={(e) => handleRanksChange(index, 'left_org_members', e.target.value)}
                            placeholder="e.g. 500"
                            required
                          />
                        </Col>
                        <Col md="6" className="mb-3">
                          <Label>{t("Required Members on Right Org")}</Label>
                          <Input
                            type="number"
                            value={rank.right_org_members}
                            onChange={(e) => handleRanksChange(index, 'right_org_members', e.target.value)}
                            placeholder="e.g. 500"
                          />
                        </Col>
                        <Col md="6" className="mb-3">
                          <Label>{t("Max Members")}</Label>
                          <Input
                            type="number"
                            value={rank.max_members}
                            onChange={(e) => handleRanksChange(index, 'max_members', e.target.value)}
                            placeholder="e.g. 300,000"
                          />
                        </Col>
                        <Col md="6" className="mb-3">
                          <Label>{t("Start of Counting")}</Label>
                          <Input
                            type="text"
                            value={rank.start_counting}
                            onChange={(e) => handleRanksChange(index, 'start_counting', e.target.value)}
                            placeholder="Time of Promotion"
                          />
                        </Col>
                        <Col md="6" className="mb-3">
                          <Label>{t("SPV Reward Per Member")}</Label>
                          <Input
                            type="number"
                            step="0.1"
                            value={rank.spv_reward_per_member}
                            onChange={(e) => handleRanksChange(index, 'spv_reward_per_member', e.target.value)}
                            placeholder="e.g. 0.2"
                          />
                        </Col>
                        <Col md="6" className="mb-3">
                          <Label>{t("One Time Reward (DPV)")}</Label>
                          <Input
                            type="number"
                            value={rank.one_time_reward}
                            onChange={(e) => handleRanksChange(index, 'one_time_reward', e.target.value)}
                            placeholder="e.g. 150"
                          />
                        </Col>
                        <Col md="6" className="mb-3">
                          <Label>{t("Infin8 SPV Reward")}</Label>
                          <Input
                            type="textarea"
                            value={rank.infin8_spv_reward}
                            onChange={(e) => handleRanksChange(index, 'infin8_spv_reward', e.target.value)}
                            placeholder="e.g. 0.2 SPV to given across all members"
                          />
                        </Col>
                        <Col md="6" className="mb-3">
                          <Label>{t("Maintenance")}</Label>
                          <Input
                            type="textarea"
                            value={rank.maintenance}
                            onChange={(e) => handleRanksChange(index, 'maintenance', e.target.value)}
                            placeholder="e.g. Monthly personal repurchase worth at least 25 DPV"
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                  ))}
                  <div className="text-center">
                    <Button type="submit" color="success" size="lg" className="me-3">
                      {t("Save")}
                    </Button>
                    <Button type="button" color="secondary" size="lg" onClick={toggle}>
                      {t("Cancel")}
                    </Button>
                  </div>
                </form>
              </CardBody>
            </Card>
          </ModalBody>
        </LoadingOverlay>
      </Modal>
    </span>
  );
};

export default AddRanks;