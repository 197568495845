import axios from "axios";
import AuthService from "../Authentication/auth.service";


const API_URL  = process.env.REACT_APP_BASEURL;
const storeduser = JSON.parse(localStorage.getItem("user"));

const REACT_APP_LOGINURL  = process.env.REACT_APP_LOGINURL;


const getAllUsers = (sessionID) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  var payload = {
    functionName: "GetUsers",
    postData: {},
  }
  return axios.post(API_URL, payload, {headers});
};

const getAllUsers_ = (sessionID) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  var payload = {
    functionName: "GetAllUsers",
    postData: {},
  }
  return axios.post(REACT_APP_LOGINURL+'UserModule/GetAllUsers', payload, {headers});
};


const addUser = (sessionID,payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(API_URL, payload, {headers});
};


const updateUser = (sessionID,payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(API_URL, payload, {headers});
};


const deleteUser = (sessionID,id) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  var payload = {
    functionName: "DeleteUser",
    postData: {
      "_id": id,
      "login_user_id": storeduser._id
    },
  }
  return axios.post(API_URL, payload, {headers});
};


const getSingleUser = (sessionID,id) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  var payload = {
    functionName: "GetUsers",
    postData: {
      "_id": id,
    },
  }
  return axios.post(API_URL, payload, {headers});
};


const sendPassword = (sessionID,id) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  var payload = {
    functionName: "GetNewUserPassword",
    postData: {
      "_id": id,
      // "login_user_id": storeduser._id
    },
  }
  return axios.post(API_URL, payload, {headers});
};


const UserService = {
  getAllUsers,
  addUser,
  getAllUsers_,
  updateUser,
  deleteUser,
  getSingleUser,
  sendPassword
};

export default UserService;
