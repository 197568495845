import React, { Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { Col } from "reactstrap";
import TabWarehouse from "./TabWarehouse";

const BusinessesSettings = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className="bg-light-gray bg-animation">
            <div className="d-flex justify-content-center align-items-center">
              <Col md="12" className="mx-auto app-login-box">
                <div className="app-page-title">
                  <div className="page-title-wrapper">
                    <div className="page-title-heading fw-bold">
                      <div className="page-title-icon">
                        <i className="pe-7s-news-paper icon-gradient bg-tempting-azure" />
                      </div>
                      <div>
                        {`${t("Warehouse")}`}
                        <div className="page-title-subheading">
                          {t("Add, Update & Delete Warehouse")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-center">
                  <div className="col-md-12">
                    <TabWarehouse />
                  </div>
                </div>

              </Col>
            </div>
          </div>
        </div>
      </div >
    </Fragment >
  );
};

export default BusinessesSettings;
