import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

const DashboardHeader = ({start, data}) => {
  return (
    <div className="dashboard-header mb-4">
      <Breadcrumb className="custom-breadcrumb">
        <BreadcrumbItem>
          <span className="text-muted">{start} Dashboard</span>
        </BreadcrumbItem>
        <BreadcrumbItem active>
          <span className="fw-bold">{data}</span>
        </BreadcrumbItem>
      </Breadcrumb>
      <style jsx>{`
        .custom-breadcrumb {
          font-size: 18px;
          background-color: transparent;
          padding: 0;
          margin: 0;
        }
        .custom-breadcrumb .breadcrumb-item + .breadcrumb-item::before {
          content: "/";
          color: #6c757d;
        }
        .custom-breadcrumb .breadcrumb-item {
          color: #6c757d;
        }
        .custom-breadcrumb .breadcrumb-item.active {
          color: #000;
        }
      `}</style>
    </div>
  );
};

export default DashboardHeader;