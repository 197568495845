import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";
import {
  Typography, Chip, Grid, Paper, Box, Table,
  TableBody, TableCell, TableContainer, TableRow,
  Button, ImageList, ImageListItem
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PersonIcon from '@mui/icons-material/Person';
import SubjectIcon from '@mui/icons-material/Subject';
import CategoryIcon from '@mui/icons-material/Category';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DescriptionIcon from '@mui/icons-material/Description';
import ImageIcon from '@mui/icons-material/Image';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import Services from "./services.js";
import AuthService from "../Authentication/auth.service";
import Alerts from "../../components/Alerts/alerts";
import PermissionAccess from '../../PermissionAccess/Access.js';
import { format } from 'date-fns';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  margin: theme.spacing(3, 0),
  backgroundColor: '#fff',
  boxShadow: '0 2px 12px rgba(0,0,0,0.08)',
  borderRadius: '12px',
  border: '1px solid #edf2f7'
}));

const SectionTitle = styled(Typography)({
  fontSize: '1.25rem',
  fontWeight: 600,
  color: '#2d3748',
  marginBottom: '1.5rem',
  paddingBottom: '1rem',
  borderBottom: '1px solid #edf2f7',
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  '& .icon': {
    color: 'var(--buttons-color, #30b1ff)'
  }
});

const StyledTableCell = styled(TableCell)({
  fontWeight: 600,
  color: '#4a5568',
  borderBottom: '1px solid #edf2f7',
  padding: '1rem',
  width: '200px',
  '& .icon': {
    color: 'var(--buttons-color, #30b1ff)'
  }
});

const ValueCell = styled(TableCell)({
  color: '#2d3748',
  borderBottom: '1px solid #edf2f7',
  padding: '1rem'
});

const StyledChip = styled(Chip)({
  fontWeight: 500,
  '&.type-chip': {
    backgroundColor: 'var(--buttons-color-light, rgba(48, 177, 255, 0.1))',
    color: 'var(--buttons-color, #30b1ff)',
    border: '1px solid var(--buttons-color, #30b1ff)'
  },
  '&.open': {
    backgroundColor: '#dcfce7',
    color: '#166534',
    border: '1px solid #bbf7d0'
  },
  '&.in-progress': {
    backgroundColor: '#fef3c7',
    color: '#92400e',
    border: '1px solid #fde68a'
  },
  '&.resolved': {
    backgroundColor: '#e0e7ff',
    color: '#3730a3',
    border: '1px solid #c7d2fe'
  },
  '&.closed': {
    backgroundColor: '#fee2e2',
    color: '#991b1b',
    border: '1px solid #fecaca'
  }
});

const DetailText = styled(Typography)({
  whiteSpace: 'pre-wrap',
  color: '#4a5568',
  lineHeight: 1.6
});

const MediaContainer = styled(Box)({
  marginTop: '1rem',
  '& img': {
    borderRadius: '8px',
    border: '1px solid #edf2f7'
  }
});

const ActionButton = styled(Button)({
  padding: '8px 24px',
  borderRadius: '8px',
  fontWeight: 600,
  textTransform: 'none',
  boxShadow: 'none',
  '&.resolve': {
    backgroundColor: '#22c55e',
    '&:hover': {
      backgroundColor: '#16a34a'
    }
  },
  '&.close': {
    backgroundColor: '#ef4444',
    '&:hover': {
      backgroundColor: '#dc2626'
    }
  }
});

const CustomerSupportDetailsComponent = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { state: ticket } = location;
  const [loading, setLoading] = useState(false);
  const { authData } = PermissionAccess();

  const handleStatusUpdate = async (newStatus) => {
    setLoading(true);
    try {
      const response = await Services.updateTicketStatus(ticket._id, newStatus, authData.sessionID);
      if (response.data.status) {
        ticket.status = newStatus;
        Alerts.swalSuccessAlert(`Ticket ${newStatus.toLowerCase()} successfully`);
      } else {
        Alerts.swalErrorAlert(response.data.message || "Error updating ticket status");
      }
    } catch (error) {
      Alerts.swalErrorAlert("Network operation failed");
    } finally {
      setLoading(false);
    }
  };

  const getStatusChipClass = (status) => {
    switch (status.toLowerCase()) {
      case 'open':
        return 'open';
      case 'in progress':
        return 'in-progress';
      case 'resolved':
        return 'resolved';
      case 'closed':
        return 'closed';
      default:
        return '';
    }
  };

  const renderInfoRow = (icon, label, value) => (
    <TableRow>
      <StyledTableCell>
        <Box display="flex" alignItems="center" gap={1}>
          {icon}
          <Typography>{t(label)}</Typography>
        </Box>
      </StyledTableCell>
      <ValueCell>{value}</ValueCell>
    </TableRow>
  );

  return (
    <LoadingOverlay active={loading} spinner={<DNALoader />}>
      <StyledPaper elevation={3}>
        <SectionTitle>
          <SubjectIcon className="icon" />
          {t("Support Ticket Details")}
        </SectionTitle>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TableContainer>
              <Table>
                <TableBody>
                  {/* Member Information */}
                  {renderInfoRow(
                    <PersonIcon className="icon" />,
                    'Member',
                    <Typography variant="body1" fontWeight={600}>
                      {ticket?.member?.[0]?.name || ticket?.member?.[0]?.email || 'N/A'}
                    </Typography>
                  )}

                  {/* Summary */}
                  {renderInfoRow(
                    <SubjectIcon className="icon" />,
                    'Summary',
                    <Typography variant="body1">
                      {ticket.summary}
                    </Typography>
                  )}

                  {/* Type */}
                  {renderInfoRow(
                    <CategoryIcon className="icon" />,
                    'Type',
                    <StyledChip
                      label={ticket.type}
                      className="type-chip"
                    />
                  )}

                  {/* Created On */}
                  {renderInfoRow(
                    <CalendarTodayIcon className="icon" />,
                    'Created On',
                    <Typography variant="body1">
                      {format(new Date(ticket.createdAt), 'dd-mm-yyyy HH:mm')}
                    </Typography>
                  )}

                  {/* Details */}
                  {renderInfoRow(
                    <DescriptionIcon className="icon" />,
                    'Details',
                    <DetailText>
                      {ticket.details}
                    </DetailText>
                  )}

                  {/* Media */}
                  {ticket?.media && ticket?.media?.length > 0 && renderInfoRow(
                    <ImageIcon className="icon" />,
                    'Media',
                    <MediaContainer>
                      <ImageList sx={{ width: 500 }} cols={3} rowHeight={164}>
                        {ticket?.media?.map((item, index) => (
                          <ImageListItem key={index}>
                            <img
                              src={`${process.env.REACT_APP_IMAGEURL}${item}`}
                              alt={`Attachment ${index + 1}`}
                              loading="lazy"
                            />
                          </ImageListItem>
                        ))}
                      </ImageList>
                    </MediaContainer>
                  )}

                  {/* Status */}
                  {renderInfoRow(
                    <AssignmentTurnedInIcon className="icon" />,
                    'Status',
                    <StyledChip
                      label={ticket.status}
                      className={getStatusChipClass(ticket.status)}
                    />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>

        <Box display="flex" justifyContent="center" gap={2} mt={4}>
          {ticket.status !== "Resolved" && (
            <ActionButton
              variant="contained"
              className="resolve"
              onClick={() => handleStatusUpdate("Resolved")}
            >
              Mark as Resolved
            </ActionButton>
          )}
          {ticket.status !== "Closed" && (
            <ActionButton
              variant="contained"
              className="close"
              onClick={() => handleStatusUpdate("Closed")}
            >
              Close Ticket
            </ActionButton>
          )}
        </Box>
      </StyledPaper>
    </LoadingOverlay>
  );
};

export default CustomerSupportDetailsComponent;