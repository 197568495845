import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import { Card, CardBody } from "reactstrap";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";
import Alerts from "../../components/Alerts/alerts";
import ItemService from "./Item.service";
import AuthService from "../Authentication/auth.service";
import { useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { createGlobalStyle } from 'styled-components';
import PermissionAccess from '../../PermissionAccess/Access.js';

const imageStyle = {
  width: 'auto',
  height: '200px',
  objectFit: 'cover',
  margin: '0px auto',
};

const slideStyle = {};

const customSliderStyles = `
  .slick-prev,
  .slick-next {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    z-index: 1;
  }

  .slick-prev:hover,
  .slick-next:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }

  .slick-prev {
    left: 10px;
  }

  .slick-next {
    right: 10px;
  }

  .slick-prev:before,
  .slick-next:before {
    font-size: 20px;
    line-height: 1;
    opacity: 0.75;
    color: white;
  }
`;

const GlobalStyle = createGlobalStyle`
  ${customSliderStyles}
`;

const DriverInsertionForm = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const form = useRef();
  const [pre_params, setpre_params] = useState('');
  const [collectionlist, setcollectionlist] = useState([]);
  const [fieldlist, setfieldlist] = useState([]);
  const [item_name, setItem_name] = useState('');
  const [item_mpv, setItem_mpv] = useState('');

  const [Size, setSize] = useState('');
  const [Color, setColor] = useState('');
  const [SPV, setSPV] = useState('');
  const [DPV, setDPV] = useState('');

  const [item_code, setItem_code] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [selectedimages, setSelectedimages] = useState([]);
  const [errors, setErrors] = useState({});
  const { authData, accessPages, super_admin, current_module } = PermissionAccess();
  const [categoryError, setCategoryError] = useState('');
  const [filteredCategories, setFilteredCategories] = useState([]);

  // const handleFileChange = (event) => {
  //   const files = Array.from(event.target.files);
  //   const imageUrls = files.map(file => URL.createObjectURL(file));
  //   setSelectedimages(imageUrls);
  // };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedimages(files);
  };
  

  const validateForm = () => {
    let formErrors = {};

    if (!item_name.trim()) formErrors.item_name = "Product Name is required";
    if (!item_code.trim()) formErrors.item_code = "Model Name is required";
    if (!item_mpv.trim()) formErrors.item_code = "MPV is required";

    if (!SPV.trim()) formErrors.item_code = "SPV is required";
    if (!DPV.trim()) formErrors.item_code = "DPV is required";


    if (!description.trim()) formErrors.description = "Description is required";
    if (!price) formErrors.price = "Price is required";

    // Add category validation
    const categoryField = fieldlist.find(field => field.referal_name === 'category');
    if (categoryField) {
      const categoryValue = document.getElementById(categoryField.referal_name)?.value;
      if (!categoryValue) {
        formErrors[categoryField.referal_name] = "Category is required";
        setCategoryError("Category is required");
      } else {
        setCategoryError("");
      }
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const sliderStyle = {
    width: '100%',
    maxWidth: '900px',
    margin: '0 auto',
  }

  const ImageSlideshow = ({ images }) => {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      pauseOnHover: true,
      arrows: true,
    };

    return (
      <div style={sliderStyle}>
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index} style={slideStyle}>
              <img src={URL.createObjectURL(image)} alt={`Slide ${index + 1}`} style={imageStyle} />
            </div>
          ))}
        </Slider>
      </div>
    );
  };

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchDynamicData();
  }, []);

  const fetchDynamicData = async () => {
    try {
      const response = await ItemService.get_cruds_dynamic_data(authData.sessionID);
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      } else {
        const data = await response.data;
        var custom_fields = data;
        for (var i = 0; i < custom_fields.length; i++) {
          if (custom_fields[i].options_collection !== '') {
            const _id = authData.userData._id
            const optresponse = await ItemService.getoptions(custom_fields[i].options_collection, "", {}, 5000, authData.sessionID, _id);
            const optdata = await optresponse.data.data;
            var options = [];
            for (var j = 0; j < optdata.length; j++) {
              options.push({ label: optdata[j][custom_fields[i].Options_Field_Name], value: optdata[j]._id, business_id: optdata[j]?.business_id ? optdata[j]?.business_id : null })
            }
            custom_fields[i].options = options;
          }
          if (custom_fields[i].parent_col === pre_params.Parent_referal_name) {
            custom_fields[i].default_value = pre_params.Parentid
          } else {
            custom_fields[i].default_value = ""
          }
        }
        data[0].custom_fields = custom_fields;
        setcollectionlist(data[0]);
        setfieldlist(data[0].custom_fields);

        // Auto-select first business and set its categories
        const businessField = custom_fields.find(field => field.referal_name === 'business_id');
        if (businessField && businessField.options.length > 0) {
          const firstBusinessId = businessField.options[0].value;
          const categories = custom_fields.find(field => field.referal_name === 'category').options.filter(option => option.business_id.toString() === firstBusinessId);
          setFilteredCategories(categories);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };


  async function handleSubmit(e) {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
  
    const formData = new FormData();
    formData.append('functionName', "AddItems");
    formData.append('formId', collectionlist._id);
    formData.append('user_type_id', collectionlist._id);
    formData.append('item_name', item_name);
    formData.append('item_mpv', item_mpv);
    formData.append('Size', Size);
    formData.append('Color', Color);
    formData.append('SPV', SPV);
    formData.append('DPV', DPV);
    formData.append('item_code', item_code);
    formData.append('description', description);
    formData.append('price', price);
    formData.append('status', 'Active'); // Set status to Active by default
  
    // Append selected images to formData
    selectedimages.forEach((image, index) => {
      formData.append('images', image);
    });
  
    // Append dynamic fields to formData
    fieldlist.forEach(field => {
      if (field.show_in === 'Items List' && field.status === 'Active') {
        if (field.item_in_invoice === 'Both' || field.item_in_invoice === 'Items') {
          if (field.field_type === 'radio') {
            const elements1 = document.querySelectorAll('.prop' + field.referal_name + ':checked');
            var elementvalue = elements1.length > 0 ? elements1[0].value : null;
            formData.append(field.referal_name, elementvalue);
          } else if (field.field_type === 'checkbox') {
            const elements1 = document.querySelectorAll('.prop' + field.referal_name + ':checked');
            var elementvalue = elements1.length > 0 ? Array.from(elements1).map(element => element.value) : [];
            formData.append(field.referal_name, JSON.stringify(elementvalue));
          } else {
            var fieldvalue = document.getElementById(field.referal_name)?.value;
            formData.append(field.referal_name, fieldvalue);
          }
        }
      }
    });
  
    setLoading(true);
    try {
      const response = await ItemService.save_item(formData, authData.sessionID);
      setLoading(false);
      if (response.data.status) {
        navigate("/items-list");
        Alerts.swalSuccessAlert(response.data.message || "Data added successfully.");
      } else {
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        } else {
          Alerts.swalErrorAlert(response.data.message || "Error: Can't add data at the moment. Please try again later.");
        }
      }
    } catch (error) {
      setLoading(false);
      Alerts.swalErrorAlert("Network Operation failed.");
    }
  }

  return (
    <>
      <GlobalStyle />
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className="app-page-title">
            <div className="page-title-wrapper justify-content-left">
              <div className="page-title-heading fw-bold">
                <div className="page-title-icon">
                  <i className="pe-7s-plus icon-gradient bg-happy-green" />
                </div>
                <div>
                  {t("Add Items")}
                  <div className="page-title-subheading">
                    {t('Fill the form below to add Items')}.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center">
            <div className="col-md-6">
              <Card className="main-card mb-3">
                <CardBody>
                  <LoadingOverlay
                    tag="div"
                    active={loading}
                    styles={{ overlay: (base) => ({ ...base }) }}
                    spinner={<DNALoader />}
                  >
                    <Form onSubmit={handleSubmit} ref={form}>
                      <div className="row">
                        <div className="col-md-12 col-xl-12">
                          <div className="form-group">
                            <label htmlFor="item_name">{t("Product Name")} <span style={{ color: "red" }}>*</span></label>
                            <input
                              type='text'
                              value={item_name}
                              onChange={(e) => setItem_name(e.target.value)}
                              className={`form-control ${errors.item_name ? 'is-invalid' : ''}`}
                              placeholder={t("Product Name")}
                              name="item_name"
                              id="item_name"
                            />
                            {errors.item_name && <div className="invalid-feedback">{errors.item_name}</div>}
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-md-12 col-xl-12">
                          <div className="form-group">
                            <label htmlFor="item_code">{t("Model Name")} <span style={{ color: "red" }}>*</span></label>
                            <input
                              type='text'
                              value={item_code}
                              onChange={(e) => setItem_code(e.target.value)}
                              className={`form-control ${errors.item_code ? 'is-invalid' : ''}`}
                              placeholder={t("Model Name")}
                              name="item_code"
                              id="item_code"
                            />
                            {errors.item_code && <div className="invalid-feedback">{errors.item_code}</div>}
                          </div>
                        </div>
                      </div>
                      <br />

                      <div className="row">
                        <div className="col-md-12 col-xl-12">
                          <div className="form-group">
                            <label htmlFor="price">{t("Price")} <span style={{ color: "red" }}>*</span></label>
                            <input
                              value={price}
                              onChange={(e) => setPrice(e.target.value)}
                              type='number'
                              className={`form-control ${errors.price ? 'is-invalid' : ''}`}
                              placeholder={t("price")}
                              name="price"
                              id="price"
                            />
                            {errors.price && <div className="invalid-feedback">{errors.price}</div>}
                          </div>
                        </div>
                      </div>

                      <br />
                      <div className="row">
                        <div className="col-md-12 col-xl-12">
                          <div className="form-group">
                            <label htmlFor="item_mpv">{t("MPV")} <span style={{ color: "red" }}>*</span></label>
                            <input
                              value={item_mpv}
                              onChange={(e) => setItem_mpv(e.target.value)}
                              type='number'
                              className={`form-control ${errors.item_mpv ? 'is-invalid' : ''}`}
                              placeholder={t("Item MPV")}
                              name="item_mpv"
                              id="item_mpv"
                            />
                            {errors.item_mpv && <div className="invalid-feedback">{errors.item_mpv}</div>}
                          </div>
                        </div>
                      </div>
                      <br />


                      <div className="row">
                        <div className="col-md-12 col-xl-12">
                          <div className="form-group">
                            <label htmlFor="SPV">{t("SPV")} <span style={{ color: "red" }}>*</span></label>
                            <input
                              value={SPV}
                              onChange={(e) => setSPV(e.target.value)}
                              type='number'
                              className={`form-control ${errors.SPV ? 'is-invalid' : ''}`}
                              placeholder={t("Item SPV")}
                              name="SPV"
                              id="SPV"
                            />
                            {errors.SPV && <div className="invalid-feedback">{errors.SPV}</div>}
                          </div>
                        </div>
                      </div>
                      <br />

                      <div className="row">
                        <div className="col-md-12 col-xl-12">
                          <div className="form-group">
                            <label htmlFor="DPV">{t("DPV")} <span style={{ color: "red" }}>*</span></label>
                            <input
                              value={DPV}
                              onChange={(e) => setDPV(e.target.value)}
                              type='number'
                              className={`form-control ${errors.DPV ? 'is-invalid' : ''}`}
                              placeholder={t("Item DPV")}
                              name="DPV"
                              id="DPV"
                            />
                            {errors.DPV && <div className="invalid-feedback">{errors.DPV}</div>}
                          </div>
                        </div>
                      </div>
                      <br />

                      <div className="row">
                        <div className="col-md-12 col-xl-12">
                          <div className="form-group">
                            <label htmlFor="Size">{t("Size")} <span style={{ color: "red" }}>*</span></label>
                            <input
                              type='text'
                              value={Size}
                              onChange={(e) => setSize(e.target.value)}
                              className={`form-control ${errors.Size ? 'is-invalid' : ''}`}
                              placeholder={t("Size")}
                              name="Size"
                              id="Size"
                            />
                            {errors.Size && <div className="invalid-feedback">{errors.Size}</div>}
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-md-12 col-xl-12">
                          <div className="form-group">
                            <label htmlFor="Color">{t("Color")} <span style={{ color: "red" }}>*</span></label>
                            <input
                              type='text'
                              value={Color}
                              onChange={(e) => setColor(e.target.value)}
                              className={`form-control ${errors.Color ? 'is-invalid' : ''}`}
                              placeholder={t("Color")}
                              name="Color"
                              id="Color"
                            />
                            {errors.Color && <div className="invalid-feedback">{errors.Color}</div>}
                          </div>
                        </div>
                      </div>


                      <br />
                      {fieldlist && fieldlist.map((field, index) => {
                        if (field.show_in === 'Items List' && field.status === 'Active') {
                          if (field.item_in_invoice === 'Both' || field.item_in_invoice === 'Items') {


                            if (field.field_type === 'text' || field.field_type === 'email' || field.field_type === 'number' || field.field_type === 'password' || field.field_type === 'file') {
                              if (field.referal_name === 'images') {
                                return (
                                  <>
                                    <div className="row" key={index}>
                                      <div className="col-md-12 col-xl-12" >
                                        <div className="form-group" >
                                          <label htmlFor="name">{field.field_lable}
                                            <span style={{ color: "red" }}>*</span>
                                            {/* <span id={field.referal_name + "error-box"} style={{ color: "red" }}></span> */}
                                          </label>
                                          <input
                                            type='file'
                                            accept="image/*"
                                            multiple
                                            className={`form-control ${errors[field.referal_name] ? 'is-invalid' : ''}`}
                                            placeholder={field.field_lable}
                                            name={field.referal_name}
                                            id={field.referal_name}
                                            onChange={handleFileChange}
                                          />
                                          {errors[field.referal_name] && (
                                            <div className="invalid-feedback">{errors[field.referal_name]}</div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <br />
                                    {selectedimages.length > 0 && (
                                      <div className="row">
                                        <div className="col-md-12 col-xl-12">
                                          <ImageSlideshow images={selectedimages} />
                                        </div>
                                      </div>
                                    )}

                                  </>
                                )
                              } else {
                                return (
                                  <>
                                    <div className="row" key={index}>
                                      <div className="col-md-12 col-xl-12" >
                                        <div className="form-group" >
                                          <label htmlFor={field.referal_name}>{field.field_lable}
                                            <span style={{ color: "red" }}>*</span>
                                          </label>
                                          <input
                                            type={field.field_type}
                                            className={`form-control ${errors[field.referal_name] ? 'is-invalid' : ''}`}
                                            placeholder={field.field_lable}
                                            name={field.referal_name}
                                            id={field.referal_name}
                                          />
                                          {errors[field.referal_name] && (
                                            <div className="invalid-feedback">{errors[field.referal_name]}</div>
                                          )}

                                        </div>
                                      </div>
                                    </div>
                                    <br />
                                  </>
                                )
                              }
                            }
                            else if (field.field_type === 'dropdown') {
                              if (field.referal_name === 'category') {
                                return (
                                  <div className="row" key={index}>
                                    <div className="col-md-12 col-xl-12">
                                      <div className="form-group">
                                        <label htmlFor={field.referal_name}>{field.field_lable}
                                          <span style={{ color: 'red' }}>*</span>
                                        </label>
                                        <select
                                          name={field.referal_name}
                                          id={field.referal_name}
                                          className={`form-select form-control ${errors[field.referal_name] ? 'is-invalid' : ''}`}
                                        >
                                          <option disabled={false} value="">{t('--Choose an option--')}</option>
                                          {filteredCategories.map((option, optionsindex) => (

                                            <option key={field.referal_name + optionsindex} value={option.value}>
                                              {option.label}
                                            </option>
                                          ))}
                                        </select>
                                        {errors[field.referal_name] && (
                                          <div className="invalid-feedback">{errors[field.referal_name]}</div>
                                        )}
                                      </div>
                                      <br />
                                    </div>
                                  </div>
                                );
                              } else if (field.referal_name !== 'business_id' && field.referal_name !== 'status') {
                                return (
                                  <div className="row" key={index}>
                                    <div className="col-md-12 col-xl-12">
                                      <div className="form-group">
                                        <label htmlFor={field.referal_name}>{field.field_lable}
                                          <span style={{ color: 'red' }}>*</span>
                                        </label>
                                        <select
                                          name={field.referal_name}
                                          id={field.referal_name}
                                          className={`form-select form-control ${errors[field.referal_name] ? 'is-invalid' : ''}`}
                                        >
                                          <option disabled={false} value="">{t('--Choose an option--')}</option>
                                          {field.options.map((option, optionsindex) => (
                                            <option key={field.referal_name + optionsindex} value={option.value}>{option.label}</option>
                                          ))}
                                        </select>
                                        {errors[field.referal_name] && (
                                          <div className="invalid-feedback">{errors[field.referal_name]}</div>
                                        )}
                                      </div>
                                    </div>
                                    <br />
                                  </div>
                                );
                              }
                            }
                            else if (field.field_type === 'radio' || field.field_type === 'checkbox') {
                              return (
                                <>
                                  <div className="row" key={index}>
                                    <div className="col-md-12 col-xl-12" ><label htmlFor="name">{field.field_lable} <span id={field.referal_name + "error-box"} style={{ color: "red" }}></span></label></div>
                                    <br />
                                    <div className="col-md-12 col-xl-12" >
                                      <ul className="child mb-0 p-0">
                                        {field.options.map((option, optionsindex) => (
                                          <li className="form-check form-check-inline">
                                            <input className={"form-check-input prop" + field.referal_name}
                                              type={field.field_type}
                                              value={option.value} name={field.referal_name} id={field.referal_name + optionsindex}
                                            />
                                            <label className="form-check-label" htmlFor="users">
                                              {option.label}
                                            </label>
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  </div>
                                  <br />
                                  {errors[field.referal_name] && <div className="invalid-feedback">{errors[field.referal_name]}</div>}
                                </>
                              )
                            }
                            else {
                              return (
                                <>
                                  <div className="row" key={index}>
                                    <div className="col-md-12 col-xl-12" >
                                      <div className="form-group" >
                                        <label htmlFor="name">{field.field_lable} <span id={field.referal_name + "error-box"} style={{ color: "red" }}></span></label>
                                        <input type="text"
                                          className={`form-control ${errors[field.referal_name] ? 'is-invalid' : ''}`}
                                          placeholder={field.field_lable} name={field.referal_name} id={field.referal_name} />
                                        {errors[field.referal_name] && <div className="invalid-feedback">{errors[field.referal_name]}</div>}
                                      </div>
                                    </div>
                                  </div>
                                  <br />


                                </>
                              )
                            }
                          }
                        }
                      })}
                      <br />
                      <div className="row">
                        <div className="col-md-12 col-xl-12">
                          <div className="form-group">
                            <label htmlFor="description">{t("Description")} <span style={{ color: "red" }}>*</span></label>
                            <input
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                              type='text'
                              className={`form-control ${errors.description ? 'is-invalid' : ''}`}
                              placeholder={t("Description")}
                              name="description"
                              id="description"
                            />
                            {errors.description && <div className="invalid-feedback">{errors.description}</div>}
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-md-12 col-xl-12" >
                          <center>
                            <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-light btn-hover-shine me-3 mt-3">{t('Cancel')}</button>
                            <button className="btn btn-custom-color btn-hover-shine me-3 mt-3" >{t('Add data')}</button>
                          </center>
                        </div>
                      </div>
                    </Form>
                  </LoadingOverlay>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );


}

export default DriverInsertionForm;