import React, { useState } from 'react';
import InvoicePopup from './InvoicePopup.js';
import { Card } from 'reactstrap';
import { useLocation } from 'react-router-dom';

const styles = {
  container: { fontFamily: 'Arial, sans-serif', padding: '20px' },
  header: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' },
  title: { fontSize: '24px', fontWeight: 'bold', color: '#111827' },
  button: { padding: '8px 16px', backgroundColor: 'white', color: '#2563eb', border: '1px solid #2563eb', borderRadius: '4px', marginLeft: '10px', cursor: 'pointer', display: 'inline-flex', alignItems: 'center' },
  buttonIcon: { marginRight: '8px', width: '16px', height: '16px' },
  cardContainer: { display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '16px', marginBottom: '20px' },
  card: { backgroundColor: 'white', padding: '16px', borderRadius: '8px', boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)' },
  cardTitle: { fontSize: '14px', color: '#6b7280', marginBottom: '8px' },
  cardValue: { fontSize: '14px', color: '#111827', display: 'flex', alignItems: 'center' },
  statusDot: { width: '8px', height: '8px', borderRadius: '50%', backgroundColor: '#10b981', marginRight: '8px' },
  cardIcon: { marginRight: '8px', width: '16px', height: '16px', color: '#9ca3af' },
  alertIcon: { marginRight: '12px', width: '20px', height: '20px', color: '#38bdf8' },
  alertText: { color: '#0c4a6e' },
  tableContainer: { backgroundColor: 'white', borderRadius: '8px', boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)', overflowX: 'auto' },
  table: { width: '100%', borderCollapse: 'collapse' },
  th: { textAlign: 'left', padding: '12px 16px', borderBottom: '1px solid #e5e7eb', fontSize: '12px', fontWeight: 'medium', color: '#6b7280', textTransform: 'uppercase' },
  td: { padding: '12px 16px', borderBottom: '1px solid #e5e7eb', fontSize: '14px', color: '#111827' },
  footer: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px' },
  select: { padding: '4px 8px', border: '1px solid #d1d5db', borderRadius: '4px', marginRight: '8px' },
  pagination: { display: 'flex', alignItems: 'center' },
  paginationButton: { padding: '4px 8px', border: '1px solid #d1d5db', borderRadius: '4px', marginLeft: '4px', cursor: 'pointer' },
  activePaginationButton: { backgroundColor: '#2563eb', color: 'white', border: 'none' }
};
const EInvoiceDetails = () => {
  const location = useLocation();
  const data = location.state
  console.log("data", data);
  const [isInvoicePopupOpen, setIsInvoicePopupOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  const invoiceDetails = {
    invoiceNumber: data.invoice_number,
    status: data.lhdn_status,
    lastUpdated: '09/08/2024 (12:36:13)',
    eInvoiceDocumentCode: data.invoiceCodeNumber,
    eInvoiceType: data.invoice_type,
    buyerInformation: data.user_details[0].name,
    issuedOn: data.invoice_date,
    uniqueIdentifierNo: data.uuid,
    paymentMethod: 'Bank Transfer',
    items: data.item
  };

  const totalPages = Math.ceil(invoiceDetails.items.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = invoiceDetails.items.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const openEInvoice = () => {
    window.open(`https://preprod.myinvois.hasil.gov.my/${data.uuid}/share/${data.longID}`, '_blank');
  };


  return (
    <div className="app-main__outer">
      <div>
        <InvoicePopup
          isOpen={isInvoicePopupOpen}
          data={data}
          onClose={() => setIsInvoicePopupOpen(false)}
        />
      </div>
      <Card body>
        <div style={styles.container}>

          <div style={styles.header}>
            <h1 style={styles.title}>Invoice - {invoiceDetails.invoiceNumber}</h1>
            <div>
              <button onClick={() => setIsInvoicePopupOpen(true)} style={styles.button}>
                <svg style={styles.buttonIcon} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                </svg>
                View invoice
              </button>
              <button onClick={() => openEInvoice()}  style={styles.button}>
                <svg style={styles.buttonIcon} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                </svg>
                View E-invoice
              </button>
            </div>
          </div>

          <div style={styles.cardContainer}>
            <InfoCard style={{
              color: data?.lhdn_status === 'Valid' ? '#16DBCC' : data?.lhdn_status === 'Invalid' ? '#F16262' : data?.lhdn_status === 'Cancelled' ? 'red' : data?.lhdn_status === 'Submitted' ? '#50A6E4' : '#50A6E4'
            }} title="Status" value={data?.lhdn_status === 'Valid' ? 'Valid' : data?.lhdn_status === 'Invalid' ? 'Invalid' : data?.lhdn_status === 'Cancelled' ? 'Cancelled' : data?.lhdn_status === 'Submitted' ? 'Submitted' : data.lhdn_status} isDot={true} />
            <InfoCard title="Last Updated" value={invoiceDetails.lastUpdated} />
            <InfoCard title="E-Invoice Document Code" value={invoiceDetails.eInvoiceDocumentCode} />
            <InfoCard title="E-Invoice Type" value={invoiceDetails.eInvoiceType} />
          </div>

          <div style={styles.cardContainer}>
            <InfoCard title="Buyer Information" value={invoiceDetails.buyerInformation} />
            <InfoCard title="Issued On" value={invoiceDetails.issuedOn} />
            <InfoCard title="Unique Identifier No." value={invoiceDetails.uniqueIdentifierNo} />
            <InfoCard title="Payment Method" value={invoiceDetails.paymentMethod} />
          </div>

          <div style={{
            backgroundColor: '#e0f2fe',
            borderLeft: '4px solid #38bdf8',
            padding: '16px', marginBottom: '20px',
            display: 'flex', alignItems: 'center'
          }}>
            <svg style={styles.alertIcon} fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <span style={{
              color: data?.lhdn_status === 'Valid' ? '#16DBCC' : data?.lhdn_status === 'Invalid' ? '#F16262' : data?.lhdn_status === 'Cancelled' ? 'red' : data?.lhdn_status === 'Submitted' ? '#50A6E4' : '#50A6E4'
            }}>E-Invoice has {data?.lhdn_status === 'Valid' ? 'Valid' : data?.lhdn_status === 'Invalid' ? 'Invalid' : data?.lhdn_status === 'Cancelled' ? 'Cancelled' : data?.lhdn_status === 'Submitted' ? 'Submitted' : data.lhdn_status} for this invoice.</span>
          </div>

          <h2 style={{ ...styles.title, fontSize: '20px', marginBottom: '16px' }}>Items</h2>
          <div style={styles.tableContainer}>
            <table style={styles.table}>
              <thead>
                <tr>
                  <th style={styles.th}>No #</th>
                  <th style={styles.th}>Description</th>
                  <th style={styles.th}>Qty</th>
                  <th style={styles.th}>Price</th>
                  {/* <th style={styles.th}>Amount</th> */}
                  <th style={styles.th}>Disc.</th>
                  <th style={styles.th}>Tax</th>
                  <th style={styles.th}>Total</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((item, index) => (
                  <tr key={index}>
                    <td style={styles.td}>{index}</td>
                    <td style={styles.td}>{item.description}</td>
                    <td style={styles.td}>{item.quantity}</td>
                    <td style={styles.td}>{item.rate}</td>
                    {/* <td style={styles.td}>{item.item_total ? item.item_total : item.amount}</td> */}
                    <td style={styles.td}>{item.discount_amount ? item.discount_amount : '--'}</td>
                    <td style={styles.td}>{item?.line_item_taxes?.[0]?.tax_amount ? item.line_item_taxes[0].tax_amount : item.tax ? item.tax : 0}</td>
                    <td style={styles.td}>{item.item_total ? item.item_total + item?.line_item_taxes?.[0]?.tax_amount :  item.amount + item.tax}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div style={styles.footer}>
            <div>
              <select style={styles.select} value={itemsPerPage} onChange={handleItemsPerPageChange}>
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={25}>25</option>
              </select>
              <span style={{ fontSize: '14px', color: '#6b7280' }}>Rows per page</span>
            </div>
            <div style={styles.pagination}>
              <button
                style={styles.paginationButton}
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
              >
                &lt;
              </button>
              {[...Array(totalPages).keys()].map((number) => (
                <button
                  key={number + 1}
                  onClick={() => paginate(number + 1)}
                  style={{
                    ...styles.paginationButton,
                    ...(currentPage === number + 1 ? styles.activePaginationButton : {})
                  }}
                >
                  {number + 1}
                </button>
              ))}
              <button
                style={styles.paginationButton}
                onClick={() => paginate(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                &gt;
              </button>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

const InfoCard = ({ title, value, isDot }) => (
  <div style={styles.card}>
    <h3 style={styles.cardTitle}>{title}</h3>
    <div style={styles.cardValue}>
      {isDot ? (
        <div style={styles.statusDot}></div>
      ) : (
        <svg style={styles.cardIcon} fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
        </svg>
      )}
      <span style={isDot ? { color: '#10b981' } : {}}>{value}</span>
    </div>
  </div>
);

export default EInvoiceDetails;