import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Row, Col, FormGroup, Input, Button, Label, Modal, ModalHeader, ModalBody, Card, CardBody } from "reactstrap";
import ItemsService from "./Item.service";
import AuthService from "../Authentication/auth.service";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import Alerts from "../../components/Alerts/alerts";
import PermissionAccess from '../../PermissionAccess/Access.js';

const AddItemVariation = ({ onSuccess, state }) => {
    const [modal, setModal] = useState(false);
    const { i18n } = useTranslation();
    const [variations, setVariations] = useState([{ variation_id: '', status: '', price: '', variation_option_id: '' }]);
    const [Fetched_Variations, setFetched_Variations] = useState([]);
    const [Fetched_Variation_Options, setFetched_Variation_Options] = useState({});
    const [loading, setLoading] = useState(false);
    console.log('state', state);
    const { authData, accessPages, super_admin, current_module } = PermissionAccess();

    const [loginNavigate, setLoginNavigate] = useState(false);
    useEffect(() => {
        i18n.changeLanguage(localStorage.getItem('language'));
        fetchVariations();
    }, []);
    const { t } = useTranslation();
    const navigate = useNavigate();
    function fetchVariations() {
        setLoading(true);
        ItemsService.getAllVariations(authData.sessionID).then(
            (response) => {
                setLoading(false);
                console.log("Get Variations response:-", response);
                if (response.data.message === "Unauthorized") {
                    AuthService.logout();
                    navigate("/login");
                    window.location.reload();
                } else {
                    console.log(response.data);
                    setFetched_Variations(response.data.data);
                }
            },
            (error) => {
                setLoading(false);
                const errContent =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
                if (errContent) {
                    Alerts.swalErrorAlert(errContent);
                } else {
                    Alerts.swalErrorAlert(t("Server Error, Please try again later."));
                }
            }
        );
    }

    // function fetchVariationsTypes(index, variation_id) {
    //     setLoading(true);
    //     console.log("variation_id", variation_id, "index", variation_id);
    //     ItemsService.Get_Variation_sub_Types(index, variation_id, authData.sessionID).then(
    //         (response) => {
    //             setLoading(false);
    //             console.log("Get Variations response:-", response);
    //             if (response.data.message === "Unauthorized") {
    //                 AuthService.logout();
    //                 navigate("/login");
    //                 window.location.reload();
    //             } else {
    //                 console.log(response.data);
    //                 setFetched_Variations_Types(response.data.data);
    //             }
    //         },
    //         (error) => {
    //             setLoading(false);
    //             const errContent =
    //                 (error.response && error.response.data) ||
    //                 error.message ||
    //                 error.toString();
    //             if (errContent) {
    //                 Alerts.swalErrorAlert(errContent);
    //             } else {
    //                 Alerts.swalErrorAlert(t("Server Error, Please try again later."));
    //             }
    //         }
    //     );
    // }

    const toggle = () => {
        setModal(!modal);
        setVariations([{ variation_id: '', status: '', price: '', variation_option_id: '', price_option_id: '' }]);
    };

    const addVariationRow = () => {
        setVariations([...variations, { variation_id: '', status: '', price: '', variation_option_id: '', price_option_id: '' }]);
    };

    const removeVariationRow = (index) => {
        setVariations(variations.filter((_, i) => i !== index));
    };

    function fetchVariationOptions(index, variation_id) {
        setLoading(true);
        ItemsService.Get_Variation_sub_Types(index, variation_id, authData.sessionID).then(
            (response) => {
                setLoading(false);
                if (response.data.message === "Unauthorized") {
                    AuthService.logout();
                    navigate("/login");
                    window.location.reload();
                } else {
                    setFetched_Variation_Options(prevState => ({
                        ...prevState,
                        [index]: response.data.data
                    }));
                }
            },
            (error) => {
                setLoading(false);
                const errContent = (error.response && error.response.data) || error.message || error.toString();
                Alerts.swalErrorAlert(errContent || t("Server Error, Please try again later."));
            }
        );
    }

    const handleVariationChange = (index, field, value) => {
        const newVariations = variations.map((variation, i) => {
            if (i === index) {
                if (field === "variation_id") {
                    fetchVariationOptions(index, value);
                    return { ...variation, [field]: value, variation_option_id: '', price: '' };
                } else if (field === "variation_option_id") {
                    const selectedOption = Fetched_Variation_Options[index].find(option => option._id === value);
                    return { ...variation, [field]: value, price: selectedOption ? selectedOption.price : '' };
                } else {
                    return { ...variation, [field]: value };
                }
            }
            return variation;
        });
        setVariations(newVariations);
    };

    // const handleVariationChange = (index, field, value) => {
    //     console.log('handleVariationChange', index, field, value.name);
    //     console.log(value._id);
    //     console.log(value.price);

    //     const newVariations = variations.map((variation, i) =>
    //         i === index ? field === "variation_option_id" ? { ...variation, [field]: value._id, 'price': value.price } : { ...variation, [field]: value } : variation,
    //     );
    //     console.log('newVariations', newVariations);
    //     if (field === "variation_id") {
    //         fetchVariationsTypes(index, value)
    //     }
    //     setVariations(newVariations);
    // };

    const validateForm = () => {
        return variations.every(variation => variation.variation_id && variation.variation_option_id && variation.price && variation.status);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validateForm()) {
            Alerts.swalErrorAlert("Please fill all fields for all variations.");
            return;
        }

        // setLoading(true);

        variations.map((variation) => {
            variation.item_id = state._id
        })
        const payloadData = {
            functionName: "AddBulkItemVariation",
            postData: {
                variations: variations
            }
        };
        console.log(payloadData)
        ItemsService.AddBulkItemVariation(payloadData, authData.sessionID).then(
            (response) => {
                setLoading(false);
                if (response.data.status) {
                    onSuccess();
                    Alerts.swalSuccessAlert(response.data.message || "Data Added Successfully.").then(() => {
                        toggle();
                    });
                } else {
                    if (response.data.message === "Unauthorized") {
                        AuthService.logout();
                        setLoginNavigate(true);
                    } else {
                        Alerts.swalErrorAlert(response.data.message || "Error: Can't Add Variations at the moment. Please try again later.");
                    }
                }
            },
            (error) => {
                setLoading(false);
                Alerts.swalErrorAlert("Network Operation failed.");
            }
        );
    };

    if (loginNavigate) {
        navigate('/login');
        return null;
    }

    return (
        <span className="d-inline-block float-end mb-2 me-2">
            <Button onClick={toggle} className="btn btn-custom-color ms-1">
                {t("Add New")}
            </Button>
            <Modal size='lg' isOpen={modal} toggle={toggle} backdrop={true}>
                <LoadingOverlay active={loading} spinner={<DNALoader />}>
                    <ModalHeader>{t("Add Item Variations")}</ModalHeader>
                    <ModalBody>
                        <Card className="main-card mb-3">
                            <CardBody>
                                <form onSubmit={handleSubmit}>
                                    {variations.map((variation, index) => (
                                        <Row key={index} className="mb-3">
                                            <Col md="3">
                                                <FormGroup>
                                                    <Label>{t("Select Variation")}</Label>
                                                    <Input
                                                        type="select"
                                                        value={variation.variation_id}
                                                        onChange={(e) => handleVariationChange(index, 'variation_id', e.target.value)}
                                                    >
                                                        <option value="">--{t("Select Variation")}--</option>
                                                        {Fetched_Variations.map((Variation, idx) => (
                                                            <option key={idx} value={Variation._id}>{Variation.name}</option>
                                                        ))}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col md="3">
                                                <FormGroup>
                                                    <Label>{t("Select Variation Option")}</Label>
                                                    <Input
                                                        type="select"
                                                        value={variation.variation_option_id}
                                                        onChange={(e) => handleVariationChange(index, 'variation_option_id', e.target.value)}
                                                        disabled={!variation.variation_id}
                                                    >
                                                        <option value="">--{t("Select Variation Option")}--</option>
                                                        {Fetched_Variation_Options[index] && Fetched_Variation_Options[index].map((option, idx) => (
                                                            <option key={idx} value={option._id}>{option.variation_type_name}</option>
                                                        ))}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col md="2">
                                                <FormGroup>
                                                    <Label>{t("Price")}</Label>
                                                    <Input
                                                        type="text"
                                                        value={variation.price}
                                                        onChange={(e) => handleVariationChange(index, 'price', e.target.value)}
                                                        placeholder="Enter Price"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="3">
                                                <FormGroup>
                                                    <Label>{t("Status")}</Label>
                                                    <Input
                                                        type="select"
                                                        value={variation.status}
                                                        onChange={(e) => handleVariationChange(index, 'status', e.target.value)}
                                                    >
                                                        <option value="">{t("Select status")}</option>
                                                        <option value="Active">{t("Active")}</option>
                                                        <option value="InActive">{t("InActive")}</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col md="1" >
                                                {variations.length > 1 && (
                                                    <i style={{ marginTop: '35px' }} onClick={() => removeVariationRow(index)} className="pe-7s-trash text-danger btn-icon-wrapper fs-5"> </i>
                                                )}
                                                <i style={{ marginTop: '35px' }} className="pe-7s-plus text-danger btn-icon-wrapper fs-5" onClick={addVariationRow}></i>
                                            </Col>
                                        </Row>
                                    ))}
                                    <div className="text-center">
                                        <Button type="submit" size="lg" className="btn btn-custom-color me-3">
                                            {t("Add")}
                                        </Button>
                                        <Button type="button" outline size="lg" onClick={toggle}>
                                            {t("Cancel")}
                                        </Button>
                                    </div>
                                </form>
                            </CardBody>
                        </Card>
                    </ModalBody>
                </LoadingOverlay>
            </Modal>
        </span>
    );
};

export default AddItemVariation;