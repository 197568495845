import React, { useState, useEffect, useRef } from 'react';
import {
  Box, Typography, TextField, Button, Dialog, DialogContent, List, ListItem, ListItemText,
  ListItemIcon, IconButton, styled, Divider, Grid, FormControl, InputLabel, Select, MenuItem
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddressService from './addressService';
import AlertService from "../../components/Alerts/alerts";
import { useTranslation } from 'react-i18next';
import { ArrowBack, Close as CloseIcon, Check as CheckIcon } from '@mui/icons-material';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: '16px',
    width: '100%',
    maxWidth: '600px',
    margin: 0,
  },
}));

const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px',
}));

const AddressIcon = styled(LocationOnIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  marginRight: '8px',
}));

const AddNewText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 'bold',
  cursor: 'pointer',
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  borderBottom: '1px solid #e0e0e0',
  '&:last-child': {
    borderBottom: 'none',
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '100%',
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    '& fieldset': {
      border: 'none', // Remove the border
    },
  },
  backgroundColor: '#F0F5FB',
  '& .MuiInputLabel-root': {
    fontSize: '16px',
    color: '#757575',
  },
  '& .MuiOutlinedInput-input': {
    padding: '12px 14px',
    fontSize: '13px',
  },
  marginBottom: theme.spacing(2),
}));


const ActionButton = styled(Button)(({ theme }) => ({
  borderRadius: '8px',
  padding: '8px 16px',
  textTransform: 'none',
  fontWeight: 'bold',
  boxShadow: 'none',
  width: '30%',
  borderRadius: '10px',
  backgroundColor: '#e67e22',
  padding: '10px 20px',
}));

const CancelButton = styled(Button)(({ theme }) => ({
  padding: '8px 16px',
  textTransform: 'none',
  fontWeight: 'bold',
  color: '#16294E',
  boxShadow: 'none',
  width: '30%',
  borderRadius: '10px',
  backgroundColor: '#E7E7E7',
  padding: '10px 20px',
  border: '1px solid #E7E7E7',
  // padding: '10px 20px',
}));

const AddressPopup = ({ open, onClose, addresses, onSelectAddress, onAddNew }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    location: '',
    block: '',
    unit: '',
    label: '',
    latitude: '',
    longitude: '',
    address_url: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
  });
  const autocompleteRef = useRef(null);
  const inputRef = useRef(null);
  const [storeduser, setStoredUser] = useState(JSON.parse(localStorage.getItem("user")));

  useEffect(() => {
    const initMap = () => {
      if (!inputRef.current || autocompleteRef.current) return;

      const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current, {
        types: ['address'],
      });
      autocompleteRef.current = autocomplete;

      autocomplete.addListener('place_changed', handlePlaceSelect);

      // Add this part to adjust the z-index of the autocomplete dropdown
      const pacContainer = document.querySelector('.pac-container');
      if (pacContainer) {
        //   pacContainer.style.zIndex = "1000";  // Set a high z-index
        // pacContainer.style.position = "fixed";  // Change to fixed positioning
      }
    };

    if (window.google && window.google?.maps) {
      initMap();
    } else {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDlKtLygdyr4XpjVE1WcyR7yHuigyq_agw&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = initMap;
      document.head.appendChild(script);
    }

    return () => {
      if (autocompleteRef.current) {
        window.google?.maps.event.clearInstanceListeners(autocompleteRef.current);
        autocompleteRef.current = null;
      }
    };
  }, [open, addresses, formData.location, formData]);


  const handlePlaceSelect = () => {
    const place = autocompleteRef.current.getPlace();
    if (!place.geometry) {
      console.error('No details available for input:', place.name);
      return;
    }

    const addressComponents = place.address_components;
    let block = '';
    let unit = '';
    let location = '';
    let city = '';
    let state = '';
    let postalCode = '';
    let country = '';

    addressComponents.forEach((component) => {
      const componentType = component.types[0];

      switch (componentType) {
        case 'street_number':
          unit = component.long_name;
          break;
        case 'route':
          block = component.long_name;
          break;
        case 'locality':
          city = component.long_name;
          break;
        case 'administrative_area_level_1':
          state = component.long_name;
          break;
        case 'postal_code':
          postalCode = component.long_name;
          break;
        case 'country':
          country = component.long_name;
          break;
      }
    });

    location = `${block} ${unit}, ${city}, ${state} ${postalCode}, ${country}`.trim();

    setFormData({
      ...formData,
      location: location,
      block: block,
      unit: unit,
      city: city,
      state: state,
      postalCode: postalCode,
      country: country,
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
      address_url: place.url,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };


  const handleBack = () => {
    onClose();
    setFormData({
      location: '',
      block: '',
      unit: '',
      label: '',
      latitude: '',
      longitude: '',
      address_url: '',
      city: '',
      state: '',
      postalCode: '',
      country: '',
    });
  };

  const handleAddAddress = async () => {
    try {
      const data = {
        user_id: storeduser._id,
        label: formData.label,
        unit: formData.unit,
        location: formData.location,
        block: formData.block,
        city: formData.city,
        state: formData.state,
        postalCode: formData.postalCode,
        country: formData.country,
        latitude: formData.latitude,
        longitude: formData.longitude
      };
      const response = await AddressService.addAddress(data);

      if (response.data.status) {
        AlertService.swalSuccessAlert("Address added successfully.");
        onAddNew(response.data.address); // Assuming the API returns the newly added address
        handleBack();
      } else {
        AlertService.swalErrorAlert(response.data.message || "Error adding address?.");
      }
    } catch (error) {
      AlertService.swalErrorAlert("Error adding address?. Please try again.");
    }
  };

  return (
    <StyledDialog open={open} onClose={onClose} fullWidth>
      <Header>
        <IconButton onClick={handleBack}>
          <ArrowBackIcon />
        </IconButton>
        <Box display="flex" alignItems="center">
          <AddressIcon />
          <Typography variant="subtitle1">Address</Typography>
        </Box>
      </Header>
      <DialogContent sx={{ padding: 0 }}>
        <Box sx={{ p: 2 }}>
          <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }} variant="h6">{t('Address')}</Typography>
          <StyledTextField
            inputRef={inputRef}
            fullWidth
            placeholder="Search Nearest Location"
            name="location"
            value={formData.location}
            onChange={handleInputChange}
            InputProps={{
              startAdornment: <LocationOnIcon color="action" />,
            }}
            sx={{
              '& .MuiInputBase-root': {
                zIndex: 1,  // Ensure the input is above other elements in the dialog
              },
            }}
          />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography sx={{ fontSize: '13px', fontWeight: 'bold' }} variant="h6">{t('Block or Street name')}</Typography>
              <StyledTextField
                fullWidth
                placeholder="Block or Street name"
                name="block"
                value={formData.block}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ fontSize: '13px', fontWeight: 'bold' }} variant="h6">{t('Unit/House no')}</Typography>
              <StyledTextField
                fullWidth
                placeholder="Unit/House no."
                name="unit"
                value={formData.unit}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography sx={{ fontSize: '13px', fontWeight: 'bold' }} variant="h6">{t('City')}</Typography>
              <StyledTextField
                fullWidth
                placeholder="City"
                name="city"
                value={formData.city}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ fontSize: '13px', fontWeight: 'bold' }} variant="h6">{t('State/Province')}</Typography>
              <StyledTextField
                fullWidth
                placeholder="State/Province"
                name="state"
                value={formData.state}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography sx={{ fontSize: '13px', fontWeight: 'bold' }} variant="h6">{t('Postal Code')}</Typography>
              <StyledTextField
                fullWidth
                placeholder="Postal Code"
                name="postalCode"
                value={formData.postalCode}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ fontSize: '13px', fontWeight: 'bold' }} variant="h6">{t('Country')}</Typography>
              <StyledTextField
                fullWidth
                placeholder="Country"
                name="country"
                value={formData.country}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <Typography sx={{ fontSize: '13px', fontWeight: 'bold' }} variant="h6">{t('label')}</Typography>
            <Select
              name="label"
              sx={{
                width: '100%',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '12px',
                },
                backgroundColor: '#F0F5FB',
                '& .MuiInputLabel-root': {
                  fontSize: '16px',
                  color: '#757575',
                },
                '& .MuiOutlinedInput-input': {
                  padding: '12px 14px',
                  fontSize: '13px',
                },
                marginBottom: '2px',
                '& fieldset': {
                  border: 'none', // Remove the border from Select component
                },
              }}
              value={formData.label}
              onChange={handleInputChange}
            >
              <MenuItem value="Home">Home</MenuItem>
              <MenuItem value="Work">Work</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
          </FormControl>
          <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center' }}>
            <CancelButton
              fullWidth
              variant="outlined"
              onClick={handleBack}
              startIcon={<CloseIcon />}
            >
              Cancel
            </CancelButton>
            <ActionButton
              fullWidth
              variant="contained"
              onClick={handleAddAddress}
              startIcon={<CheckIcon />}
            >
              Add
            </ActionButton>
          </Box>
        </Box>
      </DialogContent>
      <style jsx global>{`
                .pac-container {
                    z-index: 9999 !important;
                    position: fixed !important;
                }
            `}</style>
    </StyledDialog>
  );
};

export default AddressPopup;

// import React, { useState, useEffect, useRef } from 'react';
// import {
//     Box, Typography, TextField, Button, Dialog, DialogContent, List, ListItem, ListItemText,
//     ListItemIcon, IconButton, styled, Divider, Grid, FormControl, InputLabel, Select, MenuItem
// } from '@mui/material';
// import LocationOnIcon from '@mui/icons-material/LocationOn';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import AddressService from './addressService';
// import AlertService from "../../components/Alerts/alerts";

// const StyledDialog = styled(Dialog)(({ theme }) => ({
//     '& .MuiDialog-paper': {
//         borderRadius: '16px',
//         width: '90%',
//         maxWidth: '400px',
//         margin: 0,
//     },
// }));

// const Header = styled(Box)(({ theme }) => ({
//     display: 'flex',
//     justifyContent: 'space-between',
//     alignItems: 'center',
//     padding: '16px',
//     borderBottom: '1px solid #e0e0e0',
// }));

// const AddressIcon = styled(LocationOnIcon)(({ theme }) => ({
//     color: theme.palette.primary.main,
//     marginRight: '8px',
// }));

// const AddNewText = styled(Typography)(({ theme }) => ({
//     color: theme.palette.primary.main,
//     fontWeight: 'bold',
//     cursor: 'pointer',
// }));

// const StyledListItem = styled(ListItem)(({ theme }) => ({
//     borderBottom: '1px solid #e0e0e0',
//     '&:last-child': {
//         borderBottom: 'none',
//     },
// }));

// const StyledTextField = styled(TextField)(({ theme }) => ({
//     marginBottom: '16px',
// }));

// const ActionButton = styled(Button)(({ theme }) => ({
//     borderRadius: '20px',
//     padding: '10px 20px',
// }));
// const AddressPopup = ({ open, onClose, onAddNew }) => {
//   const [formData, setFormData] = useState({
//       location: '',
//       block: '',
//       unit: '',
//       label: '',
//       latitude: '',
//       longitude: '',
//       address_url: '',
//       city: '',
//       state: '',
//       postalCode: '',
//       country: '',
//   });
//   const autocompleteRef = useRef(null);
//   const inputRef = useRef(null);
//   const [storedUser, setStoredUser] = useState(JSON.parse(localStorage.getItem("user")));

//   useEffect(() => {
//       const initMap = () => {
//           if (!window.google || !window.google.maps || !inputRef.current || autocompleteRef.current) return;

//           const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current, {
//               types: ['address'],
//           });
//           autocompleteRef.current = autocomplete;

//           autocomplete.addListener('place_changed', handlePlaceSelect);

//           // Adjust the z-index of the autocomplete dropdown
//           const pacContainer = document.querySelector('.pac-container');
//           if (pacContainer) {
//               pacContainer.style.zIndex = "9999";
//               pacContainer.style.position = "fixed";
//           }
//       };

//       if (window.google && window.google.maps) {
//           initMap();
//       } else {
//           const script = document.createElement('script');
//           script.src = `https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&libraries=places`;
//           script.async = true;
//           script.defer = true;
//           script.onload = initMap;
//           document.head.appendChild(script);
//       }

//       return () => {
//           if (autocompleteRef.current) {
//               window.google?.maps.event.clearInstanceListeners(autocompleteRef.current);
//               autocompleteRef.current = null;
//           }
//       };
//   }, []);

//   const handlePlaceSelect = () => {
//       const place = autocompleteRef.current.getPlace();
//       if (!place.geometry) {
//           console.error('No details available for input:', place.name);
//           return;
//       }

//       const addressComponents = place.address_components;
//       let newFormData = {
//           location: '',
//           block: '',
//           unit: '',
//           city: '',
//           state: '',
//           postalCode: '',
//           country: '',
//           latitude: place.geometry.location.lat(),
//           longitude: place.geometry.location.lng(),
//           address_url: place.url,
//       };

//       addressComponents.forEach((component) => {
//           const types = component.types;
//           if (types.includes('street_number')) {
//               newFormData.unit = component.long_name;
//           } else if (types.includes('route')) {
//               newFormData.block = component.long_name;
//           } else if (types.includes('locality')) {
//               newFormData.city = component.long_name;
//           } else if (types.includes('administrative_area_level_1')) {
//               newFormData.state = component.long_name;
//           } else if (types.includes('postal_code')) {
//               newFormData.postalCode = component.long_name;
//           } else if (types.includes('country')) {
//               newFormData.country = component.long_name;
//           }
//       });

//       newFormData.location = place.formatted_address;

//       setFormData(newFormData);
//   };

//   const handleInputChange = (e) => {
//       const { name, value } = e.target;
//       setFormData(prev => ({ ...prev, [name]: value }));
//   };

//   const handleBack = () => {
//       setFormData({
//           location: '',
//           block: '',
//           unit: '',
//           label: '',
//           latitude: '',
//           longitude: '',
//           address_url: '',
//           city: '',
//           state: '',
//           postalCode: '',
//           country: '',
//       });
//   };

//   const handleAddAddress = async () => {
//       try {
//           const data = {
//               user_id: storedUser._id,
//               ...formData
//           };
//           const response = await AddressService.addAddress(data);

//           if (response.data.status) {
//               AlertService.swalSuccessAlert("Address added successfully.");
//               onAddNew(response.data.address);
//               handleBack();
//               onClose();
//           } else {
//               AlertService.swalErrorAlert(response.data.message || "Error adding address.");
//           }
//       } catch (error) {
//           AlertService.swalErrorAlert("Error adding address. Please try again.");
//       }
//   };

//   return (
//       <StyledDialog open={open} onClose={onClose} fullWidth>
//           <Header>
//               <IconButton onClick={handleBack}>
//                   <ArrowBackIcon />
//               </IconButton>
//               <Box display="flex" alignItems="center">
//                   <AddressIcon />
//                   <Typography variant="subtitle1">Address</Typography>
//               </Box>
//           </Header>
//           <DialogContent sx={{ padding: 0 }}>
//               <Box sx={{ p: 2 }}>
//                   <Typography variant="h6" sx={{ mb: 2 }}>Address</Typography>
//                   <StyledTextField
//                       inputRef={inputRef}
//                       fullWidth
//                       placeholder="Search Nearest Location"
//                       name="location"
//                       value={formData.location}
//                       onChange={handleInputChange}
//                       InputProps={{
//                           startAdornment: <LocationOnIcon color="action" />,
//                       }}
//                       sx={{
//                           '& .MuiInputBase-root': {
//                               zIndex: 1,
//                           },
//                       }}
//                   />
//                   <Grid container spacing={2}>
//                       <Grid item xs={6}>
//                           <StyledTextField
//                               fullWidth
//                               placeholder="Block or Street name"
//                               name="block"
//                               value={formData.block}
//                               onChange={handleInputChange}
//                           />
//                       </Grid>
//                       <Grid item xs={6}>
//                           <StyledTextField
//                               fullWidth
//                               placeholder="Unit/House no."
//                               name="unit"
//                               value={formData.unit}
//                               onChange={handleInputChange}
//                           />
//                       </Grid>
//                   </Grid>
//                   <Grid container spacing={2}>
//                       <Grid item xs={6}>
//                           <StyledTextField
//                               fullWidth
//                               placeholder="City"
//                               name="city"
//                               value={formData.city}
//                               onChange={handleInputChange}
//                           />
//                       </Grid>
//                       <Grid item xs={6}>
//                           <StyledTextField
//                               fullWidth
//                               placeholder="State/Province"
//                               name="state"
//                               value={formData.state}
//                               onChange={handleInputChange}
//                           />
//                       </Grid>
//                   </Grid>
//                   <Grid container spacing={2}>
//                       <Grid item xs={6}>
//                           <StyledTextField
//                               fullWidth
//                               placeholder="Postal Code"
//                               name="postalCode"
//                               value={formData.postalCode}
//                               onChange={handleInputChange}
//                           />
//                       </Grid>
//                       <Grid item xs={6}>
//                           <StyledTextField
//                               fullWidth
//                               placeholder="Country"
//                               name="country"
//                               value={formData.country}
//                               onChange={handleInputChange}
//                           />
//                       </Grid>
//                   </Grid>
//                   <FormControl fullWidth sx={{ mb: 2 }}>
//                       <InputLabel>Label</InputLabel>
//                       <Select
//                           name="label"
//                           value={formData.label}
//                           onChange={handleInputChange}
//                       >
//                           <MenuItem value="Home">Home</MenuItem>
//                           <MenuItem value="Work">Work</MenuItem>
//                           <MenuItem value="Other">Other</MenuItem>
//                       </Select>
//                   </FormControl>
//                   <Box sx={{ display: 'flex', gap: 2 }}>
//                       <ActionButton
//                           fullWidth
//                           variant="outlined"
//                           onClick={onClose}
//                       >
//                           Cancel
//                       </ActionButton>
//                       <ActionButton
//                           fullWidth
//                           variant="contained"
//                           onClick={handleAddAddress}
//                       >
//                           Add
//                       </ActionButton>
//                   </Box>
//               </Box>
//           </DialogContent>
//           <style jsx global>{`
//               .pac-container {
//                   z-index: 9999 !important;
//                   position: fixed !important;
//               }
//           `}</style>
//       </StyledDialog>
//   );
// };

// export default AddressPopup;
