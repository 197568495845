import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Typography, Button, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import AuthService from "./auth.service";
import AlertService from "../../components/Alerts/alerts";
import ConfigurationService from "../Configuration/configuration.service";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState(localStorage.getItem("system_logo"));
  const [email, setEmail] = useState('');

  useEffect(() => {
    getSetLanguage();
    getSystemConfig();
  }, []);

  function getSetLanguage() {
    const language = localStorage.getItem("language") || 'en';
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
  }

  function getSystemConfig() {
    ConfigurationService.getConfiguration().then((response) => {
      if (response.data.status) {
        setSystemLogo(response.data.data[0].system_logo);
      }
    }).catch(() => {
      // Handle error
    });
  }

  function setSystemLogo(sys_logo) {
    setLogo(sys_logo);
    localStorage.setItem("system_logo", sys_logo);
  }

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    AuthService.pwresetreq(email).then((response) => {
      setLoading(false);
      if (response.data.status) {
        localStorage.setItem("resetpass", true);
        navigate("/customer_reset_password");
        AlertService.successToast(t("Email is sent successfully. Please check your email and reset your password."));
      } else {
        AlertService.swalErrorAlert(response.data.message || t("Error: Can't send reset code at the moment. Please try again later."));
      }
    }).catch((error) => {
      setLoading(false);
      AlertService.swalErrorAlert(t("Server Error, Please try again later."));
    });
  };

  return (
    <div style={{ backgroundColor: "black", minHeight: "100vh", display: "flex", justifyContent: "center" }}>
      <div style={{ width: "100%", maxWidth: "1300px", backgroundColor: "white", position: "relative" }}>
        <div style={{ backgroundColor: "white", minHeight: "50vh", display: "flex", justifyContent: "center", paddingTop: "50px" }}>
          <div style={{
            width: '100%',
            maxWidth: '500px',
            backgroundColor: '#fff',
            padding: '30px',
            borderRadius: '15px',
            boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
            position: 'relative'
          }}>
            <div style={{ position: 'absolute', top: '20px', left: '20px' }}>
              <IconButton onClick={() => navigate(-1)}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            
            {/* Logo */}
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
              <img
                src={logo}
                alt="Logo"
                style={{ width: '80px', height: '80px', borderRadius: '50%' }}
              />
            </div>

            {/* Form */}
            <form onSubmit={onSubmit}>
              <Typography variant="h5" align="center" style={{ marginBottom: '20px' }}>
                Forgot Password
              </Typography>
              <Typography variant="body2" align="center" style={{ marginBottom: '20px', color: '#666' }}>
                Enter your email to reset your password.
              </Typography>

              {/* Email Input */}
              <div style={{ marginBottom: '20px' }}>
                <Typography variant="body1" style={{ marginBottom: '5px', color: '#9897A1' }}>Email</Typography>
                <input
                  type="email"
                  placeholder="Your Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  style={{
                    width: '100%',
                    padding: '15px',
                    color:'#A2A1AA',
                    fontSize: '16px',
                    border: '1px solid #EEEEEE',
                    borderRadius: '8px',
                    backgroundColor: '#FEFEFE'
                  }}
                />
              </div>

              {/* Send Reset Link Button */}
              <Button
                type="submit"
                fullWidth
                style={{
                  backgroundColor: '#F2731C',
                  color: '#fff',
                  padding: '15px',
                  fontSize: '16px',
                  borderRadius: '8px',
                  textTransform: 'none',
                  marginBottom: '20px'
                }}
              >
                Send Reset Link
              </Button>
            </form>

            {/* Back to Login */}
            <Typography variant="body2" align="center">
              Remember your password?{' '}
              <span
                style={{ color: '#F2731C', cursor: 'pointer' }}
                onClick={() => navigate("/customer_login")}
              >
                Back to Login
              </span>
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;