import React, { Fragment, useState, useEffect } from "react";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWallet, faCalendar, faPlus } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import Header from './Header';
import AddTopUp from './AddTopUp';
import DashboardService from "../dashboard.service";
import AuthService from "../../Authentication/auth.service";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Alerts from "../../../components/Alerts/alerts";
import ReviewModal from './ReviewModal';

// Dummy data
const dummyToReviewItems = [
  { id: 1, name: "Kabab Kabsa", image: "https://altitudeprojects.net/js-mp/uploads/sellers/products/17001165156555b823d845bkmc_20230323_230721.jpg" },
  { id: 2, name: "Chicken Mandy with Biryani Rice Chicken Mandy with Biryani Rice", image: "https://altitudeprojects.net/js-mp/uploads/sellers/products/17001165156555b823d845bkmc_20230323_230721.jpg" },
  { id: 3, name: "Lamb Mandy with Mandy Rice", image: "https://altitudeprojects.net/js-mp/uploads/sellers/products/17001165156555b823d845bkmc_20230323_230721.jpg" },
  { id: 4, name: "Mix Vegetables", image: "https://altitudeprojects.net/js-mp/uploads/sellers/products/17001165156555b823d845bkmc_20230323_230721.jpg" },
  { id: 5, name: "Chana Curry", image: "https://altitudeprojects.net/js-mp/uploads/sellers/products/17001165156555b823d845bkmc_20230323_230721.jpg" },
  { id: 6, name: "Potato & Egg Curry", image: "https://altitudeprojects.net/js-mp/uploads/sellers/products/17001165156555b823d845bkmc_20230323_230721.jpg" },
];

const dummyMyReviews = [
  { id: 1, itemName: "Mutton Biryani", itemImage: "https://altitudeprojects.net/js-mp/uploads/sellers/products/17001165156555b823d845bkmc_20230323_230721.jpg", rating: 4, comment: "Well and good", date: "2024-07-26" },
  { id: 2, itemName: "Test Item", itemImage: "https://altitudeprojects.net/js-mp/uploads/sellers/products/17001165156555b823d845bkmc_20230323_230721.jpg", rating: 5, comment: "Amazing", date: "2024-04-06" },
  { id: 3, itemName: "Lamb Mandi", itemImage: "https://altitudeprojects.net/js-mp/uploads/sellers/products/17001165156555b823d845bkmc_20230323_230721.jpg", rating: 3, comment: "ABC", date: "2024-07-24" },
  { id: 4, itemName: "Beef Burger", itemImage: "https://altitudeprojects.net/js-mp/uploads/sellers/products/17001165156555b823d845bkmc_20230323_230721.jpg", rating: 5, comment: "ABC", date: "2024-07-24" },
  { id: 5, itemName: "Potato & Egg Curry", itemImage: "https://altitudeprojects.net/js-mp/uploads/sellers/products/17001165156555b823d845bkmc_20230323_230721.jpg", rating: 5, comment: "Best product", date: "2024-04-23" },
];

const CreditPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [branch, setBranch] = useState('');
  const [activeTab, setActiveTab] = useState('toReview');
  const [toReviewItems, setToReviewItems] = useState([]);
  const [myReviews, setMyReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const storeduser = JSON.parse(localStorage.getItem("user"));


  const handleReviewClick = (item) => {
    setSelectedItem(item);
  };

  const handleCloseModal = () => {
    setSelectedItem(null);
  };

  const handleSubmitReview = (reviewData) => {
    console.log('Review submitted:', reviewData);
    console.log('selectedItem:', selectedItem);

    let payload = {
      "functionName": "AddReview",
      "postData": {
        "item_id": selectedItem.item_id,
        "user_id": storeduser._id,
        "rating": reviewData.rating,
        "comment": reviewData.comment
      }
    }
    DashboardService.AddReview(payload).then((response) => {
      setLoading(false);
      console.log("response", response);
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      } else {
        setToReviewItems(response.data.orderData);
      }
    }).catch((error) => {
      setLoading(false);
      Alerts.swalErrorAlert(t("Server Error, Please try again later."));
    });
  };


  useEffect(() => {
    fetchReviewData();
    fetchToReviewData();
  }, []);

  const fetchReviewData = () => {
    setLoading(true);
    // Replace this with your actual API call
    let payload = {
      functionName: "GetOrdersProductForReview",
      postData: {
        user_id: storeduser._id
      }
    }
    DashboardService.GetOrdersProductForReview(payload).then((response) => {
      setLoading(false);
      console.log("response", response);
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      } else {
        setToReviewItems(response.data.orderData);
      }
    }).catch((error) => {
      setLoading(false);
      Alerts.swalErrorAlert(t("Server Error, Please try again later."));
    });
  };



  const fetchToReviewData = () => {
    setLoading(true);
    // Replace this with your actual API call
    let payload = {
      "functionName": "MyReviews",
      postData: {
        user_id: storeduser._id
      }
    }
    DashboardService.MyReviews(payload).then((response) => {
      setLoading(false);
      if (response.data.reviewsData.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      } else {
        console.log("MyReviews", response.data.reviewsData.data);
        setMyReviews(response.data.reviewsData.data);
      }
    }).catch((error) => {
      setLoading(false);
      Alerts.swalErrorAlert(t("Server Error, Please try again later."));
    });
  };

  const styles = {
    tabContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '20px',
    },
    tab: {
      padding: '10px 20px',
      textAlign: 'center',
      cursor: 'pointer',
      borderRadius: '20px',
      margin: '0 5px',
      border: '1px solid #ddd',
      color: '#888',
      fontWeight: 'bold',
      transition: 'all 0.3s ease',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '20px',
    },
    activeTab: {
      backgroundColor: '#ff4444',
      color: 'white',
      border: '1px solid #ff4444',
    },
    itemContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '20px',
      justifyContent: 'center',
    },
    itemCard: {
      width: 'calc(25% - 15px)',
      border: '1px solid #ddd',
      borderRadius: '8px',
      overflow: 'hidden',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      display: 'flex',
      flexDirection: 'column',
    },
    itemImage: {
      width: '100%',
      height: '150px',
      objectFit: 'cover',
    },
    itemInfo: {
      padding: '10px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      flexGrow: 1,
    },
    itemName: {
      margin: '0 0 10px 0',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
    },
    reviewButton: {
      backgroundColor: '#ff4444',
      color: 'white',
      border: 'none',
      padding: '8px 0',
      borderRadius: '5px',
      cursor: 'pointer',
      width: '100%',
      marginTop: 'auto',
      fontWeight: 'bold',
    },
    emptyState: {
      textAlign: 'center',
      padding: '50px',
    },
    emptyStateImage: {
      width: '150px',
      marginBottom: '20px',
    },
  };

  const renderToReviewItems = () => {
    if (toReviewItems.length === 0) {
      return (
        <div style={styles.emptyState}>
          <img src="/path-to-empty-state-image.png" alt="No items to review" style={styles.emptyStateImage} />
          <h3>NO PRODUCTS TO REVIEW</h3>
          <p>Please try again later!</p>
        </div>
      );
    }

    return (
      <div style={styles.itemContainer}>
        {toReviewItems.map((item, index) => (
          <div key={index} style={styles.itemCard}>
            <img src={item.image} alt={item.name} style={styles.itemImage} />
            <div style={styles.itemInfo}>
              <h4 style={styles.itemName}>{item.item_name}</h4>
              <button
                style={styles.reviewButton}
                onClick={() => handleReviewClick(item)}
              >
                Review
              </button>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderMyReviews = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
        {myReviews.map((review, index) => (
          <div key={index} style={{
            backgroundColor: 'white',
            borderRadius: '8px',
            padding: '15px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            display: 'flex',
            alignItems: 'center'
          }}>
            <img
              src={review.selected_image}
              alt={review.itemName}
              style={{
                width: '60px',
                height: '60px',
                borderRadius: '50%',
                marginRight: '15px',
                objectFit: 'cover'
              }}
            />
            <div style={{ flex: 1 }}>
              <h4 style={{ margin: '0 0 5px 0' }}>{review.item_details.item_name}</h4>
              <div style={{
                color: '#FFD700',
                fontSize: '18px',
                marginBottom: '5px'
              }}>
                {"★".repeat(review.rating)}{"☆".repeat(5 - review.rating)} {review.rating}
              </div>
              <p style={{ margin: '0 0 5px 0' }}>{review.comment}</p>
              <small style={{ color: '#888' }}>{moment(review.datetime_added).format('MMM D, YYYY')}</small>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <Fragment>
      <div className="app-main__outer">
        {selectedItem && (
          <ReviewModal
            item={selectedItem}
            onClose={handleCloseModal}
            onSubmit={handleSubmitReview}
          />
        )}

        <div className="app-main__inner">
          <TransitionGroup>
            <CSSTransition component="div" classNames="TabsAnimation" appear={true} timeout={0} enter={false} exit={false}>
              <div>
                <div className="app-page-title">
                  <div className="page-title-wrapper">
                    <Header branch={branch} setBranch={setBranch} />
                  </div>
                </div>
                <div>
                  <header style={styles.header}>
                    {/* <FontAwesomeIcon icon={faBars} /> */}
                    <h1></h1>
                    <h1>Reviews</h1>
                    <h1></h1>
                    {/* <FontAwesomeIcon icon={faShoppingCart} /> */}
                  </header>
                  <div style={styles.tabContainer}>
                    <div
                      style={{ ...styles.tab, ...(activeTab === 'toReview' ? styles.activeTab : {}) }}
                      onClick={() => setActiveTab('toReview')}
                    >
                      TO REVIEW
                    </div>
                    <div
                      style={{ ...styles.tab, ...(activeTab === 'myReviews' ? styles.activeTab : {}) }}
                      onClick={() => setActiveTab('myReviews')}
                    >
                      MY REVIEWS
                    </div>
                  </div>
                  {activeTab === 'toReview' ? renderToReviewItems() : renderMyReviews()}
                </div>
              </div>
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
    </Fragment>
  );
};

export default CreditPage;