import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { Col, Row, Button, Label, FormGroup, Input } from "reactstrap";
import { Form, Field } from "react-final-form";
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom';
import SliderComponent from './SliderComponent';

import AuthService from "./auth.service";
import AlertService from "../../components/Alerts/alerts";

import { ToastContainer } from 'react-toastify';
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";
import CopyRight from "../../components/CopyRight Bar/CopyRight";
import ConfigurationService from "../Configuration/configuration.service";
import FinanceService from "../Finance/Settings/configuration.service";
import bg from './bg.png';

const Login = () => {
  const params = useParams();
  const location = useLocation();
  console.log('location', location)
  var pathname = location.pathname;
  pathname = pathname.split('/')[2];
  const segments = pathname.split('/');
  const businessId = segments[segments.length - 1];
  console.log('business_id:', businessId);
  const user_type = 'Customers'
  useEffect(() => {
    getSetLanguage();
    setTimeout(() => {
      getFinanceConfig();
      getSystemConfig();
    }, 2000);
  }, []);

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState(localStorage.getItem("system_logo"));
  localStorage.setItem("user_type", 'users');

  function getSetLanguage() {
    if (localStorage.getItem("language")) {
      const language = localStorage.getItem("language");
      i18n.changeLanguage(language);
    }
    else {
      localStorage.setItem('language', 'en');
      i18n.changeLanguage('en');
    }
  }

  function getSystemConfig() {
    ConfigurationService.getConfiguration().then((response) => {
      console.log("API Response:-", response);
      if (response.data.status) {
        setColors(response.data.data[0].buttons_color, '--buttons-color');
        setColors(response.data.data[0].topbar_color, '--topbar-color');
        setColors(response.data.data[0].leftbar_color, '--leftbar-color');
        setColors(response.data.data[0].buttons_text_color, '--buttons-text-color');
        setSystemLogo(response.data.data[0].system_logo);
        localStorage.setItem("google_api_key", response.data.data[0].google_api_key)
      }
      else {
        setColors('#3ac47d', 'onerror');
      }
    },
      (error) => {
        setColors('#3ac47d', 'onerror');
      });
  }

  function setColors(color, param) {
    if (param === '--buttons-color') {
      localStorage.setItem("buttons_color", color);
      document.documentElement.style.setProperty('--buttons-color', color)
    }
    if (param === '--topbar-color') {
      localStorage.setItem("topbar_color", color);
      document.documentElement.style.setProperty('--topbar-color', color)
    }
    if (param === '--leftbar-color') {
      localStorage.setItem("leftbar_color", color);
      document.documentElement.style.setProperty('--leftbar-color', color)
    }
    if (param === '--buttons-text-color') {
      localStorage.setItem("buttons_text_color", color);
      document.documentElement.style.setProperty('--buttons-text-color', color)
    }
    if (param === 'onerror') {
      localStorage.setItem("buttons_color", color);
      localStorage.setItem("topbar_color", '#fff');
      localStorage.setItem("leftbar_color", '#fff');
      localStorage.setItem("buttons_text_color", '#fff');
      document.documentElement.style.setProperty('--buttons-color', color)
      document.documentElement.style.setProperty('--topbar-color', '#fff')
      document.documentElement.style.setProperty('--leftbar-color', '#fff')
      document.documentElement.style.setProperty('--buttons-text-color', '#fff')
    }
  }

  function setSystemLogo(sys_logo) {
    setLogo(sys_logo);
    localStorage.setItem("system_logo", sys_logo);
  }

  function handlereset() {
    localStorage.setItem("reset", true)
  };

  function handleregprod() {
    localStorage.setItem("regprod", true)
  };

  const onSubmit = values => {
    setLoading(true);
    AuthService.loginCustomer(values.email, values.password, user_type, businessId).then((response) => {
      console.log("Login:-", response);
      setLoading(false);
      // alert("Login Sucessful");
      // var landingpage = response.role_details.landingpage
      AlertService.successToast("Login Successful.")
      // navigate("/Business_Customer_Home");
      navigate("/Store");
      // window.location.reload();
    },
      (error) => {
        setLoading(false);
        const resMessage =
          (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        if (resMessage === 'Unauthorized') {
          AlertService.swalErrorAlert(t("Invalid Credentials."))
        }
        else {
          AlertService.swalErrorAlert(t('Invalid Credentials'))
        }
      }
    );
  };


  function getFinanceConfig() {
    FinanceService.getConfiguration().then((response) => {
      console.log("Finance API Response:-", response.data.data);
      if (response.data.status) {
        localStorage.setItem("payment_method", response.data.data[0]?.payment_method);
        localStorage.setItem("due_date", response.data.data[0]?.due_date);
        localStorage.setItem("default_notes", response.data.data[0]?.default_notes);
        localStorage.setItem("tax_percentage", response.data.data[0]?.tax_percentage);
        localStorage.setItem("currency", response.data.data[0]?.currency);
        localStorage.setItem("invoice_to", response.data.data[0]?.invoice_to);
        localStorage.setItem("invoice_to_field", response.data.data[0]?.invoice_to_field);
        localStorage.setItem("payment_types", response.data.data[0]?.payment_types);

      }
      else {
        setColors('#3ac47d', 'onerror');
      }
    },
      (error) => {
        setColors('#3ac47d', 'onerror');
      });
  }


  const validateFtn = (values) => {
    const errors = {};
    function validateEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
    if (!values.email) {
      errors.email = t('Email Required.');
    }
    else if (!validateEmail(values.email)) {
      errors.email = t('Please enter a valid email address.');
    }
    if (!values.password) {
      errors.password = t('Password Required.');
    }
    return errors;
  };


  return (
    <div style={styles.container}>
      <div style={styles.leftContainer}>
        <div style={styles.logoContainer}>
          <img src={logo} alt="Logo" style={styles.logo} />
        </div>
        <Form onSubmit={onSubmit} validate={validateFtn}
          render={({ handleSubmit, valid }) => (
            <form onSubmit={handleSubmit}>
              <LoadingOverlay active={loading} spinner={<DNALoader />}>
                <div className="modal-body p-5">
                  <h2 style={styles.heading}>Login</h2>
                  <p style={styles.logoText}>Enter Credentials below to login.</p>
                  <Row form>
                    <Col md={12}>
                      <FormGroup>
                        <Field name="email">
                          {({ input, meta }) => (
                            <div className="mb-3">
                              <Label for="email">{t('Email')}</Label>
                              <Input {...input} type="email" invalid={meta.error && meta.touched} placeholder={t('Enter Email')} />
                              {meta.error && meta.touched && <span className="text-danger">{meta.error}</span>}
                            </div>
                          )}
                        </Field>
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup>
                        <Field name="password">
                          {({ input, meta }) => (
                            <div className="mb-3">
                              <Label for="password">{t('Password')}</Label>
                              <Input {...input} type="password" invalid={meta.error && meta.touched} placeholder="**********" />
                              {meta.error && meta.touched && <span className="text-danger">{meta.error}</span>}
                            </div>
                          )}
                        </Field>
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup check className="mb-3">
                    <div style={styles.formFooter}>
                      <div style={styles.rememberMe}>
                        {/* <Input type="checkbox" id="rememberMe" />
                        <label htmlFor="rememberMe" style={styles.checkboxLabel}>Remember Me</label> */}
                      </div>
                      <a href="/Userforgot-password" style={styles.forgotPassword}>Forgot Password?</a>
                    </div>
                  </FormGroup>
                  <div className="d-grid mb-3">
                    <Button size="lg" className="btn-custom-color" type="submit" disabled={!valid}>
                      {t('Login')}
                    </Button>
                  </div>
                  <div className="text-center">
                    {t('Already Have an Account?')}
                    <Link to={`/register_client/${businessId}`} style={{ color: '#007bff', textDecoration: 'none', marginLeft: '5px' }}>
                      {t('Create New')}
                    </Link>
                  </div>
                </div>
              </LoadingOverlay>
            </form>
          )}
        />
        {/* <CopyRight /> */}
      </div>
      <div style={styles.rightContainer}>
        <SliderComponent />
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    height: '100vh',
  },
  leftContainer: {
    flex: 1,
    display: 'flex',
    marginTop: '3%',
    flexDirection: 'column',
    background: 'white',
    padding: '0 50px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  rightContainer: {
    flex: 1,
    margin: '1%',
    borderRadius: '1%',
    backgroundImage: `url(${bg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
  },
  overlayText: {
    position: 'absolute',
    top: '1%',
    left: '10%',
    color: 'white',
    textAlign: 'left',
    padding: '20px',
    backgroundColor: 'rgba(72, 132, 194, 0.5)',
  },
  logoContainer: {
    marginBottom: '20px',
  },
  logo: {
    width: '200px',
    height: 'auto',
  },
  heading: {
    marginTop: '12%',
    fontSize: '28px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  logoText: {
    fontSize: '16px',
    color: '#666',
    marginBottom: '20px',
  },
  formFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rememberMe: {
    display: 'flex',
    alignItems: 'center',
  },
  checkboxLabel: {
    marginLeft: '5px',
  },
  forgotPassword: {
    color: '#007bff',
    textDecoration: 'none',
  },
};

export default Login;
