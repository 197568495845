import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { Col, Row, Button, Label, FormGroup, Input } from "reactstrap";
import { Form, Field } from "react-final-form";
import { useNavigate , Link} from 'react-router-dom';
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";
import CopyRight from "../../components/CopyRight Bar/CopyRight";
import ConfigurationService from "../Configuration/configuration.service";
import AuthService from "./auth.service";
import bg from './bg.png';
import AlertService from "../../components/Alerts/alerts";
import Alerts from "../../components/Alerts/alerts";

const ForgetPassword = () => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState(localStorage.getItem("system_logo"));
  const navigate = useNavigate();
  localStorage.removeItem("reset");

  useEffect(() => {
    getSetLanguage();
    setSystemConfig();
  }, []);

  function getSetLanguage() {
    const language = localStorage.getItem("language") || 'en';
    localStorage.setItem('language', language);
    i18n.changeLanguage(language);
  }

  function setSystemConfig() {
    ConfigurationService.getConfiguration().then((response) => {
      if (response.data.status) {
        setLogo(response.data.data[0].system_logo);
        localStorage.setItem("system_logo", response.data.data[0].system_logo);
      }
    });
  }


  const validateFtn = (values) => {
    const errors = {};
    function validateEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
    if (!values.email) {
      errors.email = t('Email Required.');
    }
    else if (!validateEmail(values.email)) {
      errors.email = t('Please enter a valid email address.');
    }
    return errors;
  };

  const onSubmit = values => {
    console.log(values);
    setLoading(true);
    AuthService.pwresetreq(values.email).then((response) => {
      console.log("Response:-", response)
      setLoading(false);
      if(response.data.status){
        localStorage.setItem("resetpass", true)
        navigate("/auth-password");
        window.location.reload();
        if(response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Email is sent successfully. Please check your email and reset your password."))
        }
      }
      else {
        if(response.data.message !== '') {
          Alerts.swalErrorAlert(response.data.message)
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't send reset code at the moment. Please try again later."))
        }
      }
    },
    (error) => {
      const resMessage =
      (error.response && error.response.data &&  error.response.data.message) || error.message || error.toString();
      setLoading(false);
      // localStorage.setItem("resetpass", true)//should be removed once API is fixed.
      // navigate("/auth-password");//should be removed once API is fixed.
      // window.location.reload();//should be removed once API is fixed.
      if(resMessage) {
        if(resMessage === 'Network Error') { 
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
        else {
          Alerts.swalErrorAlert(resMessage)
        }
      }
      else {
        Alerts.swalErrorAlert(t("Server Error, Please try again later."))
      }
    });
  };

  return (
    <div style={styles.container}>
      <div style={styles.leftContainer}>
        <div style={styles.logoContainer}>
          <img src={logo} alt="Logo" style={styles.logo} />
        </div>
        <Form onSubmit={onSubmit} validate={validateFtn}
          render={({ handleSubmit, valid }) => (
            <form onSubmit={handleSubmit}>
              <LoadingOverlay active={loading} spinner={<DNALoader />}>
                <div className="modal-body p-5">
                  <h2 style={styles.heading}>Forgot Password</h2>
                  <p style={styles.logoText}>Enter your email to reset your password.</p>
                  <Row form>
                    <Col md={12}>
                      <FormGroup>
                        <Field name="email">
                          {({ input, meta }) => (
                            <div className="mb-3">
                              <Label for="email">{t('Email')}</Label>
                              <Input {...input} type="email" invalid={meta.error && meta.touched} placeholder={t('Enter Email')} />
                              {meta.error && meta.touched && <span className="text-danger">{meta.error}</span>}
                            </div>
                          )}
                        </Field>
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="d-grid mb-3">
                    <Button size="lg" className="btn-custom-color" type="submit" disabled={!valid}>
                      {t('Send Reset Link')}
                    </Button>
                  </div>
                  <div className="text-center">
                    <Link to="/login" style={{
                      color: '#007bff',
                      textDecoration: 'none',
                    }}>Back to Login</Link>
                  </div>
                </div>
              </LoadingOverlay>
            </form>
          )}
        />
        {/* <CopyRight /> */}
      </div>
      <div style={styles.rightContainer}>
        {/* Add any additional components or styles here */}
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    height: '100vh',
  },
  leftContainer: {
    flex: 1,
    display: 'flex',
    marginTop: '3%',
    flexDirection: 'column',
    background: 'white',
    padding: '0 60px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  rightContainer: {
    flex: 2,
    backgroundImage: `url(${bg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
  },
  logoContainer: {
    marginBottom: '20px',
  },
  logo: {
    width: '180px',
    height: 'auto',
  },
  heading: {
    marginTop: '12%',
    fontSize: '28px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  logoText: {
    fontSize: '16px',
    color: '#666',
    marginBottom: '20px',
  },
};

export default ForgetPassword;
