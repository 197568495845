import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import { Row, Col, CardBody, Card } from "reactstrap";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import DataTable from 'react-data-table-component';
import DiscountCouponService from "../DiscountCoupon/Discountcoupons.service";
import AuthService from "../Authentication/auth.service";
import Alerts from "../../components/Alerts/alerts";
import { Tooltip } from "@mui/material";
import OrdersFilter from "../../components/Table Filters/OrdersFilter";
import PermissionAccess from '../../PermissionAccess/Access.js';
import moment from 'moment';
import UpdateStatus from './UpdateStatus';
import { Chip } from '@mui/material';
import styled from 'styled-components';

const StyledChip = styled(Chip)`
font-size: 12px;
height: 24px;
font-weight: 500;
`;

const OrdersList = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [filterNanoOnly, setfilterNanoOnly] = useState('');
  const [pageLimit, setPageLimit] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const { authData, super_admin, current_module } = PermissionAccess();
  const [array, setArray] = useState([]);

  const [showModalUpdateStatus, setshowModalUpdateStatus] = useState(false);
  const handleToggleModalUpdateStatus = (row) => {
    setArray(row);
    setshowModalUpdateStatus(!showModalUpdateStatus);
  }

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchOrders(currentPage, pageLimit);
  }, [pageLimit, currentPage]);

  const fetchOrders = (page_index, page_limit, filterText, filterStatus, filterNanoOnly) => {
    setLoading(true);
    const stored_user = authData?.userData;
    let businessIDs = []
    if (stored_user.businesses) {
      businessIDs = stored_user.businesses
    }
    var payload = {
      functionName: "getAllOrdersAdmin",
      postData: {
        filter: {
          text: filterText,
          status: filterStatus,
          nanoOnly: filterNanoOnly
        },
        limit: page_limit,
        offset: page_index,
      }
    }
    // DiscountCouponService.GetOrders(authData.sessionID, page_index, page_limit, text, status, business, businessIDs).then(
    DiscountCouponService.getAllOrdersAdmin(authData.sessionID, payload).then(
      (response) => {
        setLoading(false);
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        } else {
          setOrders(response.data.orderData);
          setTotalRows(response.data.count);
        }
      },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        Alerts.swalErrorAlert(errContent || t("Server Error, Please try again later."));
      }
    );
  };

  const mycolumns = [
    {
      name: t("Order ID"),
      selector: row => row?.order_number,
      sortable: true,
    },
    {
      name: t("Customer"),
      width: '30%',
      selector: row => row.username,
      cell: row => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span style={{ fontWeight: 'bold', fontSize: '14px', color: '#000' }}>
            {row.username}
          </span>
          <span style={{ fontSize: '12px', color: '#666', marginTop: '2px' }}>
            Phone: {row.mobileNumber || 'N/A'}
          </span>
          <span
            style={{
              fontSize: '12px',
              color: '#666',
              marginTop: '2px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: '100%',
              maxWidth: '320px',
              cursor: 'pointer',
            }}
            title={row.address}
          >
            Address: {row.address || 'N/A'}
          </span>
        </div>
      ),
      sortable: true,
    },
    {
      name: t("Date & Time"),
      selector: row => new Date(row.order_date).toLocaleDateString(),
      sortable: true,
    },
    {
      name: t("Total Items"),
      selector: row => row.TotalItems,
    },
    {
      name: t('Order Status'),
      selector: (row) => {
        const getButtonStyle = (status) => {
          switch (status) {
            case 'confirmed':
              return {
                borderColor: '#ffc107',
                backgroundColor: '#ffc107',
                color: '#000'
              };
            case 'processing':
              return {
                borderColor: '#007bff',
                backgroundColor: '#007bff',
                color: '#fff'
              };
            case 'in_transit':
            case 'picked_up':
              return {
                borderColor: '#6f42c1',
                backgroundColor: '#6f42c1',
                color: '#fff'
              };
            case 'cancelled':
              return {
                borderColor: '#dc3545',
                backgroundColor: '#dc3545',
                color: '#fff'
              };
            default:
              return {
                borderColor: '#28a745',
                backgroundColor: '#28a745',
                color: '#fff'
              };
          }
        };

        const formatStatus = (status) => {
          if (status.includes('*')) {
            // Remove asterisk and replace underscores with spaces
            return status.replace('*', '').replace(/_/g, ' ').toUpperCase();
          } else if (status === 'in_transit') {
            return 'IN TRANSIT';
          } else if (status === 'picked_up') {
            return 'PICKED UP';
          } else {
            return status.toUpperCase();
          }
        };

        const buttonStyle = getButtonStyle(row?.status);

        return (
          <div>
            <button
              type="button"
              style={buttonStyle}
              className={`btn btn-sm btn-hover-shine me-3 ${row?.status === 'confirmed' ? 'btn-success' : 'btn-danger'}`}
            >
              {formatStatus(row?.status)}
            </button>
          </div>
        );
      },
      sortable: true,
    },

    {
      name: t('Actions'),
      selector: (row) => {
        return (
          <div>
            {!super_admin ? current_module?.ViewData === 1 &&
              <NavLink to={`/order-details/${row?.order_id}`}><Tooltip placement="top" title={`${t("View")} ${t("Details")}`} arrow style={{ cursor: "pointer" }}>
                <i className="pe-7s-look text-primary btn-icon-wrapper text-primary fs-5 p-1"> </i>
              </Tooltip>
              </NavLink>
              :
              <NavLink to={`/order-details/${row?.order_id}`}>
                <Tooltip placement="top" title={`${t("View")} ${t("Details")}`} arrow style={{ cursor: "pointer" }}>
                  <i className="pe-7s-look text-primary btn-icon-wrapper text-primary fs-5 p-1"> </i>
                </Tooltip>
              </NavLink>
            }

            {row?.status.toLowerCase() !== "delivered" && (
              !super_admin ? current_module?.EditData === 1 &&
                <Tooltip placement="top" title={`${t("Update")} ${t("Status")}`} arrow>
                  <i
                    className="pe-7s-pen btn-icon-wrapper text-primary fs-5 p-1"
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleToggleModalUpdateStatus(row)}
                  > </i>
                </Tooltip>

                :
                <Tooltip placement="top" title={`${t("Update")} ${t("Status")}`} arrow>
                  <i
                    className="pe-7s-pen btn-icon-wrapper text-primary fs-5 p-1"
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleToggleModalUpdateStatus(row)}
                  > </i>
                </Tooltip>
            )}


            <UpdateStatus
              showModalUpdateStatus={showModalUpdateStatus}
              handleToggleModalUpdateStatus={handleToggleModalUpdateStatus}
              onSuccess={fetchOrders}
              array={array}
            />
          </div>
        );
      }
    }
  ];

  const handleSearch = (searchText, status, filterNanoOnly) => {
    setFilterText(searchText);
    setFilterStatus(status);
    setfilterNanoOnly(filterNanoOnly);
    fetchOrders(0, pageLimit, searchText, status, filterNanoOnly);
    setCurrentPage(0);
  };

  const handleClear = () => {
    setFilterText('');
    setFilterStatus('');
    setfilterNanoOnly('');
    fetchOrders(0, pageLimit, '', ''); // Fetch orders with empty filters
    setCurrentPage(0);
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <OrdersFilter
        onSearch={handleSearch}
        onClear={handleClear}
        filterText={filterText}
        filterStatus={filterStatus}
        filterNanoOnly={filterNanoOnly}
      />
    );
  }, [filterText, filterStatus, filterNanoOnly]);

  const handlePageChange = page => {
    setCurrentPage(page - 1);  // Adjust for 0-based index
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPageLimit(newPerPage);
    setCurrentPage(page - 1);  // Adjust for 0-based index
  };

  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className="bg-light-gray bg-animation">
            <div className="d-flex justify-content-center align-items-center">
              <Col md="12" className="mx-auto app-login-box">
                <div className="app-page-title">
                  <div className="page-title-wrapper">
                    <div className="page-title-heading fw-bold">
                      <div className="page-title-icon">
                        <i className="pe-7s-news-paper icon-gradient bg-tempting-azure" />
                      </div>
                      <div>
                        {`${t("Order")} ${t("Management")}`}
                        <div className="page-title-subheading">
                          {`${t("Order Details & Tracking")}`}
                        </div>
                      </div>
                    </div>
                    <div className="page-title-actions"></div>
                  </div>
                </div>
                <Row>
                  <LoadingOverlay
                    tag="div"
                    active={loading}
                    styles={{ overlay: (base) => ({ ...base }) }}
                    spinner={<DNALoader />}
                  >
                    <Col md="12">
                      <Card className="main-card mb-3">
                        <CardBody>
                          <Col md="6" style={{ float: "left", position: "absolute", zIndex: "1" }}>
                            {subHeaderComponentMemo}
                          </Col>
                          <DataTable
                            data={orders}
                            columns={mycolumns}
                            pagination
                            fixedHeader
                            fixedHeaderScrollHeight="400px"
                            subHeader
                            subHeaderComponent={<div></div>}
                            paginationServer
                            paginationTotalRows={totalRows}
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handlePerRowsChange}
                            paginationResetDefaultPage={resetPaginationToggle}
                          />
                        </CardBody>
                      </Card>
                    </Col>
                  </LoadingOverlay>
                </Row>
              </Col>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default OrdersList;
