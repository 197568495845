import axios from "axios";

const B_URL = process.env.REACT_APP_LOGINURL
const API_URL = process.env.REACT_APP_BASEURL;


const headers = {
  'Content-Type': 'application/json',
  'token': localStorage.getItem("session-id")
}


const getAllBusinesses = (sessionID, page, limit, business, text, status, noActiveMatch) => {
  var payload = {
    "functionName": "GetBusinesses",
    "postData": {
      "offset": page,
      "sort": {
        "_id": -1
      },
      "limit": limit,
      "business_ids": business,
      "text": text,
      "status": status,
      "noActiveMatch": noActiveMatch
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "BusinessModule/GetBusinesses", payload, { headers });
};

const getAllBusinessAttributes = (business_id) => {
  var payload = {
    "functionName": "GetBusinessAttribute",
    "postData": {
      "offset": 0,
      "sort": {
        "_id": -1
      },
      "limit": 9999999,
      "business_id": business_id,
      "attribute_id": ""
    }
  }
  return axios.post(B_URL + "BusinessAttributeModule/GetBusinessAttribute", payload, { headers });
};

const updateMultipleBusinessAttributes = (data) => {
  // console.log("DATA:", data);
  var payload = {
    "functionName": "UpdateMultipleBusinessAttributes",
    "postData": {
      "business_id": data.business_id,
      "section_id": data.section_id,
      "updates": data.updates
    }
  }
  return axios.post(B_URL + "BusinessAttributeModule/UpdateMultipleBusinessAttribute", payload, { headers });
};

const AddSection = (sessionID, data) => {
  // console.log("DATA:", data);
  var payload = {
    "functionName": "AddSection",
    "postData": {
      "name": data.name,
      "status": data.status
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "SectionModule/AddSection", payload, { headers });
};

const EditSection = (sessionID, data, id) => {
  // console.log("DATA:", data);
  var payload = {
    "functionName": "UpdateSection",
    "postData": {
      "section_id": id,
      "name": data.name,
      "status": data.status
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "SectionModule/UpdateSection", payload, { headers });
};

const EditAttribute = (sessionID, data, id) => {
  // console.log("DATA:", data);
  var payload = {
    "functionName": "UpdateAttribute",
    "postData": {
      "attribute_id": id,
      "lable": data.label,
      "key": data.key,
      "status": data.status,
      "field_type": data.field_type,
      "section_id": data.section_id,
      "options": data.options,
      "getOptionsFrom": data.getOptionsFrom,
      "getOptionFieldName": data.getOptionFieldName
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "AttributeModule/UpdateAttribute", payload, { headers });
};

const AddAttribute = (sessionID, data) => {
  // console.log("DATA:", data);
  var payload = {
    "functionName": "AddAttribute",
    "postData": {
      "lable": data.label,
      "key": data.key,
      "status": data.status,
      "field_type": data.field_type,
      "section_id": data.section_id,
      "options": data.options,
      "getOptionsFrom": data.getOptionsFrom,
      "getOptionFieldName": data.getOptionFieldName
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "AttributeModule/AddAttribute", payload, { headers });
};

const getAllSections = (sessionID, page, limit, text, status, noActiveMatch) => {
  var payload = {
    "functionName": "GetSections",
    "postData": {
      "offset": page,
      "sort": {
        "_id": -1
      },
      "limit": limit,
      "name": text,
      "status": status,
      "noActiveMatch": noActiveMatch
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "SectionModule/GetSections", payload, { headers });
};

const GetSingleSection = (sessionID, id) => {
  var payload = {
    "functionName": "GetSingleSection",
    "postData": {
      "id": id
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "SectionModule/GetSingleSection", payload, { headers });
};

const GetSingleAttribute = (sessionID, id) => {
  var payload = {
    "functionName": "GetSingleAttribute",
    "postData": {
      "id": id
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "AttributeModule/GetSingleAttribute", payload, { headers });
};

const getAllAttributes = (sessionID, page, limit, text, status) => {
  var payload = {
    "functionName": "GetAttributes",
    "postData": {
      "offset": page,
      "sort": {
        "_id": -1
      },
      "limit": limit,
      "text": text,
      "status": status
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "AttributeModule/GetAttributes", payload, { headers });
};

const deleteSection = (sessionID, id) => {
  var payload = {
    "functionName": "DeleteSection",
    "postData": {
      "section_id": id
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "SectionModule/DeleteSection", payload, { headers });
};

const deleteAttribute = (sessionID, id) => {
  var payload = {
    "functionName": "DeleteAttribute",
    "postData": {
      "attribute_id": id
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "AttributeModule/DeleteAttribute", payload, { headers });
};

const deleteBulkSection = (sessionID, selectedRows) => {
  var payload = {
    "functionName": "DeleteBulkSection",
    "postData": {
      "section_ids": selectedRows
    }
  }

  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }

  return axios.post(B_URL + "SectionModule/DeleteBulkSection", payload, { headers });
};

const deleteBulkAttribute = (sessionID, selectedRows) => {
  var payload = {
    "functionName": "DeleteBulkAttribute",
    "postData": {
      "attribute_ids": selectedRows
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "AttributeModule/DeleteBulkAttribute", payload, { headers });
};

const AddBusinessAttribute = (data) => {
  // console.log("DATA:", data);
  var payload = {
    "functionName": "SetBusinessAttribute",
    "postData": {
      "business_id": data.business_id,
      "section_id": data.section_id,
      "attribute_id": data.attribute_id,
      "value": data.value,
      "field_type": data.field_type,
      "options": data.options,
      "getOptionsFrom": data.getOptionsFrom,
      "getOptionFieldName": data.getOptionFieldName
    }
  }
  return axios.post(B_URL + "BusinessAttributeModule/SetBusinessAttribute", payload, { headers });
};

const deleteBusinessAttribute = (id) => {
  var payload = {
    "functionName": "DeleteBusinessAttribute",
    "postData": {
      "business_attribute_id": id
    }
  }
  return axios.post(B_URL + "BusinessAttributeModule/DeleteBusinessAttribute", payload, { headers });
};

const AddBusiness = (data) => {
  // console.log("DATA:", data);
  var payload = {
    "functionName": "AddBusiness",
    "postData": {
      "name": data.name,
      "email": data.email,
      "status": data.status
    }
  }
  return axios.post(B_URL + "BusinessModule/AddBusiness", payload, { headers });
};

const deleteBusiness = (id) => {
  var payload = {
    "functionName": "DeleteBusiness",
    "postData": {
      "business_id": id
    }
  }
  return axios.post(B_URL + "BusinessModule/DeleteBusiness", payload, { headers });
};

const deleteBulkBusiness = (selectedRows) => {
  var payload = {
    "functionName": "DeleteBulkBusiness",
    "postData": {
      "business_ids": selectedRows
    }
  }
  return axios.post(B_URL + "BusinessModule/DeleteBulkBusiness", payload, { headers });
};

const EditBusiness = (data) => {
  // console.log("DATA:", data);
  var payload = {
    "functionName": "UpdateBusiness",
    "postData": {
      "business_id": data.business_id,
      "name": data.name,
      "email": data.email,
      "status": data.status
    }
  }
  return axios.post(B_URL + "BusinessModule/UpdateBusiness", payload, { headers });
};

const fetchcollections = (sessionID) => {

  var payload = {
    functionName: "FetchCollections",
    "postData": {}
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(API_URL, payload, { headers });
}

const getoptions = (referal_name, filter, fieldlist, limit, offset) => {
  var payload = {
    functionName: "GetCollectionData",
    postData: {
      "collection_name": referal_name,
      "login_user_id": '',
      "fieldlist": fieldlist,
      "filter": filter,
      "limit": limit,
      "offset": offset
    },
  }
  return axios.post(API_URL, payload, { headers });
};

const GetSingleBusiness = (id) => {
  var payload = {
    "functionName": "GetSingleBusiness",
    "postData": {
      "id": id
    }
  }
  return axios.post(B_URL + "BusinessModule/GetSingleBusiness", payload, { headers });
};

const getAllItemsByBusiness = (sessionID, page, limit, business_id) => {
  var payload = {
    "functionName": "GetItems",
    "postData": {
      "offset": page,
      "sort": {
        "_id": -1
      },
      "business_id": business_id,
      "limit": limit
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "ItemModule/GetItems", payload, { headers });
};

const getShippingZones = (sessionID, businessId) => {
  var payload = {
    "functionName": "GetShippingZones",
    "postData": {
      "business_id": businessId
    }
  };
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  };
  return axios.post(B_URL + "ShippingModule/GetShippingZones", payload, { headers });
};

const addShippingZone = (sessionID, businessId, zoneData) => {
  var payload = {
    "functionName": "AddShippingZone",
    "postData": {
      "business_id": businessId,
      "name": zoneData.name,
      "regions": zoneData.regions,
      "shipment_method": zoneData.shipment_method,
      "flat_rate": zoneData.flat_rate,
      "per_kg_rate": zoneData.per_kg_rate
    }
  };
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  };
  return axios.post(B_URL + "ShippingModule/AddShippingZone", payload, { headers });
};

const updateShippingZone = (sessionID, businessId, zoneId, zoneData) => {
  var payload = {
    "functionName": "UpdateShippingZone",
    "postData": {
      "business_id": businessId,
      "_id": zoneId,
      "name": zoneData.name,
      "regions": zoneData.regions,
      "shipment_method": zoneData.shipment_method,
      "flat_rate": zoneData.flat_rate,
      "per_kg_rate": zoneData.per_kg_rate,
      "status": zoneData.status
    }
  };
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  };
  return axios.post(B_URL + "ShippingModule/UpdateShippingZone", payload, { headers });
};

const deleteShippingZone = (sessionID, businessId, zoneId) => {
  var payload = {
    "functionName": "DeleteShippingZone",
    "postData": {
      "business_id": businessId,
      "_id": zoneId
    }
  };
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  };
  return axios.post(B_URL + "ShippingModule/DeleteShippingZone", payload, { headers });
};

const getLalamoveSettings = (sessionID, businessId) => {
  var payload = {
    "functionName": "GetLalamoveSettings",
    "postData": {
      "business_id": businessId
    }
  };
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  };
  return axios.post(B_URL + "ShippingModule/GetLalamoveSettings", payload, { headers });
};

const getGeneralData = (sessionID, businessId) => {
  var payload = {
    "functionName": "getGeneralData",
    "postData": {
      "business_id": businessId
    }
  };
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  };
  return axios.post(B_URL + "ShippingModule/getGeneralData", payload, { headers });
};

const addGeneralData = (sessionID, businessId, data) => {
  var payload = {
    "functionName": "addGeneralData",
    "postData": {
      "business_id": businessId,
      "distance_for_free_delivery": data.distance_for_free_delivery,
      "order_amount_for_free_delivery": data.order_amount_for_free_delivery,
    }
  };
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  };
  return axios.post(B_URL + "ShippingModule/addGeneralData", payload, { headers });
};

const updateGeneralData = (sessionID, businessId, data) => {
  var payload = {
    "functionName": "updateGeneralData",
    "postData": {
      "business_id": businessId,
      "_id": data._id,
      "distance_for_free_delivery": data.distance_for_free_delivery,
      "order_amount_for_free_delivery": data.order_amount_for_free_delivery,
    }
  };
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  };
  return axios.post(B_URL + "ShippingModule/updateGeneralData", payload, { headers });
};


const addLalamoveSettings = (sessionID, businessId, data) => {
  var payload = {
    "functionName": "AddLalamoveSettings",
    "postData": {
      "business_id": businessId,
      "host_name": data.host_name,
      "api_secret": data.api_secret,
      "api_key": data.api_key,
    }
  };
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  };
  return axios.post(B_URL + "ShippingModule/AddLalamoveSettings", payload, { headers });
};

const updateLalamoveSettings = (sessionID, businessId, data) => {
  var payload = {
    "functionName": "UpdateLalamoveSettings",
    "postData": {
      "business_id": businessId,
      "_id": data._id,
      "host_name": data.host_name,
      "api_secret": data.api_secret,
      "api_key": data.api_key,
    }
  };
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  };
  return axios.post(B_URL + "ShippingModule/UpdateLalamoveSettings", payload, { headers });
};

const fetchStatus = (lalamove, sessionID, business_id) => {
  var payload = {
    "functionName": "fetchStatus_DeliveryMethod",
    "postData": {
      "business_id": business_id,
      "deliveryMethod": lalamove
    }
  };
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  };
  return axios.post(B_URL + "ShippingModule/fetchStatus_DeliveryMethod", payload, { headers });
};

const getDeliveryMethodStatus = (sessionID, businessId, method) => {
  var payload = {
    "functionName": "getDeliveryMethodStatus",
    "postData": {
      "business_id": businessId,
      "method": method
    }
  };
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  };
  return axios.post(B_URL + "ShippingModule/getDeliveryMethodStatus", payload, { headers });
};

const updateDeliveryMethodStatus = (sessionID, businessId, method, status) => {
  var payload = {
    "functionName": "updateDeliveryMethodStatus",
    "postData": {
      "business_id": businessId,
      "method": method,
      "status": status
    }
  };
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  };
  return axios.post(B_URL + "ShippingModule/updateDeliveryMethodStatus", payload, { headers });
};


const BusinessService = {
  getLalamoveSettings,
  fetchStatus,
  getDeliveryMethodStatus,
  updateDeliveryMethodStatus,
  getGeneralData,
  addGeneralData,
  updateGeneralData,
  addLalamoveSettings,
  updateLalamoveSettings,
  getShippingZones,
  addShippingZone,
  updateShippingZone,
  deleteShippingZone,
  getAllBusinesses,
  getAllBusinessAttributes,
  updateMultipleBusinessAttributes,
  getAllSections,
  getAllAttributes,
  AddSection,
  AddAttribute,
  GetSingleSection,
  EditSection,
  GetSingleAttribute,
  EditAttribute,
  deleteSection,
  deleteAttribute,
  deleteBulkSection,
  deleteBulkAttribute,
  AddBusinessAttribute,
  deleteBusinessAttribute,
  AddBusiness,
  deleteBusiness,
  deleteBulkBusiness,
  EditBusiness,
  fetchcollections,
  getoptions,
  GetSingleBusiness,
  getAllItemsByBusiness
};

export default BusinessService;