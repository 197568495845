import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Row, Col, CardBody, Card } from "reactstrap";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import DataTable from 'react-data-table-component';
import DiscountCouponService from "../DiscountCoupon/Discountcoupons.service";
import AuthService from "../Authentication/auth.service";
import Alerts from "../../components/Alerts/alerts";
import UserStatementFilter from "../../components/Table Filters/UserStatementFilter";
import PermissionAccess from '../../PermissionAccess/Access.js';
import moment from 'moment';

const UserStatement = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [statementData, setStatementData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filterBusiness, setFilterBusiness] = useState('');
    const [filterUser, setFilterUser] = useState('');
    const [filterDate, setFilterDate] = useState('');
    const [pageLimit, setPageLimit] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const { authData } = PermissionAccess();
    const [dateRange, setDateRange] = useState({ from: '', to: '' });
    const [totalBalance, setTotalBalance] = useState(null);

    useEffect(() => {
        i18n.changeLanguage(localStorage.getItem('language'));
        fetchStatementData(currentPage, pageLimit, filterBusiness,filterUser, '');
    }, [])

    const fetchStatementData = (page_index, page_limit, business = filterBusiness, user = filterUser, date = filterDate) => {
        setLoading(true);
        const stored_user = authData.userData;
        let businessIDs = []
        if (stored_user.businesses) {
            businessIDs = stored_user.businesses
        }
        DiscountCouponService.getUserStatement(authData.sessionID, page_index, page_limit, business, user, date, businessIDs).then(
            (response) => {
                setLoading(false);
                if (response.data.message === "Unauthorized") {
                    AuthService.logout();
                    navigate("/login");
                    window.location.reload();
                } else {
                    setStatementData(response.data.data);
                    setTotalRows(response.data.count.count);
                    if (response.data.data.length > 0) {
                        setTotalBalance(response.data.data[0].total_balance);
                    } else {
                        setTotalBalance(null);
                    }
                }
            },
            (error) => {
                setLoading(false);
                const errContent =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
                Alerts.swalErrorAlert(errContent || t("Server Error, Please try again later."));
            }
        );
    }

    const mycolumns = [
        {
            name: t('Date'),
            selector: 'date',
            width: "20%",
            sortable: true,
            cell: row => moment(row.date).format('YYYY-MM-DD HH:mm:ss A')
        },

        {
            name: t('Description'),
            selector: 'action',
            width: "40%",
            sortable: true,
            cell: row => `${row.action} ${row.status === 'IN' ? '' : ''}`
        },
        {
            name: t('IN'),
            selector: 'amount',
            width: "20%",
            sortable: true,
            cell: row => row.status === 'IN' ? row.amount.toFixed(2) : ''
        },
        {
            name: t('OUT'),
            selector: 'amount',
            width: "20%",
            sortable: true,
            cell: row => row.status === 'OUT' ? row.amount.toFixed(2) : ''
        }
    ];

    const handleSearch = (business,user, date) => {
        setFilterBusiness(business);
        setFilterUser(user);
        setFilterDate(date);
        if (date) {
            const [from, to] = date.split(' - ');
            setDateRange({ from, to });
        } else {
            setDateRange({ from: '', to: '' });
        }
        fetchStatementData(0, pageLimit, business,user, date);
        setCurrentPage(0);
    };

    const handleClear = () => {
        setFilterBusiness('');
        setFilterUser('');
        setFilterDate('');
        setDateRange({ from: '', to: '' });
        fetchStatementData(0, pageLimit, '', '');
        setCurrentPage(0);
    };

    const subHeaderComponentMemo = React.useMemo(() => {
        return (
            <UserStatementFilter
                onSearch={handleSearch}
                onClear={handleClear}
                filterBusiness={filterBusiness}
                filterUser={filterUser}
                filterDate={filterDate}
            />
        );
    }, [filterBusiness,filterUser, filterDate]);

    const handlePageChange = page => {
        setCurrentPage(page - 1);
        fetchStatementData(page - 1, pageLimit, filterBusiness,filterUser, filterDate);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPageLimit(newPerPage);
        setCurrentPage(page - 1);
        fetchStatementData(page - 1, newPerPage, filterBusiness,filterUser, filterDate);
    };


    const padRight = (string, length) => {
        return string.padEnd(length, ' ');
    };

    const generateCSV = () => {
        const dateRangeStr = dateRange.from && dateRange.to
            ? `(${dateRange.from} - ${dateRange.to})`
            : '';
        let csvContent = `Credit Statement ${dateRangeStr}\n\n`;
        csvContent += "Date,Description,IN,OUT\n";

        const columnWidths = {
            date: 25,
            description: 30,
            in: 15,
            out: 15
        };

        statementData.forEach(row => {
            const date = padRight(moment(row.date).format('YYYY-MM-DD'), columnWidths.date);
            let description = row.action.split(' ')[0]; // Get the first word of the action
            if (description.toLowerCase().includes('topup')) {
                description = 'Topup';
            } else if (description.toLowerCase().includes('order')) {
                description = row.status === 'IN' ? 'Order Cancelled' : 'Order Placed';
            } else if (description.toLowerCase().includes('redeem')) {
                description = 'Redeemed';
            }
            description = padRight(description, columnWidths.description);
            const inAmount = padRight(row.status === 'IN' ? row.amount.toFixed(2) : '', columnWidths.in);
            const outAmount = padRight(row.status === 'OUT' ? row.amount.toFixed(2) : '', columnWidths.out);
            csvContent += `${date},${description},${inAmount},${outAmount}\n`;
        });

        return csvContent;
    };

    const downloadCSV = () => {
        const csvContent = generateCSV();
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const dateRangeStr = dateRange.from && dateRange.to
            ? `${dateRange.from.replace(/\//g, '-')}_${dateRange.to.replace(/\//g, '-')}`
            : 'all_time';
        const fileName = `credit_statement_${dateRangeStr}.csv`;
        
        // Create a temporary URL for the Blob
        const url = window.URL.createObjectURL(blob);
        
        // Create a temporary anchor element and trigger the download
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        
        // Clean up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    };

    return (
        <Fragment>
            <div className="app-main__outer">
                <div className="app-main__inner">
                    <div className="bg-light-gray bg-animation">
                        <div className="d-flex justify-content-center align-items-center">
                            <Col md="12" className="mx-auto app-login-box">
                                <div className="app-page-title">
                                    <div className="page-title-wrapper">
                                        <div className="page-title-heading fw-bold">
                                            <div className="page-title-icon">
                                                <i className="pe-7s-news-paper icon-gradient bg-tempting-azure" />
                                            </div>
                                            <div>
                                                {t("Credit Statement")}
                                                <div className="page-title-subheading">
                                                    {t("Credit Statement Filters")}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Card className="main-card mb-3">
                                    <CardBody>
                                        {subHeaderComponentMemo}
                                        <Row className="mb-3">
                                        {filterUser && totalBalance !== null && (
                                            <Col md="12">
                                                <h3 className="m-2" style={{ color: 'red' }}>
                                                    {t("Balance")}: {totalBalance.toFixed(2)}
                                                </h3>
                                            </Col>
                                        )}
                                        </Row>
                                        <LoadingOverlay
                                            active={loading}
                                            spinner={<DNALoader />}
                                        >
                                            <DataTable
                                                columns={mycolumns}
                                                data={statementData}
                                                pagination
                                                paginationServer
                                                paginationTotalRows={totalRows}
                                                onChangePage={handlePageChange}
                                                onChangeRowsPerPage={handlePerRowsChange}
                                                paginationResetDefaultPage={resetPaginationToggle}
                                                persistTableHead
                                                fixedHeader
                                                fixedHeaderScrollHeight="400px"
                                                subHeader
                                                subHeaderComponent={
                                                    <div>
                                                        <button
                                                            type="button"
                                                            className="btn btn-custom-color btn-hover-shine me-3 btn"
                                                            onClick={downloadCSV}
                                                        >
                                                            {t('Export')}
                                                        </button>
                                                    </div>
                                                }
                                            />
                                        </LoadingOverlay>
                                    </CardBody>
                                </Card>
                            </Col>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default UserStatement;
