import React, { useState, useEffect, useRef } from 'react';
import { Card, Row, Col, Input, Button, Form, FormGroup, Label, Modal, ModalBody, Table, Alert, CardBody } from 'reactstrap';
import { FaPlus, FaCheck, FaTrash, FaArrowLeft, FaEdit } from 'react-icons/fa';
import { faCommentAlt, faBicycle, faWalking, faMapMarkerAlt, faCheckCircle, FaCreditCard, faShoppingBasket, faPaperPlane, faEnvelope, faSearch, faTruck, faFire, faClipboardList, faClock, faPhone, faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import RejectQuotationPopup from './RejectQuotationPopup';
import DashboardService from "../Dashboard/dashboard.service";
import AuthService from "../Authentication/auth.service";
import Alerts from "../../components/Alerts/alerts";
import { useTranslation } from 'react-i18next';
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DiscountCouponService from "../DiscountCoupon/Discountcoupons.service";
import QuotationModal from './QuotationModal';
import {
  faSpinner,
  faBox, faBan, faQuestion
} from '@fortawesome/free-solid-svg-icons';
import services from "./services";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { FaDownload } from 'react-icons/fa';

const ViewRfqs = () => {
  const [rfqDetails, setRfqDetails] = useState(null);
  const [orderCreditNoteDetails, setOrderCreditNoteDetails] = useState(null);
  const [orderTrackingDetails, setOrderTrackingDetails] = useState(null);
  const [orderInvoiceDetails, setInvoiceDetails] = useState(null);
  const [orderPaymentDetails, setPaymentDetails] = useState(null);
  const [activeTab, setActiveTab] = useState('details');
  const [loading, setLoading] = useState(true);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = location.state;
    const chatContainerRef = useRef(null);
  const { t } = useTranslation();
  const sessionID = localStorage.getItem("session-id");
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);
  const [quotationDetails, setQuotationDetails] = useState(null);
  const [isQuotationModalOpen, setIsQuotationModalOpen] = useState(false);
  const [outOfStockItems, setOutOfStockItems] = useState([]);
  const [discount, setDiscount] = useState(0);
  const [paymentTerms, setPaymentTerms] = useState('net-30');
// Helper functions
const [returnReason, setReturnReason] = useState('');

const formatStatus = (status) => {
  if (!status) return '';
  return status
    ?.toLowerCase()
    ?.split('_')
    ?.map(word => word.charAt(0).toUpperCase() + word.slice(1))
    ?.join(' ');
};

const calculateTotals = (items) => {
  const subtotal = items?.reduce((total, item) => {
    const availableQuantity = item?.selectedBatches?.reduce((sum, batch) => sum + batch.quantity, 0) || 0;
    return total + (availableQuantity * item?.price || 0);
  }, 0);
  const discount = rfqDetails?.discount || 0;
  const discountAmount = discount ? (discount / 100) * subtotal : 0;
  const totalAmount = subtotal - discountAmount;
  return { subtotal, discountPercentage: discountAmount, totalAmount };
};

const renderItemRows = (items) => {
  let rows = [];
  const groupedItems = items?.reduce((acc, item) => {
    const category = item?.categoryDetails?.Name || 'Uncategorized';
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(item);
    return acc;
  }, {});

  Object.entries(groupedItems).forEach(([category, categoryItems]) => {
    let isFirstItemInCategory = true;
    categoryItems.forEach((item, itemIndex) => {
      const batches = item?.selectedBatches || [{}];
      batches.forEach((batch, batchIndex) => {
        rows.push(
          <tr key={`${category}-${itemIndex}-${batchIndex}`}>
            {isFirstItemInCategory && (
              <td rowSpan={categoryItems.reduce((sum, item) => sum + (item.selectedBatches?.length || 1), 0)}>
                {category}
              </td>
            )}
            {batchIndex === 0 && (
              <>
                <td rowSpan={batches.length}>
                  <div className="d-flex align-items-center">
                    {item?.itemDetails?.images && (
                      <img
                        src={item?.itemDetails?.images[(item?.itemDetails?.index - 1) || 0]}
                        alt={item?.itemDetails?.item_name}
                        className="item-image me-2"
                      />
                    )}
                    <span>{item?.itemDetails?.item_name}</span>
                  </div>
                </td>
                <td rowSpan={batches.length}>{item?.quantity}</td>
                <td rowSpan={batches.length}>{item?.price ? `RM ${parseFloat(item?.price).toFixed(2)}` : '-'}</td>
              </>
            )}
            <td>{batch.batch_number || '-'}</td>
            <td>{batch.expiry ? moment(batch.expiry).format('DD-MM-YYYY') : '-'}</td>
            <td>{`RM ${((batch.quantity || 0) * (item?.price || 0)).toFixed(2)}`}</td>
          </tr>
        );
        if (batchIndex === 0) {
          isFirstItemInCategory = false;
        }
      });
    });
  });
  return rows;
};

const formatCurrency = (value) => {
  const parsedValue = parseFloat(value);
  return !isNaN(parsedValue) ? parsedValue.toFixed(2) : '0.00';
};

  useEffect(() => {
    fetchRfqDetails();
  }, [id]);

  const fetchRfqDetails = async () => {
    setLoading(true);
    try {
      var payload = {
        functionName: "GetRFQDetails",
        postData: {
          _id: id,
        }
      }

      const response = await DashboardService.GetRFQDetails(payload);
      if (response.data.status) {
        console.log("response.data.data", response.data.data)
        setRfqDetails(response?.data?.data?.[0]);
        setReturnReason(response?.data?.data?.[0]?.return_reason || '')
        setOrderTrackingDetails(response?.data?.orderTrackingDetails);
        setOrderCreditNoteDetails(response?.data?.orderCreditNoteDetails);
        setInvoiceDetails(response?.data?.orderInvoiceDetails);
        setPaymentDetails(response?.data?.orderPaymentDetails);
        setDiscount(response?.data?.data?.[0]?.discount || 0);
      } else {
        Alerts.swalErrorAlert(response.data.message || t("Failed to fetch RFQ details"));
      }
    } catch (error) {
      console.error("Error fetching RFQ details:", error);
      Alerts.swalErrorAlert(t("An error occurred while fetching RFQ details"));
    } finally {
      setLoading(false);
    }
  };

  const openPopup = () => setPopupVisible(true);
  const closePopup = () => setPopupVisible(false);

  const handleEditQuotation = () => {
    setIsEditMode(true);
    IssueQuotation();
  };

  const fetchMessages = async () => {
    try {
      const response = await DiscountCouponService.GetOrderMessages(sessionID, id, 'admin');
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      } else if (response.data) {
        setMessages(response.data.messages);
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  const sendMessage = async () => {
    if (newMessage.trim() === '') return;

    try {
      await DiscountCouponService.SendOrderMessage(sessionID, id, newMessage, 'admin');
      setNewMessage('');
      fetchMessages(); // Refresh messages after sending
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };
  const IssueQuotation = async () => {
    try {
      var payload = {
        functionName: "GetQuotationDetails",
        postData: {
          _id: rfqDetails?._id,
        }
      }

      const response = await services.GetQuotationDetails(payload, sessionID);
      if (response.data.status) {
        console.log("response", response)
        setQuotationDetails(response.data.data);
        setOutOfStockItems(response?.data?.data?.outOfStockItems || []);
        setIsQuotationModalOpen(true);
      } else {
        Alerts.swalErrorAlert(response.data.message || t("Failed to get quotation details"));
      }
    } catch (error) {
      console.error("Error getting quotation details:", error);
      Alerts.swalErrorAlert(t("An error occurred while getting quotation details"));
    }
  };

  const handleAccept = async () => {
    try {
      var payload = {
        functionName: "AcceptRFQ",
        postData: {
          _id: id,
        }
      }
      const response = await DashboardService.AcceptRFQ(payload);
      if (response.data.status) {
        Alerts.swalSuccessAlert(t("RFQ accepted successfully"));
        fetchRfqDetails();
      } else {
        Alerts.swalErrorAlert(response.data.message || t("Failed to accept RFQ"));
      }
    } catch (error) {
      console.error("Error accepting RFQ:", error);
      Alerts.swalErrorAlert(t("An error occurred while accepting the RFQ"));
    }
  };

  const HandlePayment = (row) => {
    console.log("row", row);
    var getData = {
      functionName: "OnlinePayment",
      row: row,
      invoice_id: row?._id ? row?._id : null,
      amount: parseFloat(row.total_amount).toFixed(2),
      invoice_number: row.invoice_number,
      business_id: row?.business_id
    }
    console.log("getData", getData);
    setLoading(true);
    DashboardService.OnlinePayment(getData).then((response) => {
      console.log(response);
      setLoading(false);
      if (response.status === 200) {
        console.log(response);
        window.open(response.data.data, '_blank');
        console.log('Response : ', response);
      } else if (response.status === 401) {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      } else {
        window.open(response.data.data, '_blank');
      }
    },
    (error) => {
      setLoading(false);
      const errContent =
        (error.response && error.response.data) || error.message || error.toString();
      console.log("Net:-", errContent);
      if (errContent) {
        Alerts.swalErrorAlert(errContent);
      } else {
        Alerts.swalErrorAlert(t("Server Error, Please try again later."));
      }
    })
  }

  const handlePriceChange = (index, newPrice) => {
    setQuotationDetails(prevDetails => {
      const updatedItems = [...prevDetails.items];
      updatedItems[index] = { ...updatedItems[index], price: newPrice };
      return {
        ...prevDetails,
        items: updatedItems
      };
    });
  };

  const handleDiscountChange = (newDiscount) => {
    setDiscount(newDiscount);
  };

  const handlePaymentTermsChange = (newTerms) => {
    setPaymentTerms(newTerms);
  };

  const handleBatchChange = (itemIndex, batchIndex, newBatchId) => {
    setQuotationDetails(prevDetails => {
      const newItems = [...prevDetails.items];
      const item = newItems[itemIndex];
      const newBatch = item.availableBatches.find(batch => batch.batchId === newBatchId);

      if (newBatch) {
        item.selectedBatches[batchIndex] = {
          batchId: newBatch.batchId,
          batch_number: newBatch.batch_number,
          quantity: Math.min(newBatch.quantity, item.selectedBatches[batchIndex].quantity),
          expiry: newBatch.expiry
        };
      }

      return { ...prevDetails, items: newItems };
    });
  };

  const handleQuantityChange = (itemIndex, batchIndex, newQuantity) => {
    setQuotationDetails(prevDetails => {
      const newItems = [...prevDetails.items];
      const item = newItems[itemIndex];
      const batch = item.selectedBatches[batchIndex];
      const availableBatch = item.availableBatches.find(ab => ab.batchId === batch.batchId);

      if (availableBatch) {
        const parsedQuantity = parseInt(newQuantity, 10);
        batch.quantity = Math.min(Math.max(1, parsedQuantity), availableBatch.quantity);
      }

      return { ...prevDetails, items: newItems };
    });
  };

  const saveQuotation = async () => {
    try {
      const quotationData = {
        ...quotationDetails,
        discount,
        paymentTerms,
        rfq_id: rfqDetails?._id,
        isEdit: isEditMode
      };
      const response = await services.SaveQuotation(quotationData, sessionID);
      if (response.data.status) {
        Alerts.swalSuccessAlert(t(isEditMode ? "Quotation updated successfully" : "Quotation saved successfully"));
        setIsQuotationModalOpen(false);
        setIsEditMode(false);
        fetchRfqDetails();
      } else {
        Alerts.swalErrorAlert(response.data.message || t("Failed to save quotation"));
      }
    } catch (error) {
      console.error("Error saving quotation:", error);
      Alerts.swalErrorAlert(t("An error occurred while saving the quotation"));
    }
  };

  const finalIssueQuotation = async () => {
    try {
      let data;
      if (quotationDetails === null) {
        data = rfqDetails;
      } else {
        data = quotationDetails;
      }
      console.log("data", data)
      var payload = {
        ...data,
        discount,
        functionName: "finalIssueQuotation",
        paymentTerms,
        _id: rfqDetails?._id,
      }

      const response = await services.FinalIssueQuotation(payload, sessionID);
      if (response.data.status) {
        Alerts.swalSuccessAlert(t("Quotation issued successfully"));
        setIsQuotationModalOpen(false);
        fetchRfqDetails();
      } else {
        Alerts.swalErrorAlert(response.data.message || t("Failed to issue quotation"));
      }
    } catch (error) {
      console.error("Error issuing quotation:", error);
      Alerts.swalErrorAlert(t("An error occurred while issuing the quotation"));
    }
  };

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'pending':
        return '#FFA500';
      case 'confirmed':
        return '#4169E1';
      case 'processing':
        return '#9370DB';
      case 'in_transit':
        return '#1E90FF';
      case 'delivered':
        return '#32CD32';
      case 'cancelled':
        return '#DC143C';
      case 'order paid':
        return '#DC143C';
      case 'order received':
        return '#DC143C';
      default:
        return '#808080';
    }
  };

  const getIconForStatus = (status) => {
    switch (status.toLowerCase()) {
      case 'pending':
        return faClock;
      case 'confirmed':
        return faCheckCircle;
      case 'processing':
        return faSpinner;
      case 'in_transit':
        return faTruck;
      case 'delivered':
        return faBox;
      case 'cancelled':
        return faBan;
      case 'order paid':
        return faFire;
      case 'order received':
        return faClipboardList;
      default:
        return faQuestion;
    }
  };

  const renderOrderDetails = () => (
    <div className="item-list mb-3">
      <div className="container mt-4">
        {isPopupVisible && <RejectQuotationPopup quotationId={id} rfq_id={rfqDetails?.rfq_id} onClose={closePopup} fetchRfqDetails={fetchRfqDetails} />}
        <LoadingOverlay active={loading ? true : false} spinner={<DNALoader />} >
          <Card className="rfq-card">
            <div className="p-4">
              <Form>
                <Row className="mb-4">
                  <Col md={6}>
                    <FormGroup>
                      <Label for="rfq_date" className="text-muted mb-2">{t("RFQ Date")}</Label>
                      <Input type="text" id="rfq_date" className="form-select" readOnly value={new Date(rfqDetails?.datetime_added).toLocaleDateString()} />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="status" className="text-muted mb-2">{t("Status")}</Label>
                      <Input type="text" id="status" className="form-select" readOnly value={rfqDetails?.status} />
                    </FormGroup>
                  </Col>
                  {rfqDetails?.status === "rejected" &&
                    <Col md={12}>
                      <FormGroup>
                        <Label for="reason" className="text-muted mb-2">{t("Rejection Reason")}</Label>
                        <Input type="textarea" id="reason" readOnly value={rfqDetails?.reason} />
                      </FormGroup>
                    </Col>
                  }
                </Row>

                <h5 className="mb-3 fw-bold">{t("List of Item(s)")}</h5>
                <div className="item-list mb-3">
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>{t("Category")}</th>
                        <th>{t("Item(s) - Brand")}</th>
                        <th>{t("Quantity")}</th>
                        <th>{t("Unit Price")}</th>
                        <th>{t("Batches")}</th>
                        <th>{t("Expiry Date")}</th>
                        <th>{t("Total Price")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {renderItemRows(rfqDetails?.items || [])}
                    </tbody>
                  </Table>
                </div>

                {rfqDetails?.outOfStockItems?.length > 0 && (
                  <Alert color="warning">
                    The following items are out of stock or have insufficient quantity:
                    <ul>
                      {rfqDetails?.outOfStockItems?.map((item, index) => (
                        <li key={index}>
                          {item.item_name}: {item.extra_quantity} units out of stock
                        </li>
                      ))}
                    </ul>
                  </Alert>
                )}

                {rfqDetails?.status !== "requested" && rfqDetails?.status !== "Review" ?
                  <div className="totals text-end">
                    {(() => {
                      const { subtotal, discountPercentage, totalAmount } = calculateTotals(rfqDetails?.items || []);
                      return (
                        <>
                          {rfqDetails?.discount && rfqDetails?.discount !== null && rfqDetails?.discount !== '0' ?
                            <>
                              <div className="totals-row">
                                <span>{t("Subtotal")}:</span>
                                <span className="ms-3">RM {subtotal.toFixed(2)}</span>
                              </div>
                              <div className="totals-row">
                                <span>{t(`Discount(${rfqDetails?.discount || 0}%)`)}:</span>
                                <span className="ms-3">{parseFloat(discountPercentage).toFixed(2) || 0}</span>
                              </div>
                              <div className="totals-row total-amount fw-bold">
                                <span>{t("Total Amount")}:</span>
                                <span className="ms-3">RM {totalAmount.toFixed(2)}</span>
                              </div>
                            </>
                            :
                            <>
                              <div className="totals-row total-amount fw-bold">
                                <span>{t("Total Amount")}:</span>
                                <span className="ms-3">RM {totalAmount.toFixed(2)}</span>
                              </div>
                            </>
                          }
                        </>
                      );
                    })()}
                  </div>
                  : ''}
              </Form>
            </div>
          </Card>
        </LoadingOverlay>
      </div>
    </div>
  );

  const renderInvoiceDetails = () => {
    const invoice = orderInvoiceDetails;
    if (!invoice) return null;

    const downloadInvoice = () => {
      const input = document.getElementById('invoiceContent');
      html2canvas(input)
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF();
          const imgProps = pdf.getImageProperties(imgData);
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
          pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
          pdf.save(`invoice_${invoice.invoice_number}.pdf`);
        });
    };
    // Group items by category
    const groupedItems = invoice.item?.reduce((acc, item) => {
      const category = item.categoryDetails?.Name || 'Uncategorized';
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(item);
      return acc;
    }, {});

    return (
      <div className="invoice-container">
        <Card className="invoice-card" id="invoiceContent">
          <div className="invoice-header">
            <Row>
              <Col md={8}>
                <img src="/path/to/your/logo.png" alt="" className="company-logo" />
                <h2 className="company-name">Nano</h2>
                <p className="company-details">NO 323A, JALAN PUDU, 55100 KUALA LUMPUR.</p>
                <p className="company-details">Nano7@gmail.com</p>
                <p className="company-details">+60 1234567890</p>
              </Col>
              <Col md={4} className="text-end">
                <h1 className="invoice-title">INVOICE</h1>
                <table className="invoice-info-table">
                  <tbody>
                    <tr>
                      <th>Invoice Number:</th>
                      <td>{invoice.invoice_number}</td>
                    </tr>
                    <tr>
                      <th>Invoice Date:</th>
                      <td>{moment(invoice.datetime_added).format('DD MMM YYYY')}</td>
                    </tr>
                    <tr>
                      <th>Due Date:</th>
                      <td>{moment(invoice.due_date).format('DD MMM YYYY')}</td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </Row>
          </div>

          <div className="invoice-body">
            <Row>
              <Col md={6}>
                <div className="bill-to-section">
                  <h3 className="section-title">BILL TO</h3>
                  <p className="client-name">{rfqDetails?.userDetails?.[0]?.name}</p>
                  <p className="client-details">{rfqDetails?.userDetails?.[0]?.email}</p>
                  <p className="client-details">{rfqDetails?.userDetails?.[0]?.Address}</p>
                </div>
              </Col>
              <Col md={6}>
                <div className="payment-section text-end">
                  <h3 className="section-title">PAYMENT DETAILS</h3>
                  <p className="payment-details"><strong>Method:</strong> {invoice.paymentMethod}</p>
                  <p className="payment-details"><strong>Terms:</strong> Due by {moment(invoice.due_date).format('DD MMM YYYY')}</p>
                </div>
              </Col>
            </Row>

            <div className="invoice-items">
              <table>
                <thead>
                  <tr>
                    <th>Category</th>
                    <th>Item Details</th>
                    <th>Batch</th>
                    <th>Quantity</th>
                    <th>Unit Price</th>
                    <th>Tax</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(groupedItems).map(([category, items], categoryIndex) => (
                    <React.Fragment key={categoryIndex}>
                      {items.map((item, itemIndex) => (
                        item.selectedBatches?.map((batch, batchIndex) => (
                          <tr key={`${categoryIndex}-${itemIndex}-${batchIndex}`}>
                            {batchIndex === 0 && itemIndex === 0 && (
                              <td rowSpan={items.reduce((sum, item) => sum + (item.selectedBatches?.length || 1), 0)}>
                                {category}
                              </td>
                            )}
                            {batchIndex === 0 && (
                              <td rowSpan={item.selectedBatches?.length || 1}>
                                <strong>{item.item_name}</strong>
                                <br />
                                <small>Item Code: {item.item_code || 'N/A'}</small>
                              </td>
                            )}
                            <td>{batch.batch_number}</td>
                            <td>{batch.quantity}</td>
                            <td>RM {parseFloat(item.price).toFixed(2)}</td>
                            <td>RM {parseFloat(item.tax || 0).toFixed(2)}</td>
                            <td>RM {(parseFloat(item.price) * parseFloat(batch.quantity)).toFixed(2)}</td>
                          </tr>
                        ))
                      ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="invoice-summary">
              <Row>
                <Col md={8}>
                  <div className="invoice-notes">
                    <h4>Notes</h4>
                    <p>{invoice.comments || 'Thank you for your business!'}</p>
                  </div>
                </Col>
                <Col md={4}>
                  <table className="summary-table">
                    <tbody>
                    {invoice.discount === 0 || invoice.discount === null ?
                      <tr className="total-row">
                        <th>Total:</th>
                        <td>RM {parseFloat(invoice.total_amount).toFixed(2)}</td>
                      </tr>
                      :  
                      <>
                      <tr>
                        <th>Subtotal:</th>
                        <td>RM {parseFloat(invoice.sub_total).toFixed(2)}</td>
                      </tr>
                      <tr>
                        <th>Discount ({invoice.discount}%):</th>
                        <td>RM {parseFloat(invoice.discount_amount || 0).toFixed(2)}</td>
                      </tr>
                      <tr>
                        <th>Tax:</th>
                        <td>RM {parseFloat(invoice.tax || 0).toFixed(2)}</td>
                      </tr>
                      <tr className="total-row">
                        <th>Total:</th>
                        <td>RM {parseFloat(invoice.total_amount).toFixed(2)}</td>
                      </tr>
                      </>
  }
                    </tbody>
                  </table>
                </Col>
              </Row>
            </div>
          </div>

          <div className="invoice-footer">
            <p>If you have any questions about this invoice, please contact us</p>
            <p>Thank you for your business!</p>
          </div>
        </Card>

        <style jsx>{`
          .invoice-container {
            background-color: #f8f9fa;
            padding: 20px;
          }
          .invoice-card {
            background-color: white;
            border: none;
            box-shadow: 0 0 30px rgba(0,0,0,0.1);
            padding: 40px;
            margin-bottom: 20px;
          }
          .invoice-header {
            border-bottom: 2px solid #3D607A;
            padding-bottom: 20px;
            margin-bottom: 30px;
          }
          .company-logo {
            max-width: 200px;
            height: auto;
            margin-bottom: 15px;
          }
          .company-name {
            color: #3D607A;
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 10px;
          }
          .company-details {
            color: #6c757d;
            margin: 0;
            line-height: 1.5;
          }
          .invoice-title {
            color: #3D607A;
            font-size: 36px;
            font-weight: 700;
            margin: 0 0 20px 0;
          }
          .invoice-info-table {
          width: 100%;
          margin-top: 10px;
        }
        .invoice-info-table th, .invoice-info-table td {
          padding: 1px;
          text-align: right;
        }
        .invoice-info-table th {
          color: #3D607A;
          font-weight: 600;
          padding-right: 1px;
        }
        .invoice-info-table td {
          padding-left: 1px;
        }
          .invoice-body {
            margin-top: 30px;
          }
          .section-title {
            color: #3D607A;
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 15px;
            text-transform: uppercase;
          }
          .client-name {
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 5px;
          }
          .client-details, .payment-details {
            color: #6c757d;
            margin: 0;
            line-height: 1.5;
          }
          .payment-section {
            text-align: right;
          }
          .invoice-items {
            margin-top: 30px;
            margin-bottom: 30px;
          }
.invoice-items table {
          width: 100%;
          border-collapse: separate;
          border-spacing: 0;
        }
        .invoice-items th, .invoice-items td {
          border: 1px solid #dee2e6;
          padding: 10px;
          text-align: left;
        }
        .invoice-items th {
          background-color: #3D607A;
          color: white;
          font-weight: 600;
        }
        .invoice-items tr:nth-child(even) {
          background-color: #f8f9fa;
        }          .summary-table {
            width: 100%;
          }
          .summary-table th, .summary-table td {
            padding: 4px;
            text-align: right;
          }
          .summary-table th {
            color: #3D607A;
            font-weight: 600;
            padding-right: 1px;
          }
          .summary-table td {
            padding-left: 1px;
          }
          .total-row {
            font-size: 18px;
            font-weight: 700;
            color: #3D607A;
          }
          .invoice-notes {
            background-color: #f8f9fa;
            border-left: 4px solid #3D607A;
            padding: 15px;
          }
          .invoice-notes h4 {
            color: #3D607A;
            margin-bottom: 10px;
          }
          .invoice-footer {
            margin-top: 40px;
            text-align: center;
            color: #6c757d;
            font-size: 14px;
          }
        `}</style>
      </div>
    );
  };


  const renderMessagingTab = () => (
    <Card className="mb-3">
      <CardBody className="p-0">
        <div className="chat-container">
          <div
            className="messages-container"
            ref={chatContainerRef}
          >
            {messages?.map((msg, index) => (
              <div key={index} className={`message-wrapper ${msg.sender_type === 'Member' ? 'received' : 'sent'}`}>
                <div className="message-content">
                  <div className="message-sender">{msg.sender_type === 'admin' ? 'admin' : 'Member'}</div>
                  <div className="message-text">
                    {msg.message}
                  </div>
                  <div className="message-time">
                    {moment(msg.date).format('DD-MM-YYYY, h:mm A')}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="message-input">
            <input
              type="text"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              placeholder="Type a message"
            />
            <Button color="primary" onClick={sendMessage}>
              <FontAwesomeIcon icon={faPaperPlane} />
            </Button>
          </div>
        </div>
      </CardBody>
    </Card>
  );

  const renderTrackingContentDEMO = () => {
    if (!orderTrackingDetails || orderTrackingDetails?.length === 0) {
      return <div>No tracking information available</div>;
    }

    return (
      <div style={{
        backgroundColor: 'white',
        borderRadius: '10px',
        padding: '20px',
        boxShadow: '0 2px 5px rgba(0,0,0,0.1)'
      }}>
        {orderTrackingDetails?.map((step, index) => (
          <div key={index} style={{
            display: 'flex',
            marginBottom: '20px',
            position: 'relative'
          }}>
            <div style={{
              width: '40px',
              height: '40px',
              backgroundColor: getStatusColor(step.status),
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginRight: '20px',
              zIndex: 1
            }}>
              <FontAwesomeIcon icon={getIconForStatus(step.status)} style={{ color: 'white' }} />
            </div>
            {index !== orderTrackingDetails.length - 1 && (
              <div style={{
                position: 'absolute',
                left: '20px',
                top: '40px',
                bottom: '-20px',
                width: '2px',
                borderLeft: `2px dashed ${getStatusColor(step.status)}`,
                zIndex: 0
              }} />
            )}
            <div style={{ flex: 1 }}>
              <h3 style={{ margin: '0 0 5px 0', color: getStatusColor(step.status) }}>{formatStatus(step.status)}</h3>
              <p style={{ margin: '0', color: '#666' }}>
                <FontAwesomeIcon icon={faClock} style={{ marginRight: '5px' }} />
                {moment(step.date_time).format('DD-MM-YYYY, h:mm:ss a')}
              </p>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const downloadInvoice = () => {
    const input = document.getElementById('invoiceContent');
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save(`invoice_pdf.pdf`);
      });
  };
  const downloadPaymentReceipt = () => {
    const input = document.getElementById('paymentReceiptContent');
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save(`payment_receipt.pdf`);
      });
  };

  const downloadCreditNote = () => {
    const input = document.getElementById('creditNoteContent');
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save(`CreditNote.pdf`);
      });
  };

  const renderPaymentsDetails = () => {
    const payment = orderPaymentDetails[0];
    if (!payment) return null;


    return (
      <div className="payment-receipt-container">
        <Card className="payment-receipt-card" id="paymentReceiptContent">
          <div className="payment-receipt-header">
            <Row>
              <Col md={8}>
                <img src="/path/to/your/logo.png" alt="" className="company-logo" />
                <h2 className="company-name">Nano</h2>
                <p className="company-details">NO 323A, JALAN PUDU, 55100 KUALA LUMPUR.</p>
                <p className="company-details">Nano7@gmail.com</p>
                <p className="company-details">+60 1234567890</p>
              </Col>
              <Col md={4} className="text-end">
                <h1 className="payment-receipt-title">PAYMENT RECEIPT</h1>
                <table className="payment-info-table">
                  <tbody>
                    <tr>
                      <th>Payment No.:</th>
                      <td>{payment.Payment_ID}</td>
                    </tr>
                    <tr>
                      <th>Date:</th>
                      <td>{moment(payment.payment_date).format('DD MMM YYYY')}</td>
                    </tr>
                    <tr>
                      <th>Status:</th>
                      <td>{payment.status}</td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </Row>
          </div>

          <div className="payment-receipt-body">
            <Row>
              <Col md={6}>
                <div className="bill-to-section">
                  <h3 className="section-title">BILL TO</h3>
                  <p className="client-name">{rfqDetails?.userDetails?.[0]?.name}</p>
                  <p className="client-details">{rfqDetails?.userDetails?.[0]?.email}</p>
                  <p className="client-details">{rfqDetails?.userDetails?.[0]?.Address}</p>
                </div>
              </Col>
              <Col md={6}>
                <div className="payment-details-section text-end">
                  <h3 className="section-title">PAYMENT DETAILS</h3>
                  <p className="payment-details"><strong>Method:</strong> {payment.payment_method}</p>
                  <p className="payment-details"><strong>Transaction ID:</strong> {payment.transition_id}</p>
                </div>
              </Col>
            </Row>

            <div className="payment-items">
              <table>
                <thead>
                  <tr>
                    <th>Payment No.</th>
                    <th>Invoice No.</th>
                    <th>Paid Amount</th>
                    <th>Total Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{payment.Payment_ID}</td>
                    <td>{payment.invoice_number}</td>
                    <td>RM {parseFloat(payment.amount).toFixed(2)}</td>
                    <td>RM {parseFloat(payment.total_amount).toFixed(2)}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="payment-summary">
              <Row>
                <Col md={8}>
                  <div className="payment-notes">
                    <h4>Notes</h4>
                    <p>Thank you for your payment!</p>
                  </div>
                </Col>
                <Col md={4}>
                  <table className="summary-table">
                    <tbody>
                      <tr className="total-row">
                        <th>Total Paid:</th>
                        <td>RM {parseFloat(payment.amount).toFixed(2)}</td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
            </div>
          </div>

          <div className="payment-receipt-footer">
            <p>If you have any questions about this payment, please contact us</p>
            <p>Thank you for your business!</p>
          </div>
        </Card>



        <style jsx>{`
          .payment-receipt-container {
            background-color: #f8f9fa;
            padding: 20px;
          }
          .payment-receipt-card {
            background-color: white;
            border: none;
            box-shadow: 0 0 30px rgba(0,0,0,0.1);
            padding: 40px;
            margin-bottom: 20px;
          }
          .payment-receipt-header {
            border-bottom: 2px solid #3D607A;
            padding-bottom: 20px;
            margin-bottom: 30px;
          }
          .company-logo {
            max-width: 200px;
            height: auto;
            margin-bottom: 15px;
          }
          .company-name {
            color: #3D607A;
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 10px;
          }
          .company-details {
            color: #6c757d;
            margin: 0;
            line-height: 1.5;
          }
          .payment-receipt-title {
            color: #3D607A;
            font-size: 36px;
            font-weight: 700;
            margin: 0 0 20px 0;
          }
          .payment-info-table {
            width: 100%;
            margin-top: 10px;
          }
          .payment-info-table th, .payment-info-table td {
            padding: 1px;
            text-align: right;
          }
          .payment-info-table th {
            color: #3D607A;
            font-weight: 600;
            padding-right: 1px;
          }
          .payment-info-table td {
            padding-left: 1px;
          }
          .payment-receipt-body {
            margin-top: 30px;
          }
          .section-title {
            color: #3D607A;
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 15px;
            text-transform: uppercase;
          }
          .client-name {
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 5px;
          }
          .client-details, .payment-details {
            color: #6c757d;
            margin: 0;
            line-height: 1.5;
          }
          .payment-details-section {
            text-align: right;
          }
          .payment-items {
            margin-top: 30px;
            margin-bottom: 30px;
          }
          .payment-items table {
            width: 100%;
            border-collapse: separate;
            border-spacing: 0;
          }
          .payment-items th, .payment-items td {
            border: 1px solid #dee2e6;
            padding: 10px;
            text-align: left;
          }
          .payment-items th {
            background-color: #3D607A;
            color: white;
            font-weight: 600;
          }
          .payment-items tr:nth-child(even) {
            background-color: #f8f9fa;
          }
          .summary-table {
            width: 100%;
          }
          .summary-table th, .summary-table td {
            padding: 4px;
            text-align: right;
          }
          .summary-table th {
            color: #3D607A;
            font-weight: 600;
            padding-right: 1px;
          }
          .summary-table td {
            padding-left: 1px;
          }
          .total-row {
            font-size: 18px;
            font-weight: 700;
            color: #3D607A;
          }
          .payment-notes {
            background-color: #f8f9fa;
            border-left: 4px solid #3D607A;
            padding: 15px;
          }
          .payment-notes h4 {
            color: #3D607A;
            margin-bottom: 10px;
          }
          .payment-receipt-footer {
            margin-top: 40px;
            text-align: center;
            color: #6c757d;
            font-size: 14px;
          }
        `}</style>
      </div>
    );
  };

  const renderCreditNoteTab = () => {
    const creditNote = orderCreditNoteDetails;
    if (!creditNote) return null;

    // Group items by category and filter out items with zero returned quantity
    const groupedItems = creditNote.items?.reduce((acc, item) => {
      if (item.returned_quantity > 0) {
        const category = item.categoryDetails?.Name || 'Uncategorized';
        if (!acc[category]) {
          acc[category] = [];
        }
        acc[category].push(item);
      }
      return acc;
    }, {});

    return (
      <div className="credit-note-container">
        <Card className="credit-note-card" id="creditNoteContent">
          <div className="credit-note-header">
            <Row>
              <Col md={8}>
                <img src="/path/to/your/logo.png" alt="" className="company-logo" />
                <h2 className="company-name">Nano</h2>
                <p className="company-details">NO 323A, JALAN PUDU, 55100 KUALA LUMPUR.</p>
                <p className="company-details">Nano7@gmail.com</p>
                <p className="company-details">+60 1234567890</p>
              </Col>
              <Col md={4} className="text-end">
                <h1 className="credit-note-title">CREDIT NOTE</h1>
                <table className="credit-note-info-table">
                  <tbody>
                    <tr>
                      <th>Credit Note Number:</th>
                      <td>{creditNote.credit_note_number}</td>
                    </tr>
                    <tr>
                      <th>Date:</th>
                      <td>{moment(creditNote.credit_note_date).format('DD MMM YYYY')}</td>
                    </tr>
                    <tr>
                      <th>Status:</th>
                      <td>{creditNote.status}</td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </Row>
          </div>

          <div className="credit-note-body">
            <Row>
              <Col md={6}>
                <div className="bill-to-section">
                  <h3 className="section-title">BILL TO</h3>
                  <p className="client-name">{rfqDetails?.userDetails?.[0]?.name}</p>
                  <p className="client-details">{rfqDetails?.userDetails?.[0]?.email}</p>
                  <p className="client-details">{rfqDetails?.userDetails?.[0]?.Address}</p>
                </div>
              </Col>
              <Col md={6}>
                <div className="original-invoice-section text-end">
                  <h3 className="section-title">ORIGINAL INVOICE</h3>
                  <p className="invoice-details"><strong>Invoice Number:</strong> {creditNote.invoice_number}</p>
                  <p className="invoice-details"><strong>Order Number:</strong> {creditNote.order_number}</p>
                </div>
              </Col>
            </Row>

            <div className="credit-note-items">
              <Table>
                <thead>
                  <tr>
                    <th>Category</th>
                    <th>Item Details</th>
                    <th>Batch</th>
                    <th>Returned Quantity</th>
                    <th>Total Quantity</th>
                    <th>Unit Price</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(groupedItems).map(([category, items], categoryIndex) => (
                    <React.Fragment key={categoryIndex}>
                      {items.map((item, itemIndex) => (
                        item.selectedBatches?.filter(batch => batch.returned_quantity > 0).map((batch, batchIndex) => (
                          <tr key={`${categoryIndex}-${itemIndex}-${batchIndex}`}>
                            {batchIndex === 0 && itemIndex === 0 && (
                              <td rowSpan={items.reduce((sum, item) => sum + (item.selectedBatches?.filter(b => b.returned_quantity > 0).length || 1), 0)}>
                                {category}
                              </td>
                            )}
                            {batchIndex === 0 && (
                              <td rowSpan={item.selectedBatches?.filter(b => b.returned_quantity > 0).length || 1}>
                                <strong>{item.item_name}</strong>
                                <br />
                                <small>Item Code: {item.item_code || 'N/A'}</small>
                              </td>
                            )}
                            <td>{batch.batch_number}</td>
                            <td>{batch.returned_quantity}</td>
                            <td>{batch.quantity}</td>
                            <td>RM {parseFloat(item.price).toFixed(2)}</td>
                            <td>RM {(parseFloat(item.price) * parseFloat(batch.returned_quantity)).toFixed(2)}</td>
                          </tr>
                        ))
                      ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </Table>
            </div>

            <div className="credit-note-summary">
              <Row>
                <Col md={8}>
                  <div className="credit-note-notes">
                    <h4>Notes</h4>
                    <p>This credit note is issued for returned items.</p>
                  </div>
                </Col>
                <Col md={4}>
                  <table className="summary-table">
                    <tbody>
                      <tr className="total-row">
                        <th>Total Credit:</th>
                        <td>RM {parseFloat(creditNote.total_amount).toFixed(2)}</td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
            </div>
          </div>

          <div className="credit-note-footer">
            <p>If you have any questions about this credit note, please contact us</p>
            <p>Thank you for your business!</p>
          </div>
        </Card>

        <style jsx>{`
          .credit-note-container {
            background-color: #f8f9fa;
            padding: 20px;
          }
          .credit-note-card {
            background-color: white;
            border: none;
            box-shadow: 0 0 30px rgba(0,0,0,0.1);
            padding: 40px;
            margin-bottom: 20px;
          }
          .credit-note-header {
            border-bottom: 2px solid #3D607A;
            padding-bottom: 20px;
            margin-bottom: 30px;
          }
          .company-logo {
            max-width: 200px;
            height: auto;
            margin-bottom: 15px;
          }
          .company-name {
            color: #3D607A;
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 10px;
          }
          .company-details {
            color: #6c757d;
            margin: 0;
            line-height: 1.5;
          }
          .credit-note-title {
            color: #3D607A;
            font-size: 36px;
            font-weight: 700;
            margin: 0 0 20px 0;
          }
          .credit-note-info-table {
            width: 100%;
            margin-top: 10px;
          }
          .credit-note-info-table th, .credit-note-info-table td {
            padding: 1px;
            text-align: right;
          }
          .credit-note-info-table th {
            color: #3D607A;
            font-weight: 600;
            padding-right: 1px;
          }
          .credit-note-info-table td {
            padding-left: 1px;
          }
          .credit-note-body {
            margin-top: 30px;
          }
          .section-title {
            color: #3D607A;
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 15px;
            text-transform: uppercase;
          }
          .client-name {
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 5px;
          }
          .client-details, .invoice-details {
            color: #6c757d;
            margin: 0;
            line-height: 1.5;
          }
          .credit-note-items {
            margin-top: 30px;
            margin-bottom: 30px;
          }
          .credit-note-items table {
            width: 100%;
            border-collapse: separate;
            border-spacing: 0;
          }
          .credit-note-items th, .credit-note-items td {
            border: 1px solid #dee2e6;
            padding: 10px;
            text-align: left;
          }
          .credit-note-items th {
            background-color: #3D607A;
            color: white;
            font-weight: 600;
          }
          .credit-note-items tr:nth-child(even) {
            background-color: #f8f9fa;
          }
          .summary-table {
            width: 100%;
          }
          .summary-table th, .summary-table td {
            padding: 4px;
            text-align: right;
          }
          .summary-table th {
            color: #3D607A;
            font-weight: 600;
            padding-right: 1px;
          }
          .summary-table td {
            padding-left: 1px;
          }
          .total-row {
            font-size: 18px;
            font-weight: 700;
            color: #3D607A;
          }
          .credit-note-notes {
            background-color: #f8f9fa;
            border-left: 4px solid #3D607A;
            padding: 15px;
          }
          .credit-note-notes h4 {
            color: #3D607A;
            margin-bottom: 10px;
          }
          .credit-note-footer {
            margin-top: 40px;
            text-align: center;
            color: #6c757d;
            font-size: 14px;
          }
        `}</style>
      </div>
    );
  };

  const ApproveReturn = async () => {
    try {
      var payload = {
        functionName: "AcceptReturnItems",
        postData: {
          _id: id,
        }
      }
      const response = await DashboardService.AcceptReturnItems(payload);
      if (response.data.status) {
        Alerts.swalSuccessAlert(t("Items Returned successfully"));
        fetchRfqDetails();
      } else {
        Alerts.swalErrorAlert(response.data.message || t("Failed to accept RFQ"));
      }
    } catch (error) {
      console.error("Error accepting RFQ:", error);
      Alerts.swalErrorAlert(t("An error occurred while accepting the RFQ"));
    }
  };
 
  const renderReturnTab = () => {
    if (!rfqDetails) return null;
  
    const handleItemChange = (itemIndex, batchIndex, value) => {
      const newItems = [...rfqDetails.items];
      newItems[itemIndex].selectedBatches[batchIndex].returned_quantity = parseInt(value);
      setRfqDetails({ ...rfqDetails, items: newItems });
    };
  

  
    return (
      <div className="return-tab-container">
        <Card className="return-card">
          <div className="p-4">
            <Form>
              <h5 className="mb-3 fw-bold">{t("Return Item(s)")}</h5>
              <FormGroup className="mb-3">
                  <Label for="returnReason">{t("Reason for Return")}</Label>
                  <Input
                    type="textarea"
                    name="returnReason"
                    id="returnReason"
                    disabled
                    style={{backgroundColor: "white"}}
                    defaultValue={rfqDetails?.return_reason || returnReason}
                    onChange={(e) => setReturnReason(e.target.value)}
                    placeholder={t("Please provide a reason for returning the items")}
                    rows="3"
                    required
                  />
                </FormGroup>
              <div className="item-list mb-3">
                <Row className="mb-2 text-muted">
                  <Col md={2}>{t("Item(s)")}</Col>
                  <Col md={2}>{t("Batch")}</Col>
                  <Col md={2}>{t("Expiry")}</Col>
                  <Col md={2}>{t("Quantity")}</Col>
                  <Col md={2}>{t("Returned Quantity")}</Col>
                  <Col md={2}>{t("Remaining Quantity")}</Col>
                </Row>
                {rfqDetails.items.map((item, itemIndex) => (
                  item.selectedBatches.map((batch, batchIndex) => (
                    <Row key={`${itemIndex}-${batchIndex}`} className="mb-2 align-items-center">
                      <Col md={2}>{item.itemDetails?.item_name}</Col>
                      <Col md={2}>{batch.batch_number}</Col>
                      <Col md={2}>{moment(batch.expiry).format("DD-MM-YYYY")}</Col>
                      <Col md={2}>{batch.quantity}</Col>
                      <Col md={2}>
                        <Input
                          type="number"
                          min="0"
                          disabled={true}
                          max={batch.quantity}
                          readOnly={rfqDetails?.return_status && rfqDetails?.return_status !== "pending"}
                          value={batch.returned_quantity || ''}
                          onChange={(e) => handleItemChange(itemIndex, batchIndex, e.target.value)}
                        />
                      </Col>
                      <Col md={2}>{batch.quantity - (batch.returned_quantity || 0)}</Col>
                    </Row>
                  ))
                ))}
              </div>
              <div className="d-flex justify-content-end mt-3">
                {rfqDetails?.return_status === "pending" && (
                  <Button onClick={ApproveReturn} color="success" className="px-4">
                    {t("Approve Return")}
                  </Button>
                )}
              </div>
            </Form>
          </div>
        </Card>
      </div>
    );
  };



  return (
    <>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <LoadingOverlay active={loading} spinner={<DNALoader />}>
            <div className="header">
              <Button color="link" className="p-0 text-decoration-none" onClick={() => navigate(-1)}>
                <FaArrowLeft className="me-2" />
                <span className="text-dark fw-bold">{t("Request For Quotation (RFQ)")}</span>
              </Button>

              {/* <div className="actions">
                {rfqDetails?.status === "Review" && (
                  <button onClick={finalIssueQuotation} className="accept-button">{t("Review (Issue Now)")}</button>
                )}
                {rfqDetails?.status === "requested" && (
                  <button onClick={IssueQuotation} className="accept-button">{t("Issue Quotation")}</button>
                )}
                {rfqDetails?.status === "accepted" && (
                  <button className="accept-button">{t("Quotation Accepted")}</button>
                )}
                {rfqDetails?.status === "rejected" && (
                  <>
                    <button onClick={handleEditQuotation} className="edit-button">
                      <FaEdit className="me-2" />
                      {t("Edit Quotation")}
                    </button>
                  </>
                )}
                {!["Review", "requested", "accepted", "rejected"].includes(rfqDetails?.status) && (
                  <button className="reject-button">{t("Pending")}</button>
                )}
              </div>
            </div> */}
            <div className="actions">
            {activeTab === 'invoice_details' &&
                <Button color="primary" onClick={downloadInvoice} className="accept-button">
                  <FaDownload className="me-2" /> Download Invoice
                </Button>
              }

              {activeTab === 'return' ?
                rfqDetails?.return_status === "pending" ? (
                  <button onClick={ApproveReturn} className="accept-button">{t("Approve")}</button>
                ) : rfqDetails?.return_status === "rejected" ? (
                  <button className="rejected-button">{t("Items Not Returned")}</button>
                ) : rfqDetails?.return_status === "accepted" ? (
                  <button className="processing-button">{t("Items Returned")}</button>
                ) : (
                  <button disabled className="pending-button">{t("Pending")}</button>
                ) : ''
              }
              {activeTab === 'payment_details' &&
                <Button color="primary" onClick={downloadPaymentReceipt} className="accept-button">
                  <FaDownload className="me-2" /> Download Payment Receipt
                </Button>
              }
              {activeTab === 'credit_note' &&
                <Button color="primary" onClick={downloadCreditNote} className="accept-button">
                  <FaDownload className="me-2" /> Download Credit Note
                </Button>
              }
            {rfqDetails?.status === "Review" ? (
                  <button onClick={finalIssueQuotation} className="accept-button">{t("Review (Issue Now)")}</button>
                ): rfqDetails?.status === "requested" ? (
                  <button onClick={IssueQuotation} className="accept-button">{t("Issue Quotation")}</button>
                ) : rfqDetails?.status === "accepted" ? (
                  <button className="accept-button">{t("Quotation Accepted")}</button>
                ) : rfqDetails?.status === "rejected" ? (
                  <>
                    <button onClick={handleEditQuotation} className="edit-button">
                      <FaEdit className="me-2" />
                      {t("Edit Quotation")}
                    </button>
                  </>
                ) :  rfqDetails?.status === "accepted" ? (
                  <button className="accept-button">{t("Quotation Accepted")}</button>
              ) : rfqDetails?.status === "rejected" || rfqDetails?.status === "cancelled" ? (
                <button className="rejected-button">{t("Quotation Rejected")}</button>
              ) : rfqDetails?.status === "processing" ? (
                <button className="processing-button">{t("Processing")}</button>
                ) : rfqDetails?.status === "confirmed" ? (
                  <button className="processing-button">{t("Confirmed")}</button>  
              ) : rfqDetails?.status === "in_transit" ? (
                <button className="in-transit-button">{t("In Transit")}</button>
              ) : rfqDetails?.status === "delivered" ? (
                <button className="delivered-button">{t("Delivered")}</button>
              ) : (
                <button disabled className="pending-button">{t("Pending")}</button>
              )}
            </div>
            </div>
            <h4 className="mb-4 fw-bold">{t("Order ID")} #{rfqDetails?.rfq_id}</h4>

            <div className="order-tabs mb-3">
              <button
                style={{
                  background: activeTab === 'details' ? 'black' : 'white',
                  color: activeTab === 'details' ? 'white' : 'black',
                  marginRight: '10px',
                }}
                onClick={() => setActiveTab('details')}
              >
                Details
              </button>
              {orderTrackingDetails?.length !== 0 &&
                <button
                  style={{
                    background: activeTab === 'tracking' ? 'black' : 'white',
                    color: activeTab === 'tracking' ? 'white' : 'black',
                    marginRight: '10px',
                  }}
                  onClick={() => setActiveTab('tracking')}
                >
                  Order Tracking
                </button>
              }
              <button
                style={{
                  background: activeTab === 'messaging' ? 'black' : 'white',
                  color: activeTab === 'messaging' ? 'white' : 'black',
                  marginRight: '10px'
                }}
                onClick={() => {
                  setActiveTab('messaging')
                  fetchMessages()
                }}
              >Messages
              </button>
              {orderInvoiceDetails  !== null &&
                <button
                  style={{
                    background: activeTab === 'invoice_details' ? 'black' : 'white',
                    color: activeTab === 'invoice_details' ? 'white' : 'black',
                    marginRight: '10px',
                  }}
                  onClick={() => setActiveTab('invoice_details')}
                >
                  Invoice
                </button>
              }
              {orderPaymentDetails?.length !== 0 &&
                <button
                  style={{
                    background: activeTab === 'payment_details' ? 'black' : 'white',
                    color: activeTab === 'payment_details' ? 'white' : 'black',
                    marginRight: '10px',
                  }}
                  onClick={() => setActiveTab('payment_details')}
                >
                  Payment
                </button>
              }
            {rfqDetails?.status === 'delivered' &&  orderInvoiceDetails !== null && orderPaymentDetails?.length !== 0 &&
              <button
                style={{
                  background: activeTab === 'return' ? 'black' : 'white',
                  color: activeTab === 'return' ? 'white' : 'black',
                  marginRight: '10px',
                }}
                onClick={() => setActiveTab('return')}
              >
                Return
              </button>
            }

            {orderCreditNoteDetails &&
              <button
                style={{
                  background: activeTab === 'credit_note' ? 'black' : 'white',
                  color: activeTab === 'credit_note' ? 'white' : 'black',
                  marginRight: '10px',
                }}
                onClick={() => setActiveTab('credit_note')}
              >
                Credit Note
              </button>
            }
            </div>
            {activeTab === 'details' && renderOrderDetails()}
            {activeTab === 'tracking' && renderTrackingContentDEMO()}
            {activeTab === 'credit_note' && renderCreditNoteTab()}
            {activeTab === 'messaging' && renderMessagingTab()}
            {activeTab === 'payment_details' && renderPaymentsDetails()}
            {activeTab === 'invoice_details' && renderInvoiceDetails()}
            {activeTab === 'return' && renderReturnTab()}
          </LoadingOverlay>
        </div>
      </div>
      <QuotationModal
        isOpen={isQuotationModalOpen}
        toggle={() => setIsQuotationModalOpen(!isQuotationModalOpen)}
        quotationDetails={quotationDetails}
        outOfStockItems={outOfStockItems}
        discount={discount}
        paymentTerms={paymentTerms}
        onPriceChange={handlePriceChange}
        onDiscountChange={handleDiscountChange}
        onPaymentTermsChange={handlePaymentTermsChange}
        onBatchChange={handleBatchChange}
        onQuantityChange={handleQuantityChange}
        onSave={saveQuotation}
        onIssue={finalIssueQuotation}
      />
      <style jsx>{`
        .app-main__outer {
          background-color: #f8f9fa;
          min-height: 100vh;
          padding-top: 20px;
        }
        .rfq-card {
          border-radius: 10px;
          box-shadow: 0 2px 10px rgba(0,0,0,0.05);
          border: none;
        }
        .form-select, .form-control {
          border-color: #e0e0e0;
          border-radius: 5px;
          padding: 10px;
        }
        .btn-light {
          background-color: #ffffff;
          border-color: #e0e0e0;
          color: #333;
        }
        .btn-primary {
          background-color: #3D607A;
          border-color: #3D607A;
        }
        .text-muted {
          color: #6c757d !important;
        }
        h4, h5 {
          color: #333;
        }
        .item-list {
          border-top: 1px solid #e0e0e0;
          border-bottom: 1px solid #e0e0e0;
          padding: 20px 0;
        }
        .item-image {
          width: 40px;
          height: 40px;
          object-fit: cover;
          border-radius: 5px;
        }
        .totals {
          margin-top: 20px;
        }
        .totals-row {
          display: flex;
          justify-content: flex-end;
          margin-bottom: 5px;
        }
        .total-amount {
          font-weight: bold;
        }
        .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;
        }
        .back-button {
          background: none;
          border: none;
          font-size: 20px;
          cursor: pointer;
        }
        .rfq-id {
          font-weight: bold;
          font-size: 1.5rem;
        }
        .actions {
          display: flex;
          gap: 10px;
        }
        .reject-button,
        .accept-button,
        .edit-button {
          padding: 8px 16px;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          font-weight: bold;
        }
        .reject-button {
          background-color: #ffdddd;
          color: #d9534f;
        }
        .accept-button {
          background-color: #5cb85c;
          color: #fff;
        }
        .edit-button {
          background-color: #f0ad4e;
          color: #fff;
        }
        .reject-button, .rejected-button {
          background-color: #ffdddd;
          color: #d9534f;
          border: 1px solid #d9534f;
        }
        .accept-button, .accepted-button {
          background-color: #dff0d8;
          color: #5cb85c;
          border: 1px solid #5cb85c;
        }
        .pay-now-button {
          background-color: #5bc0de;
          color: white;
          border: 1px solid #46b8da;
        }
        .processing-button {
          background-color: #fcf8e3;
          color: #8a6d3b;
          border: 1px solid #faebcc;
        }
        .in-transit-button {
          background-color: #d9edf7;
          color: #31708f;
          border: 1px solid #bce8f1;
        }
        .delivered-button {
          background-color: #dff0d8;
          color: #3c763d;
          border: 1px solid #d6e9c6;
        }
        .pending-button {
          background-color: #f5f5f5;
          color: #777;
          border: 1px solid #ddd;
        }
        button {
          padding: 8px 16px;
          border-radius: 5px;
          cursor: pointer;
          font-weight: bold;
          transition: all 0.3s ease;
        }
        button:hover:not(:disabled) {
          opacity: 0.8;
        }
        button:disabled {
          cursor: not-allowed;
          opacity: 0.6;
        }        
        .chat-container {
          background: linear-gradient(135deg, #3a7bd5, #3a6073);
          border-radius: 12px;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          height: 600px;
          box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
        }
        .messages-container {
          flex-grow: 1;
          overflow-y: auto;
          padding: 15px;
          background-color: #F9F7F7;
        }
        .message-wrapper {
          display: flex;
          margin-bottom: 10px;
        }
        .message-wrapper.sent {
          justify-content: flex-end;
        }
        .message-wrapper.received {
          justify-content: flex-start;
        }
        .message-content {
          max-width: 60%;
          padding: 10px 15px;
          border-radius: 18px;
          position: relative;
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        }
        .message-wrapper.sent .message-content {
          background-color: #00796B;
          color: white;
        }
        .message-wrapper.received .message-content {
          background-color: #1565C0;
          color: white;
        }
        .message-sender {
          font-weight: bold;
          margin-bottom: 4px;
          font-size: 0.9em;
          opacity: 0.8;
        }
        .message-text {
          margin-bottom: 4px;
          line-height: 1.4;
        }
        .message-time {
          font-size: 0.75em;
          opacity: 0.7;
          text-align: right;
        }
        .message-input {
          display: flex;
          align-items: center;
          padding: 15px;
          background-color: rgba(0, 0, 0, 0.2);
        }
        .message-input input {
          flex-grow: 1;
          border: none;
          border-radius: 20px;
          padding: 12px 20px;
          margin-right: 10px;
          background-color: rgba(255, 255, 255, 0.9);
          color: #333;
          font-size: 1em;
        }
        .message-input button {
          background-color: #00796B;
          border: none;
          border-radius: 50%;
          width: 45px;
          height: 45px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: background-color 0.3s ease;
        }
        .message-input button:hover {
          background-color: #00695C;
        }
      `}</style>
    </>
  );
};


export default ViewRfqs;


// import React, { useState, useEffect, useRef } from 'react';
// import { Card, Row, Col, Input, Button, Form, FormGroup, Label, Modal, ModalBody, Table, Alert, CardBody } from 'reactstrap';
// import { FaPlus, FaCheck, FaTrash, FaArrowLeft } from 'react-icons/fa';
// import { faCommentAlt, faBicycle, faWalking, faMapMarkerAlt, faCheckCircle, FaCreditCard, faShoppingBasket, faPaperPlane, faEnvelope, faSearch, faTruck, faFire, faClipboardList, faClock, faPhone, faMoneyBill } from '@fortawesome/free-solid-svg-icons';
// import { useNavigate, useParams } from 'react-router-dom';
// import RejectQuotationPopup from './RejectQuotationPopup';
// import DashboardService from "../Dashboard/dashboard.service";
// import AuthService from "../Authentication/auth.service";
// import Alerts from "../../components/Alerts/alerts";
// import { useTranslation } from 'react-i18next';
// import LoadingOverlay from "react-loading-overlay-ts";
// import DNALoader from "../../components/Loaders/DnaLoader";
// import moment from 'moment';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import DiscountCouponService from "../DiscountCoupon/Discountcoupons.service";
// import {
//   faSpinner,
//   faBox, faBan, faQuestion
// } from '@fortawesome/free-solid-svg-icons';



// const ViewRfqs = () => {
//   const [rfqDetails, setRfqDetails] = useState(null);
//   const [orderTrackingDetails, setOrderTrackingDetails] = useState(null);
//   const [orderInvoiceDetails, setInvoiceDetails] = useState(null);
//   const [orderPaymentDetails, setPaymentDetails] = useState(null);
//   const [activeTab, setActiveTab] = useState('details');
//   const [loading, setLoading] = useState(true);
//   const [isPopupVisible, setPopupVisible] = useState(false);
//   const navigate = useNavigate();
//   const { id } = useParams();
//   const { t } = useTranslation();
//   const sessionID = localStorage.getItem("session-id");
//   const [messages, setMessages] = useState([]);
//   const [newMessage, setNewMessage] = useState('');

//   useEffect(() => {
//     fetchRfqDetails();
//   }, [id]);

//   const fetchRfqDetails = async () => {
//     setLoading(true);
//     try {
//       var payload = {
//         functionName: "GetRFQDetails",
//         postData: {
//           _id: id,
//         }
//       }

//       const response = await DashboardService.GetRFQDetails(payload);
//       if (response.data.status) {
//         console.log("response.data.data", response.data.data)
//         setRfqDetails(response?.data?.data?.[0]);
//         setOrderTrackingDetails(response?.data?.orderTrackingDetails);
//         setInvoiceDetails(response?.data?.orderInvoiceDetails);
//         setPaymentDetails(response?.data?.orderPaymentDetails);

//       } else {
//         Alerts.swalErrorAlert(response.data.message || t("Failed to fetch RFQ details"));
//       }
//     } catch (error) {
//       console.error("Error fetching RFQ details:", error);
//       Alerts.swalErrorAlert(t("An error occurred while fetching RFQ details"));
//     } finally {
//       setLoading(false);
//     }
//   };

//   const openPopup = () => setPopupVisible(true);
//   const closePopup = () => setPopupVisible(false);


//   const handleAccept = async () => {
//     try {
//       var payload = {
//         functionName: "AcceptRFQ",
//         postData: {
//           _id: id,
//         }
//       }
//       const response = await DashboardService.AcceptRFQ(payload);
//       if (response.data.status) {
//         Alerts.swalSuccessAlert(t("RFQ accepted successfully"));
//         fetchRfqDetails();
//       } else {
//         Alerts.swalErrorAlert(response.data.message || t("Failed to accept RFQ"));
//       }
//     } catch (error) {
//       console.error("Error accepting RFQ:", error);
//       Alerts.swalErrorAlert(t("An error occurred while accepting the RFQ"));
//     }
//   };

//   const renderOrderDetails = () => (
//     <div className="item-list mb-3">
//       <div className="container mt-4">
//         {isPopupVisible && <RejectQuotationPopup quotationId={id} rfq_id={rfqDetails?.rfq_id} onClose={closePopup} fetchRfqDetails={fetchRfqDetails}
//         />}


//         <LoadingOverlay active={loading ? true : false} spinner={<DNALoader />} >
//           <Card className="rfq-card">
//             <div className="p-4">
//               <h4 className="mb-4 fw-bold">{t("Order ID")} #{rfqDetails?.rfq_id}</h4>
//               <Form>
//                 <Row className="mb-4">
//                   <Col md={6}>
//                     <FormGroup>
//                       <Label for="rfq_date" className="text-muted mb-2">{t("RFQ Date")}</Label>
//                       <Input type="text" id="rfq_date" className="form-select" readOnly value={new Date(rfqDetails?.datetime_added).toLocaleDateString()} />
//                     </FormGroup>
//                   </Col>
//                   <Col md={6}>
//                     <FormGroup>
//                       <Label for="status" className="text-muted mb-2">{t("Status")}</Label>
//                       <Input type="text" id="status" className="form-select" readOnly value={rfqDetails?.status} />
//                     </FormGroup>
//                   </Col>
//                   {rfqDetails?.status === "rejected" &&
//                     <Col md={12}>
//                       <FormGroup>
//                         <Label for="reason" className="text-muted mb-2">{t("Rejection Reason")}</Label>
//                         <Input type="textarea" id="reason" readOnly value={rfqDetails?.reason} />
//                       </FormGroup>
//                     </Col>
//                   }
//                 </Row>

//                 <h5 className="mb-3 fw-bold">{t("List of Item(s)")}</h5>
//                 <div className="item-list mb-3">
//                   <Table responsive>
//                     <thead>
//                       <tr>
//                         <th>{t("Category")}</th>
//                         <th>{t("Item(s) - Brand")}</th>
//                         <th>{t("Quantity")}</th>
//                         <th>{t("Unit Price")}</th>
//                         <th>{t("Batches")}</th>
//                         <th>{t("Expiry Date")}</th>
//                         <th>{t("Total Price")}</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {renderItemRows(rfqDetails?.items || [])}
//                     </tbody>
//                   </Table>
//                 </div>

//                 {rfqDetails?.outOfStockItems?.length > 0 && (
//                   <Alert color="warning">
//                     The following items are out of stock or have insufficient quantity:
//                     <ul>
//                       {rfqDetails?.outOfStockItems?.map((item, index) => (
//                         <li key={index}>
//                           {item.item_name}: {item.extra_quantity} units out of stock
//                         </li>
//                       ))}
//                     </ul>
//                   </Alert>
//                 )}


//                 {rfqDetails?.status !== "requested" && rfqDetails?.status !== "Review" ?
//                   <div className="totals text-end">
//                     {(() => {
//                       const { subtotal, discountPercentage, totalAmount } = calculateTotals(rfqDetails?.items || []);
//                       return (
//                         <>
//                           {rfqDetails?.discount && rfqDetails?.discount !== null && rfqDetails?.discount !== '0' ?
//                             <>
//                               <div className="totals-row">
//                                 <span>{t("Subtotal")}:</span>
//                                 <span className="ms-3">RM {subtotal.toFixed(2)}</span>
//                               </div>
//                               < div className="totals-row">
//                                 <span>{t(`Discount(${rfqDetails?.discount || 0}%)`)}:</span>
//                                 <span className="ms-3">{parseFloat(discountPercentage).toFixed(2) || 0}</span>
//                               </div>
//                               < div className="totals-row total-amount fw-bold">
//                                 <span>{t("Total Amount")}:</span>
//                                 <span className="ms-3">RM {totalAmount.toFixed(2)}</span>
//                               </div>
//                             </>
//                             :
//                             <>
//                               < div className="totals-row total-amount fw-bold">
//                                 <span>{t("Total Amount")}:</span>
//                                 <span className="ms-3">RM {totalAmount.toFixed(2)}</span>
//                               </div>
//                             </>
//                           }
//                         </>
//                       );
//                     })()}
//                   </div>
//                   : ''}
//               </Form>
//             </div>
//           </Card>
//         </LoadingOverlay >
//         <style jsx>{`
//         .app-main__outer {
//           background-color: #f8f9fa;
//           min-height: 100vh;
//           padding-top: 20px;
//         }
//         .rfq-card {
//           border-radius: 10px;
//           box-shadow: 0 2px 10px rgba(0,0,0,0.05);
//           border: none;
//         }
//         .form-select, .form-control {
//           border-color: #e0e0e0;
//           border-radius: 5px;
//           padding: 10px;
//         }
//         .btn-light {
//           background-color: #ffffff;
//           border-color: #e0e0e0;
//           color: #333;
//         }
//         .btn-primary {
//           background-color: #3D607A;
//           border-color: #3D607A;
//         }
//         .text-muted {
//           color: #6c757d !important;
//         }
//         h4, h5 {
//           color: #333;
//         }
//         .item-list {
//           border-top: 1px solid #e0e0e0;
//           border-bottom: 1px solid #e0e0e0;
//           padding: 20px 0;
//         }
//         .item-image {
//           width: 40px;
//           height: 40px;
//           object-fit: cover;
//           border-radius: 5px;
//         }
//         .totals {
//           margin-top: 20px;
//         }
//         .totals-row {
//           display: flex;
//           justify-content: flex-end;
//           margin-bottom: 5px;
//         }
//         .total-amount {
//           font-weight: bold;
//         }
//         .header {
//           display: flex;
//           justify-content: space-between;
//           align-items: center;
//           margin-bottom: 20px;
//         }
//         .back-button {
//           background: none;
//           border: none;
//           font-size: 20px;
//           cursor: pointer;
//         }
//         .rfq-id {
//           font-weight: bold;
//           font-size: 1.5rem;
//         }
//         .actions {
//           display: flex;
//           gap: 10px;
//         }
//         .reject-button,
//         .accept-button {
//           padding: 8px 16px;
//           border: none;
//           border-radius: 5px;
//           cursor: pointer;
//         }
//         .reject-button {
//           background-color: #ffdddd;
//           color: #d9534f;
//         }
//         .accept-button {
//           background-color: #5cb85c;
//           color: #fff;
//         }

//       `}</style>
//       </div >
//     </div>
//   );

//   const HandlePayment = (row) => {
//     console.log("row", row);
//     var getData = {
//       functionName: "OnlinePayment",
//       row: row,
//       invoice_id: row?._id ? row?._id : null,
//       amount: parseFloat(row.total_amount).toFixed(2),
//       invoice_number: row.invoice_number,
//       business_id: row?.business_id
//     }
//     console.log("getData", getData);
//     setLoading(true);
//     DashboardService.OnlinePayment(getData).then((response) => {
//       console.log(response);
//       setLoading(false);
//       if (response.status === 200) {
//         console.log(response);
//         window.open(response.data.data, '_blank');
//         console.log('Response : ', response);
//       } else if (response.status === 401) {
//         AuthService.logout();
//         navigate("/login");
//         window.location.reload();
//       } else {
//         window.open(response.data.data, '_blank');
//       }
//     },
//       (error) => {
//         setLoading(false);
//         const errContent =
//           (error.response && error.response.data) || error.message || error.toString();
//         console.log("Net:-", errContent);
//         if (errContent) {
//           Alerts.swalErrorAlert(errContent);
//         } else {
//           Alerts.swalErrorAlert(t("Server Error, Please try again later."));
//         }
//       })
//   }


//   const renderInvoiceDetails = () => (
//     <div className="item-list mb-3">
//       <div className="container mt-4">
//         {isPopupVisible && <RejectQuotationPopup quotationId={id} rfq_id={rfqDetails?.rfq_id} onClose={closePopup} fetchRfqDetails={fetchRfqDetails}
//         />}


//         <LoadingOverlay active={loading ? true : false} spinner={<DNALoader />} >
//           <Card className="rfq-card">
//             <div className="p-4">
//               <h4 className="mb-4 fw-bold">{t("Invoice Number")} #{orderInvoiceDetails?.[0]?.invoice_number}</h4>
//               <Form>
//                 <Row className="mb-4">
//                   <Col md={6}>
//                     <FormGroup>
//                       <Label for="rfq_date" className="text-muted mb-2">{t("Invoice Date")}</Label>
//                       <Input type="text" id="rfq_date" readOnly value={new Date(orderInvoiceDetails?.[0]?.datetime_added).toLocaleDateString()} />
//                     </FormGroup>
//                   </Col>
//                   <Col md={6}>
//                     <FormGroup>
//                       <Label for="status" className="text-muted mb-2">{t("Invoice Status")}</Label>
//                       <Input type="text" id="status" readOnly value={orderInvoiceDetails?.[0]?.status} />
//                     </FormGroup>
//                   </Col>
//                   <Col md={6}>
//                     <FormGroup>
//                       <Label for="rfq_date" className="text-muted mb-2">{t("Amount")}</Label>
//                       <Input type="text" id="rfq_date" readOnly value={parseFloat(orderInvoiceDetails?.[0]?.total_amount).toFixed(2)} />
//                     </FormGroup>
//                   </Col>
//                   {/* <Col md={6}>
//                     <FormGroup>
//                       <Label for="status" className="text-muted mb-2">{t("Payment Date")}</Label>
//                       <Input type="text" id="status" readOnly value={new Date(orderInvoiceDetails?.[0]?.payment_date).toLocaleDateString()} />
//                     </FormGroup>
//                   </Col> */}
//                 </Row>
//                 <Row className="mb-4">
//                   <Col md={12}>
//                     {orderInvoiceDetails?.[0]?.status !== 'paid' && (
//                       <Button className="accept-button" onClick={() => { HandlePayment(orderInvoiceDetails?.[0]) }} >
//                         Pay Now
//                       </Button>
//                     )}
//                   </Col>
//                 </Row>

//               </Form>
//             </div>
//           </Card>
//         </LoadingOverlay >
//         <style jsx>{`
//         .app-main__outer {
//           background-color: #f8f9fa;
//           min-height: 100vh;
//           padding-top: 20px;
//         }
//         .rfq-card {
//           border-radius: 10px;
//           box-shadow: 0 2px 10px rgba(0,0,0,0.05);
//           border: none;
//         }
//         .form-select, .form-control {
//           border-color: #e0e0e0;
//           border-radius: 5px;
//           padding: 10px;
//         }
//         .btn-light {
//           background-color: #ffffff;
//           border-color: #e0e0e0;
//           color: #333;
//         }
//         .btn-primary {
//           background-color: #3D607A;
//           border-color: #3D607A;
//         }
//         .text-muted {
//           color: #6c757d !important;
//         }
//         h4, h5 {
//           color: #333;
//         }
//         .item-list {
//           border-top: 1px solid #e0e0e0;
//           border-bottom: 1px solid #e0e0e0;
//           padding: 20px 0;
//         }
//         .item-image {
//           width: 40px;
//           height: 40px;
//           object-fit: cover;
//           border-radius: 5px;
//         }
//         .totals {
//           margin-top: 20px;
//         }
//         .totals-row {
//           display: flex;
//           justify-content: flex-end;
//           margin-bottom: 5px;
//         }
//         .total-amount {
//           font-weight: bold;
//         }
//         .header {
//           display: flex;
//           justify-content: space-between;
//           align-items: center;
//           margin-bottom: 20px;
//         }
//         .back-button {
//           background: none;
//           border: none;
//           font-size: 20px;
//           cursor: pointer;
//         }
//         .rfq-id {
//           font-weight: bold;
//           font-size: 1.5rem;
//         }
//         .actions {
//           display: flex;
//           gap: 10px;
//         }
//         .reject-button,
//         .accept-button {
//           padding: 8px 16px;
//           border: none;
//           border-radius: 5px;
//           cursor: pointer;
//         }
//         .reject-button {
//           background-color: #ffdddd;
//           color: #d9534f;
//         }
//         .accept-button {
//           background-color: #5cb85c;
//           color: #fff;
//         }

//       `}</style>
//       </div >
//     </div>
//   );

//   const renderPaymentsDetails = () => (
//     <div className="item-list mb-3">
//       <div className="container mt-4">
//         {isPopupVisible && <RejectQuotationPopup quotationId={id} rfq_id={rfqDetails?.rfq_id} onClose={closePopup} fetchRfqDetails={fetchRfqDetails}
//         />}


//         <LoadingOverlay active={loading ? true : false} spinner={<DNALoader />} >
//           <Card className="rfq-card">
//             <div className="p-4">
//               <h4 className="mb-4 fw-bold">{t("Payment Number")} #{orderPaymentDetails?.[0]?.Payment_ID}</h4>
//               <Form>
//                 <Row className="mb-4">
//                   <Col md={6}>
//                     <FormGroup>
//                       <Label for="rfq_date" className="text-muted mb-2">{t("payment Date")}</Label>
//                       <Input type="text" id="rfq_date" className="form-select" readOnly value={new Date(orderPaymentDetails?.[0]?.payment_date).toLocaleDateString()} />
//                     </FormGroup>
//                   </Col>
//                   <Col md={6}>
//                     <FormGroup>
//                       <Label for="status" className="text-muted mb-2">{t("Status")}</Label>
//                       <Input type="text" id="status" className="form-select" readOnly value={orderPaymentDetails?.[0]?.status} />
//                     </FormGroup>
//                   </Col>
//                   <Col md={6}>
//                     <FormGroup>
//                       <Label for="rfq_date" className="text-muted mb-2">{t("Amount")}</Label>
//                       <Input type="text" id="rfq_date" className="form-select" readOnly value={parseFloat(orderPaymentDetails?.[0]?.total_amount).toFixed(2)} />
//                     </FormGroup>
//                   </Col>
//                   <Col md={6}>
//                     <FormGroup>
//                       <Label for="status" className="text-muted mb-2">{t("Payment Method")}</Label>
//                       <Input type="text" id="status" className="form-select" readOnly value={orderPaymentDetails?.[0]?.payment_method} />
//                     </FormGroup>
//                   </Col>
//                   {/* <Col md={6}>
//                     <FormGroup>
//                       <Label for="status" className="text-muted mb-2">{t("Payment Receipt")}</Label>
//                       <img type="text" id="status" className="form-select" readOnly src={orderPaymentDetails?.[0]?.payment_method} />
//                     </FormGroup>
//                   </Col> */}
//                 </Row>

//                 <Card className="mb-3" style={{ height: '295px' }}>
//                   <CardBody>
//                     <h5 className="card-title">Order Total</h5>
//                     <Row>
//                       <Col md="6">
//                         <p><strong>Order Sub Total:</strong></p>
//                         <p><strong>Delivery Charges:</strong></p>
//                         <p><strong>Discount:</strong></p>
//                         <p><strong>{rfqDetails?.Calculations?.sst_dynamic_label} ({rfqDetails?.Calculations?.sst_percentage}):</strong></p>
//                         <p><strong>Grand Total:</strong></p>

//                         {rfqDetails?.deliveryMethod === 'delivery' && (
//                           <>
//                             <p style={{ color: 'red' }}><strong>Additional Priority/Delivery Fee by Seller:</strong></p>
//                             <p><strong>Additional Delivery Fee by Customer:</strong></p>
//                           </>
//                         )}


//                       </Col>
//                       <Col md="6" className="text-end">
//                         <p>RM {formatCurrency(rfqDetails?.Calculations?.subTotal)}</p>
//                         <p>RM {formatCurrency(rfqDetails?.Calculations?.deliveryTipFee)}</p>
//                         <p>RM {formatCurrency(rfqDetails?.Calculations?.discount)}</p>
//                         <p>RM {formatCurrency(rfqDetails?.Calculations?.sst)}</p>
//                         <p>RM {formatCurrency(rfqDetails?.Calculations?.grandTotal)}</p>
//                         {rfqDetails?.deliveryMethod === 'delivery' && (
//                           <>
//                             <p style={{ color: 'red' }}>RM {formatCurrency(rfqDetails?.Calculations?.additionalDeliveryFeeBySeller)}</p>
//                             <p>RM {formatCurrency(rfqDetails?.Calculations?.additionalDeliveryFee)}</p>

//                           </>
//                         )}
//                       </Col>
//                     </Row>
//                   </CardBody>
//                 </Card>
//               </Form>
//             </div>
//           </Card>
//         </LoadingOverlay >
//         <style jsx>{`
//         .app-main__outer {
//           background-color: #f8f9fa;
//           min-height: 100vh;
//           padding-top: 20px;
//         }
//         .rfq-card {
//           border-radius: 10px;
//           box-shadow: 0 2px 10px rgba(0,0,0,0.05);
//           border: none;
//         }
//         .form-select, .form-control {
//           border-color: #e0e0e0;
//           border-radius: 5px;
//           padding: 10px;
//         }
//         .btn-light {
//           background-color: #ffffff;
//           border-color: #e0e0e0;
//           color: #333;
//         }
//         .btn-primary {
//           background-color: #3D607A;
//           border-color: #3D607A;
//         }
//         .text-muted {
//           color: #6c757d !important;
//         }
//         h4, h5 {
//           color: #333;
//         }
//         .item-list {
//           border-top: 1px solid #e0e0e0;
//           border-bottom: 1px solid #e0e0e0;
//           padding: 20px 0;
//         }
//         .item-image {
//           width: 40px;
//           height: 40px;
//           object-fit: cover;
//           border-radius: 5px;
//         }
//         .totals {
//           margin-top: 20px;
//         }
//         .totals-row {
//           display: flex;
//           justify-content: flex-end;
//           margin-bottom: 5px;
//         }
//         .total-amount {
//           font-weight: bold;
//         }
//         .header {
//           display: flex;
//           justify-content: space-between;
//           align-items: center;
//           margin-bottom: 20px;
//         }
//         .back-button {
//           background: none;
//           border: none;
//           font-size: 20px;
//           cursor: pointer;
//         }
//         .rfq-id {
//           font-weight: bold;
//           font-size: 1.5rem;
//         }
//         .actions {
//           display: flex;
//           gap: 10px;
//         }
//         .reject-button,
//         .accept-button {
//           padding: 8px 16px;
//           border: none;
//           border-radius: 5px;
//           cursor: pointer;
//         }
//         .reject-button {
//           background-color: #ffdddd;
//           color: #d9534f;
//         }
//         .accept-button {
//           background-color: #5cb85c;
//           color: #fff;
//         }

//       `}</style>
//       </div >
//     </div>
//   );


//   const renderMessagingTab = () => (
//     <Card className="mb-3">
//       <CardBody className="p-0">
//         <div className="chat-container">
//           <div
//             className="messages-container"
//             ref={chatContainerRef}
//           >
//             {messages?.map((msg, index) => (
//               <div key={index} className={`message-wrapper ${msg.sender_type === 'admin' ? 'received' : 'sent'}`}>
//                 <div className="message-content">
//                   <div className="message-sender">{msg.sender_type === 'admin' ? 'admin' : 'Member'}</div>
//                   <div className="message-text">
//                     {msg.message}
//                   </div>
//                   <div className="message-time">
//                     {moment(msg.date).format('DD-MM-YYYY, h:mm A')}
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>
//           <div className="message-input">
//             <input
//               type="text"
//               value={newMessage}
//               onChange={(e) => setNewMessage(e.target.value)}
//               placeholder="Type a message"
//             />
//             <Button color="primary" onClick={sendMessage}>
//               <FontAwesomeIcon icon={faPaperPlane} />
//             </Button>
//           </div>
//         </div>
//       </CardBody>
//     </Card>
//   )


//   const formatStatus = (status) => {
//     if (!status) return '';

//     // Replace underscores with spaces and capitalize each word
//     return status
//       .toLowerCase() // Ensure all letters are lowercase
//       .split('_')    // Split by underscores
//       .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter of each word
//       .join(' ');    // Join the words with spaces
//   };

//   const getImageSrc = (item) => {
//     if (item.itemDetails.images && item.itemDetails.images.length > 0) {
//       const imageIndex = item.index || 0; // Use the provided index or default to 0
//       const imageSrc = item.itemDetails.images[imageIndex];

//       // Check if the image is a base64 string
//       if (imageSrc.startsWith('data:image')) {
//         return imageSrc;
//       } else {
//         // If it's not a base64 string, assume it's a URL
//         return imageSrc;
//       }
//     }
//     return '';
//   };

//   const groupItemsByCategory = (items) => {
//     return items?.reduce((acc, item) => {
//       const category = item?.categoryDetails.Name;
//       if (!acc[category]) {
//         acc[category] = [];
//       }
//       acc[category].push(item);
//       return acc;
//     }, {});
//   };

//   const calculateTotals = (items) => {
//     const subtotal = items?.reduce((total, item) => {
//       const availableQuantity = item?.selectedBatches?.reduce((sum, batch) => sum + batch.quantity, 0) || 0;
//       return total + (availableQuantity * item?.price || 0);
//     }, 0);
//     const discount = rfqDetails?.discount || 0;
//     const discountAmount = discount ? (discount / 100) * subtotal : 0;
//     const totalAmount = subtotal - discountAmount;
//     return { subtotal, discountPercentage: discountAmount, totalAmount };
//   };


//   const renderItemRows = (items) => {
//     let rows = [];

//     // Group items by category
//     const groupedItems = items?.reduce((acc, item) => {
//       const category = item?.categoryDetails?.Name || 'Uncategorized';
//       if (!acc[category]) {
//         acc[category] = [];
//       }
//       acc[category].push(item);
//       return acc;
//     }, {});

//     // Iterate through each category
//     Object.entries(groupedItems).forEach(([category, categoryItems]) => {
//       let isFirstItemInCategory = true;

//       categoryItems.forEach((item, itemIndex) => {
//         const batches = item?.selectedBatches || [{}];

//         batches.forEach((batch, batchIndex) => {
//           rows.push(
//             <tr key={`${category}-${itemIndex}-${batchIndex}`}>
//               {isFirstItemInCategory && (
//                 <td rowSpan={categoryItems.reduce((sum, item) => sum + (item.selectedBatches?.length || 1), 0)}>
//                   {category}
//                 </td>
//               )}
//               {batchIndex === 0 && (
//                 <>
//                   <td rowSpan={batches.length}>
//                     <div className="d-flex align-items-center">
//                       {item?.itemDetails?.images && (
//                         <img
//                           src={item?.itemDetails?.images[(item?.itemDetails?.index - 1) || 0]}
//                           alt={item?.itemDetails?.item_name}
//                           className="item-image me-2"
//                         />
//                       )}
//                       <span>{item?.itemDetails?.item_name}</span>
//                     </div>
//                   </td>
//                   <td rowSpan={batches.length}>{item?.quantity}</td>
//                   <td rowSpan={batches.length}>{item?.price ? `RM ${parseFloat(item?.price).toFixed(2)}` : '-'}</td>
//                 </>
//               )}
//               <td>{batch.batch_number || '-'}</td>
//               <td>{batch.expiry ? moment(batch.expiry).format('DD-MM-YYYY') : '-'}</td>
//               <td>{`RM ${((batch.quantity || 0) * (item?.price || 0)).toFixed(2)}`}</td>
//             </tr>
//           );

//           if (batchIndex === 0) {
//             isFirstItemInCategory = false;
//           }
//         });
//       });
//     });

//     return rows;
//   };

//   const getStatusColor = (status) => {
//     switch (status.toLowerCase()) {
//       case 'pending':
//         return '#FFA500'; // Orange
//       case 'confirmed':
//         return '#4169E1'; // Royal Blue
//       case 'processing':
//         return '#9370DB'; // Medium Purple
//       case 'in_transit':
//         return '#1E90FF'; // Dodger Blue
//       case 'delivered':
//         return '#32CD32'; // Lime Green
//       case 'cancelled':
//         return '#DC143C'; // Crimson
//       case 'order paid':
//         return '#DC143C'; // Crimson
//       case 'order received':
//         return '#DC143C'; // Crimson
//       default:
//         return '#808080'; // Gray for unknown status
//     }
//   };

//   const getIconForStatus = (status) => {
//     switch (status.toLowerCase()) {
//       case 'pending':
//         return faClock;
//       case 'confirmed':
//         return faCheckCircle;
//       case 'processing':
//         return faSpinner;
//       case 'in_transit':
//         return faTruck;
//       case 'delivered':
//         return faBox;
//       case 'cancelled':
//         return faBan;
//       case 'order paid':
//         return faFire;
//       case 'order received':
//         return faClipboardList;
//       default:
//         return faQuestion;
//     }
//   };
//   const finalIssueQuotation = async () => {
//     try {
//       let data;
//       if (quotationDetails === null) {
//         data = rfqDetails;
//       } else {
//         data = quotationDetails;
//       }
//       console.log("data", data)
//       var payload = {
//         ...data,
//         discount,
//         functionName: "finalIssueQuotation",
//         paymentTerms,
//         _id: rfqDetails?._id,
//       }

//       const response = await services.FinalIssueQuotation(payload, authData.sessionID);
//       if (response.data.status) {
//         Alerts.swalSuccessAlert(t("Quotation issued successfully"));
//         setIsQuotationModalOpen(false);
//         fetchRfqDetails(); // Refresh the RFQ details
//       } else {
//         Alerts.swalErrorAlert(response.data.message || t("Failed to issue quotation"));
//       }
//     } catch (error) {
//       console.error("Error issuing quotation:", error);
//       Alerts.swalErrorAlert(t("An error occurred while issuing the quotation"));
//     }
//   };
//   const renderTrackingContentDEMO = () => {
//     if (!orderTrackingDetails || orderTrackingDetails?.length === 0) {
//       return <div>No tracking information available</div>;
//     }

//     return (
//       <div style={{
//         backgroundColor: 'white',
//         borderRadius: '10px',
//         padding: '20px',
//         boxShadow: '0 2px 5px rgba(0,0,0,0.1)'
//       }}>
//         {orderTrackingDetails?.map((step, index) => (
//           <div key={index} style={{
//             display: 'flex',
//             marginBottom: '20px',
//             position: 'relative'
//           }}>
//             <div style={{
//               width: '40px',
//               height: '40px',
//               backgroundColor: getStatusColor(step.status),
//               borderRadius: '50%',
//               display: 'flex',
//               justifyContent: 'center',
//               alignItems: 'center',
//               marginRight: '20px',
//               zIndex: 1
//             }}>
//               <FontAwesomeIcon icon={getIconForStatus(step.status)} style={{ color: 'white' }} />
//             </div>
//             {index !== orderTrackingDetails.length - 1 && (
//               <div style={{
//                 position: 'absolute',
//                 left: '20px',
//                 top: '40px',
//                 bottom: '-20px',
//                 width: '2px',
//                 borderLeft: `2px dashed ${getStatusColor(step.status)}`,
//                 zIndex: 0
//               }} />
//             )}
//             <div style={{ flex: 1 }}>
//               <h3 style={{ margin: '0 0 5px 0', color: getStatusColor(step.status) }}>{formatStatus(step.status)}</h3>
//               <p style={{ margin: '0', color: '#666' }}>
//                 <FontAwesomeIcon icon={faClock} style={{ marginRight: '5px' }} />
//                 {moment(step.date_time).format('DD-MM-YYYY, h:mm:ss a')}
//               </p>
//             </div>
//           </div>
//         ))}
//       </div>
//     );
//   };

//   useEffect(() => {
//     fetchMessages();
//   }, [id, sessionID]);
//   const chatContainerRef = useRef(null);

//   useEffect(() => {
//     if (chatContainerRef.current) {
//       chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
//     }
//   }, [messages]);



//   const formatCurrency = (value) => {
//     const parsedValue = parseFloat(value);
//     return !isNaN(parsedValue)
//       ? parsedValue.toFixed(2)
//       : '0.00';
//   };



//   return (
//     <>    <div className="app-main__outer">
//       <div className="app-main__inner">
//         <LoadingOverlay active={loading} spinner={<DNALoader />}>
//           <div className="header">
//             <Button color="link" className="p-0 text-decoration-none" onClick={() => navigate(-1)}>
//               <FaArrowLeft className="me-2" />
//               <span className="text-dark fw-bold">{t("Request For Quotation (RFQ)")}</span>
//             </Button>
            // {/* <div className="actions">
            //   {rfqDetails?.status === "Issued" ? (
            //     <>
            //       <button onClick={openPopup} className="reject-button">{t("Reject")}</button>
            //       <button onClick={handleAccept} className="accept-button">{t("Accept")}</button>
            //     </>
            //   ) : rfqDetails?.status === "accepted" || rfqDetails?.status === "confirmed" ? (
            //     orderInvoiceDetails?.[0]?.status !== 'paid' ? (
            //       <Button className="pay-now-button" onClick={() => { HandlePayment(orderInvoiceDetails?.[0]) }}>
            //         {t("Pay Now")}
            //       </Button>
            //     ) : (
            //       <button className="accepted-button">{t("Quotation Accepted")}</button>
            //     )
            //   ) : rfqDetails?.status === "rejected" || rfqDetails?.status === "cancelled" ? (
            //     <button className="rejected-button">{t("Quotation Rejected")}</button>
            //   ) : rfqDetails?.status === "processing" ? (
            //     <button className="processing-button">{t("Processing")}</button>
            //   ) : rfqDetails?.status === "in_transit" ? (
            //     <button className="in-transit-button">{t("In Transit")}</button>
            //   ) : rfqDetails?.status === "delivered" ? (
            //     <button className="delivered-button">{t("Delivered")}</button>
            //   ) : (
            //     <button disabled className="pending-button">{t("Pending")}</button>
            //   )}

            // </div> */}
//             <div className="actions">
//             {rfqDetails?.status === "Review" && (
//               <button onClick={finalIssueQuotation} className="accept-button">{t("Review (Issue Now)")}</button>
//             )}
//             {rfqDetails?.status === "requested" && (
//               <button onClick={IssueQuotation} className="accept-button">{t("Issue Quotation")}</button>
//             )}
//             {rfqDetails?.status === "accepted" && (
//               <button className="accept-button">{t("Quotation Accepted")}</button>
//             )}
//             {rfqDetails?.status === "rejected" && (
//               <>
//                 <button onClick={handleEditQuotation} className="edit-button">
//                   <FaEdit className="me-2" />
//                   {t("Edit Quotation")}
//                 </button>
                
//               </>
//             )}
//             {!["Review", "requested", "accepted", "rejected"].includes(rfqDetails?.status) && (
//               <button className="reject-button">{t("Pending")}</button>
//             )}
//           </div>

//           </div>
//           <div className="order-tabs mb-3">
//             <button
//               style={{
//                 background: activeTab === 'details' ? 'black' : 'white',
//                 color: activeTab === 'details' ? 'white' : 'black',
//                 marginRight: '10px',
//               }}
//               onClick={() => setActiveTab('details')}
//             >
//               Quotation Details
//             </button>
//             {orderTrackingDetails?.length !== 0 &&
//               <button
//                 style={{
//                   background: activeTab === 'tracking' ? 'black' : 'white',
//                   color: activeTab === 'tracking' ? 'white' : 'black',
//                   marginRight: '10px',
//                 }}
//                 onClick={() => setActiveTab('tracking')}
//               >
//                 Order Tracking
//               </button>
//             }
//             <button
//               style={{
//                 background: activeTab === 'messaging' ? 'black' : 'white',
//                 color: activeTab === 'messaging' ? 'white' : 'black',
//                 marginRight: '10px'
//               }}
//               onClick={() => {
//                 setActiveTab('messaging')
//                 fetchMessages()
//               }}
//             >
//               Message
//             </button>
//             {orderInvoiceDetails?.length !== 0 &&
//               <button
//                 style={{
//                   background: activeTab === 'invoice_details' ? 'black' : 'white',
//                   color: activeTab === 'invoice_details' ? 'white' : 'black',
//                 }}
//                 onClick={() => setActiveTab('invoice_details')}
//               >
//                 Invoice Details
//               </button>
//             }
//             {orderPaymentDetails?.length !== 0 &&
//               <button
//                 style={{
//                   background: activeTab === 'payment_details' ? 'black' : 'white',
//                   color: activeTab === 'payment_details' ? 'white' : 'black',
//                 }}
//                 onClick={() => setActiveTab('payment_details')}
//               >
//                 Payment Details
//               </button>
//             }
//           </div>
//           {activeTab === 'details' && renderOrderDetails()}
//           {activeTab === 'tracking' && renderTrackingContentDEMO()}

//           {activeTab === 'messaging' && renderMessagingTab()}
//           {activeTab === 'payment_details' && renderPaymentsDetails()}
//           {activeTab === 'invoice_details' && renderInvoiceDetails()}
//         </LoadingOverlay>
//       </div>
//       <style jsx>{`
// .app-main__outer {
//   background-color: #f8f9fa;
//   min-height: 100vh;
//   padding-top: 20px;
// }
// .rfq-card {
//   border-radius: 10px;
//   box-shadow: 0 2px 10px rgba(0,0,0,0.05);
//   border: none;
// }
// .form-select, .form-control {
//   border-color: #e0e0e0;
//   background-color: red;
//   border-radius: 5px;
//   padding: 10px;
// }
// .btn-light {
//   background-color: #ffffff;
//   border-color: #e0e0e0;
//   color: #333;
// }
// .btn-primary {
//   background-color: #3D607A;
//   border-color: #3D607A;
// }
// .text-muted {
//   color: #6c757d !important;
// }
// h4, h5 {
//   color: #333;
// }
// .item-list {
//   border-top: 1px solid #e0e0e0;
//   border-bottom: 1px solid #e0e0e0;
//   padding: 20px 0;
// }
// .item-image {
//   width: 40px;
//   height: 40px;
//   object-fit: cover;
//   border-radius: 5px;
// }
// .totals {
//   margin-top: 20px;
// }
// .totals-row {
//   display: flex;
//   justify-content: flex-end;
//   margin-bottom: 5px;
// }
// .total-amount {
//   font-weight: bold;
// }
// .header {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   margin-bottom: 20px;
// }
// .back-button {
//   background: none;
//   border: none;
//   font-size: 20px;
//   cursor: pointer;
// }
// .rfq-id {
//   font-weight: bold;
//   font-size: 1.5rem;
// }
// .actions {
//   display: flex;
//   gap: 10px;
// }
// .chat-container {
//     background: linear-gradient(135deg, #3a7bd5, #3a6073);
//     border-radius: 12px;
//     overflow: hidden;
//     display: flex;
//     flex-direction: column;
//     height: 600px;
//     box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
// }

// .chat-header {
//     background-color: rgba(0, 0, 0, 0.2);
//     color: #ffffff;
//     padding: 15px;
//     font-weight: bold;
//     text-align: center;
//     font-size: 1.2em;
// }

// .messages-container {
//     flex-grow: 1;
//     overflow-y: auto;
//     padding: 15px;
//     background-color: #F9F7F7;
// }

// .message-wrapper {
//     display: flex;
//     margin-bottom: 10px;
// }

// .message-wrapper.sent {
//     justify-content: flex-end;
// }

// .message-wrapper.received {
//     justify-content: flex-start;
// }

// .message-content {
//     max-width: 60%;
//     padding: 10px 15px;
//     border-radius: 18px;
//     position: relative;
//     box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
// }

// .message-wrapper.sent .message-content {
//     background-color: #00796B;
//     color: white;
// }

// .message-wrapper.received .message-content {
//     background-color: #1565C0;
//     color: white;
// }

// .message-sender {
//     font-weight: bold;
//     margin-bottom: 4px;
//     font-size: 0.9em;
//     opacity: 0.8;
// }

// .message-text {
//     margin-bottom: 4px;
//     line-height: 1.4;
// }

// .message-time {
//     font-size: 0.75em;
//     opacity: 0.7;
//     text-align: right;
// }

// .message-input {
//     display: flex;
//     align-items: center;
//     padding: 15px;
//     background-color: rgba(0, 0, 0, 0.2);
// }

// .message-input input {
//     flex-grow: 1;
//     border: none;
//     border-radius: 20px;
//     padding: 12px 20px;
//     margin-right: 10px;
//     background-color: rgba(255, 255, 255, 0.9);
//     color: #333;
//     font-size: 1em;
// }

// .message-input input::placeholder {
//     color: #999;
// }

// .message-input button {
//     background-color: #00796B;
//     border: none;
//     border-radius: 50%;
//     width: 45px;
//     height: 45px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     cursor: pointer;
//     transition: background-color 0.3s ease;
// }

// .message-input button:hover {
//     background-color: #00695C;
// }
//               `}</style>

//     </div>
//     </>
//   );
// };

// export default ViewRfqs;


// // import React, { useState, useEffect } from 'react';
// // import { Card, Row, Col, Input, Button, Form, FormGroup, Label, Modal, ModalBody, Table, Alert } from 'reactstrap';
// // import { FaPlus, FaCheck, FaTrash, FaArrowLeft, FaEdit } from 'react-icons/fa';
// // import { useNavigate, useParams, useLocation } from 'react-router-dom';
// // import RejectQuotationPopup from './RejectQuotationPopup';
// // import services from "./services";
// // // import AuthService from "../../Authentication/auth.service";
// // import Alerts from "../../components/Alerts/alerts";
// // import { useTranslation } from 'react-i18next';
// // import LoadingOverlay from "react-loading-overlay-ts";
// // import DNALoader from "../../components/Loaders/DnaLoader";
// // import PermissionAccess from '../../PermissionAccess/Access.js';
// // import QuotationModal from './QuotationModal';
// // import moment from 'moment';


// // const ViewRfqs = () => {
// //   const [rfqDetails, setRfqDetails] = useState(null);
// //   const [loading, setLoading] = useState(true);
// //   const [isPopupVisible, setPopupVisible] = useState(false);
// //   const navigate = useNavigate();
// //   const { authData, accessPages, super_admin, current_module } = PermissionAccess();
// //   const [isEditMode, setIsEditMode] = useState(false);

// //   const handleEditQuotation = () => {
// //     setIsEditMode(true);
// //     IssueQuotation();
// //   };

// //   const handleBatchChange = (itemIndex, batchIndex, newBatchId) => {
// //     setQuotationDetails(prevDetails => {
// //       const newItems = [...prevDetails.items];
// //       const item = newItems[itemIndex];
// //       const newBatch = item.availableBatches.find(batch => batch.batchId === newBatchId);

// //       if (newBatch) {
// //         item.selectedBatches[batchIndex] = {
// //           batchId: newBatch.batchId,
// //           batch_number: newBatch.batch_number,
// //           quantity: Math.min(newBatch.quantity, item.selectedBatches[batchIndex].quantity),
// //           expiry: newBatch.expiry
// //         };
// //       }

// //       return { ...prevDetails, items: newItems };
// //     });
// //   };

// //   const handleReIssueQuotation = async () => {
// //     try {
// //       const response = await services.ReIssueQuotation({
// //         _id: rfqDetails?._id,
// //         functionName: "ReIssueQuotation"
// //       }, authData.sessionID);

// //       if (response.data.status) {
// //         Alerts.swalSuccessAlert(t("Quotation re-issued successfully"));
// //         fetchRfqDetails(); // Refresh the RFQ details
// //       } else {
// //         Alerts.swalErrorAlert(response.data.message || t("Failed to re-issue quotation"));
// //       }
// //     } catch (error) {
// //       console.error("Error re-issuing quotation:", error);
// //       Alerts.swalErrorAlert(t("An error occurred while re-issuing the quotation"));
// //     }
// //   };


// //   const { t } = useTranslation();
// //   useEffect(() => {
// //     fetchRfqDetails();
// //   }, [id]);


// //   const fetchRfqDetails = async () => {
// //     setLoading(true);
// //     try {
// //       var payload = {
// //         functionName: "GetRFQDetails",
// //         postData: {
// //           _id: id,
// //         }
// //       }

// //       const response = await services.GetRFQDetails(payload, authData.sessionID);
// //       if (response.data.status) {
// //         console.log("response.data.data", response.data.data)
// //         setRfqDetails(response?.data?.data?.[0]);
// //         setDiscount(response?.data?.data?.[0]?.discount);
// //       } else {
// //         Alerts.swalErrorAlert(response.data.message || t("Failed to fetch RFQ details"));
// //       }
// //     } catch (error) {
// //       console.error("Error fetching RFQ details:", error);
// //       Alerts.swalErrorAlert(t("An error occurred while fetching RFQ details"));
// //     } finally {
// //       setLoading(false);
// //     }
// //   };

// //   const openPopup = () => setPopupVisible(true);
// //   const closePopup = () => setPopupVisible(false);

// //   const [quotationDetails, setQuotationDetails] = useState(null);
// //   const [isQuotationModalOpen, setIsQuotationModalOpen] = useState(false);
// //   const [outOfStockItems, setOutOfStockItems] = useState([]);
// //   const [discount, setDiscount] = useState(rfqDetails?.discount || 0);
// //   const [paymentTerms, setPaymentTerms] = useState('net-30');


// //   const IssueQuotation = async () => {
// //     try {
// //       var payload = {
// //         functionName: "GetQuotationDetails",
// //         postData: {
// //           _id: rfqDetails?._id,
// //         }
// //       }

// //       const response = await services.GetQuotationDetails(payload, authData.sessionID);
// //       if (response.data.status) {
// //         console.log("response", response)
// //         setQuotationDetails(response.data.data);
// //         setOutOfStockItems(response?.data?.data?.outOfStockItems || []);
// //         setIsQuotationModalOpen(true);
// //       } else {
// //         Alerts.swalErrorAlert(response.data.message || t("Failed to get quotation details"));
// //       }
// //     } catch (error) {
// //       console.error("Error getting quotation details:", error);
// //       Alerts.swalErrorAlert(t("An error occurred while getting quotation details"));
// //     }
// //   };


// //   const handlePriceChange = (index, newPrice) => {
// //     setQuotationDetails(prevDetails => {
// //       // Create a copy of the items array
// //       const updatedItems = [...prevDetails.items];

// //       // Update the specific item's price
// //       updatedItems[index] = { ...updatedItems[index], price: newPrice };

// //       // Return the updated quotation details
// //       return {
// //         ...prevDetails,
// //         items: updatedItems
// //       };
// //     });
// //   };
// //   const handleDiscountChange = (newDiscount) => {
// //     setDiscount(newDiscount);
// //   };

// //   const handlePaymentTermsChange = (newTerms) => {
// //     setPaymentTerms(newTerms);
// //   };

// //   const saveQuotation = async () => {
// //     try {
// //       const quotationData = {
// //         ...quotationDetails,
// //         discount,
// //         paymentTerms,
// //         rfq_id: rfqDetails?._id,
// //         isEdit: isEditMode
// //       };
// //       const response = await services.SaveQuotation(quotationData, authData.sessionID);
// //       if (response.data.status) {
// //         Alerts.swalSuccessAlert(t(isEditMode ? "Quotation updated successfully" : "Quotation saved successfully"));
// //         setIsQuotationModalOpen(false);
// //         setIsEditMode(false);
// //         fetchRfqDetails(); // Refresh the RFQ details
// //       } else {
// //         Alerts.swalErrorAlert(response.data.message || t("Failed to save quotation"));
// //       }
// //     } catch (error) {
// //       console.error("Error saving quotation:", error);
// //       Alerts.swalErrorAlert(t("An error occurred while saving the quotation"));
// //     }
// //   };

 

// //   const handleQuantityChange = (itemIndex, batchIndex, newQuantity) => {
// //     setQuotationDetails(prevDetails => {
// //       const newItems = [...prevDetails.items];
// //       const item = newItems[itemIndex];
// //       const batch = item.selectedBatches[batchIndex];
// //       const availableBatch = item.availableBatches.find(ab => ab.batchId === batch.batchId);

// //       if (availableBatch) {
// //         const parsedQuantity = parseInt(newQuantity, 10);
// //         batch.quantity = Math.min(Math.max(1, parsedQuantity), availableBatch.quantity);
// //       }

// //       return { ...prevDetails, items: newItems };
// //     });
// //   };



// //   const getImageSrc = (item) => {
// //     if (item?.itemDetails?.images && item?.itemDetails?.images?.length > 0) {
// //       const imageIndex = item?.index || 0; // Use the provided index or default to 0
// //       const imageSrc = item?.itemDetails?.images[imageIndex];

// //       // Check if the image is a base64 string
// //       if (imageSrc.startsWith('data:image')) {
// //         return imageSrc;
// //       } else {
// //         // If it's not a base64 string, assume it's a URL
// //         return imageSrc;
// //       }
// //     }
// //     return '';
// //   };


// //   const groupItemsByCategory = (items) => {
// //     return items?.reduce((acc, item) => {
// //       const category = item?.categoryDetails.Name;
// //       if (!acc[category]) {
// //         acc[category] = [];
// //       }
// //       acc[category].push(item);
// //       return acc;
// //     }, {});
// //   };

// //   const calculateTotals = (items) => {
// //     const subtotal = items?.reduce((total, item) => {
// //       const availableQuantity = item?.selectedBatches?.reduce((sum, batch) => sum + batch.quantity, 0) || 0;
// //       return total + (availableQuantity * item?.price || 0);
// //     }, 0);
// //     const discountAmount = discount ? (discount / 100) * subtotal : 0;
// //     const totalAmount = subtotal - discountAmount;
// //     return { subtotal, discountPercentage: discountAmount, totalAmount };
// //   };

// //   const renderItemRows = (items) => {
// //     let rows = [];

// //     // Group items by category
// //     const groupedItems = items?.reduce((acc, item) => {
// //       const category = item?.categoryDetails?.Name || 'Uncategorized';
// //       if (!acc[category]) {
// //         acc[category] = [];
// //       }
// //       acc[category].push(item);
// //       return acc;
// //     }, {});

// //     // Iterate through each category
// //     Object.entries(groupedItems).forEach(([category, categoryItems]) => {
// //       let isFirstItemInCategory = true;

// //       categoryItems.forEach((item, itemIndex) => {
// //         const batches = item?.selectedBatches || [{}];

// //         batches.forEach((batch, batchIndex) => {
// //           rows.push(
// //             <tr key={`${category}-${itemIndex}-${batchIndex}`}>
// //               {isFirstItemInCategory && (
// //                 <td rowSpan={categoryItems.reduce((sum, item) => sum + (item.selectedBatches?.length || 1), 0)}>
// //                   {category}
// //                 </td>
// //               )}
// //               {batchIndex === 0 && (
// //                 <>
// //                   <td rowSpan={batches.length}>
// //                     <div className="d-flex align-items-center">
// //                       {item?.itemDetails?.images && (
// //                         <img
// //                           src={item?.itemDetails?.images[(item?.itemDetails?.index - 1) || 0]}
// //                           alt={item?.itemDetails?.item_name}
// //                           className="item-image me-2"
// //                         />
// //                       )}
// //                       <span>{item?.itemDetails?.item_name}</span>
// //                     </div>
// //                   </td>
// //                   <td rowSpan={batches.length}>{item?.quantity}</td>
// //                   <td rowSpan={batches.length}>{`RM ${parseFloat(item?.price).toFixed(2)}`}</td>
// //                 </>
// //               )}
// //               <td>{batch.batch_number || '-'}</td>
// //               <td>{batch.expiry ? moment(batch.expiry).format('DD-MM-YYYY') : '-'}</td>
// //               <td>{`RM ${((batch.quantity || 0) * (item?.price || 0)).toFixed(2)}`}</td>
// //             </tr>
// //           );

// //           if (batchIndex === 0) {
// //             isFirstItemInCategory = false;
// //           }
// //         });
// //       });
// //     });

// //     return rows;
// //   };

// //   return (
// //     <div className="app-main__outer">
// //       <QuotationModal
// //         isOpen={isQuotationModalOpen}
// //         toggle={() => setIsQuotationModalOpen(!isQuotationModalOpen)}
// //         quotationDetails={quotationDetails}
// //         outOfStockItems={outOfStockItems}
// //         discount={discount}
// //         paymentTerms={paymentTerms}
// //         onPriceChange={handlePriceChange}
// //         onDiscountChange={handleDiscountChange}
// //         onPaymentTermsChange={handlePaymentTermsChange}
// //         onBatchChange={handleBatchChange}
// //         onQuantityChange={handleQuantityChange}
// //         onSave={saveQuotation}
// //         onIssue={finalIssueQuotation}
// //       />
// //       <div className="container mt-4">
// //         {isPopupVisible && <RejectQuotationPopup quotationId={id} onClose={closePopup} />}
// //         <div className="header">
// //           <Button color="link" className="p-0 text-decoration-none" onClick={() => navigate(-1)}>
// //             <FaArrowLeft className="me-2" />
// //             <span className="text-dark fw-bold">{t("Request For Quotation (RFQ)")}</span>
// //           </Button>
// //           <div className="actions">
// //             {rfqDetails?.status === "Review" && (
// //               <button onClick={finalIssueQuotation} className="accept-button">{t("Review (Issue Now)")}</button>
// //             )}
// //             {rfqDetails?.status === "requested" && (
// //               <button onClick={IssueQuotation} className="accept-button">{t("Issue Quotation")}</button>
// //             )}
// //             {rfqDetails?.status === "accepted" && (
// //               <button className="accept-button">{t("Quotation Accepted")}</button>
// //             )}
// //             {rfqDetails?.status === "rejected" && (
// //               <>
// //                 <button onClick={handleEditQuotation} className="edit-button">
// //                   <FaEdit className="me-2" />
// //                   {t("Edit Quotation")}
// //                 </button>
// //                 {/* <button onClick={handleReIssueQuotation} className="re-issue-button">
// //                   {t("Re-Issue Quotation")}
// //                 </button> */}
// //               </>
// //             )}
// //             {!["Review", "requested", "accepted", "rejected"].includes(rfqDetails?.status) && (
// //               <button className="reject-button">{t("Pending")}</button>
// //             )}
// //           </div>
// //         </div>

//         // <Card className="rfq-card">
//         //   <div className="p-4">
//         //     <h4 className="mb-4 fw-bold">{t("RFQ ID")} #{rfqDetails?.rfq_id}</h4>
//         //     <Form>
//         //       <Row className="mb-4">
//         //         <Col md={6}>
//         //           <FormGroup>
//         //             <Label for="rfq_date" className="text-muted mb-2">{t("RFQ Date")}</Label>
//         //             <Input type="text" id="rfq_date" className="form-select" readOnly value={new Date(rfqDetails?.datetime_added).toLocaleDateString()} />
//         //           </FormGroup>
//         //         </Col>
//         //         <Col md={6}>
//         //           <FormGroup>
//         //             <Label for="status" className="text-muted mb-2">{t("Status")}</Label>
//         //             <Input type="text" id="status" className="form-select" readOnly value={rfqDetails?.status} />
//         //           </FormGroup>
//         //         </Col>
//         //         {rfqDetails?.status === "rejected" &&
//         //           <Col md={12}>
//         //             <FormGroup>
//         //               <Label for="reason" className="text-muted mb-2">{t("Rejection Reason")}</Label>
//         //               <Input type="textarea" id="reason" readOnly value={rfqDetails?.reason} />
//         //             </FormGroup>
//         //           </Col>
//         //         }
//         //       </Row>

// //               <h5 className="mb-3 fw-bold">{t("List of Item(s)")}</h5>
// //               <div className="item-list mb-3">
// //                 <Table responsive>
// //                   <thead>
// //                     <tr>
// //                       <th>{t("Category")}</th>
// //                       <th>{t("Item(s) - Brand")}</th>
// //                       <th>{t("Quantity")}</th>
// //                       <th>{t("Unit Price")}</th>
// //                       <th>{t("Batches")}</th>
// //                       <th>{t("Expiry Date")}</th>
// //                       <th>{t("Total Price")}</th>
// //                     </tr>
// //                   </thead>
// //                   <tbody>
// //                     {renderItemRows(rfqDetails?.items || [])}
// //                   </tbody>
// //                 </Table>
// //               </div>

// //               {rfqDetails?.outOfStockItems?.length > 0 && (
// //                 <Alert color="warning">
// //                   The following items are out of stock or have insufficient quantity:
// //                   <ul>
// //                     {rfqDetails?.outOfStockItems?.map((item, index) => (
// //                       <li key={index}>
// //                         {item?.item_name}: {item?.extra_quantity} units out of stock
// //                       </li>
// //                     ))}
// //                   </ul>
// //                 </Alert>
// //               )}


// //               {rfqDetails?.status === "Issued" || rfqDetails?.status === "accepted" || rfqDetails?.status === "rejected" ?
// //                 <div className="totals text-end">
// //                   {(() => {
// //                     const { subtotal, discountPercentage, totalAmount } = calculateTotals(rfqDetails?.items || []);
// //                     return (
// //                       <>
// //                         {rfqDetails?.discount && rfqDetails?.discount !== null && rfqDetails?.discount !== '0' ?
// //                           <>
// //                             <div className="totals-row">
// //                               <span>{t("Subtotal")}:</span>
// //                               <span className="ms-3">RM {subtotal.toFixed(2)}</span>
// //                             </div>
// //                             < div className="totals-row">
// //                               <span>{t(`Discount(${rfqDetails?.discount || 0}%)`)}:</span>
// //                               <span className="ms-3">{parseFloat(discountPercentage).toFixed(2) || 0}</span>
// //                             </div>
// //                             < div className="totals-row total-amount fw-bold">
// //                               <span>{t("Total Amount")}:</span>
// //                               <span className="ms-3">RM {totalAmount.toFixed(2)}</span>
// //                             </div>
// //                           </>
// //                           :
// //                           <>
// //                             < div className="totals-row total-amount fw-bold">
// //                               <span>{t("Total Amount")}:</span>
// //                               <span className="ms-3">RM {totalAmount.toFixed(2)}</span>
// //                             </div>
// //                           </>
// //                         }
// //                       </>
// //                     );
// //                   })()}
// //                 </div>
// //                 : ''}
// //             </Form>
// //           </div>
// //         </Card>
// //       </div >
// //       <style jsx>{`
// //         .app-main__outer {
// //           background-color: #f8f9fa;
// //           min-height: 100vh;
// //           padding-top: 20px;
// //         }
// //         .rfq-card {
// //           border-radius: 10px;
// //           box-shadow: 0 2px 10px rgba(0,0,0,0.05);
// //           border: none;
// //         }
// //         .form-select, .form-control {
// //           border-color: #e0e0e0;
// //           border-radius: 5px;
// //           padding: 10px;
// //         }
// //         .btn-light {
// //           background-color: #ffffff;
// //           border-color: #e0e0e0;
// //           color: #333;
// //         }
// //         .btn-primary {
// //           background-color: #3D607A;
// //           border-color: #3D607A;
// //         }
// //         .text-muted {
// //           color: #6c757d !important;
// //         }
// //         h4, h5 {
// //           color: #333;
// //         }
// //         .item-list {
// //           border-top: 1px solid #e0e0e0;
// //           border-bottom: 1px solid #e0e0e0;
// //           padding: 20px 0;
// //         }
// //         .item-image {
// //           width: 40px;
// //           height: 40px;
// //           object-fit: cover;
// //           border-radius: 5px;
// //         }
// //         .totals {
// //           margin-top: 20px;
// //         }
// //         .totals-row {
// //           display: flex;
// //           justify-content: flex-end;
// //           margin-bottom: 5px;
// //         }
// //         .total-amount {
// //           font-weight: bold;
// //         }
// //         .header {
// //           display: flex;
// //           justify-content: space-between;
// //           align-items: center;
// //           margin-bottom: 20px;
// //         }
// //         .back-button {
// //           background: none;
// //           border: none;
// //           font-size: 20px;
// //           cursor: pointer;
// //         }
// //         .rfq-id {
// //           font-weight: bold;
// //           font-size: 1.5rem;
// //         }
// //         .actions {
// //           display: flex;
// //           gap: 10px;
// //         }
// //         .reject-button,
// //         .accept-button {
// //           padding: 8px 16px;
// //           border: none;
// //           border-radius: 5px;
// //           cursor: pointer;
// //         }
// //         .reject-button {
// //           background-color: #ffdddd;
// //           color: #d9534f;
// //         }
// //         .accept-button {
// //           background-color: #5cb85c;
// //           color: #fff;
// //         }
// //         .item-image {
// //           width: 40px;
// //           height: 40px;
// //           object-fit: cover;
// //           border-radius: 5px;
// //         }
// //         .table th, .table td {
// //           vertical-align: middle;
// //         }
// //         .edit-button,
// //         .re-issue-button {
// //           padding: 8px 16px;
// //           border: none;
// //           border-radius: 5px;
// //           cursor: pointer;
// //           margin-right: 10px;
// //         }
// //         .edit-button {
// //           background-color: #ffc107;
// //           color: #000;
// //         }
// //         .re-issue-button {
// //           background-color: #17a2b8;
// //           color: #fff;
// //         }

// //       `}</style>
// //     </div >
// //   );
// // };

// // export default ViewRfqs;
