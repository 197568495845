import React, { Fragment, useState, useEffect } from "react";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Row, Col, Card, CardHeader, CardBody, Modal, ModalHeader, ModalBody, Button, Form, FormGroup, Label, Input, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import "../../../assets/custom css/loadoverlay.scss";
import moment from 'moment';
import Alerts from "../../../components/Alerts/alerts";
import DataTable from 'react-data-table-component';
import DashboardService from "../dashboard.service";
import AuthService from "../../Authentication/auth.service.js";
import axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css';
import './Business_Customer_Home.css'; // Custom CSS for additional styling
import PDFViewer from 'react-pdf-js';
import { CSVLink } from "react-csv";

const B_URL = process.env.REACT_APP_LOGINURL;

const customStyles = {
  headRow: {
    backgroundColor: '#FFFFFF',
    color: '#A7A7A7 !important',
    border: 'none',
  },
  headCells: {
    style: {
      fontSize: '16px',
      fontWeight: 'bold', // Customize font weight
      border: 'none', // Remove bottom border of each header cell
    },
  },
  rows: {
    style: {
      border: 'none',
    },
  },
  cells: {
    style: {
      padding: '8px',
      border: 'none',

    },
  },
};

const Home = () => {
  const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('user')));
  const [showPopup, setShowPopup] = useState(!userInfo?.all_required_have);
  const [formData, setFormData] = useState({
    business_id: userInfo?.business_id,
    user_id: userInfo?._id,
    registration_number: '',
    sst_registration_number: '',
    PostalZone: '',
    phone: '',
    tin: '',
    id_type: '',
    id_value: '',
    address: '',
    CityName: '',
    CountrySubentityCode: '',
    AddressLine1: '',
    AddressLine2: '',
    IdentificationCodeCountry: '',
  });
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [receipt, setReceipt] = useState(null);
  const [invoices, setInvoices] = useState([]);
  const [verifiedInvoices, setVerifiedInvoices] = useState([]);
  const [tab, setTab] = useState('pending');
  const [loading, setLoading] = useState(false);
  const [modalContent, setModalContent] = useState({ show: false, file: '' });
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchInvoices();
  }, []);
  useEffect(() => {
    fetchVerifiedInvoices(1, pageLimit);
  }, [pageLimit]);

  const fetchVerifiedInvoices = async (page, limit) => {
    setLoading(true);
    try {
      const stored_user = userInfo;
      let business = []
      if (stored_user.businesses) {
        business = stored_user.businesses
      }
      var offset = (page - 1) * limit;
      if (offset < 1) {
        offset = 0;
      }

      // Set loading state to true before fetching data
      setLoading(true);

      // Fetch data from DashboardService
      const response = await DashboardService.Get_User_Invoices(offset, limit, business, userInfo?._id);
      if (response.data.status === false) {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      } else {
        setVerifiedInvoices(response.data.data);
        setTotalRows(response.data.count);
      }
    } catch (error) {
      Alerts.swalErrorAlert(t("Failed to fetch verified invoices. Please try again."));
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchVerifiedInvoices(page, pageLimit);
  };

  const handlePerRowsChange = async (newLimit, page) => {
    setPageLimit(newLimit);
    fetchVerifiedInvoices(page, newLimit);
  };



  const fetchInvoices = async () => {
    setLoading(true);
    try {
      let payload = {
        functionName: 'fetchInvoices',
        postData: {
          user_id: userInfo._id,
          business_id: userInfo.business_id
        }
      }
      const response = await DashboardService.getInvoices(payload);
      const allInvoices = response.data.data;
      console.log("allInvoices", allInvoices)
      setLoading(false);
      if (response.data.status === false) {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        if (allInvoices.length > 0) {
          setInvoices(allInvoices.filter(inv => inv.status === 'pending'));
          setVerifiedInvoices(allInvoices.filter(inv => inv.status === 'verified'));
        }

      }
    } catch (error) {
      console.log("error", error)
      Alerts.swalErrorAlert(t("Failed to fetch invoices. Please try again."));
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await AuthService.Update_User(formData);
      if (response.data.status) {
        const updatedUser = { ...userInfo, all_required_have: true, ...formData };
        localStorage.setItem('user', JSON.stringify(updatedUser));
        setUserInfo(updatedUser);
        setShowPopup(false);
        Alerts.swalSuccessAlert("Information updated successfully!");
      } else {
        handleAuthError(response.data);
      }
    } catch (error) {
      Alerts.swalErrorAlert(t("Network Operation failed."));
    } finally {
      setLoading(false);
    }
  };

  const handleReceiptUpload = async (e) => {
    e.preventDefault();
    if (!receipt) {
      Alerts.swalErrorAlert("Please select a receipt to upload.");
      return;
    }
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('receipt', receipt);
      formData.append('user_id', userInfo._id);
      formData.append('business_id', userInfo.business_id);

      const response = await axios.post(B_URL + "Request_E_InvoiceModule/uploadInvoice", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'token': localStorage.getItem("session-id")
        }
      });

      if (response.data.status) {
        Alerts.swalSuccessAlert("Receipt uploaded successfully!");
        fetchInvoices();
      } else {
        Alerts.swalErrorAlert(response.data.message || "Failed to upload receipt. Please try again.");
      }
    } catch (error) {
      Alerts.swalErrorAlert("Network Operation failed.");
    } finally {
      setLoading(false);
    }
  };

  const handleGetEInvoice = async (invoiceId) => {
    setLoading(true);
    try {
      await DashboardService.generateEInvoice(invoiceId);
      Alerts.swalSuccessAlert("E-Invoice generated successfully!");
      fetchInvoices();
    } catch (error) {
      Alerts.swalErrorAlert("Failed to generate E-Invoice. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleAuthError = (data) => {
    if (data.message === "Unauthorized") {
      AuthService.logout();
      navigate("/login");
      window.location.reload();
    } else {
      Alerts.swalErrorAlert(data.message || t("Error: Can't update user at the moment. Please try again later."));
    }
  };

  const ReceiptPreview = ({ file }) => {
    const getFileType = (fileName) => {
      const extension = fileName.split('.').pop().toLowerCase();
      if (['jpg', 'jpeg', 'png'].includes(extension)) return 'image';
      if (extension === 'pdf') return 'pdf';
      if (extension === 'csv') return 'csv';
      return 'unknown';
    };

    const fileType = getFileType(file);

    const handleView = () => {
      setModalContent({ show: true, file: `${process.env.REACT_APP_IMAGEURL}${file}` });
    };

    return (
      <>
        <Button color="link" onClick={handleView} className="preview-button">
          View
        </Button>

        <Modal
          isOpen={modalContent.show}
          toggle={() => setModalContent({ ...modalContent, show: false })}
          size="lg"
          className="receipt-modal"
        >
          <ModalHeader toggle={() => setModalContent({ ...modalContent, show: false })}>
            Receipt Preview
          </ModalHeader>
          <ModalBody className="receipt-preview-body">
            {fileType === 'image' ? (
              <img src={modalContent.file} alt="Receipt" className="receipt-image" />
            ) : fileType === 'pdf' ? (
              <PDFViewer file={modalContent.file} className="pdf-viewer" />
            ) : fileType === 'csv' ? (
              <div className="csv-container">
                <CSVLink data={[]} filename={file} className="csv-download-link">
                  Download CSV
                </CSVLink>
              </div>
            ) : (
              'No preview available'
            )}
          </ModalBody>
        </Modal>
      </>
    );
  };


  const columns = [
    {
      name: 'Busisness',
      selector: row => row?.Businesses_Details?.[0].name,
      sortable: true,
    },
    {
      name: 'Date',
      selector: row => moment(row.date).format('DD-MM-YYYY'),
      sortable: true,
    },
    {
      name: 'Receipt',
      cell: row => row.file ? (
        <ReceiptPreview file={row.file} />
      ) : 'No receipt',
    },
    // {
    //   name: 'Actions',
    //   cell: row => (
    //     <Button color="primary" onClick={() => handleGetEInvoice(row.id)} className="action-button">
    //       Get E-Invoice
    //     </Button>
    //   ),
    // },
  ];

  const verifiedColumns = [
    { name: 'No. #', width: '8%', selector: (row, index) => `00${index + 1}`, sortable: true },
    // { name: 'No. #', width: '10%', selector: row => row?.invoice_number, sortable: true },
    { name: 'E-Invoice ID', width: '12%', selector: row => row?.invoice_number, sortable: true },

    // {
    //   name: 'No. #',
    //   selector: row => row?.invoice_number,
    //   sortable: true,
    // },
    // {
    //   name: 'E-Invoice ID',
    //   selector: row => row?.external_invoice_number,
    //   sortable: true,
    // },
    {
      name: 'Business',
      selector: row => row?.Businesses_Details?.[0]?.name,
      sortable: true,
    },
    {
      name: 'Buyer',
      selector: row => row?.user_details?.[0]?.name,
      sortable: true,
    },
    {
      name: 'Submitted',
      selector: row => moment(row?.submitted_time).format('MM/DD/YYYY (hh:mm A)'),
      sortable: true,
    },
    {
      name: 'Processed',
      selector: row => row.processed_time ? moment(row?.processed_time).format('MM/DD/YYYY (hh:mm A)') : 'N/A',
      sortable: true,
    },
    {
      name: 'Total',
      selector: row => row?.total_amount,
      sortable: true,
    },
    {
      name: 'Type',
      selector: row => row?.invoice_type,
      sortable: true,
    },
    {
      name: 'Status',
      selector: row => row?.lhdn_status,
      cell: row => (
        <span className={`status-${row?.lhdn_status?.toLowerCase()}`}
          style={{
            color: row?.lhdn_status === 'Valid' ? '#16DBCC' :
              row?.lhdn_status === 'Invalid' ? '#F16262' :
                row?.lhdn_status === 'Cancelled' ? 'red' :
                  row?.lhdn_status === 'Submitted' ? '#50A6E4' : '#50A6E4'
          }}>
          {row?.lhdn_status === 'Valid' ? 'Pass' :
            row?.lhdn_status === 'Invalid' ? 'Failed' :
              row?.lhdn_status === 'Cancelled' ? 'Cancelled' :
                row?.lhdn_status === 'Submitted' ? 'Submitted' : row.lhdn_status}
        </span>
      ),
      sortable: true,
    },
  ];



  const handleRowClick = (row) => {
    navigate(`/e-invoice-details-2/${row._id}`, { state: row });
  };

  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <TransitionGroup>
            <CSSTransition component="div" classNames="TabsAnimation"
              appear={true} timeout={0} enter={false} exit={false}>
              <div>
                <div className="app-page-title">
                  <div className="page-title-wrapper">
                    <div className="page-title-heading fw-bold">
                      <div className="page-title-icon">
                        <i className="pe-7s-graph2 icon-gradient bg-tempting-azure"></i>
                      </div>
                      <div>
                        {t('Invoices')}
                        <div className="page-title-subheading">{t('List of all your invoices.')}</div>
                      </div>
                    </div>
                  </div>
                </div>



                <Row>
                  <Col md="12">
                    {/* Upload Receipt Form */}
                    <Card>
                      <Form onSubmit={handleReceiptUpload}>
                        <FormGroup>
                          <Label for="receipt">Upload Receipt</Label>
                          <Input
                            type="file"
                            name="receipt"
                            id="receipt"
                            onChange={(e) => setReceipt(e.target.files[0])}
                            required
                          />
                        </FormGroup>
                        <Button type="submit" color="primary">Upload</Button>
                      </Form>
                    </Card>
                  </Col>

                  <Col md="12">
                    <Card>
                      <CardHeader>
                        <Nav tabs>
                          <NavItem>
                            <NavLink
                              className={tab === 'pending' ? 'active' : ''}
                              onClick={() => setTab('pending')}>
                              {t('Pending Invoices')}
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={tab === 'verified' ? 'active' : ''}
                              onClick={() => setTab('verified')}>
                              {t('Verified Invoices')}
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </CardHeader>
                      <CardBody>
                        <TabContent activeTab={tab}>
                          <TabPane tabId="pending">
                            <LoadingOverlay
                              active={loading}
                              spinner={<DNALoader />}
                              className="loading-overlay"
                            >
                              <DataTable
                                columns={columns}
                                data={invoices}
                                noHeader
                                pagination
                              />
                            </LoadingOverlay>
                          </TabPane>
                          <TabPane tabId="verified">
                            <LoadingOverlay
                              active={loading}
                              spinner={<DNALoader />}
                              className="loading-overlay"
                            >
                              <DataTable
                                columns={verifiedColumns}
                                data={verifiedInvoices}
                                pagination
                                customStyles={customStyles}
                                paginationPerPage={10}
                                paginationRowsPerPageOptions={[5, 10, 15, 20]}
                                onRowClicked={handleRowClick}
                                pointerOnHover
                                fixedHeader
                                fixedHeaderScrollHeight="560px"
                                paginationServer
                                paginationTotalRows={totalRows}
                                onChangePage={handlePageChange}
                                onChangeRowsPerPage={handlePerRowsChange}
                                paginationResetDefaultPage={resetPaginationToggle}

                              />
                           </LoadingOverlay>
                          </TabPane>
                        </TabContent>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>


                {/* Popup Form */}
                <Modal isOpen={showPopup} toggle={() => setShowPopup(!showPopup)} size="lg">
                  <ModalHeader toggle={() => setShowPopup(!showPopup)}>Additional Information Required</ModalHeader>
                  <ModalBody>
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col md={6}>
                          <FormGroup>

                            <Label for="number">Registration Number</Label>
                            <Input type="text"  name="registration_number" id="registration_number" value={formData.registration_number} onChange={handleInputChange} required />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="tin">TIN</Label>
                            <Input type="text" name="tin" id="tin" value={formData.tin} onChange={handleInputChange} required />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="id_type">ID Type</Label>
                            <Input type="select" name="id_type" id="id_type" value={formData.id_type} onChange={handleInputChange} required>
                              <option value="">Select ID Type</option>
                              <option value="BRN">BRN</option>
                              <option value="SSM">SSM</option>
                              <option value="PASSPORT">Passport</option>
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="id_value">ID Value</Label>
                            <Input type="text" name="id_value" id="id_value" value={formData.id_value} onChange={handleInputChange} required />
                          </FormGroup>
                        </Col>
                      </Row>

                      <FormGroup>
                        <Label for="sst_registration_number">SST Registration Number</Label>
                        <Input type="text" name="sst_registration_number" id="sst_registration_number" value={formData.sst_registration_number} onChange={handleInputChange} required />
                      </FormGroup>
                      <FormGroup>
                        <Label for="address">Address</Label>
                        <Input type="textarea" name="address" id="address" value={formData.address} onChange={handleInputChange} required />
                      </FormGroup>
                      <Row>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="CityName">City Name</Label>
                            <Input type="text" name="CityName" id="CityName" value={formData.CityName} onChange={handleInputChange} required />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="PostalZone">Postal Zone</Label>
                            <Input type="text"  name="PostalZone" id="PostalZone" value={formData.PostalZone} onChange={handleInputChange} required />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="CountrySubentityCode">Country Subentity Code</Label>
                            <Input type="text" placeholder="e.g 14" name="CountrySubentityCode" id="CountrySubentityCode" value={formData.CountrySubentityCode} onChange={handleInputChange} required />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="IdentificationCodeCountry">Identification Code Country</Label>
                            <Input type="text" placeholder="e.g MYS" name="IdentificationCodeCountry" id="IdentificationCodeCountry" value={formData.IdentificationCodeCountry} onChange={handleInputChange} required />
                          </FormGroup>
                        </Col>
                      </Row>
                      <FormGroup>
                        <Label for="AddressLine1">Address Line 1</Label>
                        <Input type="text" name="AddressLine1" id="AddressLine1" value={formData.AddressLine1} onChange={handleInputChange} required />
                      </FormGroup>
                      <FormGroup>
                        <Label for="AddressLine2">Address Line 2</Label>
                        <Input type="text" name="AddressLine2" id="AddressLine2" value={formData.AddressLine2} onChange={handleInputChange} />
                      </FormGroup>
                      <FormGroup>
                        <Label for="phone">Contact Number</Label>
                        <Input type="text" name="phone" id="phone" value={formData.phone} onChange={handleInputChange} required />
                      </FormGroup>
                      <Button color="primary" type="submit">Submit</Button>
                    </Form>
                  </ModalBody>
                </Modal>

              </div>
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
    </Fragment >
  );
};

export default Home;
