import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import styled from "styled-components";
import EntitiesService from "../../../pages/Entities/entities.service";
import AuthService from "../../../pages/Authentication/auth.service";
import Alerts from "../../../components/Alerts/alerts";
import PermissionAccess from '../../../PermissionAccess/Access.js';


const Input = styled.select.attrs(props => ({
  type: "select",
  size: props.small ? 5 : undefined
}))`
  height: 32px;
  width: 17%;
  border-radius: 6px;
  border: 1px solid var(--buttons-color, #30b1ff);
  padding: 0 8px 0 8px;
  margin-bottom: 12px;
  justify-content: flex-start;
  background: white;
  margin-right: 12px;
`;

const ClearButton = styled.button`
  border:none;
  height: 32px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  margin-bottom: 12px;
  border-radius: 0 5px 5px 0;
  color: white;
  background: var(--buttons-color, #30b1ff);
`;

const CollectionFilter = ({ filterText1, handleKeyDown, onFilter1, onClear }) => {

  const { t, i18n } = useTranslation();
  const [collection, setCollection] = useState([]);
  const navigate = useNavigate();
  const { authData, accessPages, super_admin, current_module } = PermissionAccess();

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchcollections();
  }, []);

  function fetchcollections() {
    EntitiesService.fetchcollections(authData.sessionID).then((response) => {
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setCollection(response.data.data);
      }
    },
      (error) => {
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };



  return (
    <>
      <Input
        type="select"
        placeholder={t("Filter by collection")}
        value={filterText1}
        onChange={onFilter1}
        onKeyDown={handleKeyDown}
        id="colfilter"
      >

        <option disabled={false} value="">{`--${t("Select Collection")}--`}</option>
        {collection &&
          collection.map((opt_collection, inde) => (
            <option key={inde} value={opt_collection.name}>{opt_collection.name}</option>
          ))
        }
      </Input>
      {/* <ClearButton onClick={onClear}>X</ClearButton> */}
    </>
  );

};

export default CollectionFilter;


