import axios from "axios";

const B_URL = process.env.REACT_APP_LOGINURL


const headers = {
  'Content-Type': 'application/json',
  'token': localStorage.getItem("session-id")
}


const getAllVariations = (sessionID,page, limit, filterStatus, filterText) => {
  var payload = {
    "functionName": "GetVariations",
    "postData": {
      "offset": page,
      "filterStatus": filterStatus,
      "filterText": filterText,
      "sort": {
        "_id": -1
      },
      "limit": limit,
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "VariationsModule/GetVariations", payload, { headers });
};
const GetVariationTypes = (sessionID,payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }


  return axios.post(B_URL + "VariationTypesModule/Get_Variation_sub_Types", payload, { headers });
};

const getAllVariationsAttributes = (Variations_id) => {
  var payload = {
    "functionName": "GetVariationsAttribute",
    "postData": {
      "offset": 0,
      "sort": {
        "_id": -1
      },
      "limit": 99999,
      "Variations_id": Variations_id,
      "attribute_id": ""
    }
  }
  return axios.post(B_URL + "VariationsAttributeModule/GetVariationsAttribute", payload, { headers });
};

const updateMultipleVariationsAttributes = (data) => {
  // console.log("DATA:", data);
  var payload = {
    "functionName": "UpdateMultipleVariationsAttributes",
    "postData": {
      "Variations_id": data.Variations_id,
      "section_id": data.section_id,
      "updates": data.updates
    }
  }
  return axios.post(B_URL + "VariationsAttributeModule/UpdateMultipleVariationsAttribute", payload, { headers });
};

const UpdateVariations = (sessionID,payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  // console.log("DATA:", data);
  return axios.post(B_URL + "VariationsModule/UpdateVariations", payload, { headers });
};

const AddSection = (data) => {
  // console.log("DATA:", data);
  var payload = {
    "functionName": "AddSection",
    "postData": {
      "name": data.name,
      "status": data.status
    }
  }
  return axios.post(B_URL + "SectionModule/AddSection", payload, { headers });
};

const AddVariations = (payloadData) => {
  return axios.post(B_URL + "VariationsModule/AddVariations", payloadData, { headers });
};
const AddVariationTypes = (payloadData) => {
  return axios.post(B_URL + "VariationsTypesModule/AddVariationTypes", payloadData, { headers });
};
const AddBulkVariations = (sessionID, payloadData) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "VariationsModule/AddBulkVariations", payloadData, { headers });
};
const AddBulkVariationTypes = (payloadData) => {
  return axios.post(B_URL + "VariationTypesModule/AddBulkVariationTypes", payloadData, { headers });
};

const EditSection = (data, id) => {
  // console.log("DATA:", data);
  var payload = {
    "functionName": "UpdateSection",
    "postData": {
      "section_id": id,
      "name": data.name,
      "status": data.status
    }
  }
  return axios.post(B_URL + "SectionModule/UpdateSection", payload, { headers });
};

const EditAttribute = (data, id) => {
  // console.log("DATA:", data);
  var payload = {
    "functionName": "UpdateAttribute",
    "postData": {
      "attribute_id": id,
      "lable": data.lable,
      "key": data.key,
      "status": data.status
    }
  }
  return axios.post(B_URL + "AttributeModule/UpdateAttribute", payload, { headers });
};

const AddAttribute = (data) => {
  // console.log("DATA:", data);
  var payload = {
    "functionName": "AddAttribute",
    "postData": {
      "lable": data.lable,
      "key": data.key,
      "status": data.status
    }
  }
  return axios.post(B_URL + "AttributeModule/AddAttribute", payload, { headers });
};

const getAllSections = (page, limit) => {
  var payload = {
    "functionName": "GetSections",
    "postData": {
      "offset": 0,
      "sort": {
        "_id": -1
      },
      "limit": 10
    }
  }
  return axios.post(B_URL + "SectionModule/GetSections", payload, { headers });
};

const GetSingleSection = (id) => {
  var payload = {
    "functionName": "GetSingleSection",
    "postData": {
      "id": id
    }
  }
  return axios.post(B_URL + "SectionModule/GetSingleSection", payload, { headers });
};

const GetSingleAttribute = (id) => {
  var payload = {
    "functionName": "GetSingleAttribute",
    "postData": {
      "id": id
    }
  }
  return axios.post(B_URL + "AttributeModule/GetSingleAttribute", payload, { headers });
};

const getAllAttributes = (page, limit) => {
  var payload = {
    "functionName": "GetAttributes",
    "postData": {
      "offset": 0,
      "sort": {
        "_id": -1
      },
      "limit": 10
    }
  }
  return axios.post(B_URL + "AttributeModule/GetAttributes", payload, { headers });
};

const deleteSection = (id) => {
  var payload = {
    "functionName": "DeleteSection",
    "postData": {
      "section_id": id
    }
  }
  return axios.post(B_URL + "SectionModule/DeleteSection", payload, { headers });
};

const deleteAttribute = (id) => {
  var payload = {
    "functionName": "DeleteAttribute",
    "postData": {
      "attribute_id": id
    }
  }
  return axios.post(B_URL + "AttributeModule/DeleteAttribute", payload, { headers });
};

const deleteBulkSection = (selectedRows) => {
  var payload = {
    "functionName": "DeleteBulkSection",
    "postData": {
      "section_ids": selectedRows
    }
  }
  return axios.post(B_URL + "SectionModule/DeleteBulkSection", payload, { headers });
};

const deleteBulkAttribute = (selectedRows) => {
  var payload = {
    "functionName": "DeleteBulkAttribute",
    "postData": {
      "attribute_ids": selectedRows
    }
  }
  return axios.post(B_URL + "AttributeModule/DeleteBulkAttribute", payload, { headers });
};
const DeleteBulkVariations = (sessionID,selectedRows) => {
  var payload = {
    "functionName": "DeleteBulkVariations",
    "postData": {
      "Variations_ids": selectedRows
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "VariationsModule/DeleteBulkVariations", payload, { headers });
};

const DeleteBulkVariationTypes = (sessionID,selectedRows) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  var payload = {
    "functionName": "DeleteBulkVariationTypes",
    "postData": {
      "_ids": selectedRows
    }
  }
  return axios.post(B_URL + "VariationTypesModule/DeleteBulkVariationTypes", payload, { headers });
};
const deleteVariation = (sessionID,id) => {
  var payload = {
    "functionName": "DeleteVariations",
    "postData": {
      "Variations_id": id
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "VariationsModule/DeleteVariations", payload, { headers });
};
const DeleteVariationTypes = (sessionID,id) => {
  var payload = {
    "functionName": "DeleteVariationTypes",
    "postData": {
      "_id": id
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "VariationTypesModule/DeleteVariationTypes", payload, { headers });
};

const UpdateVariationTypes = (payload) => {
  // console.log("DATA:", data);
  return axios.post(B_URL + "VariationTypesModule/UpdateVariationTypes", payload, { headers });
};
const VariationsService = {
  getAllVariations,
  GetVariationTypes,
  AddVariationTypes,
  AddVariations,
  AddBulkVariations,
  AddBulkVariationTypes,
  getAllVariationsAttributes,
  updateMultipleVariationsAttributes,
  UpdateVariations,
  UpdateVariationTypes,
  getAllSections,
  getAllAttributes,
  AddSection,
  AddAttribute,
  GetSingleSection,
  EditSection,
  GetSingleAttribute,
  EditAttribute,
  deleteSection,
  deleteAttribute,
  DeleteVariationTypes,
  deleteVariation,
  deleteBulkSection,
  deleteBulkAttribute,
  DeleteBulkVariations,
  DeleteBulkVariationTypes
};

export default VariationsService;