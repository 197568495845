import React, { useEffect, useState, Fragment } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import DataTable from 'react-data-table-component';
import { Row, Col, Card, CardBody, Button, ModalHeader, ModalBody, ModalFooter, Modal, Input, Label, FormGroup, Form } from "reactstrap";
import AuthService from "../Authentication/auth.service";
import { useTranslation } from 'react-i18next';
import LoadingOverlay from "react-loading-overlay-ts";
import fileDownload from 'js-file-download'
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";
import Alerts from "../../components/Alerts/alerts";
import Services from "./services";
import AddStocks from "./AddStocks";
import UpdateStock from "./UpdateStocks";
import StocksFilter from "../../components/Table Filters/MemberFilter.js";
import styled from 'styled-components';
import PermissionAccess from '../../PermissionAccess/Access.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faEye } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@mui/material/Tooltip';
import moment from "moment";

const ImageContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const SmallImage = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  transition: all 0.3s ease;
`;

const LargeImage = styled.img`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 80%;
  max-height: 80%;
  z-index: 1000;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0,0,0,0.3);
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.7);
  z-index: 999;
`;

const StocksListing = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [Customers, setCustomers] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const { authData, accessPages, super_admin, current_module } = PermissionAccess();
  const [StockToEdit, setStockToEdit] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [modal, setModal] = useState(false);
  const [warehouses, setWarehouses] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState('');
  const [csvFile, setCsvFile] = useState(null);
  const [Fetched_Businesses, setFetched_Businesses] = useState([]);

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchWarehouses();
    Fetch_Businesses();
    fetchCustomers(currentPage, pageLimit, filterText, filterStatus);
  }, [pageLimit, i18n]);


  function Fetch_Businesses() {
    setLoading(true);
    Services.GetBusinesses(authData.sessionID).then(
      (response) => {
        setLoading(false);
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        } else {
          const business = response.data.data[0];
          console.log("business", business)
          setFetched_Businesses(business);
        }
      },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        Alerts.swalErrorAlert(errContent || t("Server Error, Please try again later."));
      }
    );
  }



  const fetchWarehouses = () => {
    Services.GetWarehouses(authData.sessionID)
      .then((response) => {
        if (response.data.status) {
          setWarehouses(response.data.data);
        } else {
          Alerts.swalErrorAlert(response.data.message || t("Error fetching warehouses."));
        }
      })
      .catch((error) => {
        Alerts.swalErrorAlert(t("Failed to fetch warehouses"));
      });
  };
  const handleWarehouseChange = (e) => {
    setSelectedWarehouse(e.target.value);
  };




  // const fetchCustomers = (page, limit, text, status) => {
  //   const offset = (page - 1) * limit;
  //   const getData = {
  //     functionName: "GetCustomers",
  //     postData: {
  //       offset,
  //       filterStatus: status,
  //       filterText: text,
  //       sort: { _id: -1 },
  //       limit,
  //       business_id: authData.userData.businesses || []
  //     }
  //   };

  //   setLoading(true);
  //   Services.GetCustomers(authData.sessionID, getData)
  //     .then((response) => {
  //       setLoading(false);
  //       if (response.data.message === "Unauthorized") {
  //         AuthService.logout();
  //         navigate("/login");
  //       } else {
  //         setCustomers(response.data.data);
  //         setTotalRows(response.data.count.count);
  //       }
  //     })
  //     .catch((error) => {
  //       setLoading(false);
  //       Alerts.swalErrorAlert(t("Server Error, Please try again later."));
  //     });
  // };

  function fetchCustomers(page_index, page_limit, filterText, filterStatus) {
    var offset = (page_index - 1) * page_limit;
    if (offset < 1) {
      offset = 0;
    }
    const stored_user = authData.userData;
    let business = []
    if (stored_user.businesses) {
      business = stored_user.businesses
    }

    var getData = {
      functionName: "GetStocks",
      "postData": {
        "offset": offset,
        "filterStatus": filterStatus,
        "filterText": filterText,
        "sort": {
          "_id": -1
        },
        "limit": page_limit,
        business_id: business
      }
    }
    setLoading(true);
    Services.GetStocks(authData.sessionID, getData).then((response) => {
      setLoading(false);
      console.log("Get Stocks response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setCustomers(response.data.data);
        console.log("Responsse", response.data);
        setTotalRows(response.data.count.count);
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };
  const handleSuccess = () => {
    fetchCustomers(currentPage, pageLimit, filterText, filterStatus);
  };

  const confirmDelete = (id) => {
    Alerts.swalConfirmDeleteAlert(t("Once deleted, you will not be able to recover this Record!"))
      .then(res => {
        if (res) {
          deleteStock(id);
        }
      });
  };

  const deleteStock = (id) => {
    setLoading(true);
    Services.DeleteStock(authData.sessionID, id)
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          Alerts.swalSuccessAlert(response.data.message || t("Data Deleted Successfully."));
          fetchCustomers(currentPage, pageLimit, filterText, filterStatus);
        } else {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
          } else {
            Alerts.swalErrorAlert(response.data.message || t("Error: Can't delete at the moment."));
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        Alerts.swalErrorAlert(t("Delete operation failed"));
      });
  };

  const handleDeleteSelected = () => {
    if (selectedRows?.length > 0) {
      Alerts.swalConfirmDeleteAlert(t(selectedRows?.length + " records selected. Once deleted, you will not be able to recover this!"))
        .then(res => {
          if (res) {
            deleteBulkCustomers(selectedRows);
          }
        });
    } else {
      Alerts.swalErrorAlert('No records selected');
    }
  };

  const deleteBulkCustomers = (ids) => {
    setLoading(true);
    Services.DeleteBulkStock(authData.sessionID, ids)
      .then((response) => {
        setLoading(false);
        setSelectedRows([]);
        if (response.data.status) {
          Alerts.swalSuccessAlert(response.data.message || t("Data Deleted Successfully."));
          fetchCustomers(currentPage, pageLimit, filterText, filterStatus);
        } else {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
          } else {
            Alerts.swalErrorAlert(response.data.message || t("Error: Can't delete the Collection Data at the moment."));
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        Alerts.swalErrorAlert(t("Delete operation failed"));
      });
  };
  const handleSelectAllChange = (e) => {
    if (e.target.checked) {
      setSelectedRows(Customers.map(Stock => Stock._id));
    } else {
      setSelectedRows([]);
    }
  };

  const columns = [
    {
      name: t("Item Code"),
      selector: row => row.item_code,
      sortable: true,
    },
    {
      name: t("Batch Number"),
      selector: row => row.batch_number,
    },
    {
      name: t("Quantity"),
      selector: row => row.quantity,
    },
    {
      name: t('Expiry Date'),
      selector: row => moment(row.expiry_date, 'YYYY-MM-DD').format('DD-MM-YYYY'),
    },
    {
      name: t('Warehouse'),
      selector: row => row.warehouse,
    },
    {
      name: t('Status'),
      width: '11%',
      selector: (row) => {
        return (
          <div>
            {row.status === "Inactive" ?
              <button type="button"
                className="btn btn-danger btn-hover-shine me-3 btn">Inactive
              </button>
              :
              row.status === "Active" ?
                <button type="button"
                  className="btn btn-success btn-hover-shine me-3 btn">Active
                </button>
                :
                <button type="button"
                  className="btn btn-default btn-hover-shine me-3 btn">{row.status}
                </button>
            }
          </div>
        );
      }
    },
    {
      name: t("Actions"),
      selector: (row) => {
        return (
          <div>
            <button className="mt-2 mb-2 me-2 btn-icon btn">
              {(super_admin || current_module?.EditData === 1) && (
                <Tooltip title={t("Edit")}>
                  <i onClick={() => setStockToEdit(row)} className="pe-7s-pen btn-icon-wrapper text-primary"> </i>
                </Tooltip>
              )}
              {(super_admin || current_module?.DeleteData === 1) && (
                <Tooltip title={t("Delete")}>
                  <i onClick={() => confirmDelete(row._id)} className="pe-7s-trash text-danger btn-icon-wrapper"> </i>
                </Tooltip>
              )}
            </button>
          </div>
        );
      }
    }
  ]

  const handleCheckboxChange = (e, row) => {
    if (e.target.checked) {
      setSelectedRows([...selectedRows, row._id]);
    } else {
      setSelectedRows(selectedRows.filter(id => id !== row._id));
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchCustomers(page, pageLimit, filterText, filterStatus);
  };

  const handlePerRowsChange = async (newLimit, page) => {
    setPageLimit(newLimit);
  };

  const handleSearch = (searchText, status) => {
    setFilterText(searchText);
    setFilterStatus(status);
    fetchCustomers(0, pageLimit, searchText, status);
  };

  const handleClear = () => {
    setFilterText('');
    setFilterStatus('');
    fetchCustomers(0, pageLimit, '', '');
  };



  const toggleModal = () => {
    setModal(!modal);
  };

  const subHeaderComponentMemo = React.useMemo(() => (
    <StocksFilter
      onSearch={handleSearch}
      onClear={handleClear}
      filterText={filterText}
      filterStatus={filterStatus}
    />
  ), [filterText, filterStatus]);

  const handleDownload = (filename) => {

    var columnslist = [];
    var valuelist = [];
    columnslist.push('item_code');
    columnslist.push('batch_number');
    columnslist.push('quantity');
    columnslist.push('expiry_date');
    // valuelist.push("PAN00234");
    // valuelist.push("BT-004");
    // valuelist.push("30");
    // valuelist.push("2024-10-12T00:00:00");

    var csvData = columnslist.join(',') + "\r\n" + valuelist.join(',');
    fileDownload(csvData, filename)

  }

  const handleFileChange = (e) => {
    setCsvFile(e.target.files[0]);
  };

  const uploadFileButton = () => {
    if (!selectedWarehouse) {
      Alerts.swalErrorAlert(t("Please select a warehouse first."));
      return;
    }

    if (!csvFile) {
      Alerts.swalErrorAlert(t("Please select a CSV file."));
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append('file', csvFile);
    formData.append('warehouse', selectedWarehouse);
    formData.append('business_id', Fetched_Businesses?._id);

    Services.ImportStocks(authData.sessionID, formData)
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          Alerts.swalSuccessAlert(response.data.message);
          fetchCustomers(currentPage, pageLimit, filterText, filterStatus);
        } else {
          Alerts.swalErrorAlert(response.data.message || t("Error importing stocks."));
        }
      })
      .catch((error) => {
        setLoading(false);
        Alerts.swalErrorAlert(t("Import operation failed"));
      });
  };




  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className="bg-light-gray bg-animation">
            <div className="d-flex justify-content-center align-items-center">
              <Col md="12" className="mx-auto app-login-box">
                <Modal isOpen={modal} toggle={toggleModal} backdrop={true}>
                  <ModalHeader>{t('Import Records')}</ModalHeader>
                  <ModalBody>
                    <Card className="main-card mb-3">
                      <CardBody>
                        <Form>
                          <FormGroup>
                            <Label for="warehouseSelect">{t('Select Warehouse')}</Label>
                            <Input
                              type="select"
                              name="warehouse"
                              id="warehouseSelect"
                              value={selectedWarehouse}
                              onChange={handleWarehouseChange}
                            >
                              <option value="">{t('Select a warehouse')}</option>
                              {warehouses.map(warehouse => (
                                <option key={warehouse._id} value={warehouse.name}>
                                  {warehouse.name}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                          <FormGroup>
                            <Label for="fileUpload">{t('Upload CSV')}</Label>
                            <Input
                              type='file'
                              name="importfile"
                              id="fileUpload"
                              accept='.csv'
                              onChange={handleFileChange}
                            />
                          </FormGroup>
                        </Form>
                        <p style={{ border: "3px solid red", padding: "5px", marginTop: "10px" }}>
                          {t("* Please do not remove or change the first row in the sample file.")}
                        </p>
                      </CardBody>
                    </Card>
                  </ModalBody>
                  <ModalFooter>
                    <Button className="btn btn-success btn-hover-shine me-3 btn" onClick={() => {
                      handleDownload('sample.csv')
                    }}>{t('Download Sample')}</Button>
                    <Button color="primary" onClick={uploadFileButton} className="me-3">
                      {t('Import')}
                    </Button>
                    <Button color="secondary" onClick={toggleModal}>
                      {t('Cancel')}
                    </Button>
                  </ModalFooter>
                </Modal>


                <div className="app-page-title mb-3">
                  <div className="page-title-wrapper">
                    <div className="page-title-heading fw-bold">
                      <div className="page-title-icon">
                        <i className="pe-7s-note2 icon-gradient bg-tempting-azure" />
                      </div>
                      <div>
                        {t("Stocks")}
                        <div className="page-title-subheading">
                          {t("View Basic and detailed information of ")} {t("Stocks")}
                        </div>
                      </div>
                    </div>
                    <div className="page-title-actions">
                      <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-success btn-hover-shine me-3">
                        {t("Back")}
                      </button>
                    </div>
                  </div>
                </div>

                <Card className="main-card mb-3">
                  <CardBody>
                    <LoadingOverlay active={loading} spinner={<DNALoader />}>
                      <Row className="mb-3">
                        <Col md="6">
                          {subHeaderComponentMemo}
                        </Col>
                        <Col md="6" className="text-end">
                          {(super_admin || current_module?.BulkDeleteData === 1) && (
                            <Button color="danger" onClick={handleDeleteSelected} className="me-2">
                              {t('Delete Selected')}
                            </Button>
                          )}
                          {(super_admin || current_module?.AddData === 1) && (
                            <AddStocks onSuccess={handleSuccess} warehouses={warehouses} />
                          )}
                          {(super_admin || current_module?.ImportCSV === 1) && (
                            <button type="button" className="btn btn-custom-color btn-hover-shine me-3 btn ImportData" onClick={toggleModal}>{t('Bulk Stock In')}</button>
                          )}
                        </Col>
                      </Row>
                      <DataTable
                        columns={columns}
                        data={Customers}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handlePerRowsChange}
                        selectableRows
                        onSelectedRowsChange={({ selectedRows }) => setSelectedRows(selectedRows.map(row => row._id))}
                      />
                    </LoadingOverlay>
                  </CardBody>
                </Card>

              </Col>
            </div>
          </div>
        </div>

      </div>
      {StockToEdit && (
        <UpdateStock
          stock={StockToEdit}
          warehouses={warehouses}
          onSuccess={() => {
            setStockToEdit(null);
            handleSuccess();
          }}
          onCancel={() => setStockToEdit(null)}
        />

      )}
    </Fragment>
  );
};

export default StocksListing;