import React from "react";
import { Translation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { Row, Col, FormGroup, Input, Button, Label, Modal, ModalHeader, ModalBody, Card, CardBody } from "reactstrap";
import CustomerService from "./Warehouse.service";
import AuthService from "../Authentication/auth.service";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import Alerts from "../../components/Alerts/alerts";

class DocsAddCustomer extends React.Component {
  constructor(props) {
    console.log('PROPS', props);
    super(props);
    this.state = {
      modal: false,
      variation_types: [{ variation_id: `${this?.props?.state?._id}`, variation_type_name: '', price: '', status: '' }],
      backdrop: true,
      loading: false,
      loginNavigate: false,
    };
  }
  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
      variation_types: [{ variation_id: `${this?.props?.state?._id}`, variation_type_name: '', price: '', status: '' }],
    }));

  }

  addVariationRow = () => {
    this.setState(prevState => ({
      variation_types: [...prevState.variation_types, { variation_id: `${this?.props?.state?._id}`, variation_type_name: '', price: '', status: '' }]
    }));
  }

  removeVariationRow = (index) => {
    this.setState(prevState => ({
      variation_types: prevState.variation_types.filter((_, i) => i !== index)
    }));
  }

  handleVariationChange = (index, field, value) => {
    this.setState(prevState => ({
      variation_types: prevState.variation_types.map((variation, i) =>
        i === index ? { ...variation, [field]: value } : variation
      )
    }));
  }

  validateForm = () => {
    const { variation_types } = this.state;
    return variation_types.every(variation => variation.variation_id && variation.variation_type_name && variation.price && variation.status);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (!this.validateForm()) {
      Alerts.swalErrorAlert("Please fill all fields for all variation types.");
      return;
    }

    this.setState({ loading: true });

    const payloadData = {
      functionName: "AddBulkVariationTypes",
      postData: {
        VariationTypes: this.state.variation_types
      }
    };

    CustomerService.AddBulkVariationTypes(payloadData).then(
      (response) => {
        this.setState({ loading: false });
        if (response.data.status) {
          this.props.onSuccess();
          Alerts.swalSuccessAlert(response.data.message || "Data Added Successfully.").then(() => {
            this.toggle();
          });
        } else {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            this.setState({ loginNavigate: true });
          } else {
            Alerts.swalErrorAlert(response.data.message || "Error: Can't Add Variations at the moment. Please try again later.");
          }
        }
      },
      (error) => {
        this.setState({ loading: false });
        Alerts.swalErrorAlert("Network Operation failed.");
      }
    );
  }

  render() {
    if (this.state.loginNavigate) {
      return <Navigate to={'/login'} />;
    }

    return (
      <Translation>
        {(t) => (
          <span className="d-inline-block float-end mb-2 me-2">
            <Button color="success" onClick={this.toggle} className="btn-hover-shine ms-1">
              {t("Add New")}
            </Button>
            <Modal size='lg' isOpen={this.state.modal} toggle={this.toggle} backdrop={this.state.backdrop}>
              <LoadingOverlay active={this.state.loading} spinner={<DNALoader />}>
                <ModalHeader>{t("Add Variation Option")}</ModalHeader>
                <ModalBody>
                  <Card className="main-card mb-3">
                    <CardBody>
                      <form onSubmit={this.handleSubmit}>
                        {this.state.variation_types.map((variation, index) => (
                          <Row key={index} className="mb-3">
                            {/* <Col md="3">
                              <FormGroup>
                                <Label>{t("Variation")}</Label>
                                <Input
                                  type="text"
                                  value={this.props.data.name}
                                  // onChange={(e) => this.handleVariationChange(index, 'variation_id', e.target.value)}
                                  placeholder="Selected Variation"
                                />
                              </FormGroup>
                            </Col> */}
                            <Col md="4">
                              <FormGroup>
                                <Label>{t("Variation Option")}</Label>
                                <Input
                                  type="text"
                                  value={variation.variation_type_name}
                                  onChange={(e) => this.handleVariationChange(index, 'variation_type_name', e.target.value)}
                                  placeholder="Enter Variation Option"
                                />
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              <FormGroup>
                                <Label>{t("Price")}</Label>
                                <Input
                                  type="number"
                                  value={parseFloat(variation.price).toFixed(2)}
                                  onChange={(e) => this.handleVariationChange(index, 'price', e.target.value)}
                                  placeholder="Enter price"
                                />
                              </FormGroup>
                            </Col>
                            <Col md="3">
                              <FormGroup>
                                <Label>{t("Status")}</Label>
                                <Input
                                  type="select"
                                  value={variation.status}
                                  onChange={(e) => this.handleVariationChange(index, 'status', e.target.value)}
                                >
                                  <option value="">{t("Select status")}</option>
                                  <option value="Active">{t("Active")}</option>
                                  <option value="InActive">{t("InActive")}</option>
                                </Input>
                              </FormGroup>
                            </Col>
                            <Col md="1" >
                              <Row >
                                <div>
                                  {this.state.variation_types.length > 1 && (
                                    <i style={{cursor:'pointer', marginTop: '38px' }} onClick={() => this.removeVariationRow(index)} className="pe-7s-trash text-danger btn-icon-wrapper fs-5"> </i>
                                  )}
                                  <i style={{ cursor:'pointer', marginLeft: '3px', marginTop: '38px' }} onClick={this.addVariationRow} className="pe-7s-plus text-danger btn-icon-wrapper fs-5"> </i>
                                </div>

                              </Row>
                            </Col>
                          </Row>
                        ))}

                        <div className="text-center">
                          <Button type="submit" color="success" size="lg" className="me-3">
                            {t("Add")}
                          </Button>
                          <Button type="button" color="success" outline size="lg" onClick={this.toggle}>
                            {t("Cancel")}
                          </Button>
                        </div>
                      </form>
                    </CardBody>
                  </Card>
                </ModalBody>
              </LoadingOverlay>
            </Modal>
          </span >
        )
        }
      </Translation>
    );
  }
}

export default DocsAddCustomer;
