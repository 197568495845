import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from "react-router-dom";
import { Col, Row, Label, FormGroup, Input, Card, CardBody } from "reactstrap";
import { Form, Field } from "react-final-form";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import "../../../assets/custom css/loadoverlay.scss";
import Alerts from "../../../components/Alerts/alerts";


import ConfigurationService from "./configuration.service";
import AuthService from "../../Authentication/auth.service";
import PermissionAccess from '../../../PermissionAccess/Access.js';


const TabGenralConfig = () => {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [collection, setCollection] = useState([]);
  const [selectedCollections, setSelectedCollections] = useState([]);

  const [invoice_to_Data, setInvoice_to_Data] = useState([]);
  const { authData, accessPages, super_admin, current_module } = PermissionAccess();

  const [configs, setConfig] = useState([]);
  const { t, i18n } = useTranslation();
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    // Retrieve data from localStorage
    const dataFromLocalStorage = localStorage.getItem('invoice_to');
    if (dataFromLocalStorage) {
      const initialSelectedOptions = dataFromLocalStorage.split(',');
      setSelectedOptions(initialSelectedOptions);
    }
  }, []);
  const handleChipClick = (option) => {
    const isSelected = selectedOptions.includes(option);
    let updatedOptions;

    if (isSelected) {
      updatedOptions = selectedOptions.filter((selected) => selected !== option);
    } else {
      updatedOptions = [...selectedOptions, option];
    }
    setSelectedOptions(updatedOptions);
    // Update localStorage with the updated options
    localStorage.setItem('invoice_to', updatedOptions.join(','));
  };
  useEffect(() => {
    getLocalStorageValues();
    i18n.changeLanguage(localStorage.getItem('language'));
    getSystemConfig();
    fetchUsers_type();
  }, []);

  const getLocalStorageValues = () => {
    const storedValues = localStorage.getItem('invoice_to');
    if (storedValues) {
      const valuesArray = storedValues.split(',');
      setSelectedCollections(valuesArray);
    }
  };



  console.log("selectedCollections", selectedCollections);
  function fetchUsers_type() {
    ConfigurationService.fetchUsers_type(authData.sessionID).then((response) => {
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        const mergedCollection = response.data.data.flatMap(item => item.user_type);
        console.log(mergedCollection)
        setCollection(mergedCollection);
      }
    },
      (error) => {
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };


  function getSystemConfig() {
    ConfigurationService.getConfiguration(authData.sessionID).then((response) => {
      console.log("API Response:-", response);
      if (response.data.status) {
        const [rec_data] = response.data.data;
        console.log("setConfig", rec_data);
        if (rec_data?.invoice_to) {
          fetchDataForField('users');
        }
        setConfig(rec_data);

      }
      else {
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        }
        else {
          alert(t("Unable to retrieve configurations at the moment."))
        }
      }
    },
      (error) => {
        alert(t("Unable to retrieve configurations at the moment."))
      });
  }



  function fetchDataForField(itemrec) {
    console.log(itemrec);
    ConfigurationService.fetchDataForField('users', authData.sessionID).then((response) => {
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        console.log('Fields', response)
        setInvoice_to_Data(response.data.fields);
      }
    },
      (error) => {
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  function handleSave(values) {
    setLoading(true);
    console.log("Form Values:-", values);
    localStorage.setItem("payment_method", values.payment_method);
    localStorage.setItem("default_notes", values.default_notes);
    localStorage.setItem("due_date", values.due_date);
    localStorage.setItem("tax_percentage", values.tax_percentage);
    localStorage.setItem("currency", values.currency);
    localStorage.setItem("invoice_to", localStorage.getItem('invoice_to'));
    localStorage.setItem("invoice_to_field", values.invoice_to_field);

    var payloadData = {
      functionName: "Set_Finance_Config",
      postData: {
        payment_method: values.payment_method,
        default_notes: values.default_notes,
        due_date: values.due_date,
        tax_percentage: values.tax_percentage,
        currency: values.currency,
        invoice_to: localStorage.getItem('invoice_to'),
        invoice_to_field: values.invoice_to_field,
      },
    }
    ConfigurationService.setConfiguration(payloadData, authData.sessionID).then((response) => {
      console.log("API Response:-", response);
      setLoading(false);
      if (response.data.status) {
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Configuration updated successfully."));
        }
        getSystemConfig();
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
      else {
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't update configuration at the moment. Please try again later."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        console.log("Error:-", error)
        Alerts.swalErrorAlert(t("Network Operation failed."))
      });
  }

  const validateFtn = (values) => {
    const errors = {};
    if (!values.payment_method) {
      errors.payment_method = t("Payment method Required.");
    }
    if (!values.default_notes) {
      errors.default_notes = t("Default Notes Required.");
    }
    if (!values.due_date) {
      errors.due_date = t("Due Date Required.");
    }
    if (!values.tax_percentage) {
      errors.tax_percentage = t("Tax Percentage Required.");
    }
    if (!values.currency) {
      errors.currency = t("Currency Required.");
    }
    return errors;
  };
  const handleChangeColor = (userType) => {
    console.log("userType",)
    return selectedCollections.includes(userType) ? 'primary' : 'default';
  }
  console.log("collection", collection)
  return (
    <Fragment>
      <Card className="main-card mb-3">
        <CardBody className="col-md-8">
          <Form
            initialValues={configs}
            validate={validateFtn}
            onSubmit={handleSave}
            FormState
            render={({ handleSubmit, values, submitting, validating, valid, form: { getState } }) => (
              <form onSubmit={handleSubmit}>
                <LoadingOverlay tag="div" active={loading}
                  styles={{ overlay: (base) => ({ ...base }) }}
                  spinner={<DNALoader />}>
                  <Row>
                    <Col md={12}>
                      <FormGroup>
                        <Field name="payment_method">
                          {({ input, meta }) => (
                            <div>
                              <div className="row mb-3">
                                <Label for="payment_method" className="col-sm-2 col-form-label">{t('Payment Method')}</Label>
                                <div className="col-sm-10">
                                  <Input {...input} type="text"
                                    invalid={meta.error && meta.touched}
                                    placeholder={t('Method use for Payments')}
                                  />
                                  {meta.error && meta.touched &&
                                    <span className="text-danger">{meta.error}</span>}
                                </div>
                              </div>
                            </div>
                          )}
                        </Field>
                      </FormGroup>
                      <FormGroup>
                        <Field name="default_notes">
                          {({ input, meta }) => (
                            <div>
                              <div className="row mb-3">
                                <Label for="default_notes" className="col-sm-2 col-form-label">{t('Default Notes')}</Label>
                                <div className="col-sm-10">
                                  <Input {...input} type="text"
                                    invalid={meta.error && meta.touched}
                                    placeholder={t('Default Notes for Invoice')}
                                  />
                                  {meta.error && meta.touched &&
                                    <span className="text-danger">{meta.error}</span>}
                                </div>
                              </div>
                            </div>
                          )}
                        </Field>
                      </FormGroup>
                      <FormGroup>
                        <Field name="due_date">
                          {({ input, meta }) => (
                            <div>
                              <div className="row mb-3">
                                <Label for="due_date" className="col-sm-2 col-form-label">{t('Due Date')}</Label>
                                <div className="col-sm-10">
                                  <Input {...input} type="number"
                                    invalid={meta.error && meta.touched}
                                    placeholder={t('Due Date range for Invoice Dues')}
                                  />
                                  {meta.error && meta.touched &&
                                    <span className="text-danger">{meta.error}</span>}
                                </div>
                              </div>
                            </div>
                          )}
                        </Field>
                      </FormGroup>
                      <FormGroup>
                        <Field name="tax_percentage">
                          {({ input, meta }) => (
                            <div>
                              <div className="row mb-3">
                                <Label for="tax_percentage" className="col-sm-2 col-form-label">{t('Tax %')}</Label>
                                <div className="col-sm-10">
                                  <Input {...input} type="text"
                                    invalid={meta.error && meta.touched}
                                    placeholder={t('Default % for tax')}
                                  />
                                  {meta.error && meta.touched &&
                                    <span className="text-danger">{meta.error}</span>}
                                </div>
                              </div>
                            </div>
                          )}
                        </Field>
                      </FormGroup>
                      <FormGroup>
                        <Field name="currency">
                          {({ input, meta }) => (
                            <div>
                              <div className="row mb-3">
                                <Label for="currency" className="col-sm-2 col-form-label">{t('Currency')}</Label>
                                <div className="col-sm-10">
                                  <Input {...input} type="text"
                                    invalid={meta.error && meta.touched}
                                    placeholder={t('Currency')}
                                  />
                                  {meta.error && meta.touched &&
                                    <span className="text-danger">{meta.error}</span>}
                                </div>
                              </div>
                            </div>
                          )}
                        </Field>
                      </FormGroup>
                      <Row >
                        <Col md={2}>
                          <div >
                            <Label for="invoice_to" className=" col-form-label">{t('Invoice To')}</Label>
                          </div>
                        </Col>
                        <Col md={10}>
                          <Stack direction="row" spacing={2} sx={{ flexWrap: 'wrap', width: '100%' }}>
                            {collection.map((opt_collection, index) => (
                              <Chip
                                key={index}
                                label={opt_collection}
                                clickable
                                color={selectedOptions.includes(opt_collection) ? 'primary' : 'default'}
                                onClick={() => handleChipClick(opt_collection)}
                              />
                            ))}
                          </Stack>
                        </Col>

                      </Row>
                      <Row >
                        <Col style={{ marginTop: '10px' }} md={6}>
                          <Row >
                            <Col md={4}>
                              <div >
                                <Label for="invoice_to_field" className=" col-form-label">{t('Select Field')}</Label>
                              </div>
                            </Col>
                            <Col md={8}>
                              <FormGroup>
                                <Field name="invoice_to_field" initialValue={configs?.invoice_to_field}>
                                  {({ input, meta }) => (
                                    <div className="">
                                      <Input
                                        {...input}
                                        type="select"
                                        invalid={meta.error && meta.touched}
                                        placeholder={t("Filter by collection")}
                                      >
                                        <option disabled={false} value="">{`--${t("Select Field")}--`}</option>
                                        {invoice_to_Data &&
                                          invoice_to_Data.map((opt_collection, index) => (
                                            <option key={index} value={opt_collection}>{opt_collection}</option>
                                          ))
                                        }
                                      </Input>
                                      {meta.error && meta.touched &&
                                        <span className="text-danger">{meta.error}</span>}
                                    </div>
                                  )}
                                </Field>

                              </FormGroup>
                            </Col>
                          </Row>

                        </Col>
                      </Row>
                      <FormGroup>
                        <div className="d-flex justify-content-center EditData">
                          <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-light btn-hover-shine me-3 mt-3">{t('Cancel')}</button>
                          <button className="btn btn-custom-color btn-hover-shine me-3 mt-3" type="submit" disabled={!valid}>{t('Save')}</button>
                        </div>
                      </FormGroup>

                    </Col>
                  </Row>
                </LoadingOverlay>
              </form>

            )}
          />
        </CardBody>
      </Card>
    </Fragment>
  );

};
export default TabGenralConfig; 