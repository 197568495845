import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Table, Input, Alert } from 'reactstrap';

const QuotationModal = ({
  isOpen, toggle, quotationDetails, outOfStockItems, discount,
  paymentTerms, onPriceChange, onDiscountChange, onPaymentTermsChange,
  onSave, onIssue, onBatchChange, onQuantityChange
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="xl">
      <ModalHeader toggle={toggle}>Issue Quotation</ModalHeader>
      <ModalBody>
        <Table responsive>
          <thead>
            <tr>
              <th style={{ width: '20%' }}>Item</th>
              <th style={{ width: '10%' }}>Quantity</th>
              <th style={{ width: '25%' }}>Batch</th>
              <th style={{ width: '15%' }}>Quantity</th>
              <th style={{ width: '15%' }}>Expiry</th>
              <th style={{ width: '25%' }}>Price</th>
            </tr>
          </thead>
          <tbody>
            {quotationDetails?.items?.flatMap((item, itemIndex) =>
              item.selectedBatches.map((batch, batchIndex) => (
                <tr key={`${item._id}-${batchIndex}`}>
                  <td>{batchIndex === 0 ? item.item_name : ''}</td>
                  <td>{batchIndex === 0 ? item.quantity : ''}</td>
                  <td>
                    <Input
                      type="select"
                      value={batch.batchId}
                      onChange={(e) => onBatchChange(itemIndex, batchIndex, e.target.value)}
                      style={{ width: '100%' }}
                    >
                      {item.availableBatches.map(availableBatch => (
                        <option key={availableBatch.batchId} value={availableBatch.batchId}>
                          {availableBatch.batch_number} (Available: {availableBatch.quantity})
                        </option>
                      ))}
                    </Input>
                  </td>
                  <td>
                    <Input
                      type="number"
                      value={batch.quantity}
                      onChange={(e) => onQuantityChange(itemIndex, batchIndex, e.target.value)}
                      min="1"
                      max={item.availableBatches.find(ab => ab.batchId === batch.batchId)?.quantity || 0}
                    />
                  </td>
                  <td>{new Date(batch.expiry).toLocaleDateString()}</td>
                  <td>
                    <Input
                      type="number"
                      value={item.price}
                      onChange={(e) => onPriceChange(itemIndex, e.target.value)}
                    />
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
        {outOfStockItems.length > 0 && (
          <Alert color="warning">
            The following items are out of stock or have insufficient quantity:
            <ul>
              {outOfStockItems.map((item, index) => (
                <li key={index}>
                  {item.item_name}: {item.extra_quantity} units out of stock
                </li>
              ))}
            </ul>
          </Alert>
        )}
        <div className="mt-3">
          <label>Discount (%):
            <Input
              type="number"
              value={discount}
              onChange={(e) => onDiscountChange(e.target.value)}
            />
          </label>
        </div>
        <div className="mt-3">
          <label>Payment Terms:
            <Input
              type="select"
              value={paymentTerms}
              onChange={(e) => onPaymentTermsChange(e.target.value)}
            >
              <option value="net-30">Net 30</option>
              <option value="net-60">Net 60</option>
              <option value="advance">Advance</option>
            </Input>
          </label>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onSave}>Save as Draft</Button>
        <Button color="success" onClick={onIssue}>Issue Quotation</Button>
        <Button color="secondary" onClick={toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
};

export default QuotationModal;