import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { Col, Row, Button, Label, FormGroup, Input } from "reactstrap";
import { Form, Field } from "react-final-form";
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom';
import AuthService from "./auth.service";
import AlertService from "../../components/Alerts/alerts";
import { ToastContainer } from 'react-toastify';
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faTimes, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import bg from './bg.png';
import Swal from 'sweetalert2';

const Register = () => {
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState(localStorage.getItem("system_logo"));
  const [documents, setDocuments] = useState([]);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const params = useParams();
  const location = useLocation();
  var pathname = location.pathname;
  pathname = pathname.split('/')[2];
  const usertype = pathname;

  const [address, setAddress] = useState('');
  const [isAutocompletSelected, setIsAutocompletSelected] = useState(false);

  const autocompleteRef = useRef(null);
  const inputRef = useRef(null);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  useEffect(() => {
    getSetLanguage();
    loadGoogleMapsScript();
  }, []);

  const loadGoogleMapsScript = () => {
    if (!window.google || !window.google.maps) {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDlKtLygdyr4XpjVE1WcyR7yHuigyq_agw&libraries=places`;
      script.async = true;
      script.onload = initAutocomplete;
      document.head.appendChild(script);
    } else {
      initAutocomplete();
    }
  };

  const initAutocomplete = () => {
    if (!inputRef.current) {
      setTimeout(initAutocomplete, 100);
      return;
    }

    autocompleteRef.current = new window.google.maps.places.Autocomplete(inputRef.current, {
      types: ['address'],
      componentRestrictions: { country: 'MY' },
    });

    autocompleteRef.current.addListener('place_changed', handlePlaceSelect);

    // Adjust the position of the autocomplete dropdown
    const pacContainer = document.querySelector('.pac-container');
    if (pacContainer) {
      pacContainer.style.zIndex = "1500"; // Ensure it's above other elements
    }
  };

  const handlePlaceSelect = () => {
    const place = autocompleteRef.current.getPlace();
    if (!place.geometry) {
      console.error('No details available for input:', place.name);
      return;
    }
    setLatitude(place.geometry.location.lat());
    setLongitude(place.geometry.location.lng());
    const selectedAddress = place.formatted_address;
    setAddress(selectedAddress);
    setIsAutocompletSelected(true);
    if (inputRef.current) {
      inputRef.current.value = selectedAddress;
    }
  };

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
    setIsAutocompletSelected(false);
  };

  function getSetLanguage() {
    const language = localStorage.getItem("language") || 'en';
    i18n.changeLanguage(language);
  }

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setDocuments(prev => [...prev, ...files]);
  };

  const removeDocument = (docIndex) => {
    setDocuments(prev => prev.filter((_, index) => index !== docIndex));
  };

  const onSubmit = async (values) => {
    setLoading(true);
    const formData = new FormData();

    Object.keys(values).forEach(key => {
      if (key !== 'Address') {
        formData.append(key, values[key]);
      }
    });

    // Append documents
    documents.forEach((doc, index) => {
      formData.append(`Documents`, doc);
    });

    formData.append('Address', address);
    formData.append('user_type_id', '6576b4b4e66749ea4ce13108');
    formData.append('user_type', usertype);
    formData.append('latitude', latitude);
    formData.append('longitude', longitude);
    formData.append('all_required_have', 'false');
    formData.append('status', 'Inactive');

    try {
      const response = await AuthService.Register_Member(formData);
      setLoading(false);
      console.log("response", response);
      if (response.data.status) {
        AlertService.successToast("SignUp Successful. Login Now");
        Swal.fire({
          title: 'Registration Successful!',
          text: 'Your account has been created successfully. Please wait for admin approval.',
          icon: 'success',
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result.isConfirmed) {
            navigate(`/customer_login`);
          }
        });
      } else if (response.data.message === 'Email Already Registered') {
        AlertService.swalErrorAlert("Email Already Registered");
      }
    } catch (error) {
      setLoading(false);
      const resMessage = error.response?.data?.message || error.message || "An error occurred";
      AlertService.swalErrorAlert(t(resMessage));
    }
  };

  const validateForm = (values) => {
    const errors = {};
    if (!values.name) errors.name = t('Name is required');
    if (!values.email) errors.email = t('Email is required');
    // if (!values.password) errors.password = t('Password is required');
    // if (values.password !== values.confirmPassword) errors.confirmPassword = t('Passwords do not match');
    if (!values.RegistrationNumber) errors.RegistrationNumber = t('Registration Number is required');
    if (!values.Phone) errors.Phone = t('Phone is required');
    if (!values.Address) errors.Address = t('Address is required');
    if (documents.length === 0) errors.documents = t('At least one document is required');
    return errors;
  };

  return (
    <div style={styles.container}>
      <div style={styles.leftContainer}>
        <div style={styles.logoContainer}>
          <img src={logo} alt="Logo" style={styles.logo} />
        </div>
        <Form
          onSubmit={onSubmit}
          validate={validateForm}
          render={({ handleSubmit, valid }) => (
            <form onSubmit={handleSubmit}>
              <LoadingOverlay active={loading} spinner={<DNALoader />}>
                <div className="modal-body">
                  <h2 style={styles.heading}>{t('Sign Up')}</h2>
                  <p style={styles.logoText}>{t('Create Account to Request For Quotation.')}</p>
                  <Row form>
                    <Col md={6}>
                      <Field name="name">
                        {({ input, meta }) => (
                          <FormGroup>
                            <Label>{t('Member Name')}</Label>
                            <Input {...input} type="text" placeholder={t('Enter Member Name')} invalid={meta.error && meta.touched} />
                            {meta.error && meta.touched && <span className="text-danger">{meta.error}</span>}
                          </FormGroup>
                        )}
                      </Field>
                    </Col>
                    <Col md={6}>
                      <Field name="RegistrationNumber">
                        {({ input, meta }) => (
                          <FormGroup>
                            <Label>{t('Registration Number')}</Label>
                            <Input {...input} type="text" placeholder={t('Enter Registration Number')} invalid={meta.error && meta.touched} />
                            {meta.error && meta.touched && <span className="text-danger">{meta.error}</span>}
                          </FormGroup>
                        )}
                      </Field>
                    </Col>
                    <Col md={6}>
                      <Field name="email">
                        {({ input, meta }) => (
                          <FormGroup>
                            <Label>{t('Email')}</Label>
                            <Input {...input} type="email" placeholder={t('Enter Email')} invalid={meta.error && meta.touched} />
                            {meta.error && meta.touched && <span className="text-danger">{meta.error}</span>}
                          </FormGroup>
                        )}
                      </Field>
                    </Col>
                    <Col md={6}>
                      <Field name="Phone">
                        {({ input, meta }) => (
                          <FormGroup>
                            <Label>{t('Phone')}</Label>
                            <Input {...input} type="tel" placeholder={t('Enter Phone Number')} invalid={meta.error && meta.touched} />
                            {meta.error && meta.touched && <span className="text-danger">{meta.error}</span>}
                          </FormGroup>
                        )}
                      </Field>
                    </Col>
                    <Col md={12}>
                      <Field name="Address">
                        {({ input, meta }) => (
                          <FormGroup>
                            <Label>{t('Address (Google Location)')}</Label>
                            <Input
                              {...input}
                              type="text"
                              placeholder={t('Enter Address')}
                              invalid={meta.error && meta.touched}
                              innerRef={inputRef}
                              value={address}
                              onChange={(e) => {
                                handleAddressChange(e);
                                input.onChange(e);
                              }}
                            />
                            {meta.error && meta.touched && <span className="text-danger">{meta.error}</span>}
                          </FormGroup>
                        )}
                      </Field>
                    </Col>
                    <Col md={6}>
                      <Field name="block">
                        {({ input, meta }) => (
                          <FormGroup>
                            <Label>{t('Street/Block')}</Label>
                            <Input {...input} type="text" placeholder="street / block" invalid={meta.error && meta.touched} />
                            {meta.error && meta.touched && <span className="text-danger">{meta.error}</span>}
                          </FormGroup>
                        )}
                      </Field>
                    </Col>
                    <Col md={6}>
                      <Field name="unit">
                        {({ input, meta }) => (
                          <FormGroup>
                            <Label>{t('Unit')}</Label>
                            <Input {...input} type="text" placeholder="Unit" invalid={meta.error && meta.touched} />
                            {meta.error && meta.touched && <span className="text-danger">{meta.error}</span>}
                          </FormGroup>
                        )}
                      </Field>
                    </Col>
                    <Col md={12}>
                      <FormGroup>
                        <Label>{t('Documents')}</Label>
                        <div className="document-upload-container" style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                          {documents.map((doc, docIndex) => (
                            <div key={docIndex} style={{ position: 'relative', width: '100px', height: '100px' }}>
                              {doc.type.startsWith('image/') ? (
                                <img src={URL.createObjectURL(doc)} alt={`Document ${docIndex + 1}`} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                              ) : (
                                <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#f0f0f0' }}>
                                  <FontAwesomeIcon icon={faFilePdf} size="2x" />
                                </div>
                              )}
                              <Button
                                color="danger"
                                size="sm"
                                style={{ position: 'absolute', top: '-10px', right: '-10px', borderRadius: '50%', padding: '0.2rem 0.5rem' }}
                                onClick={() => removeDocument(docIndex)}
                              >
                                <FontAwesomeIcon icon={faTimes} />
                              </Button>
                            </div>
                          ))}
                          <div style={{ width: '100px', height: '100px', border: '2px dashed #ccc', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
                            <label htmlFor="file-upload" style={{ cursor: 'pointer', margin: 0 }}>
                              <FontAwesomeIcon icon={faUpload} size="2x" />
                              <Input
                                type="file"
                                id="file-upload"
                                onChange={handleFileChange}
                                accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                                multiple
                                style={{ display: 'none' }}
                              />
                            </label>
                          </div>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="d-grid mb-3">
                    <Button size="lg" className="btn-custom-color" type="submit" disabled={!valid}>
                      {t('Sign Up')}
                    </Button>
                  </div>
                  <div className="text-center">
                    {t('Already Have an Account?')}
                    <Link to={`/customer_login`} style={{ color: '#007bff', textDecoration: 'none', marginLeft: '5px' }}>
                      {t('Login')}
                    </Link>
                  </div>
                </div>
              </LoadingOverlay>
            </form>
          )}
        />
      </div>
      <div style={styles.rightContainer}>
        {/* <SliderComponent /> */}
      </div>
      <style jsx>{`
        .pac-container {
          z-index: 1500 !important;
        }
      `}</style>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    height: '100vh',
  },
  leftContainer: {
    flex: 1,
    display: 'flex',
    marginTop: '1%',
    flexDirection: 'column',
    background: 'white',
    padding: '0 50px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  rightContainer: {
    flex: 1,
    margin: '1%',
    borderRadius: '1%',
    backgroundImage: `url(${bg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
  },
  overlayText: {
    position: 'absolute',
    top: '1%',
    left: '10%',
    color: 'white',
    textAlign: 'left',
    padding: '20px',
    backgroundColor: 'rgba(72, 132, 194, 0.5)',
  },
  logoContainer: {
    marginBottom: '5px',
  },
  logo: {
    width: '200px',
    height: 'auto',
  },
  heading: {
    fontSize: '28px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  logoText: {
    fontSize: '16px',
    color: '#666',
    marginBottom: '20px',
  },
  formFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rememberMe: {
    display: 'flex',
    alignItems: 'center',
  },
  checkboxLabel: {
    marginLeft: '5px',
  },
  forgotPassword: {
    color: '#007bff',
    textDecoration: 'none',
  },
};

export default Register;
