import axios from "axios";

const API_URL = process.env.REACT_APP_BASEURL;
const B_URL = process.env.REACT_APP_LOGINURL
const storeduser = JSON.parse(localStorage.getItem("user"));


const headers = {
  'Content-Type': 'application/json',
  'token': localStorage.getItem("session-id")
}

const GetDashboardSummary = (sessionID, payload) => {
  console.log("GetDashboardSummary:-", payload);
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "ReportsModule/DashboardSummary", payload, { headers });
};

const GetCategories = (payload, sessionID) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + 'CategoryModule/' + payload.functionName, payload, { headers });
};


const getuserform = (name) => {
  var payload = {
    functionName: "GetUserForm",
    "postData": {
      name: name
    }
  }
  return axios.post(API_URL, payload, { headers });
};


const deleteCollection = (id, referal_name) => {
  var payload = {
    functionName: "DeleteCollectionData",
    postData: {
      "_id": id,
      "collection_name": referal_name,
      "login_user_id": storeduser._id
    },
  }
  return axios.post(API_URL, payload, { headers });
};
const bulkdeleteCollection = (id, referal_name) => {
  var payload = {
    functionName: "BulkDeleteDynCollectionData",
    postData: {
      "_id": id,
      "collection_name": referal_name,
      "login_user_id": storeduser._id
    },
  }
  return axios.post(API_URL, payload, { headers });
};


const getcollection = (referal_name, filter, limit, offset) => {
  var payload = {
    functionName: "GetCollectionData",
    postData: {
      "collection_name": referal_name,
      "login_user_id": storeduser._id,
      "filter": filter,
      "limit": limit,
      "offset": offset
    },
  }
  return axios.post(API_URL, payload, { headers });
};

const getoptions = (referal_name, filter, fieldlist, limit, sessionID, _id) => {
  console.log("Get _id", _id)
  console.log("Get sessionID", sessionID)
  var payload = {
    functionName: "GetCollectionData",
    postData: {
      "collection_name": referal_name,
      "login_user_id": _id,
      "fieldlist": fieldlist,
      "filter": filter,
      "limit": limit,
      "offset": 0
    },
  }
  console.log("payload", payload)
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }

  return axios.post(API_URL, payload, { headers });
};


const insertCollectionData = payload => {
  console.log("Received addRole Payload:-", payload)
  return axios.post(API_URL, payload, { headers });
}

const import_dyn_records = (formData) => {
  formData.login_user_id = storeduser._id
  var payload = {
    functionName: "ImportDynCollectionData",
    postData: formData
  }
  console.log(formData);
  return axios.post(API_URL, payload, { headers });
};

const getAllCruds = (filter) => {
  var payload = {
    functionName: "GetCollectionData",
    "postData": {
      "collection_name": "dynamic_modules",
      "filter": filter
    }
  }
  return axios.post(API_URL, payload, { headers });
};

const save_user = (payload) => {
  const API_URL2 = process.env.REACT_APP_LOGINURL + 'modifyUsers';
  return axios.post(API_URL2, payload, { headers });
};
// const save_item = (payload) => {
//   console.log('Payload saved: ' + JSON.stringify(payload));
//   const API_URL2 = process.env.REACT_APP_LOGINURL + 'modifyItems';
//   return axios.post(API_URL2, payload, { headers });
// };

const save_item = (payload, sessionID) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + 'ItemsRoutes/AddItems', payload, { headers });
};


const Update_item = (payload, sessionID) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + 'ItemsRoutes/UpdateItems', payload, { headers });
};

const update_featured_image = (payload, sessionID) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + 'ItemsRoutes/' + payload.functionName, payload, { headers });
};

const getAllDrivers = (payload, sessionID) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + 'ItemsRoutes/' + payload.functionName, payload, { headers });
};

const deleteDriver = (id, sessionID) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  var payload = {
    functionName: "DeleteItems",
    postData: {
      _id: id,
    },
  }
  return axios.post(B_URL + 'ItemsRoutes/' + payload.functionName, payload, { headers });
};

const getDocTypes = () => {
  var payload = {
    functionName: "GetTypes",
    postData: {},
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'DocumentsRoutes/' + payload.functionName, payload, { headers });
};

const getDriverDocs = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'DocumentsRoutes/' + payload.functionName, payload, { headers });
};


const AddDriverDocument = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'DocumentsRoutes/' + payload.functionName, payload, { headers });
};

const UpdateDriverDocument = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'DocumentsRoutes/' + payload.functionName, payload, { headers });
};

const deleteDocument = (id) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  var payload = {
    functionName: "DeleteDocument",
    postData: {
      "_id": id,
    },
  }
  return axios.post(B_URL + 'DocumentsRoutes/' + payload.functionName, payload, { headers });
};

const get_cruds_dynamic_data = (sessionID) => {
  var payload = {
    functionName: "GetCrud_Invoices",
    "postData": {
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }

  return axios.post(API_URL, payload, { headers });
};




const GetBusinesses = (payload, sessionID) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + 'BusinessModule/' + payload.functionName, payload, { headers });
};
const getAllVariations = (sessionID) => {
  const headers = {
    'Content-Type': 'application/json',
    'token':sessionID
  }
  var payload = {
    "functionName": "GetVariations",
    "postData": {
      "offset": 0,
      "sort": {
        "_id": -1
      },
      "limit": 999999
    }
  }
  return axios.post(B_URL + 'VariationsModule/' + payload.functionName, payload, { headers });
};

const Get_Variation_sub_Types = (index, variation_id, sessionID) => {
  var payload = {
    "functionName": "Get_Variation_sub_Types",
    "postData": {
      "offset": 0,
      "sort": {
        "_id": -1
      },
      "limit": 999999,
      variation_id: variation_id
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }

  return axios.post(B_URL + 'VariationTypesModule/' + payload.functionName, payload, { headers });
};

const Get_Item_Variation = (offset, limit, item_id, sessionID) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  var payload = {
    "functionName": "Get_Item_Variation",
    "postData": {
      "offset": offset,
      "sort": {
        "_id": -1
      },
      "limit": limit,
      item_id: item_id
    }
  }
  return axios.post(B_URL + 'ItemVariationsModule/' + payload.functionName, payload, { headers });
};
const AddBulkItemVariation = (payload, sessionID) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }

  return axios.post(B_URL + 'ItemVariationsModule/' + payload.functionName, payload, { headers });
};
const DeleteItemVariation = (_id, sessionID) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  var payload = {
    "functionName": "DeleteItemVariation",
    "postData": {
      _id: _id
    }
  }
  return axios.post(B_URL + 'ItemVariationsModule/' + payload.functionName, payload, { headers });
};

const DeleteBulkItemVariation = (selectedRows, sessionID) => {
  var payload = {
    "functionName": "DeleteBulkItemVariation",
    "postData": {
      "_ids": selectedRows
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }

  return axios.post(B_URL + "ItemVariationsModule/DeleteBulkItemVariation", payload, { headers });
};
const UpdateItemVariation = (payloadData, sessionID) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "ItemVariationsModule/UpdateItemVariation", payloadData, { headers });
};


const DriverService = {
  get_cruds_dynamic_data,
  getAllDrivers,
  update_featured_image,
  Get_Item_Variation,
  AddBulkItemVariation,
  Get_Variation_sub_Types,
  UpdateItemVariation,
  DeleteItemVariation,
  GetBusinesses,
  DeleteBulkItemVariation,
  GetCategories,
  deleteDriver,
  GetDashboardSummary,
  getAllVariations,
  getDocTypes,
  getDriverDocs,
  AddDriverDocument,
  UpdateDriverDocument,
  deleteDocument,
  import_dyn_records,
  getuserform,
  deleteCollection,
  bulkdeleteCollection,
  getcollection,
  insertCollectionData,
  getAllCruds,
  getoptions,
  save_user,
  save_item,
  Update_item
};

export default DriverService;