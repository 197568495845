import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, Button, IconButton, Box, Typography } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { styled } from '@mui/material';
import styledEmotion from '@emotion/styled';

const StyledPasswordDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: '24px',
    width: '90%',
    maxWidth: '400px',
    margin: 0,
    padding: theme.spacing(4),
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    position: 'relative',
  },
}));

const LogoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: theme.spacing(4),
}));

const StyledPasswordTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
  },
  '& .MuiInputLabel-root': {
    fontSize: '16px',
    color: '#757575',
  },
  '& .MuiOutlinedInput-input': {
    padding: '14px 16px',
    fontSize: '16px',
  },
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#e67e22',
  color: 'white',
  borderRadius: '12px',
  padding: '14px',
  fontSize: '18px',
  fontWeight: 'bold',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#d35400',
  },
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  color: theme.palette.grey[500],
}));

const PasswordResetPopup = ({ open, handleClose }) => {
  const [passwordData, setPasswordData] = useState({ oldPassword: '', newPassword: '', confirmPassword: '' });

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordData({ ...passwordData, [name]: value });
  };

  const handleSubmit = () => {
    // Password reset logic here
    if (passwordData.newPassword !== passwordData.confirmPassword) {
      alert('Passwords do not match');
      return;
    }
    // Reset password logic...
  };

  return (
    <StyledPasswordDialog open={open} onClose={handleClose}>
      <CloseButton onClick={handleClose}>
        <CloseIcon />
      </CloseButton>
      <DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold' }}>Reset Password</DialogTitle>
      <DialogContent>
        <StyledPasswordTextField
          label="Old Password"
          type="password"
          name="oldPassword"
          value={passwordData.oldPassword}
          onChange={handlePasswordChange}
          fullWidth
        />
        <StyledPasswordTextField
          label="New Password"
          type="password"
          name="newPassword"
          value={passwordData.newPassword}
          onChange={handlePasswordChange}
          fullWidth
        />
        <StyledPasswordTextField
          label="Confirm New Password"
          type="password"
          name="confirmPassword"
          value={passwordData.confirmPassword}
          onChange={handlePasswordChange}
          fullWidth
        />
      </DialogContent>
      <Box sx={{ textAlign: 'center', padding: '16px' }}>
        <SubmitButton onClick={handleSubmit}>Submit</SubmitButton>
      </Box>
    </StyledPasswordDialog>
  );
};

export default PasswordResetPopup;
