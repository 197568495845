import React from "react";
import { Translation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { Row, Col, FormGroup, Input, Button, Label, Modal, ModalHeader, ModalBody, Card, CardBody } from "reactstrap";
import CustomerService from "./Variations.service";
import AuthService from "../Authentication/auth.service";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import Alerts from "../../components/Alerts/alerts";

class DocsAddCustomer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            props: props,
            modal: false,
            variations: [{ name: '', status: '' }],
            backdrop: true,
            loading: false,
            loginNavigate: false,
        };
    }

    toggle = () => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            variations: [{ name: '', status: '' }],
        }));

    }

    addVariationRow = () => {
        this.setState(prevState => ({
            variations: [...prevState.variations, { name: '', status: '' }]
        }));
    }

    removeVariationRow = (index) => {
        this.setState(prevState => ({
            variations: prevState.variations.filter((_, i) => i !== index)
        }));
    }

    handleVariationChange = (index, field, value) => {
        this.setState(prevState => ({
            variations: prevState.variations.map((variation, i) =>
                i === index ? { ...variation, [field]: value } : variation
            )
        }));
    }

    validateForm = () => {
        const { variations } = this.state;
        return variations.every(variation => variation.name && variation.status);
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (!this.validateForm()) {
            Alerts.swalErrorAlert("Please fill all fields for all variations.");
            return;
        }

        this.setState({ loading: true });

        const payloadData = {
            functionName: "AddBulkVariations",
            postData: {
                variations: this.state.variations
            }
        };
        console.log('MY Props', this.state.props);
        const sessionID = this.state.props.authData.sessionID
        CustomerService.AddBulkVariations(sessionID, payloadData).then(
            (response) => {
                this.setState({ loading: false });
                if (response.data.status) {
                    this.props.onSuccess();
                    Alerts.swalSuccessAlert(response.data.message || "Data Added Successfully.").then(() => {
                        this.toggle();
                    });
                } else {
                    if (response.data.message === "Unauthorized") {
                        AuthService.logout();
                        this.setState({ loginNavigate: true });
                    } else {
                        Alerts.swalErrorAlert(response.data.message || "Error: Can't Add Variations at the moment. Please try again later.");
                    }
                }
            },
            (error) => {
                this.setState({ loading: false });
                Alerts.swalErrorAlert("Network Operation failed.");
            }
        );
    }

    render() {
        if (this.state.loginNavigate) {
            return <Navigate to={'/login'} />;
        }

        return (
            <Translation>
                {(t) => (
                    <span className="d-inline-block float-end mb-2 me-2">
                        <Button color="success" onClick={this.toggle} className="btn-hover-shine ms-1">
                            {t("Add New")}
                        </Button>
                        <Modal size='lg' isOpen={this.state.modal} toggle={this.toggle} backdrop={this.state.backdrop}>
                            <LoadingOverlay active={this.state.loading} spinner={<DNALoader />}>
                                <ModalHeader>{t("Add Variations")}</ModalHeader>
                                <ModalBody>
                                    <Card className="main-card mb-3">
                                        <CardBody>
                                            <form onSubmit={this.handleSubmit}>
                                                {this.state.variations.map((variation, index) => (
                                                    <Row key={index} className="mb-3">
                                                        <Col md="5">
                                                            <FormGroup>
                                                                <Label>{t("Variation Name")}</Label>
                                                                <Input
                                                                    type="text"
                                                                    value={variation.name}
                                                                    onChange={(e) => this.handleVariationChange(index, 'name', e.target.value)}
                                                                    placeholder="Enter Variation Name"
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="5">
                                                            <FormGroup>
                                                                <Label>{t("Status")}</Label>
                                                                <Input
                                                                    type="select"
                                                                    value={variation.status}
                                                                    onChange={(e) => this.handleVariationChange(index, 'status', e.target.value)}
                                                                >
                                                                    <option value="">{t("Select status")}</option>
                                                                    <option value="Active">{t("Active")}</option>
                                                                    <option value="InActive">{t("InActive")}</option>
                                                                </Input>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="2" >
                                                            <Row >
                                                                <div>
                                                                    {this.state.variations.length > 1 && (
                                                                        <i style={{ cursor: 'pointer', marginTop: '38px' }} onClick={() => this.removeVariationRow(index)} className="pe-7s-trash text-danger btn-icon-wrapper fs-5"> </i>
                                                                    )}
                                                                    <i style={{ cursor: 'pointer', marginLeft: '3px', marginTop: '38px' }} onClick={this.addVariationRow} className="pe-7s-plus text-danger btn-icon-wrapper fs-5"> </i>
                                                                </div>

                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                ))}
                                                <div className="text-center">
                                                    <Button type="submit" color="success" size="lg" className="me-3">
                                                        {t("Add")}
                                                    </Button>
                                                    <Button type="button" color="success" outline size="lg" onClick={this.toggle}>
                                                        {t("Cancel")}
                                                    </Button>
                                                </div>
                                            </form>
                                        </CardBody>
                                    </Card>
                                </ModalBody>
                            </LoadingOverlay>
                        </Modal>
                    </span>
                )}
            </Translation>
        );
    }
}

export default DocsAddCustomer;

// import React from "react";
// import { Translation } from "react-i18next";
// import { Navigate } from "react-router-dom";
// import { Row, Col, FormGroup, Input, Button, Label, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody } from "reactstrap";
// import { Form, Field } from "react-final-form";
// import CustomerService from "./Variations.service";
// import AuthService from "../Authentication/auth.service";

// import LoadingOverlay from "react-loading-overlay-ts";
// import DNALoader from "../../components/Loaders/DnaLoader";
// import "../../assets/custom css/loadoverlay.scss";

// import Alerts from "../../components/Alerts/alerts";;


// class DocsAddCustomer extends React.Component {
//     constructor(props) {
//         super(props);
//         console.log("Received Prop add variations:-", props);
//         this.state = {
//             modal: false,
//             variations: [{ name: '', status: '' }],
//             backdrop: true,
//             loading: false,
//             loginNavigate: false,
//         };
//         this.toggle = this.toggle.bind(this);
//         this.validateFtn = this.validateFtn.bind(this);
//         this.onSubmit = this.onSubmit.bind(this);

//     }

//     toggle() {
//         this.setState({
//             modal: !this.state.modal,
//         });
//     }

//     validateFtn = values => {
//         const errors = {};
//         this.state.variations.forEach((variation, index) => {
//             if (!variation.name) {
//                 errors[`name_${index}`] = "Name Required.";
//             }
//             if (!variation.status) {
//                 errors[`status_${index}`] = "Status Required.";
//             }
//         });
//         return errors;
//     };

//     addVariationRow = () => {
//         this.setState(prevState => ({
//             variations: [...prevState.variations, { name: '', status: '' }]
//         }));
//     }

//     removeVariationRow = (index) => {
//         this.setState(prevState => ({
//             variations: prevState.variations.filter((_, i) => i !== index)
//         }));
//     }

//     handleVariationChange = (index, field, value) => {
//         this.setState(prevState => ({
//             variations: prevState.variations.map((variation, i) =>
//                 i === index ? { ...variation, [field]: value } : variation
//             )
//         }));
//     }


//     onSubmit = values => {
//         this.validateFtn(values)
//         console.log(values);
//         var payloadData = {
//             functionName: "AddVariations",
//             postData: {
//                 name: this.state.name,
//                 status: this.state.status,
//             }
//         }
//         this.setState({
//             loading: !this.state.loading,
//         });
//         CustomerService.AddBulkVariations(payloadData).then((response) => {
//             console.log("API Response:-", response);
//             this.setState({
//                 loading: !this.state.loading,
//             });
//             if (response.data.status) {
//                 if (response.data.message !== '') {
//                     Alerts.swalSuccessAlert(response.data.message).then(res => {
//                         console.log("Resp:-", res)
//                         this.props.onSuccess();
//                         this.toggle();

//                     })
//                 }
//                 else {
//                     Alerts.swalSuccessAlert("Data Added Successfully.").then(res => {
//                         console.log("Resp:-", res)
//                         this.toggle();
//                     })
//                 }
//             }
//             else {
//                 if (response.data.message !== '') {
//                     if (response.data.message === "Unauthorized") {
//                         AuthService.logout();
//                         this.setState({ loginNavigate: true });
//                         window.location.reload();
//                     }
//                     else {
//                         Alerts.swalErrorAlert("Server Error, Try Again!").then(res => {
//                         })
//                     }
//                 }
//                 else {
//                     Alerts.swalErrorAlert("Error: Can't Add Document at the moment. Please try again later.")
//                 }
//             }
//         },
//             (error) => {
//                 this.setState({
//                     loading: !this.state.loading,
//                 });
//                 const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
//                 Alerts.swalErrorAlert("Network Operation failed.")
//             });

//     }

//     render() {
//         {
//             this.state.loginNavigate &&
//                 <Navigate to={'/login'} />
//         }
//         return (
//             <Translation>
//                 {(t) => (
//                     <span className="d-inline-block float-end mb-2 me-2">
//                         <a onClick={this.toggle} className="btn btn-custom-color btn-hover-shine ms-1">
//                             {t("Add New")}
//                         </a>
//                         <Modal size="lg" isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} backdrop={true}>
//                             <LoadingOverlay tag="div" active={this.state.loading}
//                                 styles={{ overlay: (base) => ({ ...base }) }}
//                                 spinner={<DNALoader />}>
//                                 <ModalHeader>{t("Add Variation")}</ModalHeader>
//                                 <ModalBody>
//                                     <Card className="main-card mb-3">
//                                         <CardBody>
//                                             <Row>
//                                                 <Col md="12" className="pt-3">
//                                                     <Form onSubmit={this.onSubmit}
//                                                         validate={this.validateFtn}
//                                                         render={({ handleSubmit, values, submitting, validating, valid }) => (
//                                                             <form onSubmit={handleSubmit}>
//                                                                 {this.state.variations.map((variation, index) => (
//                                                                     <Row key={index}>
//                                                                         <Col md="5">
//                                                                             <FormGroup>
//                                                                                 <Label className="mb-2">{t("Variation Name")}</Label>
//                                                                                 <Input
//                                                                                     type="text"
//                                                                                     placeholder="Enter Variation Name"
//                                                                                     value={variation.name}
//                                                                                     onChange={(e) => this.handleVariationChange(index, 'name', e.target.value)}
//                                                                                 />
//                                                                             </FormGroup>
//                                                                         </Col>
//                                                                         <Col md="5">
//                                                                             <FormGroup>
//                                                                                 <Label className="mb-2">{t("Status")}</Label>
//                                                                                 <Input
//                                                                                     type="select"
//                                                                                     value={variation.status}
//                                                                                     onChange={(e) => this.handleVariationChange(index, 'status', e.target.value)}
//                                                                                 >
//                                                                                     <option disabled={true} value="">{t("Select status")}</option>
//                                                                                     <option value="Active">{t("Active")}</option>
//                                                                                     <option value="InActive">{t("InActive")}</option>
//                                                                                 </Input>
//                                                                             </FormGroup>
//                                                                         </Col>

//                                                                         <Col md="2">
//                                                                             <div style={{ marginTop: '35px' }} onClick={() => this.removeVariationRow(index)}>
//                                                                                 <i className="pe-7s-trash text-danger btn-icon-wrapper fs-5"> </i>
//                                                                             </div>
//                                                                         </Col>

//                                                                     </Row>
//                                                                 ))}
//                                                                 <Button color="success" onClick={this.addVariationRow} className="mt-2">
//                                                                     <i className="fa fa-plus"></i> Add Row
//                                                                 </Button>
//                                                                 <div className="text-center mt-3">
//                                                                     <Button onClick={this.onSubmit} type="submitsubmit" color="secondary" size="lg" className="me-3">
//                                                                         {t("Add")}
//                                                                     </Button>
//                                                                     <Button type="button" color="success" outline size="lg" onClick={this.toggle}>
//                                                                         {t("Cancel")}
//                                                                     </Button>
//                                                                 </div>
//                                                             </form>
//                                                         )}
//                                                     />
//                                                 </Col>
//                                             </Row>
//                                         </CardBody>
//                                     </Card>
//                                 </ModalBody>
//                             </LoadingOverlay>
//                         </Modal>
//                     </span>
//                 )
//                 }
//             </Translation>
//         );
//     };


// };

// export default DocsAddCustomer;