import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import styled from "styled-components";
import BusinessService from "../../pages/Business/Businesses.service";
import AuthService from "../../pages/Authentication/auth.service";
import Alerts from "../../components/Alerts/alerts";
import { useNavigate } from 'react-router-dom';
import PermissionAccess from '../../PermissionAccess/Access.js';

const Input = styled.input.attrs(props => ({
  type: "text",
  size: props.small ? 5 : undefined
}))`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid var(--buttons-color, #30b1ff);
  padding: 0 32px 0 16px;
  justify-content: flex-start;
`;

const Select = styled.select`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid var(--buttons-color, #30b1ff);
  margin-left: 10px;
  padding: 0 16px;
`;

const Button = styled.button`
  margin-left: 2%;
  type: button;
  class: btn btn-custom-color btn-hover-shine me-3 btn;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const DiscountCouponsFilter = ({ onClear, onSearch }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [searchText, setSearchText] = useState("");
  const [status, setStatus] = useState("");
  const [business, setBusiness] = useState("");
  const [businesses, setBusinesses] = useState([]);
  const { authData, accessPages, super_admin, current_module } = PermissionAccess();
  useEffect(() => {
    if (businesses.length === 1) {
      setBusiness(businesses[0]._id);
    }
  }, [businesses]);
  const handleClear = () => {
    onClear();
    setSearchText('');
    setStatus('');
    setBusiness('');
  };

  const handleSearch = () => {
    onSearch(searchText, status, business);
  };

  function fetchBusinesses() {

    const stored_user = authData.userData;
    let business = []
    if (stored_user.businesses) {
      business = stored_user.businesses
    }

    BusinessService.getAllBusinesses(authData.sessionID,0, 9999999, business).then(
      (response) => {

        console.log("Get businesses response:-", response);
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        } else {
          console.log(response.data);
          setBusinesses(response.data.data);
        }
      },
      (error) => {

        const errContent =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        if (errContent) {
          Alerts.swalErrorAlert(errContent);
        } else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."));
        }
      }
    );
  }

  useEffect(() => {
    fetchBusinesses();
  }, []);
  return (
    <Container>
      <Input
        type="text"
        placeholder={t('Coupon Code')}
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
      />
      <Select
        value={status}
        onChange={(e) => setStatus(e.target.value)}
      >
        <option value="">{t('Select Status')}</option>
        <option value="Active">{t('Active')}</option>
        <option value="InActive">{t('InActive')}</option>
      </Select>

      <Select
        value={business}
        onChange={(e) => setBusiness(e.target.value)}
      >
        <option value="">Select Business</option>
        {businesses.length > 0 ? (
          businesses.map((row) => (
            <option selected={businesses.length === 1} value={row._id}>{row.name}</option>
          ))
        ) : (
          <option value="">No business available</option>
        )}
      </Select>

      <Button className="btn btn-custom-color btn-hover-shine me-3 btn"
        onClick={handleSearch}
      >
        {t('Search')}
      </Button>
      <Button className="btn btn-custom-color btn-hover-shine me-3 btn"
        onClick={handleClear}
      >
        {t('Reset')}
      </Button>
    </Container>
  );
};

export default DiscountCouponsFilter;
