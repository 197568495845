import React from "react";
import { Translation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { Row, Col, FormGroup, Input, Button, Label, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody } from "reactstrap";
import { Form, Field } from "react-final-form";

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";
import Alerts from "../../components/Alerts/alerts";

import CustomerService from "./Variations.service";
import AuthService from "../Authentication/auth.service";


class DocsUpdateCustomer extends React.Component {
    constructor(props) {
        super(props);
        console.log("Received Update Prop:-", props);
        this.state = {
            props: props,
            initialvalues: this.props.data,
            name: this.props.data.name,
            status: this.props.data.status,
            modal: false,
            backdrop: true,
            loading: false,
            loginNavigate: false,
            docTypes: [],
            upfile: ''
        };
        this.toggle = this.toggle.bind(this);
        this.validateFtn = this.validateFtn.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

    }

    toggle() {
        this.setState({
            modal: !this.state.modal,
        });
    }


    validateFtn = values => {
        const errors = {};
        if (!this.state.name === '') {
            errors.email = "Name Required.";
        }
        if (!this.state.status === '') {
            errors.status = "Status Required.";
        }
        return errors;
    };

    onSubmit = values => {
        console.log(values);
        var payloadData = {
            functionName: "UpdateVariations",
            postData: {
                Variations_id: this.props.data._id,
                name: this.state.name,
                status: this.state.status,
            }
        }
        this.setState({
            loading: !this.state.loading,
        });
        console.log('MY Props', this.state.props);
        const sessionID = this.state.props.authData.sessionID
        CustomerService.UpdateVariations(sessionID, payloadData).then((response) => {
            console.log("API Response:-", response);
            this.setState({
                loading: !this.state.loading,
            });
            this.props.onSuccess();
            if (response.data.status) {
                if (response.data.message !== '') {
                    Alerts.swalSuccessAlert(response.data.message).then(res => {
                        console.log("Resp:-", res);
                        this.toggle();
                    })
                }
                else {
                    Alerts.swalSuccessAlert("Data Updated successfully.").then(res => {
                        console.log("Resp:-", res);
                        this.toggle();
                    })
                }
            }
            else {
                if (response.data.message !== '') {
                    if (response.data.message === "Unauthorized") {
                        AuthService.logout();
                        this.setState({ loginNavigate: true });
                        window.location.reload();
                    }
                    else {
                        Alerts.swalErrorAlert("Server Error. Try Again").then(res => {
                            console.log("Resp:-", res)
                        })
                    }
                }
                else {
                    Alerts.swalErrorAlert("Error: Can't Update Document at the moment. Please try again later.")
                }
            }
        },
            (error) => {
                this.setState({
                    loading: !this.state.loading,
                });
                const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                Alerts.swalErrorAlert("Network Operation failed.")
            });

    }


    render() {
        {
            this.state.loginNavigate &&
                <Navigate to={'/login'} />
        }
        return (
            <Translation>
                {(t) => (
                    <span className="d-inline-block mb-2">
                        <i onClick={this.toggle} className="pe-7s-pen btn-icon-wrapper text-primary"> </i>
                        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} backdrop={true}>
                            <LoadingOverlay tag="div" active={this.state.loading}
                                styles={{ overlay: (base) => ({ ...base }) }}
                                spinner={<DNALoader />}>
                                <ModalHeader>{t("Update Variations")}</ModalHeader>
                                <ModalBody>
                                    <Card className="main-card mb-3">
                                        <CardBody>
                                            <Row>
                                                <Col md="12" className="pt-3">
                                                    <Form onSubmit={this.onSubmit}
                                                        validate={this.validateFtn}
                                                        initialValues={this.state.initialValues}
                                                        render={({ handleSubmit, values, submitting, validating, valid }) => (
                                                            <form onSubmit={handleSubmit}>
                                                                <Row>
                                                                    <Col md="12">
                                                                        <FormGroup>
                                                                            <div className="mb-12" >
                                                                                <Label className="mb-2">{t("Name")}</Label>
                                                                                <Field name="name">
                                                                                    {({ input, meta }) => (
                                                                                        <div>
                                                                                            <Input {...input} type="text"
                                                                                                placeholder="Enter Variation name"
                                                                                                value={this.state.name}
                                                                                                onChange={(e) => {
                                                                                                    this.setState({ name: e.target.value });
                                                                                                }}
                                                                                                invalid={meta.error && meta.touched}
                                                                                            >
                                                                                            </Input>
                                                                                            {meta.error && meta.touched &&
                                                                                                <span className="text-danger">{meta.error}</span>}
                                                                                        </div>
                                                                                    )}
                                                                                </Field>
                                                                            </div>
                                                                        </FormGroup>
                                                                    </Col>

                                                                    <Col md="12" >
                                                                        <FormGroup>
                                                                            <div className="mb-12">
                                                                                <Label className="mb-2">{t("Status")}</Label>
                                                                                <Field name="status">
                                                                                    {({ input, meta }) => (
                                                                                        <div>
                                                                                            <Input {...input} type="select"
                                                                                                value={this.state.status}
                                                                                                placeholder="select Status"
                                                                                                onChange={(e) => { this.setState({ status: e.target.value }); }}
                                                                                            >
                                                                                                <option disabled={true} value="">{t("Select Status")}</option>
                                                                                                <option key={'Active'} value="Active">{t("Active")}</option>
                                                                                                <option key={'InActive'} value="InActive">{t("InActive")}</option>
                                                                                            </Input>
                                                                                            {meta.error && meta.touched &&
                                                                                                <span className="text-danger">{meta.error}</span>}
                                                                                        </div>
                                                                                    )}
                                                                                </Field>
                                                                            </div>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <div className="text-center">
                                                                        <FormGroup>
                                                                            <div className="text-center">
                                                                                <button type="submit" className="btn btn-secondary btn-lg btn-hover-shine me-3">
                                                                                    {t("Add")} </button>
                                                                                <button type="button" className="btn btn-outline-success btn-lg btn-hover-shine me-3" onClick={this.toggle}>
                                                                                    {t("Cancel")}
                                                                                </button>
                                                                            </div>
                                                                        </FormGroup>
                                                                    </div>
                                                                </Row>
                                                            </form>
                                                        )}
                                                    />
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </ModalBody>
                            </LoadingOverlay>
                        </Modal>
                    </span>
                )}
            </Translation>
        );
    };
};

export default DocsUpdateCustomer;