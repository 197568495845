import axios from "axios";

const GET_API_URL = process.env.REACT_APP_LOGINURL;
const API_URL = process.env.REACT_APP_BASEURL;


const headers = {
  'Content-Type': 'application/json',
  'token': localStorage.getItem("session-id")
}

const getConfiguration = (sessionID) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }

  return axios.post(GET_API_URL + 'FinanceRoutes/Get_Finance_Config', { headers });
};
const setConfiguration = (data, sessionID) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(GET_API_URL + 'FinanceRoutes/Set_Finance_Config', data, { headers });
};

const fetchcollections = () => {

  var payload = {
    functionName: "FetchCollections",
    "postData": {}
  }
  return axios.post(API_URL, payload, { headers });
}
const fetchUsers_type = (sessionID) => {

  var payload = {
    functionName: "fetchUsers_type",
    "postData": {}
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(API_URL, payload, { headers });
}
const fetchDataForField = (table, sessionID) => {

  var payload = {
    functionName: "fetchDataForField",
    "postData": {
      table: table
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(API_URL, payload, { headers });
}


const ConfigurationService = {
  getConfiguration,
  setConfiguration,
  fetchUsers_type,
  fetchDataForField,
  fetchcollections
};

export default ConfigurationService;

