import React, { Fragment, useState, useEffect, useRef, useCallback } from 'react';
import {  Card, Typography, CardMedia, Box, IconButton, 
  FormControl, FormControlLabel } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from "react-router-dom";
import Alerts from "../../../components/Alerts/alerts";
import AuthService from "../../Authentication_usertypes/auth.service";
import AlertService from "../../../components/Alerts/alerts";
import DashboardService from "../dashboard.service";
import ConfigurationService from "../../Configuration/configuration.service";
import Autocomplete from '@mui/material/Autocomplete';
import { Row, Col, CardBody } from "reactstrap";
import {
  Drawer,  TextField} from "@mui/material";
import axios from "axios";
import CART_VIEW from "./CartView";
import { PulseLoader } from "react-spinners";
const Image_URL = process.env.REACT_APP_IMAGEURL;

const B_URL = process.env.REACT_APP_LOGINURL


const headers = {
  'Content-Type': 'application/json',
  'token': localStorage.getItem("session-id")
};


const LoaderContainer = ({ loading }) => {
  return (
    loading && (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f9f9f9",
        }}
      >
        <PulseLoader size={15} color="#C1B315" />
      </div>
    )
  );
};

const App = () => {
  const [activeCategory, setActiveCategory] = useState('');
  const categoryRefs = useRef({});
  const scrollRef = useRef(null);
  const navigate = useNavigate();
  const [logo, setLogo] = useState(localStorage.getItem("system_logo"));
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [searchTerm, setSearchTerm] = useState('');
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [Branch, setBranch] = useState('');
  const [branches, setBranches] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [pageLimit, setPageLimit] = useState(1000);
  const [products, setProducts] = useState([]);
  const [tempCart, setTempCart] = useState([]);
  const [userData, setUser] = useState(null);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [storeduser, setStoredUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [source, setSource] = useState(storeduser.type ? storeduser.type === 'City/Town Stockist' ? ["Nano ProTech"] : ["Nano ProTech", "City/Town Stockist"] : ["City/Town Stockist", "Mobile Stockist"]);
  const [selectedSource, setSelectedSource] = useState(tempCart.length > 0 ? tempCart?.[0]?.selectedSource : '');
  const [activeOption, setActiveOption] = useState(tempCart.length > 0 ? tempCart?.[0]?.activeOption : '');
  const [MobileStockists, setMobileStockists] = useState([]);
  const [StockistStock, setStockistStock] = useState([]);
  const [CityStockists, setCityStockists] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false); // Manage drawer state
  const userCity = JSON.parse(localStorage.getItem("user"))?.address?.city || "";
  const [selectedCurrency, setSelectedCurrency] = useState(
    JSON.parse(localStorage.getItem("selectedCurrency")) || {
      code: "PHP",
      rate: 1,
      symbol: "₱",
    }
  );
  
  useEffect(() => {
    if (selectedSource === '') {
      setSelectedSource(tempCart.length > 0 ? tempCart?.[0]?.selectedSource : '');
      setActiveOption(tempCart.length > 0 ? tempCart?.[0]?.activeOption : '');
    }
  }, [tempCart]);

  useEffect(() => {
    console.log("-------------------------------- FetchStockistStock FetchNanoStock--------------------------------")
    console.log("selectedSource ", selectedSource);
    if (selectedSource === '') {
      console.log("selectedSource ", selectedSource);
      if(tempCart.length > 0 ){
        console.log("tempCart.length ", tempCart.length);
        if(tempCart?.[0]?.activeOption){
          console.log("tempCart?.[0]?.activeOption ", tempCart?.[0]?.activeOption);
          FetchStockistStock(tempCart?.[0]?.activeOption)
        }
      }
    }
    if(selectedSource === 'Nano ProTech'){
      console.log("selectedSource ", selectedSource);
      if(tempCart.length > 0 ){
          FetchNanoStock()
        }
    }
  }, [tempCart,activeOption, selectedSource, tempCart?.[0]?.activeOption]);


  useEffect(() => {
    const userString = localStorage.getItem('user');
    if (userString) {
      setUser(JSON.parse(userString));
    }
  }, []);

  useEffect(() => {
    if (Branch !== '')
      fetchCategories(0, pageLimit)
  }, [Branch]);

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchBusiness()
    getSystemConfig();
    fetchExistingCart();
  }, []);
  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const CartView = () => {
    setDrawerOpen(true); // Open the drawer instead of navigating
  };


  useEffect(() => {
    const userString = localStorage.getItem('user');
    if (userString) {
     let user = JSON.parse(userString);
    fetchMobileStockists(user._id);
    fetchCityStockists(user._id);
  }
  }, []);

  const fetchCityStockists = async (user_id) => {
    setLoading(true);
    try {
      var payload = {
        functionName: "GetCityStockists",
        postData: {
          user_id:user_id
        },
      };
      const response = await axios.post(B_URL + 'AddressModule/GetCityStockists', payload, { headers });
      if (response.data.status) {
        console.log("Get City Stockists", response.data.data.data);
        setCityStockists(response.data.data.data);
      } else {
        AlertService.swalErrorAlert(response.data.message || t("Error fetching addresses."));
      }
    } catch (error) {
      AlertService.swalErrorAlert(t("Error fetching addresses. Please try again."));
    } finally {
      setLoading(false);
    }
  };

  const fetchMobileStockists = async (user_id) => {
    setLoading(true);
    try {
      var payload = {
        functionName: "GetMobileStockists",
        postData: {
          user_id:user_id
        },
      };
      const response = await axios.post(B_URL + 'AddressModule/GetMobileStockists', payload, { headers });
      if (response.data.status) {
        console.log("CITIES", response.data.data.data);
        setMobileStockists(response.data.data.data);
      } else {
        AlertService.swalErrorAlert(response.data.message || t("Error fetching addresses."));
      }
    } catch (error) {
      AlertService.swalErrorAlert(t("Error fetching addresses. Please try again."));
    } finally {
      setLoading(false);
    }
  };


  function getSystemConfig() {
    ConfigurationService.getConfiguration().then((response) => {
      console.log("API Response:-", response);
      if (response.data.status) {
        setColors(response.data.data[0].buttons_color, '--buttons-color');
        setColors(response.data.data[0].topbar_color, '--topbar-color');
        setColors(response.data.data[0].leftbar_color, '--leftbar-color');
        setColors(response.data.data[0].buttons_text_color, '--buttons-text-color');
        setSystemLogo(response.data.data[0].system_logo);
      }
      else {
        setColors('#3ac47d', 'onerror');
      }
    },
      (error) => {
        setColors('#3ac47d', 'onerror');
      });
  }

  function setColors(color, param) {
    if (param === '--buttons-color') {
      localStorage.setItem("buttons_color", color);
      document.documentElement.style.setProperty('--buttons-color', color)
    }
    if (param === '--topbar-color') {
      localStorage.setItem("topbar_color", color);
      document.documentElement.style.setProperty('--topbar-color', color)
    }
    if (param === '--leftbar-color') {
      localStorage.setItem("leftbar_color", color);
      document.documentElement.style.setProperty('--leftbar-color', color)
    }
    if (param === '--buttons-text-color') {
      localStorage.setItem("buttons_text_color", color);
      document.documentElement.style.setProperty('--buttons-text-color', color)
    }
    if (param === 'onerror') {
      localStorage.setItem("buttons_color", color);
      localStorage.setItem("topbar_color", '#fff');
      localStorage.setItem("leftbar_color", '#fff');
      localStorage.setItem("buttons_text_color", '#fff');
      document.documentElement.style.setProperty('--buttons-color', color)
      document.documentElement.style.setProperty('--topbar-color', '#fff')
      document.documentElement.style.setProperty('--leftbar-color', '#fff')
      document.documentElement.style.setProperty('--buttons-text-color', '#fff')
    }
  }

  function setSystemLogo(sys_logo) {
    setLogo(sys_logo);
    localStorage.setItem("system_logo", sys_logo);
  }
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (branches.length > 0 && !Branch) {
      setBranch(branches[0]?._id);
    }
  }, [branches, Branch, setBranch]);

  useEffect(() => {
    if (selectedCategory !== undefined) {
      fetchProducts(0, pageLimit)
    }
  }, [selectedCategory, Branch]);

  const fetchExistingCart = async () => {
    try {
      setLoading(true);
      const userString = localStorage.getItem('user');
      const user = JSON.parse(userString);
      const userId = user['_id'];
      const updateData = {
        "functionName": "GetCartList",
        "postData": {
          "user_id": userId,
          "filters": {
            "status": false,
            "coupan_code": '',
            "prioritize": "",
            "quantity": {
              "cart_id": '',
              "quantity": ''
            }
          }
        }
      };
      const response = await DashboardService.GetCartList(updateData);
      if (response.data && response.data.cartData) {
        const existingCartItems = response.data.cartData.map(item => ({
          business_id: item.business_details?._id,
          category_id: item.category_details?._id,
          product_id: item.product_details?._id,
          quantity: parseInt(item?.quantity),
          price: parseFloat(item?.price),
          selectedSource: item?.selectedSource,
          activeOption: item?.activeOption,
          variations: item?.variations || []
        }));
        setTempCart(existingCartItems);
      }
    } catch (error) {
      console.error('Error fetching existing cart:', error);
    } finally {
      setLoading(false);
    }
  };
  const [categorizedProducts, setCategorizedProducts] = useState({});

  function fetchCategories(page_index, page_limit) {
    var offset = (page_index - 1) * page_limit;
    if (offset < 1) {
      offset = 0;
    }
    setLoading(true);
    let branches = [];
    if (Branch) {
      branches.push(Branch)
    }
    DashboardService.getAllCategories(offset, page_limit, branches).then((response) => {
      setLoading(false);
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        if (response.data.data) {
          console.log("ALL Category", response.data.data);
          setCategories(response.data.data);
          if (response.data.data.length > 0) {
            setSelectedCategory(response.data.data[0]._id);
          }
        }
      }
    },
      (error) => {
        setLoading(false);
        const errContent = (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  function fetchBusiness() {
    setLoading(true);
    DashboardService.getAllBusiness().then((response) => {
      setLoading(false);
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        if (response.data.data) {
          console.log("ALL Business", response.data.data);
          setBranches(response.data.data);
        }
      }
    },
      (error) => {
        setLoading(false);
        const errContent = (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  // Modify the fetchProducts function
  function fetchProducts(page_index, page_limit) {
    var offset = (page_index - 1) * page_limit;
    if (offset < 1) {
      offset = 0;
    }

    setLoading(true);
    let branch = [];
    if (Branch !== '') {
      branch.push(Branch)
    }
    let Category = [];
    categories.map((category) => {
      Category.push(category?._id)
    })
    if (Category?.length > 0 && branch.length > 0) {
      DashboardService.getAllProducts(offset, page_limit, Category, branch).then((response) => {
        setLoading(false);
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        }
        else {
          if (response.data.data) {
            console.log("ALL Products", response.data.data);
            setProducts(response.data.data !== {} ? response.data.data : []);

            // Categorize products
            const categorized = {};
            response.data.data.forEach(product => {
              if (!categorized[product.category]) {
                categorized[product.category] = [];
              }
              categorized[product.category].push(product);
            });
            setCategorizedProducts(categorized);
          }
        }
      },
        (error) => {
          setLoading(false);
          const errContent = (error.response && error.response.data) || error.message || error.toString();
          console.log("Net:-", errContent)
          if (errContent) {
            Alerts.swalErrorAlert(errContent)
          }
          else {
            Alerts.swalErrorAlert(t("Server Error, Please try again later."))
          }
        })
    }
  };

  const handleVariationChange = (product, variationGroup, type, isChecked) => {
    setTempCart(prev => {
      const productIndex = prev.findIndex(item => item.product_id === product._id);
      if (productIndex === -1) {
        // If product is not in cart, add it with the selected variation
        const newItem = {
          business_id: product.business_id,
          category_id: product.category,
          product_id: product._id,
          quantity: 1,
          price: parseFloat(product.price),
          variations: [{
            variation_id: variationGroup.Variation._id,
            variation_name: variationGroup.Variation.name,
            variation_option_id: type._id,
            variation_type_name: type.variation_type_name,
            variation_price: parseFloat(type.price)
          }],
          total_price: parseFloat(product.price) + parseFloat(type.price)
        };
        return [...prev, newItem];
      }

      const updatedCart = [...prev];
      const cartItem = { ...updatedCart[productIndex] };

      if (isChecked) {
        cartItem.variations.push({
          variation_id: variationGroup.Variation._id,
          variation_name: variationGroup.Variation.name,
          variation_option_id: type._id,
          variation_type_name: type.variation_type_name,
          variation_price: parseFloat(type.price)
        });
      } else {
        cartItem.variations = cartItem.variations.filter(
          v => !(v.variation_id === variationGroup.Variation._id && v.variation_option_id === type._id)
        );
      }

      const basePrice = parseFloat(cartItem.price);
      const variationTotal = cartItem.variations.reduce((sum, v) => sum + v.variation_price, 0);
      cartItem.total_price = (basePrice + variationTotal) * cartItem.quantity;

      updatedCart[productIndex] = cartItem;
      setCart(JSON.parse(localStorage.getItem('user'))._id, updatedCart);
      return updatedCart;
    });
  };

  const handleIncrement = (product) => {
    if (selectedSource === '' || !selectedSource) {
      return AlertService.swalErrorAlert("Please select a source");
    }
  
    // Skip stock checks for "Nano ProTech"
    let availableStock = Infinity;
    if (selectedSource !== "Nano ProTech") {
      const stockItem = StockistStock.find(
        (stock) => stock.product_id === product._id
      );
      availableStock = stockItem ? stockItem.quantity : 0;
    }
  
    setTempCart((prev) => {
      const existingProductIndex = prev.findIndex(
        (item) => item.product_id === product._id
      );
  
      if (existingProductIndex > -1) {
        const updatedCart = [...prev];
        const currentQuantity = updatedCart[existingProductIndex].quantity;
  
        if (currentQuantity >= availableStock) {
          AlertService.swalErrorAlert("Cannot add more than available stock");
          return prev;
        }
  
        updatedCart[existingProductIndex] = {
          ...updatedCart[existingProductIndex],
          selectedSource,
          activeOption,
          quantity: currentQuantity + 1,
          total_mpv: (updatedCart[existingProductIndex].quantity + 1) * (parseFloat(product.item_mpv)),
          total_price:
            (currentQuantity + 1) *
            (parseFloat(product.price) +
              updatedCart[existingProductIndex].variations.reduce(
                (sum, v) => sum + v.variation_price,
                0
              )),
        };
        setCart(JSON.parse(localStorage.getItem('user'))._id, updatedCart);
        return updatedCart;
      } else {
        const newCart = [
          ...prev,
          {
            business_id: product.business_id,
            category_id: product.category,
            product_id: product._id,
            quantity: 1,
            selectedSource,
            activeOption,
            item_mpv: parseFloat(product.item_mpv),
            price: parseFloat(product.price),
            variations: [],
            total_price: parseFloat(product.price),
            total_mpv:parseFloat(product.item_mpv)
          },
        ];
        setCart(JSON.parse(localStorage.getItem('user'))._id, newCart);
        return newCart;
      }
    });
  };
  
  const handleDecrement = (product) => {
    if(selectedSource === '' || selectedSource === undefined || selectedSource === null){
      return AlertService.swalErrorAlert("Please select a source");
    }else if(selectedSource !== 'Nano ProTech'){
      if(activeOption === '' || activeOption === undefined || activeOption === null){
        return AlertService.swalErrorAlert("Please select a Stockist as well");
      }
    }
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user) {
      setShowLoginPopup(true);
    } else {
      setTempCart(prev => {
        const existingProductIndex = prev.findIndex(item => item.product_id === product._id);
        if (existingProductIndex > -1) {
          if (prev[existingProductIndex].quantity > 1) {
            const updatedCart = [...prev];
            const itemTotalPrice = parseFloat(product.price) +
              updatedCart[existingProductIndex].variations.reduce((sum, v) => sum + v.variation_price, 0);
            updatedCart[existingProductIndex] = {
              ...updatedCart[existingProductIndex],
              quantity: updatedCart[existingProductIndex].quantity - 1,
              total_price: (updatedCart[existingProductIndex].quantity - 1) * itemTotalPrice
            };
            setCart(JSON.parse(localStorage.getItem('user'))._id, updatedCart);
            return updatedCart;
          } else {
            const newCart = prev.filter(item => item.product_id !== product._id);
            setCart(JSON.parse(localStorage.getItem('user'))._id, newCart);
            return newCart;
          }
        }
        return prev;
      });
    }
  };


  const setCart = (userId, cartData) => {
    console.log("cartData", cartData)

    setLoading(true);
    const payload = {
      "functionName": "SetCart",
      user_id: userId,
      "postData": cartData
    };
    console.log("payload setCart", payload)
    DashboardService.setCart(payload)
      .then((response) => {
        setLoading(false);
        if (response.data.status === false) {
          AlertService.swalErrorAlert(t(response.data.message));
        }
        else {
          console.log('Cart updated successfully:', response);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Error updating cart:', error);
        AlertService.swalErrorAlert(t("Error updating cart. Please try again."));
      });
  };


  const getProductQuantity = (productId) => {
    const cartItem = tempCart.find(item => item.product_id === productId);
    return cartItem ? cartItem.quantity : 0;
  };


  const filterProducts = (products, term) => {
    if (!term) return products;
    return products.filter(product =>
      product?.item_name.toLowerCase().includes(term?.toLowerCase())
    );
  };
  // Function to get all products across categories
  const getAllProducts = () => {
    return Object.values(categorizedProducts)?.flat();
  };

  function FetchStockistStock(user_id) {
    setLoading(true);
    DashboardService.getStockistStock(user_id).then((response) => {
      setLoading(false);
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        if (response.data.data) {
          console.log("ALL Stockist Stock", response.data.data);
          setStockistStock(response?.data?.data?.[0]?.Items || []);
        }
      }
    },
      (error) => {
        setLoading(false);
        const errContent = (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  function FetchNanoStock(user_id) {
    setLoading(true);
    DashboardService.getNanoStock(user_id).then((response) => {
      setLoading(false);
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        if (response.data.data) {
          console.log("ALL Stockist Stock", response.data.data);
          setStockistStock(response?.data?.data?.[0]?.Items || []);
        }
      }
    },
      (error) => {
        setLoading(false);
        const errContent = (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  // Filtered products
  const filteredProducts = filterProducts(getAllProducts(), searchTerm);
  const scrollToCategory = useCallback((categoryId) => {
    const element = categoryRefs.current[categoryId];
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    }
  }, []);


  // const handleCategoryClick = (category) => {
  //   setActiveCategory(category?._id);
  //   scrollToCategory(category?._id);
  // };

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: -100, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: 100, behavior: 'smooth' });
    }
  };
  useEffect(() => {
    if (activeCategory) {
      scrollToCategory(activeCategory);
    }
  }, [activeCategory, scrollToCategory]);

  const handleCategoryClick = (category) => {
    setActiveCategory(category?._id);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.pageYOffset + 200; // Offset to trigger category change earlier
      let currentCategory = categories[0];

      for (const category of categories) {
        const element = categoryRefs.current[category?._id];
        if (element && scrollPosition >= element.offsetTop) {
          currentCategory = category;
        } else {
          break;
        }
      }

      setActiveCategory(currentCategory?._id);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [categories]);
  const ProductCard = ({
    product,
    index,
    handleDecrement,
    handleIncrement,
    getProductQuantity,
    tempCart,
    StockistStock,
    selectedSource,
  }) => {
    const quantity = getProductQuantity(product?._id);
  
    // Determine availability
    let availableStock = Infinity; // No stock limit if Nano ProTech
      const stockItem = StockistStock.find(
        (stock) => stock.product_id === product._id
      );
      availableStock = stockItem ? stockItem.quantity : 0;
  
    return (
      <Card
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "16px",
          marginBottom: "16px",
          cursor:'pointer',
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
          opacity:
            selectedSource === "Nano ProTech" || availableStock > 0 ? 1 : 0.5,
          pointerEvents:
            selectedSource === "Nano ProTech" || availableStock > 0
              ? "auto"
              : "none",
          transition: "all 0.3s",
          "&:hover": {
            transform: "scale(1.03)", // Slight scale on hover
            boxShadow: "0 6px 12px rgba(0,0,0,0.15)",
          },
        }}
      >
        {/* Product Image */}
        <CardMedia
          component="img"
          image={`${Image_URL}${product?.images?.[0]}`}
          alt={product?.item_name}
          sx={{
            width: "80px",
            height: "80px",
            borderRadius: "8px",
            marginRight: "16px",
            objectFit: "cover",
          }}
        />
  
        {/* Product Details */}
        <Box sx={{ flex: 1 }}>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "bold",
              color: "#000",
              marginBottom: "8px",
            }}
          >
            {product?.item_name}
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              color: "#999",
              marginBottom: "8px",
            }}
          >
            MPV {parseFloat(product?.item_mpv).toFixed(2)}  ({availableStock - quantity})
          </Typography>
        </Box>
  
        {/* Price and Quantity Controls */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              color: "#000",
              marginBottom: "8px",
            }}
          >
           {selectedCurrency.symbol}  {parseFloat(product?.price * selectedCurrency.rate).toFixed(2)}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton
              onClick={() => handleDecrement(product)}
              disabled={quantity === 0}
              sx={{
                backgroundColor: "#000",
                color: "#fff",
                width: "20px",
                height: "20px",
                fontSize:'13px',
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50%",
                opacity: quantity > 0 ? 1 : 0.5,
                "&:hover": { backgroundColor: "#333" },
              }}
            >
              <RemoveIcon fontSize="small" />
            </IconButton>
            <Typography
              sx={{
                margin: "0 8px",
                fontSize: "16px",
                fontWeight: "bold",
                color: "#000",
              }}
            >
              {quantity}
            </Typography>
            <IconButton
              onClick={() => handleIncrement(product)}
              disabled={quantity >= availableStock}
              sx={{
                backgroundColor: "#C1B315",
                color: "#fff",
                width: "20px",
                fontSize:'13px',
                height: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50%",
                "&:hover": { backgroundColor: "#A19010" },
              }}
            >
              <AddIcon fontSize="small" />
            </IconButton>
          </Box>
        </Box>
      </Card>
    );
  };
  
  
  

  function logOut() {
    navigate("/customer_login");
    AuthService.logout();
    // window.location.reload();
  }


  return (
<Fragment>
  <div className="app-main__outer">
    <div className="app-main__inner">
      <div className="bg-light-gray bg-animation">
              {/* Cart Drawer */}
              <Drawer
    anchor="right"
    open={drawerOpen}
    onClose={toggleDrawer(false)}
    PaperProps={{
        style: { width: "455px",
        borderRadius: '15px',
        marginTop:'10px',
        marginRight: '10px',
     },
    }}
>
<CART_VIEW state={{ points: 0, type: "MPV" }} setDrawerOpen={setDrawerOpen} />
</Drawer>


        <div className="d-flex justify-content-center align-items-center">
          <Col md='12' sm='12' xs="12" lg="12" xl='12' className="mx-auto app-login-box">
            <Card className="main-card mb-3" style={{ padding: '2%', borderRadius: '20px' }}>
              <CardBody style={{ borderRadius: '20px', border: '1px solid #CDCDCD' }}>
                <Row
                  className="mb-3"
                  style={{
                    alignItems: "center",
                    marginBottom: "20px",
                    marginTop: "10px",
                  }}
                >
                  <Col
                    xs="12"
                    className="d-flex justify-content-between align-items-center flex-wrap"
                  >
                    <h4
                      style={{
                        fontSize: "1.2rem",
                        fontWeight: "bold",
                        margin: 0,
                        color: "#333",
                      }}
                    >
                      New Order
                    </h4>
                    <div
                      className="d-flex align-items-center gap-2 mt-2 mt-md-0"
                      style={{ flexWrap: "wrap" }}
                    >
                      <div style={{ position: "relative", maxWidth: "300px", flex: "1 1 auto" }}>
                        <input
                          type="text"
                          placeholder={t("Type here...")}
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                          style={{
                            width: "100%",
                            padding: "8px 8px 8px 40px",
                            border: "1px solid #ddd",
                            borderRadius: "5px",
                            backgroundColor: "#f9f9f9",
                            fontSize: "0.9rem",
                            color: "#333",
                          }}
                        />
                        <i
                          className="fas fa-search"
                          style={{
                            position: "absolute",
                            left: "10px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            fontSize: "1rem",
                            color: "#aaa",
                          }}
                        >
                          🔍
                        </i>
                      </div>
                      <div
                        onClick={() => {
                          CartView();
                        }}
                        className="cart-icon"
                        style={{
                          background: "#EEEEEE",
                          borderRadius: "5px",
                          padding: "5px",
                          fontSize: "20px",
                          position: "relative",
                          cursor: "pointer",
                          minWidth: "50px",
                          textAlign: "center",
                        }}
                      >
                        🛒 Cart
                        <span
                          style={{
                            position: "absolute",
                            top: "-5px",
                            right: "-5px",
                            backgroundColor: "red",
                            color: "white",
                            borderRadius: "50%",
                            padding: "2px 6px",
                            fontSize: "12px",
                          }}
                        >
                          {tempCart.reduce((sum, item) => sum + item.quantity, 0)}
                        </span>
                      </div>
                    </div>
                  </Col>
                </Row>
                <div style={{marginLeft:'10px'}}>
                {source.map((source, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <input
                      type="radio"
                      name="source"
                      value={source}
                      checked={selectedSource === source}
                      onChange={(e) => {
                        if(tempCart.length > 0) {
                         AlertService.swalErrorAlert("Please Complete current cart order or empty cart to change source")
                        }else{
                          setSelectedSource(e.target.value)}                    
                          if(e.target.value === "Nano ProTech"){
                            FetchNanoStock();
                          }
                        }
                      }
                      style={{
                        marginRight: "8px",
                        accentColor: "#C1B314",
                        width: "14px",
                        height: "14px",
                      }}
                    />
                  }
                  label={`${source} : `}
                  sx={{ fontSize: "14px", color: "black", marginRight: "30px" }}
                />
              ))}
              </div>

            {/* Show Dropdown for City/Town Stockist or Mobile Stockist */}
            {selectedSource === "City/Town Stockist" ? (
  <FormControl fullWidth sx={{ marginTop: "10px" }}>
    <Autocomplete
      options={CityStockists || []} // Use filtered stockists
      getOptionLabel={(option) =>
        `${option?.username} (${option?.mobileNumber || ""})`
      }
      value={CityStockists.find(
        (option) => option?._id === activeOption
      )}
      onChange={(event, newValue) => {
        if (tempCart.length > 0) {
          AlertService.swalErrorAlert(
            "Please Complete current cart order or empty cart to change Stockist"
          );
        } else {
          setActiveOption(newValue ? newValue._id : "");
          if (newValue) FetchStockistStock(newValue._id);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={`Select ${selectedSource}`}
          variant="outlined"
          sx={{
            width: "20%",
            "& .MuiOutlinedInput-root": { borderRadius: "8px" },
          }}
        />
      )}
    />
  </FormControl>
) : selectedSource === "Mobile Stockist" ? (
  <FormControl fullWidth sx={{ marginTop: "10px" }}>
    <Autocomplete
      options={MobileStockists || []} // Use filtered stockists
      getOptionLabel={(option) =>
        `${option?.username} (${option?.mobileNumber || ""})`
      }
      value={MobileStockists.find(
        (option) => option?._id === activeOption
      )}
      onChange={(event, newValue) => {
        if (tempCart.length > 0) {
          AlertService.swalErrorAlert(
            "Please Complete current cart order or empty cart to change Stockist"
          );
        } else {
          setActiveOption(newValue ? newValue._id : "");
          if (newValue) FetchStockistStock(newValue._id);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={`Select ${selectedSource}`}
          variant="outlined"
          sx={{
            width: "20%",
            "& .MuiOutlinedInput-root": { borderRadius: "8px" },
          }}
        />
      )}
    />
  </FormControl>
) : null}



                <main style={{ padding: "20px" }}>
                  <LoaderContainer loading={loading} />
                  {!searchTerm && (
                    <div
                      style={{
                        position: "sticky",
                        top: "60px",
                        zIndex: 999,
                        borderBottom: "1px solid #e0e0e0",
                        backgroundColor: "white",
                      }}
                    >
                      <div
                        ref={scrollRef}
                        style={{
                          display: "flex",
                          overflowX: "auto",
                          padding: "10px",
                          scrollBehavior: "smooth",
                        }}
                      >
                          {categories.map((category) => (
                            <button
                              key={category?._id}
                              ref={(el) => categoryRefs.current[category?._id] = el}
                              className="category-button fade-in"
                              style={{
                                padding: '10px 20px',
                                marginRight: '10px',
                                marginLeft: '10px',
                                marginTop: '10px',
                                backgroundColor: category?._id === activeCategory ? '#C1B315' : 'white',
                                color: category?._id === activeCategory ? 'white' : 'black',
                                border: 'none',
                                borderRadius: '20px',
                                whiteSpace: 'nowrap',
                                fontSize: '14px',
                                fontWeight: category?._id === activeCategory ? 'bold' : 'normal',
                                boxShadow: category?._id === activeCategory ? '0 2px 4px rgba(0,0,0,0.1)' : 'none'
                              }}
                              onClick={() => handleCategoryClick(category)}
                            >
                              {category?.Name}
                            </button>
                          ))}

                      </div>
                    </div>
                  )}
                  {searchTerm ? (
                    <div>
                      <h2
                        style={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          color: "#333",
                          marginBottom: "15px",
                        }}
                      >
                        Search Results
                      </h2>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns:
                            "repeat(auto-fill, minmax(250px, 1fr))",
                          gap: "20px",
                        }}
                      >
                        {filteredProducts.map((product, index) => (
                          <ProductCard
                            key={index}
                            product={product}
                            index={index}
                            handleDecrement={handleDecrement}
                            handleIncrement={handleIncrement}
                            getProductQuantity={getProductQuantity}
                            handleVariationChange={handleVariationChange}
                            tempCart={tempCart}
                            StockistStock={StockistStock}
                            selectedSource={selectedSource}
                          />
                        ))}
                      </div>
                    </div>
                  ) : (
                    categories.map((category) => (
                      <div
                        key={category?._id}
                        ref={(el) => categoryRefs.current[category?._id] = el}
                        style={{ marginBottom: '30px' }}
                      >
                        <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: '#333', marginBottom: '10px' }}>{category?.Name}</Typography>
                        <div style={{
                          display: 'grid',
                          gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
                          gap: '20px'
                        }}>
                          {categorizedProducts[category?._id]?.map((product, index) => (
                            <ProductCard
                              key={index}
                              product={product}
                              index={index}
                              handleDecrement={handleDecrement}
                              handleIncrement={handleIncrement}
                              getProductQuantity={getProductQuantity}
                              handleVariationChange={handleVariationChange}
                              tempCart={tempCart}
                              StockistStock={StockistStock}
                              selectedSource={selectedSource}

                            />
                          ))}
                        </div>
                      </div>
                    ))
                  )}
                </main>
              </CardBody>
            </Card>
          </Col>
        </div>
      </div>
    </div>
  </div>
  <style>
      {`
    @keyframes fadeIn {
      from { opacity: 0; }
      to { opacity: 1; }
    }
    
    @keyframes slideIn {
      from { transform: translateX(-100%); }
      to { transform: translateX(0); }
    }
    
    @keyframes slideUp {
      from { transform: translateY(100%); }
      to { transform: translateY(0); }
    }
    
    body {
      background-color: black;
      margin: 0;
      padding: 0;
    }
    
    .fade-in {
      animation: fadeIn 0.5s ease-out;
    }
    
    .slide-in {
      animation: slideIn 0.3s ease-out;
    }
    
    .slide-up {
      animation: slideUp 0.3s ease-out;
    }
    
    .category-button {
      transition: background-color 0.3s, color 0.3s;
    }
    
    #scrollRef::-webkit-scrollbar {
      display: none;
    }

    #scrollRef {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }

    @media (max-width: 768px) {
      .category-button {
        padding: 8px 15px;
        margin-right: 5px;
        font-size: 12px
      }
    }
  `}
    </style>
</Fragment>
  );
};

export default App;

// <Fragment>
// <div className="app-main__outer">
//   <div className="app-main__inner">
//     <div className="bg-light-gray bg-animation">
//       <div className="d-flex justify-content-center align-items-center">
//         <Col md="12" className="mx-auto app-login-box">
//           <Card className="main-card mb-3" style={{ padding: '2%', borderRadius: '20px' }}>
//             <CardBody style={{ borderRadius: '20px', border: '1px solid #CDCDCD' }} >
//               <>
//                 <Row
//                   className="mb-3"
//                   style={{
//                     alignItems: "center",
//                     marginBottom: "20px",
//                     marginTop: "10px",
//                   }}
//                 >
//                   <Col
//                     md="12"
//                     style={{
//                       display: "flex",
//                       justifyContent: "space-between",
//                       alignItems: "center",
//                     }}
//                   >
//                     <h4
//                       style={{
//                         fontSize: "1.2rem",
//                         fontWeight: "bold",
//                         margin: 0,
//                         color: "#333",
//                       }}
//                     >
//                       New Order
//                     </h4>
//                     <div
//                       style={{
//                         display: "flex",
//                         alignItems: "center",
//                         gap: "15px",
//                       }}
//                     >
//                       {/* Search bar */}
//                       <div style={{ position: "relative", flex: 1, maxWidth: "300px" }}>
//                         <input
//                           type="text"
//                           placeholder={t("Type here...")}
//                           value={searchTerm}
//                           onChange={(e) => setSearchTerm(e.target.value)}
//                           style={{
//                             width: "100%",
//                             padding: "8px 8px 8px 40px", // Left padding to fit the icon
//                             border: "1px solid #ddd",
//                             borderRadius: "5px",
//                             backgroundColor: "#f9f9f9",
//                             fontSize: "0.9rem",
//                             color: "#333",
//                           }}
//                         />
//                         <i
//                           className="fas fa-search"
//                           style={{
//                             position: "absolute",
//                             left: "10px",
//                             top: "50%",
//                             transform: "translateY(-50%)",
//                             fontSize: "1rem",
//                             color: "#aaa",
//                           }}
//                         >
//                           🔍 
//                         </i>
//                       </div>

//                       {/* Cart icon */}
//                       <div
//                         onClick={() => {
//                           CartView();
//                         }}
//                         style={{
//                           background: "#EEEEEE",
//                           borderRadius: "5px",
//                           padding: "5px",
//                           fontSize: "20px",
//                           position: "relative",
//                           cursor: "pointer",
//                           minWidth: "50px",
//                           textAlign: "center",
//                         }}
//                       >
//                         🛒 Cart
//                         <span
//                           style={{
//                             position: "absolute",
//                             top: "-5px",
//                             right: "-5px",
//                             backgroundColor: "red",
//                             color: "white",
//                             borderRadius: "50%",
//                             padding: "2px 6px",
//                             fontSize: "12px",
//                           }}
//                         >
//                           {tempCart.reduce((sum, item) => sum + item.quantity, 0)}
//                         </span>
//                       </div>
//                     </div>
//                   </Col>
//                 </Row>


//                 <main style={{ padding: '20px' }}>

//                   <LoaderContainer loading={loading} />
//                   <>
//                     {!searchTerm && (
//                       <div style={{
//                         position: 'relative',
//                         backgroundColor: 'white',
//                         position: 'sticky',
//                         top: '60px',
//                         zIndex: 999,
//                         borderBottom: '1px solid #e0e0e0'
//                       }}>
//                         <div ref={scrollRef} style={{
//                           display: 'flex',
//                           overflowX: 'auto',
//                           padding: '10px',
//                           paddingRight: '50px', // Make space for the right button
//                           scrollBehavior: 'smooth'
//                         }}>
//                           {categories.map((category) => (
//                             <button
//                               key={category?._id}
//                               ref={(el) => categoryRefs.current[category?._id] = el}
//                               className="category-button fade-in"
//                               style={{
//                                 padding: '10px 20px',
//                                 marginRight: '10px',
//                                 marginLeft: '10px',
//                                 marginTop: '10px',
//                                 backgroundColor: category?._id === activeCategory ? '#F2731C' : 'white',
//                                 color: category?._id === activeCategory ? 'white' : 'black',
//                                 border: 'none',
//                                 borderRadius: '20px',
//                                 whiteSpace: 'nowrap',
//                                 fontSize: '14px',
//                                 fontWeight: category?._id === activeCategory ? 'bold' : 'normal',
//                                 boxShadow: category?._id === activeCategory ? '0 2px 4px rgba(0,0,0,0.1)' : 'none'
//                               }}
//                               onClick={() => handleCategoryClick(category)}
//                             >
//                               {category?.Name}
//                             </button>
//                           ))}
//                         </div>
//                       </div>
//                     )}
//                     {searchTerm ? (
//                       <div>
//                         <h2 style={{ fontSize: '18px', fontWeight: 'bold', color: '#333', marginBottom: '15px' }}>Search Results</h2>
//                         <div style={{
//                           display: 'grid',
//                           gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
//                           gap: '20px'
//                         }}>
//                           {filteredProducts.map((product, index) => (
//                             <ProductCard
//                               key={index}
//                               product={product}
//                               index={index}
//                               handleDecrement={handleDecrement}
//                               handleIncrement={handleIncrement}
//                               getProductQuantity={getProductQuantity}
//                               handleVariationChange={handleVariationChange}
//                               tempCart={tempCart}
//                             />
//                           ))}
//                         </div>
//                       </div>
//                     ) : (
                    //   categories.map((category) => (
                    //     <div
                    //       key={category?._id}
                    //       ref={(el) => categoryRefs.current[category?._id] = el}
                    //       style={{ marginBottom: '30px' }}
                    //     >
                    //       <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: '#333', marginBottom: '10px' }}>{category?.Name}</Typography>
                    //       <div style={{
                    //         display: 'grid',
                    //         gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
                    //         gap: '20px'
                    //       }}>
                    //         {categorizedProducts[category?._id]?.map((product, index) => (
                    //           <ProductCard
                    //             key={index}
                    //             product={product}
                    //             index={index}
                    //             handleDecrement={handleDecrement}
                    //             handleIncrement={handleIncrement}
                    //             getProductQuantity={getProductQuantity}
                    //             handleVariationChange={handleVariationChange}
                    //             tempCart={tempCart}
                    //           />
                    //         ))}
                    //       </div>
                    //     </div>
                    //   ))
                    // )}
//                   </>
//                 </main>
//               </>
//             </CardBody>
//           </Card>
//         </Col>
//       </div>
//     </div>

//     <Snackbar
//       open={openSnackbar}
//       autoHideDuration={6000}
//       onClose={handleCloseSnackbar}
//       anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
//     >
//       <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
//         Please select at least one item.
//       </Alert>
//     </Snackbar>
//     <Snackbar
//       open={openSnackbar1}
//       autoHideDuration={6000}
//       onClose={handleCloseSnackbar1}
//       anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
//     >
//       <Alert onClose={handleCloseSnackbar1} severity="success" sx={{ width: '100%' }}>
//         Proceed to cart.
//       </Alert>
//     </Snackbar>

  
//   </div >
// </div >
// </Fragment >