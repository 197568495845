import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, Navigate } from "react-router-dom";
import { Row, Col, FormGroup, Input, Button, Label, Modal, ModalHeader, ModalBody, Card, CardBody } from "reactstrap";
import Services from "./services";
import AuthService from "../Authentication/auth.service";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import Alerts from "../../components/Alerts/alerts";
import PermissionAccess from '../../PermissionAccess/Access.js';

const UpdateStock = ({ stock, onSuccess, onCancel, warehouses }) => {
  const [modal, setModal] = useState(true);
  const [Stock, setStock] = useState({ ...stock });
  const [loading, setLoading] = useState(false);
  const [loginNavigate, setLoginNavigate] = useState(false);
  const { authData } = PermissionAccess();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
  }, [i18n]);

  const handleStockChange = (field, value) => {
    setStock(prev => ({ ...prev, [field]: value }));
  };

  const validateForm = () => {
    return Stock.item_code &&
      Stock.batch_number &&
      Stock.quantity
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      Alerts.swalErrorAlert("Please fill all fields for the Stock.");
      return;
    }

    const formData = { ...Stock };

    setLoading(true);
    try {
      const response = await Services.UpdateStock(authData.sessionID, Stock._id, formData);
      setLoading(false);
      if (response.data.status) {
        onSuccess();
        Alerts.swalSuccessAlert(response.data.message || "Stock Updated Successfully.").then(() => {
          setModal(false);
        });
      } else {
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          setLoginNavigate(true);
        } else {
          Alerts.swalErrorAlert(response.data.message || "Error: Can't Update Stock at the moment. Please try again later.");
        }
      }
    } catch (error) {
      setLoading(false);
      Alerts.swalErrorAlert("Network Operation failed.");
    }
  };

  if (loginNavigate) {
    return <Navigate to={'/login'} />;
  }

  return (
    <Modal size='lg' isOpen={modal} toggle={() => setModal(false)} backdrop={true}>
      <LoadingOverlay active={loading} spinner={<DNALoader />}>
        <ModalHeader>{t("Update Stock")}</ModalHeader>
        <ModalBody>
          <Card className="main-card mb-3">
            <CardBody>
              <form onSubmit={handleSubmit}>
                <FormGroup className="mb-3">
                  <Row>
                    <Col md="12">
                      <Label>{t("Model Name")}</Label>
                      <Input
                        type="text"
                        disabled
                        value={Stock.item_code}
                        onChange={(e) => handleStockChange('item_code', e.target.value)}
                        placeholder={t("Enter Model Name")}
                      />
                    </Col>
                    <Col style={{ marginTop: '15px' }} md="12">
                      <Label>{t("Batch Number")}</Label>
                      <Input
                        type="text"
                        value={Stock.batch_number}
                        onChange={(e) => handleStockChange('batch_number', e.target.value)}
                        placeholder={t("Enter Batch Number")}
                      />
                    </Col>
                    <Col style={{ marginTop: '15px' }} md="12">
                      <Label>{t("Quantity")}</Label>
                      <Input
                        type="number"
                        value={Stock.quantity}
                        onChange={(e) => handleStockChange('quantity', e.target.value)}
                        placeholder={t("Enter Quantity")}
                      />
                    </Col>
                  </Row>
                  <div className="text-center mt-3">
                    <Button type="submit" color="success" size="lg" className="me-3">
                      {t("Update")}
                    </Button>
                    <Button type="button" color="secondary" size="lg" onClick={() => { setModal(false); onCancel(); }}>
                      {t("Cancel")}
                    </Button>
                  </div>
                </FormGroup>
              </form>
            </CardBody>
          </Card>
        </ModalBody>
      </LoadingOverlay>
    </Modal>
  );
};

export default UpdateStock;