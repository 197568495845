import React, { useState, useEffect } from "react";

const Clock = ({ timezone }) => {
  const [currentTime, setCurrentTime] = useState("");
  const [currentDate, setCurrentDate] = useState("");
  const [timezoneName, setTimezoneName] = useState("");

  useEffect(() => {
    const updateTime = () => {
      const now = new Date();

      // Format Time
      const time = new Intl.DateTimeFormat("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        timeZone: timezone,
        hour12: true,
      }).format(now);

      // Format Date
      const date = new Intl.DateTimeFormat("en-US", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        timeZone: timezone,
      }).format(now);

      // Get Timezone Name
      const tzName = new Intl.DateTimeFormat("en-US", {
        timeZoneName: "short",
        timeZone: timezone,
      }).formatToParts(now).find((part) => part.type === "timeZoneName")?.value;

      setCurrentTime(time);
      setCurrentDate(date);
      setTimezoneName(tzName || timezone);
    };

    updateTime();
    const interval = setInterval(updateTime, 1000);
    return () => clearInterval(interval);
  }, [timezone]);

  return (
    <div style={styles.clockContainer}>
      <div style={styles.date}>{currentDate}</div>
      <div style={styles.time}>{currentTime}</div>
      {/* <div style={styles.timezone}>{timezoneName}</div> */}
    </div>
  );
};

const styles = {
  clockContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "transparent",
    boxShadow: "none",
    minWidth: "220px", // Adjusted for smaller size
    margin: "10px",
  },
  date: {
    fontSize: "1rem", // Smaller font size for date
    fontWeight: "400",
    color: "#6c757d", // Subtle gray for the date
    marginBottom: "5px",
  },
  time: {
    fontSize: "1.5rem", // Slightly smaller clock size
    fontWeight: "600",
    color: "#343a40",
    letterSpacing: "1px",
    textShadow: "1px 1px 2px rgba(0, 0, 0, 0.2)",
  },
  timezone: {
    fontSize: "0.9rem",
    fontWeight: "400",
    color: "#6c757d",
    marginTop: "5px",
  },
};

export default Clock;
