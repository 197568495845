import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";
import AlertService from "../../components/Alerts/alerts";
import {
  Typography, Grid, Paper, Box, Table, TableBody, TableCell, TableContainer, TableRow,
  Card, CardContent, Divider, Chip, useTheme, useMediaQuery
} from '@mui/material';
import { styled } from '@mui/material/styles';
import BusinessIcon from '@mui/icons-material/Business';
import DescriptionIcon from '@mui/icons-material/Description';
import EventNoteIcon from '@mui/icons-material/EventNote';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PersonIcon from '@mui/icons-material/Person';
import WorkIcon from '@mui/icons-material/Work';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Services from "./services.js";
import PermissionAccess from '../../PermissionAccess/Access.js';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.paper,
  transition: 'box-shadow 0.3s ease-in-out',
  '&:hover': {
    boxShadow: theme.shadows[4],
  },
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  marginBottom: theme.spacing(2),
  color: theme.palette.primary.main,
}));

const InfoRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
}));

const InfoLabel = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  marginRight: theme.spacing(1),
  minWidth: 120,
  color: theme.palette.text.secondary,
}));

const InfoValue = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const MemberDetailsComponent = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const { state: Member } = location;
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const { authData } = PermissionAccess();

  useEffect(() => {
    const fetchUserInfo = async () => {
      setLoading(true);
      try {
        let payload = {
          functionName: 'getUserInfo',
          postData: {
            user_id: Member._id,
          }
        }
        const response = await Services.getUserInfo(payload, authData.sessionID);
        if (response.data.status) {
          setUserInfo(response?.data?.data?.data?.[0]?.companyDetails);
        } else {
          AlertService.swalErrorAlert("Error fetching user information");
        }
      } catch (error) {
        AlertService.swalErrorAlert("Network operation failed");
      } finally {
        setLoading(false);
      }
    };

    fetchUserInfo();
  }, [Member._id, authData.sessionID]);

  const handleAddressClick = () => {
    if (userInfo?.businessAddress) {
      const addressText = encodeURIComponent(userInfo.businessAddress);
      const mapsURL = `https://www.google.com/maps/search/?api=1&query=${addressText}`;
      window.open(mapsURL, '_blank', 'noopener,noreferrer');
    } else {
      AlertService.swalErrorAlert("No valid address available");
    }
  };

  const renderInfoSection = (title, icon, content) => (
    <InfoRow>
      <Box display="flex" alignItems="center" width={isMobile ? "100%" : "auto"}>
        {icon}
        <InfoLabel variant="body2">{title}:</InfoLabel>
      </Box>
      <InfoValue variant="body2">{content}</InfoValue>
    </InfoRow>
  );

  const renderDetailedInfo = () => (
    userInfo ? (
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <StyledCard>
            <StyledCardContent>
              <SectionTitle variant="h6">Company Information</SectionTitle>
              {renderInfoSection("Company Name", <BusinessIcon color="primary" sx={{ mr: 1 }} />, userInfo.companyName)}
              {renderInfoSection("Business Reg. No.", <DescriptionIcon color="primary" sx={{ mr: 1 }} />, userInfo.businessRegNo)}
              {renderInfoSection("Company Reg. Date", <EventNoteIcon color="primary" sx={{ mr: 1 }} />, new Date(userInfo.companyRegDate).toLocaleDateString())}
              {renderInfoSection("Type of Business", <WorkIcon color="primary" sx={{ mr: 1 }} />, userInfo.typeOfBusiness)}
              {renderInfoSection("Business Structure", <AccountBalanceIcon color="primary" sx={{ mr: 1 }} />, userInfo.businessStructure)}
            </StyledCardContent>
          </StyledCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledCard>
            <StyledCardContent>
              <SectionTitle variant="h6">Contact Information</SectionTitle>
              {renderInfoSection("Business Address", <LocationOnIcon color="primary" sx={{ mr: 1 }} />, 
                <Box component="span" onClick={handleAddressClick} style={{ cursor: 'pointer', color: theme.palette.primary.main }}>
                  {userInfo.businessAddress}
                </Box>
              )}
              {renderInfoSection("Billing Address", <LocationOnIcon color="primary" sx={{ mr: 1 }} />, userInfo.billingAddress)}
              {renderInfoSection("Telephone No.", <PhoneIcon color="primary" sx={{ mr: 1 }} />, userInfo.telephoneNo)}
              {renderInfoSection("Fax No.", <PhoneIcon color="primary" sx={{ mr: 1 }} />, userInfo.faxNo)}
              {renderInfoSection("Email Address", <EmailIcon color="primary" sx={{ mr: 1 }} />, userInfo.emailAddress)}
            </StyledCardContent>
          </StyledCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledCard>
            <StyledCardContent>
              <SectionTitle variant="h6">Account Person Details</SectionTitle>
              {renderInfoSection("Name", <PersonIcon color="primary" sx={{ mr: 1 }} />, userInfo.accountPersonName)}
              {renderInfoSection("Position", <WorkIcon color="primary" sx={{ mr: 1 }} />, userInfo.accountPersonPosition)}
              {renderInfoSection("Telephone", <PhoneIcon color="primary" sx={{ mr: 1 }} />, userInfo.accountPersonTelephone)}
              {renderInfoSection("Email", <EmailIcon color="primary" sx={{ mr: 1 }} />, userInfo.accountPersonEmail)}
            </StyledCardContent>
          </StyledCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledCard>
            <StyledCardContent>
              <SectionTitle variant="h6">Applicant Details</SectionTitle>
              {renderInfoSection("Name", <PersonIcon color="primary" sx={{ mr: 1 }} />, userInfo.applicantName)}
              {renderInfoSection("Position", <WorkIcon color="primary" sx={{ mr: 1 }} />, userInfo.applicantPosition)}
              {renderInfoSection("Telephone", <PhoneIcon color="primary" sx={{ mr: 1 }} />, userInfo.applicantTelephone)}
              {renderInfoSection("Email", <EmailIcon color="primary" sx={{ mr: 1 }} />, userInfo.applicantEmail)}
            </StyledCardContent>
          </StyledCard>
        </Grid>
        <Grid item xs={12}>
          <StyledCard>
            <StyledCardContent>
              <SectionTitle variant="h6">Financial Information</SectionTitle>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  {renderInfoSection("Paid Up Capital", <AttachMoneyIcon color="primary" sx={{ mr: 1 }} />, userInfo.paidUpCapital)}
                  {renderInfoSection("Expected Monthly Purchases", <AttachMoneyIcon color="primary" sx={{ mr: 1 }} />, userInfo.expectedMonthlyPurchases)}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {renderInfoSection("Credit Terms Required", <DescriptionIcon color="primary" sx={{ mr: 1 }} />, userInfo.creditTermsRequired)}
                  {renderInfoSection("Credit Limit Required", <AttachMoneyIcon color="primary" sx={{ mr: 1 }} />, userInfo.creditLimitRequired)}
                </Grid>
              </Grid>
            </StyledCardContent>
          </StyledCard>
        </Grid>
        <Grid item xs={12}>
          <StyledCard>
            <StyledCardContent>
              <SectionTitle variant="h6">Documents & Bank References</SectionTitle>
              <Box mb={2}>
                <Typography variant="subtitle2" gutterBottom>Documents Attached:</Typography>
                {userInfo.documentsAttached.map((doc, index) => (
                  <StyledChip key={index} label={doc} color="primary" variant="outlined" />
                ))}
              </Box>
              <Typography variant="subtitle2" gutterBottom>Bank References:</Typography>
              <TableContainer component={Paper} elevation={0}>
                <Table size="small">
                  <TableBody>
                    {userInfo.bankReferences.map((bank, index) => (
                      <React.Fragment key={index}>
                        <TableRow>
                          <TableCell><strong>Name & Branch:</strong></TableCell>
                          <TableCell>{bank.name}, {bank.branch}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell><strong>Account Number:</strong></TableCell>
                          <TableCell>{bank.accountNumber}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell><strong>Banking Facility:</strong></TableCell>
                          <TableCell>{bank.bankingFacility}</TableCell>
                        </TableRow>
                        {index < userInfo.bankReferences.length - 1 && (
                          <TableRow>
                            <TableCell colSpan={2}><Divider /></TableCell>
                          </TableRow>
                        )}
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </StyledCardContent>
          </StyledCard>
        </Grid>
      </Grid>
    ) : (
      <Typography variant="body1">No detailed information available.</Typography>
    )
  );

  return (
    <LoadingOverlay
      active={loading}
      styles={{ overlay: (base) => ({ ...base }) }}
      spinner={<DNALoader />}
    >
      <Box sx={{ p: 3 }}>
        {renderDetailedInfo()}
      </Box>
    </LoadingOverlay>
  );
};

export default MemberDetailsComponent;