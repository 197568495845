import React, { useState, useEffect } from 'react';
import bg from './bg.png';

const SliderComponent = () => {
  const slides = [
    { id: 1, text: "Unified Invoicing Process and Efficiency Gains", backgroundImage: bg },
    { id: 2, text: "Seamless Tax Return Filing", backgroundImage: bg },
    { id: 3, text: "Operational Streaming for Larger Business", backgroundImage: bg },
    { id: 4, text: "Progressive Transition for MSMEs", backgroundImage: bg },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const slideInterval = setInterval(nextSlide, 3000); // Auto-move slides every 3 seconds
    return () => clearInterval(slideInterval);
  }, []);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  return (
    <div style={sliderStyles.container}>
      <div
        style={{
          ...sliderStyles.slide,
          backgroundImage: `url(${slides[currentSlide].backgroundImage})`,
        }}
      >
        <div style={sliderStyles.overlay}>
          <h2 style={sliderStyles.text}>{slides[currentSlide].text}</h2>
          <div style={sliderStyles.dotsContainer}>
            {slides.map((_, index) => (
              <div
                key={index}
                onClick={() => setCurrentSlide(index)}
                style={{
                  ...sliderStyles.dot,
                  backgroundColor: index === currentSlide ? '#fff' : '#aaa',
                }}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const sliderStyles = {
  container: {
    position: 'relative',
    width: '100%',
    padding:'3%',
    height: '100%',
  },
  slide: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  overlay: {
    width: '100%',
    height: '100%',
    borderRadius:'3%',
    border:'1px solid white',
    backgroundColor: 'rgba(72, 132, 194, 0.5)', 
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: '50px',
  },
  text: {
    color: 'white',
    fontSize: '34px',
    fontWeight: 'bold',
    textAlign: 'left',
    paddingLeft: '10px',
    paddingBottom:'90%',
  },
  dotsContainer: {
    position: 'absolute',
    bottom: '20%',
    left: '15%',
    transform: 'translateX(-50%)',
    display: 'flex',
    gap: '10px',
  },
  dot: {
    width: '20px',
    height: '10px',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};

export default SliderComponent;
