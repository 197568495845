import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';
import { faStar as fasStar } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const ReviewModal = ({ item, onClose, onSubmit }) => {
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const [modalHeight, setModalHeight] = useState('auto');
  const modalContentRef = useRef(null);

  useEffect(() => {
    const updateModalHeight = () => {
      if (modalContentRef.current) {
        const windowHeight = window.innerHeight;
        const modalContent = modalContentRef.current;
        const modalContentHeight = modalContent.scrollHeight;
        
        if (modalContentHeight > windowHeight * 0.9) {
          setModalHeight(`${windowHeight * 0.9}px`);
        } else {
          setModalHeight('auto');
        }
      }
    };

    updateModalHeight();
    window.addEventListener('resize', updateModalHeight);

    return () => window.removeEventListener('resize', updateModalHeight);
  }, []);

  const handleSubmit = () => {
    onSubmit({ rating, comment });
    onClose();
  };

  return (
    <div style={styles.modalOverlay}>
      <div style={{...styles.modalContent, height: modalHeight}} ref={modalContentRef}>
        <div style={styles.modalHeader}>
          <h2>Review Product</h2>
          <button onClick={onClose} style={styles.closeButton}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <div style={styles.scrollableContent}>
          <div style={styles.productInfo}>
            <img src={item.image} alt={item.name} style={styles.productImage} />
            <h3>{item.name}</h3>
          </div>
          <div style={styles.ratingContainer}>
            {[1, 2, 3, 4, 5].map((star) => (
              <FontAwesomeIcon
                key={star}
                icon={star <= rating ? fasStar : farStar}
                style={styles.star}
                onClick={() => setRating(star)}
              />
            ))}
          </div>
          <textarea
            placeholder="Write your comments here"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            style={styles.commentBox}
          />
          <button onClick={handleSubmit} style={styles.submitButton}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

const styles = {
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  modalContent: {
    backgroundColor: 'white',
    borderRadius: '8px',
    width: '90%',
    maxWidth: '500px',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '90vh',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px',
    borderBottom: '1px solid #eee',
  },
  scrollableContent: {
    overflowY: 'auto',
    padding: '20px',
    flexGrow: 1,
  },
  closeButton: {
    background: 'none',
    border: 'none',
    fontSize: '20px',
    cursor: 'pointer',
  },
  productInfo: {
    textAlign: 'center',
    marginBottom: '20px',
  },
  productImage: {
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    objectFit: 'cover',
    marginBottom: '10px',
  },
  ratingContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  star: {
    color: '#FFD700',
    fontSize: '24px',
    cursor: 'pointer',
    margin: '0 5px',
  },
  commentBox: {
    width: '100%',
    height: '100px',
    padding: '10px',
    marginBottom: '20px',
    borderRadius: '5px',
    border: '1px solid #ddd',
  },
  submitButton: {
    width: '100%',
    padding: '10px',
    backgroundColor: 'black',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};

export default ReviewModal;