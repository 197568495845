import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';
import styled from "styled-components";
import BusinessService from "../../pages/Business/Businesses.service";
import AuthService from "../../pages/Authentication/auth.service";
import Alerts from "../../components/Alerts/alerts";
import { useNavigate } from 'react-router-dom';
import PermissionAccess from '../../PermissionAccess/Access.js';
import moment from 'moment';
import { Calendar } from 'lucide-react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const Input = styled.input`
  height: 32px;
  width: 300px;
  border-radius: 5px;
  border: 1px solid var(--buttons-color, #30b1ff);
  padding: 0 32px 0 16px;
`;

const Select = styled.select`
  height: 32px;
  width: 200px;
  border-radius: 5px;
  border: 1px solid var(--buttons-color, #30b1ff);
  margin-left: 10px;
  padding: 0 16px;
`;

const Button = styled.button`
  margin-left: 2%;
  padding: 6px 12px;
  background-color: ${props => props.primary ? '#ffc107' : '#6c757d'};
  color: ${props => props.primary ? 'black' : 'white'};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${props => props.primary ? '#e0a800' : '#5a6268'};
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  z-index: 1000;
`;

const DateRangeContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const CalendarIcon = styled(Calendar)`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
`;

const DatePresetDropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  z-index: 1001;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const DatePresetOption = styled.div`
  padding: 8px 16px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const CustomDatePickerWrapper = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1002;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const SettlementReportFilter = ({ onClear, onSearch }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [business, setBusiness] = useState("");
  const [businesses, setBusinesses] = useState([]);
  const [dateRange, setDateRange] = useState("");
  const [showDatePresets, setShowDatePresets] = useState(false);
  const [showCustomDatePicker, setShowCustomDatePicker] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const { authData } = PermissionAccess();
  const dateInputRef = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    // Set default date range (Last 30 Days)
    const end = moment().format('YYYY/MM/DD');
    const start = moment().subtract(29, 'days').format('YYYY/MM/DD');
    setDateRange(`${start} - ${end}`);
    setStartDate(moment().subtract(29, 'days').toDate());
    setEndDate(moment().toDate());

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !dateInputRef.current.contains(event.target)) {
        setShowDatePresets(false);
        setShowCustomDatePicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (businesses.length === 1) {
      setBusiness(businesses[0]._id);
    }
  }, [businesses]);

  const handleClear = () => {
    setBusiness('');
    const end = moment().format('YYYY/MM/DD');
    const start = moment().subtract(29, 'days').format('YYYY/MM/DD');
    setDateRange(`${start} - ${end}`);
    setStartDate(moment().subtract(29, 'days').toDate());
    setEndDate(moment().toDate());
    onClear();
  };

  const handleSearch = () => {
    onSearch(business, dateRange);
  };

  const handleDateRangeChange = (preset) => {
    let start, end;
    switch (preset) {
      case 'today':
        start = end = moment().format('YYYY/MM/DD');
        setStartDate(moment().toDate());
        setEndDate(moment().toDate());
        break;
      case 'yesterday':
        start = end = moment().subtract(1, 'days').format('YYYY/MM/DD');
        setStartDate(moment().subtract(1, 'days').toDate());
        setEndDate(moment().subtract(1, 'days').toDate());
        break;
      case 'last7Days':
        start = moment().subtract(6, 'days').format('YYYY/MM/DD');
        end = moment().format('YYYY/MM/DD');
        setStartDate(moment().subtract(6, 'days').toDate());
        setEndDate(moment().toDate());
        break;
      case 'last30Days':
        start = moment().subtract(29, 'days').format('YYYY/MM/DD');
        end = moment().format('YYYY/MM/DD');
        setStartDate(moment().subtract(29, 'days').toDate());
        setEndDate(moment().toDate());
        break;
      case 'thisMonth':
        start = moment().startOf('month').format('YYYY/MM/DD');
        end = moment().endOf('month').format('YYYY/MM/DD');
        setStartDate(moment().startOf('month').toDate());
        setEndDate(moment().endOf('month').toDate());
        break;
      case 'lastMonth':
        start = moment().subtract(1, 'month').startOf('month').format('YYYY/MM/DD');
        end = moment().subtract(1, 'month').endOf('month').format('YYYY/MM/DD');
        setStartDate(moment().subtract(1, 'month').startOf('month').toDate());
        setEndDate(moment().subtract(1, 'month').endOf('month').toDate());
        break;
      case 'thisYear':
        start = moment().startOf('year').format('YYYY/MM/DD');
        end = moment().endOf('year').format('YYYY/MM/DD');
        setStartDate(moment().startOf('year').toDate());
        setEndDate(moment().endOf('year').toDate());
        break;
      case 'lastYear':
        start = moment().subtract(1, 'year').startOf('year').format('YYYY/MM/DD');
        end = moment().subtract(1, 'year').endOf('year').format('YYYY/MM/DD');
        setStartDate(moment().subtract(1, 'year').startOf('year').toDate());
        setEndDate(moment().subtract(1, 'year').endOf('year').toDate());
        break;
      case 'custom':
        setShowCustomDatePicker(true);
        setShowDatePresets(false);
        return;
      default:
        return;
    }
    setDateRange(`${start} - ${end}`);
    setShowDatePresets(false);
  };

  const handleCustomDateChange = (date, isStart) => {
    if (isStart) {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
    
    if (startDate && endDate) {
      const formattedStart = moment(startDate).format('YYYY/MM/DD');
      const formattedEnd = moment(endDate).format('YYYY/MM/DD');
      setDateRange(`${formattedStart} - ${formattedEnd}`);
    }
  };

  const applyCustomDateRange = () => {
    if (startDate && endDate) {
      const formattedStart = moment(startDate).format('YYYY/MM/DD');
      const formattedEnd = moment(endDate).format('YYYY/MM/DD');
      setDateRange(`${formattedStart} - ${formattedEnd}`);
      setShowCustomDatePicker(false);
    }
  };

  function fetchBusinesses() {
    const stored_user = authData.userData;
    let business = [];
    if (stored_user.businesses) {
      business = stored_user.businesses;
    }

    BusinessService.getAllBusinesses(authData.sessionID, 0, 9999999, business).then(
      (response) => {
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        } else {
          setBusinesses(response.data.data);
        }
      },
      (error) => {
        const errContent =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        if (errContent) {
          Alerts.swalErrorAlert(errContent);
        } else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."));
        }
      }
    );
  }

  useEffect(() => {
    fetchBusinesses();
  }, []);

  return (
    <Container>
      <Select
        value={business}
        onChange={(e) => setBusiness(e.target.value)}
      >
        <option value="">{t('Select Business')}</option>
        {businesses.length > 0 ? (
          businesses.map((row) => (
            <option key={row._id} value={row._id}>{row.name}</option>
          ))
        ) : (
          <option value="">{t('No business available')}</option>
        )}
      </Select>

      <DateRangeContainer>
        <Input
          ref={dateInputRef}
          value={dateRange}
          onChange={(e) => setDateRange(e.target.value)}
          onClick={() => setShowDatePresets(!showDatePresets)}
          placeholder="Select date range"
        />
        <CalendarIcon size={20} />
        {showDatePresets && (
          <DatePresetDropdown ref={dropdownRef}>
            <DatePresetOption onClick={() => handleDateRangeChange('today')}>{t('Today')}</DatePresetOption>
            <DatePresetOption onClick={() => handleDateRangeChange('yesterday')}>{t('Yesterday')}</DatePresetOption>
            <DatePresetOption onClick={() => handleDateRangeChange('last7Days')}>{t('Last 7 Days')}</DatePresetOption>
            <DatePresetOption onClick={() => handleDateRangeChange('last30Days')}>{t('Last 30 Days')}</DatePresetOption>
            <DatePresetOption onClick={() => handleDateRangeChange('thisMonth')}>{t('This Month')}</DatePresetOption>
            <DatePresetOption onClick={() => handleDateRangeChange('lastMonth')}>{t('Last Month')}</DatePresetOption>
            <DatePresetOption onClick={() => handleDateRangeChange('thisYear')}>{t('This Year')}</DatePresetOption>
            <DatePresetOption onClick={() => handleDateRangeChange('lastYear')}>{t('Last Year')}</DatePresetOption>
            <DatePresetOption onClick={() => handleDateRangeChange('custom')}>{t('Custom Range')}</DatePresetOption>
          </DatePresetDropdown>
        )}
        {showCustomDatePicker && (
          <CustomDatePickerWrapper>
            <DatePicker
              selected={startDate}
              onChange={(date) => handleCustomDateChange(date, true)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              inline
            />
            <DatePicker
              selected={endDate}
              onChange={(date) => handleCustomDateChange(date, false)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              inline
            />
            <Button onClick={applyCustomDateRange}>{t('Apply')}</Button>
          </CustomDatePickerWrapper>
        )}
      </DateRangeContainer>

      <Button primary onClick={handleSearch}>{t('Search')}</Button>
      <Button primary onClick={handleClear}>{t('Reset')}</Button>
    </Container>
  );
};

export default SettlementReportFilter;