import axios from "axios";

const API_URL = process.env.REACT_APP_BASEURL;
const B_URL = process.env.REACT_APP_LOGINURL



const headers = {
  'Content-Type': 'application/json',
  'token': localStorage.getItem("session-id")
}


const getAll = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(API_URL, payload, { headers });
};

const getInvoices = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  console.log("getInvoices", getInvoices)
  return axios.post(B_URL + 'Request_E_InvoiceModule/' + payload.functionName, payload, { headers });
};


const getUserInvoices = (payload) => {
  return axios.post(B_URL + 'OrderModule/getUserInvoices', payload, { headers });
};

const Get_User_Invoices = (page, limit, business, user_id) => {
  var payload = {
    functionName: "Get_User_Invoices",
    postData: {
      filter: {},
      limit: limit,
      offset: page,
      user_id: user_id,
      business: business
    }
  }
  console.log("Get_User_Invoices", payload);
  return axios.post(B_URL + "E_invoiceFetchingModule/" + payload.functionName, payload, { headers });
};



const getAllStatements = (page, limit, filter) => {
  var payload = {
    functionName: "User_Record_Dashboard",
    Customer_ID: filter,
    limit: limit,
    offset: page
  }
  return axios.post(B_URL + "statements", payload, { headers });
};

const getAllBusiness = () => {
  var payload = {
    functionName: "GetBusinesses",
    postData: {
      limit: 9999999,
      offset: 0,
      business_ids: []
    }
  }
  return axios.post(B_URL + "BusinessModule/GetBusinesses", payload, { headers });
};

const getAllCategories = (page, limit, business_id) => {
  var payload = {
    functionName: "GetCategories",
    postData: {
      limit: limit,
      offset: page,
      business_id: business_id
    }
  }
  console.log("payload", payload);
  return axios.post(B_URL + "CategoryModule/GetCategories", payload, { headers });
};

const getAllProducts = (page, limit, Category, Branch) => {
  var payload = {
    functionName: "GetCustomerItems",
    postData: {
      filter: {},
      limit: limit,
      offset: page,
      category: Category,
      business: Branch
    }
  }
  console.log("GetCustomerItems", payload);
  return axios.post(B_URL + "ItemsRoutes/GetCustomerItems", payload, { headers });
};



const GetDashboardData = (payload, sessionID) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + 'DashboardRoutes/GetDashboardData', payload, { headers });
};

const GetPackagesData = (sessionID) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + 'PackageModule/GetPackagesData', { headers });
};

const setCart = (payload) => {
  console.log("setCart", payload);
  return axios.post(B_URL + "CartModule/setCart", payload, { headers });
};
const GetCartList = (payload) => {
  console.log("GetCartList", payload);
  return axios.post(B_URL + "CartModule/GetCartList", payload, { headers });
};

const GetProducctDetails = (payload) => {
  console.log("GetProducctDetails", payload);
  return axios.post(B_URL + "ItemModule/SearchItem", payload, { headers });
};

const GeProductReviews = (payload) => {
  console.log("GeProductReviews", payload);
  return axios.post(B_URL + "OrderModule/ProductReviews", payload, { headers });
};

const DeleteCart = (payload) => {
  console.log("DeleteCart", payload);
  return axios.post(B_URL + "CartModule/DeleteCart", payload, { headers });
};

const PlaceOrder = (payload) => {
  console.log("PlaceOrder", payload);
  return axios.post(B_URL + "CartModule/PlaceOrder", payload, { headers });
};

const AddTopUp = (payload) => {
  console.log("AddTopUp", payload);
  return axios.post(B_URL + "TopUpModule/AddTopUp", payload, { headers });
};

const GetTopUp = (payload) => {
  console.log("GetTopUp", payload);
  return axios.post(B_URL + "TopUpModule/GetTopUp", payload, { headers });
};
const GetReviewData = (payload) => {
  console.log("GetReviewData", payload);
  return axios.post(B_URL + "ReviewModule/GetReviewData", payload, { headers });
};

const GetOrdersProductForReview = (payload) => {
  console.log("GetOrdersProductForReview", payload);
  return axios.post(B_URL + "OrderModule/GetOrdersProductForReview", payload, { headers });
};

const MyReviews = (payload) => {
  console.log("MyReviews", payload);
  return axios.post(B_URL + "OrderModule/MyReviews", payload, { headers });
};

const AddReview = (payload) => {
  console.log("AddReview", payload);
  return axios.post(B_URL + "OrderModule/AddReview", payload, { headers });
};

const GetRewards = (user_id) => {
  console.log("user_id", user_id);

  var payload = {
    "functionName": "GetRewardsHistory",
    "postData": {
      "user_id": user_id
    }
  }

  return axios.post(B_URL + "RewardModule/GetRewardsHistory", payload, { headers });
};

const RedeemPoints = (data) => {
  console.log("data", data);
  var payload = {
    "functionName": "RewardToPayment",
    "postData": {
      "points": data.points,
      "user_id": data.user_id
    }
  }

  return axios.post(B_URL + "RewardModule/RewardToPayment", payload, { headers });
};



const GetDashboardSummary = (sessionID, payload) => {
  console.log("GetDashboardSummary:-", payload);
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "ReportsModule/DashboardSummary", payload, { headers });
};


const uploadReceipt = (receipt, user_id, business_id) => {
  console.log("user_id", user_id);

  var payload = {
    "functionName": "processInvoice",
    "postData": {
      "user_id": user_id,
      "receipt": receipt,
      "business_id": business_id
    }
  }

  return axios.post(B_URL + "E_InvoiceModule/processInvoice", payload, { headers });
};

const getAllItems = (payload) => {
  return axios.post(B_URL + 'ItemsRoutes/' + payload.functionName, payload, { headers });
};


const saveRFQ = (payload) => {
  console.log("payload", payload);
  return axios.post(B_URL + "RFQModule/AddRFQ", payload, { headers });
};

const updateRFQ = (payload) => {
  console.log("payload", payload);
  return axios.put(B_URL + "RFQModule/updateRFQ", payload, { headers });
};

const ReturnRFQ = (payload) => {
  console.log("payload", payload);
  return axios.put(B_URL + "RFQModule/ReturnRFQ", payload, { headers });
};

const GetRFQs = (payload) => {
  console.log("payload", payload);
  return axios.post(B_URL + "RFQModule/GetRFQs", payload, { headers });
};


const GetBusinesses = (sessionID, payload) => {
  // console.log("DATA:", data);
  var payload = {
    "functionName": "GetBusinesses",
    "postData": {
      "offset": 0,
      "sort": {
        "_id": -1
      },
      "limit": 9999999999,
      "business_ids": []
    }
  }
  return axios.post(B_URL + "BusinessModule/GetBusinesses", payload, { headers });
};

const GetRFQDetails = (payload) => {
  console.log("payload", payload);
  return axios.post(B_URL + "RFQModule/GetRFQDetails", payload, { headers });
};

const AcceptRFQ = (payload) => {
  console.log("payload", payload);
  return axios.post(B_URL + "RFQModule/AcceptRFQ", payload, { headers });
};

const AcceptReturnItems = (payload) => {
  console.log("payload", payload);
  return axios.post(B_URL + "RFQModule/AcceptReturnItems", payload, { headers });
};

const RejectRFQ = (payload) => {
  console.log("payload", payload);
  return axios.post(B_URL + "RFQModule/RejectRFQ", payload, { headers });
};

const GetUserOrders = (payload) => {
  return axios.post(B_URL + "OrderModule/GetUserOrders", payload, { headers });
};

const OnlinePayment = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + "invoices", payload, { headers });
};

const getDashboardData = (_id, sessionID) => {
  let payload = {
    functionName: 'getDashboardData',
    postData: {
      _id: _id
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + 'RFQModule/getDashboardData', payload, { headers });
};


const getAdminDashboardData = (sessionID) => {
  let payload = {
    functionName: 'getAdminDashboardData',
    postData: {
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + 'RFQModule/getAdminDashboardData', payload, { headers });
};

const getActiveBanners = (sessionID) => {
  let payload = {
    functionName: 'getActiveBanners',
    postData: {
      "type": "Members_portal",
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + 'RFQModule/getActiveBanners', payload, { headers });
};


const getProducts = () => {
  var payload = {
    functionName: "GetCustomerItems",
    postData: {
      filter: {},
      limit: 999999,
      offset: 0,
      // category: Category,
      // business: Branch
    }
  }
  console.log("GetCustomerItems", payload);
  return axios.post(B_URL + "ItemsRoutes/GetCustomerItems", payload, { headers });
};

const AddPackageToUser = (sessionID, payload) => {
  console.log("AddPackageToUser", payload);
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "PackageModule/AddPackageToUser", payload, { headers });
};

const Update_User_Package = (sessionID, payload) => {
  console.log("Update_User_Package", payload);
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "PackageModule/Update_User_Package", payload, { headers });
};

const SubmitTopUp = (payload) => {
  return axios.post(B_URL + "TopUpModule/SubmitTopUp", payload, { headers });
};

const getStockistStock = (user_id) => {
  var payload = {
    functionName: "getStockistStock",
    postData: {
      user_id: user_id
    }
  }
  console.log("GetCustomerItems", payload);
  return axios.post(B_URL + "ItemsRoutes/getStockistStock", payload, { headers });
};


const getNanoStock = (user_id) => {
  var payload = {
    functionName: "getNanoStock",
    postData: {
      user_id: user_id
    }
  }
  console.log("GetCustomerItems", payload);
  return axios.post(B_URL + "ItemsRoutes/getNanoStock", payload, { headers });
};


const DashboardService = {
  GetReviewData,
  getStockistStock,
  SubmitTopUp,
  getNanoStock,
  AcceptRFQ,
  getProducts,
  AddPackageToUser,
  Update_User_Package,
  AcceptReturnItems,
  getActiveBanners,
  getDashboardData,
  getAdminDashboardData,
  RejectRFQ,
  ReturnRFQ,
  OnlinePayment,
  getUserInvoices,
  GetRFQDetails,
  getAll,
  GetBusinesses,
  GetRFQs,
  getAllItems,
  saveRFQ,
  updateRFQ,
  GetUserOrders,
  GetOrdersProductForReview,
  MyReviews,
  getInvoices,
  Get_User_Invoices,
  AddReview,
  AddTopUp,
  GetTopUp,
  uploadReceipt,
  PlaceOrder,
  DeleteCart,
  GetPackagesData,
  GetDashboardData,
  getAllBusiness,
  getAllCategories,
  getAllStatements,
  getAllProducts,
  GetCartList,
  GetProducctDetails,
  GeProductReviews,
  setCart,
  GetRewards,
  RedeemPoints,
  GetDashboardSummary

};

export default DashboardService;