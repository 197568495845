
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { Col, Row, Button, Label, FormGroup, Input, FormText } from "reactstrap";
import { Form, Field } from "react-final-form";
import { Box, TextField } from "@mui/material";
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import AuthService from "./auth.service";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faTimes, faFilePdf, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import loginLogo from '../../assets/loginLogo.svg';
import Swal from 'sweetalert2';
import { Container, Typography, useMediaQuery, useTheme } from "@mui/material";
import axios from 'axios';
import ConfigurationService from "../Configuration/configuration.service";
import FinanceService from "../Finance/Settings/configuration.service";
import Alerts from "../../components/Alerts/alerts";
import bg from './bg.png';

const Register = () => {
  localStorage.removeItem("resetpass");

  const { t, i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
  }, []);

  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState(localStorage.getItem("system_logo"));
  const navigate = useNavigate();

  const validateFtn = (values) => {
    const errors = {};
    if (!values.resetcode) {
      errors.resetcode = t("Reset Code Required.");
    }
    if (!values.password) {
      errors.password = t("New Password Required.");
    }
    if (!values.confirmpassword) {
      errors.confirmpassword = t("Confirm New Password Required.");
    }
    if (values.password !== values.confirmpassword) {
      errors.confirmpassword = t("Passwords Do Not Match.");
    }
    return errors;
  };

  const onSubmit = values => {
    setLoading(true);
    AuthService.resetpw(values.password, values.confirmpassword, values.resetcode).then((response) => {
      setLoading(false);
      if (response.data.status) {
        Alerts.swalSuccessAlert("Password is reset successfully. Please login again.")
        navigate("/user-login/Members");
        window.location.reload();
      } else {
        Alerts.swalErrorAlert(response.data.message)
      }
    },
      (error) => {
        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        setLoading(false);
        Alerts.swalErrorAlert(resMessage || t("Server Error, Please try again later."))
      });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <img src={loginLogo} alt="Logo" style={{ marginBottom: '100px', width: '30%', height: 'auto' }} />
      <Box
        sx={{
          maxWidth: 500,
          width: '100%',
          // background: '#f9f8e8' 
          backgroundColor: '#fff8dc',
          borderRadius: 8,
          padding: 4,
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          textAlign: 'center',
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
          {t('Reset Password')}
        </Typography>
        <Typography variant="body1" sx={{ color: '#666', marginBottom: 3 }}>
          {t('Use the form below to reset it.')}
        </Typography>
        <Form onSubmit={onSubmit} validate={validateFtn}
          render={({ handleSubmit, valid }) => (
            <form onSubmit={handleSubmit}>
              <LoadingOverlay active={loading} spinner={<DNALoader />}>
                <div className="modal-body p-5">
                  <Row form>
                    <Col md={12}>
                      <FormGroup>
                        <Field name="resetcode">
                          {({ input, meta }) => (
                            <Row className="align-items-center mb-3">
                              <Col md="12" style={{ textAlign: 'left', paddingRight: '10px' }}>
                                <Label for="email" style={{ fontWeight: 'bold', fontSize: '1rem' }}>
                                  {t('Reset Code')}
                                </Label>
                              </Col>
                              <Col md="12">
                                <Input {...input} type="text" style={{
                                  border: '1px solid #303030',
                                  background: '#F9F8E8',
                                }}
                                  invalid={meta.error && meta.touched} placeholder={t('Reset Code')} />
                                {meta.error && meta.touched && <span className="text-danger">{meta.error}</span>}
                              </Col>
                            </Row>
                          )}
                        </Field>
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup>
                        <Field name="password">
                          {({ input, meta }) => (
                            <Row className="align-items-center mb-3">
                              <Col md="12" style={{ textAlign: 'left', paddingRight: '10px' }}>
                                <Label for="email" style={{ fontWeight: 'bold', fontSize: '1rem' }}>
                                  {t('New Password')}
                                </Label>
                              </Col>
                              <Col md="12">
                                <Input {...input} type="password" style={{
                                  border: '1px solid #303030',
                                  background: '#F9F8E8',
                                }}
                                  invalid={meta.error && meta.touched} placeholder={t('New Password')} />
                                {meta.error && meta.touched && <span className="text-danger">{meta.error}</span>}
                              </Col>
                            </Row>
                          )}
                        </Field>
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup>
                        <Field name="confirmpassword">
                          {({ input, meta }) => (
                            <Row className="align-items-center mb-3">
                              <Col md="12" style={{ textAlign: 'left', paddingRight: '10px' }}>
                                <Label for="email" style={{ fontWeight: 'bold', fontSize: '1rem' }}>
                                  {t('Confirm New Password')}
                                </Label>
                              </Col>
                              <Col md="12">
                                <Input {...input} style={{
                                  border: '1px solid #303030',
                                  background: '#F9F8E8',
                                }}
                                  type="password" invalid={meta.error && meta.touched} placeholder={t('Confirm New Password')} />
                                {meta.error && meta.touched && <span className="text-danger">{meta.error}</span>}
                              </Col>
                            </Row>
                          )}
                        </Field>
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="d-grid mb-3">
                    <Button size="lg" className="btn-custom-color" type="submit" disabled={!valid}>
                      {t('Reset Password')}
                    </Button>
                  </div>
                  <div className="text-center">
                    <a href="/user-login/Members" style={styles.link}>{t('Sign in existing account')}</a>
                  </div>
                </div>
              </LoadingOverlay>
            </form>
          )}
        />
      </Box>
    </div>
  );
};


const styles = {
  container: {
    display: 'flex',
    height: '100vh',
  },
  leftContainer: {
    flex: 1,
    display: 'flex',
    marginTop: '3%',
    flexDirection: 'column',
    background: 'white',
    padding: '0 60px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  rightContainer: {
    flex: 2,
    backgroundImage: `url(${bg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
  },
  logoContainer: {
    marginBottom: '20px',
  },
  logo: {
    width: '180px',
    height: 'auto',
  },
  heading: {
    marginTop: '12%',
    fontSize: '28px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  logoText: {
    fontSize: '16px',
    color: '#666',
    marginBottom: '20px',
  },
  link: {
    color: '#007bff',
    textDecoration: 'none',
  },
};

export default Register;
