import axios from "axios";

const API_URL = process.env.REACT_APP_BASEURL;
const storeduser = JSON.parse(localStorage.getItem("user"));
const B_URL = process.env.REACT_APP_LOGINURL

const headers = {
  'Content-Type': 'application/json',
  'token': localStorage.getItem("session-id")
}

const getAll = (filter, sessionID) => {
  var payload = {
    functionName: "GetCollectionData",
    "postData": {
      "collection_name": "custom_fields_invoice",
      "filter": filter
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }


  return axios.post(API_URL, payload, { headers });
};

const deleteAttribute = (id) => {
  var payload = {
    functionName: "DeleteCollectionData",
    postData: {
      "_id": id,
      "collection_name": "custom_fields_invoice",
      "login_user_id": storeduser._id
    },
  }
  return axios.post(API_URL, payload, { headers });
};


const addAttribute = (payload, sessionID) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  console.log("Received addAttribute Payload:-", payload)
  return axios.post(API_URL, payload, { headers });
}



const ADD_TaxClass = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'invoices', payload, { headers });
};


const ADD_Reminders = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'invoices', payload, { headers });
};

const Update_Reminders = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'invoices', payload, { headers });
};

const ADD_PaymentMethod = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'payments', payload, { headers });
};

const Update_PaymentMethod = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'payments', payload, { headers });
};

const Update_TaxClass = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'invoices', payload, { headers });
};

const deleteClass = (_id) => {
  var payloadData = {
    functionName: "deleteClass",
    _id: _id,
  }

  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'invoices', payloadData, { headers });
};

const deleteReminder = (_id) => {
  var payloadData = {
    functionName: "deleteReminder",
    _id: _id,
  }

  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'invoices', payloadData, { headers });
};
const deletePaymentMethod = (_id) => {
  var payloadData = {
    functionName: "deletePaymentMethod",
    _id: _id,
  }

  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'payments', payloadData, { headers });
};



const getAllClasses = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'invoices', payload, { headers });
};


const getAllReminders = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'invoices', payload, { headers });
};

const getAllPaymentMethods = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'payments', payload, { headers });
};


const updateAttribute = (payload, sessionID) => {
  console.log("Received updateAttribute Payload:-", payload)
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }

  return axios.post(API_URL, payload, { headers });
}

const getAllCurrency = () => {
  var payload = {
    functionName: "getAllCurrency",
    postData: {
    }
  }
  console.log("CurrencyRoutes", payload);
  return axios.post(B_URL + "CurrencyRoutes/getAllCurrency", payload, { headers });
};

const ADD_Currency = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + "CurrencyRoutes/ADD_Currency", payload, { headers });
};

const Update_Currency = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + "CurrencyRoutes/Update_Currency", payload, { headers });
};


const deleteCurrency = (_id) => {
  var payloadData = {
    functionName: "deleteCurrency",
    _id: _id,
  }

  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + "CurrencyRoutes/deleteCurrency", payloadData, { headers });
};




const AttributesService = {
  ADD_TaxClass,
  Update_Currency,
  deleteCurrency,
  ADD_Currency,
  ADD_PaymentMethod,
  getAllCurrency,
  ADD_Reminders,
  Update_Reminders,
  Update_PaymentMethod,
  Update_TaxClass,
  getAll,
  getAllReminders,
  getAllClasses,
  deleteClass,
  deleteReminder,
  getAllPaymentMethods,
  deletePaymentMethod,
  deleteAttribute,
  addAttribute,
  updateAttribute
}

export default AttributesService;