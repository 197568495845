import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import { Card, CardBody } from "reactstrap";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";
import Alerts from "../../components/Alerts/alerts";
import ItemService from "./Item.service";
import AuthService from "../Authentication/auth.service";
import { useNavigate, useLocation } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { createGlobalStyle } from 'styled-components';
import PermissionAccess from '../../PermissionAccess/Access.js';
const Image_URL = process.env.REACT_APP_IMAGEURL;



const imageStyle = {
  width: 'auto',
  height: '200px',
  objectFit: 'cover',
  margin: '0px auto',
};

const slideStyle = {};

const customSliderStyles = `
  .slick-prev,
  .slick-next {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    z-index: 1;
  }

  .slick-prev:hover,
  .slick-next:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }

  .slick-prev {
    left: 10px;
  }

  .slick-next {
    right: 10px;
  }

  .slick-prev:before,
  .slick-next:before {
    font-size: 20px;
    line-height: 1;
    opacity: 0.75;
    color: white;
  }
`;

const GlobalStyle = createGlobalStyle`
  ${customSliderStyles}
`;

const DriverUpdationForm = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const { state } = location;
  const [loading, setLoading] = useState(false);
  const form = useRef();
  const [collectionlist, setcollectionlist] = useState([]);
  const [fieldlist, setfieldlist] = useState([]);
  const [item_name, setItem_name] = useState(state?.item_name);
  const [item_mpv, setItem_mpv] = useState(state?.item_mpv);
  const [item_code, setItem_code] = useState(state?.item_code);
  const [description, setDescription] = useState(state?.description);
  const [price, setPrice] = useState(state?.price);

  const [Size, setSize] = useState(state.Size);
  const [Color, setColor] = useState(state.Color);
  const [SPV, setSPV] = useState(state.SPV);
  const [DPV, setDPV] = useState(state.DPV);

  console.log("state?.images", state?.images)

  const [selectedimages, setSelectedimages] = useState(
    state?.images?.map(img => (typeof img === "string" ? `${Image_URL}${img}` : img)) || []
  );
    const { authData, accessPages, super_admin, current_module } = PermissionAccess();
  const [errors, setErrors] = useState({});
  const [categoryError, setCategoryError] = useState('');
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(state?.category || '');
  const [pre_params, setpre_params] = useState('');

  const stored_user = authData.userData;
  let business = []
  if (stored_user.businesses) {
    business = stored_user.businesses
  }
  console.log("business:", business);

  useEffect(() => {

    i18n.changeLanguage(localStorage.getItem('language'));
    fetchDynamicData();
    console.log(state)
  }, []);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedimages(prevImages => [...prevImages, ...files]); 
  };
  
  

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  };


  const sliderStyle = {
    width: '100%',
    maxWidth: '900px',
    margin: '0 auto',
  }

  const ImageSlideshow = ({ images, onRemove }) => {
    if (!images || images.length === 0) return null;
  
    const removeButtonStyle = {
      position: 'absolute',
      top: '10px',
      right: '10px',
      background: 'rgba(255, 0, 0, 0.7)',
      color: 'white',
      border: 'none',
      borderRadius: '50%',
      width: '30px',
      height: '30px',
      cursor: 'pointer',
      zIndex: 10,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '16px',
      fontWeight: 'bold',
    };
  
    const getImageUrl = (image) => {
      if (image instanceof File) {
        return URL.createObjectURL(image);
      }
      return image; // Already stored URL
    };
  
    return (
      <div style={{ width: '100%', maxWidth: '900px', margin: '0 auto', position: 'relative' }}>
        <Slider dots infinite speed={500} slidesToShow={1} slidesToScroll={1} autoplay autoplaySpeed={3000} pauseOnHover arrows>
          {images.map((image, index) => (
            <div key={index} style={{ position: 'relative' }}>
              <img src={getImageUrl(image)} alt={`Slide ${index + 1}`} style={{ width: 'auto', height: '200px', objectFit: 'cover', margin: '0px auto' }} />
              <button onClick={() => onRemove(index)} style={removeButtonStyle}>X</button>
            </div>
          ))}
        </Slider>
      </div>
    );
  };
  



  const removeImage = (index) => {
    setSelectedimages(prevImages => prevImages.filter((_, i) => i !== index));
  };
  

  const fetchDynamicData = async () => {
    try {
      const response = await ItemService.get_cruds_dynamic_data(authData.sessionID);
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      } else {
        const data = await response.data;
        var custom_fields = data;
        for (var i = 0; i < custom_fields.length; i++) {
          if (custom_fields[i].options_collection !== '') {
            const _id = authData.userData._id
            const optresponse = await ItemService.getoptions(custom_fields[i].options_collection, "", {}, 5000, authData.sessionID, _id);
            const optdata = await optresponse.data.data;
            var options = [];
            for (var j = 0; j < optdata.length; j++) {
              options.push({ label: optdata[j][custom_fields[i].Options_Field_Name], value: optdata[j]._id, business_id: optdata[j]?.business_id ? optdata[j]?.business_id : null })
            }
            custom_fields[i].options = options;
          }
          if (custom_fields[i].parent_col === pre_params.Parent_referal_name) {
            custom_fields[i].default_value = pre_params.Parentid
          } else {
            custom_fields[i].default_value = ""
          }
        }
        data[0].custom_fields = custom_fields;
        setcollectionlist(data[0]);
        setfieldlist(data[0].custom_fields);

        // Auto-select first business and set its categories
        const businessField = custom_fields.find(field => field.referal_name === 'business_id');
        if (businessField && businessField.options.length > 0) {
          const firstBusinessId = businessField.options[0].value;
          const categories = custom_fields.find(field => field.referal_name === 'category').options.filter(option => option.business_id.toString() === firstBusinessId);
          setFilteredCategories(categories);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };



  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    if (!item_name.trim()) {
      formErrors.item_name = "Product Name is required";
      isValid = false;
    }
    if (!item_mpv.trim()) {
      formErrors.item_name = "MPV is required";
      isValid = false;
    }
    if (!SPV.trim()) formErrors.item_code = "SPV is required";
    if (!DPV.trim()) formErrors.item_code = "DPV is required";


    if (!item_code.trim()) {
      formErrors.item_code = "Model Name is required";
      isValid = false;
    }
    if (!description.trim()) {
      formErrors.description = "Description is required";
      isValid = false;
    }
    if (!price) {
      formErrors.price = "Price is required";
      isValid = false;
    }

    // Category validation
    if (!selectedCategory) {
      formErrors.category = "Category is required";
      setCategoryError("Category is required");
      isValid = false;
    } else {
      setCategoryError("");
    }

    // Image validation
    if (selectedimages.length === 0) {
      formErrors.images = "At least one image is required";
      isValid = false;
    }

    setErrors(formErrors);
    return isValid;
  };



  async function handleSubmit(e) {
    e.preventDefault();
    if (!validateForm()) return;
  
    const formData = new FormData();
    formData.append('_id', state._id);
    formData.append('functionName', "UpdateItems");
    formData.append('formId', collectionlist._id);
    formData.append('user_type_id', collectionlist._id);
    formData.append('user_type', collectionlist.name);
    formData.append('collection_name', collectionlist.collection_name);
    formData.append('item_name', item_name);
    formData.append('item_mpv', item_mpv);
    formData.append('Size', Size);
    formData.append('Color', Color);
    formData.append('SPV', SPV);
    formData.append('DPV', DPV);
    formData.append('item_code', item_code);
    formData.append('description', description);
    formData.append('price', price);
    formData.append('category', selectedCategory);
    formData.append('status', 'Active');
  
    selectedimages.forEach((image) => {
      if (image instanceof File) {
        formData.append('images', image); // Upload new images
      } else if (typeof image === 'string' && image.startsWith(Image_URL)) {
        formData.append('existingImages[]', image.replace(Image_URL, '')); // Keep stored images as paths
      }
    });
  
    console.log("Form Data:", [...formData]);
  
    setLoading(true);
  
    try {
      const response = await ItemService.Update_item(formData, authData.sessionID);
      setLoading(false);
      if (response.data.status) {
        navigate("/items-list");
        Alerts.swalSuccessAlert(response.data.message || t("Data Updated successfully."));
      } else {
        Alerts.swalErrorAlert(response.data.message || t("Error: Can't update data at the moment. Please try again later."));
      }
    } catch (error) {
      setLoading(false);
      Alerts.swalErrorAlert(t("Network Operation failed."));
    }
  }
  



  const handle_change = (e) => {
    var value = e.target.value;
    var referal_name = e.target.id;

    var custom_fields = fieldlist;
    var datafield_values = {};
    for (var i = 0; i < custom_fields.length; i++) {

      if (custom_fields[i].referal_name === referal_name) {
        custom_fields[i].fieldvalue = value
        datafield_values[custom_fields[i].referal_name] = value
      } else {
        datafield_values[custom_fields[i].referal_name] = custom_fields[i].fieldvalue;
      }

    }
    // setfieldvalues(datafield_values);ok
  }

  const handle_optionchange = (e) => {

    var element = e.target
    var value = element.value;
    var referal_name = element.name;
    var type = element.type;
    var custom_fields = fieldlist;
    var datafield_values = {};
    for (var i = 0; i < custom_fields.length; i++) {

      if (custom_fields[i].referal_name === referal_name) {

        if (type === "radio") {
          custom_fields[i].fieldvalue = value
          datafield_values[custom_fields[i].referal_name] = value
        }

        if (type === "checkbox") {
          var fieldvals = custom_fields[i].fieldvalue;
          if (typeof fieldvals === "string") {
            var cval = fieldvals;
            fieldvals = [];
            fieldvals = cval.split(",");
          }


          if (typeof fieldvals === "object") {

            if (!element.checked) {
              var index = fieldvals.indexOf(value);
              if (index !== -1) {

                fieldvals.splice(index, 1);
              }
            } else {

              fieldvals.push(value);


            }
          }
          custom_fields[i].fieldvalue = fieldvals
          datafield_values[custom_fields[i].referal_name] = fieldvals
        }



      } else {
        datafield_values[custom_fields[i].referal_name] = custom_fields[i].fieldvalue;
      }

    }
    // setfieldvalues(datafield_values);
  }
  const [selectedBusiness, setSelectedBusiness] = useState(state.business_id || '');

  useEffect(() => {
    // Filter categories based on the pre-selected business
    if (selectedBusiness) {
      const categoryField = fieldlist?.find(field => field.referal_name === 'category');
      const categories = categoryField?.options?.filter(option => option.business_id.toString() === selectedBusiness) || [];
      setFilteredCategories(categories);
    }
  }, [selectedBusiness, fieldlist]);

  const handleBusinessChange = (event) => {
    const businessId = event.target.value;
    setSelectedBusiness(businessId);
    setSelectedCategory(''); // Reset category when business changes
    // Filter categories based on the selected business
    const categoryField = fieldlist?.find(field => field.referal_name === 'category');
    const categories = categoryField?.options?.filter(option => option.business_id.toString() === businessId) || [];
    setFilteredCategories(categories);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };



  // const handleBusinessChange = (event) => {
  //   const businessId = event.target.value;
  //   setSelectedBusiness(businessId);
  //   // Filter categories based on the selected business
  //   const categories = fieldlist.find(field => field.referal_name === 'category').options.filter(option => option.business_id.toString() === businessId);
  //   setFilteredCategories(categories);
  // };




  return (
    <>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className="app-page-title">
            <div className="page-title-wrapper justify-content-left">
              <div className="page-title-heading fw-bold">
                <div className="page-title-icon">
                  <i className="pe-7s-plus icon-gradient bg-happy-green" />
                </div>
                <div>
                  {t("Update Item")}
                  <div className="page-title-subheading">
                    {t('Fill the form below to update Items')}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center">
            <div className="col-md-6">
              <Card className="main-card mb-3">
                <CardBody>
                  <LoadingOverlay
                    tag="div"
                    active={loading}
                    styles={{ overlay: (base) => ({ ...base }) }}
                    spinner={<DNALoader />}
                  >
                    <Form onSubmit={handleSubmit} ref={form}>
                      <div className="row">
                        <div className="col-md-12 col-xl-12">
                          <div className="form-group">
                            <label htmlFor="item_name">{t("Product Name")} <span style={{ color: "red" }}>*</span></label>
                            <Input
                              type='text'
                              value={item_name}
                              onChange={(e) => setItem_name(e.target.value)}
                              className={`form-control ${errors.item_name ? 'is-invalid' : ''}`}
                              placeholder={t("Product Name")}
                              name="item_name"
                              id="item_name"
                            />
                            {errors.item_name && <div className="invalid-feedback">{errors.item_name}</div>}
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-md-12 col-xl-12">
                          <div className="form-group">
                            <label htmlFor="item_code">{t("Model Name")} <span style={{ color: "red" }}>*</span></label>
                            <Input
                              type='text'
                              value={item_code}
                              onChange={(e) => setItem_code(e.target.value)}
                              className={`form-control ${errors.item_code ? 'is-invalid' : ''}`}
                              placeholder={t("Model Name")}
                              name="item_code"
                              id="item_code"
                            />
                            {errors.item_code && <div className="invalid-feedback">{errors.item_code}</div>}
                          </div>
                        </div>
                      </div>

                      <br />
                      <div className="row">
                        <div className="col-md-12 col-xl-12">
                          <div className="form-group">
                            <label htmlFor="price">{t("Price")} <span style={{ color: "red" }}>*</span></label>
                            <Input
                              value={price}
                              onChange={(e) => setPrice(e.target.value)}
                              type='number'
                              className={`form-control ${errors.price ? 'is-invalid' : ''}`}
                              placeholder={t("Price")}
                              name="price"
                              id="price"
                            />
                            {errors.price && <div className="invalid-feedback">{errors.price}</div>}
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-md-12 col-xl-12">
                          <div className="form-group">
                            <label htmlFor="item_mpv">{t("MPV")} <span style={{ color: "red" }}>*</span></label>
                            <Input
                              value={item_mpv}
                              onChange={(e) => setItem_mpv(e.target.value)}
                              type='number'
                              className={`form-control ${errors.item_mpv ? 'is-invalid' : ''}`}
                              placeholder={t("Item MPV")}
                              name="item_mpv"
                              id="item_mpv"
                            />
                            {errors.item_mpv && <div className="invalid-feedback">{errors.item_mpv}</div>}
                          </div>
                        </div>
                      </div>

                      <br />


                      <div className="row">
                        <div className="col-md-12 col-xl-12">
                          <div className="form-group">
                            <label htmlFor="SPV">{t("SPV")} <span style={{ color: "red" }}>*</span></label>
                            <input
                              value={SPV}
                              onChange={(e) => setSPV(e.target.value)}
                              type='number'
                              className={`form-control ${errors.SPV ? 'is-invalid' : ''}`}
                              placeholder={t("Item SPV")}
                              name="SPV"
                              id="SPV"
                            />
                            {errors.SPV && <div className="invalid-feedback">{errors.SPV}</div>}
                          </div>
                        </div>
                      </div>
                      <br />

                      <div className="row">
                        <div className="col-md-12 col-xl-12">
                          <div className="form-group">
                            <label htmlFor="DPV">{t("DPV")} <span style={{ color: "red" }}>*</span></label>
                            <input
                              value={DPV}
                              onChange={(e) => setDPV(e.target.value)}
                              type='number'
                              className={`form-control ${errors.DPV ? 'is-invalid' : ''}`}
                              placeholder={t("Item DPV")}
                              name="DPV"
                              id="DPV"
                            />
                            {errors.DPV && <div className="invalid-feedback">{errors.DPV}</div>}
                          </div>
                        </div>
                      </div>
                      <br />

                      <div className="row">
                        <div className="col-md-12 col-xl-12">
                          <div className="form-group">
                            <label htmlFor="Size">{t("Size")} <span style={{ color: "red" }}>*</span></label>
                            <input
                              type='text'
                              value={Size}
                              onChange={(e) => setSize(e.target.value)}
                              className={`form-control ${errors.Size ? 'is-invalid' : ''}`}
                              placeholder={t("Size")}
                              name="Size"
                              id="Size"
                            />
                            {errors.Size && <div className="invalid-feedback">{errors.Size}</div>}
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-md-12 col-xl-12">
                          <div className="form-group">
                            <label htmlFor="Color">{t("Color")} <span style={{ color: "red" }}>*</span></label>
                            <input
                              type='text'
                              value={Color}
                              onChange={(e) => setColor(e.target.value)}
                              className={`form-control ${errors.Color ? 'is-invalid' : ''}`}
                              placeholder={t("Color")}
                              name="Color"
                              id="Color"
                            />
                            {errors.Color && <div className="invalid-feedback">{errors.Color}</div>}
                          </div>
                        </div>
                      </div>


                      <br />

                      <div className="row">
                        <div className="col-md-12 col-xl-12">
                          <div className="form-group">
                            <label htmlFor="category">{t("Category")} <span style={{ color: "red" }}>*</span></label>
                            <select
                              name="category"
                              id="category"
                              value={selectedCategory}
                              className={`form-select form-control ${errors.category ? 'is-invalid' : ''}`}
                              onChange={handleCategoryChange}
                            >
                              <option disabled={false} value="">{t('--Choose an option--')}</option>
                              {filteredCategories?.map((option, index) => (
                                <option key={index} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                            {errors.category && <div className="invalid-feedback">{errors.category}</div>}
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-md-12 col-xl-12">
                          <div className="form-group">
                            <label htmlFor="images">{t("Picture")} <span style={{ color: "red" }}>*</span></label>
                            <Input
                              type='file'
                              accept="image/*"
                              multiple
                              className={`form-control ${errors?.images ? 'is-invalid' : ''}`}
                              name="images"
                              id="images"
                              onChange={handleFileChange}
                            />
                            {errors.images && <div className="invalid-feedback">{errors?.images}</div>}
                          </div>
                        </div>
                      </div>
                      <br />
                      {selectedimages.length > 0 && (
                        <div className="row">
                          <div className="col-md-12 col-xl-12">
                            <ImageSlideshow images={selectedimages} onRemove={removeImage} />
                          </div>
                        </div>
                      )}
                      <br />
                      <div className="row">
                        <div className="col-md-12 col-xl-12">
                          <div className="form-group">
                            <label htmlFor="description">{t("Description")} <span style={{ color: "red" }}>*</span></label>
                            <Input
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                              type='text'
                              className={`form-control ${errors.description ? 'is-invalid' : ''}`}
                              placeholder={t("Description")}
                              name="description"
                              id="description"
                            />
                            {errors.description && <div className="invalid-feedback">{errors.description}</div>}
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-md-12 col-xl-12">
                          <center>
                            <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-light btn-hover-shine me-3 mt-3">{t('Cancel')}</button>
                            <button className="btn btn-custom-color btn-hover-shine me-3 mt-3">{t('Update data')}</button>
                          </center>
                        </div>
                      </div>
                    </Form>
                  </LoadingOverlay>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );


}

export default DriverUpdationForm;