import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import { Row, Col, CardBody, Card } from "reactstrap";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import DataTable from 'react-data-table-component';
import BusinessService from "./Businesses.service";
import AuthService from "../Authentication/auth.service";
import Alerts from "../../components/Alerts/alerts";
import { Tooltip } from "@mui/material";
import BusinessFilter from "../../components/Table Filters/BusinessFilter";
import AddBusinessModal from './AddBusinessModal';
import EditBusinessModal from './EditBusinessModal';
import PermissionAccess from '../../PermissionAccess/Access.js';

const BusinessesList = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [businesses, setBusinesses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [businessArray, setBusinessArray] = useState([]);
  const { authData, accessPages, super_admin, current_module } = PermissionAccess();

  const [pageLimit, setPageLimit] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [showModalAddBusiness, setShowModalAddBusiness] = useState(false);
  const handleToggleModalAddBusiness = () => {
    setShowModalAddBusiness(!showModalAddBusiness);
  }

  const [showModalEditBusiness, setShowModalEditBusiness] = useState(false);
  const handleToggleModalEditBusiness = (dataArray) => {
    console.log('dataArray :', dataArray);
    setBusinessArray(dataArray);
    setShowModalEditBusiness(!showModalEditBusiness);
  }

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchBusinesses(0, pageLimit);
  }, [pageLimit]);


  const stored_user = authData?.userData;
  let business = []
  if (stored_user?.businesses) {
    business = stored_user?.businesses
  }

  function fetchBusinesses(page_index, page_limit, text, status) {
    setLoading(true);
    var offset = (page_index - 1) * page_limit;
    if (offset < 1) {
      offset = 0;
    }
    BusinessService.getAllBusinesses(authData.sessionID, offset, page_limit, business, text, status, true).then(
      (response) => {
        setLoading(false);
        console.log("Get businesses response:-", response);
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        } else {
          console.log(response.data);
          setBusinesses(response.data.data);
          setTotalRows(response.data.count.count);
        }
      },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        if (errContent) {
          Alerts.swalErrorAlert(errContent);
        } else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."));
        }
      }
    );
  }

  const handleCheckboxChange = (e, row) => {
    const { checked } = e.target;
    if (checked) {
      setSelectedRows((prevSelected) => [...prevSelected, row._id]);
    } else {
      setSelectedRows((prevSelected) => prevSelected.filter(id => id !== row._id));
    }
  };

  const handleSelectAllChange = (e) => {
    const { checked } = e.target;
    if (checked) {
      setSelectedRows(businesses.map((business) => business._id));
    } else {
      setSelectedRows([]);
    }
  };

  const handleDeleteSelected = () => {
    if (selectedRows.length > 0) {
      let msg = t(selectedRows.length + " records selected. Once deleted, you will not be able to recover this!");
      Alerts.swalConfirmDeleteAlert(msg).then(res => {
        console.log("Resp:-", res)
        if (res === true) {
          sendBulkDelete(selectedRows)
        }
      })
    } else {
      Alerts.swalErrorAlert('No records selected');
    }
  };

  function sendBulkDelete(selectedRows) {
    setLoading(true);
    BusinessService.deleteBulkBusiness(selectedRows).then((response) => {
      console.log("Delete BulkBusiness response:-", response)
      setLoading(false);
      setSelectedRows([]);
      fetchBusinesses(currentPage, pageLimit, filterText, filterStatus);
      if (response.data.status) {
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Collection Data Deleted Successfully."))
        }
        console.log(response.data.message)
      }
      else {
        setLoading(false);
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't delete the Collection Data at the moment."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Delete operation failed"))
      })
  };

  const mycolumns = [
    {
      name: (
        <div>
          <input
            className="form-check-input"
            type="checkbox"
            onChange={handleSelectAllChange}
          />
        </div>
      ),
      width: '10%',
      selector: (row) => {
        return (
          <div>
            <input
              className="form-check-input"
              type="checkbox"
              checked={selectedRows.includes(row._id)}
              onChange={(e) => handleCheckboxChange(e, row)}
            />
          </div>
        );
      },
    },
    {
      name: t('Name'),
      selector: "name",
      width: "30%",
      sortable: true,
    },
    {
      name: t('Email'),
      selector: "email",
      width: "30%",
      sortable: true,
    },
    {
      name: t('Status'),
      width: '20%',
      selector: (row) => {
        return (
          <div>
            {row.status.toLowerCase() === "active" ? (
              <button
                type="button"
                style={{ borderColor: '#3ac47d', backgroundColor: '#1BD35A' }}
                className="btn btn-sm btn-success btn-hover-shine me-3"
              >
                {row.status}
              </button>
            ) : (
              <button
                type="button"
                style={{ borderColor: '#FF0000', backgroundColor: 'red' }}
                className="btn btn-sm btn-danger btn-hover-shine me-3"
              >
                {row.status}
              </button>
            )}
          </div>
        );
      },
      sortable: true,
    },
    {
      name: t('Actions'),
      width: "10%",
      selector: (row) => {
        return (
          <div>
            {!super_admin ? current_module?.ViewData === 1 &&
              <Tooltip placement="top" title={`${t("View")} ${t("Business")}`} arrow>
                <NavLink to={`/business-details/${row._id}`}>
                  <i className="pe-7s-look text-primary btn-icon-wrapper fs-5"> </i>
                </NavLink>
              </Tooltip>
              :
              <Tooltip placement="top" title={`${t("View")} ${t("Business")}`} arrow>
                <NavLink to={`/business-details/${row._id}`}>
                  <i className="pe-7s-look text-primary btn-icon-wrapper fs-5"> </i>
                </NavLink>
              </Tooltip>
            }
            {!super_admin? current_module?.EditData === 1 &&
              <Tooltip placement="top" title={`${t("Update")} ${t("Business")}`} arrow onClick={() => handleToggleModalEditBusiness(row)} style={{ cursor: "pointer" }}>
                <i className="pe-7s-pen btn-icon-wrapper text-primary fs-5 p-1"> </i>
              </Tooltip>
              :
              <Tooltip placement="top" title={`${t("Update")} ${t("Business")}`} arrow onClick={() => handleToggleModalEditBusiness(row)} style={{ cursor: "pointer" }}>
                <i className="pe-7s-pen btn-icon-wrapper text-primary fs-5 p-1"> </i>
              </Tooltip>
            }
            {!super_admin? current_module?.DeleteData === 1 &&
              <Tooltip placement="top" title={`${t("Delete")} ${t("Business")}`} arrow onClick={() => confirmDelete(row._id)} style={{ cursor: "pointer" }}>
                <i className="pe-7s-trash text-danger btn-icon-wrapper fs-5"> </i>
              </Tooltip>
              :
              <Tooltip placement="top" title={`${t("Delete")} ${t("Business")}`} arrow onClick={() => confirmDelete(row._id)} style={{ cursor: "pointer" }}>
                <i className="pe-7s-trash text-danger btn-icon-wrapper fs-5"> </i>
              </Tooltip>
            }
          </div>
        );
      }
    },
  ];

  const handleSearch = (searchText, status) => {
    setFilterText(searchText);
    setFilterStatus(status);
    if (searchText !== '' || status !== '') {
      fetchBusinesses(0, pageLimit, searchText, status);
    }
  };

  const handleClear = () => {
    setFilterText('');
    setFilterStatus('');
    fetchBusinesses(0, pageLimit);
  };
  const filteredItems = businesses;
  /*
  const filteredItems = businesses.filter(
    item =>
      (item.name.toLowerCase().includes(filterText.toLowerCase()) ||
        item.email.toLowerCase().includes(filterText.toLowerCase())) &&
      (filterStatus === '' || item.status === filterStatus)
  );
*/
  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <BusinessFilter
        onSearch={handleSearch}
        onClear={handleClear}
        filterText={filterText}
        filterStatus={filterStatus}
      />
    );
  }, [filterText, filterStatus]);

  function confirmDelete(_id) {
    let msg = t("Once deleted, you will not be able to recover this user!")
    Alerts.swalConfirmDeleteAlert(msg).then(res => {
      console.log("Resp:-", res)
      if (res === true) {
        sendDelete(_id)
      }
    })
  };

  function sendDelete(id) {
    setLoading(true);
    BusinessService.deleteBusiness(id).then((response) => {
      console.log("Delete Business response:-", response)
      setLoading(false);
      fetchBusinesses(currentPage, pageLimit, filterText, filterStatus);
      if (response.data.status) {
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Business Deleted Successfully."))
        }
        console.log(response.data.message)
      }
      else {
        setLoading(false);
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't delete the Section."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Delete operation failed"))
      })
  };

  function handlePageChange(page_index) {
    console.log("Page index:-", page_index)
    setCurrentPage(page_index)
    fetchBusinesses(page_index, pageLimit, filterText, filterStatus);
  }

  async function handlePerRowsChange(newPageLimit, page_index) {
    setPageLimit(newPageLimit);
  }

  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className="bg-light-gray bg-animation">
            <div className="d-flex justify-content-center align-items-center">
              <Col md="12" className="mx-auto app-login-box">
                <div className="app-page-title">
                  <div className="page-title-wrapper">
                    <div className="page-title-heading fw-bold">
                      <div className="page-title-icon">
                        <i className="pe-7s-news-paper icon-gradient bg-tempting-azure" />
                      </div>
                      <div>
                        {`${t("Business")} ${t("Management")}`}
                        <div className="page-title-subheading">
                          {`${t("Add, Update, Delete & View Details of")} ${t("Business")}`}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Row>
                  <LoadingOverlay
                    tag="div"
                    active={loading}
                    styles={{ overlay: (base) => ({ ...base }) }}
                    spinner={<DNALoader />}
                  >
                    <Col md="12">
                      <Card className="main-card mb-3">
                        <CardBody>
                          <Col md="6" style={{ float: "left", position: "absolute", zIndex: "1" }}>
                            {subHeaderComponentMemo}
                          </Col>
                          <DataTable
                            data={filteredItems}
                            columns={mycolumns}
                            pagination
                            fixedHeader
                            fixedHeaderScrollHeight="400px"
                            subHeader
                            subHeaderComponent={
                              <div>
                                {!super_admin ? current_module?.BulkDeleteData === 1 &&

                                  <button type="button"
                                    className="btn btn-outline-danger btn-hover-shine me-3 btn"
                                    onClick={handleDeleteSelected}
                                  >
                                    {t('Delete Selected')}
                                  </button>
                                  :
                                  <button type="button"
                                    className="btn btn-outline-danger btn-hover-shine me-3 btn"
                                    onClick={handleDeleteSelected}
                                  >
                                    {t('Delete Selected')}
                                  </button>
                                }
                                {/* {!super_admin ? current_module?.AddData === 1 &&
                                  <button type="button" className="btn btn-custom-color btn-hover-shine me-3 btn" onClick={handleToggleModalAddBusiness}>
                                    {t('Add New')}
                                  </button>
                                  :
                                  <button type="button" className="btn btn-custom-color btn-hover-shine me-3 btn" onClick={handleToggleModalAddBusiness}>
                                    {t('Add New')}
                                  </button>
                                } */}
                                <AddBusinessModal showModalAddBusiness={showModalAddBusiness} handleToggleModalAddBusiness={handleToggleModalAddBusiness} onSucess={fetchBusinesses} />
                                <EditBusinessModal showModalEditBusiness={showModalEditBusiness} handleToggleModalEditBusiness={handleToggleModalEditBusiness} onSucess={fetchBusinesses} businessArray={businessArray} />
                              </div>
                            }

                            paginationServer
                            paginationTotalRows={totalRows}
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handlePerRowsChange}
                            paginationResetDefaultPage={resetPaginationToggle}
                          />
                        </CardBody>
                      </Card>
                    </Col>
                  </LoadingOverlay>
                </Row>
              </Col>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default BusinessesList;
