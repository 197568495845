import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from "react-router-dom";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Row, Col, Card, CardBody } from "reactstrap";
import Tooltip from '@mui/material/Tooltip';

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";

import GenFilter from "../../components/Table Filters/GenFilter";
import DataTable from 'react-data-table-component';

import Alerts from "../../components/Alerts/alerts";

import EntitiesService from "./entities.service";
import AuthService from "../Authentication/auth.service";
import PermissionAccess from '../../PermissionAccess/Access.js';

const EntitiesList = () => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [EntitiesList, setEntities] = useState([]);
  const { authData, accessPages, super_admin, current_module } = PermissionAccess();

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchEntities();
  }, []); 

  function fetchEntities() {
    setLoading(true);
    EntitiesService.getAll({},authData.sessionID).then((response) => {
      setLoading(false);
      console.log("Get Entities response:-", response)
      if(response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setEntities(response?.data?.data);
      }
    },
    (error) => {
      setLoading(false);
      const errContent =
      (error.response && error.response.data) || error.message || error.toString();
      if(errContent) {
        Alerts.swalErrorAlert(errContent)
      }
      else {
        Alerts.swalErrorAlert(t("Server Error, Please try again later."))
      }
    })
  };

  const TBcolumns = [
    {
      name: t('Name'),
      selector: row => row.name,
    },
    {
      name: t('Page Title'),
      selector: row => row.page_title,
    },
    {
    name: t('Menu Label'),
    selector: row => row.menu_label,
    },
    {
    name: t('Menu Position'),
    selector: row => row.menu_positon,
    },{
    name: t('Referal Name'),
    selector: row => row.referal_name,
    },
    {
    name: t('Actions'),
    selector:  (row) =>  {
      return (
        <div>
            {!super_admin ? current_module?.Attributes === 1 &&
              <Tooltip placement="top" title={t("Attributes" )}arrow>
              <Link to="/attributes-list" state={row} >
                <i className="pe-7s-news-paper icon-gradient bg-arielle-smile btn-lg px-1 "> </i>
              </Link>
              </Tooltip>
              :
              <Tooltip placement="top" title={t("Attributes" )}arrow>
              <Link to="/attributes-list" state={row} >
                <i className="pe-7s-news-paper icon-gradient bg-arielle-smile btn-lg px-1 "> </i>
              </Link>
              </Tooltip>
            }

            {!super_admin ? current_module?.EditData === 1 &&
                      <Tooltip placement="top" title={t("Edit")} arrow>
                      <Link className="EditData" to="/update-entity" state={row} >
                        <i className="pe-7s-pen icon-gradient bg-arielle-smile btn-lg px-1"> </i>
                      </Link>
                      </Tooltip>
                      :
                    <Tooltip placement="top" title={t("Edit")} arrow>
                      <Link className="EditData" to="/update-entity" state={row} >
                        <i className="pe-7s-pen icon-gradient bg-arielle-smile btn-lg px-1"> </i>
                      </Link>
                    </Tooltip>
            }

            {!super_admin ? current_module?.DeleteData === 1 &&
                      <Tooltip placement="top" title={t("Delete")} arrow>
                      <a className="DeleteData" onClick={() => confirmDelete(row._id,row.referal_name)} style={{cursor: "pointer"}}>
                        <i className="pe-7s-trash icon-gradient bg-ripe-malin btn-lg px-0 "> </i>
                      </a>
                      </Tooltip>
                      :
                    <Tooltip placement="top" title={t("Delete")} arrow>
                      <a className="DeleteData" onClick={() => confirmDelete(row._id,row.referal_name)} style={{cursor: "pointer"}}>
                        <i className="pe-7s-trash icon-gradient bg-ripe-malin btn-lg px-0 "> </i>
                      </a>
                    </Tooltip>
            }


        </div>
      );
    }
    },
  ];

  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  let filteredItems = EntitiesList?.filter(
    item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
  );

  const handleSearch = (searchText) => {
    setFilterText(searchText);
  };
  
  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText('');
    }
  };

  const subHeaderComponentMemo = React.useMemo(() => {


    return (
      <GenFilter
        onSearch={handleSearch}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);



  function confirmDelete(_id,_referal_name) {
    let msg = t("Once deleted, you will not be able to recover this entity!")
    Alerts.swalConfirmDeleteAlert(msg).then(res => {
      console.log("Resp:-",res)
      if(res === true) {
        sendDelete(_id,_referal_name)
      }
    }) 
  };


  function sendDelete(id,referal_name) {
    setLoading(true);
    EntitiesService.deleteEntity(authData.sessionID,authData._id,id,referal_name).then((response) => {
      setLoading(false);
      console.log("Delete Entity response:-", response)
      fetchEntities();
      if(response.data.status) {
        if(response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Entity Deleted Successfully."))
        }
        console.log(response.data.message)
      }
      else {
        if(response.data.message !== '') {
          Alerts.swalErrorAlert(response.data.message)
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't delete the Entity at the moment."))
        }
      }
    },
    (error) => {
      setLoading(false);
      const errContent =
      (error.response && error.response.data) || error.message || error.toString();
      Alerts.swalErrorAlert(t("Delete operation failed"))
    })
  };

  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <TransitionGroup>
            <CSSTransition component="div" classNames="TabsAnimation" appear={true}
              timeout={1500} enter={false} exit={false}>
              <div>
                <div className="app-page-title">
                  <div className="page-title-wrapper">
                    <div className="page-title-heading fw-bold">
                      <div className="page-title-icon">
                        <i className="pe-7s-box1 icon-gradient bg-happy-green" />
                      </div>
                      <div>
                      {t('Entities')}
                        <div className="page-title-subheading">
                          {t('View all Entities, Create, Edit and Delete an entity.')}
                        </div>
                      </div>
                    </div>
                    <div className="page-title-actions">
                    <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-success btn-hover-shine me-3">
                              {t('Back')}
                            </button>
                    </div>
                  </div>
                </div>
                <Row>
                  <Col md="12">
                    <Card className="main-card mb-3">
                      <CardBody>
                        <LoadingOverlay tag="div" active={loading}
                          styles = {{overlay: (base) => ({...base})}}
                          spinner = {<DNALoader/>}>
                          <Col md="5"  style={{float:"left", position:"absolute", zIndex:"1"}}>
                          {subHeaderComponentMemo}
                          </Col>
                          <DataTable
                            columns={TBcolumns}
                            data={filteredItems}
                            pagination
                            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                            subHeader
                            subHeaderComponent={ 
                            
                              <div>
                                  {!super_admin ? current_module?.AddData === 1 &&
                                    <a href="/add-entity" className="AddData">
                                     <button type="button" className="btn btn-custom-color btn-hover-shine me-3 btn">{t('Add New')}</button>
                                   </a>
                                    :
                                    <a href="/add-entity" className="AddData">
                                    <button type="button" className="btn btn-custom-color btn-hover-shine me-3 btn">{t('Add New')}</button>
                                  </a>
                                  }
                              </div>

                     
                      
                    }
                            selectableRowDisabled={true}
                            persistTableHead
                          />
                        </LoadingOverlay>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
    </Fragment>
  );

};

export default EntitiesList;


// return (
//   <>
    
//     <div className="container-fluid">
//         <br/>
//         <div className="row">
//         <div className="col-sm-12">
//             <div className="page-title-box">
//             <div className="float-end">
//                 <a href="/addentities">
//                 <button type="button" className="btn btn-custom-color">Add New</button>
//                 </a>
//             </div>
//             <h4 className="page-title">Entity Listing</h4>
//             </div>
//         </div>
//         </div>
//         <br/>
//         <div className="row">
//         <div className="col-lg-12">
//             <div className="card">
//             <div className="card-body">
//                 <div className="table-responsive">
                
//                 <DataTable
//                     columns={TBcolumns}
//                     data={filteredItems}
//                     pagination
//                     paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
//                     subHeader
//                     subHeaderComponent={subHeaderComponentMemo}
//                     selectableRowDisabled={true}
//                     persistTableHead
//                 />
//               </div>
//             </div>
//             </div>
//         </div>
//         </div>
//     </div>
      
//   </>
//   );


