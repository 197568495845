import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  FormGroup,
  Label,
} from "reactstrap";
import { Form, Field } from "react-final-form";

import AttributesService from "./fields.service";
import AuthService from "../../Authentication/auth.service";

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import "../../../assets/custom css/loadoverlay.scss";

import Alerts from "../../../components/Alerts/alerts";

const UpdateCurrency = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location; // Existing currency data passed via navigation

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
  }, []);

  // Validation Function
  const validateFtn = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = t("Name Required.");
    }
    if (!values.code) {
      errors.code = t("Currency Code Required.");
    }
    if (!values.symbol) {
      errors.symbol = t("Currency Symbol Required.");
    }
    if (!values.timezone) {
      errors.timezone = t("Time Zone Required.");
    }
    if (!values.rate || isNaN(values.rate)) {
      errors.rate = t("Valid Exchange Rate Required.");
    }
    return errors;
  };

  // Submit Function
  const onSubmit = (values) => {
    console.log("Update Values:", values);
    console.log("state:", state);
    const payloadData = {
      functionName: "Update_Currency",
      _id: state._id,
      name: values.name,
      code: values.code,
      symbol: values.symbol,
      timezone: values.timezone,
      rate: parseFloat(values.rate),
    };

    setLoading(true);
    AttributesService.Update_Currency(payloadData)
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          navigate("/Finance-Settings");
          Alerts.swalSuccessAlert(response.data.message || t("Currency Updated Successfully."));
        } else {
          Alerts.swalErrorAlert(response.data.message || t("Error updating Currency."));
        }
      })
      .catch((error) => {
        setLoading(false);
        Alerts.swalErrorAlert(t("Network Operation Failed."));
      });
  };

  return (
    <>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className="app-page-title">
            <div className="page-title-wrapper justify-content-left">
              <div className="page-title-heading fw-bold">
                <div className="page-title-icon">
                  <i className="pe-7s-pen icon-gradient bg-happy-green" />
                </div>
                <div>
                  {t('Update Currency')}
                  <div className="page-title-subheading">
                    {t('Update the details of the selected currency.')}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center">
            <div className="col-md-6">
              <Card className="main-card mb-3">
                <CardBody>
                  <LoadingOverlay active={loading} spinner={<DNALoader />}>
                    <Form
                      onSubmit={onSubmit}
                      validate={validateFtn}
                      initialValues={state} // Pre-populate existing currency data
                      render={({ handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                          <Row>
                            {/* Name */}
                            <Col md="12">
                              <Label>{t("Name")}</Label>
                              <Field name="name">
                                {({ input, meta }) => (
                                  <Input
                                    {...input}
                                    placeholder={t("Name for Currency")}
                                    invalid={meta.error && meta.touched}
                                  />
                                )}
                              </Field>
                            </Col>

                            {/* Currency Code */}
                            <Col md="12">
                              <Label>{t("Currency Code")}</Label>
                              <Field name="code">
                                {({ input, meta }) => (
                                  <Input
                                    {...input}
                                    placeholder={t("e.g., USD, PHP")}
                                    invalid={meta.error && meta.touched}
                                  />
                                )}
                              </Field>
                            </Col>

                            {/* Currency Symbol */}
                            <Col md="12">
                              <Label>{t("Currency Symbol")}</Label>
                              <Field name="symbol">
                                {({ input, meta }) => (
                                  <Input
                                    {...input}
                                    placeholder={t("e.g., $, ₱")}
                                    invalid={meta.error && meta.touched}
                                  />
                                )}
                              </Field>
                            </Col>
                             {/* timezone */}
                             <Col md="12">
                              <Label>{t("Time Zone")}</Label>
                              <Field name="timezone">
                                {({ input, meta }) => (
                                  <Input
                                    {...input}
                                    placeholder={t("e.g., Asia/Manila")}
                                    invalid={meta.error && meta.touched}
                                  />
                                )}
                              </Field>
                            </Col>

                            {/* Exchange Rate */}
                            <Col md="12">
                              <Label>{t("Exchange Rate")}</Label>
                              <Field name="rate">
                                {({ input, meta }) => (
                                  <Input
                                    {...input}
                                    placeholder={t("e.g., 1.0 for base currency")}
                                    type="number"
                                    step="0.01"
                                    invalid={meta.error && meta.touched}
                                  />
                                )}
                              </Field>
                            </Col>
                          </Row>

                          {/* Submit Buttons */}
                          <Row className="mt-4">
                            <Col className="text-center">
                              <Button type="submit" color="secondary" size="lg" className="me-3">
                                {t("Update")}
                              </Button>
                              <Button
                                type="button"
                                color="outline-success"
                                size="lg"
                                onClick={() => navigate("/Finance-Settings")}
                              >
                                {t("Cancel")}
                              </Button>
                            </Col>
                          </Row>
                        </form>
                      )}
                    />
                  </LoadingOverlay>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateCurrency;
