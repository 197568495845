import React from 'react';
import { X } from 'lucide-react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const InvoicePopup = ({ isOpen, onClose, data }) => {
  if (!isOpen) return null;


  let logo = localStorage.getItem("system_logo");

  const MyData = {
    invoiceNumber: data?.invoice_number,
    from: {
      name: data?.companyName,
      address: data?.companyAddress,
      city: data?.companyEmail,
      phone: '(555) 123-4567'
    },
    billTo: {
      name: data?.user_details?.[0]?.name,
      address: `${data?.user_details?.[0]?.AddressLine1},  ${data?.user_details?.[0]?.AddressLine2}`,
      city: data?.user_details?.[0]?.CityName,
      phone: data?.user_details?.[0]?.phone
    },
    items: data?.item
  };
  const generatePDF = () => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;
    const margin = 20;

    // Add logo
    const logoWidth = 40;
    const logoHeight = 20;
    doc.addImage(logo, 'PNG', (pageWidth - logoWidth) / 2, margin, logoWidth, logoHeight);

    // Add title
    doc.setFontSize(22);
    doc.text('Invoice', margin, margin + logoHeight + 15);

    // Add invoice number
    doc.setFontSize(12);
    doc.text(`Invoice #: ${MyData?.invoiceNumber}`, pageWidth - margin, margin + logoHeight + 15, { align: 'right' });

    // Add From section
    doc.setFontSize(14);
    doc.text('From:', margin, margin + logoHeight + 30);
    doc.setFontSize(10);
    const fromLines = doc.splitTextToSize(`${MyData?.from?.name}\n${MyData?.from?.address}\n${MyData?.from?.city}\n${MyData?.from?.phone}`, (pageWidth - 2 * margin) / 2 - 5);
    doc.text(fromLines, margin, margin + logoHeight + 40);

    // Add Bill To section
    doc.setFontSize(14);
    doc.text('Bill To:', pageWidth / 2, margin + logoHeight + 30);
    doc.setFontSize(10);
    const billToLines = doc.splitTextToSize(`${MyData?.billTo?.name}\n${MyData?.billTo?.address}\n${MyData?.billTo?.city}\n${MyData?.billTo?.phone}`, (pageWidth - 2 * margin) / 2 - 5);
    doc.text(billToLines, pageWidth / 2, margin + logoHeight + 40);

    // Add items table
    const startY = Math.max(
      doc.getTextDimensions(fromLines).h + margin + logoHeight + 45,
      doc.getTextDimensions(billToLines).h + margin + logoHeight + 45
    );

    doc.autoTable({
      startY: startY,
      head: [['Description', 'Qty', 'Rate/Qty', 'Total']],
      body: MyData?.items.map(item => [
        item.description,
        item.quantity,
        `${item.rate}`,
        `${item.item_total}`
      ]),
      theme: 'grid',
      headStyles: { fillColor: [59, 130, 246], textColor: 255 },
      columnStyles: {
        0: { cellWidth: 'auto' },
        1: { cellWidth: 30 },
        2: { cellWidth: 40 },
        3: { cellWidth: 40 }
      },
    });

    // // Add total
    // const total = MyData?.items.reduce((sum, item) => sum + parseFloat(item.item_total), 0);
    // doc.setFontSize(12);
    // doc.text(`Total: $${total.toFixed(2)}`, pageWidth - margin, doc.lastAutoTable.finalY + 10, { align: 'right' });

    // Save the PDF
    doc.save('invoice.pdf');
  };

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000
    }}>
      <div style={{
        backgroundColor: 'white',
        borderRadius: '8px',
        width: '100%',
        maxWidth: '800px',
        maxHeight: '90%',
        overflowY: 'auto',
        position: 'relative',
        padding: '20px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
      }}>
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: '1px solid #e0e0e0',
          paddingBottom: '10px',
          marginBottom: '20px'
        }}>
          <h2 style={{ margin: 0, fontSize: '18px', fontWeight: 'bold' }}>E-Invoice</h2>
          <button onClick={onClose} style={{
            background: 'none',
            border: 'none',
            cursor: 'pointer',
            padding: '5px'
          }}>
            <X size={20} />
          </button>
        </div>

        <div style={{ marginBottom: '20px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '20px' }}>
            <img src={logo} alt="Company Logo" style={{ maxWidth: '150px', height: 'auto', marginBottom: '15px' }} />
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <h1 style={{ fontSize: '28px', color: '#333', margin: 0 }}>Invoice</h1>
              <div>
                <strong>Invoice #:</strong>
                <div style={{ borderBottom: '1px solid #ccc', width: '200px', marginTop: '5px' }}>{MyData?.invoiceNumber}</div>
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
            <div style={{ width: '45%' }}>
              <strong>From:</strong>
              <div style={{ borderBottom: '1px solid #ccc', marginTop: '5px' }}>{MyData?.from.name}</div>
              <div style={{ borderBottom: '1px solid #ccc', marginTop: '5px' }}>{MyData?.from.address}</div>
              <div style={{ borderBottom: '1px solid #ccc', marginTop: '5px' }}>{MyData?.from.city}</div>
              <div style={{ borderBottom: '1px solid #ccc', marginTop: '5px' }}>{MyData?.from.phone}</div>
            </div>
            <div style={{ width: '45%' }}>
              <strong>Bill To:</strong>
              <div style={{ borderBottom: '1px solid #ccc', marginTop: '5px' }}>{MyData?.billTo.name}</div>
              <div style={{ borderBottom: '1px solid #ccc', marginTop: '5px' }}>{MyData?.billTo.address}</div>
              <div style={{ borderBottom: '1px solid #ccc', marginTop: '5px' }}>{MyData?.billTo.city}</div>
              <div style={{ borderBottom: '1px solid #ccc', marginTop: '5px' }}>{MyData?.billTo.phone}</div>
            </div>
          </div>
        </div>
        <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px', marginTop: '20px' }}>
          <thead>
            <tr style={{ border: '1px solid #ddd', backgroundColor: '#f0f0f0' }}>
              <th style={{ textAlign: 'left', padding: '10px' }}>Description</th>
              <th style={{ textAlign: 'left', padding: '10px' }}>Qty</th>
              <th style={{ textAlign: 'left', padding: '10px' }}>Rate/Qty</th>
              <th style={{ textAlign: 'left', padding: '10px' }}>Total</th>
            </tr>
          </thead>
          <tbody>
            {MyData?.items.map((item, index) => (
              <tr key={index} style={{ border: '1px solid #ddd' }}>
                <td style={{ padding: '10px' }}>{item.description}</td>
                <td style={{ padding: '10px' }}>{item.quantity}</td>
                <td style={{ padding: '10px' }}>{item.rate}</td>
                <td style={{ padding: '10px' }}>{item.item_total}</td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
          <div style={{ width: '200px', textAlign: 'right' }}>
            <strong>Total:</strong>
            <div style={{ borderBottom: '1px solid #ccc', marginTop: '5px' }}>
              {MyData?.items.reduce((sum, item) => sum + parseFloat(item.item_total), 0).toFixed(2)}
            </div>
          </div>
        </div> */}

        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
          <button onClick={generatePDF} style={{
            padding: '10px 20px',
            border: '1px solid #ddd',
            borderRadius: '4px',
            background: 'white',
            cursor: 'pointer'
          }}>
            Save PDF
          </button>
          <button onClick={() => window.print()} style={{
            padding: '10px 20px',
            border: 'none',
            borderRadius: '4px',
            background: '#3b82f6',
            color: 'white',
            cursor: 'pointer'
          }}>
            Print
          </button>
        </div>
      </div>
    </div>
  );
};

export default InvoicePopup;