import React, { Fragment, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Row, Col, Card, CardBody, Button, Input, FormGroup, Label
} from "reactstrap";
import moment from "moment";
import { format } from "date-fns";
import DynModuleService from "./dynamicmodules.service";

import CustomerService from "../../../pages/Finance/Invoices/Invoices.service";
import Select from 'react-select';

import { Form, Field } from "react-final-form";

import AuthService from "../../Authentication/auth.service";
import { useTranslation } from 'react-i18next';

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import "../../../assets/custom css/loadoverlay.scss";

import Alerts from "../../../components/Alerts/alerts";
import { Divider, Tooltip } from "@mui/material";
import AlertService from "../../../components/Alerts/alerts";
import PermissionAccess from '../../../PermissionAccess/Access.js';


const AddInvoice = () => {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  console.log("state", state);
  const [loading, setLoading] = useState(false);
  const [total_amount, setTotal_amount] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const getCurrentDate = () => {
    return format(new Date(), "yyyy-MM-dd");
  };

  const getDefaultDueDate = () => {
    let days;
    if (parseInt(localStorage.getItem("due_date"))) {
      days = parseInt(localStorage.getItem("due_date"));
    } else {
      days = parseInt(0)
    }
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + days);
    return format(currentDate, "yyyy-MM-dd");
  };
  const { authData, accessPages, super_admin, current_module } = PermissionAccess();

  const [invoiceDate, setInvoiceDate] = useState(moment().format('YYYY-MM-DD'));
  const [DueDate, setDueDate] = useState(getDefaultDueDate());
  const [discount_Amount, setDiscount_Amount] = useState(0);
  const [amount, setAmount] = useState(0);
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [discount, setDiscount] = useState(0);
  const [discountType, setDiscountType] = useState('%');

  const [selectedCustomer, setSelectedCustomer] = useState({ value: '', label: '' });

  const [orderDetails, setOrderDetails] = useState([]);
  const [Customers, setCustomers] = useState([]);
  const [Services, setServices] = useState([]);
  const [TaxClasses, setTaxClasses] = useState([]);
  const [TaxClass, setTaxClass] = useState(0);
  const [AllBusinesses, setAllBusinesses] = useState([]);
  const [AllCategories, setAllCategories] = useState([]);


  const [collectionlist, setcollectionlist] = useState([])
  const [fieldlist, setfieldlist] = useState([])
  const [fieldlist_validate, setfieldlist_validate] = useState([])



  function getLastInvoiceNumber() {
    var getData = {
      functionName: "getLastInvoiceNumber",
      postData: {
        "sort": { _id: -1 },
        "offset": 0,
        "limit": 1,
        "filter": {}
      },
    }
    CustomerService.getLastInvoiceNumber(getData, authData.sessionID).then((response) => {
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        if (response.data) {
          setInvoiceNumber(generateNextInvoiceNumber(response.data?.data?.[0]?.invoice_number));
        }
      }
    },
      (error) => {
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  function generateNextInvoiceNumber(lastInvoiceNumber) {
    // Extract the numeric part of the invoice number
    if (lastInvoiceNumber) {
      const numericPart = parseInt(lastInvoiceNumber.split('-')[1]); // Extracts 3108 from 'INV-3108'

      // Increment the numeric part by 1
      const nextNumericPart = numericPart + 1;

      // Generate the next invoice number by concatenating the prefix 'INV-' with the incremented number
      const nextInvoiceNumber = `INV-${nextNumericPart}`;

      return nextInvoiceNumber;
    } else {
      const nextInvoiceNumber = `INV-1`;
      return nextInvoiceNumber;

    }
  }
  const [data, setData] = useState([]);
  const [tax, setTax] = useState(0);

  useEffect(() => {
    fetchDynamicData();
    fetchTaxClasses();
    getLastInvoiceNumber();
    fetchBusinesses();
    fetchCategories();
  }, []);

  function fetchCategories() {
    const stored_user = authData.userData
    let business = []
    if (stored_user.businesses) {
      business = stored_user.businesses
    }
    var getData = {
      functionName: "GetCategories",
      postData: {
        sort: { _id: -1 },
        offset: 0,
        limit: 1000,
        "business_id": business,
      },
    };
    CustomerService.GetCategories(getData, authData.sessionID).then((response) => {
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        if (response.data) {
          setAllCategories(response.data.data);
        }
      }
    },
      (error) => {
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };
  function fetchBusinesses() {
    const stored_user = authData.userData
    let business = []
    if (stored_user.businesses) {
      business = stored_user.businesses
    }

    var getData = {
      functionName: "GetBusinesses",
      postData: {
        sort: { _id: -1 },
        offset: 0,
        limit: 1000,
        business_ids: business,
      },
    };
    CustomerService.GetBusinesses(getData, authData.sessionID).then((response) => {
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        if (response.data) {
          setAllBusinesses(response.data.data);
        }
      }
    },
      (error) => {
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  const fetchDynamicData = async () => {
    try {
      const response = await DynModuleService.get_cruds_dynamic_data();
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        const data = await response.data;

        var custom_fields = data;
        for (var i = 0; i < custom_fields.length; i++) {
          if (custom_fields[i].options_collection !== '') {

            const optresponse = await DynModuleService.getoptions(custom_fields[i].options_collection, "", {}, 5000);
            const optdata = await optresponse.data.data;
            var options = [];

            for (var j = 0; j < optdata.length; j++) {
              options.push({ label: optdata[j][custom_fields[i].Options_Field_Name], value: optdata[j]._id, })
            }
            custom_fields[i].options = options;
          }
          custom_fields[i].default_value = ""
        }

        data[0].custom_fields = custom_fields;

        setcollectionlist(data[0]);
        setfieldlist(data[0].custom_fields);
        var cust = data[0].custom_fields;
        var validations = {};
        for (var i = 0; i < cust.length; i++) {
          validations[cust[i].referal_name] = cust[i].is_required;
        }
        setfieldlist_validate(validations);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const renderSelect = ({ input, meta }) => (
    <div>
      <Select
        {...input}
        options={Customers}
        value={selectedCustomer}
        onChange={(selectedOption) => {
          input.onChange(selectedOption);
          setSelectedCustomer(selectedOption);
        }}
        isSearchable={true}
        placeholder="Select or Add a Customer"
        isDisabled={!selectedCustomer}
      />
      {meta.error && meta.touched && <span className="text-danger">{meta.error}</span>}
    </div>
  );

  const [editableRowIndex, setEditableRowIndex] = useState(null);

  const [bulkEditMode, setBulkEditMode] = useState(false); // New state to control bulk edit mode

  const handleEditClick = (index) => {
    if (index === undefined) {
      // If index is undefined, it's the Bulk Update button
      setBulkEditMode(!bulkEditMode); // Toggle bulk edit mode
      if (!bulkEditMode) {
        setEditableRowIndex(null); // Reset individual edit mode if entering bulk edit mode
      }
    } else {
      setEditableRowIndex(index); // Individual row edit mode
    }
  };
  const handleSaveClick = () => {
    setEditableRowIndex([null]);
  };



  const handleAddRow = () => {
    if (data.every(item => item.item_name && item.description)) {
      const id = data.length + 1;
      const taxClass = TaxClasses?.[0]?.percentage ? TaxClasses?.[0]?.percentage : 0;
      const newData = [...data, {
        id, item_name: '', description: ''
        , tax_class: taxClass
        , quantity: 1, item_type: 'Service'
      }];
      setData(newData);
      setEditableRowIndex(newData.length - 1);
    } else {
      AlertService.swalErrorAlert('Please fill in Existing rows First.');
    }
  };

  const handleDeleteClick = (index) => {
    const newData = [...data];
    newData.splice(index, 1); // Remove the item at the specified index
    setData(newData);
    setEditableRowIndex(null); // Clear the editable row if deleted
  };
  const handleTaxChange = (newValue, index) => {
    const newData = [...data];
    newData[index]["tax"] = parseFloat(newValue);

    const totalTax = newData.reduce((acc, item) => acc + (item.tax || 0), 0);

    setTax(totalTax);
    setData(newData);
  };

  const handleInputChange = (e, field, index) => {
    const newData = [...data];
    newData[index][field] = e;
    if (field === 'tax_class') {
      console.log(newData);
      newData[index]["tax"] = (parseFloat(newData[index].quantity) * parseFloat(newData[index].rate) * parseFloat(e) / 100);
    } else {
      // Calculate the new tax amount
      newData[index]["tax"] = (parseFloat(newData[index].quantity) * parseFloat(newData[index].rate) * parseFloat(newData[index].tax_class) / 100);
    }
    console.log(parseFloat(newData[index].tax_class))
    // Update the amount field
    newData[index]["amount"] = (parseFloat(newData[index].rate) * parseFloat(newData[index].quantity));
    const totalTax = data.reduce((acc, item) => acc + item.tax, 0);

    setTax(totalTax);
    setData(newData);
  };

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchCustomers();
    fetchServices();
    getTotal();
  }, []);

  useEffect(() => {
    getTotal();
  }, [data, discount, discountType, TaxClass]);

  function confirmBack() {
    let msg = t("Once Back, you will not be able to revert this Invoice!")
    Alerts.swalConfirmGeneralAlert(msg).then(res => {
      console.log("Resp:-", res)
      if (res === true) {
        navigate(-1)
      }
    })
  };

  const [customerNotes, setCustomerNotes] = useState(localStorage.getItem('default_notes')); // Initialize the state variable with an empty string


  function fetchTaxClasses() {

    var getData = {
      functionName: "getAllClasses",
      postData: {
        "sort": { _id: -1 },
        "offset": 0,
        "limit": 1000,
        "filter": {}
      },
    }
    CustomerService.getAllClasses(getData, authData.sessionID).then((response) => {
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      } else {
        if (response.data && response.data.data) {
          setTaxClasses(response.data.data);
          console.log(response.data)
        }
      }
    }, (error) => {
      const errContent =
        (error.response && error.response.data) || error.message || error.toString();
      if (errContent) {
        Alerts.swalErrorAlert(errContent);
      } else {
        Alerts.swalErrorAlert(t("Server Error, Please try again later."));
      }
    });
  }
  const handleCustomerNotesChange = (event) => {
    // Update the state variable when the user types in the textarea
    setCustomerNotes(event.target.value);
  };
  function getTotal() {
    const totalAmount = data.reduce((total, invoice) => {
      const amount = parseFloat(invoice.amount);
      return !isNaN(amount) ? total + amount : total;
    }, 0);
    if (discountType === '%') {
      const discountPercentage = parseFloat(discount) / 100;
      const discountAmount = totalAmount * discountPercentage;
      const finalAmount = totalAmount - discountAmount;
      setDiscount_Amount(discountAmount);
      setTotal_amount(finalAmount);
      setSubtotal(parseFloat(totalAmount).toFixed(2));
    } else {
      const discountPercentage = parseFloat(discount);
      const discountAmount = discountPercentage;
      const finalAmount = totalAmount - discountAmount;
      setDiscount_Amount(discountAmount);
      setTotal_amount(finalAmount);
      setSubtotal(parseFloat(totalAmount).toFixed(2));

    }
  }
  function fetchServices() {
    const stored_user = JSON.parse(localStorage.getItem("user"));
    let business = []
    if (stored_user.businesses) {
      business = stored_user.businesses
    }
    var getData = {
      functionName: "GetItems",
      postData: {
        "sort": { _id: -1 },
        "offset": 0,
        "limit": 1000,
        "filter": {},
        "business": business
      },
    }

    CustomerService.GetItems(getData, authData.sessionID).then((response) => {
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        if (response.data) {
          const data = transformDataForServices(response.data.data);

          setServices(data);
        }
      }
    },
      (error) => {
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };




  function fetchCustomers() {
    var getData = {
      functionName: "GetInvoiceTo",
      postData: {
        sort: { _id: -1 },
        offset: 0,
        limit: 1000,
        filter: {
          user_type: localStorage.getItem("invoice_to").split(','),
        },
        TableName: 'users',
        TableField: localStorage.getItem("invoice_to_field")
      },
    };
    CustomerService.GetInvoiceTo(getData, authData.sessionID).then((response) => {
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        if (response.data) {
          const data = transformDataForSelect(response.data.data);

          setCustomers(data);
        }
      }
    },
      (error) => {
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  const transformDataForSelect = (data) => {
    let getTableField = localStorage.getItem('invoice_to_field');
    console.log('getTableField', getTableField)
    return data.map((item) => ({
      label: item[getTableField],
      value: item._id,
    }));
  };
  const transformDataForServices = (data) => {
    return data.map((item) => ({
      label: item.item_name,
      description: item.description,
      price: item.price,
      value: item._id,
    }));
  };

  function selectChanged(type, ind, ev) {
    let _id;
    if (type === "services") {
      Services.forEach((service) => {
        if (service.name === ev.target.value) {
          _id = service._id;
        }
      })
    }
    const updatedOrderDetails = [...orderDetails];
    const newValue = { [type]: _id };
    updatedOrderDetails[ind] = {
      ...updatedOrderDetails[ind],
      ...newValue,
    };
    setOrderDetails(updatedOrderDetails);
  }

  const onSubmit = (values) => {

    let business_id = values.business_id
    let category = values.category
    delete values.business_id
    delete values.category
    delete values.invoice_to;
    delete values.discount;
    delete values.discount_type;

    const Order = values.order;
    delete values.order;

    var payloadData = {
      functionName: "AddInvoice",
      customer_id: selectedCustomer.value,
      invoice_to: selectedCustomer.label,
      order_no: Order,
      invoice_number: invoiceNumber,
      invoice_date: invoiceDate,
      due_date: DueDate,
      discountType: discountType,
      item: data,
      business_id: business_id,
      category: category,
      sub_total: parseFloat(subtotal).toFixed(2),
      discount: discount,
      tax: tax,
      amount: parseFloat(amount).toFixed(2),
      total_amount: parseFloat(total_amount + tax).toFixed(2),
      remaining_amount: parseFloat(total_amount + tax).toFixed(2),
      discount_amount: parseFloat(discount_Amount).toFixed(2),
      comments: customerNotes,
      currency: localStorage.getItem('currency'),
      paymentMethod: localStorage.getItem('payment_method'),
      invoice_list_data: values

    }
    console.log("Payload:-", payloadData)
    setLoading(true);
    CustomerService.AddInvoice(payloadData, authData.sessionID).then((response) => {
      console.log("API Response:-", response);
      setLoading(false);
      if (response.data.status) {
        if (state?.check) {
          localStorage.setItem('tab5', state.check);
        } else {
          localStorage.setItem('tab5', "1");
        }
        navigate(-1)
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
      }

      else {
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't add fleet at the moment. Please try again later."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Network Operation failed."))
      });

  }
  const validateFtn = (values) => {
    const errors = {};
    if (!values.invoice_to) {
      errors.invoice_to = "Customer Required.";
    }

    return errors;
  };



  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className=" bg-light-gray bg-animation">
            <div className="d-flex  justify-content-center align-items-center">
              <Col md="12" className="mx-auto app-login-box">

                <div className="app-page-title">
                  <div className="page-title-wrapper">
                    <div className="page-title-heading fw-bold">
                      <div className="page-title-icon">
                        <i className="pe-7s-news-paper icon-gradient bg-tempting-azure" />
                      </div>
                      <div>
                        {t("New Invoice")}
                        <div className="page-title-subheading">
                          {t('Add a New Invoice against an order!')}
                        </div>

                      </div>
                    </div>
                    <div className="page-title-actions">
                      <button type="button" onClick={() => {
                        if (state?.check) {
                          localStorage.setItem('tab5', state.check);
                        } else {
                          localStorage.setItem('tab5', "1");
                        }
                        navigate(-1)
                      }} className="btn btn-outline-success btn-hover-shine me-3">
                        Back
                      </button>
                    </div>
                  </div>
                </div>

                <Card className="main-card mb-3">
                  <CardBody>
                    <Row>
                      <Col md="12" className="pt-3">
                        <LoadingOverlay tag="div" active={loading}
                          styles={{ overlay: (base) => ({ ...base }) }}
                          spinner={<DNALoader />}>
                          <Form
                            onSubmit={onSubmit}
                            validate={validateFtn}
                            render={({ handleSubmit, values, submitting, validating, valid }) => (
                              <form onSubmit={handleSubmit}>
                                <Row>
                                  <Col md="12" >
                                    <Row style={{}} className="align-items-center">
                                      <Col md="2"><Label className="pb-1">{t("Customer Name")}</Label></Col>
                                      <Col md="4">
                                        <FormGroup>
                                          <Field name="invoice_to" component={renderSelect}
                                            selectOptions={Customers.value} />
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </Col>

                                  <Col md="6" >
                                    <Row className="align-items-center">
                                      <Col md="4">
                                        <Label className="pb-1">{t("Invoice #")}</Label>
                                      </Col>
                                      <Col md="8">
                                        <FormGroup className="d-flex align-items-center">
                                          <Input type="text" value={invoiceNumber} readOnly />
                                        </FormGroup>
                                      </Col>

                                    </Row>
                                  </Col>
                                  <Col md="4" >
                                    <Row className="align-items-center">
                                      <Col md="4">
                                        <Label className="pb-1">{("Invoice For")}</Label>
                                      </Col>
                                      <Col md="8">
                                        <FormGroup className=" align-items-left">
                                          <Field name="order">
                                            {({ input, meta }) => (
                                              <div>
                                                <Input
                                                  {...input}
                                                  type="text"
                                                  invalid={meta.error && meta.touched}
                                                />
                                                {meta.error && meta.touched && (
                                                  <span className="text-danger">{meta.error}</span>
                                                )}
                                              </div>
                                            )}
                                          </Field>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col md="6" >
                                    <Row className="align-items-center">
                                      <Col md="4"><Label className="">{t("Invoice Date")}</Label></Col>
                                      <Col md="8">
                                        <FormGroup>
                                          <Field name="invoice_date">
                                            {({ input, meta }) => (
                                              <div>
                                                <Input
                                                  {...input}
                                                  type="date"
                                                  invalid={meta.error && meta.touched}
                                                  value={invoiceDate}
                                                  onChange={(e) => {
                                                    setInvoiceDate(e.target.value);
                                                    input.onChange(e);
                                                  }}
                                                />
                                                {meta.error && meta.touched && (
                                                  <span className="text-danger">{meta.error}</span>
                                                )}
                                              </div>
                                            )}
                                          </Field>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </Col>

                                  <Col md="4"   >
                                    <Row className="align-items-center">
                                      <Col md="4"><Label className="">{t("Due Date")}</Label></Col>
                                      <Col md="8">
                                        <FormGroup>
                                          <Field name="due_date">
                                            {({ input, meta }) => (
                                              <div>
                                                <Input
                                                  {...input}
                                                  onChange={(e) => {
                                                    setDueDate(e.target.value);
                                                    input.onChange(e);
                                                  }}
                                                  type="date"
                                                  invalid={meta.error && meta.touched}
                                                  min={getCurrentDate()}
                                                  value={DueDate}
                                                />
                                                {meta.error && meta.touched && (
                                                  <span className="text-danger">{meta.error}</span>
                                                )}
                                              </div>
                                            )}
                                          </Field>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col md="6" >
                                    <Row className="align-items-center">
                                      <Col md="4"><Label className="">{t("Business")}</Label></Col>
                                      <Col md="8">
                                        <FormGroup>
                                          <Field name='business_id'>
                                            {({ input, meta }) => (
                                              <div>
                                                <Input
                                                  {...input}
                                                  type={'select'}
                                                  placeholder='Select Business'
                                                  invalid={meta.error && meta.touched}
                                                >
                                                  <option disabled={false} value="">{t('Select Business')}</option>
                                                  {AllBusinesses.map((option) => (
                                                    <option key={option._id} value={option._id}>{option.name}</option>
                                                  ))}
                                                </Input>
                                                {meta.error && meta.touched && (
                                                  <span className="text-danger">{meta.error}</span>
                                                )}
                                              </div>
                                            )}
                                          </Field>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col md="4" >
                                    <Row className="align-items-center">
                                      <Col md="4"><Label className="">{t("Category")}</Label></Col>
                                      <Col md="8">
                                        <FormGroup>
                                          <Field name='category'>
                                            {({ input, meta }) => (
                                              <div>
                                                <Input
                                                  {...input}
                                                  type={'select'}
                                                  placeholder='Select Category'
                                                  invalid={meta.error && meta.touched}
                                                >
                                                  <option disabled={false} value="">{t('Select Category')}</option>
                                                  {AllCategories.map((option) => (
                                                    <option key={option._id} value={option._id}>{option.Name}</option>
                                                  ))}
                                                </Input>
                                                {meta.error && meta.touched && (
                                                  <span className="text-danger">{meta.error}</span>
                                                )}
                                              </div>
                                            )}
                                          </Field>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col md="12" >
                                    <Row className="align-items-center">
                                      <Col md="6" >
                                        {fieldlist && fieldlist.map((field, index) => {
                                          if (field.show_in === 'Invoice List' && field.status === 'Active') {
                                            if (field.field_type === 'text' || field.field_type === 'email' || field.field_type === 'number' || field.field_type === 'password') {
                                              return (
                                                <>
                                                  <Row md={12} >
                                                    <Col md="4"><Label className="">{t(field.field_lable)}</Label></Col>
                                                    <Col md="8" >
                                                      <FormGroup className=" align-items-left">
                                                        <Field name={field.referal_name}>
                                                          {({ input, meta }) => (
                                                            <div>
                                                              <Input
                                                                {...input}
                                                                type={field.field_type}
                                                                placeholder={field.field_lable}
                                                                invalid={meta.error && meta.touched}
                                                              />
                                                              {meta.error && meta.touched && (
                                                                <span className="text-danger">{meta.error}</span>
                                                              )}
                                                            </div>
                                                          )}
                                                        </Field>
                                                      </FormGroup>
                                                      {/* <Input type={field.field_type} className="form-control" placeholder={field.field_lable} name={field.referal_name} id={field.referal_name} /> */}
                                                    </Col  >
                                                  </Row >
                                                </>
                                              )
                                            }
                                            else if (field.field_type === 'dropdown') {

                                              return (
                                                <>
                                                  <Row md={12} >
                                                    <Col md="4"><Label className="">{t(field.field_lable)}</Label></Col>
                                                    <Col md="8" >
                                                      <FormGroup>
                                                        <Field name={field.referal_name}>
                                                          {({ input, meta }) => (
                                                            <div>
                                                              <Input
                                                                {...input}
                                                                type={'select'}
                                                                placeholder={field.field_lable}
                                                                invalid={meta.error && meta.touched}
                                                              >
                                                                <option disabled={false} value="">{t('--Choose an option--')}</option>
                                                                {field.options.map((option, optionsindex) => (
                                                                  <option key={field.referal_name + optionsindex} value={option.value}>{option.label}</option>
                                                                ))}
                                                              </Input>
                                                              {meta.error && meta.touched && (
                                                                <span className="text-danger">{meta.error}</span>
                                                              )}
                                                            </div>
                                                          )}
                                                        </Field>
                                                      </FormGroup>
                                                    </Col  >
                                                  </Row >
                                                </>
                                              )
                                            }
                                            else if (field.field_type === 'radio' || field.field_type === 'checkbox') {
                                              return (
                                                <>

                                                  <Row md={12} >
                                                    <Col md="4"><Label className="">{t(field.field_lable)}</Label></Col>
                                                    <Col md="8" >
                                                      <FormGroup>
                                                        <Field name={field.referal_name}>
                                                          {({ input, meta }) => (
                                                            <div>
                                                              <ul className="child mb-0 p-0">
                                                                {field.options.map((option, optionsindex) => (
                                                                  <li className="form-check form-check-inline">
                                                                    <Input {...input} className="form-check-input"
                                                                      type={field.field_type}
                                                                      value={option.label} name={field.referal_name} id={field.referal_name + optionsindex}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="users">
                                                                      {option.label}
                                                                    </label>
                                                                  </li>
                                                                ))}
                                                              </ul>
                                                              {meta.error && meta.touched && (
                                                                <span className="text-danger">{meta.error}</span>
                                                              )}
                                                            </div>
                                                          )}
                                                        </Field>
                                                      </FormGroup>

                                                    </Col>
                                                  </Row >
                                                </>
                                              )
                                            }
                                            else {
                                              return (
                                                <>
                                                  <Row md={12} >
                                                    <Col md="4"><Label className="">{t(field.field_lable)}</Label></Col>
                                                    <Col md="8" >
                                                      <Input type="text" className="form-control" placeholder={field.field_lable} name={field.referal_name} id={field.referal_name} />
                                                    </Col  >
                                                  </Row >
                                                </>
                                              )
                                            }
                                          }

                                        })}
                                      </Col>
                                    </Row>
                                  </Col>

                                  <Divider sx={{ marginBottom: "20px", marginTop: "10px" }} />

                                  <div>
                                    <table className="custom-table">
                                      <thead>
                                        <tr>
                                          <th>{t("Item")}</th>
                                          <th>{t("Item Details")}</th>

                                          {fieldlist && fieldlist.map((field, index) => {
                                            if (field.show_in === 'Items List' && field.status === 'Active') {
                                              if (field.item_in_invoice === 'Both' || field.item_in_invoice === 'Invoice Items') {
                                                return (
                                                  <>
                                                    <th>{t(field.field_lable)}</th>
                                                  </>
                                                )
                                              }
                                            }
                                          })}
                                          <th>{t("Type")}</th>
                                          <th>{t("Quantity")}</th>
                                          <th>{t("Price (Per Unit)")}</th>
                                          <th>{`Tax`}</th>
                                          <th>{`Amount (${localStorage.getItem('currency')})`}</th>
                                          <th>{t("Actions")}</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {data.map((item, index) => (
                                          <tr key={item.id}>
                                            <td >
                                              {bulkEditMode || editableRowIndex === index ? (
                                                <FormGroup>
                                                  <Field name="item_name">
                                                    {({ input, meta }) => (
                                                      <div>
                                                        <>
                                                          <Input
                                                            type="text"
                                                            {...input}
                                                            value={item.item_name}
                                                            placeholder="Item Name"
                                                            autoComplete="off"
                                                            onChange={(e) => {
                                                              handleInputChange(e.target.value, 'item_name', index);
                                                              selectChanged("services", index, e);
                                                              Services.forEach((service) => {
                                                                if (service.label === e.target.value) {
                                                                  item.description = service.description;
                                                                  item.rate = service.price;
                                                                  handleInputChange(item.rate, 'rate', index)
                                                                }
                                                              })
                                                            }

                                                            }
                                                            list="servicesList"
                                                          />

                                                          <datalist id="servicesList">
                                                            {Services.map((option) => (
                                                              <option key={option.label} value={option.label}>
                                                                {option.label}
                                                              </option>
                                                            ))}
                                                          </datalist>
                                                        </>
                                                        {meta.error && meta.touched && (
                                                          <span className="text-danger">{meta.error}</span>
                                                        )}
                                                      </div>
                                                    )}
                                                  </Field>
                                                </FormGroup>
                                              ) : (
                                                item.item_name
                                              )}
                                            </td>
                                            <td >
                                              {bulkEditMode || editableRowIndex === index ? (
                                                <div>
                                                  <FormGroup >
                                                    <Field name="item_name">
                                                      {({ input, meta }) => (
                                                        <div>
                                                          <>
                                                            <Input
                                                              type="text"
                                                              {...input}
                                                              value={item.description}
                                                              placeholder="Item Details"
                                                              autoComplete="off"
                                                              onChange={(e) => {
                                                                handleInputChange(e.target.value, 'description', index)
                                                                selectChanged("packaging", index, e);
                                                              }
                                                              }
                                                            // list="descriptionList"
                                                            />

                                                            {/* <datalist id="descriptionList">
                                                              {Packaging.map((option) => (
                                                                <option key={option.label} value={option.label}>
                                                                  {option.name}
                                                                </option>
                                                              ))}
                                                            </datalist> */}
                                                          </>
                                                          {meta.error && meta.touched && (
                                                            <span className="text-danger">{meta.error}</span>
                                                          )}
                                                        </div>
                                                      )}
                                                    </Field>
                                                  </FormGroup>
                                                </div>

                                              ) : (
                                                <div style={{ width: "100%" }}>
                                                  {` ${item.description}`}
                                                </div>

                                              )}
                                            </td>

                                            {fieldlist && fieldlist.map((field, ind) => {
                                              if (field.show_in === 'Items List' && field.status === 'Active') {
                                                if (field.item_in_invoice === 'Both' || field.item_in_invoice === 'Invoice Items') {
                                                  if (field.field_type === 'text' || field.field_type === 'email' || field.field_type === 'number' || field.field_type === 'password') {
                                                    return (
                                                      <td key={`field-${ind}`}>
                                                        {bulkEditMode || editableRowIndex === index ? (
                                                          <div>
                                                            <FormGroup style={{ width: "100%" }}>
                                                              <Field name={field.referal_name}>
                                                                {({ input, meta }) => (
                                                                  <div>
                                                                    <Input
                                                                      type={field.field_type}
                                                                      {...input}
                                                                      value={item[field.referal_name]}
                                                                      placeholder={field.field_lable}
                                                                      autoComplete="off"
                                                                      onChange={(e) => handleInputChange(e.target.value, field.referal_name, index)}
                                                                    />
                                                                    {meta.error && meta.touched && (
                                                                      <span className="text-danger">{meta.error}</span>
                                                                    )}
                                                                  </div>
                                                                )}
                                                              </Field>
                                                            </FormGroup>
                                                          </div>
                                                        ) : (
                                                          <div style={{ width: "100%" }}>
                                                            {`${item[field.referal_name]}`}
                                                          </div>
                                                        )}
                                                      </td>
                                                    )
                                                  }
                                                  else if (field.field_type === 'dropdown') {
                                                    return (
                                                      <td key={`field-${ind}`}>
                                                        {bulkEditMode || editableRowIndex === index ? (
                                                          <div>
                                                            <FormGroup style={{ width: "100%" }}>
                                                              <Field name={field.referal_name}>
                                                                {({ input, meta }) => (
                                                                  <div>
                                                                    <Input
                                                                      {...input}
                                                                      type='select'
                                                                      placeholder={field.field_lable}
                                                                      invalid={meta.error && meta.touched}
                                                                    >
                                                                      <option disabled value="">{t('--Choose an option--')}</option>
                                                                      {field.options.map((option, optionsindex) => (
                                                                        <option key={`${field.referal_name}-${optionsindex}`} value={option.value}>{option.label}</option>
                                                                      ))}
                                                                    </Input>
                                                                    {meta.error && meta.touched && (
                                                                      <span className="text-danger">{meta.error}</span>
                                                                    )}
                                                                  </div>
                                                                )}
                                                              </Field>
                                                            </FormGroup>
                                                          </div>
                                                        ) : (
                                                          <div style={{ width: "100%" }}>
                                                            {`${item[field.referal_name]}`}
                                                          </div>
                                                        )}
                                                      </td>
                                                    )
                                                  }
                                                  else if (field.field_type === 'radio' || field.field_type === 'checkbox') {
                                                    return (
                                                      <td key={`field-${ind}`}>
                                                        {bulkEditMode || editableRowIndex === index ? (
                                                          <div>
                                                            <FormGroup style={{ width: "100%" }}>
                                                              <ul className="child mb-0 p-0">
                                                                {field.options.map((option, optionsindex) => (
                                                                  <li key={`${field.referal_name}-${optionsindex}`} className="form-check form-check-inline">
                                                                    <input
                                                                      className="form-check-input"
                                                                      type={field.field_type}
                                                                      value={option.value}
                                                                      name={field.referal_name}
                                                                      id={`${field.referal_name}-${optionsindex}`}
                                                                    />
                                                                    <label className="form-check-label" htmlFor={`${field.referal_name}-${optionsindex}`}>
                                                                      {option.label}
                                                                    </label>
                                                                  </li>
                                                                ))}
                                                              </ul>
                                                            </FormGroup>
                                                          </div>
                                                        ) : (
                                                          <div style={{ width: "100%" }}>
                                                            {`${item[field.referal_name]}`}
                                                          </div>
                                                        )}
                                                      </td>
                                                    )
                                                  }
                                                  else {
                                                    return (
                                                      <td key={`field-${ind}`}>
                                                        {bulkEditMode || editableRowIndex === index ? (
                                                          <div>
                                                            <FormGroup style={{ width: "100%" }}>
                                                              <Input
                                                                type={field.field_type}
                                                                className="form-control"
                                                                placeholder={field.field_lable}
                                                                name={field.referal_name}
                                                                id={field.referal_name}
                                                              />
                                                            </FormGroup>
                                                          </div>
                                                        ) : (
                                                          <div style={{ width: "100%" }}>
                                                            {`${item[field.referal_name]}`}
                                                          </div>
                                                        )}
                                                      </td>
                                                    )
                                                  }
                                                }
                                              }
                                            })}
                                            <td>
                                              {bulkEditMode || editableRowIndex === index ? (
                                                <div>
                                                  <FormGroup >
                                                    <Field name="item_name">
                                                      {({ input, meta }) => (
                                                        <div>
                                                          <>
                                                            <select
                                                              value={item.item_type}
                                                              onChange={(e) => handleInputChange(e.target.value, 'item_type', index)}
                                                              className="form-select" name='item_type' id='item_type'    >
                                                              <option disabled={false} value="">{t('--Choose an option--')}</option>
                                                              <option key='Service' value='Service'>{t("Service")}</option>
                                                              <option key='Goods' value='Goods'>{t("Goods")}</option>
                                                            </select>
                                                            {meta.error && meta.touched && (
                                                              <span className="text-danger">{meta.error}</span>
                                                            )}
                                                          </>
                                                        </div>
                                                      )}
                                                    </Field>
                                                  </FormGroup>
                                                </div>
                                              ) : (
                                                item.item_type
                                              )}
                                            </td>
                                            <td>
                                              {bulkEditMode || editableRowIndex === index ? (
                                                <div>
                                                  <FormGroup >
                                                    <Field name="item_name">
                                                      {({ input, meta }) => (
                                                        <div>
                                                          <>
                                                            <Input
                                                              type="number"
                                                              value={item.quantity}
                                                              onChange={(e) => handleInputChange(e.target.value, 'quantity', index)}
                                                            />
                                                            {meta.error && meta.touched && (
                                                              <span className="text-danger">{meta.error}</span>
                                                            )}
                                                          </>
                                                        </div>
                                                      )}
                                                    </Field>
                                                  </FormGroup>
                                                </div>
                                              ) : (
                                                item.quantity
                                              )}
                                            </td>
                                            <td >
                                              {bulkEditMode || editableRowIndex === index ? (
                                                <div>
                                                  <FormGroup >
                                                    <Field name="item_name">
                                                      {({ input, meta }) => (
                                                        <div>
                                                          <>
                                                            <Input
                                                              type="number"
                                                              {...input}
                                                              value={item.rate}
                                                              placeholder="Rate"
                                                              autoComplete="off"
                                                              onChange={(e) => handleInputChange(e.target.value, 'rate', index)}
                                                            />
                                                          </>
                                                          {meta.error && meta.touched && (
                                                            <span className="text-danger">{meta.error}</span>
                                                          )}
                                                        </div>
                                                      )}
                                                    </Field>
                                                  </FormGroup>
                                                </div>

                                              ) : (
                                                <div style={{ width: "100%" }}>
                                                  {` ${item.rate}`}
                                                </div>

                                              )}
                                            </td>
                                            {/* <td>
                                              {editableRowIndex === index ? (
                                                <input
                                                  type="number"
                                                  value={parseFloat(item.rate).toFixed(2)}
                                                  onChange={(e) => handleInputChange(e, 'rate', index)}
                                                />
                                              ) : (
                                                parseFloat(item.rate).toFixed(2)
                                              )}
                                            </td> */}
                                            <td>
                                              {bulkEditMode || editableRowIndex === index ? (
                                                <div style={{ display: 'flex' }}>
                                                  <div style={{ width: "80px", textAlign: 'left', display: 'inline-block' }}>
                                                    <FormGroup>
                                                      <Field name="item_name">
                                                        {({ input, meta }) => (
                                                          <div>
                                                            <Input
                                                              {...input}
                                                              type="text"
                                                              onChange={(e) => handleTaxChange(e.target.value, index)}
                                                              style={{
                                                                borderRadius: '10px 0 0 10px',
                                                                borderRight: 'none',
                                                              }}
                                                              value={item.tax}
                                                              invalid={meta.error && meta.touched}
                                                            />
                                                            {meta.error && meta.touched && (
                                                              <span className="text-danger">{meta.error}</span>
                                                            )}
                                                          </div>
                                                        )}
                                                      </Field>
                                                    </FormGroup>
                                                  </div>
                                                  <div style={{ width: "120px", textAlign: 'left', display: 'inline-block' }}>
                                                    <FormGroup>
                                                      <Field name="tax_class">
                                                        {({ input, meta }) => (
                                                          <div>
                                                            <Input
                                                              {...input}
                                                              type="select"
                                                              style={{
                                                                borderRadius: '0 10px 10px 0',
                                                                backgroundColor: '#F4F6F5'
                                                              }}
                                                              // value={input.value}
                                                              value={input.tax_class}
                                                              onChange={(e) => {
                                                                handleInputChange(e.target.value, 'tax_class', index);
                                                                // setTaxClass(e.target.value);
                                                                // input.onChange(e);
                                                              }}
                                                              invalid={meta.error && meta.touched}
                                                            >
                                                              {TaxClasses.map((tax) => (
                                                                <option key={tax.percentage} value={tax.percentage}>
                                                                  {`${tax.percentage}% ${tax.name}`}
                                                                </option>
                                                              ))}
                                                            </Input>
                                                            {meta.error && meta.touched && (
                                                              <span className="text-danger">{meta.error}</span>
                                                            )}
                                                          </div>
                                                        )}
                                                      </Field>
                                                    </FormGroup>

                                                  </div>
                                                  {/* <FormGroup >
                                                    <Field name="item_name">
                                                      {({ input, meta }) => (
                                                        <div>
                                                          <>
                                                            <Input
                                                              type="number"
                                                              value={item.tax}
                                                              onChange={(e) => handleTaxChange(e.target.value, index)}
                                                            />
                                                            {meta.error && meta.touched && (
                                                              <span className="text-danger">{meta.error}</span>
                                                            )}
                                                          </>
                                                        </div>
                                                      )}
                                                    </Field>
                                                  </FormGroup> */}
                                                </div>
                                              ) : (
                                                parseFloat(item.tax).toFixed(2)

                                              )}
                                            </td>
                                            <td>
                                              {bulkEditMode || editableRowIndex === index ? (
                                                <div>
                                                  <FormGroup >
                                                    <Field name="item_name">
                                                      {({ input, meta }) => (
                                                        <div>
                                                          <>
                                                            <Input
                                                              disabled
                                                              type="number"
                                                              value={parseFloat(item.amount).toFixed(2)}
                                                              onChange={(e) => handleInputChange(e.target.value, 'amount', index)}
                                                            />
                                                            {meta.error && meta.touched && (
                                                              <span className="text-danger">{meta.error}</span>
                                                            )}
                                                          </>
                                                        </div>
                                                      )}
                                                    </Field>
                                                  </FormGroup>
                                                </div>
                                              ) : (
                                                parseFloat(item.amount).toFixed(2)

                                              )}
                                            </td>
                                            <td>
                                              {bulkEditMode || editableRowIndex === index ? (
                                                <div>
                                                  <FormGroup >
                                                    <div>
                                                      <>
                                                        <Tooltip placement="top" title="Save" arrow>
                                                          <i onClick={() => handleSaveClick(index)} className="pe-7s-edit btn-icon-wrapper text-info"> </i>
                                                        </Tooltip>
                                                      </>
                                                    </div>
                                                  </FormGroup>
                                                </div>
                                              ) : (
                                                <>
                                                  <Tooltip placement="top" title="Edit" arrow>
                                                    <i onClick={() => handleEditClick(index)} className="pe-7s-pen btn-icon-wrapper text-info"> </i>
                                                  </Tooltip>
                                                  <Tooltip placement="top" title="Delete" arrow>
                                                    <i onClick={() => handleDeleteClick(index)} className="pe-7s-trash text-danger btn-icon-wrapper"> </i>
                                                  </Tooltip>
                                                </>)}
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>

                                  <Col md="12" >
                                    <Row className="align-items-center">
                                      <Col md="6">
                                        <button type="button" onClick={handleAddRow} className="btn btn-secondary btn-lg btn-hover-shine me-3">
                                          Add New Item
                                        </button>
                                        <button type="button" onClick={() => handleEditClick()} className="btn btn-secondary btn-lg btn-hover-shine me-3">
                                          {bulkEditMode ? 'Save Bulk Update' : 'Bulk Update'}
                                        </button>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Divider sx={{ marginBottom: "20px", marginTop: "10px" }} />

                                </Row>

                                <div style={{ textAlign: 'right', marginBottom: '20px' }}>
                                  <table style={{
                                    width: '100%'
                                  }}>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <div style={{
                                            fontWeight: '600px', backgroundColor: 'var(--topbar-color, #1e6cd2)',
                                            width: "550px", textAlign: 'left', padding: '5px', display: 'inline-block',
                                            color: '#fff'
                                          }}>
                                            Invoice Amount Summary
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div style={{ width: "430px", textAlign: 'left', padding: '5px', display: 'inline-block' }}>
                                            Subtotal:
                                          </div>
                                          <div style={{ width: "110px", textAlign: 'left', padding: '5px', display: 'inline-block' }}>
                                            {`${parseFloat(subtotal).toFixed(2)}`}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div style={{ width: "250px", textAlign: 'left', padding: '5px', display: 'inline-block' }}>
                                            Discount:
                                          </div>
                                          <div style={{ width: "100px", textAlign: 'left', display: 'inline-block' }}>
                                            <FormGroup>
                                              <Field name="discount">
                                                {({ input, meta }) => (
                                                  <div>
                                                    <Input
                                                      {...input}
                                                      type="text"
                                                      style={{
                                                        borderRadius: '10px 0 0 10px',
                                                        borderRight: 'none',
                                                      }}
                                                      value={discount}
                                                      onChange={(e) => {
                                                        setDiscount(e.target.value);
                                                        input.onChange(e);
                                                      }}
                                                      invalid={meta.error && meta.touched}
                                                    />
                                                    {meta.error && meta.touched && (
                                                      <span className="text-danger">{meta.error}</span>
                                                    )}
                                                  </div>
                                                )}
                                              </Field>
                                            </FormGroup>
                                          </div>
                                          <div style={{ width: "80px", textAlign: 'left', display: 'inline-block' }}>
                                            <FormGroup>
                                              <Field name="discount_type">
                                                {({ input, meta }) => (
                                                  <div>
                                                    <Input
                                                      {...input}
                                                      type="select"
                                                      style={{
                                                        borderRadius: '0 10px 10px 0',
                                                        backgroundColor: '#F4F6F5'
                                                      }}
                                                      value={input.value}
                                                      onChange={(e) => {
                                                        input.onChange(e);
                                                        setDiscountType(e.target.value);
                                                      }}
                                                      invalid={meta.error && meta.touched}
                                                    >
                                                      {/* Place the options inside the select input */}
                                                      <option key='%' value='%'>{'%'}</option>
                                                      <option key={localStorage.getItem('currency')} value={localStorage.getItem('currency')}>
                                                        {localStorage.getItem('currency')}
                                                      </option>
                                                    </Input>
                                                    {meta.error && meta.touched && (
                                                      <span className="text-danger">{meta.error}</span>
                                                    )}
                                                  </div>
                                                )}
                                              </Field>
                                            </FormGroup>

                                          </div>

                                          <div style={{ width: "110px", textAlign: 'left', padding: '5px', display: 'inline-block' }}>
                                            {parseFloat(discount_Amount).toFixed(2)}({localStorage.getItem('currency')})
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div style={{ width: "430px", textAlign: 'left', padding: '5px', display: 'inline-block' }}>
                                            Tax:
                                          </div>
                                          <div style={{ width: "110px", textAlign: 'left', padding: '5px', display: 'inline-block' }}>
                                            {`${parseFloat(tax).toFixed(2)}`}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div style={{ width: "550px", textAlign: 'left', padding: '5px', display: 'inline-block' }}>
                                            <Divider />
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div style={{ width: "430px", textAlign: 'left', padding: '5px', display: 'inline-block' }}>
                                            Total ({`${localStorage.getItem("currency")}`})
                                          </div>
                                          <div style={{ width: "110px", textAlign: 'left', padding: '5px', display: 'inline-block' }}>
                                            {parseFloat(total_amount + tax).toFixed(2)}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div style={{ width: "550px", textAlign: 'left', padding: '5px', display: 'inline-block' }}>
                                            <Divider />
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>


                                <Col md="12" >
                                  <Row className="align-items-center">
                                    <Col md="10">
                                      <Label style={{ marginTop: '20px' }} className="pb-1">Customers Notes</Label>
                                      <FormGroup  >
                                        <textarea
                                          name="customer_notes"
                                          style={{ width: '90%', height: '90px' }}
                                          placeholder="Customer Notes"
                                          value={customerNotes} // Bind the value to the state variable
                                          onChange={handleCustomerNotesChange} // Update the state when the user types
                                        />
                                        {/* You can display an error message if needed
                                          {meta.error && meta.touched && (
                                            <span className="text-danger">{meta.error}</span>
                                          )} */}
                                      </FormGroup>
                                      <Label className="pb-1">Will be Displayed on Invoice</Label>
                                    </Col>
                                  </Row>
                                </Col>

                                <Row style={{ marginTop: "20px" }}>
                                  <FormGroup>
                                    <div className="text-center">
                                      <Button type="submit" className="btn btn-secondary btn-lg btn-hover-shine me-3">
                                        Save
                                      </Button>
                                      <button type="button" onClick={() => {
                                        if (state?.check) {
                                          localStorage.setItem('tab5', state.check);
                                        } else {
                                          localStorage.setItem('tab5', "1");
                                        }

                                        confirmBack();
                                      }} className="btn btn-outline-success btn-lg btn-hover-shine me-3">
                                        Cancel
                                      </button>
                                    </div>
                                  </FormGroup>
                                </Row>
                              </form>
                            )}
                          />
                        </LoadingOverlay>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </div>

          </div >
        </div >
      </div >
    </Fragment >
  );
};
export default AddInvoice;