import React, { useState, useEffect, Fragment, useRef, memo } from "react";
import {
  Box, Avatar, Typography, Tabs, Tab, TextField, Button, Drawer, Tooltip,
  Card, CardContent, IconButton, Container, Grid, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, Visibility, VisibilityOff } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import AlertService from "../../components/Alerts/alerts";
import axios from "axios";
import facebook from "../../assets/facebook.svg";
import copy from "../../assets/copy.svg";
import email from "../../assets/email.svg";
import google from "../../assets/google.svg";
import linkedin from "../../assets/linkedin.svg";
import twitter from "../../assets/twitter.svg";
import pintrest from "../../assets/pintrest.svg";
import whatsapp from "../../assets/whatsApp.svg";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Autocomplete from '@mui/material/Autocomplete';

import Alerts from "../../components/Alerts/alerts";
import Profile from "./Profile";
import AuthService from "../Authentication/auth.service";
import AddressService from './addressService';
import TwoFactorAuth from './TwoFactorAuth';
import styled from "styled-components";
const FRONT_URL = process.env.REACT_APP_FRONT_URL
const B_URL = process.env.REACT_APP_LOGINURL


const headers = {
  'Content-Type': 'application/json',
  'token': localStorage.getItem("session-id")
};

const StyledTab = styled(Tab)(({ theme }) => ({
  color: theme.palette.text.secondary,
  '&.Mui-selected': {
    color: theme.palette.error.main,
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.error.main,
  color: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.error.dark,
  },
}));

const SidebarContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  background-color: #f9f9f9;
  overflow: hidden;
  border-radius: 15px;

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
  }
`;

const Sidebar = styled.div`
  flex: 1;
  background-color: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  min-width: 250px;
  @media (max-width: 768px) {
    width: 100%;
    padding: 10px;
  }

`;

const SidebarItem = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${({ active }) => (active ? "white" : "#888")};
  margin-bottom: 15px;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 10px;
  background-color: ${({ active }) => (active ? "#C1B314" : "transparent")};
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background-color: #C1B314;
    color: white;
  }
`;

const MainContent = styled.div`
  flex: 3;
  padding: 20px;
  background-color: white;
  overflow-y: auto;
  position: relative;
  @media (max-width: 768px) {
    padding: 10px;
  }
  &::before {
    content: '';
    position: absolute;
    top: 30px; 
    bottom: 30px; 
    left: 0;
    width: 1px;
    background-color: #CDCDCD; 
  }

`;

const CardStyled = styled(Card)`
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 10px;
  border: 1px solid #CDCDCD;
`;

const SidebarArrow = styled.div`
  font-size: 16px;
  color: #888;
`;

const PreviewContainer = styled(Box)`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

const PreviewImage = styled.img`
  max-width: 100%;
  max-height: 200px;
  border: 1px solid #CDCDCD;
  border-radius: 5px;
  object-fit: cover;
`;


const UploadButton = styled(Button)`
  margin-top: 15px;
  width: 100%;
  height: 60px;
  border: 1px dashed #CDCDCD;
  color: #888;
  &:hover {
    border-color: #C1B314;
  }
`;


const API_URL = process.env.REACT_APP_BASEURL;
const tabs = [
  { id: 1, label: "Profile Information" },
  { id: 2, label: "Change Account Security Password" },
  { id: 3, label: "Change Transaction E-Pin" },
  { id: 8, label: "Verify Account" },
  { id: 5, label: "Two-Factor Authentication (2FA)" },
  { id: 6, label: "Upgrade Account" },
  { id: 7, label: "Stockist Application" },
  { id: 4, label: "Referral Link" },

];
const MyProfile = () => {
  const [tabValue, setTabValue] = useState(0);
  const [userData, setUserData] = useState(JSON.parse(localStorage.getItem("user")));
  const [addresses, setAddresses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [opass, setOpass] = useState("");
  const [storeduser, setStoredUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [activeTab, setActiveTab] = useState(1);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const baseUrl = `${FRONT_URL}register_client/Members`;
  const referralId = storeduser?._id;
  const username = encodeURIComponent(storeduser?.username);
  const mobile = encodeURIComponent(storeduser?.mobileNumber);
  const [referralLink, setReferralLink] = useState(`${baseUrl}?ref=${referralId}&sponsor=${username}&contact=${mobile}&name=${storeduser?.fullName}`);
  const [Data, setData] = useState([]);

  const [passwordVisibility, setPasswordVisibility] = useState({
    newPassword: false,
    confirmPassword: false
  });







  console.log("storeduser?.transaction_password", Data?.transaction_password)
  const [oldTransactionPassword, setOldTransactionPassword] = useState('');
  const [newTransactionPassword, setTransactionNewPassword] = useState('');
  const [TransactionConfirmPassword, setTransactionConfirmPassword] = useState('');

  const [passwordTransactionVisibility, setPasswordTransactionVisibility] = useState({
    oldTransactionPassword: false,
    newTransactionPassword: false,
    TransactionConfirmPassword: false
  });

  const togglePasswordTransactionVisibility = (field) => {
    setPasswordTransactionVisibility(prev => ({
      ...prev,
      [field]: !prev[field]
    }));
  };

  const togglePasswordVisibility = (field) => {
    setPasswordVisibility(prev => ({
      ...prev,
      [field]: !prev[field]
    }));
  };





  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const headers = { "Content-Type": "application/json" };
        const payload = {
          functionName: "User_Data",
          postData: { user_id: userData._id },
        };
        const response = await axios.post(
          `${B_URL}UserModule/User_Data`,
          payload,
          { headers }
        );
        const data = response?.data?.data?.[0] || {};
        console.log("User details:", data);
        setData(data);
      } catch (error) {
        console.error("Failed to fetch user details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, [userData]);








  const handleUpdatePassword = async () => {

    if (newPassword === '' || confirmPassword === '') {
      AlertService.swalErrorAlert(t("Enter Passwords."));
      return;
    }


    if (newPassword !== confirmPassword) {
      AlertService.swalErrorAlert(t("Passwords do not match."));
      return;
    }

    setLoading(true);
    const payloadData = {
      functionName: "UpdateCustomerProfile",
      postData: {
        password: newPassword,
        _id: userData?._id
      },
    };
    SendSubmit(payloadData);
  };

  const handleUpdateTransactionPassword = async () => {


    if (Data?.transaction_password) {
      if (oldTransactionPassword === '') {
        AlertService.swalErrorAlert(t("Enter Old Password."));
        return;
      }
    }



    if (newTransactionPassword === '' || TransactionConfirmPassword === '') {
      AlertService.swalErrorAlert(t("Enter Passwords."));
      return;
    }


    if (newTransactionPassword !== TransactionConfirmPassword) {
      AlertService.swalErrorAlert(t("Passwords do not match."));
      return;
    }

    setLoading(true);
    const payloadData = {
      functionName: "UpdateTransitionPassword",
      postData: {
        is_old: Data?.transaction_password ? true : false,
        old_password: oldTransactionPassword,
        transaction_password: newTransactionPassword,
        _id: userData?._id
      },
    };
    SendSubmit(payloadData);
  };


  const SendSubmit = (payload) => {
    sendUpdateProfile(payload).then((response) => {
      setLoading(false);
      if (response.data.status) {
        saveUpdatedCreds(payload, response.data.message);
      } else {
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        } else {
          AlertService.swalErrorAlert(response.data.message || t("Error: Can't update profile at the moment. Please try again later."));
        }
      }
    }).catch((error) => {
      setLoading(false);
      AlertService.swalErrorAlert(t("Network Operation failed."));
    });
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(referralLink);
    Alerts.swalSuccessAlert("Referral link copied to clipboard!");
  };
  const handleShare = (platform) => {
    const encodedLink = encodeURIComponent(referralLink);

    let url = "";
    switch (platform) {
      case "facebook":
        url = `https://www.facebook.com/sharer/sharer.php?u=${encodedLink}`;
        break;
      case "twitter":
        url = `https://twitter.com/intent/tweet?url=${encodedLink}&text=Check%20out%20this%20amazing%20opportunity!`;
        break;
      case "linkedin":
        url = `https://www.linkedin.com/shareArticle?mini=true&url=${encodedLink}&title=Referral%20Link&summary=Check%20out%20this%20amazing%20opportunity!`;
        break;
      case "email":
        url = `mailto:?subject=Check%20this%20out!&body=Here%20is%20my%20referral%20link:%20${encodedLink}`;
        break;
      case "pinterest":
        url = `https://pinterest.com/pin/create/button/?url=${encodedLink}&description=Check%20this%20out!`;
        break;
      case "google":
        url = `https://www.google.com/search?q=${encodedLink}`;
        break;
      case "whatsapp":
        url = `https://api.whatsapp.com/send?text=Check%20out%20this%20amazing%20opportunity!%20${encodedLink}`;
        break;
      case "messenger":
        url = `https://www.messenger.com/t/?link=${encodedLink}`;
        break;
        // case "viber":
        //   // Use viber:// protocol if on mobile, fallback to web-based
        //   url = `viber://forward?text=${encodedLink}`;
        //   if (!/viber/i.test(navigator.userAgent)) {
        //     url = `https://invite.viber.com/?text=${encodedLink}`;
        //   }
        break;
      default:
        return;
    }

    window.open(url, "_blank", "noopener,noreferrer");
  };


  const VerifyAccount = () => {
    const [isDrawerOpen, setDrawerOpen] = useState(false);

    // State for uploaded files (Base64)
    const [documents, setDocuments] = useState({
      frontId: storeduser?.frontId || null,
      backId: storeduser?.backId || null,
      selfie: storeduser?.selfie || null,
    });

    const handleDrawerOpen = () => {
      setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
      setDrawerOpen(false);
      setDocuments({
        frontId: storeduser?.frontId || null,
        backId: storeduser?.backId || null,
        selfie: storeduser?.selfie || null,
      })
    };

    // Function to handle file uploads and convert to Base64
    const handleFileChange = (event, field) => {
      const file = event.target.files[0];
      if (file) {
        // Validate file type (optional)
        const validTypes = ["image/jpeg", "image/png", "image/jpg"];
        if (!validTypes.includes(file.type)) {
          alert("Please upload an image file (jpg, jpeg, or png).");
          return;
        }

        // Validate file size (optional, e.g., max 5MB)
        const maxSize = 5 * 1024 * 1024;
        if (file.size > maxSize) {
          alert("File size should be less than 5MB.");
          return;
        }

        const reader = new FileReader();
        reader.onload = () => {
          setDocuments((prev) => ({
            ...prev,
            [field]: reader.result, // Store Base64 string
          }));
        };
        reader.readAsDataURL(file);
      }
    };

    // Function to handle form submission
    const handleSubmit = async () => {
      // Check if all documents are uploaded
      if (!documents.frontId || !documents.backId || !documents.selfie) {
        Alerts.swalErrorAlert("Please upload all required documents.");
        return;
      }
      // Here you can send the documents to the server
      console.log("Submitting documents:", documents);
      try {
        let payload = {
          functionName: 'SubmitVerificationDocs',
          postData: {
            user_id: storeduser._id,
            frontId: documents.frontId,
            backId: documents.backId,
            selfie: documents.selfie,
          }
        }
        const response = await AddressService.SubmitVerificationDocs(payload);
        if (response.data.status) {
          AlertService.swalSuccessAlert(t("Document Upload for Verification, Wait for admin approval."));
          setDrawerOpen(false);
        } else {
          AlertService.swalErrorAlert(response.data.message || t("Error updating detailed information."));
          setDrawerOpen(false);
        }
      } catch (error) {
        setDrawerOpen(false);
        console.error("Error submitting user info:", error);
        AlertService.swalErrorAlert(t("Error updating detailed information. Please try again."));
      } finally {
        setDrawerOpen(false);
      }
    };

    return (
      <>
        {/* Verify Account Card */}
        <CardStyled>
          <CardContent>
            <Typography variant="h6" fontWeight="bold">
              Verify Account
            </Typography>
            <Typography variant="body2" gutterBottom>
              Secure your account with verification: unlock enhanced security, gain access to premium features, and enjoy a fully customized experience tailored just for you.
            </Typography>
            {storeduser.is_verified === 'verified' ? (
              <Button
                variant="contained"
                fullWidth
                sx={{
                  marginTop: "20px",
                  padding: "10px 20px",
                  backgroundColor: "green",
                  "&:hover": { backgroundColor: "#99001A" },
                }}
              >
                Account already verified
              </Button>
            ) :
              storeduser.is_verified !== 'verified' &&
                !storeduser.frontId || !storeduser.backId || !storeduser.selfie ? (
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => { handleDrawerOpen() }}
                  sx={{
                    marginTop: "20px",
                    padding: "10px 20px",
                    backgroundColor: "#C60023",
                    "&:hover": { backgroundColor: "#99001A" },
                  }}
                >
                  Verify Account
                </Button>
              ) : storeduser.is_verified !== 'verified' && (
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => { handleDrawerOpen() }}
                  sx={{
                    marginTop: "20px",
                    padding: "10px 20px",
                    backgroundColor: "#C60023",
                    "&:hover": { backgroundColor: "#99001A" },
                  }}
                >
                  Documents submitted, Wait for Admin approval
                </Button>
              )}
          </CardContent>
        </CardStyled>

        {/* Drawer for Account Verification */}
        <Drawer
          anchor="right"
          open={isDrawerOpen}
          onClose={handleDrawerClose}
          sx={{
            "& .MuiDrawer-paper": {
              width: "400px",
              padding: "20px",
              borderRadius: "15px 0 0 15px",
            },
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography variant="h6" fontWeight="bold">
              Account Verification
            </Typography>
            <IconButton onClick={() => { handleDrawerClose() }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
            Verify your account to ensure maximum security, unlock exclusive features, and enjoy a seamless, personalized experience tailored to your needs.
          </Typography>

          <Box sx={{ mt: 3 }}>
            {/* Upload Fields */}
            <Box sx={{ mb: 3 }}>
              <Typography variant="subtitle1" fontWeight="bold">
                ID Card (Front)
              </Typography>
              <Button
                variant="outlined"
                fullWidth
                sx={{
                  mt: 1,
                  border: "1px dashed #CDCDCD",
                  height: "60px",
                  color: "#888",
                  backgroundColor: documents.frontId ? "#F9F9F9" : "transparent",
                  "&:hover": { borderColor: "#C1B314", backgroundColor: "#F9F9F9" },
                }}
                component="label"
              >
                {documents.frontId ? "Change File" : "+ Upload Front Side of ID Card"}
                <input
                  type="file"
                  accept="image/*"
                  hidden
                  onChange={(e) => handleFileChange(e, "frontId")}
                />
              </Button>
              {/* Display uploaded image */}
              {documents.frontId && (
                <PreviewContainer>
                  <PreviewImage src={documents.frontId} alt="Front ID Preview" />
                </PreviewContainer>
              )}
            </Box>

            <Box sx={{ mb: 3 }}>
              <Typography variant="subtitle1" fontWeight="bold">
                ID Card (Back)
              </Typography>
              <Button
                variant="outlined"
                fullWidth
                sx={{
                  mt: 1,
                  border: "1px dashed #CDCDCD",
                  height: "60px",
                  color: "#888",
                  backgroundColor: documents.backId ? "#F9F9F9" : "transparent",
                  "&:hover": { borderColor: "#C1B314", backgroundColor: "#F9F9F9" },
                }}
                component="label"
              >
                {documents.backId ? "Change File" : "+ Upload Back Side of ID Card"}
                <input
                  type="file"
                  accept="image/*"
                  hidden
                  onChange={(e) => handleFileChange(e, "backId")}
                />
              </Button>
              {/* Display uploaded image */}
              {documents.backId && (
                <PreviewContainer>
                  <PreviewImage src={documents.backId} alt="Back ID Preview" />
                </PreviewContainer>
              )}
            </Box>

            <Box sx={{ mb: 4 }}>
              <Typography variant="subtitle1" fontWeight="bold">
                Selfie
              </Typography>
              <Button
                variant="outlined"
                fullWidth
                sx={{
                  mt: 1,
                  border: "1px dashed #CDCDCD",
                  height: "60px",
                  color: "#888",
                  backgroundColor: documents.selfie ? "#F9F9F9" : "transparent",
                  "&:hover": { borderColor: "#C1B314", backgroundColor: "#F9F9F9" },
                }}
                component="label"
              >
                {documents.selfie ? "Change File" : "+ Upload Selfie Holding ID Card in Hand"}
                <input
                  type="file"
                  accept="image/*"
                  hidden
                  onChange={(e) => handleFileChange(e, "selfie")}
                />
              </Button>
              {/* Display uploaded image */}
              {documents.selfie && (
                <PreviewContainer>
                  <PreviewImage src={documents.selfie} alt="Selfie Preview" />
                </PreviewContainer>
              )}
            </Box>

            {/* Submit & Cancel Buttons */}
            <Button
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: !(documents.frontId && documents.backId && documents.selfie) ? "darkgray" : '#C1B314',
                color: "white",
                "&:hover": { backgroundColor: "#A99E14" },
                mb: 2,
              }}
              disabled={!documents.frontId || !documents.backId || !documents.selfie}
              onClick={() => { handleSubmit() }}
            >
              Submit Request
            </Button>
            <Button
              variant="outlined"
              fullWidth
              onClick={() => { handleDrawerClose() }}
              sx={{
                borderColor: "#CDCDCD",
                color: "#888",
                "&:hover": { borderColor: "#888" },
              }}
            >
              Cancel
            </Button>
          </Box>
        </Drawer>
      </>
    );
  };
  const [AllCities, setAllCities] = useState([]);
  const [NoStockistCities, setNoStockistCities] = useState([]);
  const [CityStockists, setCityStockists] = useState([]);
  const Stockist = memo(() => {
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const [MPV, setMPV] = useState(1000);
    const [RequiredDocuments, setRequiredDocuments] = useState([]); // Array for required documents
    const [paymentProof, setPaymentProof] = useState([]); // Array for payment proofs
    const [discount, setDiscount] = useState(0);
    const [StockistType, setStockistType] = useState('');
    const [selectedCity, setSelectedCity] = useState("");
    // {
    //   "City/Town Stockist": ["City 1", "City 2"],
    //   "Mobile Stockist": ["City 1", "City 2", "City 3"],
    // });
    const optionsFromApi = {
      "City/Town Stockist": ["City 1", "City 2"],
      "Mobile Stockist": ["Mobile 1", "Mobile 2"],
    };
    const [selectedSource, setSelectedSource] = useState("");
    const [activeOption, setActiveOption] = useState("");
    const handleMultipleFileChange = (event, setState) => {
      const files = event.target.files;
      if (files.length > 0) {
        const fileReaders = [];
        const base64Files = [];
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          if (file.size > 5 * 1024 * 1024) {
            alert("File size should be less than 5MB.");
            return;
          }
          const reader = new FileReader();
          fileReaders.push(
            new Promise((resolve) => {
              reader.onload = () => {
                base64Files.push(reader.result); // Convert to Base64
                resolve();
              };
              reader.readAsDataURL(file);
            })
          );
        }
        Promise.all(fileReaders).then(() => {
          setState((prevState) => [...prevState, ...base64Files]);
        });
      }
    };



    const handleDrawerOpen = () => {
      setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
      setDrawerOpen(false);
      setPaymentProof([]);
      setRequiredDocuments([]);
    };


    // Function to handle form submission
    const handleSubmit = async () => {
      try {
        if (MPV < 25) {
          return AlertService.swalErrorAlert("MPV must be at least 25")
        }
        const amount = parseFloat(MPV * parseFloat(136).toFixed(2)).toFixed(2);
        const discountAmount = (amount * discount) / 100;
        const payableAmount = amount - discountAmount;
        let SelectedSource = selectedSource
        if (StockistType === 'City/Town Stockist') {
          SelectedSource = "Nano ProTech"
        }
        let payload = {
          functionName: 'RequestForStockist',
          postData: {
            user_id: storeduser._id,
            selectedCity: selectedCity,
            StockistType: StockistType,
            selectedSource: SelectedSource,
            activeOption: activeOption,
            MPV: MPV,
            amount: parseFloat(amount),
            discount: discount,
            discountAmount: parseFloat(discountAmount),
            payableAmount: parseFloat(payableAmount),
            RequiredDocuments: RequiredDocuments,
            paymentProof: paymentProof
          }
        }
        console.log("payload", payload)
        // Make backend request
        const response = await axios.post(B_URL + "TopUpModule/RequestForStockist", payload, { headers });
        if (response.data.status) {
          AlertService.swalSuccessAlert(t("Request for Stockist submitted successfully. Wait for approval."));
          setDrawerOpen(false);
          setMPV(0);
          setPaymentProof([]);
          setRequiredDocuments([]);
          setSelectedSource('')
          setActiveOption('')
          // fetchTopups()
        } else {
          AlertService.swalErrorAlert(response.data.message || t("Error submitting top-up request."));
          setDrawerOpen(false);
        }
      } catch (error) {
        setDrawerOpen(false);
        console.error("Error submitting top-up request:", error);
        AlertService.swalErrorAlert(t("Error submitting top-up request. Please try again."));
      } finally {
        setDrawerOpen(false);
      }
    };

    return (
      <>
        {/* Verify Account Card */}
        <CardStyled>
          <CardContent>
            <Typography variant="h6" fontWeight="bold">
              {storeduser.type ? "Already a Stockist" : 'Become a Stockist'}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {storeduser.type ? "Congrats!, You already become a Stockist, Now you can use extra feature like order for others manage others order" : 'Secure your account with verification: unlock enhanced security, gain access to premium features, and enjoy a fully customized experience tailored just for you'}
              .
            </Typography>
            {storeduser.type ? (storeduser.type === 'Mobile Stockist' ?
              <Button
                variant="contained"
                fullWidth
                onClick={() => { handleDrawerOpen() }}
                sx={{
                  marginTop: "20px",
                  padding: "10px 20px",
                  backgroundColor: "blue",
                  "&:hover": { backgroundColor: "#99001A" },
                }}
              >
                {storeduser.type}
              </Button>
              :
              <Button
                variant="contained"
                fullWidth
                // onClick={() => { handleDrawerOpen() }}
                sx={{
                  marginTop: "20px",
                  padding: "10px 20px",
                  backgroundColor: "green",
                  "&:hover": { backgroundColor: "#99001A" },
                }}
              >
                {storeduser.type}
              </Button>)
              :
              <Button
                variant="contained"
                fullWidth
                onClick={() => { handleDrawerOpen() }}
                sx={{
                  marginTop: "20px",
                  padding: "10px 20px",
                  backgroundColor: "#C60023",
                  "&:hover": { backgroundColor: "#99001A" },
                }}
              >
                Upgrade to Stockist
              </Button>
            }
          </CardContent>
        </CardStyled>

        <Drawer
          anchor="right"
          open={isDrawerOpen}
          onClose={handleDrawerClose}
          sx={{
            "& .MuiDrawer-paper": {
              width: "480px",
              padding: "20px",
              borderRadius: "15px 0 0 15px",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
            },
          }}
        >
          {/* Header Section */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <Typography variant="h6" fontWeight="bold">
              Upgrade to Stockist
            </Typography>
            <IconButton onClick={() => { handleDrawerClose() }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography
            variant="body2"
            sx={{
              fontSize: "14px",
              marginBottom: "20px",
              color: "#6B7280",
            }}
          >
            You can simply upgrade to Stockist to get extra features of the system
          </Typography>

          {/* Radio Buttons for Source */}
          <Typography
            variant="subtitle1"
            fontWeight="bold"
            sx={{
              marginBottom: "10px",
              fontSize: "14px",
              color: "#000",
            }}
          >
            Select Stockist Type
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            {
              (
                storeduser?.type === 'Mobile Stockist'
                  ? ["City/Town Stockist"]
                  : ["City/Town Stockist", "Mobile Stockist"]
              ).map((stockistType, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <input
                      type="radio"
                      name="StockistType"
                      value={stockistType}
                      checked={stockistType === StockistType}
                      onChange={(e) => setStockistType(e.target.value)}
                      style={{
                        marginRight: "8px",
                        accentColor: "#C1B314",
                        width: "14px",
                        height: "14px",
                      }}
                    />
                  }
                  label={stockistType}
                  sx={{ fontSize: "14px", color: "#6B7280" }}
                />
              ))
            }
          </Box>

          {/* Show Dropdown for City/Town Stockist or Mobile Stockist */}
          {StockistType === "City/Town Stockist" ? (
            <FormControl fullWidth sx={{ marginBottom: "20px" }}>
              <Autocomplete
                options={NoStockistCities || []}
                getOptionLabel={(option) => option['city/town']}
                value={NoStockistCities.find((city) => city._id === selectedCity) || null}
                onChange={(event, newValue) => setSelectedCity(newValue ? newValue._id : '')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select City Stockist"
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": { borderRadius: "8px" },
                    }}
                  />
                )}
              />
            </FormControl>
          ) : (
            <FormControl fullWidth sx={{ marginBottom: "20px" }}>
              <Autocomplete
                options={AllCities || []}
                getOptionLabel={(option) => option['city/town']}
                value={AllCities.find((city) => city._id === selectedCity) || null}
                onChange={(event, newValue) => setSelectedCity(newValue ? newValue._id : '')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select City for Mobile Stockist"
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": { borderRadius: "8px" },
                    }}
                  />
                )}
              />
            </FormControl>
          )}


          {/* Radio Buttons for Source */}
          <Typography
            variant="subtitle1"
            fontWeight="bold"
            sx={{
              marginBottom: "10px",
              fontSize: "14px",
              color: "#000",
            }}
          >
            Select Source
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            {StockistType === 'City/Town Stockist' ? (
              ["Nano ProTech"].map((source, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <input
                      type="radio"
                      name="source"
                      value={source}
                      checked={source}
                      onChange={(e) => setSelectedSource(e.target.value)}
                      style={{
                        marginRight: "8px",
                        accentColor: "#C1B314",
                        width: "14px",
                        height: "14px",
                      }}
                    />
                  }
                  label={source}
                  sx={{ fontSize: "14px", color: "#6B7280" }}
                />
              ))
            ) : (
              ["City/Town Stockist", "Nano ProTech"].map((source, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <input
                      type="radio"
                      name="source"
                      value={source}
                      checked={selectedSource === source}
                      onChange={(e) => setSelectedSource(e.target.value)}
                      style={{
                        marginRight: "8px",
                        accentColor: "#C1B314",
                        width: "14px",
                        height: "14px",
                      }}
                    />
                  }
                  label={source}
                  sx={{ fontSize: "14px", color: "#6B7280" }}
                />
              )))}
          </Box>

          {/* Show Dropdown for City/Town Stockist or Mobile Stockist */}
          {selectedSource === "City/Town Stockist" && (
            <FormControl fullWidth sx={{ marginBottom: "20px" }}>
              <Autocomplete
                options={CityStockists || []}
                getOptionLabel={(option) =>
                  `${option.username} (${option.mobileNumber || ''})`
                }
                value={CityStockists.find((option) => option._id === activeOption) || null}
                onChange={(event, newValue) =>
                  setActiveOption(newValue ? newValue._id : '')
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`Select ${selectedSource}`}
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": { borderRadius: "8px" },
                    }}
                  />
                )}
              />
            </FormControl>
          )}


          {/* Remaining Drawer Content */}
          <Box sx={{ mt: 4 }}>
            {/* Required MPV */}
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              sx={{
                marginBottom: "10px",
                fontSize: "14px",
              }}
            >
              Required MPV
            </Typography>
            <TextField
              fullWidth
              type='number'
              placeholder="Enter MPV"
              min={25}
              value={MPV}
              onChange={(e) => setMPV(e.target.value)}
              sx={{
                "& .MuiOutlinedInput-root": { borderRadius: "8px" },
              }}
            />
            <Box sx={{ display: 'flex', alignItems: 'center', fontSize: '13px' }}>
              <Typography sx={{ fontSize: '13px' }}>Note :</Typography>
              {MPV < 25 ?
                <Typography sx={{ fontSize: '13px', color: 'red', marginLeft: '5px' }}>
                  MPV must be at least 25</Typography>
                :
                <Typography sx={{ fontSize: '13px', color: 'red', marginLeft: '5px' }}>
                  For City/Town 10,000 & Mobile Stockist Require 1,000 MPV
                </Typography>
              }
            </Box>

            {/* Amount Section */}
            {/* <Typography sx={{ marginTop: "20px" }} variant="subtitle1">
              Amount
            </Typography>
            <Typography sx={{ marginLeft: '5px', fontSize: '16px', marginBottom: '15px' }} variant="subtitle1" color='#5A5A5A'>
              Pesos {parseFloat(MPV * parseFloat(136).toFixed(2)).toFixed(2)} */}
            {/* </Typography> */}
            {/* Discount Section */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <Typography variant="subtitle1" >
                Payable Amount
              </Typography>
              <Typography variant="subtitle1" color="black">
                Pesos {(parseFloat(MPV * parseFloat(136).toFixed(2)).toFixed(2)) - ((parseFloat(MPV * parseFloat(136).toFixed(2)).toFixed(2) * discount) / 100)}
              </Typography>
            </Box>
            {/* <Typography sx={{ marginLeft: '5px', fontSize: '16px', marginBottom: '15px' }} variant="subtitle1" color='#5A5A5A'>
              {discount}%
            </Typography> */}
            {/* Payable Amount Section */}
            {/* <Typography variant="subtitle1" >
              Payable Amount
            </Typography>
            <Typography sx={{ marginLeft: '5px', fontSize: '16px', marginBottom: '15px' }} variant="subtitle1" color='#5A5A5A'>
              Pesos {(parseFloat(MPV * parseFloat(136).toFixed(2)).toFixed(2)) - ((parseFloat(MPV * parseFloat(136).toFixed(2)).toFixed(2) * discount) / 100)}
            </Typography> */}

            {/* Payment Proof Section */}
            <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
              <Typography
                variant="subtitle1"
                fontWeight="bold"
                sx={{
                  fontSize: "14px",
                  mr: 1,
                }}
              >
                Payment Proof
              </Typography>
              <Tooltip
                title="Upload payment proofs like receipts or invoices in image or PDF format."
                arrow
              >
                <InfoIcon sx={{ fontSize: "18px", color: "gray", cursor: "pointer" }} />
              </Tooltip>
            </Box>
            <Button
              variant="outlined"
              fullWidth
              component="label"
              sx={{
                height: "60px",
                border: "1px dashed #CDCDCD",
                color: "#888",
                "&:hover": { borderColor: "#C1B314" },
                mb: 3,
              }}
            >
              Upload Payment Proofs (images/PDF)
              <input
                type="file"
                hidden
                multiple
                accept=".jpg,.jpeg,.png,.pdf" // Accept only images and PDF files
                onChange={(e) => handleMultipleFileChange(e, setPaymentProof)}
              />
            </Button>

            {/* Display Uploaded Payment Proofs */}
            <Box display="flex" flexWrap="wrap" gap={2} mb={3}>
              {paymentProof.map((proof, index) => {
                const isPdf = proof.startsWith("data:application/pdf");
                return (
                  <Box
                    key={index}
                    sx={{
                      width: "80px",
                      height: "80px",
                      borderRadius: "8px",
                      overflow: "hidden",
                      border: "1px solid #CDCDCD",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#F9F9F9",
                    }}
                  >
                    {isPdf ? (
                      <PictureAsPdfIcon sx={{ fontSize: "40px", color: "#C60023" }} />
                    ) : (
                      <img
                        src={proof}
                        alt={`Payment Proof ${index + 1}`}
                        style={{ width: "100%", height: "100%", objectFit: "cover" }}
                      />
                    )}
                  </Box>
                );
              })}
            </Box>

            {/* Required Documents Section */}
            <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
              <Typography
                variant="subtitle1"
                fontWeight="bold"
                sx={{
                  fontSize: "14px",
                  mr: 1,
                }}
              >
                Required Documents
              </Typography>
              <Tooltip
                title="The required documents include Valid business permits, DTI/SEC registration, BIR Certificate of Registration (COR), identity proof, address proof, and additional documents as per stockist type."
                arrow
              >
                <InfoIcon sx={{ fontSize: "18px", color: "gray", cursor: "pointer" }} />
              </Tooltip>
            </Box>
            <Button
              variant="outlined"
              fullWidth
              component="label"
              sx={{
                height: "60px",
                border: "1px dashed #CDCDCD",
                color: "#888",
                "&:hover": { borderColor: "#C1B314" },
                mb: 3,
              }}
            >
              Upload Required Documents (images/PDF)
              <input
                type="file"
                hidden
                multiple
                accept=".jpg,.jpeg,.png,.pdf" // Accept only images and PDF files
                onChange={(e) => handleMultipleFileChange(e, setRequiredDocuments)}
              />
            </Button>

            {/* Display Uploaded Required Documents */}
            <Box display="flex" flexWrap="wrap" gap={2} mb={3}>
              {RequiredDocuments.map((doc, index) => {
                const isPdf = doc.startsWith("data:application/pdf");
                return (
                  <Box
                    key={index}
                    sx={{
                      width: "80px",
                      height: "80px",
                      borderRadius: "8px",
                      overflow: "hidden",
                      border: "1px solid #CDCDCD",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#F9F9F9",
                    }}
                  >
                    {isPdf ? (
                      <PictureAsPdfIcon sx={{ fontSize: "40px", color: "#C60023" }} />
                    ) : (
                      <img
                        src={doc}
                        alt={`Document ${index + 1}`}
                        style={{ width: "100%", height: "100%", objectFit: "cover" }}
                      />
                    )}
                  </Box>
                );
              })}
            </Box>

            <Button
              variant="contained"
              fullWidth
              onClick={() => handleSubmit()}
              sx={{
                backgroundColor: !StockistType || !selectedCity || paymentProof.length === 0 || RequiredDocuments.length === 0
                  ? 'gray'
                  : '#C1B314',
                color: !StockistType || !selectedCity || paymentProof.length === 0 || RequiredDocuments.length === 0
                  ? '#888'
                  : '#fff',
                "&:hover": {
                  backgroundColor: !StockistType || !selectedCity || paymentProof.length === 0 || RequiredDocuments.length === 0
                    ? 'gray'
                    : '#A99E14',
                },
                marginBottom: '10px',
                borderRadius: '10px',
                textTransform: 'none',
                fontWeight: 'bold',
              }}
              disabled={!StockistType || !selectedCity || paymentProof.length === 0 || RequiredDocuments.length === 0}
            >
              Submit
            </Button>

            <Button
              variant="outlined"
              fullWidth
              onClick={() => { handleDrawerClose() }}
              sx={{
                borderColor: "#CDCDCD",
                color: "#888",
                "&:hover": { borderColor: "#888" },
                borderRadius: "10px",
                textTransform: "none",
                fontWeight: "bold",
              }}
            >
              Cancel
            </Button>
          </Box>
        </Drawer>

      </>
    );
  });

  useEffect(() => {
    fetchCities();
    fetchNoStockistCities();
    const userString = localStorage.getItem('user');
    if (userString) {
      let user = JSON.parse(userString);
      fetchCityStockists(user._id);
    }

  }, []);

  const fetchCityStockists = async (user_id) => {
    setLoading(true);
    try {
      var payload = {
        functionName: "GetCityStockists",
        postData: {
          user_id: user_id
        },
      };
      const response = await axios.post(B_URL + 'AddressModule/GetCityStockists', payload, { headers });
      if (response.data.status) {
        console.log("Get City Stockists", response.data.data.data);
        setCityStockists(response.data.data.data);
      } else {
        AlertService.swalErrorAlert(response.data.message || t("Error fetching addresses."));
      }
    } catch (error) {
      AlertService.swalErrorAlert(t("Error fetching addresses. Please try again."));
    } finally {
      setLoading(false);
    }
  };

  const fetchNoStockistCities = async () => {
    setLoading(true);
    try {
      var payload = {
        functionName: "GetNoStockistCities",
        postData: {
        },
      };
      const response = await axios.post(B_URL + 'AddressModule/GetNoStockistCities', payload, { headers });
      if (response.data.status) {
        console.log("CITIES", response.data.data.data);
        setNoStockistCities(response.data.data.data);
      } else {
        AlertService.swalErrorAlert(response.data.message || t("Error fetching addresses."));
      }
    } catch (error) {
      AlertService.swalErrorAlert(t("Error fetching addresses. Please try again."));
    } finally {
      setLoading(false);
    }
  };

  const fetchCities = async () => {
    setLoading(true);
    try {
      var payload = {
        functionName: "GetAllCities",
        postData: {
        },
      };
      const response = await axios.post(B_URL + 'AddressModule/GetAllCities', payload, { headers });
      if (response.data.status) {
        console.log("CITIES", response.data.data.data);
        setAllCities(response.data.data.data);
      } else {
        AlertService.swalErrorAlert(response.data.message || t("Error fetching addresses."));
      }
    } catch (error) {
      AlertService.swalErrorAlert(t("Error fetching addresses. Please try again."));
    } finally {
      setLoading(false);
    }
  };
  const userString = localStorage.getItem('user');
  const user = JSON.parse(userString);
  console.log("Profile userData={user}", user)
  const renderContent = () => {
    switch (activeTab) {
      case 1:
        return (
          <Profile userData={user} />
        );
      case 2:
        return (
          <CardStyled >
            <CardContent>
              <Typography variant="h6" fontWeight="bold" gutterBottom>
                Change Account Security Password
              </Typography>
              <Typography variant="body2" gutterBottom>
                Secure Your Account, Update Your Password Regularly to Protect Your Information
              </Typography>
              <Box component="form">
                <Typography sx={{ mt: 2 }}>Password</Typography>
                <TextField
                  fullWidth
                  sx={{ mt: '-2px' }}
                  margin="normal"
                  type="password"
                  onChange={(e) => { setNewPassword(e.target.value) }}
                  type={passwordVisibility.newPassword ? "text" : "password"}
                  placeholder="Enter password"
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={() => togglePasswordVisibility('newPassword')}>
                        {passwordVisibility.newPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    ),
                  }}
                />
                <Typography>Confirm</Typography>
                <TextField
                  fullWidth
                  margin="normal"
                  sx={{ mt: '-2px' }}
                  onChange={(e) => { setConfirmPassword(e.target.value) }}
                  type={passwordVisibility.confirmPassword ? "text" : "password"}
                  placeholder="Confirm password"
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={() => togglePasswordVisibility('confirmPassword')}>
                        {passwordVisibility.confirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    ),
                  }}
                />
                <Button
                  variant="contained"
                  fullWidth
                  disabled={newPassword === '' || confirmPassword === ''}
                  onClick={handleUpdatePassword}
                  sx={{
                    marginTop: "20px",
                    padding: "10px 20px",
                    backgroundColor: "#C1B314",
                    "&:hover": { backgroundColor: "#6eb233" },
                  }}
                >
                  Change Password
                </Button>
              </Box>
            </CardContent>
          </CardStyled>
        );
      case 3:
        return (
          <CardStyled>
            <CardContent>
              <Typography variant="h6" fontWeight="bold" gutterBottom>
                {Data?.transaction_password ? "Change Transaction Security Password" : "Set Transaction Security Password"}
              </Typography>
              <Typography variant="body2" gutterBottom>
                Secure Your Transactions, Update Your Password Regularly to Protect Your Information
              </Typography>
              <Box component="form">
                {Data?.transaction_password &&
                  <>
                    <Typography sx={{ mt: 2 }}>Old Password</Typography>
                    <TextField
                      fullWidth
                      sx={{ mt: '-2px' }}
                      margin="normal"
                      type="password"
                      onChange={(e) => { setOldTransactionPassword(e.target.value) }}
                      type={passwordTransactionVisibility.oldTransactionPassword ? "text" : "password"}
                      placeholder="Enter password"
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <IconButton onClick={() => togglePasswordTransactionVisibility('oldTransactionPassword')}>
                            {passwordTransactionVisibility.oldTransactionPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        ),
                      }}
                    />
                  </>
                }
                <Typography sx={{ mt: 2 }}>Password</Typography>
                <TextField
                  fullWidth
                  sx={{ mt: '-2px' }}
                  margin="normal"
                  type="password"
                  onChange={(e) => { setTransactionNewPassword(e.target.value) }}
                  type={passwordTransactionVisibility.newTransactionPassword ? "text" : "password"}
                  placeholder="Enter password"
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={() => togglePasswordTransactionVisibility('newTransactionPassword')}>
                        {passwordTransactionVisibility.newTransactionPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    ),
                  }}
                />
                <Typography sx={{ mt: 2 }}>Confirm Passsword</Typography>
                <TextField
                  fullWidth
                  margin="normal"
                  sx={{ mt: '-2px' }}
                  onChange={(e) => { setTransactionConfirmPassword(e.target.value) }}
                  type={passwordTransactionVisibility.TransactionConfirmPassword ? "text" : "password"}
                  placeholder="Confirm password"
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={() => togglePasswordTransactionVisibility('TransactionConfirmPassword')}>
                        {passwordTransactionVisibility.TransactionConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    ),
                  }}
                />
                <Button
                  variant="contained"
                  fullWidth
                  disabled={newTransactionPassword === '' || TransactionConfirmPassword === ''}
                  onClick={() => { handleUpdateTransactionPassword() }}
                  sx={{
                    marginTop: "20px",
                    padding: "10px 20px",
                    backgroundColor: "#C1B314",
                    "&:hover": { backgroundColor: "#6eb233" },
                  }}
                >
                  Change Password
                </Button>
              </Box>
            </CardContent>
          </CardStyled>
        );
      case 4:
        return (
          <CardStyled style={{ border: 'none', boxShadow: "none", borderRadius: "10px", padding: "10px" }}>
            <CardContent>
              <Box
                mt={2}
                p={2}
                sx={{ borderRadius: '15px', border: "1px solid #CDCDCD" }}
              >
                {/* Title */}
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  Share Referral
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  Share Your Unique Referral Link with Friends and Earn Exclusive Rewards for Every Sign-Up!
                </Typography>

                {/* Referral Link Section */}
                <Box
                  display="flex"
                  alignItems="center"
                  mt={2}
                  mb={3}
                  sx={{
                    border: "1px solid #CDCDCD",
                    borderRadius: "8px",
                    padding: "8px 12px",
                    backgroundColor: "#fff",
                  }}
                >
                  <TextField
                    fullWidth
                    value={referralLink}
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                      sx: {
                        fontSize: "14px",
                        color: "#555",
                        padding: 0,
                        border: "none",
                      },
                    }}
                  />
                  <img
                    onClick={() => { handleCopyLink() }}
                    sx={{
                      color: "#F9C01C", // Gold color for the copy icon
                      padding: 0,
                      minWidth: 0,
                      marginLeft: 1,
                      fontSize: "20px",
                    }}
                    src={copy} alt="content_copy"
                  >
                  </img>
                </Box>

                {/* Social Media Share Section */}
                <Typography variant="subtitle2" fontWeight="bold" gutterBottom>
                  SHARE THIS
                </Typography>
                <Box display="flex" justifyContent="space-between" mt={2} mb={4} sx={{ maxWidth: "400px" }}>
                  <img
                    onClick={() => handleShare("facebook")}
                    style={{
                      cursor: "pointer", width: "40px", height: "40px", borderRadius: "50%"
                    }}
                    src={facebook} alt="Facebook"
                  />
                  <img
                    onClick={() => handleShare("email")}
                    style={{
                      cursor: "pointer", width: "40px", height: "40px", borderRadius: "50%"
                    }}
                    src={email} alt="Email"
                  />
                  <img
                    onClick={() => handleShare("twitter")}
                    style={{
                      cursor: "pointer", width: "40px", height: "40px", borderRadius: "50%"
                    }}
                    src={twitter} alt="Twitter"
                  />
                  <img
                    onClick={() => handleShare("google")}
                    style={{
                      cursor: "pointer", width: "40px", height: "40px", borderRadius: "50%"
                    }}
                    src={google} alt="Google"
                  />
                  <img
                    onClick={() => handleShare("linkedin")}
                    style={{
                      cursor: "pointer", width: "40px", height: "40px", borderRadius: "50%"
                    }}
                    src={linkedin} alt="LinkedIn"
                  />
                  <img
                    onClick={() => handleShare("pinterest")}
                    style={{
                      cursor: "pointer", width: "40px", height: "40px", borderRadius: "50%"
                    }}
                    src={pintrest} alt="Pinterest"
                  />
                  <img
                    onClick={() => handleShare("whatsapp")}
                    style={{
                      cursor: "pointer", width: "40px", height: "40px", borderRadius: "50%"
                    }}
                    src={whatsapp} alt="WhatsApp"
                  />
                  <img
                    onClick={() => handleShare("messenger")}
                    style={{
                      cursor: "pointer", width: "40px", height: "40px", borderRadius: "50%"
                    }}
                    src="https://scontent.fisb6-1.fna.fbcdn.net/v/t39.8562-6/120009688_325579128711709_1736249742330805861_n.png?_nc_cat=1&ccb=1-7&_nc_sid=f537c7&_nc_ohc=XPnpl6ljRXUQ7kNvgGiW6-e&_nc_oc=AdhQqJXV5XVNxeqo4-tPfMad5yesWKcj3MkIrbgZa8y3SAr1W4tsvuKjxlj_5KtXF1M&_nc_zt=14&_nc_ht=scontent.fisb6-1.fna&_nc_gid=A8_hjENu1MoNnlAj-mivWLS&oh=00_AYCdTnS-shJvFjNyL2itJbHP9coy530Wrk6grSEuE2hs-A&oe=679528FD" alt="Messenger"
                  />
                  {/* <img
                    onClick={() => handleShare("viber")}
                    style={{
                      cursor: "pointer", width: "40px", height: "40px", borderRadius: "50%"
                    }}
                    src="https://www.viber.com/app/uploads/viber-logo.png" alt="Viber"
                  /> */}

                </Box>
              </Box>
              <Box
                mt={2}
                p={2}
                sx={{ borderRadius: '15px', border: "1px solid #CDCDCD" }}
              >
                {/* Referral Stats Section */}
                <Typography sx={{ fontSize: '22px' }} variant="subtitle2" fontWeight="bold" gutterBottom>
                  Referral Stats
                </Typography>
                <Box
                  mt={4}
                  p={2}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "10px",
                  }}
                >

                  <Box
                    textAlign="center"
                    p={2}
                    sx={{
                      backgroundColor: "#F9F9F9",
                      borderRadius: "8px",
                      flex: 1,
                      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                      Total Visits
                    </Typography>
                    <Typography variant="h6" fontWeight="bold">
                      500
                    </Typography>
                  </Box>
                  <Box
                    textAlign="center"
                    p={2}
                    sx={{
                      backgroundColor: "#F9F9F9",
                      borderRadius: "8px",
                      flex: 1,
                      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                      Total Active
                    </Typography>
                    <Typography variant="h6" fontWeight="bold">
                      400
                    </Typography>
                  </Box>
                  <Box
                    textAlign="center"
                    p={2}
                    sx={{
                      backgroundColor: "#F9F9F9",
                      borderRadius: "8px",
                      flex: 1,
                      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                      Total Inactive
                    </Typography>
                    <Typography variant="h6" fontWeight="bold">
                      100
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </CardStyled>
        );
      case 5:
        return (
          <CardStyled>
            <CardContent>
              <Typography variant="h6" fontWeight="bold">
                2 Factor Authentication (2FA)
              </Typography>
              <TwoFactorAuth />
            </CardContent>
          </CardStyled>
        );
      case 6:
        return (
          <CardStyled>
            <CardContent>
              <Typography variant="h6" fontWeight="bold">
                Upgrade Account
              </Typography>
              <Typography variant="body2" gutterBottom>
                Please contact admin to upgrade the account
              </Typography>
            </CardContent>
          </CardStyled>
        );
      case 7:
        return (
          <Stockist />
        );
      case 8:
        return (
          <VerifyAccount />
        );
      default:
        return null;
    }
  };


  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    if (!userData) {
      AuthService.logout();
      navigate("/login");
      window.location.reload();
    } else {
      decryptPass(userData?.password);
      // fetchAddresses();
    }
  }, []);

  const fetchAddresses = async () => {
    setLoading(true);
    try {
      const response = await AddressService.getAddresses();
      if (response.data.status) {
        setAddresses(response.data.data);
      } else {
        AlertService.swalErrorAlert(response.data.message || t("Error fetching addresses."));
      }
    } catch (error) {
      AlertService.swalErrorAlert(t("Error fetching addresses. Please try again."));
    } finally {
      setLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const decryptPass = (pass) => {
    let dec = atob(pass);
    setOpass(dec);
  };

  const handleUpdateBasicInfo = async (event) => {
    event.preventDefault();
    setLoading(true);
    const formData = new FormData(event.currentTarget);
    const payloadData = {
      functionName: "UpdateCustomerProfile",
      postData: {
        name: formData.get('fullName'),
        phone: formData.get('mobileNumber'),
        _id: userData?._id
      },
    };
    SendSubmit(payloadData);
  };



  const sendUpdateProfile = (payload) => {
    const headers = {
      'Content-Type': 'application/json',
      'token': localStorage.getItem("session-id")
    };
    return axios.post(API_URL, payload, { headers });
  };

  const saveUpdatedCreds = (payload, msg) => {
    let updatedUser = { ...userData };
    if (payload.postData.name) updatedUser.name = payload.postData.name;
    if (payload.postData.phone) updatedUser.phone = payload.postData.phone;
    if (payload.postData.password) updatedUser.password = btoa(payload.postData.password);

    localStorage.setItem("user", JSON.stringify(updatedUser));
    setUserData(updatedUser);

    AlertService.swalSuccessAlert(msg || t("Profile updated successfully.")).then(() => {
      window.location.reload();
    });
  };
  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <SidebarContainer>
            {/* Sidebar */}
            <Sidebar>
              {tabs.map((tab) => (
                <SidebarItem
                  key={tab.id}
                  active={activeTab === tab.id}
                  onClick={() => setActiveTab(tab.id)}
                >
                  <span>{tab.label}</span>
                  <SidebarArrow>→</SidebarArrow>
                </SidebarItem>
              ))}
            </Sidebar>
            {/* Main Content */}
            <MainContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {renderContent()}
                </Grid>
              </Grid>
            </MainContent>
          </SidebarContainer>
        </div>
      </div>
    </Fragment>
  );
};

export default MyProfile;