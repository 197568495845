import axios from "axios";

const API_URL = process.env.REACT_APP_BASEURL;
const BASE_URL = process.env.REACT_APP_LOGINURL;


const headers = {
  'Content-Type': 'application/json',
  'token': localStorage.getItem("session-id")
}


const getAll = (sessionID) => {
  var payload = {
    functionName: "GetUserRole",
    postData: {},
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(API_URL, payload, { headers });
};


const deleteRole = (sessionID,id) => {
  var payload = {
    functionName: "DeleteUserRole",
    postData: {
      _id: id,
    },
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(API_URL, payload, { headers });
};


const addRole = (sessionID,payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  console.log("Received addRole Payload:-", payload)
  return axios.post(API_URL, payload, { headers });
}

const updateRole = (sessionID,payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  console.log("Received addRole Payload:-", payload)
  return axios.post(API_URL, payload, { headers });
}
const GetBusinesses = (sessionID) => {
  var payload = {
    functionName: "GetBusinesses",
    postData: {
      limit: 999999999,
      business_ids: [],
      offset: 0
    },
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(BASE_URL + 'BusinessModule/GetBusinesses', payload, { headers });

};

const RoleService = {
  getAll,
  GetBusinesses,
  deleteRole,
  addRole,
  updateRole
};

export default RoleService;