import React, { useEffect, useState, useRef } from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import DiscountCouponService from "../../../pages/DiscountCoupon/Discountcoupons.service.js";
import AuthService from "../../Authentication/auth.service";
import Alerts from "../../../components/Alerts/alerts";
import { Col, CardBody, Card } from "reactstrap";
import PermissionAccess from '../../../PermissionAccess/Access.js';


const UpdateStatusModal = ({ showModalUpdateStatus, handleToggleModalUpdateStatus, onSuccess, array }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState('');
    const { authData } = PermissionAccess();
    const isInitialMount = useRef(true);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else if (showModalUpdateStatus && array && array.status) {
            setSelectedStatus(array.status);
        }
    }, [showModalUpdateStatus, array]);

    const handleClose = () => {
        handleToggleModalUpdateStatus();
        isInitialMount.current = true;
    };

    const handleStatusChange = (e) => {
        setSelectedStatus(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const orderId = array?.order_id;
        const deliveryMethod = array?.deliveryMethod;
        setLoading(true);
        console.log("orderId Member", orderId)
        DiscountCouponService.UpdateStatusMember( selectedStatus, orderId,deliveryMethod).then(
            (response) => {
                setLoading(false);
                if (response.data.message === "Unauthorized") {
                    AuthService.logout();
                    navigate("/login");
                    window.location.reload();
                } else {
                    if (response.data.status === true) {
                        Alerts.swalClickProceedAlert(response.data.message).then(res => {
                            if (res === true) {
                                onSuccess();
                                handleClose();
                            }
                        });
                    } 
                }
            },
            (error) => {
                setLoading(false);
                const errContent =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
                Alerts.swalErrorAlert(errContent || t("Server Error, Please try again later."));
            }
        );   
    };

    return (
        <div className={showModalUpdateStatus ? "modal d-block mt-4" : "modal"} style={{ display: showModalUpdateStatus ? 'block' : 'none', backgroundColor: 'rgba(0, 0, 0, 0.1)' }} tabIndex="-1" role="dialog">
            <form onSubmit={handleSubmit}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">{t("Change Order Status")}</h5>
                        </div>
                        <div className="modal-body">
                            <Col md="12">
                                <Card className="main-card mb-3">
                                    <CardBody>
                                        <LoadingOverlay
                                            tag="div"
                                            active={loading}
                                            styles={{ overlay: (base) => ({ ...base }) }}
                                            spinner={<DNALoader />}
                                        >
                                            <input type="hidden" className="form-control" name="order_id" value={array?._id || ''} />
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <select 
                                                            className="form-control"
                                                            name="status"
                                                            value={selectedStatus}
                                                            onChange={handleStatusChange}
                                                        >
                                                            <option value="pending">{t("Pending")}</option>
                                                            <option value="confirmed">{t("Confirmed")}</option>
                                                            <option value="processing">{t("Processing")}</option>
                                                            <option value="in_transit">{t("In Transit")}</option>
                                                            <option value="delivered">{t("Delivered")}</option>
                                                            <option value="cancelled">{t("Cancelled")}</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="text-center mt-4">
                                                    <button type="button" className="btn btn-outline-light btn-hover-shine me-3" onClick={handleClose}>{t("Close")}</button>
                                                    <button type="submit" className="btn btn-custom-color">{t("Save")}</button>
                                                </div>
                                            </div>
                                        </LoadingOverlay>
                                    </CardBody>
                                </Card>
                            </Col>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default UpdateStatusModal;