import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import QRCode from 'qrcode.react';
import axios from 'axios';
import AlertService from "../../components/Alerts/alerts";

const TwoFactorAuth = () => {
  const { t } = useTranslation();
  const user = JSON.parse(localStorage.getItem('user'));
  const [userId, setUserId] = useState(user._id);
  const [twoFactorSecret, setTwoFactorSecret] = useState('');
  const [twoFactorCode, setTwoFactorCode] = useState('');
  const [twoFactorEnabled, setTwoFactorEnabled] = useState(false);

  useEffect(() => {
    checkTwoFactorStatus();
  }, []);

  // New function to check if 2FA is already enabled
  const checkTwoFactorStatus = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_LOGINURL}UserModule/check-2fa/${userId}`);
      setTwoFactorEnabled(response.data.is2FAEnabled);
    } catch (error) {
      console.error('Error checking 2FA status:', error);
    }
  };

  const fetchTwoFactorSecret = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_LOGINURL}UserModule/two-factor/${userId}`);
      setTwoFactorSecret(response.data.secret);
    } catch (error) {
      console.error('Error fetching two-factor secret:', error);
      AlertService.swalErrorAlert(t('Error fetching two-factor secret. Please try again.'));
    }
  };

  const enableTwoFactor = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_LOGINURL}UserModule/two-factor/${userId}`, { code: twoFactorCode });
      if (response.data.status) {
        setTwoFactorEnabled(true);
        setTwoFactorSecret(''); // Clear the secret after successful enabling
        AlertService.swalSuccessAlert(t('Two-factor authentication enabled.'));
      } else {
        AlertService.swalErrorAlert(t('Invalid two-factor code. Please try again.'));
      }
    } catch (error) {
      console.error('Error enabling two-factor authentication:', error);
      AlertService.swalErrorAlert(t('Error enabling two-factor authentication. Please try again.'));
    }
  };

  const disableTwoFactor = async () => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_LOGINURL}UserModule/two-factor/${userId}`);
      if (response.data.status) {
        setTwoFactorEnabled(false);
        setTwoFactorSecret('');
        AlertService.swalSuccessAlert(t('Two-factor authentication disabled.'));
      } else {
        AlertService.swalErrorAlert(t('Error disabling two-factor authentication. Please try again.'));
      }
    } catch (error) {
      console.error('Error disabling two-factor authentication:', error);
      AlertService.swalErrorAlert(t('Error disabling two-factor authentication. Please try again.'));
    }
  };

  return (
    <Box>
      {twoFactorEnabled ? (
        <Box>
          <Typography variant="body1" gutterBottom color="success.main">
            {t('Two-factor authentication is currently enabled on your account.')}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: 'red',
                color: '#FFF', // Optional: white text for contrast
                ':hover': {
                  backgroundColor: '#B22222', // Darker red for hover effect
                },
              }}
              onClick={disableTwoFactor}
            >
              {t('Disable Two-Factor Authentication')}
            </Button>
          </Box>
        </Box>
      ) : (
        <Box>
          {twoFactorSecret ? (
            <Box>
              <Typography variant="body1" gutterBottom>
                {t('Scan the QR code with your Google Authenticator app to set up two-factor authentication.')}
              </Typography>
              <Box sx={{ my: 3, display: 'flex', justifyContent: 'center' }}>
                <QRCode
                  value={`otpauth://totp/Nano%20Pro-Tech?secret=${twoFactorSecret}&issuer=Nano%20Pro-Tech`}
                  size={200}
                />
              </Box>
              <TextField
                label={t('Two-Factor Code')}
                value={twoFactorCode}
                onChange={(e) => setTwoFactorCode(e.target.value)}
                margin="normal"
                fullWidth
                placeholder={t('Enter 6-digit code')}
                inputProps={{ maxLength: 6 }}
              />
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: 'red',
                    color: '#FFF', // Optional: white text for contrast
                    ':hover': {
                      backgroundColor: '#B22222', // Darker red for hover effect
                    },
                  }}
                  onClick={enableTwoFactor}
                  disabled={twoFactorCode.length !== 6}
                >
                  {t('Enable Two-Factor Authentication')}
                </Button>
              </Box>
            </Box>
          ) : (
            <Box>
              <Typography variant="body1" gutterBottom>
                {t('Enhance your account security by enabling two-factor authentication.')}
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: 'red',
                    color: '#FFF', // Optional: white text for contrast
                    ':hover': {
                      backgroundColor: '#B22222', // Darker red for hover effect
                    },
                  }}
                  onClick={fetchTwoFactorSecret}
                >
                  {t('Set Up Two-Factor Authentication')}
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Box>

  );
};

export default TwoFactorAuth;