import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const ErrorPage = () => {

  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
  }, []);

  const pageStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#f4f4f4',
    padding: '20px',
    boxSizing: 'border-box',
    textAlign: 'center'
  };

  const headingStyle = {
    fontSize: '2rem',
    marginBottom: '10px',
    color: '#333', // Text color
  };

  const subheadingStyle = {
    fontSize: '1.5rem',
    marginBottom: '20px',
    color: '#666', // Subheading text color
  };

  const iconStyle = {
    marginBottom: '20px',
    color: '#dc3545', // Icon color
    fontSize: '4rem',
  };

  const imageStyle = {
    maxWidth: '150px',
    marginBottom: '20px',
  };

  const backButtonStyle = {
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: '#fff',
    borderRadius: '5px',
    textDecoration: 'none',
    marginTop: '20px',
    transition: 'background-color 0.3s ease', // Smooth hover effect
  };

  const backButtonHoverStyle = {
    ...backButtonStyle,
    backgroundColor: '#0056b3', // Darker color on hover
  };

  const [logo, setLogo] = useState(localStorage.getItem("system_logo"));

  return (
    <div style={pageStyle}>
      <img src={logo} alt="Error" style={imageStyle} />
      <FontAwesomeIcon icon={faExclamationCircle} style={iconStyle} />
      <h1 style={headingStyle}>
        {t('Server Error')}
      </h1>
      <h2 style={subheadingStyle}>
        {t("Invoice Isn't Submitted")}
      </h2>
      <p>{t('Please try again later or contact support if the issue persists.')}</p>
      <Link 
        to="/home" 
        style={backButtonStyle} 
        onMouseEnter={(e) => e.target.style.backgroundColor = backButtonHoverStyle.backgroundColor}
        onMouseLeave={(e) => e.target.style.backgroundColor = backButtonStyle.backgroundColor}
      >
        {t('Back to Home')}
      </Link>
    </div>
  );
};

export default ErrorPage;
