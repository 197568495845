import React, { Fragment, useState, useEffect } from "react";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, CardBody, Modal, ModalBody, ModalFooter } from "reactstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faMotorcycle, faBox } from '@fortawesome/free-solid-svg-icons';
import { faCommentAlt, faBicycle, faMapMarkerAlt, faCheckCircle, faShoppingBasket, faEnvelope, faSearch, faTruck, faFire, faClipboardList, faClock, faPhone, faTimes } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import Alerts from "../../../components/Alerts/alerts";
import OrderService from "../order.service.js";
import AuthService from "../../Authentication/auth.service";
import DiscountCouponService from "../../DiscountCoupon/Discountcoupons.service.js";
import Header from './Header';
import { Container, Button } from 'reactstrap';
import deliveryIcon from '../../../assets/delivery.svg';
import pickup from '../../../assets/pickup.svg';
import {
  IconButton
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Orders = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [branch, setBranch] = useState('');
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('orderdetails');
  const [trackingData, setTrackingData] = useState([]);
  const [isTrackingLoading, setIsTrackingLoading] = useState(true);
  const [trackingError, setTrackingError] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState(
    JSON.parse(localStorage.getItem("selectedCurrency")) || {
      code: "PHP",
      rate: 1,
      symbol: "₱",
    }
  );
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const formatStatus = (status) => {
    return status
      .toLowerCase()
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  useEffect(() => {
    const fetchTrackingData = async () => {
      if (orderDetails) {
        setIsTrackingLoading(true);
        try {
          const lalamove_orderID = orderDetails.lalamove_orderID;
          const order_id = orderDetails.order_id;
          var payload = {
            "functionName": "TrackOrder",
            "postData": {
              "lalamove_orderID": lalamove_orderID,
              "order_id": order_id,
            }
          }
          const response = await OrderService.GetOrderTrack(payload);
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          } else {
            console.log(response.data.orderData)
            setTrackingData(response.data.orderData);
          }
        } catch (err) {
          console.log("err",)
          setTrackingError('Failed to fetch tracking data');
        } finally {
          setIsTrackingLoading(false);
        }
      }
    };

    fetchTrackingData();
  }, [orderDetails, activeTab]);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      setLoading(true);
      try {
        const orderId = location.state.order_id;
        const response = await OrderService.getOrderDetails(orderId);
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        } else {
          // console.log("Order Details  : ", response.data.orderData[0]);
          setOrderDetails(response?.data?.orderData?.[0]);
        }
      } catch (error) {
        console.error('Error fetching order details:', error);
        Alerts.swalErrorAlert(t("Failed to fetch order details."));
      } finally {
        setLoading(false);
      }
    };

    fetchOrderDetails();
  }, []);

  if (loading) {
    return (
      <Fragment>
        <div className="app-main__outer">
          <div className="app-main__inner">
            <TransitionGroup>
              <CSSTransition component="div" classNames="TabsAnimation" appear={true} timeout={0} enter={false} exit={false}>

                <LoadingOverlay tag="div" active={loading}
                  styles={{ overlay: (base) => ({ ...base }) }}
                  spinner={<DNALoader />}>
                  <div style={{ height: '100vh' }}></div>
                </LoadingOverlay>
              </CSSTransition>
            </TransitionGroup>
          </div>
        </div>
      </Fragment >
    );
  }

  const cancelOrder = async (e, order_id, deliveryMethod) => {
    e.preventDefault();

    const userString = localStorage.getItem('user');
    const user = JSON.parse(userString);
    const sessionId = user['session-id'];

    const msg = t("You want to cancel order ?")
    Alerts.swalOKAlert(msg).then(async res => {
      if (res === true) {
        setLoading(true);
        DiscountCouponService.UpdateStatus(sessionId, 'cancelled', order_id, deliveryMethod).then(
          (response) => {
            setLoading(false);
            if (response.data.message === "Unauthorized") {
              AuthService.logout();
              navigate("/login");
              window.location.reload();
            } else {
              Alerts.swalClickProceedAlert('Order Has Been Cancelled Successfully').then(res => {
                if (res === true) {
                  navigate("/CustomerOrders");
                }
              });

            }
          },
          (error) => {
            setLoading(false);
            const errContent =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
            Alerts.swalErrorAlert(errContent || t("Server Error, Please try again later."));
          }
        );
      }
    });
  };

  // Helper function to get the appropriate icon for each status
  const getIconForStatus = (status) => {
    switch (status.toLowerCase()) {
      case 'delivered':
        return faCheckCircle;
      case 'picked by driver':
        return faShoppingBasket;
      case 'driver assigned':
        return faBicycle;
      case 'on going':
        return faFire;
      case 'assigning driver':
        return faSearch;
      case 'order received':
        return faClipboardList;
      default:
        return faClipboardList;
    }
  };

  const renderHeader = () => (
    <header style={{
      // position: 'fixed',
      width: isMobile ? '100%' : '90%',
      top: 0,
      marginLeft: isMobile ? '0%' : '5%',
      left: 0,
      zIndex: 1000,
      backgroundColor: 'black',
    }}>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px',
        backgroundColor: 'white',
        margin: '0 auto',
        maxWidth: '100%',
      }}>
        <div style={{ borderRadius: '5px', padding: '8px', fontSize: '24px' }}
          onClick={() => navigate(-1)}
        >
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
        </div>
        <h3 style={{ cursor: 'pointer', width: '180px', height: 'auto' }} >Order Tracking</h3>
        <div>
        </div>
      </div>
    </header>
  );

  const renderOrderSummary = () => (
    <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '8px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '15px' }}>
        <div>
          <h3 style={{ align: 'center', margin: '0', fontSize: '16px' }}>Order ID #{orderDetails?.order_number}</h3>
          {orderDetails?.deliveryMethod === 'delivery' ?
            <p style={{ margin: '5px 0 0', color: '#666', fontSize: '14px' }}><img src={deliveryIcon} alt="Delivery" width="60" height="60" />Delivery</p>
            :
            <p style={{ margin: '5px 0 0', color: '#666', fontSize: '14px' }}><img src={pickup} alt="Self Pickup" width="60" height="60" />Self Pickup</p>
          }
        </div>
        <div style={{
          backgroundColor: '#4CAF50',
          color: 'white',
          padding: '5px 15px',
          borderRadius: '20px',
          fontSize: '14px'
        }}>
          {orderDetails?.status || 'Processing'}
        </div>
      </div>
      <hr />
    </div>
  );;

  const renderTabs = () => (
    <div style={{ display: 'flex' }}>
      {['Tracking Info', 'Order Details'].map((tab) => (
        <button
          key={tab}
          style={{
            flex: 1,
            backgroundColor: 'white',
            border: 'none',
            borderBottom: activeTab === tab.toLowerCase().replace(' ', '') ? '2px solid #C1B413' : '2px solid transparent',
            color: activeTab === tab.toLowerCase().replace(' ', '') ? '#C1B413' : '#333',
            padding: '15px',
            fontSize: '16px',
            fontWeight: 'bold',
            cursor: 'pointer'
          }}
          onClick={() => setActiveTab(tab.toLowerCase().replace(' ', ''))}
        >
          {tab}
        </button>
      ))}
    </div>
  );

  const renderOrderDetails = () => (
    <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '8px' }}>
      <div style={{ marginBottom: '20px' }}>
        <p style={{ margin: '0', fontSize: '16px' }}>
          <span style={{ color: '#666', marginRight: '5px' }}>Date:</span>
          <span>
            {orderDetails?.order_date ? moment(orderDetails.order_date).format("MMM DD,YYYY - HH:MM") : 'N/A'}
          </span>
        </p>
      </div>
      <hr />
      <div style={{ marginBottom: '10px' }}>
        <p style={{ margin: '0', fontSize: '16px' }}>
          <span style={{ color: '#666', marginRight: '5px' }}>Name :</span>
          <span>{orderDetails?.fullName}</span>
        </p>
        <p style={{ margin: '0', fontSize: '16px' }}>
          <span style={{ color: '#666', marginRight: '5px' }}>Contact No. :</span>
          <span>{orderDetails?.mobileNumber}</span>
        </p>
        <p style={{ margin: '0', fontSize: '16px' }}>
          <span style={{ color: '#666', marginRight: '5px' }}>Address :</span>
          <span>{orderDetails?.address || 'Self Pickup'}</span>
        </p>
      </div>
      <hr />
      <div style={{ marginBottom: '10px' }}>
        <p style={{ margin: '0', fontSize: '16px' }}>
          <span style={{ color: '#666', marginRight: '5px' }}>Items:</span>
          <span style={{}}>{orderDetails?.cartDetails?.length || 0}</span>
        </p>
      </div>
      {orderDetails?.cartDetails?.map((item, index) => (
        <div key={index} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: '10px', fontSize: '16px' }}>{item.quantity} x</span>
            <span style={{ fontSize: '16px' }}>{item.item.item_name}</span>
          </div>
          <p style={{ margin: '0', fontWeight: 'bold', fontSize: '16px' }}>
            {selectedCurrency.symbol}  {parseFloat((item.price_per_item || 0.00) * item.quantity * selectedCurrency.rate).toFixed(2)}
          </p>
        </div>
      ))}
      <hr style={{ margin: '20px 0' }} />
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
        <p style={{ margin: '0', fontSize: '14px' }}>Subtotal</p>

        <p style={{ margin: '0', fontSize: '14px' }}>{selectedCurrency.symbol}  {parseFloat((orderDetails?.Calculations?.subTotal || 0.00) * selectedCurrency.rate).toFixed(2)}</p>
      </div>
      {/* <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
        <p style={{ margin: '0', fontSize: '14px' }}>
          SST
          <span style={{ backgroundColor: '#FFA500', color: 'white', padding: '2px 5px', borderRadius: '3px', fontSize: '12px', marginLeft: '5px' }}>
            {orderDetails?.Calculations?.sst_percentage || 0}
          </span>
        </p>
        <p style={{ margin: '0', fontSize: '14px' }}>{orderDetails?.Calculations?.sst?.toFixed(2) || '0.00'}</p>
      </div> */}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
        <p style={{ margin: '0', fontSize: '14px' }}>Delivery Charges</p>
        <p style={{ margin: '0', fontSize: '14px' }}>{orderDetails?.Calculations?.deliveryTipFee?.toFixed(2) || '0.00'}</p>
      </div>
      <hr style={{ margin: '20px 0' }} />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <p style={{ margin: '0', fontWeight: 'bold', fontSize: '18px' }}>GRAND TOTAL</p>
        <p style={{ margin: '0', fontWeight: 'bold', fontSize: '18px' }}>
          {selectedCurrency.symbol}  {parseFloat((orderDetails?.Calculations?.grandTotal || 0.00) * selectedCurrency.rate).toFixed(2)}
        </p>
      </div>
    </div>
  );


  const renderSellerDetails = () => (
    <div style={{ border: '1px #D3D1D9 solid', backgroundColor: 'white', padding: '20px', borderRadius: '8px', marginTop: '10px' }}>
      <h3 style={{ margin: '0 0 15px 0', fontSize: '18px' }}>Seller Details</h3>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{
          width: '50px',
          height: '50px',
          backgroundColor: '#C1B413',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginRight: '15px'
        }}>
          <span style={{ color: 'white', fontSize: '24px', fontWeight: 'bold' }}>
            {orderDetails?.businessDetails?.[0]?.name?.[0] || 'S'}
          </span>
        </div>
        <div>
          <p style={{ margin: '0', fontWeight: 'bold', fontSize: '16px' }}>{orderDetails?.businessDetails?.[0]?.name || 'Seller Name'}</p>
          <p style={{ margin: '5px 0 0', fontSize: '14px' }}>
            <FontAwesomeIcon icon={faMapMarkerAlt} style={{ marginRight: '5px', color: '#C1B413' }} />
            {orderDetails?.businessDetails?.[0]?.address || 'Seller Address'}
          </p>
          <p style={{ margin: '5px 0 0', fontSize: '14px' }}>
            <FontAwesomeIcon icon={faPhone} style={{ marginRight: '5px', color: '#C1B413' }} />
            {orderDetails?.businessDetails?.[0]?.phone || 'Seller Phone'}
          </p>
        </div>
      </div>
    </div>
  );
  const renderTrackingInfo = () => (
    <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '8px' }}>
      {trackingData.map((step, index) => (
        <div key={index} style={{ display: 'flex', marginBottom: '30px', position: 'relative' }}>
          <div style={{
            width: '30px',
            height: '30px',
            backgroundColor: '#C1B413',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: '15px',
            zIndex: 1
          }}>
            <FontAwesomeIcon icon={getIconForStatus(step.status)} style={{ color: 'white', fontSize: '14px' }} />
          </div>
          {index !== trackingData.length - 1 && (
            <div style={{
              position: 'absolute',
              left: '15px',
              top: '30px',
              bottom: '-30px',
              width: '2px',
              backgroundColor: '#C1B413',
              zIndex: 0
            }} />
          )}
          <div>
            <h4 style={{ margin: '0 0 5px 0', fontSize: '16px' }}>{formatStatus(step.status)}</h4>
            <p style={{ margin: '0', color: '#666', fontSize: '14px' }}>{step.date || step.date_time}</p>
            {step.status === 'ON_GOING' && (
              <button style={{
                backgroundColor: '#C1B413',
                color: 'white',
                border: 'none',
                borderRadius: '20px',
                padding: '5px 15px',
                marginTop: '10px',
                cursor: 'pointer',
                fontSize: '14px'
              }}>
                Track
              </button>
            )}
            {step.driver && (
              <div style={{ marginTop: '10px', backgroundColor: '#f8f8f8', padding: '10px', borderRadius: '8px' }}>
                <p style={{ margin: '0 0 5px 0', fontSize: '14px' }}><strong>Driver:</strong> {step.driver.name}</p>
                <p style={{ margin: '0 0 5px 0', fontSize: '14px' }}>
                  <strong>Phone:</strong> {step.driver.phone}
                  <FontAwesomeIcon icon={faPhone} style={{ color: '#C1B413', marginLeft: '10px', cursor: 'pointer' }} />
                </p>
                {step.driver.plateNumber && (
                  <p style={{ margin: '0', fontSize: '14px' }}><strong>Number Plate:</strong> {step.driver.plateNumber}</p>
                )}
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );


  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <Col md="12" className="mx-auto app-login-box">
            <Card className="main-card mb-3" style={{ padding: '2%', borderRadius: '20px' }}>
              <CardBody style={{ borderRadius: '20px', border: '1px solid #CDCDCD' }} >
                <LoadingOverlay active={loading} spinner={<DNALoader />}>
                  <div style={{ fontFamily: 'Arial, sans-serif', minHeight: "100vh", width: '100%', margin: '0 auto', backgroundColor: 'white' }}>
                    {renderHeader()}
                    <div style={{ maxWidth: '600px', margin: '0 auto', padding: '0px 10px 20px' }}>
                      {renderOrderSummary()}
                      {renderTabs()}
                      {activeTab === 'trackinginfo' && renderTrackingInfo()}
                      {activeTab === 'orderdetails' && renderOrderDetails()}
                      {/* {activeTab === 'orderdetails' && renderSellerDetails()} */}
                    </div>
                  </div>
                </LoadingOverlay>
              </CardBody>
            </Card>

          </Col>
        </div>
      </div>

      <style>
        {`
@keyframes fadeIn {
from { opacity: 0; }
to { opacity: 1; }
}


@keyframes slideIn {
from { transform: translateX(-100%); }
to { transform: translateX(0f); }
}

@keyframes slideUp {
from { transform: translateY(100%); }
to { transform: translateY(0); }
}

.fade-in {
animation: fadeIn 0.5s ease-out;
}

.slide-in {
animation: slideIn 0.3s ease-out;
}

.slide-up {
animation: slideUp 0.3s ease-out;
}

.category-button {
transition: background-color 0.3s, color 0.3s;
}

.product-card {
transition: transform 0.3s, box-shadow 0.3s;
}

.product-card:hover {
transform: translateY(-5px);
box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

@media (max-width: 768px) {
.product-card {
flex-direction: row;
text-align: left;
}

.product-card img {
margin-right: 15px;
margin-bottom: 0;
}
}

@media (max-width: 480px) {
.category-button {
padding: 8px 15px;
margin-right: 5px;
font-size: 12px;
}
}

/* Hide scrollbar for Chrome, Safari and Opera */
#scrollRef::-webkit-scrollbar {
display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#scrollRef {
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
}
`}
      </style>
    </Fragment>
  );
};

export default Orders;


// return (
//   <>
//     <Container fluid style={{ padding: 0, backgroundColor: "black" }}>
//       <Container style={{ maxWidth: isMobile ? '100%' : '90%', padding: 0, backgroundColor: "black" }}>
//         <div style={{ fontFamily: 'Arial, sans-serif', minHeight: "100vh", width: '100%', margin: '0 auto', backgroundColor: 'white', paddingBottom: '60px' }}>
          // <header style={{
          //   position: 'fixed',
          //   width: isMobile ? '100%' : '90%',
          //   top: 0,
          //   marginLeft: isMobile ? '0%' : '5%',
          //   left: 0,
          //   zIndex: 1000,
          //   backgroundColor: 'black',
          // }}>
          //   <div style={{
          //     display: 'flex',
          //     justifyContent: 'space-between',
          //     alignItems: 'center',
          //     padding: '10px',
          //     backgroundColor: 'white',
          //     margin: '0 auto',
          //     maxWidth: '100%',
          //   }}>
          //     <div style={{ borderRadius: '5px', padding: '8px', fontSize: '24px' }}
          //       onClick={() => navigate(-1)}
          //     >
          //       {'>'}</div>
          //     <h3 style={{ cursor: 'pointer', width: '180px', height: 'auto' }} >Order Tracking</h3>
          //     <div>
          //     </div>
          //   </div>
          // </header>
//           <div style={{ marginTop: '120px', maxWidth: '600px', margin: '0 auto', padding: '20px', fontFamily: 'Arial, sans-serif', backgroundColor: '#f5f5f5' }}>
//             <h1 style={{ textAlign: 'center', color: '#333', marginBottom: '20px' }}>Order Details</h1>

//             {/* Order header */}
//             <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
//               <div>
//                 <p style={{ margin: '5px 0', color: '#666' }}>Order ID: #{orderDetails?.order_number}</p>
//                 <p style={{ margin: '5px 0', color: '#666' }}>Placed on: {new Date(orderDetails?.order_date).toLocaleString()}</p>
//               </div>
//               <button style={{
//                 backgroundColor: '#0066cc',
//                 color: 'white',
//                 border: 'none',
//                 borderRadius: '5px',
//                 padding: '10px 20px',
//                 fontWeight: 'bold'
//               }}>
//                 {trackingData?.[0]?.status || orderDetails?.status}
//               </button>
//             </div>

//             {/* Message seller button */}
//             <button style={{
//               backgroundColor: '#333',
//               color: 'white',
//               border: 'none',
//               borderRadius: '5px',
//               padding: '10px 20px',
//               width: '100%',
//               marginBottom: '20px',
//               display: 'flex',
//               alignItems: 'center',
//               justifyContent: 'center',
//               fontWeight: 'bold'
//             }}>
//               <FontAwesomeIcon icon={faCommentAlt} style={{ marginRight: '10px' }} /> Message Seller
//             </button>

//             {/* Details and Tracking buttons */}
//             <div style={{ display: 'flex', marginBottom: '20px' }}>
//               <button
//                 style={{
//                   flex: 1,
//                   backgroundColor: activeTab === 'details' ? '#C1B413' : 'white',
//                   color: activeTab === 'details' ? 'white' : '#333',
//                   border: activeTab === 'details' ? 'none' : '1px solid #ccc',
//                   borderRadius: '5px 0 0 5px',
//                   padding: '10px',
//                   fontWeight: 'bold',
//                   cursor: 'pointer'
//                 }}
//                 onClick={() => setActiveTab('details')}
//               >
//                 DETAILS
//               </button>
//               <button
//                 style={{
//                   flex: 1,
//                   backgroundColor: activeTab === 'tracking' ? '#C1B413' : 'white',
//                   color: activeTab === 'tracking' ? 'white' : '#333',
//                   border: activeTab === 'tracking' ? 'none' : '1px solid #ccc',
//                   borderRadius: '0 5px 5px 0',
//                   padding: '10px',
//                   fontWeight: 'bold',
//                   cursor: 'pointer'
//                 }}
//                 onClick={() => setActiveTab('tracking')}
//               >
//                 TRACKING
//               </button>
//             </div>
//             {activeTab === 'details' ? renderDetailsContent() : renderTrackingContentDEMO()}
//             {/* {activeTab === 'details' ? renderDetailsContent() : renderTrackingContent()} */}

//             {renderCancelOrderButton()}
//           </div>
//         </div >

//       </Container >
//     </Container >
//     <style>
//       {`
// @keyframes fadeIn {
// from { opacity: 0; }
// to { opacity: 1; }
// }
// body {
// background-color: black;
// }

// @keyframes slideIn {
// from { transform: translateX(-100%); }
// to { transform: translateX(0f); }
// }

// @keyframes slideUp {
// from { transform: translateY(100%); }
// to { transform: translateY(0); }
// }

// .fade-in {
// animation: fadeIn 0.5s ease-out;
// }

// .slide-in {
// animation: slideIn 0.3s ease-out;
// }

// .slide-up {
// animation: slideUp 0.3s ease-out;
// }

// .category-button {
// transition: background-color 0.3s, color 0.3s;
// }

// .product-card {
// transition: transform 0.3s, box-shadow 0.3s;
// }

// .product-card:hover {
// transform: translateY(-5px);
// box-shadow: 0 4px 8px rgba(0,0,0,0.2);
// }

// @media (max-width: 768px) {
// .product-card {
// flex-direction: row;
// text-align: left;
// }

// .product-card img {
// margin-right: 15px;
// margin-bottom: 0;
// }
// }

// @media (max-width: 480px) {
// .category-button {
// padding: 8px 15px;
// margin-right: 5px;
// font-size: 12px;
// }
// }

// /* Hide scrollbar for Chrome, Safari and Opera */
// #scrollRef::-webkit-scrollbar {
// display: none;
// }

// /* Hide scrollbar for IE, Edge and Firefox */
// #scrollRef {
// -ms-overflow-style: none;  /* IE and Edge */
// scrollbar-width: none;  /* Firefox */
// }
// `}
//     </style>
//   </>
// );
// };

