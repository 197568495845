import React, { Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { Col } from "reactstrap";
import Tabs, { TabPane } from "rc-tabs";
import TabContent from "rc-tabs/lib/SwipeableTabContent";
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar";
import TabSection from "./TabSection";
import TabAttribute from "./TabAttribute";

const BusinessesSettings = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className="bg-light-gray bg-animation">
            <div className="d-flex justify-content-center align-items-center">
              <Col md="12" className="mx-auto app-login-box">
                <div className="app-page-title">
                  <div className="page-title-wrapper">
                    <div className="page-title-heading fw-bold">
                      <div className="page-title-icon">
                        <i className="pe-7s-news-paper icon-gradient bg-tempting-azure" />
                      </div>
                      <div>
                        {`${t("Business")} ${t("Settings")}`}
                        <div className="page-title-subheading">
                          {t("Add, Update & Delete of Section & Attribute")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-center">
                  <div className="col-md-12">
                    <Tabs
                      defaultActiveKey="1"
                      renderTabBar={() => <ScrollableInkTabBar />}
                      renderTabContent={() => <TabContent />}
                    >
                      <TabPane tab={t('Section')} key="1">
                        <TabSection className="btn-custom-color" />
                      </TabPane>
                      <TabPane tab={t('Attribute')} key="2">
                        <TabAttribute className="btn-custom-color" />
                      </TabPane>
                    </Tabs>
                  </div>
                </div>

              </Col>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default BusinessesSettings;
