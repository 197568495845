import axios from "axios";


const API_URL = process.env.REACT_APP_BASEURL;
const Login_url = process.env.REACT_APP_LOGINURL;
// console.log("Login URL:-", Login_url)
// const API_URL = process.env.;

const register = (username, email, password, role_id) => {

  var newdata = {
    functionName: "AddUser",
    postData: {
      "name": username,
      "email": email,
      "password": password,
      "role_id": role_id
    },
  }
  return axios.post(API_URL + "settings", newdata);
  // return axios.post(API_URL + "signup", {
  //   username,
  //   email,
  //   password,
  // });
};

const registerCustomer = (payload) => {

  return axios.post(Login_url + "UserModule/Register_User", payload);
};

const Register_Member = (payload) => {

  return axios.post(Login_url + "UserModule/Register_Member", payload, {
    headers: {
      'Content-Type': 'application/json',
    }
  });
};

const checkUsername = (payload) => {

  return axios.post(Login_url + "UserModule/check_username", payload, {
    headers: {
      'Content-Type': 'application/json',
    }
  });
};

const loginCustomer = (username, password, user_type, business_id) => {
  var logindata = {
    functionName: "LoginMainUser",
    postData: {
      "username": username,
      "password": password,
      "user_type": user_type,
      "business_id": business_id

    },
  }

  return axios
    .post(Login_url + "login/", logindata)
    .then((response) => {

      if (response.data.status) {
        // if (response.data.accessToken) {
        var user_data = response.data.userData;
        user_data['username'] = user_data.email;
        user_data['accessToken'] = user_data._id;
        user_data['session-id'] = response.data.sessionID;
        user_data['roles'] = [
          "ROLE_ADMIN",
          "ROLE_USER"
        ];
        console.log("Login Response:-", response)
        localStorage.setItem("user", JSON.stringify(user_data));
        localStorage.setItem("session-id", response.data.sessionID);
      }
      // return response.data;
      console.log("User Data:-", user_data);
      return user_data;
    });
};

const login = (username, password, usertype) => {
  var logindata = {
    functionName: "LoginMainUser",
    postData: {
      "username": username,
      "password": password,
      "user_type": usertype,

    },
  }

  return axios
    .post(Login_url + "login/", logindata)
    .then((response) => {
      console.log("Login Response:-", response)
      if (response.data.status) {
        // if (response.data.accessToken) {
        var user_data = response.data.userData;
        user_data['username'] = user_data.username;
        user_data['accessToken'] = user_data._id;
        user_data['session-id'] = response.data.sessionID;
        user_data['roles'] = [
          "ROLE_ADMIN",
          "ROLE_USER"
        ];
        const { frontId, backId, selfie, ...filteredUser } = user_data;
        localStorage.setItem("user", JSON.stringify(filteredUser));
        localStorage.setItem("first_login", filteredUser.first_login)
        localStorage.setItem("session-id", response.data.sessionID);
      }
      // return response.data;
      console.log("User Data:-", user_data);
      return user_data;
    });
};

const logout = () => {
  // localStorage.removeItem("user");
  const logo = localStorage.getItem("system_logo");
  const buttons_color = localStorage.getItem("buttons_color");
  const topbar_color = localStorage.getItem("topbar_color");
  const leftbar_color = localStorage.getItem("leftbar_color");
  const buttons_text_color = localStorage.getItem("buttons_text_color");
  const language = localStorage.getItem("language");

  localStorage.clear();
  localStorage.setItem("system_logo", logo);
  localStorage.setItem("buttons_color", buttons_color);
  localStorage.setItem("topbar_color", topbar_color);
  localStorage.setItem("leftbar_color", leftbar_color);
  localStorage.setItem("buttons_text_color", buttons_text_color);
  localStorage.setItem("language", language);

  var logindata = {
    functionName: "LogoutUser",
    postData: {
      // "email": username,
      // "password": password,
      // "resetcode": resetcode
    },
  }

  return axios
    // return axios.post(API_URL+"logout/")  
    .post(API_URL + "logout/", logindata)
    .then((response) => {
      return response.data;
    });
};

const pwresetreq = (username) => {

  var logindata = {
    functionName: "GetResetCodeMainUser",
    postData: {
      "email": username,
    },
  }

  return axios
    .post(Login_url + "forgotpassword/", logindata)
    .then((response) => {

      // if (response.data.status) {
      // if (response.data.accessToken) {
      // var user_data = response.data.userData;
      // }

      // console.log(response.headers);
      //   console.log(response.request);
      //   console.log(response.config);
      //   console.log(response.data);

      // return response.data;
      // console.log(user_data);
      return response;
    });
};

const resetpw = (username, password, resetcode) => {

  var logindata = {
    functionName: "ResetPassowrdMainUser",
    postData: {
      "email": username,
      "password": password,
      "resetcode": resetcode
    },
  }

  return axios.post(Login_url + "forgotpassword/", logindata)
  // .then((response) => {

  //   if (response.data.status) {
  //     var user_data = response.data.userData;
  //   }
  //   console.log(user_data);
  //   return user_data;
  // });
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const getCountries = () => {
  return axios.post(`${Login_url}AddressModule/GetCountries`,
    {
      headers: {
        'Content-Type': 'application/json',
      }
    });
}

const getRegions = (countryId) => {
  return axios.post(`${Login_url}AddressModule/regions`, {
    postData: { country_id: countryId },
    functionName: "GetRegions"
  }, {
    headers: {
      'Content-Type': 'application/json',
    }
  });
}

const getProvinces = (regionId) => {
  return axios.post(`${Login_url}AddressModule/GetProvinces`, {
    postData: { region_id: regionId },
    functionName: "GetRegions"
  }, {
    headers: {
      'Content-Type': 'application/json',
    }
  });
}

const getDistricts = (provinceId) => {
  return axios.post(`${Login_url}AddressModule/districts`, {
    postData: { province_id: provinceId },
    functionName: "districts"
  }, {
    headers: {
      'Content-Type': 'application/json',
    }
  });
}

const getCities = (districtId) => {
  return axios.post(`${Login_url}AddressModule/GetCities`, {
    postData: { district_id: districtId },
    functionName: "GetCities"
  }, {
    headers: {
      'Content-Type': 'application/json',
    }
  });
}

const getBarangays = (cityId) => {
  return axios.post(`${Login_url}AddressModule/barangays`, {
    postData: { city_id: cityId },
    functionName: "barangays"
  }, {
    headers: {
      'Content-Type': 'application/json',
    }
  });
}

const getZipCodes = (barangayId) => {
  return axios.post(`${Login_url}AddressModule/GetZipCodes`, {
    postData: { barangay_id: barangayId },
    functionName: "GetZipCodes"
  }, {
    headers: {
      'Content-Type': 'application/json',
    }
  });
}


const AuthService = {
  getCountries,
  getRegions,
  getProvinces,
  getDistricts,
  getCities,
  getBarangays,
  getZipCodes,
  register,
  registerCustomer,
  Register_Member,
  checkUsername,
  loginCustomer,
  login,
  logout,
  pwresetreq,
  resetpw,
  getCurrentUser,
}

export default AuthService;
