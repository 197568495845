import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, Navigate } from "react-router-dom";
import { Row, Col, FormGroup, Input, Button, Label, Modal, ModalHeader, ModalBody, Card, CardBody } from "reactstrap";
import Services from "./services";
import AuthService from "../Authentication/auth.service";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import Alerts from "../../components/Alerts/alerts";
import PermissionAccess from '../../PermissionAccess/Access.js';

const RANK_CATEGORIES = {
  infin8_starter: {
    name: "Infin8 Starter Rank",
    left_org_members: 500,
    right_org_members: 500,
    max_members: 300000,
    spv_reward_per_member: 0.2,
    one_time_reward: 150,
    infin8_spv_reward: "0.2 SPV to given across all members",
    maintenance: "must have a monthly personal repurchase worth of at least 25 DPV"
  },
  infin8_explorer: {
    name: "Infin8 Explorer Rank",
    left_org_members: 1250,
    right_org_members: 1250,
    max_members: 500000,
    spv_reward_per_member: 0.4,
    one_time_reward: 300,
    infin8_spv_reward: "0.4 SPV to given across all members from the date of Promotion",
    maintenance: "must have a monthly personal repurchase worth of at least 25 DPV"
  },
  infin8_pioneer: {
    name: "Infin8 Pioneer Rank",
    left_org_members: 2250,
    right_org_members: 2250,
    max_members: 1000000,
    spv_reward_per_member: 0.6,
    one_time_reward: 450,
    infin8_spv_reward: "0.6 SPV to given across all members from the date of Promotion",
    maintenance: "must have a monthly personal repurchase worth of at least 50 DPV"
  },
  infin8_leader: {
    name: "Infin8 Leader Rank",
    left_org_members: 3500,
    right_org_members: 3500,
    max_members: 1500000,
    spv_reward_per_member: 0.8,
    one_time_reward: 600,
    infin8_spv_reward: "0.8 SPV to given across all members from the date of Promotion",
    maintenance: "must have a monthly personal repurchase worth of at least 50 DPV"
  },
  infin8_visionary: {
    name: "Infin8 Visionary Rank",
    left_org_members: 5000,
    right_org_members: 5000,
    max_members: 8000000,
    spv_reward_per_member: 1,
    one_time_reward: 750,
    infin8_spv_reward: "1 SPV to given across all members from the date of Promotion",
    maintenance: "must have a monthly personal repurchase worth of at least 75 DPV"
  }
};

const RANK_OPTIONS = [
  { value: "infin8_starter", label: "Infin8 Starter Rank" },
  { value: "infin8_explorer", label: "Infin8 Explorer Rank" },
  { value: "infin8_pioneer", label: "Infin8 Pioneer Rank" },
  { value: "infin8_leader", label: "Infin8 Leader Rank" },
  { value: "infin8_visionary", label: "Infin8 Visionary Rank" }
];

const UpdateRanks = ({ RANKS, onSuccess, onCancel }) => {
  const [modal, setModal] = useState(true);
  const [ranks, setRanks] = useState({ ...RANKS });
  const [loading, setLoading] = useState(false);
  const [loginNavigate, setLoginNavigate] = useState(false);
  const { authData } = PermissionAccess();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
  }, [i18n]);

  const handleRanksChange = (field, value) => {
    setRanks(prev => ({ ...prev, [field]: value }));
  };

  const validateForm = () => {
    return (
      ranks.name &&
      ranks.left_org_members &&
      ranks.right_org_members &&
      ranks.max_members &&
      ranks.spv_reward_per_member &&
      ranks.one_time_reward &&
      ranks.infin8_spv_reward &&
      ranks.maintenance
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      Alerts.swalErrorAlert("Please fill all required fields.");
      return;
    }

    let payload = {
      name: ranks.name,
      left_org_members: ranks.left_org_members,
      right_org_members: ranks.right_org_members,
      max_members: ranks.max_members,
      start_counting: ranks.start_counting,
      spv_reward_per_member: ranks.spv_reward_per_member,
      one_time_reward: ranks.one_time_reward,
      infin8_spv_reward: ranks.infin8_spv_reward,
      maintenance: ranks.maintenance
    };
    
    setLoading(true);
    try {
      const response = await Services.UpdateRanks(authData.sessionID, ranks._id, payload);
      setLoading(false);
      if (response.data.status) {
        onSuccess();
        Alerts.swalSuccessAlert(response.data.message || "Rank Updated Successfully.").then(() => {
          setModal(false);
        });
      } else {
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          setLoginNavigate(true);
        } else {
          Alerts.swalErrorAlert(response.data.message || "Error: Can't Update Rank at the moment. Please try again later.");
        }
      }
    } catch (error) {
      setLoading(false);
      Alerts.swalErrorAlert("Network Operation failed.");
    }
  };

  if (loginNavigate) {
    return <Navigate to={'/login'} />;
  }

  return (
    <Modal size='lg' isOpen={modal} toggle={() => setModal(false)} backdrop={true}>
      <LoadingOverlay active={loading} spinner={<DNALoader />}>
        <ModalHeader>{t("Update Rank")}</ModalHeader>
        <ModalBody>
          <Card className="main-card mb-3">
            <CardBody>
              <form onSubmit={handleSubmit}>
                <FormGroup className="mb-3">
                  <Row>
                    <Col md="12" className="mb-3">
                      <Label>{t("Rank Category")} *</Label>
                      <Input
                        type="text"
                        value={ranks.name}
                        onChange={(e) => handleRanksChange('name', e.target.value)}
                        required
                      >
                      </Input>
                    </Col>
                    <Col md="6" className="mb-3">
                      <Label>{t("Required Members on Left Org")} *</Label>
                      <Input
                        type="number"
                        value={ranks.left_org_members}
                        onChange={(e) => handleRanksChange('left_org_members', e.target.value)}
                        placeholder="e.g. 500"
                        required
                      />
                    </Col>
                    <Col md="6" className="mb-3">
                      <Label>{t("Required Members on Right Org")} *</Label>
                      <Input
                        type="number"
                        value={ranks.right_org_members}
                        onChange={(e) => handleRanksChange('right_org_members', e.target.value)}
                        placeholder="e.g. 500"
                        required
                      />
                    </Col>
                    <Col md="6" className="mb-3">
                      <Label>{t("Max Members")} *</Label>
                      <Input
                        type="number"
                        value={ranks.max_members}
                        onChange={(e) => handleRanksChange('max_members', e.target.value)}
                        placeholder="e.g. 300,000"
                        required
                      />
                    </Col>
                    <Col md="6" className="mb-3">
                      <Label>{t("Start of Counting")} *</Label>
                      <Input
                        type="number"
                        value={ranks.start_counting}
                        onChange={(e) => handleRanksChange('start_counting', e.target.value)}
                        placeholder="e.g. Time of Promotion"
                        required
                      />
                    </Col>
                    <Col md="6" className="mb-3">
                      <Label>{t("SPV Reward Per Member")} *</Label>
                      <Input
                        type="number"
                        step="0.1"
                        value={ranks.spv_reward_per_member}
                        onChange={(e) => handleRanksChange('spv_reward_per_member', e.target.value)}
                        placeholder="e.g. 0.2"
                        required
                      />
                    </Col>
                    <Col md="6" className="mb-3">
                      <Label>{t("One Time Reward (DPV)")} *</Label>
                      <Input
                        type="number"
                        value={ranks.one_time_reward}
                        onChange={(e) => handleRanksChange('one_time_reward', e.target.value)}
                        placeholder="e.g. 150"
                        required
                      />
                    </Col>
                    <Col md="6" className="mb-3">
                      <Label>{t("Infin8 SPV Reward")} *</Label>
                      <Input
                        type="textarea"
                        value={ranks.infin8_spv_reward}
                        onChange={(e) => handleRanksChange('infin8_spv_reward', e.target.value)}
                        placeholder="e.g. 0.2 SPV to given across all members"
                        required
                      />
                    </Col>
                    <Col md="6" className="mb-3">
                      <Label>{t("Maintenance")} *</Label>
                      <Input
                        type="textarea"
                        value={ranks.maintenance}
                        onChange={(e) => handleRanksChange('maintenance', e.target.value)}
                        placeholder="e.g. Monthly personal repurchase worth at least 25 DPV"
                        required
                      />
                    </Col>
                  </Row>
                  <div className="text-center mt-3">
                    <Button type="submit" color="success" size="lg" className="me-3">
                      {t("Update")}
                    </Button>
                    <Button type="button" color="secondary" size="lg" onClick={() => { setModal(false); onCancel(); }}>
                      {t("Cancel")}
                    </Button>
                  </div>
                </FormGroup>
              </form>
            </CardBody>
          </Card>
        </ModalBody>
      </LoadingOverlay>
    </Modal>
  );
};

export default UpdateRanks;