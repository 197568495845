import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import styled from "styled-components";
import EntitiesService from "../../../pages/Entities/entities.service";
import AuthService from "../../../pages/Authentication/auth.service";
import Alerts from "../../Alerts/alerts";


const Input = styled.select.attrs(props => ({
  type: "text",
  size: props.small ? 5 : undefined
}))`
  height: 32px;
  width: 17%;
  border-radius: 6px;
  border: 1px solid var(--buttons-color, #30b1ff);
  padding: 0 8px 0 8px;
  margin-bottom: 12px;
  justify-content: flex-start;
  background: white;
  margin-right: 12px;
`;

const ClearButton = styled.button`
  border:none;
  height: 32px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  margin-bottom: 12px;
  border-radius: 0 5px 5px 0;
  color: white;
  background: var(--buttons-color, #30b1ff);
`;

const CollectionFilter = ({ filterText1, handleKeyDown, onFilter1, onClear }) => {

  const { t, i18n } = useTranslation();
  const [collection, setCollection] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchEmails();
  }, []);

  function fetchEmails() {
    EntitiesService.fetchEmails().then((response) => {
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        console.log("Emails Response", response);
        setCollection(response.data.data);
      }
    },
      (error) => {
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };



  return (
    <>
      <input
        type="text"
        style={{
          height: '32px',
          width: '17%',
          borderRadius: "6px",
          border: "1px solid var(--buttons-color, #30b1ff)",
          padding: "0 8px 0 8px",
          marginBottom: "12px",
          justifyContent: "flex-start",
          background: "white",
          marginRight: "12px",
        }}
        placeholder={t("Search Text")}
        value={filterText1}
        onChange={onFilter1}
        // onKeyDown={handleKeyDown}
        id="colfilter"
      >

        {/* <option disabled={false} value="">{`--${t("Select Email")}--`}</option>
        {collection &&
          collection.map((opt_collection, index) => (
            <option key={index} value={opt_collection.recipient}>{opt_collection.recipient}</option>
          ))
        } */}
      </input>
      {/* <ClearButton onClick={onClear}>X</ClearButton> */}
    </>
  );

};

export default CollectionFilter;


