
import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { Col, Row, Label, FormGroup, Input, Card, CardBody } from "reactstrap";
import { Form, Field } from "react-final-form";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";
import Alerts from "../../components/Alerts/alerts";
import UserService from "../../pages/Users Management/users.service";
import AuthService from "../Authentication/auth.service";
import RoleService from "../Roles/roles.service";
import $ from 'jquery';
import PermissionAccess from '../../PermissionAccess/Access.js';

const AddUser = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [BusinessList, setBusinesses] = useState([]);
  const [modulesvalues, setmodulesvalues] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const { authData, accessPages, super_admin, current_module } = PermissionAccess();

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    getroles();
    GetBusinesses();
  }, []);

  const getroles = async () => {
    try {
      const response = await RoleService.getAll(authData.sessionID);
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      } else {
        const data = await response.data.data;
        setRoles(data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const GetBusinesses = async () => {
    try {
      const response = await RoleService.GetBusinesses(authData.sessionID);
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      } else {
        const data = await response.data.data;
        setBusinesses(data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleModuleChange = (e) => {
    const modval = e.target.value;
    const parent = $(e.target).attr('parent');
    const modulesvaluesarr = [...modulesvalues];

    if (e.target.checked) {
      $(".child-check-box-" + modval).prop("checked", true);
      if (parent !== "") {
        $("#module-checkbox" + parent).prop("checked", true);
      }
      if (!modulesvaluesarr.includes(modval)) {
        modulesvaluesarr.push(modval);
      }
    } else {
      $(".child-check-box-" + modval).prop("checked", false);
      const index = modulesvaluesarr.indexOf(modval);
      if (index !== -1) {
        modulesvaluesarr.splice(index, 1);
      }
    }

    setmodulesvalues(modulesvaluesarr);
    setCheckAll(modulesvaluesarr.length === BusinessList.length);
  };

  const handleCheckAllChange = () => {
    if (checkAll) {
      setmodulesvalues([]);
      $(".module-checkbox").prop("checked", false);
    } else {
      const allBusinessIds = BusinessList.map(business => business._id);
      setmodulesvalues(allBusinessIds);
      $(".module-checkbox").prop("checked", true);
    }
    setCheckAll(!checkAll);
  };

  const onSubmit = values => {
    console.log(values);
    const payloadData = {
      functionName: "AddUser",
      postData: {
        name: values.username,
        email: values.email,
        password: values.password,
        contact: values.contact,
        role_id: values.userrole,
        businesses: modulesvalues,
        status: values.userstatus
      },
    };
    setLoading(true);
    UserService.addUser(authData.sessionID,payloadData).then((response) => {
      console.log("API Response:-", response);
      setLoading(false);
      if (response.data.status) {
        navigate("/users-list");
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message);
        } else {
          Alerts.swalSuccessAlert(t("User added successfully."));
        }
      } else {
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          } else {
            Alerts.swalErrorAlert(response.data.message);
          }
        } else {
          Alerts.swalErrorAlert(t("Error: Can't add user at the moment. Please try again later."));
        }
      }
    }, (error) => {
      setLoading(false);
      const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      Alerts.swalErrorAlert(t("Network Operation failed."));
    });
  };

  const validateFtn = (values) => {
    const errors = {};
    function validateEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
    if (!values.username) {
      errors.username = t("User Name Required.");
    }
    if (!values.email) {
      errors.email = t("Email Required.");
    } else if (!validateEmail(values.email)) {
      errors.email = t("Please enter a valid email address.");
    }
    if (!values.password) {
      errors.password = t("Password Required.");
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = t("Confirm Password Required.");
    }
    if (values.confirmPassword !== values.password) {
      errors.confirmPassword = t("Passwords do not match");
    }
    if (!values.contact) {
      errors.contact = t("Contact No Required.");
    }
    if (!values.userrole) {
      errors.userrole = t("Please select a role.");
    }
    if (!values.userstatus) {
      errors.userstatus = t("Please select status.");
    }
    return errors;
  };

  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className="app-page-title">
            <div className="page-title-wrapper justify-content-left">
              <div className="page-title-heading fw-bold">
                <div className="page-title-icon">
                  <i className="pe-7s-add-user icon-gradient bg-happy-green" />
                </div>
                <div>{t('Add New User')}
                  <div className="page-title-subheading">
                    {t('Fill the form below to add a new User.')}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center">
            <div className="col-md-12">
              <Card className="main-card mb-3">
                <CardBody>
                  <Form onSubmit={onSubmit}
                    validate={validateFtn}
                    render={({ handleSubmit, values, submitting, validating, valid }) => (
                      <form onSubmit={handleSubmit}>
                        <LoadingOverlay tag="div" active={loading}
                          styles={{ overlay: (base) => ({ ...base }) }}
                          spinner={<DNALoader />}>
                          <Row form>
                            <Col md={6}>
                              <FormGroup>
                                <Field name="username">
                                  {({ input, meta }) => (
                                    <div>
                                      <Label for="username">{t('User Name')}</Label>
                                      <Input {...input} type="text"
                                        invalid={meta.error && meta.touched}
                                        placeholder={t('User Name')}
                                      />
                                      {meta.error && meta.touched &&
                                        <span className="text-danger">{meta.error}</span>}
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                              <FormGroup>
                                <Field name="password">
                                  {({ input, meta }) => (
                                    <div>
                                      <Label for="password">{t('Password')}</Label>
                                      <Input {...input} type="password"
                                        invalid={meta.error && meta.touched}
                                        placeholder="********"
                                      />
                                      {meta.error && meta.touched &&
                                        <span className="text-danger">{meta.error}</span>}
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                              <FormGroup>
                                <Field name="userstatus">
                                  {({ input, meta }) => (
                                    <div>
                                      <Label for="status">{t('Status')}</Label>
                                      <Input {...input} type="select"
                                        invalid={meta.error && meta.touched}
                                      >
                                        <option disabled={true} value="">{t('--Choose an option--')}</option>
                                        <option value="1">{t('Active')}</option>
                                        <option value="0">{t('InActive')}</option>
                                      </Input>
                                      {meta.error && meta.touched &&
                                        <span className="text-danger">{meta.error}</span>}
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                              <FormGroup>
                                <Field name="userrole">
                                  {({ input, meta }) => (
                                    <div>
                                      <Label for="role">{t('Role')}</Label>
                                      <Input {...input} type="select"
                                        invalid={meta.error && meta.touched}
                                      >
                                        <option disabled={true} value="">{t('--Choose an option--')}</option>
                                        {roles &&
                                          roles.map((role, inde) => (
                                            <option key={inde} value={role._id}>{role.name}</option>
                                          ))
                                        }
                                      </Input>
                                      {meta.error && meta.touched &&
                                        <span className="text-danger">{meta.error}</span>}
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <Field name="email">
                                  {({ input, meta }) => (
                                    <div>
                                      <Label for="email">{t('Email')}</Label>
                                      <Input {...input} type="email"
                                        invalid={meta.error && meta.touched}
                                        placeholder={t('User Email')}
                                      />
                                      {meta.error && meta.touched &&
                                        <span className="text-danger">{meta.error}</span>}
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                              <FormGroup>
                                <Field name="confirmPassword">
                                  {({ input, meta }) => (
                                    <div>
                                      <Label for="password">{t('Confirm Password')}</Label>
                                      <Input {...input} type="password"
                                        invalid={meta.error && meta.touched}
                                        placeholder="********"
                                      />
                                      {meta.error && meta.touched &&
                                        <span className="text-danger">{meta.error}</span>}
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                              <FormGroup>
                                <Field name="contact">
                                  {({ input, meta }) => (
                                    <div>
                                      <Label for="contact">{t('Contact No')}</Label>
                                      <Input {...input} type="number"
                                        invalid={meta.error && meta.touched}
                                        placeholder={t('User Contact No')}
                                      />
                                      {meta.error && meta.touched &&
                                        <span className="text-danger">{meta.error}</span>}
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                            </Col>
                            <div className="mb-3">
                              <h5 >{t('Assigned Business')}
                                <input
                                  style={{ marginLeft: 15, marginTop: 2 }}
                                  type="checkbox"
                                  checked={checkAll}
                                  onChange={handleCheckAllChange}
                                />
                              </h5>
                            </div>
                            {BusinessList.length > 0 && BusinessList.map((module, index) => (
                              <div className="accordion col-md-6 col-xl-6 pb-3" key={module._id}>
                                <div className="card">
                                  <div className="card-header bg-light" id={"heading" + module._id}>
                                    <input
                                      className="form-check-input module-checkbox"
                                      type="checkbox"
                                      name="modules[]"
                                      value={module._id}
                                      onChange={handleModuleChange}
                                      id={"module-checkbox" + module._id}
                                      parent=""
                                      checked={modulesvalues.includes(module._id)}
                                    />
                                    {'  '}
                                    <a className="mt-1">
                                      &nbsp;&nbsp;{module.name}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            ))}
                            <FormGroup>
                              <div className="d-flex justify-content-center">
                                <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-light btn-hover-shine me-3 mt-3">{t('Cancel')}</button>
                                <button className="btn btn-custom-color btn-hover-shine me-3 mt-3" disabled={!valid}>{t('Add User')}</button>
                              </div>
                            </FormGroup>
                          </Row>
                        </LoadingOverlay>
                      </form>
                    )}
                  />
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AddUser;























{/* <div className="row">
            <div className="col-lg-3" ></div>
              <div className="col-lg-6" >
              <div className="card">
                <div className="card-body">
                  <Form onSubmit={addupdateuser} ref={form}>
                    {!successful && (
                      <div>
                        <div className="form-group">
                          <div className="col-md-12 col-xl-12" >
                            <label htmlFor="username">Name</label>
                            <Input
                              type="text"
                              className="form-control"
                              name="username"
                              value={username}
                              placeholder="User Name"
                              onChange={onChangeUsername}
                              validations={[required, vusername]}
                              autoComplete="off"
                            />
                          </div>
                        </div>
                        <br/>

                        <div className="form-group">
                          <div className="col-md-12 col-xl-12" >
                            <label htmlFor="useremail">Email</label>
                            <Input
                              type="email"
                              className="form-control"
                              name="useremail"
                              value={useremail}
                              placeholder="User Email"
                              onChange={onChangeUseremail}
                              validations={[required]}
                            />
                          </div>
                        </div>
                        <br/>

                        {!state &&
                          <div className="form-group">
                            <div className="col-md-12 col-xl-12" >
                              <label htmlFor="useremail">Password</label>
                              <Input
                                type="password"
                                className="form-control"
                                name="password"
                                value={password}
                                placeholder="User Password"
                                onChange={onChangePassword}
                                validations={[required]}
                                autoComplete="new-password"
                              />
                            </div>
                            <br/>
                          </div>
                        }

                        <div className="form-group">
                          <div className="col-md-12 col-xl-12" >
                            <label htmlFor="usercontact">Contact No.</label>
                            <Input
                              type="number"
                              className="form-control"
                              name="contact"
                              value={contact}
                              placeholder="User Contact No"
                              onChange={onChangecontact}
                              validations={[required]}
                            />
                          </div>
                        </div>
                        <br/>

                        <div className="form-group">
                          <div className="col-md-12 col-xl-12" >
                            <label htmlFor="role">Role</label>
                            <select className="form-select" onChange={onChangeRole} value={userrole}>
                              <option disabled={true} value="">--Choose an option--</option>
                              {roles &&
                                roles.map((role, inde) => (
                                  <option key = {inde} value={role._id}>{role.name}</option>
                                ))
                              }
                            </select>
                            <div className="form-group" style={{marginTop: "16px", marginLeft: "8px"}}>
                              {rolerequired == false && 
                                <p style={{color:"red", fontSize:"10px"}}>This selection is required!</p>}
                            </div>
                          </div>
                        </div>
                        <br/>

                        <div className="form-group">
                          <div className="col-md-12 col-xl-12" >
                            <label htmlFor="status">Status</label>
                            <select className="form-select" onChange={onChangeStatus} value={userstatus}>
                              <option disabled={true} value="">--Choose an option--</option>
                              {statuses &&
                                statuses.map((stat, ind) => (
                                  <option key = {ind} value={stat.id}>{stat.name}</option>
                                ))
                              }
                            </select>
                            <div className="form-group" style={{marginTop: "16px", marginLeft: "8px"}}>
                              {statusrequired == false && 
                                <p style={{color:"red", fontSize:"10px"}}>This selection is required!</p>}
                            </div>
                          </div>
                        </div>
                        <br/>
                       
                        <div className="form-group">
                          <div className="col-md-12 col-xl-12" >
                            <center>
                              <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-light btn-hover-shine me-3">Cancel</button>
                              {!state &&
                                <button className="btn btn-custom-color btn-hover-shine me-3">Add User</button>
                              }
                              {state &&
                                <button className="btn btn-custom-color btn-hover-shine me-3">Update User</button>
                              }
                            </center>
                          </div>
                        </div>
                      </div>
                    )}

                    {message && (
                      <div className="form-group">
                        <div
                          className={
                            successful ? "alert alert-success" : "alert alert-danger"
                          }
                          role="alert"
                        >
                          {message}
                        </div>
                      </div>
                    )}
                    <CheckButton style={{ display: "none" }} ref={checkBtn} />
                  </Form>

                </div>
              </div>
            </div>
</div> */}



{/* <Form onSubmit={addupdateuser} ref={form}>
    {!successful && (
      <div>
        <div className="form-group">
          <div className="col-md-12 col-xl-12" >
            <label htmlFor="username">Name</label>
            <Input
              type="text"
              className="form-control"
              name="username"
              value={username}
              placeholder="User Name"
              onChange={onChangeUsername}
              validations={[required, vusername]}
              autoComplete="off"
            />
          </div>
        </div>
        <br/>

        <div className="form-group">
          <div className="col-md-12 col-xl-12" >
            <label htmlFor="useremail">Email</label>
            <Input
              type="email"
              className="form-control"
              name="useremail"
              value={useremail}
              placeholder="User Email"
              onChange={onChangeUseremail}
              validations={[required]}
            />
          </div>
        </div>
        <br/>

        {!state &&
          <div className="form-group">
            <div className="col-md-12 col-xl-12" >
              <label htmlFor="useremail">Password</label>
              <Input
                type="password"
                className="form-control"
                name="password"
                value={password}
                placeholder="User Password"
                onChange={onChangePassword}
                validations={[required]}
                autoComplete="new-password"
              />
            </div>
            <br/>
          </div>
        }

        <div className="form-group">
          <div className="col-md-12 col-xl-12" >
            <label htmlFor="usercontact">Contact No.</label>
            <Input
              type="number"
              className="form-control"
              name="contact"
              value={contact}
              placeholder="User Contact No"
              onChange={onChangecontact}
              validations={[required]}
            />
          </div>
        </div>
        <br/>

        <div className="form-group">
          <div className="col-md-12 col-xl-12" >
            <label htmlFor="role">Role</label>
            <select className="form-select" onChange={onChangeRole} value={userrole}>
              <option disabled={true} value="">--Choose an option--</option>
              {roles &&
                roles.map((role, inde) => (
                  <option key = {inde} value={role._id}>{role.name}</option>
                ))
              }
            </select>
            <div className="form-group" style={{marginTop: "16px", marginLeft: "8px"}}>
              {rolerequired == false && 
                <p style={{color:"red", fontSize:"10px"}}>This selection is required!</p>}
            </div>
          </div>
        </div>
        <br/>

        <div className="form-group">
          <div className="col-md-12 col-xl-12" >
            <label htmlFor="status">Status</label>
            <select className="form-select" onChange={onChangeStatus} value={userstatus}>
              <option disabled={true} value="">--Choose an option--</option>
              {statuses &&
                statuses.map((stat, ind) => (
                  <option key = {ind} value={stat.id}>{stat.name}</option>
                ))
              }
            </select>
            <div className="form-group" style={{marginTop: "16px", marginLeft: "8px"}}>
              {statusrequired == false && 
                <p style={{color:"red", fontSize:"10px"}}>This selection is required!</p>}
            </div>
          </div>
        </div>
        <br/>
        
        <div className="form-group">
          <div className="col-md-12 col-xl-12" >
            <center>
              <button type="button" onClick={() => navigate(-1)} className="btn btn-soft-secondary btn-block me-2">Cancel</button>
              {!state &&
                <button className="btn btn-custom-color btn-block me-2">Add User</button>
              }
              {state &&
                <button className="btn btn-custom-color btn-block me-2">Update User</button>
              }
            </center>
          </div>
        </div>
      </div>
    )}
    {message && (
      <div className="form-group">
        <div
          className={
            successful ? "alert alert-success" : "alert alert-danger"
          }
          role="alert"
        >
          {message}
        </div>
      </div>
    )}
    <CheckButton style={{ display: "none" }} ref={checkBtn} />
  </Form> */}