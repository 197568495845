import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Row, Col, FormGroup, Input, Button, Label, Modal, ModalHeader, ModalBody, Card, CardBody } from "reactstrap";
import CustomerService from "./Variations.service";
import AuthService from "../Authentication/auth.service";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import Alerts from "../../components/Alerts/alerts";
import PermissionAccess from '../../PermissionAccess/Access.js';

const ImagePopup = ({ src }) => (
  <div style={{
    position: 'absolute',
    zIndex: 1000,
    border: '1px solid #ccc',
    borderRadius: '4px',
    padding: '5px',
    background: 'white',
    boxShadow: '0 2px 10px rgba(0,0,0,0.1)'
  }}>
    <img src={src} alt="Preview" style={{ maxWidth: '200px', maxHeight: '200px' }} />
  </div>
);

const ImagePopup3 = ({ src }) => (
  <div style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'auto',
    height: '150px',
    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
    marginTop: '10px',
    marginBottom: '10px'
  }}>
    <img
      src={src}
      alt="Preview"
      style={{
        maxWidth: '100%',
        maxHeight: '100%',
        objectFit: 'contain'
      }}
    />
  </div>
);

const DocsUpdateCustomer = ({ data, onSuccess }) => {
  const [modal, setModal] = useState(false);
  const [variation, setVariation] = useState({ ...data, Status: 'Active' }); // Set status to Active by default
  const [loading, setLoading] = useState(false);
  const [showImagePopup, setShowImagePopup] = useState(false);
  const [Fetched_Businesses, setFetched_Businesses] = useState([]);
  const navigate = useNavigate();
  const { authData } = PermissionAccess();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    Fetch_Businesses();
  }, []);

  function Fetch_Businesses() {
    setLoading(true);
    const stored_user = authData.userData;
    let business = []
    if (stored_user.businesses) {
      business = stored_user.businesses;
    }
    CustomerService.GetBusinesses(authData.sessionID, business).then(
      (response) => {
        setLoading(false);
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        } else {
          setFetched_Businesses(response.data.data);
        }
      },
      (error) => {
        setLoading(false);
        Alerts.swalErrorAlert(t("Server Error, Please try again later."));
      }
    );
  }

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setVariation(prev => ({ ...prev, Image: reader.result }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageHover = (isHovering) => {
    setShowImagePopup(isHovering);
  };

  const toggle = () => {
    setModal(!modal);
  };

  const handleVariationChange = (field, value) => {
    setVariation(prev => ({ ...prev, [field]: value }));
  };

  const validateForm = () => {
    return variation.Name && variation.Image; // Removed validation for Status and business_id
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      Alerts.swalErrorAlert("Please fill all fields for the variation.");
      return;
    }

    const payloadData = {
      functionName: "UpdateCategory",
      postData: {
        categoryDetails: variation
      }
    };
    setLoading(true);
    CustomerService.UpdateCategory(authData.sessionID, payloadData).then(
      (response) => {
        setLoading(false);
        if (response.data.status) {
          onSuccess();
          Alerts.swalSuccessAlert(response.data.message || "Data Updated Successfully.").then(() => {
            toggle();
          });
        } else {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
          } else {
            Alerts.swalErrorAlert(response.data.message || "Error: Can't Update Category at the moment. Please try again later.");
          }
        }
      },
      (error) => {
        setLoading(false);
        Alerts.swalErrorAlert("Network Operation failed.");
      }
    );
  };

  return (
    <span className="d-inline-block mb-2">
      <i onClick={toggle} className="pe-7s-pen btn-icon-wrapper text-primary"> </i>
      <Modal isOpen={modal} toggle={toggle} backdrop={true}>
        <LoadingOverlay active={loading} spinner={<DNALoader />}>
          <ModalHeader>{t("Update Category")}</ModalHeader>
          <ModalBody>
            <Card className="main-card mb-3">
              <CardBody>
                <form onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <Col md="12">
                      <FormGroup>
                        <Label>{t("Category Name")}</Label>
                        <Input
                          type="text"
                          value={variation.Name}
                          onChange={(e) => handleVariationChange('Name', e.target.value)}
                          placeholder="Enter Category Name"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup>
                        <Label>{t("Status")}</Label>
                        <Input
                          type="select"
                          value={variation.Status}
                          onChange={(e) => handleVariationChange('Status', e.target.value)}
                        >
                          <option value="">{t("Select Status")}</option>
                          <option value="Active">{t("Active")}</option>
                          <option value="InActive">{t("InActive")}</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup>
                        <Label
                          onMouseEnter={() => handleImageHover(true)}
                          onMouseLeave={() => handleImageHover(false)}
                          style={{ position: 'relative', cursor: 'pointer' }}
                        >
                          {t("Image")}
                          {showImagePopup && variation.Image && (
                            <ImagePopup src={variation.Image} />
                          )}
                        </Label>
                        <Input
                          type="file"
                          onChange={handleImageChange}
                          accept="image/*"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <Label>{t("Selected Image")}</Label>
                      {variation.Image && (
                        <ImagePopup3 src={variation.Image} />
                      )}
                    </Col>
                  </Row>
                  <div className="text-center">
                    <Button type="submit" size="lg" className="btn btn-custom-color me-3">
                      {t("Update")}
                    </Button>
                    <Button type="button" outline size="lg" onClick={toggle}>
                      {t("Cancel")}
                    </Button>
                  </div>
                </form>
              </CardBody>
            </Card>
          </ModalBody>
        </LoadingOverlay>
      </Modal>
    </span>
  );
};

export default DocsUpdateCustomer;
