import React, { Fragment, useState, useEffect } from "react";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy, faPlus } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import Header from './Header';
import DashboardService from "../dashboard.service";
import AuthService from "../../Authentication/auth.service";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Alerts from "../../../components/Alerts/alerts";
import AddReward from './AddReward';
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import { Container, Button } from 'reactstrap';

const CustomerRewards = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [branch, setBranch] = useState('');
  const [rewardPoints, setRewardPoints] = useState(0);
  const [rewardsHistory, setRewardsHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showReward, setShowReward] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [logo, setLogo] = useState(localStorage.getItem("system_logo"));
  const [showBranchPopup, setShowBranchPopup] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const handleRewardClick = () => {
    setShowReward(true);
  };

  const handleCloseReward = () => {
    setShowReward(false);
  };

  const handleSuccessfulRedeem = () => {
    fetchRewards(); // Re-fetch the rewards data
  };

  useEffect(() => {
    fetchRewards();
  }, []);

  const fetchRewards = () => {
    const userString = localStorage.getItem('user');
    const user = JSON.parse(userString);
    const userId = user['_id'];
    setLoading(true);
    DashboardService.GetRewards(userId).then((response) => {
      setLoading(false);
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      } else {
        setRewardsHistory(response.data.RewardsHistoryData);
        setRewardPoints(response.data.totalRemainingPoints);
      }
    }).catch((error) => {
      setLoading(false);
      const errContent = (error.response && error.response.data) || error.message || error.toString();
      if (errContent) {
        Alerts.swalErrorAlert(errContent);
      } else {
        Alerts.swalErrorAlert(t("Server Error, Please try again later."));
      }
    });
  };

  const styles = {
    creditPage: {
      fontFamily: 'Arial, sans-serif',
      width: '100%',
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '20px',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '20px',
    },
    balanceSection: {
      background: 'linear-gradient(to bottom, #4a4a4a, #000000)',
      color: 'white',
      padding: '20px',
      borderRadius: '10px',
      textAlign: 'center',
    },
    walletIcon: {
      backgroundColor: '#f0e68c',
      width: '50px',
      height: '50px',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0 auto 10px',
    },
    transactionsSection: {
      backgroundColor: '#f8f9fa',
      borderRadius: '10px',
      padding: '20px',
      marginTop: '20px',
    },
    topUp: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '20px',
    },
    topUpButton: {
      backgroundColor: '#000',
      color: 'white',
      border: 'none',
      borderRadius: '50%',
      width: '30px',
      height: '30px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    },
    filterDate: {
      position: 'relative',
      marginBottom: '20px',
    },
    filterInput: {
      width: '100%',
      padding: '10px',
      paddingRight: '30px',
      border: '1px solid #ccc',
      borderRadius: '5px',
    },
    calendarIcon: {
      position: 'absolute',
      right: '10px',
      top: '50%',
      transform: 'translateY(-50%)',
    },
    transactionsList: {
      listStyleType: 'none',
      padding: 0,
    },
    transactionItem: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px 0',
      borderBottom: '1px solid #eee',
    },
    transactionInfo: {
      display: 'flex',
      flexDirection: 'column',
    },
    transactionType: {
      fontWeight: 'bold',
    },
    transactionDate: {
      color: '#666',
      fontSize: '0.9em',
    },
    transactionAmount: {
      fontWeight: 'bold',
    },
  };

  const FooterItem = ({ icon, text, to }) => {
    const isActive = location.pathname === to;

    return (
      <div
        style={{
          textAlign: 'center',
          color: isActive ? '#F2731C' : '#888',
          fontSize: 'clamp(12px, 2vw, 14px)',
          cursor: 'pointer'
        }}
        onClick={() => navigate(to)}
      >
        {icon}<br />{text}
      </div>
    );
  };

  const SidebarItem = ({ icon, text, color = 'black', onClick, to }) => {
    const isActive = location.pathname === to;

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          margin: '10px 0',
          color: isActive ? '#F2731C' : color,
          cursor: 'pointer'
        }}
        onClick={() => {
          if (onClick) {
            onClick();
          } else if (to) {
            navigate(to);
          }
        }}
      >
        <span style={{ marginRight: '15px', fontSize: '20px' }}>{icon}</span>
        <span style={{ fontSize: '16px' }}>{text}</span>
      </div>
    );
  };


  const toggleBranchPopup = () => {
    setShowBranchPopup(!showBranchPopup);
  };

  const SidebarTextItem = ({ text, to, color = 'black', onClick }) => {
    const isActive = location.pathname === to;
    return (
      <div
        onClick={() => {
          if (onClick) {
            onClick();
          } else if (to) {
            navigate(to);
          }
        }}
        style={{ margin: '10px 0' }}>
        <span style={{
          fontSize: '14px',
          color: isActive ? '#F2731C' : color,
        }}>{text}</span>
      </div>
    )
  };

  return (
    <>
      <Container fluid style={{ padding: 0, backgroundColor: "black" }}>
        <Container style={{ maxWidth: isMobile ? '100%' : '90%', padding: 0, backgroundColor: "black" }}>
          <div style={{ fontFamily: 'Arial, sans-serif', minHeight: "100vh", width: '100%', margin: '0 auto', backgroundColor: 'white', paddingBottom: '60px' }}>
            <header style={{
              position: 'fixed',
              width: isMobile ? '100%' : '90%',
              top: 0,
              marginLeft: isMobile ? '0%' : '5%',
              left: 0,
              zIndex: 1000
            }}>
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '10px',
                backgroundColor: 'white',
                margin: '0 auto',
                maxWidth: '100%',
              }}>
                <div style={{ border: '1px solid #F2731C', borderRadius: '5px', padding: '8px', fontSize: '24px' }} onClick={() => setSidebarOpen(true)}>≡</div>
                <img src={logo} alt="Logo" style={{ cursor: 'pointer', width: '120px', height: 'auto' }} />
                <div>
                </div>
              </div>
            </header>

            {sidebarOpen && (
              <div className="slide-in" style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '80%',
                maxWidth: '300px',
                height: '100%',
                backgroundColor: 'white',
                zIndex: 1001,
                boxShadow: '2px 0 5px rgba(0,0,0,0.1)',
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'auto'
              }}>
                <div style={{
                  backgroundColor: '#F2731C',
                  color: 'white',
                  padding: '20px',
                  position: 'relative'
                }}>
                  <h2 style={{ margin: 0, fontSize: '24px' }}>wasim minhas</h2>
                  <p style={{ margin: '5px 0 0 0', fontSize: '14px' }}>wasimminhas000@gmail.com</p>
                  <button onClick={() => setSidebarOpen(false)} style={{ position: 'absolute', top: '20px', right: '20px', background: 'none', border: 'none', fontSize: '24px', color: 'white' }}>
                    &gt;
                  </button>
                </div>
                <div style={{ padding: '20px' }}>
                  <SidebarItem
                    icon="🏠"
                    text="Home"
                    to="/Store"
                  />
                  <SidebarItem
                    icon="🏢"
                    text="Change Branch"
                    onClick={toggleBranchPopup}
                  />
                  <SidebarItem icon="📋" text="My Orders" to="/CustomerOrders" />
                  <SidebarItem icon="💳" text="Credits" to="/CustomerTopup" />
                  <SidebarItem icon="🛒" text="Cart" to="/CartView" />
                  <SidebarItem icon="🛒" text="Profile" to="/User_Profile" />
                  <div style={{ backgroundColor: '#F2731C', padding: '10px', margin: '10px 0', borderRadius: '5px' }}>
                    <SidebarItem icon="⭐" text="Create App Shortcut" color="white" />
                  </div>
                  <SidebarItem icon="🌐" text="Switch Language" />
                </div>
                <div style={{ padding: '0 20px' }}>
                  <SidebarTextItem text="Helps & FAQs" to="/term_of_use" />
                  <SidebarTextItem text="Terms of Use" to="/term_of_use" />
                  <SidebarTextItem text="Privacy Policy" to="/privacy_policy" />
                  <SidebarTextItem text="Return Policy" to="/return_policy" />
                  <SidebarTextItem text="Terms & Conditions" to="/terms_and_conditions" />
                  <SidebarTextItem text="Newsroom" to="/news_room" />
                  <SidebarTextItem text="NEW Page" to="/new_page" />
                </div>
                <div style={{ padding: '20px', marginTop: 'auto' }}>
                  <button style={{
                    backgroundColor: '#F2731C',
                    color: 'white',
                    border: 'none',
                    padding: '10px 20px',
                    borderRadius: '5px',
                    width: '100%',
                    fontSize: '16px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                    <span style={{ marginRight: '10px' }}>↩</span> Logout
                  </button>
                </div>
                <div style={{ padding: '10px 20px', borderTop: '1px solid #eee' }}>
                  <p style={{ margin: 0, color: '#888', fontSize: '14px' }}>Version: 2.1</p>
                </div>
              </div>
            )}

            <LoadingOverlay tag="div" active={loading}
              styles={{ overlay: (base) => ({ ...base }) }}
              spinner={<DNALoader />}>
              <div>
                <header style={styles.header}>
                  <h1>Reward Points</h1>
                </header>

                <div style={styles.balanceSection}>
                  <div style={styles.walletIcon}>
                    <FontAwesomeIcon icon={faTrophy} style={{ color: 'black' }} />
                  </div>
                  <p>Reward Points</p>
                  <h2>{rewardPoints}</h2>
                </div>

                <div style={styles.transactionsSection}>
                  <div style={styles.topUp}>
                    <span>Redeem Points</span>
                    <button style={styles.topUpButton} onClick={handleRewardClick}>
                      <FontAwesomeIcon icon={faPlus} />
                    </button>
                  </div>
                  <h3>History</h3>

                  <ul style={styles.transactionsList}>
                    {rewardsHistory.map((reward, index) => (
                      <li key={index} style={styles.transactionItem}>
                        <div style={styles.transactionInfo}>
                          <span style={styles.transactionType}>{reward.reason}</span>
                          <span style={styles.transactionDate}>
                            {moment(reward.date).format('DD MMM, YYYY - HH:mm A')}
                          </span>
                        </div>
                        <span
                          style={{
                            ...styles.transactionAmount,
                            color: reward.status === 'Points In' ? 'green' : 'red'
                          }}
                        >
                          {reward.status === 'Points In' ? `+${reward.points}` : `-${reward.points}`} ↑
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </LoadingOverlay>
            {showReward &&
              <AddReward
                onClose={handleCloseReward}
                availablePoints={rewardPoints}
                onSuccessfulRedeem={handleSuccessfulRedeem}
              />
            }

            <footer className="slide-up" style={{
              position: 'fixed',
              bottom: 0,
              left: isMobile ? '0%' : '5%',
              width: isMobile ? '100%' : '90%',
              display: 'flex',
              justifyContent: 'space-around',
              backgroundColor: 'white',
              padding: '10px',
              boxShadow: '0 -2px 5px rgba(0,0,0,0.1)'
            }}>
              <FooterItem icon="🎁" text="Rewards" to="/CustomerRewards" />
              <FooterItem icon="🛒" text="Cart" to="/CartView" />
              <FooterItem icon="🏠" text="Home" to="/Store" />
              <FooterItem icon="📋" text="Orders" to="/CustomerOrders" />
              <FooterItem icon="👤" text="Profile" to="/User_Profile" />
            </footer>
          </div>

        </Container>
      </Container>
      <style>
        {`
          @keyframes fadeIn {
            from { opacity: 0; }
            to { opacity: 1; }
          }
          body {
            background-color: black;
          }
          
          @keyframes slideIn {
            from { transform: translateX(-100%); }
            to { transform: translateX(0); }
          }
          
          @keyframes slideUp {
            from { transform: translateY(100%); }
            to { transform: translateY(0); }
          }
          
          .fade-in {
            animation: fadeIn 0.5s ease-out;
          }
          
          .slide-in {
            animation: slideIn 0.3s ease-out;
          }
          
          .slide-up {
            animation: slideUp 0.3s ease-out;
          }
          
          .category-button {
            transition: background-color 0.3s, color 0.3s;
          }
          
          .product-card {
            transition: transform 0.3s, box-shadow 0.3s;
          }
          
          .product-card:hover {
            transform: translateY(-5px);
            box-shadow: 0 4px 8px rgba(0,0,0,0.2);
          }
          
          @media (max-width: 768px) {
      .product-card {
        flex-direction: row;
        text-align: left;
      }
      
      .product-card img {
        margin-right: 15px;
        margin-bottom: 0;
      }
    }
    
    @media (max-width: 480px) {
      .category-button {
        padding: 8px 15px;
        margin-right: 5px;
        font-size: 12px;
      }
    }
    
    /* Hide scrollbar for Chrome, Safari and Opera */
    #scrollRef::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    #scrollRef {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }
        `}
      </style>
    </>
  );
};

const cardStyle = {
  width: '80%', // Reduce card width
  maxWidth: '500px', // Set a maximum width to prevent it from becoming too large on bigger screens
  backgroundColor: 'white',
  border: '1px #ccc solid',
  marginBottom: '20px',
  padding: '15px',
  cursor: 'pointer',
  margin: '0 auto', // Center align the card
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};



const cardBodyStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
};

const orderInfoStyle = {
  flexGrow: 1,
  marginRight: '20px',
};

const orderIdStyle = {
  fontSize: '16px',
  fontWeight: 'bold',
  color: '#003366',
  marginBottom: '5px',
};

const orderDetailStyle = {
  fontSize: '14px',
  color: '#777',
  margin: '5px 0',
};


export default CustomerRewards;