import React, { useEffect, useState, Fragment } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import DataTable from 'react-data-table-component';
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import AuthService from "../Authentication/auth.service";
import { useTranslation } from 'react-i18next';
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";
import Alerts from "../../components/Alerts/alerts";
import Services from "./services";
import AddRanks from "./AddRanks";
import UpdateRanks from "./UpdateRanks";
import RanksFilter from "../../components/Table Filters/RanksFilter.js";
import styled from 'styled-components';
import PermissionAccess from '../../PermissionAccess/Access.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faEye } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@mui/material/Tooltip';
  
const RANK_NAMES = {
  infin8_starter: "Infin8 Starter Rank",
  infin8_explorer: "Infin8 Explorer Rank",
  infin8_pioneer: "Infin8 Pioneer Rank",
  infin8_leader: "Infin8 Leader Rank",
  infin8_visionary: "Infin8 Visionary Rank"
};

const RanksListing = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [Customers, setCustomers] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const { authData, accessPages, super_admin, current_module } = PermissionAccess();
  const [RanksToEdit, setRanksToEdit] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchCustomers(currentPage, pageLimit, filterText, filterStatus);
  }, [pageLimit, i18n]);

  function fetchCustomers(page_index, page_limit, filterText, filterStatus) {
    var offset = (page_index - 1) * page_limit;
    if (offset < 1) {
      offset = 0;
    }
    const stored_user = authData.userData;
    let business = []
    if (stored_user.businesses) {
      business = stored_user.businesses
    }

    var getData = {
      functionName: "GetRanks",
      "postData": {
        "offset": offset,
        "filterStatus": filterStatus,
        "filterText": filterText,
        "sort": {
          "_id": -1
        },
        "limit": page_limit,
        business_id: business
      }
    }
    setLoading(true);
    Services.GetRanks(authData.sessionID, getData).then((response) => {
      setLoading(false);
      console.log("Get Ranks response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setCustomers(response.data.data);
        console.log("Responsse", response.data);
        setTotalRows(response.data.count.count);
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };
  const handleSuccess = () => {
    fetchCustomers(currentPage, pageLimit, filterText, filterStatus);
  };

  const confirmDelete = (id) => {
    Alerts.swalConfirmDeleteAlert(t("Once deleted, you will not be able to recover this Record!"))
      .then(res => {
        if (res) {
          deleteRanks(id);
        }
      });
  };

  const deleteRanks = (id) => {
    setLoading(true);
    Services.DeleteRanks(authData.sessionID, id)
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          Alerts.swalSuccessAlert(response.data.message || t("Data Deleted Successfully."));
          fetchCustomers(currentPage, pageLimit, filterText, filterStatus);
        } else {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
          } else {
            Alerts.swalErrorAlert(response.data.message || t("Error: Can't delete at the moment."));
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        Alerts.swalErrorAlert(t("Delete operation failed"));
      });
  };

  const handleDeleteSelected = () => {
    if (selectedRows?.length > 0) {
      Alerts.swalConfirmDeleteAlert(t(selectedRows?.length + " records selected. Once deleted, you will not be able to recover this!"))
        .then(res => {
          if (res) {
            deleteBulkCustomers(selectedRows);
          }
        });
    } else {
      Alerts.swalErrorAlert('No records selected');
    }
  };

  const deleteBulkCustomers = (ids) => {
    setLoading(true);
    Services.DeleteBulkRanks(authData.sessionID, ids)
      .then((response) => {
        setLoading(false);
        setSelectedRows([]);
        if (response.data.status) {
          Alerts.swalSuccessAlert(response.data.message || t("Data Deleted Successfully."));
          fetchCustomers(currentPage, pageLimit, filterText, filterStatus);
        } else {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
          } else {
            Alerts.swalErrorAlert(response.data.message || t("Error: Can't delete the Collection Data at the moment."));
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        Alerts.swalErrorAlert(t("Delete operation failed"));
      });
  };
  const handleSelectAllChange = (e) => {
    if (e.target.checked) {
      setSelectedRows(Customers.map(Ranks => Ranks._id));
    } else {
      setSelectedRows([]);
    }
  };

    const columns = [
      {
        name: t("Rank Category"),
        selector: row => RANK_NAMES[row.name] || row.name,
        sortable: true,
        width: '200px'
      },
      {
        name: t("Required Members"),
        cell: row => (
          <div>
            <div>Left: {row.left_org_members?.toLocaleString()}</div>
            <div>Right: {row.right_org_members?.toLocaleString()}</div>
          </div>
        ),
        width: '150px'
      },
      {
        name: t("Max Members"),
        selector: row => row.max_members?.toLocaleString(),
        sortable: true,
        width: '150px'
      },
      {
        name: t("SPV Reward"),
        cell: row => (
          <div>
            <div>Per Member: {row.spv_reward_per_member}</div>
            <div className="text-wrap" style={{ fontSize: '0.85em' }}>
              {row.infin8_spv_reward}
            </div>
          </div>
        ),
        width: '200px'
      },
      {
        name: t("One Time Reward (DPV)"),
        selector: row => row.one_time_reward,
        sortable: true,
        width: '150px'
      },
      {
        name: t("Maintenance"),
        cell: row => (
          <div className="text-wrap" style={{ whiteSpace: 'normal', fontSize: '0.85em' }}>
            {row.maintenance}
          </div>
        ),
        width: '200px'
      },
      {
        name: t('Status'),
        width: '120px',
        cell: (row) => {
          return (
            <div>
              {row.status === "Inactive" ? (
                <button type="button" className="btn btn-danger btn-sm btn-hover-shine">
                  Inactive
                </button>
              ) : row.status === "Active" ? (
                <button type="button" className="btn btn-success btn-sm btn-hover-shine">
                  Active
                </button>
              ) : (
                <button type="button" className="btn btn-secondary btn-sm btn-hover-shine">
                  {row.status}
                </button>
              )}
            </div>
          );
        }
      },
      {
        name: t("Actions"),
        width: '150px',
        cell: (row) => {
          return (
            <div>
            <button className="mt-2 mb-2 me-2 btn-icon btn">
              {(super_admin || current_module?.ViewData === 1) && (
                <Tooltip title={t("View Details")}>
                  <Link to="/Ranks-details" state={row}>
                    <i className="pe-7s-note2 btn-icon-wrapper text-info"> </i>
                  </Link>
                </Tooltip>
              )}
              {(super_admin || current_module?.EditData === 1) && (
                <Tooltip title={t("Edit")}>
                  <i onClick={() => setRanksToEdit(row)} className="pe-7s-pen btn-icon-wrapper text-primary"> </i>
                </Tooltip>
              )}
              {(super_admin || current_module?.DeleteData === 1) && (
                <Tooltip title={t("Delete")}>
                  <i onClick={() => confirmDelete(row._id)} className="pe-7s-trash text-danger btn-icon-wrapper"> </i>
                </Tooltip>
              )}
            </button>
          </div>
            // <div className="d-flex">
            //   {(super_admin || current_module?.ViewData === 1) && (
            //     <Tooltip title={t("View Details")}>
            //       <Button
            //         color="info"
            //         className="btn-icon btn-sm me-2"
            //         onClick={() => navigate("/Ranks-details", { state: row })}
            //       >
            //         <FontAwesomeIcon icon={faEye} />
            //       </Button>
            //     </Tooltip>
            //   )}
            //   {(super_admin || current_module?.EditData === 1) && (
            //     <Tooltip title={t("Edit")}>
            //       <Button
            //         color="primary"
            //         className="btn-icon btn-sm me-2"
            //         onClick={() => setRanksToEdit(row)}
            //       >
            //         <FontAwesomeIcon icon={faEdit} />
            //       </Button>
            //     </Tooltip>
            //   )}
            //   {(super_admin || current_module?.DeleteData === 1) && (
            //     <Tooltip title={t("Delete")}>
            //       <Button
            //         color="danger"
            //         className="btn-icon btn-sm"
            //         onClick={() => confirmDelete(row._id)}
            //       >
            //         <FontAwesomeIcon icon={faTrash} />
            //       </Button>
            //     </Tooltip>
            //   )}
            // </div>
          );
        }
      }
    ];

  const handleCheckboxChange = (e, row) => {
    if (e.target.checked) {
      setSelectedRows([...selectedRows, row._id]);
    } else {
      setSelectedRows(selectedRows.filter(id => id !== row._id));
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchCustomers(page, pageLimit, filterText, filterStatus);
  };

  const handlePerRowsChange = async (newLimit, page) => {
    setPageLimit(newLimit);
  };

  const handleSearch = (searchText, status) => {
    setFilterText(searchText);
    setFilterStatus(status);
    fetchCustomers(0, pageLimit, searchText, status);
  };

  const handleClear = () => {
    setFilterText('');
    setFilterStatus('');
    fetchCustomers(0, pageLimit, '', '');
  };

  const subHeaderComponentMemo = React.useMemo(() => (
    <RanksFilter
      onSearch={handleSearch}
      onClear={handleClear}
      filterText={filterText}
      filterStatus={filterStatus}
    />
  ), [filterText, filterStatus]);

  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className="bg-light-gray bg-animation">
            <div className="d-flex justify-content-center align-items-center">
              <Col md="12" className="mx-auto app-login-box">

                <div className="app-page-title mb-3">
                  <div className="page-title-wrapper">
                    <div className="page-title-heading fw-bold">
                      <div className="page-title-icon">
                        <i className="pe-7s-note2 icon-gradient bg-tempting-azure" />
                      </div>
                      <div>
                        {t("Infini8 Ranks Management")}
                        <div className="page-title-subheading">
                          {t("View Basic and detailed information of ")} {t("Infini8 Ranks Management")}
                        </div>
                      </div>
                    </div>
                    <div className="page-title-actions">
                      <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-success btn-hover-shine me-3">
                        {t("Back")}
                      </button>
                    </div>
                  </div>
                </div>

                <Card className="main-card mb-3">
                  <CardBody>
                    <LoadingOverlay active={loading} spinner={<DNALoader />}>
                      <Row className="mb-3">
                        <Col md="6">
                          {subHeaderComponentMemo}
                        </Col>
                        <Col md="6" className="text-end">
                          {(super_admin || current_module?.BulkDeleteData === 1) && (
                            <Button color="danger" onClick={handleDeleteSelected} className="me-2">
                              {t('Delete Selected')}
                            </Button>
                          )}
                          {(super_admin || current_module?.AddData === 1) && (
                            <AddRanks onSuccess={handleSuccess} />
                          )}
                        </Col>
                      </Row>
                      <DataTable
                        columns={columns}
                        data={Customers}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handlePerRowsChange}
                        selectableRows
                        onSelectedRowsChange={({ selectedRows }) => setSelectedRows(selectedRows.map(row => row._id))}
                        dense
                        striped
                        responsive
                      />
                    </LoadingOverlay>
                  </CardBody>
                </Card>

              </Col>
            </div>
          </div>
        </div>

      </div>
      {RanksToEdit && (
        <UpdateRanks
          RANKS={RanksToEdit}
          onSuccess={() => {
            setRanksToEdit(null);
            handleSuccess();
          }}
          onCancel={() => setRanksToEdit(null)}
        />
      )}
    </Fragment>
  );
};

export default RanksListing;