import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Modal, ModalHeader, ModalBody, Input, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FaFileAlt, FaFileInvoiceDollar, FaFileUpload, FaCheckCircle, FaTimesCircle, FaBan } from 'react-icons/fa';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import DashboardService from "./dashboard.service";
import AuthService from "../Authentication/auth.service";
import Alerts from "../../components/Alerts/alerts";
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import PermissionAccess from '../../PermissionAccess/Access.js';
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";
import AlertService from '../../components/Alerts/alerts';
import PDFViewer from 'react-pdf-js';
import { CSVLink } from "react-csv";
import { Tooltip } from "@mui/material";

const customStyles = {
  headRow: {
    backgroundColor: '#FFFFFF',
    color: '#A7A7A7 !important',
    border: 'none',
  },
  headCells: {
    style: {
      fontSize: '16px',
      fontWeight: 'bold', // Customize font weight
      border: 'none', // Remove bottom border of each header cell
    },
  },
  rows: {
    style: {
      border: 'none',
    },
  },
  cells: {
    style: {
      padding: '8px',
      border: 'none',

    },
  },
};
const Home = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [Business, setBusiness] = useState('');
  const [AllBusinesses, setAllBusinesses] = useState([]);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [currentPage, setCurrentPage] = useState("");
  const [daysDropdownValue, setDaysDropdownValue] = useState('Last 7 Days');

  const [modalContent, setModalContent] = useState({ show: false, file: '' });

  const [pageLimit, setPageLimit] = useState(10);
  const [Data, setData] = useState([]);
  const { authData, accessPages, super_admin, current_module } = PermissionAccess();

  // Perform actions on component mount or when 'pageLimit' changes
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchData(0, pageLimit);
  }, [pageLimit, Business]);

  // Perform actions on component mount or when 'pageLimit' changes
  useEffect(() => {
    fetchBusinesses()
  }, []);


  // Function to fetch table data based on page index and limit
  async function fetchData(page_index, page_limit) {
    const stored_user = authData.userData
    let business = []
    if (Business !== '') {
      business.push(Business)
    } else if (stored_user.businesses) {
      business = stored_user.businesses
    }
    var offset = (page_index - 1) * page_limit;
    if (offset < 1) {
      offset = 0;
    }

    var payload = {
      functionName: 'fetchAll_E_invoice_Requested',
      postData: {
        filter: { status: 'pending' },
        limit: page_limit,
        offset: offset,
        business: business
      }
    }
    // Set loading state to true before fetching data
    setLoading(true);

    // Fetch data from DashboardService
    DashboardService.fetchAll_E_invoice_Requested(payload, authData.sessionID).then((response) => {
      setLoading(false); // Set loading state to false after data retrieval
      // Handle response data
      if (response.data.message === "Unauthorized") {
        // Perform logout and redirection for unauthorized access
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setData(response.data.data);
        if (response.data.data) {
          setTotalRows(response.data.count.count);
        }
      }
    },
      // Handle error scenarios
      (error) => {
        setLoading(false); // Set loading state to false in case of error
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  function fetchBusinesses() {
    const stored_user = authData.userData
    let business = []
    if (stored_user.businesses) {
      business = stored_user.businesses
    }

    var getData = {
      functionName: "GetBusinesses",
      postData: {
        sort: { _id: -1 },
        offset: 0,
        limit: 1000,
        business_ids: business,
      },
    };
    DashboardService.GetBusinesses(getData, authData.sessionID).then((response) => {
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        if (response.data) {
          if (response.data.data.length === 1) {
            setBusiness(response.data.data[0]._id)
          }
          setAllBusinesses(response.data.data);
        }
      }
    },
      (error) => {
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  function FetchNewInvoices() {
    if (Business) {
      setLoading(true);
      DashboardService.get_all_invoices(Business).then((response) => {
        console.log("Response get_all_invoices: ", response);
        if (response.data.message === "Unauthorized") {
          setLoading(false);
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        } else if (response.data.message === "Failed to retrieve invoices") {
          Alerts.swalClickProceedAlert('Your Token Expired, Refresh your Token').then(res => {
            if (res === true) {
              DashboardService.access_token(Business).then((response) => {
                // Check if the response contains a valid URL
                console.log("Response get_all_invoices: ", response);
                if (response.data && typeof response.data === 'string') {
                  // Open the URL in a new tab
                  window.open(response.data, '_blank');
                }
                FetchNewInvoices();
              })
            }
          });
        } else if (response.data.message === "No new invoices to insert") {
          console.log("response.data.message: ", response.data.message);
          AlertService.swalSuccessAlert("No new invoices to insert");
        } else {
          setLoading(false);
          fetchData(0, pageLimit)
        }
        setLoading(false);
      },
        (error) => {
          const errContent =
            (error.response && error.response.data) || error.message || error.toString();
          if (errContent) {
            Alerts.swalErrorAlert(errContent)
          }
          else {
            Alerts.swalErrorAlert(t("Server Error, Please try again later."))
          }
        })
    } else {
      AlertService.swalErrorAlert("Select a business to fetch new invoices");
    }
  };

  const handleRowClick = (row) => {
    navigate(`/e-invoice-details-2/${row._id}`, { state: row });
  };


  const columns = [
    {
      name: 'Busisness',
      selector: row => row?.business_details?.[0].name,
      sortable: true,
    },
    {
      name: 'Date',
      selector: row => moment(row.date).format('DD-MM-YYYY'),
      sortable: true,
    },
    {
      name: 'File',
      cell: row => row.file ? (
        <ReceiptPreview file={row.file} />
      ) : 'No receipt',
    },

    { name: 'Buyer', selector: row => row?.user_details?.[0]?.name, sortable: true },
    {
      name: 'Status',
      selector: row => row?.status,
      cell: row => <span className={`status-${row?.status?.toLowerCase()}`}
        style={{ color: row?.status === 'pending' ? 'red' : '#F16262' }}>
        {row?.status === 'pending' ? 'Pending' : row?.status === 'valid' ? "Valid" : row?.status}
      </span>
      ,
      sortable: true
    },
    {
      name: 'Actions',
      selector: (row) => {
        return (
          <div>
            <button className="mt-2 mb-2 me-2 btn-icon btn">
              {/* {!super_admin ? current_module?.SendEmail === 1 && */}
              {row.status === 'pending' &&
                <Tooltip placement="top" title="Create invoice" arrow>
                  <i
                    onClick={() => handleCreateInvoice(row)}
                    className="pe-7s-pen text-primary btn-icon-wrapper"></i>
                </Tooltip>
                //  :
                // <Tooltip placement="top" title="Create invoice" arrow>
                //   <i
                //     // onClick={() => handleMail(row)} 
                //     className="pe-7s-pen text-primary btn-icon-wrapper"></i>
                // </Tooltip> 

              }
            </button>
          </div>
        );
      }
    },
  ];

  const handleCreateInvoice = (row) => {
    navigate('/add-requested-invoice', {
      state: row
    });
  };


  const ReceiptPreview = ({ file }) => {
    const getFileType = (fileName) => {
      const extension = fileName.split('.').pop().toLowerCase();
      if (['jpg', 'jpeg', 'png'].includes(extension)) return 'image';
      if (extension === 'pdf') return 'pdf';
      if (extension === 'csv') return 'csv';
      return 'unknown';
    };

    const fileType = getFileType(file);

    const handleView = () => {
      setModalContent({ show: true, file: `${process.env.REACT_APP_IMAGEURL}${file}` });
    };

    return (
      <>
        <Button color="link" onClick={handleView} className="preview-button">
          View
        </Button>

        <Modal
          isOpen={modalContent.show}
          toggle={() => setModalContent({ ...modalContent, show: false })}
          size="lg"
          className="receipt-modal"
        >
          <ModalHeader toggle={() => setModalContent({ ...modalContent, show: false })}>
            Receipt Preview
          </ModalHeader>
          <ModalBody className="receipt-preview-body">
            {fileType === 'image' ? (
              <img src={modalContent.file} alt="Receipt" className="receipt-image" />
            ) : fileType === 'pdf' ? (
              <PDFViewer file={modalContent.file} className="pdf-viewer" />
            ) : fileType === 'csv' ? (
              <div className="csv-container">
                <CSVLink data={[]} filename={file} className="csv-download-link">
                  Download CSV
                </CSVLink>
              </div>
            ) : (
              'No preview available'
            )}
          </ModalBody>
        </Modal>
      </>
    );
  };


  //for search with enter keypress
  function checkenterkey(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      setResetPaginationToggle(!resetPaginationToggle);
      var page = 0
      handlePageChange(page++)
    }
  };
  function handlePageChange(page_index) {
    console.log("Page index:-", page_index)
    setCurrentPage(page_index)
    fetchData(page_index, pageLimit);
  }

  async function handlePerRowsChange(newPageLimit, page_index) {
    setPageLimit(newPageLimit);
  }



  return (
    <div className="app-main__outer">
      <div >
        <LoadingOverlay tag="div" active={loading}
          styles={{ overlay: (base) => ({ ...base }) }}
          spinner={<DNALoader />}>

          <Card body>
            <div className="container mt-4">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h1 style={{ fontWeight: '900', fontSize: '26px' }}>Requests E-Invoices</h1>
                <div className="d-flex justify-content-end">
                  <Input
                    type="select"
                    style={{
                      borderColor: '#ccc',
                      backgroundColor: '#1E5EAA',
                      borderRadius: '4px',
                      fontSize: '16px',
                      padding: '10px 20px',
                      color: '#fff',
                      backgroundPosition: 'right 10px center',
                      backgroundRepeat: 'no-repeat',
                    }}
                    placeholder="Search Business"
                    value={Business}
                    onChange={(e) => {
                      setPageLimit(10)
                      setBusiness(e.target.value);
                    }}
                    onKeyDown={(event) => checkenterkey(event)}
                    id="colfilter"
                  >
                    <option disabled={false} value="">
                      Select Business
                    </option>
                    {AllBusinesses &&
                      AllBusinesses.map((Businesses, index) => (
                        <option key={index} value={Businesses._id}>
                          {Businesses.name}
                        </option>
                      ))}
                  </Input>
                </div>
              </div>

              <div className="mt-5">
                <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                  <DataTable
                    columns={columns}
                    data={Data}
                    pagination
                    customStyles={customStyles}
                    paginationPerPage={10}
                    paginationRowsPerPageOptions={[5, 10, 15, 20]}
                    // onRowClicked={handleRowClick}
                    pointerOnHover
                    fixedHeader
                    fixedHeaderScrollHeight="560px"
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handlePerRowsChange}
                    paginationResetDefaultPage={resetPaginationToggle}

                  />
                </div>
              </div>
              <style jsx>{`
            .trend-card {
              padding: 25px;
              border: none;
              border-radius:10px;
              box-shadow: 0 2px 10px rgba(0,0,0,0.05);
              background-color: #ffffff;
            }
            .icon-circle {
              width: 50px;
              height: 50px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 15px;
              font-size: 20px;
              flex-shrink: 0;
            }
            .trend-content {
              flex-grow: 1;
            }
            .trend-title {
              font-size: 14px;
              color: #777;
              margin-bottom: 2px;
            }
            .trend-value {
              font-size: 22px;
              font-weight: 600;
              color: #333;
            }
            .bg-light-blue { background-color: #e8f4ff; color: #4a90e2; }
            .bg-light-yellow { background-color: #fff8e1; color: #ffc107; }
            .bg-light-pink { background-color: #ffe8f0; color: #ff4081; }
            .bg-light-green { background-color: #e8f5e9; color: #4caf50; }
            .bg-light-red { background-color: #ffebee; color: #f44336; }
          `}</style>
            </div>
          </Card>
        </LoadingOverlay>
      </div>
    </div>
  );
};

export default Home;