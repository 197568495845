import React from "react";
import { Modal, ModalBody, Button } from "reactstrap";
import { Divider } from "@mui/material";
import styled from "styled-components";

const StyledModal = styled(Modal)`
.modal-dialog {
  max-width: 200px !important;
  width: 90%;
  margin: 10% auto;
}

.modal-content {
  border-radius: 10px;
  padding: 20px;
  border: 1px solid #e5e5e5;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
`;

const ModalHeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  color: #333;
  padding-bottom: 10px;
  margin-bottom: 15px;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 30px;
  color: #333;
  cursor: pointer;

  &:hover {
    color: #000;
  }
`;

const SectionTitle = styled.p`
  font-weight: bold;
  font-size: 14px;
  color: #666;
  margin: 0;
`;

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  margin-top: 10px;

  .info-label {
    font-weight: bold;
    color: #333;
  }

  .info-value {
    color: #C1B314;
    cursor: pointer;
    text-decoration: underline;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  .image-wrapper {
    text-align: center;

    img {
      width: 150px;
      height: 150px;
      object-fit: cover;
      border: 1px solid #ddd;
      border-radius: 5px;
    }

    p {
      margin-top: 5px;
      font-size: 12px;
      color: #666;
      font-weight: bold;
    }
  }
`;

const FooterButtons = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 20px;

  button {
    width: 130px;
    height: 40px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 5px;
  }

  .approve-btn {
    background-color: #85c744;
    color: #fff;
    border: none;

    &:hover {
      background-color: #6eb233;
    }
  }

  .reject-btn {
    background-color: #f95f62;
    color: #fff;
    border: none;

    &:hover {
      background-color: #e24b4e;
    }
  }
`;

const MemberApprovalPopup = ({ member, onClose, onApprove, onReject }) => {
  if (!member) return null;

  return (
    <StyledModal isOpen={!!member} toggle={onClose}>
      <ModalBody>
        {/* Header */}
        <ModalHeaderStyled>
          <span>Member’s Details</span>
          <CloseButton onClick={onClose}>×</CloseButton>
        </ModalHeaderStyled>

        {/* Description */}
        <SectionTitle>
          Review Member Details and Verify Documents for Sponsorship Approval
        </SectionTitle>

        {/* Info Rows */}

        <InfoRow>
          <div className="info-label">Member Name:</div>
          <div className="info-label">Email:</div>
        </InfoRow>
        <InfoRow>
          <div className="info-label">{member.fullName || "Member"}</div>
          <div className="info-label" >  {member.email || "Member"}</div>
        </InfoRow>
        <InfoRow>
          <div className="info-label">Phone Number:</div>
          <div className="info-label">Member Type:</div>
        </InfoRow>
        <InfoRow>
          <div className="info-label">{member.mobileNumber || "N/A"}</div>
          <div className="info-label" >  {member.user_type || "Member"}</div>
        </InfoRow>
        <InfoRow>
          <div className="info-label">Package Name:</div>
          {member.package_name ? (
            <div className="info-label">Order Details:</div>
          ) : (
            <div className="info-label">{member.package_name || "None"}</div>
          )}
        </InfoRow>
        {member.package_name && (
          <InfoRow>
            <div className="info-label">{member.package_name || "None"}</div>
            <div className="info-value" onClick={() => alert("Order Details Clicked")}>
              View Details
            </div>
          </InfoRow>
        )}
        <Divider sx={{ marginTop: '30px' }} />
        < ImageContainer >
          {member.frontId &&
            <div style={{ marginLeft: '10px' }} className="image-wrapper">
              <img src={member.frontId} alt="ID Front" />
              <p>ID-Card (Front)</p>
            </div>
          }
          {member.backId &&
            <div style={{ marginLeft: '10px', marginRight: '10px' }} className="image-wrapper">
              <img src={member.backId} alt="ID Back" />
              <p>ID-Card (Back)</p>
            </div>
          }
          {member.selfie &&
            <div className="image-wrapper">
              <img src={member.selfie} alt="Selfie" />
              <p>Selfie</p>
            </div>
          }
        </ImageContainer>
        <Divider sx={{ marginTop: '30px' }} />
        <ImageContainer>
          {member.payment_slip &&
            <div className="image-wrapper">
              <iframe src={member.payment_slip} alt="Payment Slip" />
              <p>Payment Slip</p>
            </div>
          }
        </ImageContainer>
        {member.status === 'Inactive' &&
          <FooterButtons>
            <Button className="approve-btn" onClick={onApprove}>
              Approve
            </Button>
            <Button className="reject-btn" onClick={onReject}>
              Reject
            </Button>
          </FooterButtons>
        }
      </ModalBody>
    </StyledModal >
  );
};

export default MemberApprovalPopup;
