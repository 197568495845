import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Button, Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, CardBody } from 'reactstrap';
import { FaWallet, FaGlobe, FaFile, FaShoppingCart, FaPlus } from 'react-icons/fa';
import DataTable from 'react-data-table-component';
import { NavLink, useNavigate } from 'react-router-dom';
import DashboardService from "../dashboard.service";
import AuthService from "../../Authentication/auth.service";
import Alerts from "../../../components/Alerts/alerts";
import { useTranslation } from 'react-i18next';
import QuotationsRequested from './QuotationsRequested';
import DashboardHeader from './DashboardHeader';
import { FaEye, FaEdit, FaFilter } from 'react-icons/fa';
import moment from 'moment';
import { Tooltip } from "@mui/material";


const customStyles = {
  headRow: {
    backgroundColor: '#FFFFFF',
    color: '#A7A7A7 !important',
    border: 'none',
  },
  headCells: {
    style: {
      fontSize: '16px',
      fontWeight: 'bold', // Customize font weight
      border: 'none', // Remove bottom border of each header cell
    },
  },
  rows: {
    style: {
      border: 'none',
    },
  },
  cells: {
    style: {
      padding: '8px',
      border: 'none',

    },
  },
};
const cardStyle = {
  borderRadius: '10px',
  boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
};

const headerStyle = {
  fontSize: '0.9rem',
  color: '#6c757d',
  marginBottom: '0.25rem',
};

const valueStyle = {
  fontSize: '1.2rem',
  fontWeight: 'bold',
  marginBottom: '0',
};

const listItemStyle = {
  fontSize: '0.9rem',
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '0.5rem',
};


const Home = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [ActiveOrders, setActiveOrders] = useState(0);
  const [ApprovedOrders, setApprovedOrders] = useState(0);
  const [TotalOrders, setTotalOrders] = useState(0);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [daysDropdownValue, setDaysDropdownValue] = useState('Last 7 Days');
  const [pageLimit, setPageLimit] = useState(10);
  const authData = JSON.parse(localStorage.getItem("user"));
  const [currentPage, setCurrentPage] = useState(0);

  const [data, setData] = useState();

  const handleAddNewRFQ = (row) => {
    navigate('/add_new_rfq', { state: { orderDetails: row } });
  };


  // Perform actions on component mount or when 'pageLimit' changes
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchData(0, pageLimit);
  }, [pageLimit, daysDropdownValue]);

  // Function to fetch table data based on page index and limit
  function fetchData(page_index, page_limit) {
    var offset = (page_index - 1) * page_limit;
    if (offset < 1) {
      offset = 0;
    }

    // Set loading state to true before fetching data
    setLoading(true);
    var payload = {
      "functionName": "GetUserOrders",
      "postData": {
        "offset": offset,
        "sort": {
          "_id": -1
        },
        "user_id": authData._id,
        "limit": page_limit,
      }
    }

    // Fetch data from DashboardService
    DashboardService.GetUserOrders(payload).then((response) => {

      setLoading(false); // Set loading state to false after data retrieval
      // Handle response data  
      if (response.data.message === "Unauthorized") {
        // Perform logout and redirection for unauthorized access
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        console.log("response response:", response)
        setData(response.data.data);
        if (response.data.data) {
          setActiveOrders(response.data.ActiveOrders.count);
          setApprovedOrders(response.data.ApprovedOrders.count);
          setTotalOrders(response.data.TotalOrders.count);
          setTotalRows(response.data.count.count);
        }
      }
    },
      // Handle error scenarios
      (error) => {
        setLoading(false); // Set loading state to false in case of error
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  const trendData = [
    { icon: <FaWallet />, title: 'Active', value: ActiveOrders, color: '#3D607A' },
    { icon: <FaGlobe />, title: 'Approved', value: ApprovedOrders, color: '#3D607A' },
    { icon: <FaFile />, title: 'Total Orders', value: TotalOrders, color: '#3D607A' },
  ];

  const handleRowClick = (row) => {
    navigate(`/e-invoice-details/${row._id}`, { state: row });
  };


  //for search with enter keypress
  function checkenterkey(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      setResetPaginationToggle(!resetPaginationToggle);
      var page = 0
      handlePageChange(page++)
    }
  };
  function handlePageChange(page_index) {
    console.log("Page index:-", page_index)
    setCurrentPage(page_index)
    fetchData(page_index, pageLimit);
  }

  // async function handlePerRowsChange(newPageLimit, page_index) {
  //   setPageLimit(newPageLimit);
  // }

  const columns = [
    {
      name: t('Name'),
      selector: row => {
        const name = row?.user_details?.name || t('N/A');
        return `${name}`;
      },
      sortable: true,
    },
    {
      name: t('Order Date'),
      selector: "order_date",
      sortable: true,
      cell: row => moment(row?.datetime_added).format('DD-MM-YYYY')
    },
    {
      name: t('Total Items'),
      selector: row => {
        return `${row?.items?.length}`;
      },
      sortable: true,
    },
    {
      name: t('Amount'),
      selector: row => {
        return `${row?.total_amount}`;
      },
      sortable: true,
    },

    {
      name: t('Order Status'),
      selector: (row) => {
        const getButtonStyle = (status) => {
          switch (status) {
            case 'confirmed':
              return {
                borderColor: '#ffc107',
                backgroundColor: '#ffc107',
                color: '#000'
              };
            case 'processing':
              return {
                borderColor: '#007bff',
                backgroundColor: '#007bff',
                color: '#fff'
              };
            case 'in_transit':
            case 'picked_up':
              return {
                borderColor: '#6f42c1',
                backgroundColor: '#6f42c1',
                color: '#fff'
              };
            case 'cancelled':
              return {
                borderColor: '#dc3545',
                backgroundColor: '#dc3545',
                color: '#fff'
              };
            default:
              return {
                borderColor: '#28a745',
                backgroundColor: '#28a745',
                color: '#fff'
              };
          }
        };

        const formatStatus = (status) => {
          if (status.includes('*')) {
            // Remove asterisk and replace underscores with spaces
            return status.replace('*', '').replace(/_/g, ' ').toUpperCase();
          } else if (status === 'in_transit') {
            return 'IN TRANSIT';
          } else if (status === 'picked_up') {
            return 'PICKED UP';
          } else {
            return status.toUpperCase();
          }
        };

        const buttonStyle = getButtonStyle(row?.status);

        return (
          <div>
            <button
              type="button"
              style={buttonStyle}
              className={`btn btn-sm btn-hover-shine me-3 ${row?.status === 'confirmed' ? 'btn-success' : 'btn-danger'}`}
            >
              {formatStatus(row?.status)}
            </button>
          </div>
        );
      },
      sortable: true,
    },

    {
      name: t('Actions'),
      selector: (row) => {
        return (
          <div>
            <NavLink to={`/user-order-details/${row?._id}`}>
              <Tooltip placement="top" title={`${t("View")} ${t("Details")}`} arrow style={{ cursor: "pointer" }}>
                <i className="pe-7s-look text-primary btn-icon-wrapper text-primary fs-5 p-1"> </i>
              </Tooltip>
            </NavLink>
            <Tooltip placement="top" title={`${t("Add New RFQ")}`} arrow style={{ cursor: "pointer" }}>
              <i className="pe-7s-plus text-success btn-icon-wrapper text-success fs-5 p-1" onClick={() => handleAddNewRFQ(row)}> </i>
            </Tooltip>
          </div>
        );
      }
    }
  ];

  // const handlePageChange = page => {
  //   setCurrentPage(page - 1);  
  // };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPageLimit(newPerPage);
    setCurrentPage(page - 1);
  };


  return (
    <div className="app-main__outer">
      <div className="container mt-4">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <DashboardHeader data='Orders' />
          <div className="d-flex">
            <Input type="text" placeholder="Search for something" className="mr-2" />
          </div>
        </div>

        <Row className="g-4 mb-4">
          {trendData.map((trend, index) => (
            <Col md={4} key={index}>
              <Card body className="trend-card d-flex flex-row align-items-center justify-content-between">
                <div>
                  <div className="trend-title">{trend.title}</div>
                  <div className="trend-value">{trend.value}</div>
                </div>
                <div className="icon-circle" style={{ backgroundColor: trend.color }}>
                  {trend.icon}
                </div>
              </Card>
            </Col>
          ))}
        </Row>

        <Row className="g-4">
          <QuotationsRequested data={data} columns={columns} sort={false} add={false} totalRows={totalRows} handlePerRowsChange={handlePerRowsChange} handlePageChange={handlePageChange} header="Orders" />
        </Row>

        <style jsx>{`
        .trend-card {
          border-radius: 10px;
          box-shadow: 0 2px 10px rgba(0,0,0,0.05);
          background-color: #ffffff;
        }
        .icon-circle {
          width: 40px;
          height: 40px;
          border-radius: 25%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
        }
        .trend-title {
          font-size: 14px;
          color: #777;
          margin-bottom: 2px;
        }
        .trend-value {
          font-size: 18px;
          font-weight: 600;
          color: #333;
        }
        .chart-card, .order-card {
          height: 400px;
          overflow: hidden;
        }
        .order-list {
          max-height: 250px;
          overflow-y: auto;
        }
      `}</style>
      </div>
      {/* </Card> */}
      {/* </LoadingOverlay> */}
      {/* </div> */}
    </div>
  );
};

export default Home;