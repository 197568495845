import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import styled from "styled-components";
import CustomerService from "../../../pages/Finance/Invoices/Invoices.service";
import AuthService from "../../../pages/Authentication/auth.service";
import Alerts from "../../Alerts/alerts";
import { Row, Col } from 'react-bootstrap';
import DateRangePickerComponent from './statementRangePicker'; // Adjust the import path as needed
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import { Tooltip } from "@mui/material";
import GetAppIcon from '@mui/icons-material/GetApp';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const DateInput = styled.input.attrs(props => ({
  type: "date",
  placeholder: "Select Date",
}))`
  height: 32px;
  width: 100%;
  
  border-radius: 6px;
`;

const Input = styled.select.attrs(props => ({
  type: "select",
  size: props.small ? 5 : undefined
}))`
  height: 32px;
  width: 100%;
  border-radius: 6px;
  border-color:#ccc; 
  background: white;
`;

const StatusInput = styled.input.attrs(props => ({
  type: "text",
  placeholder: "Search Status"
}))`
  height: 32px;
  width: 100%;
  border-radius: 6px;
  
`;

const Label = styled.label`
  display: block;
  font-weight: bold;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  gap: 40px;
`;

const FilterRow = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const CollectionFilter = ({ filterText1,
  handleKeyDown, onFilter1, onFilterInvoice_StartDate,
  onFilterInvoicdue_date,
  searchCustomers, resetFilters, handlePDF, sendMail, viewPDF, authData, accessPages, super_admin, current_module }) => {
  const { t, i18n } = useTranslation();
  const [collection, setCollection] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchcollections();
  }, []);
  const [selectedDate, setSelectedDate] = useState(null);

  function fetchcollections() {
    var getData = {
      functionName: "GetInvoiceTo",
      postData: {
        sort: { _id: -1 },
        offset: 0,
        limit: 1000,
        filter: {
          user_type: localStorage.getItem("invoice_to").split(','),
        },
        TableName: 'users',
        TableField: localStorage.getItem("invoice_to_field")
      },
    };
    CustomerService.GetInvoiceTo(getData, authData.sessionID).then((response) => {
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        if (response.data) {
          setCollection(response.data.data);

        }
      }
    },
      (error) => {
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  console.log(filterText1);


  const handleDateChange_Invoice = (startDateFormatted, endDateFormatted) => {
    // Call the callback functions to update the parent component's state
    onFilterInvoice_StartDate(startDateFormatted);
    onFilterInvoicdue_date(endDateFormatted);
  };
  return (
    <>
      <Row style={{ marginTop: '20px', marginLeft: '10px', marginBottom: '20px' }}>
        <Col xs="12" sm="8" md="4" lg="2" >
          <Input
            type="select"
            placeholder={t("Search Customer")}
            value={filterText1}
            onChange={onFilter1}
            onKeyDown={handleKeyDown}
            id="colfilter"
          >

            <option disabled={false} value="">--{t("Select Members")}--</option>
            {collection &&
              collection.map((opt_collection, index) => (
                <option key={index} value={opt_collection._id}>{opt_collection[localStorage.getItem('invoice_to_field')]}</option>
              ))
            }
          </Input>
        </Col>
        <Col style={{
          marginLeft: '10px',
          background: '#F9F9FB', height: "35px", border: '1px solid #ccc',
          borderRadius: '6px', marginBottom: '20px', display: 'flex', alignItems: 'center'
        }} xs="12" sm="8" md="6" lg="4">
          <Label style={{ width: '100px', background: '#F9F9FB', paddingRight: '' }}>
            {t('Dates')}</Label>
          <div className="d-flex justify-content-end align-items-center h-100 pr-2">
            <div style={{ borderRight: '2px solid #ccc', height: '4%' }}></div>
          </div>
          <DateRangePickerComponent style={{ paddingLeft: '10px' }} onDateChange={handleDateChange_Invoice} />
        </Col>


        <Col xs="12" sm="10" md="7" lg="5">
          <button type="button" className="btn btn-custom-color btn-hover-shine ms-1"
            onClick={() => searchCustomers()}
          >{t("Search")}</button>
          <button style={{ marginLeft: '10px', marginRight: '10px' }} type="button"
            className="btn btn-custom-color btn-hover-shine"
            onClick={() => resetFilters()}
          >{t("Reset")}</button>
          {!super_admin ? current_module?.ViewPDF === 1 &&
            <Tooltip placement="top" title="View PDF" arrow>
              <button onClick={() => viewPDF()} style={{ marginRight: '10px' }} className="btn btn-custom-color btn-hover-shine ms-1">
                <RemoveRedEyeIcon />
              </button>
            </Tooltip>
            :
            <Tooltip placement="top" title="View PDF" arrow>
              <button onClick={() => viewPDF()} style={{ marginRight: '10px' }} className="btn btn-custom-color btn-hover-shine ms-1">
                <RemoveRedEyeIcon />
              </button>
            </Tooltip>}
          {!super_admin ? current_module?.DownloadPDF === 1 &&

            <Tooltip placement="top" title="Download PDF" arrow>
              <button onClick={() => handlePDF()} style={{ marginRight: '10px' }} className="btn btn-custom-color btn-hover-shine ms-1">
                <GetAppIcon />
              </button>
            </Tooltip>
            :
            <Tooltip placement="top" title="Download PDF" arrow>
              <button onClick={() => handlePDF()} style={{ marginRight: '10px' }} className="btn btn-custom-color btn-hover-shine ms-1">
                <GetAppIcon />
              </button>
            </Tooltip>
          }
          {!super_admin ? current_module?.SendEmail === 1 &&
            <Tooltip placement="top" title="Send Email" arrow>
              <button onClick={() => sendMail()} style={{ marginRight: '10px' }} className="btn btn-custom-color btn-hover-shine ms-1">
                <AttachEmailIcon />
              </button>
            </Tooltip>
            :
            <Tooltip placement="top" title="Send Email" arrow>
              <button onClick={() => sendMail()} style={{ marginRight: '10px' }} className="btn btn-custom-color btn-hover-shine ms-1">
                <AttachEmailIcon />
              </button>
            </Tooltip>
          }
        </Col>


      </Row>
    </>

  );
};

export default CollectionFilter;
