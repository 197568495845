
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import React, { useState, useRef, useEffect, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { Form, Field } from "react-final-form";
import "../../assets/custom css/loadoverlay.scss";
import DynModuleService from "../Dynamic Modules/dynamicmodules.service";
import AuthService from "../Authentication/auth.service";
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom';
import FormsService from "../DynamicUserForms/UserForms.service";
import ConfigurationService from "../Configuration/configuration.service";
import $ from 'jquery';
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  Input
} from "reactstrap";





const InsertionForm = () => {

  const params = useParams();

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [logo, setLogo] = useState(localStorage.getItem("system_logo"));

  var  pathname = location.pathname;
  pathname =  pathname.split('/')[2];
  var pathData = {}

  pathData.Parent_referal_name = "";
  pathData.redirect_param = "";
  pathData.Parentid = "";
  pathData.id = pathname;
  if(pathData.id){
    pathData.redirect_param = pathname;
    var text = pathname;
    if(text.indexOf("-") > -1){
      pathData.id = text.split('-')[0];
      pathData.Parentid = text.split('-')[1];
      pathData.Parent_referal_name = text.split('-')[2];
  
    }
  }
  const { state } = location;

  const [loading, setLoading] = useState(false);
  const form = useRef();
  const [pre_params, setpre_params] = useState(pathData)
  const [collectionlist, setcollectionlist] = useState([])
  const [fieldlist, setfieldlist] = useState([])
  const [fieldlist_validate, setfieldlist_validate] = useState([]) 
  const [showPreviousBtn, setshowPreviousBtn] = useState(false) 
  const [showNextBtn, setshowNextBtn] = useState(true) 
  const [showFinishBtn, setshowFinishBtn] = useState(false) 
  const [showNavigation, setshowNavigation] = useState(true) 
  const [compState, setcompState] = useState(0) 
  const [finished, setfinished] = useState(false) 
  
  
  const [steps, setsteps] = useState( []) 
  const [navState, setnavState] = useState({current: 0, styles: ["done","doing","todo"] })
  
  
  
  useEffect(() => {
    getNavStates(0, steps.length);
    getSetLanguage();
    setTimeout(() => {
        getSystemConfig();
    }, 2000);
    fetchDynamicData();
  }, []); 

  

  function getSetLanguage() {
      if(localStorage.getItem("language")) {
          const language = localStorage.getItem("language");
          i18n.changeLanguage(language);
      }
      else {
          localStorage.setItem('language', 'en');
          i18n.changeLanguage('en');
      }
  }

  function getSystemConfig() {
      ConfigurationService.getConfiguration().then((response) => { 
        
          if(response.data.status){
          setColors(response.data.data[0].buttons_color, '--buttons-color');
          setColors(response.data.data[0].topbar_color, '--topbar-color');
          setColors(response.data.data[0].leftbar_color, '--leftbar-color');
          setColors(response.data.data[0].buttons_text_color, '--buttons-text-color');
          setSystemLogo(response.data.data[0].system_logo);
          }
          else {
          setColors('#3ac47d', 'onerror');
          }
      },
      (error) => {
          setColors('#3ac47d', 'onerror');
      });
  }

  function setColors(color, param) {
      if(param === '--buttons-color') {
          localStorage.setItem("buttons_color", color);
          document.documentElement.style.setProperty('--buttons-color', color)
      }
      if(param === '--topbar-color') {
          localStorage.setItem("topbar_color", color);
          document.documentElement.style.setProperty('--topbar-color', color)
      }
      if(param === '--leftbar-color') {
          localStorage.setItem("leftbar_color", color);
          document.documentElement.style.setProperty('--leftbar-color', color)
      }
      if(param === '--buttons-text-color') {
          localStorage.setItem("buttons_text_color", color);
          document.documentElement.style.setProperty('--buttons-text-color', color)
      }
      if(param === 'onerror') {
          localStorage.setItem("buttons_color", color);
          localStorage.setItem("topbar_color", '#fff');
          localStorage.setItem("leftbar_color", '#fff');
          localStorage.setItem("buttons_text_color", '#fff');
          document.documentElement.style.setProperty('--buttons-color', color)
          document.documentElement.style.setProperty('--topbar-color', '#fff')
          document.documentElement.style.setProperty('--leftbar-color', '#fff')
          document.documentElement.style.setProperty('--buttons-text-color', '#fff')
      }
  }

  function setSystemLogo(sys_logo) {
      setLogo(sys_logo);
      localStorage.setItem("system_logo", sys_logo);
  }
  const fetchDynamicData = async () => {
    try {
      const response = await FormsService.get_signiup_data(pre_params.id);
      if(response.data.message === "Unauthorized") {
        // AuthService.logout();
        // navigate("/login");
        // window.location.reload();
      }
      else {
        const data = await response.data.data;
        
        var custom_fields = data[0].custom_fields;
        var formsteps = data[0].formsteps;
        var stepobj = [];
        for(var i in formsteps){
          stepobj.push({ name: formsteps[i], component: '' });
        }
        
        stepobj.push({ name: 'Finish', component: '' });
        
        setsteps(stepobj);
        for(var i=0;i < custom_fields.length; i++){
          if(custom_fields[i].options_collection !==''){
            
            const optresponse = await FormsService.getoptions(custom_fields[i].options_collection,"",{},5000); 
            const optdata = await optresponse.data.data;
            var options = [];
            
            for(var j=0;j < optdata.length; j++){
              options.push({label:optdata[j][custom_fields[i].Options_Field_Name],value:optdata[j]._id,})
            }
            custom_fields[i].options = options;
          }
          if(custom_fields[i].parent_col === pre_params.Parent_referal_name ){
            custom_fields[i].default_value = pre_params.Parentid
          }else{
            custom_fields[i].default_value = ""
          }
        }
      
        data[0].custom_fields = custom_fields;
        
        setcollectionlist(data[0]);
        setfieldlist(data[0].custom_fields);
        var cust = data[0].custom_fields;
        var validations = {};
        for (var i =0; i < cust.length;i++) {
          validations[cust[i].referal_name] = cust[i].is_required;
        }

       
        setfieldlist_validate(validations);
       // renderStepsComponent(0);
      }
    } catch (error) {
        console.log("error", error);
    }
  };

  var setNavStates = (next) => {
    getNavStates(next, steps.length)
    if (next < steps.length) {
      setcompState(next)
    }
    checkNavState(next, steps.length)
  };

  var handleKeyDown = (evt) => {
    if (evt.which === 13) {
      this.next();
    }
  };

  var handleOnClick = (evt) => {
    if (
      evt.currentTarget.value === steps.length - 1 &&
      compState === steps.length - 1
    ) {
      setNavStates(steps.length);
    } else {
      setNavStates(evt.currentTarget.value);
    }
  };
 
  async function next() {
    
    var field_errors =  [];
    for(var i in fieldlist) {
        var field = fieldlist[i];
        if(field.step-1 === compState){
          var fieldvalue = document.getElementById(field.referal_name).value;
          if(field.is_required){
           
            if(fieldvalue ===''){
              field_errors.push({field:field.referal_name,status:false,message:field.name+" is required"});
              document.getElementById(field.referal_name).style.border ="2px solid red"
              document.getElementById(field.referal_name+"error-box").innerHTML =" is required."
            }else{
              
              document.getElementById(field.referal_name).removeAttribute("style");
              document.getElementById(field.referal_name+"error-box").innerHTML ="";
            }
          }
          if(field.is_unique && fieldvalue !==''){
            var postData = {}
            postData['collection_name'] = collectionlist.collection_name;
            var filter = {}
            filter[field.referal_name] = fieldvalue;
            postData['filter'] =filter;
            
            const response = await FormsService.get_collection_data(postData);
            const data = await response.data;
            if(data.count){
              if(data.count.count > 0 ){
                field_errors.push({field:field.referal_name,status:false,message:field.name+" already exists"});
                document.getElementById(field.referal_name).style.border ="2px solid red"
                document.getElementById(field.referal_name+"error-box").innerHTML =" already exists."
              } else{
                document.getElementById(field.referal_name).removeAttribute("style");
                document.getElementById(field.referal_name+"error-box").innerHTML ="";
              }
            } else{
              document.getElementById(field.referal_name).removeAttribute("style");
              document.getElementById(field.referal_name+"error-box").innerHTML ="";
            } 
          }
          if(fieldvalue !=='' && field.referal_name==='cpassword'){
            var password_val =  document.getElementById('password').value;
            if( fieldvalue !==password_val){
              field_errors.push({field:field.referal_name,status:false,message:"Confirm Password and password should be matching."});
              document.getElementById(field.referal_name).style.border ="2px solid red"
              document.getElementById(field.referal_name+"error-box").innerHTML =" should be matching with password field."
            }else{
              
              document.getElementById(field.referal_name).removeAttribute("style");
              document.getElementById(field.referal_name+"error-box").innerHTML ="";
            }
          }
        }
    }          
    if(field_errors.length < 1){
      setNavStates(compState + 1);
     // renderStepsComponent(compState + 1);
    }
   
  };

  var previous = () => {
    if (compState > 0) {
      setNavStates(compState - 1);
     // renderStepsComponent(compState - 1);
    }
  };

  var getClassName = (className, i) => {
    
    return className + "-" + navState.styles[i];
  };

  var renderSteps = () => {
   
    return steps.map((s, i) =>{ 
      return (
      <li
        className={getClassName("form-wizard-step", i)}
        onClick={handleOnClick}
        key={i}
        value={i}
      >
        <em>{i + 1}</em>
        <span>{steps[i].name}</span>
      </li>
    )}
    );
  };
  const getNavStates = (indx, length) => {
    let styles =[];
    for (let i = 0; i < length; i++) {
      if (i < indx) {
        styles.push("done");
      } else if (i === indx) {
        styles.push("doing");
      } else {
        styles.push("todo");
      }
    }
    setnavState({current:indx,styles:styles})
  };
  
  
  const validateFtn = (values) => {
    const errors = {};
    function validateEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
    if (!values.email) {
      errors.email = t('Email Required.');
    }
      else if (!validateEmail(values.email)) {
      errors.email = t('Please enter a valid email address.');
    }
    if (!values.password) {
      errors.password = t('Password Required.');
    }
    return errors;
  };

  async function onSubmit(values){
    
    var field_errors =  [];
    
    var formData = new FormData();
    for(var i in fieldlist) {
        var field = fieldlist[i];
        if(field.field_type === 'radio'){
          
          const elements1 = Array.from(
            document.getElementsByClassName('prop'+field.referal_name)
          );
          var elementvalue = 0;
          elements1.forEach(element => {
            
            if(element.checked){
              elementvalue = element.value
            }
          });
          formData.append(field.referal_name, elementvalue)
        }else if(field.field_type === 'checkbox'){
          const elements1 = Array.from(
            document.getElementsByClassName('prop'+field.referal_name)
          );
          var elementvalue = [];
          elements1.forEach(element => {
            
            if(element.checked){
              elementvalue.push(element.value)
            }
          });
          var fieldelevalue = 0;
          if(elementvalue.length ==0){
            formData.append(field.referal_name, 0)
          }else if(elementvalue.length ==1){
            formData.append(field.referal_name, elementvalue[0])
          }else{
            formData.append(field.referal_name, elementvalue)
          }
          
        }
        else{
          
          var fieldvalue = document.getElementById(field.referal_name).value;
          if(field.field_type === 'file'){
            formData.append(field.referal_name, $('#'+field.referal_name).prop('files')[0]);
          }else{
            formData.append(field.referal_name,fieldvalue)
          }
          if(field.step-1 === compState){
            var fieldvalue = document.getElementById(field.referal_name).value;
            if(field.is_required){
            
              if(fieldvalue ===''){
                field_errors.push({field:field.referal_name,status:false,message:field.name+" is required"});
                document.getElementById(field.referal_name).style.border ="2px solid red"
                document.getElementById(field.referal_name+"error-box").innerHTML =" is required."
              }else{
                
                document.getElementById(field.referal_name).removeAttribute("style");
                document.getElementById(field.referal_name+"error-box").innerHTML ="";
              }
            }
            if(field.is_unique && fieldvalue !==''){
              var postData = {}
              postData['collection_name'] = collectionlist.collection_name;
              var filter = {}
              filter[field.referal_name] = fieldvalue;
              postData['filter'] =filter;
              
              const response = await FormsService.get_collection_data(postData);
              const data = await response.data;
              if(data.count){
                if(data.count.count > 0 ){
                  field_errors.push({field:field.referal_name,status:false,message:field.name+" already exists"});
                  document.getElementById(field.referal_name).style.border ="2px solid red"
                  document.getElementById(field.referal_name+"error-box").innerHTML =" already exists."
                } else{
                  document.getElementById(field.referal_name).removeAttribute("style");
                  document.getElementById(field.referal_name+"error-box").innerHTML ="";
                }
              } else{
                document.getElementById(field.referal_name).removeAttribute("style");
                document.getElementById(field.referal_name+"error-box").innerHTML ="";
              } 
            }
            if(fieldvalue !=='' && field.referal_name==='cpassword'){
              var password_val =  document.getElementById('password').value;
              if( fieldvalue !==password_val){
                field_errors.push({field:field.referal_name,status:false,message:"Confirm Password and password should be matching."});
                document.getElementById(field.referal_name).style.border ="2px solid red"
                document.getElementById(field.referal_name+"error-box").innerHTML =" should be matching with password field."
              }else{
                
                document.getElementById(field.referal_name).removeAttribute("style");
                document.getElementById(field.referal_name+"error-box").innerHTML ="";
              }
            }
          }

        }
        
    }                 
    if(field_errors.length < 1){
      setLoading(true);
      setNavStates(compState + 1);
      setfinished(true);
     
      formData.append("functionName","InsertUserForm")
      formData.append("formId",collectionlist._id)
      formData.append("user_type_id",collectionlist._id)
      formData.append("user_type",collectionlist.name)
      formData.append("collection_name",collectionlist.collection_name);
      
      FormsService.save_user(formData).then((response) => {
        setLoading(false);
        setNavStates(compState + 1);
        setfinished(true);
      }, 
      (error) => {
        setLoading(false);
        const resMessage =
          (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          if(resMessage === 'Unauthorized') {
           // AlertService.swalErrorAlert(t("Invalid Credentials."))
          }
          else {
           // AlertService.swalErrorAlert(resMessage)
          }
        }
      );
    }
  };
  
  const checkNavState = (currentStep, stepsLength) => {
    if (currentStep > 0 && currentStep < stepsLength - 2) {

      setshowPreviousBtn(true)
      setshowNextBtn(true)
      setshowFinishBtn(false);
    } else if (currentStep == stepsLength - 2) {
      setshowPreviousBtn(true)
      setshowNextBtn(false)
      setshowFinishBtn(true);
    }else if (currentStep === 0) {
      setshowPreviousBtn(false)
      setshowNextBtn(true)
      setshowFinishBtn(false);
    } else {
      setshowPreviousBtn(false)
      setshowNextBtn(false)
      setshowFinishBtn(false);
    }
  };  
  
 
  return (
    <Fragment>
      <TransitionGroup>
        <CSSTransition component="div" classNames="TabsAnimation" appear={true}
          timeout={1500} enter={false} exit={false}>
          <div>
            {/* <PageTitle heading="Forms Wizard"
              subheading="Easily create beautiful form multi step wizards!"
              icon="lnr-map text-info"/> */}
            <Row>
              <Col md="12" lg="8" style={{margin:"0 auto"}}>
                <Card className="main-card mb-3">
                <br/><br/>
                  <div className="h5 modal-title text-center">
                     <img src={logo} className="app-logo mx-auto mb-3" /> 
                    <h6 className="mb-5">                                
                         <span>{t('Sign Up to your account below.')}</span> 
                    </h6>
                  </div>
                  <CardBody>
                  <div onKeyDown={handleKeyDown}>
                    <ol className="forms-wizard">{renderSteps()}</ol>
                    <Form onSubmit={onSubmit} validate={validateFtn}  render={({ handleSubmit, values, submitting, validating, valid }) => (
                      <form onSubmit={handleSubmit}>
                        {/* {renderStepsComponent()} */}
                        {steps && steps.map((step, stepindex) => {
                          var stepno = stepindex + 1;
                          
                          if(stepindex === compState){
                            var showstyle = {display:"block"}
                          }else{
                            var showstyle = {display:"none"}
                          }
                          
                          return(
                            <div id={"step-"+stepno} className="stepsDiv" style={showstyle} >
                              {fieldlist && fieldlist.map((field, index) => {
                                var req = "";
                                if(field.is_required){ req ="*"}

                                if(field.step===stepno){
                                  if(field.field_type === 'text' || field.field_type === 'email' || field.field_type === 'number'|| field.field_type === 'password'|| field.field_type === 'file' || field.field_type === 'date'){
                                    return (
                                      <>
                                        <div className="row" key={index}>
                                          <div className="col-md-12 col-xl-12" >
                                            <div className="form-group" >
                                              <label htmlFor="name">{field.field_lable} <span style={{color:"red",fontSize: "x-large"}} >{req}</span> <span id={field.referal_name+"error-box"} style={{color:"red"}}></span></label>
                                              <Input type={field.field_type} autocomplete={"new-"+field.referal_name}  className="form-control" placeholder={field.field_lable}  name={field.referal_name} id={field.referal_name}    />
                                            </div>
                                          </div>
                                        </div>
                                        <br/>
                                      </>
                                    )
                                  }
                                  else if(field.field_type === 'dropdown'){
                                    
                                    return (
                                      <>
                                        <div className="row" key={index}>
                                          <div className="col-md-12 col-xl-12" >
                                            <div className="form-group" >
                                              <label htmlFor="name">{field.field_lable}  <span style={{color:"red",fontSize: "x-large"}} >{req}</span>  <span id={field.referal_name+"error-box"} style={{color:"red"}}></span></label>
                                              <select className="form-select" name={field.referal_name} id={field.referal_name}    >
                                                  <option disabled={false} value="">{t('--Choose an option--')}</option>
                                                  {field.options.map((option, optionsindex) =>   (
                                                      <option key={field.referal_name+optionsindex} value ={option.value}>{option.label}</option>
                                                  ))}
                                              </select>
                                            </div>
                                          </div>
                                        </div>
                                      <br/>
                                      </>
                                    )
                                  }
                                  else if(field.field_type === 'radio' || field.field_type === 'checkbox'){
                                    return (
                                      <>
                                        <div className="row" key={index}>
                                          <div className="col-md-12 col-xl-12" ><label htmlFor="name">{field.field_lable}  <span style={{color:"red",fontSize: "x-large"}} >{req}</span>  <span id={field.referal_name+"error-box"} style={{color:"red"}}></span></label></div>
                                          <br/>
                                          <div className="col-md-12 col-xl-12" >
                                            <ul className="child mb-0 p-0">
                                              {field.options.map((option, optionsindex) =>   (
                                                <li className="form-check form-check-inline">
                                                  <input className={"form-check-input prop"+field.referal_name} 
                                                      type={field.field_type}
                                                      value ={option.value}  name={field.referal_name} id={field.referal_name+optionsindex}
                                                  />
                                                  <label className="form-check-label" htmlFor="users">
                                                    {option.label}
                                                  </label>
                                                </li>
                                              ))}   
                                            </ul>
                                          </div>
                                        </div>
                                        <br/>
                                      </>
                                    )
                                  }
                                  else {
                                    return (
                                      <>
                                        <div className="row" key={index}>
                                          <div className="col-md-12 col-xl-12" >
                                            <div className="form-group" >
                                              <label htmlFor="name">{field.field_lable}  <span style={{color:"red",fontSize: "x-large"}} >{req}</span>  <span id={field.referal_name+"error-box"} style={{color:"red"}}></span></label>
                                              <Input type="text" className="form-control" placeholder={field.field_lable}  name={field.referal_name} id={field.referal_name}    />
                                            </div>
                                          </div>
                                        </div>
                                      <br/>
                                      </>
                                    )
                                  }

                                }
                              })}
                            </div>
                          )
                        })}
                        
                        { finished && <div id="finishstep" >
                          <Fragment>
                            <div className="form-wizard-content">
                              <div className="no-results">
                                <div className="sa-icon sa-success animate">
                                  <span className="sa-line sa-tip animateSuccessTip" />
                                  <span className="sa-line sa-long animateSuccessLong" />
                                  <div className="sa-placeholder" />
                                  <div className="sa-fix" />
                                </div>
                                <div className="results-subtitle mt-4">Finished!</div>
                                <div className="results-title">
                                  Thanks You For signing up!
                                </div>
                                <div className="mt-3 mb-3" />
                                <div className="text-center">
                                  <Button color="success" size="lg" className="btn-shadow btn-wide">
                                    Click Here to login
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </Fragment>
                        </div>}
                        
                        <div className="divider" />
                        <div className="clearfix">
                          <div style={showNavigation ? {} : { display: "none" }}>
                            <Button color="secondary" className="btn-shadow float-start btn-wide btn-pill" outline
                              style={showPreviousBtn ? {} : { display: "none" }} onClick={previous}>
                              Previous
                            </Button>
                            <Button color="primary" className="btn-shadow btn-wide float-end btn-pill btn-hover-shine"
                              style={showNextBtn ? {} : { display: "none" }} onClick={next}>
                              Next
                            </Button>
                            <Button color="primary" id="finishbtn" className="btn-shadow btn-wide float-end btn-pill btn-hover-shine"
                              style={showFinishBtn ? {} : { display: "none" }} onClick={onSubmit}>
                              Finish
                            </Button>
                          </div>
                        </div>
                        </form>
                      )}
                      />
                  </div>
                  </CardBody>
                </Card>
              </Col>
              
            </Row>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </Fragment>
  );
  

}

export default InsertionForm;



