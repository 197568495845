import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useParams  } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, CardBody, Card } from "reactstrap";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import BusinessService from "./Businesses.service.js";
import AuthService from "../Authentication/auth.service.js";
import Alerts from "../../components/Alerts/alerts.js";
import PermissionAccess from '../../PermissionAccess/Access.js';

const EditSection = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const { authData, accessPages, super_admin, current_module } = PermissionAccess();

    const [data, setData] = useState({
        name: '',
        status: '',
      });

    const [errors, setErrors] = useState({
        name: '',
        status: '',
      });

  //get data
  useEffect(() => {
    const fetchData = async () => {
        setLoading(true);
        BusinessService.GetSingleSection(authData.sessionID,id).then((response) => {
            setLoading(false);
            if (response.data.message === "Unauthorized") {
                AuthService.logout();
                navigate("/login");
                window.location.reload();
            } else {
                if (response.data.status == true) {
                    setData({
                        name: response.data.data.data.name,
                        status: response.data.data.data.status,
                      });
                    
                  }
                  else {
                    Alerts.swalErrorAlert(t("Something went wrong, Please try again."));
                  }
            }
        }, (error) => {
            setLoading(false);
            const errContent = (error.response && error.response.data) || error.message || error.toString();
            if (errContent) {
                Alerts.swalErrorAlert(errContent);
            } else {
                Alerts.swalErrorAlert(t("Server Error, Please try again later."));
            }
        });
    };
    fetchData();
  }, [id]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    let error = '';

    if (name === 'name') {
        if (!value) {
          error = 'Name is required';
        }
      }

      if (name === 'status') {
        if (!value) {
          error = 'Status is required';
        }
      }

    
    setErrors({ ...errors, [name]: error });
    setData({ ...data, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validationErrors = {};

    if (!data.name) { validationErrors.name = 'Name is required';}
    if (!data.status) { validationErrors.status = 'Status is required';}

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
        setLoading(true);
            BusinessService.EditSection(authData.sessionID,data,id).then((response) => {
                setLoading(false);
                if (response.data.message === "Unauthorized") {
                    AuthService.logout();
                    navigate("/login");
                    window.location.reload();
                } else {
                    if (response.data.status == true) {
                        Alerts.swalSuccessAlert(t('Section updated successfully.'));
                        validationErrors.name = '';
                        navigate("/business-settings");
                      }
                      else {
                        if (response.data.errors && response.data.errors.length > 0) {
                            const validationErrors = {};
                            response.data.errors.forEach(error => {
                                validationErrors[error.field] = error.message;
                            });
                            setErrors(validationErrors);
                        }
                      }
                }
            }, (error) => {
                setLoading(false);
                const errContent = (error.response && error.response.data) || error.message || error.toString();
                if (errContent) {
                    Alerts.swalErrorAlert(errContent);
                } else {
                    Alerts.swalErrorAlert(t("Server Error, Please try again later."));
                }
            });
          
    }
  };

    return (
        <div className="app-main__outer">
            <div className="app-main__inner">
                <div className="bg-light-gray bg-animation">
                    <div className="d-flex justify-content-center align-items-center">
                        <Col md="12" className="mx-auto app-login-box">
                            <div className="app-page-title">
                                <div className="page-title-wrapper">
                                    <div className="page-title-heading fw-bold">
                                        <div className="page-title-icon">
                                            <i className="pe-7s-news-paper icon-gradient bg-tempting-azure" />
                                        </div>
                                        <div>
                                            {`${t("Section")} ${t("Management")}`}
                                            <div className="page-title-subheading">
                                                {`${t("Update")} ${t("Section")}`}.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="page-title-actions">
                                        <NavLink to="/business-settings">
                                            <button type="button" className="btn btn-outline-success btn-hover-shine me-3">
                                                {t("Back")}
                                            </button>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex justify-content-center">
                                <Col md="12">
                                    <Card className="main-card mb-3">
                                        <CardBody>
                                            <LoadingOverlay tag="div" active={loading}
                                                styles={{ overlay: (base) => ({ ...base }) }}
                                                spinner={<DNALoader />}>
                                                <form onSubmit={handleSubmit}>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="form-group row mt-3">
                                                                <label className="col-sm-2 col-form-label">{t('Name')}</label>
                                                                <div className="col-sm-7">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder={t('Name')}
                                                                        name="name"
                                                                        value={data.name}
                                                                        onChange={handleChange}
                                                                    />
                                                               {errors.name && <strong className="error text-danger">{errors.name}</strong>}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-12">
                                                            <div className="form-group row mt-3">
                                                                <label className="col-sm-2 col-form-label">{t('Status')}</label>
                                                                <div className="col-sm-7">
                                                                    <select className="form-control"
                                                                        name="status"
                                                                        value={data.status}
                                                                        onChange={handleChange}
                                                                     >
                                                                        <option value="">{t('Please select status.')}</option>
                                                                        <option value="Active">{t('Active')}</option>
                                                                        <option value="Inactive">{t('InActive')}</option>
                                                                    </select>
                                                                    {errors.status && <strong className="error text-danger">{errors.status}</strong>}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="form-group row mt-5">
                                                            <div className="col-5"></div>
                                                            <div className="col-7">
                                                                <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-light btn-hover-shine me-3">{t("Cancel")}</button>
                                                                <button type="submit" className="btn btn-custom-color">{t("Save")}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </LoadingOverlay>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </div>
                        </Col>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditSection;
