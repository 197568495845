import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import styled from "styled-components";

const Input = styled.input.attrs(props => ({
  type: "text",
  size: props.small ? 5 : undefined
}))`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid var(--buttons-color, #30b1ff);
  padding: 0 32px 0 16px;
  justify-content: flex-start;
`;

const Select = styled.select`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid var(--buttons-color, #30b1ff);
  margin-left: 10px;
  padding: 0 16px;
`;

const SectionFilter = ({ onClear, onSearch }) => {
  const { t, i18n } = useTranslation();
  const [searchText, setSearchText] = useState("");
  const [status, setStatus] = useState("");

  const handleClear = () => {
    onClear();
    setSearchText('');
    setStatus('');
  };

  const handleSearch = () => {
    onSearch(searchText, status);
  };

  return (
    <>
      <Input
        type="text"
        placeholder={t('Filter by name')}
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
      />
      <Select
        value={status}
        onChange={(e) => setStatus(e.target.value)}
      >
        <option value="">{t('Select Status')}</option>
        <option value="Active">{t('Active')}</option>
        <option value="Inactive">{t('Inactive')}</option>
      </Select>
      <button
        style={{ marginLeft: '2%' }}
        type="button"
        className="btn btn-custom-color btn-hover-shine me-3 btn"
        onClick={handleSearch}
      >
        {t('Search')}
      </button>
      <button
        onClick={handleClear}
        type="button"
        className="btn btn-custom-color btn-hover-shine me-3 btn"
      >
        {t('Reset')}
      </button>
    </>
  );
};

export default SectionFilter;
