import axios from "axios";

const API_URL = process.env.REACT_APP_BASEURL;
const B_URL = process.env.REACT_APP_LOGINURL


const headers = {
  'Content-Type': 'application/json',
  'token': localStorage.getItem("session-id")
}


const getAll = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(API_URL, payload, { headers });
};
const getAllStatements = (page, limit, filter) => {
  var payload = {
    functionName: "User_Record_Dashboard",
    Customer_ID: filter,
    limit: limit,
    offset: page
  }
  return axios.post(B_URL + "statements", payload, { headers });
};

const getAllBusiness = () => {
  var payload = {
    functionName: "GetBusinesses",
    postData: {
      limit: 9999999,
      offset: 0,
      business_ids: []
    }
  }
  return axios.post(B_URL + "BusinessModule/GetBusinesses", payload, { headers });
};

const getAllCategories = (page, limit, business_id) => {
  var payload = {
    functionName: "GetCategories",
    postData: {
      limit: limit,
      offset: page,
      business_id: business_id
    }
  }
  console.log("payload", payload);
  return axios.post(B_URL + "CategoryModule/GetCategories", payload, { headers });
};

const getAllProducts = (page, limit, Category, Branch) => {
  var payload = {
    functionName: "GetItems",
    postData: {
      filter: {},
      limit: limit,
      offset: page,
      category: Category,
      business: Branch
    }
  }
  console.log("GetItems", payload);
  return axios.post(B_URL + "ItemsRoutes/GetItems", payload, { headers });
};

const GetDashboardData = (payload, sessionID) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + 'DashboardRoutes/GetDashboardData', payload, { headers });
};

const setCart = (payload) => {
  console.log("setCart", payload);
  return axios.post(B_URL + "CartModule/setCart", payload, { headers });
};
const GetCartList = (payload) => {
  console.log("GetCartList", payload);
  return axios.post(B_URL + "CartModule/GetCartList", payload, { headers });
};

const GetProducctDetails = (payload) => {
  console.log("GetProducctDetails", payload);
  return axios.post(B_URL + "ItemModule/SearchItem", payload, { headers });
};

const GeProductReviews = (payload) => {
  console.log("GeProductReviews", payload);
  return axios.post(B_URL + "OrderModule/ProductReviews", payload, { headers });
};

const DeleteCart = (payload) => {
  console.log("DeleteCart", payload);
  return axios.post(B_URL + "CartModule/DeleteCart", payload, { headers });
};

const PlaceOrder = (payload) => {
  console.log("PlaceOrder", payload);
  return axios.post(B_URL + "CartModule/PlaceOrder", payload, { headers });
};

const AddTopUp = (payload) => {
  console.log("AddTopUp", payload);
  return axios.post(B_URL + "TopUpModule/AddTopUp", payload, { headers });
};

const GetTopUp = (payload) => {
  console.log("GetTopUp", payload);
  return axios.post(B_URL + "TopUpModule/GetTopUp", payload, { headers });
};
const GetReviewData = (payload) => {
  console.log("GetReviewData", payload);
  return axios.post(B_URL + "ReviewModule/GetReviewData", payload, { headers });
};

const GetOrdersProductForReview = (payload) => {
  console.log("GetOrdersProductForReview", payload);
  return axios.post(B_URL + "OrderModule/GetOrdersProductForReview", payload, { headers });
};

const MyReviews = (payload) => {
  console.log("MyReviews", payload);
  return axios.post(B_URL + "OrderModule/MyReviews", payload, { headers });
};

const AddReview = (payload) => {
  console.log("AddReview", payload);
  return axios.post(B_URL + "OrderModule/AddReview", payload, { headers });
};


const getInvoices = (page, limit, business, daysDropdown, sessionID) => {
  var payload = {
    functionName: "GetInvoices",
    postData: {
      filter: {},
      limit: limit,
      offset: page,
      daysDropdown: daysDropdown,
      business: business
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  console.log("GetInvoices", payload);
  return axios.post(B_URL + "E_invoiceFetchingModule/GetInvoices", payload, { headers });
};

const GetBusinesses = (payload, sessionID) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + 'BusinessModule/' + payload.functionName, payload, { headers });
};

const get_all_invoices = (business) => {
  return axios.get(B_URL + "ZohoBoxModule/get_all_invoices/" + business);
};


const access_token = (business) => {
  return axios.get(B_URL + "ZohoBoxModule/auth/" + business);
};

const fetchAll_E_invoice_Requested = (payload, sessionID) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + 'Request_E_InvoiceModule/' + payload.functionName, payload, { headers });
};

const Add_Bulk_Invoices = (payload, sessionID) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + 'ZohoBoxModule/' + payload.functionName, payload, { headers });
};


const DashboardService = {
  GetReviewData,
  getAll,
  fetchAll_E_invoice_Requested,
  getInvoices,
  Add_Bulk_Invoices,
  get_all_invoices,
  access_token,
  GetOrdersProductForReview,
  MyReviews,
  AddReview,
  GetBusinesses,
  AddTopUp,
  GetTopUp,
  PlaceOrder,
  DeleteCart,
  GetDashboardData,
  getAllBusiness,
  getAllCategories,
  getAllStatements,
  getAllProducts,
  GetCartList,
  GetProducctDetails,
  GeProductReviews,
  setCart

};

export default DashboardService;