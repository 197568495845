import React, { useState } from 'react';

const RejectQuotationPopup = ({ quotationId, onClose }) => {
  const [reason, setReason] = useState('');

  const handleSubmit = () => {
    // Handle submit logic, e.g., send reason to backend
    console.log('Rejection reason submitted:', reason);
    // Close the popup after submission
    onClose();
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <button className="close-button" onClick={onClose}>
          ✖
        </button>
        <h2>Reject Quotation</h2>
        <h3>ID# {quotationId}</h3>
        <div className="input-group">
          <label htmlFor="reason">Reason</label>
          <textarea
            id="reason"
            placeholder="Please submit the reason for Cancelling the quotation"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </div>
        <div className="button-group">
          <button className="back-button" onClick={onClose}>
            Go Back
          </button>
          <button className="submit-button" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>

      {/* CSS Styles */}
      <style jsx>{`
        .popup-overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        }
        .popup-content {
          background: #fff;
          border-radius: 15px;
          padding: 30px;
          width: 400px;
          text-align: center;
          box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
          position: relative;
        }
        .close-button {
          position: absolute;
          top: 15px;
          right: 15px;
          background: #fbe3e4;
          border: none;
          border-radius: 50%;
          width: 35px;
          height: 35px;
          font-size: 20px;
          color: #e74c3c;
          cursor: pointer;
        }
        h2 {
          margin: 20px 0 10px;
          font-size: 1.5rem;
        }
        h3 {
          margin-bottom: 20px;
          font-size: 1.2rem;
          font-weight: bold;
        }
        .input-group {
          margin-bottom: 20px;
          text-align: left;
        }
        label {
          display: block;
          margin-bottom: 5px;
          font-size: 0.9rem;
          color: #6b6b6b;
        }
        textarea {
          width: 100%;
          padding: 10px;
          border: 1px solid #ddd;
          border-radius: 5px;
          resize: none;
          height: 80px;
        }
        .button-group {
          display: flex;
          justify-content: space-between;
        }
        .back-button {
          background: #ffd5d5;
          color: #d9534f;
          padding: 10px 20px;
          border: none;
          border-radius: 5px;
          cursor: pointer;
        }
        .submit-button {
          background: #5cb85c;
          color: white;
          padding: 10px 20px;
          border: none;
          border-radius: 5px;
          cursor: pointer;
        }
      `}</style>
    </div>
  );
};

export default RejectQuotationPopup;
