import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Row, Label, FormGroup, Input, Card, CardBody } from "reactstrap";
import { Form, Field } from "react-final-form";
import moment from 'moment';

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../../components/Loaders/DnaLoader";
import "../../../../assets/custom css/loadoverlay.scss";

import Alerts from "../../../../components/Alerts/alerts";

import PaymentService from "./payments.service.js";
import AuthService from "../../../Authentication/auth.service";
import AlertService from "../../../../components/Alerts/alerts";


const AddUser = () => {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state.row;
  const method = location.state.method;
  console.log("state", state)
  const [loading, setLoading] = useState(false);
  const [Receipt, setReceipt] = useState('');
  const [PaymentMethods, setPaymentMethods] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState({});
  let payment_types = localStorage.getItem('payment_types').split(',')

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchPaymentMethods();

  }, []);

  useEffect(() => {
    const today = new Date().toISOString().split('T')[0];
    const paymentDateInput = document.getElementById('payment_date');

    if (paymentDateInput) {
      paymentDateInput.setAttribute('max', today);
    }
  }, []);

  function fetchPaymentMethods() {
    setLoading(true);
    PaymentService.getAllPaymentMethods().then((response) => {
      setLoading(false);
      console.log("Get Customers response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        console.log(response.data);
        setPaymentMethods(response.data.data);
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };


  const onSubmit = values => {
    console.log(values);
    let status;
    console.log(paymentMethod);
    if (Object.keys(paymentMethod).length !== 0) {
      if (state?.is_admin) {
        status = 'paid';
      } else {
        if (paymentMethod.is_moderated === 'Yes') {
          status = 'In Process';
        } else {
          status = 'paid';
        }
      }

      if (paymentMethod.is_online === 'Yes') {
        var getData = {
          functionName: "OnlinePayment",
          row: state,
          invoice_id: state?._id ? state?._id : null,
          amount: parseFloat(values.total_amount).toFixed(2),
          invoice_number: values.invoice_number,
          business_id: state?.business_id
        }
        console.log("getData", getData);
        setLoading(true);
        PaymentService.OnlinePayment(getData).then((response) => {
          console.log(response);
          setLoading(false);
          if (response.status === 200) {
            console.log(response);
            window.open(response.data.data, '_blank');
            console.log('Response : ', response);
          } else if (response.status === 401) {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          } else {
            window.open(response.data.data, '_blank');
          }
        },
          (error) => {
            setLoading(false);
            const errContent =
              (error.response && error.response.data) || error.message || error.toString();
            console.log("Net:-", errContent);
            if (errContent) {
              Alerts.swalErrorAlert(errContent);
            } else {
              Alerts.swalErrorAlert(t("Server Error, Please try again later."));
            }
          })
      } else {
        var payloadData = {
          functionName: "AddPayments",
          invoice_number: values.invoice_number,
          amount: parseFloat(values.total_amount).toFixed(2),
          total_amount: parseFloat(state.total_amount).toFixed(2),
          payment_method: paymentMethod?.payment_method,
          customer_id: state?.user_details?._id,
          order_id: state?.order_id,
          transaction_id: values.transaction_id,
          payment_date: values.payment_date,
          order_no: values.order_no,
          is_moderated: paymentMethod.is_moderated,
          Receipt: Receipt,
          status: status,
          invoice_id: state?._id ? state?._id : null,
          currency: localStorage.getItem("currency"),
          business_id: state?.business_id
        }
        console.log(payloadData);
        setLoading(true);
        PaymentService.addPayments(payloadData).then((response) => {
          console.log("API Response:-", response);
          setLoading(false);
          if (response.data.status) {
            if (state.check) {
              localStorage.setItem('tab5', "5");
            }

            navigate(-1)
            if (response.data.message !== '') {
              Alerts.swalSuccessAlert(response.data.message)
            }
            else {
              Alerts.swalSuccessAlert(t("Payment Added Successfully."));
            }
          }
          else {
            if (response.data.message !== '') {
              if (response.data.message === "Unauthorized") {
                AuthService.logout();
                navigate("/login");
                window.location.reload();
              }
              else {
                Alerts.swalErrorAlert(response.data.message);
              }
            }
            else {
              Alerts.swalErrorAlert(t("Error: Can't add Payment at the moment. Please try again later."))
            }
          }
        },
          (error) => {
            setLoading(false);
            Alerts.swalErrorAlert(t("Network Operation failed."))
          });
      }
    } else {
      AlertService.swalErrorAlert('Select a Payment Method!');
    }

  }


  const HandlePaymentMethod = (value) => {
    console.log("value", value);
    setPaymentMethod(value);
  }


  const validateFtn = (values) => {
    const errors = {};
    if (parseFloat(values.total_amount).toFixed(2) < parseFloat(state?.remaining_amount).toFixed(2)) {

    } else if (parseFloat(values.total_amount).toFixed(2) === parseFloat(state?.remaining_amount).toFixed(2)) {
    } else {
      errors.total_amount = t("Amount Should be less or equal to Total amount.");
    }
    
    if (paymentMethod && paymentMethod.is_online === "No" && !Receipt) {
      errors.receipt = t("Payment Receipt is required");
    }


    return errors;
  };

  const onFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      console.log(e.target.files)
      var filename = e.target.files[0].name
      console.log("File Name", filename)
      var fileReader = new FileReader();
      fileReader.readAsDataURL(e.target.files[0])
      fileReader.onload = () => {
        const convertedPDF = fileReader.result
        console.log("Converted PDF File:-", convertedPDF)
        setReceipt(convertedPDF);
      }
    } else {
      setReceipt('');
    }

  }


  const initialValues = {
    method: method?.payment_method || '',
    invoice_number: state?.invoice_number || '',
    order_no: state?.order_no || '',
    total_amount: state?.remaining_amount || '',
    payment_date: moment().format("YYYY-MM-DD")
  };

  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className="app-page-title">
            <div className="page-title-wrapper justify-content-left">
              <div className="page-title-heading fw-bold">
                <div className="page-title-icon">
                  <i className="pe-7s-add-user icon-gradient bg-happy-green" />
                </div>
                <div>{t(`Add Payment for Invoice ${state.invoice_number}`)}
                  <div className="page-title-subheading">
                    {t('Fill the form below to add payment details for invoice.')}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center">
            <div className="col-md-12">
              <Card className="main-card mb-3">
                <CardBody>
                  <Form onSubmit={onSubmit}
                    validate={validateFtn}
                    initialValues={initialValues}
                    render={({ handleSubmit, values, submitting, validating, valid }) => (
                      <form onSubmit={handleSubmit}>
                        <LoadingOverlay tag="div" active={loading}
                          styles={{ overlay: (base) => ({ ...base }) }}
                          spinner={<DNALoader />}>
                          <Row>
                            <Col md={6}>
                              <FormGroup>
                                <Field name="invoice_number">
                                  {({ input, meta }) => (
                                    <div>
                                      <Label for="invoice_number">{t('Invoice Number')}</Label>
                                      <Input {...input} type="text"
                                        invalid={meta.error && meta.touched}
                                        placeholder={t('Invoice Number')}
                                        disabled
                                      />
                                      {meta.error && meta.touched &&
                                        <span className="text-danger">{meta.error}</span>}
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                              <FormGroup>
                                <Field name="total_amount">
                                  {({ input, meta }) => {
                                    // Function to format the input value as a decimal with two decimal places

                                    return (
                                      <div>
                                        <Label for="total_amount">{t(`Amount (${localStorage.getItem("currency")})`)}</Label>
                                        <Input
                                          {...input}
                                          type="number"
                                          disabled={payment_types.includes("partial_payment") ? false : true}
                                          value={parseFloat(input.value).toFixed(2)}
                                          invalid={meta.error && meta.touched}
                                          placeholder={t('Amount')}
                                        />
                                        {meta.error && meta.touched && (
                                          <span className="text-danger">{meta.error}</span>
                                        )}
                                      </div>
                                    );
                                  }}
                                </Field>
                              </FormGroup>

                              <FormGroup>
                                <Field name="customer_id" initialValue={state.user_details ? state?.user_details[localStorage.getItem('invoice_to_field')] : ''}>
                                  {({ input, meta }) => (
                                    <div>
                                      <Label for="customer_id">{t('Customer Name')}</Label>
                                      <Input {...input} type="text"
                                        invalid={meta.error && meta.touched}
                                        placeholder="Customer"
                                        disabled
                                      />
                                      {meta.error && meta.touched &&
                                        <span className="text-danger">{meta.error}</span>}
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                              {paymentMethod && paymentMethod.is_online === "No" ? (
                                <FormGroup>
                                  <Field name="receipt">
                                    {({ input, meta }) => (
                                      <div>
                                        <Label for="method">{t('Payment Receipt')}</Label>
                                        <Input
                                          name="file"
                                          type='file'
                                          onChange={(e) => {
                                            onFileChange(e);
                                            input.onChange(e);
                                          }}
                                          required
                                        />
                                        {meta.error && meta.touched &&
                                          <span className="text-danger">{meta.error}</span>}
                                      </div>
                                    )}
                                  </Field>
                                </FormGroup>
                              ) : ''}
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <Field name="order_no">
                                  {({ input, meta }) => (
                                    <div>
                                      <Label for="order_no">{t('Order Number')}</Label>
                                      <Input {...input} type="text"
                                        invalid={meta.error && meta.touched}
                                        placeholder={t('Order Number')}
                                        disabled
                                      />
                                      {meta.error && meta.touched &&
                                        <span className="text-danger">{meta.error}</span>}
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>

                              <FormGroup>
                                <Field name="payment_method">
                                  {({ input, meta }) => (
                                    <div>
                                      <Label for="payment_method">{t('Payment Method')}</Label>
                                      <Input
                                        {...input}
                                        type="select"
                                        value={JSON.stringify(paymentMethod) || ''}
                                        onChange={(e) => HandlePaymentMethod(JSON.parse(e.target.value))}
                                        invalid={meta.error && meta.touched}
                                      >
                                        <option defaultValue key='' value={''}>{t("Select Payment Method")}</option>
                                        {PaymentMethods.filter((method) => method.status === 'Active').map((method) => (
                                          <option key={method.payment_method} value={JSON.stringify(method)}>
                                            {method.payment_method}
                                          </option>
                                        ))}
                                      </Input>


                                      {meta.error && meta.touched &&
                                        <span className="text-danger">{meta.error}</span>}
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>

                              <FormGroup>
                                <FormGroup>
                                  <Field name="payment_date">
                                    {({ input, meta }) => {
                                      return (
                                        <div>
                                          <Label for="payment_date">{t('Payment Date')}</Label>
                                          <Input
                                            {...input}
                                            type="date"
                                            id="payment_date"
                                            disabled={paymentMethod.is_online === "Yes"}
                                            defaultValue={input.value}
                                            invalid={meta.error && meta.touched}
                                          />
                                          {meta.error && meta.touched && (
                                            <span className="text-danger">{meta.error}</span>
                                          )}
                                        </div>
                                      );
                                    }}
                                  </Field>
                                </FormGroup>

                              </FormGroup>


                            </Col>
                            <FormGroup>
                              <div className="d-flex justify-content-center">
                                <button type="button" onClick={() => {
                                  if (state.check) {
                                    localStorage.setItem('tab5', "5");
                                  }
                                  navigate(-1)
                                }} className="btn btn-outline-light btn-hover-shine me-3 mt-3">{t('Cancel')}</button>
                                <button className="btn btn-custom-color btn-hover-shine me-3 mt-3" disabled={!valid} >{t('Make Payment!')}</button>
                              </div>
                            </FormGroup>
                          </Row>
                        </LoadingOverlay>
                      </form>
                    )}
                  />
                </CardBody>
              </Card>
            </div>
          </div>

        </div>
      </div>
    </Fragment >
  );

};
export default AddUser