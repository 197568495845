import React, { useState, useEffect } from 'react';
import {
  Box, Avatar, Typography, IconButton, List, ListItem, ListItemIcon, ListItemText,
  Container, styled, Divider, BottomNavigation, BottomNavigationAction,
  TextField, Dialog, DialogTitle, DialogContent, DialogActions, Button
} from '@mui/material';
import {
  ArrowBack, Edit as EditIcon, Delete as DeleteIcon,
  Person, Email, Phone, Home, Add as AddIcon, QuestionMark, Lock,
  HomeOutlined, ShoppingCartOutlined, CardGiftcardOutlined, ReceiptLongOutlined, PersonOutline
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from "react-router-dom";
import AlertService from "../../components/Alerts/alerts";
import AuthService from "../Authentication/auth.service";
import AddressService from './addressService';
import styledEmotion from '@emotion/styled';
import AddAddressForm from './AddAddressPopup';
import UpdateAddressPopup from './UpdateAddressPopup';
import axios from "axios";
import PasswordResetPopup from './PasswordResetPopup';

const OrangeAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: '#f2721c',
  borderRadius: '8px',
  width: 40,
  height: 40,
  fontSize: 20,
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: 'bold',
  marginBottom: theme.spacing(1),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const SectionBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  padding: theme.spacing(2),
  borderRadius: '8px',
  boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
  backgroundColor: 'white',
  width: '100%',  // Increased width
  maxWidth: '450px',  // Maximum width to maintain readability
  margin: '0 auto',  // Center the box
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(1, 0),
}));

const CircularIcon = styled(Box)(({ theme }) => ({
  width: 32,
  height: 32,
  borderRadius: '50%',
  display: 'flex',
  color: '#f2721c',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#f0f0f0',
  marginRight: theme.spacing(2),
}));

const ActionButton = styled(IconButton)(({ theme }) => ({
  padding: 4,
  border: '1px solid #e0e0e0',
  color: '#f2721c',
  marginLeft: theme.spacing(1),
}));

const LoaderOverlay = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  backdrop-filter: blur(5px);
`;

const PopupOverlay = styledEmotion.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const PopupContent = styledEmotion.div`
  background-color: white;
  border-radius: 10px;
  width: 80%;
  max-width: 300px;
  overflow: hidden;
`;

const PopupHeader = styledEmotion.div`
  background-color: white;
  padding: 20px;
  text-align: center;
`;

const PopupIcon = styledEmotion.div`
  background-color: #d32f2f;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 20px;
`;

const PopupTitle = styledEmotion.h2`
  margin: 0;
  font-size: 18px;
  color: #333;
`;

const PopupButtons = styledEmotion.div`
  display: flex;
`;

const PopupButton = styledEmotion.button`
  flex: 1;
  padding: 15px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:first-of-type {
    background-color: #e67e22;
    color: white;
  }

  &:last-of-type {
    background-color: #c0392b;
    color: white;
  }

  &:hover {
    opacity: 0.9;
  }
`;

const StyledPasswordDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: '24px',
    width: '90%',
    maxWidth: '400px',
    margin: 0,
    padding: theme.spacing(4),
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
  },
}));

const LogoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: theme.spacing(4),
}));

const Logo = styled('img')({
  width: '120px',
  height: '120px',
});

const StyledPasswordTextField = styled(TextField)(({ theme }) => ({
  width: '100%',
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    '& fieldset': {
      border: 'none', 
    },
  },
  backgroundColor: '#F0F5FB',
  '& .MuiInputLabel-root': {
    fontSize: '16px',
    color: '#757575',
  },
  '& .MuiOutlinedInput-input': {
    padding: '12px 14px',
    fontSize: '13px',
  },
  marginBottom: theme.spacing(2),
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#e67e22',
  color: 'white',
  borderRadius: '12px',
  padding: '14px',
  fontSize: '18px',
  fontWeight: 'bold',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#d35400',
  },
}));

const MyProfile = () => {
  const [userData, setUserData] = useState(JSON.parse(localStorage.getItem("user")));
  const [addresses, setAddresses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
  const [passwordData, setPasswordData] = useState({ oldPassword: '', newPassword: '', confirmPassword: '' });
  const [deleteConfirmDialog, setDeleteConfirmDialog] = useState({ open: false, addressId: null, address: '' });
  const [openAddressDialog, setOpenAddressDialog] = useState(false);
  const [isUpdateAddressOpen, setIsUpdateAddressOpen] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [logo, setLogo] = useState(localStorage.getItem("system_logo"));


  const handleAddAddress = () => {
    setOpenAddressDialog(true);
  };

  const handleUpdateAddress = (address) => {
    setSelectedAddress(address);
    setIsUpdateAddressOpen(true);
  };

  // const handleAddAddress = () => {
  //   navigate('/add_address');
  // };

  const handleAddressAdded = () => {
    // Refresh the addresses list
    fetchAddresses();
  };


  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!userData) {
      AuthService.logout();
      navigate("/login");
    } else {
      fetchAddresses();
    }
  }, []);

  const fetchAddresses = async () => {
    setLoading(true);
    try {
      const response = await AddressService.getAddresses(userData._id);
      if (response.data.status) {
        setAddresses(response.data.data);
      } else {
        AlertService.swalErrorAlert(response.data.message || t("Error fetching addresses."));
      }
    } catch (error) {
      AlertService.swalErrorAlert(t("Error fetching addresses. Please try again."));
    } finally {
      setLoading(false);
    }
  };


  const handlePasswordChange = async () => {
    if (passwordData.newPassword !== passwordData.confirmPassword) {
      AlertService.swalErrorAlert(t("Passwords do not match."));
      return;
    }
    setLoading(true);
    try {
      const API_URL = process.env.REACT_APP_BASEURL;
      const payload = {
        functionName: 'UpdateCustomerPassword',
        postData: {
          _id: userData._id,
          old_password: passwordData.oldPassword,
          new_password: passwordData.newPassword,
        },
      };
      const headers = {
        'Content-Type': 'application/json',
        'token': localStorage.getItem("session-id")
      };
      const response = await axios.post(API_URL, payload, { headers });

      if (response.data.status) {
        AlertService.swalSuccessAlert(t("Password changed successfully."));
        setOpenPasswordDialog(false);
        setPasswordData({ oldPassword: '', newPassword: '', confirmPassword: '' });
      } else {
        AlertService.swalErrorAlert(response.data.message || t("Error changing password."));
      }
    } catch (error) {
      console.error("Error changing password:", error);
      AlertService.swalErrorAlert(t("Error changing password. Please try again."));
    } finally {
      setLoading(false);
    }
  };


  const handleEditAddress = (address) => {
    navigate(`/update_address`, { state: { address } });
  };

  const handleDeleteConfirm = (addressId, address) => {
    setDeleteConfirmDialog({ open: true, addressId, address });
  };

  const handleDeleteAddress = async () => {
    const { addressId } = deleteConfirmDialog;
    setDeleteConfirmDialog({ open: false, addressId: null, address: '' });

    try {
      const response = await AddressService.deleteAddress(addressId);
      if (response.data.status) {
        AlertService.swalSuccessAlert(t("Address deleted successfully."));
        fetchAddresses();
      } else {
        AlertService.swalErrorAlert(response.data.message || t("Error deleting address."));
      }
    } catch (error) {
      AlertService.swalErrorAlert(t("Error deleting address. Please try again."));
    }
  };


  const FooterItem = ({ icon, text, to, onClick }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const isActive = location.pathname === to;

    return (
      <Box
        onClick={() => {
          if (onClick) {
            onClick();
          } else if (to) {
            navigate(to);
          }
        }}
        sx={{
          textAlign: 'center',
          color: isActive ? '#f2721c' : '#888',
          fontSize: 'clamp(12px, 2vw, 14px)',
          cursor: 'pointer'
        }}
      >
        {icon}<br />{text}
      </Box>
    );
  };

  const User_Profile = () => {
    navigate('/User_Profile')
  };

  const CustomerTopup = () => {
    navigate('/CustomerTopup')
  };

  const CustomerRewards = () => {
    navigate('/CustomerRewards')
  };

  const CustomerOrders = () => {
    navigate('/CustomerOrders')
  };

  const CartView = () => {
    navigate('/CartView')
  };

  const handleEditProfile = () => {
    navigate('/edit_profile');
  };


  return (
    <div style={{ backgroundColor: "black", minHeight: "100vh", display: "flex", justifyContent: "center" }}>
      <div style={{ width: "100%", maxWidth: "1300px", backgroundColor: "white", position: "relative" }}>
        <IconButton sx={{ mt: 2, position: 'absolute', left: 16, top: 16 }} onClick={() => navigate(-1)}>
          <ArrowBack />
        </IconButton>
        <Typography variant="h6" align="center" sx={{ mt: 2, mb: 2, fontWeight: 'bold' }}>
          {t("Profile")}
        </Typography>
        <Container maxWidth="xs" sx={{ border: '1px solid #EFEFEF', pb: 7 }}>
          <Box sx={{ pt: 2, pb: 4 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
              <OrangeAvatar>
                {userData.name.charAt(0).toUpperCase()}
              </OrangeAvatar>
              <Box sx={{ ml: 2 }}>
                <Typography variant="h6">{userData.name}</Typography>
                <Typography variant="body2" color="text.secondary">{userData.email}</Typography>
              </Box>
            </Box>

            <SectionBox>
              <SectionTitle>
                {t("Personal Info")}
                <ActionButton onClick={handleEditProfile}>
                  <EditIcon fontSize="small" />
                </ActionButton>
              </SectionTitle>
              <List disablePadding>
                <StyledListItem>
                  <CircularIcon>
                    <Person fontSize="small" />
                  </CircularIcon>
                  <ListItemText primary={t("Full Name")} secondary={userData.name} />
                </StyledListItem>
                <StyledListItem>
                  <CircularIcon>
                    <Email fontSize="small" />
                  </CircularIcon>
                  <ListItemText primary={t("Email")} secondary={userData.email} />
                </StyledListItem>
                <StyledListItem>
                  <CircularIcon>
                    <Phone fontSize="small" />
                  </CircularIcon>
                  <ListItemText primary={t("Phone Number")} secondary={userData.phone} />
                </StyledListItem>
              </List>
            </SectionBox>

            <SectionBox sx={{
              mb: 3,
              mt: 3,
              padding: 2,
              borderRadius: '8px',
              boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
              backgroundColor: 'white'
            }} >
              <SectionTitle>
                {t("My Address")}
                <ActionButton onClick={handleAddAddress}>
                  <AddIcon fontSize="small" />
                </ActionButton>
              </SectionTitle>
              <List disablePadding>
                {addresses.map((address, index) => (
                  <React.Fragment key={address._id}>
                    <StyledListItem>
                      <CircularIcon>
                        <Home fontSize="small" />
                      </CircularIcon>
                      <ListItemText
                        primary={address.label}
                        secondary={`${address.unit}, ${address.block}, ${address.location}`}
                        secondaryTypographyProps={{ style: { fontSize: '0.75rem' } }}
                      />
                      <ActionButton onClick={() => handleUpdateAddress(address)}>
                        <EditIcon fontSize="small" />
                      </ActionButton>
                      <ActionButton onClick={() => handleDeleteConfirm(address._id, `${address.unit}, ${address.block}, ${address.location}`)}>
                        <DeleteIcon fontSize="small" />
                      </ActionButton>
                    </StyledListItem>
                    {index < addresses.length - 1 && <Divider />}
                  </React.Fragment>
                ))}
              </List>
            </SectionBox>

            <SectionBox sx={{
              mb: 3,
              padding: 2,
              borderRadius: '8px',
              boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
              backgroundColor: 'white'
            }} >
              <SectionTitle>
                {t("Change Password")}
                <ActionButton onClick={() => setOpenPasswordDialog(true)}>
                  <EditIcon fontSize="small" />
                </ActionButton>
              </SectionTitle>
            </SectionBox>
          </Box>
          {/* <PasswordResetPopup open={openPasswordDialog} handleClose={() => setOpenPasswordDialog(false)} /> */}

          <StyledPasswordDialog open={openPasswordDialog} onClose={() => setOpenPasswordDialog(false)}>
            <DialogContent>
              <LogoContainer>
                <Logo src={logo} alt="Logo" />
              </LogoContainer>

              <List disablePadding>
              <Typography sx={{ fontSize: '13px', fontWeight: 'bold' }} variant="h6">{t('Old Password')}</Typography>
                <StyledListItem>
                  <StyledPasswordTextField
                    fullWidth
                    name="oldPassword"
                    label={t("Old Password")}
                    type="password"
                    value={passwordData.oldPassword}
                    onChange={(e) => setPasswordData({ ...passwordData, oldPassword: e.target.value })}
                    placeholder={t("Enter Old Password")}
                  />
                </StyledListItem>
                <Typography sx={{ fontSize: '13px', fontWeight: 'bold' }} variant="h6">{t('New Password')}</Typography>
                <StyledListItem>
                  <StyledPasswordTextField
                    fullWidth
                    name="newPassword"
                    label={t("New Password")}
                    type="password"
                    value={passwordData.newPassword}
                    onChange={(e) => setPasswordData({ ...passwordData, newPassword: e.target.value })}
                    placeholder={t("Enter New Password")}
                  />
                </StyledListItem>
                <Typography sx={{ fontSize: '13px', fontWeight: 'bold' }} variant="h6">{t('Confirm Password')}</Typography>
                <StyledListItem>
                  <StyledPasswordTextField
                    fullWidth
                    name="confirmPassword"
                    label={t("Confirm Password")}
                    type="password"
                    value={passwordData.confirmPassword}
                    onChange={(e) => setPasswordData({ ...passwordData, confirmPassword: e.target.value })}
                    placeholder={t("Confirm Password")}
                  />
                </StyledListItem>
              </List>

              <SubmitButton fullWidth onClick={handlePasswordChange} disabled={loading}>
                {t("Submit")}
              </SubmitButton>
            </DialogContent>
          </StyledPasswordDialog>



          {deleteConfirmDialog.open && (
            <PopupOverlay>
              <PopupContent>
                <PopupHeader>
                  <PopupIcon>
                    <span style={{ color: 'white', fontSize: '30px' }}>?</span>
                  </PopupIcon>
                  <PopupTitle>{t("Remove address: {{address}}?", { address: deleteConfirmDialog.address })}</PopupTitle>
                </PopupHeader>
                <PopupButtons>
                  <PopupButton onClick={() => setDeleteConfirmDialog({ open: false, addressId: null, address: '' })}>No</PopupButton>
                  <PopupButton onClick={handleDeleteAddress}>Yes</PopupButton>
                </PopupButtons>
              </PopupContent>
            </PopupOverlay>
          )}
          <AddAddressForm
            key={addresses?.length}
            open={openAddressDialog}
            onClose={() => setOpenAddressDialog(false)}
            addresses={addresses}
            // onSelectAddress={(address) => {
            //   handleSelectAddress(address._id);
            //   handleCloseAddressDialog();
            // }}
            onAddNew={handleAddressAdded}
          />
          <UpdateAddressPopup
            open={isUpdateAddressOpen}
            onClose={() => setIsUpdateAddressOpen(false)}
            address={selectedAddress}
            onUpdateAddress={(updatedAddress) => {
              fetchAddresses();
            }}
          />



          {/* <AddAddressForm
            // key={addressesList?.length}
            // addresses={addressesList}
            open={openAddressDialog}
            onClose={() => setOpenAddressDialog(false)}
            // onAddressAdded={handleAddressAdded}
            onSelectAddress={(address) => {
              // handleSelectAddress(address._id);
              // handleCloseAddressDialog();
            }}
            onAddNew={handleAddressAdded}
          /> */}

          <footer className="slide-up" style={{
            position: 'fixed',
            bottom: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            width: '100%',
            maxWidth: "1300px",
            display: 'flex',
            justifyContent: 'space-around',
            backgroundColor: 'white',
            padding: '10px',
            boxShadow: '0 -2px 5px rgba(0,0,0,0.1)'
          }}>
            <FooterItem icon="🎁" text="Rewards" onClick={() => { CustomerRewards() }} />
            <FooterItem icon="🛒" text="Cart" onClick={() => { CartView() }} />
            <FooterItem icon="🏠" text="Home" to="/Store" />
            <FooterItem icon="📋" text="Orders" onClick={() => { CustomerOrders() }} />
            <FooterItem icon="👤" text="Profile" to="/User_Profile" onClick={() => { User_Profile() }} />
          </footer>
        </Container>
      </div>
    </div>

  );
};

export default MyProfile;