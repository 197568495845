import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate,NavLink } from 'react-router-dom';
import { Row, Col, CardBody, Card } from "reactstrap";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import DataTable from 'react-data-table-component';
import DiscountCouponService from "../DiscountCoupon/Discountcoupons.service";
import AuthService from "../Authentication/auth.service";
import Alerts from "../../components/Alerts/alerts";
import SettlementReportFilter from "../../components/Table Filters/SattlementReportFilter";
import PermissionAccess from '../../PermissionAccess/Access.js';
import moment from 'moment';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { format, subDays } from 'date-fns';

const SattlementReport = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filterBusiness, setFilterBusiness] = useState('');
    const [filterDate, setFilterDate] = useState('');
    const [pageLimit, setPageLimit] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [pageSums, setPageSums] = useState({});
    const { authData, super_admin, current_module } = PermissionAccess();
    const [dateRange, setDateRange] = useState({ from: '', to: '' });

    const [summaryData, setSummaryData] = useState({
        TotalAmount: 0,
        TotalDeliveryCharges: 0,
        TotalDiscount: 0,
        TotalShare: 0
    });

    useEffect(() => {
        i18n.changeLanguage(localStorage.getItem('language'));

        // Set initial date range for the last 30 days
        const toDate = new Date();
        const fromDate = subDays(toDate, 30);
        const initialDateRange = {
            from: format(fromDate, 'yyyy/MM/dd'),
            to: format(toDate, 'yyyy/MM/dd')
        };
        setDateRange(initialDateRange);
        setFilterDate(`${initialDateRange.from} - ${initialDateRange.to}`);

        fetchOrders(currentPage, pageLimit, filterBusiness, `${initialDateRange.from} - ${initialDateRange.to}`);
    }, [])

    const fetchOrders = (page_index, page_limit, business = filterBusiness, date = filterDate) => {
        setLoading(true);
        const stored_user = authData.userData;
        let businessIDs = []
        if (stored_user.businesses) {
            businessIDs = stored_user.businesses
        }
        DiscountCouponService.getSettlementReport(authData.sessionID, page_index, page_limit, business, date, businessIDs).then(
            (response) => {
                setLoading(false);
                if (response.data.message === "Unauthorized") {
                    AuthService.logout();
                    navigate("/login");
                    window.location.reload();
                } else {
                    setOrders(response.data.data);
                    setTotalRows(response.data.count.count);
                    setPageSums(response.data.pageSums);
                    setSummaryData({
                        TotalAmount: response.data.count.TotalAmount,
                        TotalDeliveryCharges: response.data.count.TotalDeliveryCharges,
                        TotalDiscount: response.data.count.TotalDiscount,
                        TotalShare: response.data.count.TotalShare
                    });
                }
            },
            (error) => {
                setLoading(false);
                const errContent =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
                Alerts.swalErrorAlert(errContent || t("Server Error, Please try again later."));
            }
        );
    }

    const customStyles = {

        footer: {
            style: {
                fontWeight: 'bold',
                fontSize: '20px',
                color: '#202124',
                backgroundColor: '#f1f1f1',
                borderTopStyle: 'solid',
                borderTopWidth: '1px',
                borderTopColor: '#E0E0E0',
            }
        }
    };

    const mycolumns = [
        {
            name: t('Date'),
            selector: row => row.order_date,
            width: "14%",
            sortable: true,
            cell: row => row.order_date === 'Total' ?
                <span style={{ fontWeight: 'bold', fontSize: '17px' }}>Total</span> :
                moment(row.order_date).format('DD-MM-YYYY hh:mm:ss A')
        },
        {
            name: t('Customer Name'),
            selector: row => {
                const name = row.user_details?.name || '';
                return `${name}`;
            },
            width: "15%",
            sortable: true,
        },
        {
            name: t('Order ID'),
            selector: "order_number",
            width: "10%",
            sortable: true,
            cell: row => {
                if (row.user_details?.name === t('Total')) {
                    // Footer row: render as plain text
                    return row.order_number;
                } else {
                    // Regular row: render as NavLink
                    return (
                        <NavLink 
                            to={`/order-details/${row._id}`} 
                            className="text-primary"
                            style={{ textDecoration: 'none' }}
                        >
                            {row.order_number}
                        </NavLink>
                    );
                }
            }
        },
        {
            name: t('Sub Total'),
            selector: row => {
                const subTotal = row.Calculations?.subTotal;
                return subTotal !== undefined ? parseFloat(subTotal).toFixed(2) : t('N/A');
            },
            width: "10%",
            sortable: true,
        },
        {
            name: t('Delivery Charges'),
            selector: row => {
                const deliveryTipFee = row.Calculations?.deliveryTipFee;
                return deliveryTipFee !== undefined ? parseFloat(deliveryTipFee).toFixed(2) : t('N/A');
            },
            width: "12%",
            sortable: true,
        },
        {
            name: t('Discount'),
            selector: row => {
                const discount = row.Calculations?.discount;
                return discount !== undefined ? parseFloat(discount).toFixed(2) : t('N/A');
            },
            width: "10%",
            sortable: true,
        },
        {
            name: t('Tax'),
            selector: row => {
                const sst = row.Calculations?.sst;
                return sst !== undefined ? parseFloat(sst).toFixed(2) : t('N/A');
            },
            width: "10%",
            sortable: true,
        },
        {
            name: t('Total'),
            selector: row => {
                const grandTotal = row.Calculations?.grandTotal;
                return grandTotal !== undefined ? parseFloat(grandTotal).toFixed(2) : t('N/A');
            },
            width: "9%",
            sortable: true,
        },
        {
            name: t('Commission'),
            selector: row => row.syslab_share,
            format: row => row.syslab_share !== undefined ? parseFloat(row.syslab_share).toFixed(2) : t('N/A'),
            width: "10%",
            sortable: true,
        }
    ];

    const footerRow = {
        order_date: 'Total',
        user_details: { name: '' },
        order_number: pageSums.Orders,
        Calculations: {
            subTotal: pageSums.SubTotal?.toFixed(2) || '0.00',
            deliveryTipFee: pageSums.TotalDeliveryCharges?.toFixed(2) || '0.00',
            discount: pageSums.TotalDiscount?.toFixed(2) || '0.00',
            sst: pageSums.Tax?.toFixed(2) || '0.00',
            grandTotal: pageSums.GrandTotal?.toFixed(2) || '0.00'
        },
        syslab_share: pageSums.TotalShare?.toFixed(2) || '0.00'
    };

    const paginatedOrders = [
        ...orders,
        footerRow
    ];

    const handleSearch = (business, date) => {
        setFilterBusiness(business);
        setFilterDate(date);
        if (date) {
            const [from, to] = date.split(' - ');
            setDateRange({ from, to });
        }
        fetchOrders(0, pageLimit, business, date);
        setCurrentPage(0);
    };

    const handleClear = () => {
        setFilterBusiness('');
        setFilterDate('');
        setDateRange({ from: '', to: '' });
        fetchOrders(0, pageLimit, '', '');
        setCurrentPage(0);
    };

    const subHeaderComponentMemo = React.useMemo(() => {
        return (
            <SettlementReportFilter
                onSearch={handleSearch}
                onClear={handleClear}
                filterBusiness={filterBusiness}
                filterDate={filterDate}
            />
        );
    }, [filterBusiness, filterDate]);

    const handlePageChange = page => {
        setCurrentPage(page - 1);  // Adjust for 0-based index
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPageLimit(newPerPage);
        setCurrentPage(page - 1);  // Adjust for 0-based index
    };


    const SummaryCard = ({ title, value, color, icon }) => (
        <Card className="mb-3" style={{ backgroundColor: color, color: 'white' }}>
            <CardBody>
                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <h6>{title}</h6>
                        <h3>{value.toFixed(2)}</h3>
                    </div>
                    <i className={`pe-7s-${icon} fa-2x`} />
                </div>
            </CardBody>
        </Card>
    );


    const generateCSV = () => {
        // Headers
        const dateRangeStr = dateRange.from && dateRange.to
            ? `(${dateRange.from} - ${dateRange.to})`
            : '';
        let csvContent = `Settlement Report ${dateRangeStr}\n`;
        csvContent += "Date,Customer Name,ORDER ID,Sub total,Delivery Charges,Discount,Tax,Total,Commission\n";


        // Data rows
        paginatedOrders.forEach(row => {
            if (row.order_date === 'Total') {
                csvContent += `TOTAL,,${pageSums.Orders},${row.Calculations.subTotal},${row.Calculations.deliveryTipFee},${row.Calculations.discount},${row.Calculations.sst},${row.Calculations.grandTotal},${row.syslab_share}\n`;
            } else {
                csvContent += `${moment(row.order_date).format('DD/MM/YYYY')},${row.user_details.name},${row.order_number},${row.Calculations.subTotal},${row.Calculations.deliveryTipFee},${row.Calculations.discount},${row.Calculations.sst},${row.Calculations.grandTotal},${row.syslab_share}\n`;
            }
        });

        return csvContent;
    };

    const downloadCSV = () => {
        const csvContent = generateCSV();
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const dateRangeStr = dateRange.from && dateRange.to
            ? `${dateRange.from.replace(/\//g, '-')}_${dateRange.to.replace(/\//g, '-')}`
            : '';
        saveAs(blob, `settlement_report_${dateRangeStr}.csv`);
    };

    const downloadPDF = () => {
        const doc = new jsPDF();
        const pageWidth = doc.internal.pageSize.width;

        // Add white background for the heading
        doc.setFillColor(255, 255, 255);
        doc.rect(0, 0, pageWidth, 20, 'F');

        // Add centered black text for the heading with date range
        doc.setTextColor(0, 0, 0);
        doc.setFontSize(16);
        const dateRangeStr = dateRange.from && dateRange.to
            ? ` (${dateRange.from} - ${dateRange.to})`
            : '';
        const text = `Settlement Report${dateRangeStr}`;
        const textWidth = doc.getStringUnitWidth(text) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        const textX = (pageWidth - textWidth) / 2;
        doc.text(text, textX, 15);

        const tableColumn = ["Date", "Customer Name", "ORDER ID", "Sub total", "Delivery Charges", "Discount", "Tax", "Total", "Commission"];
        const tableRows = [];

        paginatedOrders.forEach(row => {
            const rowData = [
                row.order_date === 'Total' ? 'TOTAL' : moment(row.order_date).format('DD/MM/YYYY'),
                row.user_details?.name || '',
                row.order_number,
                row.Calculations?.subTotal,
                row.Calculations?.deliveryTipFee,
                row.Calculations?.discount,
                row.Calculations?.sst,
                row.Calculations?.grandTotal,
                row.syslab_share
            ];
            tableRows.push(rowData);
        });

        doc.autoTable(tableColumn, tableRows, {
            startY: 25,
            headStyles: { fillColor: [0, 0, 0], textColor: [255, 255, 255] },
            alternateRowStyles: { fillColor: [245, 245, 245] }
        });

        doc.save("settlement_report.pdf");
    };

    return (
        <Fragment>
            <div className="app-main__outer">
                <div className="app-main__inner">
                    <div className="bg-light-gray bg-animation">
                        <div className="d-flex justify-content-center align-items-center">
                            <Col md="12" className="mx-auto app-login-box">
                                <div className="app-page-title">
                                    <div className="page-title-wrapper">
                                        <div className="page-title-heading fw-bold">
                                            <div className="page-title-icon">
                                                <i className="pe-7s-news-paper icon-gradient bg-tempting-azure" />
                                            </div>
                                            <div>
                                                {`${t("Settlement Report")}`}
                                                <div className="page-title-subheading">
                                                    {`${t("Settlement Report Filters")}`}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="page-title-actions"></div>
                                    </div>
                                </div>
                                <Row>
                                    <LoadingOverlay
                                        tag="div"
                                        active={loading}
                                        styles={{ overlay: (base) => ({ ...base }) }}
                                        spinner={<DNALoader />}
                                    >
                                        <Col md="12">
                                            <Card className="main-card mb-3">
                                                <CardBody>
                                                    <Col md="12" className="mb-3">
                                                        {subHeaderComponentMemo}
                                                    </Col>

                                                    {/* Summary Cards */}
                                                    <Row className="mb-3">
                                                        <Col md="3">
                                                            <Card className="mb-3" style={{ backgroundColor: "#ff6b6b", color: 'white' }}>
                                                                <CardBody>
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <div>
                                                                            <h6>{t("Total Amount")}</h6>
                                                                            <h3>{summaryData.TotalAmount.toFixed(2)}</h3>
                                                                        </div>
                                                                        <i className="pe-7s-wallet fa-2x" />
                                                                    </div>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                        <Col md="3">
                                                            <Card className="mb-3" style={{ backgroundColor: "#4ecdc4", color: 'white' }}>
                                                                <CardBody>
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <div>
                                                                            <h6>{t("Delivery Charges")}</h6>
                                                                            <h3>{summaryData.TotalDeliveryCharges.toFixed(2)}</h3>
                                                                        </div>
                                                                        <i className="pe-7s-car fa-2x" />
                                                                    </div>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                        <Col md="3">
                                                            <Card className="mb-3" style={{ backgroundColor: "#45aaf2", color: 'white' }}>
                                                                <CardBody>
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <div>
                                                                            <h6>{t("Total Discount")}</h6>
                                                                            <h3>{summaryData.TotalDiscount.toFixed(2)}</h3>
                                                                        </div>
                                                                        <i className="pe-7s-gift fa-2x" />
                                                                    </div>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                        <Col md="3">
                                                            <Card className="mb-3" style={{ backgroundColor: "#feca57", color: 'white' }}>
                                                                <CardBody>
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <div>
                                                                            <h6>{t("Total Share")}</h6>
                                                                            <h3>{summaryData.TotalShare.toFixed(2)}</h3>
                                                                        </div>
                                                                        <i className="pe-7s-users fa-2x" />
                                                                    </div>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    </Row>

                                                    {/* DataTable */}
                                                    <DataTable
                                                        data={paginatedOrders}
                                                        columns={mycolumns}
                                                        pagination
                                                        fixedHeader
                                                        fixedHeaderScrollHeight="400px"
                                                        subHeader
                                                        subHeaderComponent={
                                                            <div>

                                                                {!super_admin ? current_module?.PrintCSV === 1 &&
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-custom-color btn-hover-shine me-3 btn"
                                                                        onClick={downloadCSV}
                                                                    >
                                                                        {t('CSV')}
                                                                    </button>
                                                                    :
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-custom-color btn-hover-shine me-3 btn"
                                                                        onClick={downloadCSV}
                                                                    >
                                                                        {t('CSV')}
                                                                    </button>
                                                                }


                                                                {!super_admin ? current_module?.PrintPDF === 1 &&
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-custom-color btn-hover-shine me-3 btn"
                                                                        onClick={downloadPDF}
                                                                    >
                                                                        {t('PDF')}
                                                                    </button>
                                                                    :
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-custom-color btn-hover-shine me-3 btn"
                                                                        onClick={downloadPDF}
                                                                    >
                                                                        {t('PDF')}
                                                                    </button>
                                                                }

                                                            </div>
                                                        }
                                                        paginationServer
                                                        paginationTotalRows={totalRows}
                                                        onChangePage={handlePageChange}
                                                        onChangeRowsPerPage={handlePerRowsChange}
                                                        paginationResetDefaultPage={resetPaginationToggle}
                                                        customStyles={customStyles}
                                                    />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </LoadingOverlay>
                                </Row>
                            </Col>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default SattlementReport;