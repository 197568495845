import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const TermsAndConditions = ({ onClose }) => {
    const styles = {
        container: {
            padding: '20px',
            maxWidth: '800px',
            margin: '0 auto',
            fontFamily: 'Arial, sans-serif',
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: '20px',
            borderBottom: '1px solid #eee',
            paddingBottom: '10px',
        },
        backButton: {
            background: 'none',
            border: 'none',
            cursor: 'pointer',
            fontSize: '24px',
            marginRight: '20px',
        },
        title: {
            fontSize: '24px',
            fontWeight: 'bold',
        },
        section: {
            marginBottom: '20px',
        },
        sectionTitle: {
            fontSize: '18px',
            fontWeight: 'bold',
            marginBottom: '10px',
        },
        paragraph: {
            marginBottom: '10px',
            lineHeight: '1.6',
        },
        list: {
            marginLeft: '20px',
            marginBottom: '10px',
        },
        listItem: {
            marginBottom: '5px',
        },
    };

    return (
        <div style={styles.container}>
            <div style={styles.header}>
                <h1 style={styles.title}>Terms & Conditions</h1>
            </div>

            <div style={styles.section}>
                <p style={styles.paragraph}>WELCOME TO JomSmart.</p>
                <p style={styles.paragraph}>PLEASE READ THE FOLLOWING TERMS OF USE THOROUGHLY AND CAREFULLY. By using and / or browsing the Website, you agree that you have read, understood and agree to be bound by these Terms of Use.</p>
                <p style={styles.paragraph}>If you do not agree with these Terms of Use, in while or in part, please discontinue using and / or browsing our Website immediately.</p>
                <p style={styles.paragraph}>JomSmart MARKETPLACE SDN BHD (1350246-U) is a company incorporated under Malaysian law (JomSmart, "we" or "us"), operating the Website.</p>
                <p style={styles.paragraph}>These Terms of Use shall be deemed to include our Privacy Policy and any Additional Policies. We may assign or change any part or parts of our rights under these Terms of Use without your consent or prior notification. The Terms of Use and Privacy Policy constitute a legally-binding agreement between us and you.</p>
                <p style={styles.paragraph}>You acknowledge that the Website functions as an online platform for User to connect with BIZs for the purposes of transacting to respectively receive and provide Services. JomSmart is not itself a BIZ or a User.</p>
                <p style={styles.paragraph}>By using or participating in the Website, you represent, warrant and undertake that:</p>
                <p style={styles.paragraph}>JomSmart hereby grants you a non-exclusive, revocable license to use the Website and Mobile Application as set forth in these Terms of Use, provided however that:</p>
                <p style={styles.paragraph}>Without prejudice to the Privacy Policy, you acknowledge and agree that JomSmart does not guarantee any confidentiality with respect to any Description Of Product And Service by BIZ, including without limitation, your Profile or information conveyed, posted or shared by you, that is hosted and / or published on the Website.</p>
                <p style={styles.paragraph}>JomSmart reserves the right, in its sole discretion, to change, modify or otherwise amend the Terms of Use and any other documents incorporated by reference at any time. It is your responsibility to review the Terms of Use for any changes. Your use of the Website following any amendment of the Terms of Use will signify your assent to and acceptance of any revised Terms of Use. If you do not agree to abide by these or any future Terms of Use, please do not use or access the Website.</p>
                <p style={styles.paragraph}>is your personal decision, for which you alone are responsible. You understand that JomSmart does not and cannot make representations as to the suitability of any user you may decide to interact with on or through the Website and/or the accuracy or suitability of any advice, information, or recommendations made by any user.</p>
                <p style={styles.paragraph}>The Terms of Use, together with the Privacy Policy and any other legal notices or Additional Policies published by JomSmart on the Website, shall constitute the entire agreement between you and JomSmart concerning the Website. If any provision of the Terms of Use is deemed invalid by a court of competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of the Terms of Use, which shall remain in full force and effect.</p>
                <p style={styles.paragraph}>These Terms of Use shall be governed by the laws of Malaysia and you agree to submit to the jurisdiction of the courts of Malaysia in the event of any claims or disputes.</p>
                <p style={styles.paragraph}>Without prejudice to the Privacy Policy, you acknowledge and agree that JomSmart does not guarantee any confidentiality with respect to any Description Of Product And Service by BIZ, including without limitation, your Profile or information conveyed, posted or shared by you, that is hosted and / or published on the Website.</p>
                <p style={styles.paragraph}>JomSmart reserves the right, in its sole discretion, to change, modify or otherwise amend the Terms of Use and any other documents incorporated by reference at any time. It is your responsibility to review the Terms of Use for any changes. Your use of the Website following any amendment of the Terms of Use will signify your assent to and acceptance of any revised Terms of Use. If you do not agree to abide by these or any future Terms of Use, please do not use or access the Website.</p>
                <p style={styles.paragraph}>The Terms of Use, together with the Privacy Policy and any other legal notices or Additional Policies published by JomSmart on the Website, shall constitute the entire agreement between you and JomSmart concerning the Website. If any provision of the Terms of Use is deemed invalid by a court of competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of the Terms of Use, which shall remain in full force and effect.</p>
                <p style={styles.paragraph}>These Terms of Use shall be governed by the laws of Malaysia and you agree to submit to the jurisdiction of the courts of Malaysia in the event of any claims or disputes.</p>
            </div>

            <div style={styles.section}>
                <h2 style={styles.sectionTitle}>1. Acceptance</h2>
                <p style={styles.paragraph}>
                    By using the Website, you agree to be bound by these Terms of Use, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this Website. The materials contained in this Website are protected by applicable copyright and trademark law.
                </p>
            </div>

            <div style={styles.section}>
                <h2 style={styles.sectionTitle}>2. Definitions</h2>
                <ol style={styles.list}>
                    <li style={styles.listItem}>"Account" means a registered account which you may be required to create if you wish to access and utilise the full facilities and features of the Website, whether as a User or BIZ;</li>
                    <li style={styles.listItem}>"Additional Policies" means any and all other operating rules, policies, and / or guidelines in addition to the Terms of Use, that may govern the use of the Website and which is made known to you from time to time;</li>
                    <li style={styles.listItem}>"Data" is defined in Clause 14.1;</li>
                    <li style={styles.listItem}>"JomSmart Service" means the facility provided by the Website including but not limited to (i) accepting User requests and disseminating them to BIZs; (ii) facilitating BIZs to render quotations to User; (iii) facilitating User and BIZs to make contact; (iv) maintaining profiles for BIZs; and (v) any other ancillary or related services;</li>
                    <li style={styles.listItem}>"Marks" is defined in Clause 14.1;</li>
                    <li style={styles.listItem}>"Password" means the password which you may be required to create, provide and use in order to create and use an Account;</li>
                    <li style={styles.listItem}>"Product & Service" means the product and professional service offered and / or to be rendered by the BIZ in the course of his business to a User;</li>
                    <li style={styles.listItem}>"User" means any individual or company that has (i) used or browsed the Website; and / or (ii) submitted a request of purchase for product or Service offered by BIZ, via the Website or Mobile Application.</li>
                    <li style={styles.listItem}>"BIZ" means any individual or company that has signed up to offer their Product And Service to the general public and Users via the Website or Mobile Application;</li>
                    <li style={styles.listItem}>"Description Of Product And Service" is defined in Clause 7.1;</li>
                    <li style={styles.listItem}>"Terms of Use" refer to these Terms of Use, Privacy Policy and any Additional Policies as may be modified from time to time;</li>
                    <li style={styles.listItem}>"user", "you" and "your", as applicable, means a person who accesses, uses, and/or participates in the Website in any manner, whether as a "User" or "BIZ"; and</li>
                    <li style={styles.listItem}>"Website" means (i) the websites bearing the domain www.JomSmart.com and any other related microsites and / or mobile or web applications owned and operated by JomSmart which may or may not be of similar names; and (ii) any related or ancillary facilities or functions such as e-mails, SMS, newsletters, notifications or other communication or content.</li>
                </ol>
            </div>

            <div style={styles.section}>
                <h2 style={styles.sectionTitle}>3. Function and Purpose</h2>
                <p style={styles.paragraph}>
                    The Website functions as an online platform for User to connect with BIZs for the purposes of transacting to respectively receive and provide Services. JomSmart is not itself a BIZ or a User.
                </p>
            </div>

            <div style={styles.section}>
                <h2 style={styles.sectionTitle}>4. User Representation</h2>
                <p style={styles.paragraph}>
                    By using or participating in the Website, you represent, warrant and undertake that:
                </p>
                <ol style={styles.list}>
                    <li style={styles.listItem}>you are at least eighteen (18) years of age;</li>
                    <li style={styles.listItem}>you are or intend to be either a BIZ or User;</li>
                    <li style={styles.listItem}>you have the authority to enter into and accept these Terms of Use;</li>
                    <li style={styles.listItem}>all registration or other information that you are required to submit in order to gain full use of the Website for your intended purpose as a BIZ or User is true and accurate and that you will maintain the accuracy of such information;</li>
                    <li style={styles.listItem}>your use of the Website and Services offered through this Website do not violate any applicable law or regulation which you are subject to; and</li>
                    <li style={styles.listItem}>you agree to make payment, if required, for any Product and Services requested through the use of the Website or Mobile Application(if any)</li>
                </ol>
            </div>

            <div style={styles.section}>
                <h2 style={styles.sectionTitle}>5. License to Access</h2>
                <p style={styles.paragraph}>
                    JomSmart hereby grants you a non-exclusive, revocable license to use the Website and Mobile Application as set forth in these Terms of Use, provided however that:
                </p>
                <ol style={styles.list}>
                    <li style={styles.listItem}>you will not copy, distribute, or make derivative works of the Website and Mobile Application in any medium without JomSmart's prior written consent;</li>
                    <li style={styles.listItem}>you will not alter or modify any part of the Website other than as may be reasonably necessary to use the Website for its intended purposes; and</li>
                    <li style={styles.listItem}>you will otherwise act in accordance with these Terms of Use and with all applicable laws.</li>
                </ol>
            </div>


            <div style={styles.section}>
                <h2 style={styles.sectionTitle}>6. Account</h2>
                <ol style={styles.list}>
                    <li style={styles.listItem}>You may generally use and browse the Website without registering or providing your personal details. However, in order to access and utilise the full facilities and features of the Website, you may be required to create an Account. To create an Account, you must provide and submit your name, email address and / or create a Password. You may also provide additional optional information at your discretion, which is not required to create an Account but which will help JomSmart to provide you with a more customised experience when using the Website.</li>
                    <li style={styles.listItem}>You are solely responsible for safeguarding your Password and shall keep your Password secure at all times. You shall be solely responsible for all activity that occurs on your Account and you shall notify JomSmart immediately of any breach of security or any unauthorised use of your Account. Similarly, you shall never use another person's Account without permission. You agree that you will not misrepresent yourself or represent yourself as another user of the Website and/or the Services offered through the Website. You hereby acknowledge and agree that JomSmart will not be liable for your losses caused by an unauthorised use of your Account. Notwithstanding, you may be liable for the losses of JomSmart or others due to such unauthorised use.</li>
                    <li style={styles.listItem}>In addition and in connection with the use of your Account, you acknowledge and agree that:
                        <ol style={styles.list}>
                            <li style={styles.listItem}>You will not copy or distribute any part of the Website in any medium without JomSmart's prior written authorisation;</li>
                            <li style={styles.listItem}>You will not alter or modify any part of the Website other than as may be reasonably necessary to use the Website for its intended purpose;</li>
                            <li style={styles.listItem}>You will provide accurate and complete information when creating your Account;</li>
                            <li style={styles.listItem}>You shall not use any automated system, including but not limited to, "robots," "spiders," "offline readers," "scrapers," etc., to access the Website for any purpose without JomSmart's prior written approval;</li>
                            <li style={styles.listItem}>You shall not in any manual or automated manner collect BIZ or User information, including but not limited to, names, addresses, phone numbers, or email addresses, copying copyrighted text, or otherwise misuse or misappropriate Website information or content, including but not limited to, use on a "mirrored", competitive, or third party site;</li>
                            <li style={styles.listItem}>You shall not in any way that transmits more request messages to the JomSmart servers, or any server of a JomSmart subsidiary or affiliate, in a given period of time than a human can reasonably produce in the same period by using a conventional online web browser; provided, however, that the operators of public search engines may use spiders or robots to copy materials from the site for the sole purpose of creating publicly available searchable indices of the materials, but not caches or archives of such material. JomSmart reserves the right to revoke these exceptions either generally or in specific cases;</li>
                            <li style={styles.listItem}>You shall not recruit, solicit, or contact via any medium, any BIZ or User for employment or contracting for a business not affiliated with JomSmart without prior, written authorisation from JomSmart;</li>
                            <li style={styles.listItem}>You shall not take any action that (i) unreasonably encumbers or, in JomSmart's sole discretion, may unreasonably encumber the Website's infrastructure; (ii) interferes or attempts to interfere with the proper working of the Website or any third-party participation in the Website; or (iii) bypasses JomSmart's measures that are used to prevent or restrict access to the Website; and</li>
                            <li style={styles.listItem}>You agree not to collect or harvest any personally identifiable data, including without limitation, names or other Account information, from the Website, nor to use the communication systems provided by the Website for any commercial solicitation purposes</li>
                        </ol>
                    </li>
                </ol>
            </div>

            <div style={styles.section}>
                <h2 style={styles.sectionTitle}>7. Your Description Of Product And Service – Prohibitions, Representations and Warranties</h2>
                <ol style={styles.list}>
                    <li style={styles.listItem}>You shall not upload, post, transmit, transfer, disseminate, distribute, or facilitate distribution of any content, including text, images, video, sound, data, information, or software, to any part of the Website including but not limited to (i) your profile; (ii) the posting of your Product Or Service; (iii) the posting of your desired Product Or Service; or (iv) the posting of any opinions or reviews in connection with the Website, the Product, the Service, the BIZ, or the User (collectively referred to as "Description Of Product And Service") that
                        <ol style={styles.list}>
                            <li style={styles.listItem}>misrepresents the source of anything you post, including impersonation of another individual or entity of any false or inaccurate biographical information for any BIZ, provides or create links to external sites that violate this Terms of Use, is intended to harm or exploit any individual in any way or is designed to solicit, or collect personally identifiable information of any person without his or her express consent;</li>
                            <li style={styles.listItem}>invades anyone's privacy by attempting to harvest, collect or otherwise utilize or publish any of their information without their knowledge and willing consent;</li>
                            <li style={styles.listItem}>contains falsehoods or misrepresentations that could damage JomSmart or any third party affiliated with JomSmart;</li>
                            <li style={styles.listItem}>is pornographic, harassing, hateful, illegal, obscene, defamatory, libelous, slanderous, threatening, discriminatory, racially, culturally, or ethnically offensive; incites, advocates, or expresses pornography, obscenity, vulgarity, profanity, hatred, bigotry, racism or gratuitous violence, encourages conduct that would be considered a criminal offence, give rise to civil liability or violate any law, promotes racism, hatred or physical harm of any kind against any group or individual, contains nudity, violence or inappropriate subject matter, or is otherwise inappropriate;</li>
                            <li style={styles.listItem}>is in whole or part copyrighted, protected trade secret or otherwise subject to third-party proprietary rights, including privacy and publicity rights, unless you are the owner of such rights or have permission from the rightful owner to post the material and to grant JomSmart all of the license rights granted therein;</li>
                            <li style={styles.listItem}>is intended to threaten, stalk, defame, defraud, degrade, victimise, or intimidate an individual or group of individuals for any reason or to incite or encourage anyone else to do so;</li>
                            <li style={styles.listItem}>intends to harm or disrupt another user's computer or would allow others to illegally access software or bypass security on websites or servers through any means;</li>
                            <li style={styles.listItem}>advertises or solicits a business not related to or appropriate for the Website (as determined by JomSmart in its sole discretion);</li>
                            <li style={styles.listItem}>contains or could be considered "junk mail", "spam", "chain letters", "pyramid schemes", "affiliate marketing", or unsolicited commercial advertisement;</li>
                            <li style={styles.listItem}>contains advertising for ponzi schemes, discount cards, credit counseling, online surveys or online contests;</li>
                            <li style={styles.listItem}>distributes or contains viruses or any other technologies that may harm JomSmart, or the interests or property of JomSmart users;</li>
                            <li style={styles.listItem}>contains links to commercial services or websites, except as allowed pursuant to the Terms of Use;</li>
                            <li style={styles.listItem}>is non-local or irrelevant content; or</li>
                            <li style={styles.listItem}>contains identical content to other open Postings you have already posted, or uses any form of automated device or computer programme that enables the submission of Postings without the express written consent of JomSmart.</li>
                        </ol>
                    </li>
                    <li style={styles.listItem}>You will not send messages to other users whom you have made contact with via or through the Website containing:
                        <ol style={styles.list}>
                            <li style={styles.listItem}>offers to make national or international money transfers for amounts exceeding the asking price of a service, with intent to request a refund of any portion of the payment; or</li>
                            <li style={styles.listItem}>unsolicited advertising or marketing of a service that is (i) not offered on the Website; or (ii) is offered on an external website.</li>
                        </ol>
                    </li>
                    <li style={styles.listItem}>While using the Website, you shall not:
                        <ol style={styles.list}>
                            <li style={styles.listItem}>post content or items in any inappropriate category or areas on the Website;</li>
                            <li style={styles.listItem}>violate any laws, third-party rights, Account Policies, or any provision of the Terms of Use, such as the prohibitions described above;</li>
                            <li style={styles.listItem}>fail to deliver payment for Product and Services purchased by you, unless the BIZ has materially changed the description of the Product and Service after you negotiate an agreement for such Product or Service, a clear typographical error is made, or you cannot authenticate the BIZ identity;</li>
                            <li style={styles.listItem}>fail to perform, produce, and deliver Product and Services purchased from you, unless the User fails to materially meet the terms of the mutually agreed upon;</li>
                            <li style={styles.listItem}>circumvent or manipulate any fee structure that we may have or implement;</li>
                            <li style={styles.listItem}>post false, inaccurate, misleading, defamatory, or libelous content (including personal information about any Website user);</li>
                            <li style={styles.listItem}>take any action that may undermine any feature that JomSmart has or may come up with for the purposes of the Website; or</li>
                            <li style={styles.listItem}>leave any untrue or inappropriate feedback about a BIZ and you shall at all times use careful and good judgment in leaving any feedback</li>
                        </ol>
                    </li>
                    <li style={styles.listItem}>You are solely and fully responsible for your own Description Of Product and Service and the consequences of posting or publishing it. You hereby affirm, represent, and/or warrant that:
                        <ol style={styles.list}>
                            <li style={styles.listItem}>you own or have the necessary licenses, rights, consents, and permissions to use and authorize JomSmart to use all patent, trademark, trade secret, copyright or other proprietary rights in and to any and all Description Of Product And Service;</li>
                            <li style={styles.listItem}>you have the written consent, release, and/or permission of each and every identifiable individual person in the Description Of Product And Service to use the name or likeness of each and every such identifiable individual person; and</li>
                            <li style={styles.listItem}>you agree to pay for all royalties, fees, and any other monies owing any person by reason of any Description Of Product And Service posted by you to or through the Website and Mobile Application.</li>
                        </ol>
                    </li>
                    <li style={styles.listItem}>You retain all of your ownership rights in your Description Of Product And Service. You hereby grant, and you represent and warrant that you have the right to grant to JomSmart a perpetual, worldwide, non-exclusive, royalty-free, sublicenseable and transferable license to link to, use, reproduce, distribute, reformat, translate, prepare derivative works of, display, and perform the Description Of Product And Service in connection with the Website, Mobile Application and JomSmart (and its successor's) business operations, including without limitation, for the promotion and redistribution of any part or all of the Website (and any derivative works) in any media formats and through any media channels.</li>
                    <li style={styles.listItem}>You also hereby grant each user of the Website a non-exclusive license to access your Description Of Product And Service through the Website, and to read and use, such Description Of Product And Service as permitted through the functionality of the Website and under these Terms of Use</li>
                </ol>
            </div>

            <div style={styles.section}>
                <h2 style={styles.sectionTitle}>8. JomSmart - Disclaimers and Right to Remove Description Of Product And Service</h2>
                <ol style={styles.list}>
                    <li style={styles.listItem}>JomSmart does not endorse any Description Of Product And Service or any opinion, recommendation, or advice provided by any users and JomSmart expressly disclaims any and all liability in connection with all Description Of Product And Service.</li>
                    <li style={styles.listItem}>JomSmart does not permit copyright infringing activities and infringement of intellectual property rights on the Website, and JomSmart will remove any Data or Description Of Product And Service in its sole discretion, upon being notified or having reason to believe that an infringement has occurred, without prior notice to a user who has or is suspected to have conducted such infringement. JomSmart may take any action or steps it deems fit in its sole discretion against such infringer.</li>
                    <li style={styles.listItem}>JomSmart reserves the right in its sole and absolute discretion to decide whether any Data or Description Of Product And Service is appropriate and complies with these Terms of Use for any and all violations.</li>
                    <li style={styles.listItem}>You acknowledge and understand that when using the Website, you will be exposed to Description Of Product And Service:
                        <ol style={styles.list}>
                            <li style={styles.listItem}>from a variety of sources and that JomSmart is not responsible for the accuracy, usefulness, safety, or intellectual property rights of or relating to such Description Of Product And Service; and</li>
                            <li style={styles.listItem}>that is or may be inaccurate, offensive, indecent, or objectionable, and you agree to and hereby waive any legal or equitable rights or remedies you have or may have against JomSmart, and agree to indemnify and hold JomSmart, its owners, members, managers, operators, directors, officers, agents, affiliates, and/or licensors, harmless to the fullest extent allowed by law regarding all matters related to your use of the Website or Mobile Application.</li>
                        </ol>
                    </li>
                    <li style={styles.listItem}>You are solely responsible for the photos, profiles and other content, including, without limitation, Description Of Product And Service, that you publish or display on or through the Website or and Mobile Application, or transmit to other Website users. You understand and agree that JomSmart may, in its sole discretion and without incurring any liability, review and delete or remove any Description Of Product And Service that violates this Terms of Use or which might be offensive, illegal, or that might violate the rights, harm, or threaten the safety of Website users or others.</li>
                </ol>
            </div>


            <div style={styles.section}>
                <h2 style={styles.sectionTitle}>9. BIZ</h2>
                <ol style={styles.list}>
                    <li style={styles.listItem}>If you are an individual using the Website and / or have register for an Account as a BIZ, you represent and warrant that you have the authority to bind the individual and / or company that is responsible for and operates the business as a BIZ and your acceptance of the Terms of Use will be deemed an acceptance by that BIZ.</li>
                    <li style={styles.listItem}>By signing up, registering or creating an Account on the Website or Mobile Application as a BIZ, you agree and undertake to comply with any requests for information which we may make for the purpose of verifying the legality, legitimacy, conduct and proper establishment of your (i) business, company or operations; and / or (ii) provision of your Service.</li>
                    <li style={styles.listItem}>BIZ shall not:
                        <ol style={styles.list}>
                            <li style={styles.listItem}>register or list on the Website in a category of Product Or Service which they do not offer or that is irrelevant to or inappropriate for the Product Or Service they are offering;</li>
                            <li style={styles.listItem}>misrepresent their location(s) at which they will provide a Product Or Service;</li>
                            <li style={styles.listItem}>include third party brand names or other inappropriate keywords in their Profile, title or any posting, messages or submission made in or through the Website;</li>
                            <li style={styles.listItem}>use misleading titles, words or phrases that do not accurately describe the Product Or Service they provide;</li>
                            <li style={styles.listItem}>include any information in their Profile that is fraudulent or otherwise incorrect;</li>
                            <li style={styles.listItem}>do anything to circumvent or avoid any fees that JomSmart may charge;</li>
                            <li style={styles.listItem}>conduct fraud or misconduct on any promotions or partnerships which JomSmart may conduct;</li>
                            <li style={styles.listItem}>use their Profile or the Website for promoting (i) products or goods which violate the terms of use; (ii) Product or Services which are not offered by the BIZ through the Website; (iii)Product or Services which are not a recognised category on the Website or other Medium by JomSmart; (iv) any website, service, product, party or anything not directly related to their Product Or Service or otherwise prohibited by JomSmart in its sole discretion from time to time; or (iv) any Product or Services that may be prohibited under law;</li>
                            <li style={styles.listItem}>solicit User or any person to pay or do anything not specifically permitted by these Terms of Use or by JomSmart as it may in its sole discretion determine from time to time;</li>
                            <li style={styles.listItem}>offer or solicit any Product Or Service or do anything that contravenes these Terms of Use, the Privacy Policy or any laws.</li>
                        </ol>
                    </li>
                </ol>
            </div>

            <div style={styles.section}>
                <h2 style={styles.sectionTitle}>10. User</h2>
                <ol style={styles.list}>
                    <li style={styles.listItem}>User shall not:
                        <ol style={styles.list}>
                            <li style={styles.listItem}>commit to purchasing or using a Product Or Service with no intention of paying for such Product Or Service;</li>
                            <li style={styles.listItem}>sign up, request or negotiate a price for, use, purchase or otherwise solicit a Service (i) with no genuine intention as at the time of such conduct of following through with your use of or payment for the Product Or Service; (ii) for the purposes of using pricing, quotations or other information received in doing so for commercial or competitive purposes, business or market intelligence purposes or general surveying; or (iii) any other non-personal use;</li>
                            <li style={styles.listItem}>request for or agree to purchase a Product and Service when (i) you know, have reason to believe or have been put on notice that you do not meet the BIZ terms; or (ii) with the intention of causing disruption, committing fraud or other tortious or illegal purpose.</li>
                        </ol>
                    </li>
                    <li style={styles.listItem}>As a User, you are encouraged to refer to our Terms Of Use and to exercise reasonable and independent judgement, discretion and care in deciding to purchase and liaise with a BIZ.</li>
                </ol>
            </div>

            <div style={styles.section}>
                <h2 style={styles.sectionTitle}>11. No Confidentiality</h2>
                <p style={styles.paragraph}>
                    Except for information necessary to place an order or as otherwise specifically provided for in our Privacy Policy, any material, information, suggestions, ideas, concepts, know-how, techniques, questions, comments or other communication you transmit or post to the Website or JomSmart in any manner ("User Communications") is and will be considered non-confidential and non-proprietary. JomSmart may use any or all User Communications for any purpose whatsoever, including, without limitation, reproduction, transmission, disclosure, publication, broadcast, development, manufacturing and/or marketing in any manner whatsoever for any or all commercial or non-commercial purposes. JomSmart may, but is not obligated to, monitor or review any User Communications. JomSmart shall have no obligations to use, return, review, or respond to any User Communications. JomSmart will have no liability related to the content of any such User Communications, whether or not arising under the laws of copyright, libel, privacy, obscenity, or otherwise. JomSmart retains the right to remove any or all User Communications that includes any material JomSmart deems inappropriate or unacceptable.
                </p>
            </div>

            <div style={styles.section}>
                <h2 style={styles.sectionTitle}>12. Modifications to Terms of Use or Privacy Policy</h2>
                <p style={styles.paragraph}>
                    JomSmart reserves the right to change these Terms of Use or Privacy Policy at any time in its sole discretion and to impose new or additional terms or conditions on your use of the Website. Such modifications and additional terms and conditions will be effective immediately and incorporated into these Terms of Use or Privacy Policy. Your continued use of the Website will be deemed to constitute your acceptance of any and all such additional terms and conditions.
                </p>
            </div>

            <div style={styles.section}>
                <h2 style={styles.sectionTitle}>13. Termination</h2>
                <ol style={styles.list}>
                    <li style={styles.listItem}>JomSmart reserves the right at any time and from time to time to modify or discontinue, temporarily or permanently, the Website or any part thereof with or without notice and in its sole discretion. You agree that JomSmart shall not be liable to you or to any third party for any modification, suspension or discontinuance of JomSmart's services.</li>
                    <li style={styles.listItem}>You hereby acknowledge and agree that JomSmart in its sole and absolute discretion has the right but not the obligation to delete, terminate, or deactivate your Account, block your email or IP address, cancel the Website or otherwise terminate your access to or use of the Website or any part thereof, immediately and without notice for any reason, including without limitation, account inactivity or if JomSmart believes that you have violated these Terms of Use.</li>
                </ol>
            </div>
            <div style={styles.section}>
                <h2 style={styles.sectionTitle}>14. Intellectual Property Rights</h2>
                <ol style={styles.list}>
                    <li style={styles.listItem}>The content on the Website (exclusive of all Description Of Product And Service), including without limitation, the text, software, scripts, graphics, photos, sounds, music, videos, interactive features and the like ("Data") and the trademarks, service marks and logos contained therein ("Marks"), are owned by JomSmart or a related corporations of JomSmart ("Group"), subject to copyright and other intellectual property rights under applicable laws and international conventions. Such Data and / or Marks are or may become protected by copyright, trademark, patent, trade secret and other laws, and JomSmart or the Group owns and retains all rights in such Data and Marks. All other trademarks, names and logos on this Website are the property of their respective owners.</li>
                    <li style={styles.listItem}>Data on the Website is provided to you for your information and personal use only and may not be used, copied, reproduced, distributed, transmitted, broadcast, displayed, sold, licensed, or otherwise exploited for any other purposes whatsoever without the prior written consent of the respective owners.</li>
                    <li style={styles.listItem}>JomSmart or the Group reserves all rights not expressly granted in and to the Website and the Data. You agree not to use, copy, or distribute, any of the Data other than as expressly permitted herein, including any use, copying, or distribution of Description Of Product And Service obtained through the Website for any commercial purposes. If you download or print a copy of the Data for personal use, you must retain all copyright and other proprietary notices contained thereon. You agree not to circumvent, disable or otherwise interfere with security features of the Website or features that prevent or restrict use or copying of any Data or enforce limitations on use of the Website or the Data therein.</li>
                </ol>
            </div>

            <div style={styles.section}>
                <h2 style={styles.sectionTitle}>15. Disputes</h2>
                <ol style={styles.list}>
                    <li style={styles.listItem}>Your interactions with other users, whether individuals, companies and/or organizations and whether BIZ or User, found on or through the Website, including payment of and performance of any Services, and any other terms, conditions, warranties or representations associated with such transactions or dealings, are solely between you and such third party or user. You should take reasonable precautions and conduct any necessary investigation or inquiries you deem necessary or appropriate before proceeding to hire, pay or transact with such third party or user for any purpose whatsoever.</li>
                    <li style={styles.listItem}>You understand and acknowledge that deciding whether to:
                        <ol style={styles.nestedList}>
                            <li style={styles.listItem}>Use/purchase/buy the Products or Services of a BIZ</li>
                            <li style={styles.listItem}>Sell/provide Products or Services to a User; or</li>
                            <li style={styles.listItem}>use information contained in any Description Of Product And Service</li>
                        </ol>
                    </li>
                    <li style={styles.listItem}>In the event of any dispute between you and a User, BIZ or any other third party or user in relation to the Website, such dispute shall be resolved between you and such User, BIZ or any third party or user, and JomSmart shall not have or hold any responsibility, liability or obligation in relation thereof.</li>
                    <li style={styles.listItem}>NOTWITHSTANDING THE FOREGOING, YOU AGREE THAT JomSmart SHALL NOT BE RESPONSIBLE OR LIABLE FOR ANY LOSS OR DAMAGE OF ANY SORT WHATSOEVER INCURRED AS THE RESULT OF ANY SUCH TRANSACTION OR DEALINGS. IF THERE IS A DISPUTE BETWEEN USERS ON THE WEBSITE, OR BETWEEN USERS AND ANY THIRD PARTY, YOU ACKNOWLEDGE AND AGREE THAT JomSmart IS UNDER NO OBLIGATION TO BE INVOLVED. IN THE EVENT THAT A DISPUTE ARISES BETWEEN YOU AND ONE OR MORE USERS OR ANY THIRD PARTY, YOU HEREBY RELEASE JomSmart, ITS OFFICERS, MANAGERS, MEMBERS, DIRECTORS, EMPLOYEES, ATTORNEYS, AGENTS, AND SUCCESSORS IN RIGHTS FROM ANY CLAIMS, DEMANDS, AND DAMAGES (ACTUAL AND CONSEQUENTIAL) OF EVERY KIND OR NATURE, KNOWN OR UNKNOWN, SUSPECTED OR UNSUSPECTED, FORESEEABLE OR UNFORESEEABLE, DISCLOSED OR UNDISCLOSED, ARISING OUT OF OR IN ANY WAY RELATED TO SUCH DISPUTES AND/OR THE WEBSITE OR ANY SERVICE PROVIDED THEREUNDER.</li>
                </ol>
            </div>
        </div>
    );
};

export default TermsAndConditions;