import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Typography, Button, IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { GoogleLogin } from 'react-google-login';

import AuthService from "./auth.service";
import AlertService from "../../components/Alerts/alerts";
import ConfigurationService from "../Configuration/configuration.service";
import googleLogo from '../../assets/googleLogo.png';

const Login = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState(localStorage.getItem("system_logo"));
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [googleClientId, setGoogleClientId] = useState('');

  useEffect(() => {
    getSetLanguage();
    getSystemConfig();
  }, []);

  function getSetLanguage() {
    const language = localStorage.getItem("language") || 'en';
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
  }

  function getSystemConfig() {
    ConfigurationService.getConfiguration().then((response) => {
      if (response.data.status) {
        setColors(response.data.data[0].buttons_color, '--buttons-color');
        setColors(response.data.data[0].topbar_color, '--topbar-color');
        setColors(response.data.data[0].leftbar_color, '--leftbar-color');
        setColors(response.data.data[0].buttons_text_color, '--buttons-text-color');
        setSystemLogo(response.data.data[0].system_logo);
        setGoogleClientId(response.data.data[0].google_client_id);
        localStorage.setItem("google_api_key", response.data.data[0].google_api_key);
      } else {
        setColors('#3ac47d', 'onerror');
      }
    }).catch(() => {
      setColors('#3ac47d', 'onerror');
    });
  }

  function setColors(color, param) {
    localStorage.setItem(param.slice(2), color);
    document.documentElement.style.setProperty(param, color);
  }

  function setSystemLogo(sys_logo) {
    setLogo(sys_logo);
    localStorage.setItem("system_logo", sys_logo);
  }

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    AuthService.login(email, password, 'customers').then((response) => {
      setLoading(false);
      AlertService.successToast("Login Successful.")
      navigate("/Store");
      window.location.reload();
    }).catch((error) => {
      setLoading(false);
      AlertService.swalErrorAlert(t('Invalid Credentials'))
    });
  };

  const onGoogleSuccess = (response) => {
    const { tokenId } = response;
    AuthService.googleLogin(tokenId).then((response) => {
      AlertService.successToast("Google Login Successful.")
      navigate("/Store");
      window.location.reload();
    }).catch((error) => {
      AlertService.swalErrorAlert(t('Google Login Failed'))
    });
  };

  const onGoogleFailure = (error) => {
    console.error("Google Sign-In Error:", error);
    AlertService.swalErrorAlert(t('Google Sign-In Failed'));
  };

  return (
    <div style={{ backgroundColor: "black", minHeight: "100vh", display: "flex", justifyContent: "center" }}>
      <div style={{ width: "100%", maxWidth: "1300px", backgroundColor: "white", position: "relative" }}>
        <div style={{ backgroundColor: "white", minHeight: "50vh", display: "flex", justifyContent: "center", paddingTop: "50px" }}>
          <div style={{
            width: '100%',
            maxWidth: '500px',
            backgroundColor: '#fff',
            padding: '30px',
            borderRadius: '15px',
            boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
            position: 'relative'
          }}>
            <div style={{ position: 'absolute', top: '20px', left: '20px' }}>
              <IconButton onClick={() => navigate(-1)}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            {/* Logo */}
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
              <img
                src={logo}
                alt="IPH"
                style={{ width: '80px', height: '80px', borderRadius: '50%' }}
              />
            </div>

            {/* Form */}
            <form onSubmit={onSubmit}>
              {/* Email Input */}
              <div style={{ marginBottom: '20px' }}>
                <Typography variant="body1" style={{ marginBottom: '5px', color: '#9897A1' }}>Email</Typography>
                <input
                  type="email"
                  placeholder="Your Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  style={{
                    width: '100%',
                    padding: '15px',
                    color:'#A2A1AA',
                    fontSize: '16px',
                    border: '1px solid #EEEEEE',
                    borderRadius: '8px',
                    backgroundColor: '#FEFEFE'
                  }}
                />
              </div>

              <div style={{ marginBottom: '20px', position: 'relative' }}>
                <Typography variant="body1" style={{ marginBottom: '5px', color: '#9897A1' }}>Password</Typography>
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  style={{
                    width: '100%',
                    color:'#A2A1AA',
                    padding: '15px',
                    fontSize: '16px',
                    border: '1px solid #EEEEEE',
                    borderRadius: '8px',
                    backgroundColor: '#FEFEFE',
                    paddingRight: '50px' 
                  }}
                />
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  style={{
                    position: 'absolute',
                    right: '5px',
                    top: '40px',
                    color: '#666',
                  }}
                >
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </div>

              {/* Forgot Password */}
              <div style={{ textAlign: 'right', marginBottom: '20px' }}>
                <span
                  style={{ color: '#F2731C', cursor: 'pointer' }}
                  onClick={() => navigate("/customer_forgot_password")}
                >
                  Forgot Password?
                </span>
              </div>

              {/* Login Button */}
              <Button
                type="submit"
                fullWidth
                style={{
                  backgroundColor: '#F2731C',
                  color: '#fff',
                  padding: '15px',
                  fontSize: '16px',
                  borderRadius: '8px',
                  textTransform: 'none',
                  marginBottom: '20px'
                }}
              >
                LOGIN
              </Button>
            </form>

            {/* Sign Up Redirect */}
            <Typography variant="body2" align="center" style={{ marginBottom: '20px' }}>
              Don't have an account?{' '}
              <span
                style={{ color: '#F2731C', cursor: 'pointer' }}
                onClick={() => navigate("/customer_register")}
              >
                Sign Up
              </span>
            </Typography>

            {/* Google Sign-in */}
            {/* <GoogleLogin
              clientId={'AIzaSyDlKtLygdyr4XpjVE1WcyR7yHuigyq_agw'}
              render={renderProps => ( */}
                <Button
                  // onClick={renderProps.onClick}
                  // disabled={renderProps.disabled}
                  style={{
                    backgroundColor: '#4285F4',
                    color: '#fff',
                    cursor:'pointer',
                    padding: '10px',
                    fontSize: '16px',
                    borderRadius: '8px',
                    textTransform: 'none',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%'
                  }}
                >
                  <img
                    src={googleLogo}
                    alt="Google"
                    style={{ marginRight: '10px', width: '20px', height: '20px' }}
                  />
                  Sign in With Google
                </Button>
              {/* )}
              onSuccess={onGoogleSuccess}
              onFailure={onGoogleFailure}
              cookiePolicy={'single_host_origin'}
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;