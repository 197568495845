import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import { useNavigate, Link } from 'react-router-dom';
import { Popover } from "@mui/material";
import {
  Row,
  Col,
  CardBody,
  Card,
  Label,
  Input
} from "reactstrap";
import { Form, Field } from "react-final-form";
import $ from 'jquery';
import moment from 'moment';

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import "../../../assets/custom css/loadoverlay.scss";
import { Button, Modal, FormGroup, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import Alerts from "../../../components/Alerts/alerts";

import CustomerService from "./Customer.service";
import AuthService from "../../Authentication/auth.service";

import DataTable from 'react-data-table-component';
import { Tooltip } from "@mui/material";

import CustomerFilter from "../../../components/Table Filters/InvoiceFilter/CustomerFilter4.js";
import AlertService from "../../../components/Alerts/alerts";



const CustomersList = () => {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [pdfModalIsOpen, setPDFModalIsOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [filterInvoice_StartDate, setFilterInvoice_StartDate] = useState('');
  const [filterInvoicdue_date, setFilterInvoicdue_date] = useState('');
  const [filterDue_StartDate, setFilterDue_StartDate] = useState('');
  const [filterDudue_date, setFilterDudue_date] = useState('');
  const [search_text, setSearchText] = useState('');
  const [modal, setModal] = useState(false);

  const [selectedRows, setSelectedRows] = useState([]);

  const handleSelectedRowsChange = (state) => {
    console.log(state.selectableRows)
    console.log(state)
    setSelectedRows(state.selectedRows);
  };




  const [loading, setLoading] = useState(false);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [PaymentMethods, setPaymentMethods] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState("");
  const [searchName, setsearchName] = useState('');
  const [searchRegNo, setsearchRegNo] = useState('');
  const [searchEmail, setsearchEmail] = useState('');
  const [filterText1, setFilterText1] = useState('');
  const [filterText2, setFilterText2] = useState('');
  const [filterText3, setFilterText3] = useState('');
  const [Rows, setRow] = useState('');

  let payment_types = localStorage.getItem('payment_types')?.split(',')
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchCustomers(0, pageLimit);
    fetchPaymentMethods();

  }, [pageLimit]);
  function setFilterTextFtn(itemrec, param) {
    console.log(itemrec)
    console.log('Value of key1:', itemrec);
    if (param === 'users') {
      setFilterText1(itemrec);
    }
    if (param === 'orders') {
      setFilterText2(itemrec);
    }
  };



  function checkenterkey(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      setResetPaginationToggle(!resetPaginationToggle);
      var page = 0
      handlePageChange(page++)
    }
  };


  function fetchPaymentMethods() {
    setLoading(true);
    CustomerService.getAllPaymentMethods().then((response) => {
      setLoading(false);
      console.log("Get Customers response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        console.log(response.data);
        setPaymentMethods(response.data.data);
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  function fetchCustomers(page_index, page_limit, filterText1, filterText2,
    filterInvoice_StartDate, filterInvoicdue_date,
    filterDue_StartDate, filterDudue_date, search_text) {
    var filter = {};
    const userString = localStorage.getItem('user');
    const user = JSON.parse(userString);
    const userId = user['_id'];
    filter["customer_id"] = userId
    if (search_text) {
      console.log("search_text");
      filter["search_text"] = search_text
    }
    if (filterText2) {
      console.log("filterText1");
      filter["status"] = filterText2
    }
    if (filterDue_StartDate) {
      console.log("filterDue_StartDate");
      filter["filterDue_StartDate"] = filterDue_StartDate
    } if (filterDudue_date) {
      console.log("filterDudue_date");
      filter["filterDudue_date"] = filterDudue_date
    } if (filterInvoice_StartDate) {
      console.log("filterInvoice_StartDate");
      filter["filterInvoice_StartDate"] = filterInvoice_StartDate
    } if (filterInvoicdue_date) {
      filter["filterInvoicdue_date"] = filterInvoicdue_date
    }
    var offset = (page_index - 1) * page_limit;
    if (offset < 1) {
      offset = 0;
    }

    setLoading(true);
    CustomerService.getAllInvoices(offset, page_limit, userId,
      filterText2, filterInvoice_StartDate, filterInvoicdue_date, filterDue_StartDate, filterDudue_date, search_text).then((response) => {
        setLoading(false);
        console.log("Get Customers response:-", response)
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        }
        else {
          console.log(response.data);
          setCustomers(response.data.data);
          setTotalRows(response.data.count.count);
        }
      },
        (error) => {
          setLoading(false);
          const errContent =
            (error.response && error.response.data) || error.message || error.toString();
          console.log("Net:-", errContent)
          if (errContent) {
            Alerts.swalErrorAlert(errContent)
          }
          else {
            Alerts.swalErrorAlert(t("Server Error, Please try again later."))
          }
        })
  };
  function handleClear(item) {
    if (item === 'users') {
      $("#colfilter").val('');
      setFilterText1('');
      setFilterText2('');
      setResetPaginationToggle(!resetPaginationToggle);
      var page = 0
      handlePageChange(page++)
    }
    if (item === 'action') {
      // $("#modfilter").val('');
      $("#actfilter").val('');
      setFilterText2('');
      setResetPaginationToggle(!resetPaginationToggle);
      var page = 0
      handlePageChange(page++)
    }
    if (item === 'function') {
      $("#funcfilter").val('');
      setFilterText3('');
      setResetPaginationToggle(!resetPaginationToggle);
      var page = 0
      handlePageChange(page++)
    }
  };

  function handlePageChange(page_index) {
    console.log("Page index:-", page_index)
    setCurrentPage(page_index)

    fetchCustomers(page_index, pageLimit);
  }

  async function handlePerRowsChange(newPageLimit, page_index) {
    setPageLimit(newPageLimit);
  }

  function nameFilter(e) {
    console.log(e.target.value);
    setsearchName(e.target.value)
  }
  function regFilter(e) {
    console.log(e.target.value);
    setsearchRegNo(e.target.value)
  }
  function emailFilter(e) {
    console.log(e.target.value);
    setsearchEmail(e.target.value)
  }


  function resetFilters() {
    setResetPaginationToggle(!resetPaginationToggle);
    setFilterText1('');
    setSearchText('');
    setFilterInvoice_StartDate('');
    setFilterInvoicdue_date('');
    setFilterDue_StartDate('');
    setFilterDudue_date('');
    setFilterText2('');
    fetchCustomers(0, pageLimit);

  }

  function searchCustomers() {
    setResetPaginationToggle(!resetPaginationToggle);
    const page = 0;

    // Filter values
    const filteredText1 = filterText1 || "";
    const filteredText2 = filterText2 || "";
    const FilterInvoice_StartDate1 = filterInvoice_StartDate || "";
    const FilterInvoicdue_date2 = filterInvoicdue_date || "";
    const FilterDue_StartDate3 = filterDue_StartDate || "";
    const Search_text = search_text || "";
    const FilterDudue_date4 = filterDudue_date || "";
    console.log(FilterDue_StartDate3)
    console.log(FilterDudue_date4)
    fetchCustomers(0, pageLimit, filteredText1, filteredText2, FilterInvoice_StartDate1,
      FilterInvoicdue_date2, FilterDue_StartDate3, FilterDudue_date4, Search_text);
  }


  function confirmDeleteCustomer(_id, order_no, status) {
    if (status === 'generated') {
      let msg = t("Once deleted, you will not be able to recover this Invoice!")
      Alerts.swalConfirmDeleteAlert(msg).then(res => {
        console.log("Resp:-", res)
        if (res === true) {
          sendDelete(_id, order_no)
        }
      })
    } else {
      AlertService.swalErrorAlert("Invoice status should be Generated")
    }
  };


  function sendDelete(id, order_no) {
    setLoading(true);
    CustomerService.deleteInvoice(id, order_no).then((response) => {
      setLoading(false);
      console.log("Delete customer response:-", response)
      if (response.data.status) {
        fetchCustomers(currentPage, pageLimit)
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {

          console.log(response);
          setLoading(false);
          Alerts.swalSuccessAlert(t("Invoice Deleted Successfully."))
        }
        console.log(response.data.message)
      }
      else {
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't delete the customer at the moment."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Delete operation failed"))
      })
  };

  const [popoverAnchors, setPopoverAnchors] = useState({});

  const handleClick = (event, row) => {
    setPopoverAnchors({ ...popoverAnchors, [row._id]: event.currentTarget });
  };

  const handleClose = (row) => {
    setPopoverAnchors({ ...popoverAnchors, [row._id]: null });
  };

  const openPopover = (row) => Boolean(popoverAnchors[row._id]);
  const getPopoverId = (row) => (openPopover(row) ? `payment-popover-${row._id}` : undefined);



  const mycolumns = [

    {
      name: "Invoice Date",
      selector: "invoice_date",
      width: "12%",
      sortable: true,
      cell: (row) => format(parseISO(row.invoice_date), 'dd-MM-yyyy'),
    },
    {
      name: "Invoice Number",
      width: "12%",
      selector: "invoice_number",
      sortable: true,
    },
    {
      name: t('Status'),
      width: '11%',
      selector: (row) => {
        return (
          <div>
            {row.status === "partial paid" ?
              <button type="button" style={{ borderColor: '#F39026', backgroundColor: '#F39026' }}
                className="btn btn-success btn-hover-shine me-3 btn">{row.status}
              </button>
              :
              row.status === "cancelled" ?
                <button type="button"
                  className="btn btn-danger btn-hover-shine me-3 btn">{row.status}
                </button>
                :
                row.status === "generated" ?
                  <button type="button" style={{ borderColor: '#1BD35A', backgroundColor: '#1BD35A' }}
                    className="btn btn-c btn-hover-shine me-3 btn">{row.status}
                  </button>
                  :
                  row.status === "paid" ?
                    <button type="button"
                      className="btn btn-success btn-hover-shine me-3 btn">{row.status}
                    </button>

                    :
                    row.status === "unpaid" ?
                      <button type="button" style={{ borderColor: '#609FFC', backgroundColor: '#609FFC' }}
                        className="btn btn-info btn-hover-shine me-3 btn">{row.status}
                      </button>
                      :
                      <button type="button"
                        className="btn btn-default btn-hover-shine me-3 btn">{row.status}
                      </button>
            }
          </div>
        );
      }
    },

    {
      name: "Due Date",
      width: "9%",
      selector: "due_date",
      sortable: true,
      cell: (row) => format(parseISO(row.due_date), 'dd-MM-yyyy'),
    },
    {
      name: "Amount",
      width: "15%",
      sortable: true,
      selector: (row) => (
        <div >{`${row.currency}${parseFloat(row.total_amount).toFixed(2)}`} </div>
      )
    },
    {
      name: "Remaining Amount",
      sortable: true,
      selector: (row) => {
        if (row.status !== 'paid') {
          return (
            <div>
              {row.remaining_amount ? `${row.currency}${parseFloat(row.remaining_amount).toFixed(2)}` : `${row.currency}${parseFloat(row.total_amount).toFixed(2)}`}
            </div>
          );
        } else {
          return (
            <div>
              {`${row.currency}-0`}
            </div>
          );
        }
      }
    },
    {
      name: 'Actions',
      cell: (row) => (
        <div>
          <button className="mt-2 mb-2 me-2 btn-icon btn">
            {/* {payment_types.includes("partial_payment") ?
              <Tooltip placement="top" title="Make Payment" arrow>
                <i onClick={() => handlePartialPayment(row)} className="pe-7s-credit text-primary btn-icon-wrapper"></i>
              </Tooltip>
              :
             
            } */}
            <Tooltip placement="top" title="Make Payment" arrow>
              <i onClick={() => handleInvoicePayments(row)} className="pe-7s-credit text-primary btn-icon-wrapper"></i>
            </Tooltip>
            {/* <Tooltip placement="top" title="Make Payment" arrow>
              <i onClick={(event) => handleClick(event, row)} className="pe-7s-credit text-primary btn-icon-wrapper"></i>
            </Tooltip>
            <Popover
              id={getPopoverId(row)}
              open={openPopover(row)}
              anchorEl={popoverAnchors[row._id]}
              onClose={() => handleClose(row)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column", gap: "10px", padding: "10px" }}>
                {PaymentMethods.map((method) => (
                  method.status === "Active" &&
                  <Button key={method.id} onClick={() => handlePaymentMethods(method, row)}>
                    {method.payment_method}
                  </Button>
                ))}
              </div>
            </Popover> */}

            <Tooltip placement="top" title="Send Email" arrow>
              <i onClick={() => handleMail(row)} className="pe-7s-mail text-primary btn-icon-wrapper"></i>
            </Tooltip>

            <Tooltip placement="top" title="View PDF" arrow>
              <i onClick={() => handleViewPDF(row._id)} className="pe-7s-look text-primary btn-icon-wrapper"> </i>
            </Tooltip>
            <Tooltip placement="top" title="Download PDF" arrow>
              <i onClick={() => handlePDF(row._id)} className="pe-7s-download text-success btn-icon-wrapper text-info"> </i>
            </Tooltip>
          </button>
        </div>
      ),
    }
  ];
  const downloadPDF = (response) => {
    console.log("PDF Data Length: ", response.data.length);
    console.log("Partial PDF Data: ", response.data.slice(0, 100));
    const pdfBlob = new Blob([response.data], { type: 'application/pdf' });

    // Create a download link and trigger the download
    const url = window.URL.createObjectURL(pdfBlob);
    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.download = 'invoice.pdf'; // Set the desired file name here.
    downloadLink.style.display = 'none';

    document.body.appendChild(downloadLink);
    downloadLink.click();

    // Clean up by removing the link and revoking the URL
    document.body.removeChild(downloadLink);
    window.URL.revokeObjectURL(url);
  };

  const handlePDF = (_id) => {
    var getData = {
      functionName: "Get_PDF_Invoice",
      _id: _id
    }
    setLoading(true);
    CustomerService.getInvoice_PDF(getData).then((response) => {
      console.log(response);
      const pre = document.createElement('pre');
      pre.textContent = response.data;
      document.body.appendChild(pre);
      setLoading(false);
      if (response.status === 200) {

        downloadPDF(response);
      } else if (response.status === 401) {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      } else {
        // Handle other error cases, if necessary
        console.log("Response status: " + response.status);
        // Display an error message or perform other actions as needed.
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent);
        if (errContent) {
          Alerts.swalErrorAlert(errContent);
        } else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."));
        }
      })
  }
  const handlePayments = (row, method) => {
    if (row.status !== 'paid') {
      method.is_admin = false;
      navigate('/add-payment', { state: { row, check: false, method } });
    } else {
      AlertService.swalErrorAlert("Invoice Already Paid Successfully")
    }

  }
  const handleInvoicePayments = (row) => {
    if (row.status !== 'paid') {
      row.is_admin = false;
      navigate('/add-payment', { state: { row, check: false } });
    } else {
      AlertService.swalErrorAlert("Invoice Already Paid Successfully")
    }

  }
  const handleOnlinePayment = (row) => {
    if (row.status !== 'paid') {
      console.log(row)
      var getData = {
        functionName: "OnlinePayment",
        row: row,
        invoice_id: row._id,
        amount: row.total_amount,
        invoice_number: row.invoice_number
      }
      console.log("getData", getData);
      setLoading(true);
      CustomerService.OnlinePayment(getData).then((response) => {
        console.log(response);
        setLoading(false);
        if (response.status === 200) {
          console.log(response);
          window.open(response.data.data, '_blank');
          console.log('Response : ', response);
        } else if (response.status === 401) {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        } else {
          window.open(response.data.data, '_blank');
        }
      },
        (error) => {
          setLoading(false);
          const errContent =
            (error.response && error.response.data) || error.message || error.toString();
          console.log("Net:-", errContent);
          if (errContent) {
            Alerts.swalErrorAlert(errContent);
          } else {
            Alerts.swalErrorAlert(t("Server Error, Please try again later."));
          }
        })
    } else {
      AlertService.swalErrorAlert("Invoice Already Paid Successfully")
    }

  }
  const handlePartialPayment = (row) => {
    console.log(row)
    if (row.status !== 'paid') {
      setRow(row);
      toggleModal();
    } else {
      AlertService.swalErrorAlert("Invoice Already Paid Successfully")
    }

  }


  const handlePaymentMethods = (method, row) => {
    console.log("row", row);
    if (method.payment_method === "Online Payment") {
      if (payment_types.includes("partial_payment")) {
        handlePartialPayment(row);
      } else {
        handleOnlinePayment(row);
      }
    } else {
      // handlePayments(row, method)
      handleInvoicePayments(row);
    }
  }

  const handleEdit = (row, check) => {
    console.log(row);
    if (row.status !== 'paid' && row.status !== 'partial paid') {
      navigate('/update-invoice', {
        state: {
          state: row,
          check: check
        }
      });
    } else {
      AlertService.swalErrorAlert("Paid Invoice isn't Changeable")
    }

  }


  function handleMail(row) {
    let msg = t("Are you sure you want to send Invoice in Email?")
    Alerts.swalConfirmGeneralAlert(msg).then(res => {
      console.log("Resp:-", res)
      if (res === true) {
        sendMail(row)
      }
    })
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  function pay_selected(selectedRows) {
    if (selectedRows.length > 0) {
      process_bulkPay(selectedRows)
    }
  }
  function process_bulkPay(selectedRows) {


    if (selectedRows.length > 0) {
      let msg = t(selectedRows.length + " records selected. Are You Sure, You want to Proceed for Payment!")
      Alerts.swalConfirmGeneralAlert(msg).then(res => {
        if (res === true) {
          setLoading(true);
          var getData = {
            functionName: "Online_Bulk_Payments",
            selectedRows,
            currency: localStorage.getItem('currency')
          };
          CustomerService.Online_Bulk_Payments(getData).then((response) => {
            console.log(response);
            setLoading(false);
            if (response.status === 200) {
              console.log(response);
              window.open(response.data.data, '_blank');
              console.log('Response : ', response);
            } else if (response.status === 401) {
              AuthService.logout();
              navigate("/login");
              window.location.reload();
            } else {
              window.open(response.data.data, '_blank');
            }
          },
            (error) => {
              setLoading(false);
              const errContent =
                (error.response && error.response.data) || error.message || error.toString();
              Alerts.swalErrorAlert(t("Delete operation failed"))
            })
        }
      })
    }

  }




  function sendMail(row) {
    setLoading(true);
    CustomerService.sendPDF_Mail(row).then((response) => {
      console.log("Send mail to:-", response)
      setLoading(false);
      if (response.data.status) {
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Invoice emailed Successfully."))
        }
        console.log(response.data.message)
      }
      else {
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't send the Password."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Operation failed"))
      })
  };


  const validateFtn = (values) => {
    const errors = {};
    if (!values.remaining_amount) {
      errors.remaining_amount = t("Remaining Amount Required.");
    }
    return errors;
  };
  const handleViewPDF = (_id) => {
    var getData = {
      functionName: "Get_PDF_Invoice",
      _id: _id
    };
    setLoading(true);
    console.log(getData);
    CustomerService.getInvoice_PDF(getData)
      .then((response) => {
        setLoading(false);
        console.log(response.status);
        console.log(response);
        if (response.status === 200) {
          setPdfUrl(URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' })));
          setPDFModalIsOpen(true);
        } else if (response.status === 401) {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        } else {
          // Handle other error cases, if necessary
          console.log("Response status: " + response.status);
          // Display an error message or perform other actions as needed.
        }
      })
      .catch((error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent);
        if (errContent) {
          Alerts.swalErrorAlert(errContent);
        } else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."));
        }
      });
  };

  const PDFViewerModal = () => {
    console.log(pdfUrl);
    return (
      <div
        className={`modal fade ${pdfModalIsOpen ? "show" : ""}`}
        style={{ display: pdfModalIsOpen ? "block" : "none" }}
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content ">
            <div className="modal-header ">
              <h5 className="modal-title">Customer's Order Invoice</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setPDFModalIsOpen(false)}
              ></button>
            </div>
            <div className="modal-body">
              <iframe title="pdfUrl" src={pdfUrl} style={{ width: "100%", height: "500px" }} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const onSubmit = values => {
    var getData = {
      functionName: "OnlinePayment",
      row: values,
      invoice_id: Rows._id,
      amount: values.remaining_amount,
      invoice_number: Rows.invoice_number
    }
    console.log("getData", getData);
    setLoading(true);
    CustomerService.OnlinePayment(getData).then((response) => {

      console.log(response);
      setLoading(false);
      if (response.status === 200) {
        console.log(response);
        window.open(response.data.data, '_blank');
        console.log('Response : ', response);
      } else if (response.status === 401) {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      } else {
        window.open(response.data.data, '_blank');
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent);
        if (errContent) {
          Alerts.swalErrorAlert(errContent);
        } else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."));
        }
      })
  }

  const isRowDisabled = (row) => {
    return row.status === 'paid';
  };
  return (
    <>
      <PDFViewerModal />
      <Fragment>
        <div className="app-main__outer">
          <div className="app-main__inner">

            <span className="d-inline-block mb-2 me-2">
              <Card className="main-card mb-3">
                <Modal isOpen={modal} toggle={toggleModal} backdrop={true}>
                  <ModalHeader >{t('Invoice Payment')}</ModalHeader>
                  <ModalBody>
                    <CardBody>
                      <Form onSubmit={onSubmit}
                        validate={validateFtn}
                        initialValues={Rows}
                        render={({ handleSubmit, values, submitting, validating, valid }) => (
                          <form onSubmit={handleSubmit}>
                            <LoadingOverlay tag="div" active={loading}
                              styles={{ overlay: (base) => ({ ...base }) }}
                              spinner={<DNALoader />}>
                              <Row>
                                <Col md={12}>
                                  <FormGroup>
                                    <Field initialValue={Rows.remaining_amount} name="remaining_amount">
                                      {({ input, meta }) => {
                                        return (
                                          <div>
                                            <Label for="remaining_amount">{t(`Amount (${localStorage.getItem("currency")})`)}</Label>
                                            <Input
                                              {...input}
                                              type="number"
                                              value={parseFloat(input.value).toFixed(2)}
                                              invalid={meta.error && meta.touched}
                                              placeholder={t('Enter Amount to be Paid')}
                                            />
                                            {meta.error && meta.touched && (
                                              <span className="text-danger">{meta.error}</span>
                                            )}
                                          </div>
                                        );
                                      }}
                                    </Field>
                                  </FormGroup>
                                </Col>
                                <Col md={12}>
                                  <FormGroup>
                                    <Field name="method">
                                      {({ input, meta }) => (
                                        <div>
                                          <Label for="method">{t('Payment Method')}</Label>
                                          <Input {...input} type="text"
                                            disabled
                                            value={'Credit Card'}
                                            invalid={meta.error && meta.touched}
                                            placeholder='Payment Method'
                                          >
                                          </Input>
                                          {meta.error && meta.touched &&
                                            <span className="text-danger">{meta.error}</span>}
                                        </div>
                                      )}
                                    </Field>
                                  </FormGroup>
                                </Col>
                                <Col md={12}>
                                  <FormGroup>
                                    <FormGroup>
                                      <Field name="payment_date">
                                        {({ input, meta }) => (
                                          <div>
                                            <Label for="payment_date">{t('Payment Date')}</Label>
                                            <Input
                                              {...input}
                                              type="date"
                                              disabled
                                              value={moment().format("YYYY-MM-DD")}
                                              invalid={meta.error && meta.touched}
                                            />
                                            {meta.error && meta.touched && (
                                              <span className="text-danger">{meta.error}</span>
                                            )}
                                          </div>
                                        )}
                                      </Field>
                                    </FormGroup>

                                  </FormGroup>
                                  <FormGroup>
                                    <div className="d-flex justify-content-center">
                                      <button type="button" onClick={toggleModal} className="btn btn-outline-light btn-hover-shine me-3 mt-3">{t('Cancel')}</button>
                                      <button className="btn btn-custom-color btn-hover-shine me-3 mt-3" disabled={!valid} >{t('Make Payment!')}</button>
                                    </div>
                                  </FormGroup>
                                </Col>

                              </Row>
                            </LoadingOverlay>
                          </form>
                        )}
                      />

                    </CardBody>
                  </ModalBody>
                </Modal>
              </Card>
            </span>

            <div className=" bg-light-gray bg-animation ">
              <div className="d-flex  justify-content-center align-items-center">
                <Col md="12" className="mx-auto app-login-box">
                  <div className="app-page-title">
                    <div className="page-title-wrapper">
                      <div className="page-title-heading fw-bold">
                        <div className="page-title-icon">
                          <i className="pe-7s-news-paper icon-gradient bg-tempting-azure" />
                        </div>
                        <div>
                          {t("Invoice Management")}
                          <div className="page-title-subheading">
                            {t('Add, Update, Delete & View Details of Invoices.')}
                          </div>
                        </div>
                      </div>
                      <div className="page-title-actions">
                        <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-success btn-hover-shine me-3">
                          {t("Back")}
                        </button>
                      </div>
                    </div>
                  </div>
                  <Row>


                    <Fragment>
                      <Row>
                        <Col md="12">
                          <Card className="main-card mb-3">
                            <CardBody>
                              <LoadingOverlay tag="div" active={loading}
                                styles={{ overlay: (base) => ({ ...base }) }}
                                spinner={<DNALoader />}>
                                <CustomerFilter
                                  search_text={search_text}
                                  onSearch_text={(e) => {
                                    setSearchText(e.target.value);
                                  }}
                                  onFilterInvoice_StartDate={setFilterInvoice_StartDate}
                                  onFilterInvoicdue_date={setFilterInvoicdue_date}
                                  onFilterDue_StartDate={setFilterDue_StartDate}
                                  onFilterDudue_date={setFilterDudue_date}
                                  searchCustomers={searchCustomers}
                                  resetFilters={resetFilters}
                                  onFilter1={(e) => {
                                    console.log(e.target.value);
                                    setFilterTextFtn(e.target.value, 'users');
                                  }}
                                  onFilter2={(e) => {
                                    console.log(e.target.value);
                                    setFilterText2(e.target.value, 'users');
                                  }}
                                  handleKeyDown={(event) => checkenterkey(event)}
                                  filterText1={filterText1}
                                  filterText2={filterText2}
                                  filterInvoice_StartDate={filterInvoice_StartDate}
                                  filterInvoicdue_date={filterInvoicdue_date}
                                  filterDue_StartDate={filterDue_StartDate}
                                  filterDudue_date={filterDudue_date}

                                  onClear={() => handleClear('users')}
                                />

                                <div>
                                  <DataTable
                                    data={customers}
                                    columns={mycolumns}
                                    pagination
                                    fixedHeader
                                    fixedHeaderScrollHeight="400px"
                                    paginationServer
                                    paginationTotalRows={totalRows}
                                    onChangePage={handlePageChange}
                                    onChangeRowsPerPage={handlePerRowsChange}
                                    paginationResetDefaultPage={resetPaginationToggle}
                                    selectableRows={payment_types?.includes('bulk_payment') ? true : false}
                                    onSelectedRowsChange={handleSelectedRowsChange}
                                    selectableRowDisabled={isRowDisabled}
                                  />
                                  {/* Additional actions for bulk Payment */}
                                  {selectedRows.length > 0 && (
                                    <div>
                                      <span className="" >

                                        <button type="button" onClick={() => { pay_selected(selectedRows) }} className="btn btn-outline-danger btn-hover-shine me-3 pay_selected_btn">
                                          {t('Bulk Payment')}
                                        </button>
                                      </span>
                                    </div>)}
                                </div>
                              </LoadingOverlay>


                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </Fragment>
                  </Row>
                </Col>
              </div>
            </div>
          </div>
        </div>

      </Fragment>
    </>

  );
};

export default CustomersList;