const authReducer = (state = { 
    authData: null, 
    loading: false, 
    error: false, 
    accessPermissions: [], 
    accessPages: [] 
  }, action) => {
    switch (action.type) {
      case "AUTH_START":
        return {...state, loading: true, error: false };
      case "AUTH_SUCCESS":
        localStorage.setItem("profile", JSON.stringify({...action?.data}));
        return {...state, authData: action.data, loading: false, error: false };
      case "AUTH_FAIL":
        return {...state, loading: false, error: true };
      case "SET_ACCESS_PERMISSIONS":
        return { ...state, accessPermissions: action.payload };
      case "SET_ACCESS_PAGES":
        return { ...state, accessPages: action.payload };
      default:
        return state;
    }
  };
  
  export default authReducer;