import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const TermsOfUse = () => {
    const styles = {
        container: {
            padding: '20px',
            maxWidth: '800px',
            margin: '0 auto',
            fontFamily: 'Arial, sans-serif',
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: '20px',
        },
        backButton: {
            background: 'none',
            border: 'none',
            cursor: 'pointer',
            fontSize: '24px',
            marginRight: '20px',
        },
        title: {
            fontSize: '24px',
            fontWeight: 'bold',
        },
        paragraph: {
            marginBottom: '15px',
            lineHeight: '1.6',
        },
        quote: {
            fontStyle: 'italic',
            marginTop: '20px',
        },
    };

    return (
        <div style={styles.container}>
            <div style={styles.header}>
                <h1 style={styles.title}>Newsroom</h1>
            </div>
            <div>
                <p style={styles.paragraph}>
                    JomSmart Marketplace Sdn Bhd, A Fully Malaysian Owned Company, is the first company to focus on "Localized Community Marketplace" on the Web and Mobile Application Platform in Malaysia. Our Marketplace is created with intentions of creating A "More People Values, And Not Just Technology" in our mind. By building a closer relationship between our MP BIZ Sellers, Service Providers, and MP RIDERZ, we sincerely intend to enable our MP BIZ and our MP Users like yourself to grow closer and experience e-Commerce literally first-hand.
                </p>
                <p style={styles.paragraph}>
                    Our focus is primarily on the growth of entrepreneurs whom we equip with specially customized designed delivery solutions tools in preparing them for their entrepreneurial journey to give their local neighbourhood and its surroundings a more direct customer relationship building experience. This concept is directed towards building a vision and mission to establish "ONE HOME, ONE ENTREPRENEUR" Concept.
                </p>
            </div>
        </div>
    );
};

export default TermsOfUse;