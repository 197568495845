import React, { useEffect, useState, Fragment } from "react";
import { useNavigate, useLocation, Link , NavLink} from "react-router-dom";
import DataTable from 'react-data-table-component';
import { Row, Col, Card, CardBody, Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import AuthService from "../../Authentication/auth.service";
import { useTranslation } from 'react-i18next';
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import "../../../assets/custom css/loadoverlay.scss";
import Alerts from "../../../components/Alerts/alerts";
import OrderService from '../order.service.js';
import MemberFilter from "../../../components/Table Filters/MemberFilter.js";
import styled from 'styled-components';
import PermissionAccess from '../../../PermissionAccess/Access.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faEye, faKey, faUser, faStar } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@mui/material/Tooltip';
import { Chip } from '@mui/material';
import MemberApprovalPopup from '../../../pages/Member/MemberApprovalPopup';
import UpdateStatus from './UpdateStatus';
const StyledChip = styled(Chip)`
  font-size: 12px;
  height: 24px;
  font-weight: 500;
`;


const MemberListing = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [Customers, setCustomers] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [filterLevel, setFilterLevel] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const userString = localStorage.getItem('user');
  const authData = JSON.parse(userString);
  const { accessPages, super_admin, current_module } = PermissionAccess();
  const [MemberToEdit, setMemberToEdit] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [popupMember, setPopupMember] = useState(null);
  const [array, setArray] = useState([]);
  const [showModalUpdateStatus, setshowModalUpdateStatus] = useState(false);
  const handleToggleModalUpdateStatus = (row) => {
    console.log(row)
    setArray(row);
    setshowModalUpdateStatus(!showModalUpdateStatus);
  }
  const handleOrderDetails = (order) => {
    navigate('/orders_details', { state: order });
  };

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchOrders(currentPage, pageLimit, filterText, filterStatus, filterLevel);
  }, [pageLimit, i18n, filterStatus,filterLevel, filterText]);

  function fetchOrders(page_index, page_limit, filterText, filterStatus, filterLevel) {
    var offset = (page_index - 1) * page_limit;
    if (offset < 1) {
      offset = 0;
    }
    const stored_user = authData;
    let business = []
    if (stored_user?.businesses) {
      business = stored_user?.businesses
    }
    console.log("stored_user", stored_user)
    var getData = {
      functionName: "getAllOrdersPastPresent",
      "postData": {
        "offset": offset,
        "is_nanoPorTech": stored_user?.username === "Nano ProTech" ? true : false,
        "user_id": authData._id,
        "filterStatus": filterStatus,
        "filterText": filterText,
        "filterLevel": filterLevel,
        "sort": {
          "_id": -1
        },
        "limit": page_limit,
        business_id: business
      }
    }
    setLoading(true);
    OrderService.getAllOrdersPastPresent(getData).then((response) => {
      setLoading(false);
      console.log("Get Member response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        console.log("response.data.data", response.data.data);
        setCustomers(response.data.orderData);
        setTotalRows(response.data.count);
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  

  const columns = [
    {
      name: t("Order ID"),
      selector: row => row?.order_number,
      sortable: true,
    },
    {
      name: t("Customer"),
      width:'30%',
      selector: row => row.username, 
      cell: row => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span style={{ fontWeight: 'bold', fontSize: '14px', color: '#000' }}>
            {row.username}
          </span>
              <span style={{ fontSize: '12px', color: '#666', marginTop: '2px' }}>
            Phone: {row.mobileNumber || 'N/A'}
          </span>
              <span
            style={{
              fontSize: '12px',
              color: '#666',
              marginTop: '2px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '320px',
              cursor: 'pointer',
            }}
            title={row.address} 
          >
            Address: {row.address || 'N/A'}
          </span>
        </div>
      ),
      sortable: true,
    },
    {
      name: t("Date & Time"),
      selector: row => new Date(row.order_date).toLocaleDateString(),
      sortable: true,
    },
    {
      name: t("Total Items"),
      selector: row => row.TotalItems,
    },
    {
      name: t('Order Status'),
      selector: (row) => {
        const getButtonStyle = (status) => {
          switch (status) {
            case 'confirmed':
              return {
                borderColor: '#ffc107',
                backgroundColor: '#ffc107',
                color: '#000'
              };
            case 'processing':
              return {
                borderColor: '#007bff',
                backgroundColor: '#007bff',
                color: '#fff'
              };
            case 'in_transit':
            case 'picked_up':
              return {
                borderColor: '#6f42c1',
                backgroundColor: '#6f42c1',
                color: '#fff'
              };
            case 'cancelled':
              return {
                borderColor: '#dc3545',
                backgroundColor: '#dc3545',
                color: '#fff'
              };
            default:
              return {
                borderColor: '#28a745',
                backgroundColor: '#28a745',
                color: '#fff'
              };
          }
        };

        const formatStatus = (status) => {
          if (status.includes('*')) {
            // Remove asterisk and replace underscores with spaces
            return status.replace('*', '').replace(/_/g, ' ').toUpperCase();
          } else if (status === 'in_transit') {
            return 'IN TRANSIT';
          } else if (status === 'picked_up') {
            return 'PICKED UP';
          } else {
            return status.toUpperCase();
          }
        };

        const buttonStyle = getButtonStyle(row?.status);

        return (
          <div>
            <button
              type="button"
              style={buttonStyle}
              className={`btn btn-sm btn-hover-shine me-3 ${row?.status === 'confirmed' ? 'btn-success' : 'btn-danger'}`}
            >
              {formatStatus(row?.status)}
            </button>
          </div>
        );
      },
      sortable: true,
    },

    {
      name: t('Actions'),
      selector: (row) => {
        return (
          <div>
              <Tooltip placement="top" title={`${t("View")} ${t("Details")}`} arrow>
                  <i 
                      className="pe-7s-look btn-icon-wrapper text-primary fs-5 p-1"
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleOrderDetails(row)} 
                    > </i>
                  </Tooltip>
            {!row.isMyOrder && 
            row?.status.toLowerCase() !== "delivered" && row?.status.toLowerCase() !== "cancelled" && (
                <Tooltip placement="top" title={`${t("Update")} ${t("Status")}`} arrow>
                  <i
                    className="pe-7s-pen btn-icon-wrapper text-primary fs-5 p-1"
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleToggleModalUpdateStatus(row)}
                  > </i>
                </Tooltip>
            )}
            <UpdateStatus
              showModalUpdateStatus={showModalUpdateStatus}
              handleToggleModalUpdateStatus={handleToggleModalUpdateStatus}
              onSuccess={fetchOrders}
              array={array}
            />
          </div>
        );
      }
    }
  ];

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchOrders(page, pageLimit, filterText, filterStatus);
  };

  const handlePerRowsChange = async (newLimit, page) => {
    setPageLimit(newLimit);
  };

  const handleSearch = (searchText, status) => {
    setFilterText(searchText);
    setFilterStatus(status);
    fetchOrders(0, pageLimit, searchText, status);
  };

  const handleClear = () => {
    setFilterText('');
    setFilterStatus('');
    fetchOrders(0, pageLimit, '', '');
  };

  const subHeaderComponentMemo = React.useMemo(() => (
    <MemberFilter
      onSearch={handleSearch}
      onClear={handleClear}
      filterText={filterText}
      filterStatus={filterStatus}
    />
  ), [filterText, filterStatus]);

  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className="bg-light-gray bg-animation">
            <div className="d-flex justify-content-center align-items-center">
              <Col md="12" className="mx-auto app-login-box">
                <Card className="main-card mb-3" style={{ padding: '2%', borderRadius: '20px' }}>
                  <CardBody style={{ borderRadius: '20px', border: '1px solid #CDCDCD' }} >
                    <LoadingOverlay active={loading} spinner={<DNALoader />}>
                      <>
                        <Row className="mb-3">
                          <Row className="mb-3" style={{ alignItems: "center", marginBottom: "20px", marginTop: "10px" }}>
                            <Col md="6">
                              <h4 style={{ fontSize: "1.2rem", fontWeight: "bold", margin: 0, color: "#333" }}>
                                Orders
                              </h4>
                            </Col>
                            <Col
                              md="6"
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                                gap: "15px",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "1rem",
                                  fontWeight: 500,
                                  color: "#666",
                                }}
                              >
                                Filter by
                              </span>
                              <select
                                style={{
                                  padding: "5px 10px",
                                  border: "1px solid #ddd",
                                  borderRadius: "5px",
                                  backgroundColor: "#f9f9f9",
                                  fontSize: "0.9rem",
                                  color: "#333",
                                }}
                                onChange={(e) => setFilterStatus(e.target.value)}
                                value={filterStatus}
                              >
                                <option value="">{t("Status")}</option>
                                <option value="pending">{t("Pending")}</option>
                                <option value="confirmed">{t("Confirmed")}</option>
                                <option value="in_transit">{t("in Transit")}</option>
                                <option value="picked_up">{t("Picked Up")}</option>
                                <option value="delivered">{t("Delivered")}</option>
                                <option value="cancelled">{t("Cancelled")}</option>
                              </select>
                              <div
                                style={{
                                  position: "relative",
                                  width: "250px",
                                }}
                              >
                                <input
                                  type="text"
                                  placeholder={t("Type here...")}
                                  value={filterText}
                                  onChange={(e) => setFilterText(e.target.value)}
                                  style={{
                                    width: "100%",
                                    padding: "5px 40px 5px 10px",
                                    border: "1px solid #ddd",
                                    borderRadius: "5px",
                                    backgroundColor: "#f9f9f9",
                                    fontSize: "0.9rem",
                                    color: "#333",
                                  }}
                                />
                                <i
                                  className="fas fa-search"
                                  style={{
                                    position: "absolute",
                                    right: "10px",
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                    fontSize: "1rem",
                                    color: "#aaa",
                                  }}
                                ></i>
                              </div>
                            </Col>
                          </Row>

                        </Row>
                        <DataTable
                          columns={columns}
                          data={Customers}
                          pagination
                          paginationServer
                          paginationTotalRows={totalRows}
                          onChangePage={handlePageChange}
                          onChangeRowsPerPage={handlePerRowsChange}
                          // selectableRows
                          // onSelectedRowsChange={({ selectedRows }) => setSelectedRows(selectedRows.map(row => row._id))}
                          highlightOnHover
                          pointerOnHover
                          responsive
                        />
                      </>
                    </LoadingOverlay>
                  </CardBody>
                </Card>

              </Col>
            </div>
          </div>
        </div>

      </div>
    </Fragment>
  );
};

export default MemberListing;

// import React, { useState, useEffect } from 'react';
// import { Container, Button } from 'reactstrap';
// import { useLocation, useNavigate } from 'react-router-dom';
// import moment from 'moment';
// import {
//   IconButton
// } from '@mui/material';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import LoadingOverlay from 'react-loading-overlay-ts';
// import DNALoader from '../../../components/Loaders/DnaLoader';
// import OrderService from '../order.service.js';
// import AuthService from '../../Authentication/auth.service';
// import Alerts from '../../../components/Alerts/alerts';
// import { useTranslation } from 'react-i18next';
// import pickup from '../../../assets/pickup.svg';
// import order1 from '../../../assets/order1.svg';
// import deliveryIcon from '../../../assets/delivery.svg';

// const CustomerOrders = () => {
//   const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
//   const [activeClicked, setActiveClicked] = useState(true);
//   const [orders, setOrders] = useState([]);
//   const [activeOrdersCount, setActiveOrdersCount] = useState(0);
//   const [pastOrdersCount, setPastOrdersCount] = useState(0);
//   const [loading, setLoading] = useState(false);
//   const [page, setPage] = useState(0);
//   const [hasMore, setHasMore] = useState(true);

//   const { t } = useTranslation();
//   const navigate = useNavigate();
//   const location = useLocation();
//   useEffect(() => {
//     const observerInstance = new IntersectionObserver((entries) => {
//       if (entries[0].isIntersecting && hasMore) {
//         setPage(prevPage => prevPage + 1);
//       }
//     }, { threshold: 1 });

//     const lastCard = document.querySelector('.order-card:last-child');
//     if (lastCard) {
//       observerInstance.observe(lastCard);
//     }

//     return () => {
//       if (lastCard) {
//         observerInstance.unobserve(lastCard);
//       }
//     };
//   }, [orders, hasMore]);

//   useEffect(() => {
//     const handleResize = () => {
//       setIsMobile(window.innerWidth <= 768);
//     };
//     window.addEventListener('resize', handleResize);

//     return () => window.removeEventListener('resize', handleResize);
//   }, []);

//   const fetchOrders = (active_orders, past_orders, page) => {
//     const userString = localStorage.getItem('user');
//     const user = JSON.parse(userString);
//     const user_id = user['_id'];

//     setLoading(true);
//     OrderService.getAllOrders(active_orders, past_orders, page, user_id)
//       .then((response) => {
//         setLoading(false);
//         if (response.data.message === "Unauthorized") {
//           AuthService.logout();
//           navigate("/login");
//           window.location.reload();
//         } else {
//           if (page === 0) {
//             setOrders(response.data.orderData);
//           } else {
//             setOrders(prevOrders => [...prevOrders, ...response.data.orderData]);
//           }
//           setActiveOrdersCount(response.data.activeOrdersCount);
//           setPastOrdersCount(response.data.pastOrdersCount);
//           setHasMore(response.data.orderData.length > 0);
//         }
//       })
//       .catch((error) => {
//         setLoading(false);
//         const errContent = (error.response && error.response.data) || error.message || error.toString();
//         Alerts.swalErrorAlert(errContent || t("Server Error, Please try again later."));
//       });
//   };

//   useEffect(() => {
//     setOrders([]);
//     setPage(0);
//     setHasMore(true);
//     fetchOrders(activeClicked, !activeClicked, 0);
//   }, [activeClicked]);

//   useEffect(() => {
//     if (page > 0) {
//       fetchOrders(activeClicked, !activeClicked, page);
//     }
//   }, [page]);

//   const handleActiveOrdersClick = () => setActiveClicked(true);
//   const handlePastOrdersClick = () => setActiveClicked(false);

//   const handleOrderDetails = (order) => {
//     navigate('/orders_details', { state: order });
//   };

//   const OrderCard = ({ order }) => {
//     const statusButtonStyle = {
//       backgroundColor: '#ffc107', // Yellow for "Confirmed"
//       color: '#fff',
//       border: 'none',
//       borderRadius: '5px',
//       padding: '5px 15px',
//       fontSize: '14px',
//       marginRight: '15px',
//     };

//     return (
//       <div onClick={() => handleOrderDetails(order)} style={cardStyle} className="order-card">
//         <div style={cardBodyStyle}>
//           <div style={orderInfoStyle}>
//             {/* Order number */}
//             <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
//               <img
//                 src={order1}
//                 alt="Self Pickup"
//                 style={{ width: '20px', height: 'auto', marginRight: '8px' }}
//               />
//               <h5 style={orderIdStyle}>Order: #{order.order_number}</h5>
//             </div>

//             {/* Item details */}
//             <p style={orderDetailStyle}>Items: {order.total_cart_item}</p>

//             {order.deliveryMethod === "delivery" ?
//               < div style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
//                 <img
//                   src={deliveryIcon}
//                   alt="Delivery"
//                   style={{ width: '50px', height: 'auto', marginRight: '8px' }}
//                 />
//                 <p style={{ fontSize: '14px', margin: 0 }}>Delivery</p>
//               </div>
//               :
//               <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
//                 <img
//                   src={pickup}
//                   alt="Self Pickup"
//                   style={{ width: '50px', height: 'auto', marginRight: '8px' }}
//                 />
//                 <p style={{ fontSize: '14px', margin: 0 }}>Self Pickup</p>
//               </div>
//             }
//           </div>

//           <div>
//             {/* Date and Time */}
//             <p style={{ fontSize: '14px', color: '#777', marginBottom: '10px' }}>
//               {moment(order.order_date).format('MMM DD, YYYY - HH:mm')}
//             </p>

//             {/* Status Button */}
//             <Button style={statusButtonStyle}>
//               {order.status}
//             </Button>
//           </div>
//         </div>
//       </div >
//     );
//   };

//   return (
//     <>
//       <div className="app-main__outer">
//         <div className="container mt-4">
//           <div style={{ marginTop: '60px', padding: '20px' }}>
//             <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
//               <button
//                 style={{
//                   backgroundColor: activeClicked ? '#F2731C' : '#f8f9fa',
//                   color: activeClicked ? '#fff' : '#000',
//                   border: 'none',
//                   borderRadius: '5px',
//                   padding: '10px 20px',
//                   marginRight: '10px',
//                   cursor: 'pointer',
//                 }}
//                 onClick={handleActiveOrdersClick}
//               >
//                 {activeOrdersCount} ACTIVE ORDERS
//               </button>
//               <button
//                 style={{
//                   backgroundColor: !activeClicked ? '#F2731C' : '#f8f9fa',
//                   color: !activeClicked ? '#fff' : '#000',
//                   border: 'none',
//                   borderRadius: '5px',
//                   padding: '10px 20px',
//                   marginLeft: '10px',
//                   cursor: 'pointer',
//                 }}
//                 onClick={handlePastOrdersClick}
//               >
//                 {pastOrdersCount} PAST ORDERS
//               </button>
//             </div>

//             <LoadingOverlay
//               active={loading}
//               spinner={<DNALoader />}
//             >
//               {orders.map((order, index) => (
//                 <OrderCard key={order.order_id} order={order} />
//               ))}
//               {!loading && orders.length === 0 && (
//                 <p style={{ textAlign: 'center', marginTop: '20px' }}>No orders found.</p>
//               )}
//             </LoadingOverlay>
//           </div>
//         </div>
//       </div>
//       <style>
//         {`
//           @keyframes fadeIn {
//             from { opacity: 0; }
//             to { opacity: 1; }
//           }
          
//           @keyframes slideIn {
//             from { transform: translateX(-100%); }
//             to { transform: translateX(0); }
//           }
          
//           @keyframes slideUp {
//             from { transform: translateY(100%); }
//             to { transform: translateY(0); }
//           }
          
//           .fade-in {
//             animation: fadeIn 0.5s ease-out;
//           }
          
//           .slide-in {
//             animation: slideIn 0.3s ease-out;
//           }
          
//           .slide-up {
//             animation: slideUp 0.3s ease-out;
//           }
          
//           .category-button {
//             transition: background-color 0.3s, color 0.3s;
//           }
          
//           .product-card {
//             transition: transform 0.3s, box-shadow 0.3s;
//           }
          
//           .product-card:hover {
//             transform: translateY(-5px);
//             box-shadow: 0 4px 8px rgba(0,0,0,0.2);
//           }
          
//           @media (max-width: 768px) {
//       .product-card {
//         flex-direction: row;
//         text-align: left;
//       }
      
//       .product-card img {
//         margin-right: 15px;
//         margin-bottom: 0;
//       }
//     }
    
//     @media (max-width: 480px) {
//       .category-button {
//         padding: 8px 15px;
//         margin-right: 5px;
//         font-size: 12px;
//       }
//     }
    
//     /* Hide scrollbar for Chrome, Safari and Opera */
//     #scrollRef::-webkit-scrollbar {
//       display: none;
//     }

//     /* Hide scrollbar for IE, Edge and Firefox */
//     #scrollRef {
//       -ms-overflow-style: none;  /* IE and Edge */
//       scrollbar-width: none;  /* Firefox */
//     }
//         `}
//       </style>
//     </>
//   );
// };

// const cardStyle = {
//   width: '80%', // Reduce card width
//   maxWidth: '500px', // Set a maximum width to prevent it from becoming too large on bigger screens
//   backgroundColor: 'white',
//   border: '1px #ccc solid',
//   marginBottom: '20px',
//   padding: '15px',
//   cursor: 'pointer',
//   margin: '0 auto', // Center align the card
//   display: 'flex',
//   justifyContent: 'space-between',
//   alignItems: 'center',
// };



// const cardBodyStyle = {
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'space-between',
//   width: '100%',
// };

// const orderInfoStyle = {
//   flexGrow: 1,
//   marginRight: '20px',
// };

// const orderIdStyle = {
//   fontSize: '16px',
//   fontWeight: 'bold',
//   color: '#003366',
//   marginBottom: '5px',
// };

// const orderDetailStyle = {
//   fontSize: '14px',
//   color: '#777',
//   margin: '5px 0',
// };


// export default CustomerOrders;