import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Input, Button, Form, FormGroup, Label, Modal, ModalBody } from 'reactstrap';
import { FaPlus, FaCheck, FaTrash, FaArrowLeft } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import Alerts from "../../../components/Alerts/alerts";
import DashboardService from "../dashboard.service";
import AuthService from "../../Authentication/auth.service";
import { useTranslation } from 'react-i18next';
import moment from "moment";

const OrderConfirmationModal = ({ isOpen, toggle, orderId }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered className="order-confirmation-modal">
      <ModalBody className="text-center p-4">
        <div className="confirmation-icon mb-3">
          <FaCheck size={24} color="#fff" />
        </div>
        <h4 className="mb-2">Order Items Return Request Processed Successfully</h4>
        <h2 className="order-id mb-0">ID# {orderId}</h2>
      </ModalBody>
    </Modal>
  );
};

const EditRfq = () => {
  const [items, setItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [RfqDetails, setRFQDetails] = useState({});
  const [categoryItems, setCategoryItems] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [orderId, setOrderId] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('user')));
  const [returnReason, setReturnReason] = useState('');

  useEffect(() => {
    fetchCategories();
    fetchRfqDetails();
  }, []);

  const fetchCategories = async () => {
    setLoading(true);
    try {
      let offset = 0;
      let limit = 1000;
      let business_id = []
      const response = await DashboardService.getAllCategories(offset, limit, business_id);
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
      } else {
        setCategories(response.data.data);
      }
    } catch (error) {
      Alerts.swalErrorAlert(t("Error fetching categories"));
    }
    setLoading(false);
  };

  const fetchRfqDetails = async () => {
    setLoading(true);
    try {
      const payload = {
        functionName: "GetRFQDetails",
        postData: {
          _id: id,
        }
      }
      const response = await DashboardService.GetRFQDetails(payload);
      if (response.data.status) {
        const rfqData = response.data.data[0];
        setRFQDetails(rfqData);
        setReturnReason(rfqData?.return_reason || '')
        setItems(rfqData.items);
        setOrderId(rfqData.rfq_id);
        rfqData.items.forEach(item => fetchItemsForCategory(item.category));
      } else {
        Alerts.swalErrorAlert(response.data.message || t("Failed to fetch RFQ details"));
      }
    } catch (error) {
      Alerts.swalErrorAlert(t("An error occurred while fetching RFQ details"));
    }
    setLoading(false);
  };

  const fetchItemsForCategory = async (categoryId) => {
    try {
      const getData = {
        functionName: "GetItems",
        postData: {
          sort: { _id: -1 },
          offset: 0,
          limit: 1000,
          filter: { category: categoryId },
          business: []
        },
      };
      const response = await DashboardService.getAllItems(getData);
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
      } else {
        setCategoryItems(prevItems => ({
          ...prevItems,
          [categoryId]: response.data.data
        }));
      }
    } catch (error) {
      Alerts.swalErrorAlert(t("Error fetching items"));
    }
  };

  const handleItemChange = (itemIndex, batchIndex, value) => {
    const newItems = [...items];
    newItems[itemIndex].selectedBatches[batchIndex].returned_quantity = parseInt(value);
    setItems(newItems);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const rfqData = {
        _id: id,
        items: items.map(item => ({
          category: item.category,
          item: item.item,
          selectedBatches: item.selectedBatches.map(batch => ({
            batchId: batch?.batchId,
            returned_quantity: batch?.returned_quantity || 0
          }))
        })),
        user_id: userInfo._id,
      };
      const response = await DashboardService.ReturnRFQ(rfqData);
      if (response.data.status) {
        setModalOpen(true);
      } else {
        Alerts.swalErrorAlert(response.data.message || t("Failed to update RFQ"));
      }
    } catch (error) {
      Alerts.swalErrorAlert(t("Error in Order Item Returns"));
    } finally {
      setLoading(false);
    }
  };

  const handleAccept = async () => {
    try {
      var payload = {
        functionName: "AcceptReturnItems",
        postData: {
          _id: id,
        }
      }
      const response = await DashboardService.AcceptReturnItems(payload);
      if (response.data.status) {
        Alerts.swalSuccessAlert(t("Items Returned successfully"));
        fetchRfqDetails();
      } else {
        Alerts.swalErrorAlert(response.data.message || t("Failed to accept RFQ"));
      }
    } catch (error) {
      console.error("Error accepting RFQ:", error);
      Alerts.swalErrorAlert(t("An error occurred while accepting the RFQ"));
    }
  };

  return (
    <div className="app-main__outer">
      <div className="container mt-4">
        <div className="header">
          <Button color="link" className="p-0 text-decoration-none" onClick={() => navigate(-1)}>
            <FaArrowLeft className="me-2" />
            <span className="text-dark fw-bold">{t("Return Request For Order")}</span>
          </Button>
          <div className="actions">
            {RfqDetails?.return_status === "pending" ? (
              <button onClick={handleAccept} className="accept-button">{t("Approve")}</button>
            ) : RfqDetails?.return_status === "rejected" ? (
              <button className="rejected-button">{t("Items Not Returned")}</button>
            ) : RfqDetails?.return_status === "accepted" ? (
              <button className="processing-button">{t("Items Returned")}</button>
            ) : (
              <button disabled className="pending-button">{t("Pending")}</button>
            )}
          </div>
        </div>
        <Card className="rfq-card">
          <div className="p-4">
            <h4 className="mb-4 fw-bold">{t("Return Order Items")} #{orderId}</h4>
            <Form onSubmit={handleSubmit}>
              <LoadingOverlay active={loading} spinner={<DNALoader />}>
                <FormGroup className="mb-3">
                  <Label for="returnReason">{t("Reason for Return")}</Label>
                  <Input
                    type="textarea"
                    name="returnReason"
                    id="returnReason"
                    disabled
                    style={{ backgroundColor: "white" }}
                    defaultValue={RfqDetails?.return_reason || returnReason}
                    onChange={(e) => setReturnReason(e.target.value)}
                    placeholder={t("Please provide a reason for returning the items")}
                    rows="3"
                    required
                  />
                </FormGroup>
                <h5 className="mb-3 fw-bold">{t("Return Item(s)")}</h5>
                <div className="item-list mb-3">
                  <Row className="mb-2 text-muted">
                    <Col md={2}>{t("Item(s)")}</Col>
                    <Col md={2}>{t("Batch")}</Col>
                    <Col md={2}>{t("Expiry")}</Col>
                    <Col md={2}>{t("Quantity")}</Col>
                    <Col md={2}>{t("Returned Quantity")}</Col>
                    <Col md={2}>{t("Remaining Quantity")}</Col>
                  </Row>
                  {items.map((item, itemIndex) => (
                    item.selectedBatches.map((batch, batchIndex) => (
                      <Row key={`${itemIndex}-${batchIndex}`} className="mb-2 align-items-center">
                        <Col md={2}>{item.itemDetails?.item_name}</Col>
                        <Col md={2}>{batch?.batch_number}</Col>
                        <Col md={2}>{moment(batch?.expiry).format("DD-MM-YYYY")}</Col>
                        <Col md={2}>{batch?.quantity}</Col>
                        <Col md={2}>
                          <Input
                            type="number"
                            min="0"
                            disabled={true}
                            max={batch?.quantity}
                            readOnly={RfqDetails?.return_status && RfqDetails?.return_status !== "pending"}
                            value={batch?.returned_quantity || ''}
                            onChange={(e) => handleItemChange(itemIndex, batchIndex, e.target.value)}
                          />
                        </Col>
                        <Col md={2}>{batch?.quantity - (batch?.returned_quantity || 0)}</Col>
                      </Row>
                    ))
                  ))}
                </div>
              </LoadingOverlay>
            </Form>
          </div>
        </Card>
        <OrderConfirmationModal isOpen={modalOpen} toggle={() => { setModalOpen(!modalOpen); navigate(-1); }} orderId={orderId} />
      </div>
      <style jsx>{`
        .app-main__outer {
          background-color: #f8f9fa;
          min-height: 100vh;
          padding-top: 20px;
        }
        .rfq-card {
          border-radius: 10px;
          box-shadow: 0 2px 10px rgba(0,0,0,0.05);
          border: none;
        }
        .form-select, .form-control {
          border-color: #e0e0e0;
          border-radius: 5px;
          padding: 10px;
        }
        .btn-light {
          background-color: #ffffff;
          border-color: #e0e0e0;
          color: #333;
        }
        .btn-primary {
          background-color: #3D607A;
          border-color: #3D607A;
        }
        .text-muted {
          color: #6c757d !important;
        }
        h4, h5 {
          color: #333;
        }
        .item-list {
          border-top: 1px solid #e0e0e0;
          border-bottom: 1px solid #e0e0e0;
          padding: 20px 0;
        }
        .order-confirmation-modal {
          display: flex !important;
          align-items: center;
          justify-content: center;
        }
      
        .order-confirmation-modal .modal-dialog {
          width: 100%;
          max-width: 400px;
          margin: 0 auto;
        }
      
        .order-confirmation-modal .modal-content {
          border-radius: 20px;
          border: none;
          box-shadow: 0 5px 15px rgba(0,0,0,0.1);
        }
      
        .order-confirmation-modal .modal-body {
          padding: 2rem;
        }
        .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;
        }

        .reject-button, .rejected-button {
          background-color: #ffdddd;
          color: #d9534f;
          border: 1px solid #d9534f;
        }
        .accept-button, .accepted-button {
          background-color: #dff0d8;
          color: #5cb85c;
          border: 1px solid #5cb85c;
        }
        .pay-now-button {
          background-color: #5bc0de;
          color: white;
          border: 1px solid #46b8da;
        }
        .processing-button {
          background-color: #fcf8e3;
          color: #8a6d3b;
          border: 1px solid #faebcc;
        }
        .in-transit-button {
          background-color: #d9edf7;
          color: #31708f;
          border: 1px solid #bce8f1;
        }
        .delivered-button {
          background-color: #dff0d8;
          color: #3c763d;
          border: 1px solid #d6e9c6;
        }
        .pending-button {
          background-color: #f5f5f5;
          color: #777;
          border: 1px solid #ddd;
        }
        button {
          padding: 8px 16px;
          border-radius: 5px;
          cursor: pointer;
          font-weight: bold;
          transition: all 0.3s ease;
        }
        button:hover:not(:disabled) {
          opacity: 0.8;
        }
        button:disabled {
          cursor: not-allowed;
          opacity: 0.6;
        }
        
        .confirmation-icon {
          width: 60px;
          height: 60px;
          background-color: #4CAF50;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto;
        }
      
        .order-id {
          font-size: 2rem;
          font-weight: bold;
        }
      `}</style>
    </div>
  );
};

export default EditRfq;
