import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, Navigate } from "react-router-dom";
import { Row, Col, FormGroup, Input, Button, Label, Modal, ModalHeader, ModalBody, Card, CardBody } from "reactstrap";
import Services from "./services";
import AuthService from "../Authentication/auth.service";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import Alerts from "../../components/Alerts/alerts";
import PermissionAccess from '../../PermissionAccess/Access.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faTimes, faFilePdf } from '@fortawesome/free-solid-svg-icons';


const AddStock = ({ state, onSuccess, warehouses }) => {
  console.log("warehouses", warehouses)
  const [modal, setModal] = useState(false);
  const [Customers, setCustomers] = useState([{ item_code: state?.item_code || '', batch_number: '', quantity: '', expiry_date: '', warehouse: '' }]);
  const [loading, setLoading] = useState(false);
  const [loginNavigate, setLoginNavigate] = useState(false);
  const [Fetched_Businesses, setFetched_Businesses] = useState([]);
  const { authData, accessPages, super_admin, current_module } = PermissionAccess();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    Fetch_Businesses();
  }, []);

  function Fetch_Businesses() {
    setLoading(true);
    Services.GetBusinesses(authData.sessionID).then(
      (response) => {
        setLoading(false);
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        } else {
          const business = response.data.data[0];
          setFetched_Businesses([business]);
        }
      },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        Alerts.swalErrorAlert(errContent || t("Server Error, Please try again later."));
      }
    );
  }



  const toggle = () => {
    setModal(!modal);
    setCustomers([{ item_code: state?.item_code || '', batch_number: '', quantity: '', expiry_date: '', warehouse: '' }]);
  };

  const handleStockChange = (index, field, value) => {
    setCustomers(prevCustomers => prevCustomers.map((Stock, i) =>
      i === index ? { ...Stock, [field]: value } : Stock
    ));
  };

  const validateForm = () => {
    return Customers.every(Stock =>
      Stock.item_code &&
      Stock.batch_number &&
      Stock.quantity
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      Alerts.swalErrorAlert("Please fill all fields and upload at least one document for Customers.");
      return;
    }

    const formData = {}

    // Append Stock data to formData
    Object.keys(Customers[0]).forEach(key => {
      // Append other fields
      formData[key] = Customers[0][key]
    });

    formData.business_id = Fetched_Businesses[0]._id
    formData.status = "Active"
    setLoading(true);
    try {
      const response = await Services.AddStock(authData.sessionID, formData);
      setLoading(false);
      if (response.data.status) {
        onSuccess();
        Alerts.swalSuccessAlert(response.data.message || "Stock Added Successfully.").then(() => {
          toggle();
        });
      } else {
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          setLoginNavigate(true);
        } else {
          Alerts.swalErrorAlert(response.data.message || "Error: Can't Add Stock at the moment. Please try again later.");
        }
      }
    } catch (error) {
      setLoading(false);
      Alerts.swalErrorAlert("Network Operation failed.");
    }
  };

  if (loginNavigate) {
    return <Navigate to={'/login'} />;
  }

  return (
    <span className="d-inline-block float-end mb-2 me-2">
      <Button onClick={toggle} className="btn btn-custom-color btn-hover-shine">
        {t("Stock In")}
      </Button>
      <Modal size='lg' isOpen={modal} toggle={toggle} backdrop={true}>
        <LoadingOverlay active={loading} spinner={<DNALoader />}>
          <ModalHeader>{t("Add Stock")}</ModalHeader>
          <ModalBody>
            <Card className="main-card mb-3">
              <CardBody>
                <form onSubmit={handleSubmit}>
                  {Customers.map((Stock, index) => (
                    <FormGroup key={index} className="mb-3">
                      <Row>
                        <Col md="12">
                          <Label>{t("Model Name")}</Label>
                          <Input
                            type="text"
                            value={Stock.item_code}
                            disabled={state?.item_code}
                            onChange={(e) => handleStockChange(index, 'item_code', e.target.value)}
                            placeholder={t("Enter Item Code")}
                          />
                        </Col>
                        <Col style={{ marginTop: '15px' }} md="12">
                          <Label>{t("Batch Number")}</Label>
                          <Input
                            type="text"
                            value={Stock.batch_number}
                            onChange={(e) => handleStockChange(index, 'batch_number', e.target.value)}
                            placeholder={t("Enter Batch Number")}
                          />
                        </Col>
                        <Col style={{ marginTop: '15px' }} md="12">
                          <Label>{t("Quantity")}</Label>
                          <Input
                            type="number"
                            value={Stock.quantity}
                            onChange={(e) => handleStockChange(index, 'quantity', e.target.value)}
                            placeholder={t("Enter Quantity")}
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                  ))}
                  <div className="text-center">
                    <Button type="submit" color="success" size="lg" className="me-3">
                      {t("Save")}
                    </Button>
                    <Button type="button" color="secondary" size="lg" onClick={toggle}>
                      {t("Cancel")}
                    </Button>
                  </div>
                </form>
              </CardBody>
            </Card>
          </ModalBody>
        </LoadingOverlay>
      </Modal>
    </span>
  );
};

export default AddStock;

