import React, { useEffect, useState, Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import DataTable from 'react-data-table-component';

import {
  Row,
  Col,
  Card,
  CardBody
} from "reactstrap";
import Tooltip from '@mui/material/Tooltip';
import AuthService from "../Authentication/auth.service";
import { useTranslation } from 'react-i18next';
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";
import Alerts from "../../components/Alerts/alerts";
import VariationsService from "./Variations.service";
import AddCategory from "./AddCategory";
import UpdateCategory from "./UpdateCategory";
import VariationsFilter from "../../components/Table Filters/VariationTypesFilter.js";
import styled from 'styled-components';
import PermissionAccess from '../../PermissionAccess/Access.js';

const ImageContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const SmallImage = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  transition: all 0.3s ease;
`;
const LargeImage = styled.img`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 80%;
  max-height: 80%;
  z-index: 1000;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0,0,0,0.3);
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.7);
  z-index: 999;
`;

const CustomerDetails = () => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [checkTab, setCheckTab] = useState(localStorage.getItem('tab5'));
  const location = useLocation();
  const [selectedRows, setSelectedRows] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const { state } = location;
  console.log("Received Object Variation Details:-", state);
  const { authData, accessPages, super_admin, current_module } = PermissionAccess();
  

  useEffect(() => {
    if (!checkTab) {
      setCheckTab("1")
    } else {
      localStorage.removeItem('tab5');
    }
  }, [checkTab]);

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
  }, []);


  const [Category, setCategory] = useState([]);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const [totalRows, setTotalRows] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState("");


  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchCategory(0, pageLimit, filterText, filterStatus);
  }, [pageLimit]);

  function fetchCategory(page_index, page_limit, filterText, filterStatus) {
    var offset = (page_index - 1) * page_limit;
    if (offset < 1) {
      offset = 0;
    }
    const stored_user = authData.userData;
    let business = []
    if (stored_user.businesses) {
      business = stored_user.businesses
    }

    var getData = {
      functionName: "GetCategories",
      "postData": {
        "offset": offset,
        "filterStatus": filterStatus,
        "filterText": filterText,
        "sort": {
          "_id": -1
        },
        "limit": page_limit,
        business_id: business,
        "noActiveMatch": true
      }
    }
    setLoading(true);
    VariationsService.GetCategories(authData.sessionID,getData).then((response) => {
      setLoading(false);
      console.log("Get Category response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setCategory(response.data.data);
        console.log(response.data);
        setTotalRows(response.data.count.count);
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  function handleSuccess() {
    fetchCategory(currentPage, pageLimit, filterText, filterStatus)
  };

  function confirmDelete(_id) {
    let msg = t("Once deleted, you will not be able to recover this Record!");
    Alerts.swalConfirmDeleteAlert(msg).then(res => {
      console.log("Resp:-", res);
      if (res === true) {
        sendDelete(_id);
      }
    });
  }

  const handleCheckboxChange = (e, row) => {
    const { checked } = e.target;
    if (checked) {
      setSelectedRows((prevSelected) => [...prevSelected, row._id]);
    } else {
      setSelectedRows((prevSelected) => prevSelected.filter(id => id !== row._id));
    }
  };

  const handleSelectAllChange = (e) => {
    const { checked } = e.target;
    if (checked) {
      setSelectedRows(Category.map((attribute) => attribute._id));
    } else {
      setSelectedRows([]);
    }
  };


  const handleDeleteSelected = () => {
    if (selectedRows.length > 0) {
      let msg = t(selectedRows.length + " records selected. Once deleted, you will not be able to recover this!");
      Alerts.swalConfirmDeleteAlert(msg).then(res => {
        console.log("Resp:-", res)
        if (res === true) {
          sendBulkDelete(selectedRows)
        }
      })
    } else {
      Alerts.swalErrorAlert('No records selected');
    }
  };

  function sendBulkDelete(selectedRows) {
    setLoading(true);
    VariationsService.DeleteBulkCategory(authData.sessionID,selectedRows).then((response) => {
      console.log("Delete BulkVariations response:-", response)
      setLoading(false);
      setSelectedRows([]);
      fetchCategory(currentPage, pageLimit, filterText, filterStatus)
      if (response.data.status) {
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Data Deleted Successfully."))
        }
        console.log(response.data.message)
      }
      else {
        setLoading(false);
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't delete the Collection Data at the moment."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Delete operation failed"))
      })
  };


  function sendDelete(id) {
    setLoading(true);
    VariationsService.DeleteCategory(authData.sessionID,id).then((response) => {
      setLoading(false);
      console.log("Delete service details response:-", response)
      if (response.data.status) {
        fetchCategory(currentPage, pageLimit, filterText, filterStatus);
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Data Deleted Successfully."))
        }
        console.log(response.data.message)
      }
      else {
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't delete at the moment."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Delete operation failed"))
      })
  };

  const ImageWithHover = ({ src, alt }) => {
    const [showLarge, setShowLarge] = useState(false);

    return (
      <ImageContainer
        onMouseEnter={() => setShowLarge(true)}
        onMouseLeave={() => setShowLarge(false)}
      >
        <SmallImage src={src} alt={alt} />
        {showLarge && (
          <>
            {/* <Overlay /> */}
            <LargeImage src={src} alt={alt} />
          </>
        )}
      </ImageContainer>
    );
  };
  const mycolumns = [
    {
      name: (
        <div>
          <input
            className="form-check-input"
            type="checkbox"
            onChange={handleSelectAllChange}
          />
        </div>
      ),
      width: '10%',
      selector: (row) => {
        return (
          <div>
            <input
              className="form-check-input"
              type="checkbox"
              checked={selectedRows.includes(row._id)}
              onChange={(e) => handleCheckboxChange(e, row)}
            />
          </div>
        );
      },
    },

    {
      name: t("Name"),
      selector: row => row.Name
    },
    // {
    //   name: t("Business"),
    //   cell: (row) => row?.business_details?.name ? row?.business_details?.name : '',
    //   width: '20%',
    // },
    {
      name: t("Image"),
      selector: row => <ImageWithHover src={row.Image} alt={row.Name} />,
    },
    {
      name: t('Status'),
      selector: (row) => {
        return (
          <div>
            {row?.Status === "Active" ? (
              <button
                type="button"
                style={{ borderColor: '#3ac47d', backgroundColor: '#1BD35A' }}
                className="btn btn-sm btn-success btn-hover-shine me-3"
              >
                {row.Status}
              </button>
            ) : (
              <button
                type="button"
                style={{ borderColor: '#FF0000', backgroundColor: 'red' }}
                className="btn btn-sm btn-danger btn-hover-shine me-3"
              >
                {row.Status}
              </button>
            )}
          </div>
        );
      },
      sortable: true,
    },
    {
      name: t("Actions"),
      selector: (row) => {
        return (
          <div>
            <button className="mt-2 mb-2 me-2 btn-icon btn">
              {!super_admin ? current_module?.EditData === 1 &&
                <Tooltip placement="top" title={t("Update")} arrow>
                  <i>
                    <UpdateCategory data={row} onSuccess={handleSuccess} />
                  </i>
                </Tooltip>
                :
                <Tooltip placement="top" title={t("Update")} arrow>
                  <i>
                    <UpdateCategory data={row} onSuccess={handleSuccess} />
                  </i>
                </Tooltip>
              }
              {!super_admin ? current_module?.DeleteData === 1 &&

                <Tooltip placement="top" title={t("Delete")} arrow>
                  <i onClick={() => confirmDelete(row._id)} className="pe-7s-trash text-danger btn-icon-wrapper"> </i>
                </Tooltip>
                :
                <Tooltip placement="top" title={t("Delete")} arrow>
                  <i onClick={() => confirmDelete(row._id)} className="pe-7s-trash text-danger btn-icon-wrapper"> </i>
                </Tooltip>
              }
            </button>
          </div>
        );
      }
    },
  ];
  function handlePageChange(page_index) {
    console.log("Page index:-", page_index)
    setCurrentPage(page_index)
    fetchCategory(page_index, pageLimit, filterText, filterStatus);
  }

  async function handlePerRowsChange(newPageLimit, page_index) {
    setPageLimit(newPageLimit);
  }

  const handleSearch = (searchText, status) => {
    setFilterText(searchText);
    setFilterStatus(status);
    fetchCategory(0, pageLimit, searchText, status);
  };

  const handleClear = () => {
    setFilterText('');
    setFilterStatus('');
    fetchCategory(0, pageLimit, '', '');
  };

  const filteredItems = Category.filter(
    item =>
      (item?.Name?.toLowerCase().includes(filterText?.toLowerCase()) ||
        item?.price?.toLowerCase().includes(filterText?.toLowerCase())
      ) &&
      (filterStatus === '' || item.status === filterStatus)
  );
  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <VariationsFilter
        onSearch={handleSearch}
        onClear={handleClear}
        filterText={filterText}
        filterStatus={filterStatus}
      />
    );
  }, [filterText, filterStatus]);


  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className="bg-light-gray bg-animation">
            <div className="d-flex justify-content-center align-items-center">
              <Col md="12" className="mx-auto app-login-box">
                <div className="app-page-title mb-1">
                  <div className="page-title-wrapper">
                    <div className="page-title-heading fw-bold">
                      <div className="page-title-icon">
                        <i className="pe-7s-note2 icon-gradient bg-tempting-azure" />
                      </div>
                      <div>
                        {t("Category")}
                        <div className="page-title-subheading">
                          {t("View Basic and detailed information of ")} {t("Category")}
                        </div>
                      </div>
                    </div>
                    <div className="page-title-actions">
                      <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-success btn-hover-shine me-3">
                        {t("Back")}
                      </button>
                    </div>
                  </div>
                </div>

                <Row>
                  <Fragment>
                    <Row>
                      <Col md="12">
                        <Card className="main-card mb-3">
                          <CardBody>
                            <LoadingOverlay tag="div" active={loading}
                              styles={{ overlay: (base) => ({ ...base }) }}
                              spinner={<DNALoader />}>
                              <Row className="mt-2">
                                <Col md="6" style={{ float: "left", position: "absolute", zIndex: "1" }}>
                                  {subHeaderComponentMemo}
                                </Col>
                                <Col md="12" className="pt-3">
                                  <DataTable
                                    data={Category}
                                    columns={mycolumns}
                                    pagination
                                    fixedHeader
                                    subHeader
                                    subHeaderComponent={
                                      <div>
                                        <button type="button"
                                          className="btn btn-outline-danger btn-hover-shine me-3 btn flex-end"
                                          onClick={handleDeleteSelected}
                                        >
                                          {t('Delete Selected')}
                                        </button>
                                        {!super_admin ? current_module?.AddData === 1 &&
                                          <AddCategory state={state} onSuccess={handleSuccess} />
                                          :
                                          <AddCategory state={state} onSuccess={handleSuccess} />
                                        }
                                      </div>
                                    }
                                    fixedHeaderScrollHeight="400px"
                                    paginationServer
                                    paginationTotalRows={totalRows}
                                    onChangePage={handlePageChange}
                                    onChangeRowsPerPage={handlePerRowsChange}
                                    paginationResetDefaultPage={resetPaginationToggle}
                                  />
                                  {/* <DataTable
                                    data={Category}
                                    columns={mycolumns}
                                    pagination
                                    fixedHeader
                                    fixedHeaderScrollHeight="400px"
                                  /> */}
                                </Col>
                              </Row>
                            </LoadingOverlay>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Fragment>
                </Row>

              </Col>
            </div>

          </div>
        </div>
      </div>
    </Fragment>
  );
};


export default CustomerDetails;

