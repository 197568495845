import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import QRCode from 'react-qr-code';
import CustomerService from "./Customer.service";

const SuccessPage = () => {
  const { t, i18n } = useTranslation();
  const [logo, setLogo] = useState(localStorage.getItem("system_logo"));
  const [submissionID, setSubmissionID] = useState('');
  const [documents, setDocuments] = useState([]);
  const [documentDetails, setDocumentDetails] = useState([]);
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const reqData = searchParams.get('reqData');

    if (reqData) {
      const decodedData = decodeURIComponent(reqData);
      const parsedData = JSON.parse(decodedData);
      setSubmissionID(parsedData.submissionUid);
      setDocuments(parsedData.acceptedDocuments || []);
      const detailsArray = [];
      parsedData.acceptedDocuments.forEach(doc => {
        const payload = {
          "functionName": "Get_Document",
          "postData": {
            "uuid": doc.uuid
          }
        };
        CustomerService.GetDocument(payload).then((response) => {
          // Extract relevant details and push into detailsArray
          const detail = {
            longID: response.data.longID,
            uuid: doc.uuid,
            internalId: response.data.internalId
          };
          detailsArray.push(detail);
          setDocumentDetails(detailsArray);
        },
          (error) => {
            console.error(error);
          });
      });

    }
  }, [location.search]);

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
  }, [i18n]);

  const pageStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    fontFamily: '"Segoe UI", Tahoma, Geneva, Verdana, sans-serif',
    backgroundColor: '#f0f2f5',
    padding: '20px',
    boxSizing: 'border-box',
    textAlign: 'center',
  };

  const headingStyle = {
    fontSize: '2.5rem',
    marginBottom: '20px',
    display: 'flex',
    alignItems: 'center',
    color: '#333',
    fontWeight: '600',
  };

  const iconStyle = {
    marginRight: '10px',
    color: '#28a745',
    fontSize: '3rem',
  };

  const imageStyle = {
    maxWidth: '150px',
    marginBottom: '20px',
  };

  const subheadingStyle = {
    fontSize: '1.5rem',
    marginBottom: '20px',
    color: '#555',
    fontWeight: '500',
  };

  const infoStyle = {
    fontSize: '1.25rem',
    marginBottom: '10px',
    color: '#777',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  };

  const infoLabelStyle = {
    fontWeight: '200',
    color: '#333',
    flexBasis: '100%',
    textAlign: 'center',
  };

  const documentContainerStyle = {
    flexBasis: '30%',
    marginBottom: '10px',
  };
  const backButtonStyle = {
    padding: '12px 84px',
    backgroundColor: '#007bff',
    color: '#fff',
    borderRadius: '8px',
    textDecoration: 'none',
    marginTop: '20px',
    transition: 'background-color 0.3s ease, transform 0.2s ease',
    cursor: 'pointer',
    fontSize: '1rem',
    display: 'inline-block',
  };

  const backButtonHoverStyle = {
    backgroundColor: '#0056b3',
  };
  return (
    <div style={pageStyle}>
      <img src={logo} alt="Thank You!" style={imageStyle} />
      <FontAwesomeIcon icon={faCheckCircle} style={iconStyle} />
      <h1 style={headingStyle}>
        {t('Your Invoice has been submitted successfully!')}
      </h1>
      <h2 style={subheadingStyle}>{t('Thank you for your submission.')}</h2>
      <div style={infoStyle}>
        <p><span style={infoLabelStyle}>{t('Submission ID')}:</span> {submissionID}</p>
      </div>
      <div style={infoStyle}>
        {documentDetails.map((doc, index) => (
          <div key={index} style={documentContainerStyle}>
            <QRCode value={`https://preprod.myinvois.hasil.gov.my/${doc.uuid}/share/${doc.longID}`} />
            <p style={infoLabelStyle}>{t('Document ID')}: {doc.uuid}</p>
            <p style={infoLabelStyle}>{t('Invoice Number')}: {doc.internalId}</p>
          </div>
        ))}
       
       
      </div>
      {/* QR Code here */}
      <Link
        to="/home"
        style={backButtonStyle}
        onMouseEnter={(e) => e.target.style.backgroundColor = backButtonHoverStyle.backgroundColor}
        onMouseLeave={(e) => e.target.style.backgroundColor = backButtonStyle.backgroundColor}
      >
        {t('Exit')}
      </Link>
    </div>
  );
};

export default SuccessPage;
