// SuccessPage.js
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import CustomerService from "./Customer.service";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import "../../../assets/custom css/loadoverlay.scss";
import Alerts from "../../../components/Alerts/alerts";
import {
  Row,
  Col,
  CardBody,
  Card,
  Label,
  Input
} from "reactstrap";

const SuccessPage = () => {
  const { t, i18n } = useTranslation();
  const [logo, setLogo] = useState(localStorage.getItem("system_logo"));
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [buyerData, setBuyerData] = useState(null);
  const [formData, setFormData] = useState();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const reqData = searchParams.get('reqData');

    if (reqData) {
      const decodedData = decodeURIComponent(reqData);
      const parsedData = JSON.parse(decodedData);
      setBuyerData(parsedData);
      setFormData(parsedData);
    }
  }, [location.search]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      Buyer: {
        ...formData.Buyer,
        [name]: value
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  const onSubmit = (formData) => {
    setLoading(true);
    const payload = {
      "functionName": "generate_e_invoice",
      "postData": formData
    };
    CustomerService.generateEInvoice(payload).then((response) => {
      setLoading(false);
      window.location.href = response.data.redirectURL;
    },
      (error) => {
        const errContent = (error.response && error.response.data) || error.message || error.toString();
        if (errContent) {
          Alerts.swalErrorAlert(errContent);
        } else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."));
        }
      });
  };

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
  }, [i18n]);

  const pageStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#f4f4f4',
    padding: '20px',
    boxSizing: 'border-box',
    textAlign: 'center',
  };

  const headingStyle = {
    fontSize: '2rem',
    marginBottom: '20px',
    display: 'flex',
    alignItems: 'center',
    color: '#333',
  };

  const iconStyle = {
    marginRight: '10px',
    color: '#28a745',
    fontSize: '2.5rem',
  };

  const imageStyle = {
    maxWidth: '150px',
    marginBottom: '20px',
  };

  const subheadingStyle = {
    fontSize: '1.5rem',
    marginBottom: '20px',
    color: '#666',
  };

  const formStyle = {
    backgroundColor: '#fff',
    padding: '10px',
    borderRadius: '10px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    width: '80%',
  };

  const formGroupStyle = {
    marginBottom: '15px',
    textAlign: 'left',
  };

  const labelStyle = {
    display: 'block',
    marginBottom: '5px',
    fontWeight: 'bold',
    color: '#333',
  };

  const inputStyle = {
    width: '100%',
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #ddd',
    fontSize: '1rem',
  };

  const buttonStyle = {
    padding: '8px 50px',
    backgroundColor: '#28a745',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '1rem',
    margin: '10px 5px 0 0',
  };

  const backButtonStyle = {
    padding: '8px 50px',
    backgroundColor: '#007bff',
    color: '#fff',
    borderRadius: '5px',
    textDecoration: 'none',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  };

  const backButtonHoverStyle = {
    backgroundColor: '#0056b3',
  };

  return (
    <div style={pageStyle}>
      <img src={logo} alt="Thank You!" style={imageStyle} />
      <FontAwesomeIcon icon={faCheckCircle} style={iconStyle} />

      <h1 style={headingStyle}>
        {t(`Some of Buyer's Required data is Missing!`)}
      </h1>
      <div style={formStyle}>
        <h2 style={subheadingStyle}>Buyer Information</h2>
        <form onSubmit={handleSubmit}>
          <LoadingOverlay tag="div" active={loading}
            styles={{ overlay: (base) => ({ ...base }) }}
            spinner={<DNALoader />}>
            <Row md='12'>
              <Col md='4'>
                <div style={formGroupStyle}>
                  <label style={labelStyle}>Name:</label>
                  <input type="text" name="name" value={formData?.Buyer?.name || ''} onChange={handleChange} style={inputStyle} />
                </div>
                <div style={formGroupStyle}>
                  <label style={labelStyle}>TIN:</label>
                  <input type="text" name="tin" value={formData?.Buyer?.tin || ''} onChange={handleChange} style={inputStyle} />
                </div>
                <div style={formGroupStyle}>
                  <label style={labelStyle}>ID Type:</label>
                  <input type="text" name="id_type" value={formData?.Buyer?.id_type || ''} onChange={handleChange} style={inputStyle} />
                </div>
                <div style={formGroupStyle}>
                  <label style={labelStyle}>ID Value:</label>
                  <input type="text" name="id_value" value={formData?.Buyer?.id_value || ''} onChange={handleChange} style={inputStyle} />
                </div>
                <div style={formGroupStyle}>
                  <label style={labelStyle}>Registration Number:</label>
                  <input type="text" name="RegistrationNumber" value={formData?.Buyer?.RegistrationNumber || ''} onChange={handleChange} style={inputStyle} />
                </div>
              </Col>
              <Col md='4'>
                <div style={formGroupStyle}>
                  <label style={labelStyle}>SST Registration Number:</label>
                  <input type="text" name="SSTRegistrationNumber" value={formData?.Buyer?.SSTRegistrationNumber || ''} onChange={handleChange} style={inputStyle} />
                </div>
                <div style={formGroupStyle}>
                  <label style={labelStyle}>Buyer Email:</label>
                  <input type="text" name="BuyerEmail" value={formData?.Buyer?.BuyerEmail || ''} onChange={handleChange} style={inputStyle} />
                </div>
                <div style={formGroupStyle}>
                  <label style={labelStyle}>City Name:</label>
                  <input type="text" name="CityName" value={formData?.Buyer?.CityName || ''} onChange={handleChange} style={inputStyle} />
                </div>
                <div style={formGroupStyle}>
                  <label style={labelStyle}>Postal Zone:</label>
                  <input type="text" name="PostalZone" value={formData?.Buyer?.PostalZone || ''} onChange={handleChange} style={inputStyle} />
                </div>
              </Col>
              <Col md='4'>
                <div style={formGroupStyle}>
                  <label style={labelStyle}>Country Subentity Code:</label>
                  <input type="text" name="CountrySubentityCode" value={formData?.Buyer?.CountrySubentityCode || ''} onChange={handleChange} style={inputStyle} />
                </div>
                <div style={formGroupStyle}>
                  <label style={labelStyle}>Address Line 1:</label>
                  <input type="text" name="AddressLine1" value={formData?.Buyer?.AddressLine1 || ''} onChange={handleChange} style={inputStyle} />
                </div>
                <div style={formGroupStyle}>
                  <label style={labelStyle}>Address Line 2:</label>
                  <input type="text" name="AddressLine2" value={formData?.Buyer?.AddressLine2 || ''} onChange={handleChange} style={inputStyle} />
                </div>
                <div style={formGroupStyle}>
                  <label style={labelStyle}>Identification Code Country:</label>
                  <input type="text" name="IdentificationCodeCountry" value={formData?.Buyer?.IdentificationCodeCountry || ''} onChange={handleChange} style={inputStyle} />
                </div>
              </Col>
            </Row>

            <button type="submit" style={buttonStyle}
              onMouseEnter={(e) => e.target.style.backgroundColor = backButtonHoverStyle.backgroundColor}
              onMouseLeave={(e) => e.target.style.backgroundColor = backButtonStyle.backgroundColor}
            >Submit</button>
            <button
              type="button"
              style={backButtonStyle}
              onMouseEnter={(e) => e.target.style.backgroundColor = backButtonHoverStyle.backgroundColor}
              onMouseLeave={(e) => e.target.style.backgroundColor = backButtonStyle.backgroundColor}
              onClick={() => navigate('/exit')}
            >
              {t('Exit')}
            </button>
          </LoadingOverlay>
        </form>
      </div>
    </div >
  );
};

export default SuccessPage;
