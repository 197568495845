import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import { Row, Col, CardBody, Card } from "reactstrap";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import DataTable from 'react-data-table-component';
import BusinessService from "./Businesses.service";
import AuthService from "../Authentication/auth.service";
import Alerts from "../../components/Alerts/alerts";
import { Tooltip } from "@mui/material";
import SectionFilter from "../../components/Table Filters/SectionFilter";
import PermissionAccess from '../../PermissionAccess/Access.js';


const TabSection = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const { authData, accessPages, super_admin, current_module } = PermissionAccess();

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchSections(0, pageLimit);
  }, [pageLimit]);

  function fetchSections(page_index, page_limit, text, status) {
    setLoading(true);
    var offset = (page_index - 1) * page_limit;
    if (offset < 1) {
      offset = 0;
    }
    BusinessService.getAllSections(authData.sessionID,offset, page_limit, text, status,true).then(
      (response) => {
        setLoading(false);
        console.log("Get sections response:-", response);
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        } else {
          console.log(response.data);
          setSections(response.data.data);
          setTotalRows(response.data.count.count);
        }
      },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        if (errContent) {
          Alerts.swalErrorAlert(errContent);
        } else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."));
        }
      }
    );
  }


  const handleCheckboxChange = (e, row) => {
    const { checked } = e.target;
    if (checked) {
      setSelectedRows((prevSelected) => [...prevSelected, row._id]);
    } else {
      setSelectedRows((prevSelected) => prevSelected.filter(id => id !== row._id));
    }
  };

  const handleSelectAllChange = (e) => {
    const { checked } = e.target;
    if (checked) {
      setSelectedRows(sections.map((section) => section._id));
    } else {
      setSelectedRows([]);
    }
  };


  const handleDeleteSelected = () => {
    if (selectedRows.length > 0) {
      let msg = t(selectedRows.length + " records selected. Once deleted, you will not be able to recover this!");
      Alerts.swalConfirmDeleteAlert(msg).then(res => {
        console.log("Resp:-", res)
        if (res === true) {
          sendBulkDelete(selectedRows)
        }
      })
    } else {
      Alerts.swalErrorAlert('No records selected');
    }
  };

  function sendBulkDelete(selectedRows) {
    setLoading(true);
    BusinessService.deleteBulkSection(authData.sessionID,selectedRows).then((response) => {
      console.log("Delete BulkSection response:-", response)
      setLoading(false);
      setSelectedRows([]);
      fetchSections(currentPage, pageLimit, filterText, filterStatus);
      if (response.data.status) {
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Collection Data Deleted Successfully."))
        }
        console.log(response.data.message)
      }
      else {
        setLoading(false);
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't delete the Collection Data at the moment."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Delete operation failed"))
      })
  };

  const mycolumns = [
    {
      name: (
        <div>
          <input
            className="form-check-input"
            type="checkbox"
            onChange={handleSelectAllChange}
          />
        </div>
      ),
      width: '10%',
      selector: (row) => {
        return (
          <div>
            <input
              className="form-check-input"
              type="checkbox"
              checked={selectedRows.includes(row._id)}
              onChange={(e) => handleCheckboxChange(e, row)}
            />
          </div>
        );
      },
    },
    {
      name: t('Name'),
      selector: "name",
      width: "30%",
      sortable: true,
    },
    {
      name: t('Status'),
      width: '30%',
      selector: (row) => {
        return (
          <div>
            {row.status.toLowerCase() === "active" ? (
              <button
                type="button"
                style={{ borderColor: '#3ac47d', backgroundColor: '#1BD35A' }}
                className="btn btn-sm btn-success btn-hover-shine me-3"
              >
                {row.status}
              </button>
            ) : (
              <button
                type="button"
                style={{ borderColor: '#FF0000', backgroundColor: 'red' }}
                className="btn btn-sm btn-danger btn-hover-shine me-3"
              >
                {row.status}
              </button>
            )}
          </div>
        );
      },
      sortable: true,
    },
    {
      name: t('Actions'),
      width: "30%",
      selector: (row) => {
        return (
          <div>
            {!super_admin ? current_module?.UpdateSection === 1 &&
              <Tooltip placement="top" title={`${t("Update")} ${t("Section")}`} arrow>
                <NavLink to={`/edit-section/${row._id}`}><i className="pe-7s-pen btn-icon-wrapper text-primary fs-5 p-1"> </i></NavLink>
              </Tooltip>
              :
              <Tooltip placement="top" title={`${t("Update")} ${t("Section")}`} arrow>
                <NavLink to={`/edit-section/${row._id}`}><i className="pe-7s-pen btn-icon-wrapper text-primary fs-5 p-1"> </i></NavLink>
              </Tooltip>
            }
            {!super_admin ? current_module?.DeleteSection === 1 &&
              <Tooltip placement="top" title={`${t("Delete")} ${t("Section")}`} arrow>
                <a className="DeletetData" onClick={() => confirmDelete(row._id)} style={{ cursor: "pointer" }}>
                  <i className="pe-7s-trash text-danger btn-icon-wrapper fs-5"> </i>
                </a>
              </Tooltip>
              :
              <Tooltip placement="top" title={`${t("Delete")} ${t("Section")}`} arrow>
                <a className="DeletetData" onClick={() => confirmDelete(row._id)} style={{ cursor: "pointer" }}>
                  <i className="pe-7s-trash text-danger btn-icon-wrapper fs-5"> </i>
                </a>
              </Tooltip>
            }
          </div>
        );
      }
    },
  ];


  const handleSearch = (searchText, status) => {
    setFilterText(searchText);
    setFilterStatus(status);
    if (searchText !== '' || status !== '') {
      fetchSections(0, pageLimit, searchText, status)
    }
  };

  const handleClear = () => {
    setFilterText('');
    setFilterStatus('');
    fetchSections(0, pageLimit);
  };
  const filteredItems = sections;
  /*
  const filteredItems = sections.filter(
    item =>
      (item.name.toLowerCase().includes(filterText.toLowerCase()) ) &&
      (filterStatus === '' || item.status === filterStatus)
  );
*/
  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <SectionFilter
        onSearch={handleSearch}
        onClear={handleClear}
        filterText={filterText}
        filterStatus={filterStatus}
      />
    );
  }, [filterText, filterStatus]);

  function confirmDelete(_id) {
    let msg = t("Once deleted, you will not be able to recover this user!")
    Alerts.swalConfirmDeleteAlert(msg).then(res => {
      console.log("Resp:-", res)
      if (res === true) {
        sendDelete(_id)
      }
    })
  };

  function sendDelete(id) {
    setLoading(true);
    BusinessService.deleteSection(authData.sessionID,id).then((response) => {
      console.log("Delete Section response:-", response)
      setLoading(false);
      fetchSections(currentPage, pageLimit, filterText, filterStatus);
      if (response.data.status) {
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Section Deleted Successfully."))
        }
        console.log(response.data.message)
      }
      else {
        setLoading(false);
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't delete the Section."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Delete operation failed"))
      })
  };


  function handlePageChange(page_index) {
    console.log("Page index:-", page_index)
    setCurrentPage(page_index)
    fetchSections(page_index, pageLimit, filterText, filterStatus);
  }

  async function handlePerRowsChange(newPageLimit, page_index) {
    setPageLimit(newPageLimit);
  }

  return (
    <Fragment>
      <Col md="12" className="mx-auto app-login-box">

        <Row>
          <LoadingOverlay
            tag="div"
            active={loading}
            styles={{ overlay: (base) => ({ ...base }) }}
            spinner={<DNALoader />}
          >
            <Col md="12">
              <Card className="main-card mb-3">
                <CardBody>
                  <Col md="6" style={{ float: "left", position: "absolute", zIndex: "1" }}>
                    {subHeaderComponentMemo}
                  </Col>
                  <DataTable
                    data={filteredItems}
                    columns={mycolumns}
                    pagination
                    fixedHeader
                    fixedHeaderScrollHeight="400px"
                    subHeader
                    subHeaderComponent={
                      <div>
                        {!super_admin ? current_module?.BulkDeleteSection === 1 &&
                          <button type="button"
                            className="btn btn-outline-danger btn-hover-shine me-3 btn"
                            onClick={handleDeleteSelected}
                          >
                            {t('Delete Selected')}
                          </button>
                          :
                          <button type="button"
                            className="btn btn-outline-danger btn-hover-shine me-3 btn"
                            onClick={handleDeleteSelected}
                          >
                            {t('Delete Selected')}
                          </button>
                          }
                        {!super_admin ? current_module?.AddSection === 1 &&
                          <NavLink to="/add-section" className="AddData">
                            <button type="button" className="btn btn-custom-color btn-hover-shine me-3 btn">
                              {t('Add New')}
                            </button>
                          </NavLink>
                          :
                          <NavLink to="/add-section" className="AddData">
                            <button type="button" className="btn btn-custom-color btn-hover-shine me-3 btn">
                              {t('Add New')}
                            </button>
                          </NavLink>
                        }
                      </div>
                    }

                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handlePerRowsChange}
                    paginationResetDefaultPage={resetPaginationToggle}

                  />
                </CardBody>
              </Card>
            </Col>
          </LoadingOverlay>
        </Row>
      </Col>
    </Fragment>
  );
};

export default TabSection;
