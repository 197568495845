import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, Link, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Row, Col, Card, CardBody } from "reactstrap";
import Tooltip from '@mui/material/Tooltip';

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import "../../../assets/custom css/loadoverlay.scss";

import GenFilter from "../../../components/Table Filters/GenFilter";
import DataTable from 'react-data-table-component';

import Alerts from "../../../components/Alerts/alerts";

import AttributesService from "./fields.service";
import AuthService from "../../Authentication/auth.service";

import PermissionAccess from '../../../PermissionAccess/Access.js';


const AttributesList = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const { authData, accessPages, super_admin, current_module } = PermissionAccess();


  const { state } = location;
  const [loading, setLoading] = useState(false);
  const [attributeslist, setattributes] = useState([]);

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchAttributes();
  }, []);



  function fetchAttributes() {
    setLoading(true);
    var filter = {};
    AttributesService.getAll(filter, authData.sessionID).then((response) => {
      setLoading(false);
      console.log("Get Attributes response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setattributes(response.data.data);
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  const TBcolumns = [
    // {
    //   name: t('Field Label'),
    //   selector: row => row.field_lable,
    // },
    // {
    //   name: t('Field Type'),
    //   selector: row => row.field_type,
    // },
    // {
    //   name: t('Map'),
    //   selector: row => row.map,
    // },
    // {
    //   name: t('Editable'),
    //   selector: (row) => {
    //     if (parseInt(row.is_editable)) {
    //       return (<div> <button className="btn btn-success btn">{t('Yes')}</button> </div>);
    //     } else {
    //       return (<div> <button className="btn btn-danger btn">{t('No')}</button> </div>);
    //     }

    //   }
    // }, 

    {
      name: t('Field Name'),
      selector: "field_lable",
      sortable: true,
    },
    {
      name: t('Data Type'),
      selector: "field_type",
      sortable: true,
    },
    // {
    //   name: "Show In",
    //   selector: "show_in",
    //   sortable: true,
    // },
    {
      name: t('Show In'),
      selector: (row) => {
        if (row.show_in === 'Items List') {
          return (<div> <button className="btn btn-success btn">{t('Items List')}</button> </div>);
        } else {
          return (<div> <button className="btn btn-primary btn">{t('Invoice List')}</button> </div>);
        }

      }
    },
    {
      name: t('Show in DataTable'),
      selector: (row) => {
        if (parseInt(row.data_table)) {
          return (<div> <button className="btn btn-success btn">{t('Yes')}</button> </div>);
        } else {
          return (<div> <button className="btn btn-danger btn">{t('No')}</button> </div>);
        }

      }
    },
    // {
    //   name: t('Mandatory'),
    //   selector: (row) => {
    //     if (parseInt(row.is_required)) {
    //       return (<div> <button className="btn btn-success btn">{t('Yes')}</button> </div>);
    //     } else {
    //       return (<div> <button className="btn btn-danger btn">{t('No')}</button> </div>);
    //     }

    //   }
    // },
    {
      name: t('Show in PDFs'),
      selector: (row) => {
        if (parseInt(row.pdf_show)) {
          return (<div> <button className="btn btn-success btn">{t('Yes')}</button> </div>);
        } else {
          return (<div> <button className="btn btn-danger btn">{t('No')}</button> </div>);
        }

      }
    },

    {
      name: t('Status'),
      selector: (row) => {
        return (
          <div>
            {row.status === "Active" ?
              <button type="button" style={{ borderColor: '#F39026', backgroundColor: '#F39026' }}
                className="btn btn-success btn-hover-shine me-3 btn">{row.status}
              </button>
              :
              row.status === "Inactive" ?
                <button type="button"
                  className="btn btn-danger btn-hover-shine me-3 btn">{row.status}
                </button>
                :
                <button type="button"
                  className="btn btn-default btn-hover-shine me-3 btn">{row.status}
                </button>
            }
          </div>
        );
      }
    },
    {
      name: t('Actions'),
      selector: (row) => {
        return (
          <div>
            {/* {!super_admin ? current_module?.EditData === 1 && */}
            <Tooltip placement="top" title={t("Edit")} arrow>
              <Link className="EditData" to="/update_field" state={row} >
                <i className="pe-7s-pen icon-gradient bg-arielle-smile btn-lg px-1 "> </i>
              </Link>
            </Tooltip >
            {/*    :
               <Tooltip placement="top" title={t("Edit")} arrow>
                 <Link className="EditData" to="/update_field" state={row} >
                   <i className="pe-7s-pen icon-gradient bg-arielle-smile btn-lg px-1 "> </i>
                 </Link>
               </Tooltip >
             } */}
            {/* {!super_admin ? current_module?.DeleteData === 1 && */}
            <Tooltip placement="top" title={t("Delete")} arrow>
              <a className="DeleteData" onClick={() => confirmDelete(row._id)} style={{ cursor: "pointer" }}>
                <i className="pe-7s-trash icon-gradient bg-ripe-malin btn-lg px-0"> </i>
              </a>
            </Tooltip >
            {/* :
              <Tooltip placement="top" title={t("Delete")} arrow>
                <a className="DeleteData" onClick={() => confirmDelete(row._id)} style={{ cursor: "pointer" }}>
                  <i className="pe-7s-trash icon-gradient bg-ripe-malin btn-lg px-0"> </i>
                </a>
              </Tooltip >
            } */}
          </div>

        );
      }
    },
  ];


  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const filteredItems = React.useMemo(() => {
    if (!filterText) return attributeslist;
    
    return attributeslist.filter(item => {
      const searchText = filterText.toLowerCase();
      return (
        (item.field_lable && item.field_lable.toLowerCase().includes(searchText)) ||
        (item.field_type && item.field_type.toLowerCase().includes(searchText)) ||
        (item.status && item.status.toLowerCase().includes(searchText))
      );
    });
  }, [attributeslist, filterText]);
  const handleSearch = (searchText) => {
    setFilterText(searchText);
  };

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText('');
    }
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <GenFilter
        onSearch={handleSearch}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle])


  function confirmDelete(_id) {
    let msg = t("Once deleted, you will not be able to recover this attribute!")
    Alerts.swalConfirmDeleteAlert(msg).then(res => {
      console.log("Resp:-", res)
      if (res === true) {
        sendDelete(_id)
      }
    })
  };

  function sendDelete(id) {
    setLoading(true);
    AttributesService.deleteAttribute(id).then((response) => {
      setLoading(false);
      console.log("Delete Attribute response:-", response)
      fetchAttributes();
      if (response.data.status) {
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Field Deleted Successfully."))
        }
        console.log(response.data.message)
      }
      else {
        if (response.data.message !== '') {
          Alerts.swalErrorAlert(response.data.message)
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't delete the Field at the moment."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Delete operation failed"))
      })
  };


  return (
    <Fragment>
      <TransitionGroup>
        <CSSTransition component="div" className="TabsAnimation" appear={true}
          timeout={1500} enter={false} exit={false}>
          <div>
            <Row>
              <Col md="12">
                <Card className="main-card mb-3">
                  <CardBody>
                    <LoadingOverlay tag="div" active={loading}
                      styles={{ overlay: (base) => ({ ...base }) }}
                      spinner={<DNALoader />}>
                      <Col md="6" style={{ float: "left", position: "absolute", zIndex: "1" }}>
                        {subHeaderComponentMemo}
                      </Col>
                      <DataTable
                        columns={TBcolumns}
                        data={filteredItems}
                        pagination
                        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                        subHeader
                        subHeaderComponent={<Link to="/add_field" state={{ "crud_id": 1 }} className="AddData">
                          <button type="button" className="btn btn-custom-color btn-hover-shine me-3 btn">{t('Add New')}</button>
                        </Link>}
                        selectableRowDisabled={true}
                        persistTableHead
                      />
                    </LoadingOverlay>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </Fragment>
  );


}

export default AttributesList;

// import React, { useEffect, useState, Fragment } from "react";
// import { useTranslation } from 'react-i18next';
// import { useNavigate, useLocation } from "react-router-dom";
// import { Col, Row, Label, FormGroup, Input, Card, CardBody } from "reactstrap";
// import { Form, Field } from "react-final-form";
// import { format, parseISO } from 'date-fns';

// import LoadingOverlay from "react-loading-overlay-ts";
// import DNALoader from "../../../components/Loaders/DnaLoader";
// import "../../../assets/custom css/loadoverlay.scss";
// import Alerts from "../../../components/Alerts/alerts";
// import { Tooltip } from "@mui/material";
// import AlertService from "../../../components/Alerts/alerts";
// import DataTable from 'react-data-table-component';


// import ConfigurationService from "./configuration.service";
// import AuthService from "../../Authentication/auth.service";


// const TabGenralConfig = () => {

//   const navigate = useNavigate();
//   const [loading, setLoading] = useState(false);
//   const [collection, setCollection] = useState([]);
//   const [pageLimit, setPageLimit] = useState(10);
//   const [customers, setCustomers] = useState([]);
//   const [totalRows, setTotalRows] = useState(0);
//   const [invoice_toValue, setInvoice_toValue] = useState(localStorage.getItem('invoice_to'));
//   const [invoice_forValue, setInvoice_forValue] = useState(localStorage.getItem('invoice_for'));
//   const [currentPage, setCurrentPage] = useState("");

//   const [invoice_to_Data, setInvoice_to_Data] = useState([]);
//   const [invoice_for_Data, setInvoice_for_Data] = useState([]);
//   const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

//   const [configs, setConfig] = useState([]);
//   const { t, i18n } = useTranslation();

//   useEffect(() => {
//     i18n.changeLanguage(localStorage.getItem('language'));
//     getSystemConfig();
//     fetchcollections();

//   }, []);

//   function fetchcollections() {
//     ConfigurationService.fetchcollections().then((response) => {
//       if (response.data.message === "Unauthorized") {
//         AuthService.logout();
//         navigate("/login");
//         window.location.reload();
//       }
//       else {
//         setCollection(response.data.data);
//       }
//     },
//       (error) => {
//         const errContent =
//           (error.response && error.response.data) || error.message || error.toString();
//         if (errContent) {
//           Alerts.swalErrorAlert(errContent)
//         }
//         else {
//           Alerts.swalErrorAlert(t("Server Error, Please try again later."))
//         }
//       })
//   };
//   function getSystemConfig() {
//     ConfigurationService.getConfiguration().then((response) => {
//       console.log("API Response:-", response);
//       if (response.data.status) {
//         const [rec_data] = response.data.data;
//         console.log("setConfig", rec_data);
//         if (rec_data?.invoice_to) {
//           fetchDataForField(rec_data?.invoice_to, 'invoice_to');
//         }
//         if (rec_data?.invoice_for) {
//           fetchDataForField(rec_data?.invoice_for, "invoice_for");
//         }
//         if (rec_data?.invoice_items) {
//           fetchDataForField(rec_data?.invoice_items, "invoice_items");
//         }
//         if (rec_data?.invoice_items_description) {
//           fetchDataForField(rec_data?.invoice_items_description, "invoice_items_description");
//         }

//         setConfig(rec_data);

//       }
//       else {
//         if (response.data.message === "Unauthorized") {
//           AuthService.logout();
//           navigate("/login");
//           window.location.reload();
//         }
//         else {
//           alert(t("Unable to retrieve configurations at the moment."))
//         }
//       }
//     },
//       (error) => {
//         alert(t("Unable to retrieve configurations at the moment."))
//       });
//   }

//   function getFields(itemrec, param) {
//     if (param === 'invoice_to') {
//       console.log(itemrec);
//       setInvoice_toValue(itemrec, param);
//       const data = fetchDataForField(itemrec);
//       
//     }
//     if (param === 'invoice_for') {
//       setInvoice_forValue(itemrec);
//       const data = fetchDataForField(itemrec, param);
//       
//     }
//     // if (param === 'invoice_items') {
//     //   setInvoice_itemsValue(itemrec);
//     //   const data = fetchDataForField(itemrec, param);
//     //   
//     // }
//     // if (param === 'invoice_items_description') {
//     //   setInvoice_items_descriptionValue(itemrec);
//     //   const data = fetchDataForField(itemrec, param);
//     //   
//     // }
//   };

//   function fetchDataForField(itemrec, param) {
//     console.log(itemrec);
//     ConfigurationService.fetchDataForField(itemrec).then((response) => {
//       if (response.data.message === "Unauthorized") {
//         AuthService.logout();
//         navigate("/login");
//         window.location.reload();
//       }
//       else {
//         console.log('Fields', response)
//         if (param === 'invoice_to') {
//           setInvoice_to_Data(response.data.fields);
//         }
//         if (param === 'invoice_for') {
//           setInvoice_for_Data(response.data.fields);
//         }
//       }
//     },
//       (error) => {
//         const errContent =
//           (error.response && error.response.data) || error.message || error.toString();
//         if (errContent) {
//           Alerts.swalErrorAlert(errContent)
//         }
//         else {
//           Alerts.swalErrorAlert(t("Server Error, Please try again later."))
//         }
//       })
//   };

//   function handleSave(values) {
//     setLoading(true);
//     console.log("Form Values:-", values);
//     localStorage.setItem("payment_method", values.payment_method);
//     localStorage.setItem("default_notes", values.default_notes);
//     localStorage.setItem("due_date", values.due_date);
//     localStorage.setItem("tax_percentage", values.tax_percentage);
//     localStorage.setItem("currency", values.currency);
//     localStorage.setItem("invoice_to", invoice_toValue);
//     localStorage.setItem("invoice_for", invoice_forValue);

//     // localStorage.setItem("invoice_items", invoice_itemsValue);
//     // localStorage.setItem("invoice_items_description", invoice_items_descriptionValue);
//     localStorage.setItem("invoice_to_field", values.invoice_to_field);
//     localStorage.setItem("invoice_for_field", values.invoice_for_field);
//     // localStorage.setItem("invoice_items_field", values.invoice_items_field);
//     // localStorage.setItem("invoice_items_description_field", values.invoice_items_description_field);

//     var payloadData = {
//       functionName: "Set_Finance_Config",
//       postData: {
//         payment_method: values.payment_method,
//         default_notes: values.default_notes,
//         due_date: values.due_date,
//         tax_percentage: values.tax_percentage,
//         currency: values.currency,
//         invoice_to: invoice_toValue,
//         invoice_for: invoice_forValue,
//         // invoice_items: invoice_itemsValue,
//         // invoice_items_description: invoice_items_descriptionValue,
//         invoice_to_field: values.invoice_to_field,
//         invoice_for_field: values.invoice_for_field,
//         // invoice_items_field: values.invoice_items_field,
//         // invoice_items_description_field: values.invoice_items_description_field

//         // system_logo: B64converted
//       },
//     }
//     // if (B64converted) {
//     //   payloadData.postData['system_logo'] = B64converted;
//     // }
//     ConfigurationService.setConfiguration(payloadData).then((response) => {
//       console.log("API Response:-", response);
//       setLoading(false);
//       if (response.data.status) {
//         if (response.data.message !== '') {
//           Alerts.swalSuccessAlert(response.data.message)
//         }
//         else {
//           Alerts.swalSuccessAlert(t("Configuration updated successfully."));
//         }
//         getSystemConfig();
//         setTimeout(() => {
//           window.location.reload();
//         }, 2000);
//       }
//       else {
//         if (response.data.message !== '') {
//           if (response.data.message === "Unauthorized") {
//             AuthService.logout();
//             navigate("/login");
//             window.location.reload();
//           }
//           else {
//             Alerts.swalErrorAlert(response.data.message);
//           }
//         }
//         else {
//           Alerts.swalErrorAlert(t("Error: Can't update configuration at the moment. Please try again later."))
//         }
//       }
//     },
//       (error) => {
//         setLoading(false);
//         const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
//         console.log("Error:-", error)
//         Alerts.swalErrorAlert(t("Network Operation failed."))
//       });
//   }

//   const handleEdit = (row, check) => {
//     console.log(row);
//     if (row.lock_status !== 'lock') {
//       navigate('/update-field', {
//         state: {
//           state: row,
//           check: check
//         }
//       });
//     } else {
//       AlertService.swalErrorAlert("Paid Invoice isn't Changeable")
//     }

//   }

//   function handlePageChange(page_index) {
//     console.log("Page index:-", page_index)
//     setCurrentPage(page_index)
//     fetchCustomers(page_index, pageLimit);
//   }

//   function handleDelete(_id, order_no, status) {
//     if (status === 'generated') {
//       let msg = t("Once deleted, you will not be able to recover this Invoice!")
//       Alerts.swalConfirmDeleteAlert(msg).then(res => {
//         console.log("Resp:-", res)
//         if (res === true) {
//           sendDelete(_id, order_no)
//         }
//       })
//     } else {
//       AlertService.swalErrorAlert("Invoice status should be Generated")
//     }
//   };

//   function fetchCustomers(page_index, page_limit, filterText1, filterText2,
//     filterInvoice_StartDate, filterInvoicdue_date,
//     filterDue_StartDate, filterDudue_date, search_text) {
//     var filter = {};
//     console.log(filterText1);
//     if (filterText1) {
//       console.log("filterText1");
//       filter["customer_id"] = filterText1
//     }
//     if (search_text) {
//       console.log("search_text");
//       filter["search_text"] = search_text
//     }
//     if (filterText2) {
//       console.log("filterText1");
//       filter["status"] = filterText2
//     }
//     if (filterDue_StartDate) {
//       console.log("filterDue_StartDate");
//       filter["filterDue_StartDate"] = filterDue_StartDate
//     } if (filterDudue_date) {
//       console.log("filterDudue_date");
//       filter["filterDudue_date"] = filterDudue_date
//     } if (filterInvoice_StartDate) {
//       console.log("filterInvoice_StartDate");
//       filter["filterInvoice_StartDate"] = filterInvoice_StartDate
//     } if (filterInvoicdue_date) {
//       filter["filterInvoicdue_date"] = filterInvoicdue_date
//     }
//     var offset = (page_index - 1) * page_limit;
//     if (offset < 1) {
//       offset = 0;
//     }

//     setLoading(true);

//     ConfigurationService.getAllInvoices(offset, page_limit, filterText1,
//       filterText2, filterInvoice_StartDate, filterInvoicdue_date, filterDue_StartDate, filterDudue_date, search_text).then((response) => {
//         setLoading(false);
//         console.log("Get Customers response:-", response)
//         if (response.data.message === "Unauthorized") {
//           AuthService.logout();
//           navigate("/login");
//           window.location.reload();
//         }
//         else {
//           console.log(response.data);
//           setCustomers(response.data.data);
//           setTotalRows(response.data.count.count);
//         }
//       },
//         (error) => {
//           setLoading(false);
//           const errContent =
//             (error.response && error.response.data) || error.message || error.toString();
//           console.log("Net:-", errContent)
//           if (errContent) {
//             Alerts.swalErrorAlert(errContent)
//           }
//           else {
//             Alerts.swalErrorAlert(t("Server Error, Please try again later."))
//           }
//         })
//   };
//   function sendDelete(id, order_no) {
//     setLoading(true);
//     ConfigurationService.deleteInvoice(id, order_no).then((response) => {
//       setLoading(false);
//       console.log("Delete customer response:-", response)
//       if (response.data.status) {
//         fetchCustomers(currentPage, pageLimit)
//         if (response.data.message !== '') {
//           Alerts.swalSuccessAlert(response.data.message)
//         }
//         else {

//           console.log(response);
//           setLoading(false);
//           Alerts.swalSuccessAlert(t("Invoice Deleted Successfully."))
//         }
//         console.log(response.data.message)
//       }
//       else {
//         if (response.data.message !== '') {
//           if (response.data.message === "Unauthorized") {
//             AuthService.logout();
//             navigate("/login");
//             window.location.reload();
//           }
//           else {
//             Alerts.swalErrorAlert(response.data.message);
//           }
//         }
//         else {
//           Alerts.swalErrorAlert(t("Error: Can't delete the customer at the moment."))
//         }
//       }
//     },
//       (error) => {
//         setLoading(false);
//         const errContent =
//           (error.response && error.response.data) || error.message || error.toString();
//         Alerts.swalErrorAlert(t("Delete operation failed"))
//       })
//   };


//   const mycolumns = [
//     {
//       name: "Field Name",
//       selector: "field_name",
//       width: "12%",
//       sortable: true,
//     },
//     {
//       name: "Data Type",
//       width: "12%",
//       selector: "data_type",
//       sortable: true,
//     },

//     {
//       name: "Mandatory",
//       selector: 'mandatory'
//     },
//     {
//       name: "Show in ALL PDFs",
//       width: "11%",
//       selector: 'pdf_show',
//       sortable: true,
//     },
//     {
//       name: t('Status'),
//       width: '11%',
//       selector: (row) => {
//         return (
//           <div>
//             {row.status === "Active" ?
//               <button type="button" style={{ borderColor: '#F39026', backgroundColor: '#F39026' }}
//                 className="btn btn-success btn-hover-shine me-3 btn">{row.status}
//               </button>
//               :
//               row.status === "Inactive" ?
//                 <button type="button"
//                   className="btn btn-danger btn-hover-shine me-3 btn">{row.status}
//                 </button>
//                 :
//                 <button type="button"
//                   className="btn btn-default btn-hover-shine me-3 btn">{row.status}
//                 </button>
//             }
//           </div>
//         );
//       }
//     },
//     {
//       name: 'Actions',
//       selector: (row) => {
//         return (
//           <div>
//             <button className="mt-2 mb-2 me-2 btn-icon btn">
//               <Tooltip placement="top" title="Update Field" arrow>
//                 <i>
//                   {/* <Link to="/update-invoice" state={{ state: row, check: false }}> */}
//                   <i onClick={() => handleEdit(row, false)} className="pe-7s-pen btn-icon-wrapper text-primary"> </i>
//                   {/* </Link> */}
//                 </i>
//               </Tooltip>
//               <Tooltip placement="top" title="Delete Field" arrow>
//                 <i onClick={() => handleDelete(row._id, row.order_no, row.status)} className="pe-7s-trash text-danger btn-icon-wrapper"> </i>
//               </Tooltip>
//             </button>
//           </div>
//         );
//       }
//     },
//   ];
//   async function handlePerRowsChange(newPageLimit, page_index) {
//     setPageLimit(newPageLimit);
//   }

//   return (
//     <Fragment>
//       <Card className="main-card mb-3">
//         <CardBody className="">
//           <div className="d-flex justify-content-end mb-3">
//             <button
//               type="button"
//               className="btn btn-custom-color btn-hover-shine"
//               onClick={() => navigate('/add_field')}
//             >
//               Add New
//             </button>
//           </div>
//           <DataTable
//             data={customers}
//             columns={mycolumns}
//             pagination
//             fixedHeader
//             fixedHeaderScrollHeight="400px"
//             paginationServer
//             paginationTotalRows={totalRows}
//             onChangePage={handlePageChange}
//             onChangeRowsPerPage={handlePerRowsChange}
//             paginationResetDefaultPage={resetPaginationToggle}
//           />
//         </CardBody>
//       </Card>
//     </Fragment>

//   );

// };
// export default TabGenralConfig; 