import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardService from "../dashboard.service";
import AuthService from "../../Authentication/auth.service";
import { useTranslation } from 'react-i18next';
import BrownDiamond from "../../../assets/bronze.jpeg";
import OrangeDiamond from "../../../assets/silver.jpeg";
import BlueDiamond from "../../../assets/gold.jpeg";
import YellowDiamond from "../../../assets/diamond.jpeg";
import Minus from "../../../assets/minus.svg";
import Add from "../../../assets/add.svg";
import PerfectScrollbar from "react-perfect-scrollbar";
import 'react-perfect-scrollbar/dist/css/styles.css';
import { IconButton, Drawer, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Row, Modal, ModalBody, ModalHeader } from 'reactstrap';
import Alerts from "../../../components/Alerts/alerts";
import AlertService from '../../../components/Alerts/alerts';
import WelcomePage from "./WelcomePage";
import Swal from 'sweetalert2';
import './MemberDashboard.css';
import axios from "axios";
const Image_URL = process.env.REACT_APP_IMAGEURL;
const API_URL = process.env.REACT_APP_LOGINURL;

const Loader = () => (
  <div style={{
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999
  }}>
    <div style={{
      width: '50px',
      height: '50px',
      border: '5px solid #C1B314',
      borderTop: '5px solid transparent',
      borderRadius: '50%',
      animation: 'spin 1s linear infinite'
    }} />
    <style>{`
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    `}</style>
  </div>
);

const Home = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [PackagesData, setPackagesData] = useState([]);
  const [showDrawer, setShowDrawer] = useState(false);
  const [showProductDrawer, setShowProductDrawer] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [selectedOrderType, setSelectedOrderType] = useState(null);
  const [selectedDeliveryAddress, setSelectedDeliveryAddress] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [paymentProof, setPaymentProof] = useState(null); // Holds the file details
  const [paymentProofBase64, setPaymentProofBase64] = useState(null); // Holds the Base64 string
  const [customAddress, setCustomAddress] = useState("");
  const [receiver_name, setReceiver_name] = useState("");
  const [receiver_phone, setReceiver_phone] = useState("");
  const [Products, setProducts] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [showWelcome, setShowWelcome] = useState(false);
  const [totalMPV, setTotalMPV] = useState(0);
  const [sponsorInventory, setSponsorInventory] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState(
    JSON.parse(localStorage.getItem("selectedCurrency")) || {
      code: "PHP",
      rate: 1,
      symbol: "₱",
    }
  );
  const theme = {
    overlayColor: "rgba(0, 0, 0, 0.8)",
    cardBackgroundColor: "#ffffff",
    textColor: "#000000",
    progressBarColor: "#C1B314",
    progressTrackColor: "#e0e0e0",
    buttonPrimaryColor: "#C1B314",
    buttonSecondaryColor: "#6c757d",
    buttonSuccessColor: "#28a745",
    skipTextColor: "#999",
  };




  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  function FetchStockistStock() {
    setLoading(true);
    const userString = localStorage.getItem('user');
    const user = JSON.parse(userString);
    let referral_id = user.referral_id
    DashboardService.getStockistStock(referral_id).then((response) => {
      setLoading(false);
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        if (response.data.data) {
          console.log("ALL Stockist Stock", response.data.data);
          setSponsorInventory(response?.data?.data?.[0]?.Items || []);
        }
      }
    },
      (error) => {
        setLoading(false);
        const errContent = (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  useEffect(() => {
    FetchStockistStock();
  }, []);

  const filteredProducts = Products.map((product) => {
    const sponsorItem = sponsorInventory.find((item) => item.product_id === product._id);
    return {
      ...product,
      isAvailable: !!sponsorItem,
      sponsorQuantity: sponsorItem ? sponsorItem.quantity : 0,
    };
  })
  // .sort((a, b) => b.isAvailable - a.isAvailable); // Sort isAvailable items first

  const styles = {
    ...{
      card: {
        width: '300px',
        height: '100%',
        backgroundColor: '#fff',
        borderRadius: '12px',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
        padding: '20px',
        cursor: 'pointer',
        fontFamily: 'Arial, sans-serif',
        textAlign: 'center',
        margin: 'auto',
        transition: '0.3s',
        border: '2px solid transparent',
      },
      cardSelected: {
        border: '2px solid #C1B314',
      },
      button: {
        backgroundColor: 'transparent',
        border: '2px solid #000',
        borderRadius: '8px',
        padding: '10px 20px',
        fontSize: '1em',
        cursor: 'pointer',
        transition: '0.3s',
      },
      buttonSelected: {
        backgroundColor: '#C1B314',
        color: '#000',
        border: '2px solid #C1B314',
      },
      // drawer: {
      //   position: 'fixed',
      //   top: 90,
      //   right: showDrawer ? 6 : showProductDrawer ? 6 : '-500px',
      //   width: isMobile ? '100%' : '31%',
      //   height: 'calc(100vh - 90px)',
      //   backgroundColor: '#fff',
      //   boxShadow: '0 0 10px rgba(0,0,0,0.3)',
      //   transition: 'right 0.3s ease-in-out',
      //   zIndex: '100',
      //   padding: '20px',
      //   borderRadius: '20px',
      //   overflow: 'auto', // Enable scrolling
      //   scrollbarWidth: 'thin', // For Firefox
      // },
      // scrollbar: {
      //   '&::-webkit-scrollbar': {
      //     width: '6px',
      //   },
      //   '&::-webkit-scrollbar-track': {
      //     background: '#f1f1f1',
      //   },
      //   '&::-webkit-scrollbar-thumb': {
      //     background: '#888',
      //     borderRadius: '8px',
      //   },
      //   '&::-webkit-scrollbar-thumb:hover': {
      //     background: '#555',
      //   },
      // },
      // overlay: {
      //   position: 'fixed',
      //   top: '0',
      //   left: '0',
      //   width: '100%',
      //   height: '100%',
      //   backgroundColor: 'rgba(0, 0, 0, 0.5)',
      //   display: showDrawer ? 'block' : showProductDrawer ? 'block' : 'none',
      //   zIndex: '99', // Slightly below the drawer
      // },
      spanStyle: {
        fontFamily: 'Metropolis',
        fontSize: '1.1rem',
        color: 'black',
      },

      drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'Metropolis',
        fontSize: '1.2rem',
        fontWeight: '500px',
        color: 'black',
        marginBottom: '10px',
      },
      drawerCloseButton: {
        fontSize: '2rem',
        cursor: 'pointer',
        border: 'none',
        background: 'none',
      },
      drawerSelect: {
        width: '100%',
        padding: '10px',
        border: '1px solid #ccc',
        borderRadius: '8px',
        fontSize: '1rem',
        backgroundColor: '#fff',
        position: 'relative',
      },
      drawerButtonGroup: {
        display: 'flex',
        gap: '10px',
        marginBottom: '20px',
      },
      drawerButton: {
        flex: 1,
        padding: '10px',
        borderRadius: '8px',
        fontSize: '1rem',
        fontWeight: 'bold',
        cursor: 'pointer',
        border: '1px solid #ccc',
        textAlign: 'center',
      },
      drawerButtonActive: {
        backgroundColor: '#C1B314',
        color: '#fff',
        borderColor: '#C1B314',
      },
      drawerUploadSection: {
        border: '1px dashed #ccc',
        borderRadius: '8px',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
        marginBottom: '20px',
      },
      drawerFooter: {
        display: 'flex',
        marginTop: '10px',
        flexDirection: 'column',
        gap: '10px',
      },
      drawerFooterButton: {
        width: '100%',
        padding: '10px',
        borderRadius: '8px',
        fontSize: '1rem',
        fontWeight: 'bold',
        cursor: 'pointer',
      },
      drawerFooterPrimary: {
        backgroundColor: '#C1B314',
        color: '#fff',
        border: 'none',
      },
      drawerFooterSecondary: {
        backgroundColor: '#f5f5f5',
        color: '#333',
        border: '1px solid #ccc',
      },
      drawerButtonActive: {
        backgroundColor: '#C1B314', // Active background color
        color: '#fff', // Active text color
        borderColor: '#C1B314', // Match active border
      },

    },
    ...{
      header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      },
      icon: {
        width: '120px',
        height: '120px',
      },
      title: {
        fontSize: '1.5em',
        color: 'black',
        margin: '5px 0',
        fontWeight: 'bold',
      },
      priceText: {
        fontSize: '2em',
        fontWeight: '500',
        color: 'black',
      },
      points: {
        color: 'black',
        margin: '5px 0',
        fontSize: '1.2em',
      },
      divider: {
        border: '0.5px solid #d1d1d1',
        margin: '15px 0',
      },
      description: {
        fontSize: '1.2em',
        color: 'black',
        lineHeight: '1.5',
        height: '50vh', // Fixed height
        overflowY: 'auto', // Add scroll if content exceeds height
        margin: '10px 0', // Add spacing
        textAlign: 'center', // Center-align the text
        display: 'flex', // Flexbox for vertical alignment
        alignItems: 'center', // Center content vertically
        justifyContent: 'center', // Center content horizontally
      },
    },
  };

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchPackagesData();
  }, []);

  const fetchProducts = () => {
    setLoading(true);
    DashboardService.getProducts()
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          console.log("Products response.data.data", response.data.data)
          setProducts(response.data.data);
        } else {
          console.error("Failed to fetch dashboard data");
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching dashboard data:", error);
        if (error.response?.data?.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        }
      });
  };

  const fetchPackagesData = () => {
    setLoading(true);
    DashboardService.GetPackagesData(localStorage.getItem("session-id"))
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          setPackagesData(response.data.data);
        } else {
          console.error("Failed to fetch dashboard data");
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching dashboard data:", error);
        if (error.response?.data?.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        }
      });
  };

  // Recalculate Total Price and MPV whenever Products change
  useEffect(() => {
    const totals = Products.reduce(
      (acc, product) => {
        const quantity = product.quantity || 0;
        const price = parseFloat(product.price || 0);
        const mpv = parseFloat(product.item_mpv || 0);
        acc.price += quantity * price;
        acc.mpv += quantity * mpv;
        return acc;
      },
      { price: 0, mpv: 0 }
    );
    setTotalPrice(totals.price);
    setTotalMPV(totals.mpv);
  }, [Products]);



  const GetBasicData = () => {
    fetchProducts();
    setShowProductDrawer(true);
  };

  const HandleSubmit = () => {
    const selectedPackageDetails = selectedPackage !== null ? PackagesData[selectedPackage] : null;

    // Filter selected products
    const selectedProducts = Products.filter((product) => product.isSelected && product.quantity > 0).map((product) => ({
      id: product._id,
      name: product.item_name,
      quantity: product.quantity,
      price: product.price,
      totalPrice: product.quantity * product.price,
      mpv: product.item_mpv,
      totalMPV: product.quantity * product.item_mpv,
    }));

    if (totalMPV > selectedPackageDetails.mpv) {
      return AlertService.swalErrorAlert("Selected Item's MPV must not be exceed then Package's MPV")
    }


    setShowDrawer(false);
    setShowProductDrawer(false);
    const userString = localStorage.getItem('user');
    const user = JSON.parse(userString);
    const userId = user['_id'];
    let Payload = {
      functionName: "AddPackageToUser",
      postData: {
        user_id: userId,
        selectedPackage: selectedPackageDetails,
        totalMPV: totalMPV,
        totalPrice: totalPrice,
        selectedOrderType: selectedOrderType,
        selectedDeliveryAddress: selectedDeliveryAddress,
        customAddress: customAddress,
        receiver_name: receiver_name,
        receiver_phone: receiver_phone,
        paymentProofBase64: paymentProofBase64,
        selectedProducts: selectedProducts
      }
    };
    console.log("Payload", Payload)



    // HandlePlaceOrder(selectedProducts, selectedDeliveryAddress, customAddress, totalPrice, totalMPV, Payload)

    setLoading(true);
    DashboardService.AddPackageToUser(localStorage.getItem("session-id"), Payload)
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          Alerts.swalClickProceedAlert(response.data.message).then(res => {
            console.log("Resp:-", res)
            if (res === true) {
              (navigate("/Member-profile"))
            }
          })
        } else {
          AlertService.swalErrorAlert(response.data.message);
          console.error("Failed to fetch dashboard data");
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching dashboard data:", error);
        if (error.response?.data?.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        }
      });


  };

  const updateFirstLoginStatus = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("user")); // Retrieve user data from localStorage
      if (!user || !user._id) {
        console.error("User data not found in localStorage");
        return;
      }
      const headers = {
        'Content-Type': 'application/json',
        // 'token': localStorage.getItem("session-id")
      };

      // API call payload
      const payload = {
        functionName: "Update_First_Login",
        postData: {
          user_id: user._id,
          first_login: false,
        },
      };

      // API Call
      const response = await axios.post(`${API_URL}UserModule/Update_First_Login`, payload, headers);

      if (response.data.status) {
        console.log("First login status updated successfully:", response.data);
      } else {
        console.error("Failed to update first login status:", response.data.message);
      }
    } catch (error) {
      console.error("Error updating first login status:", error);
    }
  };


  useEffect(() => {
    const isFirstLogin = localStorage.getItem("first_login");
    console.log("localStorage.getItem", isFirstLogin)
    if (isFirstLogin === "true") {
      setShowWelcome(true);
      // localStorage.setItem("first_login", "false");
    }
  }, []);

  const handleSkip = async () => {
    localStorage.setItem("first_login", "false");
    await updateFirstLoginStatus();
    setShowWelcome(false);
  };

  const handleComplete = async () => {
    localStorage.setItem("first_login", "false");
    await updateFirstLoginStatus();
    setShowWelcome(false);
  };


  const updateQuantity = (index, delta) => {
    setProducts((prevProducts) => {
      const updatedProducts = prevProducts.map((product, i) => {
        if (i === index) {
          const sponsorItem = sponsorInventory.find((item) => item.product_id === product._id);

          // Ensure sponsor item exists and validate decrements
          if (!sponsorItem) {
            Swal.fire({
              title: "Error",
              text: "This product is not available in sponsor inventory.",
              icon: "error",
              confirmButtonText: "OK",
              customClass: {
                popup: 'swal2-popup',
                title: 'swal2-title',
                content: 'swal2-content',
                confirmButton: 'swal2-confirm',
              },
            });
            return product;
          }

          // Calculate the new quantity
          const newQuantity = Math.max((product.quantity || 0) + delta, 0);

          // Prevent decrementing below sponsor's initial stock
          if (delta < 0 && newQuantity < sponsorItem.quantity) {
            Swal.fire({
              title: "Error",
              text: `You cannot reduce the quantity below the sponsor's available stock (${sponsorItem.quantity}).`,
              icon: "error",
              confirmButtonText: "OK",
              customClass: {
                popup: 'swal2-popup',
                title: 'swal2-title',
                content: 'swal2-content',
                confirmButton: 'swal2-confirm',
              },
            });
            return product;
          }

          // Prevent exceeding sponsor's stock
          if (delta > 0 && newQuantity > sponsorItem.quantity) {
            Swal.fire({
              title: "Error",
              text: `Cannot increase quantity beyond the sponsor's available stock (${sponsorItem.quantity}).`,
              icon: "error",
              confirmButtonText: "OK",
              customClass: {
                popup: 'swal2-popup',
                title: 'swal2-title',
                content: 'swal2-content',
                confirmButton: 'swal2-confirm',
              },
            });
            return product;
          }

          // Validate MPV limits
          const newMPV = totalMPV - (product.quantity || 0) * product.item_mpv + newQuantity * product.item_mpv;
          if (newMPV > PackagesData[selectedPackage].mpv) {
            Swal.fire({
              title: "Error",
              text: `Cannot exceed the package MPV limit`,
              icon: "error",
              confirmButtonText: "OK",
              customClass: {
                popup: 'swal2-popup',
                title: 'swal2-title',
                content: 'swal2-content',
                confirmButton: 'swal2-confirm',
              },
            });
            return product;
          }

          // Update the sponsor inventory for decrements
          if (delta < 0) {
            setSponsorInventory((prevSponsorInventory) =>
              prevSponsorInventory.map((item) =>
                item.product_id === product._id
                  ? { ...item, quantity: item.quantity + Math.abs(delta) }
                  : item
              )
            );
          }

          // Update the sponsor inventory for increments
          if (delta > 0) {
            setSponsorInventory((prevSponsorInventory) =>
              prevSponsorInventory.map((item) =>
                item.product_id === product._id
                  ? { ...item, quantity: item.quantity - delta }
                  : item
              )
            );
          }

          return {
            ...product,
            quantity: newQuantity,
            isSelected: newQuantity > 0,
          };
        }
        return product;
      });

      return updatedProducts;
    });
  };
  const handleCheckboxChange = (index, checked) => {
    setProducts((prevProducts) => {
      let currentMPV = totalMPV; // Track the current total MPV

      const updatedProducts = prevProducts.map((product, i) => {
        if (i === index) {
          const sponsorItem = sponsorInventory.find((item) => item.product_id === product._id);

          if (checked) {
            // Ensure sponsor item exists and has stock
            if (!sponsorItem || sponsorItem.quantity <= 0) {
              Swal.fire({
                title: "Error",
                text: "This product is not available in sponsor inventory.",
                icon: "error",
                confirmButtonText: "OK",
              });
              return product;
            }

            const newMPV = currentMPV + product.item_mpv; // Calculate new MPV if adding this product

            // Prevent exceeding package MPV
            if (newMPV > PackagesData[selectedPackage].mpv) {
              Swal.fire({
                title: "Error",
                text: `Adding this product exceeds the package MPV limit.`,
                icon: "error",
                confirmButtonText: "OK",
              });
              return product; // Do not add the product
            }

            // Deduct sponsor stock and update MPV
            setSponsorInventory((prevSponsorInventory) =>
              prevSponsorInventory.map((item) =>
                item.product_id === product._id
                  ? { ...item, quantity: item.quantity - 1 }
                  : item
              )
            );

            currentMPV += product.item_mpv;

            return { ...product, quantity: 1, isSelected: true };
          } else {
            // Restore sponsor stock when unchecking
            setSponsorInventory((prevSponsorInventory) =>
              prevSponsorInventory.map((item) =>
                item.product_id === product._id
                  ? { ...item, quantity: item.quantity + product.quantity }
                  : item
              )
            );

            return { ...product, quantity: 0, isSelected: false };
          }
        }
        return product;
      });

      return updatedProducts;
    });
  };

  const selectMaxProducts = () => {
    setProducts((prevProducts) => {
      const packageMPV = PackagesData[selectedPackage]?.mpv || 0; // Package MPV limit
      let currentMPV = 0; // Track current MPV usage

      const updatedProducts = prevProducts.map((product) => {
        const sponsorItem = sponsorInventory.find((item) => item.product_id === product._id);

        if (sponsorItem && sponsorItem.quantity > 0) {
          const remainingMPV = packageMPV - currentMPV;
          const maxAllowedQuantity = Math.min(
            Math.floor(remainingMPV / product.item_mpv),
            sponsorItem.quantity
          );

          if (maxAllowedQuantity > 0) {
            currentMPV += maxAllowedQuantity * product.item_mpv;

            // Update sponsor inventory
            setSponsorInventory((prevSponsorInventory) =>
              prevSponsorInventory.map((item) =>
                item.product_id === product._id
                  ? { ...item, quantity: item.quantity - maxAllowedQuantity }
                  : item
              )
            );

            return {
              ...product,
              quantity: maxAllowedQuantity,
              isSelected: true,
            };
          }
        }

        return { ...product, quantity: 0, isSelected: false };
      });

      return updatedProducts;
    });
  };


  const deselectAllProducts = () => {
    setProducts((prevProducts) => {
      // Re-add quantities back to sponsor inventory
      setSponsorInventory((prevSponsorInventory) => {
        const updatedSponsorInventory = prevSponsorInventory.map((item) => {
          const product = prevProducts.find((prod) => prod._id === item.product_id);

          // Restore the sponsor quantity by adding back the product quantity
          if (product && product.quantity > 0) {
            return {
              ...item,
              quantity: item.quantity + product.quantity,
            };
          }
          return item;
        });

        return updatedSponsorInventory;
      });

      // Reset all product quantities and selection
      return prevProducts.map((product) => ({
        ...product,
        quantity: 0,
        isSelected: false,
      }));
    });
  };

  const isDisabled = (product) => {
    return (
      totalMPV >= PackagesData[selectedPackage].mpv && product.quantity === 0
    );
  };




  return (
    <div className="app-main__outer">
      <div className="container mt-4">
        {showWelcome && <WelcomePage onSkip={handleSkip} onComplete={handleComplete} theme={theme} />}
        <Row style={{ marginTop: '20px', marginBottom: '20px' }} className="g-4 mb-4">
          {PackagesData?.map((pkg, index) => (
            <div
              key={index}
              style={{
                ...styles.card, marginBottom: '20px',
                ...(selectedPackage === index ? styles.cardSelected : {}),
              }}
              onMouseEnter={(e) => {
                if (selectedPackage !== index) {
                  e.currentTarget.style.border = styles.cardSelected.border;
                }
              }}
              onMouseLeave={(e) => {
                if (selectedPackage !== index) {
                  e.currentTarget.style.border = styles.card.border;
                }
              }}
              onClick={() => {
                setSelectedPackage(index);
                setShowDrawer(true);
              }}

            >
              <div style={styles.header}>
                <img
                  src={
                    index === 0
                      ? BrownDiamond
                      : index === 1
                        ? OrangeDiamond
                        : index === 2
                          ? BlueDiamond
                          : YellowDiamond
                  }
                  alt="Package Icon"
                  style={styles.icon}
                />
                <h2 style={styles.title}>{pkg.name}</h2>
              </div>
              <div>
                <h3
                  style={{
                    ...styles.priceText,
                    color:
                      selectedPackage === index
                        ? '#C1B314'
                        : styles.priceText.color,
                  }}
                >
                  {selectedCurrency.symbol}  {parseFloat(pkg.price * selectedCurrency.rate).toFixed(2)}
                </h3>
                <p style={styles.points}>{pkg.mpv} MPV</p>
              </div>
              <hr style={styles.divider} />
              <div>
                <PerfectScrollbar>
                  <p style={styles.description}>{pkg.description}</p>
                </PerfectScrollbar>
              </div>
              <hr style={styles.divider} />
              <button
                style={{
                  ...styles.button,
                  ...(selectedPackage === index ? styles.buttonSelected : {}),
                }}
              >
                Select
              </button>
            </div>
          ))}
        </Row>
      </div>


      {/* Right Drawer */}
      <Drawer
        anchor="right"
        open={showDrawer}
        onClose={() => { setShowDrawer(false) }}
        sx={{
          "& .MuiDrawer-paper": {
            width: "450px",
            padding: "20px",
            marginTop: '20px',
            marginBottom: '20px',
            marginRight: '10px',
            borderRadius: "15px 15px 15px 15px",
          },
        }}
      >
        <PerfectScrollbar>
          <div>
            <div style={styles.drawerHeader}>
              <button
                onClick={() => { setShowDrawer(false); setShowProductDrawer(false) }}
                style={styles.drawerCloseButton}
              >
                &times;
              </button>
              <span style={{ marginTop: '4px', marginLeft: '10px' }} >Complete Purchase Order</span>
            </div>
            <Typography style={{ ...styles.spanStyle, marginTop: '10px' }} >Package</Typography>
            <div style={{ position: 'relative' }}>
              <div
                style={{
                  ...styles.drawerSelect,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => setShowDropdown((prev) => !prev)}
              >
                {selectedPackage !== null ? (
                  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <img
                      src={
                        selectedPackage === 0
                          ? BrownDiamond
                          : selectedPackage === 1
                            ? OrangeDiamond
                            : selectedPackage === 2
                              ? BlueDiamond
                              : YellowDiamond
                      }
                      alt="Selected Package Icon"
                      style={{ width: '20px', height: '20px' }}
                    />
                    <span>{PackagesData[selectedPackage]?.name}</span>
                  </div>
                ) : (
                  <span>Select a Package</span>
                )}
                <span>▼</span> {/* Dropdown arrow */}
              </div>
              {showDropdown && (
                <div
                  style={{
                    position: 'absolute',
                    top: '100%',
                    left: 0,
                    width: '100%',
                    backgroundColor: '#fff',
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                    zIndex: '10',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    maxHeight: '200px',
                    overflowY: 'auto',
                  }}
                >
                  {PackagesData?.map((pkg, index) => (
                    <div
                      key={index}
                      style={{
                        padding: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        cursor: 'pointer',
                        backgroundColor: selectedPackage === index ? '#C1B314' : '#fff',
                        color: selectedPackage === index ? '#fff' : '#000',
                      }}
                      onClick={() => {
                        setSelectedPackage(index);
                        setShowDropdown(false);
                      }}
                    >
                      <img
                        src={
                          index === 0
                            ? BrownDiamond
                            : index === 1
                              ? OrangeDiamond
                              : index === 2
                                ? BlueDiamond
                                : YellowDiamond
                        }
                        alt="Package Icon"
                        style={{ width: '20px', height: '20px' }}
                      />
                      <span>{pkg.name}</span>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div>
              <Typography style={{ ...styles.spanStyle, marginTop: '20px' }}>Order Type</Typography>
              <div style={styles.drawerButtonGroup}>
                <button
                  style={{
                    ...styles.drawerButton,
                    ...(selectedOrderType === "delivery" ? styles.drawerButtonActive : {}),
                  }}
                  onClick={() => setSelectedOrderType("delivery")}
                >

                  Delivery
                </button>
                <button
                  style={{
                    ...styles.drawerButton,
                    ...(selectedOrderType === "pickup" ? styles.drawerButtonActive : {}),
                  }}
                  onClick={() => setSelectedOrderType("pickup")}
                >
                  Pick-Up
                </button>
              </div>
            </div>

            <div>
              <span style={{ ...styles.spanStyle, marginTop: '4px' }}>Delivery Address</span>
              <div style={styles.drawerButtonGroup}>
                <button
                  style={{
                    ...styles.drawerButton,
                    ...(selectedDeliveryAddress === "same" ? styles.drawerButtonActive : {}),
                  }}
                  onClick={() => {
                    setSelectedDeliveryAddress("same");
                    setCustomAddress("");
                    setReceiver_name("");
                    setReceiver_phone("");
                  }}
                >
                  Same Address
                </button>
                <button
                  style={{
                    ...styles.drawerButton,
                    ...(selectedDeliveryAddress === "different" ? styles.drawerButtonActive : {}),
                  }}
                  onClick={() => setSelectedDeliveryAddress("different")}
                >
                  Different Address
                </button>
              </div>

              {selectedDeliveryAddress === "different" && (
                <>
                  <div style={{ marginTop: "10px" }}>
                    <label htmlFor="fullName" style={{ display: "block", marginBottom: "5px", fontWeight: "bold" }}>
                      Full Name:
                    </label>
                    <input
                      type="text"
                      id="fullName"
                      value={receiver_name}
                      onChange={(e) => setReceiver_name(e.target.value)}
                      placeholder="Enter receiver name"
                      style={{
                        width: "100%",
                        padding: "10px",
                        border: "1px solid #ccc",
                        borderRadius: "8px",
                        fontSize: "1rem",
                      }}
                    />
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <label htmlFor="contactNumber" style={{ display: "block", marginBottom: "5px", fontWeight: "bold" }}>
                      Contact Number:
                    </label>
                    <input
                      type="text"
                      id="contactNumber"
                      value={receiver_phone}
                      onChange={(e) => setReceiver_phone(e.target.value)}
                      placeholder="Enter receiver phone"
                      style={{
                        width: "100%",
                        padding: "10px",
                        border: "1px solid #ccc",
                        borderRadius: "8px",
                        fontSize: "1rem",
                      }}
                    />
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <label htmlFor="customAddress" style={{ display: "block", marginBottom: "5px", fontWeight: "bold" }}>
                      Enter Delivery Address:
                    </label>
                    <input
                      type="text"
                      id="customAddress"
                      value={customAddress}
                      onChange={(e) => setCustomAddress(e.target.value)}
                      placeholder="Enter receiver address"
                      style={{
                        width: "100%",
                        padding: "10px",
                        border: "1px solid #ccc",
                        borderRadius: "8px",
                        fontSize: "1rem",
                      }}
                    />
                  </div>
                </>
              )}
            </div>


            <div style={{ marginTop: '10px' }}>
              <span style={{ ...styles.spanStyle, marginTop: '4px' }}>Payment Proof</span>
              <div
                style={{
                  ...styles.drawerUploadSection,
                  borderColor: paymentProof ? '#C1B314' : '#ccc', // Highlight if uploaded
                }}
              >
                <input
                  type="file"
                  accept="image/*,.pdf"
                  id="paymentProofUpload"
                  style={{ display: 'none' }}
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file) {
                      // Convert to Base64
                      const reader = new FileReader();
                      reader.onloadend = () => {
                        setPaymentProofBase64(reader.result); // Store Base64 string
                        setPaymentProof(file); // Store file details
                      };
                      reader.readAsDataURL(file); // Read file as Base64
                    }
                  }}
                />
                <label htmlFor="paymentProofUpload" style={{ cursor: 'pointer' }}>
                  {paymentProof ? (
                    <>
                      <p style={{ color: '#C1B314' }}>File Uploaded: {paymentProof.name}</p>
                    </>
                  ) : (
                    <p>+ Upload Payment Proof (Image or PDF)</p>
                  )}
                </label>
              </div>
              {paymentProof && (
                <div style={{ marginTop: '10px', textAlign: 'center' }}>
                  {paymentProof.type.startsWith('image') ? (
                    <img
                      src={paymentProofBase64}
                      alt="Uploaded Proof"
                      style={{ maxWidth: '100%', height: 'auto', borderRadius: '8px' }}
                    />
                  ) : (
                    <p style={{ color: '#555' }}>Uploaded PDF: {paymentProof.name}</p>
                  )}
                </div>
              )}
            </div>

            <div style={styles.drawerFooter}>
              <button
                disabled={
                  !(
                    selectedPackage !== null &&
                    selectedOrderType !== null &&
                    paymentProof &&
                    (selectedDeliveryAddress === "same" ||
                      (customAddress.trim() !== "" &&
                        receiver_name.trim() !== "" &&
                        receiver_phone.trim() !== ""))
                  )
                }
                onClick={() => {
                  GetBasicData();
                }}
                style={{
                  ...styles.drawerFooterButton,
                  ...styles.drawerFooterPrimary,
                  backgroundColor: !(
                    selectedPackage !== null &&
                    selectedOrderType !== null &&
                    paymentProof &&
                    (selectedDeliveryAddress === "same" ||
                      (customAddress.trim() !== "" &&
                        receiver_name.trim() !== "" &&
                        receiver_phone.trim() !== ""))
                  )
                    ? "darkgray" // Color when disabled
                    : styles.drawerFooterPrimary.backgroundColor, // Color when enabled
                  color: !(
                    selectedPackage !== null &&
                    selectedOrderType !== null &&
                    paymentProof &&
                    (selectedDeliveryAddress === "same" ||
                      (customAddress.trim() !== "" &&
                        receiver_name.trim() !== "" &&
                        receiver_phone.trim() !== ""))
                  )
                    ? "white" // Text color when disabled
                    : styles.drawerFooterPrimary.color, // Text color when enabled
                }}
              >
                Select Products
              </button>
              <button onClick={() => setShowDrawer(false)} style={{ ...styles.drawerFooterButton, ...styles.drawerFooterSecondary }}>
                Cancel
              </button>
            </div>
          </div>
        </PerfectScrollbar>
      </Drawer >
      <Drawer
        anchor="right"
        open={showProductDrawer}
        onClose={() => { setShowProductDrawer(false) }}
        sx={{
          "& .MuiDrawer-paper": {
            width: "450px",
            padding: "20px",
            marginTop: '20px',
            marginBottom: '20px',
            marginRight: '10px',
            borderRadius: "15px 15px 15px 15px",
          },
        }}
      >
        <PerfectScrollbar>
          <div>
            {/* Drawer Header */}
            <div style={styles.drawerHeader}>
              <button
                onClick={() => { setShowDrawer(false); setShowProductDrawer(false) }}
                style={styles.drawerCloseButton}
              >
                &times;
              </button>
              <span style={{ marginTop: '4px', marginLeft: '10px' }}>
                {'Complete Purchase Order'}
              </span>
            </div>

            {/* Content based on selection */}
            {selectedPackage !== null ? (
              <>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: '15px',
                  }}
                >
                  <span
                    style={{
                      fontFamily: 'Metropolis',
                      fontSize: '1.3rem',
                      width: '200px',
                      fontWeight: '500',
                      color: 'black',
                    }}
                  >
                    Select products to complete order.
                  </span>
                  <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'right' }}>
                    <span
                      style={{
                        fontFamily: 'Metropolis',
                        fontSize: '1rem',
                        color: '#999999',
                        marginBottom: '5px',
                      }}
                    >
                      Total
                    </span>
                    <span
                      style={{
                        width: '150px',
                        fontFamily: 'Metropolis',
                        fontSize: '1rem',
                        fontWeight: '550',
                        color: 'black',
                      }}
                    >
                      {selectedCurrency.symbol}  {parseFloat(totalPrice * selectedCurrency.rate).toFixed(2)}
                      {" | "} {totalMPV} MPV
                    </span>
                  </div>
                </div>
                <input
                  type="checkbox"
                  onChange={(e) => {
                    if (e.target.checked) {
                      selectMaxProducts(); // Select max products when checked
                    } else {
                      deselectAllProducts(); // Deselect all products when unchecked
                    }
                  }}
                  style={{
                    marginRight: '10px',
                    accentColor: '#C1B314',
                  }}
                />
                <span
                  style={{
                    fontFamily: 'Metropolis',
                    fontSize: '1rem',
                    fontWeight: '500',
                    color: 'black',
                  }}
                >
                  Select max products
                </span>
                <PerfectScrollbar>
                  <div style={{ marginTop: '20px', height: '50vh' }}>
                    {loading ? <Loader /> :
                      filteredProducts.map((item, index) => (
                        <div
                          key={index}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginBottom: '20px',
                            borderBottom: '1px solid #f1f1f1',
                            paddingBottom: '10px',
                            opacity: item.isAvailable ? 1 : 0.5,
                            pointerEvents: item.isAvailable ? 'auto' : 'none',
                          }}
                        >
                          {/* Checkbox and Product Details */}
                          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            {/* Checkbox */}
                            <input
                              type="checkbox"
                              checked={item.isSelected || false}
                              disabled={isDisabled(item) || !item.isAvailable}
                              onChange={(e) => handleCheckboxChange(index, e.target.checked)}
                              style={{
                                accentColor: '#C1B314',
                              }}
                            />
                            {/* Product Image */}
                            <img
                              src={`${Image_URL}${item.images[item.index || 0]}`}
                              alt="Product"
                              style={{
                                borderRadius: '8px',
                                width: '50px',
                                height: '50px',
                              }}
                            />

                            {/* Product Info */}
                            <div>
                              <p
                                style={{
                                  margin: 0,
                                  fontWeight: 'bold',
                                  fontSize: '1.1rem',
                                  color: '#000',
                                }}
                              >
                                {item.item_name}
                              </p>
                              <p
                                style={{
                                  margin: 0,
                                  fontSize: '0.9rem',
                                  color: '#666',
                                }}
                              >
                                {item.isAvailable ? `In Stock: ${item.sponsorQuantity}` : 'Out of Stock'}
                              </p>
                              <p
                                style={{
                                  margin: '5px 0 0',
                                  fontSize: '0.9rem',
                                  color: '#333',
                                  fontWeight: 'bold',
                                }}
                              >
                                Price {selectedCurrency.symbol}  {parseFloat(item.price * selectedCurrency.rate).toFixed(2)} | {item.item_mpv} MPV
                              </p>
                            </div>
                          </div>

                          {/* Quantity Controls */}
                          <div style={{ display: "flex", flexDirection: "column", textAlign: "right" }}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                              <img
                                src={Minus}
                                alt="Decrease"
                                style={{
                                  cursor: item.quantity > 0 ? "pointer" : "not-allowed",
                                  opacity: item.quantity > 0 ? 1 : 0.5,
                                }}
                                disabled={item.quantity === 0}
                                onClick={() => updateQuantity(index, -1)}
                              />
                              <span
                                style={{
                                  fontSize: "0.9rem",
                                  fontWeight: "bold",
                                  color: "#333",
                                  margin: "0 10px",
                                }}
                              >
                                {item.quantity || 0}
                              </span>
                              <img
                                src={Add}
                                disabled={isDisabled(item)}
                                alt="Increase"
                                style={{
                                  marginRight: '15px',
                                  cursor: "pointer",
                                }}
                                onClick={() => updateQuantity(index, 1)}
                              />
                            </div>

                            {/* Price & MPV */}
                            <div style={{ textAlign: "right" }}>
                              <p
                                style={{
                                  margin: 0,
                                  marginRight: '10px',
                                  fontSize: "0.8rem",
                                  color: "#333",
                                }}
                              >
                                {selectedCurrency.symbol}  {parseFloat((item.quantity || 0) * parseFloat(item.price || 0).toFixed(2) * selectedCurrency.rate).toFixed(2)}
                                {" | "}
                                {(item.quantity || 0) * parseFloat(item.item_mpv || 0).toFixed(2)} MPV
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </PerfectScrollbar>
              </>
            ) : (
              <p style={{ textAlign: 'center', marginTop: '50px' }}>Please select a package to continue.</p>
            )}

            {/* Drawer Footer */}
            <div style={styles.drawerFooter}>
              <button
                onClick={() => {
                  HandleSubmit();
                }}
                style={{
                  ...styles.drawerFooterButton,
                  ...styles.drawerFooterPrimary,
                }}
              >
                {selectedPackage !== null ? 'Select Products & Complete Order' : 'Complete Purchase Order'}
              </button>
              <button
                onClick={() => { setShowDrawer(false); setShowProductDrawer(false) }}
                style={{
                  ...styles.drawerFooterButton,
                  ...styles.drawerFooterSecondary,
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </PerfectScrollbar>
      </Drawer>
    </div >
  );
};

export default Home;
