import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, Navigate } from "react-router-dom";
import { Row, Col, FormGroup, Input, Button, Label, Modal, ModalHeader, ModalBody, Card, CardBody } from "reactstrap";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Services from "./services";
import AuthService from "../Authentication/auth.service";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import Alerts from "../../components/Alerts/alerts";
import PermissionAccess from '../../PermissionAccess/Access.js';

const AddBulkEmail = ({ onSuccess }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [bulkEmail, setBulkEmail] = useState({
    subject: '',
    recipients: '',
    email_body: '',
    date_time: ''
  });
  const [loading, setLoading] = useState(false);
  const [loginNavigate, setLoginNavigate] = useState(false);
  const [fetchedBusinesses, setFetchedBusinesses] = useState([]);
  const { authData } = PermissionAccess();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchBusinesses();
  }, []);

  function fetchBusinesses() {
    setLoading(true);
    Services.GetBusinesses(authData.sessionID).then(
      (response) => {
        setLoading(false);
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        } else {
          const business = response.data.data[0];
          setFetchedBusinesses([business]);
        }
      },
      (error) => {
        setLoading(false);
        const errContent = (error.response && error.response.data) || error.message || error.toString();
        Alerts.swalErrorAlert(errContent || t("Server Error, Please try again later."));
      }
    );
  }

  const toggle = () => {
    setModalOpen(!modalOpen);
    if (!modalOpen) {
      setBulkEmail({
        subject: '',
        recipients: '',
        email_body: '',
        date_time: ''
      });
    }
  };

  const handleInputChange = (field, value) => {
    setBulkEmail(prev => ({ ...prev, [field]: value }));
  };

  const validateForm = () => {
    return bulkEmail.subject && bulkEmail.recipients && bulkEmail.email_body && bulkEmail.date_time;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      Alerts.swalErrorAlert("Please fill all fields.");
      return;
    }

    const payload = {};
    Object.keys(bulkEmail).forEach(key => {
      payload[key] = bulkEmail[key];
    });
    payload.business_id = fetchedBusinesses[0]._id
    payload.status = "Active";

    setLoading(true);
    try {
      const response = await Services.AddBulkEmail(authData.sessionID, payload);
      setLoading(false);
      console.log("response.data.status", response.data)
      if (response.data.status) {
        onSuccess();
        Alerts.swalSuccessAlert(response.data.message || "Bulk Email Scheduled Successfully.").then(() => {
          toggle();
        });
      } else {
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          setLoginNavigate(true);
        } else {
          Alerts.swalErrorAlert(response.data.message || "Error: Can't Schedule Bulk Email at the moment. Please try again later.");
        }
      }
    } catch (error) {
      console.log("error", error)
      setLoading(false);
      Alerts.swalErrorAlert("Network Operation failed.");
    }
  };

  if (loginNavigate) {
    return <Navigate to={'/login'} />;
  }

  return (
    <span className="d-inline-block float-end mb-2 me-2">
      <Button color="success" onClick={toggle} className="btn-hover-shine ms-1">
        {t("Schedule Announcements")}
      </Button>
      <Modal size='lg' isOpen={modalOpen} toggle={toggle} backdrop={true}>
        <LoadingOverlay active={loading} spinner={<DNALoader />}>
          <ModalHeader>{t("Schedule Bulk Email")}</ModalHeader>
          <ModalBody>
            <Card className="main-card mb-3">
              <CardBody>
                <form onSubmit={handleSubmit}>
                  <FormGroup className="mb-3">
                    <Row>
                      <Col md="12">
                        <Label>{t("Email Subject")}</Label>
                        <Input
                          type="text"
                          value={bulkEmail.subject}
                          onChange={(e) => handleInputChange('subject', e.target.value)}
                          placeholder={t("Enter subject")}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col md="6">
                        <Label>{t("Schedule Date Time")}</Label>
                        <Input
                          type="datetime-local"
                          value={bulkEmail.date_time}
                          onChange={(e) => handleInputChange('date_time', e.target.value)}
                        />
                      </Col>
                      <Col md="6">
                        <Label>{t("Recipients")}</Label>
                        <Input
                          type="select"
                          value={bulkEmail.recipients}
                          onChange={(e) => handleInputChange('recipients', e.target.value)}
                        >
                          <option value="" disabled>{t("Select recipients")}</option>
                          <option value="Members">{t("Members")}</option>
                          <option value="customers">{t("Customers")}</option>
                          <option value="all">{t("All")}</option>
                        </Input>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col md="12">
                        <Label>{t("Email Body")}</Label>
                        <ReactQuill
                          value={bulkEmail.email_body}
                          onChange={(content) => handleInputChange('email_body', content)}
                          modules={{
                            toolbar: [
                              [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                              [{ size: [] }],
                              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                              [{ 'list': 'ordered' }, { 'list': 'bullet' },
                              { 'indent': '-1' }, { 'indent': '+1' }],
                              ['link', 'image'],
                              ['clean']
                            ],
                          }}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <div className="text-center">
                    <Button type="submit" color="success" size="lg" className="me-3">
                      {t("Schedule")}
                    </Button>
                    <Button type="button" color="secondary" size="lg" onClick={toggle}>
                      {t("Cancel")}
                    </Button>
                  </div>
                </form>
              </CardBody>
            </Card>
          </ModalBody>
        </LoadingOverlay>
      </Modal>
    </span>
  );
};

export default AddBulkEmail;