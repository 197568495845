import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import { Col, Row, Button, Label, FormGroup, Input } from "reactstrap";
import { Form, Field } from "react-final-form";
import { useNavigate } from 'react-router-dom';
import { logIn } from '../../actions/AuthAction.js';
import AlertService from "../../components/Alerts/alerts";
import { useDispatch, useSelector } from 'react-redux';
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";
import ConfigurationService from "../Configuration/configuration.service";
import FinanceService from "../Finance/Settings/configuration.service";
import bg from '../../assets/frame.svg';

const Login = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.authReducer.loading);

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [logo, setLogo] = useState(localStorage.getItem("system_logo"));
  const [system_name, setSystem_Name] = useState(localStorage.getItem("name"));
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  localStorage.setItem("user_type", 'admin');

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Track window resize for responsive handling
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const getFinanceConfig = useCallback(() => {
    FinanceService.getConfiguration().then((response) => {
      console.log("Finance API Response:-", response.data.data);
      if (response.data.status) {
        localStorage.setItem("payment_method", response.data.data[0]?.payment_method);
        localStorage.setItem("due_date", response.data.data[0]?.due_date);
        localStorage.setItem("default_notes", response.data.data[0]?.default_notes);
        localStorage.setItem("tax_percentage", response.data.data[0]?.tax_percentage);
        localStorage.setItem("currency", response.data.data[0]?.currency);
        localStorage.setItem("invoice_to", response.data.data[0]?.invoice_to);
        localStorage.setItem("invoice_to_field", response.data.data[0]?.invoice_to_field);
        localStorage.setItem("payment_types", response.data.data[0]?.payment_types);
      }
      else {
        setColors('#3ac47d', 'onerror');
      }
    },
      (error) => {
        setColors('#3ac47d', 'onerror');
      });
  }, []);
  
  const getSetLanguage = useCallback(() => {
    if (localStorage.getItem("language")) {
      const language = localStorage.getItem("language");
      i18n.changeLanguage(language);
    }
    else {
      localStorage.setItem('language', 'en');
      i18n.changeLanguage('en');
    }
  }, [i18n]);

  const getSystemConfig = useCallback(() => {
    ConfigurationService.getConfiguration().then((response) => {
      console.log("API Response:-", response);
      if (response.data.status) {
        setColors(response.data.data[0].buttons_color, '--buttons-color');
        setColors(response.data.data[0].topbar_color, '--topbar-color');
        setColors(response.data.data[0].leftbar_color, '--leftbar-color');
        setColors(response.data.data[0].buttons_text_color, '--buttons-text-color');
        setSystemLogo(response.data.data[0].system_logo);
        setSystemName(response.data.data[0].name);
      }
      else {
        setColors('#3ac47d', 'onerror');
      }
    },
      (error) => {
        setColors('#3ac47d', 'onerror');
      });
  }, []);

  useEffect(() => {
    getSetLanguage();
    setTimeout(() => {
      getFinanceConfig();
      getSystemConfig();
    }, 2000);
  }, [getFinanceConfig, getSystemConfig, getSetLanguage]);

  function setColors(color, param) {
    if (param === '--buttons-color') {
      localStorage.setItem("buttons_color", color);
      document.documentElement.style.setProperty('--buttons-color', color)
    }
    if (param === '--topbar-color') {
      localStorage.setItem("topbar_color", color);
      document.documentElement.style.setProperty('--topbar-color', color)
    }
    if (param === '--leftbar-color') {
      localStorage.setItem("leftbar_color", color);
      document.documentElement.style.setProperty('--leftbar-color', color)
    }
    if (param === '--buttons-text-color') {
      localStorage.setItem("buttons_text_color", color);
      document.documentElement.style.setProperty('--buttons-text-color', color)
    }
    if (param === 'onerror') {
      localStorage.setItem("buttons_color", color);
      localStorage.setItem("topbar_color", '#fff');
      localStorage.setItem("leftbar_color", '#fff');
      localStorage.setItem("buttons_text_color", '#fff');
      document.documentElement.style.setProperty('--buttons-color', color)
      document.documentElement.style.setProperty('--topbar-color', '#fff')
      document.documentElement.style.setProperty('--leftbar-color', '#fff')
      document.documentElement.style.setProperty('--buttons-text-color', '#fff')
    }
  }

  function setSystemLogo(sys_logo) {
    setLogo(sys_logo);
    localStorage.setItem("system_logo", sys_logo);
  }
  
  function setSystemName(name) {
    setSystem_Name(name);
    localStorage.setItem("system_name", name);
  }
  
  function handlereset() {
    localStorage.setItem("reset", true)
  };

  function handleregprod() {
    localStorage.setItem("regprod", true)
  };

  const onSubmit = async values => {
    console.log(values);
    var login_data = {
      functionName: "LoginUser",
      postData: {
        "email": values.email,
        "password": values.password,
      },
    }
    const response = await dispatch(logIn(login_data));
    if (response.data.status === true) {
      console.log("Login RES :-", response);
      var user_data = response.data.userData;
      user_data['username'] = user_data?.email;
      user_data['accessToken'] = user_data._id;
      user_data['session-id'] = response.data.sessionID;
      user_data['roles'] = [
        "ROLE_ADMIN",
        "ROLE_USER"
      ];
      console.log("Login Response:-", response)
      localStorage.setItem("user", JSON.stringify(user_data));
      localStorage.setItem("session-id", response.data.sessionID);
      var landingpage = response?.data.userData.role_details?.landingpage
      navigate("/" + landingpage);
    } else {
      AlertService.swalErrorAlert(t("Invalid Credentials or Access denied."))
    }
  };

  const validateFtn = (values) => {
    const errors = {};
    function validateEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
    if (!values.email) {
      errors.email = t('Email Required.');
    }
    else if (!validateEmail(values.email)) {
      errors.email = t('Please enter a valid email address.');
    }
    if (!values.password) {
      errors.password = t('Password Required.');
    }
    return errors;
  };

  // Determine if we should show the background image based on screen size and performance
  const shouldShowBgImage = windowWidth > 768;
  
  // Lazy load the background image
  const [bgImageLoaded, setBgImageLoaded] = useState(false);
  
  useEffect(() => {
    if (shouldShowBgImage) {
      const img = new Image();
      img.src = bg;
      img.onload = () => setBgImageLoaded(true);
    }
  }, [shouldShowBgImage]);

  // Apply media query styles
  const applyResponsiveStyles = useCallback(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    
    // Function to apply styles based on media query
    const handleMediaQueryChange = (e) => {
      const leftContainer = document.querySelector('.left-container');
      const rightContainer = document.querySelector('.right-container');
      const logoContainer = document.querySelector('.logo-container');
      const heading = document.querySelector('.heading');
      
      if (e.matches) {
        // Mobile styles
        if (leftContainer) {
          leftContainer.style.flex = '1';
          leftContainer.style.padding = '0 20px';
          leftContainer.style.marginTop = '0';
        }
        if (rightContainer) {
          rightContainer.style.display = 'none';
        }
        if (logoContainer) {
          logoContainer.style.textAlign = 'center';
        }
        if (heading) {
          heading.style.marginTop = '5%';
          heading.style.fontSize = '24px';
        }
      } else {
        // Desktop styles
        if (leftContainer) {
          leftContainer.style.flex = '1';
          leftContainer.style.padding = '0 5%';
          leftContainer.style.marginTop = '3%';
        }
        if (rightContainer) {
          rightContainer.style.display = 'block';
          rightContainer.style.flex = '2';
        }
        if (logoContainer) {
          logoContainer.style.textAlign = 'left';
        }
        if (heading) {
          heading.style.marginTop = '12%';
          heading.style.fontSize = '28px';
        }
      }
    };
    
    // Initial check
    handleMediaQueryChange(mediaQuery);
    
    // Add listener for changes
    mediaQuery.addEventListener('change', handleMediaQueryChange);
    
    // Cleanup function
    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
    };
  }, []);

  // Apply responsive styles on component mount
  useEffect(() => {
    const cleanup = applyResponsiveStyles();
    return cleanup;
  }, [applyResponsiveStyles]);

  // Add styles to make the whole page scrollable
  useEffect(() => {
    // Apply styles to body and html to ensure proper scrolling
    document.body.style.overflow = 'auto';
    document.body.style.height = 'auto';
    document.documentElement.style.overflow = 'auto';
    document.documentElement.style.height = 'auto';
    
    // Clean up
    return () => {
      document.body.style.overflow = '';
      document.body.style.height = '';
      document.documentElement.style.overflow = '';
      document.documentElement.style.height = '';
    };
  }, []);

  return (
    <div 
      style={{ 
        display: 'flex',
        minHeight: '100vh', // Changed from height to minHeight
        flexDirection: windowWidth <= 768 ? 'column' : 'row',
        overflow: 'hidden' // Prevent double scrollbars
      }} 
      className="login-container"
    >
      <div 
        className="left-container"
        style={{ 
          flex: windowWidth <= 768 ? 1 : 1, 
          display: 'flex', 
          marginTop: windowWidth <= 768 ? 0 : '3%', 
          flexDirection: 'column', 
          background: 'white', 
          padding: windowWidth <= 768 ? '0 20px' : '0 5%', 
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          // Removed overflowY: 'auto' to prevent separate scrolling
          minHeight: windowWidth <= 768 ? 'auto' : '100%'
        }}
      >
        <div className="logo-container" style={{ 
          marginBottom: '20px', 
          marginTop: '20px',
          textAlign: windowWidth <= 768 ? 'center' : 'left'
        }}>
          <img src={logo || "/placeholder.svg"} alt="Logo" style={{ width: '180px', maxWidth: '100%', height: 'auto' }} />
        </div>
        <div style={{ width: '100%', maxWidth: '450px', margin: '0 auto' }}>
          <Form onSubmit={onSubmit} validate={validateFtn}
            render={({ handleSubmit, valid }) => (
              <form onSubmit={handleSubmit}>
                <LoadingOverlay active={loading} spinner={<DNALoader />}>
                  <div className="modal-body p-5">
                    <h2 className="heading" style={{ 
                      marginTop: windowWidth <= 768 ? '5%' : '12%', 
                      fontSize: windowWidth <= 768 ? '24px' : '28px', 
                      fontWeight: 'bold', 
                      marginBottom: '10px' 
                    }}>Sign in</h2>
                    <p style={{ fontSize: '16px', color: '#666', marginBottom: '20px' }}>Please login to continue to your account.</p>
                    <Row form>
                      <Col md={12}>
                        <FormGroup>
                          <Field name="email">
                            {({ input, meta }) => (
                              <div className="mb-3">
                                <Label for="email">{t('Email')}</Label>
                                <Input {...input} type="email" invalid={meta.error && meta.touched} placeholder={t('Enter Email')} />
                                {meta.error && meta.touched && <span className="text-danger">{meta.error}</span>}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup>
                          <Field name="password">
                            {({ input, meta }) => (
                              <div className="mb-3">
                                <Label for="password">{t('Password')}</Label>
                                <Input {...input} type={showPassword ? "text" : "password"} invalid={meta.error && meta.touched} placeholder="**********" />
                                {meta.error && meta.touched && <span className="text-danger">{meta.error}</span>}
                              </div>
                            )}
                          </Field>
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormGroup check className="mb-3">
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {/* <Input type="checkbox" id="rememberMe" />
                          <label htmlFor="rememberMe" style={{ marginLeft: '5px' }}>Keep me logged in</label> */}
                        </div>
                        <a href="/forgot-password" style={{ color: '#007bff', textDecoration: 'none' }}>Forgot Password?</a>
                      </div>
                    </FormGroup>
                    <div className="d-grid mb-3">
                      <Button size="lg" className="btn-custom-color" type="submit" disabled={!valid}>
                        {t('Login')}
                      </Button>
                    </div>
                    {/* <div className="text-center">
                      Don't Have an Account?
                      <a href={`/register_client/`} style={{
                        color: '#007bff',
                        textDecoration: 'none',
                        alignContent: 'center',
                      }}>Create New</a>
                    </div> */}
                  </div>
                </LoadingOverlay>
              </form>
            )}
          />
          {/* <CopyRight /> */}
        </div>
      </div>
      
      {/* Only render the right container if we're on a larger screen */}
      {shouldShowBgImage && (
        <div 
          className="right-container"
          style={{ 
            flex: 2, 
            backgroundImage: bgImageLoaded ? `url(${bg})` : 'none',
            backgroundColor: '#f5f5f5', // Placeholder color while image loads
            backgroundSize: 'cover', 
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            position: 'relative',
            transition: 'background-image 0.3s ease-in',
            minHeight: '100%'
          }}
        >
          {/* Optional loading indicator while image loads */}
          {!bgImageLoaded && (
            <div style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              color: '#666'
            }}>
              Loading...
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Login;

// Add this CSS to your stylesheet for better scrolling behavior
/*
html, body {
  height: auto;
  overflow-x: hidden;
  overflow-y: auto;
}

.login-container {
  min-height: 100vh;
  overflow: hidden;
}

@media (max-width: 768px) {
  .login-container {
    flex-direction: column !important;
  }
  
  .left-container {
    flex: 1 !important;
    padding: 0 20px !important;
    margin-top: 0 !important;
  }
  
  .right-container {
    display: none !important;
  }
  
  .logo-container {
    text-align: center !important;
  }
  
  .heading {
    margin-top: 5% !important;
    font-size: 24px !important;
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .right-container {
    background-size: contain !important;
  }
}

.right-container {
  will-change: background-image;
  contain: paint;
}
*/