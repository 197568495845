import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, CardBody, Button, Input, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import DiscountCouponService from "../../DiscountCoupon/Discountcoupons.service";
import AuthService from "../../Authentication/auth.service";
import DashboardHeader from './DashboardHeader';

const ChatInterface = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const chatContainerRef = useRef(null);
  const sessionID = localStorage.getItem("session-id");

  // useEffect(() => {
  //   fetchMessages();
  //   const intervalId = setInterval(fetchMessages, 10000);
  //   return () => clearInterval(intervalId);
  // }, [id, sessionID]);

  useEffect(() => {
    fetchMessages();
  }, [id, sessionID]);


  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const fetchMessages = async () => {
    try {
      setLoading(true);
      const response = await DiscountCouponService.GetMessages(sessionID, id, 'Member');
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
      } else if (response.data) {
        setMessages(response.data.messages);
      }
    } catch (error) {
      console.error(`Error fetching messages:`, error);
    } finally {
      setLoading(false);
    }
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    if (newMessage.trim() === '') return;

    try {
      setLoading(true);
      await DiscountCouponService.SendMessage(sessionID, id, newMessage, 'Member');
      setNewMessage('');
      await fetchMessages();
    } catch (error) {
      console.error(`Error sending message:`, error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="app-main__outer">
      <div className="container mt-4">
        <Row className="mb-3">
          <Col>
            <Button color="link" className="p-0" onClick={() => navigate(-1)}>
              <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
              Back
            </Button>
          </Col>
        </Row>
        <Card className="chat-card">
          <CardBody>
            <div className="chat-header mb-3">
              <h4>{'Quotation Chat'}</h4>
            </div>
            <div className="chat-container">
              <div className="messages-container" ref={chatContainerRef}>
                {messages.map((msg, index) => (
                  <div key={index} className={`message-wrapper ${msg.sender_type === 'admin' ? 'received' : 'sent'}`}>
                    <div className="message-content">
                      <div className="message-sender">{msg.sender_type === 'admin' ? 'Admin' : 'Member'}</div>
                      <div className="message-text">{msg.message}</div>
                      <div className="message-time">{moment(msg.date).format('DD-MM-YYYY, h:mm A')}</div>
                    </div>
                  </div>
                ))}
              </div>
              <form onSubmit={sendMessage} className="message-input">
                <Input
                  type="text"
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  placeholder="Type a message"
                  disabled={loading}
                />
                <Button color="primary" type="submit" disabled={loading}>
                  <FontAwesomeIcon icon={faPaperPlane} />
                </Button>
              </form>
            </div>
          </CardBody>
        </Card>
      </div>
      <style jsx>{`
                .app-main__outer {
                    background-color: #f8f9fa;
                    min-height: 100vh;
                    padding-top: 20px;
                }
                .chat-container {
                    background-color: #ffffff;
                    border-radius: 12px;
                    overflow: hidden;
                    display: flex;
                    flex-direction: column;
                    height: 600px;
                    box-shadow: 0 2px 10px rgba(0,0,0,0.05);
                }
                .messages-container {
                    flex-grow: 1;
                    overflow-y: auto;
                    padding: 15px;
                    background-color: #f8f9fa;
                }
                .message-wrapper {
                    display: flex;
                    margin-bottom: 10px;
                }
                .message-wrapper.sent {
                    justify-content: flex-end;
                }
                .message-wrapper.received {
                    justify-content: flex-start;
                }
                .message-content {
                    max-width: 60%;
                    padding: 10px 15px;
                    border-radius: 18px;
                    position: relative;
                    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
                }
                .message-wrapper.sent .message-content {
                    background-color: #3D607A;
                    color: white;
                }
                .message-wrapper.received .message-content {
                    background-color: #e9ecef;
                    color: #333;
                }
                .message-sender {
                    font-weight: bold;
                    margin-bottom: 4px;
                    font-size: 0.9em;
                    opacity: 0.8;
                }
                .message-text {
                    margin-bottom: 4px;
                    line-height: 1.4;
                }
                .message-time {
                    font-size: 0.75em;
                    opacity: 0.7;
                    text-align: right;
                }
                .message-input {
                    display: flex;
                    align-items: center;
                    padding: 15px;
                    background-color: #ffffff;
                    border-top: 1px solid #e0e0e0;
                }
                .message-input input {
                    flex-grow: 1;
                    border: 1px solid #e0e0e0;
                    border-radius: 20px;
                    padding: 10px 15px;
                    margin-right: 10px;
                }
                .message-input button {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            `}</style>

      {/* <style jsx>{`
        .chat-card {
          height: calc(100vh - 150px);
          display: flex;
          flex-direction: column;
        }
        .chat-container {
          display: flex;
          flex-direction: column;
          height: 60vh;
        }
        .messages-container {
          flex-grow: 1;
          overflow-y: auto;
          padding: 15px;
          background-color: #f8f9fa;
        }
        .message-wrapper {
          display: flex;
          margin-bottom: 10px;
        }
        .message-wrapper.sent {
          justify-content: flex-end;
        }
        .message-content {
          max-width: 70%;
          padding: 10px 15px;
          border-radius: 18px;
          background-color: #ffffff;
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        }
        .message-wrapper.sent .message-content {
          background-color: #dcf8c6;
        }
        .message-sender {
          font-weight: bold;
          font-size: 0.9em;
          margin-bottom: 5px;
        }
        .message-text {
          margin-bottom: 5px;
        }
        .message-time {
          font-size: 0.8em;
          color: #999;
          text-align: right;
        }
        .message-input {
          display: flex;
          padding: 15px;
          background-color: #f8f9fa;
        }
        .message-input input {
          flex-grow: 1;
          margin-right: 10px;
        }
      `}</style> */}
    </div>
  );
};

export default ChatInterface;

// import React, { useState, useEffect, useRef } from 'react';
// import { useNavigate, useParams } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
// import { Button, Input, Col, CardBody, Card, Row } from "reactstrap";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPaperPlane, faCommentAlt, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
// import moment from 'moment';
// import DiscountCouponService from "../../DiscountCoupon/Discountcoupons.service";
// import AuthService from "../../Authentication/auth.service";
// import LoadingOverlay from "react-loading-overlay-ts";
// import DNALoader from "../../../components/Loaders/DnaLoader";
// import Alerts from "../../../components/Alerts/alerts";

// const ChatInterface = () => {
//   const { t } = useTranslation();
//   const navigate = useNavigate();
//   const [loading, setLoading] = useState(true);
//   const { id } = useParams();
//   const [messages, setMessages] = useState([]);
//   const [newMessage, setNewMessage] = useState('');
//   const chatContainerRef = useRef(null);
//   const sessionID = localStorage.getItem("session-id");

//   useEffect(() => {
//     fetchMessages();
//   }, [id, sessionID]);

//   useEffect(() => {
//     if (chatContainerRef.current) {
//       chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
//     }
//   }, [messages]);

//   const fetchMessages = async () => {
//     try {
//       const response = await DiscountCouponService.GetMessages(sessionID, id, 'Member');
//       if (response.data.message === "Unauthorized") {
//         AuthService.logout();
//         navigate("/login");
//         window.location.reload();
//       } else if (response.data) {
//         setMessages(response.data.messages);
//       }
//     } catch (error) {
//       console.error("Error fetching messages:", error);
//       Alerts.swalErrorAlert(t("Error fetching messages"));
//     } finally {
//       setLoading(false);
//     }
//   };

//   const sendMessage = async () => {
//     if (newMessage.trim() === '') return;

//     try {
//       setLoading(true);
//       await DiscountCouponService.SendMessage(sessionID, id, newMessage, 'Member');
//       setNewMessage('');
//       await fetchMessages();
//     } catch (error) {
//       console.error("Error sending message:", error);
//       Alerts.swalErrorAlert(t("Error sending message"));
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <div className="app-main__outer">
//       <div className="app-main__inner">
//         <div className="header">
//           <Button color="link" className="p-0 text-decoration-none" onClick={() => navigate(-1)}>
//             <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
//             <span className="text-dark fw-bold">{t("Back")}</span>
//           </Button>
//         </div>

//         <Card className="mb-3">
//           <CardBody className="p-0">
//             <div className="chat-header">
//               <h6 className="card-title">{t("Quotation Chat")}</h6>
//             </div>

//             <div className="chat-container">
//               <div
//                 className="messages-container"
//                 ref={chatContainerRef}
//               >
//                 {messages?.map((msg, index) => (
//                   <div key={index} className={`message-wrapper ${msg.sender_type === 'admin' ? 'received' : 'sent'}`}>
//                     <div className="message-content">
//                       <div className="message-sender">{msg.sender_type === 'admin' ? 'Admin' : 'Member'}</div>
//                       <div className="message-text">
//                         {msg.message}
//                       </div>
//                       <div className="message-time">
//                         {moment(msg.date).format('DD-MM-YYYY, h:mm A')}
//                       </div>
//                     </div>
//                   </div>
//                 ))}
//               </div>
//               <div className="message-input">
//                 <Input
//                   type="text"
//                   value={newMessage}
//                   onChange={(e) => setNewMessage(e.target.value)}
//                   placeholder={t("Type a message")}
//                 />
//                 <Button color="primary" onClick={sendMessage}>
//                   <FontAwesomeIcon icon={faPaperPlane} />
//                 </Button>
//               </div>
//             </div>
//           </CardBody>
//         </Card>
//       </div>
      // <style jsx>{`
      //           .app-main__outer {
      //               background-color: #f8f9fa;
      //               min-height: 100vh;
      //               padding-top: 20px;
      //           }
      //           .chat-container {
      //               background-color: #ffffff;
      //               border-radius: 12px;
      //               overflow: hidden;
      //               display: flex;
      //               flex-direction: column;
      //               height: 600px;
      //               box-shadow: 0 2px 10px rgba(0,0,0,0.05);
      //           }
      //           .messages-container {
      //               flex-grow: 1;
      //               overflow-y: auto;
      //               padding: 15px;
      //               background-color: #f8f9fa;
      //           }
      //           .message-wrapper {
      //               display: flex;
      //               margin-bottom: 10px;
      //           }
      //           .message-wrapper.sent {
      //               justify-content: flex-end;
      //           }
      //           .message-wrapper.received {
      //               justify-content: flex-start;
      //           }
      //           .message-content {
      //               max-width: 60%;
      //               padding: 10px 15px;
      //               border-radius: 18px;
      //               position: relative;
      //               box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
      //           }
      //           .message-wrapper.sent .message-content {
      //               background-color: #3D607A;
      //               color: white;
      //           }
      //           .message-wrapper.received .message-content {
      //               background-color: #e9ecef;
      //               color: #333;
      //           }
      //           .message-sender {
      //               font-weight: bold;
      //               margin-bottom: 4px;
      //               font-size: 0.9em;
      //               opacity: 0.8;
      //           }
      //           .message-text {
      //               margin-bottom: 4px;
      //               line-height: 1.4;
      //           }
      //           .message-time {
      //               font-size: 0.75em;
      //               opacity: 0.7;
      //               text-align: right;
      //           }
      //           .message-input {
      //               display: flex;
      //               align-items: center;
      //               padding: 15px;
      //               background-color: #ffffff;
      //               border-top: 1px solid #e0e0e0;
      //           }
      //           .message-input input {
      //               flex-grow: 1;
      //               border: 1px solid #e0e0e0;
      //               border-radius: 20px;
      //               padding: 10px 15px;
      //               margin-right: 10px;
      //           }
      //           .message-input button {
      //               width: 40px;
      //               height: 40px;
      //               border-radius: 50%;
      //               display: flex;
      //               align-items: center;
      //               justify-content: center;
      //           }
      //       `}</style>
//     </div>
//   );
// };

// export default ChatInterface;