import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate, Link } from 'react-router-dom';
import { Row, Col, CardBody, Card } from "reactstrap";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import DataTable from 'react-data-table-component';
import ItemsService from "./Item.service.js";
import AuthService from "../Authentication/auth.service";
import Alerts from "../../components/Alerts/alerts";
import { Tooltip } from "@mui/material";
import VariationsFilter from "../../components/Table Filters/VariationsFilter";
import AddItemVariations from "./AddItemVariations";
import UpdateVariations from "./EditItemVariations";
import PermissionAccess from '../../PermissionAccess/Access.js';

const TabAttribute = (state) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [Variations, setVariations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [pageLimit, setPageLimit] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  console.log('state', state.state);
  const { authData, accessPages, super_admin, current_module } = PermissionAccess();

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchData(0, pageLimit);
  }, [pageLimit]);

  function fetchData(page_index, page_limit) {
    setLoading(true);
    var offset = (page_index - 1) * page_limit;
    if (offset < 1) {
      offset = 0;
    }
    ItemsService.Get_Item_Variation(offset, page_limit, state.state._id, authData.sessionID).then(
      (response) => {
        setLoading(false);
        console.log("Get Variations response:-", response);
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        } else {
          console.log(response.data);
          setVariations(response.data.data);
          setTotalRows(response.data.count.count);
        }
      },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        if (errContent) {
          Alerts.swalErrorAlert(errContent);
        } else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."));
        }
      }
    );
  }

  const handleCheckboxChange = (e, row) => {
    const { checked } = e.target;
    if (checked) {
      setSelectedRows((prevSelected) => [...prevSelected, row._id]);
    } else {
      setSelectedRows((prevSelected) => prevSelected.filter(id => id !== row._id));
    }
  };

  const handleSelectAllChange = (e) => {
    const { checked } = e.target;
    if (checked) {
      setSelectedRows(Variations.map((attribute) => attribute._id));
    } else {
      setSelectedRows([]);
    }
  };


  const handleDeleteSelected = () => {
    if (selectedRows.length > 0) {
      let msg = t(selectedRows.length + " records selected. Once deleted, you will not be able to recover this!");
      Alerts.swalConfirmDeleteAlert(msg).then(res => {
        console.log("Resp:-", res)
        if (res === true) {
          sendBulkDelete(selectedRows)
        }
      })
    } else {
      Alerts.swalErrorAlert('No records selected');
    }
  };

  function sendBulkDelete(selectedRows) {
    setLoading(true);
    ItemsService.DeleteBulkItemVariation(selectedRows, authData.sessionID).then((response) => {
      console.log("Delete BulkVariations response:-", response)
      setLoading(false);
      setSelectedRows([]);
      fetchData(currentPage, pageLimit)
      if (response.data.status) {
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Data Deleted Successfully."))
        }
        console.log(response.data.message)
      }
      else {
        setLoading(false);
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't delete the Collection Data at the moment."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Delete operation failed"))
      })
  };

  const mycolumns = [
    {
      name: (
        <div>
          <input
            className="form-check-input"
            type="checkbox"
            onChange={handleSelectAllChange}
          />
        </div>
      ),
      width: '5%',
      selector: (row) => {
        return (
          <div>
            <input
              className="form-check-input"
              type="checkbox"
              checked={selectedRows.includes(row._id)}
              onChange={(e) => handleCheckboxChange(e, row)}
            />
          </div>
        );
      },
    },
    {
      name: t('Name'),
      selector: (row) => {
        return row?.variation_details?.[0]?.name ? row?.variation_details?.[0]?.name : ''
      },
      width: "20%",
      sortable: true,
    },
    {
      name: t('Option'),
      selector: (row) => {
        return row?.variation_option_details?.[0]?.variation_type_name ? row?.variation_option_details?.[0]?.variation_type_name : ''
      },
      width: "20%",
      sortable: true,
    },
    {
      name: t('Price'),
      selector: (row) => {
        return row?.price ? parseFloat(row?.price).toFixed(2) : 0
      },
      width: "20%",
      sortable: true,
    },
    {
      name: t('Status'),
      width: '20%',
      selector: (row) => {
        return (
          <div>
            {row?.status?.toLowerCase() === "active" ? (
              <button
                type="button"
                style={{ borderColor: '#3ac47d', backgroundColor: '#1BD35A' }}
                className="btn btn-sm btn-success btn-hover-shine me-3"
              >
                {row.status}
              </button>
            ) : (
              <button
                type="button"
                style={{ borderColor: '#FF0000', backgroundColor: 'red' }}
                className="btn btn-sm btn-danger btn-hover-shine me-3"
              >
                {row.status}
              </button>
            )}
          </div>
        );
      },
      sortable: true,
    },
    {
      name: t('Actions'),
      width: "15%",
      selector: (row) => {
        return (
          <div>
            <Tooltip placement="top" title={`${t("Update")} ${t("Item's")} ${t("Variation")}`} arrow>
              <i style={{ marginLeft: '10px', marginRight: '10px', cursor: 'pointer' }}>
                <UpdateVariations onSuccess={handleSuccess} data={row} />
              </i>
            </Tooltip>
            <Tooltip placement="top" title={`${t("Delete")} ${t("Item's")} ${t("Variation")}`} arrow>
              <a className="DeletetData" onClick={() => confirmDelete(row._id)} style={{ cursor: "pointer" }}>
                <i className="pe-7s-trash text-danger btn-icon-wrapper fs-5"> </i>
              </a>
            </Tooltip>
          </div>
        );
      }
    },
  ];

  const handleSearch = (searchText, status) => {
    setFilterText(searchText);
    setFilterStatus(status);
  };

  const handleClear = () => {
    setFilterText('');
    setFilterStatus('');
  };

  const filteredItems = Variations.filter(
    item =>
      (item?.variation_details?.[0]?.name?.toLowerCase().includes(filterText?.toLowerCase())
      ) &&
      (filterStatus === '' || item.status === filterStatus)
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <VariationsFilter
        onSearch={handleSearch}
        onClear={handleClear}
        filterText={filterText}
        filterStatus={filterStatus}
      />
    );
  }, [filterText, filterStatus]);


  function handlePageChange(page_index) {
    console.log("Page index:-", page_index)
    setCurrentPage(page_index)
    fetchData(page_index, pageLimit);
  }


  function confirmDelete(_id) {
    let msg = t("Once deleted, you will not be able to recover!")
    Alerts.swalConfirmDeleteAlert(msg).then(res => {
      console.log("Resp:-", res)
      if (res === true) {
        sendDelete(_id)
      }
    })
  };
  function handleSuccess() {
    fetchData();
  };
  function sendDelete(id) {
    setLoading(true);
    ItemsService.DeleteItemVariation(id, authData.sessionID).then((response) => {
      console.log("Delete Variation response:-", response)
      setLoading(false);
      fetchData(currentPage, pageLimit)
      if (response.data.status) {
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Data Deleted Successfully."))
        }
        console.log(response.data.message)
      }
      else {
        setLoading(false);
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't delete the Attribute."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Delete operation failed"))
      })
  };
  async function handlePerRowsChange(newPageLimit, page_index) {
    setPageLimit(newPageLimit);
  }

  return (
    <Fragment>
      <Col md="12" className="mx-auto app-login-box">

        <Row>
          <LoadingOverlay
            tag="div"
            active={loading}
            styles={{ overlay: (base) => ({ ...base }) }}
            spinner={<DNALoader />}
          >
            <Col md="12">
              <Card className="main-card mb-3">
                <CardBody>
                  <Col md="6" style={{ float: "left", position: "absolute", zIndex: "1" }}>
                    {subHeaderComponentMemo}
                  </Col>
                  <DataTable
                    data={filteredItems}
                    columns={mycolumns}
                    pagination
                    fixedHeader
                    fixedHeaderScrollHeight="400px"
                    subHeader
                    subHeaderComponent={
                      <div>
                        <button type="button"
                          className="btn btn-outline-danger btn-hover-shine me-3 btn"
                          onClick={handleDeleteSelected}
                        >
                          {t('Delete Selected')}
                        </button>

                        <AddItemVariations onSuccess={handleSuccess} state={state.state} />
                      </div>
                    }
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handlePerRowsChange}
                    paginationResetDefaultPage={resetPaginationToggle}
                  />

                </CardBody>
              </Card>
            </Col>
          </LoadingOverlay>
        </Row>
      </Col>
    </Fragment>
  );
};

export default TabAttribute;
