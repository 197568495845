
import React, { useEffect, useState, useRef } from "react";
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ButtonGroup, Button, InputGroup, InputGroupText, Input, Col, CardBody, Card, Row, Table } from "reactstrap";
import './Order.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentAlt, faBicycle, faWalking, faMapMarkerAlt, faCheckCircle, faShoppingBasket, faEnvelope, faSearch, faTruck, faFire, faClipboardList, faClock, faPhone, faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import DiscountCouponService from "../DiscountCoupon/Discountcoupons.service";
import AuthService from "../Authentication/auth.service";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import PermissionAccess from '../../PermissionAccess/Access.js';
import Alerts from "../../components/Alerts/alerts";
import { faPaperPlane, faUser } from '@fortawesome/free-solid-svg-icons';

const OrderDetails = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    const [activeTab, setActiveTab] = useState('details');
    const [orderData, setOrderData] = useState(null);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const sessionID = localStorage.getItem("session-id");

    useEffect(() => {
        fetchOrderDetails();
        fetchMessages();
    }, [id, sessionID]);
    const chatContainerRef = useRef(null);

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [messages]);


    const fetchOrderDetails = async () => {
        try {
            const response = await DiscountCouponService.GetUser_OrderDetails(sessionID, id);
            if (response.data.message === "Unauthorized") {
                AuthService.logout();
                navigate("/login");
                window.location.reload();
            } else if (response.data) {
                setOrderData(response.data.orderData[0]);
            }
        } catch (error) {
            console.error("Error fetching order details:", error);
        } finally {
            setLoading(false);
        }
    };

    const fetchMessages = async () => {
        try {
            const response = await DiscountCouponService.GetOrderMessages(sessionID, id, 'Member');
            if (response.data.message === "Unauthorized") {
                AuthService.logout();
                navigate("/login");
                window.location.reload();
            } else if (response.data) {
                setMessages(response.data.messages);
            }
        } catch (error) {
            console.error("Error fetching messages:", error);
        }
    };

    const sendMessage = async () => {
        if (newMessage.trim() === '') return;

        try {
            await DiscountCouponService.SendOrderMessage(sessionID, id, newMessage, 'Member');
            setNewMessage('');
            fetchMessages(); // Refresh messages after sending
        } catch (error) {
            console.error("Error sending message:", error);
        }
    };

    const renderMessagingTab = () => (
        <Card className="mb-3">
            <CardBody className="p-0">
                <div className="chat-container">
                    <div
                        className="messages-container"
                        ref={chatContainerRef}
                    >
                        {messages?.map((msg, index) => (
                            <div key={index} className={`message-wrapper ${msg.sender_type === 'admin' ? 'received' : 'sent'}`}>
                                <div className="message-content">
                                    <div className="message-sender">{msg.sender_type === 'admin' ? 'admin' : 'Member'}</div>
                                    <div className="message-text">
                                        {msg.message}
                                    </div>
                                    <div className="message-time">
                                        {moment(msg.date).format('DD-MM-YYYY, h:mm A')}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="message-input">
                        <input
                            type="text"
                            value={newMessage}
                            onChange={(e) => setNewMessage(e.target.value)}
                            placeholder="Type a message"
                        />
                        <Button color="primary" onClick={sendMessage}>
                            <FontAwesomeIcon icon={faPaperPlane} />
                        </Button>
                    </div>
                </div>
            </CardBody>
        </Card>
    );




    const formatStatus = (status) => {
        if (!status) return '';

        // Replace underscores with spaces and capitalize each word
        return status
            .toLowerCase() // Ensure all letters are lowercase
            .split('_')    // Split by underscores
            .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter of each word
            .join(' ');    // Join the words with spaces
    };


    const renderItemRows = (items) => {
        let rows = [];

        // Group items by category
        const groupedItems = items?.reduce((acc, item) => {
            const category = item?.categoryDetails?.Name || 'Uncategorized';
            if (!acc[category]) {
                acc[category] = [];
            }
            acc[category].push(item);
            return acc;
        }, {});

        // Iterate through each category
        Object.entries(groupedItems).forEach(([category, categoryItems]) => {
            let isFirstItemInCategory = true;

            categoryItems.forEach((item, itemIndex) => {
                const batches = item?.selectedBatches || [{}];

                batches.forEach((batch, batchIndex) => {
                    rows.push(
                        <tr key={`${category}-${itemIndex}-${batchIndex}`}>
                            {isFirstItemInCategory && (
                                <td rowSpan={categoryItems.reduce((sum, item) => sum + (item.selectedBatches?.length || 1), 0)}>
                                    {category}
                                </td>
                            )}
                            {batchIndex === 0 && (
                                <>
                                    <td rowSpan={batches.length}>
                                        <div className="d-flex align-items-center">
                                            {item?.itemDetails?.images && (
                                                <img
                                                    src={item?.itemDetails?.images[(item?.itemDetails?.index - 1) || 0]}
                                                    alt={item?.itemDetails?.item_name}
                                                    className="item-image me-2"
                                                />
                                            )}
                                            <span>{item?.itemDetails?.item_name}</span>
                                        </div>
                                    </td>
                                    <td rowSpan={batches.length}>{item?.quantity}</td>
                                    <td rowSpan={batches.length}>{`RM ${parseFloat(item?.price).toFixed(2)}`}</td>
                                </>
                            )}
                            <td>{batch.batch_number || '-'}</td>
                            <td>{batch.expiry ? moment(batch.expiry).format('DD-MM-YYYY') : '-'}</td>
                            <td>{`RM ${((batch.quantity || 0) * (item?.price || 0)).toFixed(2)}`}</td>
                        </tr>
                    );

                    if (batchIndex === 0) {
                        isFirstItemInCategory = false;
                    }
                });
            });
        });

        return rows;
    };

    const renderTrackingContentDEMO = () => {
        if (!orderData || !orderData?.orderTrackingDetails || orderData?.orderTrackingDetails.length === 0) {
            return <div>No tracking information available</div>;
        }

        return (
            <div style={{
                backgroundColor: 'white',
                borderRadius: '10px',
                padding: '20px',
                boxShadow: '0 2px 5px rgba(0,0,0,0.1)'
            }}>
                {orderData?.orderTrackingDetails.map((step, index) => (
                    <div key={index} style={{
                        display: 'flex',
                        marginBottom: '20px',
                        position: 'relative'
                    }}>
                        <div style={{
                            width: '40px',
                            height: '40px',
                            backgroundColor: '#e74c3c',
                            borderRadius: '50%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginRight: '20px',
                            zIndex: 1
                        }}>
                            <FontAwesomeIcon icon={getIconForStatus(step.status)} style={{ color: 'white' }} />
                        </div>
                        {index !== orderData?.orderTrackingDetails.length - 1 && (
                            <div style={{
                                position: 'absolute',
                                left: '20px',
                                top: '40px',
                                bottom: '-20px',
                                width: '2px',
                                borderLeft: '2px dashed #e74c3c',
                                zIndex: 0
                            }} />
                        )}
                        <div style={{ flex: 1 }}>
                            <h3 style={{ margin: '0 0 5px 0', color: '#333' }}>{formatStatus(step.status)}</h3>
                            <p style={{ margin: '0', color: '#e74c3c' }}>
                                <FontAwesomeIcon icon={faClock} style={{ marginRight: '5px' }} />
                                {/* {step.date} */}
                                {moment(step.date_time).format('DD-MM-YYYY, h:mm:ss a')}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    const getIconForStatus = (status) => {
        switch (status.toLowerCase()) {
            case 'delivered':
                return faCheckCircle;
            case 'picked by driver':
                return faShoppingBasket;
            case 'driver assigned':
                return faBicycle;
            case 'on going':
                return faFire;
            case 'assigning driver':
                return faSearch;
            case 'order received':
                return faClipboardList;
            default:
                return faClipboardList;
        }
    };

    const [inputValue, setInputValue] = useState('');
    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const applyTip = async () => {
        if (isNaN(parseFloat(inputValue)) || parseFloat(inputValue) <= parseFloat(orderData?.priorityTip)) {
            Alerts.swalErrorAlert(`The new tip must be greater than the previous tip ${orderData?.priorityTip}.`);
            return;
        }
        Alerts.swalOKAlert('You want to apply this tip?').then(async res => {
            if (res === true) {
                setLoading(true);
                const response = await DiscountCouponService.AdditionalTip(sessionID, id, inputValue);
                setLoading(false);
                if (response.data.status === true) {
                    Alerts.swalClickProceedAlert('Tip Has Been Applied Successfully').then(res => {
                        if (res === true) {
                            window.location.reload();
                        }
                    });
                }
            }
        });
    };


    const [inputValueLink, setInputValueLink] = useState('');
    const handleInputLinkChange = (e) => {
        setInputValueLink(e.target.value);
    };

    const applyLink = async () => {
        if (isNaN(parseFloat(inputValueLink))) {
            Alerts.swalErrorAlert(`Lalamove ID required.`);
            return;
        }
        Alerts.swalOKAlert('You want to link this ID?').then(async res => {
            if (res === true) {
                setLoading(true);
                const response = await DiscountCouponService.LalamoveLinkID(sessionID, id, inputValueLink);
                setLoading(false);
                if (response.data.status === true) {
                    Alerts.swalClickProceedAlert('Lalamove order id updated successfully').then(res => {
                        if (res === true) {
                            window.location.reload();
                        }
                    });
                }
            }
        });
    };


    const additionalTip = async (e, amount) => {
        e.preventDefault();

        Alerts.swalOKAlert('You want to apply this tip?').then(async res => {
            if (res === true) {
                setLoading(true);
                const response = await DiscountCouponService.AdditionalTip(sessionID, id, amount);
                setLoading(false);
                if (response.data.status === true) {
                    Alerts.swalClickProceedAlert('Tip Has Been Applied Successfully').then(res => {
                        if (res === true) {
                            window.location.reload();
                        }
                    });
                }
            }
        });
    };




    const renderOrderHeader = () => (
        <Card className="mb-3">
            <CardBody>
                <Row>
                    <Col md="3">
                        <div className="order-number">
                            <FontAwesomeIcon icon={faClipboardList} className="text-danger icon-style me-2" />
                            <h6 className="card-title">Order Number</h6>
                            <p>{orderData?.order_number}</p><br />
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="order-status">
                            <FontAwesomeIcon icon={faClock} className="text-danger icon-style me-2" />
                            <h6 className="card-title">Status</h6>
                            <p>{formatStatus(orderData?.status)}</p>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="order-delivery">
                            <FontAwesomeIcon icon={orderData?.deliveryMethod === 'delivery' ? faBicycle : faWalking} className="text-warning icon-style me-2" />
                            <h6 className="card-title">{orderData?.deliveryMethod === 'delivery' ? 'Delivery' : 'Self Pickup'}</h6>

                            {orderData?.late_status === true && orderData?.lalamove_orderID ? (
                                <>
                                    <p>Late</p>
                                </>
                            ) : (
                                <>
                                    <p>Normal</p>
                                </>
                            )}
                        </div>
                    </Col>

                    <Col md="3">
                        <div className="order-delivery">
                            <FontAwesomeIcon icon={faClock} className="text-warning icon-style me-2" />
                            <h6 className="card-title">Order placed at:</h6>
                            <p>{moment(orderData?.order_date).format('MMMM Do YYYY, h:mm:ss a')}</p>
                        </div>
                    </Col>

                </Row>
            </CardBody>
        </Card>
    );

    const renderOrderDetails = () => (
        <div className="item-list mb-3">
            <Table responsive>
                <thead>
                    <tr>
                        <th>{t("Category")}</th>
                        <th>{t("Item(s) - Brand")}</th>
                        <th>{t("Quantity")}</th>
                        <th>{t("Unit Price")}</th>
                        <th>{t("Batches")}</th>
                        <th>{t("Expiry Date")}</th>
                        <th>{t("Total Price")}</th>
                    </tr>
                </thead>
                <tbody>
                    {renderItemRows(orderData?.cartDetails || [])}
                </tbody>
            </Table>
        </div>
    );



    const formatCurrency = (value) => {
        const parsedValue = parseFloat(value);
        return !isNaN(parsedValue)
            ? parsedValue.toFixed(2)
            : '0.00';
    };

    const renderOrderTotal = () => (
        <Card className="mb-3" style={{ height: '295px' }}>
            <CardBody>
                <h5 className="card-title">Order Total</h5>
                <Row>
                    <Col md="6">
                        <p><strong>Order Sub Total:</strong></p>
                        <p><strong>Delivery Charges:</strong></p>
                        <p><strong>Discount:</strong></p>
                        <p><strong>{orderData?.Calculations?.sst_dynamic_label} ({orderData?.Calculations?.sst_percentage}):</strong></p>
                        <p><strong>Grand Total:</strong></p>

                        {orderData?.deliveryMethod === 'delivery' && (
                            <>
                                <p style={{ color: 'red' }}><strong>Additional Priority/Delivery Fee by Seller:</strong></p>
                                <p><strong>Additional Delivery Fee by Customer:</strong></p>
                            </>
                        )}


                    </Col>
                    <Col md="6" className="text-end">
                        <p>RM {formatCurrency(orderData?.Calculations?.subTotal)}</p>
                        <p>RM {formatCurrency(orderData?.Calculations?.deliveryTipFee)}</p>
                        <p>RM {formatCurrency(orderData?.Calculations?.discount)}</p>
                        <p>RM {formatCurrency(orderData?.Calculations?.sst)}</p>
                        <p>RM {formatCurrency(orderData?.Calculations?.grandTotal)}</p>
                        {orderData?.deliveryMethod === 'delivery' && (
                            <>
                                <p style={{ color: 'red' }}>RM {formatCurrency(orderData?.Calculations?.additionalDeliveryFeeBySeller)}</p>
                                <p>RM {formatCurrency(orderData?.Calculations?.additionalDeliveryFee)}</p>

                            </>
                        )}
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );

    return (
        <div className="app-main__outer">
            <div className="app-main__inner">
                <LoadingOverlay active={loading} spinner={<DNALoader />}>
                    <div className="header">
                        <Button color="link" className="p-0 text-decoration-none" onClick={() => navigate(-1)}>
                            {/* <FaLeft className="me-2" /> */}
                            <span className="text-dark fw-bold">{t("Order Details")}</span>
                        </Button>
                    </div>
                    {renderOrderHeader()}

                    <div className="order-tabs mb-3">
                        <button
                            style={{
                                background: activeTab === 'details' ? 'black' : 'white',
                                color: activeTab === 'details' ? 'white' : 'black',
                                marginRight: '10px',
                            }}
                            onClick={() => setActiveTab('details')}
                        >
                            Order Details
                        </button>
                            <button
                                style={{
                                    background: activeTab === 'tracking' ? 'black' : 'white',
                                    color: activeTab === 'tracking' ? 'white' : 'black',
                                    marginRight: '10px',
                                }}
                                onClick={() => setActiveTab('tracking')}
                            >
                                Order Tracking
                            </button>
                        <button
                            style={{
                                background: activeTab === 'messaging' ? 'black' : 'white',
                                color: activeTab === 'messaging' ? 'white' : 'black',
                            }}
                            onClick={() => {
                                setActiveTab('messaging')
                                fetchMessages()
                            }}
                        >
                            Messaging
                        </button>
                        <button
                            style={{
                                background: activeTab === 'payment_details' ? 'black' : 'white',
                                color: activeTab === 'payment_details' ? 'white' : 'black',
                            }}
                            onClick={() => setActiveTab('payment_details')}
                        >
                            Payment Details
                        </button>
                    </div>
                    {activeTab === 'messaging' && renderMessagingTab()}
                    {activeTab !== 'payment_details' && (
                        <>
                            {activeTab === 'details' && renderOrderDetails()}
                            {activeTab === 'tracking' && renderTrackingContentDEMO()}
                        </>
                    )}

                    <Row className="mt-3">
                        <Col md="12">
                            {renderOrderTotal()}
                        </Col>
                    </Row>
                </LoadingOverlay>
            </div>
            <style jsx>{`
        .app-main__outer {
          background-color: #f8f9fa;
          min-height: 100vh;
          padding-top: 20px;
        }
        .rfq-card {
          border-radius: 10px;
          box-shadow: 0 2px 10px rgba(0,0,0,0.05);
          border: none;
        }
        .form-select, .form-control {
          border-color: #e0e0e0;
          background-color: red;
          border-radius: 5px;
          padding: 10px;
        }
        .btn-light {
          background-color: #ffffff;
          border-color: #e0e0e0;
          color: #333;
        }
        .btn-primary {
          background-color: #3D607A;
          border-color: #3D607A;
        }
        .text-muted {
          color: #6c757d !important;
        }
        h4, h5 {
          color: #333;
        }
        .item-list {
          border-top: 1px solid #e0e0e0;
          border-bottom: 1px solid #e0e0e0;
          padding: 20px 0;
        }
        .item-image {
          width: 40px;
          height: 40px;
          object-fit: cover;
          border-radius: 5px;
        }
        .totals {
          margin-top: 20px;
        }
        .totals-row {
          display: flex;
          justify-content: flex-end;
          margin-bottom: 5px;
        }
        .total-amount {
          font-weight: bold;
        }
        .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;
        }
        .back-button {
          background: none;
          border: none;
          font-size: 20px;
          cursor: pointer;
        }
        .rfq-id {
          font-weight: bold;
          font-size: 1.5rem;
        }
        .actions {
          display: flex;
          gap: 10px;
        }
        .reject-button,
        .accept-button {
          padding: 8px 16px;
          border: none;
          border-radius: 5px;
          cursor: pointer;
        }
        .reject-button {
          background-color: #ffdddd;
          color: #d9534f;
        }
        .accept-button {
          background-color: #5cb85c;
          color: #fff;
        }
        .chat-container {
            background: linear-gradient(135deg, #3a7bd5, #3a6073);
            border-radius: 12px;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            height: 600px;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
        }
        
        .chat-header {
            background-color: rgba(0, 0, 0, 0.2);
            color: #ffffff;
            padding: 15px;
            font-weight: bold;
            text-align: center;
            font-size: 1.2em;
        }
        
        .messages-container {
            flex-grow: 1;
            overflow-y: auto;
            padding: 15px;
            background-color: #F9F7F7;
        }
        
        .message-wrapper {
            display: flex;
            margin-bottom: 10px;
        }
        
        .message-wrapper.sent {
            justify-content: flex-end;
        }
        
        .message-wrapper.received {
            justify-content: flex-start;
        }
        
        .message-content {
            max-width: 60%;
            padding: 10px 15px;
            border-radius: 18px;
            position: relative;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        }
        
        .message-wrapper.sent .message-content {
            background-color: #00796B;
            color: white;
        }
        
        .message-wrapper.received .message-content {
            background-color: #1565C0;
            color: white;
        }
        
        .message-sender {
            font-weight: bold;
            margin-bottom: 4px;
            font-size: 0.9em;
            opacity: 0.8;
        }
        
        .message-text {
            margin-bottom: 4px;
            line-height: 1.4;
        }
        
        .message-time {
            font-size: 0.75em;
            opacity: 0.7;
            text-align: right;
        }
        
        .message-input {
            display: flex;
            align-items: center;
            padding: 15px;
            background-color: rgba(0, 0, 0, 0.2);
        }
        
        .message-input input {
            flex-grow: 1;
            border: none;
            border-radius: 20px;
            padding: 12px 20px;
            margin-right: 10px;
            background-color: rgba(255, 255, 255, 0.9);
            color: #333;
            font-size: 1em;
        }
        
        .message-input input::placeholder {
            color: #999;
        }
        
        .message-input button {
            background-color: #00796B;
            border: none;
            border-radius: 50%;
            width: 45px;
            height: 45px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: background-color 0.3s ease;
        }
        
        .message-input button:hover {
            background-color: #00695C;
        }
                      `}</style>

        </div>
    );
};

export default OrderDetails;