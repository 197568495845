import React, { useEffect, useState, Fragment } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import DataTable from 'react-data-table-component';
import { Row, Col, Card, CardBody, Button, ModalHeader, ModalBody, ModalFooter, Modal, Input, Label, FormGroup, Form } from "reactstrap";
import AuthService from "../Authentication/auth.service";
import { useTranslation } from 'react-i18next';
import LoadingOverlay from "react-loading-overlay-ts";
import fileDownload from 'js-file-download'
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";
import Alerts from "../../components/Alerts/alerts";
import Services from "./services";
import QuotationsFilter from "../../components/Table Filters/MemberFilter.js";
import styled from 'styled-components';
import PermissionAccess from '../../PermissionAccess/Access.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faEye } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@mui/material/Tooltip';
import moment from "moment";
import UpdateStatus from '../Order/UpdateStatus';

const customStyles = {
  rows: {
    style: {
      minHeight: '32px', // override the row height
    }
  },
  cells: {
    style: {
      paddingLeft: '8px', // override the cell padding for data cells
      paddingRight: '8px',
    },
  },
};
const conditionalRowStyles = [
  {
    when: row => row.return_status === 'pending',
    style: {
      backgroundColor: '#FFFFE0',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
];



const QuotationsListing = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [Customers, setCustomers] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const { authData, accessPages, super_admin, current_module } = PermissionAccess();
  const [QuotationsToEdit, setQuotationsToEdit] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [modal, setModal] = useState(false);
  const [warehouses, setWarehouses] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState('');
  const [csvFile, setCsvFile] = useState(null);
  const [Fetched_Businesses, setFetched_Businesses] = useState([]);
  const [showModalUpdateStatus, setshowModalUpdateStatus] = useState(false);
  const [array, setArray] = useState([]);

  const handleToggleModalUpdateStatus = (row) => {
    setArray(row);
    setshowModalUpdateStatus(!showModalUpdateStatus);
  }
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchWarehouses();
    Fetch_Businesses();
    fetchCustomers(currentPage, pageLimit, filterText, filterStatus);
  }, [pageLimit, i18n]);


  function Fetch_Businesses() {
    setLoading(true);
    Services.GetBusinesses(authData.sessionID).then(
      (response) => {
        setLoading(false);
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        } else {
          const business = response.data.data[0];
          console.log("business", business)
          setFetched_Businesses(business);
        }
      },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        Alerts.swalErrorAlert(errContent || t("Server Error, Please try again later."));
      }
    );
  }



  const fetchWarehouses = () => {
    Services.GetWarehouses(authData.sessionID)
      .then((response) => {
        if (response.data.status) {
          setWarehouses(response.data.data);
        } else {
          Alerts.swalErrorAlert(response.data.message || t("Error fetching warehouses."));
        }
      })
      .catch((error) => {
        Alerts.swalErrorAlert(t("Failed to fetch warehouses"));
      });
  };
  const handleWarehouseChange = (e) => {
    setSelectedWarehouse(e.target.value);
  };




  // const fetchCustomers = (page, limit, text, status) => {
  //   const offset = (page - 1) * limit;
  //   const getData = {
  //     functionName: "GetCustomers",
  //     postData: {
  //       offset,
  //       filterStatus: status,
  //       filterText: text,
  //       sort: { _id: -1 },
  //       limit,
  //       business_id: authData.userData.businesses || []
  //     }
  //   };

  //   setLoading(true);
  //   Services.GetCustomers(authData.sessionID, getData)
  //     .then((response) => {
  //       setLoading(false);
  //       if (response.data.message === "Unauthorized") {
  //         AuthService.logout();
  //         navigate("/login");
  //       } else {
  //         setCustomers(response.data.data);
  //         setTotalRows(response.data.count.count);
  //       }
  //     })
  //     .catch((error) => {
  //       setLoading(false);
  //       Alerts.swalErrorAlert(t("Server Error, Please try again later."));
  //     });
  // };

  function fetchCustomers(page_index, page_limit, filterText, filterStatus) {
    var offset = (page_index - 1) * page_limit;
    if (offset < 1) {
      offset = 0;
    }
    const stored_user = authData.userData;
    let business = []
    if (stored_user.businesses) {
      business = stored_user.businesses
    }
    var payload = {
      functionName: "GetAdminRFQs",
      postData: {
        filter: {},
        limit: page_limit,
        offset: offset,
        business: [],
        order_for:'Member'
      }
    }

    setLoading(true);
    Services.GetAdminRFQs(authData.sessionID, payload).then((response) => {
      setLoading(false);
      console.log("Get Quotations response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setCustomers(response.data.data);
        console.log("Responsse", response.data);
        setTotalRows(response.data.count.count);
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };
  const handleSuccess = () => {
    fetchCustomers(currentPage, pageLimit, filterText, filterStatus);
  };

  const confirmDelete = (id) => {
    Alerts.swalConfirmDeleteAlert(t("Once deleted, you will not be able to recover this Record!"))
      .then(res => {
        if (res) {
          deleteQuotations(id);
        }
      });
  };


  const ViewDetails = (id) => {
    navigate(`/quotation_details`, { state: { id } });
  }

  const deleteQuotations = (id) => {
    setLoading(true);
    Services.DeleteQuotations(authData.sessionID, id)
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          Alerts.swalSuccessAlert(response.data.message || t("Data Deleted Successfully."));
          fetchCustomers(currentPage, pageLimit, filterText, filterStatus);
        } else {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
          } else {
            Alerts.swalErrorAlert(response.data.message || t("Error: Can't delete at the moment."));
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        Alerts.swalErrorAlert(t("Delete operation failed"));
      });
  };

  const handleDeleteSelected = () => {
    if (selectedRows?.length > 0) {
      Alerts.swalConfirmDeleteAlert(t(selectedRows?.length + " records selected. Once deleted, you will not be able to recover this!"))
        .then(res => {
          if (res) {
            deleteBulkCustomers(selectedRows);
          }
        });
    } else {
      Alerts.swalErrorAlert('No records selected');
    }
  };

  const deleteBulkCustomers = (ids) => {
    setLoading(true);
    Services.DeleteBulkQuotations(authData.sessionID, ids)
      .then((response) => {
        setLoading(false);
        setSelectedRows([]);
        if (response.data.status) {
          Alerts.swalSuccessAlert(response.data.message || t("Data Deleted Successfully."));
          fetchCustomers(currentPage, pageLimit, filterText, filterStatus);
        } else {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
          } else {
            Alerts.swalErrorAlert(response.data.message || t("Error: Can't delete the Collection Data at the moment."));
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        Alerts.swalErrorAlert(t("Delete operation failed"));
      });
  };
  const handleSelectAllChange = (e) => {
    if (e.target.checked) {
      setSelectedRows(Customers.map(Quotations => Quotations._id));
    } else {
      setSelectedRows([]);
    }
  };
  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'pending':
        return '#FFA500'; // Orange
      case 'confirmed':
        return 'green'; // green
      case 'accepted':
        return '#4169E1'; // Royal Blue
      case 'processing':
        return '#9370DB'; // Medium Purple
      case 'in_transit':
        return '#1E90FF'; // Dodger Blue
      case 'delivered':
        return '#32CD32'; // Lime Green
      case 'cancelled':
      case 'rejected':
        return '#DC143C'; // Crimson
      case 'issued':
        return '#228B22'; // Forest Green
      case 'review':
        return '#FF4500'; // Orange Red
      default:
        return '#808080'; // Gray for unknown status
    }
  };
  const columns = [
    { name: 'Order ID', selector: row => row?.order_number ? row?.order_number :row?.rfq_id, sortable: true },
    { name: 'Member Name', selector: row => row?.userDetails?.[0]?.name, sortable: true },
    { name: 'Date of Order', selector: row => moment(row?.datetime_added).format('DD-MM-YYYY'), sortable: true },
    { name: 'Quotation Date', selector: row => row?.quotation_date ? moment(row?.quotation_date).format('DD-MM-YYYY') : 'N/A', sortable: true },
    { name: 'Total Item', selector: row => row?.items?.length, sortable: true },
    {
      name: 'Status',
      selector: row => row?.status.charAt(0).toUpperCase() + row?.status.slice(1),
      sortable: true,
      cell: row => {
        const capitalizedStatus = row?.status.charAt(0).toUpperCase() + row?.status.slice(1);
        return (
          <span style={{
            color: getStatusColor(row?.status),
            fontWeight: 'bold',
          }}>
            {capitalizedStatus}
          </span>
        );
      }
    },
    {
      name: t("Actions"),
      selector: (row) => {
        return (
          <div>
            <button className="mt-2 mb-2 me-2 btn-icon btn">
              {(super_admin || current_module?.ViewData === 1) && (
                <Tooltip title={t("View Details")}>
                  <i onClick={() => ViewDetails(row?._id)} className="pe-7s-note2 btn-icon-wrapper text-primary"> </i>
                </Tooltip>
              )}
              {/* {(super_admin || current_module?.chat === 1) && (
                <Tooltip title={t("Chat")}>
                  <button className="btn btn-link p-0" onClick={() => navigate(`/chat_admin/${row?._id}`)}>
                    <i className="pe-7s-chat text-success" style={{ fontSize: '1.2rem' }}></i>
                  </button>
                </Tooltip>
              )} */}
              {row?.return_status === 'pending' && (super_admin || current_module?.chat === 1) && row?.status.toLowerCase() === "delivered" && (
                <Tooltip title={t("Return Items")}>
                  <button className="btn btn-link p-0" onClick={() => navigate(`/return_items_admin/${row._id}`)} >
                    <i className="pe-7s-refresh text-success" style={{ fontSize: '1.2rem' }}></i>
                  </button>
                </Tooltip>
              )}
              {row?.status.toLowerCase() !== "delivered" && row?.status.toLowerCase() !== "requested" && row?.status.toLowerCase() !== "Review" && (
                !super_admin ? current_module?.EditData === 1 &&
                  <Tooltip placement="top" title={`${t("Update")} ${t("Status")}`} arrow>
                    <i
                      className="pe-7s-pen btn-icon-wrapper text-primary fs-5 p-1"
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleToggleModalUpdateStatus(row)}
                    > </i>
                  </Tooltip>

                  :
                  <Tooltip placement="top" title={`${t("Update")} ${t("Status")}`} arrow>
                    <i
                      className="pe-7s-pen btn-icon-wrapper text-primary fs-5 p-1"
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleToggleModalUpdateStatus(row)}
                    > </i>
                  </Tooltip>
              )}
            </button>
          </div>
        );
      }
    }
  ]


  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchCustomers(page, pageLimit, filterText, filterStatus);
  };

  const handlePerRowsChange = async (newLimit, page) => {
    setPageLimit(newLimit);
  };

  const handleSearch = (searchText, status) => {
    setFilterText(searchText);
    setFilterStatus(status);
    fetchCustomers(0, pageLimit, searchText, status);
  };

  const handleClear = () => {
    setFilterText('');
    setFilterStatus('');
    fetchCustomers(0, pageLimit, '', '');
  };



  const toggleModal = () => {
    setModal(!modal);
  };

  const subHeaderComponentMemo = React.useMemo(() => (
    <QuotationsFilter
      onSearch={handleSearch}
      onClear={handleClear}
      filterText={filterText}
      filterStatus={filterStatus}
    />
  ), [filterText, filterStatus]);

  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className="bg-light-gray bg-animation">
            <div className="d-flex justify-content-center align-items-center">
              <Col md="12" className="mx-auto app-login-box">
                <UpdateStatus
                  showModalUpdateStatus={showModalUpdateStatus}
                  handleToggleModalUpdateStatus={handleToggleModalUpdateStatus}
                  onSuccess={fetchCustomers}
                  array={array}
                />
                <div className="app-page-title mb-3">
                  <div className="page-title-wrapper">
                    <div className="page-title-heading fw-bold">
                      <div className="page-title-icon">
                        <i className="pe-7s-note2 icon-gradient bg-tempting-azure" />
                      </div>
                      <div>
                        {t("Orders")}
                        <div className="page-title-subheading">
                          {t("View Basic and detailed information of ")} {t("Orders")}
                        </div>
                      </div>
                    </div>
                    <div className="page-title-actions">
                      <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-success btn-hover-shine me-3">
                        {t("Back")}
                      </button>
                    </div>
                  </div>
                </div>

                <Card className="main-card mb-3">
                  <CardBody>
                    <LoadingOverlay active={loading} spinner={<DNALoader />}>
                      <Row className="mb-3">
                        <Col md="6">
                          {subHeaderComponentMemo}
                        </Col>
                        <Col md="6" className="text-end">
                          {/* {(super_admin || current_module?.BulkDeleteData === 1) && (
                            <Button color="danger" onClick={handleDeleteSelected} className="me-2">
                              {t('Delete Selected')}
                            </Button>
                          )} */}
                          {/* {(super_admin || current_module?.AddData === 1) && (
                            <AddQuotations onSuccess={handleSuccess} warehouses={warehouses} />
                          )}
                          {(super_admin || current_module?.ImportCSV === 1) && (
                            <button type="button" className="btn btn-custom-color btn-hover-shine me-3 btn ImportData" onClick={toggleModal}>{t('Bulk Quotations In')}</button>
                          )} */}
                        </Col>
                      </Row>
                      <DataTable
                        columns={columns}
                        data={Customers}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handlePerRowsChange}
                        customStyles={customStyles}
                        conditionalRowStyles={conditionalRowStyles}
                      />
                    </LoadingOverlay>
                  </CardBody>
                </Card>
              </Col>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default QuotationsListing;