
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { Col, Row, Button, Label, FormGroup, Input, FormText } from "reactstrap";
import { Form, Field } from "react-final-form";
import { Box, TextField } from "@mui/material";
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import AuthService from "./auth.service";
import AddressService from "./auth.service";
import AlertService from "../../components/Alerts/alerts";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faTimes, faFilePdf, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import loginLogo from '../../assets/loginLogo.svg';
import Swal from 'sweetalert2';
import { Container, Typography, useMediaQuery, useTheme } from "@mui/material";
import axios from 'axios';
import ConfigurationService from "../Configuration/configuration.service";
import FinanceService from "../Finance/Settings/configuration.service";
import Alerts from "../../components/Alerts/alerts";

const ForgotPassword = () => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState(localStorage.getItem("system_logo"));
  const navigate = useNavigate();
  localStorage.removeItem("reset");

  useEffect(() => {
    getSetLanguage();
    setSystemConfig();
  }, []);

  function getSetLanguage() {
    const language = localStorage.getItem("language") || 'en';
    localStorage.setItem('language', language);
    i18n.changeLanguage(language);
  }

  function setSystemConfig() {
    ConfigurationService.getConfiguration().then((response) => {
      if (response.data.status) {
        setLogo(response.data.data[0].system_logo);
        localStorage.setItem("system_logo", response.data.data[0].system_logo);
      }
    });
  }


  const validateFtn = (values) => {
    const errors = {};
    function validateEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
    if (!values.email) {
      errors.email = t('Email Required.');
    }
    else if (!validateEmail(values.email)) {
      errors.email = t('Please enter a valid email address.');
    }
    return errors;
  };

  const onSubmit = values => {
    console.log(values);
    setLoading(true);
    AuthService.pwresetreq(values.email).then((response) => {
      console.log("Response:-", response)
      setLoading(false);
      if (response.data.status) {
        localStorage.setItem("resetpass", true)
        Alerts.swalClickProceedAlert(response.data.message).then(res => {
          console.log("Resp:-", res)
          if (res === true) {
            navigate("/Userauth-password");
          }
        })
        if (response.data.message !== '') {
          Alerts.swalClickProceedAlert(response.data.message).then(res => {
            console.log("Resp:-", res)
            if (res === true) {
              navigate("/Userauth-password");
            }
          })
        }
        else {
          Alerts.swalSuccessAlert(t("Email is sent successfully. Please check your email and reset your password."))
        }
      }
      else {
        if (response.data.message !== '') {
          Alerts.swalErrorAlert(response.data.message)
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't send reset code at the moment. Please try again later."))
        }
      }
    },
      (error) => {
        const resMessage =
          (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        setLoading(false);
        // localStorage.setItem("resetpass", true)//should be removed once API is fixed.
        // navigate("/auth-password");//should be removed once API is fixed.
        // window.location.reload();//should be removed once API is fixed.
        if (resMessage) {
          if (resMessage === 'Network Error') {
            Alerts.swalErrorAlert(t("Server Error, Please try again later."))
          }
          else {
            Alerts.swalErrorAlert(resMessage)
          }
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      });
  };


  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <img src={loginLogo} alt="Logo" style={{ marginBottom: '100px', width: '30%', height: 'auto' }} />
      <Box
        sx={{
          maxWidth: 500,
          width: '100%',
          // background: '#f9f8e8' 
          backgroundColor: '#fff8dc',
          borderRadius: 8,
          padding: 4,
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          textAlign: 'center',
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
          {t('Forgot Password')}
        </Typography>
        <Typography variant="body1" sx={{ color: '#666', marginBottom: 3 }}>
          {t('Enter your email to reset your password.')}
        </Typography>
        <Form onSubmit={onSubmit} validate={validateFtn}
          render={({ handleSubmit, valid }) => (
            <form onSubmit={handleSubmit}>
              <LoadingOverlay active={loading} spinner={<DNALoader />}>
                <div className="modal-body p-5">
                  <Row form>
                    <Col md={12}>
                      <FormGroup>
                        <Field name="email">
                          {({ input, meta }) => (
                            <Row className="align-items-center mb-3">
                              <Col md="12" style={{ textAlign: 'left', paddingRight: '10px' }}>
                                <Label for="email" style={{ fontWeight: 'bold', fontSize: '1rem' }}>
                                  {t('Email')}
                                </Label>
                              </Col>
                              <Col md="12">
                                <Input
                                  {...input}
                                  style={{
                                    border: '1px solid #303030',
                                    background: '#F9F8E8',
                                  }}
                                  type="email"
                                  invalid={meta.error && meta.touched}
                                  placeholder={t('Enter Email')}
                                />
                                {meta.error && meta.touched && (
                                  <span className="text-danger">{meta.error}</span>
                                )}
                              </Col>
                            </Row>
                          )}
                        </Field>
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="d-grid mb-3">
                    <Button size="lg" className="btn-custom-color" type="submit" disabled={!valid}>
                      {t('Send Reset Code')}
                    </Button>
                  </div>
                  <div className="text-center">
                    <Link to="/user-login/Members" style={{
                      color: '#007bff',
                      textDecoration: 'none',
                    }}>Back to Login</Link>
                  </div>
                </div>
              </LoadingOverlay>
            </form>
          )}
        />
      </Box>
    </div>
  );
};

export default ForgotPassword;

