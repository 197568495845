import axios from "axios";

const API_URL = process.env.REACT_APP_BASEURL;
const B_URL = process.env.REACT_APP_LOGINURL
// const storeduser = JSON.parse(localStorage.getItem("user"));


const headers = {
  'Content-Type': 'application/json',
  'token': localStorage.getItem("session-id")
}


const getuserform = (name) => {
  var payload = {
    functionName: "GetUserForm",
    "postData": {
      name: name
    }
  }
  return axios.post(API_URL, payload, { headers });
};


// const deleteCollection = (id, referal_name) => {
//   var payload = {
//     functionName: "DeleteCollectionData",
//     postData: {
//       "_id": id,
//       "collection_name": referal_name,
//       "login_user_id": storeduser._id
//     },
//   }
//   return axios.post(API_URL, payload, { headers });
// };
// const bulkdeleteCollection = (id, referal_name) => {
//   var payload = {
//     functionName: "BulkDeleteDynCollectionData",
//     postData: {
//       "_id": id,
//       "collection_name": referal_name,
//       "login_user_id": storeduser._id
//     },
//   }
//   return axios.post(API_URL, payload, { headers });
// };

const Search_Recurring_Invoices = (sessionID, page, limit, filter, status, startDate, endDate, dueStartDate, dueEndDate, search_text) => {
  var payload = {
    functionName: "Search_Recurring_Invoices",
    customer_id: filter,
    status: status,
    startDate: startDate,
    endDate: endDate,
    search_text: search_text,
    dueStartDate: dueStartDate,
    dueEndDate: dueEndDate,
    limit: limit,
    offset: page
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }

  return axios.post(B_URL + "recurring_invoices", payload, { headers });

};

const getAllPaymentMethods = () => {
  var payload = {
    functionName: "getAllPaymentMethods",
  }
  return axios.post(B_URL + "payments", payload, { headers });
};


// const getcollection = (referal_name, filter, limit, offset) => {
//   var payload = {
//     functionName: "GetCollectionData",
//     postData: {
//       "collection_name": referal_name,
//       "login_user_id": storeduser._id,
//       "filter": filter,
//       "limit": limit,
//       "offset": offset
//     },
//   }
//   return axios.post(API_URL, payload, { headers });
// };

// const getoptions = (referal_name, filter, fieldlist, limit, offset) => {
//   var payload = {
//     functionName: "GetCollectionData",
//     postData: {
//       "collection_name": referal_name,
//       "login_user_id": storeduser._id,
//       "fieldlist": fieldlist,
//       "filter": filter,
//       "limit": limit,
//       "offset": offset
//     },
//   }
//   return axios.post(API_URL, payload, { headers });
// };


// const insertCollectionData = payload => {
//   console.log("Received addRole Payload:-", payload)
//   return axios.post(API_URL, payload, { headers });
// }

// const import_dyn_records = (formData) => {
//   formData.login_user_id = storeduser._id
//   var payload = {
//     functionName: "ImportDynCollectionData",
//     postData: formData
//   }
//   console.log(formData);
//   return axios.post(API_URL, payload, { headers });
// };

const getAllCruds = (filter) => {
  var payload = {
    functionName: "GetCollectionData",
    "postData": {
      "collection_name": "dynamic_modules",
      "filter": filter
    }
  }
  return axios.post(API_URL, payload, { headers });
};

const save_user = (payload) => {
  const API_URL2 = process.env.REACT_APP_LOGINURL + 'modifyusertypes';
  return axios.post(API_URL2, payload, { headers });
};

const getAllCustomers = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'customerRoutes/GetCustomers', payload, { headers });
};

const delete_Recurring_Invoice = (sessionID, id) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  var payload = {
    functionName: "delete_Recurring_Invoice",
    _id: id,
  }
  return axios.post(B_URL + 'recurring_invoices', payload, { headers });
};
const deleteCustomer = (id) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  var payload = {
    functionName: "DeleteCustomer",
    postData: {
      _id: id,
    },
  }
  return axios.post(B_URL + 'customers', payload, { headers });
};
const getInvoice_PDF = (sessionID, payload) => {
  const headers = {

    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + 'invoices', payload, {
    headers,
    responseType: 'arraybuffer'
  });
};
const getDocTypes = () => {
  var payload = {
    functionName: "GetTypes",
    postData: {},
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'documents', payload, { headers });
};

const getCustomerDocs = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'documents', payload, { headers });
};


const AddCustomerDocument = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'documents', payload, { headers });
};

const UpdateCustomerDocument = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'documents', payload, { headers });
};

const deleteDocument = (id) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  var payload = {
    functionName: "DeleteDocument",
    postData: {
      "_id": id,
    },
  }
  return axios.post(B_URL + 'documents', payload, { headers });
};

const getCustomerPricing = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'customers', payload, { headers });
};

const addCustomerPricing = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'customers', payload, { headers });
};

const updateCustomerPricing = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'customers', payload, { headers });
};

const deleteCustomerPricing = (id, service_id, user_id) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  var payload = {
    functionName: "DeleteCustomerPricing",
    postData: {
      "_id": id,
      "service_id": service_id,
      "user_id": user_id
    },
  }
  return axios.post(B_URL + 'customers', payload, { headers });
};


const UpdateOrderStatus = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'orderRoutes/UpdateOrder', payload, { headers });
};
const sendPDF_Mail = (sessionID, row) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  var payload = {
    functionName: "sendPDF_Mail",
    postData: {
      "row": row,
    },
  }
  return axios.post(B_URL + "invoices", payload, { headers });
};
const OnlinePayment = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + "invoices", payload, { headers });
};

const Online_Bulk_Payments = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + "invoices", payload, { headers });
};

const CustomerService = {
  OnlinePayment,
  getAllPaymentMethods,
  Online_Bulk_Payments,
  sendPDF_Mail,
  Search_Recurring_Invoices,
  UpdateOrderStatus,
  getAllCustomers,
  deleteCustomer,
  getInvoice_PDF,
  delete_Recurring_Invoice,
  getDocTypes,
  getCustomerDocs,
  AddCustomerDocument,
  UpdateCustomerDocument,
  deleteDocument,
  getCustomerPricing,
  addCustomerPricing,
  deleteCustomerPricing,
  updateCustomerPricing,
  // import_dyn_records,
  getuserform,
  // deleteCollection,
  // bulkdeleteCollection,
  // getcollection,
  // insertCollectionData,
  getAllCruds,
  // getoptions,
  save_user
};

export default CustomerService;