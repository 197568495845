import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, NavLink } from 'react-router-dom';
import { Row, Col, CardBody, Card } from "reactstrap";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import DataTable from 'react-data-table-component';
import DiscountCouponService from "../DiscountCoupon/Discountcoupons.service";
import AuthService from "../Authentication/auth.service";
import Alerts from "../../components/Alerts/alerts";
import { Tooltip } from "@mui/material";
import UserPointStatementFilter from "../../components/Table Filters/UserPointStatementFilter";
import PermissionAccess from '../../PermissionAccess/Access.js';
import moment from 'moment';

const DeliveryFeeReport = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [rewardPointsHistory, setRewardPointsHistory] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filterBusiness, setFilterBusiness] = useState('');
    const [filterSearchText, setFilterSearchText] = useState('');
    const [filterDate, setFilterDate] = useState('');
    const [pageLimit, setPageLimit] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const { authData, super_admin, current_module } = PermissionAccess();
    const [dateRange, setDateRange] = useState({ from: '', to: '' });

    const [summaryData, setSummaryData] = useState({
        totalPointsIn: 0,
        totalPointsOut: 0,
        totalBalance: 0
    });

    useEffect(() => {
        i18n.changeLanguage(localStorage.getItem('language'));
        fetchRewardPointsHistory(currentPage, pageLimit, filterBusiness, filterSearchText, '');
    }, [])

    const fetchRewardPointsHistory = (page_index, page_limit, business = filterBusiness, search_text = filterSearchText, date = filterDate) => {
        setLoading(true);
        const stored_user = authData.userData;
        let businessIDs = []
        if (stored_user.businesses) {
            businessIDs = stored_user.businesses
        }
        DiscountCouponService.getUserPointStatement(authData.sessionID, page_index, page_limit, business, search_text, date, businessIDs).then(
            (response) => {
                setLoading(false);
                if (response.data.message === "Unauthorized") {
                    AuthService.logout();
                    navigate("/login");
                    window.location.reload();
                } else {
                    setRewardPointsHistory(response.data.data);
                    setTotalRows(response.data.count.count);
                    setSummaryData({
                        totalPointsIn: response.data.sums.totalPointsIn,
                        totalPointsOut: response.data.sums.totalPointsOut,
                        totalBalance: response.data.sums.totalBalance
                    });
                }
            },
            (error) => {
                setLoading(false);
                const errContent =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
                Alerts.swalErrorAlert(errContent || t("Server Error, Please try again later."));
            }
        );
    }

    const mycolumns = [
        {
            name: t('Date Time'),
            selector: 'date',
            width: "13%",
            sortable: true,
            cell: row => moment(row.date).format('DD-MM-YYYY hh:mm:ss A')
        },
        {
            name: t('Customer'),
            selector: row => row.user_details,
            width: "17%",
            sortable: true,
            cell: row => {
                const details = row.user_details || {};
                return (
                    <div style={{ lineHeight: '1.5' }}>
                        <div className="mt-2">
                            <i className="pe-7s-user fs-5" style={{ marginRight: '5px', color: '#black' }}></i>
                            {details.name || t('N/A')}
                        </div>
                        <div className="mt-2">
                            <i className="pe-7s-mail fs-5" style={{ marginRight: '5px', color: '#black' }}></i>
                            {details.email || t('N/A')}
                        </div>
                        <div className="mt-2">
                            <i className="pe-7s-call fs-5" style={{ marginRight: '5px', color: '#black' }}></i>
                            {details.phone || t('N/A')}
                        </div>
                    </div>
                );
            }
        },
        {
            name: t('Order ID'),
            selector: row => row.order_details?.order_number || '',
            width: "10%",
            sortable: true,
            cell: row => (
                row.order_details?.order_number ?
                    <NavLink
                        to={`/order-details/${row.order_id}`}
                        className="text-primary"
                        style={{ textDecoration: 'none' }}
                    >
                        {row.order_details.order_number}
                    </NavLink> : ''
            )
        },
        {
            name: t('Seller Name'),
            selector: row => row.business_details?.name || '',
            width: "10%",
            sortable: true,
        },
        {
            name: t('Amount'),
            selector: 'amount',
            width: "10%",
            sortable: true,
            cell: row => parseFloat(row.amount).toFixed(2)
        },
        {
            name: t('Points'),
            selector: 'points',
            width: "10%",
            sortable: true,
            cell: row => parseFloat(row.points).toFixed(2)
        },
        {
            name: t('Status'),
            selector: 'status',
            width: "10%",
            sortable: true,
            cell: row => (
                <div style={{
                    backgroundColor: row.status === 'Points In' ? '#20c997' : '#dc3545',
                    color: 'white',
                    padding: '2px 7px',
                    borderRadius: '3px',
                    minWidth: '80px',
                    textAlign: 'center'
                }}>
                    {row.status}
                </div>
            )
        },
        {
            name: t('Reason'),
            selector: 'reason',
            width: "15%",
            sortable: true,
            cell: row => (
                <div style={{
                    backgroundColor: row.status === 'Points In' ? '#20c997' : '#dc3545',
                    color: 'white',
                    padding: '2px 7px',
                    borderRadius: '3px',
                    minWidth: '80px',
                    textAlign: 'center'
                }}>
                    {row.reason}
                </div>
            )
        },
        {
            name: t('Actions'),
            width: "5%",
            cell: (row) => (
                <div>

                    {!super_admin ? current_module?.ViewDetails === 1 &&
                        <NavLink to={`/order-details/${row.order_id}`}>
                            <Tooltip placement="top" title={t("View Details")} arrow>
                                <i className="pe-7s-look text-primary btn-icon-wrapper fs-5 p-1" />
                            </Tooltip>
                        </NavLink>
                        :
                        <NavLink to={`/order-details/${row.order_id}`}>
                            <Tooltip placement="top" title={t("View Details")} arrow>
                                <i className="pe-7s-look text-primary btn-icon-wrapper fs-5 p-1" />
                            </Tooltip>
                        </NavLink>
                    }

                </div>
            ),
            button: true,
        }
    ];

    const handleSearch = (business, searchText, date) => {
        setFilterBusiness(business);
        setFilterSearchText(searchText);
        setFilterDate(date);
        if (date) {
            const [from, to] = date.split(' - ');
            setDateRange({ from, to });
        } else {
            setDateRange({ from: '', to: '' });
        }
        fetchRewardPointsHistory(0, pageLimit, business, searchText, date);
        setCurrentPage(0);
    };

    const handleClear = () => {
        setFilterBusiness('');
        setFilterSearchText('');
        setFilterDate('');
        setDateRange({ from: '', to: '' });
        fetchRewardPointsHistory(0, pageLimit, '', '');
        setCurrentPage(0);
    };

    const subHeaderComponentMemo = React.useMemo(() => {
        return (
            <UserPointStatementFilter
                onSearch={handleSearch}
                onClear={handleClear}
                filterBusiness={filterBusiness}
                filterSearchText={filterSearchText}
                filterDate={filterDate}
            />
        );
    }, [filterBusiness, filterSearchText, filterDate]);

    const handlePageChange = page => {
        setCurrentPage(page - 1);  // Adjust for 0-based index
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPageLimit(newPerPage);
        setCurrentPage(page - 1);  // Adjust for 0-based index
    };

    const SummaryCard = ({ title, value, color, icon }) => (
        <Card className="mb-3" style={{ backgroundColor: color, color: 'white' }}>
            <CardBody>
                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <h6>{title}</h6>
                        <h3>{value.toFixed(2)}</h3>
                    </div>
                    <i className={`pe-7s-${icon} fa-2x`} />
                </div>
            </CardBody>
        </Card>
    );

    return (
        <Fragment>
            <div className="app-main__outer">
                <div className="app-main__inner">
                    <div className="bg-light-gray bg-animation">
                        <div className="d-flex justify-content-center align-items-center">
                            <Col md="12" className="mx-auto app-login-box">
                                <div className="app-page-title">
                                    <div className="page-title-wrapper">
                                        <div className="page-title-heading fw-bold">
                                            <div className="page-title-icon">
                                                <i className="pe-7s-news-paper icon-gradient bg-tempting-azure" />
                                            </div>
                                            <div>
                                                {t("Points Statement")}
                                                <div className="page-title-subheading">
                                                    {t("Points Statement Filters")}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Filters */}


                                <Row>
                                    <LoadingOverlay
                                        tag="div"
                                        active={loading}
                                        styles={{ overlay: (base) => ({ ...base }) }}
                                        spinner={<DNALoader />}
                                    >
                                        <Col md="12">
                                            <Card className="main-card mb-3">
                                                <CardBody>
                                                    <Col md="12" className="mb-3">
                                                        {subHeaderComponentMemo}
                                                    </Col>
                                                    {/* Summary Cards */}
                                                    <Row className="mb-3">
                                                        <Col md="4">
                                                            <SummaryCard
                                                                title={t("Total Points In")}
                                                                value={summaryData.totalPointsIn}
                                                                color="#4ecdc4"
                                                                icon="arrow-up"
                                                            />
                                                        </Col>
                                                        <Col md="4">
                                                            <SummaryCard
                                                                title={t("Total Points Out")}
                                                                value={summaryData.totalPointsOut}
                                                                color="#ff6b6b"
                                                                icon="arrow-down"
                                                            />
                                                        </Col>
                                                        <Col md="4">
                                                            <SummaryCard
                                                                title={t("Balance")}
                                                                value={summaryData.totalBalance}
                                                                color="#45aaf2"
                                                                icon="wallet"
                                                            />
                                                        </Col>
                                                    </Row>

                                                    {/* DataTable */}
                                                    <DataTable
                                                        data={rewardPointsHistory}
                                                        columns={mycolumns}
                                                        pagination
                                                        paginationServer
                                                        paginationTotalRows={totalRows}
                                                        onChangePage={handlePageChange}
                                                        onChangeRowsPerPage={handlePerRowsChange}
                                                        paginationResetDefaultPage={resetPaginationToggle}
                                                        persistTableHead
                                                    />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </LoadingOverlay>
                                </Row>
                            </Col>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default DeliveryFeeReport;