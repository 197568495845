import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { Col, Row, Label, FormGroup, Input, Card, CardBody } from "reactstrap";
import { Form, Field } from "react-final-form";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";
import Alerts from "../../components/Alerts/alerts";
import ConfigurationService from "./configuration.service";
import AuthService from "../Authentication/auth.service";
import PermissionAccess from '../../PermissionAccess/Access.js';

const RewardPointsSettings = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [configs, setConfig] = useState({});
  const { t, i18n } = useTranslation();
  const { authData, accessPages, super_admin, current_module } = PermissionAccess();

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    getSystemConfig();
  }, []);

  function getSystemConfig() {
    ConfigurationService.getConfiguration(authData.sessionID).then((response) => {
      if (response.data.status) {
        const [rec_data] = response.data.data;
        setConfig(rec_data);
      } else {
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        } else {
          Alerts.swalErrorAlert(t("Unable to retrieve configurations at the moment."));
        }
      }
    }, (error) => {
      Alerts.swalErrorAlert(t("Unable to retrieve configurations at the moment."));
    });
  }

  function handleSave(values) {
    setLoading(true);
    const payloadData = {
      functionName: "SetConfigurations",
      postData: {
        points_per_unit: values.points_per_unit,
        minimum_points_to_redeem: values.minimum_points_to_redeem,
        redemption_rate: values.redemption_rate,
      },
    };

    ConfigurationService.setConfiguration(authData.sessionID, payloadData).then((response) => {
      setLoading(false);
      if (response.data.status) {
        Alerts.swalSuccessAlert(t("Configuration updated successfully."));
        getSystemConfig();
      } else {
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        } else {
          Alerts.swalErrorAlert(response.data.message || t("Error: Can't update configuration at the moment. Please try again later."));
        }
      }
    }, (error) => {
      setLoading(false);
      Alerts.swalErrorAlert(t("Network Operation failed."));
    });
  }

  const validateForm = (values) => {
    const errors = {};
    if (!values.points_per_unit) {
      errors.points_per_unit = t("Points Per Unit is required.");
    }
    if (!values.minimum_points_to_redeem) {
      errors.minimum_points_to_redeem = t("Minimum Points To Redeem is required.");
    }
    if (!values.redemption_rate) {
      errors.redemption_rate = t("Redemption Rate is required.");
    }
    return errors;
  };

  return (
    <Card className="main-card mb-3">
      <CardBody className="col-md-8">
        <Form
          initialValues={configs}
          validate={validateForm}
          onSubmit={handleSave}
          render={({ handleSubmit, valid }) => (
            <form onSubmit={handleSubmit}>
              <LoadingOverlay tag="div" active={loading} styles={{ overlay: (base) => ({ ...base }) }} spinner={<DNALoader />}>
                <Row form>
                  <Col md={12}>
                    <FormGroup>
                      <Field name="points_per_unit">
                        {({ input, meta }) => (
                          <div className="row mb-3">
                            <Label for="points_per_unit" className="col-sm-5 col-form-label">{t('Award Points Per Ringget (Example: RM 1=1Point)')}</Label>
                            <div className="col-sm-7">
                              <Input {...input} type="number" invalid={meta.error && meta.touched} placeholder={t('Points Per Unit')} />
                              {meta.error && meta.touched && <span className="text-danger">{meta.error}</span>}
                            </div>
                          </div>
                        )}
                      </Field>
                    </FormGroup>

                    <FormGroup>
                      <Field name="minimum_points_to_redeem">
                        {({ input, meta }) => (
                          <div className="row mb-3">
                            <Label for="minimum_points_to_redeem" className="col-sm-5 col-form-label">{t('Minimum Points To Redeem')}</Label>
                            <div className="col-sm-7">
                              <Input {...input} type="number" invalid={meta.error && meta.touched} placeholder={t('Minimum Points To Redeem')} />
                              {meta.error && meta.touched && <span className="text-danger">{meta.error}</span>}
                            </div>
                          </div>
                        )}
                      </Field>
                    </FormGroup>

                    <FormGroup>
                      <Field name="redemption_rate">
                        {({ input, meta }) => (
                          <div className="row mb-3">
                            <Label for="redemption_rate" className="col-sm-5 col-form-label">{t('Redemption Rate (Example: 100 Points=RM 1)')}</Label>
                            <div className="col-sm-7">
                              <Input {...input} type="number" invalid={meta.error && meta.touched} placeholder={t('Redemption Rate')} />
                              {meta.error && meta.touched && <span className="text-danger">{meta.error}</span>}
                            </div>
                          </div>
                        )}
                      </Field>
                    </FormGroup>

                    <FormGroup>
                      <div className="d-flex justify-content-center">
                        <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-light btn-hover-shine me-3 mt-3">{t('Cancel')}</button>
                        <button className="btn btn-custom-color btn-hover-shine me-3 mt-3" type="submit" disabled={!valid}>{t('Save')}</button>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </LoadingOverlay>
            </form>
          )}
        />
      </CardBody>
    </Card>
  );
};

export default RewardPointsSettings;