import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Typography, Button, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import AuthService from "./auth.service";
import AlertService from "../../components/Alerts/alerts";
import ConfigurationService from "../Configuration/configuration.service";

const ResetPassword = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState(localStorage.getItem("system_logo"));
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [resetCode, setResetCode] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  useEffect(() => {
    getSetLanguage();
    getSystemConfig();
  }, []);

  function getSetLanguage() {
    const language = localStorage.getItem("language") || 'en';
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
  }

  function getSystemConfig() {
    ConfigurationService.getConfiguration().then((response) => {
      if (response.data.status) {
        setSystemLogo(response.data.data[0].system_logo);
      }
    }).catch(() => {
      // Handle error
    });
  }

  function setSystemLogo(sys_logo) {
    setLogo(sys_logo);
    localStorage.setItem("system_logo", sys_logo);
  }

  const onSubmit = (e) => {
    e.preventDefault();
    if (!resetCode || !password || !confirmPassword) {
      AlertService.swalErrorAlert(t("All fields are required."));
      return;
    }
    if (password !== confirmPassword) {
      AlertService.swalErrorAlert(t("Passwords do not match."));
      return;
    }
    setLoading(true);
    AuthService.resetpw(password, confirmPassword, resetCode).then((response) => {
      setLoading(false);
      if (response.data.status) {
        AlertService.swalSuccessAlert(t("Password is reset successfully. Please login again."));
        navigate("/customer_login");
      } else {
        AlertService.swalErrorAlert(response.data.message);
      }
    }).catch((error) => {
      setLoading(false);
      AlertService.swalErrorAlert(t("Server Error, Please try again later."));
    });
  };

  return (
    <div style={{ backgroundColor: "black", minHeight: "100vh", display: "flex", justifyContent: "center" }}>
      <div style={{ width: "100%", maxWidth: "1300px", backgroundColor: "white", position: "relative" }}>
        <div style={{ backgroundColor: "white", minHeight: "50vh", display: "flex", justifyContent: "center", paddingTop: "50px" }}>
          <div style={{
            width: '100%',
            maxWidth: '500px',
            backgroundColor: '#fff',
            padding: '30px',
            borderRadius: '15px',
            boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
            position: 'relative'
          }}>
            <div style={{ position: 'absolute', top: '20px', left: '20px' }}>
              <IconButton onClick={() => navigate(-1)}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            
            {/* Logo */}
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
              <img
                src={logo}
                alt="Logo"
                style={{ width: '80px', height: '80px', borderRadius: '50%' }}
              />
            </div>

            {/* Form */}
            <form onSubmit={onSubmit}>
              <Typography variant="h5" align="center" style={{ marginBottom: '20px' }}>
                {t('Reset Password')}
              </Typography>
              <Typography variant="body2" align="center" style={{ marginBottom: '20px', color: '#666' }}>
                {t('Use the form below to reset it.')}
              </Typography>

              {/* Reset Code Input */}
              <div style={{ marginBottom: '20px' }}>
                <Typography variant="body1" style={{ marginBottom: '5px', color: '#9897A1' }}>{t('Reset Code')}</Typography>
                <input
                  type="text"
                  placeholder={t('Reset Code')}
                  value={resetCode}
                  onChange={(e) => setResetCode(e.target.value)}
                  required
                  style={{
                    width: '100%',
                    padding: '15px',
                    color:'#A2A1AA',
                    fontSize: '16px',
                    border: '1px solid #EEEEEE',
                    borderRadius: '8px',
                    backgroundColor: '#FEFEFE'
                  }}
                />
              </div>

              {/* New Password Input */}
              <div style={{ marginBottom: '20px', position: 'relative' }}>
                <Typography variant="body1" style={{ marginBottom: '5px', color: '#9897A1' }}>{t('New Password')}</Typography>
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder={t('New Password')}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  style={{
                    width: '100%',
                    padding: '15px',
                    color:'#A2A1AA',
                    fontSize: '16px',
                    border: '1px solid #EEEEEE',
                    borderRadius: '8px',
                    backgroundColor: '#FEFEFE',
                    paddingRight: '50px'
                  }}
                />
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  style={{
                    position: 'absolute',
                    right: '5px',
                    top: '30px',
                    color: '#666',
                  }}
                >
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </div>

              {/* Confirm New Password Input */}
              <div style={{ marginBottom: '20px', position: 'relative' }}>
                <Typography variant="body1" style={{ marginBottom: '5px', color: '#9897A1' }}>{t('Confirm New Password')}</Typography>
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder={t('Confirm New Password')}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                  style={{
                    width: '100%',
                    padding: '15px',
                    color:'#A2A1AA',
                    fontSize: '16px',
                    border: '1px solid #EEEEEE',
                    borderRadius: '8px',
                    backgroundColor: '#FEFEFE',
                    paddingRight: '50px'
                  }}
                />
                <IconButton
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  style={{
                    position: 'absolute',
                    right: '5px',
                    top: '30px',
                    color: '#666',
                  }}
                >
                  {showConfirmPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </div>

              {/* Reset Password Button */}
              <Button
                type="submit"
                fullWidth
                style={{
                  backgroundColor: '#F2731C',
                  color: '#fff',
                  padding: '15px',
                  fontSize: '16px',
                  borderRadius: '8px',
                  textTransform: 'none',
                  marginBottom: '20px'
                }}
              >
                {t('Reset Password')}
              </Button>
            </form>

            {/* Back to Login */}
            <Typography variant="body2" align="center">
              {t('Remember your password?')}{' '}
              <span
                style={{ color: '#F2731C', cursor: 'pointer' }}
                onClick={() => navigate("/customer_login")}
              >
                {t('Sign in existing account')}
              </span>
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;