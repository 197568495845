import React, { useEffect, useState, Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Row,
  Col
} from "reactstrap";

import { useTranslation } from 'react-i18next';
import { Card, CardBody } from "reactstrap";
import CustomerService from "./Customer.service";
import AuthService from "../../Authentication/auth.service";
import Alerts from "../../../components/Alerts/alerts";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import "../../../assets/custom css/loadoverlay.scss";

import Tabs, { TabPane } from "rc-tabs";
import TabContent from "rc-tabs/lib/SwipeableTabContent";
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar";

import PermissionAccess from '../../../PermissionAccess/Access.js';

const CustomerDetails = () => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [checkTab, setCheckTab] = useState(localStorage.getItem('tab5'));
  const location = useLocation();
  const { state } = location;
  console.log("Received Object:-", state);
  const [pdfUrl, setPdfUrl] = useState("");
  const [pdfModalIsOpen, setPDFModalIsOpen] = useState(false);
  const { authData, accessPages, super_admin, current_module } = PermissionAccess();

  useEffect(() => {
    if (!checkTab) {
      setCheckTab("1")
    } else {
      localStorage.removeItem('tab5');
    }
  }, [checkTab]);

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
  }, []);

  const handleViewPDF = (invoice_number) => {
    var getData = {
      functionName: "Get_Invoice_Details",
      invoice_number: invoice_number
    };
    console.log(getData);
    setLoading(true);
    console.log(getData);
    CustomerService.getInvoice_PDF(getData, authData.sessionID)
      .then((response) => {
        setLoading(false);
        console.log(response.status);
        console.log(response);
        if (response.status === 200) {
          setPdfUrl(URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' })));
          setPDFModalIsOpen(true);
        } else if (response.status === 401) {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        } else {
          // Handle other error cases, if necessary
          console.log("Response status: " + response.status);
          // Display an error message or perform other actions as needed.
        }
      })
      .catch((error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent);
        if (errContent) {
          Alerts.swalErrorAlert(errContent);
        } else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."));
        }
      });
  };

  const PDFViewerModal = () => {
    console.log(pdfUrl);
    return (
      <div
        className={`modal fade ${pdfModalIsOpen ? "show" : ""}`}
        style={{ display: pdfModalIsOpen ? "block" : "none" }}
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content ">
            <div className="modal-header ">
              <h5 className="modal-title">Customer's Order Invoice</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setPDFModalIsOpen(false)}
              ></button>
            </div>
            <div className="modal-body">
              <iframe title="pdfUrl" src={pdfUrl} style={{ width: "100%", height: "500px" }} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <PDFViewerModal />
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className="bg-light-gray bg-animation">
            <div className="d-flex justify-content-center align-items-center">
              <Col md="12" className="mx-auto app-login-box">
                <div className="app-page-title mb-1">
                  <div className="page-title-wrapper">
                    <div className="page-title-heading fw-bold">
                      <div className="page-title-icon">
                        <i className="pe-7s-note2 icon-gradient bg-tempting-azure" />
                      </div>
                      <div>
                        {t("Payments Details")}
                        <div className="page-title-subheading">
                          {t("View Basic and detailed information of a Payment.")}
                        </div>
                      </div>
                    </div>
                    <div className="page-title-actions">
                      <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-success btn-hover-shine me-3">
                        {t("Back")}
                      </button>
                    </div>
                  </div>
                </div>

                <Row>
                  <Tabs defaultActiveKey={checkTab} renderTabBar={() => <ScrollableInkTabBar />} renderTabContent={() => <TabContent />}>
                    <TabPane tab={t("Detailed Information")} key="2">
                    </TabPane>

                  </Tabs>
                  <Col md="12">
                    <Card className="main-card">
                      <LoadingOverlay tag="div" active={loading}
                        styles={{ overlay: (base) => ({ ...base }) }}
                        spinner={<DNALoader />}>
                        <CardBody>
                          <table className="table table-striped table-bordered nowrap">
                            <tbody>
                              <tr>
                                <th>{t('Payment ID ')}</th>
                                <td>{state.Payment_ID}</td>
                              </tr>
                              <tr>
                                <th>{t('Payment Method')}</th>
                                <td>{state.payment_method}</td>
                              </tr>
                              <tr>
                                <th>{t('Payment Currency')}</th>
                                <td>{state.payment_currency}</td>
                              </tr>
                              <tr>
                                <th>{t('Total Amount')}</th>
                                <td>{state.total_amount}</td>
                              </tr>
                              {/* <tr>
                                <th>{t('Remaining Amount')}</th>
                                <td>{state?.remaining_amount}</td>
                              </tr> */}
                              <tr>
                                <th>{t('Transition ID')}</th>
                                <td>{state.transition_id}</td>
                              </tr>
                              <tr>
                                <th>{t('Status')}</th>
                                <td>{state.status}</td>
                              </tr>

                              {state?.invoice_number ? (
                                state.invoice_number.includes(',') ? (
                                  state.invoice_number.split(',').map((invoice, index) => (
                                    <tr key={index}>
                                      <th
                                        style={{ color: 'red', cursor: 'pointer' }}
                                        onClick={() => handleViewPDF(invoice)}
                                      >
                                        {t(`Amount for ${invoice}`)}
                                      </th>
                                      <td>
                                        {state.remaining_amount?.split(',')[index]}
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <th
                                      style={{ color: 'blue', cursor: 'pointer' }}
                                      onClick={() => handleViewPDF(state.invoice_number)}
                                    >
                                      {t(`Amount for ${state.invoice_number}`)}
                                    </th>
                                    <td>
                                      {state.amount}
                                    </td>
                                  </tr>
                                )
                              ) : (
                                // Render a message or fallback content if invoice_number is not available
                                <tr>
                                  <td colSpan="2">{t("No invoice number available")}</td>
                                </tr>
                              )}
                              {state?.Receipt &&
                                <tr>
                                  <th>{t('Payment Proof')}</th>
                                  <td>
                                    <img
                                      src={state.Receipt}
                                      alt="Receipt"
                                      style={{ maxWidth: '100px', transition: 'transform 0.3s ease-in-out' }}
                                      onMouseOver={(e) => { e.target.style.transform = 'scale(5)'; }}
                                      onMouseOut={(e) => { e.target.style.transform = 'scale(1)'; }}
                                    />
                                  </td>
                                </tr>
                              }

                            </tbody>
                          </table>
                        </CardBody>
                      </LoadingOverlay>

                    </Card>
                  </Col>
                </Row>

              </Col>
            </div>

          </div>
        </div>
      </div>
    </Fragment>
  );
};


export default CustomerDetails;

