import React, { Fragment, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Row, Col, Card, CardBody, Button, Input, FormGroup, Label,
} from "reactstrap";
import moment from "moment";
import { format } from "date-fns";
import DynModuleService from "./dynamicmodules.service";

import CustomerService from "./Invoices.service";
import Select from 'react-select';

import { Form, Field } from "react-final-form";

import AuthService from "../../Authentication/auth.service";
import { useTranslation } from 'react-i18next';
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import "../../../assets/custom css/loadoverlay.scss";
import Alerts from "../../../components/Alerts/alerts";
import { Divider, Tooltip } from "@mui/material";
import PermissionAccess from '../../../PermissionAccess/Access.js';


const UpdateInvoice = () => {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state.state;
  const check = location;

  console.log(state);
  const [loading, setLoading] = useState(false);
  const [total_amount, setTotal_amount] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [tax, setTax] = useState(state.tax);
  const { authData, accessPages, super_admin, current_module } = PermissionAccess();

  const getCurrentDate = () => {
    return format(new Date(), "yyyy-MM-dd");
  };

  const getDefaultDueDate = (days) => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + days);
    return format(currentDate, "yyyy-MM-dd");
  };
  const [invoiceDate, setInvoiceDate] = useState(state.invoice_date ? moment(state.invoice_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'));
  const [DueDate, setDueDate] = useState(state.due_date ? moment(state.due_date).format('YYYY-MM-DD') : getDefaultDueDate(parseInt(localStorage.getItem("due_date"))));
  const [discount_Amount, setDiscount_Amount] = useState(0);
  const [amount, setAmount] = useState(0);
  const [discount, setDiscount] = useState(state.discount ? state.discount : 0);
  const [discountType, setDiscountType] = useState(state?.discountType ? state.discountType : '%');

  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [Customers, setCustomers] = useState([]);
  const [Services, setServices] = useState([]);
  const [TaxClasses, setTaxClasses] = useState([]);


  const [collectionlist, setcollectionlist] = useState([])
  const [fieldlist, setfieldlist] = useState([])
  const [fieldlist_validate, setfieldlist_validate] = useState([])


  
  useEffect(() => {
    const foundCustomer = Customers.find(customer => customer.value === state.customer_id);
    if (foundCustomer) {
      setSelectedCustomer(foundCustomer);
    }
  }, [Customers]);


  const [data, setData] = useState(state.item ? state.item : []);

  // Define your select options
  const renderSelect = ({ input, meta }) => (
    <div>
      <Select
        {...input}
        options={Customers}
        isDisabled={true}
        value={selectedCustomer}
        onChange={(selectedOption) => {
          input.onChange(selectedOption);
          setSelectedCustomer(selectedOption);
        }}
        isSearchable={true}
        placeholder="Select or Add a Customer"
      />
      {meta.error && meta.touched && <span className="text-danger">{meta.error}</span>}
    </div>
  );


  const [editableRowIndex, setEditableRowIndex] = useState([]);
  const [bulkEditMode, setBulkEditMode] = useState(false); // New state to control bulk edit mode

  const handleEditClick = (index) => {
    if (index === undefined) {
      // If index is undefined, it's the Bulk Update button
      setBulkEditMode(!bulkEditMode); // Toggle bulk edit mode
      if (!bulkEditMode) {
        setEditableRowIndex(null); // Reset individual edit mode if entering bulk edit mode
      }
    } else {
      setEditableRowIndex(index); // Individual row edit mode
    }
  };
  const handleSaveClick = () => {
    setEditableRowIndex([null]);
  };


  const handleAddRow = () => {
    const newData = [...data];
    const id = data.length + 1;
    newData.push({
      id, item_name: '', description: '',
      quantity: 1
    });
    setData(newData);

    // Set editableRowIndex to the index of the newly added item
    setEditableRowIndex(newData.length - 1);
  };

  const handleDeleteClick = (index) => {
    const newData = [...data];
    newData.splice(index, 1); // Remove the item at the specified index
    setData(newData);
    setEditableRowIndex(null); // Clear the editable row if deleted
  };

  const handleInputChange = (value, field, index) => {
    const newData = [...data];
    newData[index][field] = value;
    if (field === 'tax_class') {
      console.log(newData);
      newData[index]["tax"] = (parseFloat(newData[index].quantity) * parseFloat(newData[index].rate) * parseFloat(value) / 100);
    }
    if (field === 'quantity' || field === 'rate') {
      // Calculate the new tax amount based on quantity, rate, and tax percentage
      const taxPercentage = parseInt(newData[index].tax_class) || 0;
      const quantity = parseInt(newData[index].quantity) || 0;
      const rate = parseInt(newData[index].rate) || 0;

      newData[index]["tax"] = (quantity * rate * taxPercentage) / 100;

      // Update the amount field
      newData[index]["amount"] = rate * quantity;
    }

    const totalTax = newData.reduce((acc, item) => acc + (item.tax || 0), 0);

    setTax(totalTax);
    setData(newData);
  };
  const handleTaxChange = (newValue, index) => {
    const newData = [...data];
    newData[index]["tax"] = parseFloat(newValue);

    const totalTax = newData.reduce((acc, item) => acc + (item.tax || 0), 0);

    setTax(totalTax);
    setData(newData);
  };

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchCustomers();
    fetchServices();
    getTotal();
  }, []);

  useEffect(() => {
    getTotal();
  }, [data, discount, discountType]);
  useEffect(() => {
    fetchTaxClasses();
    fetchDynamicData();
  }, []);
  function fetchTaxClasses() {

    var getData = {
      functionName: "getAllClasses",
      postData: {
        "sort": { _id: -1 },
        "offset": 0,
        "limit": 1000,
        "filter": {}
      },
    }
    CustomerService.getAllClasses(getData, authData.sessionID).then((response) => {
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      } else {
        if (response.data && response.data.data) {
          setTaxClasses(response.data.data);
          console.log(response.data)
        }
      }
    }, (error) => {
      const errContent =
        (error.response && error.response.data) || error.message || error.toString();
      if (errContent) {
        Alerts.swalErrorAlert(errContent);
      } else {
        Alerts.swalErrorAlert(t("Server Error, Please try again later."));
      }
    });
  }
  const fetchDynamicData = async () => {
    try {
      const response = await DynModuleService.get_cruds_dynamic_data();
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        const data = await response.data;
        
        var custom_fields = data;
        for (var i = 0; i < custom_fields.length; i++) {
          if (custom_fields[i].options_collection !== '') {

            const optresponse = await DynModuleService.getoptions(custom_fields[i].options_collection, "", {}, 5000);
            const optdata = await optresponse.data.data;
            var options = [];

            for (var j = 0; j < optdata.length; j++) {
              options.push({ label: optdata[j][custom_fields[i].Options_Field_Name], value: optdata[j]._id, })
            }
            custom_fields[i].options = options;
          }
          custom_fields[i].default_value = ""
        }

        data[0].custom_fields = custom_fields;

        setcollectionlist(data[0]);
        setfieldlist(data[0].custom_fields);
        var cust = data[0].custom_fields;
        var validations = {};
        for (var i = 0; i < cust.length; i++) {
          validations[cust[i].referal_name] = cust[i].is_required;
        }
        setfieldlist_validate(validations);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const [customerNotes, setCustomerNotes] = useState(state.comments ? state.comments : localStorage.getItem('default_notes'));


  const handleCustomerNotesChange = (event) => {
    // Update the state variable when the user types in the textarea
    setCustomerNotes(event.target.value);
  };
  function getTotal() {
    const totalAmount = data.reduce((total, invoice) => {
      const amount = parseFloat(invoice.amount);
      return !isNaN(amount) ? total + amount : total;
    }, 0);
    if (discountType === '%') {
      const discountPercentage = parseFloat(discount) / 100;
      const discountAmount = totalAmount * discountPercentage;
      const finalAmount = totalAmount - discountAmount;
      setDiscount_Amount(discountAmount);
      setTotal_amount(finalAmount);
      setSubtotal(parseFloat(totalAmount).toFixed(2));
    } else {
      const discountPercentage = parseFloat(discount);
      const discountAmount = discountPercentage;
      const finalAmount = totalAmount - discountAmount;
      setDiscount_Amount(discountAmount);
      setTotal_amount(finalAmount);
      setSubtotal(parseFloat(totalAmount).toFixed(2));

    }
  }
  function fetchServices() {
    var getData = {
      functionName: "GetItems",
      postData: {
        "sort": { _id: -1 },
        "offset": 0,
        "limit": 1000,
        "filter": {},
        // TableName: localStorage.getItem("invoice_items"),
        // TableField: localStorage.getItem("invoice_items_field")

      },
    }
    CustomerService.GetItems(getData, authData.sessionID).then((response) => {
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        if (response.data) {
          const data = transformDataForServices(response.data.data);
          setServices(data);
        }
      }
    },
      (error) => {
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };
  const transformDataForServices = (data) => {
    return data.map((item) => ({
      label: item.item_name,
      description: item.description,
      price: item.price,
      value: item._id,
    }));
  };

  function confirmBack() {
    let msg = t("Once Back, you will not be able to revert this Invoice!")
    Alerts.swalConfirmGeneralAlert(msg).then(res => {
      console.log("Resp:-", res)
      if (res === true) {
        navigate(-1)
      }
    })
  };

  function fetchCustomers() {
    var getData = {
      functionName: "GetInvoiceTo",
      postData: {
        sort: { _id: -1 },
        offset: 0,
        limit: 1000,
        filter: {
          user_type: localStorage.getItem("invoice_to").split(','),
        },
        TableName: 'users',
        TableField: localStorage.getItem("invoice_to_field")
      },
    };
    CustomerService.GetInvoiceTo(getData, authData.sessionID).then((response) => {
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        if (response.data) {
          const data = transformDataForSelect(response.data.data);
          setCustomers(data);
        }
      }
    },
      (error) => {
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  const transformDataForSelect = (data) => {
    let getTableField = localStorage.getItem('invoice_to_field');
    return data.map((item) => ({
      label: item[getTableField],
      value: item._id,
    }));
  };

  const onSubmit = values => {
    delete values.invoice_to;
    delete values.discount;
    delete values.discount_type;

    const Order = values.order_no;
    delete values.order;
    delete values.added_by;
    delete values.comments;
    delete values.currency;
    delete values.discountType;
    delete values.due_date;
    delete values.customer_id;
    delete values.user_details;
    delete values.item;
    delete values.order_no;
    delete values.status;
    delete values.total_amount;
    delete values.invoice_date;
    delete values.invoice_list_data;
    delete values.invoice_number;
    delete values._id;
    console.log(values);

    var payloadData = {
      functionName: "UpdateInvoice",
      _id: state._id,
      customer_id: selectedCustomer?.value,
      order_no: Order,
      invoice_number: state?.invoice_number,
      invoice_date: invoiceDate,
      due_date: DueDate,
      item: data,
      discountType: discountType,
      sub_total: parseFloat(subtotal).toFixed(2),
      discount: discount,
      tax: tax,
      amount: parseFloat(amount).toFixed(2),
      total_amount: parseFloat(total_amount + tax).toFixed(2),
      discount_amount: parseFloat(discount_Amount).toFixed(2),
      comments: customerNotes,
      currency: localStorage.getItem('currency'),
      paymentMethod: localStorage.getItem('payment_method'),
      invoice_list_data: values
    }
    console.log("Payload:-", payloadData)
    setLoading(true);
    CustomerService.UpdateInvoice(payloadData, authData.sessionID).then((response) => {
      console.log("API Response:-", response);
      setLoading(false);
      if (response.data.status) {
        if (check) {
          localStorage.setItem('tab5', "5");
        }
        navigate(-1)
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
      }
      else {
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't add fleet at the moment. Please try again later."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Network Operation failed."))
      });
  }

  const validateFtn = (values) => {
    // console.log("values:-", values.brand_id)
    const errors = {};
    // if (!selectedCustomer) {
    //   errors.customer_name = "Customer Required.";
    // }
    // if (!discount) {
    //   errors.discount = "Discount is Required.";
    // }
    return errors;
  };


  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className=" bg-light-gray bg-animation">
            <div className="d-flex  justify-content-center align-items-center">
              <Col md="12" className="mx-auto app-login-box">

                <div className="app-page-title">
                  <div className="page-title-wrapper">
                    <div className="page-title-heading fw-bold">
                      <div className="page-title-icon">
                        <i className="pe-7s-news-paper icon-gradient bg-tempting-azure" />
                      </div>
                      <div>
                        {t("Update Invoice")}
                        <div className="page-title-subheading">
                          {t('Update details of Invoice')}
                        </div>


                      </div>
                    </div>
                    <div className="page-title-actions">
                      <button type="button" onClick={() => {
                        if (check) {
                          localStorage.setItem('tab5', "5");
                        }
                        navigate(-1)
                      }} className="btn btn-outline-success btn-hover-shine me-3">
                        {t("Back")}
                      </button>
                    </div>
                  </div>
                </div>

                <Card className="main-card mb-3">
                  <CardBody>
                    <Row>
                      <Col md="12" className="pt-3">
                        <LoadingOverlay tag="div" active={loading}
                          styles={{ overlay: (base) => ({ ...base }) }}
                          spinner={<DNALoader />}>
                          <Form onSubmit={onSubmit}
                            validate={validateFtn}
                            initialValues={state}
                            render={({ handleSubmit, values, submitting, validating, valid }) => (
                              <form onSubmit={handleSubmit}>
                                <Row>
                                  <Col md="12" >

                                    <Row style={{}} className="align-items-center">
                                      <Col md="2"><Label className="pb-1">{t("Customer Name")}</Label></Col>
                                      <Col md="4">
                                        <FormGroup>
                                          <Field name="invoice_to" component={renderSelect}
                                            selectOptions={Customers.value} />
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col md="6">
                                    <Row className="align-items-center">
                                      <Col md="4"><Label className="pb-1">Invoice # </Label></Col>
                                      <Col md="8">
                                        <FormGroup className="d-flex align-items-center">
                                          <Input type="text" value={state?.invoice_number} readOnly />
                                        </FormGroup>
                                      </Col>

                                    </Row>




                                  </Col>
                                  <Col md="4">

                                    <Row className="align-items-center">
                                      <Col md="4"><Label className="pb-1">Invoice For </Label></Col>
                                      <Col md="8">
                                        <FormGroup className="align-items-center">
                                          <Field name="order_no">
                                            {({ input, meta }) => (
                                              <div>
                                                <Input
                                                  {...input}
                                                  type="text"
                                                  invalid={meta.error && meta.touched}
                                                />
                                                {meta.error && meta.touched && (
                                                  <span className="text-danger">{meta.error}</span>
                                                )}
                                              </div>
                                            )}
                                          </Field>
                                        </FormGroup>
                                      </Col>

                                    </Row>


                                  </Col>
                                  <Col md="6" >

                                    <Row className="align-items-center">
                                      <Col md="4"><Label className="pb-1">{t("Invoice Date")}</Label></Col>
                                      <Col md="8">
                                        <FormGroup>
                                          <Field name="invoice_date">
                                            {({ input, meta }) => (
                                              <div>
                                                <Input
                                                  {...input}
                                                  type="date"
                                                  invalid={meta.error && meta.touched}
                                                  value={invoiceDate}
                                                  onChange={(e) => {
                                                    setInvoiceDate(e.target.value);
                                                    input.onChange(e);
                                                  }}
                                                />
                                                {meta.error && meta.touched && (
                                                  <span className="text-danger">{meta.error}</span>
                                                )}
                                              </div>
                                            )}
                                          </Field>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </Col>

                                  <Col md="4" >
                                    <Row className="align-items-center">
                                      <Col md="4"><Label className="pb-1">Due Date</Label></Col>
                                      <Col md="8">
                                        <FormGroup>
                                          <Field name="due_date">
                                            {({ input, meta }) => (
                                              <div>
                                                <Input
                                                  {...input}
                                                  onChange={(e) => {
                                                    setDueDate(e.target.value);
                                                    input.onChange(e);
                                                  }}
                                                  type="date"
                                                  invalid={meta.error && meta.touched}
                                                  min={getCurrentDate()}
                                                  value={DueDate}
                                                />
                                                {meta.error && meta.touched && (
                                                  <span className="text-danger">{meta.error}</span>
                                                )}
                                              </div>
                                            )}
                                          </Field>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </Col>


                                  <Col md="12" >
                                    <Row className="align-items-center">
                                      <Col md="6" >
                                        {fieldlist && fieldlist.map((field, index) => {
                                          if (field.show_in === 'Invoice List' && field.status === 'Active') {
                                            if (field.field_type === 'text' || field.field_type === 'email' || field.field_type === 'number' || field.field_type === 'password') {
                                              return (
                                                <>

                                                  <Row md={12} >
                                                    <Col md="4" >
                                                      <label htmlFor="name">{field.field_lable}</label>
                                                    </Col>
                                                    <Col md="8" >
                                                      <FormGroup className="align-items-left">
                                                        <Field
                                                          name={field.referal_name}
                                                          initialValue={state.invoice_list_data[field.referal_name]}
                                                        >
                                                          {({ input, meta }) => (
                                                            <div>
                                                              <Input
                                                                {...input}
                                                                type={field.field_type}
                                                                value={input.value}
                                                                placeholder={field.field_lable}
                                                                invalid={meta.error && meta.touched}
                                                              />
                                                              {meta.error && meta.touched && (
                                                                <span className="text-danger">{meta.error}</span>
                                                              )}
                                                            </div>
                                                          )}
                                                        </Field>
                                                      </FormGroup>
                                                    </Col  >
                                                  </Row >
                                                </>
                                              )
                                            }
                                            else if (field.field_type === 'dropdown') {

                                              return (
                                                <Row md={12} >
                                                  <Col md="4" >
                                                    <label htmlFor="name">{field.field_lable}</label>
                                                  </Col>
                                                  <Col md="8" >
                                                    <FormGroup>
                                                      <Field
                                                        name={field.referal_name}
                                                        initialValue={state.invoice_list_data[field.referal_name]}
                                                      >
                                                        {({ input, meta }) => (
                                                          <div>
                                                            <Input
                                                              {...input}
                                                              type={'select'}
                                                              value={input.value}
                                                              placeholder={field.field_lable}
                                                              invalid={meta.error && meta.touched}
                                                            >
                                                              <option disabled={false} value="">{t('--Choose an option--')}</option>
                                                              {field.options.map((option, optionsindex) => (
                                                                <option key={field.referal_name + optionsindex} value={option.value}>{option.label}</option>
                                                              ))}
                                                            </Input>
                                                            {meta.error && meta.touched && (
                                                              <span className="text-danger">{meta.error}</span>
                                                            )}
                                                          </div>
                                                        )}
                                                      </Field>

                                                    </FormGroup>
                                                  </Col  >
                                                </Row >
                                              )
                                            }
                                            else if (field.field_type === 'radio' || field.field_type === 'checkbox') {
                                              return (
                                                <Row md={12}>
                                                  <Col md="4">
                                                    <label htmlFor="name">{field.field_lable}</label>
                                                  </Col>
                                                  <Col md="8">
                                                    <FormGroup>
                                                      <Field
                                                        name={field.referal_name}
                                                        initialValue={state.invoice_list_data[field.referal_name]}
                                                      >
                                                        {({ inputs, meta }) => (
                                                          <div>
                                                            <ul className="child mb-0 p-0">
                                                              {field.options.map((option, optionsindex) => (
                                                                <li className="form-check form-check-inline">
                                                                  <input className="form-check-input"
                                                                    {...inputs}
                                                                    type={field.field_type}
                                                                    value={option.value} name={field.referal_name} id={field.referal_name + optionsindex}
                                                                  />
                                                                  <label className="form-check-label" htmlFor="users">
                                                                    {t(option.label)}
                                                                  </label>
                                                                </li>
                                                              ))}
                                                            </ul>
                                                            {meta.error && meta.touched && (
                                                              <span className="text-danger">{meta.error}</span>
                                                            )}
                                                          </div>
                                                        )}
                                                      </Field>

                                                    </FormGroup>
                                                  </Col>
                                                </Row>

                                              );
                                            }

                                            else {
                                              return (
                                                <Row md={12} >
                                                  <Col md="4" >
                                                    <label htmlFor="name">{field.field_lable}</label>
                                                  </Col>
                                                  <Col md="8" >
                                                    <Input type="text" className="form-control" placeholder={field.field_lable} name={field.referal_name} id={field.referal_name} />
                                                  </Col  >
                                                </Row >

                                              )
                                            }
                                          }
                                        })}
                                      </Col>
                                    </Row>
                                  </Col>





                                  <Divider sx={{ marginBottom: "20px", marginTop: "10px" }} />

                                  <div>
                                    <table className="custom-table">
                                      <thead>
                                        <tr>
                                          <th>{t("Item")}</th>
                                          <th>{t("Item Details")}</th>
                                          {fieldlist && fieldlist.map((field, index) => {
                                            if (field.show_in === 'Items List' && field.status === 'Active') {
                                              if (field.item_in_invoice === 'Both' || field.item_in_invoice === 'Invoice Items') {
                                                return (
                                                  <>
                                                    <th>{t(field.field_lable)}</th>
                                                  </>
                                                )
                                              }
                                            }
                                          })}
                                          <th>{t("Quantity")}</th>
                                          <th>{t("Price (Per Unit)")}</th>
                                          <th>{`Tax`}</th>
                                          <th>{`Amount (${localStorage.getItem('currency')})`}</th>
                                          <th>{t("Actions")}</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {data.map((item, index) => (
                                          <tr key={item.id}>
                                            {/* <td>{item.id}</td> */}
                                            <td >
                                              {bulkEditMode || editableRowIndex === index ? (
                                                <FormGroup>
                                                  <Field name="item_name">
                                                    {({ input, meta }) => (
                                                      <div>
                                                        <>
                                                          <Input
                                                            type="text"
                                                            {...input}
                                                            value={item.item_name}
                                                            placeholder="Item Name"
                                                            autoComplete="off"
                                                            onChange={(e) => {
                                                              handleInputChange(e.target.value, 'item_name', index);
                                                              //  selectChanged("services", index, e);
                                                              Services.forEach((service) => {
                                                                if (service.label === e.target.value) {
                                                                  item.description = service.description;
                                                                  item.rate = service.price;
                                                                  handleInputChange(item.rate, 'rate', index)
                                                                }
                                                              })
                                                            }

                                                            }
                                                            list="servicesList"
                                                          />

                                                          <datalist id="servicesList">
                                                            {Services.map((option) => (
                                                              <option key={option.label} value={option.label}>
                                                                {option.label}
                                                              </option>
                                                            ))}
                                                          </datalist>
                                                        </>
                                                        {meta.error && meta.touched && (
                                                          <span className="text-danger">{meta.error}</span>
                                                        )}
                                                      </div>
                                                    )}
                                                  </Field>
                                                </FormGroup>

                                                // <Field name="item_name" component={renderSelectItem} selectOptions={Services.value} />

                                              ) : (
                                                item.item_name
                                              )}
                                            </td>
                                            <td >
                                              {bulkEditMode || editableRowIndex === index ? (
                                                <div>
                                                  <FormGroup >
                                                    <Field name="item_name">
                                                      {({ input, meta }) => (
                                                        <div>
                                                          <>
                                                            <Input
                                                              type="text"
                                                              {...input}
                                                              value={item.description}
                                                              autoComplete="off"
                                                              placeholder="Item Details"
                                                              onChange={(e) => handleInputChange(e.target.value, 'description', index)}
                                                              list="descriptionList"
                                                            />
                                                          </>
                                                          {meta.error && meta.touched && (
                                                            <span className="text-danger">{meta.error}</span>
                                                          )}
                                                        </div>
                                                      )}
                                                    </Field>
                                                  </FormGroup>
                                                </div>
                                              ) : (
                                                <div style={{ width: "100%" }}>
                                                  {`${item.description}`}
                                                </div>

                                              )}
                                            </td>
                                            {fieldlist && fieldlist.map((field, ind) => {
                                              if (field.show_in === 'Items List' && field.status === 'Active') {
                                                if (field.item_in_invoice === 'Both' || field.item_in_invoice === 'Invoice Items') {
                                                  if (field.field_type === 'text' || field.field_type === 'email' || field.field_type === 'number' || field.field_type === 'password') {
                                                    return (
                                                      <td >
                                                        {bulkEditMode || editableRowIndex === index ? (
                                                          <div>
                                                            <FormGroup >
                                                              <Field name="item_name">
                                                                {({ input, meta }) => (
                                                                  <div>
                                                                    <>
                                                                      <Input
                                                                        type={field.field_type}
                                                                        {...input}
                                                                        value={item[field.referal_name]}
                                                                        placeholder={field.field_lable}
                                                                        autoComplete="off"
                                                                        onChange={(e) => handleInputChange(e.target.value, field.referal_name, index)}
                                                                      />
                                                                    </>
                                                                    {meta.error && meta.touched && (
                                                                      <span className="text-danger">{meta.error}</span>
                                                                    )}
                                                                  </div>
                                                                )}
                                                              </Field>
                                                            </FormGroup>
                                                          </div>

                                                        ) : (
                                                          <div style={{ width: "100%" }}>
                                                            {` ${item[field.referal_name]}`}
                                                          </div>

                                                        )}
                                                      </td>

                                                    )
                                                  }
                                                  else if (field.field_type === 'dropdown') {

                                                    return (
                                                      <td >
                                                        {bulkEditMode || editableRowIndex === index ? (
                                                          <div>
                                                            <FormGroup >
                                                              <select className="form-select" name={field.referal_name} id={field.referal_name}    >
                                                                <option disabled={false} value="">{t('--Choose an option--')}</option>
                                                                {field.options.map((option, optionsindex) => (
                                                                  <option key={field.referal_name + optionsindex} value={option.value}>{option.label}</option>
                                                                ))}
                                                              </select>
                                                            </FormGroup>
                                                          </div>
                                                        ) : (
                                                          <div style={{ width: "100%" }}>
                                                            {` ${item[field.referal_name]}`}
                                                          </div>
                                                        )}

                                                      </td>


                                                    )
                                                  }
                                                  else if (field.field_type === 'radio' || field.field_type === 'checkbox') {
                                                    return (
                                                      <td >
                                                        {bulkEditMode || editableRowIndex === index ? (

                                                          <div>
                                                            <FormGroup >
                                                              <ul className="child mb-0 p-0">
                                                                {field.options.map((option, optionsindex) => (
                                                                  <li className="form-check form-check-inline">
                                                                    <input className="form-check-input"
                                                                      type={field.field_type}
                                                                      value={option.value} name={field.referal_name} id={field.referal_name + optionsindex}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="users">
                                                                      {option.label}
                                                                    </label>
                                                                  </li>
                                                                ))}
                                                              </ul>
                                                            </FormGroup>
                                                          </div>
                                                        ) : (
                                                          <div style={{ width: "100%" }}>
                                                            {` ${item[field.referal_name]}`}
                                                          </div>
                                                        )}

                                                      </td>

                                                    )
                                                  }
                                                  else {
                                                    return (
                                                      <td >
                                                        {bulkEditMode || editableRowIndex === index ? (

                                                          <div>
                                                            <FormGroup >
                                                              <Input type={field.field_type} className="form-control" placeholder={field.field_lable} name={field.referal_name} id={field.referal_name} />
                                                            </FormGroup>
                                                          </div>
                                                        ) : (
                                                          <div style={{ width: "100%" }}>
                                                            {` ${item[field.referal_name]}`}
                                                          </div>
                                                        )}

                                                      </td>
                                                    )
                                                  }
                                                }
                                              }
                                            })}
                                            <td>
                                              {bulkEditMode || editableRowIndex === index ? (
                                                <div>
                                                  <FormGroup style={{ width: "100%" }}>
                                                    <Field name="item_name">
                                                      {({ input, meta }) => (
                                                        <div>
                                                          <>
                                                            <Input
                                                              type="number"
                                                              value={item.quantity}
                                                              onChange={(e) => handleInputChange(e.target.value, 'quantity', index)}
                                                            />
                                                          </>
                                                          {meta.error && meta.touched && (
                                                            <span className="text-danger">{meta.error}</span>
                                                          )}
                                                        </div>
                                                      )}
                                                    </Field>
                                                  </FormGroup>
                                                </div>
                                              ) : (
                                                item.quantity
                                              )}
                                            </td>
                                            <td>
                                              {bulkEditMode || editableRowIndex === index ? (
                                                <div>
                                                  <FormGroup style={{ width: "100%" }}>
                                                    <Field name="item_name">
                                                      {({ input, meta }) => (
                                                        <div>
                                                          <>
                                                            <Input
                                                              type="number"
                                                              value={item.rate}
                                                              onChange={(e) => handleInputChange(e.target.value, 'rate', index)}
                                                            />
                                                          </>
                                                          {meta.error && meta.touched && (
                                                            <span className="text-danger">{meta.error}</span>
                                                          )}
                                                        </div>
                                                      )}
                                                    </Field>
                                                  </FormGroup>
                                                </div>
                                              ) : (
                                                item.rate
                                              )}
                                            </td>
                                            <td>
                                              {bulkEditMode || editableRowIndex === index ? (
                                                <div style={{ display: 'flex' }}>
                                                  <div style={{ width: "80px", textAlign: 'left', display: 'inline-block' }}>
                                                    <FormGroup>
                                                      <Field name="item_name">
                                                        {({ input, meta }) => (
                                                          <div>
                                                            <Input
                                                              {...input}
                                                              type="text"
                                                              onChange={(e) => handleTaxChange(e.target.value, index)}
                                                              style={{
                                                                borderRadius: '10px 0 0 10px',
                                                                borderRight: 'none',
                                                              }}
                                                              value={item.tax}
                                                              invalid={meta.error && meta.touched}
                                                            />
                                                            {meta.error && meta.touched && (
                                                              <span className="text-danger">{meta.error}</span>
                                                            )}
                                                          </div>
                                                        )}
                                                      </Field>
                                                    </FormGroup>
                                                  </div>
                                                  <div style={{ width: "120px", textAlign: 'left', display: 'inline-block' }}>
                                                    <FormGroup>
                                                      <Field name="tax_class">
                                                        {({ input, meta }) => (
                                                          <div>
                                                            <Input
                                                              {...input}
                                                              type="select"
                                                              style={{
                                                                borderRadius: '0 10px 10px 0',
                                                                backgroundColor: '#F4F6F5'
                                                              }}
                                                              // value={input.value}
                                                              value={item.tax_class}
                                                              onChange={(e) => {
                                                                handleInputChange(e.target.value, 'tax_class', index);
                                                                // setTaxClass(e.target.value);
                                                                // input.onChange(e);
                                                              }}
                                                              invalid={meta.error && meta.touched}
                                                            >
                                                              {TaxClasses.map((tax) => (
                                                                <option key={tax.percentage} value={tax.percentage}>
                                                                  {`${tax.percentage}% ${tax.name}`}
                                                                </option>
                                                              ))}
                                                            </Input>
                                                            {meta.error && meta.touched && (
                                                              <span className="text-danger">{meta.error}</span>
                                                            )}
                                                          </div>
                                                        )}
                                                      </Field>
                                                    </FormGroup>
                                                  </div>
                                                </div>
                                              ) : (
                                                parseFloat(item.tax).toFixed(2)

                                              )}
                                            </td>
                                            {/* <td>
                                              {bulkEditMode || editableRowIndex === index ? (
                                                <div>
                                                  <FormGroup style={{ width: "100%" }}>
                                                    <Field name="item_name">
                                                      {({ input, meta }) => (
                                                        <div>
                                                          <>
                                                            <Input
                                                              type="number"
                                                              value={parseFloat(item.tax).toFixed(2)}
                                                              onChange={(e) => handleTaxChange(e.target.value, index)}
                                                            />
                                                          </>
                                                          {meta.error && meta.touched && (
                                                            <span className="text-danger">{meta.error}</span>
                                                          )}
                                                        </div>
                                                      )}
                                                    </Field>
                                                  </FormGroup>
                                                </div>
                                              ) : (
                                                parseFloat(item.tax).toFixed(2)
                                              )}
                                            </td> */}
                                            <td>
                                              {bulkEditMode || editableRowIndex === index ? (
                                                <div>
                                                  <FormGroup style={{ width: "100%" }}>
                                                    <Field name="item_name">
                                                      {({ input, meta }) => (
                                                        <div>
                                                          <>
                                                            <Input
                                                              disabled
                                                              type="number"
                                                              value={parseFloat(item.amount).toFixed(2)}
                                                              onChange={(e) => handleInputChange(e.target.value, 'amount', index)}
                                                            />
                                                          </>
                                                          {meta.error && meta.touched && (
                                                            <span className="text-danger">{meta.error}</span>
                                                          )}
                                                        </div>
                                                      )}
                                                    </Field>
                                                  </FormGroup>
                                                </div>
                                              ) : (
                                                parseFloat(item.amount).toFixed(2)
                                              )}
                                            </td>
                                            <td>
                                              {bulkEditMode || editableRowIndex === index ? (
                                                <div>
                                                  <FormGroup style={{ width: "100%" }}>
                                                    <div>
                                                      <>
                                                        <Tooltip placement="top" title={t("Save")} arrow>
                                                          <i onClick={() => handleSaveClick(index)} className="pe-7s-edit btn-icon-wrapper text-info"> </i>
                                                        </Tooltip>
                                                      </>
                                                    </div>
                                                  </FormGroup>
                                                </div>
                                              ) : (
                                                <>
                                                  <Tooltip placement="top" title={t("Edit")} arrow>
                                                    <i onClick={() => handleEditClick(index)} className="pe-7s-pen btn-icon-wrapper text-info"> </i>
                                                  </Tooltip>
                                                  <Tooltip placement="top" title={t("Delete")} arrow>
                                                    <i onClick={() => handleDeleteClick(index)} className="pe-7s-trash text-danger btn-icon-wrapper"> </i>
                                                  </Tooltip>
                                                </>)}
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>

                                  <Col md="12" >
                                    <Row className="align-items-center">
                                      <Col md="6">
                                        <button type="button" onClick={handleAddRow} className="btn btn-secondary btn-lg btn-hover-shine me-3">
                                          Add New Item
                                        </button>
                                        <button type="button" onClick={() => handleEditClick()} className="btn btn-secondary btn-lg btn-hover-shine me-3">
                                          {bulkEditMode ? 'Save Bulk Update' : 'Bulk Update'}
                                        </button>

                                      </Col>
                                    </Row>
                                  </Col>
                                  <Divider sx={{ marginBottom: "20px", marginTop: "10px" }} />

                                </Row>

                                <div style={{ textAlign: 'right', marginBottom: '20px' }}>
                                  <table style={{
                                    width: '100%'
                                  }}>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <div style={{
                                            fontWeight: '600px', backgroundColor: 'var(--topbar-color, #1e6cd2)',
                                            width: "550px", textAlign: 'left', padding: '5px', display: 'inline-block',
                                            color: '#fff'
                                          }}>
                                            Invoice Amount Summary
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div style={{ width: "430px", textAlign: 'left', padding: '5px', display: 'inline-block' }}>
                                            Subtotal:
                                          </div>
                                          <div style={{ width: "110px", textAlign: 'left', padding: '5px', display: 'inline-block' }}>
                                            {`${parseFloat(subtotal).toFixed(2)}`}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div style={{ width: "250px", textAlign: 'left', padding: '5px', display: 'inline-block' }}>
                                            Discount:
                                          </div>
                                          <div style={{ width: "100px", textAlign: 'left', display: 'inline-block' }}>
                                            <FormGroup>
                                              <Field name="discount">
                                                {({ input, meta }) => (
                                                  <div>
                                                    <Input
                                                      {...input}
                                                      type="text"
                                                      style={{
                                                        borderRadius: '10px 0 0 10px',
                                                        borderRight: 'none',
                                                      }}
                                                      value={discount}
                                                      onChange={(e) => {
                                                        setDiscount(e.target.value);
                                                        input.onChange(e);
                                                      }}
                                                      invalid={meta.error && meta.touched}
                                                    />
                                                    {meta.error && meta.touched && (
                                                      <span className="text-danger">{meta.error}</span>
                                                    )}
                                                  </div>
                                                )}
                                              </Field>
                                            </FormGroup>
                                          </div>
                                          <div style={{ width: "80px", textAlign: 'left', display: 'inline-block' }}>
                                            <FormGroup initialValues={state.discountType}>
                                              <Field name="discountType">
                                                {({ input, meta }) => (
                                                  <div>
                                                    <Input
                                                      {...input}
                                                      type="select"
                                                      style={{
                                                        borderRadius: '0 10px 10px 0',
                                                        backgroundColor: '#F4F6F5'
                                                      }}
                                                      value={input.value}
                                                      onChange={(e) => {
                                                        input.onChange(e);
                                                        setDiscountType(e.target.value);
                                                      }}
                                                      invalid={meta.error && meta.touched}
                                                    >
                                                      {/* Place the options inside the select input */}
                                                      <option key='%' value='%'>{'%'}</option>
                                                      <option key={localStorage.getItem('currency')} value={localStorage.getItem('currency')}>
                                                        {localStorage.getItem('currency')}
                                                      </option>
                                                    </Input>
                                                    {meta.error && meta.touched && (
                                                      <span className="text-danger">{meta.error}</span>
                                                    )}
                                                  </div>
                                                )}
                                              </Field>
                                            </FormGroup>

                                          </div>

                                          <div style={{ width: "110px", textAlign: 'left', padding: '5px', display: 'inline-block' }}>
                                            {parseFloat(discount_Amount).toFixed(2)}({localStorage.getItem('currency')})
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div style={{ width: "430px", textAlign: 'left', padding: '5px', display: 'inline-block' }}>
                                            Tax:
                                          </div>
                                          <div style={{ width: "110px", textAlign: 'left', padding: '5px', display: 'inline-block' }}>
                                            {`${parseFloat(tax).toFixed(2)}`}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr></tr>
                                      <tr>
                                        <td>
                                          <div style={{ width: "550px", textAlign: 'left', padding: '5px', display: 'inline-block' }}>
                                            <Divider />
                                          </div>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td>
                                          <div style={{ width: "430px", textAlign: 'left', padding: '5px', display: 'inline-block' }}>
                                            Total ({`${localStorage.getItem("currency")}`})
                                          </div>
                                          <div style={{ width: "110px", textAlign: 'left', padding: '5px', display: 'inline-block' }}>
                                            {parseFloat(total_amount + tax).toFixed(2)}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div style={{ width: "550px", textAlign: 'left', padding: '5px', display: 'inline-block' }}>
                                            <Divider />
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>



                                <Col md="12" >
                                  <Row className="align-items-center">
                                    <Col md="10">
                                      <Label style={{ marginTop: '20px' }} className="pb-1">Customers Notes</Label>
                                      <FormGroup  >
                                        <textarea
                                          name="customer_notes"
                                          style={{ width: '90%', height: '90px' }}
                                          placeholder="Customer Notes"
                                          value={customerNotes} // Bind the value to the state variable
                                          onChange={handleCustomerNotesChange} // Update the state when the user types
                                        />
                                        {/* You can display an error message if needed
                                          {meta.error && meta.touched && (
                                            <span className="text-danger">{meta.error}</span>
                                          )} */}
                                      </FormGroup>
                                      <Label className="pb-1">Will be Displayed on Invoice</Label>
                                    </Col>
                                  </Row>
                                </Col>

                                <Row style={{ marginTop: "20px" }}>
                                  <FormGroup>
                                    <div className="text-center">
                                      <Button type="submit" className="btn btn-secondary btn-lg btn-hover-shine me-3">
                                        Update
                                      </Button>
                                      <button type="button" onClick={() => {
                                        if (check) {
                                          localStorage.setItem('tab5', "5");
                                        }
                                        confirmBack();
                                      }} className="btn btn-outline-success btn-lg btn-hover-shine me-3">
                                        Cancel
                                      </button>
                                    </div>
                                  </FormGroup>
                                </Row>
                              </form>
                            )}
                          />
                        </LoadingOverlay>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </div>

          </div>
        </div>
      </div>
    </Fragment >
  );
};
export default UpdateInvoice;