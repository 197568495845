import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Button, Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, CardBody } from 'reactstrap';
import { FaWallet, FaGlobe, FaFile, FaShoppingCart } from 'react-icons/fa';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import DashboardService from "../dashboard.service";
import AuthService from "../../Authentication/auth.service";
import Alerts from "../../../components/Alerts/alerts";
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import PermissionAccess from '../../../PermissionAccess/Access.js';
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import "../../../assets/custom css/loadoverlay.scss";
import QuotationsRequested from './QuotationsRequested';
import DashboardHeader from './DashboardHeader';
import { FaEye, FaEdit, FaFilter, FaPlus, FaCreditCard } from 'react-icons/fa';



const customStyles = {
  headRow: {
    backgroundColor: '#FFFFFF',
    color: '#A7A7A7 !important',
    border: 'none',
  },
  headCells: {
    style: {
      fontSize: '16px',
      fontWeight: 'bold', // Customize font weight
      border: 'none', // Remove bottom border of each header cell
    },
  },
  rows: {
    style: {
      border: 'none',
    },
  },
  cells: {
    style: {
      padding: '8px',
      border: 'none',

    },
  },
};
const cardStyle = {
  borderRadius: '10px',
  boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
};

const headerStyle = {
  fontSize: '0.9rem',
  color: '#6c757d',
  marginBottom: '0.25rem',
};

const valueStyle = {
  fontSize: '1.2rem',
  fontWeight: 'bold',
  marginBottom: '0',
};

const listItemStyle = {
  fontSize: '0.9rem',
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '0.5rem',
};


const Home = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [daysDropdownValue, setDaysDropdownValue] = useState('Last 7 Days');
  const [data, setData] = useState([]);
  const authData = JSON.parse(localStorage.getItem("user"));
  const [ActiveOrders, setActiveOrders] = useState(0);
  const [ApprovedOrders, setApprovedOrders] = useState(0);
  const [TotalOrders, setTotalOrders] = useState(0);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState("");

  // Perform actions on component mount or when 'pageLimit' changes
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchData(0, pageLimit);
  }, [pageLimit, daysDropdownValue]);


  // Function to fetch table data based on page index and limit
  function fetchData(page_index, page_limit) {
    const stored_user = authData?.userData
    var offset = (page_index - 1) * page_limit;
    if (offset < 1) {
      offset = 0;
    }

    // Set loading state to true before fetching data
    setLoading(true);
    var payload = {
      "functionName": "getUserInvoices",
      "postData": {
        "offset": offset,
        "sort": {
          "_id": -1
        },
        "user_id": authData._id,
        "limit": page_limit,
      }
    }

    // Fetch data from DashboardService
    DashboardService.getUserInvoices(payload).then((response) => {
      setLoading(false); // Set loading state to false after data retrieval
      // Handle response data  
      if (response.data.message === "Unauthorized") {
        // Perform logout and redirection for unauthorized access
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        console.log("response response:", response)
        setData(response.data.data);
        if (response.data.data) {
          setActiveOrders(response.data.ActiveOrders.count);
          setApprovedOrders(response.data.ApprovedOrders.count);
          setTotalOrders(response.data.TotalOrders.count);
          setTotalRows(response.data.counts);
        }
      }
    },
      // Handle error scenarios
      (error) => {
        setLoading(false); // Set loading state to false in case of error
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  const trendData = [
    { icon: <FaWallet />, title: 'Active', value: ActiveOrders, color: '#3D607A' },
    { icon: <FaGlobe />, title: 'Approved', value: ApprovedOrders, color: '#3D607A' },
    { icon: <FaFile />, title: 'Total Invoices', value: TotalOrders, color: '#3D607A' },
  ];


  const handleRowClick = (row) => {
    navigate(`/e-invoice-details/${row?._id}`, { state: row });
  };


  //for search with enter keypress
  function checkenterkey(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      setResetPaginationToggle(!resetPaginationToggle);
      var page = 0
      handlePageChange(page++)
    }
  };
  function handlePageChange(page_index) {
    console.log("Page index:-", page_index)
    setCurrentPage(page_index)
    fetchData(page_index, pageLimit);
  }

  async function handlePerRowsChange(newPageLimit, page_index) {
    setPageLimit(newPageLimit);
  }

  const columns = [
    { name: 'Invoice Number', selector: row => row?.invoice_number, sortable: true },
    // { name: 'Order ID', selector: row => row?.order_details?.order_number, sortable: true },
    { name: 'Date', selector: row => moment(row?.datetime_added).format('DD-MM-YYYY'), sortable: true },
    { name: 'Total Items', selector: row => row?.item?.length || 0, sortable: true },
    { name: 'Amount', selector: row => row?.total_amount, sortable: true },
    {
      name: 'Invoice Status',
      selector: row => row?.status,
      sortable: true,
      cell: row => {
        const capitalizeFirstLetter = (status) => status?.charAt(0)?.toUpperCase() + status?.slice(1);
        return (
          <span style={{ color: row?.status === 'generated' ? '#DAA520' : row?.status === 'paid' ? '#DC143C' : '#228B22' }}>
            {capitalizeFirstLetter(row?.status)}
          </span>
        );
      }
    },

    {
      name: 'Action',
      cell: row => (
        <div>
          <Button onClick={() => { navigate(`/view_invoice/${row._id}`) }} color="link" className="p-0 me-2">
            <FaEye size={18} color="#3D607A" />
          </Button>
          {row?.status !== 'paid' && (
            <Button onClick={() => { HandlePayment(row) }} color="link" className="p-0">
              <FaCreditCard size={18} color="#3D607A" />
            </Button>
          )}
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const HandlePayment = (row) => {
    console.log("row", row);
    var getData = {
      functionName: "OnlinePayment",
      row: row,
      invoice_id: row?._id ? row?._id : null,
      amount: parseFloat(row.total_amount).toFixed(2),
      invoice_number: row.invoice_number,
      business_id: row?.business_id
    }
    console.log("getData", getData);
    setLoading(true);
    DashboardService.OnlinePayment(getData).then((response) => {
      console.log(response);
      setLoading(false);
      if (response.status === 200) {
        console.log(response);
        window.open(response.data.data, '_blank');
        console.log('Response : ', response);
      } else if (response.status === 401) {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      } else {
        window.open(response.data.data, '_blank');
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent);
        if (errContent) {
          Alerts.swalErrorAlert(errContent);
        } else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."));
        }
      })
  }

  return (
    <div className="app-main__outer">
      <div className="container mt-4">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <DashboardHeader data='Invoices' />
          <div className="d-flex">
            <Input type="text" placeholder="Search for something" className="mr-2" />
          </div>
        </div>

        <Row className="g-4 mb-4">
          {trendData.map((trend, index) => (
            <Col md={4} key={index}>
              <Card body className="trend-card d-flex flex-row align-items-center justify-content-between">
                <div>
                  <div className="trend-title">{trend.title}</div>
                  <div className="trend-value">{trend.value}</div>
                </div>
                <div className="icon-circle" style={{ backgroundColor: trend.color }}>
                  {trend.icon}
                </div>
              </Card>
            </Col>
          ))}
        </Row>

        <Row className="g-4">
          <Card className="mt-4 mb-4">
            <div className="p-4">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h4 className="mb-0">Invoices</h4>
                <div>
                </div>
              </div>
              <DataTable
                data={data}
                columns={columns}
                pagination
                fixedHeader
                fixedHeaderScrollHeight="400px"
                paginationServer
                paginationTotalRows={totalRows}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                paginationResetDefaultPage={resetPaginationToggle}
                customStyles={customStyles}
              />

            </div>
          </Card >
        </Row>
        <style jsx>{`
        .trend-card {
          border-radius: 10px;
          box-shadow: 0 2px 10px rgba(0,0,0,0.05);
          background-color: #ffffff;
        }
        .icon-circle {
          width: 40px;
          height: 40px;
          border-radius: 25%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
        }
        .trend-title {
          font-size: 14px;
          color: #777;
          margin-bottom: 2px;
        }
        .trend-value {
          font-size: 18px;
          font-weight: 600;
          color: #333;
        }
        .chart-card, .order-card {
          height: 400px;
          overflow: hidden;
        }
        .order-list {
          max-height: 250px;
          overflow-y: auto;
        }
      `}</style>
      </div>
      {/* </Card> */}
      {/* </LoadingOverlay> */}
      {/* </div> */}
    </div>
  );
};

export default Home;