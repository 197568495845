import React, { useState, useEffect, Fragment } from "react";
import cx from "classnames";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import AuthService from "../../pages/Authentication/auth.service";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from "react-perfect-scrollbar";
import 'react-perfect-scrollbar/dist/css/styles.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faAngleDown, faUser, faUserEdit, faCrown } from "@fortawesome/free-solid-svg-icons";
import { IoIosNotifications } from "react-icons/io";
import Flag from "react-flagkit";
import city3 from "../../assets/utils/images/dropdown-header/city3.jpg";
import city2 from "../../assets/utils/images/dropdown-header/city2.jpg";
import Verify from "../../assets/verify.svg";
import UnverifiedIcon from '../../assets/UnverifiedIcon';
import Dollar from "../../assets/dollar.svg";
import bronze from "../../assets/bronze.jpeg";
import silver from "../../assets/silver.jpeg";
import gold from "../../assets/gold.jpeg";
import Diamond from "../../assets/diamond.jpeg";
import Default from "../../assets/silver.svg";



import Clock from "./Clock";

import userLogo from "../../assets/memberIcon.svg";
import { useSelector } from 'react-redux';
import {
  DropdownToggle,
  DropdownMenu,
  Button,
  UncontrolledButtonDropdown,
  UncontrolledDropdown,
  DropdownItem,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";
import './Topbar.css'
import { Typography } from "@mui/material";
import axios from "axios";

const B_URL = process.env.REACT_APP_LOGINURL
const API_URL = process.env.REACT_APP_BASEURL;


const headers = {
  'Content-Type': 'application/json',
  'token': localStorage.getItem("session-id")
}


library.add(faUser, faUserEdit);

const Topbar = () => {
  const { t, i18n } = useTranslation();
  const authData = useSelector((state) => state.authReducer.authData);
  const navigate = useNavigate();
  const location = useLocation();
  var pathname = location.pathname;
  console.log("pathname", pathname)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const currentUser = authData?.userData;
  const [logo, setLogo] = useState(localStorage.getItem("system_logo"));
  const [langFlag, setlangFlag] = useState('');
  const [active1, setActive1] = useState(false);
  const [Currencies, setCurrencies] = useState([]);
  const [active2, setActive2] = useState(false);
  const userString = localStorage.getItem('user');
  const user = JSON.parse(userString);
  // console.log("userString", user);
  let user_type;
  if (user !== null) {
    user_type = user['user_type'];
  }
  console.log("user_type", user_type)
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    checkUserData();
  }, []);

  useEffect(async () => {
    const userString = localStorage.getItem('user');
    const user = JSON.parse(userString);
    console.log("Check user Status", user)
    if (!user?.is_verified || user?.is_verified === undefined || user?.is_verified === 'partial' || user.status !== 'Active') {
      var payload = {
        "functionName": "GetSingleUser",
        "postData": {
          "user_id": user?._id,
        },
      }
      let UserData = await axios.post(B_URL + "UserModule/GetSingleUser", payload, { headers });
      let NewDataUser = UserData?.data?.data?.[0]
      console.log("Check NewDataUser", NewDataUser)
      if (NewDataUser && NewDataUser?.status === 'Active') {
        const { frontId, backId, selfie, ...filteredUser } = NewDataUser;
        localStorage.setItem("user", JSON.stringify(filteredUser));
      }
    }
  }, []);

  useEffect(async () => {
    var payload = {
      "functionName": "getAllCurrency",
    }
    let currencies = await axios.post(B_URL + "CurrencyRoutes/getAllCurrency", payload, { headers });
    let NewCurrencies = currencies?.data?.data?.data;
    console.log("Check NewCurrencies", NewCurrencies)
    setCurrencies(NewCurrencies)

  }, []);
  const [selectedCurrency, setSelectedCurrency] = useState({
    name: "Philippine Peso",
    code: "PHP",
    symbol: "₱",
    timezone: "Asia/Manila",
    rate: 1.0,
  });

  // Load default currency or existing one from localStorage
  useEffect(() => {
    const savedCurrency = JSON.parse(localStorage.getItem("selectedCurrency"));
    if (savedCurrency) {
      setSelectedCurrency(savedCurrency);
    } else {
      // Default to Philippine Peso
      const defaultCurrency = {
        name: "Philippine Peso",
        code: "PHP",
        symbol: "₱",
        timezone: "Asia/Manila",
        rate: 1.0,
      };
      localStorage.setItem("selectedCurrency", JSON.stringify(defaultCurrency));
      setSelectedCurrency(defaultCurrency);
    }
  }, []);

  // Handle currency change
  const handleCurrencyChange = (e) => {
    const selectedCode = e.target.value;
    const newCurrency = Currencies.find((curr) => curr.code === selectedCode);

    if (newCurrency) {
      // Update localStorage with the full currency object
      localStorage.setItem("selectedCurrency", JSON.stringify(newCurrency));
      setSelectedCurrency(newCurrency);
      console.log("Updated Currency:", newCurrency);
      window.location.reload();
    }

  };



  function checkUserData() {
    // var modules_arr = [];
    if (localStorage.getItem("user")) {
      // setUserData(JSON.parse(localStorage.getItem("user")));
      var userData = JSON.parse(localStorage.getItem("user"));
    } else {
      navigate(user_type === 'users' ? "/user-login/Members" : "/login");
      AuthService.logout();
    }
  };

  useEffect(() => {
    setLanguageFlag(localStorage.getItem("language"));
  }, []);

  function setLanguageFlag(lang) {
    if (lang === 'en') {
      setlangFlag('GB');
      setActive1(true);
      setActive2(false);
    } else {
      setlangFlag('MY');
      setActive1(false);
      setActive2(true);
    }
  }

  function changeLanguage(lang) {
    localStorage.setItem('language', lang);
    setLanguageFlag(lang);
    i18n.changeLanguage(lang);
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  function logOut() {
    navigate(user_type === 'users' ? "/user-login/Members" : "/login");
    AuthService.logout();
    // window.location.reload();
  }

  return (
    <Fragment>
      <TransitionGroup>
        <CSSTransition
          component="div"
          className={cx("app-header header-shadow")}
          appear={true} timeout={1500} enter={false} exit={false}>
          {user_type === "Members" ?
            <div className="topbar-container" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px 20px', background: '#fff', borderBottom: '1px solid #ddd' }}>
              {/* Left Section */}
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className="app-header__logo" style={{ marginRight: '20px' }}>
                  <img onClick={() => { navigate('/customer_analytic_dashboard') }} alt="Nano" src={logo} style={{ width: 'auto', height: '80%', cursor: 'pointer' }} className="app-logo mx-auto" />
                </div>
                {!isMobile && (
                  <Typography sx={{ fontSize: '22px', fontWeight: '600' }}>
                    {pathname === '/members' ? "Members" : pathname === '/StockistRequests' ? "Stockist Requests" : pathname === '/CustomerOrders' ? "Orders" : pathname === '/MemberHome' ? 'Buy Package' : pathname === '/Member-profile' ? 'Profile' : pathname === '/CustomerTopup' ? 'Wallet' : pathname === '/TopUpRequests' ? 'Top Up Requests' : pathname === '/Store' ? 'New Order' : pathname === '/customer_analytic_dashboard' ? 'Achievement Dashboard' : 'Purchase Package'}</Typography>
                )}
              </div>

              {/* Right Section */}
              <div className="app-header-right" style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                <div style={{ marginRight: '20px', display: 'flex', alignItems: 'center' }}>
                  <Clock timezone={selectedCurrency.timezone} />
                </div>
                {/* New Order Button */}
                {/* <button onClick={() => { navigate('/Store') }} style={{
                  backgroundColor: '#D4AF37', color: '#fff',
                  padding: '8px 15px', borderRadius: '5px', fontWeight: '600', border: 'none', cursor: 'pointer'
                }}>
                  New Order
                </button> */}

                {/* Upgrade Button */}
                {/* <button style={{ backgroundColor: '#FFCC80', color: '#fff', padding: '8px 15px', borderRadius: '5px', fontWeight: '600', border: 'none', cursor: 'pointer' }}>
                  <FontAwesomeIcon icon={faCrown} style={{ marginRight: '5px', color: '#FF6F00' }} />
                  Upgrade */}
                {/* </button> */}

                {/* Currency Dropdown */}
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <img
                    src={`https://flagcdn.com/w320/${selectedCurrency.code.slice(0, 2).toLowerCase()}.png`}
                    alt="Country Flag"
                    style={{ width: '40px', height: '40px', borderRadius: '50%', objectFit: 'cover' }}
                  />
                  <div>
                    <span style={{ fontWeight: '500' }}>Currency</span>
                    <br />
                    <select
                      onChange={handleCurrencyChange}
                      value={selectedCurrency.code} // Reflect current selected currency
                      style={{
                        color: '#C1B314',
                        fontSize: '20px',
                        border: 'none',
                        background: 'transparent',
                        fontWeight: '600',
                        cursor: 'pointer',
                      }}
                    >
                      {Currencies.map((currency) => (
                        <option key={currency.code} value={currency.code}>
                          {`${currency.code}`}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>


                {/* User Info */}
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <img src={userLogo} alt="User" style={{ width: '40px', height: '40px', borderRadius: '50%' }} />
                  <div>
                    <span style={{ fontWeight: '600' }}>{user?.fullName}</span>
                    <br />
                    <span style={{ fontSize: '14px', color: '#555' }}>Member</span>
                    <span style={{ marginLeft: '5px', color: user.status === 'Inactive' ? 'white' : '#208207', fontWeight: '500', backgroundColor: user.status === 'Inactive' ? 'red' : '#16FF0A59', padding: '2px 5px', borderRadius: '3px' }}>{user.status}</span>
                    {user.is_verified === 'verified' ?
                      <img src={Verify} alt="User" style={{ width: '20px', height: '20px', marginLeft: '5px', marginBottom: '2px' }} />
                      : <UnverifiedIcon />
                    }
                  </div>
                </div>

                {/* Member Type */}
                <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                  <img alt="Diamond" src={user?.package?.[0]?.name === 'Bronze' ? bronze : user?.package?.[0]?.name === 'Silver' ? silver : user?.package?.[0]?.name === 'Gold' ? gold : user?.package?.[0]?.name === 'Diamond' ? Diamond : Default} style={{ width: '50px', height: 'auto' }} />
                  <span style={{ fontWeight: '600', color: '#D4AF37' }}>{user?.package?.[0]?.name || 'No Package'}</span>
                </div>
              </div>
            </div>
            // <div className="topbar-container">
            //   <div className="app-header__logo">
            //     <img alt="Nano" src={logo} style={{ width: 'auto', height: '130%' }} className="app-logo mx-auto" />
            //   </div>
            //   <Typography sx={{ marginLeft: '10%', fontSize: '25px', fontWeight: '700px' }}>Dashboard</Typography>
            //   <div className="app-header__content">
            //     <div className="app-header-right">

            //       <div className="header-dots">
            //         {/* <UncontrolledDropdown>
            //         <DropdownToggle className="p-0 me-2" color="link">
            //           <div className="icon-wrapper icon-wrapper-alt rounded-circle">
            //             <Flag className="me-3 opacity-8" country={langFlag} size="40" />
            //           </div>
            //         </DropdownToggle>
            //         <DropdownMenu end className="rm-pointers">
            //           <div className="dropdown-menu-header">
            //             <div className="dropdown-menu-header-inner pt-4 pb-4 bg-focus">
            //               <div className="menu-header-content text-center text-white">
            //                 <h6 className="menu-header-subtitle mt-0">
            //                   {t('Choose Language')}
            //                 </h6>
            //               </div>
            //             </div>
            //           </div>
            //           <DropdownItem active={active1} onClick={() => changeLanguage('en')}>
            //             <Flag className="me-3 opacity-8" country="GB" />
            //             English (UK)
            //           </DropdownItem>
            //           <DropdownItem active={active2} onClick={() => changeLanguage('bm')}>
            //             <Flag className="me-3 opacity-8" country="MY" />
            //             Malaysia
            //           </DropdownItem>
            //         </DropdownMenu>
            //       </UncontrolledDropdown> */}
            //       </div>
            //       <div className="header-btn-lg pe-0">
            //         <UncontrolledButtonDropdown>
            //           <DropdownToggle color="link" className="p-0">
            //             <img width={28} src={userLogo} alt="User" />
            //             <FontAwesomeIcon className="ms-2 opacity-8" icon={faAngleDown} />
            //           </DropdownToggle>
            //           <DropdownMenu end className="rm-pointers dropdown-menu-lg">
            //             <div className="dropdown-menu-header">
            //               <div className="dropdown-menu-header-inner bg-info">
            //                 <div className="menu-header-content text-start">
            //                   <div className="widget-content p-0">
            //                     <div className="widget-content-wrapper">
            //                       <div className="widget-content-left me-3">
            //                         <img width={62} className="rounded-circle" src={userLogo} alt="User Avatar" />
            //                       </div>
            //                       <div className="widget-content-left">
            //                         {currentUser && <div className="widget-heading">{currentUser.name}</div>}
            //                         <div className="widget-subheading opacity-8">{currentUser?.role_details?.name}</div>
            //                       </div>
            //                       <div className="widget-content-right me-2">
            //                         <Button className="btn-pill btn-shadow btn-custom-color btn-shine" onClick={logOut}>
            //                           {t('Logout')}
            //                         </Button>
            //                       </div>
            //                     </div>
            //                   </div>
            //                 </div>
            //               </div>
            //             </div>
            //             <PerfectScrollbar>
            //               <div className="scroll-area-xs" style={{ height: "80px" }}>
            //                 <Nav vertical>
            //                   <NavItem>
            //                     <NavLink href={user_type === 'users' ? "/Member-profile" : "/my-profile"}>
            //                       <FontAwesomeIcon icon={faUserEdit} className="px-2 py-0" style={{ marginBottom: "3px" }} />
            //                       {t('My Profile')}
            //                     </NavLink>
            //                   </NavItem>
            //                 </Nav>
            //               </div>
            //             </PerfectScrollbar>
            //           </DropdownMenu>
            //         </UncontrolledButtonDropdown>
            //       </div>
            //       <div className="app-header__logo">
            //         <img alt="Diamond" src={Diamond} style={{ width: '80%', height: 'auto' }} />
            //         <Typography sx={{ marginLeft: '1%', fontSize: '17px', fontWeight: '500px' }}>Diamond</Typography>
            //       </div>
            //     </div>
            //   </div>
            // </div>
            :
            <div className="topbar-container">
              <div className="app-header__logo">
                <img alt="Nano" src={logo} onClick={() => { navigate('/home') }} style={{ cursor: 'pointer', width: 'auto', height: '95%' }} className="app-logo mx-auto" />
              </div>
              <div className="app-header__content">
                <div className="app-header-right">
                  <div className="header-dots">
                    {/* <UncontrolledDropdown>
                    <DropdownToggle className="p-0 me-2" color="link">
                      <div className="icon-wrapper icon-wrapper-alt rounded-circle">
                        <Flag className="me-3 opacity-8" country={langFlag} size="40" />
                      </div>
                    </DropdownToggle>
                    <DropdownMenu end className="rm-pointers">
                      <div className="dropdown-menu-header">
                        <div className="dropdown-menu-header-inner pt-4 pb-4 bg-focus">
                          <div className="menu-header-content text-center text-white">
                            <h6 className="menu-header-subtitle mt-0">
                              {t('Choose Language')}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <DropdownItem active={active1} onClick={() => changeLanguage('en')}>
                        <Flag className="me-3 opacity-8" country="GB" />
                        English (UK)
                      </DropdownItem>
                      <DropdownItem active={active2} onClick={() => changeLanguage('bm')}>
                        <Flag className="me-3 opacity-8" country="MY" />
                        Malaysia
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown> */}
                  </div>
                  <div className="header-btn-lg pe-0">
                    <UncontrolledButtonDropdown>
                      <DropdownToggle color="link" className="p-0">
                        <img width={28} src={userLogo} alt="User" />
                        <FontAwesomeIcon className="ms-2 opacity-8" icon={faAngleDown} />
                      </DropdownToggle>
                      <DropdownMenu end className="rm-pointers dropdown-menu-lg">
                        <div className="dropdown-menu-header">
                          <div className="dropdown-menu-header-inner bg-info">
                            <div className="menu-header-content text-start">
                              <div className="widget-content p-0">
                                <div className="widget-content-wrapper">
                                  <div className="widget-content-left me-3">
                                    <img width={62} className="rounded-circle" src={userLogo} alt="User Avatar" />
                                  </div>
                                  <div className="widget-content-left">
                                    {currentUser && <div className="widget-heading">{currentUser.name}</div>}
                                    <div className="widget-subheading opacity-8">{currentUser?.role_details?.name}</div>
                                  </div>
                                  <div className="widget-content-right me-2">
                                    <Button className="btn-pill btn-shadow btn-custom-color btn-shine" onClick={logOut}>
                                      {t('Logout')}
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <PerfectScrollbar>
                          <div className="scroll-area-xs" style={{ height: "80px" }}>
                            <Nav vertical>
                              <NavItem>
                                <NavLink href={user_type === 'users' ? "/Member-profile" : "/my-profile"}>
                                  <FontAwesomeIcon icon={faUserEdit} className="px-2 py-0" style={{ marginBottom: "3px" }} />
                                  {t('Admin Profile')}
                                </NavLink>
                              </NavItem>
                            </Nav>
                          </div>
                        </PerfectScrollbar>
                      </DropdownMenu>
                    </UncontrolledButtonDropdown>
                  </div>
                </div>
              </div>
            </div>

          }
        </CSSTransition>
      </TransitionGroup>
    </Fragment>
  );
};

export default Topbar;
