import axios from "axios";

const API_URL = process.env.REACT_APP_BASEURL;
const storeduser = JSON.parse(localStorage.getItem("user"));
const headers = {
  'Content-Type': 'application/json',
  'token': localStorage.getItem("session-id")
}


const getAll = (filter, sessionID) => {
  var payload = {
    functionName: "GetCollectionData",
    "postData": {
      "collection_name": "custom_fields_invoice",
      "filter": filter
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }

  return axios.post(API_URL, payload, { headers });
};

const deleteAttribute = (id) => {
  var payload = {
    functionName: "DeleteCollectionData",
    postData: {
      "_id": id,
      "collection_name": "custom_fields_invoice",
      "login_user_id": storeduser._id
    },
  }
  return axios.post(API_URL, payload, { headers });
};


const addAttribute = payload => {
  console.log("Received addAttribute Payload:-", payload)
  return axios.post(API_URL, payload, { headers });
}


const updateAttribute = payload => {
  console.log("Received updateAttribute Payload:-", payload)
  return axios.post(API_URL, payload, { headers });
}

const AttributesService = {
  getAll,
  deleteAttribute,
  addAttribute,
  updateAttribute
}

export default AttributesService;