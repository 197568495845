import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import DiscountCouponService from "./Discountcoupons.service";
import AuthService from "../Authentication/auth.service";
import Alerts from "../../components/Alerts/alerts";
import Select from 'react-select';
import { Col, CardBody, Card } from "reactstrap";
import PermissionAccess from '../../PermissionAccess/Access.js';

const AddDiscountCouponModal = ({ showModalAddDiscountCoupon, handleToggleModalAddDiscountCoupon, onSuccess }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("");
  const [businesses, setBusinesses] = useState([]);
  const { authData, accessPages, super_admin, current_module } = PermissionAccess();

  const stored_user = authData.userData;
    let business = []
    if (stored_user.businesses) {
      business = stored_user.businesses
    }
  
  const fetchBusinesses = () => {
    setLoading(true);
    DiscountCouponService.getAllBusinesses(authData.sessionID,0, 9999999, business).then(
      (response) => {
        setLoading(false);
        console.log("Get businesses response:-", response);
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        } else {
          console.log(response.data);
          setBusinesses(response.data.data);
        }
      },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        if (errContent) {
          Alerts.swalErrorAlert(errContent);
        } else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."));
        }
      }
    );
  };

  const businessOptions = businesses.map(business => ({
    value: business._id,
    label: business.name
  }));

  useEffect(() => {
    fetchBusinesses();
  }, []);

  const [data, setData] = useState({
    coupon_code: '',
    total_coupons: '',
    type: '',
    amount: '',
    start_date: '',
    end_date: '',
    status: '',
    business_ids: [],
  });

  useEffect(() => {
    if (businesses.length === 1) {
      setData({
        business_ids: businesses[0]._id,
      }
      )}
  }, [businesses]);

  const [errors, setErrors] = useState({
    coupon_code: '',
    total_coupons: '',
    type: '',
    amount: '',
    start_date: '',
    end_date: '',
    status: '',
    business_ids: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target || e;
    let error = '';
  
    if (name === 'start_date') {
      const newStartDate = value;
      setData(prevData => ({
        ...prevData,
        start_date: newStartDate,
        end_date: prevData.end_date && prevData.end_date < newStartDate ? newStartDate : prevData.end_date
      }));
      error = !value ? 'Start date is required' : '';
    } else if (name === 'end_date') {
      const newEndDate = value;
      setData(prevData => ({
        ...prevData,
        end_date: newEndDate < prevData.start_date ? prevData.start_date : newEndDate
      }));
      error = !value ? 'End date is required' : '';
    } else {
      // Handle other fields as before
      if (name === 'coupon_code' && !value) {
        error = 'Coupon code is required';
      } else if (name === 'total_coupons' && !value) {
        error = 'Total coupons is required';
      } else if (name === 'type') {
        if (!value) error = 'Type is required';
        setType(value);
      } else if (name === 'amount' && !value) {
        error = 'Amount is required';
      } else if (name === 'status' && !value) {
        error = 'Status is required';
      } else if (name === 'business_ids') {
        // Handle multi-selection for business_ids
        const selectedValues = value.map(option => option.value);
        setData(prevData => ({ ...prevData, business_ids: selectedValues }));
        setErrors(prevErrors => ({ ...prevErrors, business_ids: selectedValues.length > 0 ? '' : 'Business is required' }));
        return;
      }
      setData(prevData => ({ ...prevData, [name]: value }));
    }
  
    setErrors(prevErrors => ({ ...prevErrors, [name]: error }));
  };
  
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    let validationErrors = {};

    if (!data.coupon_code) { validationErrors.coupon_code = 'Coupon code is required'; }
    if (!data.total_coupons) { validationErrors.total_coupons = 'Total coupons is required'; }
    if (!data.type) { validationErrors.type = 'Value is required'; }
    if (!data.amount) { validationErrors.amount = 'Amount is required'; }
    if (!data.start_date) { validationErrors.start_date = 'Start date is required'; }
    if (!data.end_date) { validationErrors.end_date = 'End date is required'; }
    if (!data.status) { validationErrors.status = 'Status is required'; }
    if (!data.business_ids || data.business_ids.length === 0) { validationErrors.business_ids = 'Business is required'; }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setLoading(true);
      DiscountCouponService.AddDiscountCoupon(authData.sessionID,data).then((response) => {
        setLoading(false);
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        } else {
          if (response.data.status === true) {
            resetForm();
            handleToggleModalAddDiscountCoupon();
            onSuccess();
            validationErrors.coupon_code = '';
            Alerts.swalSuccessAlert(t("Discount coupon added successfully."));
          } else {
            validationErrors.coupon_code = response.data.message || 'An error occurred';
            setErrors(validationErrors);
          }
        }
      }, (error) => {
        setLoading(false);
        const errContent = (error.response && error.response.data) || error.message || error.toString();
        if (errContent) {
          Alerts.swalErrorAlert(errContent);
        } else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."));
        }
      });
    }
  };

  const resetForm = () => {
    setData({
      coupon_code: '',
      total_coupons: '',
      type: '',
      amount: '',
      start_date: '',
      end_date: '',
      status: '',
      business_ids: [], // Reset to empty array
    });
    setErrors({
      coupon_code: '',
    });
  };

  const handleClose = () => {
    setErrors({
      coupon_code: '',
      total_coupons: '',
      type: '',
      amount: '',
      start_date: '',
      end_date: '',
      status: '',
      business_ids: [],
    });
    handleToggleModalAddDiscountCoupon();
  };

  return (
    <div className={showModalAddDiscountCoupon ? "modal d-block mt-4" : "modal"} style={{ display: showModalAddDiscountCoupon ? 'block' : 'none', backgroundColor: 'rgba(0, 0, 0, 0.5)' }} tabIndex="-1" role="dialog">
      <form onSubmit={handleSubmit}>
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">{t("Add Discount Coupons")}</h5>
            </div>
            <div className="modal-body">
            <Col md="12">
                <Card className="main-card mb-3">
                     <CardBody>
              <LoadingOverlay
                tag="div"
                active={loading}
                styles={{ overlay: (base) => ({ ...base }) }}
                spinner={<DNALoader />}
              >
                <input type="hidden" className="form-control" name="business_id" />

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="coupon-code" className="col-form-label">{t("Coupon Code")}:</label>
                      <input type="text" className="form-control" placeholder="Enter Coupon Code" 
                      name="coupon_code"
                      onChange={handleChange}
                      value={data.coupon_code}
                      />
                      {errors.coupon_code && <strong className="error text-danger">{errors.coupon_code}</strong>}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="total-coupons" className="col-form-label">{t("Total Coupons")}:</label>
                      <input type="number" className="form-control" placeholder="Enter Total Coupons"
                      name="total_coupons"
                      onChange={handleChange}
                      value={data.total_coupons}
                      />
                      {errors.total_coupons && <strong className="error text-danger">{errors.total_coupons}</strong>}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="type-select" className="col-form-label">{t("Type")}:</label>
                      <select className="form-control"
                      name="type"
                      onChange={handleChange}
                      value={data.type}
                      >
                        <option value="">{t("Select Type")}</option>
                        <option value="Percentage">{t("Percentage")}</option>
                        <option value="Amount">{t("Amount")}</option>
                      </select>
                      {errors.type && <strong className="error text-danger">{errors.type}</strong>}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="amount" className="col-form-label">
                        {type === "Percentage" ? t("Percentage") : t("Amount")}:
                      </label>
                      <input type="number" className="form-control" placeholder={type === "Percentage" ? "Enter Percentage" : "Enter Amount"} 
                      name="amount"
                      onChange={handleChange}
                      value={data.amount}
                      />
                      {errors.amount && <strong className="error text-danger">{errors.amount}</strong>}
                    </div>
                  </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="start-date" className="col-form-label">{t("Start Date")}:</label>
                        <input 
                          type="date" 
                          className="form-control"
                          name="start_date"
                          onChange={handleChange}
                          value={data.start_date}
                          min={new Date().toISOString().split('T')[0]} // Set minimum date to today
                        />
                        {errors.start_date && <strong className="error text-danger">{errors.start_date}</strong>}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="end-date" className="col-form-label">{t("End Date")}:</label>
                        <input 
                          type="date" 
                          className="form-control" 
                          id="end-date" 
                          name="end_date"
                          onChange={handleChange}
                          value={data.end_date}
                          min={data.start_date || new Date().toISOString().split('T')[0]} // Set minimum date to start date or today, whichever is later
                        />
                        {errors.end_date && <strong className="error text-danger">{errors.end_date}</strong>}
                      </div>
                    </div>
                  </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="status-select" className="col-form-label">{t("Status")}:</label>
                      <select className="form-control" 
                      name="status"
                      onChange={handleChange}
                      value={data.status}
                      >
                        <option value="">{t("Select Status")}</option>
                        <option value="Active">{t("Active")}</option>
                        <option value="InActive">{t("InActive")}</option>
                      </select>
                      {errors.status && <strong className="error text-danger">{errors.status}</strong>}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="business-select" className="col-form-label">{t("Business")}:</label>
                      <Select
                        isMulti
                        name="business_ids"
                        options={businessOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        value={businessOptions.filter(option => data.business_ids.includes(option.value))}
                        onChange={(selectedOptions) => {
                          const selectedValues = selectedOptions.map(option => option.value);
                          setData(prevData => ({ ...prevData, business_ids: selectedValues }));
                          setErrors({ ...errors, business_ids: selectedValues.length > 0 ? '' : 'Business is required' }); // Update error status
                        }}
                      />
                      {errors.business_ids && <strong className="error text-danger">{errors.business_ids}</strong>}

                    </div>
                  </div>
                  <div className="text-center mt-4">
                  <button type="button" className="btn btn-outline-light btn-hover-shine me-3" onClick={handleClose}>{t("Close")}</button>
                  <button type="submit" className="btn btn-custom-color">{t("Save")}</button>
                </div>
                </div>
                                          </LoadingOverlay>
                                        </CardBody>
                                    </Card>
                                </Col>
            </div>
            
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddDiscountCouponModal;

