import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, Navigate } from "react-router-dom";
import { Row, Col, FormGroup, Input, Button, Label, Modal, ModalHeader, ModalBody, Card, CardBody } from "reactstrap";
import Services from "./services";
import AuthService from "../Authentication/auth.service";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import Alerts from "../../components/Alerts/alerts";
import PermissionAccess from '../../PermissionAccess/Access.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faTimes, faFilePdf } from '@fortawesome/free-solid-svg-icons';

const Preview = ({ file, onRemove }) => {
  if (!file) return null;

  const style = {
    position: 'absolute',
    top: '-160px',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 1000,
    // backgroundColor: 'white',
    padding: '10px',
    borderRadius: '5px',
    boxShadow: '0 0 10px rgba(0,0,0,0.5)',
    maxWidth: '300px',
    maxHeight: '300px'
  };

  const removeButtonStyle = {
    position: 'absolute',
    top: '5px',
    right: '5px',
    zIndex: 1001,
    backgroundColor: 'red',
    color: 'white',
    border: 'none',
    borderRadius: '50%',
    width: '25px',
    height: '25px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  };

  return (
    <div style={style}>
      {file.type === 'application/pdf' && (
        <>
          <button onClick={onRemove} style={removeButtonStyle}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <iframe src={URL.createObjectURL(file)} title="PDF Preview" width="280" height="280" />
        </>
      )
      }
    </div>
    // <div style={style}>
    //   <button onClick={onRemove} style={removeButtonStyle}>
    //     <FontAwesomeIcon icon={faTimes} />
    //   </button>
    //   {file.type.startsWith('image/') ? (
    //     <></>
    //     // <img src={URL.createObjectURL(file)} alt="Preview" style={{ maxWidth: '100%', maxHeight: '100%' }} />
    //   ) : file.type === 'application/pdf' ? (
    //     <iframe src={URL.createObjectURL(file)} title="PDF Preview" width="280" height="280" />
    //   ) : (
    //     <div>Unsupported file type</div>
    //   )}
    // </div>
  );
};

const AddMember = ({ onSuccess }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [Customers, setCustomers] = useState([{ name: '', RegistrationNumber: '', email: '', Phone: '', Address: '', Documents: [] }]);
  const [loading, setLoading] = useState(false);
  const [loginNavigate, setLoginNavigate] = useState(false);
  const [Fetched_Businesses, setFetched_Businesses] = useState([]);
  const [previewModal, setPreviewModal] = useState(false);
  const [previewDocument, setPreviewDocument] = useState(null);
  const { authData, accessPages, super_admin, current_module } = PermissionAccess();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const autocompleteRef = useRef(null);
  const inputRef = useRef(null);
  const modalRef = useRef(null);



  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    Fetch_Businesses();
  }, []);


  useEffect(() => {
    if (modalOpen) {
      loadGoogleMapsScript();
    }
  }, [modalOpen]);


  const loadGoogleMapsScript = () => {
    if (!window.google || !window.google.maps) {
      console.log("Loading Google Maps script...");
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDlKtLygdyr4XpjVE1WcyR7yHuigyq_agw&libraries=places`;
      script.async = true;
      script.onload = () => {
        console.log("Google Maps script loaded. Initializing Autocomplete...");
        initAutocomplete();
      };
      document.head.appendChild(script);
    } else {
      console.log("Google Maps already loaded. Initializing Autocomplete...");
      initAutocomplete();
    }
  };

  const initAutocomplete = () => {
    if (!inputRef.current) {
      console.log("Input ref not available. Autocomplete initialization delayed.");
      setTimeout(initAutocomplete, 100);
      return;
    }

    console.log("Initializing Autocomplete...");
    autocompleteRef.current = new window.google.maps.places.Autocomplete(inputRef.current, {
      types: ['address'],
    });

    autocompleteRef.current.addListener('place_changed', handlePlaceSelect);

    // Adjust the position of the autocomplete dropdown
    const pacContainer = document.querySelector('.pac-container');
    if (pacContainer) {
      pacContainer.style.zIndex = "1500"; // Ensure it's above the modal
      pacContainer.style.width = `${inputRef.current.offsetWidth}px`;
      pacContainer.style.left = `${inputRef.current.getBoundingClientRect().left}px`;
      pacContainer.style.top = `${inputRef.current.getBoundingClientRect().bottom}px`;
    }

    console.log("Autocomplete initialized.");

    // Prevent form submission on enter
    inputRef.current.addEventListener('keydown', (e) => {
      if (e.key === 'Enter') e.preventDefault();
    });
  };


  const handlePlaceSelect = () => {
    console.log("Place selected. Updating address...");
    const place = autocompleteRef.current.getPlace();
    if (!place.geometry) {
      console.error('No details available for input:', place.name);
      return;
    }

    const address = place.formatted_address;
    setCustomers(prevCustomers => prevCustomers.map((Member, i) =>
      i === 0 ? { ...Member, Address: address } : Member
    ));
    console.log("Address updated:", address);
  };


  function Fetch_Businesses() {
    setLoading(true);
    Services.GetBusinesses(authData.sessionID).then(
      (response) => {
        setLoading(false);
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        } else {
          const business = response.data.data[0];
          setFetched_Businesses([business]);
        }
      },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        Alerts.swalErrorAlert(errContent || t("Server Error, Please try again later."));
      }
    );
  }



  const [previewFile, setPreviewFile] = useState(null);

  const handleFileChange = (index, event) => {
    const files = Array.from(event.target.files);
    setCustomers(prevCustomers => prevCustomers.map((Member, i) =>
      i === index ? { ...Member, Documents: [...Member.Documents, ...files] } : Member
    ));
  };

  const removeDocument = (MemberIndex, docIndex) => {
    setCustomers(prevCustomers => prevCustomers.map((Member, i) =>
      i === MemberIndex ?
        { ...Member, Documents: Member.Documents.filter((_, index) => index !== docIndex) }
        : Member
    ));
    setPreviewFile(null);
  };


  const PreviewDocument = (file) => {
    setPreviewDocument(file);
    setPreviewModal(true);
  };

  const toggle = () => {
    setModalOpen(!modalOpen);
    if (!modalOpen) {
      setCustomers([{ name: '', RegistrationNumber: '', email: '', Phone: '', Address: '', Documents: [] }]);
    }
  };


  const handleMemberChange = (index, field, value) => {
    setCustomers(prevCustomers => prevCustomers.map((Member, i) =>
      i === index ? { ...Member, [field]: value } : Member
    ));
  };

  const validateForm = () => {
    return Customers.every(Member =>
      Member.name &&
      Member.RegistrationNumber &&
      Member.email &&
      Member.Phone &&
      Member.Address &&
      Member.Documents.length > 0
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      Alerts.swalErrorAlert("Please fill all fields and upload at least one document for Customers.");
      return;
    }

    const formData = new FormData();

    // Append Member data to formData
    Object.keys(Customers[0]).forEach(key => {
      if (key === 'Documents') {
        // Append each document
        Customers[0].Documents.forEach((doc, index) => {
          formData.append(`Documents`, doc);
        });
      } else {
        // Append other fields
        formData.append(key, Customers[0][key]);
      }
    });

    formData.append('business_id', Fetched_Businesses[0]._id);
    formData.append('status', "Active");
    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }

    setLoading(true);
    try {
      const response = await Services.AddMember(authData.sessionID, formData);
      setLoading(false);
      if (response.data.status) {
        onSuccess();
        Alerts.swalSuccessAlert(response.data.message || "Member Added Successfully.").then(() => {
          toggle();
        });
      } else {
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          setLoginNavigate(true);
        } else {
          Alerts.swalErrorAlert(response.data.message || "Error: Can't Add Member at the moment. Please try again later.");
        }
      }
    } catch (error) {
      setLoading(false);
      Alerts.swalErrorAlert("Network Operation failed.");
    }
  };

  if (loginNavigate) {
    return <Navigate to={'/login'} />;
  }

  return (
    <span className="d-inline-block float-end mb-2 me-2">
      <Button color="success" onClick={toggle} className="btn-hover-shine ms-1">
        {t("Add New")}
      </Button>
      <Modal size='lg' isOpen={modalOpen} toggle={toggle} backdrop={true} ref={modalRef}>
        <LoadingOverlay active={loading} spinner={<DNALoader />}>
          <ModalHeader>{t("Add Member")}</ModalHeader>
          <ModalBody>
            <Card className="main-card mb-3">
              <CardBody>
                <form onSubmit={handleSubmit}>
                  {Customers.map((Member, index) => (
                    <FormGroup key={index} className="mb-3">
                      <Row>
                        <Col md="6">
                          <Label>{t("Member Name")}</Label>
                          <Input
                            type="text"
                            value={Member.name}
                            onChange={(e) => handleMemberChange(index, 'name', e.target.value)}
                            placeholder={t("Enter Member Name")}
                          />
                        </Col>
                        <Col md="6">
                          <Label>{t("Registration Number")}</Label>
                          <Input
                            type="text"
                            value={Member.RegistrationNumber}
                            onChange={(e) => handleMemberChange(index, 'RegistrationNumber', e.target.value)}
                            placeholder={t("Enter Registration Number")}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <Label>{t("Email")}</Label>
                          <Input
                            type="email"
                            value={Member.email}
                            onChange={(e) => handleMemberChange(index, 'email', e.target.value)}
                            placeholder={t("Enter Email")}
                          />
                        </Col>
                        <Col md="6">
                          <Label>{t("Phone")}</Label>
                          <Input
                            type="tel"
                            value={Member.Phone}
                            onChange={(e) => handleMemberChange(index, 'Phone', e.target.value)}
                            placeholder={t("Enter Phone Number")}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <Label>{t("Address (Google Location)")}</Label>
                          <div style={{ position: 'relative' }}>
                            <Input
                              type="text"
                              value={Member.Address}
                              onChange={(e) => handleMemberChange(index, 'Address', e.target.value)}
                              placeholder={t("Enter Address")}
                              innerRef={inputRef}
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row className="mt-3">
                        <Col md="12">
                          <Label>{t("Documents")}</Label>
                          <div className="document-upload-container" style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                            {Member.Documents.map((doc, docIndex) => (
                              <div
                                key={docIndex}
                                style={{ position: 'relative', width: '100px', height: '100px' }}
                                onMouseEnter={() => setPreviewFile(doc)}
                                onMouseLeave={() => setPreviewFile(null)}
                              >
                                {doc.type.startsWith('image/') ? (
                                  <img src={URL.createObjectURL(doc)} alt={`Document ${docIndex + 1}`} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                ) : (
                                  <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#f0f0f0' }}>
                                    <FontAwesomeIcon icon={faFilePdf} size="2x" />
                                  </div>
                                )}
                                <Button
                                  color="danger"
                                  size="sm"
                                  style={{ position: 'absolute', top: '-10px', right: '-10px', borderRadius: '50%', padding: '0.2rem 0.5rem' }}
                                  onClick={() => removeDocument(index, docIndex)}
                                >
                                  <FontAwesomeIcon icon={faTimes} />
                                </Button>
                                {previewFile === doc &&
                                  <Preview
                                    file={doc}
                                    onRemove={() => removeDocument(index, docIndex)}
                                  />}
                              </div>
                            ))}
                            <div style={{ width: '100px', height: '100px', border: '2px dashed #ccc', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
                              <label htmlFor={`file-upload-${index}`} style={{ cursor: 'pointer', margin: 0 }}>
                                <FontAwesomeIcon icon={faUpload} size="2x" />
                                <Input
                                  type="file"
                                  id={`file-upload-${index}`}
                                  onChange={(e) => handleFileChange(index, e)}
                                  accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                                  multiple
                                  style={{ display: 'none' }}
                                />
                              </label>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </FormGroup>
                  ))}
                  <div className="text-center">
                    <Button type="submit" color="success" size="lg" className="me-3">
                      {t("Save")}
                    </Button>
                    <Button type="button" color="secondary" size="lg" onClick={toggle}>
                      {t("Cancel")}
                    </Button>
                  </div>
                </form>
              </CardBody>
            </Card>
          </ModalBody>
        </LoadingOverlay>
      </Modal>
      <style jsx>{`
        .pac-container {
          z-index: 1500 !important;
        }
      `}</style>
    </span>
  );
};

export default AddMember;

