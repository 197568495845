import React, { useState } from 'react';
import {
  Box, Avatar, Typography, TextField, Button, Container, IconButton,
  InputAdornment
} from '@mui/material';
import { ArrowBack, Close as CloseIcon, Check as CheckIcon } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import AuthService from "../Authentication/auth.service";
import AlertService from "../../components/Alerts/alerts";
import myflag from "../../assets/myflag.png";

const EditProfile = () => {
  const [userData, setUserData] = useState(JSON.parse(localStorage.getItem("user")));
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleUpdateProfile = async () => {
    setLoading(true);
    try {
      userData.user_id = userData._id
      const response = await AuthService.Update_User(userData);
      if (response.data.status) {
        AlertService.swalSuccessAlert(t("Profile updated successfully."));
        localStorage.setItem("user", JSON.stringify(userData));
        navigate('/User_Profile');
      } else {
        AlertService.swalErrorAlert(response.data.message || t("Error updating profile."));
      }
    } catch (error) {
      console.log(error);
      AlertService.swalErrorAlert(t("Error updating profile. Please try again."));
    } finally {
      setLoading(false);
    }
  };

  // const handleUpdateBasicInfo = async (event) => {
  //   event.preventDefault();
  //   setLoading(true);
  //   const formData = new FormData(event.currentTarget);
  //   const payloadData = {
  //     functionName: "UpdateCustomerProfile",
  //     postData: {
  //       name: formData.get('fullName'),
  //       phone: formData.get('mobileNumber'),
  //       _id: userData._id
  //     },
  //   };
  //   SendSubmit(payloadData);
  // };

  const inputStyle = {
    backgroundColor: '#F0F5FB',
    borderRadius: '8px',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiInputBase-input': {
      padding: '10px 10px', // Reduce height by adjusting padding
    },
  };

  const inputStyleEmail = {
    ...inputStyle,
    backgroundColor: '#EDEDED',
  };

  const buttonStyle = {
    borderRadius: '8px',
    padding: '8px 16px', 
    textTransform: 'none',
    fontWeight: 'bold',
    boxShadow: 'none',
    width: '30%', 
  };

  return (
    <div style={{ backgroundColor: "black", minHeight: "100vh", display: "flex", justifyContent: "center" }}>
      <div style={{ width: "100%", maxWidth: "1300px", backgroundColor: "white", position: "relative" }}>
        <IconButton sx={{mt: 2, position: 'absolute', left: 16, top: 16 }} onClick={() => navigate(-1)}>
          <ArrowBack />
        </IconButton>
        <Typography variant="h5" align="center" sx={{ mt: 2, mb: 2, fontWeight: 'bold' }}>
          Edit Profile
        </Typography>
        <Container maxWidth="xs" sx={{border: '1px solid #EFEFEF', pt: 4 }}> {/* Increased maxWidth to "sm" */}
          <Avatar
            sx={{
              bgcolor: '#f2721c',
              width: 60,
              height: 60,
              fontSize: 40,
              margin: '0 auto',
              marginBottom: 3,
              borderRadius: '10px',
            }}
          >
            {userData.name.charAt(0).toUpperCase()}
          </Avatar>

          <Box component="form" noValidate sx={{ mt: 1 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize:'13px' }}>Full Name</Typography>
            <TextField
              fullWidth
              id="fullName"
              name="fullName"
              value={userData.name}
              onChange={(e) => setUserData({ ...userData, name: e.target.value })}
              variant="outlined"
              sx={inputStyle}
              // margin="dense" // Changed from "normal" to "dense"
            />

            <Typography variant="subtitle1" sx={{ fontWeight: 'bold',fontSize:'13px', mt: 1 }}>Email</Typography>
            <TextField
              fullWidth
              id="email"
              name="email"
              value={userData.email}
              disabled
              variant="outlined"
              sx={inputStyleEmail}
              margin="dense" // Changed from "normal" to "dense"
            />

            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize:'13px', mt: 1 }}>Phone Number</Typography>
            <TextField
              fullWidth
              id="phoneNumber"
              name="phoneNumber"
              value={userData.phone}
              onChange={(e) => setUserData({ ...userData, phone: e.target.value })}
              variant="outlined"
              sx={inputStyle}
              margin="dense" // Changed from "normal" to "dense"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={myflag} alt="MY" style={{ width: 24, height: 16, marginRight: 8 }} />
                    +60
                  </InputAdornment>
                ),
              }}
            />

            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb:3 }}>
              <Button
                variant="contained"
                onClick={() => navigate(-1)}
                sx={{
                  ...buttonStyle,
                  marginRight:'10px',
                  backgroundColor: '#F0F0F0',
                  color: '#333',
                  '&:hover': {
                    backgroundColor: '#E0E0E0',
                  },
                }}
                startIcon={<CloseIcon />}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={handleUpdateProfile}
                disabled={loading}
                sx={{
                  ...buttonStyle,
                  backgroundColor: '#f2721c',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: '#d35400',
                  },
                }}
                startIcon={<CheckIcon />}
              >
                Update
              </Button>
            </Box>
          </Box>
        </Container>
      </div>
    </div>
  );
};

export default EditProfile;