import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";
import {
  Typography, Chip, Grid, Paper, Box, Card,
  Table, TableBody, TableCell, TableContainer, TableRow,
  Button
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import WorkIcon from '@mui/icons-material/Work';
import StarIcon from '@mui/icons-material/Star';
import CategoryIcon from '@mui/icons-material/Category';
import GroupIcon from '@mui/icons-material/Group';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Services from "./services.js";
import AuthService from "../Authentication/auth.service";
import Alerts from "../../components/Alerts/alerts";
import PermissionAccess from '../../PermissionAccess/Access.js';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ShareIcon from '@mui/icons-material/Share';
import CloseIcon from '@mui/icons-material/Close';
import { Tooltip, IconButton, TextField, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import ShareDialog from './ShareDialog';
import StatusUpdateSection from "./StatusUpdateSection"; // Import the component
import axios from "axios";
const B_URL = process.env.REACT_APP_LOGINURL
const headers = {
  'Content-Type': 'application/json',
  'token': localStorage.getItem("session-id")
}
// Styled Components
const MainContainer = styled(Box)({
  padding: '2rem',
  background: '#f8f9fa',
  minHeight: '100vh'
});

const StyledCard = styled(Card)({
  background: '#ffffff',
  borderRadius: '8px',
  boxShadow: '0 2px 12px rgba(0,0,0,0.08)',
  position: 'relative',
  overflow: 'visible'
});

const HeaderSection = styled(Box)({
  background: '#ffffff',
  borderBottom: '1px solid #edf2f7',
  padding: '1.5rem',
  marginBottom: '1.5rem',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
});

const BackButton = styled(Button)({
  marginBottom: '1rem',
  color: '#475569',
  textTransform: 'none',
  fontWeight: 500,
  '&:hover': {
    backgroundColor: '#f1f5f9'
  }
});

const DetailSection = styled(Box)({
  margin: '0.5rem',
  background: '#ffffff',
  borderRadius: '8px',
  border: '1px solid #edf2f7',
  overflow: 'hidden',
  transition: 'box-shadow 0.3s ease',
  '&:hover': {
    boxShadow: '0 4px 12px rgba(0,0,0,0.05)'
  }
});

const SectionTitle = styled(Typography)({
  color: '#2c3e50',
  fontWeight: 600,
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  padding: '1rem',
  borderBottom: '1px solid #edf2f7',
  background: '#ffffff',
  fontSize: '1.1rem',
  '& .MuiSvgIcon-root': {
    color: '#3B82F6'
  }
});

const InfoRow = styled(TableRow)({
  '&:nth-of-type(odd)': {
    backgroundColor: '#f8fafc',
  },
  '&:hover': {
    backgroundColor: '#f1f5f9',
  },
  transition: 'background-color 0.2s ease'
});

const LabelCell = styled(TableCell)({
  color: '#475569',
  fontWeight: 600,
  width: '25%',
  borderBottom: '1px solid #edf2f7',
  padding: '16px',
  fontSize: '0.95rem'
});

const ValueCell = styled(TableCell)({
  color: '#1e293b',
  borderBottom: '1px solid #edf2f7',
  padding: '16px',
  fontSize: '0.95rem'
});

const StyledChip = styled(Chip)(({ color }) => ({
  fontWeight: 500,
  height: '24px',
  fontSize: '0.85rem',
  ...(color === 'success' && {
    backgroundColor: '#dcfce7',
    color: '#166534',
    border: '1px solid #bbf7d0'
  }),
  ...(color === 'error' && {
    backgroundColor: '#fee2e2',
    color: '#991b1b',
    border: '1px solid #fecaca'
  }),
  ...(color === 'primary' && {
    backgroundColor: '#e0f2fe',
    color: '#075985',
    border: '1px solid #bae6fd'
  })
}));

const ActionButton = styled(Button)({
  textTransform: 'none',
  fontWeight: 600,
  padding: '6px 16px',
  borderRadius: '6px',
  fontSize: '0.9rem',
  boxShadow: 'none',
  '&:hover': {
    boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
  }
});

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2),
  },
}));

const ShareButton = styled(ActionButton)({
  backgroundColor: '#3B82F6',
  color: '#ffffff',
  '&:hover': {
    backgroundColor: '#2563EB',
  },
});


const MemberDetailsComponent = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { state: member } = location;
  const [loading, setLoading] = useState(false);
  const { authData } = PermissionAccess();
  const [openShareDialog, setOpenShareDialog] = useState(false);
  const [referralLink, setReferralLink] = useState('');
  const [is_verified, setIs_verified] = useState(member.is_verified);
  const [Status, setStatus] = useState(member?.status);

  const handleApproval = async (approve) => {
    setLoading(true);
    try {
      const response = await Services.updateMemberApproval(member?._id, approve, authData.sessionID);
      if (response.data.status) {
        member.status = approve ? "Active" : "Inactive";
        Alerts.swalSuccessAlert(approve ? "Member approved successfully" : "Member disapproved");
      } else {
        Alerts.swalErrorAlert(response.data.message || "Error updating member status");
      }
    } catch (error) {
      Alerts.swalErrorAlert("Network operation failed");
    } finally {
      setLoading(false);
    }
  };

  const handleApprove = () => {
    setLoading(true);
    // Approve activation API call
    Services.updateMemberVerification(member._id, 'verified').then(() => {
      setLoading(false);
      Alerts.swalSuccessAlert("Member verified successfully!");
      setIs_verified('verified');
    });
  };

  const handleReject = () => {
    Alerts.swalConfirmGeneralAlert("Are you sure you want to reject this activation?")
      .then((res) => {
        if (res) {
          setLoading(true);
          Services.updateMemberVerification(member._id, 'partial').then(() => {
            setLoading(false);
            Alerts.swalSuccessAlert("Verified rejected!");
            setIs_verified('partial');
          });
        }
      });
  };


  const handleStatusUpdate = async (memberId, selectedStatus) => {
    if (!selectedStatus) {
      Alerts.swalErrorAlert("Please select a valid status.");
      return;
    }

    setLoading(true);
    try {
      const payload = {
        functionName: "updateAccountStatus",
        postData: {
          MemberId: memberId,
          status: selectedStatus,
        },
      };
      const response = await axios.post(B_URL + "MemberModule/updateAccountStatus", payload, { headers });

      if (response.data.status) {
        setStatus(selectedStatus)
        Alerts.swalSuccessAlert("Member status updated successfully!");
      } else {
        Alerts.swalErrorAlert(response.data.message || "Failed to update status.");
      }
    } catch (error) {
      Alerts.swalErrorAlert("An error occurred while updating the status.");
    } finally {
      setLoading(false);
    }
  };



  const renderInfoRow = (icon, label, value) => (
    <InfoRow>
      <LabelCell>
        <Box display="flex" alignItems="center" gap={1}>
          {icon}
          <Typography>{t(label)}</Typography>
        </Box>
      </LabelCell>
      {label === 'Status' ?
        <ValueCell> <StatusUpdateSection member={member} Status={Status} onUpdate={handleStatusUpdate} /></ValueCell>
        :
        <ValueCell>{value}</ValueCell>

      }
    </InfoRow>
  );

  // Add these functions inside your component
  const generateReferralLink = () => {
    const baseUrl = 'http://localhost:3013/register_client/Members';
    const referralId = member?._id;
    const username = encodeURIComponent(member?.username);
    const mobile = encodeURIComponent(member?.mobileNumber);

    const link = `${baseUrl}?ref=${referralId}&sponsor=${username}&contact=${mobile}&name=${member?.fullName}`;
    setReferralLink(link);
    setOpenShareDialog(true);
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(referralLink);
    Alerts.swalSuccessAlert("Referral link copied to clipboard!");
  };

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Registration Referral',
          text: `Register using my referral link`,
          url: referralLink
        });
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      handleCopyLink();
    }
  };





  return (
    <LoadingOverlay active={loading} spinner={<DNALoader />}>
      <MainContainer>
        <StyledCard>
          <HeaderSection>
            <Box>
              <Typography variant="h5" color="primary" gutterBottom>
                Member Details
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Complete information about the member
              </Typography>
            </Box>

            <p> <StatusUpdateSection member={member} Status={Status} onUpdate={handleStatusUpdate} /></p>

            {/* <StyledChip
              label={member.status}
              color={member.status === 'Active' ? 'success' : 'error'}
              size="small"
            /> */}
            {/* <StyledChip
              label={member.is_verified}
              color={member.is_verified === 'verified' ? 'success' : 'error'}
              size="small"
            /> */}
          </HeaderSection>

          <Box p={3}>
            <Grid container spacing={3}>
              {/* Basic Information */}
              <Grid item xs={12} md={6}>
                <DetailSection>
                  <SectionTitle variant="h6">
                    <PersonIcon />
                    {t("Basic Information")}
                  </SectionTitle>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        {renderInfoRow(<PersonIcon />, 'Username', member?.username)}
                        {renderInfoRow(<PersonIcon />, 'Full Name', member?.fullName)}
                        {renderInfoRow(<PhoneIcon />, 'Mobile Number', member?.mobileNumber)}
                        {renderInfoRow(<EmailIcon />, 'Email Address', member?.email)}
                        {renderInfoRow(<EmailIcon />, 'Status', Status)}
                        {renderInfoRow(<EmailIcon />, 'Verification', is_verified)}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </DetailSection>
              </Grid>

              {/* Membership Information */}
              <Grid item xs={12} md={6}>
                <DetailSection>
                  <SectionTitle variant="h6">
                    <SupervisorAccountIcon />
                    {t("Membership Details")}
                  </SectionTitle>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        {renderInfoRow(<SupervisorAccountIcon />, 'Sponsor', member?.sponsor_username || '-')}
                        {renderInfoRow(<WorkIcon />, 'Package',
                          <StyledChip label={member?.package_name || "No Package"} color="primary" />
                        )}
                        {renderInfoRow(<StarIcon />, 'Rank',
                          <StyledChip label={member?.rank?.name || "No Rank"} color="primary" />
                        )}
                        {renderInfoRow(<CategoryIcon />, 'Type',
                          <StyledChip label={member?.type} color="primary" />
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </DetailSection>
              </Grid>

              {/* Organization & Wallet */}
              <Grid item xs={12} md={6}>
                <DetailSection>
                  <SectionTitle variant="h6">
                    <GroupIcon />
                    {t("Organization & Wallet")}
                  </SectionTitle>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        {renderInfoRow(<GroupIcon />, 'Left Org Members',
                          <Typography variant="body1" fontWeight={500}>
                            {member?.left_org_members?.toLocaleString() || '0'}
                          </Typography>
                        )}
                        {renderInfoRow(<GroupIcon />, 'Right Org Members',
                          <Typography variant="body1" fontWeight={500}>
                            {member?.right_org_members?.toLocaleString() || '0'}
                          </Typography>
                        )}
                        {renderInfoRow(<AccountBalanceWalletIcon />, 'Wallet Balance',
                          <Typography variant="body1" fontWeight={600} color="primary">
                            {new Intl.NumberFormat('en-US', {
                              style: 'currency',
                              currency: 'USD'
                            }).format(member?.wallet_balance || 0)}
                          </Typography>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </DetailSection>
              </Grid>

              {/* Address Information */}
              <Grid item xs={12} md={6}>
                <DetailSection>
                  <SectionTitle variant="h6">
                    <LocationOnIcon />
                    {t("Complete Address")}
                  </SectionTitle>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        {renderInfoRow(<LocationOnIcon />, 'Barangay', member?.address?.barangay)}
                        {renderInfoRow(<LocationOnIcon />, 'District', member?.address?.district)}
                        {renderInfoRow(<LocationOnIcon />, 'City/Town', member?.address?.city)}
                        {renderInfoRow(<LocationOnIcon />, 'Zip Code', member?.address?.zipCode)}
                        {renderInfoRow(<LocationOnIcon />, 'Province', member?.address?.province)}
                        {renderInfoRow(<LocationOnIcon />, 'Region', member?.address?.region)}
                        {renderInfoRow(<LocationOnIcon />, 'Country', member?.address?.country)}
                        {renderInfoRow(<LocationOnIcon />, 'Full Address', member?.fullAddress)}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </DetailSection>
              </Grid>
            </Grid>

            <Box display="flex" justifyContent="center" gap={2} mt={4} mb={2}>
              {/* {member.status === "Inactive" ? (
                <ActionButton
                  variant="contained"
                  color="success"
                  onClick={() => handleApproval(true)}
                >
                  Activate Member
                </ActionButton>
              ) : (
                <>
                  <ActionButton
                    variant="contained"
                    color="error"
                    onClick={() => handleApproval(false)}
                  >
                    Deactivate Member
                  </ActionButton>
                </>
              )} */}
              {is_verified === "partial" ? (
                <ActionButton
                  variant="contained"
                  color="success"
                  onClick={() => handleApprove()}
                >
                  Verify Member
                </ActionButton>
              ) : is_verified === "verified" ? (
                <>
                  <ActionButton
                    variant="contained"
                    color="error"
                    onClick={() => handleReject()}
                  >
                    Unverified Member
                  </ActionButton>
                </>
              ) : (
                <>
                  <ActionButton
                    variant="contained"
                    color="error"
                    onClick={() => handleApprove()}
                  >
                    wait for sponsor verification
                  </ActionButton>
                </>
              )}
              <ActionButton
                variant="contained"
                color="primary"
                disabled={Status !== 'Active'}
                onClick={generateReferralLink}
                startIcon={<ShareIcon />}
              >
                Generate Referral Link
              </ActionButton>
            </Box>
            <ShareDialog
              open={openShareDialog}
              disabled={Status !== 'Active'}
              onClose={() => setOpenShareDialog(false)}
              referralLink={referralLink}
              onCopy={handleCopyLink}
              onShare={handleShare}
            />
          </Box>
        </StyledCard>
      </MainContainer>
    </LoadingOverlay>
  );
};

export default MemberDetailsComponent;