import React, { useState, useEffect, useRef, Fragment } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, CardBody, Button, Input, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import Services from "./services";
import AuthService from "../Authentication/auth.service";
import { useTranslation } from 'react-i18next';

const ChatInterface = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const chatContainerRef = useRef(null);
  const sessionID = localStorage.getItem("session-id");
  const { t, i18n } = useTranslation();

  // useEffect(() => {
  //   fetchMessages();
  //   const intervalId = setInterval(fetchMessages, 10000);
  //   return () => clearInterval(intervalId);
  // }, [id, sessionID]);

  useEffect(() => {
    fetchMessages();
  }, [id, sessionID]);
  
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const fetchMessages = async () => {
    try {
      setLoading(true);
      const response = await Services.GetMessages(sessionID, id, 'admin');
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
      } else if (response.data) {
        setMessages(response.data.messages);
      }
    } catch (error) {
      console.error(`Error fetching messages:`, error);
    } finally {
      setLoading(false);
    }
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    if (newMessage.trim() === '') return;

    try {
      setLoading(true);
      await Services.SendMessage(sessionID, id, newMessage, 'admin');
      setNewMessage('');
      await fetchMessages();
    } catch (error) {
      console.error(`Error sending message:`, error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className="bg-light-gray bg-animation">
            <div className="d-flex justify-content-center align-items-center">
              <Col md="12" className="mx-auto app-login-box">

                <div className="app-page-title mb-3">
                  <div className="page-title-wrapper">
                    <div className="page-title-heading fw-bold">
                      <div className="page-title-icon">
                        <i className="pe-7s-note2 icon-gradient bg-tempting-azure" />
                      </div>
                      <div>
                        {t("Quotation Chat")}
                        <div className="page-title-subheading">
                          {t("Chat with Member Regarding current ")} {t("Quotations")}
                        </div>
                      </div>
                    </div>
                    <div className="page-title-actions">
                      <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-success btn-hover-shine me-3">
                        {t("Back")}
                      </button>
                    </div>
                  </div>
                </div>
                <Card className="chat-card">
                  <CardBody>
                    <div className="chat-container">
                      <div className="messages-container" ref={chatContainerRef}>
                        {messages.map((msg, index) => (
                          <div key={index} className={`message-wrapper ${msg.sender_type === 'Member' ? 'received' : 'sent'}`}>
                            <div className="message-content">
                              <div className="message-sender">{msg.sender_type === 'admin' ? 'Admin' : 'Member'}</div>
                              <div className="message-text">{msg.message}</div>
                              <div className="message-time">{moment(msg.date).format('DD-MM-YYYY, h:mm A')}</div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <form onSubmit={sendMessage} className="message-input">
                        <Input
                          type="text"
                          value={newMessage}
                          onChange={(e) => setNewMessage(e.target.value)}
                          placeholder="Type a message"
                          disabled={loading}
                        />
                        <Button color="primary" type="submit" disabled={loading}>
                          <FontAwesomeIcon icon={faPaperPlane} />
                        </Button>
                      </form>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </div>
            <style jsx>{`
.app-main__outer {
  background-color: #f8f9fa;
  min-height: 100vh;
  padding-top: 20px;
}
.chat-container {
  background-color: #ffffff;
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 600px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.05);
}
.messages-container {
  flex-grow: 1;
  overflow-y: auto;
  padding: 15px;
  background-color: #f8f9fa;
}
.message-wrapper {
  display: flex;
  margin-bottom: 10px;
}
.message-wrapper.sent {
  justify-content: flex-end;
}
.message-wrapper.received {
  justify-content: flex-start;
}
.message-content {
  max-width: 60%;
  padding: 10px 15px;
  border-radius: 18px;
  position: relative;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}
.message-wrapper.sent .message-content {
  background-color: #3D607A;
  color: white;
}
.message-wrapper.received .message-content {
  background-color: #e9ecef;
  color: #333;
}
.message-sender {
  font-weight: bold;
  margin-bottom: 4px;
  font-size: 0.9em;
  opacity: 0.8;
}
.message-text {
  margin-bottom: 4px;
  line-height: 1.4;
}
.message-time {
  font-size: 0.75em;
  opacity: 0.7;
  text-align: right;
}
.message-input {
  display: flex;
  align-items: center;
  padding: 15px;
  background-color: #ffffff;
  border-top: 1px solid #e0e0e0;
}
.message-input input {
  flex-grow: 1;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  padding: 10px 15px;
  margin-right: 10px;
}
.message-input button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
`}</style>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ChatInterface;