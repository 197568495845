import React, { useState, useEffect, useRef } from 'react';
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Typography,
  Container,
  Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import AlertService from "../../components/Alerts/alerts";
import AddressService from './addressService';

const UpdateAddress = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [formData, setFormData] = useState({
    location: '',
    block: '',
    unit: '',
    label: '',
    latitude: '',
    longitude: '',
    address_url: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
  });
  const autocompleteRef = useRef(null);
  const inputRef = useRef(null);
  const [storeduser, setStoredUser] = useState(JSON.parse(localStorage.getItem("user")));

  useEffect(() => {
    if (location.state && location.state.address) {
      setFormData(location.state.address);
    }
  }, [location.state]);

  useEffect(() => {
    const initMap = () => {
      if (!inputRef.current || autocompleteRef.current) return;

      const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current, {
        types: ['address'],
      });
      autocompleteRef.current = autocomplete;

      autocomplete.addListener('place_changed', handlePlaceSelect);
    };

    if (window.google && window.google.maps) {
      initMap();
    } else {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&libraries=places`;
      script.onload = initMap;
      document.head.appendChild(script);
    }
    return () => {
      if (autocompleteRef.current) {
        window.google.maps.event.clearInstanceListeners(autocompleteRef.current);
        autocompleteRef.current = null;
      }
    };
  }, []);

  const handlePlaceSelect = () => {
    const place = autocompleteRef.current.getPlace();
    if (!place.geometry) {
      console.error('No details available for input:', place.name);
      return;
    }

    const addressComponents = place.address_components;
    let block = '';
    let unit = '';
    let location = '';
    let city = '';
    let state = '';
    let postalCode = '';
    let country = '';

    addressComponents.forEach((component) => {
      const componentType = component.types[0];

      switch (componentType) {
        case 'street_number':
          unit = component.long_name;
          break;
        case 'route':
          block = component.long_name;
          break;
        case 'locality':
          city = component.long_name;
          break;
        case 'administrative_area_level_1':
          state = component.long_name;
          break;
        case 'postal_code':
          postalCode = component.long_name;
          break;
        case 'country':
          country = component.long_name;
          break;
      }
    });

    location = `${block} ${unit}, ${city}, ${state} ${postalCode}, ${country}`.trim();

    setFormData({
      ...formData,
      location: location,
      block: block,
      unit: unit,
      city: city,
      state: state,
      postalCode: postalCode,
      country: country,
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
      address_url: place.url,
    });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = {
        address_id: location.state.address._id,
        label: formData.label,
        unit: formData.unit,
        location: formData.location,
        block: formData.block,
        city: formData.city,
        state: formData.state,
        postalCode: formData.postalCode,
        country: formData.country,
        latitude: formData.latitude,
        longitude: formData.longitude
      };
      const response = await AddressService.updateAddress(data);

      if (response.data.status) {
        AlertService.swalSuccessAlert(t("Address updated successfully."));
        navigate('/User_Profile');
      } else {
        AlertService.swalErrorAlert(response.data.message || t("Error updating address."));
      }
    } catch (error) {
      AlertService.swalErrorAlert(t("Error updating address. Please try again."));
    }
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 4 }}>
        <Typography variant="h4" gutterBottom>
          {t('Update Address')}
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            inputRef={inputRef}
            margin="normal"
            id="location"
            name="location"
            label={t("Full Address")}
            fullWidth
            value={formData.location}
            onChange={handleChange}
          />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="normal"
                name="block"
                label={t("Block or Street name")}
                fullWidth
                value={formData.block}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="normal"
                name="unit"
                label={t("Unit/House no.")}
                fullWidth
                value={formData.unit}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="normal"
                name="city"
                label={t("City")}
                fullWidth
                value={formData.city}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="normal"
                name="state"
                label={t("State/Province")}
                fullWidth
                value={formData.state}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="normal"
                name="postalCode"
                label={t("Postal Code")}
                fullWidth
                value={formData.postalCode}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="normal"
                name="country"
                label={t("Country")}
                fullWidth
                value={formData.country}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <FormControl fullWidth margin="normal">
            <InputLabel>{t("Label")}</InputLabel>
            <Select
              name="label"
              value={formData.label}
              onChange={handleChange}
            >
              <MenuItem value="Home">{t("Home")}</MenuItem>
              <MenuItem value="Work">{t("Work")}</MenuItem>
              <MenuItem value="Other">{t("Other")}</MenuItem>
            </Select>
          </FormControl>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
            <Button onClick={() => navigate(-1)} variant="outlined">
              {t("Cancel")}
            </Button>
            <Button type="submit" variant="contained" color="primary">
              {t('Update')}
            </Button>
          </Box>
        </form>
      </Box>
    </Container>
  );
};

export default UpdateAddress;