import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const PrivacyPolicy = ({ onClose }) => {
    const styles = {
        container: {
            padding: '20px',
            maxWidth: '800px',
            margin: '0 auto',
            fontFamily: 'Arial, sans-serif',
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: '20px',
            borderBottom: '1px solid #eee',
            paddingBottom: '10px',
        },
        backButton: {
            background: 'none',
            border: 'none',
            cursor: 'pointer',
            fontSize: '24px',
            marginRight: '20px',
        },
        title: {
            fontSize: '24px',
            fontWeight: 'bold',
        },
        sectionTitle: {
            fontSize: '18px',
            fontWeight: 'bold',
            marginTop: '20px',
            marginBottom: '10px',
        },
        paragraph: {
            marginBottom: '15px',
            lineHeight: '1.6',
        },
        list: {
            marginLeft: '20px',
            marginBottom: '15px',
        },
        listItem: {
            marginBottom: '10px',
        },
    };

    return (
        <div style={styles.container}>
            <div style={styles.header}>
                <h1 style={styles.title}>Privacy Policy</h1>
            </div>
            <div>
                <h2 style={styles.sectionTitle}>TERM OF USE FOR USERS</h2>
                
                <h3 style={styles.sectionTitle}>GENERAL REFUND AND RETURN POLICY</h3>
                <p style={styles.paragraph}>
                    Buyer may initiate the returns process by communicating with Seller through JS Platform.
                </p>
                <p style={styles.paragraph}>
                    In the event of such refund or return arises, the process and request shall as follow:
                </p>
                <ol style={styles.list}>
                    <li style={styles.listItem}>For Food Product will be within 24 hours;</li>
                    <li style={styles.listItem}>For Non-Food or Product will be within 72 hours; and</li>
                    <li style={styles.listItem}>For Professional Services it will be between requester and provider of the best practices /or services provided for upon agreement.</li>
                </ol>
                <p style={styles.paragraph}>
                    The user hereby acknowledges and agree that all the optional refund, return and exchange products are subject to the merchant's(Biz's) approval on the buyer's request, JS shall in no manner whatsoever be held liable for any return, exchange and refund requested by you of the aforementioned period of the request as per above.
                </p>

                <h3 style={styles.sectionTitle}>GENERAL DELIVERY POLICY</h3>
                <p style={styles.paragraph}>
                    User of this Service provided by JS is responsible for ensuring that the delivery details entered by them on the platform are accurate and complete. JS shall not be liable in the event of late delivery or non-delivery of Goods that the User order by reason of inaccurate delivery details entered by User.
                </p>
                <p style={styles.paragraph}>
                    The availability of a product or service, pricing, promotions, offers, product or service descriptions, product shipping charges and transit time will depend on the Merchant's (Biz's) /or Third Party provider. All Goods order and delivery bookings placed on the platform are treated as confirmed and shall there be any cancellation of booking after it has been confirmed, User remain liable to pay the delivery fee in full.
                </p>
                <p style={styles.paragraph}>
                    The estimate delivery time/or any change and the price to be paid will be confirmed by JS Merchant/or Third Party provider by calling the User on the mobile number provided and any change in order (due to unavailability) will be reconfirmed upon the successful completion of a booking. For the sake of clarity, JS is not involved and liable for any detached arrangement between User and the JS Merchant and/or Third Party Provider regarding the amendment and/or cancellation of orders made by User where such arrangement is not confirmed and recorded on the platform.
                </p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;