import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Button, Input, Dropdown, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, CardBody } from 'reactstrap';
import { FaWallet, FaGlobe, FaFile, FaUndo, FaRedoAlt, FaShoppingCart, FaEye, FaEdit, FaFilter, FaPlus, FaCommentAlt } from 'react-icons/fa';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import DashboardService from "../dashboard.service";
import AuthService from "../../Authentication/auth.service";
import Alerts from "../../../components/Alerts/alerts";
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import "../../../assets/custom css/loadoverlay.scss";
import DashboardHeader from './DashboardHeader';
import { RotateCcw, RefreshCw, RepeatIcon, ShoppingCart, Eye, Edit } from 'lucide-react';



const customStyles = {
  headRow: {
    style: {
      backgroundColor: '#f8f9fa',
      borderBottom: 'none',
    },
  },
  headCells: {
    style: {
      color: '#6c757d',
      fontSize: '0.9rem',
      fontWeight: '600',
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  },
  rows: {
    style: {
      fontSize: '0.9rem',
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  },
};
const cardStyle = {
  borderRadius: '10px',
  boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
};

const headerStyle = {
  fontSize: '0.9rem',
  color: '#6c757d',
  marginBottom: '0.25rem',
};

const valueStyle = {
  fontSize: '1.2rem',
  fontWeight: 'bold',
  marginBottom: '0',
};

const listItemStyle = {
  fontSize: '0.9rem',
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '0.5rem',
};


const Home = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [rfqData, setRfqData] = useState([]);
  const [statusCounts, setStatusCounts] = useState({
    active: 0,
    approved: 0,
    rejected: 0,
    pending: 0
  });
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState("");
  const authData = JSON.parse(localStorage.getItem("user"));

  // Perform actions on component mount or when 'pageLimit' changes
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchData(0, pageLimit);
  }, [pageLimit]);

  function fetchData(page, limit) {
    setLoading(true);
    var offset = (page - 1) * limit;
    if (offset < 1) {
      offset = 0;
    }
    const payload = {
      functionName: "GetRFQs",
      postData: {
        filter: {},
        limit: limit,
        offset: offset,
        user_id: authData._id,
        business: []
      }
    };

    DashboardService.GetRFQs(payload).then(
      (response) => {
        setLoading(false);
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        } else {
          setRfqData(response.data.data);
          setTotalRows(response.data.counts.count);
          setStatusCounts(response.data.count.statusCounts);
        }
      },
      (error) => {
        setLoading(false);
        const errContent = (error.response && error.response.data) || error.message || error.toString();
        Alerts.swalErrorAlert(errContent || t("Server Error, Please try again later."));
      }
    );
  }

  function handlePageChange(page_index) {
    console.log("Page index:-", page_index)
    setCurrentPage(page_index)
    fetchData(page_index, pageLimit);
  }

  async function handlePerRowsChange(newPageLimit, page_index) {
    setPageLimit(newPageLimit);
  }

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case 'pending':
        return '#FFA500'; // Orange
      case 'confirmed':
        return 'green'; // Orange
      case 'accepted':
        return '#4169E1'; // Royal Blue
      case 'processing':
        return '#9370DB'; // Medium Purple
      case 'in_transit':
        return '#1E90FF'; // Dodger Blue
      case 'delivered':
        return '#32CD32'; // Lime Green
      case 'cancelled':
      case 'rejected':
        return '#DC143C'; // Crimson
      case 'issued':
        return '#228B22'; // Forest Green
      case 'review':
        return '#FF4500'; // Orange Red
      default:
        return '#808080'; // Gray for unknown status
    }
  };


  const handleReorder = (row) => {
    navigate('/add_new_rfq', { state: { orderDetails: row } });
  };

  const columns = [
    { name: 'RFQ ID', selector: row => row?.rfq_id, sortable: true },
    { name: 'Total Item', selector: row => row?.items.length, sortable: true },
    { name: 'Date', selector: row => moment(row?.datetime_added).format('DD-MM-YYYY'), sortable: true },
    {
      name: 'Status',
      selector: row => row?.status?.charAt(0)?.toUpperCase() + row?.status?.slice(1),
      sortable: true,
      cell: row => {
        const capitalizedStatus = row?.status?.charAt(0)?.toUpperCase() + row?.status?.slice(1);
        return (
          <span style={{
            color: getStatusColor(row?.status),
            fontWeight: 'bold',
          }}>
            {capitalizedStatus}
          </span>
        );
      }
    },
    {
      name: 'Payment Status',
      selector: row => row?.payment_status ? row?.payment_status?.charAt(0)?.toUpperCase() + row?.payment_status?.slice(1) : 'N/A',
      sortable: true,
      cell: row => {
        const capitalizedStatus = row?.payment_status ? row?.payment_status?.charAt(0)?.toUpperCase() + row?.payment_status?.slice(1) : 'N/A';
        return (
          <span style={{
            color: getStatusColor(row?.payment_status),
            fontWeight: 'bold',
          }}>
            {capitalizedStatus}
          </span>
        );
      }
    },
    {
      name: 'Action',
      cell: row => (
        <div>
          <Button color="link" onClick={() => navigate(`/view_rfqs/${row?._id}`)} className="p-0 me-2">
            <Eye size={18} color="#3D607A" />
          </Button>
          {(row?.status === 'requested' || row?.status === 'rejected') && (
            <Button color="link" onClick={() => navigate(`/edit_rfqs/${row?._id}`)} className="p-0">
            <Edit size={18} color="#3D607A" />
            </Button>
          )}
          {row?.status === 'delivered' && (
            <Button color="link" onClick={() => navigate(`/return_items/${row?._id}`)} className="p-0">
              <RotateCcw size={18} color="#3D607A" />
            </Button>
          )}
          {row?.status === 'delivered' && (
            <Button color="link" onClick={() => handleReorder(row)} className="p-0">
              <RepeatIcon size={18} color="#3D607A" />
            </Button>
          )}

        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];



  const trendData = [
    {
      icon: <FaWallet />, title: 'Active', value: (parseInt(statusCounts?.Review || 0) + parseInt(statusCounts?.Issued || 0)
        + parseInt(statusCounts?.in_transit || 0) + parseInt(statusCounts?.accepted || 0) + parseInt(statusCounts?.confirmed || 0)
        + parseInt(statusCounts?.pending || 0) + parseInt(statusCounts?.in_transit || 0)
        + parseInt(statusCounts?.requested || 0)) || 0, color: '#3D607A'
    },
    { icon: <FaGlobe />, title: 'Delivered', value: (parseInt(statusCounts?.delivered || 0)) || 0, color: '#3D607A' },
    { icon: <FaFile />, title: 'Cancelled', value: (parseInt(statusCounts?.rejected + parseInt(statusCounts?.cancelled || 0) || 0)) || 0, color: '#3D607A' },
  ];

  return (
    <div className="app-main__outer">
      <div className="container mt-4">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <DashboardHeader data="Orders" />
          <div className="d-flex">
            <Input type="text" placeholder="Search for something" className="mr-2" />
          </div>
        </div>

        <Row className="g-4 mb-4">
          {trendData.map((trend, index) => (
            <Col md={4} key={index}>
              <Card body className="trend-card d-flex flex-row align-items-center justify-content-between">
                <div>
                  <div className="trend-title">{trend.title}</div>
                  <div className="trend-value">{trend.value}</div>
                </div>
                <div className="icon-circle" style={{ backgroundColor: trend.color }}>
                  {trend.icon}
                </div>
              </Card>
            </Col>
          ))}
        </Row>

        <Row className="g-4">
          <Card className="mt-4 mb-4">
            <div className="p-4">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h4 className="mb-0">Orders</h4>
                <div>
                  {/* <UncontrolledDropdown className="me-2 d-inline-block">
                    < DropdownToggle caret color="light">
                      Sort by <FaFilter size={14} />
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem>Date</DropdownItem>
                      <DropdownItem>Status</DropdownItem>
                      <DropdownItem>Total Item</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown> */}

                  <Button onClick={() => navigate(`/add_new_rfq`)} style={{ backgroundColor: '#3D607A', borderColor: '#3D607A' }}>
                    Request For Quotation <FaPlus size={14} className="ms-1" />
                  </Button>

                </div>
              </div>
              <DataTable
                data={rfqData}
                columns={columns}
                pagination
                fixedHeader
                fixedHeaderScrollHeight="400px"
                paginationServer
                paginationTotalRows={totalRows}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                paginationResetDefaultPage={resetPaginationToggle}
                customStyles={customStyles}
              />

            </div>
          </Card >
        </Row>

        <style jsx>{`
        .trend-card {
          border-radius: 10px;
          box-shadow: 0 2px 10px rgba(0,0,0,0.05);
          background-color: #ffffff;
        }
        .icon-circle {
          width: 40px;
          height: 40px;
          border-radius: 25%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
        }
        .trend-title {
          font-size: 14px;
          color: #777;
          margin-bottom: 2px;
        }
        .trend-value {
          font-size: 18px;
          font-weight: 600;
          color: #333;
        }
        .chart-card, .order-card {
          height: 400px;
          overflow: hidden;
        }
        .order-list {
          max-height: 250px;
          overflow-y: auto;
        }
      `}</style>
      </div>
      {/* </Card> */}
      {/* </LoadingOverlay> */}
      {/* </div> */}
    </div>
  );
};

export default Home;

// import React, { useState, useEffect } from 'react';
// import { Card, Row, Col, Button, Input, Dropdown, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, CardBody } from 'reactstrap';
// import { FaWallet, FaGlobe, FaFile, FaShoppingCart, FaEye, FaEdit, FaFilter, FaPlus, FaCommentAlt } from 'react-icons/fa';
// import DataTable from 'react-data-table-component';
// import { useNavigate } from 'react-router-dom';
// import DashboardService from "../dashboard.service";
// import AuthService from "../../Authentication/auth.service";
// import Alerts from "../../../components/Alerts/alerts";
// import { useTranslation } from 'react-i18next';
// import moment from 'moment';
// import LoadingOverlay from "react-loading-overlay-ts";
// import DNALoader from "../../../components/Loaders/DnaLoader";
// import "../../../assets/custom css/loadoverlay.scss";
// import DashboardHeader from './DashboardHeader';



// const customStyles = {
//   headRow: {
//     style: {
//       backgroundColor: '#f8f9fa',
//       borderBottom: 'none',
//     },
//   },
//   headCells: {
//     style: {
//       color: '#6c757d',
//       fontSize: '0.9rem',
//       fontWeight: '600',
//       paddingLeft: '8px',
//       paddingRight: '8px',
//     },
//   },
//   rows: {
//     style: {
//       fontSize: '0.9rem',
//       paddingLeft: '8px',
//       paddingRight: '8px',
//     },
//   },
// };
// const cardStyle = {
//   borderRadius: '10px',
//   boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
// };

// const headerStyle = {
//   fontSize: '0.9rem',
//   color: '#6c757d',
//   marginBottom: '0.25rem',
// };

// const valueStyle = {
//   fontSize: '1.2rem',
//   fontWeight: 'bold',
//   marginBottom: '0',
// };

// const listItemStyle = {
//   fontSize: '0.9rem',
//   display: 'flex',
//   justifyContent: 'space-between',
//   marginBottom: '0.5rem',
// };


// const Home = () => {
//   const navigate = useNavigate();
//   const { t, i18n } = useTranslation();
//   const [loading, setLoading] = useState(false);
//   const [rfqData, setRfqData] = useState([]);
//   const [statusCounts, setStatusCounts] = useState({
//     active: 0,
//     approved: 0,
//     rejected: 0,
//     pending: 0
//   });
//   const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
//   const [totalRows, setTotalRows] = useState(0);
//   const [pageLimit, setPageLimit] = useState(10);
//   const [currentPage, setCurrentPage] = useState("");
//   const authData = JSON.parse(localStorage.getItem("user"));

//   // Perform actions on component mount or when 'pageLimit' changes
//   useEffect(() => {
//     i18n.changeLanguage(localStorage.getItem('language'));
//     fetchData(0, pageLimit);
//   }, [pageLimit]);

//   function fetchData(page, limit) {
//     setLoading(true);
//     var offset = (page - 1) * limit;
//     if (offset < 1) {
//       offset = 0;
//     }
//     const payload = {
//       functionName: "GetRFQs",
//       postData: {
//         filter: {},
//         limit: limit,
//         offset: offset,
//         user_id: authData._id,
//         business: []
//       }
//     };

//     DashboardService.GetRFQs(payload).then(
//       (response) => {
//         setLoading(false);
//         if (response.data.message === "Unauthorized") {
//           AuthService.logout();
//           navigate("/login");
//           window.location.reload();
//         } else {
//           setRfqData(response.data.data);
//           setTotalRows(response.data.counts.count);
//           setStatusCounts(response.data.count.statusCounts);
//         }
//       },
//       (error) => {
//         setLoading(false);
//         const errContent = (error.response && error.response.data) || error.message || error.toString();
//         Alerts.swalErrorAlert(errContent || t("Server Error, Please try again later."));
//       }
//     );
//   }

//   function handlePageChange(page_index) {
//     console.log("Page index:-", page_index)
//     setCurrentPage(page_index)
//     fetchData(page_index, pageLimit);
//   }

//   async function handlePerRowsChange(newPageLimit, page_index) {
//     setPageLimit(newPageLimit);
//   }






//   const columns = [
//     { name: 'RFQ ID', selector: row => row?.rfq_id, sortable: true },
//     { name: 'Total Item', selector: row => row?.items.length, sortable: true },
//     { name: 'Date of RFQ', selector: row => moment(row?.datetime_added).format('DD-MM-YYYY'), sortable: true },
//     {
//       name: 'Status',
//       selector: row => row?.status?.charAt(0)?.toUpperCase() + row?.status?.slice(1),
//       sortable: true,
//       cell: row => {
//         const capitalizedStatus = row?.status?.charAt(0)?.toUpperCase() + row?.status?.slice(1);
//         return (
//           <span style={{
//             color: capitalizedStatus === 'Accepted' ? '#DAA520' :
//               capitalizedStatus === 'Rejected' ? '#DC143C' :
//                 capitalizedStatus === 'Issued' ? '#228B22' :
//                   capitalizedStatus === 'Review' ? 'red' : '#000000'
//           }}>
//             {capitalizedStatus}
//           </span>
//         );
//       }
//     },
//     {
//       name: 'Action',
//       cell: row => (
//         <div>
//           <Button color="link" onClick={() => navigate(`/view_rfqs/${row?._id}`)} className="p-0 me-2">
//             <FaEye size={18} color="#3D607A" />
//           </Button>
//           {row?.status === 'requested' || row?.status === 'rejected' && (
//             <Button color="link" onClick={() => navigate(`/edit_rfqs/${row?._id}`)} className="p-0">
//               <FaEdit size={18} color="#3D607A" />
//             </Button>
//           )}
//           <Button color="link" onClick={() => navigate(`/chat/${row?._id}`)} className="p-0">
//             <FaCommentAlt size={18} color="#3D607A" />
//           </Button>

//         </div>
//       ),
//       ignoreRowClick: true,
//       allowOverflow: true,
//       button: true,
//     },
//   ];



//   const trendData = [
//     { icon: <FaWallet />, title: 'Active', value: (parseInt(statusCounts?.Review || 0) + parseInt(statusCounts?.Issued || 0) + parseInt(statusCounts?.requested || 0)) || 0, color: '#3D607A' },
//     { icon: <FaGlobe />, title: 'Approved', value: (parseInt(statusCounts?.accepted || 0)) || 0, color: '#3D607A' },
//     { icon: <FaFile />, title: 'Rejected', value: (parseInt(statusCounts?.rejected || 0)) || 0, color: '#3D607A' },
//   ];

//   return (
//     <div className="app-main__outer">
//       <div className="container mt-4">
//         <div className="d-flex justify-content-between align-items-center mb-3">
//           <DashboardHeader data="Quotations" />
//           <div className="d-flex">
//             <Input type="text" placeholder="Search for something" className="mr-2" />
//           </div>
//         </div>

//         <Row className="g-4 mb-4">
//           {trendData.map((trend, index) => (
//             <Col md={4} key={index}>
//               <Card body className="trend-card d-flex flex-row align-items-center justify-content-between">
//                 <div>
//                   <div className="trend-title">{trend.title}</div>
//                   <div className="trend-value">{trend.value}</div>
//                 </div>
//                 <div className="icon-circle" style={{ backgroundColor: trend.color }}>
//                   {trend.icon}
//                 </div>
//               </Card>
//             </Col>
//           ))}
//         </Row>

//         <Row className="g-4">
//           <Card className="mt-4 mb-4">
//             <div className="p-4">
//               <div className="d-flex justify-content-between align-items-center mb-4">
//                 <h4 className="mb-0">Quotations Requested</h4>
//                 <div>
//                   <UncontrolledDropdown className="me-2 d-inline-block">
//                     < DropdownToggle caret color="light">
//                       Sort by <FaFilter size={14} />
//                     </DropdownToggle>
//                     <DropdownMenu>
//                       <DropdownItem>Date</DropdownItem>
//                       <DropdownItem>Status</DropdownItem>
//                       <DropdownItem>Total Item</DropdownItem>
//                     </DropdownMenu>
//                   </UncontrolledDropdown>

//                   <Button onClick={() => navigate(`/add_new_rfq`)} style={{ backgroundColor: '#3D607A', borderColor: '#3D607A' }}>
//                     Add New <FaPlus size={14} className="ms-1" />
//                   </Button>

//                 </div>
//               </div>
//               <DataTable
//                 data={rfqData}
//                 columns={columns}
//                 pagination
//                 fixedHeader
//                 fixedHeaderScrollHeight="400px"
//                 paginationServer
//                 paginationTotalRows={totalRows}
//                 onChangePage={handlePageChange}
//                 onChangeRowsPerPage={handlePerRowsChange}
//                 paginationResetDefaultPage={resetPaginationToggle}
//                 customStyles={customStyles}
//               />

//             </div>
//           </Card >
//         </Row>

//         <style jsx>{`
//         .trend-card {
//           border-radius: 10px;
//           box-shadow: 0 2px 10px rgba(0,0,0,0.05);
//           background-color: #ffffff;
//         }
//         .icon-circle {
//           width: 40px;
//           height: 40px;
//           border-radius: 25%;
//           display: flex;
//           align-items: center;
//           justify-content: center;
//           color: white;
//         }
//         .trend-title {
//           font-size: 14px;
//           color: #777;
//           margin-bottom: 2px;
//         }
//         .trend-value {
//           font-size: 18px;
//           font-weight: 600;
//           color: #333;
//         }
//         .chart-card, .order-card {
//           height: 400px;
//           overflow: hidden;
//         }
//         .order-list {
//           max-height: 250px;
//           overflow-y: auto;
//         }
//       `}</style>
//       </div>
//       {/* </Card> */}
//       {/* </LoadingOverlay> */}
//       {/* </div> */}
//     </div>
//   );
// };

// export default Home;