import axios from "axios";

const API_URL = process.env.REACT_APP_BASEURL;
const B_URL = process.env.REACT_APP_LOGINURL
const storeduser = JSON.parse(localStorage.getItem("user"));


const headers = {
  'Content-Type': 'application/json',
  'token': localStorage.getItem("session-id")
}


const getuserform = (name) => {
  var payload = {
    functionName: "GetUserForm",
    "postData": {
      name: name
    }
  }
  return axios.post(API_URL, payload, { headers });
};


const deleteCollection = (id, referal_name) => {
  var payload = {
    functionName: "DeleteCollectionData",
    postData: {
      "_id": id,
      "collection_name": referal_name,
      "login_user_id": storeduser._id
    },
  }
  return axios.post(API_URL, payload, { headers });
};
const bulkdeleteCollection = (id, referal_name) => {
  var payload = {
    functionName: "BulkDeleteDynCollectionData",
    postData: {
      "_id": id,
      "collection_name": referal_name,
      "login_user_id": storeduser._id
    },
  }
  return axios.post(API_URL, payload, { headers });
};

const getAllInvoices = (page, limit, filter, status, startDate, endDate, dueStartDate, dueEndDate) => {
  var payload = {
    functionName: "Search",
    customer_id: filter,
    status: status,
    startDate: startDate,
    endDate: endDate,
    dueStartDate: dueStartDate,
    dueEndDate: dueEndDate,
    limit: limit,
    offset: page
  }
  return axios.post(B_URL + "invoices", payload, { headers });
};
const getAllPayments = (sessionID, page, limit,business, filter, status, startDate, endDate, dueStartDate, dueEndDate, search_text) => {
  var payload = {
    functionName: "Search",
    customer_id: filter,
    search_text: search_text,
    status: status,
    startDate: startDate,
    endDate: endDate,
    dueStartDate: dueStartDate,
    dueEndDate: dueEndDate,
    limit: limit,
    business_ids:business,
    offset: page
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }


  return axios.post(B_URL + "payments", payload, { headers });
};

const getcollection = (referal_name, filter, limit, offset) => {
  var payload = {
    functionName: "GetCollectionData",
    postData: {
      "collection_name": referal_name,
      "login_user_id": storeduser._id,
      "filter": filter,
      "limit": limit,
      "offset": offset
    },
  }
  return axios.post(API_URL, payload, { headers });
};

const getoptions = (referal_name, filter, fieldlist, limit, offset) => {
  var payload = {
    functionName: "GetCollectionData",
    postData: {
      "collection_name": referal_name,
      "login_user_id": storeduser._id,
      "fieldlist": fieldlist,
      "filter": filter,
      "limit": limit,
      "offset": offset
    },
  }
  return axios.post(API_URL, payload, { headers });
};


const insertCollectionData = payload => {
  console.log("Received addRole Payload:-", payload)
  return axios.post(API_URL, payload, { headers });
}

const import_dyn_records = (formData) => {
  formData.login_user_id = storeduser._id
  var payload = {
    functionName: "ImportDynCollectionData",
    postData: formData
  }
  console.log(formData);
  return axios.post(API_URL, payload, { headers });
};

const getAllCruds = (filter) => {
  var payload = {
    functionName: "GetCollectionData",
    "postData": {
      "collection_name": "dynamic_modules",
      "filter": filter
    }
  }
  return axios.post(API_URL, payload, { headers });
};

const save_user = (payload) => {
  const API_URL2 = process.env.REACT_APP_LOGINURL + 'modifyusertypes';
  return axios.post(API_URL2, payload, { headers });
};

const getAllCustomers = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'customerRoutes/GetCustomers', payload, { headers });
};

const deletePayment = (id, sessionID) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  var payload = {
    functionName: "DeletePayment",
    _id: id,
  }
  return axios.post(B_URL + 'payments', payload, { headers });
};
const deleteInvoice = (id) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  var payload = {
    functionName: "DeleteInvoice",
    _id: id,
  }
  return axios.post(B_URL + 'invoices', payload, { headers });
};
const deleteCustomer = (id) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  var payload = {
    functionName: "DeleteCustomer",
    postData: {
      _id: id,
    },
  }
  return axios.post(B_URL + 'customers', payload, { headers });
};
const getInvoice_PDF = (payload, sessionID) => {
  const headers = {

    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + 'invoices', payload, {
    headers,
    responseType: 'arraybuffer'
  });
};
const Get_PDF_Payments = (payload, sessionID) => {
  const headers = {

    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + 'payments', payload, {
    headers,
    responseType: 'arraybuffer'
  });
};
const getDocTypes = () => {
  var payload = {
    functionName: "GetTypes",
    postData: {},
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'documents', payload, { headers });
};

const getCustomerDocs = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'documents', payload, { headers });
};


const AddCustomerDocument = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'documents', payload, { headers });
};

const UpdateCustomerDocument = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'documents', payload, { headers });
};

const deleteDocument = (id) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  var payload = {
    functionName: "DeleteDocument",
    postData: {
      "_id": id,
    },
  }
  return axios.post(B_URL + 'documents', payload, { headers });
};

const getCustomerPricing = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'customers', payload, { headers });
};

const addCustomerPricing = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'customers', payload, { headers });
};

const updateCustomerPricing = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'customers', payload, { headers });
};

const deleteCustomerPricing = (id, service_id, user_id) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  var payload = {
    functionName: "DeleteCustomerPricing",
    postData: {
      "_id": id,
      "service_id": service_id,
      "user_id": user_id
    },
  }
  return axios.post(B_URL + 'customers', payload, { headers });
};


const UpdateOrderStatus = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'orderRoutes/UpdateOrder', payload, { headers });
};
const UpdateInvoice = (payload, sessionID) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + 'invoices', payload, { headers });
};

const updatePayment = (payload, sessionID) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + 'payments', payload, { headers });
};

const generateEInvoice = async (payload) => {
  let LoginDetails = await axios.post(B_URL + "login/", {
    functionName: "LoginUser",
    postData: {
      "email": 'yasir@syslabtechnologies.com',
      "password": 'syslab2023',
    },
  })
  if (LoginDetails.data.status) {
    const Token = LoginDetails.data.sessionID;
    const headers = {
      'Content-Type': 'application/json',
      'token': Token
    }
    console.log(payload);
    return axios.post(B_URL + 'FinanceModule/generate_e_invoice', payload, { headers });
  }
};

const GetDocument = async (payload) => {
  let LoginDetails = await axios.post(B_URL + "login/", {
    functionName: "LoginUser",
    postData: {
      "email": 'yasir@syslabtechnologies.com',
      "password": 'syslab2023',
    },
  })
  if (LoginDetails.data.status) {
    const Token = LoginDetails.data.sessionID;
    const headers = {
      'Content-Type': 'application/json',
      'token': Token
    }
    console.log(payload);
    return axios.post(B_URL + 'E_InvoiceModule/Get_Document', payload, { headers });
  }
};

const CustomerService = {
  GetDocument,
  generateEInvoice,
  updatePayment,
  UpdateInvoice,
  getAllInvoices,
  getAllPayments,
  UpdateOrderStatus,
  getAllCustomers,
  deleteCustomer,
  getInvoice_PDF,
  Get_PDF_Payments,
  deleteInvoice,
  deletePayment,
  getDocTypes,
  getCustomerDocs,
  AddCustomerDocument,
  UpdateCustomerDocument,
  deleteDocument,
  getCustomerPricing,
  addCustomerPricing,
  deleteCustomerPricing,
  updateCustomerPricing,
  import_dyn_records,
  getuserform,
  deleteCollection,
  bulkdeleteCollection,
  getcollection,
  insertCollectionData,
  getAllCruds,
  getoptions,
  save_user
};

export default CustomerService;