import * as AuthApi from "../pages/Authentication/api/AuthRequst.js";

export const logIn = (formData) => async (dispatch) => {
    dispatch({ type: "AUTH_START" });
    try {
        const { data } = await AuthApi.logIn(formData);
        dispatch({ type: "AUTH_SUCCESS", data: data });
        return { success: true, data: data };  // Return success result
    } catch (error) {
        console.log(error);
        dispatch({ type: "AUTH_FAIL" });
        return { success: false, error: error };  // Return failure result
    }
};

export const setAccessPermissions = (permissions) => ({
    type: 'SET_ACCESS_PERMISSIONS',
    payload: permissions,
});

export const setAccessPages = (pages) => ({
    type: 'SET_ACCESS_PAGES',
    payload: pages,
});

