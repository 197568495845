import axios from "axios";

const API_URL  = process.env.REACT_APP_BASEURL;

const headers= { 
  'Content-Type': 'application/json',
  'token': localStorage.getItem("session-id")
}

const getAll = (sessionID) => {
  var getData = {
    functionName: "GetCollectionData",
    postData: {
      collection_name: "templates"
    },
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(API_URL, getData, {headers});
};


const deleteTemplate = (sessionID,id) => {
  var payload = {
    functionName: "DeleteCollectionData",
    postData: {
      _id: id,
      collection_name:"templates"
    },
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(API_URL, payload, {headers})
};


const addTemplate = (sessionID,payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  console.log("Add Data:-", payload)
  return axios.post(API_URL, payload, {headers});
};


const updateTemplate = (sessionID,payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  console.log("Update Data:-", payload)
  return axios.post(API_URL, payload, {headers});
}


const EmailService = {
  getAll,
  deleteTemplate,
  addTemplate,
  updateTemplate
};

export default EmailService;