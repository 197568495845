import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import CustomerService from "./Customer.service";
import AuthService from "../../Authentication/auth.service";
import Alerts from "../../../components/Alerts/alerts";

const ThankYouPage = () => {

  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
  }, []);

  const pageStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#f4f4f4',
    padding: '20px',
    boxSizing: 'border-box',
  };

  const headingStyle = {
    fontSize: '2rem',
    marginBottom: '20px',
    display: 'flex',
    alignItems: 'center',
    color: '#333', // Text color
  };

  const iconStyle = {
    marginRight: '10px',
    color: '#28a745', // Icon color
  };

  const imageStyle = {
    maxWidth: '300px',
    marginBottom: '20px',
    borderRadius: '8px', // Rounded corners for the image
  };

  const backButtonStyle = {
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: '#fff',
    borderRadius: '5px',
    textDecoration: 'none',
    marginTop: '20px',
    transition: 'background-color 0.3s ease', // Smooth hover effect
  };

  const backButtonHoverStyle = {
    ...backButtonStyle,
    backgroundColor: '#0056b3', // Darker color on hover
  };
  const [logo, setLogo] = useState(localStorage.getItem("system_logo"));

  return (
    <div style={pageStyle}>
      <img src={logo} alt="Thank You!" style={imageStyle} />
      <h1 style={headingStyle}>
        <FontAwesomeIcon icon={faCheckCircle} style={iconStyle} />
        Thank You for Your Payment!
      </h1>
      <Link to="/home" style={backButtonStyle}>Back</Link>
    </div>
  );
};

export default ThankYouPage;