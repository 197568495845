import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';
import {
  Row,
  Col,
  Input,
  CardBody,
  Card
} from "reactstrap";
import Tooltip from '@mui/material/Tooltip';
import { CardHeader } from "reactstrap";

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";

import Alerts from "../../components/Alerts/alerts";
import { format } from 'date-fns';

import ItemService from "./Item.service";
import AuthService from "../Authentication/auth.service";

import DataTable from 'react-data-table-component';
import Styled from 'styled-components';
import PermissionAccess from '../../PermissionAccess/Access.js';
import { styled } from '@mui/material/styles';
const Image_URL = process.env.REACT_APP_IMAGEURL;


const StyledCard = styled(Card)({
  borderRadius: '12px',
  height: '100%',
  '& .card-header': {
    background: 'transparent',
    padding: '1rem 1.5rem',
    fontWeight: 600
  }
});


const ImageContainer = Styled.div`
  position: relative;
  display: inline-block;
`;

const SmallImage = Styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  transition: all 0.3s ease;
`;
const LargeImage = Styled.img`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 80%;
  max-height: 80%;
  z-index: 1000;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0,0,0,0.3);
`;

const Overlay = Styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.7);
  z-index: 999;
`;


const StatsNumber = styled('div')({
  fontSize: '24px',
  fontWeight: 600,
  color: '#2d3748',
  marginBottom: '4px'
});

const StatsLabel = styled('div')({
  fontSize: '14px',
  color: '#718096'
});

const DriversList = () => {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [Status, setStatus] = useState('');
  const [Type, setType] = useState('');
  const [Category, setCategory] = useState('');
  const [Business, setBusiness] = useState('');
  const [AllBusinesses, setAllBusinesses] = useState([]);
  const [AllCategories, setAllCategories] = useState([]);
  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState("");
  const [searchName, setsearchName] = useState('');
  const [fieldlist, setfieldlist] = useState([]);
  const [dashboardData, setDashboardData] = useState(null);

  const [isCategoryDisabled, setIsCategoryDisabled] = useState(true);

  const { authData, accessPages, super_admin, current_module } = PermissionAccess();
  const [dateRange, setDateRange] = useState([
    new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    new Date()
  ]);


  useEffect(() => {
    fetchDashboardData();
  }, []);

  const fetchDashboardData = async () => {
    try {
      setLoading(true);
      const stored_user = authData.userData;
      let business = []
      if (stored_user.businesses) {
        business = stored_user.businesses
      }
      const payload = {
        "functionName": "DashboardSummary",
        "postData": {
          "date_range": `${format(dateRange[0], 'yyyy/MM/dd')} - ${format(dateRange[1] || dateRange[0], 'yyyy/MM/dd')}`,
        }
      };
      const response = await ItemService.GetDashboardSummary(authData.sessionID, payload);
      setDashboardData(response.data.data);
      console.log("Dashboard summary received", response.data.data);
      // const dummyData = generateDummyData();
      // setDashboardData(dummyData);
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    } finally {
      setLoading(false);
    }
  };


  const ImageWithHover = ({ src, alt }) => {
    const [showLarge, setShowLarge] = useState(false);
    console.log("images src", src)
    return (
      <ImageContainer
        onMouseEnter={() => setShowLarge(true)}
        onMouseLeave={() => setShowLarge(false)}
      >
        {/* {alt} */}
        <SmallImage src={src} alt={alt} />
        {showLarge && (
          <>
            {/* <Overlay /> */}
            <LargeImage src={src} alt={alt} />
          </>
        )}
      </ImageContainer>
    );
  };


  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchData(0, pageLimit);
    fetchBusinesses();
  }, [pageLimit]);

  useEffect(() => {
    if (Business) {
      fetchCategories(Business);
      setIsCategoryDisabled(false);
    } else {
      setAllCategories([]);
      setCategory('');
      setIsCategoryDisabled(true);
    }
  }, [Business]);


  function fetchCategories(selectedBusiness) {
    const stored_user = authData.userData
    let business = []
    if (stored_user.businesses) {
      business = stored_user.businesses
    }
    var getData = {
      functionName: "GetCategories",
      postData: {
        sort: { _id: -1 },
        offset: 0,
        limit: 1000,
        "business_id": business,
        "business": selectedBusiness, // Use the selected business ID here
      },
    };
    setLoading(true);
    ItemService.GetCategories(getData, authData.sessionID).then((response) => {
      setLoading(false);
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        if (response.data) {
          setAllCategories(response.data.data);
        }
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  function fetchBusinesses() {
    const stored_user = authData.userData
    let business = []
    if (stored_user.businesses) {
      business = stored_user.businesses
    }

    var getData = {
      functionName: "GetBusinesses",
      postData: {
        sort: { _id: -1 },
        offset: 0,
        limit: 1000,
        business_ids: business,
      },
    };
    ItemService.GetBusinesses(getData, authData.sessionID).then((response) => {
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        if (response.data) {
          if (response.data.data.length === 1) {
            setBusiness(response.data.data[0]._id)
          }
          setAllBusinesses(response.data.data);
        }
      }
    },
      (error) => {
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };


  useEffect(() => {
    fetchDynamicData();
  }, []);



  const fetchDynamicData = async () => {
    try {
      const response = await ItemService.get_cruds_dynamic_data(authData.sessionID);
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        const data = await response.data;

        var custom_fields = data;
        for (var i = 0; i < custom_fields.length; i++) {
          if (custom_fields[i].options_collection !== '') {

            const optresponse = await ItemService.getoptions(custom_fields[i].options_collection, "", {}, 5000, authData.sessionID, authData.userData._id);
            const optdata = await optresponse.data.data;
            var options = [];

            for (var j = 0; j < optdata.length; j++) {
              options.push({ label: optdata[j][custom_fields[i].Options_Field_Name], value: optdata[j]._id, })
            }
            custom_fields[i].options = options;
          }
          custom_fields[i].default_value = ""
        }

        data[0].custom_fields = custom_fields;

        setfieldlist(data[0].custom_fields);
        var cust = data[0].custom_fields;
        var validations = {};
        for (var i = 0; i < cust.length; i++) {
          validations[cust[i].referal_name] = cust[i].is_required;
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  function fetchData(page_index, page_limit) {
    var filter = {};
    if (searchName !== "") {
      console.log("Search name:-", searchName)
      filter["name"] = searchName
    }
    if (Status !== "") {
      console.log("Search Status:-", Status)
      filter["status"] = Status
    }
    if (Type !== "") {
      console.log("Search Type:-", Type)
      filter["item_type"] = Type
    }
    var offset = (page_index - 1) * page_limit;
    if (offset < 1) {
      offset = 0;
    }
    const stored_user = JSON.parse(localStorage.getItem("user"));
    let business = []
    if (stored_user.businesses) {
      business = stored_user.businesses
    }
    let category = []
    if (Category !== "") {
      category.push(Category);
    }
    console.log("category", category)
    var getData = {
      functionName: "GetItems",
      postData: {
        "sort": { _id: -1 },
        "offset": offset,
        "limit": page_limit,
        "filter": filter,
        "business": business,
        "category": category,
      },
    }
    setLoading(true);
    ItemService.getAllDrivers(getData, authData.sessionID).then((response) => {
      setLoading(false);
      console.log("Get Drivers response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setDrivers(response.data.data);
        setTotalRows(response.data.count.count);
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  function handlePageChange(page_index) {
    console.log("Page index:-", page_index)
    setCurrentPage(page_index)
    fetchData(page_index, pageLimit);
  }

  async function handlePerRowsChange(newPageLimit, page_index) {
    setPageLimit(newPageLimit);
  }

  function nameFilter(e) {
    console.log(e.target.value);
    setsearchName(e.target.value)
  }

  function resetFilters() {
    setResetPaginationToggle(!resetPaginationToggle);
    setsearchName('');
    setStatus('');
    setType('');
    setCategory('');
    var offset = 0;
    const stored_user = JSON.parse(localStorage.getItem("user"));
    let business = []
    if (stored_user.businesses) {
      business = stored_user.businesses
    }
    var getData = {
      functionName: "GetItems",
      postData: {
        "sort": { _id: -1 },
        "offset": offset,
        "limit": pageLimit,
        "filter": {},
        "business": business
      },
    }
    setLoading(true);
    ItemService.getAllDrivers(getData, authData.sessionID).then((response) => {
      setLoading(false);
      console.log("Get Drivers response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setDrivers(response.data.data);
        setTotalRows(response.data.count.count);
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  }

  function searchItems() {
    setResetPaginationToggle(!resetPaginationToggle);
    var page = 0
    handlePageChange(page++)
  }


  function confirmDeleteItems(_id) {

    let msg = t("Once deleted, you will not be able to recover this Item!")
    Alerts.swalConfirmDeleteAlert(msg).then(res => {
      console.log("Resp:-", res)
      if (res === true) {
        sendDelete(_id)
      }
    })
  };


  function sendDelete(id) {
    setLoading(true);
    ItemService.deleteDriver(id, authData.sessionID).then((response) => {
      setLoading(false);
      console.log("Delete Item response:-", response)
      if (response.data.status) {
        fetchData(0, pageLimit)
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Item Deleted Successfully."))
        }
        console.log(response.data.message)
      }
      else {
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't delete the driver at the moment."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Delete operation failed"))
      })
  };

  let mycolumns = [];

  mycolumns.push(
    {
      name: t("Name"),
      selector: row => row.item_name,
    },
    {
      name: t("Model Name"),
      selector: row => row.item_code,
    },
    {
      name: t("Picture"),
      selector: row => <ImageWithHover src={row?.index ? `${Image_URL}${row?.images?.[row.index]}` : `${Image_URL}${row?.images?.[0]}`} alt={'view'} />,
    },
    {
      name: t("Price"),
      selector: row => `₱${parseFloat(row.price).toFixed(2)}`,
    },
    {
      name: t("MPV"),
      selector: row => row.item_mpv,
    },
    {
      name: t("Category"),
      selector: row => row?.CategoryDetails?.[0]?.Name ? row?.CategoryDetails?.[0]?.Name : '',
    },
    {
      name: t("Quantity"),
      selector: row => row.totalStockQuantity ? row.totalStockQuantity : 'N/A',
    },
  );

  const filteredFields = fieldlist.filter(field => field.data_table === '1');

  mycolumns = mycolumns.concat(filteredFields.map((field, index) => ({
    name: t(`${field.field_lable}`),
    selector: (row) => row[field.referal_name]
  })));

  mycolumns.push(
    {
      name: t('Actions'),
      selector: (row) => {
        return (
          <div>
            <button className="mt-2 mb-2 me-2 btn-icon btn">
              {!super_admin ? current_module?.ViewDetails === 1 &&
                <Tooltip placement="top" title={`${t("Item")} ${t("Details")}`} arrow>
                  <Link to="/item-details" state={row}>
                    <i className="pe-7s-note2 btn-icon-wrapper text-info"> </i>
                  </Link>
                </Tooltip >
                :
                <Tooltip placement="top" title={`${t("Item")} ${t("Details")}`} arrow>
                  <Link to="/item-details" state={row}>
                    <i className="pe-7s-note2 btn-icon-wrapper text-info"> </i>
                  </Link>
                </Tooltip >
              }
              {!super_admin ? current_module?.DeleteData === 1 &&
                <Tooltip placement="top" title="Delete" arrow>
                  <i onClick={() => confirmDeleteItems(row._id)} className="pe-7s-trash text-danger btn-icon-wrapper"> </i>
                </Tooltip >
                :
                <Tooltip placement="top" title="Delete" arrow>
                  <i onClick={() => confirmDeleteItems(row._id)} className="pe-7s-trash text-danger btn-icon-wrapper"> </i>
                </Tooltip >
              }
              {!super_admin ? current_module?.EditData === 1 &&
                <Tooltip placement="top" title="Edit" arrow>
                  <i>
                    <Link to="/update-Items" state={row}>
                      <i className="pe-7s-pen btn-icon-wrapper text-primary"> </i>
                    </Link>
                  </i>
                </Tooltip >
                :
                <Tooltip placement="top" title="Edit" arrow>
                  <i>
                    <Link to="/update-Items" state={row}>
                      <i className="pe-7s-pen btn-icon-wrapper text-primary"> </i>
                    </Link>
                  </i>
                </Tooltip >
              }
            </button>
          </div>
        );
      }
    }
  );
  //for search with enter keypress
  function checkenterkey(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      setResetPaginationToggle(!resetPaginationToggle);
      var page = 0
      handlePageChange(page++)
    }
  };



  const InventoryOverview = ({ data }) => (
    <div>
      <CardHeader>Inventory Overview</CardHeader>
      <CardBody>
        <Row className="mb-4">
          <Col md="3">
            <StatsNumber>{data?.totalProducts || 0}</StatsNumber>
            <StatsLabel>Total Products</StatsLabel>
          </Col>
          <Col md="3">
            <StatsNumber>{data?.lowStockItems || 0}</StatsNumber>
            <StatsLabel>Low Stock Items</StatsLabel>
          </Col>
          <Col md="3">
            <StatsNumber>{data?.outOfStockItems || 0}</StatsNumber>
            <StatsLabel>Out of Stock</StatsLabel>
          </Col>
          <Col md="3">
            <StatsNumber>${data?.inventoryValue.toLocaleString() || 0}</StatsNumber>
            <StatsLabel>Inventory Value</StatsLabel>
          </Col>
        </Row>
        <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>No. #</th>
                <th>Product</th>
                <th>Sales</th>
                {/* <th>Profit</th> */}
                <th>Stock</th>
              </tr>
            </thead>
            <tbody>
              {data?.topProducts?.map((product, index) => (
                <tr key={product.id}>
                  <td>{`${index + 1}`}</td>
                  <td>{product.name}</td>
                  <td>{product?.sales?.toLocaleString()}</td>
                  {/* <td>${product.profit.toLocaleString()}</td> */}
                  <td>{product.stock}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </CardBody>
    </div>
  );


  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className=" bg-light-gray bg-animation ">
            <div className="d-flex  justify-content-center align-items-center">
              <Col md="12" className="mx-auto app-login-box">
                <div className="app-page-title">
                  <div className="page-title-wrapper">
                    <div className="page-title-heading fw-bold">
                      <div className="page-title-icon">
                        <i className="pe-7s-users icon-gradient bg-tempting-azure" />
                      </div>
                      <div>
                        {t("Product Management")}
                        <div className="page-title-subheading">
                          {t('View, Add, Edit and Delete Products.')}.
                        </div>

                      </div>
                    </div>
                    <div className="page-title-actions">
                      <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-success btn-hover-shine me-3">
                        {t("Back")}
                      </button>
                    </div>
                  </div>
                </div>
                <Row>




                  <Fragment>
                    <Row>
                      <Col md="12">
                        <Card className="main-card mb-3">
                          <CardBody>
                            {/* Inventory Overview */}
                            <Row className="mb-4">
                              <Col md="12">
                                <InventoryOverview data={dashboardData?.inventoryStats} />
                              </Col>
                            </Row>
                            <LoadingOverlay tag="div" active={loading}
                              styles={{ overlay: (base) => ({ ...base }) }}
                              spinner={<DNALoader />}>
                              <Row>

                                <Col md="2" >
                                  <Input
                                    type="text"
                                    name='name'
                                    value={searchName}
                                    placeholder='Search Text'
                                    onChange={(event) => nameFilter(event)}
                                  />
                                </Col>
                                <Col md="2">
                                  <Input
                                    type="select"
                                    style={{ borderColor: '#ccc' }}
                                    placeholder={t("Search Status")}
                                    value={Status}
                                    onChange={(e) => {
                                      console.log(e.target.value);
                                      setStatus(e.target.value);
                                    }}
                                    handleKeyDown={(event) => checkenterkey(event)}
                                    id="colfilter"
                                  >

                                    <option disabled={false} value="">Select Status</option>
                                    <option key='Active' value='Active'>Enabled</option>
                                    <option key='Inactive' value='Inactive'>Disabled</option>
                                  </Input>
                                </Col>
                                {/* <Col md="2">
                                  <Input
                                    type="select"
                                    style={{ borderColor: '#ccc' }}
                                    placeholder={t("Search Type")}
                                    value={Type}
                                    onChange={(e) => {
                                      console.log(e.target.value);
                                      setType(e.target.value);
                                    }}
                                    handleKeyDown={(event) => checkenterkey(event)}
                                    id="colfilter"
                                  >

                                    <option disabled={false} value="">Select Type</option>
                                    <option key='Service' value='Service'>Service</option>
                                    <option key='Goods' value='Goods'>Goods</option>
                                  </Input>
                                </Col> */}
                                {/* <Col md="2">
                                  <Input
                                    type="select"
                                    style={{ borderColor: '#ccc' }}
                                    placeholder={t("Search Business")}
                                    value={Business}
                                    onChange={(e) => {
                                      console.log(e.target.value);
                                      setBusiness(e.target.value);
                                    }}
                                    handleKeyDown={(event) => checkenterkey(event)}
                                    id="colfilter"
                                  >
                                    <option disabled={false} value="">Select Business</option>
                                    {AllBusinesses &&
                                      AllBusinesses.map((Businesses, index) => (
                                        <option key={index} value={Businesses._id}>{Businesses.name}</option>
                                      ))
                                    }
                                  </Input>
                                </Col> */}
                                <Col md="2">
                                  <Input
                                    type="select"
                                    style={{ borderColor: '#ccc' }}
                                    placeholder={t("Search Category")}
                                    value={Category}
                                    onChange={(e) => {
                                      console.log(e.target.value);
                                      setCategory(e.target.value);
                                    }}
                                    handleKeyDown={(event) => checkenterkey(event)}
                                    id="colfilter"
                                    disabled={isCategoryDisabled}
                                  >
                                    <option disabled={false} value="">Select Category</option>
                                    {AllCategories &&
                                      AllCategories.map((Categories, index) => (
                                        <option key={index} value={Categories._id}>{Categories.Name}</option>
                                      ))
                                    }
                                  </Input>
                                </Col>
                                <Col md="3">
                                  <button type="button" className="btn btn-custom-color btn-hover-shine" onClick={() => searchItems()}>{t("Search")}</button>
                                  <button type="button" className="btn btn-custom-color btn-hover-shine ms-1" onClick={() => resetFilters()}>{t("Reset")}</button>
                                  {!super_admin ? current_module?.AddData === 1 &&
                                    <a href="/add-Items" className="btn btn-custom-color btn-hover-shine ms-1">
                                      {t("Add")}</a>
                                    :
                                    <a href="/add-Items" className="btn btn-custom-color btn-hover-shine ms-1">
                                      {t("Add")}</a>
                                  }
                                </Col>
                              </Row>


                              <Col md="12" className="pt-3">
                                <DataTable
                                  data={drivers}
                                  columns={mycolumns}
                                  pagination
                                  fixedHeader
                                  fixedHeaderScrollHeight="400px"
                                  paginationServer
                                  paginationTotalRows={totalRows}
                                  onChangePage={handlePageChange}
                                  onChangeRowsPerPage={handlePerRowsChange}
                                  paginationResetDefaultPage={resetPaginationToggle}
                                />
                              </Col>

                            </LoadingOverlay>

                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Fragment>
                </Row>
              </Col>
            </div>
          </div>
        </div>
      </div>

    </Fragment>
  );
};

export default DriversList;