import axios from "axios";

const API_URL = process.env.REACT_APP_BASEURL;
const storeduser = JSON.parse(localStorage.getItem("user"));

const headers = {
  'Content-Type': 'application/json',
  'token': localStorage.getItem("session-id")
}

const getAll = (filter, sessionID) => {
  var payload = {
    functionName: "GetCollectionData",
    "postData": {
      "collection_name": "dynamic_modules",
      "filter": filter
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(API_URL, payload, { headers });
};

const fetchcollections = (sessionID) => {

  var payload = {
    functionName: "FetchCollections",
    "postData": {}
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(API_URL, payload, { headers });
}
const fetchEmails = () => {

  var payload = {
    functionName: "fetchEmails",
    "postData": {}
  }
  return axios.post(API_URL, payload, { headers });
}
const deleteEntity = (sessionID,storeduser_id, id, referal_name) => {
  var payload = {
    functionName: "DeleteCrud",
    postData: {
      "_id": id,
      "referal_name": referal_name,
      "login_user_id": storeduser_id
    },
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(API_URL, payload, { headers });
};


const addEntity = (sessionID,payload) => {
  console.log("Received addEntity Payload:-", payload)
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(API_URL, payload, { headers });
}


const UpdateEntity = (sessionID,payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  console.log("Received addEntity Payload:-", payload)
  return axios.post(API_URL, payload, { headers });
}

const fetchFunctionNames = (sessionID) => {
  var payload = {
    functionName: "FetchFunctionNames",
    "postData": {}
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(API_URL, payload, { headers });
}

const EntitiesService = {
  getAll,
  deleteEntity,
  addEntity,
  UpdateEntity,
  fetchEmails,
  fetchcollections,
  fetchFunctionNames
}

export default EntitiesService;
