import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";
import AlertService from "../../components/Alerts/alerts";
import {
  Typography, Chip, Grid, Paper, Box, Table,
  TableBody, TableCell, TableContainer, TableRow, IconButton,
  Card, CardMedia, Divider
} from '@mui/material';
import { styled } from '@mui/material/styles';
import RoomIcon from '@mui/icons-material/Room';
import InventoryIcon from '@mui/icons-material/Inventory';
import CategoryIcon from '@mui/icons-material/Category';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BusinessIcon from '@mui/icons-material/Business';
import DescriptionIcon from '@mui/icons-material/Description';
import StarIcon from '@mui/icons-material/Star';
import ItemService from "./Item.service.js";
import AuthService from "../Authentication/auth.service";
import { useNavigate } from 'react-router-dom';
import Alerts from "../../components/Alerts/alerts";
import PermissionAccess from '../../PermissionAccess/Access.js';
const Image_URL = process.env.REACT_APP_IMAGEURL;

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(0, 3, 3, 3),
  margin: theme.spacing(3, 0),
  backgroundColor: theme.palette.background.default,
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
  backgroundColor: theme.palette.action.hover,
}));

const ImageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(2),
  marginTop: theme.spacing(2),
}));

const ItemImage = styled(CardMedia)(({ theme }) => ({
  width: 150,
  height: 150,
  objectFit: 'cover',
  cursor: 'pointer',
  borderRadius: theme.shape.borderRadius,
  position: 'relative',
  transition: 'transform 0.3s ease-in-out, border 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
  },
  '&.selected': {
    transform: 'scale(1.15)',
    border: `2px solid ${theme.palette.warning.main}`,
    boxShadow: theme.shadows[5],
  },
}));

const StarIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: 8,
  right: 8,
  width: 32,
  height: 32,
  color: theme.palette.common.white,
  '&.active': {
    color: theme.palette.warning.main,
  },
  // backgroundColor: theme.palette.warning.main,
  '&.active': {
    backgroundColor: theme.palette.warning.dark,
  }
}));


const CustomerBasicInfo = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { state } = location;
  const [loading, setLoading] = useState(false);
  const [selectedStar, setSelectedStar] = useState(state.index);
  const navigate = useNavigate();
  const { authData, accessPages, super_admin, current_module } = PermissionAccess();

  const handleLinkClick = () => {
    if (state.address_url) {
      window.open(state.address_url, '_blank', 'noopener noreferrer');
    } else if (state.address) {
      const addressText = encodeURIComponent(state.address);
      const mapsURL = `https://www.google.com/maps/search/?api=1&query=${addressText}`;
      window.open(mapsURL, '_blank', 'noopener noreferrer');
    } else {
      AlertService.swalErrorAlert("Please Enter a Valid Address URL")
    }
  };

  const handleImageClick = (index) => {
    setSelectedStar(index);
    console.log("Image clicked", index);
    setLoading(true);
    let Index = 0;
    if (index === 0) {
      Index = 0
    } else {
      Index = index;
    }
    const payload = {
      functionName: 'update_featured_image',
      _id: state._id,
      index: Index
    }
    ItemService.update_featured_image(payload, authData.sessionID).then((response) => {

      setLoading(false);
      if (response.data.status) {
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Data added successfully."));
        }
      }
      else {
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't add data at the moment. Please try again later."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Network Operation failed."))
      });
  };

  const renderInfoRow = (icon, label, value) => (
    <TableRow>
      <StyledTableCell>
        <Box display="flex" alignItems="center">
          {icon}
          <Typography variant="body1" style={{ marginLeft: 8 }}>{t(label)}</Typography>
        </Box>
      </StyledTableCell>
      <TableCell>{value}</TableCell>
    </TableRow>
  );

  return (
    <LoadingOverlay
      active={loading}
      styles={{ overlay: (base) => ({ ...base }) }}
      spinner={<DNALoader />}
    >
      <StyledPaper elevation={1}>
        <Grid container spacing={4}>
          <Grid item xs={24} md={12}>
            <Typography variant="h6" gutterBottom>
              {t('Basic Information')}
            </Typography>
            <TableContainer>
              <Table>
                <TableBody>
                  {renderInfoRow(<InventoryIcon />, 'Name', state.item_name)}
                  {renderInfoRow(<InventoryIcon />, 'Model Name', state.item_code)}
                  {renderInfoRow(<AttachMoneyIcon />, 'Price', `₱${state.price}`)}
                  {renderInfoRow(<AttachMoneyIcon />, 'MPV', state.item_mpv)}
                  {renderInfoRow(<CategoryIcon />, 'Category', state?.CategoryDetails?.[0]?.Name)}
                  {renderInfoRow(<InventoryIcon />, 'Status',
                    <Chip
                      label={state.status}
                      color={state.status === 'Active' ? 'success' : 'error'}
                      size="small"
                    />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </StyledPaper>

      <StyledPaper elevation={3}>
        <Typography variant="h6" gutterBottom>
          <Box display="flex" alignItems="center">
            <DescriptionIcon style={{ marginRight: 8 }} />
            {t('Description')}
          </Box>
        </Typography>
        <Divider style={{ margin: '16px 0' }} />
        <Typography variant="body1">
          {state.description || "No description available."}
        </Typography>
      </StyledPaper>

      <StyledPaper elevation={3}>
        <Typography variant="h6" gutterBottom>
          {t('Picture')}
        </Typography>
        <Divider style={{ margin: '16px 0' }} />
        <ImageContainer>
          {state.images.map((image, index) => (
            <Card key={index} style={{ maxWidth: 150 }}>
              <Box position="relative" onClick={() => handleImageClick(index)}>
                <ItemImage
                  component="img"
                  image={`${Image_URL}${image}`}
                  alt={`Item image ${index + 1}`}
                  className={selectedStar === index ? 'selected' : ''}
                />
                <StarIconButton
                  className={selectedStar === index ? 'active' : ''}
                >
                  <StarIcon />
                </StarIconButton>
              </Box>
            </Card>
          ))}
        </ImageContainer>
      </StyledPaper>
    </LoadingOverlay>
  );
};

export default CustomerBasicInfo;
