import React, { useEffect, useState } from "react";
import CommonService from "../../../pages/Entities/entities.service";
import AuthService from "../../../pages/Authentication/auth.service";
import Alerts from "../../Alerts/alerts";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import styled from "styled-components";
import { Row, Col } from 'react-bootstrap';
import { Input } from 'reactstrap';

import DateRangePickerComponent from '../InvoiceFilter/DateRangePickerComponent';


const Inputs = styled.select.attrs(props => ({
  type: "select",
  size: props.small ? 5 : undefined
}))`
  height: 32px;
  width: 16%;
  border-radius: 6px;
  border: 1px solid var(--buttons-color, #30b1ff);
  padding: 0 8px 0 8px;
  margin-bottom: 12px;
  justify-content: flex-start;
  background: white;
  margin-right: 20%;
`;
const Label = styled.label`
  display: flex;
  font-weight: bold;
`;
const ClearButton = styled.button`
  border:none;
  height: 32px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20%;
  margin-bottom: 12px;
  border-radius: 0 5px 5px 0;
  color: white;
  background: var(--buttons-color, #30b1ff);
`;

const FunctionFilter = ({ onFilterInvoice_StartDate, onFilterInvoicdue_date }) => {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
  }, []);

  const handleDateChange_Invoice = (startDateFormatted, endDateFormatted) => {
    console.log("startDateFormatted" , startDateFormatted);
    console.log("endDateFormatted" , endDateFormatted);
    onFilterInvoice_StartDate(startDateFormatted);
    onFilterInvoicdue_date(endDateFormatted);
  };

  return (
    <>
      <div style={{
        height: '32px',
        borderRadius: "6px",
        border: "1px solid var(--buttons-color, #30b1ff)",
        padding: "0 8px 0 8px",
        marginBottom: "12px",
        justifyContent: "flex-start",
        background: "white",
        marginRight: "12px",
        background: '#F9F9FB',
        width: '22%',
        display: 'flex',
        alignItems: 'center'
      }}>
        <Label style={{ background: '#F9F9FB', paddingRight: '10px' }}>
          {t('Date')}
        </Label>
        <div className="d-flex justify-content-end align-items-center h-100 pr-2">
          <div style={{ borderRight: '2px solid #ccc', height: '4%' }}></div>
        </div>
        <DateRangePickerComponent
          // value={filterText3}
          // onChange={handleDateChange_Invoice}
          // onKeyDown={handleKeyDown}
          id="datefilter"
          onDateChange={handleDateChange_Invoice} />
      </div>
    </>
  );

};

export default FunctionFilter;


