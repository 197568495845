import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, Navigate } from "react-router-dom";
import { Row, Col, FormGroup, Input, Button, Label, Modal, ModalHeader, ModalBody, Card, CardBody } from "reactstrap";
import ReactQuill from 'react-quill'; // Import React-Quill
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import Services from "./services";
import AuthService from "../Authentication/auth.service";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import Alerts from "../../components/Alerts/alerts";
import PermissionAccess from '../../PermissionAccess/Access.js';

const UpdateBulkEmail = ({ BulkEmail, onSuccess, onCancel }) => {
  const [modal, setModal] = useState(true);
  const [updatedBulkEmail, setUpdatedBulkEmail] = useState({ ...BulkEmail });
  console.log("updatedBulkEmail", updatedBulkEmail)
  const [loading, setLoading] = useState(false);
  const [loginNavigate, setLoginNavigate] = useState(false);
  const { authData } = PermissionAccess();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
  }, [i18n]);

  const handleInputChange = (field, value) => {
    setUpdatedBulkEmail(prev => ({ ...prev, [field]: value }));
  };

  const validateForm = () => {
    return updatedBulkEmail.subject &&
      updatedBulkEmail.recipients &&
      updatedBulkEmail.email_body &&
      updatedBulkEmail.date_time;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      Alerts.swalErrorAlert("Please fill all fields for the Bulk Email.");
      return;
    }

    const formData = {}

    Object.keys(updatedBulkEmail).forEach(key => {
      formData[key] = updatedBulkEmail[key]
    });

    setLoading(true);
    try {
      const response = await Services.UpdateBulkEmails(authData.sessionID, updatedBulkEmail._id, formData);
      setLoading(false);
      if (response.data.status) {
        onSuccess();
        Alerts.swalSuccessAlert(response.data.message || "Bulk Email Updated Successfully.").then(() => {
          setModal(false);
        });
      } else {
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          setLoginNavigate(true);
        } else {
          Alerts.swalErrorAlert(response.data.message || "Error: Can't Update Bulk Email at the moment. Please try again later.");
        }
      }
    } catch (error) {
      setLoading(false);
      Alerts.swalErrorAlert("Network Operation failed.");
    }
  };

  if (loginNavigate) {
    return <Navigate to={'/login'} />;
  }

  return (
    <Modal size='lg' isOpen={modal} toggle={() => setModal(false)} backdrop={true}>
      <LoadingOverlay active={loading} spinner={<DNALoader />}>
        <ModalHeader>{t("Update Bulk Email")}</ModalHeader>
        <ModalBody>
          <Card className="main-card mb-3">
            <CardBody>
              <form onSubmit={handleSubmit}>
                <FormGroup className="mb-3">
                  <Row>
                    <Col md="12">
                      <Label>{t("Email Subject")}</Label>
                      <Input
                        type="text"
                        value={updatedBulkEmail.subject}
                        onChange={(e) => handleInputChange('subject', e.target.value)}
                        placeholder={t("Enter subject")}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md="6">
                      <Label>{t("Schedule Date Time")}</Label>
                      <Input
                        type="datetime-local"
                        value={updatedBulkEmail.date_time}
                        onChange={(e) => handleInputChange('date_time', e.target.value)}
                      />
                    </Col>
                    <Col md="6">
                      <Label>{t("Recipients")}</Label>
                      <Input
                        type="select"
                        value={updatedBulkEmail.recipients}
                        onChange={(e) => handleInputChange('recipients', e.target.value)}
                      >
                        <option value="" disabled>{t("Select recipients")}</option>
                        <option value="Members">{t("Members")}</option>
                        <option value="customers">{t("Customers")}</option>
                        <option value="all">{t("All")}</option>
                      </Input>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md="12">
                      <Label>{t("Email Body")}</Label>
                      <ReactQuill
                        value={updatedBulkEmail.email_body}
                        onChange={(content) => handleInputChange('email_body', content)}
                        modules={{
                          toolbar: [
                            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                            [{ size: [] }],
                            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                            [{ 'list': 'ordered' }, { 'list': 'bullet' },
                            { 'indent': '-1' }, { 'indent': '+1' }],
                            ['link', 'image'],
                            ['clean']
                          ],
                        }}
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <div className="text-center mt-3">
                  <Button type="submit" color="success" size="lg" className="me-3">
                    {t("Update Scheduled Email")}
                  </Button>
                  <Button type="button" color="secondary" size="lg" onClick={() => { setModal(false); onCancel(); }}>
                    {t("Cancel")}
                  </Button>
                </div>
              </form>
            </CardBody>
          </Card>
        </ModalBody>
      </LoadingOverlay>
    </Modal>
  );
};

export default UpdateBulkEmail;