import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import BusinessService from "./Businesses.service";
import AuthService from "../Authentication/auth.service";
import Alerts from "../../components/Alerts/alerts";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, CardBody, Card } from "reactstrap";

const AddBusinessModal = ({ showModalAddBusiness, handleToggleModalAddBusiness, onSucess }) => {
  
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  

  const [data, setData] = useState({
    name: '',
    email: '',
    status: '',
  });

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    status: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    let error = '';

    if (name === 'name') {
      if (!value) {
        error = 'Name is required';
      }
    }

    if (name === 'email') {
        if (!value) {
          error = 'Email is required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
          error = 'Should be a valid email address';
        }
      }

    if (name === 'status') {
      if (!value) {
        error = 'Status is required';
      }
    }

    setErrors({ ...errors, [name]: error });
    setData({ ...data, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validationErrors = {};

    if (!data.name) { validationErrors.name = 'Name is required'; }
    if (!data.email) { validationErrors.email = 'Email is required'; }
    if (!data.status) { validationErrors.status = 'Status is required'; }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setLoading(true);
      BusinessService.AddBusiness(data).then((response) => {
        setLoading(false);
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        } else {
          if (response.data.status === true) {
            resetForm();
            handleToggleModalAddBusiness();
            Alerts.swalSuccessAlert(t('Business added successfully.'));
            onSucess();
            validationErrors.name = '';
            validationErrors.email = '';
          }
          else {
            if (response.data.errors && response.data.errors.length > 0) {
              const validationErrors = {};
              response.data.errors.forEach(error => {
                  validationErrors[error.field] = error.message;
              });
              setErrors(validationErrors);
          }
          }
        }
      }, (error) => {
        setLoading(false);
        const errContent = (error.response && error.response.data) || error.message || error.toString();
        if (errContent) {
          Alerts.swalErrorAlert(errContent);
        } else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."));
        }
      });
    }
  };

  const resetForm = () => {
    setData({
        name: '',
        email: '',
        status: '',
    });
  };

  

  const handleClose = () => {
    setErrors({
        name: '',
        email: '',
        status: '',
      });
      setData({
        name: '',
        email: '',
        status: '',
      });
    handleToggleModalAddBusiness();
  };

  return (
    
    <div className={showModalAddBusiness ? "modal d-block mt-4" : "modal"} style={{ display: showModalAddBusiness ? 'block' : 'none', backgroundColor: 'rgba(0, 0, 0, 0.5)' }} tabIndex="-1" role="dialog">
      <form onSubmit={handleSubmit}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Add Business</h5>
            </div>
            <div className="modal-body">
            <Col md="12">
                <Card className="main-card mb-3">
                     <CardBody>
                      <LoadingOverlay
                        tag="div"
                        active={loading}
                        styles={{ overlay: (base) => ({ ...base }) }}
                        spinner={<DNALoader />}
                      >
                
                  <div className="form-group">
                  <label htmlFor="value" className="col-form-label">{t("Name")}:</label>
                  <input type="text" className="form-control" placeholder="Enter Name"
                  name="name"
                  onChange={handleChange}
                  value={data.name}
                  />
                  {errors.name && <strong className="error text-danger">{errors.name}</strong>}
                </div>

                <div className="form-group">
                  <label htmlFor="value" className="col-form-label">{t("Email")}:</label>
                  <input type="email" className="form-control" placeholder="Enter Email"
                  name="email"
                  onChange={handleChange}
                  value={data.email}
                  />
                  {errors.email && <strong className="error text-danger">{errors.email}</strong>}
                </div>
                <div className="form-group">
                  <label htmlFor="section-select" className="col-form-label">{t("Satus")}:</label>
                  <select className="form-control"
                    name="status"
                    onChange={handleChange}
                    value={data.status}
                  >
                      <option value="">Select Status</option>
                      <option value="Active">Active</option>
                      <option value="InActive">InActive</option>
                  </select>
                  {errors.status && <strong className="error text-danger">{errors.status}</strong>}
                </div>

                  <div className="text-center mt-4">
                    <button type="button" className="btn btn-outline-light btn-hover-shine me-3" onClick={handleClose}>{t("Close")}</button>
                    <button type="submit" className="btn btn-custom-color">{t("Save")}</button>
                </div>
                                            </LoadingOverlay>
                                        </CardBody>
                                    </Card>
                                </Col>
            </div>
            
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddBusinessModal;
