import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from "react-router-dom";
import { Col, Row, Label, FormGroup, Input, Card, CardBody } from "reactstrap";
import { Form, Field } from "react-final-form";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";
import Alerts from "../../components/Alerts/alerts";

import { SketchPicker } from "react-color";

import ConfigurationService from "./configuration.service";
import AuthService from "../Authentication/auth.service";
import PermissionAccess from '../../PermissionAccess/Access.js';



const TabGenralConfig = () => {

  // localStorage.setItem("buttons_color", '#3ac47d');

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [B64converted, setB64converted] = useState("");
  const [lhdn_submission_time, setLhdn_submission_time] = useState("");
  const [sync_lhdn_status, setSync_lhdn_status] = useState("");

  const [displayColorPicker1, setdisplayColorPicker1] = useState(false);
  const [sketchPickerColor1, setSketchPickerColor1] = useState(localStorage.getItem("buttons_color"));
  const color1 = sketchPickerColor1;

  const [displayColorPicker2, setdisplayColorPicker2] = useState(false);
  const [sketchPickerColor2, setSketchPickerColor2] = useState(localStorage.getItem("topbar_color"));
  const color2 = sketchPickerColor2;

  const [displayColorPicker3, setdisplayColorPicker3] = useState(false);
  const [sketchPickerColor3, setSketchPickerColor3] = useState(localStorage.getItem("leftbar_color"));
  const color3 = sketchPickerColor3;

  const [displayColorPicker4, setdisplayColorPicker4] = useState(false);
  const [sketchPickerColor4, setSketchPickerColor4] = useState(localStorage.getItem("buttons_text_color"));
  const color4 = sketchPickerColor4;


  const [configs, setConfig] = useState([]);
  const { t, i18n } = useTranslation();
  const { authData, accessPages, super_admin, current_module } = PermissionAccess();

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    getSystemConfig();
  }, []);

  function getSystemConfig() {
    ConfigurationService.getConfiguration(authData.sessionID).then((response) => {
      console.log("API Response:-", response);
      if (response.data.status) {
        const [rec_data] = response.data.data;
        setConfig(rec_data);
        setColors(response.data.data[0].buttons_color, '--buttons-color');
        setColors(response.data.data[0].topbar_color, '--topbar-color');
        setColors(response.data.data[0].leftbar_color, '--leftbar-color');
        setColors(response.data.data[0].buttons_text_color, '--buttons-text-color');
        setSystemLogo(response.data.data[0].system_logo);
        setLhdn_submission_time(response.data.data[0].lhdn_submission_time)
        setSync_lhdn_status(response.data.data[0].sync_lhdn_status)
      }
      else {
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        }
        else {
          setColors('#3ac47d', 'onerror');
          alert(t("Unable to retreive configurations at the moment."))
        }
      }
    },
      (error) => {
        setColors('#3ac47d', 'onerror');
        alert(t("Unable to retreive configurations at the moment."))
      });
  }

  function setColors(color, param) {
    if (param === '--buttons-color') {
      localStorage.setItem("buttons_color", color);
      setSketchPickerColor1(color)
      document.documentElement.style.setProperty('--buttons-color', color)
    }
    if (param === '--topbar-color') {
      localStorage.setItem("topbar_color", color);
      setSketchPickerColor2(color)
      document.documentElement.style.setProperty('--topbar-color', color)
    }
    if (param === '--leftbar-color') {
      localStorage.setItem("leftbar_color", color);
      setSketchPickerColor3(color)
      document.documentElement.style.setProperty('--leftbar-color', color)
    }
    if (param === '--buttons-text-color') {
      localStorage.setItem("buttons_text_color", color);
      setSketchPickerColor4(color)
      document.documentElement.style.setProperty('--buttons-text-color', color)
    }
    if (param === 'onerror') {
      localStorage.setItem("buttons_color", color);
      localStorage.setItem("topbar_color", '#fff');
      localStorage.setItem("leftbar_color", '#fff');
      localStorage.setItem("buttons_text_color", '#fff');
      document.documentElement.style.setProperty('--buttons-color', color)
      document.documentElement.style.setProperty('--topbar-color', '#fff')
      document.documentElement.style.setProperty('--leftbar-color', '#fff')
      document.documentElement.style.setProperty('--buttons-text-color', '#fff')
    }
  }

  function setSystemLogo(sys_logo) {
    localStorage.setItem("system_logo", sys_logo);
  }

  const onChangelogo = e => {
    const files = e.target.files;
    const file = files[0];
    getBase64(file).then(result => {
      const convertedlogo = result;
      setB64converted(convertedlogo)
      console.log("Final Converted:-", convertedlogo)
    });
  }

  function getBase64(file) {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
    });
  };



  function handleSave(values) {
    console.log("Form Values:-", values);
    localStorage.setItem("buttons_color", sketchPickerColor1);
    document.documentElement.style.setProperty('--buttons-color', sketchPickerColor1);
    localStorage.setItem("topbar_color", sketchPickerColor2);
    document.documentElement.style.setProperty('--topbar-color', sketchPickerColor2);
    localStorage.setItem("leftbar_color", sketchPickerColor3);
    document.documentElement.style.setProperty('--leftbar-color', sketchPickerColor3);
    localStorage.setItem("buttons_text_color", sketchPickerColor4);
    document.documentElement.style.setProperty('--buttons-text-color', sketchPickerColor4);
    var payloadData = {
      functionName: "SetConfigurations",
      postData: {
        name: values.name,
        email: values.email,
        syslab_share: values.syslab_share,
        login_email: values.login_email,
        buttons_color: sketchPickerColor1,
        topbar_color: sketchPickerColor2,
        leftbar_color: sketchPickerColor3,
        buttons_text_color: sketchPickerColor4,
        lhdn_submission_time: lhdn_submission_time,
        // sync_lhdn_status: sync_lhdn_status,
        sync_lhdn_status: sync_lhdn_status === 'custom' ? customSyncLhdnStatus : sync_lhdn_status,
        // system_logo: B64converted
      },
    }
    if (B64converted) {
      payloadData.postData['system_logo'] = B64converted;
    }
    ConfigurationService.setConfiguration(authData.sessionID, payloadData).then((response) => {
      console.log("API Response:-", response);
      setLoading(false);
      if (response.data.status) {
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Configuration updated successfully."));
        }
        getSystemConfig();
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
      else {
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't update configuration at the moment. Please try again later."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        console.log("Error:-", error)
        Alerts.swalErrorAlert(t("Network Operation failed."))
      });
  }

  const validateFtn = (values) => {
    const errors = {};
    function validateEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
    if (!values.name) {
      errors.name = t("System Name Required.");
    }
    if (!values.email) {
      errors.email = t("Email Required.");
    }
    if (!values.login_email) {
      errors.login_email = t("Login Email Required.");
    }
    else if (!validateEmail(values.email)) {
      errors.email = t("Please enter a valid email address.");
    }
    return errors;
  };

  const colorPicker = (val) => {
    if (val === '1') {
      setdisplayColorPicker1(true);
    }
    if (val === '2') {
      setdisplayColorPicker2(true);
    }
    if (val === '3') {
      setdisplayColorPicker3(true);
    }
    if (val === '4') {
      setdisplayColorPicker4(true);
    }
  };

  const handleClose = (val) => {
    if (val === '1') {
      setdisplayColorPicker1(false);
    }
    if (val === '2') {
      setdisplayColorPicker2(false);
    }
    if (val === '3') {
      setdisplayColorPicker3(false);
    }
    if (val === '4') {
      setdisplayColorPicker4(false);
    }
  };

  const popover = {
    position: "absolute",
    zIndex: "2",
  };
  const cover = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  };


  const [customLhdnSubmissionTime, setCustomLhdnSubmissionTime] = useState('');
  const [customSyncLhdnStatus, setCustomSyncLhdnStatus] = useState('');
  const [showCustomCronModal, setShowCustomCronModal] = useState(false);
  const [currentCustomField, setCurrentCustomField] = useState('');
  
  // Add this function to handle custom cron input
  const handleCustomCronInput = (field) => {
    setCurrentCustomField(field);
    setShowCustomCronModal(true);
  };
  
  // Add this function to save the custom cron input
  const saveCustomCronInput = (customCronString) => {
    if (currentCustomField === 'lhdn_submission_time') {
      setLhdn_submission_time(customCronString);
      setCustomLhdnSubmissionTime(customCronString);
    } else if (currentCustomField === 'sync_lhdn_status') {
      setSync_lhdn_status(customCronString);
      setCustomSyncLhdnStatus(customCronString);
    }
    setShowCustomCronModal(false);
  };
  
  // Add a modal component for custom cron input
  const CustomCronModal = () => {
    const [customCronString, setCustomCronString] = useState('');
  
    return (
      <Modal isOpen={showCustomCronModal} toggle={() => setShowCustomCronModal(false)}>
        <ModalHeader toggle={() => setShowCustomCronModal(false)}>Enter Custom Cron Schedule</ModalHeader>
        <ModalBody>
          <Input
            type="text"
            placeholder="e.g., */15 * * * *"
            value={customCronString}
            onChange={(e) => setCustomCronString(e.target.value)}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setShowCustomCronModal(false)}>Cancel</Button>
          <Button color="primary" onClick={() => saveCustomCronInput(customCronString)}>Save</Button>
        </ModalFooter>
      </Modal>
    );
  };
  
  


  return (
    <Fragment>
      {showCustomCronModal && <CustomCronModal />}
      <Card className="main-card mb-3">
        <CardBody className="col-md-8">
          <Form
            initialValues={configs}
            validate={validateFtn}
            onSubmit={handleSave}
            FormState
            render={({ handleSubmit, values, submitting, validating, valid, form: { getState } }) => (
              <form onSubmit={handleSubmit}>
                <LoadingOverlay tag="div" active={loading}
                  styles={{ overlay: (base) => ({ ...base }) }}
                  spinner={<DNALoader />}>
                  <Row form>
                    <Col md={12}>
                      <FormGroup>
                        <Field name="name">
                          {({ input, meta }) => (
                            <div>
                              <div className="row mb-3">
                                <Label for="name" className="col-sm-2 col-form-label">{t('System Name')}</Label>
                                <div className="col-sm-10">
                                  <Input {...input} type="text"
                                    invalid={meta.error && meta.touched}
                                    placeholder={t('System Name')}
                                  />
                                  {meta.error && meta.touched &&
                                    <span className="text-danger">{meta.error}</span>}
                                </div>
                              </div>
                            </div>
                          )}
                        </Field>
                      </FormGroup>
                      <FormGroup>
                        <Field name="email">
                          {({ input, meta }) => (
                            <div>
                              <div className="row mb-3">
                                <Label for="email" className="col-sm-2 col-form-label">{t('Admin Email')}</Label>
                                <div className="col-sm-10">
                                  <Input {...input} type="email"
                                    invalid={meta.error && meta.touched}
                                    placeholder={t('Admin Email')}
                                  />
                                  {meta.error && meta.touched &&
                                    <span className="text-danger">{meta.error}</span>}
                                </div>
                              </div>
                            </div>
                          )}
                        </Field>
                      </FormGroup>
                      <FormGroup>
                        <Field name="lhdn_submission_time">
                          {({ input, meta }) => (
                            <div>
                              <div className="row mb-3">
                                <Label for="temp_subject" className="col-sm-2 col-form-label">{t('LHDN Submission Time')}</Label>
                                <div className="col-sm-10">
                                  <select {...input}
                                    value={lhdn_submission_time}
                                    onChange={event => setLhdn_submission_time(event.target.value)}
                                    className="form-select" name="is_required"
                                  >
                                    <option disabled={true} value="">{t('--Choose an timing--')}</option>
                                    <option key="0" value="0">{t('Immediately')}</option>
                                    <option key="5" value="5">{t('After Five Minutes')}</option>
                                    <option key="30" value="30">{t('After Thirty Minutes')}</option>
                                    <option key="60" value="60">{t('After an Hour')}</option>
                                    <option key="720" value="720">{t('After Twelve Hour')}</option>
                                    <option key="1440" value="1440">{t('After a Day')}</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          )}
                        </Field>
                      </FormGroup>

                      <FormGroup>
                        <Field name="sync_lhdn_status">
                          {({ input, meta }) => (
                            <div>
                              <div className="row mb-3">
                                <Label for="temp_subject" className="col-sm-2 col-form-label">{t('Sync LHDN Status')}</Label>
                                <div className="col-sm-10">
                                  <select
                                    {...input}
                                    value={sync_lhdn_status}
                                    onChange={(event) => {
                                      setSync_lhdn_status(event.target.value);
                                      if (event.target.value === 'custom') {
                                        // Open a modal or expand a form for custom cron input
                                        handleCustomCronInput('sync_lhdn_status');
                                      }
                                    }}
                                    className="form-select"
                                    name="is_required"
                                  >
                                    <option disabled={true} value="">{t('--Choose a schedule--')}</option>
                                    <option value="* * * * *">{t('Every minute')}</option>
                                    <option value="*/5 * * * *">{t('Every 5 minutes')}</option>
                                    <option value="*/15 * * * *">{t('Every 15 minutes')}</option>
                                    <option value="0,30 * * * *">{t('Twice per hour')}</option>
                                    <option value="0 * * * *">{t('Once per hour')}</option>
                                    <option value="0 0,12 * * *">{t('Twice per day')}</option>
                                    <option value="0 0 * * *">{t('Once per day')}</option>
                                    <option value="0 0 * * 0">{t('Once per week')}</option>
                                    <option value="0 0 1 * *">{t('Once per month')}</option>
                                    <option value="custom">{t('Custom schedule')}</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          )}
                        </Field>
                      </FormGroup>

                      <FormGroup>
                        <Field name="login_email">
                          {({ input, meta }) => (
                            <div>
                              <div className="row mb-3">
                                <Label for="login_email" className="col-sm-2 col-form-label">{t('Login Alert')}</Label>
                                <div className="col-sm-10">
                                  <Input {...input} type="login_email"
                                    invalid={meta.error && meta.touched}
                                    placeholder={t('Login Alert')}
                                  />
                                  {meta.error && meta.touched &&
                                    <span className="text-danger">{meta.error}</span>}
                                </div>
                              </div>
                            </div>
                          )}
                        </Field>
                      </FormGroup>


                      <FormGroup>
                        <Field name="email">
                          {({ input, meta }) => (
                            <div>
                              <div className="row mb-3">
                                <Label for="email" className="col-sm-2 col-form-label">{t('Admin Email')}</Label>
                                <div className="col-sm-10">
                                  <Input {...input} type="email"
                                    invalid={meta.error && meta.touched}
                                    placeholder={t('Admin Email')}
                                  />
                                  {meta.error && meta.touched &&
                                    <span className="text-danger">{meta.error}</span>}
                                </div>
                              </div>
                            </div>
                          )}
                        </Field>
                      </FormGroup>




                      <FormGroup>
                        <Field name="syslab_share">
                          {({ input, meta }) => (
                            <div>
                              <div className="row mb-3">
                                <Label for="syslab_share" className="col-sm-2 col-form-label">{t('Syslab Share')}</Label>
                                <div className="col-sm-10">
                                  <Input {...input} type="number"
                                    invalid={meta.error && meta.touched}
                                    placeholder={t('Syslab Share')}
                                  />
                                  {meta.error && meta.touched &&
                                    <span className="text-danger">{meta.error}</span>}
                                </div>
                              </div>
                            </div>
                          )}
                        </Field>
                      </FormGroup>

                      <div className="row">
                        <Label for="name" className="col-sm-2 col-form-label">{t('Theme Colors')}</Label>
                        <div className="col-md-10">
                          <div className="row">
                            <div className="col-md-6">
                              <FormGroup>
                                <div>
                                  <div className="row mb-3">
                                    <Label for="name" className="col-sm-6 col-form-label">{t('Buttons-Background')}</Label>
                                    <div className="col-sm-3">
                                      <button className="btn btn-lg btn-outline-2x btn-outline-secondary me-3 mt-2"
                                        type="button"
                                        style={{ backgroundColor: color1 }}
                                        onClick={() => colorPicker('1')}>
                                      </button>
                                      {displayColorPicker1 ? (
                                        <div style={popover}>
                                          <div style={cover} onClick={() => handleClose('1')} />
                                          <SketchPicker className="mx-auto mb-4"
                                            onChange={(color) => {
                                              setSketchPickerColor1(color.hex);
                                            }}
                                            color={sketchPickerColor1}
                                          />
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </FormGroup>
                            </div>
                            <div className="col-md-6">
                              <FormGroup>
                                <div>
                                  <div className="row mb-3">
                                    <Label for="name" className="col-sm-5 col-form-label">{t('Buttons Text')}</Label>
                                    <div className="col-sm-3">
                                      <button className="btn btn-lg btn-outline-2x btn-outline-secondary me-3 mt-2"
                                        type="button"
                                        style={{ backgroundColor: color4 }}
                                        onClick={() => colorPicker('4')}>
                                      </button>
                                      {displayColorPicker4 ? (
                                        <div style={popover}>
                                          <div style={cover} onClick={() => handleClose('4')} />
                                          <SketchPicker className="mx-auto mb-4"
                                            onChange={(color) => {
                                              setSketchPickerColor4(color.hex);
                                            }}
                                            color={sketchPickerColor4}
                                          />
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </FormGroup>
                            </div>

                            <div className="col-md-6">
                              <FormGroup >
                                <div>
                                  <div className="row mb-3">
                                    <Label for="name" className="col-sm-6 col-form-label">{t('Top Bar')}</Label>
                                    <div className="col-sm-3">
                                      <button className="btn btn-lg btn-outline-2x btn-outline-secondary me-3 mt-2"
                                        type="button"
                                        style={{ backgroundColor: color2 }}
                                        onClick={() => colorPicker('2')}>
                                      </button>
                                      {displayColorPicker2 ? (
                                        <div style={popover}>
                                          <div style={cover} onClick={() => handleClose('2')} />
                                          <SketchPicker className="mx-auto mb-4"
                                            onChange={(color) => {
                                              setSketchPickerColor2(color.hex);
                                            }}
                                            color={sketchPickerColor2}
                                          />
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </FormGroup>
                            </div>
                            <div className="col-md-6">
                              <FormGroup>
                                <div>
                                  <div className="row mb-3">
                                    <Label for="name" className="col-sm-5 col-form-label">{t('Left Bar')}</Label>
                                    <div className="col-sm-3">
                                      <button className="btn btn-lg btn-outline-2x btn-outline-secondary me-3 mt-2"
                                        type="button"
                                        style={{ backgroundColor: color3 }}
                                        onClick={() => colorPicker('3')}>
                                      </button>
                                      {displayColorPicker3 ? (
                                        <div style={popover}>
                                          <div style={cover} onClick={() => handleClose('3')} />
                                          <SketchPicker className="mx-auto mb-4"
                                            onChange={(color) => {
                                              setSketchPickerColor3(color.hex);
                                            }}
                                            color={sketchPickerColor3}
                                          />
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </FormGroup>

                            </div>
                          </div>
                        </div>
                      </div>

                      <FormGroup>
                        <Field name="file">
                          {({ input, meta }) => (
                            <div className="row mb-3">
                              <Label for="file" className="col-sm-2 col-form-label">{t('Upload Logo')}</Label>
                              <div className="col-sm-10">
                                <Input {...input} type="file" value={values.file} onChange={onChangelogo} />
                              </div>
                            </div>
                          )}
                        </Field>
                      </FormGroup>
                      <FormGroup>
                        <div className="d-flex justify-content-center EditData">
                          <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-light btn-hover-shine me-3 mt-3">{t('Cancel')}</button>
                          <button className="btn btn-custom-color btn-hover-shine me-3 mt-3" type="submit" disabled={!valid}>{t('Save')}</button>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </LoadingOverlay>
              </form>

            )}

          />

        </CardBody>
      </Card>
    </Fragment>
  );

};
export default TabGenralConfig; 