import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { Row, Col, Card, CardBody, Form, FormGroup, Label, Input, Button, CustomInput } from "reactstrap";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import BusinessService from "./Businesses.service.js";
import AuthService from "../Authentication/auth.service.js";
import Alerts from "../../components/Alerts/alerts.js";

const LalamoveTab = ({ business_id }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const sessionID = localStorage.getItem("session-id");
  const [error, setError] = useState(null);
  const [status, setStatus] = useState('disabled');
  const [methodStatus, setMethodStatus] = useState('disabled');
  const [hasData, setHasData] = useState(false);

  const initialData = {
    host_name: '',
    api_secret: '',
    api_key: '',
  };

  const [data, setData] = useState(initialData);

  useEffect(() => {
    fetchData();
    fetchMethodStatus();
  }, [business_id]);

  const fetchMethodStatus = async () => {
    try {
      const sessionID = localStorage.getItem("session-id");
      const response = await BusinessService.getDeliveryMethodStatus(sessionID, business_id, 'lalamove');
      if (response.data.status) {
        setMethodStatus(response.data.data.status);
        setHasData(response.data.data.hasData);
      }
    } catch (error) {
      console.error('Error fetching method status:', error);
    }
  };

  const handleStatusToggle = async () => {
    try {
      const sessionID = localStorage.getItem("session-id");
      const newStatus = methodStatus === 'enabled' ? 'disabled' : 'enabled';
      const response = await BusinessService.updateDeliveryMethodStatus(sessionID, business_id, 'lalamove', newStatus);
      if (response.data.status) {
        setMethodStatus(newStatus);
        Alerts.swalSuccessAlert(t(`Lalamove ${newStatus}`));
      }
    } catch (error) {
      console.error('Error updating method status:', error);
      Alerts.swalErrorAlert(t("Error updating status"));
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await BusinessService.getLalamoveSettings(sessionID, business_id);
      console.log("response.data", response.data)
      setLoading(false);
      if (response.data.status) {
        if (response.data.data.length > 0) {
          setData(response.data.data[0]);
        } else {
          setData(initialData);
        }
      } else {
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
        } else {
          Alerts.swalErrorAlert(t("Something went wrong, Please try again."));
        }
      }
    } catch (error) {
      setLoading(false);
      Alerts.swalErrorAlert(t("Server Error, Please try again later."));
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setData(prevData => ({
        ...prevData,
        deliveryVia: {
          ...prevData.deliveryVia,
          [name]: checked
        }
      }));
    } else {
      setData(prevData => ({ ...prevData, [name]: value }));
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let response;
      if (data._id) {
        // Update existing settings
        console.log("data update", data)
        response = await BusinessService.updateLalamoveSettings(sessionID, business_id, data);
      } else {
        // Add new settings
        console.log("data add", data)
        response = await BusinessService.addLalamoveSettings(sessionID, business_id, data);
      }
      setLoading(false);
      if (response.data.status) {
        Alerts.swalSuccessAlert(t("Settings saved successfully"));
        fetchData();
      } else {
        console.log("response.data.message", response.data.message)
        Alerts.swalErrorAlert(response.data.message || t("Save failed"));
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error)
      Alerts.swalErrorAlert(t("Server Error, Please try again later."));
    }
  };

  const ChangeStatus_deliveryMethods = async (status) => {
    setLoading(true);
    try {
      let Status;
      if (status === 'enabled') {
        Status = 'enabled'
      } else {
        Status = 'disabled'
      }
      let response = await BusinessService.ChangeStatus_deliveryMethods(Status, "lalamove", sessionID, business_id);
      setLoading(false);
      if (response.data.status) {
        Alerts.swalSuccessAlert(t("Status Changed successfully"));
        fetchData();
      } else {
        console.log("response.data.message", response.data.message)
        Alerts.swalErrorAlert(response.data.message || t("Save failed"));
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error)
      Alerts.swalErrorAlert(t("Server Error, Please try again later."));
    }
  };

  return (
    <Card>
      <CardBody>
        <h3>{t("Lalamove Details")}</h3>
        <Col md="12" className="d-flex justify-content-end pt-3">
          <Button
            color={methodStatus === 'enabled' ? "success" : "secondary"}
            onClick={handleStatusToggle}
            disabled={methodStatus === 'disabled' && !hasData}
            className="btn-hover-shine ms-1 mb-3"
          >
            {methodStatus === 'enabled' ? t("Enabled") : t("Disabled")}
          </Button>
        </Col>
        <LoadingOverlay active={loading} spinner={<DNALoader />}>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label for="host_name">{t('Host Name')}</Label>
                  <Input type="text" name="host_name" id="host_name" value={data.host_name} onChange={handleInputChange} />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="api_key">{t('API Key')}</Label>
                  <Input type="text" name="api_key" id="api_key" value={data.api_key} onChange={handleInputChange} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label for="api_secret">{t('API Secret')}</Label>
                  <Input type="text" name="api_secret" id="api_secret" value={data.api_secret} onChange={handleInputChange} />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md="12" className="d-flex justify-content-center pt-3">
                <Button color="success" type="submit">{data._id ? t('Update') : t('Add')}</Button>
              </Col>

            </Row>
          </Form>
        </LoadingOverlay>
      </CardBody>
    </Card>
  );
};

export default LalamoveTab;