import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const ReturnPolicy = ({ onClose }) => {
    const styles = {
        container: {
            padding: '20px',
            maxWidth: '800px',
            margin: '0 auto',
            fontFamily: 'Arial, sans-serif',
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: '20px',
            borderBottom: '1px solid #eee',
            paddingBottom: '10px',
        },
        backButton: {
            background: 'none',
            border: 'none',
            cursor: 'pointer',
            fontSize: '24px',
            marginRight: '20px',
        },
        title: {
            fontSize: '24px',
            fontWeight: 'bold',
        },
        sectionTitle: {
            fontSize: '18px',
            fontWeight: 'bold',
            marginTop: '20px',
            marginBottom: '10px',
        },
        paragraph: {
            marginBottom: '15px',
            lineHeight: '1.6',
        },
        list: {
            marginLeft: '20px',
            marginBottom: '15px',
        },
        listItem: {
            marginBottom: '10px',
        },
    };

    return (
        <div style={styles.container}>
            <div style={styles.header}>
                
                <h1 style={styles.title}>Return Policy</h1>
            </div>
            <div>
                <p style={styles.paragraph}>
                    JomSmart Marketplace Sdn Bhd has a sole intention to empower entrepreneurs with a valuable and effective platform to provide, sell and solicit their products and service and at the same time offer the consumers a safe and guaranteed satisfaction platform where they can experience this first-hand with us. We value your privacy. You are given the power to decide what personal information you share with us. We also let you know how we may use the personal information that you share with us. Defined terms used here shall bear the same meaning as defined in our Terms and Conditions, unless otherwise stated.
                </p>
                <p style={styles.paragraph}>
                    JomSmart is committed to complying with the Personal Data Protection Act 2010 ("PDPA") of Malaysia. This Privacy Policy Statement sets out how JomSmart collects and uses your personal data. It also sets out the measures that we put in place with regards to personal data protection.
                </p>

                <h2 style={styles.sectionTitle}>Collection and Use of Personal Data</h2>
                <p style={styles.paragraph}>
                    You can generally use and browse the Website without providing any Personal Data and may remain anonymous.
                </p>
                <p style={styles.paragraph}>
                    However, to gain the full benefit and optimum experience of our Website, you may elect and decide to create an Account or to register your details with us, in which case you may be required to provide personal information such as (but not limited to) your name, telephone number, general geographical location or address, e-mail address or any other information which may identify you ("Personal Data").
                </p>
                <p style={styles.paragraph}>
                    Such Personal Data may be used to:
                </p>
                <ol style={styles.list}>
                    <li style={styles.listItem}>Enable you to access and use the Website and JomSmart Services;</li>
                    <li style={styles.listItem}>send you emails, SMS or other form of communication which is related to your use of the Website, such as to verify your identity, personal information, refer you to our BIZ JomSmart for Products and Services that you have requested or for other purposes related to the JomSmart Products and Service Categories; and</li>
                    <li style={styles.listItem}>add you to our mailing list, to send you marketing and promotional materials which we think you may be interested in from time to time. You can at any time decide to unsubscribe from our mailing list and to stop receiving marketing and promotional materials via the links in such communication or by emailing us at support@motoronio.com.</li>
                </ol>

                <h2 style={styles.sectionTitle}>Other Information Collected</h2>
                <p style={styles.paragraph}>
                    When you visit our Website, we may also automatically record information that your browser sends whenever you visit a website. This data does not identify you as an individual and may include:
                </p>
                <ol style={styles.list}>
                    <li style={styles.listItem}>Your computer's IP address;</li>
                    <li style={styles.listItem}>Browser type;</li>
                    <li style={styles.listItem}>The pages you visit on our Website; and</li>
                    <li style={styles.listItem}>The time spent on those pages, items and information searched for on our site, access times and dates, and other statistics.</li>
                </ol>
                <p style={styles.paragraph}>
                    This information is collected for analysis and evaluation in order to help us improve the Website and JomSmart Products and Services offered. This data is collected anonymously and will not be used in association with any Personal Data.
                </p>
                <p style={styles.paragraph}>
                    As part of the above, we may use cookies and Google Analytics. We may also use remarketing with Google Analytics to advertise online. Third-party vendors, including Google, may show our ads on websites across the Internet. We and third-party vendors, including Google, use first-party cookies (such as the Google Analytics cookie) and third-party cookies (such as the DoubleClick cookie) together to inform, optimize, and serve ads based on visitors' past visits to our website, as well as report how ad impressions, other uses of ad services, and interactions with these ad impressions and ad services are related to visits to our website.
                </p>

                <h2 style={styles.sectionTitle}>Links to Other Websites</h2>
                <p style={styles.paragraph}>
                    We may provide links to other websites that we do not control; these include those of our BIZ JomSmart. We do not assume responsibility for the privacy practices, products, services or any other content of any website or pages that is not under our control. We do not bear any responsibility for any actions or policies of third-party websites. Moreover, we disclaim all responsibility for the subsequent use of an email address or any other personally identifiable information you have provided to an affiliate store or any other website we might link to that is not under our control. You are advised to check the privacy policies of those websites upon visiting them.
                </p>

                <h2 style={styles.sectionTitle}>Disclosure of information</h2>
                <p style={styles.paragraph}>
                    We do not sell, distribute or lease Personal Data or information that you provide or which JomSmart otherwise comes by to third parties unless required by law, or with your permission. We may however disclose or transfer Personal Data or information for the purposes of rendering our services to you and this may include disclosure to any professional, third party service providers to us, who are formally engaged by us to help us make available and provide the Website and the JomSmart Service.
                </p>

                <h2 style={styles.sectionTitle}>Protection of information</h2>
                <p style={styles.paragraph}>
                    JomSmart is committed to take all reasonable and practical steps to ensure that any personal information or data that we collect or come into possession of is protected against loss, misuse, abuse, modification or any unauthorized or accidental use, access, disclosure, modification, alteration or destruction. We ensure that all Personal Data collected will be safely and securely stored. We protect your personal information by:
                </p>
                <ol style={styles.list}>
                    <li style={styles.listItem}>Restricting access to Personal Data;</li>
                    <li style={styles.listItem}>Maintaining and implementing measures to prevent unauthorized computer access; and</li>
                    <li style={styles.listItem}>Securely destroying and / or deleting your personal information when it is no longer needed.</li>
                </ol>

                <h2 style={styles.sectionTitle}>Contact Information for Access, Correction, Enquiries</h2>
                <p style={styles.paragraph}>
                    Should you wish to:
                </p>
                <ol style={styles.list}>
                    <li style={styles.listItem}>access; or</li>
                    <li style={styles.listItem}>make any corrections to,</li>
                </ol>
                <p style={styles.paragraph}>
                    the Personal Data which JomSmart has collected from you, or otherwise have any enquiries in respect of your Personal Data, please feel free to contact us via e-mail at support@motoronio.com.
                </p>

                <h2 style={styles.sectionTitle}>Changes to this Privacy Policy</h2>
                <p style={styles.paragraph}>
                    JomSmart may revise this Privacy Policy from time to time, as it may deem necessary. You will be notified of any such changes. By accessing or continuing use of our services after any changes to this Privacy Policy, you agree to be bound by this Privacy Policy including any changes made to it. If you have any questions regarding this Privacy Policy, you may contact us at support@motoronio.com.
                </p>
            </div>
        </div>
    );
};

export default ReturnPolicy;