import React, { Fragment, useState, useEffect } from "react";
import {
    AppBar,
    Toolbar,
    Select,
    MenuItem,
    InputBase,
    alpha,
    styled,
    Typography,
    Box,
    IconButton
} from '@mui/material';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { format, parseISO } from 'date-fns';

// Import custom components and styles
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import "../../../assets/custom css/loadoverlay.scss";
import moment from 'moment';


import DataTable from 'react-data-table-component';
import DashboardService from "../dashboard.service";
import AuthService from "../../Authentication/auth.service";
import Alerts from "../../../components/Alerts/alerts";

import SearchIcon from '@mui/icons-material/Search';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: theme.spacing(3),
    width: 'auto',
    flexGrow: 1,
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));


const Header = ({ branch, setBranch }) => {
    const [branches, setBranches] = useState([]);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        i18n.changeLanguage(localStorage.getItem('language'));
        fetchBusiness()
    }, []);

    function fetchBusiness() {
        // Prepare filter and retrieve user data from local storage
        var filter = {};
        // const userString = localStorage.getItem('user');
        // const user = JSON.parse(userString);
        // const userId = user['_id'];
        // filter["customer_id"] = userId

        setLoading(true);
        DashboardService.getAllBusiness().then((response) => {
            setLoading(false); // Set loading state to false after data retrieval
            // Handle response data
            if (response.data.message === "Unauthorized") {
                // Perform logout and redirection for unauthorized access
                AuthService.logout();
                navigate("/login");
                window.location.reload();
            }
            else {
                if (response.data.data) {
                    console.log("ALL Business", response.data.data);
                    setBranches(response.data.data); // Set total rows based on response length
                }
            }
        },
            // Handle error scenarios
            (error) => {
                setLoading(false); // Set loading state to false in case of error
                const errContent =
                    (error.response && error.response.data) || error.message || error.toString();
                console.log("Net:-", errContent)
                if (errContent) {
                    Alerts.swalErrorAlert(errContent)
                }
                else {
                    Alerts.swalErrorAlert(t("Server Error, Please try again later."))
                }
            })
    };

    useEffect(() => {
        // Auto-select the first branch when branches are loaded
        if (branches.length > 0 && !branch) {
            setBranch(branches[0]._id);
        }
    }, [branches, branch, setBranch]);

    return (
        <AppBar position="static">
            <Toolbar>
                <Select
                    value={branch}
                    onChange={(e) => setBranch(e.target.value)}
                    sx={{
                        color: 'inherit',
                        '& .MuiSelect-icon': { color: 'inherit' },
                        '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
                        mr: 2,
                    }}
                >
                    {branches.map((branch) => (
                        <MenuItem key={branch._id} value={branch._id}>
                            {branch.name}
                        </MenuItem>
                    ))}
                </Select>
                <Box sx={{ flexGrow: 1 }}>
                    <Search>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase placeholder="Search…" inputProps={{ 'aria-label': 'search' }} />
                    </Search>
                </Box>
                <IconButton onClick={() => { navigate('/CartView') }} edge="end" color="inherit" aria-label="cart">
                    <ShoppingCartIcon />
                </IconButton>
            </Toolbar>
        </AppBar>
    );
};


export default Header;
