import React, { Fragment, useState, useEffect } from 'react';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";
import axios from "axios";
import NumberFormatter from "../../../components/NumberFormatter";
import { useNavigate } from "react-router-dom";
import AuthService from "../../Authentication_usertypes/auth.service";
import moment from 'moment';

const B_URL = process.env.REACT_APP_LOGINURL
const headers = {
  'Content-Type': 'application/json',
  'token': localStorage.getItem("session-id")
}
const getGradientColor = (change) => {
  return change >= 0
    ? { start: "#22c55e", end: "#22c55e20" } // Green for positive change
    : { start: "#ef4444", end: "#ef444420" }; // Red for negative change
};

const getGraphHeight = (change) => {
  if (change > 50) return "100%"; // Cap height at 100% for large changes
  if (change < -50) return "10%"; // Cap downward movement
  return `${50 + change / 2}%`; // Dynamically adjust height
};

const BonusGraph = ({ percentageChange }) => {
  const gradient = getGradientColor(percentageChange);
  const height = getGraphHeight(percentageChange);

  return (
    <div
      style={{
        height: "60px",
        background: `linear-gradient(to bottom right, ${gradient.end}, ${gradient.start}10)`,
        borderRadius: "8px",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          position: "absolute",
          bottom: "0",
          left: "0",
          right: "0",
          height,
          background: `linear-gradient(to bottom right, ${gradient.start}, transparent)`,
          clipPath: "polygon(0 100%, 100% 30%, 100% 100%, 0% 100%)",
          transition: "height 0.5s ease-in-out",
        }}
      />
    </div>
  );
};
const getFormattedDateRange = () => {
  const now = new Date();
  
  // Get Current & Last Month
  const currentMonth = now.toLocaleString("default", { month: "long" }); // e.g., "March"
  const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1).toLocaleString("default", { month: "long" }); // e.g., "February"
  
  // Get Today & Yesterday
  const today = now.toLocaleString("default", { weekday: "long" }); // e.g., "Wednesday"
  const yesterday = new Date(now.setDate(now.getDate() - 1)).toLocaleString("default", { weekday: "long" }); // e.g., "Tuesday"

  return {
    monthRange: `From ${lastMonth} ${now.getFullYear()} to ${currentMonth} ${now.getFullYear()}`,
    dayRange: `From ${yesterday} to ${today}`,
  };
};

// Call function to get values


const Dashboard = () => {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [points, setPoints] = useState([]);
  const [Bonuses, setBonuses] = useState([]);
  const [DateRange, setDateRange] = useState('Monthly');
  const [chartData, setChartData] = useState([]);
  const [MonthlyBonus, setMonthlyBonus] = useState([]);
  // const [bonuses, setBonuses] = useState([]);
  const [totalBonus, setTotalBonus] = useState(0);
  const [yesterdayTotal, setYesterdayTotal] = useState(0);
  // const [chartData, setChartData] = useState([]);
  const [bonusChange, setBonusChange] = useState(0);
  const [yesterdayChange, setYesterdayChange] = useState(0); // Percentage change
  const { monthRange, dayRange } = getFormattedDateRange();


  useEffect(() => {
    fetchData();
    fetchPoints();
    fetchBonuses();
    fetchPoints();
    fetchMonthlyBonus();
  }, []);
  useEffect(() => {
    fetchChartData();
  }, [DateRange]);

  const fetchData = async () => {
    setLoading(true);
    let payload = {
      functionName: "GetDashboardData",
      postData: {
        limit: '999999',
        offset: '0',
        // business_id: business_i
      }
    }
    console.log("payload", payload);
    let response = await axios.post(B_URL + "MemberModule/getUserStatus", payload, { headers });
    console.log("response dashboard stats", response);
    setLoading(false);
    if (response.data.message === "Unauthorized") {
      AuthService.logout();
      navigate("/login");
      window.location.reload();
    }
    else {
      if (response.data.data) {
        console.log("ALL Category", response.data.data);
        setData(response.data.data);
      }
    }
  };

  const fetchPoints = async () => {
    setLoading(true);
    let payload = {
      functionName: "getPoints",
      postData: {
        limit: '999999',
        offset: '0',
        range: DateRange
        // business_id: business_i
      }
    }
    console.log("payload", payload);
    let response = await axios.post(B_URL + "MemberModule/getPoints", payload, { headers });
    console.log("response dashboard Points", response);
    setLoading(false);
    if (response.data.message === "Unauthorized") {
      AuthService.logout();
      navigate("/login");
      window.location.reload();
    }
    else {
      if (response.data.data) {
        console.log("ALL Category", response.data.data);
        setPoints(response.data.data);
      }
    }
  }

  const fetchBonuses = async () => {
    let payload = {
      functionName: "getBonuses",
      postData: { limit: "999999", offset: "0" },
    };

    try {
      let response = await axios.post(B_URL + "MemberModule/getBonuses", payload, { headers });

      if (response.data?.status && response.data?.data) {
        const bonusData = response.data.data;
        console.log("bonusData",bonusData)
        setBonuses(bonusData);
        const today = Object.values(bonusData.today || {}).reduce(
          (sum, value) => sum + (parseFloat(value) || 0),
          0
        );
        // Calculate Total Bonus for Yesterday
        const yesterdayTotal = Object.values(bonusData.yesterday || {}).reduce(
          (sum, value) => sum + (parseFloat(value) || 0),
          0
        );
        // Calculate Total Bonus for This Month
        const total = Object.values(bonusData.thisMonth || {}).reduce(
          (sum, value) => sum + (parseFloat(value) || 0),
          0
        );

        // Calculate Total Bonus for Last Month
        const lastMonthTotal = Object.values(bonusData.lastMonth || {}).reduce(
          (sum, value) => sum + (parseFloat(value) || 0),
          0
        );




        // Calculate Percentage Change Compared to Last Month
        let percentageChange = 0;
        if (lastMonthTotal > 0) {
          percentageChange = ((total - lastMonthTotal) / lastMonthTotal) * 100;
        }

        // Calculate Percentage Change Compared to Yesterday
        let yesterdayPercentageChange = 0;
        if (yesterdayTotal > 0) { // ✅ FIXED: Now compares with yesterday's total, not last month
          yesterdayPercentageChange = ((today - yesterdayTotal) / yesterdayTotal) * 100;
        }

        // Update State
        setTotalBonus(total);
        setYesterdayTotal(yesterdayTotal);
        setBonusChange(percentageChange.toFixed(1));
        setYesterdayChange(yesterdayPercentageChange.toFixed(1));


      } else {
        console.warn("No bonus data found.");
        setTotalBonus(0);
        setBonusChange(0);
        setYesterdayChange(0);
      }
    } catch (error) {
      console.error("Error fetching bonuses:", error);
      setTotalBonus(0);
      setBonusChange(0);
      setYesterdayChange(0);
    }
  };





  const fetchChartData = async () => {
    setLoading(true);
    let payload = {
      functionName: "getChartData",
      postData: {
        limit: '999999',
        offset: '0',
        range: DateRange

        // business_id: business_i
      }
    }
    console.log("payload", payload);
    let response = await axios.post(B_URL + "MemberModule/getChartData", payload, { headers });
    console.log("response dashboard Chart Data", response);
    setLoading(false);
    if (response.data.message === "Unauthorized") {
      AuthService.logout();
      navigate("/login");
      window.location.reload();
    }
    else {
      if (response.data.data) {
        console.log("ALL Category", response.data.data);
        setChartData(response.data.data);
      }
    }
  }

  const fetchMonthlyBonus = async () => {
    setLoading(true);
    let payload = {
      functionName: "getMonthlyBonus",
      postData: {
        limit: '999999',
        offset: '0',
        // business_id: business_i
      }
    }
    console.log("payload", payload);
    let response = await axios.post(B_URL + "MemberModule/getMonthlyBonus", payload, { headers });
    console.log("response dashboard Monthly Bonus", response);
    setLoading(false);
    if (response.data.message === "Unauthorized") {
      AuthService.logout();
      navigate("/login");
      window.location.reload();
    }
    else {
      if (response.data.data) {
        console.log("ALL Category", response.data.data);
        setMonthlyBonus(response.data.data);
      }
    }
  }
  console.log("data?.lastUpgradeDate", data?.lastUpgradeDate)

  const statusCards = [
    { title: "Member Type", value: `${data?.UserType || '-----'}`, color: '#F6F4DC' },
    { title: "Current Package", value: `${data?.currentPackage || "No Package"}`, icon: "💎", highlight: true, color: '#FFF4DE' },
    { title: "Current Rank", value: `${data?.currentRank || "Aspiring STARTER RANK"}`, color: '#F6F4DC' },
    { title: "Date Joined", value: `${moment(data?.dateJoined).format('DD-MM-YYYY') || "-- -- ----"}`, color: '#DCFCE6' },
    { title: "Last Upgrade Date", value: data?.lastUpgradeDate !== 'N/A' ? `${moment(data?.lastUpgradeDate).format('DD-MM-YYYY') || "-- -- ----"}` : '-- -- ----', color: '#FFF4DE' },
    { title: "Total Members", value: <NumberFormatter value={data?.totalMembers} /> || 0, color: '#F6F4DC' },
    { title: "Total Lines", value: <NumberFormatter value={data?.totalLines} /> || 0, color: '#FFF4DE' },
  ]



  const pointCards = [
    { title: "Member Point Value (MPV)", value: <NumberFormatter value={points.MPV} /> || 0, type: "MPV", highlight: true },
    { title: "Inventory Member Point Value (IMPV)", value: <NumberFormatter value={points.IMPV} /> || 0, type: "IPV" },
    { title: "Shopping Point Value (SPV)", value: <NumberFormatter value={points.SPV} /> || 0, type: "SPV", highlight: true },
    { title: "Distributor Point Value (DPV)", value: <NumberFormatter value={points.DPV} /> || 0, type: "DPV" },
    { title: "Advocacy Point Value (APV)", value: <NumberFormatter value={points.APV} /> || 0, type: "APV", highlight: true },
  ]

  // const chartData = [
  //   { date: "November 01", MPV: 15000, IMPV: 18000, SPV: 16000, DPV: 12000, APV: 12000 },
  //   { date: "November 10", MPV: 18000, IMPV: 16000, SPV: 13000, DPV: 16000, APV: 13000 },
  //   { date: "November 15", MPV: 12000, IMPV: 17000, SPV: 12000, DPV: 16000, APV: 17000 },
  //   { date: "November 20", MPV: 14000, IMPV: 16000, SPV: 14000, DPV: 18000, APV: 16000 },
  //   { date: "November 25", MPV: 14000, IMPV: 16000, SPV: 14000, DPV: 18000, APV: 16000 },
  //   { date: "November 30", MPV: 14000, IMPV: 16000, SPV: 14000, DPV: 18000, APV: 16000 },
  // ]

  const bonusData = [
    { type: "Unilevel Bonus", value: Bonuses?.thisMonth?.['Unilevel Bonus'] ? <NumberFormatter value={Bonuses.thisMonth['Unilevel Bonus']} /> : '--' },
    { type: "Upgrade Bonus", value: Bonuses?.thisMonth?.['Upgrade Bonus'] ? <NumberFormatter value={Bonuses.thisMonth['Upgrade Bonus']} /> : '--' },
    { type: "Active Bonus", value: Bonuses?.thisMonth?.['Active Bonus'] ? <NumberFormatter value={Bonuses.thisMonth['Active Bonus']} /> : '--' },
    { type: "Leadership Bonus", value: Bonuses?.thisMonth?.['Leadership Bonus'] ? <NumberFormatter value={Bonuses.thisMonth['Leadership Bonus']} /> : '--' },
    { type: "Consistency Bonus", value: Bonuses?.thisMonth?.['Consistency Bonus'] ? <NumberFormatter value={Bonuses.thisMonth['Consistency Bonus']} /> : '--' },
    { type: "Security Bonus", value: Bonuses?.thisMonth?.['Security Bonus'] ? <NumberFormatter value={Bonuses.thisMonth['Security Bonus']} /> : '--' },
    { type: "Premium Acquisition Bonus", value: Bonuses?.thisMonth?.['Premium Acquisition Bonus'] ? <NumberFormatter value={Bonuses.thisMonth['Premium Acquisition Bonus']} /> : '--' },
    { type: "Infini8 Rank Bonus", value: Bonuses?.thisMonth?.['Infini8 Rank Bonus'] ? <NumberFormatter value={Bonuses.thisMonth['Infini8 Rank Bonus']} /> : '--' },
  ];

  const YesterdayBonusData = [
    { type: "Unilevel Bonus", value: Bonuses?.yesterday?.['Unilevel Bonus'] ? <NumberFormatter value={Bonuses.yesterday['Unilevel Bonus']} /> : '--' },
    { type: "Upgrade Bonus", value: Bonuses?.yesterday?.['Upgrade Bonus'] ? <NumberFormatter value={Bonuses.yesterday['Upgrade Bonus']} /> : '--' },
    { type: "Active Bonus", value: Bonuses?.yesterday?.['Active Bonus'] ? <NumberFormatter value={Bonuses.yesterday['Active Bonus']} /> : '--' },
    { type: "Leadership Bonus", value: Bonuses?.yesterday?.['Leadership Bonus'] ? <NumberFormatter value={Bonuses.yesterday['Leadership Bonus']} /> : '--' },
    { type: "Consistency Bonus", value: Bonuses?.yesterday?.['Consistency Bonus'] ? <NumberFormatter value={Bonuses.yesterday['Consistency Bonus']} /> : '--' },
    { type: "Security Bonus", value: Bonuses?.yesterday?.['Security Bonus'] ? <NumberFormatter value={Bonuses.yesterday['Security Bonus']} /> : '--' },
    { type: "Premium Acquisition Bonus", value: Bonuses?.yesterday?.['Premium Acquisition Bonus'] ? <NumberFormatter value={Bonuses.yesterday['Premium Acquisition Bonus']} /> : '--' },
    { type: "Infini8 Rank Bonus", value: Bonuses?.yesterday?.['Infini8 Rank Bonus'] ? <NumberFormatter value={Bonuses.yesterday['Infini8 Rank Bonus']} /> : '--' },
  ];

  const monthlyData = [
    { month: "Jan", value: `${MonthlyBonus?.[0]?.value}` },
    { month: "Feb", value: `${MonthlyBonus?.[1]?.value}` },
    { month: "Mar", value: `${MonthlyBonus?.[2]?.value}` },
    { month: "Apr", value: `${MonthlyBonus?.[3]?.value}` },
    { month: "May", value: `${MonthlyBonus?.[4]?.value}` },
    { month: "Jun", value: `${MonthlyBonus?.[5]?.value}` },
    { month: "Jul", value: `${MonthlyBonus?.[6]?.value}` },
    { month: "Aug", value: `${MonthlyBonus?.[7]?.value}` },
    { month: "Sep", value: `${MonthlyBonus?.[8]?.value}` },
    { month: "Oct", value: `${MonthlyBonus?.[9]?.value}` },
    { month: "Nov", value: `${MonthlyBonus?.[10]?.value}` },
    { month: "Dec", value: `${MonthlyBonus?.[11]?.value}` },
  ]
  const formatNumber = (value) => {
    if (!value || isNaN(value)) return value; // Return as is if not a number
    return Number(value).toLocaleString("en-US");
  };


  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "12px",
              padding: "24px",
              boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
              marginBottom: "24px",
            }}
          >
            {/* // <div style={{ padding: "24px", margin: "0 auto", fontFamily: "Arial, sans-serif" }}> */}
            {/* Status Cards */}
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
                gap: "16px",
                marginBottom: "16px",
              }}
            >
              {statusCards?.map((card, index) => (
                <div
                  key={index}
                  style={{
                    padding: "20px",
                    borderRadius: "15px",
                    backgroundColor: card.highlight ? card.color : card.color,
                    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                  }}
                >
                  <div style={{ fontSize: "14px", color: "#666", marginBottom: "8px" }}>{card.title}</div>
                  <div style={{ fontSize: "24px", fontWeight: "600", display: "flex", alignItems: "center", gap: "8px" }}>
                    {card.icon && <span>{card.icon}</span>}
                    {card.value}
                  </div>
                </div>
              ))}
            </div>

            {/* Points Overview Section */}
            <div className="bg-white p-6">
              <div
                style={{
                  borderRadius: "12px",
                  padding: "24px",
                  marginBottom: "24px",
                }}
              >
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "24px" }}>
                  <h2 style={{ fontSize: "20px", fontWeight: "600" }}>Select Date Range</h2>
                  <select
                    value={DateRange}
                    onChange={(e) => setDateRange(e.target.value)}
                    style={{
                      padding: "8px 16px",
                      borderRadius: "4px",
                      border: "1px solid #ddd",
                      backgroundColor: "white",
                      cursor: "pointer",
                    }}
                  >
                    <option value="Daily">Daily</option>
                    <option value="Last 7 Days">Last 7 Days</option>
                    <option value="Weekly">Weekly</option>
                    <option value="Monthly">Monthly</option>
                    <option value="Yearly">Yearly</option>
                  </select>
                </div>

                {/* Point Value Cards */}
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
                    gap: "16px",
                    marginBottom: "32px",
                  }}
                >
                  {pointCards?.map((card, index) => (
                    <div
                      key={index}
                      style={{
                        padding: "24px",
                        borderRadius: "8px",
                        backgroundColor: card.highlight ? "#b5a642" : "white",
                        color: card.highlight ? "white" : "inherit",
                        border: card.highlight ? "none" : "1px solid #eee",
                      }}
                    >
                      <div style={{ fontSize: "14px", marginBottom: "16px" }}>{card.title}</div>
                      <div style={{ display: "flex", alignItems: "baseline", gap: "4px" }}>
                        <span style={{ fontSize: "32px", fontWeight: "bold" }}>{card.value}</span>
                        <span style={{ fontSize: "14px", opacity: "0.8" }}>{card.type}</span>
                      </div>
                    </div>
                  ))}
                </div>

                {/* Chart */}


                <div className="bg-white border rounded-xl p-6">
                  <ResponsiveContainer width="100%" height={400}>
                    <BarChart data={chartData}>
                      <CartesianGrid strokeDasharray="3 3" vertical={false} />
                      <XAxis dataKey="date" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="MPV" fill="#b5a642" />
                      <Bar dataKey="IMPV" fill="#4caf50" />
                      <Bar dataKey="SPV" fill="#20b2aa" />
                      <Bar dataKey="DPV" fill="#2196f3" />
                      <Bar dataKey="APV" fill="#8b4513" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>



            {/* Accumulated Bonus Section */}
            <div
              style={{
                borderRadius: "12px",
                padding: "24px",
                marginBottom: "24px",
              }}
            >
              <h2 style={{ fontSize: "20px", fontWeight: "600", marginBottom: "24px" }}>Accumulated Bonus</h2>

              <div style={{ display: "grid", gridTemplateColumns: "1fr auto", gap: "24px" }}>
                {/* Bonus Table */}
                <div style={{ display: "grid", gap: "12px" }}>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr auto",
                      padding: "8px",
                      backgroundColor: "#f5f5f5",
                      borderRadius: "4px",
                    }}
                  >
                    <span style={{ fontWeight: "500" }}>Bonus Info</span>
                    <span style={{ fontWeight: "500" }}>Earning (PV)</span>
                  </div>
                  {bonusData?.map((bonus, index) => (
                    <div
                      key={index}
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr auto",
                        padding: "8px",
                        borderBottom: "1px solid #eee",
                      }}
                    >
                      <span>{bonus.type}</span>
                      <span>{bonus.value}</span>
                    </div>
                  ))}
                </div>

                {/* Total Bonus Card */}
                <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: "12px",
                    padding: "24px",
                    border: "1px solid #eee",
                    maxWidth: "500px",
                  }}
                >
                  <div style={{ marginBottom: "16px" }}>
                    <div
                      style={{ fontSize: "14px", color: "#666", marginBottom: "8px" }}
                    >
                      Total Accumulated Bonus:
                    </div>
                    <div style={{ fontSize: "32px", fontWeight: "bold" }}>
                      {totalBonus.toFixed(2)}
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                      fontSize: "14px",
                      color: "#666",
                      marginBottom: "16px",
                    }}
                  >
                    <span>{monthRange}</span>
                    <span
                      style={{
                        color: bonusChange >= 0 ? "#22c55e" : "#ef4444",
                        backgroundColor: bonusChange >= 0 ? "#dcfce7" : "#fee2e2",
                        padding: "2px 8px",
                        borderRadius: "4px",
                      }}
                    >
                      {bonusChange >= 0 ? `↑ ${bonusChange}%` : `↓ ${Math.abs(bonusChange)}%`}
                    </span>
                  </div>

                  {/* Custom Graph Design */}
                  <BonusGraph percentageChange={bonusChange} />

                  {/* <div
                    style={{
                      height: "60px",
                      background: "linear-gradient(to bottom right, #22c55e20, #22c55e10)",
                      borderRadius: "8px",
                      position: "relative",
                      overflow: "hidden",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        bottom: "0",
                        left: "0",
                        right: "0",
                        height: "40px",
                        background: "linear-gradient(to bottom right, #22c55e, transparent)",
                        clipPath: "polygon(0 100%, 100% 30%, 100% 100%, 0% 100%)",
                      }}
                    />
                  </div> */}
                </div>


              </div>
            </div>

            {/* Yesterday Bonus Section */}
            <div
              style={{
                borderRadius: "12px",
                padding: "24px",
                marginBottom: "24px",
              }}
            >
              <h2 style={{ fontSize: "20px", fontWeight: "600", marginBottom: "24px" }}>Yesterday Bonus</h2>

              <div style={{ display: "grid", gridTemplateColumns: "1fr auto", gap: "24px" }}>
                {/* Bonus Table */}
                <div style={{ display: "grid", gap: "12px" }}>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr auto",
                      padding: "8px",
                      backgroundColor: "#f5f5f5",
                      borderRadius: "4px",
                    }}
                  >
                    <span style={{ fontWeight: "500" }}>Bonus Info</span>
                    <span style={{ fontWeight: "500" }}>Earning (PV)</span>
                  </div>
                  {YesterdayBonusData?.map((bonus, index) => (
                    <div
                      key={index}
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr auto",
                        padding: "8px",
                        borderBottom: "1px solid #eee",
                      }}
                    >
                      <span>{bonus.type}</span>
                      <span>{bonus.value}</span>
                    </div>
                  ))}
                </div>

                {/* Total Yesterday Bonus Card */}

                {/* Total Bonus Card */}
                <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: "12px",
                    padding: "24px",
                    width:'400px',
                    border: "1px solid #eee",
                    maxWidth: "500px",
                  }}
                >
                  <div style={{ marginBottom: "16px" }}>
                    <div
                      style={{ fontSize: "14px", color: "#666", marginBottom: "8px" }}
                    >
                      Total Yesterday Bonus:
                    </div>
                    <div style={{ fontSize: "32px", fontWeight: "bold" }}>
                      {yesterdayTotal.toFixed(2)}
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                      fontSize: "14px",
                      color: "#666",
                      marginBottom: "16px",
                      maxWidth: "500px",
                    }}
                  >
                    <span>{dayRange}</span>
                    <span
                      style={{
                        color: yesterdayChange >= 0 ? "#22c55e" : "#ef4444",
                        backgroundColor: yesterdayChange >= 0 ? "#dcfce7" : "#fee2e2",
                        padding: "2px 8px",
                        borderRadius: "4px",
                      }}
                    >
                      {yesterdayChange >= 0 ? `↑ ${yesterdayChange}%` : `↓ ${Math.abs(yesterdayChange)}%`}
                    </span>
                  </div>
                  <BonusGraph percentageChange={yesterdayChange} />

                  {/* Custom Graph Design */}
                  {/* <div
                    style={{
                      height: "60px",
                      background: "linear-gradient(to bottom right, #22c55e20, #22c55e10)",
                      borderRadius: "8px",
                      position: "relative",
                      overflow: "hidden",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        bottom: "0",
                        left: "0",
                        right: "0",
                        height: "40px",
                        background: "linear-gradient(to bottom right, #22c55e, transparent)",
                        clipPath: "polygon(0 100%, 100% 30%, 100% 100%, 0% 100%)",
                      }}
                    />
                  </div> */}
                </div>

                {/* <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: "12px",
                    padding: "24px",
                    border: "1px solid #eee",
                    maxWidth: "450px",
                  }}
                >
                  <div style={{ marginBottom: "16px" }}>
                    <div style={{ fontSize: "14px", color: "#666", marginBottom: "8px" }}>Total Yesterday Bonus:</div>
                    <div style={{ fontSize: "32px", fontWeight: "bold" }}>35.00</div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                      fontSize: "14px",
                      color: "#666",
                      marginBottom: "16px",
                    }}
                  >
                    <span>From Jan 20,2022 to July,2022</span>
                    <span style={{ color: "#ef4444", backgroundColor: "#fee2e2", padding: "2px 8px", borderRadius: "4px" }}>
                      ↓ 16.3%
                    </span>
                  </div>
                  <div
                    style={{
                      height: "60px",
                      background: "linear-gradient(to bottom right, #22c55e20, #22c55e10)",
                      borderRadius: "8px",
                      position: "relative",
                      overflow: "hidden",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        bottom: "0",
                        left: "0",
                        right: "0",
                        height: "40px",
                        background: "linear-gradient(to bottom right, #22c55e, transparent)",
                        clipPath: "polygon(0 100%, 100% 30%, 100% 100%, 0% 100%)",
                      }}
                    />
                  </div>
                </div> */}





              </div>
            </div>

            {/* Monthly Accumulated Bonus 2025 */}
            {/* <div className="rounded-2xl p-6">
              <h2 className="text-xl font-semibold mb-6">Monthly Accumulated Bonus 2025</h2>
              <ResponsiveContainer width="100%" height={400}>
                <BarChart data={monthlyData}>
                  <CartesianGrid strokeDasharray="3 3" vertical={false} />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="value" fill="#b5a642" radius={[20, 20, 0, 0]} />
                </BarChart>
              </ResponsiveContainer>
            </div> */}
            <div className="rounded-2xl p-6">
              <h2 className="text-xl font-semibold mb-6">Monthly Accumulated Bonus 2025</h2>
              <ResponsiveContainer width="100%" height={400}>
                <BarChart data={monthlyData}>
                  <CartesianGrid strokeDasharray="3 3" vertical={false} />
                  <XAxis dataKey="month" />
                  <YAxis tickFormatter={formatNumber} /> {/* Apply number formatting */}
                  <Tooltip formatter={(value) => formatNumber(value)} /> {/* Format tooltip values */}
                  <Bar dataKey="value" fill="#b5a642" radius={[20, 20, 0, 0]} />
                </BarChart>
              </ResponsiveContainer>
            </div>


          </div>
        </div>
      </div>
    </Fragment >

  )
}

export default Dashboard

