import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Col, Row, Button, FormGroup, Label, Input } from "reactstrap";
import { Form, Field } from "react-final-form";
import AuthService from "./auth.service";
import Alerts from "../../components/Alerts/alerts";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";
import CopyRight from "../../components/CopyRight Bar/CopyRight";
import bg from './bg.png';

const ResetPassword = () => {
  localStorage.removeItem("resetpass");

  const { t, i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
  }, []);

  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState(localStorage.getItem("system_logo"));
  const navigate = useNavigate();

  const validateFtn = (values) => {
    const errors = {};
    if (!values.resetcode) {
      errors.resetcode = t("Reset Code Required.");
    }
    if (!values.password) {
      errors.password = t("New Password Required.");
    }
    if (!values.confirmpassword) {
      errors.confirmpassword = t("Confirm New Password Required.");
    }
    if (values.password !== values.confirmpassword) {
      errors.confirmpassword = t("Passwords Do Not Match.");
    }
    return errors;
  };

  const onSubmit = values => {
    setLoading(true);
    AuthService.resetpw(values.password, values.confirmpassword, values.resetcode).then((response) => {
      setLoading(false);
      if(response.data.status){
        Alerts.swalSuccessAlert("Password is reset successfully. Please login again.")
        navigate("/login");
        window.location.reload();
      }else{
        Alerts.swalErrorAlert(response.data.message)
      }
    },
    (error) => {
      const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      setLoading(false);
      Alerts.swalErrorAlert(resMessage || t("Server Error, Please try again later."))
    });
  };

  return (
    <div style={styles.container}>
      <div style={styles.leftContainer}>
        <div style={styles.logoContainer}>
          <img src={logo} alt="Logo" style={styles.logo} />
        </div>
        <Form onSubmit={onSubmit} validate={validateFtn}
          render={({ handleSubmit, valid }) => (
            <form onSubmit={handleSubmit}>
              <LoadingOverlay active={loading} spinner={<DNALoader />}>
                <div className="modal-body p-5">
                  <h2 style={styles.heading}>{t('Reset Password')}</h2>
                  <p style={styles.logoText}>{t('Use the form below to reset it.')}</p>
                  <Row form>
                    <Col md={12}>
                      <FormGroup>
                        <Field name="resetcode">
                          {({ input, meta }) => (
                            <div className="mb-3">
                              <Label for="resetcode">{t('Reset Code')}</Label>
                              <Input {...input} type="text" invalid={meta.error && meta.touched} placeholder={t('Reset Code')} />
                              {meta.error && meta.touched && <span className="text-danger">{meta.error}</span>}
                            </div>
                          )}
                        </Field>
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup>
                        <Field name="password">
                          {({ input, meta }) => (
                            <div className="mb-3">
                              <Label for="password">{t('New Password')}</Label>
                              <Input {...input} type="password" invalid={meta.error && meta.touched} placeholder={t('New Password')} />
                              {meta.error && meta.touched && <span className="text-danger">{meta.error}</span>}
                            </div>
                          )}
                        </Field>
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup>
                        <Field name="confirmpassword">
                          {({ input, meta }) => (
                            <div className="mb-3">
                              <Label for="confirmpassword">{t('Confirm New Password')}</Label>
                              <Input {...input} type="password" invalid={meta.error && meta.touched} placeholder={t('Confirm New Password')} />
                              {meta.error && meta.touched && <span className="text-danger">{meta.error}</span>}
                            </div>
                          )}
                        </Field>
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="d-grid mb-3">
                    <Button size="lg" className="btn-custom-color" type="submit" disabled={!valid}>
                      {t('Reset Password')}
                    </Button>
                  </div>
                  <div className="text-center">
                    <a href="/login" style={styles.link}>{t('Sign in existing account')}</a>
                  </div>
                </div>
              </LoadingOverlay>
            </form>
          )}
        />
        {/* <CopyRight /> */}
      </div>
      <div style={styles.rightContainer}>
        {/* Additional components or styles can be added here */}
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    height: '100vh',
  },
  leftContainer: {
    flex: 1,
    display: 'flex',
    marginTop: '3%',
    flexDirection: 'column',
    background: 'white',
    padding: '0 60px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  rightContainer: {
    flex: 2,
    backgroundImage: `url(${bg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
  },
  logoContainer: {
    marginBottom: '20px',
  },
  logo: {
    width: '180px',
    height: 'auto',
  },
  heading: {
    marginTop: '12%',
    fontSize: '28px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  logoText: {
    fontSize: '16px',
    color: '#666',
    marginBottom: '20px',
  },
  link: {
    color: '#007bff',
    textDecoration: 'none',
  },
};

export default ResetPassword;
