import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styles from './ProcessDesigner.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle,faBoxOpen, faShoppingCart, faCreditCard, faTruck, faTimes } from '@fortawesome/free-solid-svg-icons';

const Configuration = () => {

  const { t, i18n } = useTranslation();
  const [steps, setSteps] = useState([]);
  const [availableSteps, setAvailableSteps] = useState([
    { id: 'select', content: 'Select Item', icon: faCheckCircle },
    { id: 'create', content: 'Create Order', icon: faShoppingCart },
    { id: 'pay', content: 'Pay Online', icon: faCreditCard },
    { id: 'pick', content: 'Pick Order', icon: faBoxOpen },
    { id: 'deliver', content: 'Deliver Home', icon: faTruck },
  ]);
  const onDragEnd = (result) => {
    const { source, destination } = result;

    // Dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      // Reordering within the same list
      const items = reorder(
        source.droppableId === 'available' ? availableSteps : steps,
        source.index,
        destination.index
      );

      if (source.droppableId === 'available') {
        setAvailableSteps(items);
      } else {
        setSteps(items);
      }
    } else {
      // Moving from one list to another
      const result = move(
        source.droppableId === 'available' ? availableSteps : steps,
        source.droppableId === 'available' ? steps : availableSteps,
        source,
        destination
      );

      setAvailableSteps(result.available);
      setSteps(result.process);
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, {
      ...removed,
      id: `${removed.id}-${Date.now()}` // Ensure unique ID
    });

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

  const removeStep = (index) => {
    const removedStep = steps[index];
    setSteps(prevSteps => prevSteps.filter((_, i) => i !== index));
    setAvailableSteps(prevAvailable => [...prevAvailable, { ...removedStep, id: removedStep.id.split('-')[0] }]);
  };

  const renderDraggableItem = (item, index, isDraggable = true) => (
    <Draggable key={item.id} draggableId={item.id} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={styles.draggableItem}
        >
          <span>
            <FontAwesomeIcon icon={item.icon} /> {item.content}
          </span>
          {isDraggable && (
            <button onClick={() => removeStep(index)} className={styles.removeButton}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          )}
        </div>
      )}
    </Draggable>
  );
  const saveProcess = () => {
    console.log("Saving process:", steps);
  };

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
  }, []);

  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className="app-page-title mb-0">
            <div className="page-title-wrapper">
              <div className="page-title-heading fw-bold">
                <div className="page-title-icon">
                  <i className="pe-7s-settings icon-gradient bg-happy-green" />
                </div>
                <div>{t('Process')}{t('Automation')}
                  <div className="page-title-subheading">
                    {t('View and update some basic Process Automation.')}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.container}>
            <DragDropContext onDragEnd={onDragEnd}>
              <div className={styles.workArea}>
                <div className={styles.availableSteps}>
                  <h2 className={styles.sectionTitle}>{t('Available Steps')}</h2>
                  <Droppable droppableId="available">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef} className={styles.stepList}>
                        {availableSteps.map((step, index) => renderDraggableItem(step, index))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
                <div className={styles.processSteps}>
                  <h2 className={styles.sectionTitle}>{t('Current Process')}</h2>
                  <Droppable droppableId="process">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef} className={styles.stepList}>
                        {steps.map((step, index) => renderDraggableItem(step, index))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              </div>
            </DragDropContext>
            <button onClick={saveProcess} className={styles.saveButton}>
              {t('Save Process')}
            </button>
          </div>



        </div>
      </div>
    </Fragment>
  );

};
export default Configuration; 
