import React, { useState } from "react";
import { Button, Progress } from "reactstrap";
import "./WelcomePage.css";

const WelcomePage = ({ onSkip, onComplete, theme }) => {
  const [currentStep, setCurrentStep] = useState(0);

  // Steps content
  const messages = [
    "Welcome to the dashboard! Let’s help you get started.",
    "Easily manage your account and packages from the dashboard.",
    "Select a package to explore products tailored for you.",
    "Track your orders and stay updated with your purchase history.",
    "Check out our Help section for quick assistance.",
    "Customize your profile for a personalized experience.",
    "Discover new features in the updates section.",
    "Set your preferred language and currency for convenience.",
    "Monitor your rewards and loyalty points in the rewards section.",
    "Thank you for choosing us! Enjoy your journey.",
  ];

  const handleNext = () => {
    if (currentStep < messages.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <div
      className="welcome-overlay"
      style={{ backgroundColor: theme?.overlayColor || "rgba(0, 0, 0, 0.7)" }}
    >
      <div
        className="welcome-card"
        style={{
          backgroundColor: theme?.cardBackgroundColor || "#fff",
          color: theme?.textColor || "#000",
        }}
      >
        {/* Step Progress */}
        <div className="welcome-progress">
          <Progress
            value={(currentStep + 1) * (100 / messages.length)}
            style={{
              backgroundColor: theme?.progressTrackColor || "#e0e0e0",
            }}
            barStyle={{
              backgroundColor: theme?.progressBarColor || "#4caf50",
            }}
          />
        </div>

        {/* Step Content */}
        <div className="welcome-message">
          <h2 className="welcome-title">{`Step ${currentStep + 1} of ${
            messages.length
          }`}</h2>
          <p className="welcome-text">{messages[currentStep]}</p>
        </div>

        {/* Navigation Buttons */}
        <div className="welcome-actions">
          <Button
            color="secondary"
            onClick={handlePrevious}
            disabled={currentStep === 0}
            style={{
              backgroundColor: theme?.buttonSecondaryColor || "#6c757d",
              borderColor: theme?.buttonSecondaryColor || "#6c757d",
            }}
          >
            Previous
          </Button>
          {currentStep < messages.length - 1 ? (
            <Button
              color="primary"
              onClick={handleNext}
              style={{
                backgroundColor: theme?.buttonPrimaryColor || "#007bff",
                borderColor: theme?.buttonPrimaryColor || "#007bff",
              }}
            >
              Next
            </Button>
          ) : (
            <Button
              color="success"
              onClick={onComplete}
              style={{
                backgroundColor: theme?.buttonSuccessColor || "#28a745",
                borderColor: theme?.buttonSuccessColor || "#28a745",
              }}
            >
              Finish
            </Button>
          )}
        </div>

        {/* Skip Option */}
        <Button
          color="link"
          onClick={onSkip}
          className="welcome-skip"
          style={{ color: theme?.skipTextColor || "#999" }}
        >
          Skip
        </Button>
      </div>
    </div>
  );
};

export default WelcomePage;
