import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { Row, Col, Card, CardBody } from "reactstrap";

import { useNavigate, useLocation } from "react-router-dom";
import UserService from "../../pages/Users Management/users.service";
import AuthService from "../Authentication/auth.service";
import PermissionAccess from '../../PermissionAccess/Access.js';


const ViewActivityLog = () => {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [log, setLog] = useState("");
  const [user, setUser] = useState("");
  const { authData, accessPages, super_admin, current_module } = PermissionAccess();

  const location = useLocation();
  const { state } = location;
  console.log("Received Object:-", state);

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    setData();
  }, []);

  function setData() {
    if (state) {
      console.log("Going to set data object for display")
      setLog(state)
      getuser(state.user_id);
    }
    else {
      navigate(-1)
      window.location.reload()
    }
  }

  function getuser(id) {
    UserService.getSingleUser(authData.sessionID, id).then(
      (response) => {
        console.log("Get User response:-", response)
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        }
        else {
          setUser(response.data.data);
        }
      },
      (error) => {
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
      },
    )
  };


  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <TransitionGroup>
            <CSSTransition component="div" classNames="TabsAnimation" appear={true}
              timeout={1500} enter={false} exit={false}>
              <div>
                <div className="app-page-title">
                  <div className="page-title-wrapper">
                    <div className="page-title-heading fw-bold">
                      <div className="page-title-icon">
                        <i className="pe-7s-display2 icon-gradient bg-happy-green" />
                      </div>
                      <div>
                        {t('Log Details')}
                        <div className="page-title-subheading">
                          {t('Following information for')} <strong> {log.collection_name}</strong> {t('has been')}
                          {log?.action === 'UPDATE' &&
                            <strong> {t('updated')}</strong>
                          }
                          {log?.action === 'INSERT' &&
                            <strong> {t('inserted')}</strong>
                          }
                          {log?.action === 'DELETE' &&
                            <strong> {t('deleted')}</strong>
                          }
                          &nbsp;{t('by')}
                          <strong> {log?.user_details?.name}</strong>
                          &nbsp;{t('from')} <strong> {log.ip}</strong>
                          {/* {log?.data_after?.datetime_modified &&
                            <span>&nbsp;{t('on')}&nbsp;<strong>{new Date(log?.data_after?.datetime_modified).toLocaleString()}</strong></span>
                          } */}
                          {/* {log?.datetime_added &&
                            <span>&nbsp;{t('on')}&nbsp;<strong>{new Date(log?.datetime_added).toLocaleString()}</strong>.</span>
                          }  */}
                        </div>
                      </div>
                    </div>
                    <div className="page-title-actions">
                      <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-success btn-hover-shine me-3">
                        {t("Back")}
                      </button>
                    </div>
                  </div>
                </div>
                <Row>
                  <Col md="4">
                    <Card className="main-card mb-3">
                      <CardBody>
                        <table className="table table-striped table-bordered nowrap">
                          <tbody>
                            <tr>
                              <th style={{ width: "30%" }}>{t('User Name')}</th>
                              <td> {log?.user_details?.name}</td>
                            </tr>
                            <tr>
                              <th style={{ width: "30%" }}>{t('DB Table')}</th>
                              <td>{log?.collection_name}</td>
                            </tr>
                            <tr>
                              <th style={{ width: "30%" }}>{t("Action")}</th>
                              <td>{log?.action}</td>
                          </tr>
                            <tr>
                              <th style={{ width: "30%" }}>{t('Record ID')}</th>
                              <td>{log?.record_id}</td>
                            </tr>
                            <tr>
                              <th style={{ width: "30%" }}>{t('Date Time')}</th>
                              {log?.datetime_added &&
                                <td>{new Date(log?.datetime_added).toLocaleString()}</td>
                              }
                            </tr>
                            <tr>
                              <th style={{ width: "30%" }}>{t('IP')}</th>
                              <td>{log.ip}</td>
                            </tr>
                          </tbody>
                        </table>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="8">
                    <Card className="main-card mb-3">
                      <CardBody>
                        <table className="table table-striped table-bordered nowrap">
                          <tbody>
                            <tr>
                              <th style={{ width: "8%" }}>{t('Field')}</th>
                              {log?.action !== 'DELETE' &&
                                <th style={{ width: "46%" }}>{t('New Value')}</th>
                              }
                              {log?.data_before?._id &&
                                <th style={{ width: "46%" }}>{t('Old Value')}</th>
                              }
                            </tr>
                            {log?.data_after &&
                              Object.keys(log?.data_after).map((key, i) => {
                                if (typeof (log?.data_after[key]) !== "object") {
                                  return (
                                    <tr key={i}>
                                      <td >{t(key)}</td>
                                      {log?.action !== 'DELETE' &&
                                        <td style={{ color: "red" }}>{log?.data_after[key]}</td>
                                      }
                                      {log?.data_before &&
                                        Object.keys(log?.data_before).map((mykey, i) => {
                                          if (typeof (log?.data_before[key]) !== "object" && mykey === key) {
                                            return (
                                              // <tr key={i}>
                                              <td >{t(log?.data_before[mykey])}</td>
                                              // </tr>
                                            )
                                          }
                                        })
                                      }
                                    </tr>
                                  )
                                }
                                else {
                                  return (
                                    <tr key={i}>
                                      <td>{key}</td>
                                    </tr>

                                  )
                                }
                              })
                            }


                          </tbody>
                        </table>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </CSSTransition>
          </TransitionGroup>

        </div>
      </div>
    </Fragment>
  );

};
export default ViewActivityLog;