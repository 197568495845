import axios from "axios";

const B_URL = process.env.REACT_APP_LOGINURL


const headers = {
  'Content-Type': 'application/json',
  'token': localStorage.getItem("session-id")
}


const getAllDiscountCoupons = (sessionID, page, limit, text, status, business, businessIDs) => {
  var payload = {
    "functionName": "GetDiscountCoupons",
    "postData": {
      "offset": page,
      "sort": {
        "_id": -1
      },
      "limit": limit,
      "coupon_code": text,
      "status": status,
      "business_id": business,
      "businessIDs": businessIDs,
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "DiscountCouponsModule/GetDiscountCoupons", payload, { headers });
};

const getAllOrders = (sessionID, page, limit, text, status, business, businessIDs) => {
  var payload = {
    "functionName": "GetAdminOrdersList",
    "postData": {
      "search_text": text,
      "status": status,
      "business_id": business,
      "businessIDs": businessIDs,
      "offset": page,
      "sort": {
        "_id": -1
      },
      "limit": limit
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "OrderModule/GetAdminOrdersList", payload, { headers });
};


const GetOrders = (sessionID, page, limit, text, status, business, businessIDs) => {
  var payload = {
    "functionName": "GetOrders",
    "postData": {
      "search_text": text,
      "status": status,
      "business_id": business,
      "businessIDs": businessIDs,
      "offset": page,
      "sort": {
        "_id": -1
      },
      "limit": limit
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "OrderModule/GetOrders", payload, { headers });
};

const GetSingleOrder = (sessionID, order_id) => {
  var payload = {
    "functionName": "GetSingleOrder",
    "postData": {
      "order_id": order_id
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "OrderModule/GetSingleOrder", payload, { headers });
};


const GetOrderDetails = (sessionID, order_id) => {
  var payload = {
    "functionName": "GetAdminOrderDetails",
    "postData": {
      "order_id": order_id
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "OrderModule/GetAdminOrderDetails", payload, { headers });
};

const GetUser_OrderDetails = (sessionID, order_id) => {
  var payload = {
    "functionName": "GetUser_OrderDetails",
    "postData": {
      "order_id": order_id
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "OrderModule/GetUser_OrderDetails", payload, { headers });
};



const getAllBusinesses = (sessionID, page, limit, business) => {
  var payload = {
    "functionName": "GetBusinesses",
    "postData": {
      "offset": page,
      "sort": {
        "_id": -1
      },
      "limit": limit,
      "business_ids": business ? business : []
    }

  }

  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }

  return axios.post(B_URL + "BusinessModule/GetBusinesses", payload, { headers });
};

const AddDiscountCoupon = (sessionID, data) => {
  // console.log("DATA:", data);
  var payload = {
    "functionName": "AddDiscountCoupon",
    "postData": {
      "coupon_code": data.coupon_code,
      "total_coupons": data.total_coupons,
      "type": data.type,
      "amount": data.amount,
      "start_date": data.start_date,
      "end_date": data.end_date,
      "status": data.status,
      "business_ids": data.business_ids
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "DiscountCouponsModule/AddDiscountCoupon", payload, { headers });
};

const deleteDiscountCoupon = (sessionID, id) => {
  var payload = {
    "functionName": "DeleteDiscountCoupon",
    "postData": {
      "coupon_id": id
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "DiscountCouponsModule/DeleteDiscountCoupon", payload, { headers });
};


const EditDiscountCoupon = (sessionID, data, id) => {
  // console.log("DATA:", data);
  var payload = {
    "functionName": "UpdateDiscountCoupon",
    "postData": {
      "coupon_id": id,
      "coupon_code": data.coupon_code,
      "total_coupons": data.total_coupons,
      "type": data.type,
      "amount": data.amount,
      "start_date": data.start_date,
      "end_date": data.end_date,
      "status": data.status,
      "business_id": data.business_id
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "DiscountCouponsModule/UpdateDiscountCoupon", payload, { headers });
};

const GetSingleDiscountCoupon = (sessionID, id) => {
  var payload = {
    "functionName": "GetSingleDiscountCoupon",
    "postData": {
      "id": id
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "DiscountCouponsModule/GetSingleDiscountCoupon", payload, { headers });
};

const deleteBulkDiscountSection = (sessionID, selectedRows) => {
  var payload = {
    "functionName": "DeleteBulkDiscountCoupon",
    "postData": {
      "coupon_ids": selectedRows
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "DiscountCouponsModule/DeleteBulkDiscountCoupon", payload, { headers });
};

const updateBulkStatus = (sessionID, selectedRows, type) => {
  var payload = {
    "functionName": "BulkStatusUpdate",
    "postData": {
      "coupon_ids": selectedRows,
      "type": type
    },
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "DiscountCouponsModule/BulkStatusUpdate", payload, { headers });
};

const AdminProcessOrder = (sessionID, id, status) => {
  var payload = {
    "functionName": "AdminProcessOrder",
    "postData": {
      "order_id": id,
      "process_with_lalamove": status
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "OrderModule/AdminProcessOrder", payload, { headers });
};

const CancelLalamoveOrder = (sessionID, id) => {
  var payload = {
    "functionName": "CancelLalamove",
    "postData": {
      "order_id": id
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "OrderModule/CancelLalamove", payload, { headers });
};

const AdditionalTip = (sessionID, id, amount) => {
  var payload = {
    "functionName": "AddAdminExtraPriority",
    "postData": {
      "order_id": id,
      "amount": amount
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "OrderModule/AddAdminExtraPriority", payload, { headers });
};

const LalamoveLinkID = (sessionID, id, lalamove_id) => {
  var payload = {
    "functionName": "LinkLalamove",
    "postData": {
      "order_id": id,
      "lalamove_order_id": lalamove_id
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "OrderModule/LinkLalamove", payload, { headers });
};

const getSettlementReport = (sessionID, page, limit, business, date, businessIDs) => {
  var payload = {
    "functionName": "SettlementReport",
    "postData": {
      "business_id": business,
      "date_range": date,
      "businessIDs": businessIDs,
      "offset": page,
      "sort": {
        "_id": -1
      },
      "limit": limit
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "ReportsModule/SettlementReport", payload, { headers });
};


const getDeliveryFeeReport = (sessionID, page, limit, business, search_text, status, date, businessIDs) => {
  var payload = {
    "functionName": "DeliveryFeeReport",
    "postData": {
      "business_id": business,
      "search_text": search_text,
      "date_range": date,
      "status": status,
      "businessIDs": businessIDs,
      "offset": page,
      "sort": {
        "_id": -1
      },
      "limit": limit
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "ReportsModule/DeliveryFeeReport", payload, { headers });
};

const getSalesReport = (sessionID, page, limit, business, status, item_id, date, businessIDs) => {
  var payload = {
    "functionName": "SalesReport",
    "postData": {
      "business_id": business,
      "product_id": item_id,
      "status": status,
      "date_range": date,
      "businessIDs": businessIDs,
      "offset": page,
      "sort": {
        "_id": -1
      },
      "limit": limit
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "ReportsModule/SalesReport", payload, { headers });
};

const UpdateStatus = (sessionID, selectedStatus, orderId, deliveryMethod) => {
  var payload = {
    "functionName": "UpdateStatus",
    "postData": {
      "order_id": orderId,
      "status": selectedStatus,
      "deliveryMethod": deliveryMethod
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "OrderModule/UpdateStatus", payload, { headers });
};

const UpdateStatusMember = (selectedStatus, orderId, deliveryMethod) => {
  var payload = {
    "functionName": "UpdateStatus",
    "postData": {
      "order_id": orderId,
      "status": selectedStatus,
      "deliveryMethod": deliveryMethod
    }
  }
  return axios.post(B_URL + "OrderModule/UpdateStatus", payload, { headers });
};
const getUserPointStatement = (sessionID, page, limit, business, search_text, date, businessIDs) => {
  var payload = {
    "functionName": "UserPointStatement",
    "postData": {
      "business_id": business,
      "search_text": search_text,
      "date_range": date,
      "businessIDs": businessIDs,
      "offset": page,
      "sort": {
        "_id": -1
      },
      "limit": limit
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "ReportsModule/UserPointStatement", payload, { headers });
};

const getUserStatement = (sessionID, page, limit, business, user, date, businessIDs) => {
  var payload = {
    "functionName": "UserStatement",
    "postData": {
      "user_id": user,
      "type": business,
      "date_range": date,
      "businessIDs": businessIDs,
      "offset": page,
      "sort": {
        "_id": -1
      },
      "limit": limit
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "ReportsModule/UserStatement", payload, { headers });
};

const SendOrderMessage = (sessionID, id, newMessage, sender_type) => {
  var payload = {
    "functionName": "SendOrderMessage",
    "order_id": id,
    "message": newMessage,
    "sender_type": sender_type,
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "OrderModule/SendOrderMessage", payload, { headers });
};

const SendMessage = (sessionID, id, newMessage, sender_type) => {
  const payload = {
    functionName: "SendMessage",
    "quotation_id": id,
    'message': newMessage,
    "sender_type": sender_type,
  };
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "OrderModule/SendMessage", payload, { headers });
};

const GetOrderMessages = (sessionID, id, type) => {
  var payload = {
    "functionName": "GetOrderMessages",
    "id": id,
    "type": type,
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "OrderModule/GetOrderMessages", payload, { headers });
};

const GetMessages = (sessionID, id, type) => {
  const payload = {
    functionName: "GetMessages",
    "id": id,
    "type": type,
  };
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "OrderModule/GetMessages", payload, { headers });
};

const GetAdminRFQs = (sessionID, payload) => {
  // console.log("DATA--------:", payload);
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "RFQModule/GetAdminRFQs", payload, { headers });
};
const getAllOrdersAdmin = (sessionID, payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': sessionID
  }
  return axios.post(B_URL + "OrderModule/getAllOrdersAdmin", payload, { headers });
};

const DiscountCouponService = {
  getAllDiscountCoupons,
  getAllBusinesses,
  GetMessages,
  SendMessage,
  getAllOrdersAdmin,
  SendOrderMessage,
  GetOrderMessages,
  AddDiscountCoupon,
  deleteDiscountCoupon,
  EditDiscountCoupon,
  GetSingleDiscountCoupon,
  deleteBulkDiscountSection,
  updateBulkStatus,
  getAllOrders,
  GetAdminRFQs,
  GetOrders,
  GetSingleOrder,
  GetOrderDetails,
  GetUser_OrderDetails,
  AdminProcessOrder,
  AdditionalTip,
  LalamoveLinkID,
  CancelLalamoveOrder,
  getSettlementReport,
  getSalesReport,
  getDeliveryFeeReport,
  UpdateStatus,
  UpdateStatusMember,
  getUserPointStatement,
  getUserStatement
};

export default DiscountCouponService;