import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "../Authentication/auth.service";
import "./LogoutModal.css";
import { Button } from "reactstrap";

const MyProfile = () => {
  const navigate = useNavigate();
  const [logo, setLogo] = useState(localStorage.getItem("system_logo"));

  useEffect(() => {
    // Perform any necessary setup when the component mounts
  }, []);

  const handleLogout = () => {
    if (localStorage.getItem("user_type") === "users") {
      AuthService.logout();
      navigate("/user-login/Members");
      window.location.reload();
    } else {
      AuthService.logout();
      navigate("/login");
      window.location.reload();
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        textAlign: "center",
        borderRadius: "10px",
        overflow: "hidden",
        padding: "30px",
      }}
    >
      <div style={{ marginBottom: "20px" }}>
        <img
          src={logo}
          alt="Nano"
          style={{ width: "100px", height: "100px", marginBottom: "20px" }}
        />
      </div>
      <h2 style={{ fontSize: "24px", fontWeight: "bold", marginBottom: "10px" }}>
        Thank You!
        <p style={{ fontSize: "16px", color: "#6c757d" }}>
          We hope to see you again soon.
        </p>
      </h2>
      <Button
        color="primary"
        onClick={handleLogout}
        style={{
          backgroundColor: "#007bff",
          border: "none",
          padding: "10px 20px",
          fontSize: "16px",
          borderRadius: "5px",
          marginTop: "20px",
        }}
      >
        Back to Login
      </Button>
    </div>
  );
};

export default MyProfile;
