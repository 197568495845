import React, { useState } from "react";
import axios from "axios";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  CircularProgress,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import { Lock, Close } from "@mui/icons-material"; // Icons for design enhancement
import AlertService from "./Alerts/alerts"; // Ensure you have an AlertService for notifications

const B_URL = process.env.REACT_APP_LOGINURL;

const EpinVerification = ({ open, onClose, onSuccess }) => {
  const [epin, setEpin] = useState("");
  const [loading, setLoading] = useState(false);

  const handleEpinSubmit = async () => {
    try {
      setLoading(true);
      const user = JSON.parse(localStorage.getItem("user"));
      if (!user || !user._id) {
        console.error("User data not found in localStorage");
        AlertService.swalErrorAlert("User not logged in!");
        return;
      }

      const headers = { "Content-Type": "application/json" };
      const payload = { user_id: user._id, epin };
      const response = await axios.post(`${B_URL}UserModule/Verify_Epin`, payload, { headers });

      if (response.data.success) {
        onSuccess(); // Call success callback
        onClose(); // Close modal
      } else {
        AlertService.swalErrorAlert(response?.data?.message || "Invalid E-PIN! Try Again.");
      }
    } catch (error) {
      console.error("E-PIN verification failed:", error);
      AlertService.swalErrorAlert("E-PIN verification failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      {/* Header with Gradient Background */}
      <Box
        sx={{
          background: "linear-gradient(135deg, #b5a642, #b5a642)",
          color: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "16px 24px",
        }}
      >
        <Box display="flex" alignItems="center">
          <Lock sx={{ marginRight: 1, fontSize: 30 }} />
          <Typography variant="h6" fontWeight="bold">
            Enter E-PIN
          </Typography>
        </Box>
        <IconButton onClick={onClose} sx={{ color: "white" }}>
          <Close />
        </IconButton>
      </Box>

      {/* Dialog Content */}
      <DialogContent sx={{ padding: "24px", textAlign: "center" }}>
        <Typography variant="body1" color="textSecondary" mb={2}>
          Please enter your **E-PIN** to proceed.
        </Typography>

        {/* Input Field with Border Animation */}
        <TextField
          autoFocus
          fullWidth
          type="password"
          label="E-PIN"
          variant="outlined"
          value={epin}
          onChange={(e) => setEpin(e.target.value)}
          sx={{
            "& .MuiOutlinedInput-root": {
              "&:hover fieldset": {
                borderColor: "#1976d2",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#42a5f5",
              },
            },
          }}
        />
      </DialogContent>

      {/* Action Buttons */}
      <DialogActions sx={{ padding: "16px 24px", justifyContent: "center" }}>
        <Button
          onClick={onClose}
          variant="outlined"
          color="error"
          sx={{
            borderRadius: "20px",
            padding: "8px 24px",
            fontWeight: "bold",
            "&:hover": { backgroundColor: "#ffebee" },
          }}
          disabled={loading}
        >
          Cancel
        </Button>

        <Button
          onClick={handleEpinSubmit}
          variant="contained"
          sx={{
            background: "linear-gradient(135deg, #b5a642, #b5a642)",
            color: "white",
            borderRadius: "20px",
            padding: "8px 24px",
            fontWeight: "bold",
            "&:hover": { background: "#1565c0" },
          }}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} sx={{ color: "white" }} /> : "Submit"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EpinVerification;
