import React, { useEffect, useState, Fragment } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import DataTable from 'react-data-table-component';
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import AuthService from "../Authentication/auth.service";
import { useTranslation } from 'react-i18next';
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";
import Alerts from "../../components/Alerts/alerts";
import Services from "./services";
import AddMLMTier from "./AddMLMTier";
import UpdateMLMTier from "./UpdateMLMTier";
import MLMTierFilter from "../../components/Table Filters/MLMTierFilter.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faEye, faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@mui/material/Tooltip';
import { Chip } from '@mui/material';
import styled from 'styled-components';
import PermissionAccess from '../../PermissionAccess/Access.js';

const StyledCard = styled(Card)`
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  border: 1px solid #e9ecef;
`;

const ActionButton = styled(Button)`
  padding: 6px 12px;
  margin: 0 4px;
  font-size: 13px;
`;

const StyledChip = styled(Chip)`
  font-size: 12px;
  height: 24px;
  font-weight: 500;
`;

const MLMTierListing = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [tiers, setTiers] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const { authData, accessPages, super_admin, current_module } = PermissionAccess();
  const [MLMTierToEdit, setMLMTierToEdit] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchTiers(currentPage, pageLimit, filterText, filterStatus);
  }, [pageLimit, i18n]);

  function fetchTiers(page_index, page_limit, filterText, filterStatus) {
    var offset = (page_index - 1) * page_limit;
    if (offset < 1) {
      offset = 0;
    }
    const stored_user = authData.userData;
    let business = []
    if (stored_user.businesses) {
      business = stored_user.businesses
    }

    var getData = {
      functionName: "GetMLMTiers",
      "postData": {
        "offset": offset,
        "filterStatus": filterStatus,
        "filterText": filterText,
        "sort": {
          "_id": -1
        },
        "limit": page_limit,
        business_id: business
      }
    }
    setLoading(true);
    Services.GetMLMTiers(authData.sessionID, getData)
      .then((response) => {
        setLoading(false);
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        } else {
          setTiers(response.data.data);
          setTotalRows(response.data.count.count);
        }
      })
      .catch((error) => {
        setLoading(false);
        Alerts.swalErrorAlert(t("Server Error, Please try again later."));
      });
  }
  const handleSuccess = () => {
    fetchTiers(currentPage, pageLimit, filterText, filterStatus);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchTiers(page, pageLimit, filterText, filterStatus);
  };

  const handlePerRowsChange = async (newLimit, page) => {
    setPageLimit(newLimit);
  };

  const handleSearch = (searchText, status) => {
    setFilterText(searchText);
    setFilterStatus(status);
    fetchTiers(0, pageLimit, searchText, status);
  };

  const handleClear = () => {
    setFilterText('');
    setFilterStatus('');
    fetchTiers(0, pageLimit, '', '');
  };

  const subHeaderComponentMemo = React.useMemo(() => (
    <MLMTierFilter
      onSearch={handleSearch}
      onClear={handleClear}
      filterText={filterText}
      filterStatus={filterStatus}
    />
  ), [filterText, filterStatus]);

  const handleDeleteSelected = () => {
    if (selectedRows?.length > 0) {
      Alerts.swalConfirmDeleteAlert(t(selectedRows?.length + " records selected. Once deleted, you will not be able to recover this!"))
        .then(res => {
          if (res) {
            deleteBulkCustomers(selectedRows);
          }
        });
    } else {
      Alerts.swalErrorAlert('No records selected');
    }
  };
  const deleteBulkCustomers = (ids) => {
    setLoading(true);
    Services.DeleteBulkMLMTier(authData.sessionID, ids)
      .then((response) => {
        setLoading(false);
        setSelectedRows([]);
        if (response.data.status) {
          Alerts.swalSuccessAlert(response.data.message || t("Data Deleted Successfully."));
          fetchTiers(currentPage, pageLimit, filterText, filterStatus);
        } else {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
          } else {
            Alerts.swalErrorAlert(response.data.message || t("Error: Can't delete the Collection Data at the moment."));
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        Alerts.swalErrorAlert(t("Delete operation failed"));
      });
  };
  const handleSelectAllChange = (e) => {
    if (e.target.checked) {
      setSelectedRows(tiers.map(MLMTier => MLMTier._id));
    } else {
      setSelectedRows([]);
    }
  };


  const confirmDelete = (id) => {
    Alerts.swalConfirmDeleteAlert(t("Once deleted, you will not be able to recover this Record!"))
      .then(res => {
        if (res) {
          deleteMLMTier(id);
        }
      });
  };

  const deleteMLMTier = (id) => {
    setLoading(true);
    Services.DeleteMLMTier(authData.sessionID, id)
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          Alerts.swalSuccessAlert(response.data.message || t("Data Deleted Successfully."));
          fetchTiers(currentPage, pageLimit, filterText, filterStatus);
        } else {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
          } else {
            Alerts.swalErrorAlert(response.data.message || t("Error: Can't delete at the moment."));
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        Alerts.swalErrorAlert(t("Delete operation failed"));
      });
  };

  const columns = [
    {
      name: t("Level"),
      selector: row => row.level,
      sortable: true,
      cell: row => (
        <div className="d-flex align-items-center">
          <FontAwesomeIcon icon={faLayerGroup} className="text-primary me-2" />
          <span>{row.level}</span>
        </div>
      )
    },
    {
      name: t("Percentage"),
      selector: row => row.percentage,
      sortable: true,
      cell: row => (
        <StyledChip
          label={`${row.percentage}%`}
          color="primary"
          variant="outlined"
        />
      )
    },
    {
      name: t("Referral Tier"),
      selector: row => row.referral_tier,
      sortable: true,
      cell: row => (
        <StyledChip
          label={row.referral_tier}
          color="primary"
          size="small"
        />
      )
    },
    {
      name: t('Status'),
      width: '120px',
      cell: (row) => (
        <StyledChip
          label={row.status}
          color={row.status === 'Active' ? 'success' : 'error'}
          variant="outlined"
          size="small"
        />
      )
    },
    {
      name: t("Actions"),
      width: '150px',
      cell: (row) => {
        return (
          <div>
          <button className="mt-2 mb-2 me-2 btn-icon btn">
            {(super_admin || current_module?.ViewData === 1) && (
              <Tooltip title={t("View Details")}>
                <Link to="/MLM_Tier_details" state={row}>
                  <i className="pe-7s-note2 btn-icon-wrapper text-info"> </i>
                </Link>
              </Tooltip>
            )}
            {(super_admin || current_module?.EditData === 1) && (
              <Tooltip title={t("Edit")}>
                <i onClick={() => setMLMTierToEdit(row)} className="pe-7s-pen btn-icon-wrapper text-primary"> </i>
              </Tooltip>
            )}
            {(super_admin || current_module?.DeleteData === 1) && (
              <Tooltip title={t("Delete")}>
                <i onClick={() => confirmDelete(row._id)} className="pe-7s-trash text-danger btn-icon-wrapper"> </i>
              </Tooltip>
            )}
          </button>
        </div>
        );
      }
    }
  ];
  

  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div style={{ background: '#f8f9fa', minHeight: '100vh' }}>
            <div className="d-flex justify-content-center align-items-center">
              <Col md="12" className="mx-auto app-login-box">
                <div className="app-page-title mb-3">
                  <div className="page-title-wrapper">
                    <div className="page-title-heading">
                      <div className="page-title-icon bg-white">
                        <FontAwesomeIcon icon={faLayerGroup} className="text-primary" />
                      </div>
                      <div>
                        <h2>{t("Tiers Configuration")}</h2>
                        <div className="page-title-subheading">
                          {t("Manage tier Configuration levels and referral percentages")}
                        </div>
                      </div>
                    </div>
                    <div className="page-title-actions">
                      <Button
                        color="secondary"
                        outline
                        onClick={() => navigate(-1)}
                      >
                        {t("Back")}
                      </Button>
                    </div>
                  </div>
                </div>

                <StyledCard>
                  <CardBody>
                    <LoadingOverlay active={loading} spinner={<DNALoader />}>
                      <Row className="mb-3">
                        <Col md="6">
                          {subHeaderComponentMemo}
                        </Col>
                        <Col md="6" className="text-end">
                          {(super_admin || current_module?.BulkDeleteData === 1) && (
                            <Button color="danger" outline onClick={handleDeleteSelected} className="me-2">
                              {t('Delete Selected')}
                            </Button>
                          )}
                          {(super_admin || current_module?.AddData === 1) && (
                            <AddMLMTier onSuccess={handleSuccess} />
                          )}
                        </Col>
                      </Row>
                      <DataTable
                        columns={columns}
                        data={tiers}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handlePerRowsChange}
                        selectableRows
                        onSelectedRowsChange={({ selectedRows }) => 
                          setSelectedRows(selectedRows.map(row => row._id))}
                        highlightOnHover
                        pointerOnHover
                        dense
                      />
                    </LoadingOverlay>
                  </CardBody>
                </StyledCard>
              </Col>
            </div>
          </div>
        </div>
      </div>
      {MLMTierToEdit && (
        <UpdateMLMTier
        MLM_Tier={MLMTierToEdit}
          onSuccess={() => {
            setMLMTierToEdit(null);
            handleSuccess();
          }}
          onCancel={() => setMLMTierToEdit(null)}
        />
      )}
    </Fragment>
  );
};

export default MLMTierListing;