import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, Navigate } from "react-router-dom";
import { Row, Col, FormGroup, Input, Button, Label, Modal, ModalHeader, ModalBody, Card, CardBody } from "reactstrap";
import Services from "./services";
import AuthService from "../Authentication/auth.service";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import Alerts from "../../components/Alerts/alerts";
import PermissionAccess from '../../PermissionAccess/Access.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faTimes, faFilePdf, faImage } from '@fortawesome/free-solid-svg-icons';


const Preview = ({ file, onRemove }) => {
  if (!file) return null;

  const style = {
    position: 'absolute',
    top: '-160px',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 1000,
    padding: '10px',
    borderRadius: '5px',
    boxShadow: '0 0 10px rgba(0,0,0,0.5)',
    maxWidth: '300px',
    maxHeight: '300px'
  };

  const removeButtonStyle = {
    position: 'absolute',
    top: '5px',
    right: '5px',
    zIndex: 1001,
    backgroundColor: 'red',
    color: 'white',
    border: 'none',
    borderRadius: '50%',
    width: '25px',
    height: '25px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  };

  const renderContent = () => {
    if (typeof file === 'string') {
      // Existing document URL
      if (file.toLowerCase().endsWith('.pdf')) {
        return <iframe src={file} title="PDF Preview" width="280" height="280" />;
      } else {
        return <img src={file} alt="Document Preview" style={{ maxWidth: '280px', maxHeight: '280px' }} />;
      }
    } else if (file instanceof File) {
      // New file upload
      if (file.type === 'application/pdf') {
        return <iframe src={URL.createObjectURL(file)} title="PDF Preview" width="280" height="280" />;
      } else if (file.type.startsWith('image/')) {
        return <img src={URL.createObjectURL(file)} alt="Image Preview" style={{ maxWidth: '280px', maxHeight: '280px' }} />;
      }
    }
    return <div>Unsupported file type</div>;
  };

  return (
    <div style={style}>
      <button onClick={onRemove} style={removeButtonStyle}>
        <FontAwesomeIcon icon={faTimes} />
      </button>
      {renderContent()}
    </div>
  );
};



const UpdateMember = ({ MEMBER, onSuccess, onCancel }) => {
  const [modal, setModal] = useState(true);
  const [Member, setMember] = useState({ ...MEMBER });
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loginNavigate, setLoginNavigate] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);
  const { authData } = PermissionAccess();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    // Initialize documents with existing URLs
    if (Member.Documents && Member.Documents.length > 0) {
      setDocuments(Member.Documents.map(docUrl => ({ url: docUrl, isExisting: true })));
    }
  }, [Member, i18n]);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setDocuments(prev => [...prev, ...files.map(file => ({ file, isExisting: false }))]);
  };

  const removeDocument = (docIndex) => {
    setDocuments(prev => prev.filter((_, index) => index !== docIndex));
    setPreviewFile(null);
  };

  const handleMemberChange = (field, value) => {
    setMember(prev => ({ ...prev, [field]: value }));
  };

  const validateForm = () => {
    return Member.name &&
      Member.RegistrationNumber &&
      Member.email &&
      Member.Phone &&
      Member.Address &&
      documents.length > 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      Alerts.swalErrorAlert("Please fill all fields and upload at least one document for the Member.");
      return;
    }

    const formData = new FormData();

    // Append Member data to formData
    Object.keys(Member).forEach(key => {
      if (key !== 'Documents') {
        formData.append(key, Member[key]);
      }
    });

    // Append documents
    documents.forEach((doc, index) => {
      if (doc.isExisting) {
        formData.append(`ExistingDocuments[${index}]`, doc.url);
      } else {
        formData.append(`NewDocuments`, doc.file);
      }
    });

    setLoading(true);
    try {
      const response = await Services.UpdateMember(authData.sessionID, Member._id, formData);
      setLoading(false);
      if (response.data.status) {
        onSuccess();
        Alerts.swalSuccessAlert(response.data.message || "Member Updated Successfully.").then(() => {
          setModal(false);
        });
      } else {
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          setLoginNavigate(true);
        } else {
          Alerts.swalErrorAlert(response.data.message || "Error: Can't Update Member at the moment. Please try again later.");
        }
      }
    } catch (error) {
      setLoading(false);
      Alerts.swalErrorAlert("Network Operation failed.");
    }
  };

  if (loginNavigate) {
    return <Navigate to={'/login'} />;
  }

  return (
    <Modal size='lg' isOpen={modal} toggle={() => setModal(false)} backdrop={true}>
      <LoadingOverlay active={loading} spinner={<DNALoader />}>
        <ModalHeader>{t("Update Member")}</ModalHeader>
        <ModalBody>
          <Card className="main-card mb-3">
            <CardBody>
              <form onSubmit={handleSubmit}>
                <FormGroup className="mb-3">
                  <Row>
                    <Col md="6">
                      <Label>{t("Member Name")}</Label>
                      <Input
                        type="text"
                        value={Member.name}
                        onChange={(e) => handleMemberChange('name', e.target.value)}
                        placeholder={t("Enter Member Name")}
                      />
                    </Col>
                    <Col md="6">
                      <Label>{t("Registration Number")}</Label>
                      <Input
                        type="text"
                        value={Member.RegistrationNumber}
                        onChange={(e) => handleMemberChange('RegistrationNumber', e.target.value)}
                        placeholder={t("Enter Registration Number")}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <Label>{t("Email")}</Label>
                      <Input
                        type="email"
                        value={Member.email}
                        onChange={(e) => handleMemberChange('email', e.target.value)}
                        placeholder={t("Enter Email")}
                      />
                    </Col>
                    <Col md="6">
                      <Label>{t("Phone")}</Label>
                      <Input
                        type="tel"
                        value={Member.Phone}
                        onChange={(e) => handleMemberChange('Phone', e.target.value)}
                        placeholder={t("Enter Phone Number")}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <Label>{t("Address (Google Location)")}</Label>
                      <Input
                        type="text"
                        value={Member.Address}
                        onChange={(e) => handleMemberChange('Address', e.target.value)}
                        placeholder={t("Enter Address")}
                      />
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col md="12">
                      <Label>{t("Documents")}</Label>
                      <div className="document-upload-container" style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                        {documents.map((doc, docIndex) => (
                          <div
                            key={docIndex}
                            style={{ position: 'relative', width: '100px', height: '100px' }}
                            onMouseEnter={() => setPreviewFile(doc)}
                            onMouseLeave={() => setPreviewFile(null)}
                          >
                            {doc.isExisting ? (
                              doc.url.toLowerCase().endsWith('.pdf') ? (
                                <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#f0f0f0' }}>
                                  <FontAwesomeIcon icon={faFilePdf} size="2x" />
                                </div>
                              ) : (
                                <img src={`${process.env.REACT_APP_IMAGEURL}${doc.url}`} alt={`Document ${docIndex + 1}`} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                              )
                            ) : (
                              doc.file.type.startsWith('image/') ? (
                                <img src={URL.createObjectURL(doc.file)} alt={`Document ${docIndex + 1}`} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                              ) : (
                                <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#f0f0f0' }}>
                                  <FontAwesomeIcon icon={faFilePdf} size="2x" />
                                </div>
                              )
                            )}
                            <Button
                              color="danger"
                              size="sm"
                              style={{ position: 'absolute', top: '-10px', right: '-10px', borderRadius: '50%', padding: '0.2rem 0.5rem' }}
                              onClick={() => removeDocument(docIndex)}
                            >
                              <FontAwesomeIcon icon={faTimes} />
                            </Button>
                            {previewFile === doc &&
                              <Preview
                                file={doc.isExisting ? `${process.env.REACT_APP_IMAGEURL}${doc.url}` : doc.file}
                                onRemove={() => removeDocument(docIndex)}
                              />}
                          </div>
                        ))}
                        <div style={{ width: '100px', height: '100px', border: '2px dashed #ccc', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
                          <label htmlFor="file-upload" style={{ cursor: 'pointer', margin: 0 }}>
                            <FontAwesomeIcon icon={faUpload} size="2x" />
                            <Input
                              type="file"
                              id="file-upload"
                              onChange={handleFileChange}
                              accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                              multiple
                              style={{ display: 'none' }}
                            />
                          </label>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className="text-center mt-3">
                    <Button type="submit" color="success" size="lg" className="me-3">
                      {t("Update")}
                    </Button>
                    <Button type="button" color="secondary" size="lg" onClick={() => { setModal(false); onCancel(); }}>
                      {t("Cancel")}
                    </Button>
                  </div>
                </FormGroup>
              </form>
            </CardBody>
          </Card>
        </ModalBody>
      </LoadingOverlay>
    </Modal >
  );
};

export default UpdateMember;